import api from '@/config/api';

export default {
  /**
   * @deprecated
   * Use whatsAppServices.updateConversationTags instead
   */
  updateConversationTags: ({
    key,
    number,
    tags,
  }: {
    key?: string;
    number?: string;
    tags?: string[];
  }) =>
    api.whatsApp.v1.put('/conversation/tags', {
      key,
      number,
      tags,
    }),
};
