import { useMutation, useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { CommonAnalyticParams } from '@/api/services/bitLink/analytics';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { downloadXlsxFile } from '@/utils/common/file';

const ANALYTICS_QUERY_KEY = 'bitlink-analytics' as const;

export const useEngagementRate = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-engagement-rate`, activeCompany, params],
    () => services.bitLink.analytics.getEngagementRate(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useClicksViewsCTR = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-clicks-views-ctr`, activeCompany, params],
    () => services.bitLink.analytics.getClicksViewsCTR(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useCTR = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-ctr`, activeCompany, params],
    () => services.bitLink.analytics.getCTR(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useDownloadSubscribers = () => {
  const activeCompany = useActiveCompany();

  return useMutation(
    async (
      payload: Parameters<
        typeof services.bitLink.analytics.downloadSubscribers
      >[1]
    ) => services.bitLink.analytics.downloadSubscribers(activeCompany, payload),
    {
      onSuccess: (data) => {
        downloadXlsxFile(data, 'subscribers-information.xlsx');
      },
    }
  );
};

export const useLinkClick = (linkId: number | undefined) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-link-click`, activeCompany, linkId],
    () => services.bitLink.analytics.getLinkClick(activeCompany, linkId)
  );
};

export const useLinkClicks = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-link-clicks`, activeCompany, params],
    () => services.bitLink.analytics.getLinkClicks(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const usePageShared = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-page-shared`, activeCompany, params],
    () => services.bitLink.analytics.getPageShared(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const usePageView = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-page-view`, activeCompany, params],
    () => services.bitLink.analytics.getPageView(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const usePageViewCharts = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-page-view-charts`, activeCompany, params],
    () => services.bitLink.analytics.getViewCharts(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useProductEvent = (
  linkId: number | undefined,
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-product-event`, activeCompany, linkId, params],
    () =>
      services.bitLink.analytics.getProductEvent(activeCompany, linkId, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useProductEventClicks = (
  linkId: number | undefined,
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      `${ANALYTICS_QUERY_KEY}-product-event-clicks`,
      activeCompany,
      linkId,
      params,
    ],
    () =>
      services.bitLink.analytics.getProductEventClicks(
        activeCompany,
        linkId,
        params
      ),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useProductEventSummary = (
  linkId: number | undefined,
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      `${ANALYTICS_QUERY_KEY}-product-event-summary`,
      activeCompany,
      linkId,
      params,
    ],
    () =>
      services.bitLink.analytics.getProductEventSummary(
        activeCompany,
        linkId,
        params
      ),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useSubscribers = (
  params?: Parameters<typeof services.bitLink.analytics.getSubscribers>[1],
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-subscribers`, activeCompany, params],
    () => services.bitLink.analytics.getSubscribers(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useTopLinks = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-top-links`, activeCompany, params],
    () => services.bitLink.analytics.getTopLinks(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useTopLocations = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-top-locations`, activeCompany, params],
    () => services.bitLink.analytics.getTopLocations(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useTrafficSource = (
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-traffic-source`, activeCompany, params],
    () => services.bitLink.analytics.getTrafficSource(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useProductLinkClick = (
  productId?: string | undefined,
  params?: CommonAnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      `${ANALYTICS_QUERY_KEY}-product-link-click`,
      activeCompany,
      productId,
      params,
    ],
    () =>
      services.bitLink.analytics.getProductLinkClick(
        activeCompany,
        productId,
        params
      ),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};
