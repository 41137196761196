function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeOpacity="0.5"
        strokeWidth="2"
        d="M14 14l-2.99-2.996M12.667 7A5.667 5.667 0 111.334 7a5.667 5.667 0 0111.333 0v0z"
      ></path>
    </svg>
  );
}

export default SearchIcon;
