import { useHistory, useLocation } from 'react-router';
import { twMerge as cx } from 'tailwind-merge';
import ChatbotSettings from './components/ChatbotSettings/ChatbotSettings';
import LivechatSettings from './components/LivechatSettings/LivechatSettings';
import OperationHours from './components/OperationalHours';
import SoundNotification from './components/SoundNotifcation';

import { BBBContainer } from '@/components/ui';
import BBBNavigationCard from '@/components/ui/BBBNavigationCard/BBBNavigationCard';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';

const settingsNavigation = [
  { label: 'Operational hours', value: 'ops-hours' },
  { label: 'Live chat & ticket', value: 'livechat-ticket' },
  { label: 'Chatbot', value: 'chatbot' },
  { label: 'Notifications', value: 'notifications' },
];

const defaultNavigation = settingsNavigation[0].value;

export default function BitChatSettings() {
  const { data: isRestricted } = useIsModuleRestricted('BITCHAT_SETTINGS');

  return (
    <BBBContainer
      className={cx(
        isRestricted && 'pointer-events-none',
        'max-w-none lg:max-w-none'
      )}
    >
      <_BitChatSettings />
    </BBBContainer>
  );
}

function _BitChatSettings() {
  const query = useQuerySearchParams();

  const history = useHistory();
  const location = useLocation();

  const section = query.get('section') || defaultNavigation;

  const selectedTitle = settingsNavigation.find(
    (setting) => setting.value === section
  )?.label;

  const isMobile = useResponsive('sm');

  return (
    <div className="flex gap-6 items-start">
      {!isMobile && (
        <BBBNavigationCard
          selected={section}
          options={settingsNavigation}
          onChange={(val) =>
            history.push({
              pathname: location.pathname,
              search: `?section=${val}`,
            })
          }
          title="bitChat settings"
        />
      )}
      <div className="grow">
        <div className="mt-2 mb-5 text-2xl font-medium">{selectedTitle}</div>
        {section === 'ops-hours' && <OperationHours />}
        {section === 'livechat-ticket' && <LivechatSettings />}
        {section === 'notifications' && <SoundNotification />}
        {section === 'chatbot' && <ChatbotSettings />}
      </div>
    </div>
  );
}
