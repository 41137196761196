import { yupResolver } from '@hookform/resolvers/yup';
import GeneralLayout from 'layouts/GeneralLayout';
import * as yup from 'yup';

import AuthCard from '@/components/Auth/Card';
import { BBBButton, BBBTextInput } from '@/components/ui';
import useResetPasswordRequest from '@/hooks/auth/useResetPasswordRequest';
import useCustomForm from '@/hooks/common/useCustomForm';

const schema = yup.object().shape({
  email: yup.string().email().label('Email').required(),
});

type FormSchema = {
  email: string;
};

function ResetPassword() {
  const { mutate: resetPasswordRequest, isLoading } = useResetPasswordRequest();

  const { control, handleSubmit, formState } = useCustomForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = ({ email }: FormSchema) => {
    resetPasswordRequest({ email });
  };

  return (
    <GeneralLayout>
      <AuthCard title="Reset password">
        <div className="flex flex-col gap-2 mb-7">
          <BBBTextInput
            type="email"
            label="Email"
            placeholder="Type your email"
            isHookForm
            containerClassname="mb-0"
            control={control}
            controlName="email"
            error={formState.errors.email?.message}
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <BBBButton
            loadingState={isLoading}
            text="Send email"
            width="full"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </AuthCard>
    </GeneralLayout>
  );
}

export default ResetPassword;
