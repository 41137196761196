import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow';
import { createId } from '@paralleldrive/cuid2';
import { omit, pick } from 'lodash-es';
import { create } from 'zustand';
import { CreateConnectionProps } from './components/Nodes/CreateConnection';
import {
  ActionData,
  MessageData,
  TriggerAIData,
  TriggerMessageData,
  WelcomeActionData,
} from './types';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

type State =
  | {
      type: string;
      sourceId: string;
    }
  | {
      type: string;
      nodeId: string;
    };

type TriggerModalState = {
  type: string;
  id?: string;
};

export type RFState = {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  showStateModal: State | null;
  onChangeStateModal: (props: State | null) => void;
  triggerModalState: TriggerModalState | null;
  onChangeTriggerModalState: (props: TriggerModalState | null) => void;
  updateChildNodeDimensions: (
    nodeId: string,
    dimensions: { x: number; y: number }
  ) => void;
  deleteRelation: (nodeId: string) => void;
  addRelations: (nodes: Node[], edges?: Edge[]) => void;
  updateMessageNode: (
    nodeId: string,
    data: MessageData | TriggerMessageData | WelcomeActionData
  ) => void;
  updateTriggerAINode: (nodeId: string, data: TriggerAIData) => void;
  updateActionNode: (nodeId: string, data: ActionData) => void;
  activeAddState: string | null;
  loadingDuplicateFlow: boolean;
  onChangeActiveAddState: (val: string | null) => void;
  setNodes: (val: Node[]) => void;
  setEdges: (val: Edge[]) => void;
  expandState: { [id: string]: CreateConnectionProps['state'] } | null;
  setExpandState: (
    val: string,
    payload: CreateConnectionProps['state']
  ) => void;
  setLoadingDuplicateFlow: (val: boolean) => void;
  connectingNode: string | null;
  setConnectingNode: (val: string | null) => void;
  resetConnectingNode: (params: {
    mouseX: number;
    mouseY: number;
    viewportX: number;
    viewportY: number;
    zoom: number;
    sourceId: string;
    callback?: (dropNodeExists: boolean) => void;
  }) => void;
  transitioningCreateEdges: {
    source: string;
    target: string;
  } | null;
};

const useStore = create<RFState>((set, get) => ({
  nodes: initialNodes,
  edges: initialEdges,
  showStateModal: null,
  activeAddState: null,
  triggerModalState: null,
  expandState: null,
  loadingDuplicateFlow: false,
  connectingNode: null,
  transitioningCreateEdges: null,
  setLoadingDuplicateFlow: (val) => set({ loadingDuplicateFlow: val }),
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },
  onChangeStateModal: (props) => {
    set({ showStateModal: props });
  },
  onChangeTriggerModalState: (props) => {
    set({ triggerModalState: props });
  },
  updateChildNodeDimensions: (nodeId, { x, y }) => {
    set({
      nodes: get().nodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              position: {
                ...node.position,
                x,
                y,
              },
            }
          : node
      ),
    });
  },
  deleteRelation: (nodeId) => {
    set({
      nodes: get().nodes.filter((node) =>
        node.parentNode ? node.parentNode !== nodeId : node.id !== nodeId
      ),
      edges: get().edges.filter((edge) =>
        edge.target === nodeId || edge.source === nodeId ? false : true
      ),
    });
  },
  addRelations: (nodes: Node[], edges?: Edge[]) => {
    set({
      nodes: [...get().nodes, ...nodes],
      ...(edges && { edges: [...get().edges, ...edges] }),
    });
  },
  updateActionNode: (nodeId, data) => {
    set({
      nodes: [
        ...get().nodes.map((node) =>
          node.id === nodeId ? { ...node, data } : node
        ),
      ],
    });
  },
  updateMessageNode: (nodeId, data) => {
    const nodes = get().nodes;
    const existingNodeIds = nodes.map((node) => node.id);

    set({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      nodes: [
        ...nodes
          .filter((node) =>
            node.parentNode === nodeId
              ? (data.replies ?? []).map((reply) => reply.id).includes(node.id)
              : true
          )
          .map((node) => {
            return node.id === nodeId
              ? {
                  ...node,
                  data:
                    'messageCondition' in data
                      ? {
                          ...omit(data, ['replies', 'messageCondition']),
                          action: data.messageCondition,
                        }
                      : omit(data, ['replies']),
                }
              : node.parentNode === nodeId &&
                (data.replies ?? []).some((reply) => reply.id === node.id)
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    ...pick(
                      (data.replies ?? []).find(
                        (reply) => reply.id === node.id
                      ),
                      ['reply', 'index']
                    ),
                  },
                }
              : node;
          }),
        ...(data.replies || [])
          .filter((reply) => !existingNodeIds.includes(reply.id))
          .map(({ id, reply, index }) => {
            return {
              id,
              type: 'child1',
              data: {
                reply,
                parentId: nodeId,
                index,
              },
              position: { x: 0, y: 0 },
              draggable: false,
              extent: 'parent',
              parentNode: nodeId,
            };
          }),
      ],
    });
  },
  onChangeActiveAddState: (val) => set({ activeAddState: val }),
  setNodes: (nodes) => set({ nodes }),
  setEdges: (edges) => set({ edges }),
  updateTriggerAINode: (nodeId, data) => {
    const nodes = get().nodes;
    const existingNodeIds = nodes.map((node) => node.id);

    set({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      nodes: [
        ...nodes
          .filter((node) =>
            node.parentNode === nodeId
              ? [
                  ...data.conditions.map((reply) => reply.id),
                  ...data.customConditions.map((reply) => reply.id),
                ].includes(node.id)
              : true
          )
          .map((node) => {
            if (node.parentNode !== nodeId) return node;

            const type = data.customConditions.some(
              (reply) => reply.id === node.id
            )
              ? 'customCondition'
              : 'condition';

            return {
              ...node,
              data: {
                ...node.data,
                [type]: (type === 'customCondition'
                  ? data.customConditions
                  : data.conditions
                ).find((condition) => condition.id === node.id)?.value,
              },
            };
          }),
        ...data.conditions
          .filter((reply) => !existingNodeIds.includes(reply.id))
          .map(({ id, value }) => {
            return {
              id,
              type: 'child1',
              data: {
                condition: value,
                parentId: nodeId,
              },
              position: { x: 0, y: 0 },
              draggable: false,
              extent: 'parent',
              parentNode: nodeId,
            };
          }),
        ...data.customConditions
          .filter((reply) => !existingNodeIds.includes(reply.id))
          .map(({ id, value }) => {
            return {
              id,
              type: 'child1',
              data: {
                customCondition: value,
                parentId: nodeId,
              },
              position: { x: 0, y: 0 },
              draggable: false,
              extent: 'parent',
              parentNode: nodeId,
            };
          }),
      ],
    });
  },
  setExpandState: (id, payload) => {
    set({ expandState: { ...get().expandState, [id]: payload } });
  },
  setConnectingNode: (val) => {
    set((state) => ({
      connectingNode: val,
      nodes: state.nodes.map((node) => {
        if (node.id === val) {
          return {
            ...node,
            draggable: false,
          };
        }

        return node;
      }),
    }));
  },
  resetConnectingNode: ({
    mouseX,
    mouseY,
    viewportX,
    viewportY,
    zoom,
    sourceId,
    callback,
  }) => {
    const prevNodes = get().nodes;
    const prevEdges = get().edges;

    const nearestNode = prevNodes.find((node) => {
      const { clientWidth: width, clientHeight: height } =
        document.querySelector(`.react-flow__node[data-id="${node.id}"]`)!;

      const nodeLeft = viewportX + node.position.x * zoom;
      const nodeRight = viewportX + (node.position.x + width) * zoom;

      const nodeTop = viewportY + node.position.y * zoom;
      const nodeBottom = viewportY + (node.position.y + height) * zoom;

      if (
        mouseX >= nodeLeft &&
        mouseX <= nodeRight &&
        mouseY >= nodeTop &&
        mouseY <= nodeBottom
      ) {
        const sourceParent = prevNodes.find(
          (_node) => _node.id === sourceId
        )?.parentNode;

        if (node.id === sourceId) return false;

        if (sourceParent && node.id === sourceParent) return false;

        if (prevEdges.find((edge) => edge.target === node.id)) return false;

        return true;
      }

      return false;
    });

    set((state) => {
      return {
        nodes: state.nodes.map((node) => {
          if (node.type !== 'child1' && !node.draggable) {
            return {
              ...node,
              draggable: true,
            };
          }

          return node;
        }),
        connectingNode: null,
      };
    });

    if (nearestNode) {
      set((state) => ({
        edges: [
          ...state.edges,
          {
            id: createId(),
            source: sourceId,
            target: nearestNode.id,
            type: 'custom',
          },
        ],
      }));

      callback?.(true);
    } else {
      callback?.(false);
    }
  },
}));

export default useStore;
