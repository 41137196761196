import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppDispatch } from '@/hooks/rtk/store';
import { logout } from '@/stores/auth';
import { toast } from '@/utils/common/toast';

const useDeleteUser = () => {
  const dispatch = useAppDispatch();
  return useMutation(
    async () => {
      const { data } = await api.systemLogin.delete(`/auth/user`);
      return data;
    },
    {
      onSuccess: () => {
        toast.success(`User has been deleted`);
        dispatch(logout());
      },
    }
  );
};
export default useDeleteUser;
