import { twMerge as cx } from 'tailwind-merge';

import StarIcon2 from '@/assets/icons/StarIcon2';
import { BBBButton } from '@/components/ui';
import { env } from '@/config/env';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';
import { formatCurrency } from '@/utils/common/currency';

const previewProduct = {
  image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/product-live-preview.png`,
  title: 'Leather Sling Bag',
  stars: 4,
  starsValue: 4.5,
  sold: 100,
  reviews: 10,
  price: 30.88,
  variants: ['Black', 'Navy Blue', 'Brown', 'Beige'],
  description: `Elevate your everyday style with our luxurious leather bag, handcrafted for timeless elegance. Available in classic black for a touch of power, or versatile navy blue for modern sophistication, this bag seamlessly complements any outfit.`,
} as const;

export default function ProductPage() {
  const { data } = useBrandingQuery();

  return (
    <div className="relative flex flex-col h-full overflow-hidden">
      <img
        src={previewProduct.image}
        className="w-full absolute top-0 h-80 object-cover"
        alt="preview-product-image"
        loading="lazy"
      />

      <div className="absolute bottom-20 inset-x-0">
        <div className="rounded-xl px-5 py-2 bg-white">
          <div className="w-9 h-1.5 bg bg-primary-main/50 mx-auto rounded-full mb-4"></div>
          <ProductInfo />
          <ProductRating />
          <ProductVariants />
          <Divider />
          <ProductDescription />
          <Divider />
          <ProductReviews />
        </div>
      </div>

      <div className="absolute bottom-0 inset-x-0 bg-white shadow-[0px_-2px_4px_0px_#0000001A] p-4 flex items-center md:gap-5">
        <BBBButton
          text="Add to cart"
          className="w-full whitespace-normal text-[8px] md:text-base pointer-events-none"
          backgroundColor={data?.primaryColor || undefined}
        />
      </div>
    </div>
  );
}

function ProductInfo() {
  return (
    <div className="flex flex-col mb-2">
      <p className="text-primary-main font-semibold text-base">
        {previewProduct.title}
      </p>
      <p className="text-primary-main font-bold text-xl">
        {formatCurrency(previewProduct.price, 'USD')}
      </p>
    </div>
  );
}

function ProductRating() {
  return (
    <div className="flex items-center gap-1 mb-4">
      {Array.from({ length: 5 }).map((_, i) => (
        <StarIcon2
          size={14}
          key={i}
          strokeWidth={0.5}
          color={i < previewProduct.stars ? '#F1C21D' : 'transparent'}
          stroke={i < previewProduct.stars ? 'transparent' : '#F1C21D'}
        />
      ))}
      <p className="text-primary-main text-xs">
        {previewProduct.starsValue}
        <span className="text-neutral-500"> ({previewProduct.sold} sold)</span>
      </p>
    </div>
  );
}

function ProductVariants() {
  return (
    <div className="flex flex-col gap-1 mb-2">
      <p className="text-xs text-neutral-500 uppercase">Variant</p>
      <div className="flex items-center gap-1">
        {previewProduct.variants.map((variant) => (
          <div
            key={variant}
            className={cx(
              'p-1 px-2 rounded-lg border border-neutral-30',
              variant === 'Black' && 'border-primary-main'
            )}
          >
            <p className="text-xs text-primary-main">{variant}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function ProductDescription() {
  return (
    <div className="flex flex-col gap-1 mb-2">
      <p className="text-xs text-neutral-500 uppercase">Description</p>
      <p className="text-primary-main text-[9px]">
        {previewProduct.description}
      </p>
    </div>
  );
}

function ProductReviews() {
  return (
    <div className="flex flex-col gap-1 mb-2">
      <p className="text-xs text-neutral-500 uppercase">Reviews</p>
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex items-center mb-0.5">
            {Array.from({ length: 5 }).map((_, i) => (
              <StarIcon2
                size={9}
                key={i}
                strokeWidth={1}
                color={i < 4 ? '#F1C21D' : 'transparent'}
                stroke={i < previewProduct.stars ? 'transparent' : '#F1C21D'}
              />
            ))}
          </div>
          <p className="text-primary-main text-[8px]">Jessica Jane</p>
        </div>
        <p className="text-primary-main text-[9px] font-bold">
          High quality bag!
        </p>
        <p className="text-primary-main text-[9px]">
          I love the leather and the strapped handle. Thanks!
        </p>
      </div>
    </div>
  );
}

function Divider() {
  return <div className="w-full bg-[#727272] h-[0.5px] mb-2"></div>;
}
