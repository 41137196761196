import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetModelsParams } from '@/api/services/bitAi/v2-new/model';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useSortingModels = (params?: GetModelsParams) => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitai-sorting-models', activeCompany, params], () =>
    services.bitAi.v2New.model.getModels(activeCompany, params)
  );
};

export default useSortingModels;
