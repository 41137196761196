import React from 'react';

type Props = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const PencilEditIcon = ({ color = '#9A9A9A', ...props }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.93572 17.207L14.0014 7.14127C14.0795 7.06317 14.2061 7.06316 14.2842 7.14127L17.0014 9.85843C17.0795 9.93653 17.0795 10.0632 17.0014 10.1413L6.93572 20.207C6.74818 20.3945 6.49382 20.4998 6.22861 20.4998H4.14282C3.86668 20.4998 3.64282 20.276 3.64282 19.9998V17.9141C3.64282 17.6488 3.74818 17.3945 3.93572 17.207Z"
        fill={color}
      />
      <path
        d="M18.5014 8.35843L15.7842 5.64127C15.7061 5.56317 15.7061 5.43653 15.7842 5.35843L16.9357 4.20696C17.3262 3.81643 17.9594 3.81643 18.3499 4.20696L19.9357 5.79274C20.3262 6.18327 20.3262 6.81643 19.9357 7.20695L18.7842 8.35843C18.7061 8.43653 18.5795 8.43653 18.5014 8.35843Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5822 3.8534C17.1679 3.26762 18.1177 3.26762 18.7035 3.8534L20.2893 5.43919C20.8751 6.02497 20.8751 6.97472 20.2893 7.56051L19.1378 8.71198C18.8644 8.98535 18.4212 8.98535 18.1478 8.71198L15.4307 5.99482C15.1573 5.72146 15.1573 5.27824 15.4307 5.00487L16.5822 3.8534ZM17.9964 4.56051C17.8011 4.36525 17.4845 4.36525 17.2893 4.56051L16.3499 5.49985L18.6428 7.79274L19.5822 6.8534C19.7774 6.65814 19.7774 6.34156 19.5822 6.1463L17.9964 4.56051ZM14.1428 7.70696L4.28927 17.5605C4.1955 17.6543 4.14282 17.7815 4.14282 17.9141V19.9998H6.22861C6.36122 19.9998 6.48839 19.9472 6.58216 19.8534L16.4357 9.99985L14.1428 7.70696ZM14.6378 6.78772L17.355 9.50487C17.6283 9.77824 17.6283 10.2215 17.355 10.4948L7.28927 20.5605C7.00797 20.8418 6.62643 20.9998 6.22861 20.9998H4.14282C3.59054 20.9998 3.14282 20.5521 3.14282 19.9998V17.9141C3.14282 17.5162 3.30086 17.1347 3.58216 16.8534L13.6478 6.78772C13.9212 6.51435 14.3644 6.51435 14.6378 6.78772Z"
        fill={color}
      />
    </svg>
  );
};

export default PencilEditIcon;
