import { useEffect, useRef } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import isEqual from 'lodash-es/isEqual';
import { FormSchema } from '..';

import { BBBSelect, BBBTextInput } from '@/components/ui';
import { useParametersOptions } from '@/hooks/bitCRM/template/template';
import { TemplateBodyParameters } from '@/types/bitCRM/template';

type Props = {
  control: Control<FormSchema, any>;
  setValue: UseFormSetValue<FormSchema>;
  type: 'campaign' | 'automation' | 'chat';
};

export default function Params({ control, setValue, type }: Props) {
  const { fields: fieldParams } = useFieldArray({
    control,
    name: 'param',
  });

  const { errors } = useFormState({
    control,
  });

  const template = useWatch({
    control,
    name: 'template',
  });

  const params = useWatch({
    control,
    name: 'param',
  });

  const prevParamsRef = useRef<(TemplateBodyParameters | undefined)[]>();

  useEffect(() => {
    const newParams = template?.params
      ?.map((param, idx) => {
        return params?.[idx] || param;
      })
      .filter((param) => param !== null);

    if (!isEqual(newParams, prevParamsRef.current)) {
      setValue('param', newParams);
      prevParamsRef.current = newParams;
    }
  }, [params, setValue, template?.params]);

  const parameterOptions = useParametersOptions(type);

  return (
    <div className="flex flex-col gap-2 my-2">
      {fieldParams?.map((item, index) => (
        <div key={item.id}>
          <div className="flex gap-2">
            <BBBTextInput
              placeholder="Enter param value here"
              readOnly
              value={`{{${index + 1}}}`}
              containerClassname="mb-0 w-24"
              label="Parameter"
            />
            <Controller
              control={control}
              name={`param.${index}`}
              render={({ field }) => (
                <BBBSelect
                  options={parameterOptions}
                  optionLabel="label"
                  optionValue="value"
                  containerClassName="grow mb-0"
                  placeholder="Select variable"
                  value={field.value}
                  onValueChange={field.onChange}
                  label="Variable"
                  error={errors.param?.[index]?.value?.message}
                />
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
