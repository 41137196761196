import { BBBButton, BBBCard } from '@/components/ui';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setConnectIntegration } from '@/stores/common';

export default function ConnectWaCloudBanner() {
  const dispatch = useAppDispatch();

  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  if (isWhatsAppCloudConnected) {
    return null;
  }

  return (
    <>
      <BBBCard
        title="Connect to your WhatsApp Cloud API"
        desc="Connect to WhatsApp Cloud API first before creating campaign"
        rightButton={
          <BBBButton
            text="Connect"
            onClick={() =>
              dispatch(
                setConnectIntegration({
                  name: 'whatsapp_meta',
                })
              )
            }
          />
        }
        className="mb-5"
        descClassName="text-sm text-neutral-50"
      ></BBBCard>
    </>
  );
}
