import api from '@/config/api';
import { SocialSettings } from '@/types/bitLogin/v2';

export default {
  getSocialSettings: (domain: string | undefined) =>
    api.bitLogin
      .get<SocialSettings | null>(`/settings/domain/${domain}`)
      .then((res) => res.data),

  upsertSocialSettings: (
    domain: string | undefined,
    payload: Partial<SocialSettings>
  ) =>
    api.bitLogin
      .post(`/settings/domain/${domain}`, payload)
      .then((res) => res.data),
};
