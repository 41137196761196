import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { BBBBottomSheet, BBBButton, BBBTextInput } from '@/components/ui';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import { _localStorage } from '@/utils/common/localStorage';
import { cn } from '@/utils/styles';

export type BBBColorPickerTypes = {
  color?: string;
  onChangeColor: (val?: string) => void;
  /**
   * @deprecated
   * Controlled inside the components to prevent rerenders
   */
  show?: boolean;
  /**
   * @deprecated
   * Controlled inside the components to prevent rerenders
   */
  setShow?: (val: boolean) => void;
  width?: string | number;
  height?: string | number;
  label?: string;
  className?: string;
  withInput?: boolean;
  pickerClassName?: string;
  containerClassName?: string;
};

function BBBColorPicker({
  color = '#262627',
  onChangeColor,
  width = '40px',
  height = '40px',
  label,
  className,
  pickerClassName,
  withInput,
  containerClassName,
}: BBBColorPickerTypes) {
  const isMobile = useResponsive('sm');
  const [show, setShow] = useState(false);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const [currentColor, setCurrentColor] = useState(color);

  const handleSaveColor = useCallback(
    (_color: string) => {
      const colorHistory = _localStorage.getItem('colorHistory');
      if (colorHistory) {
        const parsed = JSON.parse(colorHistory);
        if (!parsed.includes(_color)) {
          parsed.unshift(_color);
          _localStorage.setItem('colorHistory', JSON.stringify(parsed));
        }
      } else {
        _localStorage.setItem('colorHistory', JSON.stringify([_color]));
      }
      onChangeColor?.(_color);
      setCurrentColor(_color);
      setShow(false);
    },
    [onChangeColor]
  );

  const handleDiscard = useCallback(() => {
    setShow(false);
    setCurrentColor(color);
  }, [color]);

  const ensureColorLength = (color: string): string => {
    if (color.length) {
      color = color.replace('#', '');
      while (color.length < 6) {
        color += color.charAt(color.length - 1);
      }
    }

    return `#${color}`;
  };

  useOutsideAlerterv2(pickerRef!, () => {
    if (show && !isMobile) {
      if (currentColor === color) {
        setShow(false);
      } else {
        handleSaveColor(ensureColorLength(currentColor));
      }
    }
  });

  return (
    <div
      className={cn('relative flex gap-2.5 items-center', containerClassName)}
    >
      <div
        className={cn(
          'relative flex-none w-10 h-10 rounded-lg cursor-pointer border border-neutral-30',
          className
        )}
        onClick={() => {
          setShow(!show);
        }}
        style={{
          background: color,
          border:
            color === '#ffffff' ||
            color === '#FFFFFF' ||
            color === 'transparent'
              ? `1px solid rgba(0,0,0,0.2)`
              : undefined,
          width,
          height,
        }}
      />
      {withInput && (
        <BBBTextInput
          inputClassName="w-28"
          value={currentColor.replace('#', '')}
          onChange={({ target: { value } }) => handleSaveColor('#' + value)}
          isFixed
          maxChar={6}
          fixedLabel="#"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onChangeColor?.(ensureColorLength(currentColor));
              handleSaveColor?.(ensureColorLength(currentColor));
              setShow(false);
            } else if (e.key === 'Escape') {
              handleDiscard();
            }
          }}
        />
      )}
      {isMobile ? (
        <BBBBottomSheet
          show={show}
          title="Pick color"
          onClose={() => handleDiscard()}
        >
          <ColorPickerPopup
            color={currentColor}
            onChangeColor={setCurrentColor}
            ref={pickerRef}
            onDiscard={handleDiscard}
            onSave={handleSaveColor}
            className={pickerClassName}
            setShow={setShow}
          />
        </BBBBottomSheet>
      ) : (
        !isMobile &&
        show && (
          <ColorPickerPopup
            color={currentColor}
            onChangeColor={setCurrentColor}
            ref={pickerRef}
            onSave={handleSaveColor}
            className={pickerClassName}
            setShow={setShow}
          />
        )
      )}
      {label && (
        <div className="flex flex-col">
          <div className="whitespace-nowrap text-sm text-primary-main">
            {label}
          </div>
          <div className="text-sm text-neutral-40">
            {color?.toLocaleUpperCase()}
          </div>
        </div>
      )}
    </div>
  );
}

export const ColorPickerPopup = React.forwardRef<
  HTMLDivElement,
  {
    color: string;
    onChangeColor?: (val: string) => void;
    onSave?: (_color: string) => void;
    onDiscard?: () => void;
    className?: string;
    setShow?: (val: boolean) => void;
  }
>(({ color, className, onChangeColor, onSave, onDiscard, setShow }, ref) => {
  const pickerInputRef = useRef<HTMLInputElement>(null);
  const isMobile = useResponsive('sm');

  const ensureColorLength = (color: string): string => {
    if (color.length) {
      color = color.replace('#', '');
      while (color.length < 6) {
        color += color.charAt(color.length - 1);
      }
    }

    return `#${color}`;
  };

  useEffect(() => {
    pickerInputRef.current?.focus();
    return () => {
      pickerInputRef.current?.blur();
    };
  }, []);

  return (
    <div
      className={cn(
        !isMobile &&
          `absolute rounded-lg left-[calc(5em)] bottom-0 z-[9] bg-white p-2 shadow`,
        className
      )}
      ref={ref}
    >
      <HexColorPicker
        className={cn('mb-2', isMobile && '!w-full !h-80')}
        color={color}
        onChange={(color) => onChangeColor?.(color)}
      />
      <BBBTextInput
        value={color.replace('#', '')}
        onChange={({ target: { value } }) => onChangeColor?.('#' + value)}
        containerClassname="!mb-0"
        isFixed
        maxChar={6}
        fixedLabel="#"
        ref={pickerInputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSave?.(ensureColorLength(color));
            onChangeColor?.(ensureColorLength(color));
            setShow?.(false);
          } else if (e.key === 'Escape') {
            onDiscard?.();
            setShow?.(false);
          }
        }}
      />
      {isMobile && (
        <div className="flex gap-2.5 mt-2.5">
          <BBBButton
            text="Save"
            className="w-full"
            onClick={() => onSave?.(color)}
          />
        </div>
      )}
      {!isMobile && (
        <div className="flex justify-between my-2">
          <div className="flex gap-2">
            {_localStorage.getItem('colorHistory') &&
              (
                JSON.parse(
                  _localStorage.getItem('colorHistory') as string
                ) as string[]
              )
                .slice(0, 4)
                .map((p) => (
                  <div
                    style={{
                      backgroundColor: p,
                    }}
                    className="w-6 h-6 rounded cursor-pointer"
                    onClick={() => onChangeColor?.(p)}
                    key={p}
                  />
                ))}
          </div>
          <div className="flex gap-2">
            <div
              className="w-6 h-6 rounded bg-[#000000] cursor-pointer"
              onClick={() => onChangeColor?.('#000000')}
            />
            <div
              className="w-6 h-6 rounded bg-[#FD823E] cursor-pointer"
              onClick={() => onChangeColor?.('#FD823E')}
            />
          </div>
        </div>
      )}
    </div>
  );
});

ColorPickerPopup.displayName = 'ColorPickerPopup';

export default BBBColorPicker;
