import {
  sidebarDataTypeSectionV2,
  SidebarIcon,
} from '@/constants/layouts/sidebarData';

function BitCRMIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitCRMIconDefault {...props} />
      ) : (
        <BitCRMIconActive {...props} />
      )}
    </>
  );
}

function BitCRMIconDefault({ size = 26 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 1.667a4.167 4.167 0 100-8.334 4.167 4.167 0 000 8.334zM4.167 5a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.667a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.167 16.667a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.666a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM15.834 16.667a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.666a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M14.756 5.244a.833.833 0 010 1.179l-1.667 1.666a.833.833 0 11-1.178-1.178l1.666-1.667a.833.833 0 011.179 0zM8.09 11.91a.833.833 0 010 1.18l-1.667 1.666a.833.833 0 01-1.179-1.179l1.667-1.666a.833.833 0 011.178 0zM5.244 5.244a.833.833 0 011.179 0l1.666 1.667a.833.833 0 01-1.178 1.178L5.244 6.423a.833.833 0 010-1.179zM11.91 11.91a.833.833 0 011.18 0l1.666 1.667a.834.834 0 01-1.179 1.179l-1.666-1.667a.833.833 0 010-1.178z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M15.834 5a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.667a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function BitCRMIconActive({ size = 26 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 1.667a4.167 4.167 0 100-8.334 4.167 4.167 0 000 8.334zM4.167 5a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.667a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.167 16.667a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.666a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM15.834 16.667a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.666a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M14.756 5.244a.833.833 0 010 1.179l-1.667 1.666a.833.833 0 11-1.178-1.178l1.666-1.667a.833.833 0 011.179 0zM8.09 11.91a.833.833 0 010 1.18l-1.667 1.666a.833.833 0 01-1.179-1.179l1.667-1.666a.833.833 0 011.178 0zM5.244 5.244a.833.833 0 011.179 0l1.666 1.667a.833.833 0 01-1.178 1.178L5.244 6.423a.833.833 0 010-1.179zM11.91 11.91a.833.833 0 011.18 0l1.666 1.667a.834.834 0 01-1.179 1.179l-1.666-1.667a.833.833 0 010-1.178z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M15.834 5a.833.833 0 100-1.667.833.833 0 000 1.667zm0 1.667a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BitCRMIcon;
