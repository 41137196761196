import { useMemo } from 'react';
import { OnboardingModule } from 'types/systemLogin';
import useSortingModels from '../bitAi/models/useSortingModels';
import { useCollections } from '../bitAi/v2/sorting';
import useShopifyIntegrationByApp from '../shopify/useShopifyIntegrationByApp';
import { useOnboardingMeta } from './onboarding';

export const useOnboarding = () => {
  const { data: onboardingData } = useOnboardingMeta('BITAI');

  const onboardingDataObj = onboardingData
    ? (Object.fromEntries(
        onboardingData.details.map((detail) => [detail.module, detail.skipped])
      ) as Record<OnboardingModule, boolean> | undefined)
    : undefined;

  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITAI');
  const { data: collections } = useCollections();
  const { data: models } = useSortingModels();

  const hasShopifyIntegrations = !!shopifyIntegration;
  const hasCollections = !!collections?.data.find(
    (item) => item.sorting?.modelId
  );
  const hasModels = !!models?.length;

  const keyPoints = [
    {
      label: 'Connecting your Shopify store',
      value: 'shopify_integration',
      completed: hasShopifyIntegrations,
      skipped: onboardingDataObj?.shopify_integration,
    },
    {
      label: 'Sorting your collections',
      value: 'sorting_collections',
      completed: hasCollections,
      skipped: onboardingDataObj?.sorting_collections,
    },
    {
      label: 'Create Custom Model',
      value: 'custom_model',
      completed: hasModels,
      skipped: onboardingDataObj?.custom_model,
    },
  ];

  return { keyPoints, dismissed: onboardingData?.dismissed };
};

export const useOnboardingCompletion = () => {
  const { keyPoints, dismissed } = useOnboarding();

  const completionPercentage = useMemo(() => {
    const completedCount = keyPoints.filter(
      (point) => point.completed || point.skipped
    ).length;
    const totalCount = keyPoints.length;
    return totalCount > 0 ? (completedCount / totalCount) * 100 : 0;
  }, [keyPoints]);

  return { progress: completionPercentage, dismissed };
};
