import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { submissionOptionsInternal } from 'constants/bitApp/publish';
import {
  useRecentPublishRequest,
  useUpdateSubmission,
} from 'hooks/bitApp/publish';
import useAppInternal from 'hooks/common/useAppInternal';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useCustomForm from 'hooks/common/useCustomForm';
import { isEqual } from 'lodash-es';
import { PublishStatus } from 'types/bitApp/v2';
import * as yup from 'yup';
import SubmissionTracker from '../PublishApp/SubmissionTracker';

import {
  BBBContainer,
  BBBSelect,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';

type SubmissionForm = {
  submissionStatusApple: {
    label: string;
    value: PublishStatus;
    disabled: boolean;
  } | null;
  submissionStatusGoogle: {
    label: string;
    value: PublishStatus;
    disabled: boolean;
  } | null;
  rejectReasonApple: string;
  rejectReasonGoogle: string;
  androidDownloadUrl: string;
  iosDownloadUrl: string;
};

const schema = yup.object().shape({
  submissionStatusApple: yup.mixed<PublishStatus>().nullable().required(),
  submissionStatusGoogle: yup.mixed<PublishStatus>().nullable().required(),
  rejectReasonApple: yup
    .string()
    .label('Reject reason')
    .when('submissionStatusApple', {
      is: (val: PublishStatus) => val === 'rejected',
      then: (rule) => rule.required(),
    }),
  rejectReasonGoogle: yup
    .string()
    .label('Reject reason')
    .when('submissionStatusGoogle', {
      is: (val: PublishStatus) => val === 'rejected',
      then: (rule) => rule.required(),
    }),
  androidDownloadUrl: yup
    .string()
    .label('Play store url')
    .when('submissionStatusGoogle', {
      is: (val: PublishStatus) => val === 'live',
      then: (rule) => rule.required(),
    }),
  iosDownloadUrl: yup
    .string()
    .label('App store url')
    .when('submissionStatusGoogle', {
      is: (val: PublishStatus) => val === 'live',
      then: (rule) => rule.required(),
    }),
});

export default function Submission() {
  const { data: recentPublishRequest } = useRecentPublishRequest();
  const { mutate: updateSubmission, isLoading: loadingUpdateSubmission } =
    useUpdateSubmission();

  const isAppInternal = useAppInternal();

  const submissionStatusApple = recentPublishRequest?.submissionStatusApple;

  const submissionOptionsApple = submissionStatusApple
    ? submissionOptionsInternal.map((data, index) => {
        if (
          index <
          submissionOptionsInternal
            .map((submissionStatus) => submissionStatus.value)
            .indexOf(submissionStatusApple)
        ) {
          return {
            ...data,
            disabled: true,
          };
        }

        return {
          ...data,
          disabled: false,
        };
      })
    : submissionOptionsInternal.map((data) => ({ ...data, disabled: false }));

  const dataFromApi = useMemo(
    () => ({
      submissionStatusApple:
        submissionOptionsInternal.find(
          (option) =>
            option.value === recentPublishRequest?.submissionStatusApple
        ) || null,
      submissionStatusGoogle:
        submissionOptionsInternal.find(
          (option) =>
            option.value === recentPublishRequest?.submissionStatusGoogle
        ) || null,
      rejectReasonApple: recentPublishRequest?.rejectedReasonApple || '',
      rejectReasonGoogle: recentPublishRequest?.rejectedReasonGoogle || '',
      androidDownloadUrl: recentPublishRequest?.downloadLinkGoogle || '',
      iosDownloadUrl: recentPublishRequest?.downloadLinkApple || '',
    }),
    [
      recentPublishRequest?.downloadLinkApple,
      recentPublishRequest?.downloadLinkGoogle,
      recentPublishRequest?.rejectedReasonApple,
      recentPublishRequest?.rejectedReasonGoogle,
      recentPublishRequest?.submissionStatusApple,
      recentPublishRequest?.submissionStatusGoogle,
    ]
  );

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useCustomForm<SubmissionForm>({
    defaultValues: dataFromApi,
    resolver: yupResolver(schema),
  });

  const { toggle } = useConfirmationBanner();

  const isAppHasSubmiitedToStore =
    recentPublishRequest?.submissionStatusGoogle !== 'submitted' &&
    recentPublishRequest?.submissionStatusApple !== 'submitted';

  useEffect(() => {
    const isFormEqual = isEqual(dataFromApi, watch());
    toggle('submssion-internal-form', {
      show: !isFormEqual,
      variant: loadingUpdateSubmission ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: (hide) => {
        handleSubmit((data) => {
          updateSubmission(
            {
              rejectedReasonGoogle: data.rejectReasonGoogle,
              rejectedReasonApple: data.rejectReasonApple,
              iosStatus: data?.submissionStatusApple?.value,
              androidStatus: data?.submissionStatusGoogle?.value,
              androidDownloadUrl: data.androidDownloadUrl,
              iosDownloadUrl: data.iosDownloadUrl,
              id: recentPublishRequest!.id,
            },
            {
              onSuccess: () => {
                hide();
              },
            }
          );
        })();
      },
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSubmission,
    recentPublishRequest,
    reset,
    toggle,
    updateSubmission,
    watch(),
  ]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  if (!isAppInternal) {
    return (
      <div className="h-screen flex justify-center items-center">
        <p className="text-primary-main font-medium text-2xl">
          For internal only
        </p>
      </div>
    );
  }

  return (
    <BBBContainer
      hasHeading
      pageTitle={
        <p className="text-primary-main font-medium text-2xl">
          Submission (Internal)
        </p>
      }
      pageDescription={
        <p className="text-neutral-50">Update status of app submission.</p>
      }
      className="flex flex-col gap-5"
    >
      <Controller
        control={control}
        name="submissionStatusGoogle"
        render={({ field }) => (
          <BBBSelect
            label="Submission Status (Google / Android)"
            options={submissionOptionsApple}
            optionLabel="label"
            optionValue="value"
            optionDisabled="disabled"
            value={field.value}
            onValueChange={(value) => field.onChange(value)}
            placeholder="Select status"
          />
        )}
      />
      {watch('submissionStatusGoogle')?.value === 'rejected' && (
        <BBBTextAreaInput
          label="Reject Reason (Google)"
          isHookForm
          control={control}
          rows={4}
          controlName="rejectReasonGoogle"
          error={errors.rejectReasonGoogle?.message}
          placeholder="Provide additional details and context on the rejection reason"
        />
      )}
      {watch('submissionStatusGoogle')?.value === 'live' && (
        <BBBTextInput
          label="Google Download URL"
          isHookForm
          control={control}
          controlName="androidDownloadUrl"
          error={errors.androidDownloadUrl?.message}
          placeholder="Input play store url"
        />
      )}
      <Controller
        control={control}
        name="submissionStatusApple"
        render={({ field }) => (
          <BBBSelect
            label="Submission Status (Apple / iOS)"
            options={submissionOptionsInternal}
            optionLabel="label"
            optionValue="value"
            value={field.value}
            onValueChange={(value) => field.onChange(value)}
            placeholder="Select status"
          />
        )}
      />
      {watch('submissionStatusApple')?.value === 'rejected' && (
        <BBBTextAreaInput
          label="Reject Reason (Apple)"
          isHookForm
          control={control}
          rows={4}
          controlName="rejectReasonApple"
          error={errors.rejectReasonApple?.message}
          placeholder="Provide additional details and context on the rejection reason"
        />
      )}
      {watch('submissionStatusApple')?.value === 'live' && (
        <BBBTextInput
          label="iOS Download URL"
          isHookForm
          control={control}
          controlName="iosDownloadUrl"
          error={errors.iosDownloadUrl?.message}
          placeholder="Input app store url"
        />
      )}
      <div className="flex flex-col gap-5 mt-4">
        <p className="text-primary-main text-xl">Current user app status:</p>
        {recentPublishRequest?.submissionStatusGoogle &&
        recentPublishRequest?.submissionStatusGoogle ? (
          <>
            {isAppHasSubmiitedToStore ? (
              <SubmissionTracker
                statusAndroid={recentPublishRequest.submissionStatusGoogle}
                statusIos={recentPublishRequest.submissionStatusApple}
                urlAndroid={recentPublishRequest.downloadLinkGoogle}
                urlIos={recentPublishRequest.downloadLinkApple}
                rejectedMessageAndroid={
                  recentPublishRequest.rejectedReasonGoogle
                }
                rejectedMessageIos={recentPublishRequest.rejectedReasonApple}
              />
            ) : (
              <p className="text-neutral-40">No submitted yet.</p>
            )}
          </>
        ) : (
          <p className="text-neutral-40">No submission status available yet.</p>
        )}
      </div>
    </BBBContainer>
  );
}
