export default function PhoneIcon2({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07197 0.612689C3.85033 -0.167199 5.19637 -0.256408 5.98971 0.658948L9.13006 4.28369C9.84609 5.1103 9.69725 6.29878 8.98905 7.01039L8.03564 7.96445C9.04291 9.58086 10.4061 10.946 12.0211 11.9556L12.9751 11.0003C12.975 11.0004 12.9752 11.0002 12.9751 11.0003C13.6838 10.2903 14.8696 10.1402 15.6953 10.8492L19.334 13.9725C20.2569 14.7653 20.1689 16.1177 19.3882 16.8983L17.8068 18.4825C16.9831 19.3075 15.9047 19.8299 14.7467 19.9648C13.778 20.0777 12.7659 19.9235 11.8673 19.4382C7.06234 16.8428 3.15673 12.9471 0.56385 8.13946C0.0771091 7.23696 -0.0773077 6.22105 0.0347548 5.24876C0.169226 4.08205 0.693855 2.99497 1.52357 2.1638L3.07197 0.612689C3.07202 0.61264 3.07192 0.612737 3.07197 0.612689ZM4.50931 2.02162C4.50507 2.02461 4.50041 2.02846 4.49545 2.03343L2.94691 3.58468C2.43742 4.09507 2.11526 4.76261 2.03269 5.47904C1.96228 6.08992 2.06423 6.68462 2.33398 7.18477C4.73962 11.6452 8.36125 15.2586 12.8231 17.6686C13.3189 17.9364 13.9087 18.0376 14.5141 17.9671C15.2215 17.8847 15.8803 17.5656 16.3834 17.0616L17.9655 15.4768C17.9712 15.471 17.9761 15.465 17.9794 15.4602L14.4197 12.4046C14.4137 12.408 14.4065 12.4131 14.3989 12.4208L13.1389 13.6825C12.9235 13.9044 12.6419 14.0508 12.3361 14.0995C12.0247 14.149 11.7057 14.0945 11.4285 13.9443C11.4209 13.9401 11.4132 13.9359 11.4057 13.9315C9.18226 12.6511 7.33782 10.8043 6.06017 8.57927L6.04887 8.55904C5.89789 8.28149 5.8429 7.96181 5.89247 7.64975C5.94108 7.34374 6.08757 7.06191 6.30969 6.84638L7.56354 5.59167C7.56343 5.59179 7.56366 5.59155 7.56354 5.59167C7.57223 5.58288 7.57808 5.57439 7.58154 5.56774L4.50931 2.02162Z"
        fill="black"
      />
    </svg>
  );
}
