import { Trash2 } from 'react-feather';
import { Handle, NodeProps, Position } from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import useStore from '../../../store';
import { CreateNewCondition } from '../Edges/CustomEdgeCondition';

import ConditionIcon from '@/assets/icons/ConditionIcon';
import { BBBCard } from '@/components/ui';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';

export default function ConditionNode({ id }: NodeProps<null>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);
  const deleteConditionNode = useStore((state) => state.deleteConditionNode);
  const confirm = useConfirmationModal();

  return (
    <>
      <BBBCard
        className={cx(
          'w-[6.25rem] h-[6.25rem] p-0 md:p-0 flex items-center justify-center group transition-colors',
          expandState === 'hover' && 'border-danger-hover',
          expandState === 'clicked' && 'border-secondary-main'
        )}
        onMouseEnter={() => {
          if (expandState !== 'clicked') {
            setExpandState(id, 'hover');
          }
        }}
        onMouseLeave={(e) => {
          if (expandState !== 'clicked') {
            setExpandState(id, null);
          }
        }}
        onClick={() => {
          confirm({
            title: 'Delete this conditional state',
            description:
              'Are you sure want to delete this conditional state? Once you delete the action cannot be undone',
            onAccept: (hide) => {
              deleteConditionNode(id);
              hide();
            },
            deleteModal: true,
          });
        }}
      >
        <div className="flex-none">
          {expandState === 'hover' ? (
            <Trash2 size={'1.5rem'} className="text-danger-main" />
          ) : (
            <ConditionIcon size={'1.5rem'} />
          )}
        </div>{' '}
        <Handle
          type="target"
          position={Position.Left}
          className={'opacity-0 pointer-events-none'}
        />
        <Handle
          type="source"
          position={Position.Right}
          className={'opacity-0 pointer-events-none'}
        />
      </BBBCard>
      {!hasEdges && (
        <div className="absolute left-full top-1/2 -translate-y-1/2 flex items-center">
          <div className="h-[1px] w-24 bg-[#9E9E9E]" />
          <CreateNewCondition sourceId={id} />
        </div>
      )}
    </>
  );
}
