export type IRedirectNotificationOptionsParam = {
  id: number;
  label: string;
  queryKey: string;
  name: string;
  type: 'options' | 'url' | 'dynamic-variable';
  data?: string | null;
};

export type IRedirectNotificationOptions = {
  id: number;
  label: string;
  value: string | null;
  params: null | IRedirectNotificationOptionsParam[];
};

const redirectNotificationOptions: IRedirectNotificationOptions[] = [
  {
    id: 102,
    label: 'None',
    value: 'none',
    params: null,
  },
  {
    id: 18,
    label: 'Collection',
    value: 'collection',
    params: [
      {
        id: 4,
        queryKey: 'id',
        label: 'Collection',
        name: 'collection',
        type: 'options',
      },
    ],
  },
  {
    id: 7,
    label: 'Product',
    value: 'product',
    params: [
      {
        id: 1,
        queryKey: 'id',
        label: 'Product',
        name: 'product',
        type: 'options',
      },
    ],
  },
  {
    id: 5,
    label: 'Blogs',
    value: 'news',
    params: [
      {
        id: 0,
        queryKey: 'id',
        label: 'Blogs',
        name: 'blog',
        type: 'options',
      },
    ],
  },
  {
    id: 12,
    label: 'Article',
    value: 'article',
    params: [
      {
        id: 3,
        queryKey: 'id',
        label: 'Article',
        name: 'article',
        type: 'options',
      },
    ],
  },
  {
    id: 20,
    label: 'Custom URL',
    value: 'web-view',
    params: [
      {
        id: 5,
        label: 'Insert your URL',
        type: 'url',
        name: 'url',
        queryKey: 'url',
      },
    ],
  },
  {
    id: 99,
    label: 'Other',
    value: 'others',
    params: null,
  },
  {
    id: 0,
    label: 'Home',
    value: 'home',
    params: null,
  },
  {
    id: 1,
    label: 'Account',
    value: 'account',
    params: null,
  },
  {
    id: 2,
    label: 'Cart',
    value: 'cart-stack',
    params: null,
  },
  {
    id: 3,
    label: 'Category',
    value: 'category',
    params: null,
  },
  {
    id: 4,
    label: 'Wishlist',
    value: 'wishlist',
    params: null,
  },
  {
    id: 6,
    label: 'Search',
    value: 'search',
    params: null,
  },
  {
    id: 8,
    label: 'Product Reviews',
    value: 'product-reviews',
    params: [
      {
        id: 2,
        queryKey: 'id',
        label: 'Product',
        name: 'product',
        type: 'options',
      },
    ],
  },
  {
    id: 9,
    label: 'Create Product Reviews',
    value: 'create-product-reviews',
    params: null,
  },
  {
    id: 11,
    label: 'Checkout',
    value: 'checkout',
    params: [
      {
        id: 7,
        queryKey: 'checkoutID',
        label: 'Checkout ID',
        name: 'checkoutID',
        type: 'dynamic-variable',
        data: '{{customer.lastIncompleteCheckout.id}}',
      },
    ],
  },
  {
    id: 13,
    label: 'Login',
    value: 'login',
    params: null,
  },
  {
    id: 14,
    label: 'Register',
    value: 'register',
    params: null,
  },
  {
    id: 15,
    label: 'Forgot Password',
    value: 'forgot-password',
    params: null,
  },
  {
    id: 16,
    label: 'Update Order Shipping Address',
    value: 'update-order-shipping-address',
    params: [
      {
        id: 6,
        queryKey: 'checkoutID',
        label: 'Checkout ID',
        name: 'checkoutID',
        type: 'dynamic-variable',
        data: '{{customer.lastIncompleteCheckout.id}}',
      },
    ],
  },
  {
    id: 17,
    label: 'Update User Shipping Address',
    value: 'update-user-shipping-address',
    params: null,
  },
  {
    id: 19,
    label: 'Order History',
    value: 'order-history',
    params: null,
  },
];

export default redirectNotificationOptions;
