import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import {
  BBBButton,
  BBBCard,
  BBBFooterAction,
  BBBImageUpload,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';
import { env } from '@/config/env';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import useOnboarding from '@/hooks/bitLink/onboarding/useOnboarding';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';
import { Linklists } from '@/types/bitLink/v2';
import { FileType } from '@/types/utils/file';

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  imagePreview: yup.mixed<FileType>().optional(),
  link: yup
    .string()
    .url('Link URL is invalid')
    .required('Link URL is required'),
  active: yup.bool().required('Active is required'),
});

type FormLinkInput = {
  title: string;
  imagePreview: string | null | undefined;
  link: string;
  active: boolean;
};

export default function FormLink() {
  const { data, status } = useOnboarding('links');

  if (status !== 'success' || !data?.lastStep) return null;

  if (data.lastStep === 4) {
    return <_FormLinkNewUsers />;
  }

  return <_FormLinkOnBoarded />;
}

function _FormLinkNewUsers() {
  return (
    <BBBCard id="form-link" className={cx('mb-cardBottom')}>
      <div className="flex justify-between gap-4">
        <BBBImageUpload
          crop={{
            aspect: 1,
          }}
          buttonText="Upload Image"
          shape="square"
          width={'6rem'}
          height={'6rem'}
          containerClassName="mb-4"
          imageUrl={`${env.REACT_APP_CDN_URL}/bitbybit/static/v1/logo-black.png`}
        />
        <BBBPrimarySwitch checked={true} />
      </div>
      <BBBTextInput
        placeholder="Card title"
        label="Title"
        value="Newest Lunch"
      />
      <BBBTextInput
        label="Link"
        placeholder="Card link"
        value="https://thisisdummylinklaunch.com"
        isUrl
      />
      <div className="flex gap-4 items-center mt-4 relative">
        <BBBButton
          text="Discard"
          variant="secondary"
          className="mr-buttonHorizontal"
        />
        <PulseEffect />
        <BBBButton
          text="Save"
          className="absolute left-[6.7rem] border-2 border-secondary-main"
        />
      </div>
    </BBBCard>
  );
}

function _FormLinkOnBoarded() {
  const { setValue, formState, control, reset, handleSubmit } =
    useCustomForm<FormLinkInput>({
      resolver: yupResolver(schema),
      defaultValues: {
        title: '',
        imagePreview: '',
        link: '',
        active: true,
      },
    });

  const isMobile = useResponsive('sm');
  const formLink = useAppSelector((state) => state.bitLink.form);
  const dispatch = useAppDispatch();

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();

  const handleDeleteForm = () => {
    if (formLink?.id) {
      deleteLinklist(formLink?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  const onSubmit = async (data: FormLinkInput) => {
    const requestData: Partial<Linklists> = {
      type: 'link' as const,
      textPrimary: data.title,
      textSecondary: data.link,
      active: data.active,
      media: data.imagePreview,
    };

    if (!formLink?.id) {
      addLinklist(requestData);
    } else {
      editLinklist({ id: formLink?.id, data: requestData });
    }
  };

  useEffect(() => {
    setValue('link', formLink?.textSecondary || '');
    setValue('title', formLink?.textPrimary || '');
    setValue('active', formLink?.active ?? true);
    setValue('imagePreview', formLink?.media || '');
  }, [formLink, setValue]);

  return (
    <BBBCard
      id="form-link"
      title="Links"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom md:p-6', isMobile && 'border-none p-0')}
    >
      <Controller
        control={control}
        name="imagePreview"
        render={({ field }) => (
          <BBBImageUpload
            crop={{
              aspect: 1,
            }}
            shape="square"
            width={'6rem'}
            height={'6rem'}
            containerClassName="my-5"
            imageUrl={field.value}
            buttonText="Upload Image"
            onChangeImage={field.onChange}
          />
        )}
      />
      <BBBTextInput
        placeholder="Enter your link title"
        control={control}
        isHookForm
        label="Title"
        controlName="title"
        error={formState.errors.title?.message}
      />
      <BBBTextInput
        label="Link"
        placeholder="Enter your link URL"
        control={control}
        isHookForm
        controlName="link"
        isUrl
        error={formState.errors.link?.message}
      />
      <BBBFooterAction
        containerClassName="justify-end"
        withoutDelete={formLink?.id ? undefined : true}
        onCancel={() => {
          dispatch(setForm(null));
          reset();
        }}
        discardLabel="Cancel"
        onSave={() => handleSubmit(onSubmit)()}
        loadingSave={isLoading || isLoadingEdit}
        onDelete={handleDeleteForm}
      />
    </BBBCard>
  );
}

function PulseEffect({
  n = 3,
  duration = 1,
  delay = 0.5,
  width = 71,
  height = 43,
  gap = 5,
}: {
  n?: number;
  duration?: number;
  delay?: number;
  width?: number;
  height?: number;
  gap?: number;
}) {
  return (
    <motion.div
      className="absolute left-[89px]"
      style={{ display: 'grid', placeItems: 'center' }}
    >
      {Array.from({ length: n }, (_, i) => {
        const pulseDelay = delay * i;
        const pulseRepeatDelay = pulseDelay;
        const pulseDuration = duration + delay * (n - i);
        return (
          <motion.div
            key={i}
            className="rounded-lg"
            style={{
              border: `1px solid ${i === 0 ? 'tranparent' : '#FD823E'}`,
              gridArea: '1 / 1 / 2 / 2',
              width: `${width + gap * i}px`,
              height: `${height + gap * i}px`,
              aspectRatio: '1/1',
              zIndex: n - i,
            }}
            {...(i !== 0 && {
              initial: { opacity: 0 },
              animate: { opacity: [0, 1, 0], scale: 1.1 },
              transition: {
                duration: pulseDuration,
                delay: pulseDelay,
                ease: [0.05, 0.6, 0.3, 0.3],
                repeat: Infinity,
                repeatDelay: pulseRepeatDelay,
              },
            })}
          />
        );
      })}
    </motion.div>
  );
}
