import api from '@/config/api';

export default {
  blastNotifications: (
    companyId: number,
    payload: {
      campaignName: string;
      title: string;
      body: string;
      redirectPath?: string | null | undefined;
      platform: string;
    }
  ) => api.bitApp.post(`/notifications/${companyId}/campaign`, payload),
};
