import React, { useState } from 'react';
import { Info } from 'react-feather';
import { startCase } from 'lodash-es';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { twMerge as cx } from 'tailwind-merge';
import {
  BBBCustomTooltipChart,
  BBBCustomTooltipChartProps,
} from '../BBBCustomTooltipChart';

import BarIcon from '@/assets/icons/BarIcon';
import GraphIcon from '@/assets/icons/GraphIcon';
import {
  BBBCard,
  BBBTooltip,
  BBBTooltipProps,
  IBBBCard,
} from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { Paths } from '@/types/utils/nestedObjectPath';

export type BBBComposedChartProps<
  T extends {
    [k: string]: unknown;
  }
> = {
  title?: string | React.ReactNode;
  containerClassName?: string;
  data: T[] | undefined;
  xAxisProps: XAxisProps;
  yAxisProps?: YAxisProps;
  lineDataKeyBar: Paths<T>;
  tooltipProps?: BBBCustomTooltipChartProps;
  customHeaders?: React.ReactNode;
  tooltip?: BBBTooltipProps['content'];

  // Graph chart
  isHasGraph?: boolean;
  lineDataKeyGraph?: Paths<T>;

  // Additional Bar chart
  isHasAdditionalBar?: boolean;
  lineDataKeyAdditionalBar?: Paths<T>;
} & IBBBCard;

const BBBComposedChart = <
  T extends {
    [k: string]: unknown;
  }
>({
  title,
  containerClassName,
  data,
  xAxisProps,
  yAxisProps,
  lineDataKeyBar,
  tooltipProps,
  customHeaders,
  tooltip = false,
  // Graph chart
  isHasGraph = false,
  lineDataKeyGraph,

  // Additional Bar chart
  isHasAdditionalBar = false,
  lineDataKeyAdditionalBar,

  ...props
}: BBBComposedChartProps<T>) => {
  const isMobile = useResponsive('sm');

  const [focusBar, setFocusBar] = useState<number | null>();

  if (isMobile) return null;

  return (
    <BBBCard
      titleClassName="text-base font-normal"
      className={cx(containerClassName)}
      title={
        customHeaders ||
        (tooltip ? (
          <div className="flex gap-1 items-center w-fit">
            <p className="text-base font-normal whitespace-nowrap">{title}</p>
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          </div>
        ) : (
          <p className="text-base font-normal whitespace-nowrap">{title}</p>
        ))
      }
      rightButton={
        <div className="flex items-center gap-4">
          {isHasGraph && (
            <div className="flex items-center justify-center gap-2">
              <GraphIcon />
              <p className="text-xs">{startCase(lineDataKeyGraph)}</p>
            </div>
          )}
          <div className="flex items-center justify-center gap-2">
            <BarIcon />
            <p className="text-xs">{startCase(lineDataKeyBar)}</p>
          </div>
          {isHasAdditionalBar && (
            <div className="flex items-center justify-center gap-2">
              <BarIcon color="#65B242" />
              <p className="text-xs">{startCase(lineDataKeyAdditionalBar)}</p>
            </div>
          )}
        </div>
      }
      {...props}
    >
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          data={data}
          onMouseMove={({ activeTooltipIndex, isTooltipActive }) => {
            if (isTooltipActive) {
              setFocusBar(activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="8" />
          <XAxis
            axisLine={false}
            tickMargin={10}
            className="text-sm text-primary-main"
            {...xAxisProps}
          />
          <YAxis
            axisLine={false}
            tickMargin={10}
            className="text-sm text-primary-main"
            type="number"
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 25) * 25]}
            {...yAxisProps}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<BBBCustomTooltipChart {...tooltipProps} />}
          />
          <Bar dataKey={lineDataKeyBar} stackId="a" fill="#FED5BF">
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={focusBar === index ? '#D36C34' : '#FED5BF'}
              />
            ))}
          </Bar>
          {isHasAdditionalBar && (
            <Bar
              dataKey={lineDataKeyAdditionalBar || ''}
              stackId="b"
              fill="#D2F1C4"
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusBar === index ? '#65B242' : '#D2F1C4'}
                />
              ))}
            </Bar>
          )}
          {isHasGraph && (
            <Line
              type="monotone"
              dataKey={lineDataKeyGraph}
              stroke="#D36C34"
              activeDot={{ r: 8 }}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </BBBCard>
  );
};

export default BBBComposedChart;
