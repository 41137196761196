import { ReactNode } from 'react';

import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

export type BBBNavigationProps = {
  name: string;
  icon: ReactNode | ((props: any) => JSX.Element);
  key: string;
  disabled?: boolean;
};

export default function BBBNavigatorTab2<T extends BBBNavigationProps>({
  navigator,
  onChange,
  value,
  id,
  className,
}: {
  navigator: T[];
  onChange: (value: T) => void;
  value: string;
  id?: string;
  className?: string;
}) {
  const isMobile = useResponsive('sm');

  return (
    <div
      id={id}
      className={cn(
        isMobile
          ? 'flex gap-3 py-2 px-3 border-t'
          : 'flex flex-wrap mb-6 bg-white border-grayColor2 border-[1.5px] rounded-lg w-[90%] 2xl:w-[80%] mx-auto',
        className
      )}
    >
      {navigator.map((item) => {
        const Icon = item.icon;

        return (
          <div key={item.key} className="flex-1">
            <div
              className={cn(
                `flex justify-center rounded-lg cursor-pointer items-center gap-2 py-4 px-4 h-full`,
                isMobile ? 'flex-col' : '',
                item.key === value ? 'bg-primary-main text-white' : ''
              )}
              onClick={() => {
                onChange(item);
              }}
            >
              <span>{typeof Icon === 'function' ? <Icon /> : item.icon}</span>
              <div className="link-name font-medium whitespace-nowrap">
                {item.name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
