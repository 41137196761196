import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketWhatsApp } from 'socket';

import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useConnectInstagram = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const userId = useUserId();
  const queryClient = useQueryClient();

  const [enableInstagramIntegration, setEnableInstagramIntegration] =
    useState(false);

  const onIntegrationAuthorized = useCallback(() => {
    toast.success('Succesfully connected to instagram');
    setEnableInstagramIntegration(false);
    setTimeout(() => {
      queryClient.invalidateQueries(['instagram-list']);
      queryClient.invalidateQueries([`bitchat-company-integrations`]);
    }, 500);
  }, [queryClient]);

  useEffect(() => {
    if (enableInstagramIntegration) {
      socketWhatsApp.emit('join-instagram', {
        companyId: activeCompany,
        userId,
      });
    }
  }, [activeCompany, enableInstagramIntegration, userId]);

  useEffect(() => {
    if (enableInstagramIntegration) {
      socketWhatsApp.on(
        `instagram-integration-authorized`,
        onIntegrationAuthorized
      );
      return () => {
        socketWhatsApp.off(
          `instagram-integration-authorized`,
          onIntegrationAuthorized
        );
      };
    }
  }, [enableInstagramIntegration, onIntegrationAuthorized]);

  return [enableInstagramIntegration, setEnableInstagramIntegration] as const;
};

export default useConnectInstagram;
