import { UseQueryResult } from '@tanstack/react-query';

import { useShopifyIntegrationExtra as useBitAiIntegrations } from '@/hooks/bitAi/integration/integrations';
import { useShopifyIntegrationExtra as useBitAppIntegrations } from '@/hooks/bitApp/integration/integrations';
import { useShopifyIntegrationExtra as useBitChatIntegrations } from '@/hooks/bitChat/integration/integrations';
import { useShopifyIntegrationExtra as useBitCRMIntegrations } from '@/hooks/bitCRM/integration/integrations';
import { useShopifyIntegrationExtra as useBitLinkIntegrations } from '@/hooks/bitLink/integration/integrations';
import { useShopifyIntegrationExtra as useBitLoginIntegrations } from '@/hooks/bitLogin/integration/integrations';
import { IntegrationExtra as IntegrationExtraBitApp } from '@/types/bitApp/v2';
import { IntegrationExtra as IntegrationExtraBitChat } from '@/types/bitChat/integrations';
import { IntegrationExtra as IntegrationExtraBitCRM } from '@/types/bitCRM/integrations';
import { AppType } from '@/types/systemLogin';

function useShopifyIntegrationByApp(
  appName: AppType,
  options?: { enabled?: boolean }
): UseQueryResult<{ domain: string } | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITAPP',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitApp | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITLINK',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitApp | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITLOGIN',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitApp | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITAI',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitApp | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITCHAT',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitChat | null, unknown>;
function useShopifyIntegrationByApp(
  appName: 'BITCRM',
  options?: { enabled?: boolean }
): UseQueryResult<IntegrationExtraBitCRM | null, unknown>;

function useShopifyIntegrationByApp(
  appName: AppType,
  options?: { enabled?: boolean }
) {
  const enabled =
    typeof options?.enabled !== 'undefined' ? options.enabled : true;

  const bitAppIntegrationData = useBitAppIntegrations({
    enabled: appName === 'BITAPP' && enabled,
  });

  const bitCRMIntegrationsData = useBitCRMIntegrations({
    enabled: appName === 'BITCRM' && enabled,
  });

  const bitLinkIntegrationsData = useBitLinkIntegrations({
    enabled: appName === 'BITLINK' && enabled,
  });

  const bitChatIntegrationsData = useBitChatIntegrations({
    enabled: appName === 'BITCHAT' && enabled,
  });

  const bitLoginIntegrationsData = useBitLoginIntegrations({
    enabled: appName === 'BITLOGIN' && enabled,
  });

  const bitAiIntegrationsData = useBitAiIntegrations({
    enabled: appName === 'BITAI' && enabled,
  });

  const returnedData = {
    BITAPP: bitAppIntegrationData,
    BITLINK: bitLinkIntegrationsData,
    BITCRM: bitCRMIntegrationsData,
    BITLOGIN: bitLoginIntegrationsData,
    BITCHAT: bitChatIntegrationsData,
    BITAI: bitAiIntegrationsData,
  };

  return returnedData[appName];
}

export default useShopifyIntegrationByApp;
