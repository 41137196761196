import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useHistory from '@/hooks/common/useHistory';

type VerifyEmailPayload = {
  id: string;
};

const useVerifyEmail = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }: VerifyEmailPayload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/auth/email-verify/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['authenticated-user']).then(() => {
          history.push('/');
        });
      },
    }
  );
};

export default useVerifyEmail;
