import { useInfiniteQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useApiKey } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';

const useSearchChat = () => {
  const apiKey = useApiKey();
  const search = useAppSelector((state) => state.bitCRM.searchChat);

  return useInfiniteQuery(
    ['livechat-search', search],
    ({ pageParam }) =>
      services.whatsApp.v3.data.searchChat(apiKey, {
        search,
        cursor: pageParam,
      }),
    {
      enabled: !!search?.length,
      getNextPageParam: (lastPage) =>
        lastPage.meta.hasNextPage ? lastPage.meta.endCursor : undefined,
    }
  );
};

export default useSearchChat;
