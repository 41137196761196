import { SVGProps } from 'react';

export default function HamburgerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.125 1.375h15.75m-15.75 12h15.75-15.75zm0-6h15.75-15.75z"
      ></path>
    </svg>
  );
}
