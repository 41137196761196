import { useRef } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { createId } from '@paralleldrive/cuid2';
import { twMerge as cx } from 'tailwind-merge';
import { useAutoPosition, useAutoSize } from '../../hooks';
import useStore from '../../store';
import { Child1Data } from '../../types';
import Connector from './Connector';
import CreateConnection from './CreateConnection';

import { aiTriggerConditions } from '@/constants/bitChat/flows';

export default function Child1Node({ id, data }: NodeProps<Child1Data>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);
  const onChangeActiveAddState = useStore((s) => s.onChangeActiveAddState);
  const isHeld = useStore((s) => s.connectingNode === id);

  const addRelations = useStore((state) => state.addRelations);

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);
  const autoPosition = useAutoPosition();

  const ref = useRef<HTMLDivElement | null>(null);

  useAutoSize(
    ref,
    `#child1-slot-${data.parentId}`,
    `.react-flow__node[data-id="${data.parentId}"]`,
    id,
    data
  );

  const condition =
    'condition' in data
      ? aiTriggerConditions.find((opt) => opt.value === data.condition)?.label
      : null;

  return (
    <div
      className={cx(
        'relative border border-neutral-30 transition-colors rounded-lg w-full p-4 text-sm',
        expandState === 'hover' && 'border-secondary-main',
        expandState === 'clicked' && 'border-secondary-main',
        isHeld && 'border-secondary-main'
      )}
      ref={ref}
      onMouseEnter={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, 'hover');
        }
      }}
      onMouseLeave={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, null);
        }
      }}
      onClick={(e) => {
        if (!hasEdges) {
          setExpandState(id, 'clicked');
          onChangeActiveAddState(id);
          e.stopPropagation();
        }
      }}
    >
      <Connector id={id} />
      <CreateConnection
        onAddState={(state) => {
          if (state === 'back') {
            const position = autoPosition(id);
            if (position) {
              const { x, y } = position;
              const targetId = createId();
              addRelations(
                [
                  {
                    id: targetId,
                    type: 'back',
                    position: { x, y },
                    data: {},
                  },
                ],
                [
                  {
                    id: createId(),
                    source: id,
                    target: targetId,
                    type: 'custom',
                  },
                ]
              );
            }
          } else {
            onChangeStateModal({
              sourceId: id,
              type: state,
            });
          }
        }}
        nodeId={id}
        state={expandState}
        onChangeState={(state) => setExpandState(id, state)}
      />
      {'reply' in data
        ? data.reply
        : 'condition' in data
        ? condition
        : data.customCondition}{' '}
      <Handle
        type="source"
        position={Position.Right}
        className={!hasEdges ? 'opacity-0 pointer-events-none' : ''}
      />
    </div>
  );
}
