import { useEffect, useState } from 'react';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import customerServices from '@/api/services/customer';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { downloadXlsxFile } from '@/utils/common/file';
import { toast } from '@/utils/common/toast';

const mapActionToTitle = {
  delete: 'Delete multiple customers',
  export: 'Export multiple customers',
  exportAll: 'Export multiple customers',
};

const mapActionToSubmitText = {
  delete: 'Delete',
  export: 'Export',
};

const mapDescriptionToTitle = {
  delete:
    'Are you sure to delete selected customers? Once you deleted the action cannot be undone',
  export: 'Are you sure to export selected customers?',
  exportAll: 'Are you sure to export all selected customers?',
};

type BulkParams = {
  selected: string[];
  isAllRowsSelected: boolean;
  count: number;
};

const useActionCustomers = (params: {
  onSuccess: () => void;
  onChangeLoadExport: (val: boolean) => void;
  onChangeLoadDelete: (val: boolean) => void;
}) => {
  const userId = useUserId();
  const toggleConfirmation = useConfirmationModal();
  const { toggle } = useConfirmationBanner();
  const client = useQueryClient();
  const companyId = useActiveCompany();

  const [loadingBulkExport, setLoadingBulkExport] = useState(false);

  const { mutate: bulkDeleteAction, isLoading: loadingDeleteCustomers } =
    useMutation(
      async (bulkParams: BulkParams) =>
        customerServices.customer.bulkDeleteCustomers({
          ids: bulkParams.selected,
          companyId,
          isAllSelected: bulkParams.isAllRowsSelected,
        }),
      {
        onMutate: (payload) => {
          params.onChangeLoadDelete(true);
        },
        onSuccess: (_, payload) => {
          toast.success(`Your customers has been deleted`);
          client.invalidateQueries(['customers']);
          params.onSuccess();
          params.onChangeLoadDelete(false);
        },
      }
    );

  const { mutate: bulkExportAction } = useMutation(
    async ({ count, ...bulkParams }: BulkParams) =>
      customerServices.customer.bulkExportCustomers({
        ids: bulkParams.selected,
        companyId,
        isAllSelected: bulkParams.isAllRowsSelected,
        count,
        userId,
      }),
    {
      onMutate: (payload) => {
        params.onChangeLoadExport(true);

        if (payload.count < 1000) {
          setLoadingBulkExport(true);
        }
      },
      onSuccess: (data, payload) => {
        if (payload.count >= 1000) {
          toast.success(
            'Request received. You will receive an email with download instructions soon.'
          );
        } else {
          downloadXlsxFile(data, 'Customers');
          setLoadingBulkExport(false);
        }

        params.onSuccess();
        params.onChangeLoadExport(false);
      },
    }
  );

  useEffect(() => {
    toggle('exporting-customers', {
      text: 'Exporting customers',
      show: loadingBulkExport,
      variant: 'loading',
    });
  }, [loadingBulkExport, toggle]);

  useEffect(() => {
    toggle('delete-customers', {
      text: 'Deleting customers',
      show: loadingDeleteCustomers,
      variant: 'loading',
    });
  }, [loadingDeleteCustomers, toggle]);

  return (
    action: 'delete' | 'export',
    bulkParams: {
      selected: string[];
      isAllRowsSelected: boolean;
      count: number;
    },
    {
      onSuccess,
      ...options
    }:
      | MutateOptions<
          AxiosResponse<any>,
          unknown,
          {
            selected: string[];
            isAllRowsSelected: boolean;
          },
          unknown
        >
      | undefined = {}
  ) => {
    toggleConfirmation({
      title: mapActionToTitle[action],
      description: mapDescriptionToTitle[action],
      onAccept: (hide) => {
        if (action === 'export') {
          bulkExportAction(bulkParams, {
            onSuccess: (...payload) => {
              hide();
              onSuccess?.(...payload);
            },
            ...options,
          });
        } else {
          bulkDeleteAction(bulkParams, {
            onSuccess: (...payload) => {
              hide();
              onSuccess?.(...payload);
            },
            ...options,
          });
        }
        hide();
      },
      deleteModal: action === 'delete',
      submitText: mapActionToSubmitText[action],
      cancelText: 'Cancel',
    });
  };
};

export default useActionCustomers;
