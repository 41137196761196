import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import { WhatsAppCloudAccountList } from '@/types/whatsApp/chat';

const useWhatsAppCloudAccounts = (
  options: Omit<
    UseQueryOptions<
      WhatsAppCloudAccountList[],
      unknown,
      WhatsAppCloudAccountList[],
      (string | number)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['whatsappp-cloud-list', activeCompany],
    () => services.whatsApp.chat.getWhatsAppCloudAccounts(activeCompany),
    options
  );
};

export default useWhatsAppCloudAccounts;
