import { useEffect, useMemo, useRef } from 'react';
import {
  Control,
  Controller,
  useForm,
  UseFormReset,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore from '../../../store';
import { ConditionData, ConditionForm, conditionSchema } from '../../../types';

import { BBBModal, BBBSelect, BBBTextInput } from '@/components/ui';
import {
  dataFieldEntries,
  dataFieldOptions,
  runIfOptionsAll,
} from '@/constants/bitCRM/automation-new';
import {
  bitloginEnabledChannels,
  bitloginEnabledChannelsHash,
} from '@/constants/bitLogin/appearance';
import {
  currencies,
  currencyOptions,
  formatCurrency,
} from '@/constants/common/currency';
import TagOptions from '@/pages/Customers/Segmentation/TagOptions';
import { isSubset } from '@/utils/common/object';

const defaultValues: ConditionForm = {
  dataField: null,
  runIf: null,
  currency: null,
  totalPrice: '',
  totalProductVariant: '',
  totalQuantity: '',
  delay: '',
  starsTreshold: '',
  visitedPageXTimes: '',
  visitedCollectionXTimes: '',
  visitedProductXTimes: '',
  addXCartItems: '',
  signupChannel: null,
  loginRange: '',
  loginXTimes: '',
  customerTags: null,
  loyaltyXPoints: '',
  loyaltyRewardsEarnMoreThan: '',
  loyaltyRewardsEarnLessThan: '',
  loyaltyRewardsUsedMoreThan: '',
  loyaltyRewardsUsedLessThan: '',
};

export default function ConditionModal({
  onSave,
  onClose,
  edgeId,
  show,
}: {
  onSave: (val: ConditionData) => void;
  onClose: () => void;
  edgeId?: string;
  show: boolean;
}) {
  const edgeData = useStore<ConditionData | undefined>((s) =>
    edgeId ? s.edges.find((edge) => edge.id === edgeId)?.data : undefined
  );

  const { handleSubmit, control, reset } = useForm<ConditionForm>({
    defaultValues,
    resolver: yupResolver(conditionSchema),
  });

  useEffect(() => {
    if (edgeData) {
      reset({
        dataField: edgeData.dataField || null,
        runIf: edgeData.runIf || null,
        //@ts-ignore
        currency: edgeData.currency ? currencies[edgeData.currency] : null,
        totalPrice: edgeData.totalPrice ? edgeData.totalPrice.toString() : '',
        totalProductVariant: edgeData.totalProductVariant
          ? edgeData.totalProductVariant.toString()
          : '',
        totalQuantity: edgeData.totalQuantity
          ? edgeData.totalQuantity.toString()
          : '',
        delay: edgeData.delay ? edgeData.delay.toString() : '',
        starsTreshold: edgeData.starsTreshold
          ? edgeData.starsTreshold.toString()
          : '',
        visitedPageXTimes: edgeData.visitedPageXTimes
          ? edgeData.visitedPageXTimes.toString()
          : '',
        visitedCollectionXTimes: edgeData.visitedCollectionXTimes
          ? edgeData.visitedCollectionXTimes.toString()
          : '',
        visitedProductXTimes: edgeData.visitedProductXTimes
          ? edgeData.visitedProductXTimes.toString()
          : '',
        addXCartItems: edgeData.addXCartItems
          ? edgeData.addXCartItems.toString()
          : '',
        signupChannel: edgeData.signupChannel
          ? bitloginEnabledChannelsHash[edgeData.signupChannel]
          : null,
        loginRange: edgeData.loginRange ? edgeData.loginRange.toString() : '',
        loginXTimes: edgeData.loginXTimes
          ? edgeData.loginXTimes.toString()
          : '',
        customerTags: edgeData.customerTags,
        loyaltyXPoints: edgeData.loyaltyXPoints
          ? edgeData.loyaltyXPoints.toString()
          : '',
        loyaltyRewardsEarnMoreThan: edgeData.loyaltyRewardsEarnMoreThan
          ? edgeData.loyaltyRewardsEarnMoreThan.toString()
          : '',
        loyaltyRewardsEarnLessThan: edgeData.loyaltyRewardsEarnLessThan
          ? edgeData.loyaltyRewardsEarnLessThan.toString()
          : '',
        loyaltyRewardsUsedMoreThan: edgeData.loyaltyRewardsUsedMoreThan
          ? edgeData.loyaltyRewardsUsedMoreThan.toString()
          : '',
        loyaltyRewardsUsedLessThan: edgeData.loyaltyRewardsUsedLessThan
          ? edgeData.loyaltyRewardsUsedLessThan.toString()
          : '',
      });
    } else {
      reset(defaultValues);
    }
  }, [edgeData, reset]);

  return (
    <BBBModal
      show={show}
      onHide={onClose}
      title="Condition"
      footer
      submitText="Save"
      cancelText="Cancel"
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            dataField:
              typeof data.dataField === 'object'
                ? data.dataField!.value
                : data.dataField,
            runIf:
              typeof data.runIf === 'object' ? data.runIf!.value : data.runIf,
            currency: data.currency?.code || null,
            totalPrice: data.totalPrice ? Number(data.totalPrice) : null,
            totalProductVariant: data.totalProductVariant
              ? Number(data.totalProductVariant)
              : null,
            totalQuantity: data.totalQuantity
              ? Number(data.totalQuantity)
              : null,
            delay: data.delay ? Number(data.delay) : null,
            starsTreshold: data.starsTreshold
              ? Number(data.starsTreshold)
              : null,
            visitedPageXTimes: data.visitedPageXTimes
              ? Number(data.visitedPageXTimes)
              : null,
            visitedCollectionXTimes: data.visitedCollectionXTimes
              ? Number(data.visitedCollectionXTimes)
              : null,
            visitedProductXTimes: data.visitedProductXTimes
              ? Number(data.visitedProductXTimes)
              : null,
            addXCartItems: data.addXCartItems
              ? Number(data.addXCartItems)
              : null,
            signupChannel: data.signupChannel ? data.signupChannel.name : null,
            loginRange: data.loginRange ? Number(data.loginRange) : null,
            loginXTimes: data.loginXTimes ? Number(data.loginXTimes) : null,
            customerTags:
              data.customerTags?.map((tag) => ({
                id: tag.id,
                name: tag.name,
              })) || null,
            loyaltyXPoints: data.loyaltyXPoints
              ? Number(data.loyaltyXPoints)
              : null,
            loyaltyRewardsEarnMoreThan: data.loyaltyRewardsEarnMoreThan
              ? Number(data.loyaltyRewardsEarnMoreThan)
              : null,
            loyaltyRewardsEarnLessThan: data.loyaltyRewardsEarnLessThan
              ? Number(data.loyaltyRewardsEarnLessThan)
              : null,
            loyaltyRewardsUsedMoreThan: data.loyaltyRewardsUsedMoreThan
              ? Number(data.loyaltyRewardsUsedMoreThan)
              : null,
            loyaltyRewardsUsedLessThan: data.loyaltyRewardsUsedLessThan
              ? Number(data.loyaltyRewardsUsedLessThan)
              : null,
          });
          onClose();
        })();
      }}
    >
      <DataFieldOptions control={control} edgeId={edgeId} reset={reset} />
      <RunIfOptions control={control} />
      <DynamicInput control={control} />
    </BBBModal>
  );
}

function RunIfOptions({ control }: { control: Control<ConditionForm, any> }) {
  const _dataField = useWatch({ control, name: 'dataField' });
  const dataField = _dataField
    ? typeof _dataField === 'object'
      ? _dataField.value
      : _dataField
    : null;

  const runIfOptions = useMemo(
    () => (dataField ? runIfOptionsAll[dataField] : []),
    [dataField]
  );

  const { errors } = useFormState({ control });

  return (
    <Controller
      control={control}
      name="runIf"
      render={({ field }) => {
        const value = field.value
          ? typeof field.value === 'object'
            ? field.value
            : runIfOptions.find((opt) => opt.value === field.value)
          : null;

        return (
          <BBBSelect
            options={runIfOptions}
            optionLabel="label"
            optionValue="value"
            label="Run this path if"
            placeholder="Select condition"
            value={value}
            onValueChange={field.onChange}
            isDisabled={!dataField}
            error={errors.runIf?.message}
          />
        );
      }}
    />
  );
}

function DataFieldOptions({
  control,
  edgeId,
  reset,
}: {
  control: Control<ConditionForm, any>;
  edgeId?: string;
  reset: UseFormReset<ConditionForm>;
}) {
  const matchingDataFieldOptions = useStore((s) => {
    const source = s.edges.find((edge) => edge.id === edgeId)!.source;

    let pointerNode = s.nodes.find(
      (node) =>
        node.id === s.edges.find((edge) => edge.target === source)!.source
    );

    const whitelistedDataFields: string[] = [];

    while (pointerNode && pointerNode.type !== 'conditions') {
      const subsetMatch = dataFieldEntries.find((entry) =>
        isSubset(entry[1], pointerNode)
      );

      if (subsetMatch) {
        whitelistedDataFields.push(subsetMatch[0]);
      }

      const previous = s.nodes.find(
        (node) =>
          node.id ===
          s.edges.find((edge) => edge.target === pointerNode!.id)?.source
      );
      pointerNode = previous;
    }

    return dataFieldOptions.filter((opt) =>
      whitelistedDataFields.includes(opt.value)
    );
  });

  const { errors } = useFormState({ control });

  return (
    <Controller
      control={control}
      name="dataField"
      render={({ field }) => {
        const value = field.value
          ? typeof field.value === 'object'
            ? field.value
            : matchingDataFieldOptions.find((opt) => opt.value === field.value)
          : null;

        return (
          <BBBSelect
            options={matchingDataFieldOptions}
            optionLabel="label"
            optionValue="value"
            label="Data field"
            placeholder="Select data field"
            containerClassName="mb-5"
            value={value}
            onValueChange={(opt) => {
              reset({
                ...defaultValues,
                dataField: opt,
              });
            }}
            error={errors.dataField?.message}
          />
        );
      }}
    />
  );
}

function DynamicInput({ control }: { control: Control<ConditionForm, any> }) {
  const _runIf = useWatch({ control, name: 'runIf' });
  const currency = useWatch({ control, name: 'currency' });

  const runIf = _runIf
    ? typeof _runIf === 'object'
      ? _runIf.value
      : _runIf
    : null;

  const { errors } = useFormState({ control });

  if (!runIf) return null;

  return (
    <>
      {(runIf === 'product_not_purchased' ||
        runIf === 'review_not_submitted') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Until X hours"
          placeholder="Input X value"
          isHookForm
          control={control}
          controlName="delay"
          error={errors.delay?.message}
        />
      )}
      {runIf.startsWith('total_price') && (
        <div className="flex items-center gap-2 mt-5">
          <Controller
            control={control}
            name="currency"
            render={({ field }) => (
              <BBBSelect
                options={currencyOptions}
                optionLabel="code"
                optionValue="code"
                label="Currency"
                placeholder="Currency"
                isSearchable
                value={field.value}
                onValueChange={field.onChange}
                error={errors.currency?.message}
                containerClassName="w-32"
              />
            )}
          />
          <Controller
            control={control}
            name="totalPrice"
            render={({ field }) => {
              const currencyCode = currency?.code || 'USD';

              return (
                <PriceInput
                  error={errors.totalPrice?.message}
                  value={formatCurrency(field.value as string, currencyCode)}
                  onChange={(value) => {
                    field.onChange(value.replace(/\D/g, ''));
                  }}
                  currency={currencyCode}
                />
              );
            }}
          />
        </div>
      )}
      {runIf.startsWith('product_variant') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Product variant value"
          placeholder="Input total product variant value"
          isHookForm
          control={control}
          controlName="totalProductVariant"
          error={errors.totalProductVariant?.message}
        />
      )}
      {runIf.startsWith('quantity') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Quantity value"
          placeholder="Input quantity value"
          isHookForm
          control={control}
          controlName="totalQuantity"
          error={errors.totalQuantity?.message}
        />
      )}
      {runIf.startsWith('review_submitted_stars') && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Stars"
          placeholder="Input stars treshold"
          isHookForm
          control={control}
          controlName="starsTreshold"
          error={errors.starsTreshold?.message}
        />
      )}
      {runIf === 'visited_page_x_times' && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Visited page X times"
          placeholder="Input a number"
          isHookForm
          control={control}
          controlName="visitedPageXTimes"
          error={errors.visitedPageXTimes?.message}
        />
      )}
      {runIf === 'visited_collection_x_times' && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Visited collection X times"
          placeholder="Input a number"
          isHookForm
          control={control}
          controlName="visitedCollectionXTimes"
          error={errors.visitedCollectionXTimes?.message}
        />
      )}
      {runIf === 'visited_product_x_times' && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Visited product X times"
          placeholder="Input a number"
          isHookForm
          control={control}
          controlName="visitedProductXTimes"
          error={errors.visitedProductXTimes?.message}
        />
      )}
      {runIf === 'add_x_cart_item' && (
        <BBBTextInput
          containerClassname="mt-5"
          label="Number of items"
          placeholder="Input a number"
          isHookForm
          control={control}
          controlName="addXCartItems"
          error={errors.addXCartItems?.message}
        />
      )}
      {runIf === 'login_x_period' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Login X times"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loginXTimes"
            error={errors.loginXTimes?.message}
          />
          <BBBTextInput
            containerClassname="mt-5"
            label="Range (days)"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loginRange"
            error={errors.loginRange?.message}
          />
        </>
      )}
      {runIf === 'signup_channel' && (
        <Controller
          control={control}
          name="signupChannel"
          render={({ field }) => {
            return (
              <BBBSelect
                placeholder="Select channel"
                containerClassName="mt-5"
                label="Channel"
                options={bitloginEnabledChannels}
                optionValue="id"
                optionLabel="switchLabel"
                value={field.value}
                onValueChange={field.onChange}
              />
            );
          }}
        />
      )}
      {runIf === 'customer_have_tags' && (
        <Controller
          control={control}
          name="customerTags"
          render={({ field }) => (
            <TagOptions
              isMulti
              containerClassName="w-full md:min-w-full mt-5"
              placeholder="Search tag"
              isSearchable
              label="Customer tag"
              //@ts-ignore
              value={field.value}
              onValueChange={field.onChange}
              isCreatable
              sensitive
            />
          )}
        />
      )}
      {runIf === 'loyalty_x_points' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Amount of points"
            placeholder="Input points"
            isHookForm
            control={control}
            controlName="loyaltyXPoints"
            error={errors.loyaltyXPoints?.message}
          />
        </>
      )}
      {runIf === 'loyalty_rewards_earn_more_than' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Point earned"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loyaltyRewardsEarnMoreThan"
            error={errors.loyaltyRewardsEarnMoreThan?.message}
          />
        </>
      )}
      {runIf === 'loyalty_rewards_earn_less_than' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Point earned"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loyaltyRewardsEarnLessThan"
            error={errors.loyaltyRewardsEarnLessThan?.message}
          />
        </>
      )}
      {runIf === 'loyalty_rewards_used_more_than' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Point earned"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loyaltyRewardsUsedMoreThan"
            error={errors.loyaltyRewardsUsedMoreThan?.message}
          />
        </>
      )}
      {runIf === 'loyalty_rewards_used_less_than' && (
        <>
          <BBBTextInput
            containerClassname="mt-5"
            label="Point earned"
            placeholder="Input a number"
            isHookForm
            control={control}
            controlName="loyaltyRewardsUsedLessThan"
            error={errors.loyaltyRewardsUsedLessThan?.message}
          />
        </>
      )}
    </>
  );
}

function PriceInput({
  error,
  value,
  onChange,
  currency,
}: {
  error?: string;
  value: string;
  onChange: (value: string) => void;
  currency?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = inputRef.current?.selectionStart ?? 0;
    const unformattedValue = value.replace(/\D/g, '');

    const prevValue = value as string;
    onChange(unformattedValue);

    // Difference in length after formatting
    const formattedValue = formatCurrency(unformattedValue, currency || 'USD');
    const lengthDifference = formattedValue.length - prevValue.length;

    // Restore cursor position with adjustment for formatting
    requestAnimationFrame(() => {
      if (inputRef.current) {
        const adjustedPosition = cursorPosition + lengthDifference;
        inputRef.current.selectionStart = adjustedPosition;
        inputRef.current.selectionEnd = adjustedPosition;
      }
    });
  };

  return (
    <BBBTextInput
      ref={inputRef}
      containerClassname="grow mb-0"
      label="Total price value"
      placeholder="Input total price value"
      error={error}
      value={value}
      onChange={handleChange}
    />
  );
}
