import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { selectActiveChatTruthy } from './chat';

import chatServices from '@/api/services/whatsApp/chat';
import { useApiKey } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';

const useMedia = (
  { id, mimeType }: { id: string; mimeType?: string },
  {
    enabled,
    ...options
  }:
    | Omit<
        UseQueryOptions<string, unknown, string, any[]>,
        'queryKey' | 'queryFn'
      >
    | undefined = {}
) => {
  const apiKey = useApiKey();
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useQuery(
    ['bitchat-media', id, { selectedChat, mimeType }],
    () =>
      chatServices.getMedia(selectedChat.clientNumber, {
        id,
        key: apiKey,
        mimetype: mimeType!,
        sources: selectedChat.sources,
      }),
    {
      enabled,
      meta: {
        disableToast: true,
      },
      ...options,
    }
  );
};

export default useMedia;
