import { gql } from 'graphql-request';

export default gql`
  query getCollectionById(
    $id: ID!
    $first: Int
    $sortKey: ProductCollectionSortKeys
    $reverse: Boolean
    $after: String
  ) {
    collection(id: $id) {
      id
      image {
        url
      }
      title
      description
      descriptionHtml
      updatedAt
      products(
        first: $first
        sortKey: $sortKey
        reverse: $reverse
        after: $after
      ) {
        edges {
          node {
            id
            title
            description
            descriptionHtml
            variants(first: 5) {
              edges {
                node {
                  compareAtPriceV2 {
                    amount
                    currencyCode
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  id
                  image {
                    url
                  }
                }
              }
            }
            options {
              id
              name
              values
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            featuredImage {
              url
            }
            images(first: 5) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`;
