import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Publish, PublishRequest2, PublishStatus } from '@/types/bitApp/v2';

export default {
  getPublishByCompanyId: (companyId: number) =>
    api.bitApp.get<Publish>(`/publish/${companyId}`).then((res) => res.data),
  publish: (companyId: number, userId: number) =>
    api.bitApp.post(`/publish/${companyId}/user/${userId}`),
  updateSubmission: (
    companyId: number,
    userId: number,
    payload: SubmissionPayload
  ) =>
    api.bitApp
      .post<Publish>(`/publish/${companyId}/user/${userId}/update`, payload)
      .then((res) => res.data),
  getRecentPublish: (companyId: number) =>
    api.bitApp
      .get<ApiResponse<PublishRequest2[]>>(`/publish/${companyId}/recent`)
      .then((res) => (res.data.data.length ? res.data.data[0] : null)),
  getRecentPublishRequest: (companyId: number) =>
    api.bitApp
      .get<ApiResponse<PublishRequest2 | null>>(
        `/publish/${companyId}/recent-request`
      )
      .then((res) => res.data.data),
  postDraftPublish: (companyId: number, payload: PostDraftPublishPayload) =>
    api.bitApp
      .post(`/publish/${companyId}/draft`, payload)
      .then((res) => res.data),
  postRequestPublish: (companyId: number, payload: { userId: number }) =>
    api.bitApp.post(`/publish/${companyId}`, payload).then((res) => res.data),
  updateSubmission2: (
    companyId: number,
    userId: number,
    payload: SubmissionPayload
  ) =>
    api.bitApp
      .post<Publish>(`/publish/${companyId}/user/${userId}/update2`, payload)
      .then((res) => res.data),
};

export type PostDraftPublishPayload = Partial<
  Pick<
    PublishRequest2,
    | 'appName'
    | 'appDescription'
    | 'appSplashScreen'
    | 'appSubtitle'
    | 'appIcon'
    | 'accountType'
    | 'primaryColor'
    | 'id'
  >
> &
  Pick<PublishRequest2, 'userId'>;

export type SubmissionPayload = Partial<{
  iosStatus: PublishStatus | null;
  androidStatus: PublishStatus | null;
  rejectedReasonApple: string;
  rejectedReasonGoogle: string;
  androidDownloadUrl: string;
  iosDownloadUrl: string;
}> & {
  id: string;
};
