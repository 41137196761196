import { useEffect, useState } from 'react';

import BBBLogo from '@/assets/icons/BBBLogo';
import { BBBButton } from '@/components/ui';
import Link from '@/components/ui/Link/Link';
import useHistory from '@/hooks/common/useHistory';

const NotFoundPage = () => {
  const [counter, setCounter] = useState(10);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => setCounter((prev) => prev - 1), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (counter === 0) {
      history.push('/');
    }
  }, [counter, history]);

  return (
    <div className="max-w-md mx-auto px-4 md:px-0 flex min-h-full flex-col justify-center items-center">
      <BBBLogo className="object-contain mb-16" />
      <div className="text-2xl font-medium mb-4">Oops! Page not found</div>
      <div className="text-neutral-50 mb-6">
        You will be automatically redirected to homepage in {counter}s...
      </div>
      <div className="flex gap-4">
        <Link to="/">
          <BBBButton text="Go to home" />
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
