import { createSlice } from '@reduxjs/toolkit';

type Store = {
  collectDataPage: number;
};

const initialState: Store = {
  collectDataPage: 0,
};

export const bitLogin = createSlice({
  name: 'bitLogin',
  initialState,
  reducers: {
    setCollectDataPage: (state, action: { payload: number }) => {
      state.collectDataPage = action.payload;
    },
  },
});

export const { setCollectDataPage } = bitLogin.actions;

export default bitLogin.reducer;
