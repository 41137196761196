import { useQuery } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { SocialMedia, SocialMediaKind } from '@/types/bitLink/v2';

const useSocialMedia = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery<
    (SocialMedia & {
      socialMediaKind: SocialMediaKind;
    })[]
  >(['bitlink-social', activeCompany], async () => {
    const { data } = await api.bitLink.get(`/sosmed/${activeCompany}`);
    return data;
  });
};

export default useSocialMedia;
