import { Info } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';

import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBAlert, BBBPrimarySwitch, BBBTooltip } from '@/components/ui';
import { bitloginChannels } from '@/constants/bitLogin/appearance';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import useActiveTemplate from '@/hooks/bitLogin/useActiveTemplate';
import useSocialConfig from '@/hooks/bitLogin/useSocialConfig';
import useUpsertSocialConfig from '@/hooks/bitLogin/useUpsertSocialConfig';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import useResponsive from '@/hooks/common/useResponsive';
import { SocialConfig } from '@/types/bitLogin/v2';

type Props = {
  livePreview?: boolean;
  withSwitchLabel?: boolean;
  type: 'web' | 'mobile';
  socialConfig?: SocialConfig[];
  isLoadingSocialConfig?: boolean;
  containerClassName?: string;
};

export default function SocialLoginButtons({
  withSwitchLabel,
  type,
  livePreview,
  containerClassName,
}: Props) {
  const isMobile = useResponsive('sm');

  const { data: socialConfig, isInitialLoading: isLoadingSocialConfig } =
    useSocialConfig();

  const { mutate: updateOauth } = useUpsertSocialConfig();
  const connectIntegration = useConnectIntegration();
  const { data } = useWhatsappCloudIntegration();
  const { data: activeTemplate } = useActiveTemplate();

  const confirm = useConfirmationModal();
  function setupOtpModal() {
    confirm({
      title: `You haven't set up your OTP`,
      description:
        'To activate WhatsApp login, you have to set up your OTP settings',
      onAccept: (hide) => {
        hide();
      },
      submitLink:
        '/misc/whatsapp-api-settings?section=bitlogin-whatsapp-settings',
      submitText: 'Set up OTP',
    });
  }

  return (
    <div className={cx('flex flex-col gap-6', containerClassName)}>
      {(livePreview
        ? bitloginChannels.filter((integration) => {
            const socialProviderConfig = socialConfig?.find(
              (config) => config.provider === integration.name
            );
            return socialProviderConfig?.enabledMobile;
          })
        : bitloginChannels
      ).map((integration) => {
        const socialProviderConfig = socialConfig?.find(
          (config) => config.provider === integration.name
        );

        return (
          <>
            <div
              className={cx('flex justify-between gap-4 items-center')}
              key={integration.id}
            >
              <div className="flex flex-col gap-4">
                <div
                  className={cx(
                    `flex items-center select-none justify-center w-[259px] gap-2 py-3 rounded-xl border`,
                    integration.name === 'GOOGLE' &&
                      'bg-transparent border border-[#000000]',
                    integration.name === 'FACEBOOK' &&
                      'bg-[#1877F2] text-white border-transparent',
                    integration.name === 'APPLE' &&
                      'bg-[#000000] text-white border-transparent',
                    integration.name === 'WHATSAPP' &&
                      'bg-[#25D366] text-white border-transparent',
                    integration.name === 'MICROSOFT' &&
                      'bg-[#2F2F2F] text-white border-transparent',
                    integration.name === 'TWITTER' &&
                      'bg-[#000000] text-white border-transparent',
                    integration.name === 'AMAZON' &&
                      'bg-[#FF9900] border-transparent',
                    integration.name === 'MAGIC_LINK' &&
                      'bg-transparent border border-[#000000]',
                    integration.name === 'QR_CODE' &&
                      'bg-transparent border border-[#000000]'
                  )}
                >
                  <div>{integration.logo}</div>
                  <div>{integration.label}</div>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                {integration.name === 'WHATSAPP' && activeTemplate?.data && (
                  <Link
                    to={
                      '/misc/whatsapp-api-settings?section=bitlogin-whatsapp-settings'
                    }
                    className="underline text-blue-500"
                  >
                    Set up OTP settings
                  </Link>
                )}
                {integration.name === 'WHATSAPP' && (
                  <BBBTooltip
                    show
                    content="To activate WhatsApp login, you will need a WhatsApp Cloud API account"
                  >
                    <Info size={14} color="#9E9E9E" />
                  </BBBTooltip>
                )}
                {!isMobile && withSwitchLabel && integration.switchLabel}
                {!livePreview &&
                  (isLoadingSocialConfig ? (
                    <Skeleton width={50} height={30} />
                  ) : (
                    <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
                      {({ connectMiddleware }) => (
                        <BBBPrimarySwitch
                          checked={
                            type === 'web'
                              ? socialProviderConfig?.enabledWeb
                              : socialProviderConfig?.enabledMobile
                          }
                          onChange={(val) => {
                            if (integration.name === 'WHATSAPP') {
                              if (data?.status !== 'CONNECTED') {
                                connectIntegration({
                                  name: 'whatsapp_meta',
                                });
                              } else if (!activeTemplate?.data) {
                                setupOtpModal();
                              } else {
                                updateOauth({
                                  [type === 'web'
                                    ? 'enabledWeb'
                                    : 'enabledMobile']: val,
                                  provider: integration.name as Exclude<
                                    typeof integration.name,
                                    'MAGIC_LINK' | 'QR_CODE'
                                  >,
                                });
                              }
                            } else {
                              connectMiddleware(() =>
                                updateOauth({
                                  [type === 'web'
                                    ? 'enabledWeb'
                                    : 'enabledMobile']: val,
                                  provider: integration.name as Exclude<
                                    typeof integration.name,
                                    'MAGIC_LINK' | 'QR_CODE'
                                  >,
                                })
                              );
                            }
                          }}
                          disabled={integration.disabled}
                        />
                      )}
                    </ShopifyAwareWrapper>
                  ))}
              </div>
            </div>
            {integration.name === 'WHATSAPP' &&
              data?.status === 'CONNECTED' &&
              !activeTemplate?.data && (
                <BBBAlert
                  message={
                    <>
                      Set up OTP to start using WhatsApp login.{' '}
                      <Link
                        to={
                          '/misc/whatsapp-api-settings?section=bitlogin-whatsapp-settings'
                        }
                        className="font-bold underline text-blue-500"
                      >
                        Set up OTP
                      </Link>
                    </>
                  }
                  type="info"
                  className="my-1 py-2"
                  iconSize={30}
                />
              )}
          </>
        );
      })}
    </div>
  );
}
