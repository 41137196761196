import analytics from './analytics';
import businessProfile from './business-profile';
import chat from './chat';
import flow from './flow';
import form from './form';
import settings from './settings';
import v2 from './v2';
import v3 from './v3';

export default {
  analytics,
  businessProfile,
  v2,
  v3,
  chat,
  flow,
  form,
  settings,
};
