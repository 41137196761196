import { twMerge as cx } from 'tailwind-merge';

import { Accent } from '@/types/utils/color';

export type BBBBadgeProps = {
  text?: string;
  textClassName?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type?: Accent;
};

function BBBBadge({
  text,
  textClassName,
  className,
  onClick,
  type = 'neutral',
}: BBBBadgeProps) {
  return (
    <div
      className={cx(
        'bg-neutral-30 rounded-lg px-2 py-1 inline-block',
        type === 'success' &&
          'bg-success-surface text-success-main border border-success-main',
        type === 'info' &&
          'bg-info-surface text-info-main border border-info-main',
        type === 'danger' &&
          'bg-danger-surface text-danger-main border border-danger-main',
        type === 'neutral' &&
          'bg-neutral-20 text-neutral-60 border border-neutral-60',
        type === 'warning' &&
          'bg-secondary-surface border text-secondary-main border-secondary-main',
        type === 'ai' && 'border border-ai-main bg-ai-hover text-ai-main',
        className
      )}
      onClick={onClick}
    >
      <div className={cx('line-clamp-1', textClassName)}>{text}</div>
    </div>
  );
}

export default BBBBadge;
