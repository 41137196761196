import { useQuery } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';

const useCustomerByPhoneNumber = (
  phoneNumber?: string,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();
  const selectedChat = useAppSelector(
    (store) => phoneNumber ?? store.bitCRM.selectedChat?.clientNumber
  );
  const isGroup = useIsGroupChat();

  return useQuery(
    ['customer-phonenumber', selectedChat, activeCompany],
    () =>
      customerServices.customer.getCustomerByPhoneNumber(
        activeCompany,
        selectedChat!
      ),
    {
      enabled:
        (typeof options?.enabled !== undefined ? options?.enabled : true) &&
        selectedChat !== undefined &&
        !isGroup,
    }
  );
};

export default useCustomerByPhoneNumber;
