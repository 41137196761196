import {
  ForwardedRef,
  forwardRef,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';

import { BBBTextInput, BBBTextInputProps } from '@/components/ui';

export type BBBDebounceInputProps<T extends FieldValues> = {
  value: string | number;
  onValueChange: (value: string) => void;
  debounce?: number;
  label?: string;
} & BBBTextInputProps<T>;

const BBBDebounceInput = <T extends FieldValues>(
  {
    value: initialValue,
    onValueChange,
    debounce = 500,
    label,
    containerClassname,
    ...props
  }: BBBDebounceInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [value, setValue] = useState(initialValue);
  const isUserInput = useRef(false);

  useEffect(() => {
    setValue(initialValue);
    isUserInput.current = false;
  }, [initialValue]);

  useEffect(() => {
    if (isUserInput.current) {
      const timeout = setTimeout(() => {
        onValueChange(value as string);
      }, debounce);

      return () => clearTimeout(timeout);
    }
  }, [debounce, onValueChange, value]);

  return (
    <BBBTextInput
      {...props}
      label={label}
      value={value}
      containerClassname={cx('mb-0', containerClassname)}
      onChange={(e) => {
        isUserInput.current = true;

        setValue(e.target.value);
      }}
      ref={ref}
    />
  );
};

export default forwardRef(BBBDebounceInput) as <T extends FieldValues>(
  p: BBBDebounceInputProps<T> & { ref?: Ref<HTMLInputElement> }
) => React.ReactElement;

export const useDebounce = (
  initialValue: string,
  onValueChange: (value: string) => void,
  debounce = 500
) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onValueChange(value as string);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onValueChange, value]);

  return setValue;
};
