import { useEffect, useState } from 'react';

/**
 *
 * @param querySelector
 * @param containerRef
 * @returns Sticky element index
 */
const useSticky = (
  querySelector: string,
  containerRef?: HTMLElement | null
) => {
  const [sticky, setSticky] = useState<{
    index: number;
    height: number;
  }>();

  useEffect(() => {
    const log = () => {
      const list: {
        index: number;
        height: number;
      }[] = [];

      document.querySelectorAll(querySelector).forEach((item, index) => {
        list.push({
          index,
          height: item.getBoundingClientRect().top,
        });
      });

      const filterMin = Array.from(list).filter((data) => data.height <= 0);
      let min: typeof list[number] | undefined = undefined;

      filterMin.forEach((data) => {
        if (data.height > (min?.height ?? -9999)) {
          min = data;
        }
      });
      setSticky(min);
    };

    containerRef?.addEventListener('scroll', log);
    return () => {
      containerRef?.removeEventListener('scroll', log);
    };
  }, [containerRef, querySelector]);

  return sticky?.index;
};

export default useSticky;
