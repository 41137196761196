import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useWACloudTemplate = () => {
  const activeCompany = useActiveCompany();
  const { id } = useParams<{ id: string }>();

  return useQuery(
    ['bitchat-wa-cloud-template', id],
    () => services.bitChat.template.getWACloudTemplate(activeCompany, id),
    {
      enabled: !!id && id !== 'new',
    }
  );
};

export default useWACloudTemplate;
