import { ReactNode, useState } from 'react';

import DefaultPhoto from '@/assets/icons/DefaultWhatsAppProfilePicture';
import { integrationsMeta } from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import useProfilePicture from '@/hooks/whatsApp/useProfilePicture';
import { Chat } from '@/types/whatsApp/chat';

type ChatPreviewProfilePictureProps = {
  clientNumber: string;
  profilePicture?: string | ReactNode;
  profilePictureUpdated?: boolean;
  sources: Chat['sources'];
};

export default function ChatPreviewProfilePicture({
  clientNumber,
  profilePicture,
  profilePictureUpdated,
  sources,
}: ChatPreviewProfilePictureProps) {
  const { data, status } = useProfilePicture(clientNumber, sources, {
    enabled: !profilePictureUpdated,
  });

  const profilePictureUrl = status === 'success' ? data : profilePicture;

  const [fallbackToDefault, setFallbackToDefault] = useState(false);

  return (
    <div className="flex-none py-2">
      <div className="relative">
        {fallbackToDefault || !profilePictureUrl ? (
          <DefaultPhoto size={'4.5rem'} />
        ) : typeof profilePictureUrl === 'string' ? (
          <img
            src={profilePictureUrl}
            alt="pp"
            className="rounded-full w-18 h-18 !max-w-none object-cover"
            loading="lazy"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setFallbackToDefault(true);
            }}
          />
        ) : (
          profilePictureUrl
        )}
        {sources && (
          <div className="absolute bottom-0 flex items-center justify-center right-0 rounded-full">
            <SourceIcon source={sources} />
          </div>
        )}
      </div>
    </div>
  );
}

function SourceIcon({ source }: { source: Chat['sources'] }) {
  const Icon = integrationsMeta[mapSourceToKnownIntegrations[source]].icon;

  return Icon ? <Icon /> : null;
}
