import api from '@/config/api';
import { SocialButtonAppearance } from '@/types/bitLogin/v2';

export type RedirectionList = {
  title: string;
  link: string;
  data?: {
    id: any;
    name: any;
    link: string;
  }[];
};

export default {
  getRedirectionList: (domain: string | undefined) =>
    api.bitLogin
      .get<RedirectionList[]>(`/appearance/redirect/${domain}`)
      .then((res) => res.data),
  getSocialAppearance: (domain: string | undefined) =>
    api.bitLogin
      .get<SocialButtonAppearance>(`/appearance/domain/${domain}`)
      .then((res) => res.data),
  upsertSocialAppearance: (
    domain: string | undefined,
    payload: Partial<SocialButtonAppearance>
  ) =>
    api.bitLogin
      .post(`/appearance/domain/${domain}`, {
        ...payload,
      })
      .then((res) => res.data),
  getSocialLanguages: () =>
    api.bitLogin
      .get<
        {
          code: string;
          country: string;
        }[]
      >(`/appearance/languages`)
      .then((res) => res.data),
};
