import { PricingPlan } from 'api/services/pricing';
import { AppType } from 'types/systemLogin';

export const groupOrder = [
  'bitChat',
  'bitCRM',
  'bitApp',
  'bitLink',
  'bitLogin',
  'bitAi',
];

export const sortAppOrder = (groupA: string, groupB: string) => {
  const indexA = groupOrder.indexOf(groupA);
  const indexB = groupOrder.indexOf(groupB);

  if (indexA === -1 && indexB === -1) {
    return 0; // Keep the original order if both groups are not in groupOrder
  } else if (indexA === -1) {
    return 1; // Put groupB first if groupA is not in groupOrder
  } else if (indexB === -1) {
    return -1; // Put groupA first if groupB is not in groupOrder
  } else {
    return indexA - indexB; // Keep the order specified in groupOrder
  }
};

export const pricingPlanPriority: Record<PricingPlan, number> = {
  free: 7,
  freeWithUsageCharge: 6,
  standard: 5,
  pro: 4,
  pro1: 3,
  enterprise: 1,
  proAdvance: 2,
};

export const appPriority: Record<AppType, number> = {
  BITCHAT: 1,
  BITCRM: 2,
  BITAPP: 3,
  BITLINK: 4,
  BITLOGIN: 5,
  BITAI: 6,
};

export const mapAppTypeToAppName: Record<AppType, string> = {
  BITCHAT: 'bitChat',
  BITCRM: 'bitCRM',
  BITAPP: 'bitApp',
  BITLINK: 'bitLink',
  BITLOGIN: 'bitLogin',
  BITAI: 'bitAi',
};
