export const formatUrlSlash = (text?: string) => {
  if (!text) return '';
  if (text.indexOf('/') === text.length - 1) return text;
  return `${text}/`;
};

export const getRawShopifyNodeId = (shopifyId: string) => {
  const splitId = shopifyId.split('/');

  return splitId[splitId.length - 1];
};
