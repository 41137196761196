import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { UpdateMetafieldsParams } from '@/api/services/customer/customer';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

export const useUpdateMetafields = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateMetafieldsParams) =>
      services.customer.customer.updateMetafields(activeCompany, payload),
    {
      onSuccess: () => {
        toast.success('Sucesfully update customer additional data');
        queryClient.invalidateQueries(['customer']);
      },
    }
  );
};

export const useCustomerMetafields = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['customer-metafields', activeCompany], () =>
    services.customer.customer.getMetafields(activeCompany)
  );
};

export const useCustomerMetafieldValues = (params: { key: string }) => {
  const activeCompany = useActiveCompany();

  return useQuery(['customer-metafields', activeCompany, params], () =>
    services.customer.customer.getMetafieldValues(activeCompany, params)
  );
};
