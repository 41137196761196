import React from 'react';

type Props = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const FluentOpenIcon = ({ color = '#9A9A9A', ...props }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.64292 5.4001C7.08597 5.4001 6.55182 5.62135 6.158 6.01517C5.76417 6.409 5.54292 6.94314 5.54292 7.5001V16.5001C5.54292 17.6593 6.48372 18.6001 7.64292 18.6001H16.6429C17.1999 18.6001 17.734 18.3788 18.1278 17.985C18.5217 17.5912 18.7429 17.0571 18.7429 16.5001V14.1001C18.7429 13.8614 18.8377 13.6325 19.0065 13.4637C19.1753 13.2949 19.4042 13.2001 19.6429 13.2001C19.8816 13.2001 20.1105 13.2949 20.2793 13.4637C20.4481 13.6325 20.5429 13.8614 20.5429 14.1001V16.5001C20.5429 17.5344 20.132 18.5264 19.4006 19.2578C18.6692 19.9892 17.6773 20.4001 16.6429 20.4001H7.64292C6.60858 20.4001 5.6166 19.9892 4.8852 19.2578C4.15381 18.5264 3.74292 17.5344 3.74292 16.5001V7.5001C3.74292 6.46575 4.15381 5.47377 4.8852 4.74238C5.6166 4.01099 6.60858 3.6001 7.64292 3.6001H10.0429C10.2816 3.6001 10.5105 3.69492 10.6793 3.8637C10.8481 4.03248 10.9429 4.2614 10.9429 4.5001C10.9429 4.73879 10.8481 4.96771 10.6793 5.13649C10.5105 5.30528 10.2816 5.4001 10.0429 5.4001H7.64292ZM12.7429 4.5001C12.7429 4.2614 12.8377 4.03248 13.0065 3.8637C13.1753 3.69492 13.4042 3.6001 13.6429 3.6001H19.6429C19.8816 3.6001 20.1105 3.69492 20.2793 3.8637C20.4481 4.03248 20.5429 4.2614 20.5429 4.5001V10.5001C20.5429 10.7388 20.4481 10.9677 20.2793 11.1365C20.1105 11.3053 19.8816 11.4001 19.6429 11.4001C19.4042 11.4001 19.1753 11.3053 19.0065 11.1365C18.8377 10.9677 18.7429 10.7388 18.7429 10.5001V6.6721L14.2789 11.1361C14.1965 11.2245 14.0972 11.2954 13.9868 11.3446C13.8764 11.3938 13.7572 11.4203 13.6363 11.4224C13.5155 11.4245 13.3955 11.4023 13.2834 11.357C13.1713 11.3118 13.0695 11.2444 12.9841 11.1589C12.8986 11.0735 12.8312 10.9717 12.786 10.8596C12.7407 10.7475 12.7185 10.6275 12.7206 10.5067C12.7227 10.3858 12.7492 10.2667 12.7984 10.1563C12.8476 10.0459 12.9185 9.94649 13.0069 9.8641L17.4709 5.4001H13.6429C13.4042 5.4001 13.1753 5.30528 13.0065 5.13649C12.8377 4.96771 12.7429 4.73879 12.7429 4.5001Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default FluentOpenIcon;
