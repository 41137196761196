import { DropdownTypes } from '../BBBSelect.type';

import { BBBBottomSheet } from '@/components/ui';
import { Nullable } from '@/types/utils/nullable';

function BBBSelectDropdownOptionMobile<
  T extends {
    [k: string]: any;
  }
>({
  isMenuOpen,
  isPaginated,
  data,
  fetchNext,
  hasMore,
  placeholder,
  onClose,
  children,
}: {
  isMenuOpen: boolean | undefined;
  data: Nullable<T[]>;
  onClose?: () => void;
  children?: React.ReactNode;
} & DropdownTypes<T>) {
  return (
    <BBBBottomSheet
      title={placeholder}
      show={isMenuOpen}
      onClose={onClose}
      className="p-0"
      headerClassName="p-3"
      isPaginated={isPaginated}
      fetchNext={fetchNext}
      hasMore={hasMore}
      dataLength={data?.length}
    >
      {children}
    </BBBBottomSheet>
  );
}

export default BBBSelectDropdownOptionMobile;
