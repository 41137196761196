import { useMutation, useQuery } from '@tanstack/react-query';
import services from 'api/services';

import { WhatsappLoginCallbackParams } from '@/types/bitLogin/v2';
import { toast } from '@/utils/common/toast';

export const useRegistrationWhatsApp = () => {
  return useMutation(
    (data: WhatsappLoginCallbackParams) =>
      services.bitLogin.login.whatsapp.addRegistrationLogin(data),
    {
      onSuccess: (data) => {
        const script = document.createElement('script');
        script.innerHTML = data;

        document.body.appendChild(script);
      },
    }
  );
};

export const useQRWhatsAppLogin = (
  domain: string | null | undefined,
  code: string | null | undefined
) => {
  return useQuery(['qr-whatsapp-bitlogin', domain], () =>
    services.bitLogin.login.whatsapp.getQRCode(domain, code)
  );
};

export const useLoginStatus = (code: string, domain: string) => {
  return useQuery(['whatsapp-login-status', code], () =>
    services.bitLogin.login.whatsapp.getWhatsappLoginStatus(code, domain)
  );
};

export const useUpdateLoginStatus = () => {
  return useMutation(
    (payload: { code: string; domain: string; status: number }) =>
      services.bitLogin.login.whatsapp.updateWhatsappLoginStatus(
        payload.code,
        payload.domain,
        { status: payload.status }
      )
  );
};

export const useRequestOTP = () => {
  return useMutation(
    (payload: { phoneNumber: string; domain: string }) =>
      services.bitLogin.login.whatsapp.sendOTP(payload),
    {
      onSuccess: () => {
        toast.success('OTP has been sent to your number!');
      },
    }
  );
};

export const useVerifyOTP = (
  onSuccessHandler: () => void,
  onFailedHandler: () => void,
  onErrorHandler: (error: string) => void
) => {
  return useMutation(
    (payload: { phoneNumber: string; domain: string; code: string }) =>
      services.bitLogin.login.whatsapp.verifyOTP(payload),
    {
      onSuccess: (data) => {
        if (data.status === 'error') {
          onErrorHandler(data.message);
        } else if (data.status === 'failed') {
          onFailedHandler();
          setTimeout(() => {
            window.close();
          }, 3000);
          window.close();
        } else if (data.status === 'success') {
          onSuccessHandler();
          setTimeout(() => {
            const script = document.createElement('script');
            script.innerHTML = data.data;
            document.body.appendChild(script);
          }, 3000);
        }
      },
    }
  );
};
