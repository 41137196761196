import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import {
  GetCompanyUsersParam,
  GetCompanyUsersPayload,
} from 'api/services/systemLogin';

import services from '@/api/services';

const useCompanyUsersByCompanyId = (
  companyId?: number,
  params?: GetCompanyUsersParam,
  {
    enabled,
    ...options
  }: Omit<
    UseInfiniteQueryOptions<
      GetCompanyUsersPayload,
      unknown,
      GetCompanyUsersPayload,
      GetCompanyUsersPayload,
      (
        | string
        | number
        | undefined
        | { params: GetCompanyUsersParam | undefined }
      )[]
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  > = {}
) => {
  return useInfiniteQuery(
    ['company-users', companyId, { params }],
    ({ pageParam }) =>
      services.systemLogin.getCompanyUsers(companyId!, {
        after: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
      enabled: !!companyId && enabled,
      ...options,
    }
  );
};

export default useCompanyUsersByCompanyId;
