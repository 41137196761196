import { twMerge as cx } from 'tailwind-merge';

import useResponsive from '@/hooks/common/useResponsive';

type Props = {
  title: string;
  children: React.ReactNode;
  date: string;
};

function OverviewBox({ children, title, date }: Props) {
  const isMobile = useResponsive('sm');

  return (
    <div
      className={cx(
        'bg-white rounded-2xl h-fit transition-all hover:shadow cursor-pointer',
        isMobile ? 'min-w-[236px]' : 'w-full'
      )}
    >
      <div className="py-5 px-4 w-full">
        <div className="relative w-full h-full">
          <div className="bg-[#FD823E] w-1 h-full absolute left-0 rounded" />
          <div className="px-3.5 flex flex-col gap-3">
            <h5 className="font-semibold text-primary-main text-xl">{title}</h5>
            {children}
          </div>
        </div>
        <p className="text-neutral-50 mt-3.5">vs. {date}</p>
      </div>
    </div>
  );
}

export default OverviewBox;
