import { isValidElement, ReactNode } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard, IBBBCard } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

export type BBBNavigatorTabProps<T> = {
  navigationLists: T[];
  value?: T;
  optionValue: keyof T;
  optionLabel: keyof T;
  optionIcon?: keyof T;
  onChange?: (val: T) => void;
  className?: string;
  rightComponent?: ReactNode;
} & Omit<IBBBCard, 'onChange'>;

function BBBNavigatorTab<T>({
  navigationLists,
  value,
  onChange,
  optionLabel,
  optionValue,
  optionIcon,
  className,
  rightComponent,
  ...cardProps
}: BBBNavigatorTabProps<T>) {
  const isMobile = useResponsive('sm');

  return (
    <BBBCard
      className={cx(
        'px-3 py-2 md:px-3 md:py-2 flex items-center gap-3 flex-wrap',
        className
      )}
      {...cardProps}
    >
      {navigationLists.map((item) => {
        const Icon = optionIcon ? item[optionIcon] : null;

        const selected = item[optionValue] === value?.[optionValue];

        return (
          <div
            key={item[optionValue] as string}
            className="flex-1 lg:flex-none"
          >
            <div
              className={cx(
                `flex justify-center whitespace-nowrap transition-all group rounded-lg hover:bg-gray-100 cursor-pointer items-center gap-2 py-2 px-4`,
                isMobile ? 'flex-col' : '',
                selected ? 'bg-primary-main pointer-events-none font-bold' : ''
              )}
              onClick={() => {
                onChange?.(item);
              }}
            >
              {Icon && (
                <span>
                  {isValidElement(Icon) ? (
                    Icon
                  ) : (
                    //@ts-ignore
                    <Icon
                      className={cn(
                        'text-primary-main',
                        selected && 'text-white'
                      )}
                    />
                  )}
                </span>
              )}
              <div className={selected ? 'text-white' : 'text-primary-main'}>
                {item[optionLabel] as ReactNode}
              </div>
            </div>
          </div>
        );
      })}
      {rightComponent && (
        <>
          <div className="grow" />
          {rightComponent}
        </>
      )}
    </BBBCard>
  );
}

export default BBBNavigatorTab;
