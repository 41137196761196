export default function WebhookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        fill="#FF7C11"
        d="M8 13h5.88c.27-.31.67-.5 1.12-.5.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.44 0-.84-.19-1.12-.5H9.9A5 5 0 114 9.1v2.07c-1.16.41-2 1.53-2 2.83 0 1.65 1.35 3 3 3s3-1.35 3-3v-1zm2.5-11c1.65 0 3 1.35 3 3h2c0-2.76-2.24-5-5-5a5.002 5.002 0 00-3.45 8.62l-2.35 3.9c-.68.14-1.2.75-1.2 1.48a1.498 1.498 0 002.178 1.339 1.498 1.498 0 00.752-1.789l3.38-5.63A3 3 0 017.5 5c0-1.65 1.35-3 3-3zm4.5 9c-.64 0-1.23.2-1.72.54l-3.05-5.07C9.53 6.35 9 5.74 9 5c0-.83.67-1.5 1.5-1.5S12 4.17 12 5c0 .15-.02.29-.06.43l2.19 3.65c.28-.05.57-.08.87-.08 2.76 0 5 2.24 5 5a5.002 5.002 0 01-7.5 4.33 5 5 0 01-1.83-1.83h2.67c.48.32 1.05.5 1.66.5 1.65 0 3-1.35 3-3s-1.35-3-3-3z"
      ></path>
    </svg>
  );
}
