import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useCompanyNavigation = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(['navigations', activeCompany], () =>
    services.bitApp.navigation.getNavigationByCompanyId(activeCompany!)
  );
};

export const useCategoryActive = () => {
  const { data } = useCompanyNavigation();

  return (
    data?.active?.some((item) => item.navigation.name === 'Category') ?? false
  );
};

export default useCompanyNavigation;
