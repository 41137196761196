import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';

const useUserCompanyDetail = (
  userId?: string,
  _companyId?: number,
  {
    enabled,
    ...options
  }: Omit<
    UseQueryOptions<
      UserCompanyWithAssociation,
      AxiosError<any>,
      UserCompanyWithAssociation,
      (string | number | undefined)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const companyId = _companyId || activeCompany;

  return useQuery(
    ['user-company', userId, companyId],
    () => services.systemLogin.getUserCompanyDetail(userId, companyId),
    {
      enabled: !!userId && userId !== 'new' && enabled,
      meta: {
        disableToast: true,
      },
      ...options,
    }
  );
};

export default useUserCompanyDetail;
