import { useMutation } from '@tanstack/react-query';
import { LoginResponseData, SocialLoginPayload } from './useLogin';

import api from '@/config/api';

type AcceptInvitationPayload = { id: string } & (
  | {
      password?: string;
      firstName?: string;
      lastName?: string;
      profilePicture?: string;
      phone?: string;
    }
  | SocialLoginPayload
);

const useAcceptInvitation = () => {
  return useMutation<
    LoginResponseData & {
      authenticatedCompanyId: number;
    },
    unknown,
    AcceptInvitationPayload
  >(async ({ id, ...payload }) => {
    const {
      data: { data },
    } = await api.systemLogin.post(`/invitation/${id}/accept`, payload);
    return data;
  });
};

export default useAcceptInvitation;
