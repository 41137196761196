import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../rtk/store';
import useIsGroupChat from './useIsGroupChat';

import chatServices from '@/api/services/whatsApp/chat';
import { useApiKey } from '@/hooks/rtk/selector';

const useWAGroupParticipants = () => {
  const isGroup = useIsGroupChat();
  const clientNumber = useAppSelector(
    (s) => s.bitCRM.selectedChat!.clientNumber
  );

  const apiKey = useApiKey();

  return useQuery(
    [
      'whatsapp-group-participants',
      clientNumber,
      {
        key: apiKey,
      },
    ],
    () =>
      chatServices.getWhatsAppGroupInfo(clientNumber, {
        key: apiKey,
      }),
    {
      enabled: isGroup,
    }
  );
};

export default useWAGroupParticipants;
