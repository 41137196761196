import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import {
  GetCompanyUsersParam,
  GetCompanyUsersPayload,
} from '@/api/services/systemLogin';

const useCompanyUsers = (
  params?: GetCompanyUsersParam,
  {
    enabled,
    ...options
  }: Omit<
    UseInfiniteQueryOptions<
      GetCompanyUsersPayload,
      unknown,
      GetCompanyUsersPayload,
      GetCompanyUsersPayload,
      (string | number | { params: GetCompanyUsersParam | undefined })[]
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    ['company-users', activeCompany, { params }],
    ({ pageParam }) =>
      services.systemLogin.getCompanyUsers(activeCompany, {
        after: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
      enabled: enabled,
      ...options,
    }
  );
};

export default useCompanyUsers;
