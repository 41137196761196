import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketCustomers } from 'socket';

import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import { useAppSelector } from '@/hooks/rtk/store';

const useBulkUploadRealtimeUpdate = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const [previousRoundedProgress, setPreviousRoundedProgress] = useState<
    number | null
  >(null);

  const { toggle, hide } = useConfirmationBanner();
  const queryClient = useQueryClient();

  const onUpdate = useCallback(
    ({ fileName, progress }: { fileName: string; progress: number }) => {
      const roundedProgress = Math.round(progress);

      const bannerId = `customer-upload-${fileName}`;
      toggle(`customer-upload-${fileName}`, {
        show: progress <= 100,
        text: `Uploading ${fileName}... ${progress}%`,
        variant: 'loading',
      });

      if (progress === 100) {
        setTimeout(() => {
          hide(bannerId);
        }, 2000);
      }

      if (roundedProgress !== previousRoundedProgress) {
        queryClient.invalidateQueries(['customers']);

        setPreviousRoundedProgress(roundedProgress);
      }
    },
    [hide, previousRoundedProgress, queryClient, toggle]
  );

  useEffect(() => {
    socketCustomers.emit('join', {
      companyId: activeCompany,
    });
  }, [activeCompany]);

  useEffect(() => {
    socketCustomers.on('progressUpdate', onUpdate);

    return () => {
      socketCustomers.off('progressUpdate', onUpdate);
    };
  }, [onUpdate]);
};

export default useBulkUploadRealtimeUpdate;
