import FormAiAgent from './Form';
import Skillsets from './SkillSets';

export default function AiAgent() {
  return (
    <div className="flex flex-col gap-5">
      <FormAiAgent />
      <Skillsets />
    </div>
  );
}
