import { Control, Controller } from 'react-hook-form';
import { FieldForm } from '../CustomFieldCard';

import { BBBCheckbox, BBBTextInput } from '@/components/ui';

type Props = {
  control: Control<FieldForm>;
};

export default function SingleTextField({ control }: Props) {
  return (
    <div className="flex flex-col gap-2">
      <BBBTextInput
        label={
          <>
            Label text <span className="text-danger-main">*</span>
          </>
        }
        placeholder="Enter label text"
        hasMaxCharLabel
        maxChar={20}
        isHookForm
        control={control}
        controlName="label"
      />
      <Controller
        name="mandatory"
        control={control}
        render={({ field }) => (
          <BBBCheckbox
            label="Required field"
            checked={field.value || false}
            onValueChange={(val) => field.onChange(val)}
          />
        )}
      />
    </div>
  );
}
