import { useEffect, useMemo, useRef, useState } from 'react';
import { Plus } from 'react-feather';
import {
  Control,
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { messageConditions } from 'constants/bitChat/flows';
import { EditorState } from 'draft-js';
import useSettings, {
  useDefaultFallback,
} from 'hooks/bitChat/settings/useSettings';
import { useCheckKeywordAvailability } from 'hooks/whatsApp/flow';
import { twMerge as cx } from 'tailwind-merge';
import { FileType } from 'types/utils/file';
import { SettingsFallback } from 'types/whatsApp/settings';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import { convertRemoteUrlToFileType } from 'utils/bitCRM';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from 'utils/common/rich';
import {
  mapChannelIntegrationsToIntegrationSources,
  useCancel,
  useFlowChannel,
} from '../../hooks';
import useStore from '../../store';
import { TriggerMessageData } from '../../types';
import AddItem from '../AddItem';
import ShortcutAction, {
  CustomerTagsForm,
  TicketCategoryForm,
} from '../ShortcutAction';
import TrashWithTransition from '../TrashWithTransition';

import {
  BBBButton,
  BBBFileUpload,
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
  BBBTag,
  BBBTextInput,
} from '@/components/ui';
import { MaxCharType } from '@/components/ui/BBBTextInput/BBBTextInput';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { useForms } from '@/hooks/whatsApp/form';
import { FormTemplate } from '@/types/whatsApp/form';

type WhatsappForm =
  | Pick<FormTemplate, 'id' | 'metaId' | 'name'>
  | FormTemplate
  | null;

export type MessageForm = {
  fallback: SettingsFallback | null;
  message: EditorState;
  replies: {
    replyId: string;
    reply: string;
  }[];
  file: FileType | null;
  type: 'reply-btns' | 'option-list' | 'whatsapp-form' | 'reply' | null;
  optionButtonText: string;
  action: {
    customerTags: CustomerTagsForm;
    ticketCategory: TicketCategoryForm;
  };
  whatsappForm: WhatsappForm;
  whatsappFormText: string;
};

type TriggerMessageForm = {
  messageCondition: typeof messageConditions[number] | null;
  keywords: string[];
} & MessageForm;

export const messageSchema = {
  message: yup.mixed<EditorState>(),
  replies: yup
    .array()
    .of(
      yup.object().shape({
        replyId: yup.string(),
        reply: yup.string().label('Reply'),
      })
    )
    .test({
      test: (value, { parent }) => {
        if (parent.type === 'option-list') {
          return value ? value.some((reply) => reply.reply?.length) : false;
        }
        return true;
      },
      message: 'Add at least 1 option to continue',
    }),
  fallback: yup
    .mixed<SettingsFallback>()
    .required('Fallback cannot be empty')
    .label('Fallback'),
  file: yup.mixed<FileType | null>(),
  type: yup.mixed<TriggerMessageForm['type']>(),
  optionButtonText: yup.string().when('type', {
    is: (value: TriggerMessageForm['type']) => value === 'option-list',
    then: (rule) => rule.required('Please add button text'),
  }),
  whatsappForm: yup
    .mixed<WhatsappForm>()
    .label('Whatsapp form')
    .when('type', {
      is: (value: TriggerMessageForm['type']) => value === 'whatsapp-form',
      then: (rule) => rule.required('Please select WhatsApp form'),
    }),
  whatsappFormText: yup
    .string()
    .label('Whatsapp form text')
    .when('type', {
      is: (value: TriggerMessageForm['type']) => value === 'whatsapp-form',
      then: (rule) => rule.required('Please add button text'),
    }),
};

const triggerMessageSchema = yup.object({
  messageCondition: yup
    .mixed<typeof messageConditions[number] | null>()
    .label('Message condition')
    .required(),
  keywords: yup.array().label('Keywords').min(1),
  ...messageSchema,
});

const keywordOptions: { label: string; value: string }[] = [];

export const useMessage = () => {
  const source = useFlowChannel();
  const { data: defaultFallback } = useDefaultFallback();

  const isCloudApi = source === 'whatsapp_meta';

  const defaultValues = useMemo(
    () => ({
      message: convertHtmlToEditorState(''),
      replies: isCloudApi ? [] : [{ replyId: createId(), reply: '' }],
      fallback: defaultFallback || null,
      file: null,
      type: isCloudApi ? null : ('reply' as const),
      optionButtonText: '',
      whatsappForm: null,
    }),
    [defaultFallback, isCloudApi]
  );

  return {
    defaultValues,
    isCloudApi,
  };
};

export default function TriggerMessageModal({
  onSave,
  onClose,
  nodeId,
}: {
  onSave: (val: TriggerMessageData) => void;
  onClose: () => void;
  nodeId?: string;
}) {
  const { defaultValues } = useMessage();
  const handleCancel = useCancel(nodeId);
  const channel = useFlowChannel();

  const { mutate: checkKeywordAvailability } = useCheckKeywordAvailability();

  const triggerMessageDefaultValues = useMemo(
    () => ({
      keywords: [],
      messageCondition: null,
      action: {
        customerTags: null,
        ticketCategory: null,
      },
      ...defaultValues,
    }),
    [defaultValues]
  );

  const { data } = useSettings();

  const methods = useForm<TriggerMessageForm>({
    resolver: yupResolver(triggerMessageSchema),
    defaultValues: triggerMessageDefaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    clearErrors,
  } = methods;

  const nodeData = useStore<TriggerMessageData | null | undefined>(
    (s) => s.nodes.find((node) => node.id === nodeId)?.data
  );
  const childNodes = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === nodeId),
    shallow
  );

  const history = useHistory();

  useEffect(() => {
    if (nodeData) {
      reset({
        message:
          convertHtmlToEditorState(nodeData.message) ||
          triggerMessageDefaultValues.message,
        keywords: nodeData.keywords || triggerMessageDefaultValues.keywords,
        messageCondition:
          messageConditions.find(
            (condition) => condition.value === nodeData.action
          ) || triggerMessageDefaultValues.messageCondition,
        replies: childNodes.length
          ? childNodes.map((childNode) => ({
              replyId: childNode.id,
              reply: childNode.data.reply,
            }))
          : triggerMessageDefaultValues.replies,
        fallback: nodeData.fallback || triggerMessageDefaultValues.fallback,
        file: nodeData.media
          ? convertRemoteUrlToFileType(nodeData.media)
          : triggerMessageDefaultValues.file,
        optionButtonText:
          nodeData.optionButtonText ||
          triggerMessageDefaultValues.optionButtonText,
        type:
          typeof nodeData.type !== 'undefined'
            ? nodeData.type
            : childNodes.length
            ? 'reply'
            : triggerMessageDefaultValues.type,
        action: {
          customerTags: nodeData.customerTags,
          ticketCategory: nodeData.ticketCategory,
        },
        whatsappForm: nodeData.form || null,
        whatsappFormText: nodeData.formButtonText || '',
      });
    }
  }, [
    childNodes,
    nodeData,
    reset,
    triggerMessageDefaultValues.fallback,
    triggerMessageDefaultValues.file,
    triggerMessageDefaultValues.keywords,
    triggerMessageDefaultValues.message,
    triggerMessageDefaultValues.messageCondition,
    triggerMessageDefaultValues.optionButtonText,
    triggerMessageDefaultValues.replies,
    triggerMessageDefaultValues.type,
  ]);

  return (
    <BBBModal
      show
      title="Trigger"
      footer
      submitText="Save"
      cancelText="Discard"
      onHide={handleCancel}
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            message: convertEditorStateToHtml(data.message) || '',
            keywords: data.keywords,
            replies: data.replies
              .filter((reply) => !!reply.reply.length)
              .map((reply, index) => ({
                id: reply.replyId,
                reply: reply.reply,
                index,
              })),
            //@ts-ignore
            messageCondition: data.messageCondition!.value,
            fallback: data.fallback,
            media: data.file?.remoteUrl ? data.file.remoteUrl : null,
            type: data.type,
            optionButtonText:
              data.type === 'option-list' ? data.optionButtonText : undefined,
            customerTags: data.action.customerTags?.length
              ? data.action.customerTags?.map((tag) => ({
                  id: tag.id,
                  name: tag.name,
                }))
              : null,
            ticketCategory: data.action.ticketCategory
              ? {
                  label: data.action.ticketCategory.label,
                  id: data.action.ticketCategory.id,
                }
              : null,
            form: data.whatsappForm
              ? {
                  id: data.whatsappForm!.id,
                  metaId: data.whatsappForm!.metaId,
                  name: data.whatsappForm!.name,
                }
              : null,
            formButtonText: data.whatsappFormText,
          });
          onClose();
        })();
      }}
    >
      <FormProvider {...methods}>
        <Controller
          control={control}
          name="fallback"
          render={({ field }) => (
            <BBBSelect
              label="Fallback"
              options={data?.fallback}
              optionLabel="name"
              optionValue="id"
              containerClassName="mb-5"
              value={field.value}
              onValueChange={field.onChange}
              //@ts-ignore
              error={errors.fallback?.message}
              placeholder="Choose fallback"
              withCreateRedirectOption
              createRedirectLabel="Create new fallback"
              onClickCreateRedirect={() => {
                history.push(
                  `/bitchat/settings?section=chatbot&sub_section=fallback`
                );
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="messageCondition"
          render={({ field }) => (
            <BBBSelect
              label="If message"
              options={messageConditions}
              optionLabel="label"
              optionValue="value"
              containerClassName="mb-5"
              value={field.value}
              onValueChange={field.onChange}
              error={errors.messageCondition?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="keywords"
          render={({ field }) => (
            <BBBSelect
              options={keywordOptions}
              optionLabel="label"
              optionValue="value"
              isCreatable
              label="Input keyword"
              containerClassName="mb-5"
              placeholder="Input trigger keyword"
              onCreateOption={(val) => {
                return new Promise((resolve, reject) =>
                  checkKeywordAvailability(
                    {
                      keyword: val,
                      source:
                        mapChannelIntegrationsToIntegrationSources[channel!],
                      id: nodeId,
                    },
                    {
                      onSuccess: (data) => {
                        if (data) {
                          field.onChange([...field.value, val]);
                          clearErrors('keywords');
                          resolve();
                        } else {
                          reject();
                          setError('keywords', {
                            message: 'This keyword is exist in other chatbot',
                          });
                        }
                      },
                      onError: () => {
                        reject();
                      },
                    }
                  )
                );
              }}
              onBlur={() => {
                clearErrors('keywords');
              }}
              isDisabled={!channel}
              error={errors.keywords?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="keywords"
          render={({ field }) => (
            <>
              {!!field.value.length && (
                <div className="flex flex-wrap gap-1 mb-5">
                  {field.value.map((keyword) => (
                    <BBBTag
                      text={keyword}
                      key={keyword}
                      onDelete={() =>
                        field.onChange(
                          field.value.filter((_keyword) => _keyword !== keyword)
                        )
                      }
                    />
                  ))}
                </div>
              )}
            </>
          )}
        />

        <Controller
          control={control}
          name="action"
          render={({ field }) => (
            <ShortcutAction
              customerTags={field.value?.customerTags}
              ticketCategory={field.value?.ticketCategory}
              onChangeCustomerTags={(value) =>
                field.onChange(
                  field.value
                    ? {
                        ...field.value,
                        customerTags: value,
                      }
                    : undefined
                )
              }
              onChangeTicketCategory={(value) =>
                field.onChange(
                  field.value
                    ? {
                        ...field.value,
                        ticketCategory: value,
                      }
                    : undefined
                )
              }
              defaultCustomerTags={nodeData?.customerTags}
              defaultTicketCategory={nodeData?.ticketCategory}
            />
          )}
        />

        <Controller
          control={control}
          name="file"
          render={({ field }) => (
            <BBBFileUpload
              containerClassName="mb-5"
              isSingle
              files={field.value}
              onChangeFile={field.onChange}
              accept="*"
              withLabel
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <BBBRichTextEditor
              label="Message"
              placeholder="Input message that will sent to customer"
              rows={5}
              containerClassName="mb-5"
              editorState={field.value}
              onChangeEditorState={(val) => field.onChange(val)}
              error={errors.message?.message}
            />
          )}
        />
        <Replies control={control} />
      </FormProvider>
    </BBBModal>
  );
}

export function Replies({
  control,
}: {
  control: Control<TriggerMessageForm, any>;
}) {
  const type = useWatch({
    control,
    name: 'type',
  });

  return type === 'option-list' ||
    type === 'reply-btns' ||
    type === 'whatsapp-form' ||
    type === null ? (
    <DynamicType control={control} />
  ) : (
    <ReplyFields control={control} />
  );
}

const buttonOptions: { label: string; type: MessageForm['type'] }[] = [
  {
    label: 'Option list',
    type: 'option-list',
  },
  {
    label: 'Reply buttons',
    type: 'reply-btns',
  },
  {
    label: 'WhatsApp Form',
    type: 'whatsapp-form',
  },
];

export function DynamicType({
  control,
}: {
  control: Control<TriggerMessageForm>;
}) {
  const type = useWatch({
    control,
    name: 'type',
  });

  const { errors } = useFormState({ control });

  const { replace } = useFieldArray({
    control,
    name: 'replies',
  });

  const [showButtonOptions, setShowButtonOptions] = useState(false);

  const sheetsRef = useRef<HTMLDivElement | null>(null);

  useOutsideAlerter(sheetsRef, () => {
    setShowButtonOptions(false);
  });

  return (
    <>
      {!type && (
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <div className="relative">
              <BBBButton
                variant="secondary"
                onClick={() => setShowButtonOptions(true)}
              >
                <div className="flex items-center gap-2.5">
                  Add button <Plus />
                </div>
              </BBBButton>
              {showButtonOptions && (
                <div className="absolute bottom-full mb-1" ref={sheetsRef}>
                  <div className="w-60 rounded-lg shadow-md bg-white border-neutral-30 border overflow-hidden">
                    {buttonOptions.map((option) => (
                      <div
                        className="px-4 py-2 hover:bg-secondary-surface transition-colors cursor-pointer"
                        key={option.type}
                        onClick={() => {
                          field.onChange(option.type);

                          if (option.type === 'reply-btns') {
                            replace([{ replyId: createId(), reply: '' }]);
                          } else if (option.type === 'option-list') {
                            replace([{ replyId: createId(), reply: '' }]);
                          }

                          setShowButtonOptions(false);
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        />
      )}
      {type === 'reply-btns' && <ReplyFields control={control} />}
      {type === 'option-list' && (
        <>
          <div className="border border-neutral-30 rounded-lg p-3 mt-5">
            <div className="flex">
              <div className="grow text-primary-main  font-medium">
                Option list
              </div>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <TrashWithTransition onClick={() => field.onChange(null)} />
                )}
              />
            </div>
            <div className="my-2 border-t border-neutral-30" />
            <BBBTextInput
              label="Button text"
              placeholder="Option list button text"
              hasMaxCharLabel
              maxChar={24}
              containerClassname="w-2/3 mb-5"
              isHookForm
              control={control}
              controlName="optionButtonText"
              error={errors.optionButtonText?.message}
            />
            <ReplyFields control={control} />
          </div>
          {errors.replies?.message && (
            <div className="mt-2 text-red-500">{errors.replies.message}</div>
          )}
        </>
      )}
      {type === 'whatsapp-form' && <WhatsappFormType control={control} />}
    </>
  );
}

function WhatsappFormType({
  control,
}: {
  control: Control<TriggerMessageForm>;
}) {
  const { data } = useForms({ status: 'PUBLISHED' });
  const { errors } = useFormState({ control });
  const history = useHistory();

  const { setValue } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="whatsappForm"
        render={({ field }) => (
          <BBBSelect
            withCreateRedirectOption
            createRedirectLabel="Create new WhatsApp form"
            onClickCreateRedirect={() => {
              history.push(`/bitchat/whatsapp-form`);
            }}
            options={data}
            optionLabel="name"
            optionValue="id"
            placeholder="Select WhatsApp form"
            label="Form"
            containerClassName="mb-5"
            value={field.value}
            onValueChange={field.onChange}
            error={errors.whatsappForm?.message}
          />
        )}
      />
      <BBBTextInput
        label="Button text"
        placeholder="Input button text"
        containerClassname="mb-5"
        isHookForm
        control={control}
        controlName="whatsappFormText"
        error={errors.whatsappFormText?.message}
      />
      <BBBButton
        variant="secondary"
        size="sm"
        onClick={() => {
          setValue('type', null);
          setValue('whatsappForm', null);
          setValue('whatsappFormText', '');
        }}
      >
        Remove WhatsApp form
      </BBBButton>
    </>
  );
}

export function ReplyFields({
  control,
}: {
  control: Control<TriggerMessageForm>;
}) {
  const {
    fields: replyFields,
    append: appendReply,
    remove,
  } = useFieldArray({
    control,
    name: 'replies',
  });

  const { errors } = useFormState({ control });

  const source = useFlowChannel();

  return (
    <Controller
      control={control}
      name="type"
      render={({ field: typeField }) => (
        <>
          {replyFields.map(({ id }, i) => (
            <Controller
              control={control}
              name={`replies.${i}.reply`}
              render={({ field }) => (
                <ReplyInput
                  value={field.value}
                  onValueChange={field.onChange}
                  onRemove={() => {
                    if (
                      (typeField.value === 'reply-btns' ||
                        source === 'whatsapp_meta') &&
                      replyFields.length === 1 &&
                      i === 0
                    ) {
                      typeField.onChange(null);
                      remove(i);
                    } else {
                      remove(i);
                    }
                  }}
                  error={errors.replies?.[i]?.reply?.message}
                  placeholder={
                    typeField.value === 'option-list'
                      ? `Option ${i + 1}`
                      : typeField.value === 'reply-btns'
                      ? 'Input reply button'
                      : 'Input reply'
                  }
                  hasMaxCharLabel={
                    typeField.value == 'reply' ? undefined : true
                  }
                  maxChar={
                    typeField.value === 'reply-btns'
                      ? 20
                      : typeField.value === 'option-list'
                      ? 24
                      : 0
                  }
                  className=""
                />
              )}
              key={id}
            />
          ))}
          {(typeField.value === 'option-list'
            ? replyFields.length < 10
            : true) && (
            <AddItem
              onClick={() => {
                appendReply({ replyId: createId(), reply: '' });
              }}
              label={
                typeField.value === 'option-list'
                  ? 'Add option'
                  : typeField.value === 'reply-btns'
                  ? 'Add a reply button'
                  : 'Add a reply'
              }
              disabled={
                typeField.value === 'reply-btns' && replyFields.length === 3
              }
            />
          )}
        </>
      )}
    />
  );
}

function ReplyInput({
  value,
  onValueChange,
  onRemove,
  error,
  placeholder,
  className,
  iconSize,
  ...props
}: {
  value: string;
  onValueChange: (val: string) => void;
  onRemove: () => void;
  error?: string;
  placeholder?: string;
  className?: string;
  iconSize?: string | number;
} & MaxCharType) {
  return (
    <div className={cx('flex items-center gap-2 mb-5 last:mb-0', className)}>
      <BBBTextInput
        placeholder={placeholder || 'Input reply'}
        containerClassname="w-full grow mb-0"
        value={value}
        isHookForm={false}
        onChange={({ target: { value } }) => onValueChange(value)}
        error={error}
        {...props}
      />
      <TrashWithTransition onClick={onRemove} size={iconSize} />
    </div>
  );
}
