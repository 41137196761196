import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';

const useCustomerMonths = (customerId: string) => {
  return useQuery(['customers-months', customerId], () =>
    services.customer.customer.getCustomerMonths(customerId)
  );
};

export default useCustomerMonths;
