import { ReactNode } from 'react';

import { PricingPlan } from '@/api/services/pricing';
import {
  isSameOrAbovePricingTier,
  standardPlanDeprecation,
} from '@/constants/pricing';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setPricingAlert } from '@/stores/common';
import { AppType } from '@/types/systemLogin';

type Props = {
  appType: Exclude<AppType, 'BITAI'>;
  desiredPlan: PricingPlan;
  children: ({
    validatePricing,
  }: {
    validatePricing: (cb: () => void) => void;
  }) => ReactNode;
  type?: 'prompt' | 'redirect';
};

function PricingAwareWrapper({ appType, children, desiredPlan, type }: Props) {
  const { data: pricingData } = usePricingByApp(appType);
  const dispatch = useAppDispatch();

  const validatePricing = (cb: () => void) => {
    if (
      pricingData?.pricingName &&
      isSameOrAbovePricingTier(
        standardPlanDeprecation(pricingData.pricingName),
        desiredPlan
      )
    ) {
      cb();
    } else {
      dispatch(
        setPricingAlert({
          show: true,
          appType,
          desiredPlan,
          type,
          onSuccess: cb,
        })
      );
    }
  };

  return <>{children({ validatePricing })}</>;
}

export default PricingAwareWrapper;
