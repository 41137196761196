function UserIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        stroke="#fff"
        d="M5 5.5c1.265 0 2.29-1.007 2.29-2.25S6.265 1 5 1C3.736 1 2.71 2.007 2.71 3.25S3.737 5.5 5 5.5z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.29 6.4h.161c.335 0 .658.12.909.338.25.218.412.518.454.844l.179 1.406a.885.885 0 01-.222.708.915.915 0 01-.687.304H1.916a.93.93 0 01-.687-.304.896.896 0 01-.222-.708l.179-1.405c.041-.327.203-.628.454-.845.251-.218.574-.338.91-.338h.16"
      ></path>
    </svg>
  );
}

export default UserIcon2;
