import { Control, Controller, useFormState } from 'react-hook-form';
import { FormSchema } from '..';

import InfoIcon from '@/assets/icons/InfoIcon';
import NewBadge from '@/components/Badge/NewBadge';
import {
  BBBCard,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
  BBBTooltip,
} from '@/components/ui';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setPreviewPowerMessage } from '@/stores/bitCRM';
import { PowerMessageDisplayFrequency } from '@/types/bitChat/waWidget';
import { cn } from '@/utils/styles';

const displayFrequencies: {
  label: string;
  value: PowerMessageDisplayFrequency;
}[] = [
  { label: 'On each page load', value: 'every_page_load' },
  { label: 'Every hour', value: 'every_hour' },
  { label: 'Every 3 hours', value: 'every_3_hours' },
  { label: 'Every 6 hours', value: 'every_6_hours' },
  { label: 'Every 12 hours', value: 'every_12_hours' },
  { label: 'Every 24 hours', value: 'every_24_hours' },
];

const displayFrequenciesMap = Object.fromEntries(
  displayFrequencies.map((section) => [section.value, section])
);

function PowerMessage({ control }: { control: Control<FormSchema> }) {
  const { errors } = useFormState({ control });

  const dispatch = useAppDispatch();

  return (
    <BBBCard
      className="mb-5"
      title={
        <>
          Power message <NewBadge />
        </>
      }
      desc={
        <>
          Capture attention instantly with customizable, timed messages above
          your chat widget.{' '}
          <span
            className="text-link underline font-bold cursor-pointer"
            onClick={() => {
              dispatch(setPreviewPowerMessage(true));
            }}
          >
            Preview
          </span>
        </>
      }
      rightButton={
        <Controller
          control={control}
          name="powerMsgEnabled"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      }
    >
      <BBBTextInput
        label="Header"
        placeholder="Input header"
        hasMaxCharLabel
        maxChar={20}
        isHookForm
        control={control}
        controlName="powerMsgHeader"
        error={errors.powerMsgHeader?.message}
      />
      <BBBTextInput
        label="Description"
        placeholder="Input description"
        hasMaxCharLabel
        maxChar={60}
        isHookForm
        control={control}
        controlName="powerMsgDescription"
      />
      <div className="flex gap-2 mb-3">
        <div className="flex-1">
          <Controller
            control={control}
            name="powerMsgDisplayDelay"
            render={({ field }) => (
              <BBBTextInput
                label="Display after (in seconds)"
                placeholder="Input display after delay"
                value={field.value}
                onChange={({ target: { value } }) => {
                  const numericValue = value.replace(/[^0-9]/g, '');
                  field.onChange(numericValue);
                }}
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            control={control}
            name="powerMsgDisplayFrequency"
            render={({ field }) => (
              <BBBSelect
                options={displayFrequencies}
                optionLabel="label"
                optionValue="value"
                label={
                  <div className="flex items-center gap-1">
                    Display frequency
                    <BBBTooltip
                      content="Power message will appear every (n) hours to each visitor"
                      show
                    >
                      <InfoIcon />
                    </BBBTooltip>
                  </div>
                }
                placeholder="Choose display frequency"
                value={field.value ? displayFrequenciesMap[field.value] : null}
                onValueChange={(opt) => field.onChange(opt!.value)}
              />
            )}
          />
        </div>
      </div>
      <div>
        <div className="mb-2.5">Power message color</div>
        <Controller
          control={control}
          name="powerMsgColor"
          render={({ field }) => (
            <div className="px-5 py-6 border border-neutral-30 rounded-lg flex gap-6">
              <div className="flex-1">
                <div
                  className={cn(
                    'cursor-pointer rounded-lg border-[1.5px] border-[#7E7E7E] h-[2.75rem] flex items-center justify-center',
                    field.value === 'default' && 'outline outline-offset-2'
                  )}
                  onClick={() => field.onChange('default')}
                >
                  Default
                </div>
              </div>
              <div className="flex-1">
                <div
                  className={cn(
                    'cursor-pointer rounded-lg h-[2.75rem] flex items-center justify-center shadow-md',
                    field.value === 'no_outline' && 'outline outline-offset-2'
                  )}
                  onClick={() => field.onChange('no_outline')}
                >
                  No outline (shadow)
                </div>
              </div>
              <div className="flex-1">
                <div
                  className={cn(
                    'cursor-pointer rounded-lg h-[2.75rem] flex items-center justify-center border-dashed border-neutral-40 border-[1.5px]',
                    field.value === 'match' && 'outline outline-offset-2'
                  )}
                  onClick={() => field.onChange('match')}
                >
                  Match with widget button
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </BBBCard>
  );
}

export default PowerMessage;
