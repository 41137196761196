import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppDispatch } from '@/hooks/rtk/store';
import { updateUser } from '@/stores/auth';
import { UserWithAssociation } from '@/types/systemLogin/association';
import { toast } from '@/utils/common/toast';

type UpdateUserProfileReqBody = {
  lastName?: string | undefined;
  profilePicture?: string | undefined | null;
  email: string;
  firstName: string;
  phoneNumber?: string | null;
};

const useUpdateUserProfile = () => {
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation<
    UserWithAssociation,
    unknown,
    Partial<UpdateUserProfileReqBody>
  >(
    (payload) =>
      api.systemLogin
        .put(`/user/profile`, payload)
        .then((res) => res.data.data),
    {
      onSuccess: (data) => {
        toast.success('Profile updated');
        client.invalidateQueries(['authenticated-user']);
        dispatch(updateUser(data));
      },
    }
  );
};

export default useUpdateUserProfile;
