import { lazy, Suspense } from 'react';

import { LocationMessage, MessageNewAssociation } from '@/types/whatsApp/v3';

type ChatBubbleLocationProps = {
  chat: MessageNewAssociation;
  isQuotedLevel?: boolean;
};

const MapView = lazy(() => import('./MapView'));

export default function ChatBubbleLocation({ chat }: ChatBubbleLocationProps) {
  const locationData = chat.locationMsg!;

  return (
    <>
      {chat.type === 'LIVE_LOCATION' ? (
        <Map locationData={locationData} type={chat.type} />
      ) : (
        <a
          href={`http://maps.google.com/maps?q=${locationData.locationLat},${locationData.locationLng}`}
          className="mb-3"
          target="_blank"
          rel="noreferrer"
        >
          <Map locationData={locationData} type={chat.type} />
        </a>
      )}

      {chat.type === 'LIVE_LOCATION' && (
        <div className="text-neutral-40 italic text-sm">
          Live location not available, please check your phone
        </div>
      )}
    </>
  );
}

function Map({
  locationData,
  type,
}: {
  locationData: LocationMessage;
  type: string;
}) {
  return (
    <Suspense fallback="loading map view...">
      <MapView {...locationData} isLiveLocation={type === 'live_location'} />
    </Suspense>
  );
}
