import { useDispatch } from 'react-redux';
import { isProd } from 'config/env';
import { infoPanelMenu } from 'constants/bitChat/info-panel';
import { useAppSelector } from 'hooks/rtk/store';
import { setSelectedInfoPanel } from 'stores/bitCRM';
import { twMerge as cx } from 'tailwind-merge';

import { BBBTooltip } from '@/components/ui';

type Props = {
  className?: string;
  onExpand?: () => void;
  isExpanded?: boolean;
};

export default function MenuPanel({ className, onExpand, isExpanded }: Props) {
  const activeInfoPanel = useAppSelector((s) => s.bitCRM.selectedInfoPanel);

  const dispatch = useDispatch();

  return (
    <div
      className={cx(
        'flex-none w-fit mx-auto max-w-[4.1rem] grow bg-neutral-10 border border-neutral-30 rounded-2xl shadow-[0px_3px_6px_0px_#9B9B9B26] px-2 py-4 flex flex-col justify-between m-2.5',
        className
      )}
      id="info-panel"
    >
      <div className="flex flex-col gap-4">
        {infoPanelMenu
          ?.filter((menu) => (isProd ? menu.name !== 'sandbox' : true))
          .map((menu) => (
            <BBBTooltip
              key={menu.name}
              show
              content={menu.title}
              offset={{
                top: 10,
              }}
            >
              <div
                onClick={() => {
                  if (isExpanded) onExpand?.();
                  dispatch(setSelectedInfoPanel(menu.name));
                }}
                className={cx(
                  'p-2 border-[1.5px] border-transparent rounded-lg hover:bg-neutral-30 transition-all hover:text-neutral-50 duration-300 cursor-pointer',
                  activeInfoPanel === menu.name
                    ? 'border-secondary-main rounded-lg shadow-[0px_0px_0px_1.5px_#FFE6D8]'
                    : ''
                )}
              >
                <div
                  className={cx(
                    'w-fit mx-auto',
                    activeInfoPanel !== menu.name ? 'opacity-50' : ''
                  )}
                >
                  {menu.icon}
                </div>
              </div>
            </BBBTooltip>
          ))}
      </div>
      <div
        className={cx(
          'w-fit mx-auto p-2.5 mb-8 cursor-pointer transform transition-transform',
          isExpanded && 'rotate-180'
        )}
        onClick={onExpand}
      >
        <Icon />
      </div>
    </div>
  );
}

function Icon({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0.970239 0.579046C0.821175 0.726344 0.702913 0.901305 0.622224 1.09391C0.541534 1.28652 0.5 1.493 0.5 1.70152C0.5 1.91005 0.541534 2.11652 0.622224 2.30913C0.702913 2.50174 0.821175 2.6767 0.970239 2.824L7.20912 9.00161L0.970239 15.1792C0.669586 15.4769 0.500681 15.8807 0.500681 16.3017C0.500681 16.7227 0.669586 17.1265 0.970239 17.4242C1.27089 17.7219 1.67866 17.8891 2.10385 17.8891C2.52904 17.8891 2.93681 17.7219 3.23746 17.4242L10.618 10.1161C10.7671 9.96882 10.8853 9.79386 10.966 9.60125C11.0467 9.40864 11.0882 9.20217 11.0882 8.99364C11.0882 8.78512 11.0467 8.57864 10.966 8.38603C10.8853 8.19343 10.7671 8.01846 10.618 7.87117L3.23746 0.563124C2.62644 -0.0418986 1.59734 -0.0418983 0.970239 0.579046Z"
        fill="#FD823E"
      />
      <path
        d="M10.3823 0.579046C10.2333 0.726344 10.115 0.901305 10.0343 1.09391C9.95364 1.28652 9.91211 1.493 9.91211 1.70152C9.91211 1.91005 9.95364 2.11652 10.0343 2.30913C10.115 2.50174 10.2333 2.6767 10.3823 2.824L16.6212 9.00161L10.3823 15.1792C10.0817 15.4769 9.91279 15.8807 9.91279 16.3017C9.91279 16.7227 10.0817 17.1265 10.3823 17.4242C10.683 17.7219 11.0908 17.8891 11.516 17.8891C11.9411 17.8891 12.3489 17.7219 12.6496 17.4242L20.0301 10.1161C20.1792 9.96882 20.2974 9.79386 20.3781 9.60125C20.4588 9.40864 20.5003 9.20217 20.5003 8.99364C20.5003 8.78512 20.4588 8.57864 20.3781 8.38603C20.2974 8.19343 20.1792 8.01846 20.0301 7.87117L12.6496 0.563124C12.0385 -0.0418986 11.0095 -0.0418983 10.3823 0.579046Z"
        fill="#FD823E"
      />
    </svg>
  );
}
