import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setActiveCompany, setCompany, setUserCompany } from '@/stores/auth';
import { Company } from '@/types/systemLogin';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { toast } from '@/utils/common/toast';

const useDeleteCompany = () => {
  const client = useQueryClient();
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const dispatch = useAppDispatch();

  return useMutation(
    () =>
      api.systemLogin
        .delete<ApiResponse<Company | UserCompanyWithAssociation[]>>(
          `/company/${activeCompany}`,
          {
            data: { userId },
          }
        )
        .then((res) => res.data.data),
    {
      onSuccess: (data) => {
        toast.success(`Company has been deleted`);

        if (Array.isArray(data)) {
          if (data.length) {
            const userCompany = data[0];
            const newCompany = userCompany.company;
            localStorage.setItem('activeCompany', newCompany.id.toString());
            dispatch(setActiveCompany(newCompany.id));
            dispatch(setCompany(newCompany));
            dispatch(setUserCompany(userCompany));
          } else {
            dispatch(setActiveCompany(undefined));
            dispatch(setCompany(null));
            dispatch(setUserCompany(null));
            localStorage.removeItem('activeCompany');
          }
        }

        client.invalidateQueries(['user-companies']);
        client.invalidateQueries(['authenticated-user-companies']);
        client.invalidateQueries(['authenticated-company']);
      },
    }
  );
};
export default useDeleteCompany;
