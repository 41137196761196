import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import { useCancel } from '../../hooks';
import useStore from '../../store';
import { TriggerAIData } from '../../types';
import AddItem from '../AddItem';
import AutoPilotInfo from '../AutoPilotInfo';
import TrashWithTransition from '../TrashWithTransition';

import { BBBModal, BBBSelect, BBBTextInput } from '@/components/ui';
import { aiTriggerConditions } from '@/constants/bitChat/flows';

const triggerAiSchema = yup.object({
  conditions: yup.array().of(
    yup.object().shape({
      conditionId: yup.string().required(),
      value: yup
        .mixed<typeof aiTriggerConditions[number] | null>()
        .required()
        .label('Condition'),
    })
  ),
  customConditions: yup.array().of(
    yup.object().shape({
      conditionId: yup.string().required(),
      value: yup.string().required().label('Custom condition'),
    })
  ),
});

export default function TriggerAIModal({
  onSave,
  nodeId,
}: {
  onSave: (params: TriggerAIData) => void;
  nodeId?: string;
}) {
  const handleCancel = useCancel(nodeId);

  const childNodes = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === nodeId),
    shallow
  );

  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    watch,
  } = useForm<{
    conditions: {
      conditionId: string;
      value: typeof aiTriggerConditions[number] | null;
    }[];
    customConditions: {
      conditionId: string;
      value: string;
    }[];
  }>({
    resolver: yupResolver(triggerAiSchema),
    defaultValues: {
      conditions: [{ conditionId: createId(), value: null }],
      customConditions: [{ conditionId: createId(), value: '' }],
    },
  });

  const { fields: conditionFields, append: appendCondition } = useFieldArray({
    control,
    name: 'conditions',
  });

  const {
    fields: customConditionFields,
    append: appendCustomCondition,
    remove: removeCustomCondition,
  } = useFieldArray({
    control,
    name: 'customConditions',
  });

  const history = useHistory();

  useEffect(() => {
    if (childNodes.length) {
      const _conditions = childNodes
        .filter((childNode) => 'condition' in childNode.data)
        .map((childNode) => {
          return {
            id: childNode.id,
            value:
              aiTriggerConditions.find(
                (aiCondition) => aiCondition.value === childNode.data.condition
              ) || null,
          };
        });

      const _customConditions = childNodes
        .filter((childNode) => 'customCondition' in childNode.data)
        .map((childNode) => ({
          id: childNode.id,
          value: childNode.data.customCondition,
        }));

      reset({
        conditions: _conditions.length
          ? _conditions
          : [{ conditionId: createId(), value: null }],
        customConditions: _customConditions.length
          ? _customConditions
          : [{ conditionId: createId(), value: '' }],
      });
    }
  }, [childNodes, reset]);

  return (
    <BBBModal
      show
      title="Trigger"
      footer
      submitText="Save"
      cancelText="Discard"
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            conditions: data.conditions
              .filter((condition) => !!condition.value)
              .map((condition) => ({
                id: condition.conditionId,
                value: condition.value!.value,
              })),
            customConditions: data.customConditions
              .filter(Boolean)
              .map((condition) => ({
                ...condition,
                id: condition.conditionId,
              })),
          });
          onChangeTriggerModalState(null);
        })();
      }}
      onHide={handleCancel}
    >
      <AutoPilotInfo />
      {conditionFields.map((condition, i) => (
        <Controller
          control={control}
          name={`conditions.${i}.value`}
          key={condition.id}
          render={({ field }) => (
            <BBBSelect
              placeholder="Choose condition"
              options={aiTriggerConditions}
              optionLabel="label"
              optionValue="value"
              containerClassName="mb-5"
              value={field.value}
              onValueChange={field.onChange}
              error={errors.conditions?.[i]?.value?.message}
            />
          )}
        />
      ))}
      <AddItem
        label="Add condition"
        className="mb-5"
        onClick={() => {
          const conditions = watch('conditions');
          if (!conditions[conditions.length - 1].value) {
            setError(`conditions.${conditions.length - 1}.value`, {
              message: 'Choose this condition before adding new one',
            });
          } else {
            appendCondition({ conditionId: createId(), value: null });
          }
        }}
      />
      <div className="mb-5">Custom condition</div>
      {customConditionFields.map((customCondition, i) => (
        <div className="flex items-center gap-2 mb-5" key={customCondition.id}>
          <BBBTextInput
            placeholder="Type your custom condition"
            containerClassname="mb-0 grow"
            control={control}
            isHookForm
            controlName={`customConditions.${i}.value`}
            error={errors.customConditions?.[i]?.value?.message}
          />
          <TrashWithTransition onClick={() => removeCustomCondition(i)} />
        </div>
      ))}
      <AddItem
        label="Add custom condition"
        className="mb-5"
        onClick={() => {
          const customConditions = watch('customConditions');
          if (!customConditions[customConditions.length - 1].value) {
            setError(`customConditions.${customConditions.length - 1}.value`, {
              message: 'Create this condition before adding new one',
            });
          } else {
            appendCustomCondition({ conditionId: createId(), value: '' });
          }
        }}
      />
    </BBBModal>
  );
}
