import { twMerge as cx } from 'tailwind-merge';

import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { RoleTypes } from '@/types/systemLogin';

type RoleBadgeProps = {
  role?: RoleTypes;
};

export const mapRoleToBadgeProps: Record<
  RoleTypes,
  { text: string; className?: string }
> = {
  SUPER_ADMIN: {
    text: 'Super admin',
    className: 'bg-[#E2CBFF]',
  },
  STAFF: {
    text: 'Regular user',
    className: 'bg-[#A2D4FF]',
  },
  ADMIN: {
    text: 'Admin',
    className: 'bg-[#B6F0E5]',
  },
  AGENT: {
    text: 'Agent',
    className: 'bg-secondary-border',
  },
  STAFF_AGENT: {
    text: 'Regular user',
    className: 'bg-[#A2D4FF]',
  },
};

function RoleBadge({ role }: RoleBadgeProps) {
  if (!role) return null;

  return (
    <BBBBadge
      {...mapRoleToBadgeProps[role]}
      className={cx(
        'border-none px-4 py-2',
        mapRoleToBadgeProps[role].className
      )}
    />
  );
}

export default RoleBadge;
