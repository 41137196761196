import { yupResolver } from '@hookform/resolvers/yup';
import { useVerifyStatusLogin } from 'hooks/bitLogin/login/whatsapp/realtime';
import {
  useQRWhatsAppLogin,
  useRegistrationWhatsApp,
} from 'hooks/bitLogin/login/whatsapp/whatsapp';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import useCustomForm from 'hooks/common/useCustomForm';
import * as yup from 'yup';

import { BBBButton, BBBSpinner, BBBTextInput } from '@/components/ui';

export default function WhatsAppLoginDesktopFlow() {
  const query = useQuerySearchParams();
  const code = query.get('code');
  const domainStore = query.get('domain');

  if (!code || !domainStore) throw new Error('Invalid code');

  const { data: qrCodeData, status: qrCodeStatus } = useQRWhatsAppLogin(
    domainStore,
    code
  );

  const verifyStatus = useVerifyStatusLogin(code, domainStore);

  return (
    <div className="h-screen flex flex-col justify-center z-50 items-center">
      <div className="mx-auto text-center p-auto">
        {typeof verifyStatus?.status === 'undefined' ? (
          <BBBSpinner />
        ) : verifyStatus.status === 0 ? (
          <>
            <p className="text-center text-2xl text-neutral-60 mb-10">
              Login to <span>{verifyStatus.shopName || domainStore}</span>
            </p>
            {qrCodeStatus === 'loading' ? (
              <BBBSpinner />
            ) : qrCodeStatus === 'error' ? (
              <>Error retrieving qr code. Please retry in a moment</>
            ) : (
              <img
                alt="QR"
                src={qrCodeData.qrCode}
                className="w-[230px] h-[228px] object-contain mb-8 mx-auto"
              />
            )}
            <p className="text-base text-neutral-50">
              Scan this QR from your phone camera
            </p>
          </>
        ) : verifyStatus.status === 1 ? (
          <>
            <p className="text-center text-2xl text-neutral-60 mb-10">
              Opening WhatsApp
            </p>
            <div className="mb-8">
              <BBBSpinner width={14} height={89} />
            </div>
            <p className="text-base text-neutral-50">
              Send the WhatsApp message on your phone to bitChat
            </p>
          </>
        ) : verifyStatus.status === 2 ? (
          <>
            <p className="text-center text-2xl text-neutral-60 mb-10">
              Verifying
            </p>
            <div className="mb-8">
              <BBBSpinner width={14} height={89} />
            </div>
            <p className="text-base text-neutral-50">
              Click link you received from bitChat{' '}
            </p>
          </>
        ) : verifyStatus.status === 3 ? (
          <>
            <p className="text-center text-2xl text-neutral-60 mb-10">
              Welcome to <span>{verifyStatus.shopName}!</span>
            </p>
            <RegisterForm domain={domainStore} code={code} />
          </>
        ) : null}
      </div>
    </div>
  );
}

const registerFormSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
});

type BitLoginRegistration = {
  name: string;
  email: string;
};

function RegisterForm({ domain, code }: { domain: string; code: string }) {
  const { mutate: register, isLoading } = useRegistrationWhatsApp();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomForm<BitLoginRegistration>({
    resolver: yupResolver(registerFormSchema),
    defaultValues: {
      name: '',
      email: '',
    },
  });

  const onSubmit = (data: BitLoginRegistration) =>
    register({
      email: data.email,
      code,
      shop: domain,
      displayName: data.name,
    });

  return (
    <>
      <div className="mb-10 flex flex-col gap-4">
        <BBBTextInput
          labelClassname="text-start"
          label="What is your name?"
          placeholder="Enter name"
          isHookForm
          control={control}
          controlName="name"
          error={errors.name?.message}
        />
        <BBBTextInput
          labelClassname="text-start"
          label="What is your email?"
          placeholder="Enter email"
          isHookForm
          type="email"
          control={control}
          controlName="email"
          error={errors.email?.message}
        />
      </div>
      <BBBButton
        width="full"
        text="Sign up"
        onClick={() => handleSubmit(onSubmit)()}
        loadingState={isLoading}
        disabled={isLoading}
      />
    </>
  );
}
