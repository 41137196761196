import React from 'react';

type Props = {
  className?: string;
  color?: string;
} & React.SVGProps<SVGSVGElement>;

function StarIcon3({ className, color = '#9a9a9a', ...props }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_11243_5963)">
        <path
          d="M15.929 5.99877C15.8435 5.72546 15.6816 5.48458 15.4639 5.3066C15.2461 5.12862 14.9823 5.02154 14.7058 4.99893L10.8037 4.67256L9.29771 0.901036C9.19188 0.634479 9.01194 0.406512 8.78076 0.246082C8.54958 0.0856531 8.27758 0 7.99931 0C7.72103 0 7.44903 0.0856531 7.21785 0.246082C6.98667 0.406512 6.80674 0.634479 6.7009 0.901036L5.19632 4.67327L1.29421 4.99893C1.01717 5.0226 0.75306 5.13054 0.535074 5.30921C0.317088 5.48787 0.15495 5.72927 0.0690365 6.00309C-0.0168775 6.2769 -0.0227347 6.57091 0.052201 6.84817C0.127137 7.12542 0.279522 7.37355 0.490207 7.56137L3.46764 10.2209L2.55745 14.1989C2.49107 14.4793 2.50677 14.7739 2.60256 15.0451C2.69836 15.3163 2.86991 15.5518 3.09537 15.7217C3.32084 15.8915 3.59001 15.9881 3.86862 15.999C4.14723 16.0099 4.42267 15.9346 4.65986 15.7829L7.99931 13.6539L11.3388 15.7829C11.5759 15.9336 11.8509 16.0081 12.1289 15.9969C12.4069 15.9857 12.6755 15.8893 12.9006 15.7199C13.1257 15.5506 13.2972 15.3158 13.3935 15.0454C13.4897 14.775 13.5063 14.4812 13.4412 14.201L12.531 10.2231L15.5084 7.56351C15.7204 7.37523 15.8735 7.12597 15.9484 6.84737C16.0233 6.56877 16.0165 6.2734 15.929 5.99877ZM11.0698 9.26252C10.955 9.36504 10.8696 9.49825 10.8229 9.64762C10.7762 9.79698 10.77 9.95677 10.805 10.1095L11.6939 13.9975L8.43303 11.9207C8.3026 11.8375 8.15246 11.7935 7.99931 11.7935C7.84616 11.7935 7.69601 11.8375 7.56559 11.9207L4.30475 13.9975L5.19357 10.1124C5.22857 9.95962 5.22239 9.79984 5.1757 9.65047C5.12901 9.5011 5.04359 9.36789 4.92878 9.26538L2.01409 6.65866L5.83415 6.33943C5.98617 6.32678 6.13185 6.27086 6.25512 6.17785C6.37838 6.08484 6.47443 5.95836 6.53265 5.81237L7.99931 2.1344L9.46596 5.81237C9.52419 5.95836 9.62023 6.08484 9.7435 6.17785C9.86676 6.27086 10.0124 6.32678 10.1645 6.33943L13.9845 6.65866L11.0698 9.26252Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11243_5963">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarIcon3;
