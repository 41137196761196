import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { DeepPartial } from '@/types/utils/deepPartial';
import { Settings } from '@/types/whatsApp/settings';

export default {
  getSettings: (companyId: number) =>
    api.whatsApp.v1
      .get<ApiResponse<Settings>>(`/settings/${companyId}`)
      .then((res) => res.data.data),
  updateSettings: (companyId: number, payload: DeepPartial<Settings>) =>
    api.whatsApp.v1
      .post<Partial<Settings>>(`/settings/${companyId}`, payload)
      .then((res) => res.data),
  deleteAiTag: (companyId: number, aiTagId: string) =>
    api.bitChat.delete(`/settings/${companyId}/ai-tag/${aiTagId}`),
};
