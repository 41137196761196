import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import {
  CommonCountryQueryParams,
  StateCitiesParams,
} from '@/api/services/countries';

export const QUERY_KEY = 'country';

export const useCountries = (params?: {
  enabled: boolean;
  country?: string;
}) => {
  return useQuery([QUERY_KEY], services.countries.getCountriesAndFlagUrl, {
    enabled: params?.enabled,
  });
};

export const useCountryStates = (params: Partial<CommonCountryQueryParams>) => {
  return useQuery(
    [`${QUERY_KEY}-states`, params],
    () =>
      services.countries.getStateByCountryName(
        params as CommonCountryQueryParams
      ),
    { enabled: !!params.country }
  );
};

export const useStateCities = (params: Partial<StateCitiesParams>) => {
  return useQuery(
    [`${QUERY_KEY}-cities`, params],
    () => services.countries.getCityByState(params as StateCitiesParams),
    { enabled: !!(params.country && params.state) }
  );
};
