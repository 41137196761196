import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import optionsDateFilter from '@/constants/analytics/common/optionsDateFilter';
import { selectedDate } from '@/types/datePicker';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(customParseFormat);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const getRangeValue = (startDate: selectedDate, endDate: selectedDate) => {
  if (!startDate || !endDate)
    return optionsDateFilter.find((opt) => opt.value === 'custom');

  const lastMonth = dayjs(startDate).subtract(1, 'month').toDate();
  const lastMonthStartDate = dayjs(lastMonth).startOf('month').toDate();
  const lastMonthEndDate = dayjs(lastMonth).endOf('month').toDate();

  const lastYear = dayjs(startDate).subtract(1, 'year').toDate();
  const lastYearStartDate = dayjs(lastYear).startOf('year').toDate();
  const lastYearEndDate = dayjs(lastYear).endOf('year').toDate();

  const diffLastMonth = dayjs(lastMonthEndDate).diff(
    dayjs(lastMonthStartDate),
    'day'
  );
  const diffLastYear = dayjs(lastYearEndDate).diff(
    dayjs(lastYearStartDate),
    'day'
  );

  const diff = dayjs(endDate).diff(dayjs(startDate), 'day');

  const isCurrentDateEqual =
    endDate.getDate() === dayjs().subtract(1, 'day').date();

  return optionsDateFilter.find((opt) => {
    if (
      dayjs(startDate).isSame(dayjs().startOf('day')) &&
      dayjs(endDate).isSame(dayjs().endOf('day'))
    ) {
      return opt.value === 'today';
    }
    if (Math.abs(diff) === 0 && isCurrentDateEqual) {
      return opt.value === 'yesterday';
    }
    if (Math.abs(diff) === 6 && isCurrentDateEqual) {
      return opt.value === 'week';
    }
    if (Math.abs(diff) === 29 && isCurrentDateEqual) {
      return opt.value === '30-days';
    }
    if (Math.abs(diff) === 89 && isCurrentDateEqual) {
      return opt.value === '90-days';
    }
    if (Math.abs(diff) === 364 && isCurrentDateEqual) {
      return opt.value === '365-days';
    }
    if (Math.abs(diff) === 393) {
      return opt.value === '12-months';
    }
    if (Math.abs(diff) === diffLastYear) {
      return opt.value === 'year';
    }
    if (Math.abs(diff) === diffLastMonth) {
      return opt.value === 'month';
    }
    return opt.value === 'custom';
  });
};

export function formatDate2(
  _date: string | number | Date | undefined | null,
  //@ts-ignore
  clockType: '12-hour' | '24-hour' = '12-hour',
  //@ts-ignore
  format: 'regular' | 'chat' = 'regular'
): undefined;
export function formatDate2(
  _date: string | number | Date,
  //@ts-ignore
  clockType: '12-hour' | '24-hour' = '12-hour',
  //@ts-ignore
  format: 'regular' | 'chat' = 'regular'
): string;
export function formatDate2(
  _date: string | number | Date | undefined | null,
  clockType: '12-hour' | '24-hour' = '12-hour',
  format: 'regular' | 'chat' = 'regular'
) {
  if (!_date) return;

  const now = dayjs();
  const date = isValidDate(_date)
    ? dayjs(_date)
    : !isNaN(Number(_date)) || typeof _date === 'number'
    ? dayjs.unix(Number(_date))
    : dayjs(_date);
  const monthDistance = dayjs(now).diff(date, 'months');

  const dayTimeFormat = clockType === '12-hour' ? `h:mm A` : `H:mm`;

  const dayTime = `${date.format(dayTimeFormat)}`;

  if (monthDistance > 1) {
    return date.format(
      date.isBefore(now, 'years') && format === 'chat'
        ? 'MM/DD/YY'
        : `MMM D${date.isBefore(now, 'years') ? ', YYYY' : ''}`
    );
  }

  const _isTomorrow = date.isTomorrow();

  if (_isTomorrow) {
    return `Tomorrow, ${dayTime}`;
  }

  const dayDifference = dayjs(now).diff(date, 'days', true);

  if (Math.abs(dayDifference) > 1) {
    const day =
      date.isBefore(now) && Math.abs(dayDifference) <= 7
        ? 'ddd'
        : date.isBefore(now)
        ? `MMM D${format === 'chat' ? '' : `, YYYY`}`
        : `D MMM`;

    return date.format(
      `${day}${
        format === 'chat' ||
        (date.isBefore(now, 'weeks') && Math.abs(dayDifference) > 7)
          ? ''
          : `, ${dayTimeFormat}`
      }`
    );
  }

  const _isYesterday = date.isYesterday();

  if (_isYesterday) {
    return `Yesterday${format === 'chat' ? '' : `, ${dayTime}`}`;
  }

  const minutesDifference = dayjs(now).diff(date, 'minutes', true);

  if (minutesDifference < 60) {
    if (format === 'chat') {
      return dayTime;
    } else {
      return now.to(date);
    }
  }

  return dayTime;
}

export function formatActivityDate(date: string | undefined) {
  if (!date) return null;

  const diff = dayjs().diff(dayjs(date), 'minute');

  if (diff < 1) {
    return 'Just now';
  }

  if (diff >= 60) {
    return formatDate2(date, '12-hour');
  }

  if (diff >= 1) {
    return `${diff} mins`;
  }

  return formatDate2(date, '12-hour');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidDate(date: any) {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  );
}

// Helper function to convert timezone offset to ISO 8601 format
export function getTimezoneOffset(timezone: string) {
  const sign = timezone.startsWith('GMT-') ? '-' : '+'; // Corrected the sign logic
  const [hours, minutes] = timezone.slice(4).split(':');
  const offsetHours = sign === '+' ? parseInt(hours) : -parseInt(hours);
  const offsetMinutes = minutes
    ? sign === '+'
      ? parseInt(minutes)
      : -parseInt(minutes)
    : 0;
  return `${sign}${padZero(Math.abs(offsetHours))}:${padZero(
    Math.abs(offsetMinutes)
  )}`;
}

// Helper function to pad numbers with leading zeros
export function padZero(num: number) {
  return String(num).padStart(2, '0');
}

export function formatAverageTime(time: number | undefined) {
  if (time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    if (hours > 0 && minutes > 0 && seconds > 0) {
      return dayjs()
        .hour(hours)
        .minute(minutes)
        .second(seconds)
        .format('HH[h] mm[m] ss[s]');
    }

    if (hours > 0 && minutes > 0) {
      return dayjs().hour(hours).minute(minutes).format('HH[h] mm[m]');
    }

    if (hours > 0 && seconds > 0) {
      return dayjs().hour(hours).second(seconds).format('HH[h] ss[s]');
    }

    if (hours > 0) {
      return dayjs().hour(hours).format('HH[h]');
    }

    if (minutes > 0 && seconds > 0) {
      return dayjs().minute(minutes).second(seconds).format('mm[m] ss[s]');
    }

    if (minutes > 0) {
      return dayjs().minute(minutes).format('mm[m]');
    }

    if (seconds > 0) {
      return dayjs().second(seconds).format('ss[s]');
    }
  }
  return '00h 00m 00s';
}

function convertTo24HourFormat(time: string): string {
  const [timeStr, period] = time.split(' ');

  let hours = parseInt(timeStr, 10);

  if (period.toUpperCase() === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === 'AM' && hours === 12) {
    hours = 0;
  }

  const convertedHours = hours.toString().padStart(2, '0');

  return `${convertedHours}:00`;
}

export function convertToLocalTime(
  inputStr: string,
  offset = dayjs().utcOffset() / 60
): string {
  const inputTime = dayjs(`2000-01-01 ${convertTo24HourFormat(inputStr)}`);

  const outputTime = inputTime.add(offset, 'hour').format('h A');

  return outputTime;
}

export default getRangeValue;
