import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import GroupDetail from './GroupDetail';

import BriefcaseIcon from '@/assets/icons/BriefcaseIcon';
import EmailIcon from '@/assets/icons/EmailIcon';
import InstagramIcon from '@/assets/icons/InstagramIcon';
import LocationIcon from '@/assets/icons/LocationIcon';
import PhoneIcon from '@/assets/icons/PhoneIcon';
import InfoList from '@/components/InfoList/InfoList';
import { BBBCard } from '@/components/ui';
import { env } from '@/config/env';
import useResponsive from '@/hooks/common/useResponsive';
import useCustomerByPhoneNumber from '@/hooks/customers/customer/useCustomerByPhoneNumber';
import { useAppDispatch } from '@/hooks/rtk/store';
import useClickMessage from '@/hooks/whatsApp/useClickMessage';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import useListMedia from '@/hooks/whatsApp/useListMedia';
import useMedia from '@/hooks/whatsApp/useMedia';
import useStarredMessages from '@/hooks/whatsApp/useStarredMessages';
import AddCustomerModal from '@/pages/Customers/AddCustomerModal';
import Notes from '@/pages/Customers/CustomerDetail/DetailCard/Notes';
import TagsCard from '@/pages/Customers/CustomerDetail/TagsCard/TagsCard';
import { setZoom } from '@/stores/bitCRM';
import { IntegrationSources } from '@/types/whatsApp/shape';
import { toWhatsAppFormat } from '@/utils/bitCRM';
import { formatDate2 } from '@/utils/common/date';
import { formatInternational } from '@/utils/common/phone';
import { formatAddressLocation, formatDisplayName } from '@/utils/customers';

type Props = {
  onEventChange?: (val: 'back') => void;
  sources?: string;
};

function CustomerDetail(props: Props) {
  return <_CustomerDetailOnboarded {...props} />;
}

function _CustomerDetailOnboarded(props: Props) {
  const isGroup = useIsGroupChat();

  if (isGroup) {
    return (
      <>
        <Header title="Group Data" />
        <GroupDetail />
      </>
    );
  }

  return (
    <>
      <Header title="Customer's Detail" />
      <__CustomerDetail {...props} />
      <CustomerNotes />
      <CustomerTags />
      <Media sources={props.sources} />
      <StarredMessage />
    </>
  );
}

function CustomerTags() {
  const { data } = useCustomerByPhoneNumber();

  if (!data) return null;

  return (
    <TagsCard
      customerId={data.id}
      customerTags={data.customerTags}
      title={
        <p className="text-neutral-50 font-medium text-sm uppercase">Tags</p>
      }
    />
  );
}

function __CustomerDetail({ onEventChange }: Props) {
  const isTablet = useResponsive('lg');
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);

  const { data, isInitialLoading: loading, error } = useCustomerByPhoneNumber();

  if (!data) return null;

  return (
    <>
      <AddCustomerModal
        show={showCustomerDetail}
        onClose={() => setShowCustomerDetail(false)}
        data={data}
      />

      {loading ? (
        <Skeleton className="rounded-lg" height={220} />
      ) : error ? null : (
        <BBBCard
          title={formatDisplayName(
            data.phoneNumber,
            data.firstName,
            data.lastName
          )}
          withCollapse
          onBack={isTablet ? () => onEventChange?.('back') : undefined}
          titleLink={`/customers/${data.id}`}
          titleLinkTooltip="Go to customer details"
          onClickTitle={() => {
            setShowCustomerDetail(true);
          }}
        >
          <div className="flex flex-col gap-4">
            <InfoList label={<PhoneIcon />} withoutDivider autoWidth withCopy>
              {formatInternational(data.phoneNumber)}
            </InfoList>
            {data.email && (
              <InfoList label={<EmailIcon />} withoutDivider autoWidth withCopy>
                {data.email}
              </InfoList>
            )}
            {data.instagramId && (
              <InfoList
                label={<InstagramIcon />}
                withoutDivider
                autoWidth
                withCopy
              >
                {data.instagramId}
              </InfoList>
            )}
            {!!data.addresses.length && (
              <>
                {!!data.addresses[0].company && (
                  <InfoList label={<BriefcaseIcon />} withoutDivider autoWidth>
                    {data.addresses[0].company}
                  </InfoList>
                )}
                <InfoList label={<LocationIcon />} withoutDivider autoWidth>
                  {formatAddressLocation(data.addresses)}
                </InfoList>
              </>
            )}
          </div>
        </BBBCard>
      )}
    </>
  );
}

function StarredMessage() {
  return <_StarredMessage />;
}

function _StarredMessage() {
  const { data: starredMessages } = useStarredMessages();

  const clickMessage = useClickMessage();

  if (!starredMessages?.length) return null;

  return (
    <BBBCard>
      <p className="text-neutral-50 font-medium text-sm uppercase mb-2.5">
        Starred Message
      </p>
      {starredMessages.map((message, i) => (
        <div
          className="p-2 border border-neutral-30 cursor-pointer rounded-lg flex gap-2 mb-4 last:mb-0"
          key={i}
          onClick={() => {
            clickMessage({
              id: message.messageId2,
            });
          }}
        >
          {message.body && (
            <div
              className="grow line-clamp-3"
              dangerouslySetInnerHTML={{
                __html: toWhatsAppFormat(message.body),
              }}
            ></div>
          )}
          <div className="text-sm text-neutral-40">
            {formatDate2(message.timestamp)}
          </div>
        </div>
      ))}
    </BBBCard>
  );
}

function Media({ sources }: Pick<Props, 'sources'>) {
  const listMediaQuery = useListMedia({
    limit: 8,
  });

  const flattedMedia =
    listMediaQuery.data?.pages.flatMap((page) => page.data) || [];

  return (
    <>
      <BBBCard>
        <p className="text-neutral-50 font-medium text-sm uppercase">Media</p>

        {flattedMedia.length ? (
          <div className="grid grid-cols-4 gap-x-1 gap-y-2 mt-2.5">
            {flattedMedia.map((image) => (
              <MediaPreview
                messageId={image.messageId}
                messageId2={image.messageId2}
                media={image.media}
                key={image.messageId2}
                source={image.sources}
              />
            ))}
          </div>
        ) : sources === 'INSTAGRAM' ? (
          <InfoMedia
            message={
              <p className="text-center">
                Media are not <br />
                available for this channel
              </p>
            }
          />
        ) : (
          <InfoMedia message="No media available" />
        )}
      </BBBCard>
    </>
  );
}

function MediaPreview({
  messageId,
  messageId2,
  media,
  source,
}: {
  messageId: string;
  messageId2: string;
  media: string | null;
  source: IntegrationSources;
}) {
  const dispatch = useAppDispatch();
  const [fallbackToDefault, setFallbackToDefault] = useState(false);

  const enabled =
    (source === 'WHATSAPP' || source === 'WHATSAPP_META') && !media;

  const { data: mediaData } = useMedia(
    {
      id: messageId,
    },
    {
      enabled,
    }
  );

  const originalMedia = media || mediaData;

  useEffect(() => {
    setFallbackToDefault(false);
  }, [originalMedia]);

  return (
    <div className="w-[4.75rem] h-[4.75rem] border border-neutral-30 bg-neutral-20 rounded-lg overflow-clip">
      <img
        src={
          fallbackToDefault
            ? `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
            : originalMedia ||
              `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        onClick={() => {
          dispatch(
            setZoom({
              status: true,
              chatId: messageId2,
            })
          );
        }}
        alt="media"
        className="w-full h-full object-cover cursor-pointer"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          setFallbackToDefault(true);
        }}
      />
    </div>
  );
}

function InfoMedia({ message }: { message: string | React.ReactNode }) {
  return (
    <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="50" height="50" rx="25" fill="#DDDDDD" />
        <path
          d="M37.7342 28.7063L33.998 24.5568L29.2812 19.3158C29.1218 19.1389 28.9217 18.9964 28.6951 18.8985C28.4686 18.8007 28.2212 18.75 27.9708 18.75C27.7203 18.75 27.4729 18.8007 27.2464 18.8985C27.0198 18.9964 26.8197 19.1389 26.6603 19.3158L21.9436 24.5568C21.9123 24.5909 21.8585 24.5906 21.8276 24.5562L20.2304 22.7809C20.071 22.6039 19.8709 22.4614 19.6443 22.3636C19.4178 22.2658 19.1704 22.2151 18.9199 22.2151C18.6695 22.2151 18.4221 22.2658 18.1956 22.3636C17.969 22.4614 17.7689 22.6039 17.6095 22.7809L14.4529 26.2894L12.2658 28.7063C12.06 28.9333 11.9291 29.2095 11.8885 29.5022C11.8479 29.795 11.8993 30.0923 12.0367 30.3593C12.174 30.6263 12.3916 30.8519 12.664 31.0096C12.9363 31.1674 13.2521 31.2508 13.5744 31.25H36.4256C36.7479 31.2508 37.0637 31.1674 37.336 31.0096C37.6084 30.8519 37.826 30.6263 37.9633 30.3593C38.1007 30.0923 38.1521 29.795 38.1115 29.5022C38.0709 29.2095 37.94 28.9333 37.7342 28.7063Z"
          fill="#757575"
        />
      </svg>

      <p className="text-neutral-50">{message}</p>
    </div>
  );
}

function CustomerNotes() {
  const { data } = useCustomerByPhoneNumber();

  if (!data) return null;

  return (
    <BBBCard>
      <p className="text-neutral-50 font-medium text-sm uppercase">
        Customer Notes
      </p>
      <Notes
        containerClassName="mt-2.5"
        notes={data?.notes}
        id={data?.id}
        withoutLabel
        withoutSaveButton
      />
    </BBBCard>
  );
}

function Header({ title }: { title: string }) {
  return <BBBCard title={title} />;
}

export default CustomerDetail;
