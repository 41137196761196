import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Menu } from 'react-feather';
import { useHistory, useLocation } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import SidebarSectionNav from './SidebarSectionNav';
import SidebarSubNav from './SidebarSubNav';
import SidebarWrapper from './SidebarWrapper';

import BBBLogo from '@/assets/icons/BBBLogo';
import { sidebarData, sidebarDatav2 } from '@/constants/layouts/sidebarData';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import useSidebarMeta from '@/hooks/common/useSidebarMeta';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setExpandedSubNav } from '@/stores/common';

export const COLLAPSED_WIDTH = 80.5;
export const EXPANDED_WIDTH = 240;

export const whitelistPath = [
  '/bitchat',
  '/bitapp',
  '/bitlink',
  '/bitcrm',
  '/bitlogin',
  '/bitai',
  '/misc',
];

type SidebarProps = {
  headerSlotRef: HTMLDivElement | null;
};

function Sidebar({ headerSlotRef }: SidebarProps) {
  const isMobile = useResponsive('sm');

  const [activeSidebar, setActiveSidebar] = useState(false);

  const appWrapperRef = document.getElementById('app');

  return (
    <>
      {isMobile && headerSlotRef && (
        <>
          {createPortal(
            <HeaderMobile
              onHamburgerClick={() => setActiveSidebar((prev) => !prev)}
            />,
            headerSlotRef
          )}
        </>
      )}
      {isMobile && appWrapperRef ? (
        createPortal(
          <_Sidebar
            activeSidebar={activeSidebar}
            setActiveSidebar={setActiveSidebar}
          />,
          appWrapperRef
        )
      ) : (
        <_Sidebar
          activeSidebar={activeSidebar}
          setActiveSidebar={setActiveSidebar}
        />
      )}
    </>
  );
}

export default Sidebar;

function _Sidebar({
  activeSidebar,
  setActiveSidebar,
}: {
  activeSidebar: boolean;
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const isMobile = useResponsive('sm');
  const dispatch = useAppDispatch();

  useOutsideAlerterv2(
    sidebarRef,
    () => {
      setActiveSidebar(false);
      if (isMobile) {
        dispatch(setExpandedSubNav(false));
      }
    },
    true
  );

  return (
    <div
      className={cx(
        isMobile &&
          cx(
            'absolute top-0 left-0  overflow-auto bg-white transition-transform bottom-0 z-[200] transform -translate-x-full',
            activeSidebar && 'translate-x-0'
          )
      )}
      id="sidebar-root"
      ref={sidebarRef}
    >
      <div
        className={cx('bg-white relative scrollbar-hide h-full flex flex-col')}
      >
        <SidebarWrapper
          setActiveSidebar={setActiveSidebar}
          activeSidebar={activeSidebar}
        >
          {sidebarData
            .filter((data) => !data.disabled)
            .map((item) => {
              return (
                <div
                  className="border-b last:border-none mx-2.5 py-2"
                  key={item.id}
                >
                  {item.child.map((subNav) => {
                    const realSubData = sidebarDatav2.find(
                      (data) => data.path === subNav
                    );

                    if (!realSubData) return null;

                    return (
                      <SidebarSectionNav
                        key={realSubData?.path}
                        data={realSubData}
                      />
                    );
                  })}
                </div>
              );
            })}
        </SidebarWrapper>
        {isMobile && document.querySelector(`#app`) ? (
          createPortal(
            <SubNav setActiveSidebar={setActiveSidebar} />,
            document.querySelector(`#app`)!
          )
        ) : (
          <SubNav setActiveSidebar={setActiveSidebar} />
        )}
      </div>
    </div>
  );
}

function SubNav({
  setActiveSidebar,
}: {
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const expandedSubNav = useAppSelector((s) => s.common.expandedSubNav);

  const { pathname } = useLocation();

  return (
    <AnimatePresence initial={false}>
      {expandedSubNav &&
        whitelistPath.some((path) => pathname.includes(path)) && (
          <_SubNav setActiveSidebar={setActiveSidebar} />
        )}
    </AnimatePresence>
  );
}

function _SubNav({
  setActiveSidebar,
}: {
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();

  const sidebarSubRef = useRef<HTMLDivElement>(null);
  const isMobile = useResponsive('sm');

  useOutsideAlerterv2(
    sidebarSubRef,
    (target) => {
      if (
        target.closest('#content')?.id === 'content' ||
        target.closest('#header-slot')?.id === 'header-slot'
      ) {
        dispatch(setExpandedSubNav(false));
      }
    },
    true,
    undefined
  );

  const { parentPath, app, mapActiveSidebar } = useSidebarMeta();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
      className={cx(
        'w-[240px] border-l border-r  bg-white absolute left-full top-0 bottom-0 z-[300] scrollbar-hide min-h-full flex flex-col overflow-auto',
        isMobile && 'left-16'
      )}
      ref={sidebarSubRef}
    >
      <SidebarSubNav
        data={mapActiveSidebar}
        parent={sidebarDatav2.find(
          (data) => data.path === parentPath.join('/')
        )}
        onClick={() => {
          setActiveSidebar(false);
          dispatch(setExpandedSubNav(false));
        }}
        app={app}
      />
    </motion.div>
  );
}

function HeaderMobile({ onHamburgerClick }: { onHamburgerClick: () => void }) {
  const history = useHistory();
  return (
    <>
      <div className="flex justify-between items-center px-3 py-2">
        <div>
          <Menu size={30} onClick={onHamburgerClick} />
        </div>
        <div onClick={() => history.push('/')}>
          <BBBLogo />
        </div>
        <div className="rounded-full w-[36px] h-[36px]" />
      </div>
    </>
  );
}
