import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { socketPricing } from 'socket';
import CardSection from './components/CardSection';
import PlanComparison from './components/PlanComparison';

import { BBBContainer, BBBNavigatorTab } from '@/components/ui';
import { mapParamToAppType } from '@/constants/common/app';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AppType } from '@/types/systemLogin';
import { sortAppOrder } from '@/utils/app';
import { toast } from '@/utils/common/toast';

export default function Pricing() {
  const queryParam = useQuerySearchParams();

  const tabParam = queryParam.get('tab') as keyof typeof mapParamToAppType;

  const _active = tabParam ? mapParamToAppType[tabParam] : 'BITCHAT';

  usePricingRealtime();

  return <_Pricing _active={_active} />;
}

function _Pricing({ _active }: { _active: AppType }) {
  const [active, setActive] = useState<AppType>(_active);

  useEffect(() => {
    setActive(_active);
  }, [_active]);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <BBBContainer ref={ref}>
      <AppNavigator active={active} />
      <CardSection active={active} />
      <PlanComparison active={active} containerRef={ref} />
    </BBBContainer>
  );
}

type AppNavigatorProps = {
  active: AppType | null;
};

const appList = [
  {
    name: 'BITLINK',
    label: 'bitLink',
    disabled: false,
  },
  {
    name: 'BITAPP',
    label: 'bitApp',
    disabled: false,
  },
  {
    name: 'BITCRM',
    label: 'bitCRM',
    disabled: false,
  },
  {
    name: 'BITCHAT',
    label: 'bitChat',
    disabled: false,
  },
  {
    name: 'BITLOGIN',
    label: 'bitLogin',
    disabled: false,
  },
  {
    name: 'BITAI',
    label: 'bitAI',
    disabled: false,
  },
].sort((a, b) => sortAppOrder(a.label, b.label));

function AppNavigator({ active }: AppNavigatorProps) {
  const history = useHistory();

  return (
    <div className="flex justify-center mb-12">
      <BBBNavigatorTab
        navigationLists={appList}
        optionLabel="label"
        optionValue="name"
        value={appList.find((list) => list.name === active)}
        onChange={(app) => {
          if (!app.disabled) {
            history.push(`/pricing?tab=${app.name.toLowerCase()}`);
          }
        }}
      />
    </div>
  );
}

function usePricingRealtime() {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  useEffect(() => {
    socketPricing.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  const invalidatePricing = useCallback(
    ({ app }: { app: AppType }) => {
      queryClient.invalidateQueries([`${app.toLowerCase()}-pricing`]);
      toast.success('Succesfully upgraded plan');
    },
    [queryClient]
  );

  useEffect(() => {
    socketPricing.on('pricing-success', invalidatePricing);

    return () => {
      socketPricing.off('pricing-success', invalidatePricing);
    };
  }, [invalidatePricing, queryClient]);
}
