import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import RecommendationIcon from '@/assets/icons/RecommendationIcon';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBAlert } from '@/components/ui';
import { BBBModal } from '@/components/ui/BBBModal';
import SearchInput from '@/components/ui/SearchInput';
import useProductRecommendation from '@/hooks/bitChat/livechat/useProductRecommendation';
import { useAppDispatch } from '@/hooks/rtk/store';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { upsertLivechatMemo } from '@/stores/bitCRM';
import { Product } from '@/types/shopify/rest/products';
import { formatCurrency } from '@/utils/common/currency';
import { convertHtmlToEditorState } from '@/utils/common/rich';

export default function RecommendationProduct() {
  const [showRecommendation, setShowRecommendation] = useState(false);

  return (
    <div className="relative">
      <ShopifyAwareWrapper appType="BITCHAT" fallbackToChildren>
        {({ connectMiddleware }) => (
          <RecommendationIcon
            className="cursor-pointer"
            onClick={(e) => {
              connectMiddleware(() => {
                setShowRecommendation(true);
                e.preventDefault();
              });
            }}
          />
        )}
      </ShopifyAwareWrapper>
      {showRecommendation && (
        <RecommendProductModal onHide={() => setShowRecommendation(false)} />
      )}
    </div>
  );
}

function RecommendProductModal({ onHide }: { onHide: () => void }) {
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();

  const { data, isInitialLoading } = useProductRecommendation({
    search: search,
  });

  const { data: shopifyData } = useShopifyIntegrationByApp('BITCHAT');

  const handleRecommendButton = () => {
    if (selectedProduct) {
      dispatch(
        upsertLivechatMemo({
          messageToSend: convertHtmlToEditorState(`<p>
            Good Choice <br />
            Check out by clicking this link:
          </p>`),
          recommendedProduct: {
            ...selectedProduct,
            url: `https://${shopifyData!.domain}/products/${
              selectedProduct.handle
            }`,
          },
        })
      );
    }
    onHide();
  };

  return (
    <BBBModal
      title="Recommend product"
      onHide={onHide}
      show
      footer
      submitText="Recommend product"
      disableSave={!selectedProduct}
      handleSave={handleRecommendButton}
    >
      <div className="mt-6">
        <SearchInput
          placeholder="Search product"
          value={search}
          onValueChange={(value) => setSearch(value)}
        />
        {!!data?.length && (
          <BBBAlert type="info">
            Try searching using product name directly
          </BBBAlert>
        )}
        <div className="h-[32rem] mt-3 overflow-auto">
          {isInitialLoading ? (
            <ProductSkeleton />
          ) : !data?.length ? (
            <div className="w-full flex h-full justify-center items-center">
              <p className="text-primary-main text-center">
                We couldn&apos;t find any matching products
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-3 justify-center row-span-1 gap-y-8 gap-x-2">
              {data.map((item) => {
                const price = item.variants.reduce(function (prev, curr) {
                  return prev.price < curr.price ? prev : curr;
                }).price;
                return (
                  <ProductItem
                    key={item.id}
                    name={`${item.title}`}
                    price={parseInt(price)}
                    image={item.image?.src}
                    onClick={() => {
                      setSelectedProduct(item);
                    }}
                    className={cx(
                      selectedProduct?.id === item.id && 'border-primary-main'
                    )}
                    oos={
                      !(
                        item.status === 'active' &&
                        item.variants.some(
                          (variant) => variant.inventory_quantity > 0
                        )
                      )
                    }
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </BBBModal>
  );
}

function ProductItem({
  name,
  price,
  image,
  onClick,
  className,
  currency,
  oos,
}: {
  name: string;
  price: number;
  image: string | undefined;
  onClick: () => void;
  className?: string;
  currency?: string;
  oos?: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className={cx(
        `relative rounded-lg flex flex-col px-1 py-1 flex-wrap gap-2 cursor-pointer border-2 border-transparent hover:border-primary-main transition-all`,
        className
      )}
    >
      <div className={cx('h-full flex flex-col')}>
        <img
          src={image}
          alt="product"
          className="object-cover w-28 h-28 mx-auto p-1 mb-2"
        />
        <div className="text-primary-main grow line-clamp-2 mb-2">{name}</div>
        <div className="text-primary-main text-sm font-semibold">
          {formatCurrency(price, currency)}{' '}
        </div>
      </div>
    </div>
  );
}

function ProductSkeleton() {
  return (
    <div className="grid grid-cols-3 gap-5">
      {Array.from(Array(6).keys()).map((item) => (
        <div key={item} className="flex flex-col gap-2">
          <Skeleton height={100} />
          <Skeleton width={90} height={20} />
          <Skeleton width={80} height={30} />
        </div>
      ))}
    </div>
  );
}
