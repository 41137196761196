function CheckIconSingle({
  color = 'rgb(156,163,175)',
  size = 8,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.toString()}
      height={size.toString()}
      fill="none"
      viewBox="0 0 8 6"
    >
      <path
        fill={color}
        d="M6.598.349a.656.656 0 01.937.919L4.043 5.634a.656.656 0 01-.945.017L.783 3.336a.656.656 0 11.928-.927L3.543 4.24 6.582.368a.236.236 0 01.017-.02z"
      />
    </svg>
  );
}

export default CheckIconSingle;
