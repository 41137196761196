import { twMerge as cx } from 'tailwind-merge';

export default function CopyIcon({
  size = 16,
  className,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  size?: number | string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 17"
      className={cx(!props.color && 'text-[#2B6AAF]', className)}
      {...props}
    >
      <path
        stroke={'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M4 2.9v8.4c0 .371.158.727.44.99.28.263.662.41 1.06.41h6c.398 0 .78-.147 1.06-.41.282-.263.44-.619.44-.99V5.17c0-.187-.04-.372-.117-.544a1.4 1.4 0 00-.334-.458L10.062 1.9A1.558 1.558 0 009.014 1.5H5.5c-.398 0-.78.147-1.06.41-.282.263-.44.619-.44.99v0z"
      ></path>
      <path
        stroke={'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M10 12.7v1.4c0 .371-.158.727-.44.99-.28.262-.662.41-1.06.41h-6c-.398 0-.78-.148-1.06-.41A1.354 1.354 0 011 14.1V6.4c0-.371.158-.727.44-.99.28-.263.662-.41 1.06-.41H4"
      ></path>
    </svg>
  );
}
