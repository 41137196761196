import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    '3 Automation Flows',
    '25 Automations per month',
    'Abandoned Cart Flow',
    'Payment Reminder Flow',
    'Order Notif Flow',
    'Sandbox for Broadcast Campaign',
  ],
  standard: [
    '5 Automation Flows',
    '1,000 Automations /mnt',
    '5,000 Broadcast /mnt',
    'Personalized Messages',
    'Use Your Own WhatsApp API',
  ],
  pro: [
    'Unlimited Automation Flows',
    '4,000 Automations /mnt',
    '10,000 Broadcast /mnt',
    'Ask Review Automation',
    'Voucher',
    'URL Shortener',
  ],
  pro1: ['20,000 Automations /mnt', '100,000 Broadcast /mnt'],
  enterprise: ['API Access', 'Customizable Plan'],
};
