function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.333 4.5a1.333 1.333 0 011.334-1.333h10.666A1.333 1.333 0 0114.667 4.5v8a1.334 1.334 0 01-1.334 1.333H2.667A1.334 1.334 0 011.333 12.5v-8z"
      ></path>
      <path
        fill="#757575"
        d="M1.333 5.833l5.001 4.001a2.667 2.667 0 003.332 0l5-4"
      ></path>
      <path
        stroke="#fff"
        strokeLinejoin="round"
        d="M1.333 5.833l5.001 4.001a2.667 2.667 0 003.332 0l5-4"
      ></path>
    </svg>
  );
}

export default EmailIcon;
