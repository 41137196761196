import React from 'react';

export default function RadioButtonIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 33.6889C27.5602 33.6889 33.6889 27.5602 33.6889 20C33.6889 12.4398 27.5602 6.31111 20 6.31111C12.4398 6.31111 6.31111 12.4398 6.31111 20C6.31111 27.5602 12.4398 33.6889 20 33.6889ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
        fill="black"
      />
      <circle cx="20.0002" cy="20.0001" r="9.84616" fill="black" />
    </svg>
  );
}
