import { SidebarIcon } from '@/constants/layouts/sidebarData';

function BitAppIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitAppIconDefault {...props} />
      ) : (
        <BitAppIconActive {...props} />
      )}
    </>
  );
}

function BitAppIconDefault({ size = 26 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M14.166 4.333a1 1 0 00-1-1H6.833a1 1 0 00-1 1v11.334a1 1 0 001 1h6.333a1 1 0 001-1V4.332zM5.833 1.666c-.92 0-1.667.747-1.667 1.667v13.334c0 .92.747 1.666 1.667 1.666h8.334c.92 0 1.666-.746 1.666-1.666V3.332c0-.92-.746-1.667-1.667-1.667H5.833z"
        clipRule="evenodd"
      ></path>
      <circle cx="10" cy="14.167" r="1.667" fill="#727272"></circle>
    </svg>
  );
}

function BitAppIconActive({ size }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M14.166 4.333a1 1 0 00-1-1H6.833a1 1 0 00-1 1v11.334a1 1 0 001 1h6.333a1 1 0 001-1V4.332zM5.833 1.666c-.92 0-1.667.747-1.667 1.667v13.334c0 .92.747 1.666 1.667 1.666h8.334c.92 0 1.666-.746 1.666-1.666V3.332c0-.92-.746-1.667-1.667-1.667H5.833z"
        clipRule="evenodd"
      ></path>
      <circle cx="10" cy="14.167" r="1.667" fill="#FF7C11"></circle>
    </svg>
  );
}

export default BitAppIcon;
