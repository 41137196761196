function BroadcastIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      fill="none"
      viewBox="0 0 12 10"
    >
      <path
        fill="#fff"
        d="M10.083 5.186a.556.556 0 01-.416-.174.59.59 0 01-.167-.429c0-.17.056-.314.168-.43a.553.553 0 01.415-.172h1.167c.165 0 .304.058.416.173a.59.59 0 01.167.43c0 .17-.056.313-.168.429a.553.553 0 01-.415.173h-1.167zm-.35 3.855L8.8 8.318a.553.553 0 01-.233-.391.637.637 0 01.116-.452.53.53 0 01.38-.241c.155-.02.3.02.437.12l.933.723c.136.1.214.231.234.392.02.16-.02.311-.117.452a.53.53 0 01-.38.24.588.588 0 01-.437-.12zM9.5 1.812a.588.588 0 01-.438.12.53.53 0 01-.379-.24.637.637 0 01-.116-.452A.553.553 0 018.8.848l.933-.722c.136-.1.282-.141.438-.121.155.02.282.1.379.241.097.14.136.291.117.452a.553.553 0 01-.234.391l-.933.723zM1.917 8.8V6.39h-.584c-.32 0-.595-.118-.824-.354a1.177 1.177 0 01-.342-.85V3.98c0-.331.114-.615.343-.851.228-.236.503-.354.823-.354h2.334l2.027-1.084c.194-.12.391-.301.59-.181.2.12.299.296.3.527v5.09c0 .231-.1.407-.3.528-.2.12-.396-.06-.59-.181L3.667 6.39h-.584V8.8H1.917zm5.25-2.199V2.565c.262.241.474.535.634.882.16.346.241.725.24 1.136 0 .412-.08.791-.24 1.138-.16.346-.372.64-.634.88zm-1.459-.21V2.776l-1.75.904H1.042v1.807h2.916l1.75.904z"
      ></path>
    </svg>
  );
}

export default BroadcastIcon;
