import { twMerge as cx } from 'tailwind-merge';

export default function BBBLogo({ className }: { className?: string }) {
  return (
    <img
      src="/bbb-logo.png"
      alt="bitbybit logo"
      className={cx('w-auto h-[56px] object-cover', className)}
    />
  );
}
