import { useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

const useAccounts = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['wa-accounts', activeCompany],
    () => services.whatsApp.chat.getIntegrations(activeCompany),
    {
      meta: {
        disableToast: false,
      },
    }
  );
};

export default useAccounts;
