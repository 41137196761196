export const acceptedXcelFileType = [
  '.csv',
  'text/x-comma-separated-values',
  'text/csv',
  'application/csv',
  'text/x-csv',
  'application/x-csv',
  'text/comma-separated-values',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

export const acceptedCrmFileTypes =
  'application/pdf,application/msword,text/plain,image/*,'.concat(
    acceptedXcelFileType
  );

export const staticFilterOptions = [
  {
    label: 'Chat',
    value: 'chat',
  },
  {
    label: 'Read',
    value: 'read',
    parentValue: 'chat',
  },
  {
    label: 'Unread',
    value: 'unread',
    parentValue: 'chat',
  },
  {
    label: 'Ticket',
    value: 'ticket',
  },
  {
    label: 'Assigned',
    value: 'Assigned',
    parentValue: 'ticket',
  },
  {
    label: 'Open',
    value: 'Open',
    parentValue: 'ticket',
  },
  {
    label: 'Resolved',
    value: 'Resolved',
    parentValue: 'ticket',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'WhatsApp app',
    value: 'WHATSAPP',
    parentValue: 'channel',
  },
  {
    label: 'WhatsApp Cloud API',
    value: 'WHATSAPP_META',
    parentValue: 'channel',
  },
  {
    label: 'Instagram',
    value: 'INSTAGRAM',
    parentValue: 'channel',
  },
  {
    label: 'Facebook',
    value: 'FACEBOOK',
    parentValue: 'channel',
  },
  {
    label: 'Agent',
    value: 'agent',
  },
  {
    label: 'Ticket category',
    value: 'ticket-category',
  },
];
