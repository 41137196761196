import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { AnalyticParams } from '@/api/services/bitChat/analytics';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAnalyticAgents = (
  params: AnalyticParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`bitchat-agent-analytics`, activeCompany, params],
    () =>
      services.bitChat.analytics.getAgents(activeCompany, {
        startDate: params.startDate,
        endDate: params.endDate,
        search: params.search,
      }),
    options ? { ...options, keepPreviousData: true } : undefined
  );
};

export default useAnalyticAgents;
