import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import customerServices from 'api/services/customer';
import { AxiosResponse } from 'axios';

import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { toast } from '@/utils/common/toast';

const useDeleteCustomer = () => {
  const toggleConfirmation = useConfirmationModal();
  const queryClient = useQueryClient();
  const deleteCustomerMutation = useMutation(
    (payload: { id: string }) =>
      customerServices.customer.deleteCustomer({ id: payload.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers']);
        toast.success('Customer deleted successfully');
      },
    }
  );

  function deleteCustomer(
    withConfirmation: true
  ): (
    params: string,
    options?: Omit<
      UseMutationOptions<unknown, unknown, unknown, unknown>,
      'mutationFn'
    >
  ) => void;
  function deleteCustomer(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown>;
  function deleteCustomer(
    withConfirmation: true | false
  ):
    | ((
        param: string,
        options?: Omit<
          UseMutationOptions<unknown, unknown, unknown, unknown>,
          'mutationFn'
        >
      ) => void)
    | UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown> {
    return withConfirmation
      ? (id, options = {}) => {
          const { onSuccess, ...restOptions } = options;
          toggleConfirmation({
            title: 'Delete customer?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteCustomerMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: (...cb) => {
                    hide();
                    onSuccess?.(...cb);
                  },
                  ...restOptions,
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          });
        }
      : deleteCustomerMutation;
  }

  return deleteCustomer;
};

export default useDeleteCustomer;
