import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { ShopifyMeta } from '@/types/integrations';
import { AppType } from '@/types/systemLogin';

export default {
  getShopifyData: (appType: AppType, domain: string | undefined) =>
    api.integrations
      .get<ApiResponse<ShopifyMeta>>(`/shopify/${appType}/auth/${domain}`)
      .then((res) => res.data.data),
};
