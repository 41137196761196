import { ApiResponse } from 'api/types';
import api from 'config/api';
import { WhatsAppCloudAccount } from 'types/whatsApp/chat';

export default {
  updateProfile: (payload: WhatsAppCloudAccount) =>
    api.whatsApp.v1
      .put<ApiResponse<WhatsAppCloudAccount>>(`/meta/business-profile`, payload)
      .then((res) => res.data.data),
  getProfile: (params: { key: string }) =>
    api.whatsApp.v1
      .get<ApiResponse<WhatsAppCloudAccount>>(`/meta/business-profile`, {
        params,
      })
      .then((res) => res.data.data),
};
