import { env } from 'config/env';
import { rootStore } from 'stores';
import { useUser } from '../rtk/selector';

const useAppInternal = () => {
  const user = useUser();

  if (!user) {
    return true;
  }
  return user.email.includes(env.REACT_APP_INTERNAL_SECURITY);
};

export const isAppInternal = rootStore.getState()
  ? rootStore
      .getState()
      ?.auth?.user?.email.includes(env.REACT_APP_INTERNAL_SECURITY)
  : true;

export default useAppInternal;
