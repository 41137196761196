import { useCallback, useMemo } from 'react';
import { startCase, toLower } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import AmazonIcon from '@/assets/icons/AmazonIcon';
import AppleIcon from '@/assets/icons/AppleIcon';
import FacebookIcon from '@/assets/icons/FacebookIcon';
import GoogleRainbowIcon from '@/assets/icons/GoogleRainbowIcon';
import MicrosoftIcon from '@/assets/icons/MicrosoftIcon';
import TwitterIcon from '@/assets/icons/TwitterIcon';
import WhatsAppIcon from '@/assets/icons/WhatsAppIcon';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBButton } from '@/components/ui/BBBButton';
import { BBBCard } from '@/components/ui/BBBCard';
import useSettings from '@/hooks/bitLogin/useSettings';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useSocialConfig from '@/hooks/bitLogin/useSocialConfig';
import { ButtonFormat } from '@/types/bitLogin/v2';
import { checkBrightness } from '@/utils/common/colors';

export default function PreviewLogin() {
  const { status: statusEnabled, data } = useSettings();

  const isDisabled = statusEnabled !== 'success' || !data?.verified;

  return (
    <div
      className={cx(
        'border-l flex-[1_1_0%] flex justify-center items-center overflow-auto',
        isDisabled && 'opacity-50 pointer-events-none'
      )}
    >
      <_PreviewLogin />
    </div>
  );
}

function _PreviewLogin() {
  const { data: socialConfig } = useSocialConfig();
  const { data: socialAppearance } = useSocialAppearance();

  const listSocialConfigEnabled = useMemo(() => {
    return (
      socialConfig
        ?.filter((social) => social.enabledWeb === true)
        .sort((a) => (a.provider === 'GOOGLE' ? -1 : 1)) || []
    );
  }, [socialConfig]);

  return (
    <div className="flex flex-col gap-6">
      <BBBCard className="w-96 !p-10 flex flex-col gap-4">
        {socialAppearance?.position === 'top' &&
          !!listSocialConfigEnabled?.length && (
            <>
              <SocialButtons />
              {!socialAppearance.hideDivider && <Divider />}
            </>
          )}
        <>
          <div className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30">
            <p className="text-primary opacity-50">Email</p>
          </div>
          <div className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30">
            <p className="text-primary opacity-50">Password</p>
          </div>
          <div className="text-neutral-60 underline text-xs">Forgot email?</div>
          <div className="w-full bg-[#D9D9D9] h-10"></div>
          <div className="text-neutral-60 text-xs">
            Don’t have an account? <span className="underline">Sign up</span>
          </div>
        </>
        {socialAppearance?.position === 'bottom' &&
          !!listSocialConfigEnabled?.length && (
            <>
              {!socialAppearance.hideDivider && <Divider />}
              <SocialButtons />
            </>
          )}
      </BBBCard>
      <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
        {({ data: shopifyIntegrationData, connectMiddleware }) => (
          <BBBButton
            text="Preview"
            width="full"
            onClick={() =>
              connectMiddleware(() =>
                window.open(
                  `https://${shopifyIntegrationData!.domain}/account/login`,
                  '_blank'
                )
              )
            }
          />
        )}
      </ShopifyAwareWrapper>
    </div>
  );
}

function SocialButtons() {
  const { data: socialAppearance } = useSocialAppearance();
  const { data: socialConfig } = useSocialConfig();

  const listSocialConfigEnabled = useMemo(() => {
    return (
      socialConfig
        ?.filter((social) => social.enabledWeb === true)
        .sort((a) => (a.provider === 'GOOGLE' ? -1 : 1)) || []
    );
  }, [socialConfig]);

  const previewAppearanceButtonShape = useMemo(
    () =>
      socialAppearance?.style === 'square'
        ? 'rounded-none'
        : socialAppearance?.style === 'fullRounded'
        ? 'rounded-full'
        : 'rounded-xl',
    [socialAppearance?.style]
  );

  const previewAppearanceButtonShadow = useMemo(
    () => socialAppearance?.hasShadow && 'shadow-md',
    [socialAppearance?.hasShadow]
  );

  const previewAppearanceButtonFormat = useMemo(
    () =>
      socialAppearance?.format === 'contentFlexReverse'
        ? 'flex flex-row-reverse'
        : socialAppearance?.format === 'contentFlexCenter'
        ? 'flex items-center justify-center'
        : socialAppearance?.format === 'contentFlexCenterReverse'
        ? 'flex flex-row-reverse items-center justify-center'
        : socialAppearance?.format === 'wrapperFlexContainerOnlyLogo'
        ? 'w-fit'
        : 'flex',
    [socialAppearance?.format]
  );

  const previewAppearanceButtonSize = useMemo(
    () =>
      socialAppearance?.size === 'small'
        ? 'py-2'
        : socialAppearance?.size === 'medium'
        ? 'py-3'
        : socialAppearance?.size === 'large'
        ? 'py-4'
        : 'py-4',
    [socialAppearance?.size]
  );

  const previewAppearanceButtonColor = useMemo(
    () =>
      socialAppearance?.colorScheme === 'black'
        ? 'bg-black text-white border-transparent'
        : socialAppearance?.colorScheme === 'white'
        ? 'bg-white text-black border-black'
        : undefined,
    [socialAppearance?.colorScheme]
  );

  const defaultSocialColorScheme = useCallback(
    (provider: string) => {
      if (socialAppearance?.colorScheme === 'matchOutline') {
        return provider === 'GOOGLE'
          ? 'bg-transparent border border-[#000000]'
          : provider === 'FACEBOOK'
          ? 'bg-transparent border-[#1877F2] text-black'
          : provider === 'APPLE'
          ? 'bg-transparent border-[#000000] text-black'
          : provider === 'WHATSAPP'
          ? 'bg-transparent border-[#25D366] text-black'
          : provider === 'AMAZON'
          ? 'bg-transparent border-[#FF9900] text-[#FF9900]'
          : 'bg-transparent border border-[#000000]';
      }
      return provider === 'GOOGLE'
        ? 'bg-transparent border border-[#000000]'
        : provider === 'FACEBOOK'
        ? 'bg-[#1877F2] text-white border-transparent'
        : provider === 'APPLE'
        ? 'bg-[#000000] text-white border-transparent'
        : provider === 'WHATSAPP'
        ? 'bg-[#25D366] text-white border-transparent'
        : provider === 'MICROSOFT'
        ? 'bg-[#2F2F2F] text-white border-transparent'
        : provider === 'TWITTER'
        ? 'bg-[#000000] text-white border-transparent'
        : provider === 'AMAZON'
        ? 'bg-[#FF9900] border-transparent'
        : 'bg-transparent border border-[#000000]';
    },
    [socialAppearance?.colorScheme]
  );

  return (
    <div
      className={cx(
        'flex flex-col gap-2 justify-center',
        socialAppearance?.format === 'wrapperFlexContainerOnlyLogo' &&
          'flex-row flex-wrap'
      )}
    >
      {listSocialConfigEnabled.map((social, idx) => {
        const iconColor =
          socialAppearance?.colorScheme === 'custom'
            ? checkBrightness(socialAppearance.backgroundColor) === 'bright'
              ? '#000000'
              : '#FFFFFF'
            : socialAppearance?.colorScheme === 'black'
            ? '#FFFFFF'
            : socialAppearance?.colorScheme === 'white'
            ? '#000000'
            : socialAppearance?.colorScheme === 'match'
            ? social.provider === 'FACEBOOK'
              ? '#FFFFFF'
              : undefined
            : social.provider === 'FACEBOOK' ||
              social.provider === 'APPLE' ||
              social.provider === 'TWITTER' ||
              social.provider === 'WHATSAPP'
            ? '#000000'
            : undefined;

        return (
          <div
            className="flex justify-between gap-4 items-center"
            key={`${social.provider}-${idx}`}
          >
            <div
              className={cx(
                `select-none px-4.5 w-full gap-2 border`,
                defaultSocialColorScheme(social.provider),
                previewAppearanceButtonShape,
                previewAppearanceButtonShadow,
                previewAppearanceButtonFormat,
                previewAppearanceButtonSize,
                previewAppearanceButtonColor
              )}
              style={{
                backgroundColor:
                  socialAppearance?.colorScheme === 'custom'
                    ? socialAppearance?.backgroundColor
                    : '',
                borderColor:
                  socialAppearance?.colorScheme === 'custom'
                    ? socialAppearance?.outlineColor
                    : '',
                color:
                  socialAppearance?.colorScheme === 'custom'
                    ? socialAppearance?.textColor
                    : '',
              }}
            >
              <div>
                {social.provider === 'GOOGLE' ? (
                  <GoogleRainbowIcon color={iconColor} />
                ) : social.provider === 'FACEBOOK' ? (
                  <FacebookIcon color={iconColor} />
                ) : social.provider === 'APPLE' ? (
                  <AppleIcon color={iconColor} />
                ) : social.provider === 'MICROSOFT' ? (
                  <MicrosoftIcon
                    theme={socialAppearance?.colorScheme}
                    fallbackColor={iconColor}
                  />
                ) : social.provider === 'TWITTER' ? (
                  <TwitterIcon color={iconColor} />
                ) : social.provider === 'AMAZON' ? (
                  <AmazonIcon color={iconColor} />
                ) : (
                  <WhatsAppIcon color={iconColor} />
                )}
              </div>
              {socialAppearance?.format &&
                socialAppearance?.format !== 'wrapperFlexContainerOnlyLogo' && (
                  <div
                    className={cx(
                      ' text-center',
                      !(
                        [
                          'contentFlexCenter',
                          'contentFlexCenterReverse',
                        ] as ButtonFormat[]
                      ).includes(socialAppearance.format) && 'grow'
                    )}
                  >
                    {socialAppearance?.buttonText}{' '}
                    {startCase(toLower(social.provider))}
                  </div>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Divider() {
  const { data: socialAppearance } = useSocialAppearance();

  return (
    <div className="flex items-center justify-center gap-4">
      <div className="text-neutral-60">{socialAppearance?.dividerText}</div>
    </div>
  );
}
