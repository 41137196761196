import ai from './ai';
import bitAi from './bitAi';
import bitApp from './bitApp';
import bitChat from './bitChat';
import bitCRM from './bitCRM';
import bitLink from './bitLink';
import bitLogin from './bitLogin';
import countries from './countries';
import customer from './customer';
import pricing from './pricing';
import systemLogin from './systemLogin';
import whatsApp from './whatsApp';

export default {
  ai,
  bitAi,
  whatsApp,
  bitChat,
  customer,
  bitLink,
  bitApp,
  bitCRM,
  bitLogin,
  systemLogin,
  pricing,
  countries,
};
