import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import { createId } from '@paralleldrive/cuid2';
import { twMerge as cx } from 'tailwind-merge';
import { useDebounceEffect } from './hooks/useDebounceEffect';
import { canvasPreview } from './utils/canvasPreview';
import 'rc-slider/assets/index.css';
import 'react-image-crop/dist/ReactCrop.css';

import { BBBAlert, BBBModal, BBBSlider } from '@/components/ui';
import {
  DEFAULT_CROP_LANDSCAPE,
  DEFAULT_CROP_PORTRAIT,
  DEFAULT_CROP_SQUARE,
} from '@/constants/common/crop';
import { UploadOption, useUploadFile } from '@/hooks/common/upload';

export type RatioOption = 'square' | 'landscape' | 'portrait';

export type BBBCropImageModalTypes = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  upImg: string;
  fileName?: string;
  handleDownload?: (url: string) => void;
  customRatio?: boolean;
  circular?: boolean;
  aspect?: number;
  whitelistedRatio?: RatioOption[];
  uploadOptions?: UploadOption;
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ratioOptions = [
  {
    label: 'Square',
    value: 'square',
    id: createId(),
    crop: DEFAULT_CROP_SQUARE,
  },
  {
    label: 'Landscape',
    value: 'landscape',
    id: createId(),
    crop: DEFAULT_CROP_LANDSCAPE,
  },
  {
    label: 'Portrait',
    value: 'portrait',
    id: createId(),
    crop: DEFAULT_CROP_PORTRAIT,
  },
];

export default function BBBCropImageModal({
  show,
  setShow,
  upImg,
  handleDownload,
  customRatio,
  circular,
  aspect = circular === true ? DEFAULT_CROP_SQUARE : DEFAULT_CROP_LANDSCAPE,
  whitelistedRatio = ['square', 'landscape', 'portrait'],
  fileName,
  uploadOptions,
}: BBBCropImageModalTypes) {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const { uploadFile } = useUploadFile();

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [noCrop, setNoCrop] = useState(false);

  const [localCrop, setLocalCrop] = useState<Crop>();
  const [loadingSave, setLoadingSave] = useState(false);

  async function generateDownload(
    canvas: HTMLCanvasElement | null,
    cropped: Crop | undefined
  ) {
    if (!cropped || !canvas) {
      return;
    }

    if (!cropped.width && !cropped.height) {
      setNoCrop(true);
      return;
    }

    setNoCrop(false);
    setLoadingSave(true);

    canvas.toBlob(
      async (blob) => {
        try {
          if (!blob) throw new Error('Blob is not defined');
          const fileData = new File([blob], fileName!, {
            type: 'image/png',
          });

          const remoteUrl = await uploadFile(fileData, uploadOptions);

          handleDownload!(remoteUrl);
          setShow(false);
        } finally {
          setLoadingSave(false);
        }
      },
      'image/png',
      1
    );
  }

  const handleSaveCrop = () =>
    generateDownload(previewCanvasRef.current, completedCrop);

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [localAspect, setLocalAspect] = useState<number>(aspect);

  useEffect(() => {
    setLocalAspect(aspect);
  }, [aspect]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (localAspect) {
      const { width, height } = e.currentTarget;
      const cropData = centerAspectCrop(width, height, localAspect);
      setLocalCrop(cropData);
      setCompletedCrop(convertToPixelCrop(cropData, width, height));
    }
  }

  const handleClickRatio = (crop: number) => {
    setLocalAspect(crop);
    if (imgRef.current) {
      const cropData = centerAspectCrop(
        imgRef.current?.width,
        imgRef.current?.height,
        crop
      );
      setLocalCrop(cropData);
      setCompletedCrop(
        convertToPixelCrop(
          cropData,
          imgRef.current.width,
          imgRef.current.height
        )
      );
    }
  };

  return (
    <BBBModal
      show={show}
      title="Crop Image"
      centerTitle
      onHide={() => setShow(false)}
      handleSave={() => handleSaveCrop()}
      footer
      centerFooter
      loadingSave={loadingSave}
    >
      <>
        <div className="flex justify-center flex-col">
          {noCrop && (
            <BBBAlert type="danger" className="self-center mb-2">
              No crop area found
            </BBBAlert>
          )}
          <ReactCrop
            crop={localCrop}
            onChange={(_, percentCrop) => {
              setLocalCrop(percentCrop);
            }}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={localAspect}
            circularCrop={circular}
            className="block object-cover !overflow-y-auto max-h-full self-center"
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={upImg}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                background: 'transparent',
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
        <div className="mt-3 mx-4">
          Scale
          <BBBSlider
            onChange={(val) => setScale(val as number)}
            min={1}
            max={5}
            step={0.1}
            value={scale}
          />
        </div>
        <div className="mt-3 mx-4">
          Rotate
          <BBBSlider
            onChange={(val) => setRotate(val as number)}
            min={0}
            max={360}
            step={1}
            value={rotate}
          />
        </div>
        {customRatio && (
          <div className="flex justify-center items-center gap-4 mt-4">
            {ratioOptions
              .filter((ratio) => {
                return whitelistedRatio.includes(ratio.value as RatioOption);
              })
              .map((ratio) => (
                <div className="flex items-center flex-col" key={ratio.id}>
                  <div
                    className={cx(
                      `w-16 h-16 flex items-center justify-center cursor-pointer border rounded-lg border-primary-main`,
                      localAspect === ratio.crop ? 'border-2' : ''
                    )}
                    onClick={() => {
                      handleClickRatio(ratio.crop);
                    }}
                  >
                    <div
                      className={
                        ratio.value === 'square'
                          ? 'border border-primary-main w-8 h-8'
                          : ratio.value === 'landscape'
                          ? 'border border-primary-main w-12 h-6'
                          : 'border border-primary-main w-6 h-12'
                      }
                    />
                  </div>
                  <div>{ratio.label}</div>
                </div>
              ))}
          </div>
        )}
      </>
      <canvas
        ref={previewCanvasRef}
        style={{
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
          display: 'none',
        }}
      />
    </BBBModal>
  );
}
