import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import { omit } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import SettingsDangerZone from '../DangerZone';

import UserProfilePicture from '@/components/Auth/UserProfilePicture';
import {
  BBBButton,
  BBBCard,
  BBBImageUpload,
  BBBTelInput,
  BBBTextInput,
} from '@/components/ui';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useResetPasswordRequest from '@/hooks/auth/useResetPasswordRequest';
import useResponsive from '@/hooks/common/useResponsive';
import { useUser } from '@/hooks/rtk/selector';
import { SettingsFormSchema } from '@/pages/Settings';

type AccountSettingsProps = {
  control: Control<SettingsFormSchema>;
  errors: Partial<FieldErrorsImpl<SettingsFormSchema>>;
};

function AccountSettings({ control, errors }: AccountSettingsProps) {
  const isMobile = useResponsive('sm');
  const data = useUser();
  const { mutate } = useResetPasswordRequest(false);

  const handleChangePassword = () => {
    mutate({ email: data.email });
  };

  const { data: isRestricted } = useIsModuleRestricted('SETTINGS_ACCOUNT');

  return (
    <>
      <BBBCard
        title={'Account'}
        titleClassName="lg:font-normal font-semibold"
        className={cx(
          'mb-cardBottom',
          isRestricted && 'pointer-events-none opacity-50'
        )}
      >
        <div className="grid grid-cols-2 lg:gap-4 gap-6">
          <Controller
            control={control}
            name="profileDP"
            render={({ field }) => (
              <>
                <BBBImageUpload
                  label="Profile picture"
                  imageUrl={field.value}
                  onChangeImage={field.onChange}
                  imageClassName="md:w-32 md:h-32"
                  containerClassName="col-span-2"
                  crop={{ circular: true }}
                  customPlaceholder={
                    data && (
                      //@ts-ignore
                      <UserProfilePicture
                        {...omit(data, ['profile.profilePicture'])}
                        size={'8rem'}
                      />
                    )
                  }
                />
              </>
            )}
          />
          <div className="lg:flex lg:flex-col lg:gap-4 col-span-2 grid grid-cols-2 gap-2">
            <BBBTextInput
              placeholder="First name"
              isHookForm
              control={control}
              controlName="profileFirstName"
              containerClassname="w-full mb-0"
              label="First name"
            />
            <BBBTextInput
              placeholder="Last Name"
              isHookForm
              control={control}
              controlName="profileLastName"
              containerClassname="w-full mb-0"
              label="Last name"
            />
          </div>
          <BBBTextInput
            placeholder="Email"
            isHookForm
            control={control}
            controlName="profileEmail"
            containerClassname="col-span-2 mb-0"
            label="Email"
            disabled
          />
          <BBBTelInput
            isHookForm
            control={control}
            controlName="profilePhoneNumber"
            containerClassname="col-span-2"
            error={errors.profilePhoneNumber?.message}
            label="Phone number"
            onFocus={() => {
              if (isMobile) return false;
            }}
          />
          <div className="col-span-2">
            <div>Password</div>
            <BBBButton
              text="Change password"
              onClick={handleChangePassword}
              variant="secondary"
              size={isMobile ? 'sm' : 'md'}
            />
          </div>
        </div>
      </BBBCard>
      <SettingsDangerZone type="user" />
    </>
  );
}

export default AccountSettings;
