import { MessageNewAssociation } from '@/types/whatsApp/v3';

type Props = {
  chat: MessageNewAssociation;
};

function ChatBubbleSummary({ chat }: Props) {
  return (
    <div className="text-center mb-3">
      <span className="font-semibold">Sentiment: </span>
      <span>{chat.summarySentiment}</span>
    </div>
  );
}

export default ChatBubbleSummary;
