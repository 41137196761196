import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { selectActiveChatTruthy } from './chat';

import services from '@/api/services';
import { RecommendMessagePayload } from '@/api/services/whatsApp/chat';
import { useApiKey } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { Chat } from '@/types/whatsApp/chat';

const useRecommendMessage = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    RecommendMessagePayload,
    unknown,
    RecommendMessagePayload,
    (string | Pick<Chat, 'clientNumber' | 'sources'>)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const apiKey = useApiKey();
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useQuery(
    ['recommendation-message', selectedChat],
    () =>
      services.whatsApp.chat.recommendMessage({
        key: apiKey,
        number: selectedChat.clientNumber!,
        sources: selectedChat.sources,
      }),
    {
      enabled: enabled,
      ...options,
    }
  );
};

export default useRecommendMessage;
