/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Info } from 'react-feather';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { twMerge as cx } from 'tailwind-merge';
import {
  BBBCustomTooltipChart,
  BBBCustomTooltipChartProps,
} from '../BBBCustomTooltipChart';

import {
  BBBCard,
  BBBSpinner,
  BBBTooltip,
  BBBTooltipProps,
  IBBBCard,
} from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { Paths } from '@/types/utils/nestedObjectPath';

export const COLORS_PALLETS = [
  '#FD823E',
  '#AF1900',
  '#E83800',
  '#007F9F',
  '#53B5D0',
  '#FCEFD7',
  '#C5B3D9',
  '#9E87AF',
  '#6D63AD',
];

export type BBBPieChartProps<T extends { [k: string]: unknown }> = {
  title?: string | React.ReactNode;
  containerClassName?: string;
  data: T[] | undefined;
  tooltip?: BBBTooltipProps['content'];
  colors?: string[];
  dataKey: Paths<T>;
  loading?: boolean;
  renderLegend: (data: T[] | undefined, colors: string[]) => React.ReactNode;

  tooltipProps?: BBBCustomTooltipChartProps;
} & IBBBCard;

const BBBPieChart = <
  T extends {
    [k: string]: unknown;
  }
>({
  title,
  containerClassName,
  data,
  tooltip,
  colors = COLORS_PALLETS,
  dataKey,
  children,
  loading,

  // Legend Props
  renderLegend,

  tooltipProps,
  ...props
}: BBBPieChartProps<T>) => {
  const isMobile = useResponsive('sm');

  const isHasData = data?.some((item) => item[dataKey] !== 0);

  if (isMobile) return null;

  return (
    <BBBCard
      className={cx(containerClassName)}
      title={
        tooltip ? (
          <div className="flex gap-1 items-center w-fit">
            <p className="text-base font-normal whitespace-nowrap">{title}</p>
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              position="top"
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          </div>
        ) : (
          <p className="text-base font-normal whitespace-nowrap">{title}</p>
        )
      }
      {...props}
    >
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <BBBSpinner />
        </div>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart data={data}>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                content={() => renderLegend(data, colors)}
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<BBBCustomTooltipChart {...tooltipProps} />}
              />
              {isHasData ? (
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#FED5BF"
                  dataKey={dataKey}
                >
                  {data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              ) : (
                <Pie
                  data={[{ name: 'No Data', [dataKey]: 1 }]}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  stroke="#FD823E"
                  fill="transparent"
                  dataKey={dataKey}
                />
              )}
            </PieChart>
          </ResponsiveContainer>
          {children}
        </>
      )}
    </BBBCard>
  );
};

export default BBBPieChart;
