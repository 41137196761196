import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ChevronLeft, ChevronRight, Download, X } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';
import ChatPreviewProfilePicture from '../../ChatPanel/ChatPreview/Chat/ProfilePicture';

import BBBLogo from '@/assets/icons/BBBLogo';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { selectActiveChatTruthy } from '@/hooks/whatsApp/chat';
import useMedia from '@/hooks/whatsApp/useMedia';
import useMedias from '@/hooks/whatsApp/useMedias';
import { setZoom } from '@/stores/bitCRM';
import { Chat } from '@/types/whatsApp/chat';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { formatWhatsAppTime2 } from '@/utils/bitCRM';
import { fileNameFromUrl } from '@/utils/common/file';

export default function ImageZoom() {
  const zoomStatus = useAppSelector((state) => state.bitCRM.zoom.status);

  if (!zoomStatus) return null;

  return <_ImageZoom />;
}

function _ImageZoom() {
  const mediasQuery = useMedias();

  if (mediasQuery.status === 'loading') return null;

  if (mediasQuery.status === 'error') return null;

  const flattenedData = mediasQuery.data.pages
    .flatMap((page) => page.data)
    .reverse();

  return (
    <__ImageZoom
      data={flattenedData}
      fetchNextPage={mediasQuery.fetchNextPage}
      fetchPreviousPage={mediasQuery.fetchPreviousPage}
      hasNextPage={mediasQuery.hasNextPage}
      hasPreviousPage={mediasQuery.hasPreviousPage}
    />
  );
}

function __ImageZoom({
  data,
  fetchNextPage,
  fetchPreviousPage,
  hasNextPage,
  hasPreviousPage,
}: {
  data: MessageNewAssociation[];
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
}) {
  const zoomId = useAppSelector((state) => state.bitCRM.zoom.chatId);

  const [zoomedId, setZoomedId] = useState(zoomId);

  useEffect(() => {
    setZoomedId(zoomId);
  }, [zoomId]);

  const image = data.find((image) => image.messageId2 === zoomedId);
  const currentIndex = data.findIndex(
    (_image) => _image.messageId2 === zoomedId
  );

  const dispatch = useAppDispatch();

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        dispatch(
          setZoom({
            status: false,
            chatId: undefined,
          })
        );
      }
    },
    [dispatch]
  );

  const onNext = useCallback(() => {
    const currentIndex = data.findIndex(
      (_data) => _data.messageId2 === zoomedId
    );
    const nextId = data[currentIndex + 1].messageId2;
    setZoomedId(nextId);
  }, [data, zoomedId]);

  const onPrev = useCallback(() => {
    const currentIndex = data.findIndex(
      (_data) => _data.messageId2 === zoomedId
    );
    const prevId = data[currentIndex - 1].messageId2;
    setZoomedId(prevId);
  }, [data, zoomedId]);

  const arrowPress = useCallback(
    (event) => {
      if (event.key === 'ArrowRight') {
        if (currentIndex < data.length - 1) {
          onNext();
        } else {
          if (hasPreviousPage) {
            fetchPreviousPage();
          }
        }
      }
      if (event.key === 'ArrowLeft') {
        if (currentIndex > 0) {
          onPrev();
        } else {
          if (hasNextPage) {
            fetchNextPage();
          }
        }
      }
    },
    [
      currentIndex,
      data.length,
      fetchNextPage,
      fetchPreviousPage,
      hasNextPage,
      hasPreviousPage,
      onNext,
      onPrev,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    document.addEventListener('keydown', arrowPress, false);

    return () => {
      document.removeEventListener('keydown', arrowPress, false);
    };
  }, [arrowPress, escFunction]);

  return createPortal(
    <>
      <div className="absolute top-0 left-0 z-[1000] right-0 bottom-0 flex flex-col h-full">
        <div className="flex justify-between items-center py-2 px-3 bg-white">
          {image && (
            <UserInfo
              id={image.messageId}
              fromMe={image.fromMe}
              timestamp={image.timestamp}
            />
          )}
          {image && (
            <Action
              oldId={image.messageId}
              originalMedia={image.media || undefined}
              source={image.sources}
            />
          )}
        </div>
        <div className="grow bg-white relative">
          <div className="absolute top-0 left-0 right-0 bottom-0 flex px-3">
            <div
              className={cx(
                `self-center cursor-pointer`,
                !hasNextPage
                  ? 'pointer-events-none opacity-20'
                  : 'pointer-events-auto opacity-100'
              )}
              onClick={onPrev}
            >
              <ChevronLeft />
            </div>
            <div className="grow flex justify-center items-center">
              {image && (
                <BigPreview
                  oldId={image.messageId}
                  source={image.sources}
                  originalMedia={image.media || undefined}
                />
              )}
            </div>
            <div
              className={cx(
                `self-center cursor-pointer`,
                !hasPreviousPage
                  ? 'pointer-events-none opacity-20'
                  : 'pointer-events-auto opacity-100'
              )}
              onClick={onNext}
            >
              <ChevronRight />
            </div>
          </div>
        </div>
        <div className="bg-white px-3 flex gap-2 w-full h-24 items-center overflow-x-auto">
          {data.map((chat) => {
            const id = chat.messageId2;

            return (
              <SmallPreview
                id={id}
                oldId={chat.messageId}
                key={id}
                zoomId={zoomedId}
                onChangeZoomId={() => setZoomedId(id)}
                originalMedia={chat.media || undefined}
                source={chat.sources}
              />
            );
          })}
        </div>
      </div>
    </>,
    document.body
  );
}

function Action({
  originalMedia,
  source,
  oldId,
}: {
  originalMedia?: string;
  oldId: string;
  source: Chat['sources'];
}) {
  const dispatch = useAppDispatch();

  const { data: mediaData } = useMedia(
    {
      id: oldId,
    },
    {
      enabled:
        (source === 'WHATSAPP' || source === 'WHATSAPP_META') && !originalMedia,
    }
  );

  const mediaUrl = originalMedia || mediaData;

  const toDataURL = async (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));

  const handleDownload = async () => {
    if (mediaUrl) {
      const linkSource = mediaUrl;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('target', '_blank');
      downloadLink.href = await toDataURL(linkSource as string);
      downloadLink.download = fileNameFromUrl(mediaUrl);
      downloadLink.click();
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Download cursor="pointer" onClick={handleDownload} />
      <X
        cursor="pointer"
        onClick={() =>
          dispatch(
            setZoom({
              status: false,
              chatId: undefined,
            })
          )
        }
      />
    </div>
  );
}

function UserInfo({
  fromMe,
  timestamp,
}: {
  fromMe?: boolean;
  timestamp: string;
  id: string;
}) {
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return (
    <div className="flex gap-2">
      {selectedChat && <ChatPreviewProfilePicture {...selectedChat} />}
      <div>
        <div className="font-medium">
          {fromMe ? 'You' : selectedChat.clientNumber}
        </div>
        {timestamp && (
          <div className="text-gray-500 text-sm">
            {formatWhatsAppTime2(timestamp)}
          </div>
        )}
      </div>
    </div>
  );
}

function BigPreview({
  oldId,
  source,
  originalMedia,
}: {
  oldId: string;
  source: Chat['sources'];
  originalMedia?: string;
}) {
  const [zoomIn, setZoomIn] = useState<boolean>(false);
  const [fallbackToDefault, setFallbackToDefault] = useState(false);

  const { data: mediaData } = useMedia(
    {
      id: oldId,
    },
    {
      enabled:
        (source === 'WHATSAPP' || source === 'WHATSAPP_META') && !originalMedia,
    }
  );

  const fallbackUrl = originalMedia || mediaData;

  useEffect(() => {
    setFallbackToDefault(false);
  }, [fallbackUrl]);

  if (!fallbackUrl || fallbackToDefault) {
    return <BBBLogo />;
  }

  return (
    <img
      src={fallbackUrl}
      alt="preview"
      className={cx(
        `h-[90%] z-10 transition-transform transform max-w-full object-contain`,
        zoomIn ? 'scale-[1.3] cursor-zoom-out' : 'cursor-zoom-in'
      )}
      onClick={() => {
        setZoomIn((prev) => !prev);
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        setFallbackToDefault(true);
      }}
    />
  );
}

function SmallPreview({
  id,
  zoomId,
  onChangeZoomId,
  oldId,
  originalMedia,
  source,
}: {
  id: string;
  zoomId?: string;
  onChangeZoomId: () => void;
  oldId: string;
  originalMedia?: string;
  source: Chat['sources'];
}) {
  const [fallbackToDefault, setFallbackToDefault] = useState(false);

  const { data: mediaData } = useMedia(
    {
      id: oldId,
    },
    {
      enabled:
        (source === 'WHATSAPP' || source === 'WHATSAPP_META') && !originalMedia,
    }
  );

  const fallbackUrl = originalMedia || mediaData;

  useEffect(() => {
    setFallbackToDefault(false);
  }, [fallbackUrl]);

  if (fallbackToDefault) {
    return <BBBLogo />;
  }

  return (
    <div
      className={cx(
        `flex-none border-2 cursor-pointer rounded-lg overflow-hidden`,
        zoomId === id ? 'w-12 h-12 border' : 'w-16 h-16 border-transparent'
      )}
      onClick={() => onChangeZoomId()}
    >
      <img
        className="w-full h-full object-cover"
        src={fallbackUrl}
        alt="preview"
        loading="lazy"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          setFallbackToDefault(true);
        }}
      />
    </div>
  );
}
