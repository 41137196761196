import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image, Loader, PlusCircle, Trash2, X } from 'react-feather';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import { isEqual, mapValues } from 'lodash-es';
import * as yup from 'yup';

import { ProductWithVariants } from '@/api/services/customer/products';
import {
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBFileUpload,
  BBBPrimarySwitch,
  BBBRichTextEditor,
  BBBTextInput,
} from '@/components/ui';
import BBBContainer from '@/components/ui/BBBContainer/BBBContainer';
import BBBPriceInput from '@/components/ui/BBBTextInput/BBBPriceInput';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import { useUploadFile } from '@/hooks/common/upload';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useProduct, useUpsertProduct } from '@/hooks/customers/products';
import { ProductVariant } from '@/types/customers';
import { FileType } from '@/types/utils/file';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import { formatInternational } from '@/utils/common/phone';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from '@/utils/common/rich';
import { richValidation } from '@/utils/common/validation';
import { cn } from '@/utils/styles';

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: richValidation,
  image: yup
    .array()
    .of(yup.mixed<FileType>())
    .min(1, 'At least one image is required'),
  price: yup.string().when('options', {
    is: (options: FormSchema['options']) =>
      !options ||
      options.filter((opt) => opt.name && opt.values.filter(Boolean).length)
        .length === 0,
    then: (schema) =>
      schema.required('Pricing is required if this product don’t have variant'),
    otherwise: (schema) => schema,
  }),
  variants: yup.lazy((obj) =>
    yup.object(
      mapValues(obj, () =>
        yup.object({
          price: yup.string().required('Price is required'),
          discountedPrice: yup.string(),
          image: yup.string().nullable().required('Image is required'),
          id: yup.string(),
          optionValueMap: yup.object(),
        })
      )
    )
  ),
});

type FormSchema = {
  title: string;
  description: EditorState;
  image: FileType[];
  displayOnWhatsappCatalog: boolean;
  variants: Record<
    string,
    {
      price: string;
      discountedPrice: string;
      image: string | null;
      id?: string;
      optionValueMap: Record<string, string> | null;
    }
  >;
  options: {
    name: string;
    values: string[];
  }[];
  price: string;
  discountedPrice: string;
  showDiscountedPrice: boolean;
};

const _defaultValues: FormSchema = {
  title: '',
  description: EditorState.createEmpty(),
  image: [],
  options: [
    {
      name: '',
      values: [],
    },
  ],
  variants: {},
  displayOnWhatsappCatalog: false,
  price: '',
  discountedPrice: '',
  showDiscountedPrice: false,
};

const getDefaultValues = (data?: ProductWithVariants) => {
  if (!data) return _defaultValues;

  const options = data.productOptions.length
    ? data.productOptions.map((option) => ({
        name: option.name,
        values: option.productOptionValues.map((value) => value.value),
      }))
    : _defaultValues.options;

  const { variants } = generateCombinations(options);

  return {
    title: data.name,
    description: data.description
      ? convertHtmlToEditorState(data.description)
      : _defaultValues.description,
    displayOnWhatsappCatalog: data.displayOnWhatsappCatalog,
    variants: variants.reduce<Record<string, any>>(
      (acc, { variant, optionValueMap }) => {
        const variantData = data.variants.find((v) =>
          isEqual(v.optionValueMap, optionValueMap)
        );

        acc[variant] = {
          price: variantData?.price ? variantData.price.toString() : '',
          discountedPrice: variantData?.price
            ? variantData.price.toString()
            : '',
          image: variantData?.media || null,
          id: variantData?.id,
          optionValueMap: variantData?.optionValueMap || optionValueMap,
        };

        return acc;
      },
      {}
    ),
    options,
    image: data.medias.length
      ? data.medias.map((media) => convertRemoteUrlToFileType(media))
      : _defaultValues.image,
    price:
      data.variants.length === 1 && data.variants[0].key === 'Default'
        ? data.variants[0].price.toString()
        : _defaultValues.price,
    discountedPrice:
      data.variants.length === 1 && data.variants[0].key === 'Default'
        ? data.variants[0].compareAtPrice?.toString() || ''
        : _defaultValues.discountedPrice,
    showDiscountedPrice:
      data.variants.length === 1 && data.variants[0].key === 'Default'
        ? data.variants[0].showDiscountedPrice
        : _defaultValues.showDiscountedPrice,
  };
};

function ProductsDetail() {
  const { id } = useParams<{ id: string }>();

  const { data } = useProduct(id);

  const formContext = useCustomForm<FormSchema>({
    defaultValues: _defaultValues,
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(id !== 'new');

  const { reset, control, formState } = formContext;

  const defaultValues = useMemo(() => getDefaultValues(data), [data]);

  useEffect(() => {
    if (data) {
      reset(defaultValues);
      setLoading(false);
    }
  }, [defaultValues, reset, data]);

  if (loading) {
    return <ProductDetailSkeleton />;
  }

  return (
    <FormProvider {...formContext}>
      <BBBContainer
        hasHeading
        hasBack
        pageTitle={id === 'new' ? 'Add product' : data?.name}
        backUrl="/products"
        rightComponent={<Action defaultValues={defaultValues} />}
      >
        <div className="flex items-start gap-5">
          <div className="flex-none w-2/3">
            <BBBCard title="Product detail" className="mb-5">
              <BBBTextInput
                label="Title"
                placeholder="Enter product title"
                isHookForm
                controlName="title"
                control={control}
                error={formState.errors.title?.message}
              />
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <BBBRichTextEditor
                    label="Description"
                    placeholder="Enter product description"
                    rows={5}
                    editorState={field.value}
                    onChangeEditorState={field.onChange}
                    error={formState.errors.description?.message}
                  />
                )}
              />
              <ImageUploader />
            </BBBCard>
            <VariantCard />
          </div>
          <div className="flex-none w-1/3">
            <WhatsappCatalogCard />
            {id === 'new' ||
            (data?.variants.length === 1 &&
              data.variants[0].key === 'Default') ? (
              <PricingCard />
            ) : null}
          </div>
        </div>
      </BBBContainer>
    </FormProvider>
  );
}

export default ProductsDetail;

function PricingCard() {
  const { control, watch, formState } = useFormContext<FormSchema>();

  const showDiscountedPrice = watch('showDiscountedPrice');

  return (
    <BBBCard
      title="Pricing"
      desc={
        <>
          You can change currency settings{' '}
          <Link className="text-link" to="/settings?section=timezone">
            here
          </Link>
        </>
      }
    >
      <Controller
        control={control}
        name="price"
        render={({ field }) => (
          <BBBPriceInput
            totalPrice={field.value}
            onChangeTotalPrice={(value) => {
              field.onChange(value);
            }}
            containerClassName="mb-6"
            labelTotalPrice="Price"
            errorTotalPrice={formState.errors.price?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="showDiscountedPrice"
        render={({ field }) => (
          <BBBCheckbox
            label="Show discounted price"
            checked={field.value}
            onValueChange={(value) => {
              field.onChange(value);
            }}
          />
        )}
      />
      {showDiscountedPrice && (
        <Controller
          control={control}
          name="discountedPrice"
          render={({ field }) => (
            <BBBPriceInput
              totalPrice={field.value}
              onChangeTotalPrice={(value) => {
                field.onChange(value);
              }}
              containerClassName="mt-6"
              labelTotalPrice="Discounted price"
            />
          )}
        />
      )}
    </BBBCard>
  );
}

function VariantCard() {
  const { control, watch, setValue, formState } = useFormContext<FormSchema>();

  const options = watch('options');
  const { variants: flattenedCombinations } = generateCombinations(options);

  return (
    <BBBCard
      title="Variant"
      className="mb-5"
      desc={
        <>
          You can change currency settings{' '}
          <Link className="text-link" to="/settings?section=timezone">
            here
          </Link>
        </>
      }
    >
      <div>
        {options.map((option, index) => (
          <div key={index} className="mb-4">
            {/* Variant Options Section */}
            <div className="flex items-center gap-4">
              <Controller
                control={control}
                name={`options.${index}.name`}
                render={({ field }) => (
                  <BBBTextInput
                    label="Option name"
                    placeholder="e.g. Color, Size"
                    containerClassname="flex-1"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);

                      const { variants: keys } = generateCombinations(
                        watch('options')
                      );

                      setValue('variants', {
                        ...Object.fromEntries(
                          keys.map(({ variant: d, optionValueMap }) => [
                            d,
                            watch('variants')[d] || {
                              price: '',
                              discountedPrice: '',
                              image: null,
                              optionValueMap,
                            },
                          ])
                        ),
                      });
                    }}
                  />
                )}
              />
              <div className="flex items-end">
                <button
                  className="text-red-500 h-10"
                  onClick={() => {
                    setValue(
                      'options',
                      watch('options').filter((_, i) => i !== index)
                    );

                    const newVariants = Object.fromEntries(
                      Object.entries(watch('variants')).map(([k, v]) => {
                        const newOptionValueMap = {
                          ...v.optionValueMap,
                        };

                        const previousValue = newOptionValueMap[option.name];

                        delete newOptionValueMap[option.name];

                        const newValue = {
                          ...v,
                          optionValueMap: newOptionValueMap,
                        };

                        const newKey = k
                          .split('-')
                          .filter((v) => v !== previousValue)
                          .join('-');

                        return [newKey, newValue];
                      })
                    );

                    setValue('variants', newVariants);
                  }}
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>

            <div className="pl-4">
              {/* Option Values */}
              {option.values.map((value, valueIndex) => (
                <div className="flex items-center gap-4" key={valueIndex}>
                  <Controller
                    control={control}
                    name={`options.${index}.values.${valueIndex}`}
                    render={({ field }) => (
                      <BBBTextInput
                        placeholder="e.g. Red, XL"
                        containerClassname="flex-1"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);

                          const { variants: keys } = generateCombinations(
                            watch('options')
                          );

                          setValue('variants', {
                            ...Object.fromEntries(
                              keys.map(({ variant: d, optionValueMap }) => [
                                d,
                                watch('variants')[d] || {
                                  price: '',
                                  discountedPrice: '',
                                  image: null,
                                  optionValueMap,
                                },
                              ])
                            ),
                          });
                        }}
                        error={
                          option.values
                            .filter((v, i) => i !== valueIndex)
                            .includes(field.value)
                            ? 'Option value conflicts'
                            : ''
                        }
                      />
                    )}
                  />
                  <div
                    className="flex items-center"
                    onClick={() => {
                      setValue(
                        `options.${index}.values`,
                        watch(`options.${index}.values`).filter(
                          (_, i) => i !== valueIndex
                        )
                      );

                      const newVariants = Object.fromEntries(
                        Object.entries(watch('variants')).filter(
                          ([k, v]) => v.optionValueMap?.[option.name] !== value
                        )
                      );

                      setValue('variants', newVariants);
                    }}
                  >
                    <button className="text-red-500 h-10">
                      <X size={18} />
                    </button>
                  </div>
                </div>
              ))}
              {/* Add Option Value Button */}
              <BBBButton
                variant="secondary"
                size="sm"
                onClick={() => {
                  setValue(`options.${index}.values`, [
                    ...watch(`options.${index}.values`),
                    '',
                  ]);
                }}
              >
                Add option value
              </BBBButton>
            </div>
          </div>
        ))}

        {/* Add Option Button */}
        <BBBButton
          variant="secondary"
          size="sm"
          className="mt-3"
          onClick={() => {
            setValue('options', [
              ...watch('options'),
              { name: '', values: [] },
            ]);
          }}
        >
          Add option
        </BBBButton>
      </div>
      {flattenedCombinations.map(({ variant }) => (
        <div className="my-6" key={variant}>
          <div className="flex items-center gap-2">
            <Controller
              control={control}
              name={`variants`}
              render={({ field }) => (
                <>
                  <VariantMedia
                    variant={variant}
                    media={field.value[variant]?.image || undefined}
                    onChangeMedia={(media) => {
                      const previousValue = watch(`variants`);

                      if (media) {
                        field.onChange({
                          ...previousValue,
                          [variant]: {
                            ...previousValue[variant],
                            image: media,
                          },
                        });
                      } else {
                        field.onChange({
                          ...previousValue,
                          [variant]: {
                            ...previousValue[variant],
                            image: null,
                          },
                        });
                      }
                    }}
                    error={formState.errors.variants?.[variant]?.image?.message}
                  />

                  <BBBTextInput
                    placeholder="Enter variant title"
                    containerClassname="mb-0"
                    value={variant}
                    readOnly
                  />

                  <BBBPriceInput
                    totalPrice={field.value[variant]?.price || ''}
                    onChangeTotalPrice={(value) => {
                      field.onChange({
                        ...field.value,
                        [variant]: {
                          ...field.value[variant],
                          price: value,
                        },
                      });
                    }}
                  />
                </>
              )}
            />
          </div>
          {(formState.errors.variants?.[variant]?.image ||
            formState.errors.variants?.[variant]?.price) && (
            <div className="text-red-500">
              {formState.errors.variants?.[variant]?.image &&
              formState.errors.variants?.[variant]?.price
                ? 'Image and price are required for this variant'
                : formState.errors.variants?.[variant]?.image
                ? formState.errors.variants?.[variant]?.image.message
                : formState.errors.variants?.[variant]?.price?.message}
            </div>
          )}
        </div>
      ))}
    </BBBCard>
  );
}

function VariantMedia({
  media,
  onChangeMedia,
  error,
  variant,
}: {
  media?: string;
  onChangeMedia: (media: string | null) => void;
  error?: string;
  variant: string;
}) {
  const { uploadFile } = useUploadFile();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const remoteUrl = await uploadFile(file);

    onChangeMedia(remoteUrl);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
  });

  return (
    <div
      className={cn(
        'w-16 h-16 flex-none rounded-lg border border-neutral-300 relative cursor-pointer',
        !media &&
          'border-dashed flex items-center justify-center flex-col gap-1',
        error && 'border-red-500'
      )}
      data-form-name={`variants.${variant}.image`}
      {...getRootProps()}
    >
      {media ? (
        <>
          <img
            src={media}
            alt="variant"
            className="w-full h-full object-cover rounded-lg"
          />
          <div
            className="absolute top-1 right-1 cursor-pointer w-6 h-6 z-10 rounded-full bg-[#9E9E9E]/80 flex items-center justify-center text-white"
            onClick={(e) => {
              e.stopPropagation();
              onChangeMedia(null);
            }}
          >
            <X size={'1.5rem'} />
          </div>
        </>
      ) : (
        <>
          <Image size={'0.8125rem'} className="text-neutral-500" />
          <span className="text-[0.625rem] text-neutral-50">Add image</span>
        </>
      )}
      <input {...getInputProps()} />
    </div>
  );
}

function ImageUploader() {
  const { control, watch, setValue, formState } = useFormContext<FormSchema>();

  const { uploadFile, loading: loadingUploadFile } = useUploadFile();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const remoteUrl = await uploadFile(file);

    setValue('image', [
      ...watch('image'),
      convertRemoteUrlToFileType(remoteUrl),
    ]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
  });

  const images = watch('image').filter(Boolean);

  return (
    <div>
      <div>Image</div>
      {images.length > 0 ? (
        <div className="flex items-center overflow-x-auto w-full gap-2.5 mb-2.5">
          {images.map((image, index) => (
            <div
              key={index}
              className="flex-none mb-3 w-[7.5rem] h-[7.5rem] bg-neutral-100 rounded-lg border border-neutral-300 relative"
            >
              <img
                src={image.remoteUrl}
                alt="product"
                className="w-full h-full object-cover rounded-lg"
              />
              <div
                className="absolute top-1 right-1 cursor-pointer w-6 h-6 z-10 rounded-full bg-[#9E9E9E]/80 flex items-center justify-center text-white"
                onClick={() => {
                  setValue(
                    'image',
                    images.filter((_, i) => i !== index)
                  );
                }}
              >
                <X size={'1.5rem'} />
              </div>
            </div>
          ))}
          <div
            className="cursor-pointer border-dashed flex-none w-[7.5rem] h-[7.5rem] mb-3 rounded-lg border border-neutral-300 text-neutral-500 flex flex-col items-center gap-2.5 justify-center"
            {...getRootProps()}
          >
            {loadingUploadFile &&
            Object.values(loadingUploadFile).some(Boolean) ? (
              <div className="animate-spin">
                <Loader size={18} />
              </div>
            ) : (
              <>
                <PlusCircle size={18} />
                <span className="text-xs">Add image</span>
              </>
            )}
            <input {...getInputProps()} />
          </div>
        </div>
      ) : (
        <Controller
          control={control}
          name="image"
          render={({ field }) => (
            <BBBFileUpload
              isSingle
              files={field.value[0]}
              onChangeFile={(data) => {
                field.onChange([data]);
              }}
            />
          )}
        />
      )}
      {formState.errors.image && (
        <div className="text-red-500">{formState.errors.image.message}</div>
      )}
      {!!images.length && (
        <div
          className="underline text-sm cursor-pointer"
          onClick={() => {
            setValue('image', []);
          }}
        >
          Remove all image
        </div>
      )}
    </div>
  );
}

function WhatsappCatalogCard() {
  const { data: cloudApiIntegration } = useWhatsappCloudIntegration();
  const hasCloudApiIntegration = !!cloudApiIntegration;

  const connect = useConnectIntegration();

  const { control } = useFormContext<FormSchema>();

  return (
    <BBBCard
      title="WhatsApp catalog"
      className="mb-5"
      rightButton={
        <a
          className="text-link underline"
          href="#"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      }
    >
      {hasCloudApiIntegration ? (
        <>
          <Controller
            control={control}
            name="displayOnWhatsappCatalog"
            render={({ field }) => (
              <BBBPrimarySwitch
                label="Display product on WhatsApp catalog"
                containerClassName="mb-6"
                checked={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
          <p className="text-neutral-500 text-sm">
            Connected to{' '}
            <span className="font-semibold">
              {formatInternational(cloudApiIntegration?.extra?.number)}
            </span>
          </p>
        </>
      ) : (
        <BBBButton
          variant="secondary"
          onClick={() => {
            connect({ name: 'whatsapp_meta' });
          }}
        >
          Connect to WhatsApp API
        </BBBButton>
      )}
    </BBBCard>
  );
}

function Action({ defaultValues }: { defaultValues: FormSchema }) {
  const { handleSubmit, reset, watch } = useFormContext<FormSchema>();

  const { mutate: upsertProduct, isLoading: loadingUpsertProduct } =
    useUpsertProduct();

  const isFormEqual = isEqual(watch(), defaultValues);
  const { id } = useParams<{ id: string }>();

  return (
    <div className="flex items-center gap-2">
      <BBBButton
        variant="secondary"
        onClick={() => {
          reset(defaultValues);
        }}
      >
        Discard product
      </BBBButton>
      <BBBButton
        onClick={() => {
          handleSubmit((data) => {
            const payload = {
              productId: id === 'new' ? undefined : id,
              title: data.title,
              description: data.description
                ? convertEditorStateToHtml(data.description)!
                : '',
              image: data.image
                .filter((image) => !!image.remoteUrl)
                .map((image) => image.remoteUrl!),
              displayOnWhatsappCatalog: data.displayOnWhatsappCatalog,
              variants: Object.entries(data.variants).map(([key, value]) => ({
                key,
                price: value.price ? parseFloat(value.price) : 0,
                media: value.image,
                id: value.id,
                optionValueMap: value.optionValueMap,
              })),
              options: data.options
                .filter((option) => !!option.name)
                .map((option) => ({
                  key: option.name,
                  values: option.values.filter((value) => !!value),
                })),
              price: data.price ? parseFloat(data.price) : undefined,
              discountedPrice: data.discountedPrice
                ? parseFloat(data.discountedPrice)
                : undefined,
              showDiscountedPrice: data.showDiscountedPrice,
            };

            upsertProduct(payload);
          })();
        }}
        disabled={isFormEqual}
        loadingState={loadingUpsertProduct}
      >
        Save product
      </BBBButton>
    </div>
  );
}

function ProductDetailSkeleton() {
  return (
    <BBBContainer
      hasHeading
      rightComponent={
        <div className="flex items-center gap-2">
          <BBBButton variant="secondary">Discard product</BBBButton>
          <BBBButton disabled>Save product</BBBButton>
        </div>
      }
    >
      <div className="flex items-start gap-5">
        <div className="flex-none w-2/3">
          <BBBCard title="Product detail" className="mb-5">
            <div className="h-10 bg-neutral-100 rounded mb-6 animate-pulse" />
            <div className="h-32 bg-neutral-100 rounded mb-6 animate-pulse" />
            <div>
              <div className="h-5 bg-neutral-100 w-16 rounded mb-2.5 animate-pulse" />
              <div className="flex items-center overflow-x-auto w-full gap-2.5 mb-2.5">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    className="flex-none mb-3 w-[7.5rem] h-[7.5rem] bg-neutral-100 rounded-lg animate-pulse"
                  />
                ))}
              </div>
            </div>
          </BBBCard>
          <BBBCard title="Variant" className="mb-5">
            <div className="h-10 bg-neutral-100 rounded mb-6 animate-pulse" />
            {Array.from({ length: 3 }).map((_, index) => (
              <div className="flex items-center gap-2 mb-6" key={index}>
                <div className="w-16 h-16 flex-none bg-neutral-100 rounded-lg animate-pulse" />
                <div className="h-10 bg-neutral-100 rounded flex-1 animate-pulse" />
                <div className="h-10 w-32 bg-neutral-100 rounded animate-pulse" />
                <div className="w-8 h-8 bg-neutral-100 rounded animate-pulse" />
              </div>
            ))}
          </BBBCard>
        </div>
        <div className="flex-none w-1/3">
          <BBBCard
            title="WhatsApp catalog"
            className="mb-5"
            rightButton={
              <a
                className="text-link underline"
                href="#"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            }
          >
            <div className="h-8 bg-neutral-100 rounded mb-6 animate-pulse" />
            <div className="h-5 bg-neutral-100 w-48 rounded animate-pulse" />
          </BBBCard>
          <BBBCard title="Pricing">
            <div className="h-10 bg-neutral-100 rounded mb-6 animate-pulse" />
            <div className="h-5 bg-neutral-100 w-40 rounded mb-6 animate-pulse" />
            <div className="h-10 bg-neutral-100 rounded animate-pulse" />
          </BBBCard>
        </div>
      </div>
    </BBBContainer>
  );
}

type Option = {
  name: string;
  values: string[];
};

function generateCombinations(options: Option[]): {
  variants: { variant: string; optionValueMap: Record<string, string> }[];
} {
  // Filter out empty options and options with only empty values
  const validOptions = options.filter(
    (opt) => opt.values.length > 0 && opt.values.some((value) => value !== '')
  );

  if (validOptions.length === 0) {
    return {
      variants: [],
    };
  }

  // Remove duplicate values from each option
  const uniqueValidOptions = validOptions.map((opt) => ({
    ...opt,
    values: [...new Set(opt.values.filter((v) => v !== ''))],
  }));

  const result: { variant: string; optionValueMap: Record<string, string> }[] =
    [];

  const combine = (
    current: string[],
    currentMap: Record<string, string>,
    index: number
  ): void => {
    if (index === uniqueValidOptions.length) {
      if (current.length > 0) {
        const variant = current.join('-');
        result.push({
          variant,
          optionValueMap: currentMap,
        });
      }
      return;
    }

    for (const value of uniqueValidOptions[index].values) {
      if (value) {
        combine(
          [...current, value],
          {
            ...currentMap,
            [uniqueValidOptions[index].name]: value,
          },
          index + 1
        );
      }
    }
  };

  combine([], {}, 0);

  return {
    variants: result,
  };
}
