import { useState } from 'react';
import { Slash } from 'react-feather';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { MessageNewAssociation } from 'types/whatsApp/v3';
import { RenderedMessage } from '..';
import ChatBubbleACK from './ACK';
import ChatBubbleAction from './Action';
import ChatActivity from './Activity';
import ChatBubbleAudio from './Audio';
import ChatBubbleButtons from './Buttons';
import ChatBody from './ChatBody';
import ChatBubbleContact from './Contact';
import ChatBubbleDocument from './Document';
import ChatBubbleImage from './Image';
import ChatInfo from './Info';
import ChatBubbleLocation from './Location';
import ChatBubbleOrder, { ChatBubbleOrderDetailButton } from './Order';
import ChatBubblePool from './Pool';
import ChatBubbleReaction from './Reaction';
import StoryMention from './StoryMention';
import ChatBubbleSummary from './Summary';
import ChatBubbleTimestamp from './Timestamp';
import ChatBubbleVideo from './Video';

import StarIcon2 from '@/assets/icons/StarIcon2';
import { BBBCard, BBBSpinner, IBBBCard } from '@/components/ui';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import useActiveStatus from '@/hooks/whatsApp/useActiveStatus';
import useClickMessage from '@/hooks/whatsApp/useClickMessage';
import useMedia from '@/hooks/whatsApp/useMedia';
import { formatSender } from '@/utils/bitChat';

type Props = {
  next?: MessageNewAssociation;
  onSelectReply?: (chat: MessageNewAssociation) => void;
  isGroup: boolean;
  level?: number;
  sender?: string;
  containerClassName?: string;
  withoutTimestamp?: boolean;
  bubbleClassName?: string;
} & IBBBCard;

type PropsDefault = Props & {
  chat: RenderedMessage;
};

type PropsMessages = Props & {
  chat: MessageNewAssociation;
};

const ratingChatTypes = ['REVIEW_ANSWER', 'REVIEW_QUESTION'];

export default function ChatBubble(props: PropsDefault) {
  if ('__typename' in props.chat) {
    return <ChatSeparator label={props.chat.label} />;
  }

  return <_ChatBubble {...props} chat={props.chat} />;
}

function ChatSeparator({ label }: { label: string }) {
  return (
    <div
      className="flex justify-center items-center my-2"
      data-message-separator
      data-message-separator-label={label}
    >
      <div className="bg-[#9A9A9A] rounded-md px-3 py-2 text-white">
        {label}
      </div>
    </div>
  );
}

function _ChatBubble({
  chat,
  isGroup,
  level = 0,
  sender,
  containerClassName,
  withoutTimestamp,
  className,
  next,
  bubbleClassName,
  ...cardProps
}: PropsMessages) {
  const activeStatus = useActiveStatus();
  const focusedChatId = useAppSelector((state) => state.bitCRM.focusedChatId);
  const source = useAppSelector((state) => state.bitCRM.selectedChat!.sources);

  const [imageError, setImageError] = useState(false);
  const [width, setWidth] = useState<number>();

  const isCustomType =
    chat.type === 'ACTIVITY_JOIN' ||
    chat.type === 'ACTIVITY_CLOSED' ||
    chat.type === 'ACTIVITY_TRANSFER' ||
    chat.type === 'ACTIVITY_SAVED' ||
    chat.type === 'ACTIVITY_EXPIRED' ||
    chat.type === 'ACTIVITY_TOOK_OVER' ||
    chat.type === 'ACTIVITY_ASSIGNED' ||
    chat.type === 'ACTIVITY_TICKET_CREATED' ||
    chat.type === 'ACTIVITY_CUSTOMER_SAVED';

  const chatId = chat.messageId;
  const fromMe = chat.fromMe;
  const nextFromMe = next?.fromMe;
  const color = chat.clientAccent;
  const name = chat.clientName || chat.clientNumber;

  const enabledMedia =
    (source === 'WHATSAPP' || source === 'WHATSAPP_META') &&
    (chat.type === 'IMAGE' ||
      chat.type === 'VIDEO' ||
      chat.type === 'AUDIO' ||
      chat.type === 'STICKER' ||
      chat.type === 'DOCUMENT') &&
    !chat.media;

  const possibleHasMedia = enabledMedia || !!chat.media;

  const { data: mediaData, isLoading: loadingMedia } = useMedia(
    {
      id: chatId,
    },
    {
      enabled: enabledMedia,
    }
  );

  const userId = useUserId();

  const disableNotMine = useAppSelector(
    (s) =>
      activeStatus !== 'active' &&
      s.bitCRM.selectedChat!.ticket?.userId !== userId
  );

  const clientName = useAppSelector(
    (s) =>
      s.bitCRM.selectedChat!.clientName || s.bitCRM.selectedChat!.clientNumber
  );

  const clickMessage = useClickMessage();

  const isQuotedLevel = level > 0;

  if (level > 1) return null;

  const isActualImage =
    chat.type === 'IMAGE' ||
    !!chat.media
      ?.split('/')
      .pop()
      ?.match(/\.(jpg|jpeg|png|gif|webp|svg)$/i);

  const isActualVideo =
    chat.type === 'VIDEO' ||
    !!chat.media
      ?.split('/')
      .pop()
      ?.match(/\.(mp4|mov|avi|wmv|mkv)$/i);

  const isLoadingMedia = enabledMedia && loadingMedia;

  return (
    <motion.div
      className={cx(
        'flex px-4 relative transition-colors',
        !fromMe ? '' : 'justify-end',
        !isCustomType &&
          ((fromMe && !nextFromMe) || (!fromMe && nextFromMe)
            ? 'mb-4'
            : 'mb-2'),
        containerClassName
      )}
    >
      <motion.div
        className="absolute inset-0 bg-secondary-surface"
        animate={{
          opacity: focusedChatId === chat.messageId2 && !isQuotedLevel ? 1 : 0,
        }}
        initial={{ opacity: 0 }}
      />
      <div
        className={cx(
          `relative`,
          !isQuotedLevel
            ? !isCustomType
              ? cx('max-w-[75%] min-w-[25%]', !fromMe ? '' : 'right-0')
              : 'w-full'
            : 'w-full',
          bubbleClassName
        )}
        id={`chat-bubble-${chat.messageId2}${isQuotedLevel ? '-child' : ''}`}
      >
        {isCustomType ? (
          <ChatActivity chat={chat} />
        ) : (
          <div className={cx('w-full inline-block relative')}>
            {chat.type === 'STORY_MENTION' ? (
              <StoryMention
                isFromMe={fromMe}
                src={chat.media}
                description={
                  fromMe
                    ? `You mentioned @${chat.clientName} in your story`
                    : 'Mentioned you in their story'
                }
                chat={chat}
              />
            ) : chat.type === 'STORY_REPLY' ? (
              <StoryMention
                isFromMe={fromMe}
                src={chat.media}
                description={
                  fromMe
                    ? 'You replied to their story'
                    : 'Replied to your story'
                }
                chat={chat}
              />
            ) : chat.type === 'SHARE' ? (
              <StoryMention
                isFromMe={fromMe}
                description={
                  fromMe ? 'You shared a post' : `${clientName} shared a post`
                }
                chat={chat}
                src={chat.media}
                isSquared
              />
            ) : chat.type === 'STICKER' ? (
              <ChatBubbleImage
                chat={chat}
                isQuotedLevel={isQuotedLevel}
                onImageError={setImageError}
                imageError={imageError}
                mediaUrl={mediaData}
                isActualImage={false}
                loadingMedia={isLoadingMedia}
                fromMe={!!fromMe}
              />
            ) : null}
            {(imageError ||
              (chat.type !== 'STICKER' &&
                chat.type !== 'STORY_MENTION' &&
                chat.type !== 'SHARE')) && (
              <BBBCard
                className={cx(
                  `rounded-lg group outline-[1.5px] -outline-offset-[1.5px] outline-none shadow-none transition-colors border-transparent`,
                  fromMe
                    ? `bg-secondary-surface hover:outline-secondary-main hover:shadow-[0px_0px_0px_2.5px_#fed5bf5e] hover:bg-secondary-border/80 rounded-br-none`
                    : 'bg-neutral-10 hover:bg-[#ECECEC] rounded-bl-none hover:outline-[#BABABA] hover:shadow-[0px_0px_0px_2.5px_#ececec6a]',
                  isQuotedLevel
                    ? cx(
                        'cursor-pointer',
                        sender
                          ? 'flex p-0 md:p-0 gap-3 items-center'
                          : 'p-1 md:p-1'
                      )
                    : ' p-2 md:p-2',
                  className
                )}
                onClick={() => {
                  if (isQuotedLevel) {
                    clickMessage({ id: chat.messageId2 });
                  }
                }}
                style={{
                  wordWrap: 'break-word',
                  ...(!isLoadingMedia && {
                    width: width,
                  }),
                }}
                {...cardProps}
              >
                {!sender &&
                  source === 'INSTAGRAM' &&
                  chat.type === 'COMMENT' && (
                    <div
                      className={cx(
                        `text-[#262627] text-sm font-light`,
                        fromMe ? 'text-right' : 'text-left'
                      )}
                    >
                      {name}
                    </div>
                  )}
                {sender && (
                  <div
                    className={cx(
                      `flex-none w-[2px] h-8 rounded-lg`,
                      sender !== 'You'
                        ? 'bg-blue-500'
                        : !isGroup
                        ? 'bg-green-500'
                        : color
                    )}
                  ></div>
                )}
                <div
                  className={isQuotedLevel ? 'flex flex-1 gap-2' : undefined}
                >
                  {isQuotedLevel && isActualImage && possibleHasMedia && (
                    <ChatBubbleImage
                      chat={chat}
                      isQuotedLevel={isQuotedLevel}
                      onWidthChange={setWidth}
                      mediaUrl={mediaData}
                      isActualImage
                      loadingMedia={isLoadingMedia}
                      fromMe={!!fromMe}
                    />
                  )}
                  <div className={isQuotedLevel ? 'my-1' : undefined}>
                    {sender && (
                      <div className="mb-1 font-semibold ">{sender}</div>
                    )}
                    {imageError ? (
                      <ChatInfo>
                        This sticker is not supported, please preview on your
                        phone
                      </ChatInfo>
                    ) : chat.type !== 'UNSUPPORTED' ? (
                      ratingChatTypes.includes(chat.type) ? (
                        <ChatInfo>
                          {chat.type === 'REVIEW_ANSWER'
                            ? 'User Rating Survey Received'
                            : 'User Rating Survey Sent'}
                        </ChatInfo>
                      ) : (
                        <>
                          {!isQuotedLevel && isGroup && (
                            <div className="flex justify-between items-center mb-1">
                              <div
                                className="font-semibold  grow self-end"
                                style={{ color }}
                              >
                                {name}
                              </div>
                            </div>
                          )}
                          {chat.parent && chat.type !== 'FORM_RESPONSE' && (
                            <ChatBubble
                              chat={chat.parent}
                              isGroup={isGroup}
                              level={level + 1}
                              sender={formatSender(chat.parent)}
                              next={next}
                              containerClassName="px-0"
                              className={cx(
                                'overflow-clip',
                                fromMe
                                  ? 'bg-[#FFD2B9] hover:bg-[#FFD2B9] hover:outline-transparent hover:shadow-none'
                                  : 'bg-neutral-20 hover:bg-neutral-20 hover:outline-transparent hover:shadow-none'
                              )}
                            />
                          )}
                          {chat.type === 'REVOKED' ? (
                            <i className="flex items-center gap-1">
                              <Slash size={12} /> This message was deleted.
                            </i>
                          ) : chat.type === 'DOCUMENT' ? (
                            <ChatBubbleDocument
                              mediaUrl={mediaData || chat.media}
                              isLoading={isLoadingMedia}
                              fromMe={!!fromMe}
                            />
                          ) : isActualImage &&
                            possibleHasMedia &&
                            !isQuotedLevel ? (
                            <ChatBubbleImage
                              mediaUrl={mediaData}
                              chat={chat}
                              isQuotedLevel={isQuotedLevel}
                              onWidthChange={setWidth}
                              isActualImage
                              loadingMedia={isLoadingMedia}
                              fromMe={!!fromMe}
                            />
                          ) : isActualVideo ? (
                            <ChatBubbleVideo
                              mediaUrl={mediaData || chat.media}
                              isQuotedLevel={isQuotedLevel}
                              isLoading={isLoadingMedia}
                              fromMe={!!fromMe}
                            />
                          ) : (chat.type === 'LOCATION' ||
                              chat.type === 'LIVE_LOCATION') &&
                            chat.locationMsg ? (
                            <ChatBubbleLocation chat={chat} />
                          ) : chat.type === 'AUDIO' ? (
                            <ChatBubbleAudio
                              mediaUrl={mediaData || chat.media}
                              fromMe={!!fromMe}
                            />
                          ) : chat.type === 'ORDER' &&
                            !!chat.orderMsg?.length ? (
                            <ChatBubbleOrder chat={chat} />
                          ) : chat.type === 'CONTACT' ? (
                            <ChatBubbleContact chat={chat} />
                          ) : chat.type === 'SUMMARY' ? (
                            <ChatBubbleSummary chat={chat} />
                          ) : chat.type === 'POLL' && chat.pollMsg ? (
                            <ChatBubblePool
                              chat={chat}
                              isQuotedLevel={isQuotedLevel}
                            />
                          ) : chat.type === 'MISSED_CALL' ? (
                            <ChatInfo>
                              <div className="italic">Missed voice call</div>
                            </ChatInfo>
                          ) : chat.type === 'MISSED_VIDEO_CALL' ? (
                            <ChatInfo>
                              <div className="italic">Missed video call</div>
                            </ChatInfo>
                          ) : chat.type === 'FORM_RESPONSE' ? (
                            <ChatInfo>
                              <div className="italic my-1">
                                Form Submitted.{' '}
                                <a
                                  href={`/bitchat/whatsapp-form/${chat.formId}/responses`}
                                  target="_blank"
                                  className="text-blue-500 underline"
                                  rel="noreferrer"
                                >
                                  See response here
                                </a>
                              </div>
                            </ChatInfo>
                          ) : chat.type === 'FORM' ? (
                            <ChatInfo>
                              <div className="italic my-1">
                                WhatsApp form sent
                              </div>
                            </ChatInfo>
                          ) : null}
                          {chat.type !== 'SHARE' &&
                            chat.type !== 'REVOKED' &&
                            chat.type !== 'FORM' &&
                            !!chat.body?.length && (
                              <ChatBody
                                body={chat.body}
                                mentionedContacts={chat.mentions}
                                isQuotedLevel={isQuotedLevel}
                              />
                            )}
                          {!isQuotedLevel &&
                            !withoutTimestamp &&
                            chat.type !== 'CONTACT' && (
                              <div className="flex justify-end items-center gap-1">
                                {chat.isStarred && <StarIcon2 />}
                                <ChatBubbleTimestamp chat={chat} />
                                {fromMe && typeof chat.ack !== 'undefined' && (
                                  <ChatBubbleACK ack={chat.ack} />
                                )}
                              </div>
                            )}
                        </>
                      )
                    ) : (
                      <ChatInfo />
                    )}
                  </div>
                </div>
                {!isQuotedLevel &&
                  !!chat.buttonMsg.length &&
                  chat.type != 'FORM' && <ChatBubbleButtons chat={chat} />}
                {!isQuotedLevel && (
                  <>
                    {!!chat.reactionMsg.length && (
                      <ChatBubbleReaction reactions={chat.reactionMsg} />
                    )}
                    {chat.type !== 'REVOKED' && !disableNotMine && (
                      <ChatBubbleAction chat={chat} />
                    )}
                    {chat.type === 'ORDER' && (
                      <ChatBubbleOrderDetailButton chat={chat} />
                    )}
                  </>
                )}
              </BBBCard>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
}

export function MediaLoading({ fromMe }: { fromMe: boolean }) {
  return (
    <div
      className={cx(
        'h-[12.5rem] w-[18.9375rem] rounded-lg flex items-center justify-center transition-colors',
        !fromMe
          ? 'bg-[#DDDDDD] '
          : 'bg-secondary-border group-hover:bg-secondary-surface'
      )}
    >
      <BBBSpinner height={16} width={3} />
    </div>
  );
}
