import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { SocialConfig } from '@/types/bitLogin/v2';
import { toast } from '@/utils/common/toast';

const useUpsertSocialConfig = () => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<SocialConfig>) =>
      services.bitLogin.configs.upsertSocialConfig(data?.domain, payload),
    {
      onMutate: async (newConfig) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries(['bitlogin-config']);
        await queryClient.cancelQueries(['bitlogin-config-provider']);

        // Snapshot the previous value
        const previousConfig = queryClient.getQueryData<SocialConfig[]>([
          'bitlogin-config',
          data?.domain,
        ]);
        const previousProviderConfig = queryClient.getQueryData<SocialConfig>([
          'bitlogin-config-provider',
          data?.domain,
          newConfig.provider,
        ]);

        // Optimistically update
        queryClient.setQueryData<SocialConfig[]>(
          ['bitlogin-config', data?.domain],
          (old) =>
            old?.map((config) =>
              config.provider === newConfig.provider
                ? {
                    ...config,
                    ...newConfig,
                  }
                : config
            )
        );

        queryClient.setQueryData<SocialConfig>(
          ['bitlogin-config-provider', data?.domain, newConfig.provider],
          (old) =>
            old
              ? {
                  ...old,
                  ...newConfig,
                }
              : undefined
        );

        return { previousConfig, previousProviderConfig };
      },
      onError: (err, newConfig, context) => {
        // Rollback on error
        queryClient.setQueryData(
          ['bitlogin-config', data?.domain],
          context?.previousConfig
        );
        queryClient.setQueryData(
          ['bitlogin-config-provider', data?.domain, newConfig.provider],
          context?.previousProviderConfig
        );
        toast.error('Failed to update config');
      },
      onSuccess: () => {
        toast.success('Successfully update config');
      },
      onSettled: () => {
        // Always refetch after error or success
        queryClient.invalidateQueries(['bitlogin-config']);
        queryClient.invalidateQueries(['bitlogin-config-provider']);
      },
    }
  );
};

export default useUpsertSocialConfig;
