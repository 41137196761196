/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import parsePhoneNumber from 'libphonenumber-js';

import { BBBTelInputValue } from '@/components/ui';

const useDefaultCountryCode = (
  fallback?: string | null
): BBBTelInputValue | undefined => {
  const queryData = useQuery<BBBTelInputValue>(['client-country-code'], () =>
    axios
      .get<{
        country_name: string;
        country_calling_code: string;
        country_code: string;
        country_code_iso3: string;
      }>('https://ipapi.co/json/')
      .then((response) => ({
        // remove the + from the country code
        countryCode: response.data.country_calling_code.slice(1),
        phoneNumber: '',
        iso2: response.data.country_code.toLowerCase(),
      }))
  );

  if (fallback) {
    const parsedPhoneNumber = parsePhoneNumber(`+${fallback.replace('+', '')}`);

    if (!parsedPhoneNumber)
      return useMemo(() => queryData.data, [queryData.data]);

    return useMemo(
      () => ({
        countryCode: parsedPhoneNumber.countryCallingCode,
        phoneNumber: parsedPhoneNumber.nationalNumber,
        iso2: parsedPhoneNumber.country?.toLowerCase(),
      }),
      [
        parsedPhoneNumber.country,
        parsedPhoneNumber.countryCallingCode,
        parsedPhoneNumber.nationalNumber,
      ]
    );
  }

  return useMemo(() => queryData.data, [queryData.data]);
};

export default useDefaultCountryCode;
