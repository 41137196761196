import React from 'react';

type Props = {
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function CustomerIcon2({ className, ...props }: Props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7084 10.071C12.9057 10.5859 11.9632 10.86 11 10.86C9.70875 10.86 8.46802 10.3677 7.55137 9.48768C6.6343 8.60729 6.1167 7.41055 6.1167 6.15999C6.1167 5.22763 6.40476 4.31727 6.9429 3.5441C7.48093 2.7711 8.24433 2.17041 9.13521 1.81615C10.026 1.46193 11.0056 1.36943 11.9505 1.54986C12.8954 1.73031 13.765 2.17598 14.4487 2.83231C15.1325 3.48876 15.5997 4.32665 15.7891 5.24073C15.9785 6.1549 15.8811 7.10233 15.5099 7.96265C15.1388 8.82281 14.5112 9.55601 13.7084 10.071ZM12.6569 3.79932C12.1675 3.48538 11.5909 3.31714 11 3.31714C10.2075 3.31714 9.4497 3.6195 8.89286 4.15407C8.33643 4.68824 8.02622 5.41006 8.02622 6.15999C8.02622 6.71946 8.19898 7.26744 8.52419 7.73469C8.84951 8.2021 9.31323 8.5682 9.85805 8.78484C10.403 9.00152 11.0032 9.05839 11.5825 8.94778C12.1616 8.83719 12.692 8.56447 13.1072 8.16591C13.5222 7.76748 13.8034 7.26136 13.9172 6.71227C14.0309 6.16327 13.9727 5.59408 13.7492 5.07612C13.5256 4.55801 13.1462 4.11318 12.6569 3.79932Z"
        fill="#262627"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 14C9.11389 14 8.06616 14.1585 6.41418 14.9102C5.42275 15.3613 4.13915 16.4012 3.5 16.9515V19.5H18.5V16.9515C17.8609 16.4012 16.5772 15.3613 15.5858 14.9102C13.9338 14.1585 12.8861 14 11 14ZM5.58582 13.0898C7.55877 12.192 8.90353 12 11 12C13.0965 12 14.4412 12.192 16.4142 13.0898C17.7096 13.6793 19.2061 14.9192 19.8232 15.4516C20.2648 15.8327 20.5 16.3811 20.5 16.9403V19.5C20.5 20.6046 19.6046 21.5 18.5 21.5H3.5C2.39543 21.5 1.5 20.6046 1.5 19.5V16.9403C1.5 16.3811 1.73521 15.8327 2.1768 15.4516C2.79385 14.9192 4.29043 13.6793 5.58582 13.0898Z"
        fill="#262627"
      />
    </svg>
  );
}

export default CustomerIcon2;
