const GraphIcon = ({
  className,
  width = 11,
  height = 5,
}: {
  className?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 5"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4C1 2.34315 1.5 1 3.5 1C5.5 1 5.78529 4 7.75 4C9.71471 4 10 2.65685 10 1"
        stroke="#FD823E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GraphIcon;
