import { useState } from 'react';
import { Link as _Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import colors from '@/constants/common/colors';

type LinkWithIconProps =
  | {
      isHasIcon?: true;
      show: boolean;
    }
  | {
      isHasIcon?: false;
      show?: never;
    };

type LinkProps = LinkWithIconProps & React.ComponentProps<typeof _Link>;

function Link({ isHasIcon = false, title, ...props }: LinkProps) {
  return isHasIcon ? (
    <LinkWithIcon {...props} title={title} show={props.show} />
  ) : (
    <_Link {...props} />
  );
}

function LinkWithIcon({ show: _show, ...props }: Omit<LinkProps, 'isHasIcon'>) {
  const [show, setShow] = useState<boolean | undefined>(false);
  return (
    <div
      className="flex gap-1 items-center w-full"
      onMouseLeave={() => setShow(false)}
    >
      <_Link {...props}>
        <div
          className={cx('hover:underline transition-all', props.className)}
          onMouseEnter={() => setShow(_show)}
        >
          {props.children}
        </div>
      </_Link>
      {show && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
        >
          <LinkOutIcon size={11} color={colors.neutral['40']} />
        </motion.div>
      )}
    </div>
  );
}

export default Link;
