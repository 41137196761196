import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useLogCampaignChart = (
  params: string,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-analytics', activeCompany, params],
    () =>
      services.bitCRM.v2.analytics.getLogCampaignChart(params, {
        timezone: new Date().toTimeString().slice(9),
      }),
    options
  );
};

export default useLogCampaignChart;
