import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { SocialLoginProvider } from '@/types/bitLogin/v2';

const useSocialConfigProvider = (provider: SocialLoginProvider) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    ['bitlogin-config-provider', data?.domain, provider],
    () =>
      services.bitLogin.configs.getSocialConfigProvider(data?.domain, provider),
    {
      enabled: status === 'success' && !!data,
    }
  );
};

export default useSocialConfigProvider;
