import { useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import SettingsDangerZone from '../DangerZone';

import {
  BBBAlert,
  BBBCard,
  BBBModal,
  BBBPasswordInput,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
} from '@/components/ui';
import { CountryOptions } from '@/constants/systemLogin/countries';
import industries from '@/constants/systemLogin/industries';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useTransferOwnership from '@/hooks/auth/useTransferOwnership';
import { useUserCompany } from '@/hooks/rtk/selector';
import AgentOptions, {
  AgentOption,
} from '@/pages/BitChat/components/AgentOptions';
import { SettingsFormSchema } from '@/pages/Settings';
import { toast } from '@/utils/common/toast';

type CompanySettingsProps = {
  control: Control<SettingsFormSchema>;
  onTransfer: (status: 'loading' | 'success') => void;
};

type TransferSuperAdminForm = {
  agent: AgentOption | null | undefined;
  password: string;
};

const transferCompanySchema = yup.object({
  agent: yup.mixed<TransferSuperAdminForm['agent']>().label('Agent').required(),
  password: yup.string().required().label('Password'),
});

export default function CompanySettings({
  control,
  onTransfer,
}: CompanySettingsProps) {
  const userCompany = useUserCompany();

  const {
    data: isEditCompanyProfileRestricted,
    isInitialLoading: isLoadingEditProfileAccessRights,
  } = useIsModuleRestricted('SETTINGS_COMPANY');

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <BBBCard
        title={'Company'}
        titleClassName="lg:font-normal font-semibold"
        className={cx('mb-cardBottom')}
        rightButton={
          <>
            {userCompany.role === 'SUPER_ADMIN' && (
              <div
                className={cx('text-info-main hover:underline cursor-pointer')}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Transfer super admin role
              </div>
            )}
            {showModal && (
              <TransferCompanyModal
                onHide={() => setShowModal(false)}
                onTransfer={onTransfer}
              />
            )}
          </>
        }
      >
        {isLoadingEditProfileAccessRights ? (
          <BBBSpinner />
        ) : (
          <div
            className={cx(
              isEditCompanyProfileRestricted && 'pointer-events-none opacity-50'
            )}
          >
            <BBBTextInput
              isHookForm
              control={control}
              controlName="companyName"
              label="Company Name"
              placeholder="Enter company name"
            />
            <Controller
              control={control}
              name="companyCountry"
              render={({ field }) => (
                <BBBSelect
                  options={CountryOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={field.value}
                  onValueChange={field.onChange}
                  isSearchable
                  containerClassName="mb-3"
                  label="Country"
                  placeholder="Select country"
                />
              )}
            />

            <Controller
              control={control}
              name="companyIndustry"
              render={({ field }) => (
                <BBBSelect
                  label="Industries"
                  value={field.value}
                  onValueChange={field.onChange}
                  options={industries}
                  optionLabel="label"
                  optionValue="value"
                  isSearchable
                  placeholder="What industry are you in?"
                />
              )}
            />
          </div>
        )}
      </BBBCard>
      <SettingsDangerZone type="company" />
    </>
  );
}

function TransferCompanyModal({
  onHide,
  onTransfer,
}: {
  onHide: () => void;
  onTransfer: (status: 'loading' | 'success') => void;
}) {
  const {
    handleSubmit,
    formState: { errors },
    control: transferSuperAdminControl,
    watch,
    setError,
  } = useForm<TransferSuperAdminForm>({
    resolver: yupResolver(transferCompanySchema),
    defaultValues: { agent: null, password: '' },
  });

  const { mutate: transferOwnership, isLoading } = useTransferOwnership();

  return (
    <BBBModal
      show
      title="Transfer Super Admin role"
      disableSave={!watch('agent') || !watch('password')}
      size="3xl"
      footer
      submitText="Transfer super admin role"
      loadingSave={isLoading}
      cancelText="Discard"
      handleSave={() => {
        handleSubmit((data) => {
          transferOwnership(
            { userId: data.agent!.userId, password: data.password },
            {
              onSuccess: () => {
                onHide();
                onTransfer('loading');
                setTimeout(() => {
                  onTransfer('success');
                  toast.success(`Super Admin role successfully transferred!`);
                }, 4000);
              },
              onError: (err) => {
                if (axios.isAxiosError(err) && err.response?.status === 400) {
                  setError('password', { message: 'Wrong password!' });
                }
              },
            }
          );
        })();
      }}
      onHide={onHide}
    >
      The new super admin will be able to:
      <ul className="list-disc ml-8 mb-6">
        <li>Have full control over the company</li>
        <li>Remove users from the company or change users permission</li>
        <li>Delete the company</li>
      </ul>
      <Controller
        control={transferSuperAdminControl}
        name="agent"
        render={({ field }) => (
          <AgentOptions
            placeholder="Choose user"
            label="Transfer to"
            containerClassName="mb-6"
            role="STAFF_AGENT,AGENT,ADMIN,STAFF"
            value={field.value || undefined}
            onValueChange={field.onChange}
            error={errors.agent?.message}
          />
        )}
      />
      <BBBPasswordInput
        label="Enter your password to continue:"
        placeholder="Enter password"
        containerClassname="mb-6"
        isHookForm
        control={transferSuperAdminControl}
        controlName="password"
        error={errors.password?.message}
      />
      <BBBAlert type="danger">
        When the transfer is complete, you will lose your unrestricted access to
        the store.
      </BBBAlert>
    </BBBModal>
  );
}
