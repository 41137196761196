import { SVGProps } from 'react';

export default function AgendaIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2 8C1.45 8 .979 7.804.587 7.412A1.925 1.925 0 010 6V2C0 1.45.196.979.587.587A1.928 1.928 0 012 0h14c.55 0 1.021.196 1.413.587C17.804.979 18 1.45 18 2v4c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0116 8H2zm0-2h14V2H2v4zm0 12c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 010 16v-4c0-.55.196-1.021.587-1.413A1.928 1.928 0 012 10h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0116 18H2zm0-2h14v-4H2v4zM2 2v4-4zm0 10v4-4z"
      ></path>
    </svg>
  );
}
