import { useQuery } from '@tanstack/react-query';

import api from '@/config/api';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AutoReply } from '@/types/bitChat/chatbot';
import { ChatbotType1 } from '@/types/bitChat/chatbot';

const useRule = (chatbotType: ChatbotType1) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      'bitchat-chatbot-rule',
      activeCompany,
      {
        chatbotType,
      },
    ],
    () =>
      api.whatsApp.v1
        .get<AutoReply[]>(`/chatbot/company/${activeCompany}/${chatbotType}`)
        .then((res) => res.data)
  );
};

export default useRule;
