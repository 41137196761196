import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { UpdateSortingRulesParams } from '@/api/services/bitAi/v2-new/sorting';
import useHistory from '@/hooks/common/useHistory';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useUpdateSortingRules = () => {
  const activeCompany = useActiveCompany();

  const history = useHistory();
  const client = useQueryClient();

  return useMutation(
    ({
      collectionId,
      rules,
    }: {
      collectionId: string;
    } & UpdateSortingRulesParams) =>
      services.bitAi.v2New.sorting.updateSortingRules(
        activeCompany,
        encodeURIComponent(collectionId),
        {
          features: rules.map((rule) =>
            rule.featuredProduct.map((featureProduct) => featureProduct.id)
          ),
        }
      ),
    {
      onSuccess: () => {
        toast.success('Collection has been updated!');
        client.invalidateQueries(['bitai-sorting-collections', activeCompany]);
        history.push('/bitai/sorting');
      },
    }
  );
};

export default useUpdateSortingRules;
