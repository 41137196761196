import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import { toast } from '@/utils/common/toast';

const useUpdateActivetemplate = () => {
  const queryClient = useQueryClient();
  const activeCompany = useActiveCompany();

  return useMutation(
    (payload: { templateId: string }) =>
      services.bitLogin.template.updateActiveTemplate(activeCompany, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-active-template']);
        toast.success('Successfully update template');
      },
    }
  );
};

export default useUpdateActivetemplate;
