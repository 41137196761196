import { shallowEqual } from 'react-redux';
import {
  InfiniteData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import services from 'api/services';
import { GetChatParams, StartChatParams } from 'api/services/whatsApp/v2/chat';
import { GET_CHATS_DEFAULT_LIMIT } from 'api/services/whatsApp/v3/chat';
import {
  useActiveCompany,
  useApiKey,
  useUser,
  useUserId,
} from 'hooks/rtk/selector';
import { chunk } from 'lodash-es';
import { RootState } from 'stores';
import {
  formatChatTicket,
  setSelectedLivechat,
  UpdateChatJoin,
  UpdateChatRead,
  UpdateChatTransfer,
  updateSelectedChat,
  upsertLivechatMemo,
} from 'stores/bitCRM';
import { Pagination } from 'types/bitCRM/api';
import { Chat, ChatResponse } from 'types/whatsApp/chat';
import { v4 } from 'uuid';
import { formatUserDisplayName } from 'utils/auth';
import { arrayMove } from 'utils/common/array';
import { emptyEditor } from 'utils/common/rich';
import { useAppDispatch, useAppSelector } from '../rtk/store';
import useAccounts from './useAccounts';
import useChatListsQueryKey from './useChatListsQueryKey';

export const useChat = (selectedChat?: {
  clientNumber: string;
  sources: Chat['sources'];
}) => {
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  return useQuery(
    ['chat', selectedChat],
    () =>
      services.whatsApp.chat.getChat(selectedChat!.clientNumber, {
        key: apiKey!,
        sources: selectedChat!.sources,
      }),
    {
      enabled: !!apiKey && !!selectedChat,
    }
  );
};

export const useChat2 = () => {
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  return useMutation(
    ({ sources, conversationId }: Omit<GetChatParams, 'apiKey'>) =>
      services.whatsApp.v2.chat.getChat({
        apiKey: apiKey!,
        sources,
        conversationId,
      })
  );
};

export const useStartChat = () => {
  const { data: accounts } = useAccounts();
  const updateChats = useUpdateChats();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const user = useUser();

  return useMutation(
    ({
      withoutCallback,
      ...payload
    }: Pick<
      StartChatParams,
      'conversationId' | 'sources' | 'message' | 'templateId' | 'source'
    > & {
      withoutCallback?: boolean;
    }) =>
      services.whatsApp.v2.chat.startChat({
        apiKey: accounts!.apiKey,
        temporaryId: v4(),
        ...payload,
        user: {
          userAccent: user.accent,
          userDisplayName: formatUserDisplayName(user),
          userEmail: user.email,
          userId: user.id,
          userProfilePicture: user.profile?.profilePicture || null,
        },
      }),
    {
      onSuccess: (chat, payload) => {
        if (!payload?.withoutCallback) {
          updateChats(chat, { shift: true });
          queryClient.invalidateQueries(['chat']);
          dispatch(setSelectedLivechat(chat));
          if (payload.sources === 'WHATSAPP_META') {
            queryClient.invalidateQueries(['chat-conversation']);
            dispatch(upsertLivechatMemo({ messageToSend: emptyEditor }));
          }
        }
      },
    }
  );
};

export const useUpdateChats = () => {
  const queryClient = useQueryClient();
  const chatsQueryKey = useChatListsQueryKey();

  return async (
    chatPayload:
      | Chat
      | (RequiredProperties<Chat, 'clientNumber' | 'sources'> & {
          __typename: 'updateChatsPartial';
        }),
    options?: { shift?: boolean }
  ) => {
    queryClient.setQueryData<InfiniteData<ChatResponse>>(
      chatsQueryKey,
      (data) => {
        if (data) {
          const flattenedContents = data.pages.flatMap((page) => page.content);
          const foundIndex = flattenedContents.findIndex(
            (chat) =>
              chat.clientNumber === chatPayload.clientNumber &&
              chat.sources === chatPayload.sources
          );

          if (foundIndex > -1) {
            if (options?.shift) {
              arrayMove(flattenedContents, foundIndex, 0, {
                ...flattenedContents[foundIndex],
                ...chatPayload,
              });
            } else {
              flattenedContents.splice(foundIndex, 1, {
                ...flattenedContents[foundIndex],
                ...chatPayload,
              });
            }
          } else {
            if ('__typename' in chatPayload)
              throw new Error(
                'Chat that has not previously present must have required "Chat" properties defined'
              );

            flattenedContents.unshift(chatPayload);
          }

          const chunkSize = GET_CHATS_DEFAULT_LIMIT || flattenedContents.length;

          const chunks = chunk(flattenedContents, chunkSize);

          if (data.pages.length) {
            const mergedPages = data.pages
              .filter((_page, index) => !!chunks[index])
              .map((_page, index) => ({
                ..._page,
                content: chunks[index],
              }));

            return {
              ...data,
              pages: mergedPages,
            };
          }

          const mergedPages = [
            { content: chunks[0], pageable: {} as Pagination, meta: {} },
          ];

          return {
            ...data,
            pages: mergedPages,
          };
        }
      }
    );
  };
};

type RequiredProperties<T, K extends keyof T> = {
  [P in K]-?: T[P];
} & {
  [P in Exclude<keyof T, K>]?: T[P];
};

export const useReadChat = () => {
  const { mutate: updateChat } = useUpdateChat();
  const userId = useUserId();

  const dispatch = useAppDispatch();

  return (payload: Chat) => {
    dispatch(setSelectedLivechat(payload));

    if (payload.ticket?.userId === userId && payload.status === 'Active') {
      updateChat({ status: 'read' });
    }
  };
};

type UpdateChatPayload = (
  | UpdateChatRead
  | UpdateChatJoin
  | UpdateChatTransfer
) & {
  target?: {
    clientNumber: Chat['clientNumber'];
    sources: Chat['sources'];
  };
};

export const useUpdateChat = () => {
  const dispatch = useAppDispatch();
  const apiKey = useApiKey();
  const companyId = useActiveCompany();
  const queryKey = useChatListsQueryKey();

  const selectedChat = useAppSelector(selectActiveChat, shallowEqual);

  const user = useUser();

  const currentUser = {
    userId: user.id,
    userEmail: user.email,
    userAccent: user.accent,
    userDisplayName: formatUserDisplayName(user),
    userProfilePicture: user.profile?.profilePicture || null,
  };

  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateChatPayload) => {
      return services.whatsApp.chat.updateChat({
        key: apiKey,
        companyId,
        number: payload.target?.clientNumber || selectedChat!.clientNumber,
        sources: payload.target?.sources || selectedChat!.sources,
        ...(payload.status === 'transfer' ||
        payload.status === 'join' ||
        payload.status === 'close' ||
        payload.status === 'took_over'
          ? {
              ...(payload.status === 'close'
                ? {
                    status: 'close',
                    ...currentUser,
                  }
                : {
                    ...(payload.status === 'transfer'
                      ? {
                          status: 'transfer',
                          notes: payload.notes,
                          oldUser: currentUser,
                          ...payload.assignedUser,
                        }
                      : payload.status === 'took_over'
                      ? {
                          status: 'took_over',
                          ...currentUser,
                          oldUser: payload.oldUser,
                        }
                      : {
                          status: 'join',
                          ...currentUser,
                        }),
                  }),
            }
          : {
              action: payload.status,
            }),
      });
    },
    {
      onMutate: async (payload) => {
        const status = payload.status;

        if (
          status === 'join' ||
          status === 'took_over' ||
          status === 'transfer' ||
          status === 'close'
        ) {
          await queryClient.cancelQueries(queryKey);

          const previousChats =
            queryClient.getQueryData<InfiniteData<ChatResponse>>(queryKey);

          dispatch(updateSelectedChat({ payload, currentUser }));

          queryClient.setQueryData<InfiniteData<ChatResponse>>(
            queryKey,
            (old) =>
              old
                ? {
                    ...old,
                    pages: old.pages.map((page) => ({
                      ...page,
                      content: page.content.map((_chat) => {
                        if (
                          _chat.clientNumber === selectedChat!.clientNumber &&
                          selectedChat!.sources === _chat.sources
                        ) {
                          const chat = formatChatTicket(
                            _chat,
                            payload,
                            currentUser
                          );

                          return chat;
                        }

                        return _chat;
                      }),
                    })),
                  }
                : undefined
          );

          return {
            previousChats,
          };
        }

        if (payload.status === 'read' || payload.status === 'unread') {
          await queryClient.cancelQueries(queryKey);

          const previousChats =
            queryClient.getQueryData<InfiniteData<ChatResponse>>(queryKey);

          queryClient.setQueryData<InfiniteData<ChatResponse>>(
            queryKey,
            (old) =>
              old
                ? {
                    ...old,
                    pages: old.pages.map((page) => {
                      return {
                        ...page,
                        content: page.content.map((c) => {
                          if (
                            c.clientNumber === selectedChat!.clientNumber &&
                            selectedChat!.sources === c.sources
                          ) {
                            return {
                              ...c,
                              unreadMessages: payload.status === 'read' ? 0 : 1,
                            };
                          }
                          return c;
                        }),
                      };
                    }),
                  }
                : undefined
          );

          return {
            previousChats,
          };
        }
      },
      onError: (err, payload, context) => {
        const status = payload.status;

        if (status === 'read' || status === 'unread') {
          queryClient.setQueryData<InfiniteData<ChatResponse>>(
            queryKey,
            context?.previousChats
          );
        }

        if (
          status === 'join' ||
          status === 'took_over' ||
          status === 'transfer' ||
          status === 'close'
        ) {
          queryClient.setQueryData<InfiniteData<ChatResponse>>(
            queryKey,
            context?.previousChats
          );
        }
      },
    }
  );
};

export function selectActiveChat(s: RootState) {
  const selectedChat = s.bitCRM.selectedChat;
  return selectedChat
    ? {
        clientNumber: selectedChat.clientNumber,
        sources: selectedChat.sources,
        messageId: selectedChat.messageId,
      }
    : undefined;
}

export function selectActiveChatTruthy(s: RootState) {
  const selectedChat = s.bitCRM.selectedChat!;

  return {
    clientNumber: selectedChat.clientNumber,
    sources: selectedChat.sources,
  };
}
