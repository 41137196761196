import axios from 'axios';

import { bitCrmApiV1BaseUrl, bitCrmApiV2BaseUrl } from '@/constants/api';
import { intercepts } from '@/utils/api';

export default {
  v1: intercepts(
    axios.create({
      baseURL: bitCrmApiV1BaseUrl,
    })
  ),
  v2: intercepts(
    axios.create({
      baseURL: bitCrmApiV2BaseUrl,
    })
  ),
};
