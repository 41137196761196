import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Profile,
  User,
  UserEmailVerificationRequest,
} from '../../types/systemLogin/index';
import useVerifyEmail from './useVerifyEmail';

import api from '@/config/api';

export type VerifyEmailResponseData = UserEmailVerificationRequest & {
  user: User & {
    profile: Profile;
  };
};

const useVerifyEmailInfo = (id: string) => {
  const { mutate: verify } = useVerifyEmail();
  return useQuery<VerifyEmailResponseData, AxiosError>(
    ['verify-email', id],
    async () => {
      const {
        data: { data },
      } = await api.systemLogin.get(`/auth/email-verify/${id}`);
      return data;
    },
    {
      enabled: !!id,
      meta: {
        disableToast: true,
      },
      onSuccess: () => verify({ id }),
    }
  );
};

export default useVerifyEmailInfo;
