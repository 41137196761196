import { useAppDispatch } from '@/hooks/rtk/store';
import { CropImageModal, setCropImageModal } from '@/stores/common';

const useCropImageModal = () => {
  const dispatch = useAppDispatch();

  const toggleCropImageModal = (state: CropImageModal) =>
    dispatch(setCropImageModal(state));

  return toggleCropImageModal;
};

export default useCropImageModal;
