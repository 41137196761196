import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import UserZone from './UserZone';

import BBBIconSmall from '@/assets/icons/BBBIconSmall';

type Props = {
  children?: React.ReactNode;
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  activeSidebar: boolean;
};

export default function SidebarWrapper({
  children,
  setActiveSidebar,
  activeSidebar,
}: Props) {
  const history = useHistory();
  return (
    <div className="flex flex-col h-full">
      <div className="grow relative">
        <div className={cx('absolute inset-0 overflow-x-hidden')}>
          <motion.div>
            <div
              className="h-14 flex justify-center items-center mt-8 mb-8 cursor-pointer"
              onClick={() => history.push('/')}
            >
              <BBBIconSmall />
            </div>
          </motion.div>
          {children}
        </div>
      </div>
      <UserZone
        setActiveSidebar={setActiveSidebar}
        activeSidebar={activeSidebar}
      />
    </div>
  );
}
