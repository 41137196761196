import { ReactNode, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trash2 } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { BBBFileUploadTypes } from '../BBBFileUpload';

import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import FileIcon from '@/assets/icons/FileIcon';
import { UploadOption, useUploadFile } from '@/hooks/common/upload';
import useCropImageModal from '@/hooks/common/useCropImageModal';
import { CropImageModal } from '@/stores/common';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { fileNameFromUrl, humanFileSize } from '@/utils/common/file';

export type SingleFileUploadProps = {
  accept: BBBFileUploadTypes['accept'];
  file: Nullable<FileType>;
  onChangeFile?: (val: FileType | null) => void;
  containerClassName?: string;
  width?: number;
  height?: number;
  placeholder?: ReactNode;
  crop?: Omit<CropImageModal, 'handleDownload' | 'file'>;
  placeholderUploading?: string;
  uploadOptions?: UploadOption;
};

function SingleFileUpload({
  accept,
  file,
  onChangeFile,
  containerClassName,
  crop,
  placeholder,
  placeholderUploading = 'Uploading files..',
  uploadOptions,
}: SingleFileUploadProps) {
  const { uploadFile, progress: _progress } = useUploadFile();

  const progress = _progress ? Object.values(_progress)[0] : undefined;

  const toggleCropImageModal = useCropImageModal();

  const onFileChange = async (changedFiles: File[]) => {
    const file = changedFiles?.[0];

    if (file) {
      if (file.type.includes('image')) {
        if (!crop) {
          const remoteUrl = await uploadFile(file);

          onChangeFile?.({
            fileData: file,
            remoteUrl,
          });
        } else {
          toggleCropImageModal({
            handleDownload: handleDownloadCroppedImage,
            file,
            ...crop,
            ...uploadOptions,
          });
        }
      } else {
        const remoteUrl = await uploadFile(file, uploadOptions);

        onChangeFile?.({
          fileData: file,
          remoteUrl,
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: onFileChange,
      accept: Object.fromEntries(
        accept?.split(',').map((data) => [data, []]) || []
      ),
    });

  const handleDownloadCroppedImage = (url: string) =>
    onChangeFile?.({
      fileData: {
        type: 'image',
      },
      remoteUrl: url,
    });

  const renderFileRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div
        {...getRootProps()}
        className={cx('mb-3 last:mb-0', containerClassName)}
      >
        <input {...getInputProps()} />
        <AnimatePresence mode="wait" initial={false}>
          {!file?.remoteUrl ? (
            <motion.div
              className={cx(
                'flex flex-col justify-center items-center border-dashed border-[1.5px] rounded-lg hover:border-secondary-main transition-colors cursor-pointer',
                isDragReject
                  ? 'border-danger-main'
                  : isDragAccept && 'border-secondary-main'
              )}
              initial={{ height: '14rem', opacity: 1 }}
              exit={{ opacity: 0, height: 64 }}
              animate={{ height: '14rem', opacity: 1 }}
              key="placeholder"
              transition={{ duration: 0.3, type: 'tween' }}
            >
              <AnimatePresence mode="wait">
                {typeof progress !== 'undefined' ? (
                  <motion.div
                    className="text-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key="uploading"
                    transition={{ duration: 0.3, type: 'tween' }}
                  >
                    {placeholderUploading}
                    <div className="w-full h-1 bg-[#DBDBDB] rounded-full mt-2">
                      <div
                        className="h-full bg-primary-main rounded-full transition-[width]"
                        style={{
                          width: `${progress ?? 0}%`,
                        }}
                      />
                    </div>
                  </motion.div>
                ) : (
                  <>
                    <motion.div
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key="default-placeholder"
                      transition={{
                        duration: 0.3,
                        type: 'tween',
                      }}
                    >
                      <FileUploadThumbnail placeholder={placeholder} />
                    </motion.div>
                  </>
                )}
              </AnimatePresence>
            </motion.div>
          ) : (
            <motion.div
              key="files"
              className="border-dashed border-[1.5px] rounded-lg"
              initial={{
                opacity: 0,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: '14rem',
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              transition={{ duration: 0.3, type: 'tween' }}
              ref={renderFileRef}
            >
              <div className="p-5 flex gap-2 items-center">
                {file.fileData?.type?.includes('image') ? (
                  <img src={file.remoteUrl} className="w-7 h-7 rounded"></img>
                ) : (
                  <div className="flex-none">
                    <FileIcon />
                  </div>
                )}

                <a
                  className="truncate grow"
                  onClick={(e) => e.stopPropagation()}
                  href={file.remoteUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {file.meta?.__fileName || fileNameFromUrl(file.remoteUrl)}
                </a>
                {file.fileData?.size && (
                  <div className="flex-none">
                    ({humanFileSize(file.fileData.size)})
                  </div>
                )}
                <Trash2
                  className="flex-none text-neutral-30 hover:text-danger-hover cursor-pointer"
                  onClick={(e) => {
                    onChangeFile?.(null);
                    e.stopPropagation();
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default SingleFileUpload;

export function FileUploadThumbnail({
  placeholder = 'Click or drop media/file here to upload',
}: {
  placeholder: ReactNode;
}) {
  return (
    <div className="flex flex-col items-center justify-center">
      {typeof placeholder === 'string' ? (
        <>
          <BBBThumbnail width={56} height={30} className="mb-3" />
          <div className="text-sm text-neutral-40">{placeholder}</div>
        </>
      ) : (
        placeholder
      )}
    </div>
  );
}
