function FilterIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M3 5h4m14 0H11m-8 7h12m6 0h-2M3 19h2m16 0H9M9 7a2 2 0 100-4 2 2 0 000 4zM17 14a2 2 0 100-4 2 2 0 000 4zM7 21a2 2 0 100-4 2 2 0 000 4z"
      ></path>
    </svg>
  );
}

export default FilterIcon2;
