import { useEffect, useState } from 'react';
import { Info, PlusCircle, Trash2 } from 'react-feather';
import { mapStatusToBadgeProps } from '../../WhatsappApiSettings/components/ChatInitation/hooks/useColumn';
import ConnectWaCloudBanner from '../../WhatsappApiSettings/components/ConnectWaCloudBanner';

import {
  BBBAlert,
  BBBCard,
  BBBContainer,
  BBBSelect,
  BBBTelInput,
  BBBTelInputValue,
  BBBTooltip,
  Link,
} from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import useActiveTemplate from '@/hooks/bitLogin/useActiveTemplate';
import useApprovedTemplates from '@/hooks/bitLogin/useApprovedTemplate';
import useDeleteTemplate from '@/hooks/bitLogin/useDeleteTemplate';
import useTemplates from '@/hooks/bitLogin/useTemplates';
import useUpdateActiveTemplate from '@/hooks/bitLogin/useUpdateActiveTemplate';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import { cn } from '@/utils/styles';

export default function BitloginWhatsappSettings() {
  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  const phoneDefaultValue = useDefaultCountryCode(
    whatsAppCloudApiIntegrationData?.extra?.number
  );
  const [phone, setPhone] = useState<BBBTelInputValue>();

  useEffect(() => {
    setPhone(phoneDefaultValue);
  }, [phoneDefaultValue]);

  const confirm = useConfirmationModal();

  const { data: templateList } = useTemplates();
  const { data: approvedTemplateList } = useApprovedTemplates();
  const { data: activeTemplate } = useActiveTemplate();
  const { mutate: updateActiveTemplate } = useUpdateActiveTemplate();
  const { mutate: deleteTemplate } = useDeleteTemplate();

  return (
    <BBBContainer
      hasHeading
      pageTitle="bitLogin WhatsApp settings"
      pageDescription={
        <>
          Set up your WhatsApp login settings{' '}
          <a
            href="https://www.bitbybit.studio/knowledge/bitcrm"
            target="_blank"
            className="text-info-main underline"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </>
      }
      className="md:px-0 md:py-0 lg:max-w-none"
    >
      <ConnectWaCloudBanner />
      <div
        className={cn(
          !isWhatsAppCloudConnected
            ? 'opacity-50 pointer-events-none cursor-none'
            : 'opacity-100 pointer-events-auto'
        )}
      >
        {templateList?.length === 0 && (
          <BBBAlert
            type="info"
            message={
              <>
                Create OTP Template to start using WhatsApp login.{' '}
                <Link
                  to={
                    '/misc/whatsapp-api-settings/templates/new?section=bitlogin'
                  }
                  className="font-bold underline text-blue-500"
                >
                  Create template
                </Link>
              </>
            }
          />
        )}
        <BBBCard
          title="WhatsApp login settings"
          desc="The data will be used for WhatsApp login and OTP message"
          className="my-4"
        >
          <BBBTelInput
            value={phone}
            onChange={setPhone}
            label={
              <div className="flex items-center gap-2">
                Active number
                <BBBTooltip
                  show
                  content="This is your active WhatsApp Cloud API number. To change or disconnect your number, go to Integration page"
                >
                  <Info size={14} color="#9E9E9E" />
                </BBBTooltip>
              </div>
            }
            className="mb-3"
            disabled
          />
          <BBBSelect
            value={activeTemplate?.data}
            onValueChange={(opt) => {
              if (opt?.id !== 'create-template') {
                updateActiveTemplate({
                  templateId: opt!.id,
                });
              }
            }}
            label={
              <div className="flex items-center gap-2">
                Active OTP template
                <BBBTooltip
                  show
                  content="You can only choose one approved template as the active template"
                >
                  <Info size={14} color="#9E9E9E" />
                </BBBTooltip>
              </div>
            }
            optionLabel="name"
            optionValue="id"
            options={[
              ...(approvedTemplateList ?? []),
              {
                id: 'create-template',
                name: (
                  <div className="w-full">
                    <Link
                      to="/misc/whatsapp-api-settings/templates/new?section=bitlogin"
                      className="block w-full text-left"
                    >
                      + Create new template
                    </Link>
                  </div>
                ),
              },
            ]}
            placeholder="Choose OTP template"
          />
        </BBBCard>
        <BBBCard
          title="OTP message template"
          desc="Create message template to send OTP to your customer via WhatsApp"
          className="mb-4"
        >
          <div className="template-list mb-4">
            {templateList?.length === 0 && (
              <div className="text-neutral-60">
                You don`t have any template yet, create one!
              </div>
            )}
            {templateList?.map((template) => (
              <div
                key={template.id}
                className="template-row flex justify-between items-center border-b py-4"
              >
                <div className="template-info">
                  <div className="text-lg text-neutral-70">{template.name}</div>
                  <div className="text-neutral-40">{template.message}</div>
                </div>
                <div className="template-status flex items-center">
                  <BBBTooltip
                    content="Usually takes 1-3 days to be approved"
                    show
                    offset={{ left: 30, top: 10 }}
                    tooltipClassName="w-auto"
                  >
                    <BBBBadge {...mapStatusToBadgeProps[template.status]} />
                  </BBBTooltip>

                  <button
                    onClick={() => {
                      confirm({
                        title: 'Delete template',
                        description:
                          'Are you sure want to delete this template? Once you deleted the action cannot be undone.',
                        onAccept: (hide) => {
                          deleteTemplate(template.id);
                          hide();
                        },
                        submitText: 'Delete',
                        deleteModal: true,
                      });
                    }}
                    className="text-[#DDDDDD] hover:text-[#EEEEEE] ml-3"
                    aria-label={`Delete template ${template.name}`}
                  >
                    <Trash2 />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <Link
            to={'/misc/whatsapp-api-settings/templates/new?section=bitlogin'}
            className="text-neutral-50 text-lg flex items-center"
            isHasIcon
            show
          >
            <PlusCircle className="mr-2" /> Create new template
          </Link>
        </BBBCard>
      </div>
    </BBBContainer>
  );
}
