import { twMerge as cx } from 'tailwind-merge';

import { UserStore } from '@/stores/auth';
import { formatUserDisplayName } from '@/utils/auth';

export default function UserProfilePicture({
  size = 32,
  fontSize = typeof size === 'number' ? size / 2 : `calc(${size} / 2)`,
  ...props
}: UserStore & {
  size?: string | number;
  fontSize?: string | number;
}) {
  return (
    <>
      <UserProfilePicture1
        accent={props.accent || undefined}
        displayName={formatUserDisplayName(props)
          ?.split(' ')
          .slice(0, 2)
          .map((name) => name.slice(0, 1))
          .join('')}
        fontSize={fontSize}
        size={size}
        profilePicture={props.profile?.profilePicture || undefined}
      />
    </>
  );
}

export type UserProfilePictureProps = {
  profilePicture?: string;
  size?: number | string;
  accent?: string;
  displayName?: string;
  fontSize?: number | string;
};

export function UserProfilePicture1({
  size,
  profilePicture,
  accent,
  displayName,
  fontSize = typeof size === 'number' ? size / 2 : `calc(${size} / 2)`,
}: UserProfilePictureProps) {
  return (
    <>
      {profilePicture ? (
        <img
          src={profilePicture}
          className={cx('!max-w-none object-cover rounded-full')}
          style={{
            width: size,
            height: size,
          }}
          alt="avatar"
          referrerPolicy="no-referrer"
        />
      ) : (
        <div
          className={cx(
            'rounded-full text-white flex-none flex items-center justify-center font-semibold uppercase'
          )}
          style={
            accent
              ? {
                  background: accent,
                  width: size,
                  height: size,
                  fontSize,
                }
              : undefined
          }
        >
          {displayName}
        </div>
      )}
    </>
  );
}
