import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import useHistory from '@/hooks/common/useHistory';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { hideConfirmationModal } from '@/stores/common';
import { toast } from '@/utils/common/toast';

const useDeleteUserCompany = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return useMutation(
    async (userId: number) =>
      services.systemLogin.deleteUserCompany(userId, activeCompany),
    {
      onSuccess: () => {
        toast.success(`Selected team member has been removed`);
        client.invalidateQueries(['company-users', activeCompany]);
        dispatch(hideConfirmationModal());
        history.push('/settings');
      },
    }
  );
};
export default useDeleteUserCompany;
