const SentimentPositiveIcon = ({
  size = 20,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2036_22045)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0007 2.50065C5.85852 2.50065 2.50065 5.85852 2.50065 10.0007C2.50065 14.1428 5.85852 17.5007 10.0007 17.5007C14.1428 17.5007 17.5007 14.1428 17.5007 10.0007C17.5007 5.85852 14.1428 2.50065 10.0007 2.50065ZM0.833984 10.0007C0.833984 4.93804 4.93804 0.833984 10.0007 0.833984C15.0633 0.833984 19.1673 4.93804 19.1673 10.0007C19.1673 15.0633 15.0633 19.1673 10.0007 19.1673C4.93804 19.1673 0.833984 15.0633 0.833984 10.0007Z"
          fill="#65B242"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66732 6.625C7.12756 6.625 7.50065 6.9981 7.50065 7.45833V7.54167C7.50065 8.0019 7.12756 8.375 6.66732 8.375C6.20708 8.375 5.83398 8.0019 5.83398 7.54167V7.45833C5.83398 6.9981 6.20708 6.625 6.66732 6.625ZM13.334 6.625C13.7942 6.625 14.1673 6.9981 14.1673 7.45833V7.54167C14.1673 8.0019 13.7942 8.375 13.334 8.375C12.8737 8.375 12.5007 8.0019 12.5007 7.54167V7.45833C12.5007 6.9981 12.8737 6.625 13.334 6.625Z"
          fill="#65B242"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.40402 10.877C6.84064 10.7314 7.31257 10.9674 7.45811 11.404C7.79806 12.4239 8.62171 13.3342 10.0009 13.3342C11.38 13.3342 12.2037 12.4239 12.5436 11.404C12.6892 10.9674 13.1611 10.7314 13.5977 10.877C14.0344 11.0225 14.2703 11.4944 14.1248 11.9311C13.6314 13.4112 12.305 15.0009 10.0009 15.0009C7.69671 15.0009 6.37036 13.4112 5.87697 11.9311C5.73143 11.4944 5.9674 11.0225 6.40402 10.877Z"
          fill="#65B242"
        />
      </g>
      <defs>
        <clipPath id="clip0_2036_22045">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SentimentPositiveIcon;
