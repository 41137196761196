import {
  IBitCRMAnalytics,
  IBitCRMChartAnalyticsResponse,
} from '@/types/bitCRM/analytics';

export const previewBitCRMAnalytics: IBitCRMAnalytics = {
  automationSent: {
    count: 367,
    percentage: 15.9,
    description: 'UP',
    detail: {
      before: 23,
      after: 24,
    },
  },
  orderCreated: {
    count: 26,
    percentage: 3.9,
    description: 'UP',
    detail: {
      before: 23,
      after: 24,
    },
  },
  abandonedCart: {
    count: 100,
    percentage: 3.9,
    description: 'UP',
    detail: {
      before: 23,
      after: 24,
    },
  },
  unsubscribe: {
    count: 34,
    percentage: 15.9,
    description: 'UP',
    detail: {
      before: 23,
      after: 24,
    },
  },
  automationReplied: {
    count: 123,
    percentage: 15.9,
    description: 'DOWN',
    detail: {
      before: 23,
      after: 24,
    },
  },
  campaignSent: {
    count: 200,
    percentage: 15.9,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  campaignReplied: {
    count: 145,
    percentage: 15.9,
    description: 'DOWN',
    detail: {
      before: 23,
      after: 42,
    },
  },
};

export const previewViewChartCampaign = [
  {
    label: 'Fri',
    range: {
      start: '2023-03-03',
      end: '2023-03-03',
    },
    replies: 20,
    message: 20,
  },
  {
    label: 'Sat',
    range: {
      start: '2023-03-04',
      end: '2023-03-04',
    },
    replies: 40,
    message: 50,
  },
  {
    label: 'Sun',
    range: {
      start: '2023-03-05',
      end: '2023-03-05',
    },
    replies: 60,
    message: 40,
  },
  {
    label: 'Mon',
    range: {
      start: '2023-03-06',
      end: '2023-03-06',
    },
    replies: 40,
    message: 30,
  },
  {
    label: 'Tue',
    range: {
      start: '2023-03-07',
      end: '2023-03-07',
    },
    replies: 20,
    message: 10,
  },
  {
    label: 'Wed',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    replies: 40,
    message: 50,
  },
];

export const previewViewChartAutomation = [
  {
    label: 'Fri',
    range: {
      start: '2023-03-03',
      end: '2023-03-03',
    },
    replies: 20,
    message: 20,
  },
  {
    label: 'Sat',
    range: {
      start: '2023-03-04',
      end: '2023-03-04',
    },
    replies: 40,
    message: 50,
  },
  {
    label: 'Sun',
    range: {
      start: '2023-03-05',
      end: '2023-03-05',
    },
    replies: 60,
    message: 40,
  },
  {
    label: 'Mon',
    range: {
      start: '2023-03-06',
      end: '2023-03-06',
    },
    replies: 40,
    message: 30,
  },
  {
    label: 'Tue',
    range: {
      start: '2023-03-07',
      end: '2023-03-07',
    },
    replies: 20,
    message: 10,
  },
  {
    label: 'Wed',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    replies: 40,
    message: 50,
  },
];
