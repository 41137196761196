import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import services from '@/api/services';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useDuplicateModel = () => {
  const activeCompany = useActiveCompany();
  const toggleConfirmation = useConfirmationModal();
  const client = useQueryClient();

  const duplicateMutation = useMutation(
    ({ modelId }: { modelId: string }) =>
      services.bitAi.v2New.model.duplicateModel(activeCompany, modelId),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your model has been duplicated`);
        client.invalidateQueries(['bitai-sorting-models']);
      },
    }
  );

  function duplicateModel(
    withConfirmation: true
  ): (data: { modelId: string }) => void;
  function duplicateModel(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { modelId: string }, unknown>;
  function duplicateModel(
    withConfirmation: true | false
  ):
    | ((data: { modelId: string }) => void)
    | UseMutationResult<AxiosResponse<any>, any, { modelId: string }, unknown> {
    return withConfirmation
      ? (data) =>
          toggleConfirmation({
            title: 'Duplicate model?',
            description: `Are you sure you want to duplicate this model?`,
            onAccept: async (hide) =>
              duplicateMutation.mutate(data, {
                onSuccess: () => {
                  hide();
                },
              }),
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : duplicateMutation;
  }

  return duplicateModel;
};

export default useDuplicateModel;
