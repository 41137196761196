import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import GeneralLayout from 'layouts/GeneralLayout';
import * as yup from 'yup';

import AuthCard from '@/components/Auth/Card';
import PasswordCheck from '@/components/Auth/Register/PasswordCheck';
import {
  BBBAlert,
  BBBButton,
  BBBPasswordInput,
  BBBSpinner,
} from '@/components/ui';
import { Link } from '@/components/ui/Link';
import passwordValidation from '@/constants/systemLogin/passwordValidation';
import useResetPassword from '@/hooks/auth/useResetPassword';
import useResetPasswordInfo from '@/hooks/auth/useResetPasswordInfo';
import useCustomForm from '@/hooks/common/useCustomForm';

const schema = yup.object().shape({
  password: passwordValidation,
  repeatedPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

type FormSchema = {
  password: string;
  repeatedPassword: string;
};

function ResetPasswordNewCredentials() {
  const { control, handleSubmit, formState, watch } = useCustomForm<FormSchema>(
    {
      resolver: yupResolver(schema),
      defaultValues: {
        password: '',
        repeatedPassword: '',
      },
    }
  );

  const { mutate: resetPassword, isLoading: isLoadingResetPassword } =
    useResetPassword();

  const { id } = useParams<{ id: string }>();

  const { data, isInitialLoading, error } = useResetPasswordInfo(id);

  const onSubmit = ({ password }: FormSchema) => {
    resetPassword({ password, id });
  };

  return (
    <GeneralLayout>
      <>
        {isInitialLoading ? (
          <BBBSpinner />
        ) : error ? (
          error.response?.status === 400 ? (
            <>
              <BBBAlert
                className="mb-4"
                message="Invitation might have been expired or already used. Please try again with the new one."
              />
              <Link to="/">
                <BBBButton text="Back to home" />
              </Link>
            </>
          ) : (
            <BBBAlert
              message={JSON.stringify(error.response?.data)}
              type="danger"
            />
          )
        ) : (
          <>
            <AuthCard>
              <div className="flex flex-col gap-3 mb-10">
                <h2 className="text-primary-main font-bold">
                  Create new password
                </h2>
              </div>
              <div className="flex flex-col gap-2 mb-7">
                <div className="mb-3">
                  <BBBPasswordInput
                    isHookForm
                    control={control}
                    type="password"
                    label="Password"
                    containerClassname="mb-0"
                    controlName="password"
                    placeholder="Password"
                  />
                  <PasswordCheck password={watch('password')} />
                </div>
                <BBBPasswordInput
                  isHookForm
                  control={control}
                  type="password"
                  label="Repeat Password"
                  controlName="repeatedPassword"
                  placeholder="Re-type your password"
                  error={formState.errors.repeatedPassword?.message}
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-2">
                <BBBButton
                  text="Submit"
                  width="full"
                  disabled={isInitialLoading || isLoadingResetPassword}
                  onClick={() => handleSubmit(onSubmit)()}
                />
              </div>
            </AuthCard>
          </>
        )}
      </>
    </GeneralLayout>
  );
}

export default ResetPasswordNewCredentials;
