import Slider, { SliderProps } from 'rc-slider';

function BBBSlider(props: SliderProps) {
  return (
    <Slider
      trackStyle={{ background: '#262627' }}
      handleStyle={{
        background: '#262627',
        borderColor: '#262627',
        border: '2px solid #FD823E',
        boxShadow: 'none',
      }}
      {...props}
    />
  );
}

export default BBBSlider;
