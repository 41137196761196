import { useInfiniteQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useTickets = (
  params?: Omit<
    Parameters<typeof services.whatsApp.v2.ticket.getTickets>[1],
    'cursor'
  >
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    ['tickets', activeCompany, params],
    ({ pageParam }) =>
      services.whatsApp.v2.ticket.getTickets(activeCompany, {
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.meta?.nextCursor ? lastPage.meta?.nextCursor : undefined,
    }
  );
};

export default useTickets;
