import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { toast } from '@/utils/common/toast';

const useUpdateWACloudTemplate = () => {
  const activeCompany = useActiveCompany();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    (
      payload: Partial<
        Pick<WACloudTemplate, 'name' | 'language' | 'message' | 'isDraft'>
      >
    ) =>
      services.bitChat.template.updateWACloudTemplate(activeCompany, {
        id: id === 'new' ? undefined : id,
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitchat-settings']);
        queryClient.invalidateQueries(['bitchat-wa-cloud-template']);
        toast.success('WA Cloud template updated successfully');
        history.push('/misc/whatsapp-api-settings?section=chat-initiation');
      },
    }
  );
};

export default useUpdateWACloudTemplate;
