import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useBranding = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(['bitapp-branding', activeCompany], () =>
    services.bitApp.branding.getBrandingByCompanyId(activeCompany!)
  );
};

export default useBranding;
