import React from 'react';

type Props = {
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function WebActivityIcon({ className, ...props }: Props) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M7.9 18.4H15.1M2.5 5.8C2.5 5.32261 2.68964 4.86477 3.02721 4.52721C3.36477 4.18964 3.82261 4 4.3 4H18.7C19.1774 4 19.6352 4.18964 19.9728 4.52721C20.3104 4.86477 20.5 5.32261 20.5 5.8V13.9C20.5 14.3774 20.3104 14.8352 19.9728 15.1728C19.6352 15.5104 19.1774 15.7 18.7 15.7H4.3C3.82261 15.7 3.36477 15.5104 3.02721 15.1728C2.68964 14.8352 2.5 14.3774 2.5 13.9V5.8Z"
        stroke="#262627"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WebActivityIcon;
