import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import integrationServices from '@/api/services/integration';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setConnectIntegration } from '@/stores/common';
import { toast } from '@/utils/common/toast';

const useSaveFacebook = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (id: string) => {
      return integrationServices.facebook.saveFacebookPage(activeCompany, id);
    },
    {
      onSuccess: () => {
        toast.success('Success connect facebook page');
        dispatch(setConnectIntegration(null));
        setTimeout(() => {
          queryClient.invalidateQueries([`bitchat-company-integrations`]);
          queryClient.invalidateQueries([`chatlists`]);
        }, 500);
      },
    }
  );
};

export default useSaveFacebook;
