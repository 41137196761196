import { FaApple, FaFacebook } from 'react-icons/fa';
import { GrGoogle } from 'react-icons/gr';
import { createId } from '@paralleldrive/cuid2';

import AmazonIcon from '@/assets/icons/AmazonIcon';
import AppleIcon from '@/assets/icons/AppleIcon';
import FacebookIcon from '@/assets/icons/FacebookIcon';
import GoogleRainbowIcon from '@/assets/icons/GoogleRainbowIcon';
import MagicLinkIcon from '@/assets/icons/MagicLinkIcon';
import MicrosoftIcon from '@/assets/icons/MicrosoftIcon';
import QRIcon from '@/assets/icons/QRIcon';
import TwitterIcon from '@/assets/icons/TwitterIcon';
import WhatsAppIcon from '@/assets/icons/WhatsAppIcon';
import { i_INIT_BUTTON_STYLE_STATE } from '@/types/bitLogin/appearance';

export const INIT_BUTTON_STYLE_STATE: i_INIT_BUTTON_STYLE_STATE = {
  buttonPosition: 'bottom',
  buttonRoundness: 'square',
  buttonShadow: false,
  buttonType: 'match',
};

export const buttonStyles = [
  {
    name: 'square',
    id: createId(),
    label: 'Square',
  },
  {
    name: 'rounded',
    id: createId(),
    label: 'Rounded',
  },
  {
    name: 'fullRounded',
    id: createId(),
    label: 'Full-rounded',
  },
] as const;

export const buttonPositions = [
  {
    id: createId(),
    name: 'top',
  },
  {
    id: createId(),
    name: 'bottom',
  },
] as const;

export const buttonColors = [
  {
    name: 'match',
    id: createId(),
    label: 'Social color',
  },
  {
    name: 'matchOutline',
    id: createId(),
    label: 'Outline color',
  },
  {
    name: 'black',
    id: createId(),
    label: 'Black',
  },
  {
    name: 'white',
    id: createId(),
    label: 'White',
  },
  {
    name: 'custom',
    id: createId(),
    label: 'Custom',
  },
] as const;

export const sizes = [
  {
    name: 'small',
    label: 'Small',
  },
  {
    name: 'medium',
    label: 'Medium',
  },
  {
    name: 'large',
    label: 'Large',
  },
] as const;

export const buttonFormat = [
  'contentFlex',
  'contentFlexReverse',
  'contentFlexCenter',
  'contentFlexCenterReverse',
  'wrapperFlexContainerOnlyLogo',
] as const;

export const bitloginChannels = [
  {
    name: 'GOOGLE',
    logo: <GoogleRainbowIcon />,
    id: createId(),
    label: 'Login with Google',
    switchLabel: 'Google',
    disabled: false,
  },
  {
    name: 'FACEBOOK',
    logo: <FacebookIcon />,
    id: createId(),
    label: 'Login with Facebook',
    switchLabel: 'Facebook',
    disabled: false,
  },
  {
    name: 'APPLE',
    logo: <AppleIcon />,
    id: createId(),
    label: 'Login with Apple',
    switchLabel: 'Apple',
    disabled: false,
  },
  {
    name: 'WHATSAPP',
    logo: <WhatsAppIcon />,
    id: createId(),
    label: 'Login with WhatsApp',
    switchLabel: 'WhatsApp  ',
    disabled: false,
  },
  {
    name: 'MICROSOFT',
    logo: <MicrosoftIcon />,
    id: createId(),
    label: 'Login with Microsoft',
    switchLabel: 'Microsoft  ',
    disabled: false,
  },
  {
    name: 'TWITTER',
    logo: <TwitterIcon />,
    id: createId(),
    label: 'Login with Twitter',
    switchLabel: 'Twitter  ',
    disabled: false,
  },
  {
    name: 'AMAZON',
    logo: <AmazonIcon />,
    id: createId(),
    label: 'Login with Amazon',
    switchLabel: 'Amazon  ',
    disabled: false,
  },
  {
    name: 'MAGIC_LINK',
    logo: <MagicLinkIcon />,
    id: createId(),
    label: 'Magic link',
    switchLabel: 'Magic link (Soon)  ',
    disabled: true,
  },
  {
    name: 'QR_CODE',
    logo: <QRIcon />,
    id: createId(),
    label: 'QR Code',
    switchLabel: 'QR code (Soon)  ',
    disabled: true,
  },
] as const;

export const bitloginEnabledChannels = bitloginChannels.filter(
  (channel) => !channel.disabled
);

export const bitloginEnabledChannelsHash = Object.fromEntries(
  bitloginEnabledChannels.map((data) => [data.name, data])
);

export type LoginChannelOption = typeof bitloginEnabledChannels[number];
export type LoginChannel = LoginChannelOption['name'];

export const iconPreviewApperance = [
  { icon: <GrGoogle /> },
  { icon: <FaFacebook /> },
  { icon: <FaApple /> },
];
