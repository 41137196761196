type Props = {
  size?: number;
  color?: string;
};

function FileIcon({ size = 24, color = '#9E9E9E' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.879 1.879A3 3 0 016 1h7.56a3 3 0 012.097.855l4.44 4.342A3.003 3.003 0 0121 8.342V20a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 01.879-2.121zM6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V8.342a1.001 1.001 0 00-.301-.715l-4.44-4.342A1 1 0 0013.56 3H6zm2 10a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zm0 4a1 1 0 011-1h3a1 1 0 110 2H9a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M13 6V2h2v4a1 1 0 001 1h4v2h-4a3 3 0 01-3-3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FileIcon;
