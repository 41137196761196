import { Control, useWatch } from 'react-hook-form';
import { isEqual, omit } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '..';

import { BBBButton } from '@/components/ui';
import StickyFooter from '@/components/ui/StickyFooter/StickyFooter';
import useResponsive from '@/hooks/common/useResponsive';
import { useDiscardOrLeaveModal } from '@/hooks/modal';

type Props = {
  control: Control<FormSchema, any>;
  dataFromApi: FormSchema;
  onSubmit: () => void;
  onSubmitDraft: (cb?: () => void) => void;
  status: string;
};

export default function Footer(props: Props) {
  const { control, dataFromApi, ..._props } = props;
  const watch = useWatch({
    control,
  });

  const isFormEqual = isEqual(omit(watch, 'param'), dataFromApi);

  const [handleDiscard] = useDiscardOrLeaveModal({
    isFormEqual,
    module: dataFromApi.id ? 'changes' : 'campaign',
    onSave: (hide, leaveCb) => {
      hide();
      props.onSubmitDraft(leaveCb);
    },
    loadingSave: props.status === 'loading',
  });

  const isMobile = useResponsive('sm');

  return (
    <>
      {isMobile ? (
        <FooterMobile {...props} onDiscard={handleDiscard} />
      ) : (
        <FooterDesktop
          {...props}
          isFormEqual={isFormEqual}
          onDiscard={handleDiscard}
        />
      )}
    </>
  );
}

function FooterMobile({
  onSubmit,
  onDiscard,
}: Props & {
  onDiscard: () => void;
}) {
  return (
    <StickyFooter>
      <div className="p-3 bg-white">
        <BBBButton
          variant="primary"
          text="Review"
          width="full"
          onClick={() => onSubmit()}
          className="mb-2.5"
        />
        <BBBButton
          variant="secondary"
          text="Discard"
          onClick={onDiscard}
          className="block"
          width="full"
        />
      </div>
    </StickyFooter>
  );
}

function FooterDesktop({
  control,
  onSubmit,
  isFormEqual,
  onDiscard,
  onSubmitDraft,
}: Props & {
  isFormEqual: boolean;
  onDiscard: () => void;
}) {
  const isDraft = useWatch({
    control,
    name: 'isDraft',
  });

  return (
    <div className="justify-end mt-4 gap-2 sm:flex hidden">
      <div id="campaign-footer" className="flex gap-2 items-center">
        <div
          className={cx(
            'underline cursor-pointer text-neutral-60',
            isFormEqual && 'opacity-50 pointer-events-none'
          )}
          onClick={() => onSubmitDraft()}
        >
          Save as draft
        </div>
        <BBBButton
          variant="secondary"
          text="Discard"
          onClick={onDiscard}
          disabled={isFormEqual}
        />
        <BBBButton
          variant="primary"
          text="Review"
          onClick={() => onSubmit()}
          disabled={isDraft ? undefined : isFormEqual}
        />
      </div>
    </div>
  );
}
