import dayjs from 'dayjs';

import { AnalyticAgents } from '@/types/bitChat/analytics';

export const previewBitChatAnalytics = {
  messageSent: {
    count: 2654,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 7 },
  },
  messageReceived: {
    count: 2864,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 130 },
  },
  conversationHandled: {
    count: 945,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  averageDailyConversation: {
    count: 135,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  averageResponseTime: {
    count: 80,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  averageResolutionTime: {
    count: 90,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  averageRating: {
    count: 4.9,
    percentage: 80,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  averageChatVolume: {
    count: 6,
    percentage: 15.9,
    description: 'UP',
    detail: { before: 0, after: 0 },
  },
  totalConversation: {
    count: 29,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  totalMessage: {
    count: 246,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  totalMessageOut: {
    count: 246,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  totalCustomer: {
    count: 16,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  newCustomer: {
    count: 0,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  ticketCreated: {
    count: 29,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  ticketResolved: {
    count: 4,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  ticketOpen: {
    count: 25,
    percentage: 0,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
};

export const previewPeakHours = [
  {
    time: '9 AM',
    value: 15,
  },
  {
    time: '10 AM',
    value: 30,
  },

  {
    time: '11 AM',
    value: 68,
  },
  {
    time: '12 AM',
    value: 90,
  },
  {
    time: '1 PM',
    value: 60,
  },
  {
    time: '2 PM',
    value: 40,
  },
  {
    time: '3 PM',
    value: 55,
  },
];

export const totalConversation = [
  {
    label: '9 AM',
    value: 15,
  },
  {
    label: '10 AM',
    value: 30,
  },

  {
    label: '11 AM',
    value: 68,
  },
  {
    label: '12 AM',
    value: 90,
  },
  {
    label: '1 PM',
    value: 60,
  },
  {
    label: '2 PM',
    value: 40,
  },
  {
    label: '3 PM',
    value: 55,
  },
];

export const totalMessage = [
  {
    label: '9 AM',
    value: 10,
  },
  {
    label: '10 AM',
    value: 40,
  },

  {
    label: '11 AM',
    value: 20,
  },
  {
    label: '12 AM',
    value: 78,
  },
  {
    label: '1 PM',
    value: 60,
  },
  {
    label: '2 PM',
    value: 43,
  },
  {
    label: '3 PM',
    value: 44,
  },
];

export const previewTicketCategories = [
  {
    label: 'Regular ticket',
    value: 150,
  },
  {
    label: 'Complain',
    value: 80,
  },

  {
    label: 'VIP clients',
    value: 80,
  },
  {
    label: 'Damaged product',
    value: 50,
  },
  {
    label: 'Incomplete product',
    value: 20,
  },
];

export const previewAiTagging = [
  {
    label: 'Regular ticket',
    value: 80,
  },
  {
    label: 'Complain',
    value: 20,
  },

  {
    label: 'Jago Party',
    value: 40,
  },
  {
    label: 'Damaged product',
    value: 5,
  },
  {
    label: 'Incomplete product',
    value: 15,
  },
];

export const previewCustomerSatisfaction = [
  {
    label: '5',
    value: 100,
  },
  {
    label: '4',
    value: 90,
  },
  {
    label: '3',
    value: 48,
  },
  {
    label: '2',
    value: 18,
  },
  {
    label: '1',
    value: 5,
  },
];

export const previewActiveHours = [
  {
    time: '9 AM',
    value: 15,
  },
  {
    time: '10 AM',
    value: 30,
  },

  {
    time: '11 AM',
    value: 68,
  },
  {
    time: '12 AM',
    value: 90,
  },
  {
    time: '1 PM',
    value: 60,
  },
  {
    time: '2 PM',
    value: 40,
  },
  {
    time: '3 PM',
    value: 55,
  },
  {
    time: '4 PM',
    value: 40,
  },
  {
    time: '5 PM',
    value: 60,
  },
  {
    time: '6 PM',
    value: 65,
  },
  {
    time: '7 PM',
    value: 40,
  },
];

export const previewCSATRating = [
  {
    rating: 1,
    value: 15,
  },
  {
    rating: 2,
    value: 30,
  },

  {
    rating: 3,
    value: 68,
  },
  {
    rating: 4,
    value: 90,
  },
  {
    rating: 5,
    value: 60,
  },
];

export const previewBitChatAgents: AnalyticAgents[] = [
  {
    userId: 1234,
    firstName: 'John',
    lastName: 'Doe',
    conversation: 10,
    messageSent: 50,
    rating: 4,
    lastChatHandled: dayjs().toISOString(),
    resolutionTime: {
      hours: 0,
      minutes: 10,
      seconds: 0,
    },
    ticketHandled: 2,
    responseTime: {
      hours: 0,
      minutes: 5,
      seconds: 0,
    },
  },
  {
    userId: 2345,
    firstName: 'Sarah',
    lastName: 'Johnson',
    conversation: 15,
    messageSent: 80,
    rating: 4,
    lastChatHandled: dayjs().toISOString(),
    resolutionTime: {
      hours: 0,
      minutes: 10,
      seconds: 0,
    },
    ticketHandled: 2,
    responseTime: {
      hours: 0,
      minutes: 5,
      seconds: 0,
    },
  },
  {
    userId: 3456,
    firstName: 'David',
    lastName: 'Lee',
    conversation: 5,
    messageSent: 25,
    rating: 4,
    lastChatHandled: dayjs().toISOString(),
    resolutionTime: {
      hours: 0,
      minutes: 10,
      seconds: 0,
    },
    ticketHandled: 2,
    responseTime: {
      hours: 0,
      minutes: 5,
      seconds: 0,
    },
  },
];
