import { Plus } from 'react-feather';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import colors from 'constants/common/colors';
import { useShopifyIntegrationExtra } from 'hooks/bitApp/integration/integrations';
import ShopifyCollectionListsOptions from 'pages/BitApp/components/PageRedirectionFields/CollectionLists';
import ShopifyAwareWrapper from 'components/ShopifyAwareWrapper';
import { destinationOptions, SchemaCategory } from '.';

import { BBBButton, BBBSelect, BBBTextInput } from '@/components/ui';

type Props = {
  control: Control<SchemaCategory>;
  onClick: () => void;
  setValue: UseFormSetValue<SchemaCategory>;
};

function NavigateCategory({ control, onClick, setValue }: Props) {
  const { data: shopifyData } = useShopifyIntegrationExtra();

  const { errors } = useFormState({
    control,
    name: 'subCategories',
  });

  const destination = useWatch({
    control,
    name: 'destination',
  });

  return (
    <div className="flex flex-col gap-5">
      <Controller
        control={control}
        name="destination"
        render={({ field }) => (
          <BBBSelect
            label="Destination page:"
            options={destinationOptions}
            optionLabel="label"
            optionValue="value"
            value={field?.value}
            onValueChange={(val) => {
              field.onChange(val);
              setValue('collection', null);
              setValue('customUrl', null);
            }}
          />
        )}
      />
      {destination.value === 'collections' ? (
        <Controller
          control={control}
          name="collection"
          render={({ field }) => (
            <ShopifyAwareWrapper
              appType="BITAPP"
              size="full"
              fallbackToChildren
            >
              {({ data: shopifyWrapperData, connectMiddleware }) => (
                <ShopifyCollectionListsOptions
                  label="Navigate to:"
                  value={field?.value?.toString()}
                  onChange={(val) => {
                    connectMiddleware(() => {
                      field.onChange(val);
                    });
                  }}
                  onClick={() =>
                    connectMiddleware(() => {
                      return;
                    })
                  }
                  error={errors.collection?.message}
                  {...(!shopifyData || !shopifyWrapperData
                    ? {
                        isMenuOpen: false,
                        isSearchable: false,
                      }
                    : {
                        isSearchable: true,
                      })}
                />
              )}
            </ShopifyAwareWrapper>
          )}
        />
      ) : (
        <BBBTextInput
          label="Insert your URL"
          placeholder="Insert your URL"
          isUrl
          isHookForm
          control={control}
          controlName="customUrl"
          error={errors.customUrl?.message}
        />
      )}
      <div className="w-full border-l-2 border-neutral-30 pl-4.5 rounded-l-sm">
        <BBBButton
          variant="secondary"
          size="sm"
          iconPosition="right"
          className="px-5 w-full"
          text={<p className="font-bold grow">Add sub-category</p>}
          onClick={onClick}
          icon={<Plus color={colors.primary.main} />}
        />
      </div>
    </div>
  );
}

export default NavigateCategory;
