import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { SocialButtonAppearance } from '@/types/bitLogin/v2';
import { toast } from '@/utils/common/toast';

const useUpsertSocialAppearance = (
  options?: Omit<
    UseMutationOptions<void, unknown, Partial<SocialButtonAppearance>, unknown>,
    'mutationFn'
  >
) => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();
  return useMutation(
    (payload: Partial<SocialButtonAppearance>) =>
      services.bitLogin.appearance.upsertSocialAppearance(
        data?.domain,
        payload
      ),
    {
      ...options,
      onMutate: async (payload) => {
        await queryClient.cancelQueries(['bitlogin-appearance', data?.domain]);

        const previousAppearance = queryClient.getQueryData([
          'bitlogin-appearance',
          data?.domain,
        ]);

        queryClient.setQueryData<Partial<SocialButtonAppearance>>(
          ['bitlogin-appearance', data?.domain],
          (old) => ({
            ...old,
            ...payload,
          })
        );

        return { previousAppearance };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-appearance']);
        toast.success('Successfully update appearance');
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['bitlogin-appearance', data?.domain],
          context?.previousAppearance
        );
      },
    }
  );
};

export default useUpsertSocialAppearance;
