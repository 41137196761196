import api from '@/config/api';
import {
  Collection,
  CollectionOptionsRequest,
  CollectionRequest,
  Collections,
  Product,
  ProductOrdering,
  UpdateCollectionRequest,
} from '@/types/bitAi/sorting/collections';

export default {
  getSortingCollection: (shopGid: string, id: string) =>
    api.bitAi.v1
      .get<Collection>('/sorting/collection', {
        params: {
          shop: shopGid,
          id,
        },
      })
      .then((res) => res.data),
  getSortingCollections: (
    shopGid: string,
    {
      last_id,
      size,
      search,
      filter_by_model,
    }: {
      last_id?: string;
      size?: number;
      search?: string;
      filter_by_model?: string | null;
    }
  ) =>
    api.bitAi.v1
      .get<Collections>('/sorting/collections', {
        params: {
          shop: shopGid,
          last_id,
          size,
          search,
          filter_by_model,
        },
      })
      .then((res) => res.data),
  updateSortingCollections: (data: CollectionRequest) => {
    api.bitAi.v1.put('/sorting/collection/model', {
      ...data,
    });
  },
  getSortingProducts: (shopGid: string, id: string) =>
    api.bitAi.v1
      .get<Product>(`/sorting/product`, {
        params: {
          shop: shopGid,
          id,
        },
      })
      .then((res) => res.data),
  getSearchedProducts: (
    shopGid: string,
    params: {
      collectionId: string;
      search_term: string;
    }
  ) =>
    api.bitAi.v1
      .get<Product[]>('/sorting/product/search', {
        params: {
          shop: shopGid,
          collection_id: params.collectionId,
          search_term: params.search_term,
        },
      })
      .then((res) => res.data),
  getProductsOrdering: () =>
    api.bitAi.v1
      .get<ProductOrdering>('/sorting/collection/products_ordering')
      .then((res) => res.data),
  updateCollectionModelOptions: (data: CollectionOptionsRequest) =>
    api.bitAi.v1.post('/sorting/collection/model/options', {
      ...data,
    }),
  updateOneCollectionModel: (data: UpdateCollectionRequest) =>
    api.bitAi.v1.post('/sorting/collection/model', {
      ...data,
    }),
};
