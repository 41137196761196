function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM11.333 9.833h.235a2 2 0 011.984 1.752l.26 2.083a1.334 1.334 0 01-1.323 1.499H3.511a1.333 1.333 0 01-1.324-1.5l.26-2.082a2 2 0 011.986-1.752h.234"
      ></path>
    </svg>
  );
}

export default UserIcon;
