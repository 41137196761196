import React from 'react';

type Props = {
  className?: string;
  color?: string;
} & React.SVGProps<SVGSVGElement>;

function ReplyIcon({ className, color = '#9a9a9a', ...props }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_11243_5961)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.18276 0.0515607C6.56654 0.183485 6.8243 0.545093 6.8243 0.951594V3.55295C10.7677 3.79647 13.165 5.56507 14.5092 7.87639C15.9104 10.2856 16.0904 13.1495 15.9684 15.1077C15.9386 15.5869 15.557 15.9688 15.0786 15.9982C14.6003 16.0276 14.175 15.6953 14.0869 15.2233C14.0725 15.146 14.0586 15.0695 14.0448 14.994C13.8771 14.0714 13.7332 13.2799 12.9337 12.595C12.1038 11.8841 10.4145 11.1841 6.8243 11.0795V13.6388C6.8243 14.0453 6.56654 14.4069 6.18276 14.5388C5.79897 14.6707 5.37392 14.5438 5.12482 14.2229L0.200068 7.87936C-0.0666892 7.53575 -0.0666892 7.0546 0.200068 6.71099L5.12482 0.367407C5.37392 0.0465346 5.79897 -0.0803636 6.18276 0.0515607ZM2.153 7.29517L4.92475 10.8655V10.1145C4.92475 9.58902 5.34998 9.16301 5.87452 9.16301C9.78642 9.16301 12.1875 9.77134 13.6731 10.7727C13.4844 10.1019 13.2226 9.4443 12.868 8.83448C11.7879 6.9773 9.77055 5.42734 5.87452 5.42734C5.34998 5.42734 4.92475 5.00133 4.92475 4.47581V3.72488L2.153 7.29517Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11243_5961">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReplyIcon;
