export const typeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Media',
    value: 'media',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Button',
    value: 'button',
  },
  {
    label: 'Button Link',
    value: 'button_link',
  },
];

export const chatbotTypes = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Media', value: 'MEDIA' },
  { label: 'Button', value: 'BUTTON' },
  { label: 'Button link', value: 'BUTTON_LINK' },
];
