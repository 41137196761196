import { aiAgentLabel, TRACKO_AGENT_ID } from 'constants/bitChat/agent';
import useShouldInitChat from 'hooks/bitChat/livechat/useShouldInitChat';
import { MessageNewAssociation } from 'types/whatsApp/v3';

import { BBBRenderHtml } from '@/components/ui';

export default function ChatBubbleSeparator({
  chat,
}: {
  chat: MessageNewAssociation;
}) {
  const AgentActive =
    chat.agentActive === TRACKO_AGENT_ID
      ? aiAgentLabel('AI Agent')
      : chat.agentActiveName;

  const AgentSource =
    chat.agentSource === TRACKO_AGENT_ID
      ? aiAgentLabel('AI Agent')
      : chat.agentSourceName;

  return (
    <>
      <div className="flex items-center gap-3 py-5">
        <div className="grow border-t" />
        <div className="text-center">
          {chat.type === 'ACTIVITY_TOOK_OVER' && (
            <div className="flex items-center gap-2">
              <span className="font-bold">{AgentActive}</span> took over ticket{' '}
              <span>#{chat.ticketId}</span> from {AgentSource}
            </div>
          )}
          {chat.type === 'ACTIVITY_JOIN' && (
            <div className="flex items-center gap-2">
              <span className="font-bold">{AgentActive}</span> joined the chat
            </div>
          )}
          {chat.type === 'ACTIVITY_CLOSED' && (
            <div className="flex items-center gap-2">
              <span className="font-bold">{AgentActive}</span> resolved ticket{' '}
              <span>#{chat.ticketId}</span>
            </div>
          )}
          {chat.type === 'ACTIVITY_TRANSFER' && (
            <div className="flex items-center gap-2">
              Ticket <span className="font-bold">#{chat.ticketId}</span>{' '}
              assigned to <span className="font-bold">{AgentActive}</span>
            </div>
          )}
          {chat.type === 'ACTIVITY_CUSTOMER_SAVED' && (
            <>Customer saved to database</>
          )}
          {chat.type === 'ACTIVITY_EXPIRED' && (
            <>The conversation has expired due to 24 hours inactivity</>
          )}
          {chat.type === 'ACTIVITY_ASSIGNED' && (
            <div className="flex items-center gap-2">
              Ticket assigned to{' '}
              <strong className="font-bold">{AgentActive}</strong>
            </div>
          )}
          {chat.type === 'ACTIVITY_TICKET_CREATED' && (
            <>
              Ticket <span className="font-bold">#{chat.ticketId}</span> is
              created
            </>
          )}
        </div>
        <div className="grow border-t" />
      </div>
      {chat.notes && (
        <div className="text-center my-5 text-neutral-50">
          <div className="flex items-center justify-center gap-2">
            Notes from <span className="font-bold">{AgentSource}</span>
          </div>
          <BBBRenderHtml content={chat.notes} />
        </div>
      )}
      {chat.type === 'ACTIVITY_EXPIRED' && <SendTemplateHint />}
      <ResolvedTicketNotesHint ticketId={chat.ticketId} notes={chat.notes} />
    </>
  );
}

function SendTemplateHint() {
  const isCloudApiRestricted = useShouldInitChat();

  if (!isCloudApiRestricted) return null;

  return (
    <div className="text-center text-neutral-50">
      You can send message by choosing template
    </div>
  );
}

function ResolvedTicketNotesHint({
  ticketId,
  notes: ticketNotes,
}: Pick<MessageNewAssociation, 'ticketId' | 'notes'>) {
  if (!ticketNotes) {
    return null;
  }

  return (
    <div className="w-fit max-w-[40rem] flex flex-col gap-1 mx-auto my-3">
      <p className="text-[13px] font-bold text-neutral-50">
        Ticket #{ticketId} notes:
      </p>
      <p className="text-[13px] text-neutral-50 break-words text-justify">
        {ticketNotes}
      </p>
    </div>
  );
}
