import { ApiResponse } from 'api/types';

import api from '@/config/api';
import { ApiResponse as ApiResponseCRM } from '@/types/bitCRM/api';
import {
  CampaignTemplate,
  TemplateBodyParameters,
} from '@/types/bitCRM/template';

export default {
  generateAiTemplate: (payload: {
    companyId: number;
    context: string;
    language: string;
    companyName: string;
    companyIndustry: string | null;
  }) =>
    api.bitCRM.v2
      .post<ApiResponse<string>>(`/template/generate`, payload)
      .then((res) => res.data.data),
  getTemplates: (
    companyId: number,
    params?: {
      size?: number;
      page?: number;
      sort?: string;
      search?: string;
      type?: CampaignTemplate['type'];
      status?: string;
    }
  ) =>
    api.bitCRM.v2
      .get<ApiResponseCRM<CampaignTemplate>>(
        `/campaign_template/${companyId}`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getTemplate: (templateId: string, params: { companyId: number }) =>
    api.bitCRM.v2
      .get<CampaignTemplate>(`/campaign_template/detail/${templateId}`, {
        params,
      })
      .then((res) => res.data),
  getParametersOptions: (type: 'campaign' | 'automation') =>
    api.bitCRM.v2
      .get<TemplateBodyParameters[]>(`/app/variables/${type}`)
      .then((res) => res.data),
  deleteTemplate: (templateId: string, params: { companyId: number }) =>
    api.bitCRM.v2.delete(`/campaign_template/${templateId}`, { params }),
  getCountNewTemplate: (companyId: number) =>
    api.bitCRM.v2
      .get<ApiResponse<number>>(
        `/campaign_template/${companyId}/count-new-template`
      )
      .then((res) => res.data.data),
};
