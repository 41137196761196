export default function DynamicVariableLink() {
  return (
    <a
      href={
        'https://www.bitbybit.studio/knowledge-base/how-to-use-dynamic-variables-in-automation-messages'
      }
      className="text-sm underline"
      target="_blank"
      rel="noreferrer"
    >
      View dynamic variable lists
    </a>
  );
}
