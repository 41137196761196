import { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';
import CryptoJS from 'crypto-js';
import GeneralLayout from 'layouts/GeneralLayout';

import LoginCard from '@/components/Auth/LoginCard';
import { BBBAlert, BBBSpinner } from '@/components/ui';
import { env } from '@/config/env';
import { mapAppTypeToUrl } from '@/constants/systemLogin/urlRedirect';
import useAuthenticatedUser from '@/hooks/auth/useAuthenticatedUser';
import useLogin, { LoginPayload } from '@/hooks/auth/useLogin';
import { AppType } from '@/types/systemLogin';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';

type DecryptedCode = {
  companyId: number;
  app: AppType;
  email?: string;
};

export default function ShopifyOauthLogin() {
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const code = urlParams.get('bitbybit-code');

  const decryptedCode: DecryptedCode | undefined = code
    ? JSON.parse(
        CryptoJS.AES.decrypt(
          decodeURIComponent(code),
          env.REACT_APP_SHOPIFY_LOGIN_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      )
    : undefined;

  if (!decryptedCode) {
    return <BBBAlert message="Invalid params" />;
  }

  return <_ShopifyOauthLogin decryptedCode={decryptedCode} />;
}

function _ShopifyOauthLogin({
  decryptedCode,
}: {
  decryptedCode: DecryptedCode;
}) {
  const { data, isInitialLoading: isLoadingAuthenticatedUser } =
    useAuthenticatedUser();

  const companyId = decryptedCode.companyId.toString();

  const redirectedUrl = `/${mapAppTypeToUrl[decryptedCode.app]}`;

  if (isLoadingAuthenticatedUser) {
    return <BBBSpinner />;
  }

  if (data?.email === decryptedCode.email) {
    _localStorage.setItem('activeCompany', companyId);
    return <Redirect to={{ pathname: redirectedUrl }} />;
  }

  return (
    <__ShopifyOauthLogin
      decryptedCode={decryptedCode}
      redirectedUrl={redirectedUrl}
      companyId={companyId}
    />
  );
}

function __ShopifyOauthLogin({
  decryptedCode,
  redirectedUrl,
  companyId,
}: {
  decryptedCode: DecryptedCode;
  redirectedUrl: string;
  companyId: string;
}) {
  const history = useHistory();
  const { mutate: login } = useLogin();

  const memoizedLoginData = useMemo(
    () => ({ email: decryptedCode.email }),
    [decryptedCode.email]
  );

  const onSubmit = (data: LoginPayload) => {
    if ('provider' in data && data.email !== decryptedCode.email) {
      toast.error(
        `Email not match. Please authenticate with ${memoizedLoginData.email}`
      );
    } else {
      login(data, {
        onSuccess: () => {
          history.push(
            `/` + `?redirectTo=${encodeURIComponent(redirectedUrl)}`
          );
          _localStorage.setItem('activeCompany', companyId);
        },
      });
    }
  };

  return (
    <GeneralLayout>
      <LoginCard data={memoizedLoginData} onSubmit={onSubmit} hideRegisterCTA />
    </GeneralLayout>
  );
}
