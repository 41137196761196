const systemDefaultFonts =
  'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

const optionFonts = [
  {
    label: 'System Fonts',
    value: systemDefaultFonts,
  },
  {
    label: 'Montserrat',
    value: 'Montserrat, sans-serif',
  },
  {
    label: 'Roboto',
    value: 'Roboto, sans-serif',
  },
  {
    label: 'Open Sans',
    value: 'Open Sans, sans-serif',
  },
  {
    label: 'Noto Sans',
    value: 'Noto Sans, sans-serif',
  },
  {
    label: 'Lato',
    value: 'Lato, sans-serif',
  },
  {
    label: 'Poppins',
    value: 'Poppins, sans-serif',
  },
  {
    label: 'Quicksand',
    value: 'Quicksand, sans-serif',
  },
  {
    label: 'Raleway',
    value: 'Raleway, sans-serif',
  },
  {
    label: 'PT Sans',
    value: 'PT Sans, sans-serif',
  },
  {
    label: 'Oswald',
    value: 'Oswald, sans-serif',
  },
];

export default optionFonts;
export { systemDefaultFonts };
