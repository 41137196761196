import { Paths } from './nestedObjectPath';
import { PickByDotNotation } from './pickByDotNotation';

export default function getDescendantProp<
  T extends Record<string, any>,
  K extends Paths<T>
>(obj: T, desc: K) {
  const arr = desc.split('.');
  while (arr.length) {
    const shifted = arr.shift();
    if (shifted) obj = obj?.[shifted] || null;
  }
  return obj as RTGetDescendantProp<T, K>;
}

export type RTGetDescendantProp<
  TObjType,
  Path extends string
> = PickByDotNotation<TObjType, Path>;
