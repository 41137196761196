import { useMutation, useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { downloadXlsxFile } from '@/utils/common/file';

const ANALYTICS_QUERY_KEY = 'bitlogin-analytics' as const;

export const useUserGender = (
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: {
    enabled?: boolean;
  }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-user-gender`, data?.domain, params],
    () => services.bitLogin.analytics.getUserGender(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useVisitors = (
  params: {
    startDate: string;
    endDate: string;
    limit?: number;
    page?: number;
    search?: string;
  },
  options?: {
    enabled?: boolean;
  }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-visitors`, data?.domain, params],
    () => services.bitLogin.analytics.getVisitors(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useDownloadVisitors = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useMutation(
    async (
      params: Parameters<typeof services.bitLogin.analytics.downloadVisitors>[1]
    ) => services.bitLogin.analytics.downloadVisitors(data!.domain, params),
    {
      onSuccess: (data) => {
        downloadXlsxFile(data, 'Visitors.xlsx');
      },
    }
  );
};

export const useLoginSession = (
  params?: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-session-analytics`, data?.domain, params],
    () => services.bitLogin.analytics.getLoginSession(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useLoginLocation = (
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: {
    enabled?: boolean;
  }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-login-location`, data?.domain, params],
    () => services.bitLogin.analytics.getLoginLocation(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useLoginByProvider = (
  provider: string,
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');
  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-login-provider`, data?.domain, provider, params],
    () =>
      services.bitLogin.analytics.getLoginByProvider(
        data!.domain,
        provider,
        params
      ),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useLoginChannel = (
  params?: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-login-channel`, data?.domain, params],
    () => services.bitLogin.analytics.getLoginChannel(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const useNewUser = (
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-new-user`, data?.domain, params],
    () => services.bitLogin.analytics.getUser(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};

export const usePeakHourAnalytics = (
  params?: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-login-peakhours`, data?.domain, params],
    () => services.bitLogin.analytics.getLoginPeakHours(data!.domain, params),
    {
      enabled:
        (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
        status === 'success' &&
        !!data?.domain,
    }
  );
};
