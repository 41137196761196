import { UserPayloadPropsExist } from 'api/services/whatsApp/chat';
import { env } from 'config/env';
import { EVERYONE_AGENT_ID, staticAgents } from 'constants/bitChat/agent';
import {
  ChatTicket,
  ChatTicketHumanAgent,
  ChatTicketStaticAgent,
} from 'types/whatsApp/chat';

export function sendNotification({
  sender,
  lastMessage,
}: {
  sender: string;
  lastMessage: string;
}) {
  if (!('Notification' in window)) {
    console.info('This browser does not support system notifications!');
  } else if (Notification.permission === 'granted') {
    _sendNotification({ sender, lastMessage });
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        _sendNotification({ sender, lastMessage });
      }
    });
  }
}

function _sendNotification({
  sender,
  lastMessage,
}: {
  sender: string;
  lastMessage: string;
}) {
  const notification = new Notification(sender, {
    body: lastMessage,
    icon: `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/logo-black.svg`,
  });
  notification.onclick = () =>
    window.open(
      `https://${
        env.REACT_APP_NODE_ENV === 'production'
          ? 'app.bitbybit.studio'
          : 'dev.bitbybit.studio'
      }/bitchat/livechat`
    );
}

export const formatAgentName = (ticket: ChatTicket) => {
  if (staticAgents.includes(ticket.userId)) {
    return ticket.userId === EVERYONE_AGENT_ID ? 'Everyone' : 'AI Agent';
  }

  return (ticket as ChatTicketHumanAgent).agentName;
};

export const formatTicketAgentPayload = (
  _ticket: ChatTicket
): UserPayloadPropsExist => {
  if (staticAgents.includes(_ticket.userId)) {
    const ticket = _ticket as ChatTicketStaticAgent;

    return {
      userId: ticket.userId,
    };
  }

  const ticket = _ticket as ChatTicketHumanAgent;

  return {
    userId: ticket.userId,
    userAccent: ticket.agentAccent || null,
    userEmail: ticket.agentEmail,
    userDisplayName: ticket.agentName || null,
    userProfilePicture: ticket.agentProfilePicture || null,
  };
};
