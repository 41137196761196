import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import services from '@/api/services';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useDeleteAiTag = () => {
  const activeCompany = useActiveCompany();
  const toggleConfirmation = useConfirmationModal();
  const queryClient = useQueryClient();

  const deleteAiTagMutation = useMutation(
    (payload: string) =>
      services.whatsApp.settings.deleteAiTag(activeCompany, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitchat-settings']);
      },
    }
  );

  function deleteAiTag(withConfirmation: true): (param: string) => void;
  function deleteAiTag(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, string, unknown>;
  function deleteAiTag(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, string, unknown> {
    return withConfirmation
      ? (aiTagId) =>
          toggleConfirmation({
            title: 'Delete label',
            description: `Are you sure want to delete this label? Once deleted the action cannot be undone`,
            onAccept: async (hide) =>
              deleteAiTagMutation.mutate(aiTagId, {
                onSuccess: () => {
                  hide();
                },
              }),
            deleteModal: true,
            submitText: 'Delete',
            cancelText: 'Cancel',
          })
      : deleteAiTagMutation;
  }

  return deleteAiTag;
};

export default useDeleteAiTag;
