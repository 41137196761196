import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import useAccounts from '@/hooks/whatsApp/useAccounts';

const useUpdateTicket = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const queryClient = useQueryClient();
  const { data: accountData } = useAccounts();

  return useMutation(
    ({
      source,
      ticketId,
      status,
      userId,
      notes,
      tagId,
      ignoreAutoResolve,
      userAccent,
      userDisplayName,
      userProfilePicture,
    }: Omit<
      Parameters<typeof services.whatsApp.v2.ticket.updateTicket>[0],
      'companyId' | 'key'
    >) =>
      services.whatsApp.v2.ticket.updateTicket({
        companyId: activeCompany!,
        source: source,
        key: accountData!.apiKey,
        ticketId,
        userId,
        status,
        notes,
        tagId,
        ignoreAutoResolve,
        userAccent,
        userDisplayName,
        userProfilePicture,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`ticket`]);
        queryClient.invalidateQueries([`tickets`]);
        queryClient.invalidateQueries([`ticket-chat`]);
        queryClient.invalidateQueries([`chatlists`]);
      },
    }
  );
};

export default useUpdateTicket;
