import axios from 'axios';

import { customersApiBaseUrl } from '@/constants/api';
import { intercepts } from '@/utils/api';

export default intercepts(
  axios.create({
    baseURL: customersApiBaseUrl,
  })
);
