import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { Linklists } from '@/types/bitLink/v2';

const useLinklistQuery = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useInfiniteQuery<{
    data: Linklists[];
    meta: {
      page?: number;
      limit: number;
    };
  }>(
    ['linklist', activeCompany],
    async ({ pageParam = 1 }) => {
      const { data } = await api.bitLink.get(`/linklist/${activeCompany}`, {
        params: {
          page: pageParam,
          limit: 10,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.meta.page,
    }
  );
};

export default useLinklistQuery;
