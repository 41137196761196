import { Control, Controller, useFormState } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { timeUnitOptions } from 'constants/bitChat';
import { integrationsMeta, KnownIntegration } from 'constants/integrations';
import useResponsive from 'hooks/common/useResponsive';
import { useConnectedChannels } from 'hooks/whatsApp/channels';
import { twMerge as cx } from 'tailwind-merge';
import { MessageType as MessageTypeChatbot } from 'types/bitChat/chatbot';
import { IntegrationName } from 'types/bitChat/integrations';
import MessageType, { MessageTypeValue } from './MessageType';

import {
  BBBAlert,
  BBBCard,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';

const modules: (keyof MessageTypeValue)[] = ['userMessage'];

type ChannelFormType = 'WaCloud' | 'Ig' | 'Fb' | '';

export const channelFormTypes: ChannelFormType[] = ['', 'WaCloud', 'Ig', 'Fb'];
const mapChannelFormToKnownIntegration: Record<
  ChannelFormType,
  KnownIntegration
> = {
  '': 'whatsapp',
  WaCloud: 'whatsapp_meta',
  Ig: 'instagram',
  Fb: 'facebook',
};
const mapChannelFormToIntegrationName: Record<
  ChannelFormType,
  IntegrationName
> = {
  '': 'whatsapp',
  WaCloud: 'whatsapp_meta',
  Ig: 'instagram',
  Fb: 'facebook',
};

export const mapChannelFormToMessageType: Record<
  ChannelFormType,
  MessageTypeChatbot
> = {
  '': 'WHATS_APP',
  WaCloud: 'WHATSAPP_CLOUD_API',
  Ig: 'INSTAGRAM',
  Fb: 'FACEBOOK',
};

type AskRatingType = 'cs' | 'chatbot';
export const askRatingTypes: AskRatingType[] = ['cs', 'chatbot'];

export type AskRatingForm = {
  autoResolveTime: string;
  autoResolveUnit: { label: string; value: string } | null;
} & Partial<Record<`${AskRatingType}${ChannelFormType}Id`, string>> &
  Record<`${AskRatingType}${ChannelFormType}Active`, boolean> &
  Record<`${AskRatingType}Message${ChannelFormType}`, MessageTypeValue>;

export const askRatingDefaultValues: AskRatingForm = {
  csMessage: {},
  csMessageWaCloud: {},
  csMessageFb: {},
  csMessageIg: {},

  chatbotMessage: {},
  chatbotMessageWaCloud: {},
  chatbotMessageFb: {},
  chatbotMessageIg: {},

  csActive: false,
  csWaCloudActive: false,
  csFbActive: false,
  csIgActive: false,

  chatbotActive: false,
  chatbotWaCloudActive: false,
  chatbotFbActive: false,
  chatbotIgActive: false,

  autoResolveTime: '',
  autoResolveUnit: null,
};

export default function AskRating({
  control,
  type,
}: {
  control: Control<AskRatingForm, any>;
  type: AskRatingType;
}) {
  const isMobile = useResponsive('sm');

  const { errors } = useFormState({
    control,
  });

  const { data: channels } = useConnectedChannels();

  const connectedChannelForm = channelFormTypes.filter((channelForm) => {
    const integrationName = mapChannelFormToIntegrationName[channelForm];
    const channelConnected = channels?.find(
      (channel) =>
        channel.integration.name === integrationName &&
        channel.status === 'CONNECTED'
    );
    return channelConnected;
  });

  return (
    <BBBCard
      title={`Ask rating for ${type === 'cs' ? 'CS' : 'Chatbot'}`}
      className={cx(
        'mb-cardBottom',
        `${isMobile && 'mb-[150px] rounded-none'}`
      )}
      desc="This chat will sent to your customers after customer service resolved a ticket. The rating range is 1 - 5"
    >
      {!channels?.length ? (
        <BBBAlert
          type="info"
          message={
            <span className="text-primary-main">
              To access this feature, please connect to a channel.{' '}
              <Link
                to={'/integrations'}
                className="hover:underline font-bold cursor-pointer"
              >
                Connect now.
              </Link>
            </span>
          }
        />
      ) : (
        <>
          {type === 'chatbot' && (
            <div className="mb-8 flex items-center gap-2">
              Auto resolved ticket and ask rating for chatbot after
              <BBBTextInput
                placeholder="Number of time"
                containerClassname="mb-0"
                control={control}
                isHookForm
                controlName="autoResolveTime"
                error={errors.autoResolveTime?.message}
                type="tel"
              />
              <Controller
                control={control}
                render={({ field }) => (
                  <BBBSelect
                    placeholder="Time unit"
                    options={timeUnitOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onValueChange={field.onChange}
                    error={errors.autoResolveUnit?.message}
                  />
                )}
                name="autoResolveUnit"
              />
              of inactivity
            </div>
          )}

          {connectedChannelForm.map((channel) => (
            <Controller
              key={channel}
              control={control}
              name={`${type}Message${channel}`}
              render={({ field }) => {
                const integrationMeta =
                  integrationsMeta[mapChannelFormToKnownIntegration[channel]];
                const title = integrationMeta.title;
                const Icon = integrationMeta.icon;

                return (
                  <MessageType
                    value={field.value}
                    onValueChange={field.onChange}
                    modules={modules}
                    placeholder="ie: Hi! How’s your conversation experience? Give rating for our agent that you talked to. Just reply 1 - 5."
                    errors={{
                      userMessage:
                        errors[`${type}Message${channel}`]?.userMessage
                          ?.message,
                    }}
                    label={
                      <div className="flex items-center gap-1.5 mb-2.5">
                        {Icon && <Icon />}
                        <div className="grow font-bold">{title}</div>
                        <Controller
                          control={control}
                          name={`${type}${channel}Active`}
                          render={({ field }) => (
                            <BBBPrimarySwitch
                              checked={field.value || false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    }
                    containerClassName="mb-5"
                  />
                );
              }}
            />
          ))}
        </>
      )}
    </BBBCard>
  );
}
