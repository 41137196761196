import { shallowEqual } from 'react-redux';

import { useAppSelector } from '@/hooks/rtk/store';
import { RootState } from '@/stores';
import { Chat } from '@/types/whatsApp/chat';

const useChatFilter = () => {
  const _filter = useAppSelector(selectFilter, shallowEqual);

  return _filter;
};

export default useChatFilter;

export function selectFilter(state: RootState) {
  const filter = state.bitCRM.chatFilter;

  const _ticketFilters = filter?.filter(
    (opt) => opt.parentValue === 'ticket' && opt.value !== 'all'
  );

  const _agentFilters = filter?.filter(
    (opt) => opt.parentValue === 'agent' && opt.value !== 'all'
  );

  const _chatFilters = filter?.filter(
    (opt) => opt.parentValue === 'chat' && opt.value !== 'all'
  );

  const _channelFilters = filter?.filter(
    (opt) => opt.parentValue === 'channel' && opt.value !== 'all'
  );

  const _ticketCategoryFilters = filter?.filter(
    (opt) => opt.parentValue === 'ticket-category' && opt.value !== 'all'
  );

  const ticketFilters = _ticketFilters?.length
    ? _ticketFilters.map((filter) => filter.value).join(',')
    : undefined;

  const agentFilters = _agentFilters?.length
    ? _agentFilters.map((filter) => filter.value).join(',')
    : undefined;

  const chatFilters = _chatFilters?.length
    ? _chatFilters.map((filter) => filter.value).join(',')
    : undefined;

  const channelFilters = _channelFilters?.length
    ? _channelFilters.map((filter) => filter.value).join(',')
    : undefined;

  const ticketCategoryFilters = _ticketCategoryFilters?.length
    ? _ticketCategoryFilters.map((filter) => filter.value).join(',')
    : undefined;

  return {
    sources: channelFilters as Chat['sources'] | undefined,
    tickets: ticketFilters,
    agents: agentFilters,
    chat: chatFilters,
    ticketCategory: ticketCategoryFilters,
  };
}
