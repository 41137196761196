import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { X } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';
import { ShoppableType } from '..';

import { env } from '@/config/env';
import useCollection from '@/hooks/bitLink/shopify/useCollection';
import useProduct from '@/hooks/bitLink/shopify/useProduct';
import useResponsive from '@/hooks/common/useResponsive';

type Props = {
  type: ShoppableType;
  items: string;
  onRemove: (item: string) => void;
  index: number;
};

function ShoppableProductRenderer({ type, onRemove, items, index }: Props) {
  const isMobile = useResponsive('sm');
  const { data: productData, isInitialLoading: isLoadingProducts } = useProduct(
    items,
    {
      enabled: type === 'product',
    }
  );

  const { data: collectionData, isInitialLoading: isLoadingCollection } =
    useCollection(items, {
      enabled: type === 'collection',
    });

  const collectionDataFiltered = collectionData?.products?.edges?.filter(
    (item) => item.node?.status === 'ACTIVE'
  );

  return (
    <>
      {type === 'product' ? (
        isLoadingProducts ? (
          isMobile ? (
            <div className="flex gap-4 items-center">
              <Skeleton width={40} height={40} />
              <Skeleton width={200} />
            </div>
          ) : (
            <Skeleton width={140} height={140} borderRadius="4px" />
          )
        ) : productData ? (
          <ShoppableItem
            id={productData.id}
            key={productData.id}
            imageUrl={productData.featuredImage?.url}
            title={productData.title}
            index={index}
            onRemove={() => onRemove(productData.id)}
          />
        ) : (
          <EmptyItemRenderer items={items} onRemove={() => onRemove(items)} />
        )
      ) : isLoadingCollection ? (
        <Skeleton width={140} height={140} borderRadius="4px" />
      ) : collectionData?.products?.edges.length ? (
        collectionDataFiltered?.map((edge) => (
          <ShoppableItem
            id={edge.node.id}
            key={edge.node.id}
            imageUrl={edge.node.featuredImage?.url}
            title={edge.node.title}
            index={index}
            onRemove={() => onRemove(edge.node.id)}
          />
        ))
      ) : (
        <EmptyItemRenderer items={items} onRemove={() => onRemove(items)} />
      )}
    </>
  );
}

function EmptyItemRenderer({
  items,
  onRemove,
}: {
  items: string;
  onRemove: () => void;
}) {
  return (
    <div className="p-2 rounded w-[10em] h-[8em] flex-none text-center break-words flex flex-col justify-between">
      <div className="text-sm">{items} is not available</div>
      <div
        className="mt-1 underline text-red-400 text-sm cursor-pointer"
        onClick={onRemove}
      >
        Remove collection
      </div>
    </div>
  );
}

function ShoppableItem({
  id,
  key,
  imageUrl,
  title,
  onRemove,
  index,
}: {
  id: string;
  key: string;
  imageUrl: string;
  title: string;
  onRemove: (item: string) => void;
  index: number;
}) {
  const isMobile = useResponsive('sm');

  const [item, setItem] = useState('');

  const removeItem = (key: string) => {
    setItem(key);
    onRemove(key);
  };

  return (
    <>
      {item !== key && (
        <Draggable draggableId={id} key={id} index={index}>
          {(providedDraggable) => (
            <div
              className={isMobile ? 'flex gap-4 items-center' : undefined}
              ref={providedDraggable?.innerRef}
              {...providedDraggable?.draggableProps}
            >
              <div
                className="relative border rounded-md border-neutral-30 overflow-hidden"
                {...providedDraggable?.dragHandleProps}
              >
                <img
                  src={
                    imageUrl
                      ? imageUrl
                      : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
                  }
                  alt={title}
                  className={cx(
                    'flex-none rounded object-cover w-36 h-36 max-w-none',
                    isMobile ? 'w-10 h-10' : undefined
                  )}
                  loading="lazy"
                />
                <X
                  style={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    borderRadius: 20,
                    cursor: 'pointer',
                    backgroundColor: 'rgb(0 0 0 / 70%)',
                    color: '#FFFFFF',
                    padding: 2,
                  }}
                  onClick={() => removeItem(key)}
                />
              </div>
              {isMobile && item !== key && (
                <div className="text-sm">{title}</div>
              )}
            </div>
          )}
        </Draggable>
      )}
    </>
  );
}

export default ShoppableProductRenderer;
