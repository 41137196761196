export default function MetaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 94 94"
    >
      <path
        fill="#fff"
        d="M27.084 15.784c-7.708 0-14.425 5.013-19.078 12.193C2.757 36.065 0 46.542 0 56.592c0 2.765.274 5.362.823 7.728a27.42 27.42 0 001.037 3.368c.405 1.03.89 2.027 1.453 2.98 2.727 4.54 7.121 7.548 14.073 7.548 5.863 0 10.313-2.628 15.53-9.572 2.976-3.964 4.48-6.369 10.43-16.92l2.96-5.245.73-1.273c.238.392.473.768.716 1.175l8.429 14.08c2.835 4.74 6.52 10.012 9.674 12.98 4.097 3.866 7.802 4.779 11.985 4.779 4.21 0 7.347-1.39 9.615-3.302a14.494 14.494 0 003.173-3.81C92.75 67.428 94 62.775 94 56.438c0-10.653-2.667-20.981-8.162-29.179-5.021-7.489-11.582-11.476-18.471-11.476-4.101 0-8.178 1.83-11.958 5.123-2.554 2.233-4.923 5.053-7.128 8.03-2.703-3.428-5.229-6.06-7.67-8.053-4.629-3.784-9.066-5.104-13.527-5.104v.004zm39.793 8.041c4.493 0 8.57 2.969 11.719 7.83 4.433 6.846 6.45 16.43 6.45 25.066 0 6.063-1.44 11.358-7.202 11.358-2.272 0-4.023-.9-6.518-3.932-1.942-2.354-5.26-7.355-11.092-17.069l-2.416-4.026a176.184 176.184 0 00-4.916-7.755c.275-.427.553-.877.827-1.28 4.386-6.53 8.291-10.192 13.148-10.192zm-39.954 2.166c4.955 0 8.06 3.098 10.477 5.663 1.203 1.281 2.887 3.412 4.833 6.185l-3.995 6.133c-2.965 4.556-7.37 11.817-11.111 16.99-4.665 6.46-7.09 7.117-9.737 7.117-2.052 0-4.066-.928-5.417-3.11-1.03-1.668-1.817-4.425-1.817-8.013 0-8.699 2.467-17.762 6.502-23.845 1.778-2.69 3.775-4.801 6.004-6.004a8.852 8.852 0 014.261-1.116z"
      ></path>
    </svg>
  );
}
