export default function WhatsAppCloudApiIcon({
  size = 24,
  color,
}: {
  size?: number | string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_1007_18699)">
        <path
          fill="#fff"
          d="M.435 11.707c.06 1.826.504 3.866 1.241 5.27L0 24l7.128-1.712a11.995 11.995 0 005.085 1.135h.005c6.493 0 11.78-5.252 11.782-11.707a11.6 11.6 0 00-3.448-8.283A11.743 11.743 0 0012.218 0C5.723 0 .438 5.252.435 11.707"
        ></path>
        <path
          fill="#fff"
          d="M2.249 11.704a9.89 9.89 0 001.33 4.964l-1.413 5.146 5.28-1.381a9.979 9.979 0 004.76 1.208h.004c5.49 0 9.958-4.454 9.96-9.93a9.854 9.854 0 00-2.915-7.024 9.912 9.912 0 00-7.045-2.912c-5.49 0-9.958 4.454-9.96 9.929"
        ></path>
        <path
          fill="url(#paint0_linear_1007_18699)"
          d="M1.077 22.89l1.682-6.122a10.686 10.686 0 01-1.275-5.064C1.487 5.808 6.298 1.01 12.21 1.01a10.674 10.674 0 017.586 3.136 10.62 10.62 0 013.14 7.567c-.003 5.897-4.814 10.695-10.725 10.695h-.005a10.776 10.776 0 01-4.852-1.159L1.077 22.89zm6.468-3.274l.265.144a9.242 9.242 0 004.396 1.116h.004c5.068 0 9.193-4.11 9.196-9.164 0-2.447-.955-4.75-2.69-6.481a9.157 9.157 0 00-6.507-2.69c-5.068 0-9.193 4.112-9.195 9.164-.001 1.608.422 3.188 1.226 4.58l.161.278-1.147 4.175 4.29-1.122z"
        ></path>
        <path
          fill="url(#paint1_linear_1007_18699)"
          d="M8.045 13.283l-.428 1.547h-1.41l1.838-6.026H9.83l1.865 6.026h-1.463l-.464-1.547H8.045zm1.526-1.019l-.375-1.278c-.107-.358-.214-.805-.303-1.163h-.018c-.09.358-.178.814-.277 1.163l-.357 1.278h1.33z"
        ></path>
        <path
          fill="url(#paint2_linear_1007_18699)"
          d="M12.039 8.885c.419-.072 1.008-.126 1.838-.126.839 0 1.437.161 1.839.483.383.304.642.805.642 1.395s-.196 1.09-.553 1.43c-.464.438-1.151.635-1.954.635a3.429 3.429 0 01-.464-.027v2.155h-1.348V8.885zm1.347 2.735c.116.027.259.036.455.036.723 0 1.17-.367 1.17-.984 0-.554-.384-.885-1.063-.885-.276 0-.464.027-.562.054v1.78z"
        ></path>
        <path
          fill="url(#paint3_linear_1007_18699)"
          d="M18.13 8.804v6.026h-1.366V8.804h1.366z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1007_18699"
          x1="12.006"
          x2="12.006"
          y1="22.89"
          y2="1.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#26AF4B'}></stop>
          <stop offset="1" stopColor={color || '#71C168'}></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1007_18699"
          x1="6.207"
          x2="11.695"
          y1="11.817"
          y2="11.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#26AF4B'}></stop>
          <stop offset="1" stopColor={color || '#71C168'}></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1007_18699"
          x1="12.039"
          x2="16.358"
          y1="11.794"
          y2="11.794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#26AF4B'}></stop>
          <stop offset="1" stopColor={color || '#71C168'}></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1007_18699"
          x1="16.764"
          x2="18.13"
          y1="11.817"
          y2="11.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#26AF4B'}></stop>
          <stop offset="1" stopColor={color || '#71C168'}></stop>
        </linearGradient>
        <clipPath id="clip0_1007_18699">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
