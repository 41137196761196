import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import services from '@/api/services';
import { disabledIntegrations } from '@/constants/bitCRM/integrations';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import {
  CompanyIntegration,
  CompanyIntegrationAssociation,
  IntegrationExtra,
  IntegrationName,
} from '@/types/bitCRM/integrations';
import { toast } from '@/utils/common/toast';

export const useIntegrations = () =>
  useQuery(
    ['bitcrm-integrations'],
    () => services.bitCRM.v2.integrations.getIntegrations(),
    {
      select: (data) => {
        return data.map((integrationCategory) => ({
          ...integrationCategory,
          integrations: integrationCategory.integrations.filter(
            (data) => !disabledIntegrations.includes(data.name)
          ),
        }));
      },
    }
  );

export const useCompanyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitcrm-company-integrations', activeCompany],
    () => services.bitCRM.v2.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      ...options,
    }
  );
};

export const useShopifyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitcrm-company-integrations', activeCompany],
    () => services.bitCRM.v2.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'Shopify'),
      ...options,
    }
  );
};

export const useShopifyIntegrationExtra = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    IntegrationExtra | null,
    (string | number | undefined)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitcrm-company-integrations', activeCompany],
    () => services.bitCRM.v2.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) =>
        companyIntegrationSelector(data, 'Shopify')?.extra || null,
      ...options,
    }
  );
};

export const useToggleIntegration = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const userId = useUserId();

  return useMutation(
    (data: Pick<CompanyIntegration, 'integrationId' | 'status'>) =>
      services.bitCRM.v2.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([
          'bitcrm-company-integrations',
          activeCompany,
        ]);
        client.invalidateQueries(['bitcrm-shopify', activeCompany]);
        toast.success('Integration disconnected successfully!');
      },
    }
  );
};

function companyIntegrationSelector(
  data: CompanyIntegrationAssociation[],
  name: IntegrationName,
  options?: { includeDisconnected?: boolean }
) {
  return (
    options?.includeDisconnected
      ? data
      : data.filter((integration) => integration.status === 'CONNECTED')
  ).find((integration) => integration.integration.name === name);
}
