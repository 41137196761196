function BriefcaseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        d="M6.313 4.2h3.375c.103 0 .187.09.187.2v1h-3.75v-1c0-.11.084-.2.188-.2zM5 4.4v1H3.5C2.673 5.4 2 6.117 2 7v2.4h12V7c0-.883-.673-1.6-1.5-1.6H11v-1c0-.773-.588-1.4-1.313-1.4H6.313C5.588 3 5 3.627 5 4.4zm9 5.8H9.5v.8c0 .443-.335.8-.75.8h-1.5c-.415 0-.75-.357-.75-.8v-.8H2v3.2c0 .883.673 1.6 1.5 1.6h9c.827 0 1.5-.717 1.5-1.6v-3.2z"
      ></path>
    </svg>
  );
}

export default BriefcaseIcon;
