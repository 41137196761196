import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import LivePreview from './components/LivePreview';
import HeaderLink from './Link/components/Header';

import useRearrangeLinklist from '@/hooks/bitLink/links/useRearrangeLinklist';
import { useUpdateSocialMediaPosition } from '@/hooks/bitLink/socmed/useUpdateSocialMedia';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppSelector } from '@/hooks/rtk/store';
import { Linklists, SocialMedia, SocialMediaKind } from '@/types/bitLink/v2';

type Props = { children: React.ReactNode };

export default function Wrapper({ children }: Props) {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const client = useQueryClient();

  const { mutate: rearrangeSocial } = useUpdateSocialMediaPosition();

  const { mutate: rearrangeLinklist } = useRearrangeLinklist();
  const isMobile = useResponsive('sm');

  /**
   * @deprecated
   * Drag context be moved to each modules
   */
  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      source.droppableId === 'linklist' &&
      destination?.droppableId === 'linklist'
    ) {
      const rerrangedList =
        client
          .getQueryData<
            | InfiniteData<{
                data: Linklists[];
                meta: {
                  page?: number | undefined;
                  limit: number;
                };
              }>
            | undefined
          >(['linklist', activeCompany])
          ?.pages.flatMap((page) => page.data) || [];

      const { destination, source } = result;
      const [reorderedItem] = rerrangedList.splice(source.index, 1);
      rerrangedList.splice(destination?.index || -99, 0, reorderedItem);
      rearrangeLinklist(rerrangedList);
    }
    if (
      source.droppableId === 'social' &&
      destination?.droppableId === 'social'
    ) {
      const cloneSocial = cloneDeep(
        (client.getQueryData([
          'bitlink-social',
          activeCompany,
        ]) as (SocialMedia & {
          socialMediaKind: SocialMediaKind;
        })[]) || []
      );
      const [reorderedItem] = cloneSocial.splice(source.index, 1);
      cloneSocial.splice(destination?.index ?? -99, 0, reorderedItem);
      rearrangeSocial(
        cloneSocial.map((data, index) => ({
          socialMediaKindId: data?.socialMediaKindId,
          position: index + 1,
        }))
      );
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="flex min-h-full">
        <div className={cx('flex-[3_3_0%] flex flex-col md:border-r-2')}>
          <HeaderLink />
          <div className="grow relative">
            <div className="absolute inset-0">{children}</div>
          </div>
        </div>
        {!isMobile && (
          <div className="flex-[2_2_0%] flex justify-center items-center overflow-y-auto">
            <LivePreview />
          </div>
        )}
      </div>
    </DragDropContext>
  );
}
