import { useActiveCompany } from '../rtk/selector';
import { useUser } from '../rtk/selector';

import { PlanType } from '@/api/services/pricing';
import { pricingApiBaseUrl } from '@/constants/api';
import { AppType } from '@/types/systemLogin';
import { formatUserDisplayName } from '@/utils/auth';

const usePricingBillingUrl = ({
  appType,
  type,
}: {
  appType: AppType;
  type: PlanType;
}) => {
  const activeCompany = useActiveCompany();

  const user = useUser();

  const queryParams = {
    app: appType,
    type,
    email: user.email,
    displayName: formatUserDisplayName(user),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const baseUrl = `${pricingApiBaseUrl}/pricing/company/${activeCompany}/billing?${queryString}`;

  return baseUrl;
};

export default usePricingBillingUrl;
