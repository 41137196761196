import { useEffect, useMemo, useState } from 'react';
import { PlusCircle, Trash2 } from 'react-feather';
import { useFieldArray, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { motion } from 'framer-motion';
import { isEqual } from 'lodash-es';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { BBBCard } from '@/components/ui/BBBCard';
import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import { BBBTextAreaInput, BBBTextInput } from '@/components/ui/BBBTextInput';
import useDeleteAiTag from '@/hooks/bitChat/settings/useDeleteAiTag';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { SettingsAITagging } from '@/types/whatsApp/settings';
import { cn } from '@/utils/styles';

export type FormAiTagging = {
  aiTags: (Omit<SettingsAITagging, 'id'> & {
    aiTagId: string;
  })[];
};

const schema = yup.object().shape({
  aiTags: yup.array(
    yup.object().shape({
      label: yup.string().required().label('Label'),
      description: yup.string().required().label('Description'),
      values: yup.string().required().label('Value'),
      aiTagId: yup.string(),
    })
  ),
});

export default function AiTagging() {
  const [hoverAiTags, setHoverAiTags] = useState<number | undefined>(undefined);
  const { control, reset, watch, handleSubmit } = useCustomForm<FormAiTagging>({
    defaultValues: {
      aiTags: [],
    },
    resolver: yupResolver(schema),
  });
  const { append, fields } = useFieldArray({
    control,
    name: 'aiTags',
  });
  const { errors } = useFormState({ control });

  const deleteAiTag = useDeleteAiTag();
  const { data } = useSettings();
  const { toggle } = useConfirmationBanner();
  const { data: pricingData } = usePricingByApp('BITCHAT');

  const {
    data: pricingCRM,
    limit,
    isReachingTreshold,
  } = useWithCRMPricing('BITCHAT', 'ai_tagging');
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const dataFromApi = useMemo<Partial<FormAiTagging>>(
    () => ({
      aiTags: data?.aiTags?.length
        ? data?.aiTags.map(({ id, ...tag }) => ({ ...tag, aiTagId: id }))
        : [],
    }),
    [data?.aiTags]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    const onSubmit = ({ aiTags }: Partial<FormAiTagging>) => {
      updateSettings({
        aiTags: aiTags?.map((tag) => ({
          ...tag,
          id: tag.aiTagId || v4(),
        })),
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('form-ai-tagging', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingUpdateSettings ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    reset,
    toggle,
    updateSettings,
    watch(),
  ]);

  return (
    <BBBCard title="AI tagging" desc="Optimize your own AI, fit what you need">
      <div className="flex flex-col gap-6">
        {fields?.map((field, index) => (
          <div key={field.id}>
            <div
              className={cn(
                'text-ls text-neutral-60 cursor-pointer relative inline-block mb-2',
                hoverAiTags === index && 'underline'
              )}
              onMouseEnter={() => setHoverAiTags(index)}
              onMouseLeave={() => {
                setHoverAiTags(undefined);
              }}
              onClick={() => deleteAiTag(true)(field.aiTagId)}
            >
              Label {index > 0 && index + 1}
              <motion.div
                animate={{
                  opacity: hoverAiTags === index ? 1 : 0,
                  x: hoverAiTags === index ? 'calc(100% + 0.25rem)' : 0,
                }}
                className="absolute z-10 top-1 right-0"
                transition={{ duration: 0.3 }}
              >
                <Trash2 size={12} color="#9E9E9E" />
              </motion.div>
            </div>
            <div className="flex gap-2 mb-2">
              <BBBTextInput
                hasMaxCharLabel
                maxChar={12}
                isHookForm
                control={control}
                controlName={`aiTags.${index}.label`}
                containerClassname="mb-0"
                placeholder="Label name"
                error={errors.aiTags?.[index]?.label?.message}
              />
              <BBBTextInput
                hasMaxCharLabel
                maxChar={60}
                isHookForm
                control={control}
                controlName={`aiTags.${index}.description`}
                containerClassname="mb-0 grow"
                placeholder="Label description"
                error={errors.aiTags?.[index]?.description?.message}
              />
            </div>
            <BBBTextAreaInput
              placeholder="Input values for this label"
              rows={5}
              isHookForm
              control={control}
              controlName={`aiTags.${index}.values`}
              error={errors.aiTags?.[index]?.values?.message}
            />
          </div>
        ))}
      </div>
      <div
        className={cn(
          'mt-5 flex items-center gap-2 cursor-pointer text-neutral-40 hover:text-neutral-50 transition-colors',
          isReachingTreshold && 'opacity-50 pointer-events-none'
        )}
        onClick={() =>
          append({
            aiTagId: createId(),
            label: '',
            values: '',
            description: '',
          })
        }
      >
        <PlusCircle />
        <div>Add new label</div>
      </div>
      {isReachingTreshold && pricingData && (
        <BBBLimitAlert
          usage={pricingCRM?.usage}
          appType="BITCHAT"
          module={pricingCRM?.pricingFeature.label}
          limit={limit}
          currentPlan={{
            label: pricingData.pricingModule.label,
            name: pricingData.pricingName,
          }}
          className="mt-6"
          customExceedTresholdLabel={
            <p className="text-primary-main font-semibold text-sm">
              You reached your limit. Upgrade plan to create more label.
            </p>
          }
          withoutTip
          forceCloseable
        />
      )}
    </BBBCard>
  );
}
