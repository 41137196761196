import { useEffect } from 'react';
import { useHasWhatsappIntegrations } from './channels';

import { integrationsMeta } from '@/constants/integrations';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';

const useWhatsAppBannerPrompt = (options?: { enabled?: boolean }) => {
  const { toggle } = useConfirmationBanner();
  const { data: hasWhatsappIntegrations, status } =
    useHasWhatsappIntegrations(options);

  useEffect(() => {
    if (status === 'success' && !hasWhatsappIntegrations) {
      toggle('whatsapp-business-connect-prompt', {
        text: <BannerPrompt />,
        isCloseable: true,
        show: !hasWhatsappIntegrations,
      });
    }
  }, [status, toggle, hasWhatsappIntegrations]);
};

export default useWhatsAppBannerPrompt;

function BannerPrompt() {
  return (
    <>
      Broadcast 10 targeted WhatsApp chats a day to segmented customers.{' '}
      <a
        href="/integrations"
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        Click here to connect your number and expand your limit with{' '}
        {integrationsMeta.whatsapp.title}
      </a>
    </>
  );
}
