import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';

export type BitLinkShopifyProducts = {
  id: string;
  featuredImage: { url: string };
  title: string;
  status: 'ACTIVE' | 'ARCHIVED' | 'DRAFT';
  onlineStoreUrl: string;
};

export type BitLinkShopifyCollections = {
  id: string;
  featuredImage: { url: string };
  image: { url: string };
  title: string;
  type: string;
  url: string;
  products: ShopifyConnection<BitLinkShopifyProducts>;
};

const useCollectionQuery = (
  search: string,
  options?:
    | Omit<
        UseInfiniteQueryOptions<
          {
            meta: {
              endCursor?: string;
              hasNextPage?: boolean;
            };
            data: BitLinkShopifyCollections[];
          },
          string[]
        >,
        string
      >
    | undefined
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useInfiniteQuery<{
    meta: {
      endCursor?: string;
      hasNextPage?: boolean;
    };
    data: BitLinkShopifyCollections[];
  }>(
    ['bitlink-collections', activeCompany?.toString() as string, search],
    async ({ pageParam }) => {
      const params = {};
      if (search) {
        Object.assign(params, {
          q: search,
        });
      } else {
        Object.assign(params, {
          cursor: pageParam,
        });
      }

      const { data } = await api.bitLink.get(
        `/shopify/collection/company/${activeCompany}`,
        {
          params,
        }
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor || undefined,
      ...options,
    }
  );
};

const useProductQuery = (
  search?: string,
  options?:
    | Omit<
        UseInfiniteQueryOptions<
          {
            meta: {
              endCursor?: string;
              hasNextPage?: boolean;
            };
            data: BitLinkShopifyProducts[];
          },
          string[]
        >,
        string
      >
    | undefined
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useInfiniteQuery<{
    meta: {
      endCursor?: string;
      hasNextPage?: boolean;
    };
    data: BitLinkShopifyProducts[];
  }>(
    ['bitlink-products', activeCompany?.toString() as string, search],
    async ({ pageParam }) => {
      const params = {};
      if (search) {
        Object.assign(params, {
          q: search,
        });
      } else {
        Object.assign(params, {
          cursor: pageParam,
        });
      }

      const { data } = await api.bitLink.get(
        `/shopify/product/company/${activeCompany}`,
        {
          params,
        }
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor || undefined,
      ...options,
    }
  );
};

export default useCollectionQuery;
export { useProductQuery };
