import { usePricings } from './v2/pricing';

import { PlanType } from '@/api/services/pricing';
import { AppType } from '@/types/systemLogin';

const usePricingOptions = (
  app: AppType,
  type: PlanType,
  options?: { enabled?: boolean }
) => {
  return usePricings({ app, type }, options);
};

export default usePricingOptions;
