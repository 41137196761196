import { useState } from 'react';
import { ArrowUpRight, Info } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import { BBBCard, BBBTooltip, BBBTooltipProps } from '@/components/ui';
import colors from '@/constants/common/colors';
import useResponsive from '@/hooks/common/useResponsive';

export type BBBOverviewBoxProps = {
  title?: string | React.ReactNode;
  value: string | number | React.ReactNode;
  status?: string;
  percent?: string | number | null;
  className?: string;
  valueClassName?: string;
  isRedirect?: boolean;
  redirectUrl?: string;
  loading?: boolean;

  // Tooltip Props
  tooltip?: BBBTooltipProps['content'];
  tooltipPosition?: BBBTooltipProps['position'];
  tooltipClassName?: BBBTooltipProps['className'];
};

const BBBOverviewBox = ({
  title,
  value,
  status,
  percent,
  className,
  valueClassName,
  isRedirect,
  redirectUrl,
  loading,

  // Tooltip Props
  tooltip,
  tooltipPosition,
  tooltipClassName,
}: BBBOverviewBoxProps) => {
  const isMobile = useResponsive('sm');

  const [hover, setHover] = useState({
    titleHover: false,
    valueHover: false,
  });

  const humanReadableValue =
    typeof value === 'number' ? value.toLocaleString() : value;

  return (
    <BBBCard
      title={
        <div className="flex gap-1 items-center w-fit">
          {isRedirect && !isMobile ? (
            <>
              <Link
                to={redirectUrl || '/analytics'}
                className="text-base font-normal lg:whitespace-nowrap hover:underline transition-all"
                onMouseEnter={() =>
                  setHover((prev) => ({
                    ...prev,
                    titleHover: true,
                  }))
                }
                onMouseLeave={() =>
                  setHover((prev) => ({
                    ...prev,
                    titleHover: false,
                  }))
                }
              >
                {title}
              </Link>
              {tooltip && (
                <BBBTooltip
                  show={!!tooltip}
                  content={tooltip}
                  position={tooltipPosition}
                  className={tooltipClassName}
                >
                  <Info size={14} color={colors.neutral['40']} />
                </BBBTooltip>
              )}
              {hover.titleHover && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <LinkOutIcon size={14} color={colors.neutral['40']} />
                </motion.div>
              )}
            </>
          ) : (
            <>
              <p className="text-base font-normal lg:whitespace-nowrap">
                {title}
              </p>
              {tooltip && (
                <BBBTooltip
                  show={!!tooltip}
                  content={tooltip}
                  position={tooltipPosition}
                  className={tooltipClassName}
                >
                  <Info size={14} color="#9E9E9E" />
                </BBBTooltip>
              )}
            </>
          )}
        </div>
      }
      headerClassName="-mb-2"
      className={cx(className)}
    >
      {loading ? (
        <Skeleton width={100} height={25} />
      ) : (
        <div className="flex gap-2 mt-2 items-center w-fit">
          {isRedirect ? (
            <Link
              to={redirectUrl || '/analytics'}
              className={cx(
                'text-2xl font-medium hover:underline transition-all',
                valueClassName
              )}
              onMouseEnter={() =>
                setHover((prev) => ({
                  ...prev,
                  valueHover: true,
                }))
              }
              onMouseLeave={() =>
                setHover((prev) => ({
                  ...prev,
                  valueHover: false,
                }))
              }
            >
              {humanReadableValue}
            </Link>
          ) : (
            <div className={cx('text-2xl font-medium', valueClassName)}>
              {humanReadableValue}
            </div>
          )}
          {hover.valueHover && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <LinkOutIcon size={14} color={colors.neutral['40']} />
            </motion.div>
          )}
          {!!percent && (
            <div
              className={cx(
                `flex items-center ${
                  status === 'UP' ? 'text-success-main' : 'text-danger-main'
                }`
              )}
            >
              <ArrowUpRight
                width={14}
                height={14}
                className={status === 'DOWN' ? 'rotate-90' : 'rotate-0'}
              />
              <p className="text-xs">
                {typeof percent === 'string' ? percent : percent.toFixed(2)}%
              </p>
            </div>
          )}
        </div>
      )}
    </BBBCard>
  );
};

export default BBBOverviewBox;
