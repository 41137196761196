export default {
  neutral: {
    10: '#FFFFFF',
    20: '#F8F8F8',
    30: '#DDDDDD',
    40: '#9E9E9E',
    50: '#757575',
    60: '#424242',
    70: '#0A0A0A',
  },
  primary: {
    main: '#262627',
    surface: '#D4D4D4',
    border: '#B7B7B7',
    hover: '#202021',
    pressed: '#131314',
    focus: '#CECECE',
  },
  secondary: {
    main: '#FD823E',
    surface: '#FFE6D8',
    border: '#FED5BF',
    hover: '#D36C34',
    pressed: '#7E411F',
    focus: '#F9E5D3',
  },
  success: {
    main: '#79D64F',
    surface: '#E4F7DC',
    border: '#D2F1C4',
    hover: '#65B242',
    pressed: '#3D6B28',
  },
  warning: {
    main: '#CD7B2E',
    surface: '#FFF9F2',
    border: '#EECEB0',
    hover: '#BF6919',
    pressed: '#734011',
  },
  info: {
    surface: '#D4EBFE',
    main: '#2699FB',
  },
  grayColor: '#E2E2E2',
  waGreen: '#25D366',
  grayColor2: '#DDDDDD',
  danger: { main: '#FF6060', surface: '#FFDFDF' },
};
