import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useSortingModel = (id: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitai-sorting-model', id],
    () => services.bitAi.v2New.model.getModel(activeCompany, id),
    {
      enabled: !id?.includes('new'),
    }
  );
};

export default useSortingModel;
