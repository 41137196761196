import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import api from 'config/api';
import { BitLinkShopifyProducts } from './useCollectionQuery';

import { useAppSelector } from '@/hooks/rtk/store';

const useProduct = (
  productId: string | undefined,
  { enabled, ...options }: UseQueryOptions<BitLinkShopifyProducts>
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery<BitLinkShopifyProducts>(
    ['bitlink-shopify-products', productId],
    async () => {
      const { data } = await api.bitLink.get(
        `/shopify/product/${encodeURIComponent(
          productId!
        )}/company/${activeCompany}`
      );
      return data;
    },
    {
      enabled: !!productId && enabled,
      ...options,
    }
  );
};
export default useProduct;
