import { twMerge as cx } from 'tailwind-merge';
import { v4 } from 'uuid';

import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import useSendMessage from '@/hooks/whatsApp/useSendMessage';
import { MessageNewAssociation } from '@/types/whatsApp/v3';

export default function ChatBubbleButtons({
  chat,
}: {
  chat: MessageNewAssociation;
}) {
  const { mutate: sendMessage } = useSendMessage();
  const fromMe = chat.fromMe;

  const buttonMsg = chat.buttonMsg!;

  return (
    <div
      className={cx(
        'mt-2 border-t',
        buttonMsg.length &&
          (!fromMe ? 'border-neutral-10' : 'border-secondary-main')
      )}
    >
      {buttonMsg.map((button) =>
        button.link ? (
          <a
            href={button.link}
            target="_blank"
            rel="noreferrer"
            className="mt-2 flex flex-wrap gap-2 items-center"
            key={button.id}
          >
            <ChatBubbleButton
              key={button.id}
              chat={chat}
              label={button.text}
              isLink
            />
          </a>
        ) : (
          <div
            className="mt-2 flex flex-wrap gap-2 items-center"
            key={button.id}
          >
            <ChatBubbleButton
              key={button.id}
              chat={chat}
              label={button.text}
              onClick={() => {
                sendMessage({
                  message: button.text,
                  type: 'chat',
                  temporaryId: v4(),
                });
              }}
            />
          </div>
        )
      )}
    </div>
  );
}

type ChatBubbleButtonProps = {
  chat: MessageNewAssociation;
  onClick?: () => void;
  label?: string;
  isLink?: boolean;
};

function ChatBubbleButton({
  chat,
  onClick,
  label,
  isLink,
}: ChatBubbleButtonProps) {
  const fromMe = chat.fromMe;

  return (
    <div
      className={cx(
        'rounded-lg mb-1 flex-1 px-3 text-[#2B6AAF] flex gap-2 justify-center py-2 text-sm last:mb-0',
        !fromMe ? 'cursor-pointer pointer-events-auto' : 'pointer-events-none'
      )}
      onClick={onClick}
    >
      {isLink && (
        <div className="flex-none">
          <LinkOutIcon />
        </div>
      )}
      <div className="line-clamp-1">{label}</div>
    </div>
  );
}
