import { useState } from 'react';

import CompanyCreationCard, {
  CompanyCreationFormSchema,
} from '@/components/Auth/CompanyCreationCard';
import RegisterCard, {
  RegisterFormSchema,
} from '@/components/Auth/RegisterCard';
import useAddUserCompanyByUserId from '@/hooks/auth/useAddUserCompanyByUserId';
import useAuthorizeShopifyConnect from '@/hooks/auth/useAuthorizeShopifyConnect';
import { SocialLoginPayload } from '@/hooks/auth/useLogin';
import useOauthRegister from '@/hooks/auth/useOauthRegister';
import { AppType } from '@/types/systemLogin';
import { formatPhonePayload } from '@/utils/common/phone';

type ShopifyOauthRegisterFormProps = {
  onChangeMode: () => void;
  companyDomain: string;
  app: AppType;
  onChangeAuthMode?: (val: 'login' | 'sign-up' | 'auth-success') => void;
};

function ShopifyOauthRegisterForm({
  onChangeMode,
  companyDomain,
  app,
  onChangeAuthMode,
}: ShopifyOauthRegisterFormProps) {
  const { mutate: register, isLoading } = useOauthRegister();
  const { mutate: addUserCompanyByUserId, isLoading: isLoadingAddUserCompany } =
    useAddUserCompanyByUserId();

  const {
    mutate: authorizeShopifyConnect,
    isLoading: isLoadingAuthorizeShopifyConnect,
  } = useAuthorizeShopifyConnect();

  const [authenticatedUserId, setAuthenticatedUserId] = useState<number>();
  const [authenticatedAccessToken, setAuthenticatedAccessToken] =
    useState<string>();
  const [authenticateMode, setAuthenticateMode] = useState<
    'register' | 'authenticated'
  >('register');

  const onSubmit = (data: RegisterFormSchema | SocialLoginPayload) => {
    if ('provider' in data) {
      register(data);
    } else {
      register(
        {
          ...data,
          phone: formatPhonePayload(data.phone),
        },
        {
          onSuccess: (data) => {
            setAuthenticateMode('authenticated');
            setAuthenticatedUserId(data.user.id);
            setAuthenticatedAccessToken(data.accessToken);
          },
        }
      );
    }
  };

  const onAddCompany = (data: CompanyCreationFormSchema) => {
    addUserCompanyByUserId(
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: authenticatedUserId!,
        country: data.country?.value as string,
        industry: data.industries?.label as string,
        name: data.company_name,
      },
      {
        onSuccess: (data) => {
          authorizeShopifyConnect({
            companyDomain,
            userId: authenticatedUserId as number,
            app,
            companyId: data.id,
            authenticatedAccessToken: authenticatedAccessToken as string,
          });
        },
      }
    );
  };

  return authenticateMode === 'register' ? (
    <RegisterCard
      onSubmit={onSubmit}
      loadingSave={isLoading}
      onRedirect={onChangeMode}
      onHaveAccount={() => onChangeAuthMode?.('login')}
    />
  ) : (
    <CompanyCreationCard
      loadingSubmit={
        isLoadingAddUserCompany || isLoadingAuthorizeShopifyConnect
      }
      onSubmit={onAddCompany}
    />
  );
}

export default ShopifyOauthRegisterForm;
