import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Chat } from '@/types/whatsApp/chat';
import { SettingsTag } from '@/types/whatsApp/settings';
import { Ticket } from '@/types/whatsApp/ticket';

export default {
  getTickets: (
    companyId: number,
    params?: {
      tags?: string;
      agents?: string;
      sort?: string;
      startDate?: string;
      endDate?: string;
      search?: string;
      cursor?: string;
    }
  ) =>
    api.whatsApp.v2
      .get<{
        data: Ticket[];
        meta: { nextCursor: number };
        summary: {
          active: number;
          resolved: number;
          waiting_all: number;
          waiting_unread: number;
        };
      }>(`/ticket/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  getTicket: (
    companyId: number,
    source: string,
    ticketId: number,
    key: string | undefined
  ) =>
    api.whatsApp.v2
      .get<ApiResponse<Ticket>>(`/ticket/${companyId}/${source}/${ticketId}`, {
        headers: {
          'x-bitbybit-key': key,
        },
      })
      .then((res) => res.data.data),
  updateTicket: ({
    companyId,
    source,
    ticketId,
    key,
    ...data
  }: {
    userId?: number;
    userDisplayName?: string;
    userAccent?: string | null;
    userProfilePicture?: string | null;
    userEmail?: string | null;
    status?: string;
    notes?: string;
    companyId: number;
    source: string;
    ticketId: number;
    key: string;
    tagId?: SettingsTag['id'];
    ignoreAutoResolve?: boolean;
  }) =>
    api.whatsApp.v2
      .put<ApiResponse<Ticket>>(
        `/ticket/${companyId}/${source}/${ticketId}`,
        data,
        {
          headers: {
            'x-bitbybit-key': key,
          },
        }
      )
      .then((res) => res.data.data),
  getTicketByChat: (
    companyId: number,
    source: string,
    chatId: string,
    key: string
  ) =>
    api.whatsApp.v2
      .get<ApiResponse<Ticket | null>>(
        `/ticket/${companyId}/chat/${source}/${chatId}`,
        {
          headers: {
            'x-bitbybit-key': key,
          },
        }
      )
      .then((res) => res.data.data),
  getTicketHistory: (
    companyId: number,
    source: Chat['sources'],
    conversationId: string,
    params?: {
      page?: number;
      limit?: number;
      search?: string;
      sort?: string;
      startDate?: string;
      endDate?: string;
      tags?: string;
      agents?: string;
    }
  ) =>
    api.whatsApp.v2
      .get<{
        data: Ticket[];
        pageable: {
          numberOfElements: number;
          pageNumber: number;
          pageSize: number;
          totalElements: number;
          totalPages: number;
        };
      }>(`/ticket/${companyId}/chat/${source}/${conversationId}/history`, {
        params,
      })
      .then((res) => res.data),
};
