export type Reply = {
  type: string;
  message: string;
  errorMessage: string;
  status: boolean;
  imageUrl: string;
  buttons: string[];
  ticketTag: string | null;
  agentId: number | null;
  agentDisplayName?: string | null;
  agentEmail?: string | null;
  agentAccent?: string | null;
  agentProfilePicture?: string | null;
  autoResolveTime?: number;
  autoResolveType?: string;
};

export type AutoReply = {
  id: string;
  companyId: number;
  chatbotType: ChatbotType;
  type: MessageType;
  groupName: string;
  keywords: string[];
  reply: Reply;
  wrongReply: string | null;
  successReply: string | null;
  replies: object | null;
  createdAt: Date;
  updatedAt: Date;
  status: boolean;
  ticketTag: string | null;
  isPrebuilt: boolean;
};

export type ChatbotType =
  | 'INVALID_REPLY'
  | 'WELCOME_MESSAGE'
  | 'AUTO_REPLY'
  | 'TRANSFER_TO_CS'
  | 'UNSUBSCRIBE'
  | 'ASK_FOR_RATING'
  | 'TRACK_ORDER'
  | 'order_tracking'
  | 'ASK_FOR_RATING_BOT';

export type ChatbotType1 =
  | 'auto_reply'
  | 'welcome_message'
  | 'invalid_reply'
  | 'transfer_to_cs'
  | 'ask_for_rating'
  | 'unsubscribe'
  | 'order_tracking';

export type MessageType =
  | 'EMAIL'
  | 'WHATS_APP'
  | 'NOTIFICATION'
  | 'WHATSAPP_CLOUD_API'
  | 'INSTAGRAM'
  | 'FACEBOOK';

export const mapChatbotType1ToChatbotType: Record<ChatbotType1, ChatbotType> = {
  auto_reply: 'AUTO_REPLY',
  welcome_message: 'WELCOME_MESSAGE',
  invalid_reply: 'INVALID_REPLY',
  transfer_to_cs: 'TRANSFER_TO_CS',
  ask_for_rating: 'ASK_FOR_RATING',
  unsubscribe: 'UNSUBSCRIBE',
  order_tracking: 'TRACK_ORDER',
};
