type Props = {
  color?: string;
  size?: number;
};

function LinkOutIcon({ color = '#2B6AAF', size = 20 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.25 8.75L17.5 2.5m-4.167 0H17.5v4.167m0 5v4.166a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V4.167A1.667 1.667 0 014.167 2.5h4.166"
      ></path>
    </svg>
  );
}

export default LinkOutIcon;
