import { ApiResponse, CursoredResponse } from '@/api/types';
import api from '@/config/api';
import { Block, Condition } from '@/constants/customers';
import {
  AiTag,
  Customer,
  CustomerAddresses,
  CustomerMetafield,
  CustomerTags,
  Segments,
  Source,
  Tags,
  Timeline,
  VisitedSite,
} from '@/types/customers';
import { CustomerWithAssociation } from '@/types/customers/association';

const customer = {
  getCustomers: (
    companyId: number,
    params?: {
      segment?: string;
      source?: string;
      tags?: string;
      limit?: number;
      after?: string;
      before?: string;
      search?: string;
      query?: string;
      sort?: string;
    }
  ) =>
    api.customers
      .get<CursoredResponse<CustomerWithAssociation[]>>(
        `/customer/company/${companyId}`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getCustomerTimelines: (
    customerId: string,
    companyId: number,
    params?: {
      limit?: number;
      after?: string;
      before?: string;
      months?: string;
    }
  ) =>
    api.customers
      .get<CursoredResponse<Timeline[]>>(
        `/customer/${customerId}/company/${companyId}/timeline`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getCustomerMonths: (customerId: string) =>
    api.customers
      .get<ApiResponse<number[]>>(`/customer/${customerId}/timeline/months`)
      .then((res) => res.data.data),
  getCustomer: (customerId: string, companyId: number) =>
    api.customers
      .get<ApiResponse<CustomerWithAssociation>>(
        `/customer/${customerId}/company/${companyId}`
      )
      .then((res) => res.data.data),
  upsertCustomer: (
    companyId: number,
    payload: Partial<
      | {
          type: 'individual';
          firstName: string;
          lastName?: string;
          email: string;
          phone?: string;
          id: string;
          source: Source;
          addresses?: Partial<
            Pick<CustomerAddresses, 'city' | 'company' | 'id'>
          >[];
          notes?: string;
          instagramId?: string;
          tags?: string[];
        }
      | {
          type: 'bulk_file';
          source: Source;
          url: string;
          id: string;
          overwrite?: boolean;
        }
    >
  ) => api.customers.post(`/customer/company/${companyId}`, payload),
  deleteCustomer: ({ id }: { id: string }) =>
    api.customers.delete(`/customer/${id}`),
  bulkDeleteCustomers: ({
    ids,
    companyId,
    isAllSelected,
  }: {
    ids: string[] | undefined;
    companyId: number;
    isAllSelected: boolean;
  }) =>
    api.customers.post(`/customer/company/${companyId}/bulk/delete`, {
      ids,
      isAllSelected,
    }),
  bulkExportCustomers: ({
    ids,
    companyId,
    isAllSelected,
    count,
    userId,
  }: {
    ids: string[] | undefined;
    companyId: number;
    isAllSelected: boolean;
    count: number;
    userId: number;
  }) =>
    api.customers
      .post(
        `/customer/company/${companyId}/bulk/export`,
        { ids, isAllSelected, count, userId },
        {
          responseType: 'blob',
        }
      )
      .then((res) => res.data),
  createTag: (
    companyId: number,
    payload: {
      name: string;
    }
  ) =>
    api.customers
      .post<ApiResponse<Tags>>(`/customer/tags/company/${companyId}`, payload)
      .then((res) => res.data.data),
  upsertSegments: (
    companyId: number,
    payload: {
      id?: string;
      name: string;
      query: {
        conditions: Condition[];
        blocks: Block[];
      };
    }
  ) =>
    api.customers
      .post<ApiResponse<Segments>>(
        `/customer/segments/company/${companyId}`,
        payload
      )
      .then((res) => res.data.data),
  getTags: (
    companyId: number,
    params?: {
      limit?: number;
      cursor?: string;
      search?: string;
      sensitive?: boolean;
    }
  ) =>
    api.customers
      .get<CursoredResponse<Tags[]>>(`/customer/tags/company/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  getAiTags: (
    companyId: number,
    params?: {
      limit?: number;
      cursor?: string;
      search?: string;
    }
  ) =>
    api.customers
      .get<CursoredResponse<AiTag[]>>(
        `/customer/ai-tags/company/${companyId}`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getSegments: (
    companyId: number,
    params?: {
      limit?: number;
      cursor?: string;
      search?: string;
    }
  ) =>
    api.customers
      .get<CursoredResponse<{ data: Segments; count: number }[]>>(
        `/customer/segments/company/${companyId}`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getCustomerByPhoneNumber: (companyId: number, phoneNumber: string) =>
    api.customers
      .get<
        ApiResponse<
          | (Customer & {
              customerTags: CustomerTags[];
              addresses: CustomerAddresses[];
            })
          | null
        >
      >(`/customer/company/${companyId}/phone/${phoneNumber}`)
      .then((res) => res.data.data),
  getSegment: (
    id: string,
    params?: {
      companyId?: number;
    }
  ) =>
    api.customers
      .get<ApiResponse<{ data: Segments; count: number }>>(
        `/customer/segments/${encodeURIComponent(id)}`,
        { params }
      )
      .then((res) => res.data.data),
  getSegmentByName: (companyId: number, segmentName: string) =>
    api.customers
      .get<ApiResponse<{ data: Segments; count: number }>>(
        `/customer/segments/name/${segmentName}/company/${companyId}`
      )
      .then((res) => res.data.data),
  deleteTag: ({
    tagName,
    customerId,
    companyId,
  }: {
    tagName: string;
    customerId: string;
    companyId: number;
  }) =>
    api.customers.delete(
      `/customer/${customerId}/company/${companyId}/tags/${encodeURIComponent(
        tagName
      )}`
    ),
  deleteSegment: (segmentId: string) =>
    api.customers.delete(`/customer/segments/${segmentId}`),
  addCustomerTags: ({
    companyId,
    tagName,
    customerId,
  }: {
    companyId: number;
    tagName: string;
    customerId: string;
  }) =>
    api.customers.post(
      `/customer/${customerId}/tags/${encodeURIComponent(
        tagName
      )}/company/${companyId}`
    ),
  mergeCustomers: ({
    customerIdSource,
    customerIdTarget,
    ...payload
  }: {
    userId: number;
    customerIdSource: string;
    customerIdTarget: string;
    imageUrlCustomerId: string;
    fullNameCustomerId: string;
    phoneNumberCustomerId: string;
    emailCustomerId: string;
    companyCustomerId: string;
    cityCustomerId: string;
    instagramCustomerId: string;
  }) =>
    api.customers.post(
      `/customer/${customerIdSource}/merge/${customerIdTarget}`,
      payload
    ),
  bulkAddCustomerTag: ({
    companyId,
    tagName,
    customerIds,
    isAllSelected,
  }: {
    companyId: number;
    tagName: string[];
    customerIds: string[];
    isAllSelected: boolean;
  }) =>
    api.customers
      .post(`/customer/tags/company/${companyId}/bulk`, {
        tagName,
        customerIds,
        isAllSelected,
      })
      .then((res) => res.data),
  getVisitedSites: (
    companyId: number,
    params?: {
      limit?: number;
      cursor?: string;
      search?: string;
    }
  ) =>
    api.customers
      .get<CursoredResponse<VisitedSite[]>>(
        `/customer/visited-sites/company/${companyId}`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getMetafieldValues: (companyId: number, params: { key: string }) =>
    api.customers
      .get<ApiResponse<string[]>>(
        `/customer/metafields/company/${companyId}/values`,
        { params }
      )
      .then((res) => res.data.data),
  getMetafields: (companyId: number) =>
    api.customers
      .get<ApiResponse<CustomerMetafield[]>>(
        `/customer/metafields/company/${companyId}`
      )
      .then((res) => res.data.data),
  updateMetafields: (companyId: number, payload: UpdateMetafieldsParams) =>
    api.customers
      .post(`/customer/metafields/company/${companyId}`, payload)
      .then((res) => res.data),
};

export default customer;
export type UpsertCustomerPayload = Parameters<
  typeof customer['upsertCustomer']
>[1];
export type GetCustomersParams = Parameters<typeof customer['getCustomers']>[1];
export type GetCustomerTimelinesParams = Parameters<
  typeof customer['getCustomerTimelines']
>[2];

export type GetCustomerByPhoneNumberParams = Parameters<
  typeof customer['getCustomerByPhoneNumber']
>[1];
export type GetCustomerByPhoneNumberPayload = Awaited<
  ReturnType<typeof customer['getCustomerByPhoneNumber']>
>;
export type GetTagPayload = Parameters<typeof customer['getTags']>[1];
export type GetAiTagPayload = Parameters<typeof customer['getAiTags']>[1];
export type GetVisitedSitesPayload = Parameters<
  typeof customer['getVisitedSites']
>[1];
export type CreateTagPayload = Parameters<typeof customer['createTag']>[1];
export type DeleteTagPayload = Parameters<typeof customer['deleteTag']>[0];
export type DeleteSegmentParams = Parameters<
  typeof customer['deleteSegment']
>[0];
export type GetSegmentsPayload = Parameters<typeof customer['getSegments']>[1];
export type GetSegmentPayload = Parameters<typeof customer['getSegment']>[0];
export type GetSegmentByNamePayload = Parameters<
  typeof customer['getSegmentByName']
>[1];
export type UpsertSegmentPayload = Parameters<
  typeof customer['upsertSegments']
>[1];
export type AddCustomerTagsParams = Parameters<
  typeof customer['addCustomerTags']
>[0];
export type MergeCustomersParams = Parameters<
  typeof customer['mergeCustomers']
>[0];

export type UpdateMetafieldsParams = {
  customerId: string;
  metafields: { key: string; value: any }[];
};
