import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Appearances, Branding } from '@/types/bitLink/v2';

export default {
  getAppearance: (companyId: number) =>
    api.bitLink
      .get<ApiResponse<Appearances>>(`/appearance/${companyId}`)
      .then((res) => res.data.data),
  getBranding: (companyId: number) =>
    api.bitLink
      .get<ApiResponse<Branding | null>>(`/branding/${companyId}`)
      .then((res) => res.data.data),
  editBranding: (companyId: number, payload: Partial<Branding>) =>
    api.bitLink
      .post<ApiResponse<Branding>>(`/branding/${companyId}`, payload)
      .then((res) => res.data.data),
  editAppearance: (companyId: number, payload: Partial<Appearances>) =>
    api.bitLink
      .post<ApiResponse<Appearances>>(`/appearance/${companyId}`, payload)
      .then((res) => res.data.data),
};
