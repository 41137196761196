import api from '@/config/api';
import {
  DuplicateModelCollectionRequest,
  ModelCollection,
  ModelRequest,
  Models,
} from '@/types/bitAi/models';

export default {
  getSortingModels: (
    shopGid: string,
    {
      last_id,
      size,
      search,
      type,
    }: {
      last_id?: string;
      size?: number;
      search?: string;
      type?: 'all' | 'user-only';
    }
  ) =>
    api.bitAi.v1
      .get<Models>('/sorting/models', {
        params: {
          shop: shopGid,
          last_id,
          size,
          search,
          type,
        },
      })
      .then((res) => res.data),
  getSortingModelById: (shopGid: string, modelId: string) =>
    api.bitAi.v1
      .get<ModelCollection>(`/sorting/model/${modelId}`, {
        params: {
          shop: shopGid,
          id: modelId,
        },
      })
      .then((res) => res.data),
  addSortingModel: (data: ModelRequest) => {
    api.bitAi.v1.post('/sorting/model', {
      ...data,
    });
  },
  updateSortingModel: (data: ModelRequest) => {
    api.bitAi.v1.put('/sorting/model', {
      ...data,
    });
  },
  duplicateSortingModel: (data: DuplicateModelCollectionRequest) =>
    api.bitAi.v1.post('/sorting/model/duplicate', {
      ...data,
    }),
};
