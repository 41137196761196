import { ReactNode } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import BBBAccordionItem from './BBBAccordionItem';

export type BBBAccordionProps = {
  children: ReactNode;
  className?: string;
};

const BBBAccordion = ({ children, className }: BBBAccordionProps) => {
  return (
    <div
      className={cx(
        'bg-white border-[1.5px] border-grayColor2 box rounded-2xl',
        className
      )}
    >
      {children}
    </div>
  );
};

BBBAccordion.Item = BBBAccordionItem;

export default BBBAccordion;
