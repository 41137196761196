import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import useHistory from '@/hooks/common/useHistory';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setAccessToken } from '@/stores/auth';
import { AppType } from '@/types/systemLogin';
import { _localStorage } from '@/utils/common/localStorage';

export type AuthorizeShopifyConnectPayload = {
  companyDomain: string;
  userId: number;
  app: AppType;
  companyId: number;
  authenticatedAccessToken: string;
};

const mapAppToSearch: Record<AppType, string> = {
  BITAPP: '/bitapp',
  BITCHAT: '/bitchat',
  BITAI: '/bitai',
  BITCRM: '/bitcrm',
  BITLINK: '/bitlink',
  BITLOGIN: '/bitlogin',
};

const useAuthorizeShopifyConnect = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  return useMutation(
    async ({
      companyId,
      companyDomain,
      userId,
      app,
    }: AuthorizeShopifyConnectPayload) => {
      const finalData = await api.integrations.get(
        `/shopify/${app}/auth/connect/app-bridge`,
        {
          params: {
            companyId,
            companyDomain,
            userId,
          },
        }
      );
      return finalData;
    },
    {
      onSuccess: (_, payload) => {
        _localStorage.setItem('activeCompany', payload.companyId.toString());

        _localStorage.setItem('accessToken', payload.authenticatedAccessToken);
        dispatch(setAccessToken(payload.authenticatedAccessToken));

        let target = '/';

        if (mapAppToSearch[payload.app]) {
          target += `?redirectTo=${encodeURIComponent(
            mapAppToSearch[payload.app]
          )}`;
        }

        history.push(target);
      },
    }
  );
};

export default useAuthorizeShopifyConnect;
