import { forwardRef, ReactNode } from 'react';
import { ArrowLeft } from 'react-feather';

import { IBBBMobileFooterButtonTypes, Link } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

export type BBBContainerLinkType =
  | {
      hasBack?: true;
      backUrl: string;
      backLabel?: string;
    }
  | {
      hasBack?: false;
      backUrl?: never;
      backLabel?: never;
    };

export type BBBContainerHeadingType =
  | {
      hasHeading?: true;
      pageTitle?: ReactNode;
      pageDescription?: string | React.ReactNode;
      rightComponent?: React.ReactNode;
    }
  | {
      hasHeading?: false;
      pageTitle?: never;
      pageDescription?: never;
      rightComponent?: never;
    };

export type BBBContainerTypes = {
  children: React.ReactNode;
  id?: string;
  /**
   * @deprecated
   */
  fluid?: boolean;
  className?: string;
  containerClassName?: string;
  /**
   * @deprecated
   * Use ref combined with useOutsideAlerter instead
   */
  onClickContainer?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  /**
   * @deprecated
   * Use StickyFooter components instead
   */
  hasFooterButton?: boolean;
  upperHeader?: ReactNode;
} & BBBContainerHeadingType &
  BBBContainerLinkType &
  IBBBMobileFooterButtonTypes;

const BBBContainer = forwardRef<HTMLDivElement, BBBContainerTypes>(
  (
    {
      children,
      fluid = false,
      hasHeading = false,
      pageTitle,
      pageDescription,
      rightComponent,
      hasBack,
      backUrl,
      backLabel,
      className,
      containerClassName,
      id,
      upperHeader,
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          'flex flex-col h-full overflow-auto w-full',
          containerClassName
        )}
        id={id}
        ref={ref}
      >
        <div
          className={cn(
            `px-4 py-4 md:py-[1.875rem] md:px-[5%] w-full lg:max-w-7xl lg:mx-auto`,
            className
          )}
        >
          {upperHeader}
          {hasHeading && (
            <div className="flex flex-col md:px-0 lg:flex-row mb-titleToCard lg:items-center gap-4 lg:align-middle">
              <div className="grow flex gap-4 items-center">
                {hasBack && <BackComponent url={backUrl} label={backLabel} />}
                <div>
                  {pageTitle && (
                    <div className="text-2xl font-medium">{pageTitle}</div>
                  )}
                  {pageDescription && (
                    <div className="mt-2">{pageDescription}</div>
                  )}
                </div>
              </div>
              {rightComponent && (
                <div className={cn(`flex justify-start lg:justify-end`)}>
                  {rightComponent}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
);

BBBContainer.displayName = 'BBBContainer';

export default BBBContainer;

export function BackComponent({
  url,
  label,
  className,
}: {
  url: string;
  label?: string;
  className?: string;
}) {
  return (
    <div className={cn('flex items-center gap-4', className)}>
      <Link to={url} className="p-1 border border-primary-border rounded">
        <ArrowLeft />
      </Link>
      {label && <span className="underline">{label}</span>}
    </div>
  );
}
