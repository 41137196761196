export function isSubset(subset: any, superset: any): boolean {
  if (typeof subset !== 'object' || typeof superset !== 'object') {
    return subset === superset;
  }

  for (const key in subset) {
    if (!superset || !(key in superset)) {
      return false;
    }
    if (!isSubset(subset[key], superset[key])) {
      return false;
    }
  }
  return true;
}
