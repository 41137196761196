export default function TiktokIcon({
  size = 24,
  color = '#262627',
}: {
  size?: string | number;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 23"
    >
      <path
        fill={color}
        d="M18.807 5.362a5.547 5.547 0 01-2.956-1.73 5.512 5.512 0 01-1.37-3.129V0h-3.958v15.627c0 .694-.218 1.37-.624 1.933s-.98.984-1.64 1.204a3.328 3.328 0 01-4.365-2.837 3.293 3.293 0 011.56-3.123 3.326 3.326 0 011.753-.492c.342-.002.682.048 1.01.149V8.46a7.876 7.876 0 00-1.148-.058A7.281 7.281 0 003.125 9.69a7.241 7.241 0 00-2.592 3.227 7.212 7.212 0 001.533 7.776A7.3 7.3 0 0010 22.32a7.27 7.27 0 003.285-2.661 7.23 7.23 0 001.242-4.033V7.625A9.386 9.386 0 0020 9.363V5.476a5.585 5.585 0 01-1.147-.114h-.046z"
      ></path>
    </svg>
  );
}
