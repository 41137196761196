import React from 'react';

function ShareIcon({
  size = 22,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 2.75008C15.4875 2.75008 14.6666 3.57089 14.6666 4.58341C14.6666 5.59594 15.4875 6.41675 16.5 6.41675C17.5125 6.41675 18.3333 5.59594 18.3333 4.58341C18.3333 3.57089 17.5125 2.75008 16.5 2.75008ZM12.8333 4.58341C12.8333 2.55837 14.4749 0.916748 16.5 0.916748C18.525 0.916748 20.1666 2.55837 20.1666 4.58341C20.1666 6.60846 18.525 8.25008 16.5 8.25008C14.4749 8.25008 12.8333 6.60846 12.8333 4.58341Z"
        fill={props.color || '#262627'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 15.5833C15.4875 15.5833 14.6666 16.4041 14.6666 17.4167C14.6666 18.4292 15.4875 19.25 16.5 19.25C17.5125 19.25 18.3333 18.4292 18.3333 17.4167C18.3333 16.4041 17.5125 15.5833 16.5 15.5833ZM12.8333 17.4167C12.8333 15.3916 14.4749 13.75 16.5 13.75C18.525 13.75 20.1666 15.3916 20.1666 17.4167C20.1666 19.4417 18.525 21.0833 16.5 21.0833C14.4749 21.0833 12.8333 19.4417 12.8333 17.4167Z"
        fill={props.color || '#262627'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49998 9.16683C4.48746 9.16683 3.66665 9.98764 3.66665 11.0002C3.66665 12.0127 4.48746 12.8335 5.49998 12.8335C6.5125 12.8335 7.33331 12.0127 7.33331 11.0002C7.33331 9.98764 6.5125 9.16683 5.49998 9.16683ZM1.83331 11.0002C1.83331 8.97512 3.47494 7.3335 5.49998 7.3335C7.52502 7.3335 9.16665 8.97512 9.16665 11.0002C9.16665 13.0252 7.52502 14.6668 5.49998 14.6668C3.47494 14.6668 1.83331 13.0252 1.83331 11.0002Z"
        fill={props.color || '#262627'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9158 5.50741C15.1709 5.9447 15.0232 6.506 14.5859 6.7611L8.33971 10.4048C7.90242 10.6599 7.34112 10.5122 7.08603 10.075C6.83093 9.63766 6.97863 9.07637 7.41592 8.82127L13.6621 5.17752C14.0994 4.92242 14.6607 5.07012 14.9158 5.50741ZM7.08603 11.925C7.34112 11.4877 7.90242 11.34 8.33971 11.5951L14.5859 15.2389C15.0232 15.4939 15.1709 16.0552 14.9158 16.4925C14.6607 16.9298 14.0994 17.0775 13.6621 16.8224L7.41592 13.1787C6.97863 12.9236 6.83093 12.3623 7.08603 11.925Z"
        fill={props.color || '#262627'}
      />
    </svg>
  );
}

export default ShareIcon;
