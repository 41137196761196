import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { GetCustomersParams } from '@/api/services/customer/customer';
import { CursoredResponse } from '@/api/types';
import { useAppSelector } from '@/hooks/rtk/store';
import { CustomerWithAssociation } from '@/types/customers/association';

const useCustomers = (
  params?: GetCustomersParams,
  options?: Omit<
    UseQueryOptions<
      CursoredResponse<CustomerWithAssociation[]>,
      unknown,
      CursoredResponse<CustomerWithAssociation[]>,
      (string | number | GetCustomersParams | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['customers', activeCompany, params],
    () => customerServices.customer.getCustomers(activeCompany!, params),
    {
      keepPreviousData: true,
      ...options,
    }
  );
};

export default useCustomers;
