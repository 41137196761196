import { useState } from 'react';
import { useHistory } from 'react-router';
import { startCase } from 'lodash-es';
import ConnectWaCloudBanner from '../../components/ConnectWaCloudBanner';
import useColumn from './hooks/useColumn';

import { BBBButton, BBBContainer, BBBSelect } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { statusOptions } from '@/constants/bitCRM/template';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import { useTemplatesBulkAction } from '@/hooks/bitCRM/template/bulk-action';
import { useTemplates } from '@/hooks/bitCRM/template/template';
import useResponsive from '@/hooks/common/useResponsive';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { getTemplateUrl, isTemplateNoAction } from '@/utils/bitCRM';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

type Props = {
  type: 'campaign' | 'automation';
};

export default function CampaignAutomation({ type }: Props) {
  const history = useHistory();
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');
  const bulkAction = useTemplatesBulkAction();
  const column = useColumn();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<string | undefined>('updatedAt:desc');
  const [status, setStatus] = useState<typeof statusOptions[number] | null>();
  const [selected, setSelected] = useState<CampaignTemplate[]>([]);

  const { data: _templateData, isInitialLoading: loadingTemplate } =
    useTemplates({
      page: pageIndex,
      size: pageSize,
      search,
      type:
        type === 'campaign'
          ? 'CAMPAIGN_WHATSAPP_CLOUD_API'
          : 'AUTOMATION_WHATSAPP_CLOUD_API',
      sort,
      status: status?.value,
    });
  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const templateData = _templateData?.content;

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  return (
    <BBBContainer
      hasHeading
      pageTitle={`${startCase(type)} template`}
      pageDescription={
        <>
          {type === 'campaign'
            ? 'Prepare your campaign message before you send it to customers'
            : 'Set up automation flow with a specific trigger'}{' '}
          <a
            href="https://www.bitbybit.studio/knowledge/bitcrm"
            target="_blank"
            className="text-info-main underline"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </>
      }
      className={'md:px-0 md:py-0 lg:max-w-none'}
    >
      <ConnectWaCloudBanner />
      <div
        className={cn(
          'z-[99999] h-full',
          !isWhatsAppCloudConnected
            ? 'opacity-50 pointer-events-none cursor-none'
            : 'opacity-100 pointer-events-auto'
        )}
      >
        <BBBTableV2
          containerProps={{
            id: 'template-table',
          }}
          headers={column}
          data={templateData ?? []}
          dataId="id"
          loadingBody={loadingTemplate}
          isPaginate
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: _templateData?.totalElements || 0,
            maxShown: 3,
          }}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
          isSelectable={!isMobile}
          withoutActionOption
          onChangeSelectable={setSelected}
          selected={selected}
          isSearchable
          searchValue={search}
          onChangeSearch={setSearch}
          searchPlaceholder={`Search on ${type}`}
          isColumnHeightFixed={isMobile || isTablet ? false : true}
          isFilterable
          renderFilterSection={() =>
            selected.length ? (
              <>
                <BBBButton
                  variant="danger-outline"
                  text={`Delete${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() =>
                    bulkAction({
                      onSuccess: () => {
                        setSelected([]);
                      },
                      action: 'delete',
                      selectedRows: selected.map((row) => row.id),
                      type,
                    })
                  }
                  size="sm"
                />
                <BBBButton
                  variant="secondary"
                  text={`Duplicate${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() => {
                    if (selected.length > 5) {
                      toast.error(
                        `Maximum 5 templates can be duplicated at a time.`
                      );
                    } else {
                      bulkAction({
                        onSuccess: () => {
                          setSelected([]);
                        },
                        action: 'duplicate',
                        selectedRows: selected.map((row) => row.id),
                        type,
                      });
                    }
                  }}
                  size="sm"
                />
              </>
            ) : (
              <>
                <BBBSelect
                  placeholder="Status"
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={status}
                  onValueChange={setStatus}
                />
                <BBBButton
                  text="Create new template"
                  onClick={() =>
                    history.push({
                      pathname: `/misc/whatsapp-api-settings/templates/new`,
                      state: { from: location.pathname },
                      search: `?section=${type}`,
                    })
                  }
                />
              </>
            )
          }
          onClickRow={(row) => history.push(getTemplateUrl(row.type, row.id))}
          isAsyncSort
          onClickSortHeader={(key, sort) => {
            setSort(`${key}:${sort}`);
          }}
          disabledRow={(row) => isTemplateNoAction(row)}
        />
      </div>
    </BBBContainer>
  );
}
