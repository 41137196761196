import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import eventPixel from '@/config/sse/pixel';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { Customer } from '@/types/customers';
import { Cart, Event } from '@/types/pixel/event';

export type ListenActivity = {
  cart_data: Cart;
  event: Event;
  cart: Cart;
};

const useWebActivityStream = (
  customerData: Partial<Pick<Customer, 'email' | 'phoneNumber'>>
) => {
  const activeCompany = useActiveCompany();
  const [data, setData] = useState<ListenActivity[]>([]);
  const [activePing, setActivePing] = useState<{
    event: {
      event_id: Event['event_id'];
    };
  }>();
  const dataClearTimer = useRef<NodeJS.Timer>();

  const sse = useMemo(() => {
    return eventPixel.streamWebActivity(activeCompany!, {
      ...(customerData?.email && { email: customerData?.email }),
      ...(customerData?.phoneNumber && {
        phone: customerData?.phoneNumber,
      }),
    });
  }, [activeCompany, customerData?.email, customerData?.phoneNumber]);

  const startDataClearTimer = () => {
    dataClearTimer.current = setInterval(() => {
      setData([]);
      setActivePing(undefined);
    }, 17000);
  };

  const resetDataClearTimer = useCallback(() => {
    clearDataClearTimer();
    startDataClearTimer();
  }, []);

  const clearDataClearTimer = () => {
    //@ts-ignore
    clearInterval(dataClearTimer.current);
  };

  useEffect(() => {
    let inserted = false;

    sse.addEventListener('active-ping', (e) => {
      setActivePing(JSON.parse(e.data));
      resetDataClearTimer();
    });

    sse.addEventListener('new-event', (e) => {
      const parsedData = JSON.parse(e.data);

      setData((prevData) => {
        for (let i = 0; i < prevData.length; i++) {
          if (parsedData.event?.event_id === prevData[i].event?.event_id) {
            inserted = true;
            break;
          }

          if (parsedData.date <= prevData[i].event?.event_id) {
            prevData.splice(i, 0, parsedData);
            inserted = true;
            break;
          }
        }

        if (!inserted) {
          return [parsedData, ...prevData];
        }
        return [...prevData];
      });

      resetDataClearTimer();
    });

    sse.addEventListener('error', (e) => {
      console.error(e);
    });

    return () => {
      sse.close();
      clearDataClearTimer();
    };
  }, [resetDataClearTimer, sse]);

  useEffect(() => {
    startDataClearTimer();

    return () => {
      clearDataClearTimer();
    };
  }, [customerData]);

  if (!activeCompany || !customerData) {
    return {
      data: undefined,
    };
  }

  return {
    data,
    activePing,
  };
};

export default useWebActivityStream;
