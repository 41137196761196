import { twMerge as cx } from 'tailwind-merge';
import ChatBubbleTimestamp from '../Timestamp';

import DefaultPhoto from '@/assets/icons/DefaultWhatsAppProfilePicture';
import { useAppSelector } from '@/hooks/rtk/store';
import { useStartChat } from '@/hooks/whatsApp/chat';
import { MessageNewAssociation } from '@/types/whatsApp/v3';

export default function ChatBubbleContact({
  chat,
}: {
  chat: MessageNewAssociation;
}) {
  const source = useAppSelector((s) => s.bitCRM.selectedChat!.sources);
  const { mutate: startChat, isLoading: loadingStartChat } = useStartChat();

  const contactMsg = chat.contactMsg;

  return (
    <div className="min-w-[200px] flex flex-col">
      <div className="flex items-center gap-4">
        {chat.contactMsg?.contactProfilePicture ? (
          <img
            src={chat.contactMsg?.contactProfilePicture}
            className="w-16 h-16 rounded-full"
          />
        ) : (
          <DefaultPhoto size={64} />
        )}
        <div className="text-sm font-semibold text-gray-500">
          {chat.contactMsg?.contactDisplayName}{' '}
        </div>
      </div>
      <div className="flex w-full justify-end items-center mb-2">
        <ChatBubbleTimestamp chat={chat} />
      </div>
      {contactMsg && (
        <div
          className={cx(
            'w-full flex items-center justify-center border-t font-medium text-secondary-main pt-4 cursor-pointer',
            loadingStartChat && 'opacity-50 pointer-events-none'
          )}
          onClick={() => {
            startChat({
              sources: source,
              conversationId: contactMsg.contactPhoneNumber,
            });
          }}
        >
          Message
        </div>
      )}
    </div>
  );
}
