import ScaleLoader from 'react-spinners/ScaleLoader';

export type BBBSpinnerProps = {
  height?: number;
  width?: number;
  color?: string;
  text?: string;
};

function BBBSpinner({ height = 35, width = 4, color, text }: BBBSpinnerProps) {
  return (
    <div className="flex flex-col gap-2 text-left items-center justify-center h-full">
      <ScaleLoader width={width} height={height} color={color} />
      {text && <p className="text-primary-main">{text}</p>}
    </div>
  );
}

export default BBBSpinner;
