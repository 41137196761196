import { useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';

type Props = Partial<FallbackProps>;

function RefreshCounter({ resetErrorBoundary }: Props) {
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => setCounter((prev) => prev - 1), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (counter === 0) {
      resetErrorBoundary?.();
    }
  }, [counter, resetErrorBoundary]);

  return <>{counter}</>;
}

export default RefreshCounter;
