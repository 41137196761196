import { SidebarIcon } from '@/constants/layouts/sidebarData';

function PricingIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <PricingIconDefault {...props} />
      ) : (
        <PricingIconActive {...props} />
      )}
    </>
  );
}

function PricingIconDefault({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        d="M9.368 4.75c0-.322.254-.583.567-.583.313 0 .566.26.566.583v1.167a.575.575 0 01-.566.583.575.575 0 01-.567-.583V4.75zM9.368 14.083c0-.322.254-.583.567-.583.313 0 .566.261.566.583v1.167a.575.575 0 01-.566.583.575.575 0 01-.567-.583v-1.167z"
      ></path>
      <path
        fill="#727272"
        d="M12.386 8.207c-.41 0-.734-.359-1.036-.643a1.25 1.25 0 00-.192-.147c-.296-.19-.683-.283-1.158-.283-.335 0-.622.045-.86.135a1.27 1.27 0 00-.55.368.835.835 0 00-.194.531.74.74 0 00.125.43c.086.122.203.225.35.31.147.083.31.153.489.21.178.057.359.105.54.143l.83.187c.335.07.656.164.964.282.312.12.59.27.835.45.248.18.444.4.588.655.144.256.216.556.216.9 0 .464-.132.874-.398 1.228-.265.351-.648.627-1.15.826-.498.196-1.102.294-1.811.294-.69 0-1.287-.095-1.795-.287-.504-.19-.899-.47-1.184-.837a2.1 2.1 0 01-.285-.498c-.176-.436.209-.843.667-.843.417 0 .737.377 1.023.69.167.183.385.32.653.41.27.091.573.136.908.136.348 0 .654-.046.916-.139.265-.096.473-.227.623-.395.15-.17.226-.37.23-.597a.713.713 0 00-.204-.512 1.503 1.503 0 00-.558-.341 5.825 5.825 0 00-.83-.248l-1.007-.233c-.73-.168-1.306-.422-1.73-.763-.42-.344-.631-.8-.631-1.368 0-.468.141-.878.424-1.23.285-.35.673-.623 1.163-.817.49-.196 1.045-.295 1.664-.295.629 0 1.18.099 1.652.295.476.194.849.464 1.12.81.091.116.167.237.229.365.202.419-.183.821-.636.821z"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M10 17.083a7.083 7.083 0 100-14.166 7.083 7.083 0 000 14.166zm0 1.25a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function PricingIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        d="M9.368 4.75c0-.322.254-.583.567-.583.313 0 .566.26.566.583v1.167a.575.575 0 01-.566.583.575.575 0 01-.567-.583V4.75zM9.368 14.083c0-.322.254-.583.567-.583.313 0 .566.261.566.583v1.167a.575.575 0 01-.566.583.575.575 0 01-.567-.583v-1.167z"
      ></path>
      <path
        fill="#FF7C11"
        d="M12.386 8.207c-.41 0-.734-.359-1.036-.643a1.25 1.25 0 00-.192-.147c-.296-.19-.683-.283-1.158-.283-.335 0-.622.045-.86.135a1.27 1.27 0 00-.55.368.835.835 0 00-.194.531.74.74 0 00.125.43c.086.122.203.225.35.31.147.083.31.153.489.21.178.057.359.105.54.143l.83.187c.335.07.656.164.964.282.312.12.59.27.835.45.248.18.444.4.588.655.144.256.216.556.216.9 0 .464-.132.874-.398 1.228-.265.351-.648.627-1.15.826-.498.196-1.102.294-1.811.294-.69 0-1.287-.095-1.795-.287-.504-.19-.899-.47-1.184-.837a2.1 2.1 0 01-.285-.498c-.176-.436.209-.843.667-.843.417 0 .737.377 1.023.69.167.183.385.32.653.41.27.091.573.136.908.136.348 0 .654-.046.916-.139.265-.096.473-.227.623-.395.15-.17.226-.37.23-.597a.713.713 0 00-.204-.512 1.503 1.503 0 00-.558-.341 5.825 5.825 0 00-.83-.248l-1.007-.233c-.73-.168-1.306-.422-1.73-.763-.42-.344-.631-.8-.631-1.368 0-.468.141-.878.424-1.23.285-.35.673-.623 1.163-.817.49-.196 1.045-.295 1.664-.295.629 0 1.18.099 1.652.295.476.194.849.464 1.12.81.091.116.167.237.229.365.202.419-.183.821-.636.821z"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M10 17.083a7.083 7.083 0 100-14.166 7.083 7.083 0 000 14.166zm0 1.25a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PricingIcon;
