import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm, triggerRefreshIframe } from '@/stores/bitLink';
import { Linklists } from '@/types/bitLink/v2';
import { toast } from '@/utils/common/toast';

const useLinklistAddMutation = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const dispatch = useAppDispatch();
  const client = useQueryClient();

  return useMutation(
    (data: Partial<Linklists>) =>
      api.bitLink.post(`/linklist/${activeCompany}`, data),
    {
      onSuccess: () => {
        dispatch(setForm(null));
        client.invalidateQueries(['linklist', activeCompany]);
        dispatch(triggerRefreshIframe());
        toast.success('Added new section');
      },
    }
  );
};

const useLinklistEditMutation = () => {
  const dispatch = useAppDispatch();
  const client = useQueryClient();
  return useMutation(
    ({
      data,
      id,
    }: {
      data: Partial<Linklists>;
      id: number | null | undefined;
    }) => {
      return api.bitLink.put(`/linklist/${id}`, data);
    },
    {
      onSuccess: () => {
        dispatch(setForm(null));
        client.invalidateQueries(['linklist']);
        dispatch(triggerRefreshIframe());
        client.invalidateQueries(['bitlink-shopify-product']);
        toast.success('Updated section');
      },
    }
  );
};

const useLinklistDeleteMutation = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const toggleConfirmation = useConfirmationModal();
  const dispatch = useAppDispatch();
  const { mutate } = useMutation(
    (linklistId: number) => api.bitLink.delete(`/linklist/${linklistId}`),
    {
      onSuccess: () => {
        client.invalidateQueries(['linklist', activeCompany]);
        dispatch(triggerRefreshIframe());
        dispatch(setForm(null));
      },
    }
  );
  return (linklistId: number) =>
    toggleConfirmation({
      title: `Delete section?`,
      description: `Once deleted you won't be able to recover it.`,
      deleteModal: true,
      submitText: 'Delete',
      onAccept: (hide) => {
        mutate(linklistId, {
          onSuccess: () => {
            hide();
          },
        });
      },
    });
};

export default useLinklistAddMutation;
export { useLinklistEditMutation, useLinklistDeleteMutation };
