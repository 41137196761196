import { forwardRef } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { Link } from '@/components/ui/Link';

export type ActionItemTypes = {
  lists: (
    | {
        isLink: true;
        label: string;
        className?: string;
        redirectUrl: string;
        target?: string;
      }
    | {
        isLink?: false;
        label: string;
        className?: string;
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
      }
  )[];
  className?: string;
};

const ActionItem = forwardRef<HTMLDivElement | null, ActionItemTypes>(
  ({ lists, className }, ref) => {
    return (
      <div
        className={cx(
          `opacity-100 pointer-events-auto rounded-md bg-white whitespace-nowrap transition-opacity z-50 absolute top-0 shadow-md overflow-clip`,
          className
        )}
        ref={ref}
      >
        {lists.map((list) => (
          <>
            {list.isLink ? (
              <Link
                key={list.label}
                to={{
                  pathname: list.redirectUrl,
                }}
                target={list.target}
              >
                <div className="cursor-pointer px-6 py-2  hover:bg-[#FFE6D8]">
                  {list.label}
                </div>
              </Link>
            ) : (
              <>
                <div
                  key={list.label}
                  className={cx(
                    'cursor-pointer px-6 py-2  hover:bg-[#FFE6D8]',
                    list.className
                  )}
                  onClick={(e) => {
                    list.onClick?.(e);
                    e.stopPropagation();
                  }}
                >
                  {list.label}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  }
);

ActionItem.displayName = 'ActionItem';

export default ActionItem;
