export default function ThunderIcon2({
  size = 46,
}: {
  size?: number | string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 46 57"
    >
      <path
        fill="#9E9E9E"
        fillRule="evenodd"
        d="M18.155.5h17.062c4.095 0 6.9 4.673 4.119 8.192l-8.263 10.441h12.155a2.542 2.542 0 011.747 4.388L11.656 55.048a2.39 2.39 0 01-.078.07c-4.137 3.601-10.464-.869-8.184-6l6.793-16.962h-4.96c-3.809 0-6.632-4.09-4.485-7.657l12.933-21.52A5.236 5.236 0 0118.155.5zm-.027 5.083a.152.152 0 00-.127.067L5.135 27.058a.281.281 0 00.093.015h8.715a2.542 2.542 0 012.36 3.487l-8.21 20.498a2.528 2.528 0 01-.049.114.373.373 0 00-.022.06.264.264 0 00.15.101.316.316 0 00.044-.029l28.628-27.088H25.82a2.542 2.542 0 01-1.993-4.119L35.304 5.594a.3.3 0 00-.088-.011H18.129z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
