import { useCallback, useState } from 'react';
import useColumn from './hooks/useColumn';
import useColumnMobile from './hooks/useColumnMobile';
import AddQuickReplyModal from './AddQuickReplyModal';

import { BBBButton, BBBContainer } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import StickyFooter from '@/components/ui/StickyFooter/StickyFooter';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import {
  useQuickReply,
  useQuickReplyBulkAction,
} from '@/hooks/bitChat/quick-reply';
import useResponsive from '@/hooks/common/useResponsive';
import { QuickReply as QuickReplyTypes } from '@/types/bitChat/quickReply';

function QuickReply() {
  const isMobile = useResponsive('sm');
  const [editedData, setEditedData] = useState<QuickReplyTypes | undefined>();

  const [showAddQuickReplyModal, setShowAddQuickReplyModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<QuickReplyTypes[]>([]);

  const { isInitialLoading, data } = useQuickReply({
    search,
    page: pageIndex,
    limit: pageSize,
  });

  const quickReplyBulkActions = useQuickReplyBulkAction();

  const { data: isRestricted, status } = useIsModuleRestricted(
    'BITCHAT_QUICK_REPLY'
  );

  const handleClickRow = useCallback(
    (row: QuickReplyTypes) => {
      setEditedData(row);
      setShowAddQuickReplyModal(true);
    },
    [setEditedData, setShowAddQuickReplyModal]
  );

  const newColumns = useColumn(setEditedData, isRestricted);
  const mobileColumns = useColumnMobile(setEditedData, isRestricted);

  return (
    <BBBContainer
      hasHeading
      pageTitle="Quick Reply Template"
      pageDescription="Set message template to reply your chat more faster"
    >
      {(!!editedData || showAddQuickReplyModal) && (
        <AddQuickReplyModal
          show={!!editedData || showAddQuickReplyModal}
          onHide={() => {
            setEditedData(undefined);
            setShowAddQuickReplyModal(false);
          }}
          editedData={editedData}
          setEditedData={setEditedData}
        />
      )}
      <BBBTableV2
        data={data?.content ?? []}
        loadingBody={isInitialLoading}
        headers={isMobile ? mobileColumns : newColumns}
        dataId="id"
        isPaginate
        pagination={{
          page: pageIndex + 1,
          limit: pageSize,
          onChange: (page) => setPageIndex(page - 1),
          total: data?.totalElements || 0,
          maxShown: isMobile ? 1 : 3,
        }}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(page) => {
          setPageSize(page!);
          setPageIndex(0);
        }}
        isSearchable
        isFilterable
        searchPlaceholder="Search on quick reply"
        searchValue={search}
        onChangeSearch={setSearch}
        isSelectable={!isMobile}
        withoutActionOption
        onChangeSelectable={setSelected}
        selected={selected}
        onClickRow={handleClickRow}
        renderFilterSection={() =>
          selected.length ? (
            <BBBButton
              variant="danger-outline"
              text={`Delete (${selected.length})`}
              onClick={() =>
                quickReplyBulkActions(
                  'delete',
                  selected.map((row) => row.id),
                  {
                    onSuccess: () => {
                      setSelected([]);
                    },
                  }
                )
              }
            />
          ) : isMobile ? (
            <StickyFooter>
              <div className="p-3 bg-white">
                <BBBButton
                  text="Add new quick reply"
                  onClick={() => {
                    setShowAddQuickReplyModal(true);
                  }}
                  size="full"
                  disabled={isRestricted}
                  loadingState={status === 'loading'}
                />
              </div>
            </StickyFooter>
          ) : (
            <BBBButton
              text="Add new quick reply"
              size="sm"
              onClick={() => {
                setShowAddQuickReplyModal(true);
              }}
              disabled={isRestricted}
              loadingState={status === 'loading'}
            />
          )
        }
      />
    </BBBContainer>
  );
}

export default QuickReply;
