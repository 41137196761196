import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { Product } from '@/types/shopify/rest/products';

const useProductById = (
  productId?: number | string,
  options?:
    | Omit<
        UseQueryOptions<Product, unknown, Product, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(
    ['bitchat-product', activeCompany, productId],
    () =>
      services.bitChat.livechat.getProductById(
        activeCompany,
        typeof productId === 'string'
          ? parseInt(productId.replace('gid://shopify/Product/', ''), 10)
          : productId
      ),
    options
  );
};

export default useProductById;
