function InboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#9E9E9E"
        fillRule="evenodd"
        d="M5 3.05h4c1.105 0 2 .94 2 2.1v2.1H8.761a.489.489 0 00-.476.5c0 .118-.067.378-.258.608-.176.211-.454.392-.887.392-.434 0-.711-.18-.887-.392-.192-.23-.259-.49-.259-.608 0-.276-.213-.5-.476-.5H3v-2.1c0-1.16.895-2.1 2-2.1zm-2 5.2v.6c0 1.16.895 2.1 2 2.1h4c1.105 0 2-.94 2-2.1v-.6H9.158c-.08.26-.218.53-.415.767a2.03 2.03 0 01-1.603.733 2.03 2.03 0 01-1.604-.733 2.218 2.218 0 01-.414-.767H3zm-1-3.1C2 3.41 3.343 2 5 2h4c1.657 0 3 1.41 3 3.15v3.7C12 10.59 10.657 12 9 12H5c-1.657 0-3-1.41-3-3.15v-3.7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InboxIcon;
