import { useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sidebarDataTypeSectionV2 } from 'constants/layouts/sidebarData';
import { useAppDispatch, useAppSelector } from 'hooks/rtk/store';
import { twMerge as cx } from 'tailwind-merge';
import { whitelistPath } from './Sidebar';
import { formatPricingRedirection } from './SidebarSubNav';

import { BBBTooltip } from '@/components/ui';
import { Link } from '@/components/ui/Link';
import { setExpandedSubNav } from '@/stores/common';

type Props = {
  data: sidebarDataTypeSectionV2;
  index?: number;
};

export default function SidebarSectionNav({ data, index = 0 }: Props) {
  const { pathname, search } = useLocation();
  const { enableMapping } = useAppSelector(
    (state) => state.common.sidebar,
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const isActive = useMemo(
    () =>
      enableMapping
        ? false
        : data.path.split('/')[index + 1] === pathname.split('/')[index + 1],
    [data.path, enableMapping, index, pathname]
  );

  const Icon = data.icon!;

  const [isHovering, setIsHovering] = useState(false);

  if (data.disabled) return null;

  const currentPath = pathname.startsWith(data.path);

  const Content = (
    <BBBTooltip show content={data.title} position="right">
      <div
        className={cx(
          'rounded-2xl transition-colors flex items-center justify-center w-[3.25rem] h-[3.25rem]',
          isActive ? 'bg-[#FFE6D8]' : 'hover:bg-gray-100 cursor-pointer'
        )}
      >
        <div className="flex-none">
          <Icon
            mode={isActive ? 'active' : isHovering ? 'hover' : 'default'}
            size={20}
          />
        </div>
      </div>
    </BBBTooltip>
  );

  function onClick() {
    if (whitelistPath.includes(data.path)) {
      dispatch(setExpandedSubNav(true));
    } else {
      dispatch(setExpandedSubNav(false));
    }
  }

  if (currentPath) {
    return (
      <div
        className={cx('cursor-pointer')}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onClick}
      >
        {Content}
      </div>
    );
  }

  return (
    <Link
      to={formatPricingRedirection(
        data.redirectPath || data.path || pathname,
        pathname,
        search
      )}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {Content}
    </Link>
  );
}
