import { useEffect } from 'react';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import useConfirmationBanner from './useConfirmationBanner';

import { BANNER_INFORMATION } from '@/constants/common/banner';

const cookies = new Cookies(null, { path: '/' });

const useInformationBanner = () => {
  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    BANNER_INFORMATION.filter(
      (data) => !data.isExpired && !cookies.get(data.id)
    ).forEach(({ text, id }) => {
      toggle(id, {
        text,
        show: true,
        isCloseable: false,
        onClose: () => {
          cookies.set(id, true, {
            expires: dayjs().add(1, 'day').toDate(),
          });
        },
      });
    });
  }, [toggle]);
};

export default useInformationBanner;
