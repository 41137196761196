import { shallowEqual } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useSaveCartSettings = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const cartSettings = useAppSelector(
    (state) => state.bitApp.cartSettings,
    shallowEqual
  );
  const client = useQueryClient();
  return useMutation(
    () =>
      services.bitApp.cartSettings.saveCartSettings(
        activeCompany!,
        cartSettings
      ),
    {
      onSuccess: () => {
        toast.success('Empty cart settings saved');
        client.invalidateQueries(['bitapp-cart-settings', activeCompany]);
      },
    }
  );
};

export default useSaveCartSettings;
