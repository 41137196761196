import produce from 'immer';

import redirectNotificationOptions, {
  IRedirectNotificationOptions,
} from '@/constants/bitApp/redirectNotificationOptions';
import { rootStore } from '@/stores';
import {
  closeAllModal,
  setChangedPopup,
  setPopup,
  setPopupError,
  setSelected,
} from '@/stores/bitApp';

export const handleCancelLiveSection = () => {
  rootStore.dispatch(closeAllModal());
  rootStore.dispatch(setSelected(null));
  rootStore.dispatch(setPopup(null));
  rootStore.dispatch(setChangedPopup(false));
  rootStore.dispatch(setPopupError(null));
};

export const afterSubmit = () => {
  rootStore.dispatch(closeAllModal());
  rootStore.dispatch(setSelected(null));
  rootStore.dispatch(setPopup(null));
};

export const formatApiLinkToRedirectionObject = (link: string | null) => {
  if (link === null)
    return redirectNotificationOptions.find((opt) => opt.value === 'none');
  if (!link?.length) return undefined;
  const [base, params] = link.split('?');
  const mappedParams = params?.split('&');

  const paramMap =
    mappedParams?.length &&
    Object.fromEntries(
      mappedParams.map((param) => {
        const [key, value] = param.split('=');
        return [key, value];
      })
    );

  const redirectionData = redirectNotificationOptions.find(
    (opt) => opt.value === base
  );

  return produce(redirectionData, (draft) => {
    if (draft?.params) {
      draft.params = draft.params?.map((param) => {
        return {
          ...param,
          data: paramMap ? decodeURIComponent(paramMap[param.queryKey]) : '',
        };
      });
    }
  });
};

export const formatRedirectionPathObject = (
  redirectionPathObj: IRedirectNotificationOptions
) => {
  if (!redirectionPathObj) return null;
  const formattedLink = redirectionPathObj.params?.map((param) => {
    return `${param?.queryKey}=${
      param.type === 'dynamic-variable'
        ? param.data
        : encodeURIComponent(param.data!)
    }`;
  });
  let basePath = redirectionPathObj.value || '';
  if (formattedLink?.length) {
    basePath = basePath?.concat(`?${formattedLink.join('&')}`);
  }

  return basePath;
};

export const generateShopifyId = (
  fragmentName: 'Collection' | 'Blog' | 'Article' | 'Product' | 'Shop',
  id?: string | null
) => {
  const identifier = `gid://shopify/${fragmentName}/`;
  return `${identifier}${id?.replace(`${identifier}`, '')}`;
};

export const getFragmentShopifyName = (id: string) => {
  return id.replace('gid://shopify/Collection/', '');
};
