export default function TwitterIcon2({
  size = 24,
  color = '#262627',
}: {
  size?: string | number;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth="0.4"
        d="M14.345 9.358L8.364.389 8.304.3H.752L.96.611l9.19 13.776L.971 25.372l-.275.328h2.617l.06-.072 7.935-9.5 6.327 9.483.06.089h7.553l-.208-.311-9.532-14.29L24.253.63 24.528.3h-2.616l-.06.072-7.507 8.986zm-1.79 5.05h0l-.94-1.383h0l-7.263-10.7h2.736l5.973 8.8h0l.938 1.383h0l7.63 11.24h-2.734l-6.34-9.34z"
      ></path>
    </svg>
  );
}
