export default function MicrosoftIcon({
  theme = 'match',
  fallbackColor,
}: {
  theme?: string;
  fallbackColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 50 50"
    >
      <path
        fill={
          theme === 'match' || theme === 'matchOutline'
            ? '#ff5722'
            : fallbackColor
        }
        d="M6 6H22V22H6z"
        transform="rotate(-180 14 14)"
      ></path>
      <path
        fill={
          theme === 'match' || theme === 'matchOutline'
            ? '#4caf50'
            : fallbackColor
        }
        d="M26 6H42V22H26z"
        transform="rotate(-180 34 14)"
      ></path>
      <path
        fill={
          theme === 'match' || theme === 'matchOutline'
            ? '#ffc107'
            : fallbackColor
        }
        d="M26 26H42V42H26z"
        transform="rotate(-180 34 34)"
      ></path>
      <path
        fill={
          theme === 'match' || theme === 'matchOutline'
            ? '#03a9f4'
            : fallbackColor
        }
        d="M6 26H22V42H6z"
        transform="rotate(-180 14 34)"
      ></path>
    </svg>
  );
}
