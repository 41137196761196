import { useInfiniteQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useApiKey } from 'hooks/rtk/selector';
import { useAppSelector } from '../rtk/store';
import { selectActiveChatTruthy } from './chat';

const useMedias = () => {
  const zoomMessageId = useAppSelector((state) => state.bitCRM.zoom.chatId);

  const selectedChat = useAppSelector(selectActiveChatTruthy);

  const apiKey = useApiKey();

  return useInfiniteQuery(
    [
      'medias',
      { conversationId: selectedChat.clientNumber, messageId: zoomMessageId },
    ],
    ({ pageParam }) =>
      services.whatsApp.v3.message.getMedias(
        {
          conversationId: selectedChat.clientNumber,
          apiKey,
        },
        {
          messageId: zoomMessageId,
          cursor: pageParam,
          sources: selectedChat.sources,
        }
      ),
    {
      getNextPageParam: (lastPage) => lastPage?.meta.endCursor,
      getPreviousPageParam: (lastPage) => lastPage?.meta.startCursor,
    }
  );
};

export default useMedias;
