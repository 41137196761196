import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

export type BBBAccordionItemProps = {
  id?: string;
  leftChildren?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  content?: string | React.ReactNode;
  contentClassName?: string;
  collapse?: boolean | undefined;
  onCollapse?: (collapse: boolean) => void;
  hideToggle?: boolean;
  hideChevron?: boolean;
};

const BBBAccordionItem = ({
  id,
  title,
  subtitle,
  leftChildren,
  content,
  contentClassName,
  collapse: collapseFromProps,
  onCollapse,
  hideToggle,
  hideChevron,
}: BBBAccordionItemProps) => {
  const [localCollapse, setLocalCollapse] = useState<boolean>(false);

  const isCollapsedControlled = typeof collapseFromProps !== 'undefined';

  const collapse = isCollapsedControlled ? collapseFromProps : localCollapse;

  return (
    <div
      id={id}
      className={cx(
        !collapse && 'cursor-pointer',
        'flex flex-col last:border-none border-b border-neutral-30 p-5'
      )}
      onClick={(e) => {
        onCollapse?.(!collapse);
        if (!isCollapsedControlled) {
          setLocalCollapse(!collapse);
        }
        e.stopPropagation();
      }}
    >
      {!hideToggle && (
        <div className="flex justify-start gap-6 items-center">
          {leftChildren && leftChildren}
          <div className="flex-1 flex-col gap-2">
            <p className="text-primary-main text-xl">{title}</p>
            {subtitle && (
              <p className="text-neutral-50 text-sm mt-1.5">{subtitle}</p>
            )}
          </div>
          {!hideChevron &&
            (collapse || localCollapse ? (
              <ChevronUp color="#262627" size={16} />
            ) : (
              <ChevronDown color="#262627" size={16} />
            ))}
        </div>
      )}
      <AnimatePresence>
        {content && collapse && (
          <motion.div
            className={cx('mx-1', contentClassName)}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BBBAccordionItem;
