import React, { Ref, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import BBBTextInput, { BBBTextInputProps } from '../BBBTextInput';

import EyeClosed from '@/assets/icons/EyeClosed';

const BBBPasswordInput = <T extends FieldValues>(
  { ref: omittedRef, ...props }: BBBTextInputProps<T>,
  ref: Ref<HTMLInputElement>
) => {
  const [show, setShow] = useState(false);

  return (
    <BBBTextInput
      {...props}
      ref={ref}
      type={show ? 'text' : 'password'}
      isFixedSuffix
      fixedSuffixLabel={
        <EyeClosed
          className="cursor-pointer"
          onClick={() => setShow((prev) => !prev)}
        />
      }
      suffixWithoutBorder
    />
  );
};

export default React.forwardRef(BBBPasswordInput) as <T extends FieldValues>(
  p: BBBTextInputProps<T> & { ref?: Ref<HTMLInputElement> }
) => React.ReactElement;
