import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import {
  IBitLoginDetailAnalytic,
  IBitLoginPeakHours,
  LoginChannel,
  LoginLocation,
  UserGender,
  Visitor,
} from '@/types/bitLogin/analytics';
import { convertToLocalTime } from '@/utils/common/date';

export default {
  getLoginSession: (
    domain: string | undefined,
    params?: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<ApiResponse<IBitLoginDetailAnalytic>>(`/analytics/${domain}/login`, {
        params: {
          startDate: params?.startDate,
          endDate: params?.endDate,
        },
      })
      .then((res) => res.data.data),
  getUser: (
    domain: string | undefined,
    params?: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<ApiResponse<IBitLoginDetailAnalytic>>(`/analytics/${domain}/user`, {
        params,
      })
      .then((res) => res.data.data),
  getLoginByProvider: (
    domain: string,
    provider: string,
    params?: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<
        ApiResponse<{
          current: string;
          previous?: string;
          percentage?: string;
        }>
      >(`/analytics/${domain}/login/${provider}`, {
        params,
      })
      .then((res) => res.data.data),
  getLoginPeakHours: (
    domain: string | undefined,
    params?: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<ApiResponse<IBitLoginPeakHours>>(
        `/analytics/${domain}/login/peakhours`,
        {
          params: {
            startDate: params?.startDate,
            endDate: params?.endDate,
          },
        }
      )
      .then((res) =>
        res.data.data.peakhours.map((data) => ({
          ...data,
          hour: convertToLocalTime(data.hour),
        }))
      ),
  getLoginChannel: (
    domain: string | undefined,
    params?: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<
        ApiResponse<{
          providerDataWithPercentage: LoginChannel[];
        }>
      >(`/analytics/${domain}/login-channel`, {
        params,
      })
      .then((res) => res.data.data.providerDataWithPercentage),
  getLoginLocation: (
    domain: string | undefined,
    params: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<ApiResponse<LoginLocation[]>>(
        `/analytics/${domain}/login/locations`,
        {
          params,
        }
      )
      .then((res) => res?.data?.data),
  getUserGender: (
    domain: string | undefined,
    params: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitLogin
      .get<ApiResponse<UserGender[]>>(`/analytics/${domain}/user/gender`, {
        params,
      })
      .then((res) => res.data.data),
  getVisitors: (
    domain: string | undefined,
    params: {
      startDate?: string;
      endDate?: string;
      limit?: number;
      page?: number;
      search?: string;
    }
  ) =>
    api.bitLogin
      .get<{
        startCursor: string;
        endCursor: string;
        total: string;
        hasNextPage?: boolean;
        hasPreviousPage?: boolean;
        limit: number;
        data: Visitor[];
      }>(`/analytics/${domain}/login/visitors`, {
        params,
      })
      .then((res) => res.data),
  downloadVisitors: (domain: string | undefined, params: { search?: string }) =>
    api
      .bitLogin(`/analytics/${domain}/login/visitors/export`, {
        responseType: 'blob',
        params,
      })
      .then((res) => res.data),
};
