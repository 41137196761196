import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import {
  Analytics,
  AnalyticsCharts,
  AnalyticsProductClicks,
  AnalyticsProducts,
  AnalyticsProductSummary,
  AnalyticsTopLink,
  AnalyticsTrafficSource,
  AnalyticsViewsClicksCTR,
} from '@/types/bitLink/analytics';
import { Customer } from '@/types/customers';

export default {
  getPageView: (companyId: number | undefined, params?: CommonAnalyticParams) =>
    api.bitLink
      .get<ApiResponse<Analytics['pageView']>>(
        `/analytics/${companyId}/page-view`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getPageShared: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<Analytics['pageShared']>>(
        `/analytics/${companyId}/share`,
        {
          params: {
            startDate: params?.startDate,
            endDate: params?.endDate,
          },
        }
      )
      .then((res) => res.data.data),
  getEngagementRate: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<Analytics['engagementRate']>>(
        `/analytics/${companyId}/engagement-rate`,
        {
          params: {
            startDate: params?.startDate,
            endDate: params?.endDate,
          },
        }
      )
      .then((res) => res.data.data),
  getLinkClick: (companyId: number | undefined, linkId: number | undefined) =>
    api.bitLink
      .get<ApiResponse<number>>(`/analytics/${companyId}/link/click/${linkId}`)
      .then((res) => res.data.data),
  getLinkClicks: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<Analytics['linkClick']>>(
        `/analytics/${companyId}/link/click`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getTopLinks: (companyId: number | undefined, params?: CommonAnalyticParams) =>
    api.bitLink
      .get<ApiResponse<Analytics['topLinks']>>(
        `/analytics/${companyId}/link/click/top`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getViewCharts: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsCharts['views']>>(
        `/analytics/${companyId}/page-view/charts`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getCTR: (companyId: number | undefined, params?: CommonAnalyticParams) =>
    api.bitLink
      .get<ApiResponse<Analytics['ctr']>>(`/analytics/${companyId}/ctr`, {
        params,
      })
      .then((res) => res.data.data),
  getClicksViewsCTR: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsViewsClicksCTR>>(
        `/analytics/${companyId}/clicks-views-ctr`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getTrafficSource: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsTrafficSource>>(
        `/analytics/${companyId}/social-media/share`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getTopLocations: (
    companyId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<
        ApiResponse<{
          locations: AnalyticsTopLink;
          others: number;
          unknown: number;
        }>
      >(`/analytics/${companyId}/locations`, {
        params,
      })
      .then((res) => res.data.data),
  getSubscribers: (
    companyId: number | undefined,
    params?: {
      search?: string;
      after?: string;
      before?: string;
      sort?: string;
      startDate?: string;
      endDate?: string;
      limit?: number;
    }
  ) =>
    api.bitLink
      .get<
        ApiResponse<{
          subscribers: Customer[];
          meta: {
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
            endCursor: string;
            total: number;
          };
          range: {
            start: string;
            end: string;
          };
        }>
      >(`/analytics/${companyId}/subscribers`, {
        params,
      })
      .then((res) => res.data.data),
  downloadSubscribers: (
    companyId: number | undefined,
    payload?: {
      search?: string;
      after?: number;
      before?: number;
      sort?: string;
      startDate?: string;
      endDate?: string;
      limit?: number;
    }
  ) =>
    api.bitLink
      .post(
        `/analytics/${companyId}/subscribers/export`,
        {
          ...payload,
        },
        {
          responseType: 'blob',
        }
      )
      .then((res) => res.data),
  getProductEvent: (
    companyId: number | undefined,
    linkId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsProducts>>(
        `/analytics/${companyId}/product/${linkId}/event`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getProductEventClicks: (
    companyId: number | undefined,
    linkId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsProductClicks>>(
        `/analytics/${companyId}/product/${linkId}/event-clicks`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getProductEventSummary: (
    companyId: number | undefined,
    linkId: number | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<AnalyticsProductSummary>>(
        `/analytics/${companyId}/product/${linkId}/event-summary`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getProductLinkClick: (
    companyId: number | undefined,
    productId: string | undefined,
    params?: CommonAnalyticParams
  ) =>
    api.bitLink
      .get<ApiResponse<number>>(
        `/analytics/${companyId}/link-product/click/${productId}`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
};

export type CommonAnalyticParams = {
  startDate?: string;
  endDate?: string;
};
