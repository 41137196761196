import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { Chat } from '@/types/whatsApp/chat';

const useProfilePicture = (
  clientNumber: Chat['clientNumber'],
  sources: Chat['sources'],
  {
    enabled,
    ...options
  }:
    | Omit<
        UseQueryOptions<
          string,
          unknown,
          string,
          (string | Pick<Chat, 'clientNumber' | 'sources'>)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined = {}
) => {
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  return useQuery(
    ['bitchat-profile-picture', { clientNumber, sources }],
    () =>
      services.whatsApp.chat.getProfilePicture(clientNumber, {
        key: apiKey!,
        source: sources,
      }),
    {
      enabled:
        enabled &&
        clientNumber !== 'ONBOARDING' &&
        (sources === 'WHATSAPP' || sources === 'WHATSAPP_META') &&
        !!apiKey,
      meta: { disableToast: true },
      ...options,
    }
  );
};

export default useProfilePicture;
