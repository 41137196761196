import { useInfiniteQuery } from '@tanstack/react-query';
import customerServices from 'api/services/customer';
import { GetAiTagPayload } from 'api/services/customer/customer';
import { useAppSelector } from 'hooks/rtk/store';

export const useVisitedSites = (params?: Omit<GetAiTagPayload, 'cursor'>) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useInfiniteQuery(
    ['visited-sites', activeCompany, params],
    ({ pageParam }) =>
      customerServices.customer.getVisitedSites(activeCompany!, {
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
    }
  );
};
