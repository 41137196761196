import React from 'react';

function WhatsAppIcon({ color = '#FFF' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g fill={color} clipPath="url(#clip0_8077_52177)">
        <path d="M11.427.514C5.183.816.256 6.094.275 12.44a11.996 11.996 0 001.276 5.369L.306 23.943a.46.46 0 00.552.544l5.921-1.424a11.53 11.53 0 005.039 1.242c6.381.099 11.703-5.045 11.902-11.521C23.933 5.842 18.288.182 11.427.514zm7.065 18.48A9.053 9.053 0 0112 21.723a8.973 8.973 0 01-4.086-.973l-.825-.417-3.63.873.764-3.765-.406-.808a9.33 9.33 0 01-.998-4.231c0-2.49.955-4.83 2.689-6.59A9.128 9.128 0 0112 3.083c2.453 0 4.758.97 6.492 2.73a9.33 9.33 0 012.69 6.59 9.41 9.41 0 01-2.69 6.59z"></path>
        <path d="M17.69 14.984l-2.27-.662a.838.838 0 00-.838.224l-.556.575a.818.818 0 01-.9.193c-1.074-.442-3.334-2.482-3.91-3.502a.85.85 0 01.065-.931l.485-.637a.87.87 0 00.104-.873l-.956-2.194a.84.84 0 00-1.322-.307c-.634.544-1.386 1.371-1.477 2.287-.162 1.616.52 3.652 3.101 6.097 2.982 2.825 5.37 3.198 6.924 2.816.882-.217 1.586-1.086 2.03-1.797a.864.864 0 00-.48-1.289z"></path>
      </g>
      <defs>
        <clipPath id="clip0_8077_52177">
          <path
            fill={color}
            d="M0 0H23.642V24H0z"
            transform="translate(.18 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default WhatsAppIcon;
