import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    'Use your domain',
    'Customizable font & styles',
    'Shoppable link in bio',
    'Unlimited link',
    'Email subscription',
    'Embed YouTube videos',
    'Image banners with various styles',
  ],
  pro: ['Hide bitLink logo'],
};
