import React from 'react';
import { Info } from 'react-feather';
import { Virtuoso } from 'react-virtuoso';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard, IBBBCard } from '@/components/ui/BBBCard';
import { BBBTooltip } from '@/components/ui/BBBTooltip';

type Data = {
  label: string;
  value: number;
};

type PaginatedStackedBarChartProps =
  | {
      isPaginated: true;
      data: Data[];
      fetchNext: () => void;
    }
  | {
      isPaginated?: false;
      data: Data[];
      fetchNext?: never;
    };

export type BBBStackedBarChartProps = {
  containerClassName?: string;
  tooltip?: string;
  title?: string;
  data: Data[];
} & IBBBCard &
  PaginatedStackedBarChartProps;

export default function BBBStackedBarChart({
  containerClassName,
  tooltip,
  title,
  data,
  isPaginated,
  fetchNext,
  ...props
}: BBBStackedBarChartProps) {
  return (
    <BBBCard
      className={cx(containerClassName)}
      title={
        tooltip ? (
          <div className="flex gap-1 items-center w-fit">
            <p className="text-base font-normal whitespace-nowrap">{title}</p>
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              position="top"
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          </div>
        ) : (
          <p className="text-base font-normal whitespace-nowrap">{title}</p>
        )
      }
      {...props}
    >
      {data?.length === 0 ? (
        <div className="h-full flex flex-col gap-1 items-center justify-center">
          <div className="text-primary-main font-medium">
            No sessions in this date range
          </div>
          <div className="text-neutral-50 text-sm">
            Try selecting a different date range
          </div>
        </div>
      ) : (
        <div className="w-full max-h-80 overflow-auto flex flex-col gap-5 pr-4">
          {isPaginated ? (
            <Virtuoso
              data={data}
              style={{
                height: '200vh',
              }}
              endReached={fetchNext}
              itemContent={(index, item) => (
                <div className="flex flex-col gap-2 mt-5">
                  <div className="flex justify-between w-full">
                    <p className="text-base text-primary">{item.label}</p>
                    <p className="text-base text-primary">{item.value}</p>
                  </div>
                  <div className="overflow-clip h-1 bg-neutral-30 rounded-lg">
                    <div
                      className="h-full bg-secondary-main rounded-lg"
                      style={{
                        width: `${
                          (item.value /
                            data.reduce((acc, curr) => acc + curr.value, 0)) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            />
          ) : (
            data?.map((item, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex justify-between w-full">
                  <p className="text-base text-primary">{item.label}</p>
                  <p className="text-base text-primary">{item.value}</p>
                </div>
                <div className="overflow-clip h-1 bg-neutral-30 rounded-lg">
                  <div
                    className="h-full bg-secondary-main rounded-lg"
                    style={{
                      width: `${
                        (item.value /
                          data.reduce((acc, curr) => acc + curr.value, 0)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </BBBCard>
  );
}
