import { Interweave, InterweaveProps } from 'interweave';

export type BBBRenderHtmlProps = {
  id?: string;
  className?: string;
  content?: string | null;
} & InterweaveProps;

const BBBRenderHtml = ({ content, ...props }: BBBRenderHtmlProps) => {
  return <Interweave content={content} {...props} />;
};

BBBRenderHtml.displayName = 'BBBRenderHtml';

export default BBBRenderHtml;
