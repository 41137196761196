import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const mapActionToTitle = {
  delete: 'Delete multiple campaign log',
  stop: 'Stop multiple campaign log',
  resend: 'Resend message log',
};

const mapDescriptionToTitle = {
  delete:
    'Are you sure to delete selected campaign log? Once you deleted the action cannot be undone',
  stop: 'Are you sure to stop selected sending campaign log? Once you stop it the action cannot be undone',
  resend:
    'Are you sure to resend message log? Once you resend it the action cannot be undone',
};

const useCampaignBulkAction = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  const { mutate: bulkAction } = useMutation(
    async ({
      action,
      selectedRows,
      id,
    }: {
      action: 'stop' | 'delete' | 'resend';
      selectedRows: string[];
      id: string;
    }) =>
      api.bitCRM.v2.post(`/campaign-new/${id}/bulk_action`, {
        companyId: activeCompany,
        data: selectedRows,
        action,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(
          `Your campaign log has been ${
            payload.action === 'stop'
              ? 'stopped'
              : payload.action === 'resend'
              ? 'resent'
              : 'deleted'
          }}`
        );
        client.invalidateQueries(['bitcrm-campaign-log']);
      },
    }
  );

  return (
    id: string,
    action: 'stop' | 'delete' | 'resend',
    selectedRows: string[]
  ) => {
    toggleConfirmation({
      title: mapActionToTitle[action],
      description: mapDescriptionToTitle[action],
      onAccept: async (hide) => {
        bulkAction(
          {
            action,
            selectedRows,
            id,
          },
          {
            onSuccess: () => {
              hide();
            },
          }
        );
      },
      deleteModal: true,
      submitText: 'Delete',
      cancelText: 'Cancel',
    });
  };
};

export default useCampaignBulkAction;
