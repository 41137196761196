function UndeliveredIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="none"
      viewBox="0 0 8 8"
    >
      <path
        fill="#FF6060"
        d="M7.044 7.394a.84.84 0 01-1.188 0L4 5.274l-1.855 2.12A.84.84 0 11.957 6.206L2.887 4 .956 1.794A.84.84 0 112.144.606L4 2.728 5.856.606a.84.84 0 111.188 1.188l-1.93 2.207 1.93 2.205a.84.84 0 010 1.188z"
      />
    </svg>
  );
}

export default UndeliveredIcon;
