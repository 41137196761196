import api from '@/config/api';
import { AnalyticsResponse } from '@/types/analytics';
import {
  IBitCRMAnalytics,
  IBitCRMAnalyticsLogCampaignChart,
  IBitCRMChartAnalyticsResponse,
} from '@/types/bitCRM/analytics';

export default {
  getAutomationAndCampaign: (
    companyId: number | undefined,
    {
      startDate,
      endDate,
    }: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitCRM.v2
      .get<AnalyticsResponse<IBitCRMAnalytics>>(`/analytics/${companyId}`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then((res) => res.data),
  getAutomationAndCampaignChart: (
    companyId: number | undefined,
    {
      startDate,
      endDate,
    }: {
      startDate?: string;
      endDate?: string;
    }
  ) =>
    api.bitCRM.v2
      .get<IBitCRMChartAnalyticsResponse>(`/analytics/${companyId}/graphic`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then((res) => res.data),
  getLogCampaignChart: (
    campaignId: string | undefined,
    {
      timezone,
    }: {
      timezone?: string;
    }
  ) =>
    api.bitCRM.v2
      .get<AnalyticsResponse<IBitCRMAnalyticsLogCampaignChart>>(
        `/log/campaign/detail/${campaignId}/graphic`,
        {
          params: {
            timezone,
          },
        }
      )
      .then((res) => res.data.data),
};
