export default function SoundMutedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M14.293 6.293a1 1 0 011.414 0L18 8.586l2.293-2.293a1 1 0 111.414 1.414L19.414 10l2.293 2.293a1 1 0 01-1.414 1.414L18 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414zM9.998 3.019l.002.024v13.914c0 .01 0 .018-.002.024L7.042 13.65l-.014-.016A1.979 1.979 0 005.58 13H2.012A.064.064 0 012 12.959v-5.92c0-.02.007-.033.012-.04H5.59a1.98 1.98 0 001.452-.641l2.956-3.34zM8.572 1.614C9.886.245 12 1.304 12 3.043v13.914c0 1.753-2.141 2.804-3.446 1.409a.865.865 0 01-.018-.02L5.566 15H2c-1.142 0-2-.952-2-2.041v-5.92C0 5.952.86 5 2 5h3.573l2.971-3.355.028-.03z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
