import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/ui';

type Props = {
  className?: string;
};

export default function HelpCenter({ className }: Props) {
  return (
    <div className={cx('flex flex-col gap-4 lg:px-0 px-4', className)}>
      <p className="lg:text-2xl text-xl font-normal text-primary-main">
        Help Center
      </p>
      <BBBCard
        className="lg:w-[15.75rem] w-[12.5rem]  border-transparent p-5 flex flex-col items-center justify-center lg:gap-7 gap-5 hover:shadow cursor-pointer transition-all"
        onClick={() => window.open('https://bitbybit.tawk.help/', '_blank')}
      >
        <InformationIcon />
        <div className="flex items-center gap-3">
          <p className="lg:text-xl text-base text-info-main font-medium">
            Help Center
          </p>
          <RedirectIcon />
        </div>
      </BBBCard>
    </div>
  );
}

function RedirectIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4999 2.49996V1.66663H18.3333V2.49996H17.4999ZM9.75575 11.4225C9.59858 11.5743 9.38808 11.6583 9.16958 11.6564C8.95109 11.6545 8.74208 11.5668 8.58757 11.4123C8.43306 11.2578 8.34542 11.0488 8.34352 10.8303C8.34162 10.6118 8.42562 10.4013 8.57742 10.2441L9.75575 11.4225ZM16.6666 9.16663V2.49996H18.3333V9.16663H16.6666ZM17.4999 3.33329H10.8333V1.66663H17.4999V3.33329ZM18.0891 3.08913L9.75575 11.4225L8.57742 10.2441L16.9108 1.91079L18.0891 3.08913Z"
        fill="#373737"
      />
      <path
        d="M16.6665 12.4999C16.6665 14.0583 16.6665 14.8366 16.3315 15.4166C16.1121 15.7966 15.7965 16.1122 15.4165 16.3316C14.8365 16.6666 14.0582 16.6666 12.4998 16.6666H8.33317C5.9765 16.6666 4.79734 16.6666 4.06567 15.9341C3.33317 15.2024 3.33317 14.0233 3.33317 11.6666V7.49992C3.33317 5.94159 3.33317 5.16325 3.66817 4.58325C3.88758 4.20324 4.20315 3.88766 4.58317 3.66825C5.16317 3.33325 5.9415 3.33325 7.49984 3.33325"
        stroke="#373737"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

function InformationIcon() {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3957 37.5C26.1248 37.5 26.7415 37.2479 27.2457 36.7437C27.7498 36.2395 28.0012 35.6236 27.9998 34.8958C27.9984 34.168 27.7471 33.5514 27.2457 33.0458C26.7443 32.5402 26.1276 32.2889 25.3957 32.2916C24.6637 32.2944 24.0478 32.5465 23.5478 33.0479C23.0478 33.5493 22.7957 34.1652 22.7915 34.8958C22.7873 35.6264 23.0394 36.243 23.5478 36.7458C24.0561 37.2486 24.6721 37.5 25.3957 37.5ZM23.5207 29.4791H27.3748C27.3748 28.3333 27.5054 27.4305 27.7665 26.7708C28.0276 26.1111 28.7651 25.2083 29.979 24.0625C30.8818 23.1597 31.5936 22.3 32.1144 21.4833C32.6353 20.6666 32.8957 19.6861 32.8957 18.5416C32.8957 16.5972 32.1839 15.1041 30.7603 14.0625C29.3366 13.0208 27.6526 12.5 25.7082 12.5C23.729 12.5 22.1235 13.0208 20.8915 14.0625C19.6596 15.1041 18.7998 16.3541 18.3123 17.8125L21.7498 19.1666C21.9234 18.5416 22.3144 17.8646 22.9228 17.1354C23.5311 16.4062 24.4596 16.0416 25.7082 16.0416C26.8193 16.0416 27.6526 16.3458 28.2082 16.9541C28.7637 17.5625 29.0415 18.2305 29.0415 18.9583C29.0415 19.6527 28.8332 20.3041 28.4165 20.9125C27.9998 21.5208 27.479 22.0847 26.854 22.6041C25.3262 23.9583 24.3887 24.9826 24.0415 25.6771C23.6943 26.3715 23.5207 27.6389 23.5207 29.4791ZM25.4998 45.8333C22.6179 45.8333 19.9096 45.2868 17.3748 44.1937C14.8401 43.1007 12.6353 41.6159 10.7603 39.7396C8.88526 37.8632 7.40123 35.6583 6.30817 33.125C5.21512 30.5916 4.6679 27.8833 4.66651 25C4.66512 22.1166 5.21234 19.4083 6.30817 16.875C7.40401 14.3416 8.88803 12.1368 10.7603 10.2604C12.6325 8.384 14.8373 6.89997 17.3748 5.8083C19.9123 4.71664 22.6207 4.16941 25.4998 4.16664C28.379 4.16386 31.0873 4.71108 33.6248 5.8083C36.1623 6.90553 38.3672 8.38955 40.2394 10.2604C42.1116 12.1312 43.5964 14.3361 44.6936 16.875C45.7908 19.4139 46.3373 22.1222 46.3332 25C46.329 27.8777 45.7818 30.5861 44.6915 33.125C43.6012 35.6639 42.1172 37.8687 40.2394 39.7396C38.3616 41.6104 36.1568 43.0951 33.6248 44.1937C31.0929 45.2923 28.3846 45.8389 25.4998 45.8333Z"
        fill="#404040"
      />
    </svg>
  );
}
