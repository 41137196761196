import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'config/api';
import {
  Profile,
  User,
  UserResetPasswordRequest,
} from '../../types/systemLogin/index';

export type ResetPasswordResponseData = UserResetPasswordRequest & {
  user: User & {
    profile: Profile;
  };
};
const useResetPasswordInfo = (id: string) =>
  useQuery<ResetPasswordResponseData, AxiosError>(
    ['reset-password', id],
    async () => {
      const {
        data: { data },
      } = await api.systemLogin.get(`/auth/reset-password/${id}`);
      return data;
    },
    {
      enabled: !!id,
      meta: {
        disableToast: true,
      },
    }
  );

export default useResetPasswordInfo;
