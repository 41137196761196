import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import useStore from '../../../store';

import PlusIcon from '@/assets/icons/PlusIcon';
import colors from '@/constants/common/colors';

export default function CustomEdge({ id, markerEnd, ...props }: EdgeProps) {
  const offset = 4;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    ...props,
    sourceX: props.sourceX - offset,
    targetX: props.targetX + offset,
    borderRadius: 20,
  });

  const hasExpandedState = useStore((state) => {
    if (state.withinDragBounds) {
      const [source, target] = state.withinDragBounds.split('_');
      if (source === props.source && target === props.target) return true;
    }

    const expandedKeys = state.expandState
      ? Object.entries(state.expandState)
          .filter(([k, v]) => !!v)
          .map(([k]) => k)
      : [];

    return !!state.edges.filter(
      (edge) => expandedKeys.includes(edge.target) && edge.id === id
    ).length;
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={
          hasExpandedState
            ? {
                stroke: colors.secondary.main,
              }
            : undefined
        }
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <NodeCreator
            hasExpandedState={hasExpandedState}
            source={props.source}
            target={props.target}
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function NodeCreator({
  source,
  target,
  hasExpandedState,
}: {
  source: string;
  target?: string;
  hasExpandedState?: boolean;
}) {
  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  const isDragGlobal = useStore((s) => !!s.draggedNode);

  return (
    <div
      className={cx(
        'cursor-pointer w-[2.25rem] h-[2.25rem] bg-white flex items-center justify-center rounded-full border border-neutral-30 transition-colors outline outline-transparent  pointer-events-auto',
        !isDragGlobal &&
          'hover:border-secondary-main hover:outline-secondary-surface',
        hasExpandedState && 'border-secondary-main outline-secondary-surface'
      )}
      onClick={(e) => {
        onChangeStateModal({
          type: 'action',
          sourceId: source,
          targetId: target,
        });
        e.stopPropagation();
      }}
    >
      <PlusIcon />
    </div>
  );
}
