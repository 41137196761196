type CurrencyLocaleMapping = {
  [currency: string]: string;
};

const currencyToLocale: CurrencyLocaleMapping = {
  AUD: 'en-AU',
  USD: 'en-US',
  EUR: 'en-EU',
  GBP: 'en-GB',
  JPY: 'ja-JP',
  CAD: 'en-CA',
  CNY: 'zh-CN',
  INR: 'hi-IN',
  RUB: 'ru-RU',
  BRL: 'pt-BR',
  ZAR: 'en-ZA',
  KRW: 'ko-KR',
  SGD: 'en-SG',
  MYR: 'ms-MY',
  THB: 'th-TH',
  IDR: 'id-ID',
  COP: 'es-CO',
  MXN: 'es-MX',
} as const;

const formatCurrency = (
  value: number | string,
  currency: string | null | undefined,
  options?: Intl.NumberFormatOptions
) => {
  return `${
    new Intl.NumberFormat(currencyToLocale[currency ?? 'IDR'], {
      style: 'currency',
      currency: currency || 'IDR',
      ...options,
    }).format(parseInt(value.toString(), 10)) || 'NaN'
  }`;
};

export { formatCurrency };
