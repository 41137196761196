export default function ArrowBack({
  size = 32,
  color = '#FD823E',
}: {
  size?: number;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.11 5.724c.52.52.52 1.365 0 1.885l-5.725 5.724 5.724 5.724a1.333 1.333 0 11-1.885 1.886l-6.667-6.667a1.333 1.333 0 010-1.886l6.667-6.666c.52-.52 1.365-.52 1.885 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.167 13.333c0-.736.596-1.333 1.333-1.333h10.666c8.1 0 14.667 6.566 14.667 14.667V28a1.333 1.333 0 01-2.666 0v-1.333c0-6.628-5.373-12-12-12H4.5a1.333 1.333 0 01-1.333-1.334z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
