import api from 'config/api';
import {
  GetAgentsResponse,
  GetBrandsResponse,
} from 'types/whatsApp/my-business';

export default {
  getBrands: ({
    apiKey,
    nextCursor,
  }: {
    apiKey: string;
    nextCursor?: string;
  }) =>
    api.whatsApp.v2
      .get<GetBrandsResponse>(`/google-my-business/brands`, {
        headers: {
          'x-bitbybit-key': apiKey,
        },
        params: {
          nextCursor,
        },
      })
      .then((res) => res.data),
  getAgents: ({
    apiKey,
    brandName,
    nextCursor,
  }: {
    apiKey: string;
    brandName: string;
    nextCursor?: string;
  }) =>
    api.whatsApp.v2
      .get<GetAgentsResponse>(
        `/google-my-business/brands/${encodeURIComponent(brandName)}/agents`,
        {
          headers: {
            'x-bitbybit-key': apiKey,
          },
          params: {
            nextCursor,
          },
        }
      )
      .then((res) => res.data),
};
