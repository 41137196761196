import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoonLoader } from 'react-spinners';
import { createId } from '@paralleldrive/cuid2';
import { twMerge as cx } from 'tailwind-merge';
import { ShoppableForm, ShoppableType } from '.';

import CheckIcon from '@/assets/icons/CheckIcon';
import {
  BBBAlert,
  BBBModal,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
} from '@/components/ui';
import SearchInput from '@/components/ui/SearchInput';
import { env } from '@/config/env';
import useCollectionQuery, {
  BitLinkShopifyCollections,
  BitLinkShopifyProducts,
  useProductQuery,
} from '@/hooks/bitLink/shopify/useCollectionQuery';
import useResponsive from '@/hooks/common/useResponsive';

type Props = {
  onHide: () => void;
  onSave: (items: string, type: ShoppableType | undefined) => void;
  form: ShoppableForm;
  show: boolean;
};

export default function ModalShoppableAds({
  onHide,
  onSave,
  form,
  show,
}: Props) {
  const [active, setActive] = useState<ShoppableType>();
  const isMobile = useResponsive('sm');

  const navigator = useMemo(
    () => [
      {
        id: createId(),
        name: isMobile ? 'Add individual product' : 'Product',
        key: 'product',
      },
      {
        id: createId(),
        name: isMobile ? 'Add collection products' : 'Collection',
        key: 'collection',
      },
    ],
    [isMobile]
  );

  useEffect(() => {
    setActive(form.textSecondary || 'product');
  }, [form.id, form.textSecondary]);

  const [checkedProduct, setCheckedProduct] = useState<string[]>([]);
  const [checkedCollection, setCheckedCollection] = useState<string[]>([]);

  const [search, setSearch] = useState('');

  const collectionsQuery = useCollectionQuery(search, {
    enabled: active === 'collection',
  });

  const {
    isInitialLoading: isLoadingCollection,
    data: collectionData,
    hasNextPage,
    fetchNextPage: fetchNextCollections,
  } = collectionsQuery;

  const productsQuery = useProductQuery(search, {
    enabled: active === 'product',
  });

  const {
    isInitialLoading: isLoadingProduct,
    data: productData,
    hasNextPage: hasNextPageProduct,
    fetchNextPage: fetchNextProducts,
  } = productsQuery;

  useEffect(() => {
    if (form.textSecondary === 'product') {
      setCheckedProduct(form.items?.split(',') || []);
    } else {
      setCheckedCollection(form.items?.split(',') || []);
    }
  }, [form.textSecondary, form]);

  const countSelected = useMemo(
    () =>
      active === 'product' ? checkedProduct.length : checkedCollection.length,
    [active, checkedCollection, checkedProduct]
  );

  const handleSaveModals = () => {
    const data = active === 'product' ? checkedProduct : checkedCollection;
    onSave(data.join(','), active);
  };

  const onProductClicked = (
    productCb: BitLinkShopifyProducts | BitLinkShopifyCollections
  ) => {
    if (checkedProduct.length === 6 && !checkedProduct.includes(productCb.id)) {
      return;
    }
    if (checkedProduct.includes(productCb.id)) {
      setCheckedProduct((prevProduct) => {
        let cloneProduct = Array.from(prevProduct);
        cloneProduct = cloneProduct.filter((prod) => prod !== productCb.id);
        return cloneProduct;
      });
    } else {
      setCheckedProduct((prevProduct) => [...prevProduct, productCb.id]);
    }
  };

  const onCollectionClicked = (
    collection: BitLinkShopifyCollections | BitLinkShopifyProducts
  ) => {
    setCheckedCollection([collection.id]);
  };

  const onClear = () => {
    if (active === 'product') {
      setCheckedProduct([]);
    } else {
      setCheckedCollection([]);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const collectionDataFiltered = productData?.pages
    ?.flatMap((item) => item.data)
    ?.filter((item) => item.status === 'ACTIVE');

  return (
    <BBBModal
      show={show}
      onHide={() => {
        onHide();
      }}
      onCancel={() => {
        onHide();
      }}
      footer
      disableSave={
        (active === 'product' ? isLoadingProduct : isLoadingCollection) ||
        countSelected === 0
      }
      customDelete={
        <div className="grow">
          <div className="text-primary-main">Selected : {countSelected}</div>
          <div className="text-danger-main cursor-pointer" onClick={onClear}>
            Clear selection
          </div>
        </div>
      }
      handleSave={handleSaveModals}
      title="Choose Product or Collections"
      subtitle="Any change you make to these products within Shopify will automatically stay in sync with your bitLink."
      submitText={`Add ${active}`}
    >
      {isMobile ? (
        <BBBSelect
          options={navigator}
          optionLabel="name"
          optionValue="key"
          value={navigator.find((nav) => nav.key === active)}
          onValueChange={(opt) => setActive(opt!.key as ShoppableType)}
          className="mb-2.5"
        />
      ) : (
        <div className="w-3/4 mx-auto flex shadow rounded-lg bg-white my-6">
          {navigator.map((item) => (
            <div
              className={cx(
                'flex-1 flex justify-center py-2 rounded-lg cursor-pointer shadow-md',
                active === item.key
                  ? 'bg-primary-main text-neutral-10'
                  : 'bg-neutral-10 text-primary-main',
                form.id
                  ? form.textSecondary === item.key
                    ? 'cursor-pointer'
                    : 'opacity-50 pointer-events-none'
                  : ''
              )}
              key={item.id}
              onClick={() => {
                setActive(item.key as ShoppableType);
              }}
            >
              <div className="link-name">{item.name}</div>
            </div>
          ))}
        </div>
      )}
      <SearchInput
        placeholder={`Search ${
          active === 'product' ? 'product' : 'collection'
        }`}
        value={search}
        onValueChange={handleSearch}
        containerClassname="mb-6"
      />
      {isMobile && (
        <div className="flex justify-between mb-2.5">
          <div className="text-primary-main">Selected : {countSelected}</div>
          <div className="text-sm underline cursor-pointer" onClick={onClear}>
            Clear selection
          </div>
        </div>
      )}
      <div className="mb-3">
        {active === 'collection' ? (
          isLoadingCollection ? (
            <BBBSpinner />
          ) : collectionData?.pages.flatMap((data) => data.data).length ===
            0 ? (
            <BBBAlert type="warning" message="No collection found" />
          ) : (
            <InfiniteShoppable
              data={collectionData?.pages.flatMap((data) => data.data) || []}
              hasMore={hasNextPage || false}
              fetchNext={fetchNextCollections}
              checkedList={checkedCollection}
              onListClicked={onCollectionClicked}
            />
          )
        ) : isLoadingProduct ? (
          <BBBSpinner />
        ) : collectionDataFiltered?.length === 0 ? (
          <BBBAlert type="warning" message="No product found" />
        ) : (
          <InfiniteShoppable
            data={collectionDataFiltered || []}
            hasMore={hasNextPageProduct || false}
            fetchNext={fetchNextProducts}
            checkedList={checkedProduct}
            onListClicked={onProductClicked}
          />
        )}
      </div>
    </BBBModal>
  );
}

type InfiniteShoppableProps = {
  data: (BitLinkShopifyProducts | BitLinkShopifyCollections)[];
  checkedList: string[];
  onListClicked: (
    product: BitLinkShopifyProducts | BitLinkShopifyCollections
  ) => void;
  hasMore: boolean;
  fetchNext: () => void;
};

function InfiniteShoppable({
  data,
  checkedList,
  onListClicked,
  hasMore,
  fetchNext,
}: InfiniteShoppableProps) {
  return (
    <InfiniteScroll
      dataLength={data.length || 0}
      height={400}
      hasMore={hasMore || false}
      next={() => fetchNext()}
      loader={
        <div className="flex justify-center items-center my-2 text-left">
          <MoonLoader size={24} />
        </div>
      }
    >
      {data.map((product) => (
        <div
          className={cx(
            'flex justify-between hover:bg-neutral-20 py-2 px-3 items-center cursor-pointer transition-all rounded-lg mt-1',
            checkedList.includes(product.id) && `selected bg-neutral-20`
          )}
          key={product.id}
          onClick={() => {
            onListClicked(product);
          }}
        >
          <div className="flex items-center gap-6">
            <img
              className="flex-none w-10 h-10 md:w-14 md:h-14 rounded object-cover"
              alt="logo"
              src={
                product.featuredImage
                  ? product.featuredImage?.url
                  : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
              }
              loading="lazy"
            />
            <div className="text-primary-main">{product.title}</div>
          </div>
          {checkedList.includes(product.id) && <CheckIcon />}
        </div>
      ))}
    </InfiniteScroll>
  );
}
