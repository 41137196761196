import { useInfiniteQuery } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { GetAiTagPayload } from '@/api/services/customer/customer';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAiTags = (params?: Omit<GetAiTagPayload, 'cursor'>) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    ['ai-tags', activeCompany, params],
    ({ pageParam }) =>
      customerServices.customer.getAiTags(activeCompany!, {
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
    }
  );
};

export default useAiTags;
