import * as yup from 'yup';

const redirectionPathSchema = yup
  .object({
    id: yup.number().required('Redirection path id is required'),
    label: yup.string().required('Redirection path label is required'),
    value: yup.lazy((value) => {
      if (value === null) return yup.string().nullable();
      return yup.string().required('Redirection path value is required');
    }),
    params: yup.lazy((value) => {
      if (!value) return yup.mixed().optional();
      return yup.array().of(
        yup.object({
          queryKey: yup.string().required().label('Redirection path queryKey'),
          label: yup.string().required().label('Redirection path label'),
          endpoint: yup.string().optional(),
          type: yup
            .mixed<'options' | 'url' | 'dynamic-variable'>()
            .oneOf(['options', 'url', 'dynamic-variable'])
            .required('Redirection path param type is required'),
          validation: yup.string().optional(),
          data: yup
            .string()
            .label('Redirection path data')
            .required('Redirection path param url is required'),
        })
      );
    }),
  })
  .nullable()
  .required()
  .label('Redirection path');

export default redirectionPathSchema;
