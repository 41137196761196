import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useAppSelector } from 'hooks/rtk/store';
import { useActiveCompany } from '../rtk/selector';
import { selectActiveChatTruthy } from './chat';

const useTicketHistory = (
  params?: Parameters<typeof services.whatsApp.v2.ticket.getTicketHistory>[3],
  enabled?: boolean
) => {
  const activeCompany = useActiveCompany();
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useQuery(
    [
      'ticket-history',
      activeCompany,
      selectedChat.sources,
      selectedChat.clientNumber,
      params,
    ],
    () =>
      services.whatsApp.v2.ticket.getTicketHistory(
        activeCompany,
        selectedChat.sources,
        selectedChat.clientNumber,
        params
      ),
    {
      enabled: !!enabled,
    }
  );
};

export default useTicketHistory;
