import GeneralLayout from 'layouts/GeneralLayout';

import CheckCircle from '@/assets/icons/CheckCircle';

function ShopifyOAuthConnectSuccess() {
  return (
    <GeneralLayout>
      <div className="absolute inset-0 flex justify-center items-center flex-col">
        <div className="text-xl font-medium mb-5">Authentication success!</div>{' '}
        <CheckCircle size={64} />
        <div className="mt-5">
          You should be redirected back to Shopify in few seconds...
        </div>
        <div>If nothing happens, close this window directly</div>
      </div>
    </GeneralLayout>
  );
}

export default ShopifyOAuthConnectSuccess;
