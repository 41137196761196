import { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash-es/isEqual';
import * as yup from 'yup';
import BannerNotification from '../BannerNotification';
import LoginStoreCard from '../LoginStoreCard';

import { BBBCard, BBBPasswordInput, BBBTextInput } from '@/components/ui';
import { knowledgeBase } from '@/constants/bitLogin/knowledge-base';
import {
  isSameOrAbovePricingTier,
  standardPlanDeprecation,
} from '@/constants/pricing';
import useSocialConfigProvider from '@/hooks/bitLogin/useSocialConfigProvider';
import useUpsertSocialConfig from '@/hooks/bitLogin/useUpsertSocialConfig';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { SocialConfig } from '@/types/bitLogin/v2';

type Props = {
  className?: string;
};

const schema = yup.object().shape({
  clientId: yup.string().required('Cannot be empty').label('Client ID'),
  clientSecret: yup.string().required('Cannot be empty').label('Client secret'),
});

function MicrosoftSettingCard({ className }: Props) {
  const { data: pricingData } = usePricingByApp('BITLOGIN');
  const { mutate: updateSocial, isLoading } = useUpsertSocialConfig();
  const { data: socialConfig, isInitialLoading: isLoadingOauth } =
    useSocialConfigProvider('MICROSOFT');

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useCustomForm<Partial<SocialConfig>>({
    resolver: yupResolver(schema),
    defaultValues: {
      clientId: '',
      clientSecret: '',
      usePersonalOauth: false,
    },
  });

  const { toggle } = useConfirmationBanner();

  const dataFromApi = useMemo<Partial<SocialConfig>>(
    () => ({
      clientId: socialConfig?.clientId || '',
      clientSecret: socialConfig?.clientSecret || '',
      usePersonalOauth: socialConfig?.usePersonalOauth || false,
    }),
    [socialConfig]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    function onSubmit(data: Partial<SocialConfig>) {
      updateSocial({ ...data, provider: 'MICROSOFT' });
    }

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('bitlogin-twitter-oauth-form', {
      show: !isFormEqual,
      variant: isLoading || isLoadingOauth ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    isLoading,
    isLoadingOauth,
    reset,
    toggle,
    updateSocial,
    watch(),
  ]);

  return (
    <div className="flex flex-col">
      <BBBCard
        title="Microsoft Custom OAuth"
        desc="Microsoft OAuth is a secure way to let users sign in without giving away their passwords."
        className={className}
      >
        {isLoadingOauth ? (
          <FieldSkeleton />
        ) : (
          <>
            <BannerNotification type="MICROSOFT" />
            <div
              className={
                pricingData?.pricingName &&
                !isSameOrAbovePricingTier(
                  standardPlanDeprecation(pricingData.pricingName),
                  'pro'
                )
                  ? 'my-3 opacity-50 pointer-events-none'
                  : 'my-3'
              }
            >
              <BBBTextInput
                label="Client ID"
                placeholder="Enter your App ID"
                isHookForm
                control={control}
                controlName="clientId"
                error={errors.clientId?.message}
                autoComplete="new-password"
              />
              <BBBPasswordInput
                label="Client Secret"
                placeholder="Enter your App Secret"
                isHookForm
                control={control}
                controlName="clientSecret"
                autoComplete="new-password"
                error={errors.clientSecret?.message}
              />
            </div>
            <a
              href={knowledgeBase.MICROSOFT}
              target="_blank"
              className="underline text-info-main"
              rel="noreferrer"
            >
              How to get Microsoft App ID & Secret Key
            </a>
          </>
        )}
      </BBBCard>
      <LoginStoreCard providerName="MICROSOFT" />
    </div>
  );
}

function FieldSkeleton() {
  return (
    <>
      <Skeleton className="mb-5" height={30} count={2} />
      <Skeleton width={100} />
    </>
  );
}

export default MicrosoftSettingCard;
