import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import useAccounts from '@/hooks/whatsApp/useAccounts';

const useTicket = (source: string, ticketId: number) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const { data: accountData } = useAccounts();

  return useQuery(
    ['ticket', activeCompany, source, ticketId, accountData?.apiKey],
    () =>
      services.whatsApp.v2.ticket.getTicket(
        activeCompany!,
        source,
        ticketId,
        accountData!.apiKey
      ),
    {
      enabled: !!accountData?.apiKey,
    }
  );
};

export default useTicket;
