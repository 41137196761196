import { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MoreHorizontal, Plus } from 'react-feather';
import { Link } from 'react-router-dom';
import FormIcon from 'assets/icons/FormIcon';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import useConnectIntegration from 'hooks/common/useConnectIntegration';
import useOutsideAlerter from 'hooks/common/useOutsideAlerterv2';
import {
  useConnectChannelConfirmation,
  useConnectedToAnyChannels,
} from 'hooks/whatsApp/channels';
import {
  useDeleteForm,
  useDuplicateForm,
  useForms,
  usePublishForm,
} from 'hooks/whatsApp/form';
import { twMerge as cx } from 'tailwind-merge';
import { FormTemplate, FormTemplateStatus } from 'types/whatsApp/form';
import BetaBadge from 'components/Badge/BetaBadge';
import { formatDate2 } from 'utils/common/date';

import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBModal,
  BBBTextInput,
} from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';

export default function WhatsAppForm() {
  const [search, setSearch] = useState('');

  const { data: isConnected } = useConnectedToAnyChannels('whatsapp_meta');

  const [showCreate, setShowCreate] = useState(false);
  const [showActionId, setShowActionId] = useState<string>();

  const confirmConnect = useConnectChannelConfirmation();

  const { data } = useForms({ search });

  const formData = data || [];

  const columns: HeadersTypes<FormTemplate> = useMemo(
    () => [
      {
        accesor: 'name',
        isSortable: true,
        renderHeader: () => 'Form name',
        render: (info) => (
          <>
            {info.status === 'DRAFT' ? `[DRAFT] ` : ''}
            {info.name}
          </>
        ),
      },
      {
        accesor: 'sent',
        isSortable: true,
        renderHeader: () => 'Sent',
        render: (info) => <>{info.sent}</>,
      },
      {
        accesor: 'answered',
        isSortable: true,
        renderHeader: () => 'Responses',
        render: (info) => <>{info.answered}</>,
      },
      {
        accesor: 'updatedAt',
        isSortable: true,
        renderHeader: () => 'Last updated',
        render: (info) => <>{formatDate2(info.updatedAt)}</>,
      },
      {
        isSortable: true,
        isAdditionalColumn: true,
        renderHeader: () => 'Action',
        render: (info) => (
          <>
            <div className="relative w-6 h-6">
              <MoreHorizontal
                className={cx(
                  'relative cursor-pointer',
                  showActionId === info.id &&
                    'rounded-lg outline outline-secondary-main outline-[1px] shadow-[0px_0px_0px_2.5px_#FED5BF]'
                )}
                id={`more-action-${info.id}`}
                onClick={(e) => {
                  setShowActionId(info.id);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              {showActionId === info.id && (
                <ActionCard
                  onClose={() => {
                    setShowActionId(undefined);
                  }}
                  status={info.status}
                  spreadSheetId={info.spreadSheetId}
                  sheetId={info.sheetId}
                  formId={info.id}
                />
              )}
            </div>
          </>
        ),
      },
    ],
    [showActionId]
  );

  return (
    <BBBContainer
      hasHeading
      pageTitle="WhatsApp Form"
      pageDescription="Collect data from your customers easily through WhatsApp"
      rightComponent={
        <BBBButton
          text={'Create new form'}
          icon={<Plus />}
          iconPosition="right"
          onClick={() => {
            if (!isConnected) {
              confirmConnect('whatsapp_meta', 'WhatsApp Form');
            } else {
              setShowCreate(true);
            }
          }}
        />
      }
    >
      {showCreate && <CreateFormModal onHide={() => setShowCreate(false)} />}
      <BBBTableV2
        headers={columns}
        data={formData}
        dataId="id"
        linkDestination={(row) =>
          row.status !== 'PUBLISHED'
            ? `/bitchat/whatsapp-form/${row.id}`
            : `/bitchat/whatsapp-form/${row.id}/responses`
        }
        isSearchable
        searchPlaceholder="Search form"
        searchValue={search}
        onChangeSearch={setSearch}
        renderEmptyMessage={() => (
          <div className="flex flex-col items-center justify-center h-[25rem]">
            <div className="bg-neutral-30 w-[3.125rem] h-[3.125rem] rounded-full flex items-center justify-center mb-2">
              <FormIcon />
            </div>
            <div className="text-neutral-50 mb-4">
              There is no data. Create a form and send to your customers
            </div>
            <BBBButton
              variant="secondary"
              text="Create a form"
              onClick={() => {
                if (!isConnected) {
                  confirmConnect('whatsapp_meta', 'WhatsApp Form');
                } else {
                  setShowCreate(true);
                }
              }}
            />
          </div>
        )}
      />
    </BBBContainer>
  );
}

function CreateFormModal({ onHide }: { onHide: () => void }) {
  const [name, setName] = useState('');

  return (
    <BBBModal
      title="Create new form"
      show
      onHide={onHide}
      footer
      submitText="Create form"
      disableSave={!name}
      submitLink={`/bitchat/whatsapp-form/new?prefill_name=${name}`}
    >
      <BBBTextInput
        label="Form name"
        hasMaxCharLabel
        maxChar={200}
        placeholder="eg: Restaurant Reservation, Doctor Appointment, etc"
        value={name}
        onChange={({ target: { value } }) => setName(value)}
      />
    </BBBModal>
  );
}

function ActionCard({
  onClose,
  status,
  spreadSheetId,
  sheetId,
  formId,
}: {
  onClose: () => void;
  status: FormTemplateStatus;
  spreadSheetId: string | null;
  sheetId: string | null;
  formId: string;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const connect = useConnectIntegration();
  const { mutate: publishForm } = usePublishForm();
  const { mutate: deleteForm } = useDeleteForm();
  const { mutate: duplicateForm } = useDuplicateForm();

  const confirm = useConfirmationModal();

  useOutsideAlerter(
    ref,
    () => {
      onClose();
    },
    true
  );

  const parentRect = useMemo(() => {
    const parentRef = document.getElementById(`more-action-${formId}`);

    return parentRef ? parentRef.getBoundingClientRect() : undefined;
  }, [formId]);

  return createPortal(
    <BBBCard
      className="absolute z-10 w-56 p-0 md:p-0 rounded-lg"
      ref={ref}
      style={
        parentRect
          ? {
              top: parentRect.top + parentRect.height,
              right: window.innerWidth - parentRect.right,
            }
          : undefined
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {status === 'DRAFT' && (
        <div
          className="px-3 md:px-5 py-3 md:py-3 rounded-lg rounded-bl-none transition-colors rounded-br-none hover:bg-secondary-border cursor-pointer"
          onClick={(e) => {
            confirm({
              title: 'Publish WhatsApp Form',
              description:
                'Are you sure want to publish this form? Once it published, you cannot edit this form',
              submitText: 'Publish WhatsApp Form',
              onAccept: (hide) => {
                publishForm(formId, {
                  onSuccess: () => {
                    onClose();
                    hide();
                  },
                });
              },
            });
            e.stopPropagation();
          }}
        >
          Publish
        </div>
      )}
      {status === 'PUBLISHED' && (
        <>
          <Link
            className="px-3 md:px-5 py-3 md:py-3 rounded-lg rounded-bl-none transition-colors rounded-br-none hover:bg-secondary-border block "
            to={`/bitchat/whatsapp-form/${formId}/responses`}
          >
            See response
          </Link>
          {spreadSheetId && sheetId ? (
            <a
              className="cursor-pointer px-3 md:px-5 py-3 md:py-3 transition-colors hover:bg-secondary-border block"
              href={`https://docs.google.com/spreadsheets/d/${spreadSheetId}/edit#gid=${sheetId}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Open in Google Sheets
            </a>
          ) : (
            <div
              className="px-3 md:px-5 py-3 md:py-3 hover:bg-secondary-border transition-colors cursor-pointer"
              onClick={(e) => {
                connect({
                  name: 'google_sheets',
                  meta: {
                    formId,
                  },
                });
                e.stopPropagation();
              }}
            >
              Save to Google Sheets
            </div>
          )}
        </>
      )}
      <div
        className="px-3 md:px-5 py-3 md:py-3 hover:bg-secondary-border transition-colors cursor-pointer"
        onClick={() => {
          duplicateForm(formId, {
            onSuccess: () => {
              onClose();
            },
          });
        }}
      >
        Duplicate
      </div>
      <div
        onClick={(e) => {
          confirm({
            title: 'Delete form',
            description:
              'Are you sure want to delete this form? Once it deleted the action cannot be undone.',
            onAccept: (hide) => {
              deleteForm(formId, {
                onSuccess: () => {
                  onClose();
                  hide();
                },
              });
            },
            submitText: 'Delete',
          });

          e.stopPropagation();
          e.preventDefault();
        }}
        className="cursor-pointer px-3 md:px-5 py-3 md:py-3 transition-colors rounded-lg rounded-tl-none rounded-tr-none hover:bg-secondary-border"
      >
        Delete
      </div>
    </BBBCard>,
    document.body!
  );
}
