import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { PromptExperiments } from 'api/services/ai/v1/customer-tag';
import { useActiveCompany } from '../rtk/selector';
import { useAppSelector } from '../rtk/store';

const CUSTOMER_TAG_QUERY_KEY = 'ai-customer-tag' as const;

export const useCustomerTag = (params?: { enabled?: boolean }) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [`${CUSTOMER_TAG_QUERY_KEY}-by-company`, activeCompany],
    () => services.ai.v1.customerTag.getCustomerTagByCompany(activeCompany),
    {
      enabled: params?.enabled,
    }
  );
};

export const useCustomerTagResult = (
  ticketId: Parameters<
    typeof services.ai.v1.customerTag.getCustomerTagByCompanyAndTicket
  >[1],
  params?: {
    enabled?: boolean;
  }
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    [
      `${CUSTOMER_TAG_QUERY_KEY}-result-by-company-and-ticket`,
      activeCompany,
      ticketId,
    ],
    () =>
      services.ai.v1.customerTag.getCustomerTagByCompanyAndTicket(
        activeCompany,
        ticketId
      ),
    {
      enabled: !!ticketId && params?.enabled,
    }
  );
};

export const useUpsertCustomerTagPrompt = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    (payload: Partial<PromptExperiments>) =>
      services.ai.v1.customerTag.upsertCustomerTagPrompt(
        activeCompany!,
        payload
      ),
    {
      onSuccess: () => {
        client.invalidateQueries([`${CUSTOMER_TAG_QUERY_KEY}-by-company`]);
        client.invalidateQueries([
          `${CUSTOMER_TAG_QUERY_KEY}-result-by-company-and-ticket`,
        ]);
        client.invalidateQueries([['ticket-chat']]);
      },
    }
  );
};
