import { ApiResponse, PaginatedResponse } from '@/api/types';
import api from '@/config/api';
import {
  Product,
  ProductOption,
  ProductValues,
  ProductVariant,
} from '@/types/customers';

export default {
  fetchProducts: (
    companyId: number,
    params?: {
      limit?: number;
      page?: number;
      search?: string;
      source?: string;
      whatsappCatalog?: boolean;
    }
  ) =>
    api.customers
      .get<PaginatedResponse<ProductWithVariants[]>>(`/products/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  fetchProduct: (companyId: number, productId: string) =>
    api.customers
      .get<ApiResponse<ProductWithVariants>>(
        `/products/${companyId}/${productId}`
      )
      .then((res) => res.data.data),
  upsertProduct: (companyId: number, data: UpsertProductParams) =>
    api.customers
      .post(`/products/${companyId}`, data)
      .then((res) => res.data.data),
  importProducts: (companyId: number, data: ImportProductsParams) =>
    api.customers.post(`/products/${companyId}/bulk-import`, data),
  toggleDisplayOnWhatsappCatalog: (
    companyId: number,
    payload: ToggleDisplayOnWhatsappCatalogParams
  ) =>
    api.customers.post(
      `/products/${companyId}/toggle-display-whatsapp-catalog`,
      payload
    ),
  hasAlreadyImported: (companyId: number) =>
    api.customers
      .get<{
        hasAlreadyImported: boolean;
      }>(`/products/${companyId}/has-already-imported`)
      .then((res) => res.data.hasAlreadyImported),
  bulkDeleteProducts: ({
    ids,
    companyId,
    isAllSelected,
  }: {
    ids: string[] | undefined;
    companyId: number;
    isAllSelected: boolean;
  }) =>
    api.customers.post(`/products/${companyId}/bulk/delete`, {
      ids,
      isAllSelected,
    }),
};

export type ToggleDisplayOnWhatsappCatalogParams = {
  productId: string;
  displayOnWhatsappCatalog: boolean;
};

export type ImportProductsParams = {
  domain: string;
};

export type ProductWithVariants = Product & {
  variants: ProductVariant[];
  productOptions: (ProductOption & {
    productOptionValues: ProductValues[];
  })[];
};
export type UpsertProductParams = {
  productId?: string;
  title: string;
  description: string;
  image: string[];
  displayOnWhatsappCatalog: boolean;
  variants: { key: string; price: number }[];
  options: { key: string; values: string[] }[];
  price?: number;
  discountedPrice?: number;
  showDiscountedPrice?: boolean;
};
