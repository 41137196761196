export interface ModalState {
  menu: boolean;
  banners: boolean;
  collections: boolean;
  imagegrids: boolean;
}

export const initialStateModal: ModalState = {
  menu: false,
  banners: false,
  imagegrids: false,
  collections: false,
};

export type PopupState = {
  type: string | null;
  id: number | null;
  hasError: boolean;
  errorMessage?: string;
};

export type CartImageCustomType = {
  mode: 'custom';
  url: string;
};

export type CartImageTemplateType = {
  mode: 'template';
  templateId: number;
};

export type CartImageTypes =
  | CartImageCustomType
  | CartImageTemplateType
  | undefined;

export type CartColorTypes = {
  isPrimaryDefault: boolean;
  color?: string;
};

export type PlaceholderPositionTypes = {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
  id: string;
};

export type CategoryTypes = {
  id: number;
  title: string;
  active: boolean;
  position: number;
  destination: {
    label: string;
    value: string;
  };
  customUrl: string | null | undefined;
  collection: string | null | undefined;
  subCategories: SubCategoryTypes[];
};

export type SubCategoryTypes = {
  categoryId: number;
  title: string;
  idCollection: string | null | undefined;
  destination: {
    label: string;
    value: string;
  };
  customUrl: string | null | undefined;
  position: number;
};
