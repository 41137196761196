import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { BBBCard } from '@/components/ui';
import { BBBModal, BBBTextInput } from '@/components/ui';
import {
  useCustomerMetafields,
  useUpdateMetafields,
} from '@/hooks/customers/customer/metafields';
import { CustomerMetafield } from '@/types/customers';
import { CustomerAdditionalDataAssociation } from '@/types/customers/association';

function AdditionalDataCard({
  additionalDatas,
}: {
  additionalDatas?: CustomerAdditionalDataAssociation[];
}) {
  const [showEditMetafieldModal, setShowEditMetafieldModal] = useState(false);

  const { data: metafields } = useCustomerMetafields();

  if (!metafields?.length) return null;

  const additionalDataHash = additionalDatas
    ? Object.fromEntries(additionalDatas.map((d) => [d.key, d.value]))
    : {};

  return (
    <>
      {showEditMetafieldModal && (
        <EditAdditionalDataModal
          metafields={metafields}
          additionalDataHash={additionalDataHash}
          onHide={() => {
            setShowEditMetafieldModal(false);
          }}
        />
      )}
      <BBBCard
        className="mb-cardBottom"
        title="Additional data"
        rightButton={
          <span
            className="text-link underline cursor-pointer"
            onClick={() => {
              setShowEditMetafieldModal(true);
            }}
          >
            Edit
          </span>
        }
      >
        {metafields.map((data) => (
          <div key={data.key} className="mb-6 last:mb-0">
            <div className="text-sub-heading">{data.label}:</div>
            <div>{additionalDataHash[data.key] || '-'}</div>
          </div>
        ))}
      </BBBCard>
    </>
  );
}

export default AdditionalDataCard;

function EditAdditionalDataModal({
  metafields,
  additionalDataHash,
  onHide,
}: {
  metafields: CustomerMetafield[];
  additionalDataHash: {
    [k: string]: string;
  };
  onHide: () => void;
}) {
  const metafieldKey = useMemo(
    () =>
      Object.fromEntries(
        metafields.map((metafield) => [metafield.key, metafield.type])
      ),
    [metafields]
  );

  const [formValue, setFormValue] = useState(additionalDataHash);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setFormValue(
      Object.fromEntries(
        Object.entries(additionalDataHash).map(([k, v]) => {
          let value = v;

          if (metafieldKey[k] === 'multiple_choice') {
            //@ts-ignore
            value = v ? JSON.parse(v).join(',') : '';
          }

          return [k, value];
        })
      )
    );
  }, [additionalDataHash, metafieldKey]);

  const { mutate: updateMetafields, isLoading: loadingUpdateMetafields } =
    useUpdateMetafields();

  return (
    <BBBModal
      title="Edit additional data"
      subtitle="Additional data will be gathered from the sign up process through bitLogin"
      show
      footer
      cancelText="Discard"
      size="xl"
      handleSave={() => {
        updateMetafields(
          {
            customerId: id,
            metafields: Object.entries(formValue).map(([k, v]) => {
              return {
                key: k,
                value: metafieldKey[k] === 'multiple_choice' ? v.split(',') : v,
              };
            }),
          },
          {
            onSuccess: () => {
              onHide();
            },
          }
        );
      }}
      loadingSave={loadingUpdateMetafields}
      onHide={onHide}
    >
      {metafields.map((data) => {
        return (
          <BBBTextInput
            key={data.key}
            placeholder="Input value"
            label={data.label}
            value={formValue[data.key]}
            onChange={({ target: { value } }) =>
              setFormValue((prev) => ({ ...prev, [data.key]: value }))
            }
          />
        );
      })}
    </BBBModal>
  );
}
