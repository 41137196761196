import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    'Unlimited design blocks!',
    'Customizable navigations',
    'Natively integrates with Android and iOS',
    'Custom your own app icon and splash screen',
  ],
  standard: [
    'Product reviews integration',
    'Unlimited Push Notification ',
    'Hide bitApp logo',
    'Integration with stamped & yotpo',
    'Publish with your own developer account',
  ],
};
