import { ApiResponse } from 'api/types';
import api from 'config/api';
import { Chat } from 'types/whatsApp/chat';
import { MessageNew, MessageNewAssociation } from 'types/whatsApp/v3';

export const GET_MESSAGES_DEFAULT_LIMIT = 20;

export default {
  getMessages: ({
    limit = GET_MESSAGES_DEFAULT_LIMIT,
    apiKey: key,
    ...params
  }: {
    chatId: string;
    apiKey: string | undefined;
    cursor?: string;
    point?: string;
    point2?: string;
    after?: string;
    before?: string;
    companyId?: number;
    limit?: number;
  } & Pick<Chat, 'sources'>) =>
    api.whatsApp.v3
      .get<{
        response: MessageNewAssociation[];
        meta: {
          startCursor?: string;
          endCursor?: string;
          hasNextPage?: boolean;
          hasPreviousPage?: boolean;
        };
      }>(`/message/${params.chatId}`, {
        params: { limit, key, ...params },
      })
      .then((res) => res.data),
  getMedias: (
    {
      apiKey,
      conversationId,
    }: {
      apiKey: string;
      conversationId: string;
    },
    params?: {
      cursor?: string;
      messageId?: string;
      sources: Chat['sources'];
      limit?: number;
    }
  ) =>
    api.whatsApp.v3
      .get<GetMediasApiResponse>(`/message/${conversationId}/media`, {
        params,
        headers: {
          'x-bitbybit-key': apiKey,
        },
      })
      .then((res) => res.data),
  getStarredMessages: ({ chatId, ...params }: GetStarredMessagesParams) =>
    api.whatsApp.v3
      .get<ApiResponse<MessageNew[]>>(`/message/${chatId}/starred`, {
        params,
      })
      .then((res) => res.data.data),
};

export type GetMediasApiResponse = {
  data: MessageNewAssociation[];
  meta: {
    startCursor?: string;
    endCursor?: string;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
};

export type GetStarredMessagesParams = {
  chatId: string;
  key?: string;
} & Pick<Chat, 'sources'>;
