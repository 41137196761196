import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';
import useStore from 'pages/BitCRM/AutomationNew/store';
import { AutomationNew } from 'types/bitCRM/automation-new';
import { DeepPartial } from 'types/utils/deepPartial';
import { toast } from 'utils/common/toast';

import useConfirmationModal from '@/hooks/common/useConfirmationModal';

export const useFlows = (
  params?: {
    search?: string;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['automations-new', activeCompany, params],
    () => services.bitCRM.v2.automationNew.getFlows(activeCompany, params),
    options
  );
};

export const useFlow = (id?: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['automation-new', id],
    () => services.bitCRM.v2.automationNew.getFlowById(activeCompany, id!),
    {
      enabled: !!id && id !== 'new',
    }
  );
};

export const useDeleteFlow = () => {
  const history = useHistory();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) =>
      services.bitCRM.v2.automationNew.deleteFlow(activeCompany, id),
    {
      onSuccess: () => {
        history.push(`/bitcrm/automation-new`, {
          key: 'shortcut-delete-automation',
        });
        queryClient.invalidateQueries(['automations-new']);
      },
    }
  );
};

export const useDuplicateFlow = () => {
  const history = useHistory();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const setLoadingDuplicateFlow = useStore((s) => s.setLoadingDuplicateFlow);

  return useMutation(
    (id: string) =>
      services.bitCRM.v2.automationNew.duplicateFlow(activeCompany, id),
    {
      onMutate: () => {
        setLoadingDuplicateFlow(true);
      },
      onSuccess: ({ data }) => {
        history.push(`/bitcrm/automation-new/${data.data.id}`);
        queryClient.invalidateQueries(['automations-new']);
        setTimeout(() => {
          setLoadingDuplicateFlow(false);
        }, 3000);
      },
      onError: () => {
        setTimeout(() => {
          setLoadingDuplicateFlow(false);
        }, 3000);
      },
    }
  );
};

export const useUpdateFlow = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  return useMutation(
    (
      payload: DeepPartial<AutomationNew> & {
        skipRedirect?: boolean;
      }
    ) =>
      services.bitCRM.v2.automationNew.updateFlow({
        ...payload,
        companyId: activeCompany,
      }),
    {
      onSuccess: (data, payload) => {
        toast.success(
          id !== 'new'
            ? `Sucesfully update automation`
            : `Automation ${payload.name} created`
        );

        queryClient.invalidateQueries(['automations-new']);
        queryClient.invalidateQueries(['automation-new']);

        if (!payload.skipRedirect && id === 'new') {
          history.push(`/bitcrm/automation-new/${data.id}`);
        }
      },
    }
  );
};

export const useToggleFlow = (queryKey?: Record<string, any>) => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, ...payload }: Pick<AutomationNew, 'id' | 'status' | 'name'>) =>
      services.bitCRM.v2.automationNew.toggleFlow(activeCompany, payload),
    {
      onSuccess: (data, { name, ...payload }) => {
        toast.success(
          `Chatbot ${name} has been ${
            payload.status === 'ACTIVE' ? 'activated' : 'inactivated'
          }`
        );
      },
      onMutate: async ({ status, id }) => {
        await queryClient.cancelQueries({
          queryKey: ['automations-new', activeCompany, queryKey],
        });

        await queryClient.cancelQueries({
          queryKey: ['automation-new', id],
        });

        const previousFlowsData = queryClient.getQueryData([
          'automations-new',
          activeCompany,
          queryKey,
        ]);

        queryClient.setQueryData<AutomationNew[]>(
          ['automations-new', activeCompany, queryKey],
          (old) => {
            return old?.map((prev) =>
              prev.id === id
                ? {
                    ...prev,
                    status,
                  }
                : prev
            );
          }
        );

        const previousFlowData = queryClient.getQueryData([
          'automation-new',
          id,
        ]);

        queryClient.setQueryData<AutomationNew>(
          ['automation-new', id],
          (old) => {
            if (old) {
              old.status = status;
            }
            return old;
          }
        );

        return { previousFlowsData, previousFlowData };
      },
      onSettled: () => {
        queryClient.invalidateQueries(['automations-new']);
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['automations-new', queryKey],
          context?.previousFlowsData
        );
      },
    }
  );
};

export const useCheckNewAutomation = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['check-new-automation', activeCompany],
    () => services.bitCRM.v2.automationNew.checkNewAutomation(activeCompany),
    {
      select: (data) => {
        const hasAbandonedTriggers = data.length > 0;
        const hasAbandonedTriggersTurnedOn = !!data.filter(
          (automationNode) => automationNode.automation.status === 'ACTIVE'
        ).length;

        return {
          hasAbandonedTriggers,
          hasAbandonedTriggersTurnedOn,
        };
      },
    }
  );
};

const mapChannelConnectCopy = {
  whatsapp_meta: {
    title: `Integrate with WhatsApp`,
    description: `To continue, you need to connect your WhatsApp API to bitbybit. It will enable you to set up automation and send campaign through your WhatsApp number`,
  },
};

export const useConnectChannelConfirmation = () => {
  const confirm = useConfirmationModal();

  return (channelName: 'whatsapp_meta', cb: () => void) => {
    confirm({
      title: mapChannelConnectCopy[channelName].title,
      description: mapChannelConnectCopy[channelName].description,
      submitText: 'Connect',
      onAccept: (hide) => {
        cb();
        hide();
      },
    });
  };
};

export const useJubelioChannels = (options?: { enabled?: boolean }) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-jubelio-channels', activeCompany],
    () => services.bitCRM.v2.automation.getJubelioChannels(activeCompany),
    {
      meta: { disableToast: true },
      ...options,
    }
  );
};
