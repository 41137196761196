import FormDivider from '../Type/FormDivider/FormDivider';
import FormImage from '../Type/FormImage/FormImage';
import FormLink from '../Type/FormLink/FormLink';
import FormShoppableAds from '../Type/FormShoppableAds';
import FormText from '../Type/FormText/FormText';
import FormWhatsapp from '../Type/FormWhatsapp/FormWhatsapp';
import FormYouTube from '../Type/FormYoutube/FormYouTube';

import { useAppSelector } from '@/hooks/rtk/store';

export default function FormCreate() {
  const form = useAppSelector((state) => state.bitLink.form);
  return (
    <>
      {form?.type === 'link' && <FormLink />}
      {form?.type === 'image' && <FormImage />}
      {form?.type === 'text' && <FormText />}
      {form?.type === 'shop' && <FormShoppableAds />}
      {form?.type === 'divider' && <FormDivider />}
      {form?.type === 'youtube' && <FormYouTube />}
      {form?.type === 'whatsapp' && <FormWhatsapp />}
    </>
  );
}
