import { useCallback } from 'react';

import { useAppDispatch } from '@/hooks/rtk/store';
import { setFocusedChatId, setSearchedMessageId } from '@/stores/bitCRM';

const useClickMessage = () => {
  const dispatch = useAppDispatch();
  const focusMessage = useFocusMessage();

  return useCallback(
    ({ id }: { id: string }) => {
      const parentRef = document.getElementById(`chat-bubble-${id}`);
      if (parentRef) {
        focusMessage({ id });
      } else {
        dispatch(setFocusedChatId(null));
        dispatch(setSearchedMessageId({ id }));
      }
    },
    [dispatch, focusMessage]
  );
};

export default useClickMessage;

export const useFocusMessage = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    ({ id }: { id: string }) => {
      const parentRef = document.getElementById(`chat-bubble-${id}`);

      if (!parentRef) return;

      setTimeout(() => {
        dispatch(setFocusedChatId(id));
      }, 1000);

      setTimeout(() => {
        parentRef.scrollIntoView();
      }, 0);

      setTimeout(() => {
        dispatch(setFocusedChatId(null));
      }, 5000);
    },
    [dispatch]
  );
};
