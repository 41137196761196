import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from '@tanstack/react-query';

import services from '@/api/services';
import { CursoredResponse } from '@/api/types';
import { useAccessToken } from '@/hooks/rtk/selector';
import { RoleTypes } from '@/types/systemLogin';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';

type Params = {
  search?: string;
  role?: RoleTypes[];
};

const useAuthenticatedUserCompanies = (
  params?: Params,
  {
    enabled,
    ...options
  }: Omit<
    UseInfiniteQueryOptions<
      CursoredResponse<UserCompanyWithAssociation[]>,
      unknown,
      CursoredResponse<UserCompanyWithAssociation[]>,
      CursoredResponse<UserCompanyWithAssociation[]>,
      (string | null | { params: Params | undefined })[]
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  > = {}
) => {
  const accessToken = useAccessToken();

  return useInfiniteQuery(
    ['authenticated-user-companies', accessToken, { params }],
    ({ pageParam }) =>
      services.systemLogin.getAuthenticatedUserCompanies({
        after: pageParam,
        search: params?.search,
        role: params?.role?.join(','),
        limit: 10,
      }),
    /* .then((res) => {
          if (res.data.length) {
            // prefetch authenticated company data
            res.data.forEach((userCompany) => {
              queryClient.setQueryData(
                ['authenticated-company', userCompany.companyId],
                userCompany.company
              );
            });
            queryClient.setQueryData(
              ['authenticated-user', accessToken],
              res.data[0].user
            );
          }
          return res;
        }) */ {
      getNextPageParam: (lastPage, allPage) =>
        allPage.flatMap((page) => page.data).length === lastPage.meta.total
          ? false
          : lastPage.meta.endCursor,
      enabled: !!accessToken && enabled,
      ...options,
    }
  );
};

export default useAuthenticatedUserCompanies;
