function BBBIconSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      fill="none"
      viewBox="0 0 19 25"
    >
      <path
        fill="#262627"
        d="M10.9 24.593a6.64 6.64 0 003.847-1.106 7.048 7.048 0 002.492-3.17 12.6 12.6 0 00.875-4.936v-.017a12.562 12.562 0 00-.875-4.944 7.03 7.03 0 00-2.484-3.154 6.642 6.642 0 00-3.84-1.096 5.993 5.993 0 00-3.309.916 5.734 5.734 0 00-2.155 2.517h-.098V.407H.53V24.23h4.822v-3.023h.099a5.701 5.701 0 002.13 2.486 6.016 6.016 0 003.318.9zm-1.603-3.996a3.457 3.457 0 01-2.063-.635 4.124 4.124 0 01-1.383-1.809 7.09 7.09 0 01-.496-2.774v-.016a6.951 6.951 0 01.504-2.766 4.22 4.22 0 011.383-1.8 3.43 3.43 0 012.055-.635 3.49 3.49 0 012.074.627 3.993 3.993 0 011.362 1.792c.34.888.504 1.833.482 2.783v.017a7.31 7.31 0 01-.478 2.773 4.066 4.066 0 01-1.354 1.808 3.435 3.435 0 01-2.086.635z"
      ></path>
      <path
        fill="#FD823E"
        d="M15.526 5.518c1.397 0 2.53-1.07 2.53-2.39s-1.133-2.39-2.53-2.39c-1.398 0-2.531 1.07-2.531 2.39s1.133 2.39 2.53 2.39z"
      ></path>
    </svg>
  );
}

export default BBBIconSmall;
