import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/ui';
import colors from '@/constants/common/colors';
import useResponsive from '@/hooks/common/useResponsive';

type Option = {
  label: string;
  value: string;
  parentValue?: string;
};

type Props = {
  selected: Option['value'] | undefined;
  onChange?: (val: Option['value']) => void;
  title?: string;
  link?: (val: Option['value']) => string;
  options: Option[];
};

export default function BBBNavigationCard({
  selected,
  onChange,
  options,
  title,
  link,
}: Props) {
  const isMobile = useResponsive('sm');
  const Comp = typeof link !== 'undefined' ? Link : 'div';

  const groupedOptions = options.reduce<Record<string, Option[]>>(
    (acc, option) => {
      if (option.parentValue) {
        if (!acc[option.parentValue]) acc[option.parentValue] = [];
        acc[option.parentValue].push(option);
      } else {
        if (!acc['ungrouped']) acc['ungrouped'] = [];
        acc['ungrouped'].push(option);
      }
      return acc;
    },
    {}
  );

  return (
    <BBBCard title={title} className="flex-none lg:w-[19.125rem] w-full">
      {Object.entries(groupedOptions).map(([parentValue, options]) => (
        <div key={parentValue} className="p-1 mb-4">
          {parentValue !== 'ungrouped' && (
            <p className="font-bold text-[#5A5A5A] mb-2">{parentValue}</p>
          )}
          {options.map((setting) => (
            // @ts-ignore
            <Comp
              key={setting.value}
              className={cx(
                'p-2 rounded-lg cursor-pointer font-normal mb-1 hover:bg-neutral-20 text-neutral-50 hover:text-neutral-70  last:mb-0 ',
                selected === setting.value &&
                  'text-neutral-70 bg-secondary-surface',
                isMobile && 'w-full justify-between',
                typeof link !== 'undefined' && 'lg:block flex'
              )}
              onClick={() => onChange?.(setting.value)}
              {...(typeof link !== 'undefined' && {
                to: link(setting.value),
              })}
            >
              {setting.label}
              {isMobile && <ChevronRight color={colors.grayColor2} />}
            </Comp>
          ))}
        </div>
      ))}
    </BBBCard>
  );
}
