import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from 'api/types';
import api from 'config/api';
import { useActiveCompany } from 'hooks/rtk/selector';
import { Onboarding as OldOnboarding } from 'types/bitApp/v2';
import { OnboardingModule } from 'types/systemLogin';
import { useWhatsappCloudIntegration } from '../bitChat/integration/integrations';
import { useCheckNewAutomation } from '../bitCRM/automation/automation-new';
import { useHasNewTemplate } from '../bitCRM/template/template';
import { useSegments } from '../customers/segment';
import useShopifyIntegrationByApp from '../shopify/useShopifyIntegrationByApp';
import { useOnboardingMeta } from './onboarding';

export const useOnboarding = () => {
  const { data: onboardingData } = useOnboardingMeta('BITCRM');

  const onboardingDataObj = onboardingData
    ? (Object.fromEntries(
        onboardingData.details.map((detail) => [detail.module, detail.skipped])
      ) as Record<OnboardingModule, boolean> | undefined)
    : undefined;

  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITCRM');
  const { data: whatsappCloudIntegration } = useWhatsappCloudIntegration();
  const { data: segmentsData } = useSegments();
  const { data: hasTemplates } = useHasNewTemplate();
  const { data: checkNewAutomation } = useCheckNewAutomation();

  const hasShopifyIntegrations = !!shopifyIntegration;
  const hasWhatsappCloudIntegrations = !!whatsappCloudIntegration;
  const segmentCount =
    segmentsData?.pages.length && segmentsData.pages[0].data.length
      ? segmentsData.pages[0].data[0].count
      : undefined;

  const hasSegment = !!(segmentCount && segmentCount > 0);

  const keyPoints = [
    {
      label: 'Boost sales with Shopify integration',
      value: 'shopify_integration',
      completed: hasShopifyIntegrations,
      skipped: onboardingDataObj?.shopify_integration,
    },
    {
      label: 'Reach more customers with WhatsApp API',
      value: 'whatsapp_api',
      completed: hasWhatsappCloudIntegrations,
    },
    {
      label: 'Personalize you message',
      value: 'personalize_message',
      completed: hasTemplates,
      skipped: onboardingDataObj?.personalize_message,
    },
    {
      label: 'Recover lost sales effortlessly',
      value: 'recover_sales',
      completed:
        checkNewAutomation?.hasAbandonedTriggers &&
        checkNewAutomation.hasAbandonedTriggersTurnedOn,
      skipped:
        (onboardingDataObj?.recover_sales ||
          checkNewAutomation?.hasAbandonedTriggers) &&
        (onboardingDataObj?.abandoned_cart ||
          checkNewAutomation?.hasAbandonedTriggersTurnedOn),
      meta: {
        completedRecoverSales: checkNewAutomation?.hasAbandonedTriggers,
        completedAbandonedCart:
          checkNewAutomation?.hasAbandonedTriggersTurnedOn,
        skippedRecoverSales: onboardingDataObj?.recover_sales,
        skippedAbandonedCart: onboardingDataObj?.abandoned_cart,
      },
    },
    {
      label: 'Target the right audience effectively',
      value: 'target_audience',
      completed: hasSegment,
      skipped: onboardingDataObj?.target_audience,
    },
  ];

  return { keyPoints, dismissed: onboardingData?.dismissed };
};

export const useOnboardingCompletion = () => {
  const { keyPoints, dismissed } = useOnboarding();

  const completionPercentage = useMemo(() => {
    const completedCount = keyPoints.filter(
      (point) => point.completed || point.skipped
    ).length;
    const totalCount = keyPoints.length;
    return totalCount > 0 ? (completedCount / totalCount) * 100 : 0;
  }, [keyPoints]);

  return { progress: completionPercentage, dismissed };
};

export const useOldOnboarding = (
  moduleKey: string,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['onboarding-bitcrm', activeCompany, moduleKey],
    async () => {
      const {
        data: { data },
      } = await api.bitCRM.v2.get<ApiResponse<OldOnboarding | null>>(
        `/onboarding/${activeCompany}/${moduleKey}`
      );
      return data;
    },
    options
  );
};

export const useUpdateOldOnboarding = () => {
  const queryClient = useQueryClient();
  const activeCompany = useActiveCompany();

  return useMutation(
    async ({ moduleKey, ...payload }: Partial<OldOnboarding>) => {
      const {
        data: { data: responseData },
      } = await api.bitCRM.v2.post(
        `/onboarding/${activeCompany}/${moduleKey}`,
        payload
      );

      return responseData;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['onboarding-bitcrm']);
      },
    }
  );
};
