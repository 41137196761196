import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { CampaignStatus } from '@/types/bitCRM/campaign';
import { Accent } from '@/types/utils/color';

type Props = {
  status: CampaignStatus;
};

export const mapStatusToText = {
  SENT: 'Sent',
  DRAFT: 'Draft',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  SCHEDULED: 'Scheduled',
  PAUSED: 'Paused',
  SENDING: 'Sending',
  PREPARING: 'Preparing',
};

export const mapStatusToBadge: Record<string, Accent> = {
  SENT: 'success',
  DRAFT: 'neutral',
  CANCELLED: 'danger',
  ERROR: 'danger',
  SCHEDULED: 'info',
  PAUSED: 'neutral',
  SENDING: 'neutral',
  PREPARING: 'neutral',
};

function StatusBadge({ status }: Props) {
  return (
    <BBBBadge
      className="px-2 py-1 text-sm w-fit"
      type={mapStatusToBadge[status as keyof typeof mapStatusToBadge]}
      text={mapStatusToText[status as keyof typeof mapStatusToText] || status}
    />
  );
}

export default StatusBadge;
