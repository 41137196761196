import BBBLogo from '@/assets/icons/BBBLogo';

function NetworkError() {
  return (
    <div className="max-w-md mx-auto px-4 md:px-0 flex min-h-full flex-col justify-center items-center">
      <BBBLogo className="object-contain mb-16" />
      <div className="text-2xl font-medium mb-4">Whoops...</div>
      <div className="text-center mb-4">
        No network connection found. Please check your internet settings and try
        again.
      </div>
    </div>
  );
}

export default NetworkError;
