import { useMemo, useState } from 'react';
import CryptoJS from 'crypto-js';
import ChooseCompany from './components/ChooseCompany';
import ShopifyOauthLoginForm from './components/LoginForm';

import { env } from '@/config/env';
import useAuthenticatedUser from '@/hooks/auth/useAuthenticatedUser';
import { useAccessToken } from '@/hooks/rtk/selector';
import GeneralLayout from '@/layouts/GeneralLayout';
import { AppType } from '@/types/systemLogin';

export default function ShopifyOauthConnect() {
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const code = urlParams.get('bitbybit-code');

  if (!code) throw new Error('Invalid params');

  const decryptedCode: {
    domain: string;
    app: AppType;
  } = JSON.parse(
    CryptoJS.AES.decrypt(
      decodeURIComponent(code),
      env.REACT_APP_SHOPIFY_CONNECT_SECRETKEY
    ).toString(CryptoJS.enc.Utf8)
  );

  return <_ShopifyOauthConnect decryptedCode={decryptedCode} />;
}

function _ShopifyOauthConnect({
  decryptedCode,
}: {
  decryptedCode: {
    domain: string;
    app: AppType;
  };
}) {
  const accessToken = useAccessToken();

  return (
    <GeneralLayout>
      {accessToken ? (
        <Authenticated
          domain={decryptedCode.domain}
          app={decryptedCode.app}
          accessToken={accessToken}
        />
      ) : (
        <ShopifyOauthLoginForm
          app={decryptedCode.app}
          companyDomain={decryptedCode.domain}
        />
      )}
    </GeneralLayout>
  );
}

function Authenticated({
  domain,
  app,
  accessToken,
}: {
  domain: string;
  app: AppType;
  accessToken: string;
}) {
  const [loggedInAction, setLoggedInAction] = useState<
    'relogin' | 'change-company'
  >();

  return (
    <>
      {loggedInAction === 'relogin' ? (
        <ShopifyOauthLoginForm app={app} companyDomain={domain} />
      ) : (
        <_ChooseCompany
          domain={domain}
          app={app}
          onChangeAction={setLoggedInAction}
          accessToken={accessToken}
        />
      )}
    </>
  );
}

function _ChooseCompany({
  domain,
  app,
  onChangeAction,
  accessToken,
}: {
  domain: string;
  app: AppType;
  onChangeAction: (val: 'relogin' | 'change-company' | undefined) => void;
  accessToken: string;
}) {
  const { data, status } = useAuthenticatedUser();

  if (status !== 'success') return null;

  return (
    <ChooseCompany
      id={data.id}
      showBack
      onBack={() => onChangeAction('relogin')}
      companyDomain={domain}
      app={app}
      authenticatedAccessToken={accessToken}
    />
  );
}
