import { useEffect, useState } from 'react';
import { socketBitLogin } from 'socket';
import { WhatsappLogin } from 'types/bitLogin/v2';
import { useLoginStatus } from './whatsapp';

export const useVerifyStatusLogin = (code: string, domain: string) => {
  const { data: loginStatus } = useLoginStatus(code, domain);

  const [verifyStatus, setVerifyStatus] = useState<WhatsappLogin>();

  useEffect(() => {
    setVerifyStatus(loginStatus);
  }, [loginStatus]);

  useEffect(() => {
    socketBitLogin.emit('join-auth-whatsapp', {
      id: code,
    });
  }, [code]);

  useEffect(() => {
    socketBitLogin.on('login-whatsapp', setVerifyStatus);

    return () => {
      socketBitLogin.off('login-whatsapp', setVerifyStatus);
    };
  }, []);

  return verifyStatus;
};
