import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useTicketPerformance = (
  params: Parameters<typeof services.whatsApp.analytics.getTicketPerfomance>[1]
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(['ticket-performance', activeCompany, params], () =>
    services.whatsApp.analytics.getTicketPerfomance(activeCompany!, params)
  );
};

export default useTicketPerformance;
