import { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { twMerge as cx } from 'tailwind-merge';
import HeaderMessagePanel from './HeaderMessagePanel/HeaderMessagePanel';
import AttachmentModal from './AttachmentModal';
import ChatAction from './ChatAction';
import ChatBoard from './ChatBoard';

import { useUploadFiles } from '@/hooks/common/upload';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';

type MessagePanelContentProps = {
  expandedBoard?: boolean;
  onEventChange?: (val: 'back' | 'customer-click') => void;
  className?: string;
};

export const acceptedFiles = '*';
export const acceptedMedias = 'image/jpeg, image/png, video/mp4, video/3gpp';

export default function MessagePanelContent(props: MessagePanelContentProps) {
  const hasSelectedChat = useAppSelector((s) => !!s.bitCRM.selectedChat);

  if (!hasSelectedChat) return null;

  return <_MessagePanel {...props} />;
}

function _MessagePanel({
  expandedBoard,
  onEventChange,
  className,
}: MessagePanelContentProps) {
  const uploadFiles = useUploadFiles();
  const userId = useUserId();
  const notActive = useAppSelector(
    (s) => s.bitCRM.selectedChat!.ticket?.userId !== userId
  );

  const [coPilotQuickActionRef, setCoPilotQuickActionRef] =
    useState<HTMLDivElement | null>();

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: async (files) => {
        await uploadFiles(files);
      },
      noClick: true,
      disabled: notActive,
      accept: Object.fromEntries(
        acceptedFiles.split(',').map((data) => [data, []])
      ) as Accept,
    });

  return (
    <div
      className={cx(`flex-none relative transition-all`, className)}
      id="message-panel"
    >
      <div className="flex shadow flex-col h-full relative" {...getRootProps()}>
        {isDragActive && (
          <div
            className={cx(
              'absolute inset-0 bg-neutral-60/75 z-30 text-neutral-10 text-2xl flex justify-center items-center text-center'
            )}
          >
            {isDragReject
              ? `Restricted file type`
              : 'Drop image or file here to attach'}
          </div>
        )}
        <input {...getInputProps()} />
        <HeaderMessagePanel
          onEventChange={onEventChange}
          expandedBoard={expandedBoard}
        />
        <div className="relative grow flex flex-col">
          <AttachmentModal />
          <ChatBoard coPilotQuickActionRef={coPilotQuickActionRef} />
          <ChatAction setCoPilotQuickActionRef={setCoPilotQuickActionRef} />
        </div>
      </div>
    </div>
  );
}
