import { Handle, NodeProps, Position } from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import { useDeleteState } from '../../hooks';
import useStore from '../../store';
import { MessageData } from '../../types';
import TrashWithTransition from '../TrashWithTransition';
import Connector from './Connector';
import CreateConnection from './CreateConnection';

import { BBBCard, BBBRenderHtml } from '@/components/ui';

export default function MessageNode({ data, id }: NodeProps<MessageData>) {
  const childLength = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === id).length
  );
  const deleteNode = useDeleteState();
  const onChangeStateModal = useStore((s) => s.onChangeStateModal);
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const hasTarget = useStore((state) =>
    state.edges.some((edge) => edge.target === id)
  );
  const isHeld = useStore((s) => s.connectingNode === id);

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);

  return (
    <BBBCard
      title="Message"
      className={cx(
        'w-96 group relative transition-colors',
        expandState === 'hover' && 'border-secondary-main',
        expandState === 'clicked' && 'border-secondary-main',
        isHeld && 'border-secondary-main'
      )}
      id={`message-flow-${id}`}
      onClick={() => {
        onChangeStateModal({ nodeId: id, type: 'message' });
      }}
      rightButton={
        <TrashWithTransition
          onClick={(e) => {
            deleteNode(id);
            e.stopPropagation();
          }}
        />
      }
      onMouseEnter={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, 'hover');
        }
      }}
      onMouseLeave={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, null);
        }
      }}
    >
      {!childLength && (
        <>
          <Connector id={id} />
          <CreateConnection
            onAddState={(state) => {
              onChangeStateModal({
                sourceId: id,
                type: state,
              });
            }}
            nodeId={id}
            state={expandState}
            onChangeState={(state) => setExpandState(id, state)}
          />
        </>
      )}
      <div className="rounded-lg bg-neutral-20 p-2 text-neutral-50">
        <BBBRenderHtml
          className="line-clamp-5 break-words"
          content={data.message}
        />
      </div>
      {data.fallback?.name && (
        <div className="mt-4 text-neutral-50">
          Fallback: {data.fallback?.name}
        </div>
      )}
      {!!childLength && (
        <div
          style={{ height: childLength * 64 }}
          className="relative mt-4"
          id={`child1-slot-${id}`}
        />
      )}
      <Handle
        type="target"
        position={Position.Left}
        className={!hasTarget ? 'opacity-0 pointer-events-none' : ''}
      />
      <Handle
        type="source"
        position={Position.Right}
        className={!hasEdges ? 'opacity-0 pointer-events-none' : ''}
      />
    </BBBCard>
  );
}
