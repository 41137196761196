import { shallowEqual } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash-es/cloneDeep';
import { useUpdateChats } from './chat';
import useActiveChatMemo, { selectedReplySelector } from './useActiveChatMemo';
import useChatMessagesQueryKey from './useChatMessagesQueryKey';

import services from '@/api/services';
import { SendMessageParams } from '@/api/services/whatsApp/chat';
import { useActiveCompany, useApiKey, useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import useChatListsQueryKey from '@/hooks/whatsApp/useChatListsQueryKey';
import { ChatMessagesType } from '@/hooks/whatsApp/useChatMessages';
import {
  setSearchChat,
  setSearchedMessageId,
  upsertLivechatMemo,
} from '@/stores/bitCRM';
import { MediaProcessed } from '@/types/whatsApp/chat';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { scrollToBottom } from '@/utils/bitChat';
import {
  convertEditorStateToHtml,
  emptyEditor,
  formatSelectionToEnd,
} from '@/utils/common/rich';

type SendMessagePayload =
  | ({
      type: 'chat';
    } & Pick<SendMessageParams, 'media' | 'form' | 'temporaryId' | 'message'>)
  | {
      type: 'media';
    };

const useSendMessage = () => {
  const queryClient = useQueryClient();
  const { clientNumber, sources, connectedNumber, clientName, remoteJid } =
    useAppSelector((s) => {
      const selectedChat = s.bitCRM.selectedChat!;

      return {
        clientNumber: selectedChat.clientNumber,
        sources: selectedChat.sources,
        clientName: selectedChat.clientName,
        connectedNumber: selectedChat.connectedNumber,
        remoteJid: selectedChat.remoteJid,
      };
    }, shallowEqual);

  const isSearch = useAppSelector(
    (state) => !!state.bitCRM.selectedChat!.messageId
  );

  const userId = useUserId();

  const apiKey = useApiKey();

  const activeCompany = useActiveCompany();
  const activeSelectedReply = useAppSelector(selectedReplySelector);
  const chatsQueryKey = useChatListsQueryKey();

  const messagesQueryKey = useChatMessagesQueryKey();
  const updateChats = useUpdateChats();

  const dispatch = useAppDispatch();

  const activeChat = useActiveChatMemo();

  return useMutation(
    async (payload: SendMessagePayload) => {
      const message = await Promise.all([
        ...(payload.type === 'chat'
          ? [
              services.whatsApp.chat.sendMessage({
                key: apiKey,
                number: clientNumber,
                message: payload.message,
                messageId: activeSelectedReply?.messageId,
                userId,
                hasMedia: !!payload.media,
                media: payload.media,
                temporaryId: payload.temporaryId,
                sources: sources,
                form: payload.form,
              }),
            ]
          : [...(activeChat?.mediaUrl ?? [])].reverse().map((_data) => {
              const data = _data as MediaProcessed;

              return services.whatsApp.chat.sendMedia({
                key: apiKey,
                number: clientNumber,
                sources: sources,
                message: convertEditorStateToHtml(data.message) || '',
                messageId: activeSelectedReply?.messageId,
                companyId: activeCompany,
                temporaryId: data.id,
                imageUrl: data.value,
                mimeType: data.meta?.type,
              });
            })),
      ]);
      return message;
    },
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(messagesQueryKey);
        await queryClient.cancelQueries(chatsQueryKey);

        const previousMessages =
          queryClient.getQueryData<ChatMessagesType>(messagesQueryKey);

        const previousChats =
          queryClient.getQueryData<ChatMessagesType>(chatsQueryKey);

        const temporaryId: string | string[] =
          payload.type === 'chat'
            ? payload.temporaryId
            : (activeChat?.mediaUrl ?? []).map((media) => media.id);

        updateChats(
          {
            lastMessageTimestamp: new Date().toISOString(),
            lastMessage:
              payload.type === 'chat'
                ? payload.message || ''
                : convertEditorStateToHtml(
                    (
                      activeChat?.mediaUrl?.[
                        activeChat.mediaUrl.length - 1
                      ] as MediaProcessed
                    ).message
                  ) || '',
            unreadMessages: 0,
            __typename: 'updateChatsPartial',
            sources: sources,
            clientNumber: clientNumber,
          },
          { shift: true }
        );

        if (payload.type === 'chat' && payload.form) return;

        /**
         * Perform optimistic update on current active chat messages
         */

        queryClient.setQueryData<ChatMessagesType>(messagesQueryKey, (old) => {
          if (!old) return;

          const previousPages = cloneDeep(old.pages);
          const date = new Date().toISOString();

          const quoted: Pick<
            MessageNewAssociation,
            'parent' | 'parentCompanyId' | 'parentId' | 'parentConnectedNumber'
          > = {
            parent: activeSelectedReply || null,
            parentCompanyId: activeSelectedReply?.companyId || null,
            parentId: activeSelectedReply?.messageId || null,
            parentConnectedNumber: activeSelectedReply?.connectedNumber || null,
          };

          const messageToAdd: MessageNewAssociation[] =
            payload.type === 'chat'
              ? [
                  {
                    ack: 0,
                    fromMe: true,
                    clientNumber,
                    sources,
                    clientName,
                    connectedNumber,
                    remoteJid,
                    messageId: temporaryId as string,
                    messageId2: temporaryId as string,
                    companyId: activeCompany,
                    type: payload.media ? 'IMAGE' : 'TEXT',
                    body: payload.message || '',
                    timestamp: date,
                    media: payload.media || null,
                    isStarred: false,
                    clientAccent: '#000000',
                    mediaMimeType: null,
                    participantJid: null,
                    agentActive: null,
                    agentActiveName: null,
                    agentSource: null,
                    agentSourceName: null,
                    summarySentiment: null,
                    formId: null,
                    formResponseMsg: null,
                    notes: null,
                    ticketId: null,
                    pollMsg: null,
                    contactMsg: null,
                    orderMsg: null,
                    buttonMsg: [],
                    productMsg: null,
                    locationMsg: null,
                    reactionMsg: [],
                    child: [],
                    mentions: [],
                    ...quoted,
                  },
                ]
              : [...(activeChat?.mediaUrl ?? [])]
                  .reverse()
                  .map((_media, index) => {
                    const media = _media as MediaProcessed;
                    const messageId = (temporaryId as string[])[index];

                    return {
                      ack: 0,
                      fromMe: true,
                      clientNumber,
                      sources,
                      clientName,
                      connectedNumber,
                      remoteJid,
                      messageId,
                      messageId2: messageId,
                      companyId: activeCompany,
                      type: media.type.startsWith('image')
                        ? 'IMAGE'
                        : 'DOCUMENT',
                      body: convertEditorStateToHtml(media.message) || '',
                      timestamp: date,
                      media: media.value,
                      isStarred: false,
                      clientAccent: '#000000',
                      mediaMimeType: null,
                      participantJid: null,
                      agentActive: null,
                      agentActiveName: null,
                      agentSource: null,
                      agentSourceName: null,
                      summarySentiment: null,
                      formId: null,
                      formResponseMsg: null,
                      notes: null,
                      ticketId: null,
                      pollMsg: null,
                      contactMsg: null,
                      orderMsg: null,
                      buttonMsg: [],
                      productMsg: null,
                      locationMsg: null,
                      reactionMsg: [],
                      child: [],
                      mentions: [],
                      ...(index === 0
                        ? quoted
                        : {
                            parent: null,
                            parentCompanyId: null,
                            parentConnectedNumber: null,
                            parentId: null,
                          }),
                    };
                  });

          if (previousPages.length) {
            previousPages[0].response.unshift(...messageToAdd);
          } else {
            previousPages.push({
              response: messageToAdd,
              meta: {},
            });
          }

          return {
            pages: previousPages,
            pageParams: old.pageParams,
          };
        });

        dispatch(
          upsertLivechatMemo({
            selectedReply: null,
            messageToSend: formatSelectionToEnd(emptyEditor),
            recommendedProduct: null,
            selectedMedia: null,
            mediaUrl: [],
          })
        );

        dispatch(setSearchChat(undefined));

        if (!isSearch) {
          scrollToBottom();
        }

        return {
          previousMessages,
          previousChats,
        };
      },
      onSuccess: () => {
        if (isSearch) {
          dispatch(setSearchedMessageId(undefined));
        }
      },
      onError: (err, payload, context) => {
        queryClient.setQueryData<ChatMessagesType>(
          messagesQueryKey,
          context?.previousMessages
        );
        queryClient.setQueryData(chatsQueryKey, context?.previousChats);
      },
    }
  );
};

export default useSendMessage;
