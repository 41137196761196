import api from 'config/api';
import { CollectionV2 } from 'types/bitAi/sorting/collections';

export default {
  getSortingCollections: (
    shopGid: string,
    {
      size,
      search,
      after,
      before,
      managed_type,
      sort_by,
      sort_direction,
    }: GetSortingCollectionsParams
  ) =>
    api.bitAi.v2
      .get<GetSortingCollectionsPayload>('/sorting/collections', {
        params: {
          shop: shopGid,
          size,
          search,
          after,
          before,
          managed_type,
          sort_by,
          sort_direction,
        },
      })
      .then((res) => res.data),
};

export type GetSortingCollectionsParams = {
  size?: number;
  search?: string;
  after?: string;
  before?: string;
  managed_type?: string;
  sort_by?: string;
  sort_direction?: string;
};

export type GetSortingCollectionsPayload = {
  collections: CollectionV2[];
  start_cursor: string;
  end_cursor: string;
  shop_detail: {
    id: string;
    name: string;
  };
};
