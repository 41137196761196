import LoginCard from '@/components/Auth/LoginCard';
import useLogin, { LoginPayload } from '@/hooks/auth/useLogin';

const Login = () => {
  const { mutate: login, isLoading: loading } = useLogin();

  const onSubmit = (data: LoginPayload) => login(data);

  return <LoginCard isLoginPage loadingSubmit={loading} onSubmit={onSubmit} />;
};

export default Login;
