import { useCallback, useMemo, useState } from 'react';
import { Plus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';
import { createId } from '@paralleldrive/cuid2';
import { segmentQueryBuilder } from 'utils/customers';
import useColumn from './hooks/useColumn';
import useColumnMobile from './hooks/useColumnMobile';
import TagOptions from './Segmentation/TagOptions';
import AddBulkCustomerTagModal from './AddBulkCustomerTagModal';
import AddCustomerModal from './AddCustomerModal';
import Segmentation from './Segmentation';

import { BBBButton, BBBContainer, BBBSelect } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { Link } from '@/components/ui/Link';
import {
  Block,
  Condition,
  operators,
  sourceOptions,
} from '@/constants/customers';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useHistory from '@/hooks/common/useHistory';
import useResponsive from '@/hooks/common/useResponsive';
import useActionCustomers from '@/hooks/customers/customer/useActionCustomers';
import useBulkUploadRealtimeUpdate from '@/hooks/customers/customer/useBulkUploadRealtimeUpdate';
import useCustomers from '@/hooks/customers/customer/useCustomers';
import {
  useDeleteSegment,
  useSegment,
  useSegments,
} from '@/hooks/customers/segment';
import DesktopOptimized from '@/layouts/DesktopOptimized';
import { Segments, Tags } from '@/types/customers';
import { CustomerWithAssociation } from '@/types/customers/association';

const defaultConjunctionId = createId();
const defaultBlockId = createId();
const defaultConditionId = createId();

const defaultConjunctionId2 = createId();
const defaultBlockId2 = createId();

export const defaultConditions: Condition[] = [
  {
    conjunction: 'AND',
    id: defaultConjunctionId,
  },
  {
    conjunction: 'OR',
    id: defaultConjunctionId2,
    parentId: defaultConjunctionId,
    blockId: defaultBlockId,
  },
  {
    key: null,
    operator: operators.find((opt) => opt.value === 'equals')!,
    id: defaultConditionId,
    value: null,
    parentId: defaultConjunctionId2,
    blockId: defaultBlockId2,
  },
];

export const defaultBlocks: Block[] = [
  {
    conjunctionId: defaultConjunctionId,
    id: defaultBlockId,
  },
  {
    conjunctionId: defaultConjunctionId2,
    id: defaultBlockId2,
  },
];

const allOption = {
  name: 'All',
} as Segments;

const allOptions = [allOption];

export default function Customers() {
  const { segmentId } = useParams<{ segmentId: string }>();

  const isMobile = useResponsive('sm');

  const [conditions, setConditions] = useState<Condition[]>(defaultConditions);
  const [blocks, setBlocks] = useState<Block[]>(defaultBlocks);
  const [name, setName] = useState('');
  const [id, setId] = useState<string>();
  const [initialLoadComplete, setIsInitialLoadComplete] = useState(
    segmentId === 'new' ? true : false
  );

  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [cursor, setCursor] = useState<{
    after?: string;
    before?: string;
  }>();
  const [tags, setTags] = useState<Tags[] | null>(null);
  const [source, setSource] = useState<typeof sourceOptions[number] | null>(
    null
  );
  const [selected, setSelected] = useState<CustomerWithAssociation[]>([]);
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [searchSegment, setSearchSegment] = useState<string | undefined>('');
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);

  const {
    data: segmentsData,
    hasNextPage: hasNextSegments,
    fetchNextPage: fetchNextSegments,
    isLoading: loadingSegments,
    isFetchingNextPage,
  } = useSegments({ search: searchSegment });

  const query = useQuerySearchParams();

  const history = useHistory();
  const { search: searchParams } = useLocation();

  const selectedSegment = query.get('segment');

  const enabledSegmentQuery =
    (segmentId && segmentId !== 'new') || !!selectedSegment;

  const { data: segmentData, isLoading: loadingSegment } = useSegment(
    segmentId || selectedSegment!,
    {
      enabled: enabledSegmentQuery,
    }
  );

  const deleteSegment = useDeleteSegment();

  const flattenedSegments = [
    ...allOptions,
    ...(segmentsData?.pages.flatMap((page) =>
      page.data.map((segment) => segment.data)
    ) || []),
  ];

  const segmentQuery = segmentQueryBuilder(conditions, blocks);

  const { data: customersData, isInitialLoading: loadingCustomers } =
    useCustomers({
      limit: pageSize,
      ...cursor,
      search,
      segment: !segmentId
        ? segmentData?.data.name ??
          (!selectedSegment ? undefined : selectedSegment)
        : undefined,
      tags: tags?.map((tag) => tag.name).join(','),
      source: source?.value,
      query: segmentId ? JSON.stringify(segmentQuery) : undefined,
      sort,
    });

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const bulkAction = useActionCustomers({
    onSuccess: () => {
      setIsAllRowsSelected(false);
      setSelected([]);
    },
    onChangeLoadDelete: setLoadingDelete,
    onChangeLoadExport: setLoadingExport,
  });

  const defaultColumns = useColumn();
  const mobileColumns = useColumnMobile();

  const flattenedCustomers = customersData?.data;
  const customersMeta = customersData?.meta;

  useBulkUploadRealtimeUpdate();

  const actualSelected = isAllRowsSelected
    ? customersMeta?.total
      ? customersMeta.total - selected.length
      : 0
    : selected.length;

  const bulkParams = useMemo(
    () => ({
      selected: selected.map((row) => row.id),
      isAllRowsSelected,
      count: actualSelected,
    }),
    [actualSelected, isAllRowsSelected, selected]
  );

  const handleBulkDelete = useCallback(
    () =>
      bulkAction('delete', bulkParams, {
        onSuccess: () => {
          setSelected([]);
        },
      }),
    [bulkParams, bulkAction]
  );

  const handleBulkExport = useCallback(() => {
    return bulkAction('export', bulkParams, {
      onSuccess: () => {
        setSelected([]);
      },
    });
  }, [bulkParams, bulkAction]);

  if (isMobile && segmentId) {
    return (
      <DesktopOptimized
        backLink={`/customers${searchParams}`}
        description="Segmentation is more optimized in desktop website. Switch over to be able to set up your segmentation."
      />
    );
  }

  return (
    <BBBContainer
      hasHeading
      pageTitle={
        segmentId !== 'new'
          ? !segmentId
            ? 'Customers'
            : segmentData?.data.name
          : undefined
      }
      pageDescription="Automate your message by creating a chatbot"
      {...(!segmentId
        ? {
            rightComponent: (
              <div className="flex gap-2 items-center">
                <BBBButton
                  onClick={() => setShowAdd(true)}
                  icon={<Plus size={20} />}
                  iconPosition="right"
                  text="Add New Customer"
                />
              </div>
            ),
          }
        : {
            hasBack: true,
            backUrl: `/customers${searchParams}`,
            pageTitle: initialLoadComplete ? (
              `${id !== undefined ? 'Edit' : 'Create'} segmentation`
            ) : (
              <Skeleton borderRadius={'0.25rem'} width="16rem" />
            ),
            pageDescription:
              'Group your customers to certain segmentation for better campaign target',
          })}
    >
      {segmentId && (
        <Segmentation
          segmentId={segmentId === 'new' ? undefined : segmentId}
          conditions={conditions}
          blocks={blocks}
          id={id}
          name={name}
          setBlocks={setBlocks}
          setConditions={setConditions}
          setId={setId}
          setName={setName}
          initialLoadComplete={initialLoadComplete}
          setIsInitialLoadComplete={setIsInitialLoadComplete}
        />
      )}

      <BBBTableV2
        data={/* !enabled ? defaultTableData : */ flattenedCustomers}
        loadingBody={loadingCustomers}
        headers={isMobile ? mobileColumns : defaultColumns}
        dataId="id"
        isPaginate
        pagination={{
          hasNext: customersData?.meta.hasNextPage,
          hasPrev: customersData?.meta.hasPreviousPage,
          onNext: () => setCursor({ after: customersData?.meta.endCursor }),
          onPrev: () => setCursor({ before: customersData?.meta.startCursor }),
        }}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(number) => {
          setPageSize(number!);
          setCursor(undefined);
        }}
        renderEmptyMessage={() => 'No customer found'}
        isSearchable
        searchPlaceholder="Search on customers"
        searchValue={search}
        onChangeSearch={setSearch}
        isSelectable={!segmentId && !isMobile}
        onChangeSelectable={setSelected}
        selected={selected}
        isAllRowSelected={isAllRowsSelected}
        onChangeAllRowSelected={setIsAllRowsSelected}
        withoutActionOption
        withoutHeader={isMobile}
        subHeaderComponent={
          !segmentId && (
            <div className="flex md:flex-row flex-col justify-between gap-2 md:items-center mb-3.5 mt-2 mx-2">
              {enabledSegmentQuery && loadingSegment ? (
                <Skeleton height={20} width={320} />
              ) : (
                <div className="grow flex items-center gap-2">
                  Showing{' '}
                  {loadingSegments ? (
                    <Skeleton height={16} width={36} />
                  ) : (
                    `${(customersMeta?.total ?? 0).toLocaleString()}`
                  )}{' '}
                  results from{' '}
                  <span>
                    <BBBSelect
                      selectedDropdownClassName="border-none shadow-none p-0"
                      options={flattenedSegments}
                      optionLabel="name"
                      optionValue="name"
                      menuContainerClassName="w-32"
                      activeInputClassName="flex-none"
                      activeLabelClassName="underline font-bold"
                      isPaginated
                      hasMore={!!hasNextSegments}
                      fetchNext={fetchNextSegments}
                      value={segmentData?.data || allOption}
                      onValueChange={(opt) =>
                        history.push({
                          pathname: '/customers',
                          search:
                            opt?.name === 'All'
                              ? undefined
                              : `?segment=${encodeURIComponent(opt!.name)}`,
                        })
                      }
                      loading={loadingSegments}
                      isFetchingNextPage={isFetchingNextPage}
                      isSearchable
                      searchOnOptions
                      search={searchSegment}
                      onChangeSearch={setSearchSegment}
                      searchPlaceholder="Search segment"
                    />
                  </span>
                </div>
              )}

              {!segmentId && (
                <>
                  {actualSelected ? (
                    <>
                      <BBBButton
                        variant="danger-outline"
                        text={`${
                          loadingDelete ? `Deleting` : `Delete`
                        } (${actualSelected.toLocaleString()})`}
                        onClick={() => handleBulkDelete()}
                        loadingState={loadingDelete}
                      />
                      <BBBButton
                        variant="secondary"
                        text={`${
                          loadingExport ? 'Downloading' : 'Download'
                        } customer (${actualSelected.toLocaleString()})`}
                        onClick={() => handleBulkExport()}
                        loadingState={loadingExport}
                      />
                      <BBBButton
                        variant="secondary"
                        text={`Add customer Tag (${actualSelected.toLocaleString()})`}
                        onClick={() => setShowAddTag(!showAddTag)}
                      />
                    </>
                  ) : (
                    !(enabledSegmentQuery && loadingSegment) &&
                    (!selectedSegment ||
                      (selectedSegment && segmentData?.data)) && (
                      <Link
                        to={`/customers/segment/${
                          !selectedSegment ? 'new' : segmentData!.data.id
                        }${searchParams}`}
                      >
                        <BBBButton
                          variant="secondary"
                          text={`${
                            !selectedSegment ? 'Create new' : 'Edit'
                          } segmentation`}
                        />
                      </Link>
                    )
                  )}
                </>
              )}
            </div>
          )
        }
        {...(!segmentId
          ? {
              isFilterable: true,
              renderFilterSection: () => (
                <>
                  <BBBSelect
                    placeholder="Source"
                    options={sourceOptions}
                    optionLabel="label"
                    optionValue="value"
                    containerClassName="md:w-36"
                    value={source}
                    onValueChange={(opt) => setSource(opt ?? null)}
                    isClearable
                  />
                  <TagOptions
                    value={tags}
                    onValueChange={(opt) => setTags(opt ?? null)}
                    isMulti
                    isSearchable
                    placeholder="Tags"
                    renderCustomSelectedValues={(values) => (
                      <>{values?.length} Tags</>
                    )}
                    withClearableOption
                    searchOnOptions
                    persistShowOptionsOnClick
                  />
                </>
              ),
            }
          : { isFilterable: false })}
        linkDestination={
          segmentId ? undefined : (row) => `/customers/${row.id}`
        }
        isAsyncSort
        onClickSortHeader={(key, sort) => {
          setSort(`${key}:${sort}`);
        }}
        customSelectedHeader={() => (
          <CustomSelectHeader
            actualSelected={actualSelected}
            setSelected={setSelected}
            total={customersMeta?.total ?? 0}
            setIsAllRowsSelected={setIsAllRowsSelected}
          />
        )}
      />

      {segmentId && segmentId !== 'new' && (
        <div className="flex justify-end mt-5">
          <BBBButton
            variant="danger-outline"
            text="Delete segmentation"
            onClick={() => deleteSegment(true)(segmentId)}
          />
        </div>
      )}

      {showAdd && (
        <AddCustomerModal
          show={showAdd}
          onClose={() => {
            setShowAdd(false);
          }}
          onSuccess={() => setCursor(undefined)}
        />
      )}
      {showAddTag && (
        <AddBulkCustomerTagModal
          show={showAddTag}
          onClose={() => {
            setShowAddTag(false);
          }}
          selectedRows={selected}
          onSuccess={() => {
            setCursor(undefined);
            setIsAllRowsSelected(false);
            setSelected([]);
          }}
          isAllRowSelected={isAllRowsSelected}
        />
      )}
    </BBBContainer>
  );
}

function CustomSelectHeader({
  setSelected,
  total,
  setIsAllRowsSelected,
  actualSelected,
}: {
  setSelected: (value: CustomerWithAssociation[]) => void;
  total: number;
  setIsAllRowsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  actualSelected: number;
}) {
  return (
    <p className="pl-1.5 text-base font-medium">
      {actualSelected.toLocaleString()} selected{' '}
      {actualSelected < total ? (
        <>
          or{' '}
          <span
            className="text-info-main underline cursor-pointer hover:opacity-90 transition-all"
            onClick={() => {
              setSelected([]);
              setIsAllRowsSelected(true);
            }}
          >
            select all customers ({total.toLocaleString()})
          </span>
        </>
      ) : null}
    </p>
  );
}
