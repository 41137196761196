import { ReactionMessage } from '@/types/whatsApp/v3';

export default function ChatBubbleReaction({
  reactions,
}: {
  reactions: ReactionMessage[];
}) {
  return (
    <div className="bg-white absolute top-[90%] z-10 left-0 inline-block w-6 h-6 rounded-full">
      <div className="flex items-center justify-center">
        {reactions[0].reaction}
      </div>
    </div>
  );
}
