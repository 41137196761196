import { useState } from 'react';
import { useHistory } from 'react-router';
import useConfirmationModal from './common/useConfirmationModal';
import { useBeforeUnload, useHistoryChange } from './router';

export const useConfirmLeaveModal = () => {
  const confirm = useConfirmationModal();

  return ({
    module,
    onSaveProgress,
    loadingSave,
    hasBackEdit,
    handleLeave,
    handleHide,
    cancelText,
  }: {
    module: string;
    onSaveProgress: (hide: () => void) => void;
    loadingSave: boolean;
    hasBackEdit?: boolean;
    handleLeave?: () => void;
    handleHide?: () => void;
    cancelText?: string;
  }) =>
    confirm({
      title: `Unsaved ${module}`,
      description:
        "Secure your progress! Save it before move to other page to ensure you don't lose any of your current creation's valuable steps",
      submitText: `Save ${module}`,
      onAccept: onSaveProgress,
      loadingSave,
      onHide: () => handleHide?.(),
      ...(hasBackEdit && {
        additionalButton: ({ onCancel }) => (
          <p
            className="mt-4 cursor-pointer text-center underline text-primary hover:opacity-80 transition-opacity"
            onClick={() => {
              onCancel?.();
              handleHide?.();
            }}
          >
            Back to edit
          </p>
        ),
      }),
      cancelText: cancelText || `Discard ${module}`,
      onCancel: handleLeave,
    });
};

export const useDiscardOrLeaveModal = ({
  onSave,
  module,
  loadingSave,
  isFormEqual,
}: {
  onSave: (hide: () => void, leaveCb?: () => void) => void;
  module: string;
  loadingSave: boolean;
  isFormEqual: boolean;
}) => {
  const history = useHistory();
  const [userPreferenceDiscard, setUserPreferenceDiscard] = useState(false);
  const confirm = useConfirmationModal();

  const leaveModal = useConfirmLeaveModal();

  const handleDiscard = () => {
    setUserPreferenceDiscard(true);
    confirm({
      title: 'Discard changes',
      description:
        'Are you sure want to discard the changes? This action cannot be undone.',
      cancelText: 'Cancel',
      submitText: 'Discard changes',
      onAccept: (hide) => {
        history.goBack();
        hide();
      },
      centerFooter: true,
      onHide: () => setUserPreferenceDiscard(false),
      onCancel: () => setUserPreferenceDiscard(false),
    });
  };

  const handleLeaveModal = (leaveCb: (() => void) | undefined) => {
    setUserPreferenceDiscard(true);
    leaveModal({
      module,
      onSaveProgress: (hide) =>
        onSave(hide, () => {
          leaveCb?.();
        }),
      loadingSave,
      hasBackEdit: true,
      handleLeave: () => {
        leaveCb?.();
      },
      handleHide: () => {
        setUserPreferenceDiscard(false);
      },
    });
  };

  useHistoryChange(
    !userPreferenceDiscard && !isFormEqual && !loadingSave,
    (leaveCb) => handleLeaveModal(leaveCb)
  );

  useBeforeUnload(!isFormEqual);

  return [handleDiscard] as const;
};
