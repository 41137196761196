import { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { ModelOptions } from '../../Sorting';

import {
  CollectionAssociation,
  GetCollectionParams,
} from '@/api/services/bitAi/v2-new/sorting';
import { BBBBottomSheet, BBBPrimarySwitch } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import colors from '@/constants/common/colors';
import useUpdateOos from '@/hooks/bitAi/sorting/useUpdateOos';
import useUpdateSortingModel from '@/hooks/bitAi/sorting/useUpdateSortingModel';
import useGetDomainStore from '@/hooks/bitAi/useGetDomainStore';
import useGetStoreName from '@/hooks/bitAi/useGetStoreName';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { formatDate2 } from '@/utils/common/date';
import { getRawShopifyNodeId } from '@/utils/common/url';

export default function useColumn(
  params: GetCollectionParams
): HeadersTypes<CollectionAssociation> {
  const [showActions, setShowActions] = useState(false);
  const storeName = useGetStoreName();
  const domainStore = useGetDomainStore();
  const { mutate: updateOOS } = useUpdateOos(params);
  const { mutate: updateSortingModel } = useUpdateSortingModel(params);

  const { isReachingTreshold } = useWithCRMPricing(
    'BITAI',
    'managed_collections'
  );

  return [
    {
      accesor: 'title',
      headerClassName: 'bg-transparent px-4',
      render: (row, value) => (
        <>
          <div className="flex flex-col gap-2">
            <div className="flex w-full items-center justify-between">
              <div className="w-fit flex flex-col gap-2">
                <div className="text-base text-primary-main font-semibold">
                  {row.sorting?.model?.type === 'CUSTOM' ? (
                    <Link
                      to={{
                        pathname: `/bitai/sorting/${encodeURIComponent(
                          row.id
                        )}`,
                      }}
                    >
                      {value}
                    </Link>
                  ) : (
                    <>{value}</>
                  )}
                </div>
                <div className="text-base text-primary-main">
                  {row.collectionProducts.length}
                </div>
              </div>
              <MoreVertical
                onClick={(e) => {
                  setShowActions(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                color={colors.primary.main}
              />
            </div>
            <p className="text-neutral-500 text-base">
              Last Updated {formatDate2(value)}
            </p>
            <ModelOptions
              value={
                row.sorting?.model
                  ? {
                      ...row.sorting.model,
                      //@ts-ignore
                      parentValue: row.sorting.model.type,
                    }
                  : null
              }
              onValueChange={(value) =>
                updateSortingModel({
                  data: {
                    collectionId: row.id,
                    modelId: value?.id || null,
                    model: value || null,
                  },
                })
              }
              disabled={!row.sorting?.model && isReachingTreshold}
            />
            <div className="w-full flex items-center justify-between">
              <p className="text-base text-primary-main">Out Of Stock</p>
              <BBBPrimarySwitch
                checked={row.sorting?.oos}
                onChange={(checked) =>
                  updateOOS({
                    data: {
                      collectionId: row.id,
                      oos: !!checked,
                    },
                  })
                }
                className="justify-start"
              />
            </div>
          </div>
          <MobileActions
            title="Actions"
            show={showActions}
            onHide={() => setShowActions(false)}
            domainStore={domainStore}
            storeName={storeName}
            handle={row.handle}
            id={row.id}
          />
        </>
      ),
    },
  ];
}

function MobileActions({
  id,
  show,
  title,
  onHide,
  domainStore,
  handle,
  storeName,
}: {
  show: boolean;
  title: string;
  onHide?: () => void;
  domainStore: string | undefined;
  storeName: string | undefined;
  id: string;
  handle: string;
}) {
  return (
    <BBBBottomSheet show={show} title={title} onClose={onHide}>
      <div className="flex flex-col gap-4">
        <p className="text-primary-main"></p>
        <Link
          to={{
            pathname: `/bitai/sorting/${encodeURIComponent(id)}`,
            state: {
              data: id,
            },
          }}
        >
          Manage
        </Link>
        <a
          href={`https://${domainStore}/collections/${handle}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="text-primary-main"
        >
          Preview
        </a>
        <a
          href={`https://admin.shopify.com/store/${storeName}/collections/${getRawShopifyNodeId(
            id
          )}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="text-primary-main"
        >
          Edit collections
        </a>
      </div>
    </BBBBottomSheet>
  );
}
