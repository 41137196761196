import { shallowEqual } from 'react-redux';
import { useActiveCompany } from '../rtk/selector';
import { selectActiveChat } from './chat';

import { useAppSelector } from '@/hooks/rtk/store';

const useChatMessagesQueryKey = () => {
  const activeCompany = useActiveCompany();
  const selectedChat = useAppSelector(selectActiveChat, shallowEqual);

  return [
    'chat-conversation',
    selectedChat,
    {
      companyId: activeCompany,
    },
  ];
};

export default useChatMessagesQueryKey;
