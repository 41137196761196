import UpsellCard from '../UpsellCard';
import AmazonSettingCard from './components/AmazonSetting';
import AppleSettingCard from './components/AppleSetting';
import FacebookSettingCard from './components/FacebookSetting';
import GoogleSettingCard from './components/GoogleSetting';
import MicrosoftSettingCard from './components/MicrosoftSetting';
import TwitterSettingCard from './components/TwitterSetting';

import { BBBContainer } from '@/components/ui';
import BBBNavigationCard from '@/components/ui/BBBNavigationCard/BBBNavigationCard';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import { capitalize } from '@/utils/common/text';

const settingsNavigation = [
  { label: 'Google', value: 'google' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Apple', value: 'apple' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Microsoft', value: 'microsoft' },
  { label: 'Amazon', value: 'amazon' },
];

function ProviderSetting() {
  const isMobile = useResponsive('sm');
  const queryParams = useQuerySearchParams();
  const defaultSelected = isMobile ? undefined : 'google';
  const selected = queryParams.get('section') ?? defaultSelected;

  return isMobile ? (
    <ProviderSettingMobile selected={selected || ''} />
  ) : (
    <ProviderSettingDefault selected={selected || ''} />
  );
}

type ProviderSettingProps = {
  selected: string;
};

function ProviderSettingDefault({ selected }: ProviderSettingProps) {
  return (
    <BBBContainer hasHeading>
      <div className="flex gap-6 items-start">
        <BBBNavigationCard
          selected={selected}
          options={settingsNavigation}
          title="OAuth Settings"
          link={(val) => `/bitlogin/oauth-settings?section=${val}`}
        />
        <div className="grow">
          <UpsellCard type="oauth-helper" />
          <div className="mb-titleToCard">
            <div className="text-2xl font-medium">{capitalize(selected)}</div>
            <div className="font-light mt-2">
              Use your own OAuth to personalize and go deeper with your login
              button.
            </div>
          </div>
          {selected === 'google' ? (
            <GoogleSettingCard className="mb-cardBottom" />
          ) : selected === 'facebook' ? (
            <FacebookSettingCard className="mb-cardBottom" />
          ) : selected === 'apple' ? (
            <AppleSettingCard className="mb-cardBottom" />
          ) : selected === 'twitter' ? (
            <TwitterSettingCard className="mb-cardBottom" />
          ) : selected === 'microsoft' ? (
            <MicrosoftSettingCard className="mb-cardBottom" />
          ) : (
            <AmazonSettingCard className="mb-cardBottom" />
          )}
        </div>
      </div>
    </BBBContainer>
  );
}

function ProviderSettingMobile({ selected }: ProviderSettingProps) {
  return selected === 'google' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Google Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <GoogleSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : selected === 'facebook' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Facebook Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <FacebookSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : selected === 'apple' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Apple Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <AppleSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : selected === 'twitter' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Twitter Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <TwitterSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : selected === 'microsoft' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Microsoft Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <MicrosoftSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : selected === 'amazon' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Amazon Settings"
      pageDescription="Use your own OAuth to personalize and go deeper with your login button"
      backUrl="/bitlogin/oauth-settings"
    >
      <AmazonSettingCard className="mb-cardBottom" />
    </BBBContainer>
  ) : (
    <BBBContainer hasHeading pageTitle="OAuth Settings">
      <BBBNavigationCard
        selected={selected}
        options={settingsNavigation}
        link={(val) => `/bitlogin/oauth-settings?section=${val}`}
      />
    </BBBContainer>
  );
}

export default ProviderSetting;
