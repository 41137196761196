import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useUpsertRedirect = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  return useMutation(
    async (payload: { link: string; redirectId?: string }) => {
      const { data } = await api.bitLink.post(
        `/shopify/redirect/company/${activeCompany}`,
        payload
      );
      return data;
    },
    {
      onSuccess(data, variables, context) {
        client.invalidateQueries(['bitlink-shopify-redirect', activeCompany]);
        toast.success(
          variables.redirectId === undefined
            ? `Succesfully added ${variables.link} to your own custom domain`
            : `Succesfully updated your domain to ${variables.link}`
        );
      },
    }
  );
};

export default useUpsertRedirect;
