import {
  DivideCircle,
  Image,
  Link2,
  ShoppingBag,
  Type,
  Youtube,
} from 'react-feather';
import { createId } from '@paralleldrive/cuid2';

import WhatsAppSectionIcon from '@/assets/icons/WhatsAppSectionIcon';

const sectionOptions = [
  {
    label: 'Image',
    id: createId(),
    name: 'image',
    description: 'Add clickable image with embedded link',
    icon: <Image />,
  },
  {
    label: 'Text',
    id: createId(),
    name: 'text',
    description: 'Organize your links with text',
    icon: <Type />,
  },
  {
    label: 'Divider',
    id: createId(),
    name: 'divider',
    description: 'Divide links to give it space',
    icon: <DivideCircle />,
  },
  {
    label: 'Shop',
    id: createId(),
    name: 'shop',
    description: 'Embed your Shopify products',
    icon: <ShoppingBag />,
  },
  {
    label: 'WhatsApp',
    id: createId(),
    name: 'whatsapp',
    description: 'Let visitors contact your WhatsApp',
    icon: <WhatsAppSectionIcon />,
  },
  {
    label: 'YouTube',
    id: createId(),
    name: 'youtube',
    description: 'Embed videos to your link',
    icon: <Youtube />,
  },
];

export default sectionOptions;
