import { SidebarIcon } from '@/constants/layouts/sidebarData';

function IntegrationsIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <IntegrationsIconDefault {...props} />
      ) : (
        <IntegrationsIconActive {...props} />
      )}
    </>
  );
}

function IntegrationsIconDefault({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M4.724 2.323l-.91 13.875 12.13-5.6-11.22-8.275zm1.468 3.154l-.527 8.032 7.021-3.242-6.494-4.79z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M8.225 1.93a3.323 3.323 0 01-1.627 4.42A3.348 3.348 0 012.16 4.73 3.323 3.323 0 013.787.31a3.348 3.348 0 014.438 1.62z"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M5.895 4.84a1.662 1.662 0 00.814-2.21 1.674 1.674 0 00-2.22-.81 1.662 1.662 0 00-.813 2.21 1.674 1.674 0 002.22.81zm.703 1.51a3.323 3.323 0 001.627-4.42A3.348 3.348 0 003.787.31 3.323 3.323 0 002.16 4.73a3.348 3.348 0 004.438 1.62z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M6.365 15.02a2.796 2.796 0 01-1.369 3.72c-1.41.65-3.081.04-3.734-1.364a2.797 2.797 0 011.368-3.72 2.817 2.817 0 013.735 1.364z"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M4.528 17.733a1.689 1.689 0 00.826-2.246 1.701 1.701 0 00-2.255-.824 1.689 1.689 0 00-.827 2.247 1.701 1.701 0 002.256.823zm.468 1.006a2.796 2.796 0 001.369-3.719 2.817 2.817 0 00-3.735-1.363 2.797 2.797 0 00-1.368 3.72 2.817 2.817 0 003.734 1.363z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M19.623 8.899a4.032 4.032 0 01-1.974 5.363 4.062 4.062 0 01-5.385-1.966 4.032 4.032 0 011.974-5.363 4.062 4.062 0 015.385 1.966z"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M16.946 12.752a2.37 2.37 0 001.16-3.153 2.388 2.388 0 00-3.165-1.156 2.37 2.37 0 00-1.16 3.153 2.388 2.388 0 003.165 1.156zm.703 1.51A4.032 4.032 0 0019.623 8.9a4.062 4.062 0 00-5.385-1.966 4.032 4.032 0 00-1.974 5.363 4.062 4.062 0 005.385 1.966z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function IntegrationsIconHover({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 22"
    >
      <g clipPath="url(#clip0_6824_92320)">
        <path
          fill="#404040"
          fillRule="evenodd"
          d="M20.209 2.342a5.859 5.859 0 012.779 2.117A5.584 5.584 0 0124 7.675a5.637 5.637 0 01-1.589 3.912 5.668 5.668 0 01-2.884 1.6 5.561 5.561 0 01-1.753.092 6.22 6.22 0 01-1.848-.51 5.23 5.23 0 01-.84-.492l-4.282 3.807c.176.486.268.998.271 1.514-.006.261-.031.521-.073.779a4.384 4.384 0 01-1.168 2.283 4.408 4.408 0 01-2.248 1.244 4.338 4.338 0 01-1.35.07 4.883 4.883 0 01-1.475-.404 4.378 4.378 0 01-1.908-1.74 4.357 4.357 0 01-.593-2.51 4.563 4.563 0 011.046-2.562 4.17 4.17 0 011.415-1.091l-.608-5.303a4.382 4.382 0 01-1.709-.392A4.187 4.187 0 01.576 6.304 4.167 4.167 0 01.012 3.9a4.321 4.321 0 01.986-2.417A4.228 4.228 0 015.608.245c.824.304 1.537.85 2.044 1.566.335.473.565 1.013.672 1.582l5.101 1.517a6.01 6.01 0 01.609-.891 5.681 5.681 0 016.175-1.677zM7.393 6.907A4.245 4.245 0 015.967 7.99l.606 5.249a4.145 4.145 0 011.55.235 4.56 4.56 0 011.63 1.025l3.981-3.553-.044-.068c-.065-.099-.13-.2-.192-.301a5.574 5.574 0 01-.795-3.276c.018-.205.046-.41.085-.613L8.244 5.333a4.228 4.228 0 01-.85 1.574z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_6824_92320">
          <path fill="#fff" d="M0 0H24V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function IntegrationsIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M4.724 2.323l-.91 13.875 12.13-5.6-11.22-8.275zm1.468 3.154l-.527 8.032 7.021-3.242-6.494-4.79z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FED5BF"
        d="M8.225 1.93a3.324 3.324 0 01-1.627 4.42A3.348 3.348 0 012.16 4.73 3.323 3.323 0 013.787.31a3.348 3.348 0 014.438 1.62z"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M5.895 4.84a1.662 1.662 0 00.814-2.21 1.674 1.674 0 00-2.22-.81 1.662 1.662 0 00-.813 2.21 1.674 1.674 0 002.22.81zm.703 1.51a3.324 3.324 0 001.627-4.42A3.348 3.348 0 003.787.31 3.323 3.323 0 002.16 4.73a3.348 3.348 0 004.438 1.62z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FED5BF"
        d="M6.365 15.02a2.796 2.796 0 01-1.369 3.72c-1.41.65-3.081.04-3.734-1.364a2.797 2.797 0 011.368-3.72 2.817 2.817 0 013.735 1.364z"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M4.528 17.733a1.689 1.689 0 00.826-2.246 1.701 1.701 0 00-2.255-.824 1.689 1.689 0 00-.827 2.247 1.701 1.701 0 002.256.823zm.468 1.006a2.796 2.796 0 001.369-3.719 2.817 2.817 0 00-3.735-1.363 2.797 2.797 0 00-1.368 3.72 2.817 2.817 0 003.734 1.363z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FED5BF"
        d="M19.623 8.899a4.032 4.032 0 01-1.974 5.363 4.062 4.062 0 01-5.385-1.966 4.032 4.032 0 011.974-5.363 4.062 4.062 0 015.385 1.966z"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M16.946 12.752a2.37 2.37 0 001.16-3.153 2.388 2.388 0 00-3.165-1.156 2.37 2.37 0 00-1.16 3.153 2.388 2.388 0 003.165 1.156zm.703 1.51A4.032 4.032 0 0019.623 8.9a4.062 4.062 0 00-5.385-1.966 4.032 4.032 0 00-1.974 5.363 4.062 4.062 0 005.385 1.966z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IntegrationsIcon;
