import React, { useRef, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/ui';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { Accent } from '@/types/utils/color';

function TagsColorPicker({
  value,
  onValueChange,
  className,
}: {
  value: Accent;
  onValueChange?: (value: Accent) => void;
  className?: string;
}) {
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideAlerter(ref, () => {
    setShowPicker(false);
  });

  return (
    <TagsColorPreview
      onClick={() => setShowPicker(true)}
      accent={value}
      className={className}
    >
      {showPicker && (
        <BBBCard
          ref={ref}
          className="p-4 pointer-events-none md:p-4 absolute z-10 top-full mt-1 left-0 rounded-lg flex flex-wrap gap-3 w-36"
          onClick={(e) => e.stopPropagation()}
        >
          {(['neutral', 'success', 'danger', 'warning', 'info'] as const).map(
            (accent) => (
              <TagsColorPreview
                key={accent}
                accent={accent}
                onClick={() => {
                  onValueChange?.(accent);
                  setShowPicker(false);
                }}
              />
            )
          )}
        </BBBCard>
      )}
    </TagsColorPreview>
  );
}

function TagsColorPreview({
  children,
  accent = 'neutral',
  onClick,
  className,
}: {
  children?: React.ReactNode;
  accent?: Accent;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <div
      className={cx(
        'w-7 h-7 cursor-pointer pointer-events-auto rounded-lg relative border',
        accent === 'neutral' && 'bg-neutral-20 border-primary-main',
        accent === 'info' && 'bg-info-surface border-info-main',
        accent === 'success' && 'bg-success-surface border-success-main',
        accent === 'warning' && 'bg-secondary-surface border-secondary-main',
        accent === 'danger' && 'bg-danger-surface border-danger-main',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default TagsColorPicker;
