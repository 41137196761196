import { useState } from 'react';
import { HelpCircle, X } from 'react-feather';
import { getVariantClassNames } from '../BBBAlert/BBBAlert';
import UpgradeText from './UpgradeText';

import { PricingPlan } from '@/api/services/pricing';
import AlertIcon2 from '@/assets/icons/AlertIcon2';
import { BBBTooltip } from '@/components/ui/BBBTooltip';
import { appNameFromType } from '@/constants/app';
import colors from '@/constants/common/colors';
import { TRESHOLD_2 as _TRESHOLD_2, TRESHOLD_3 } from '@/constants/pricing';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import { AppType } from '@/types/systemLogin';
import { Accent } from '@/types/utils/color';
import { cn } from '@/utils/styles';

type Props = {
  usage?: number;
  limit?: number;
  appType: AppType;
  module?: string;
  currentPlan?: { name: PricingPlan; label: string | null };
  withoutTip?: boolean;
  className?: string;
  customTip?: string;
  customExceedTresholdLabel?: JSX.Element;
  customReachingTresholdLabel?: JSX.Element;
  customLabel?: JSX.Element;
  forceCloseable?: boolean;
  customTresholdFn?: (limit: number) => number;
  accent?: Accent;
};

function BBBLimitAlert({
  usage = 0,
  limit = 0,
  appType,
  module,
  currentPlan,
  withoutTip,
  className,
  customTip,
  customExceedTresholdLabel,
  customReachingTresholdLabel,
  customLabel,
  forceCloseable,
  customTresholdFn,
  accent = 'secondary',
}: Props) {
  const [show, setShow] = useState(true);

  const diff = limit - usage;

  const rate = usage / limit;

  const TRESHOLD_2 =
    typeof customTresholdFn !== 'undefined'
      ? customTresholdFn(limit)
      : _TRESHOLD_2(limit);

  const { isMaximumLevel } = useIsMaximumLevel(appType);

  if (rate < TRESHOLD_2) return null;

  if (!show) return null;

  return (
    <div
      className={cn(
        accent && getVariantClassNames(accent),
        'px-3 flex justify-center items-center gap-2.5 py-2.5',
        className
      )}
    >
      <div className="flex-none">
        <AlertIcon2 type={accent} />
      </div>
      <div className="text-sm text-center">
        {customLabel ||
          (rate >= TRESHOLD_2 && rate < TRESHOLD_3
            ? customReachingTresholdLabel ||
              `${diff} active ${module} left for ${appNameFromType[appType]} ${
                currentPlan?.label || currentPlan?.name
              } plan.`
            : customExceedTresholdLabel || (
                <>
                  You have reached your {limit} active {module} limit.{' '}
                  {!isMaximumLevel && (
                    <>
                      <UpgradeText appType={appType} /> for more.
                    </>
                  )}
                </>
              ))}
      </div>
      {!withoutTip && (
        <BBBTooltip
          show
          content={
            customTip ||
            `On our ${
              currentPlan?.label || currentPlan?.name
            } plan, you can create up to ${limit} active ${module}. ${
              !isMaximumLevel
                ? `Upgrade for unlimited ${module}, plus other features.`
                : `Contact us for unlimited ${module}, plus other features`
            }`
          }
        >
          <HelpCircle color={colors.secondary.main} size={12} />
        </BBBTooltip>
      )}
      {(forceCloseable || usage < limit) && (
        <X
          size={12}
          className="cursor-pointer flex-none"
          onClick={() => setShow(false)}
        />
      )}
    </div>
  );
}

export default BBBLimitAlert;
