import { useMutation, useQueryClient } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { CreateTagPayload } from '@/api/services/customer/customer';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useCreateTag = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CreateTagPayload) =>
      customerServices.customer.createTag(activeCompany!, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tags']);
        toast.success('Successfully add tags');
      },
    }
  );
};

export default useCreateTag;
