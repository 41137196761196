import { useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { PlusCircle } from 'react-feather';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import useColumnDefault from './hooks/manage-model/useColumn';
import useColumnMobile from './hooks/manage-model/useColumnMobile';

import {
  ModelCondition,
  UpsertModelPayload,
} from '@/api/services/bitAi/v2-new/model';
import { BBBButton, BBBContainer, BBBTextInput } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import useSortingModel from '@/hooks/bitAi/models/useSortingModel';
import useUpsertModel from '@/hooks/bitAi/models/useUpsertModel';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { arrayMove } from '@/utils/common/array';

const schema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required('Name is required'),
  rules: yup.array().of(
    yup.object().shape({
      index: yup.number().required('Priority Order is required'),
      qty: yup.number().required('Number Used is required'),
      condition: yup.mixed<ModelCondition>().required('Sort Order is required'),
    })
  ),
});

export default function ManageModel() {
  const isMobile = useResponsive('sm');
  const { id } = useParams<{
    id: string;
  }>();

  const { toggle } = useConfirmationBanner();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useCustomForm<UpsertModelPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      rules: [],
      id,
    },
  });

  const {
    data: dataSortingModel,
    isInitialLoading: loadingSortingModel,
    status: statusSortingModel,
  } = useSortingModel(id);

  const { mutate: upsertModel, isLoading } = useUpsertModel();

  const rules = watch('rules');

  const reorderData = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const startIndex = source.index;
    const endIndex = destination.index;

    const newData = [...rules];

    arrayMove(newData, startIndex, endIndex);

    setValue('rules', newData);
  };

  const addSection = () => {
    setValue('rules', [
      ...rules,
      {
        id: createId(),
        qty: 0,
        condition: 'HIGHEST_DISCOUNT',
        index: rules.length,
      },
    ]);
  };

  const columnsDefault = useColumnDefault({
    onUpdate: (rowId, columnId, value) => {
      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === rowId) {
            return {
              ...rule,
              [columnId]: value,
            };
          }

          return rule;
        })
      );
    },
    onRemove: (rowId) => {
      setValue(
        'rules',
        rules.filter((rule) => rule.id !== rowId)
      );
    },
  });
  const columnsMobile = useColumnMobile({
    onUpdate: (rowId, columnId, value) => {
      setValue(
        'rules',
        rules.map((rule) => {
          if (rule.id === rowId) {
            return {
              ...rule,
              [columnId]: value,
            };
          }

          return rule;
        })
      );
    },
    onRemove: (rowId) => {
      setValue(
        'rules',
        rules.filter((rule) => rule.id !== rowId)
      );
    },
  });

  const dataFromApi = useMemo<Partial<UpsertModelPayload>>(
    () => ({
      name: dataSortingModel?.name || '',
      rules: dataSortingModel?.rules || [],
      id: dataSortingModel?.id,
    }),
    [dataSortingModel?.id, dataSortingModel?.name, dataSortingModel?.rules]
  );

  useEffect(() => {
    function onSubmit(data: UpsertModelPayload) {
      upsertModel(data);
    }
    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('bitai-manage-model', {
      show: !isFormEqual,
      variant: isLoading ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  });

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  return (
    <BBBContainer
      hasBack
      backUrl={'/bitai/model'}
      hasHeading
      pageTitle={`${
        id === 'new'
          ? 'New Model'
          : statusSortingModel === 'loading' || loadingSortingModel
          ? undefined
          : dataSortingModel?.name
      }`}
    >
      <BBBTableV2
        data={rules}
        dataId="id"
        loadingBody={loadingSortingModel}
        headers={isMobile ? columnsMobile : columnsDefault}
        renderEmptyMessage={() =>
          'Add new section by pressing the button below'
        }
        isDraggable
        footer={
          <div className="d-flex py-3 px-4 justify-content-end">
            <BBBButton
              variant="secondary"
              width="full"
              text="Add new section"
              icon={<PlusCircle className="mr-3" />}
              onClick={() => {
                addSection();
              }}
            />
          </div>
        }
        withoutHeader={isMobile}
        onDragEnd={reorderData}
        renderCustomHeader={() => (
          <div className="lg:pl-2 px-3 lg:pt-2 pt-3 lg:w-1/4 w-full lg:flex flex-none items-center gap-4 justify-between">
            <BBBTextInput
              placeholder="Copy of Sorting Model 001"
              inputClassName="text-2xl w-full"
              isHookForm
              control={control}
              controlName="name"
              error={errors.name?.message}
              autoFocus
              containerClassname={isMobile ? 'w-full' : ''}
            />
          </div>
        )}
      />
    </BBBContainer>
  );
}
