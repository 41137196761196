import React from 'react';

const UnsubscribeIcon = ({ color = '#FF6060' }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0909 12.5526L3.81818 8.07895V17.0263H11.0909C11.0909 17.3395 11.1136 17.6416 11.1591 17.9327C11.2045 18.2238 11.2727 18.5181 11.3636 18.8158H3.81818C3.31818 18.8158 2.8903 18.6407 2.53455 18.2906C2.17879 17.9404 2.00061 17.519 2 17.0263V6.28947C2 5.79737 2.17818 5.37625 2.53455 5.02611C2.89091 4.67596 3.31879 4.5006 3.81818 4.5H18.3636C18.8636 4.5 19.2918 4.67537 19.6482 5.02611C20.0045 5.37684 20.1824 5.79796 20.1818 6.28947V11.3895C19.9091 11.2553 19.6176 11.1434 19.3073 11.0539C18.997 10.9645 18.6824 10.8974 18.3636 10.8526V8.07895L11.0909 12.5526ZM11.0909 10.7632L18.3636 6.28947H3.81818L11.0909 10.7632ZM17.4545 21.5C16.197 21.5 15.1252 21.0637 14.2391 20.191C13.353 19.3183 12.9097 18.2634 12.9091 17.0263C12.9091 15.7886 13.3524 14.7337 14.2391 13.8616C15.1258 12.9896 16.1976 12.5532 17.4545 12.5526C18.7121 12.5526 19.7842 12.989 20.6709 13.8616C21.5576 14.7343 22.0006 15.7892 22 17.0263C22 18.264 21.5567 19.3192 20.67 20.1919C19.7833 21.0646 18.7115 21.5006 17.4545 21.5ZM14.7273 17.4737H20.1818V16.5789H14.7273V17.4737Z"
        fill="#FF6060"
      />
    </svg>
  );
};

export default UnsubscribeIcon;
