import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { textColorOptions } from 'constants/bitChat/waWidget';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from 'hooks/bitChat/integration/integrations';
import useEditWaWidget from 'hooks/bitChat/waWidget/useEditWaWidget';
import useWidget from 'hooks/bitChat/waWidget/useWidget';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useCustomForm from 'hooks/common/useCustomForm';
import useDefaultCountryCode from 'hooks/common/useDefaultCountryCode';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import { phoneValidator } from 'utils/common/phone';
import Appearance from './components/Appearance';
import ButtonWidget from './components/ButtonWidget';
import EmbedCode from './components/EmbedCode';
import EnableWidget from './components/EnableWidget';
import HideWidget from './components/HideWidget';
import PowerMessage from './components/PowerMessage';
import QuickResponses from './components/QuickResponse';
import SocialColor from './components/SocialColor';
import SocialMedia from './components/SocialMedia';
import WhatsAppNumber from './components/WhatsAppNumber';
import WidgetColor from './components/WidgetColor';
import Wrapper from './components/Wrapper';

import AgendaIcon from '@/assets/icons/AgendaIcon';
import BrushIcon from '@/assets/icons/BrushIcon';
import HamburgerIcon from '@/assets/icons/HamburgerIcon';
import { BBBTelInputValue } from '@/components/ui';
import BBBNavigatorTab2 from '@/components/ui/BBBNavigatorTab/BBBNavigatorTab2';
import { SocialValues } from '@/constants/social';
import useResponsive from '@/hooks/common/useResponsive';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import DesktopOptimized from '@/layouts/DesktopOptimized';
import { AutoReply } from '@/types/bitChat/chatbot';
import {
  PhonePreference,
  WaWidget2 as WaWidgetType,
  WidgetIconType,
  WidgetRadiusType,
  WidgetTextColor,
} from '@/types/bitChat/waWidget';
import { DeepPartial } from '@/types/utils/deepPartial';
import { Flow } from '@/types/whatsApp/flow';
import { formatPhonePayload } from '@/utils/common/phone';

export type QuickResponseForm = {
  buttonText: string;
  chatbot: Flow | (Pick<Flow, 'id' | 'name'> & { keywords: string }) | null;
};

export type FormSchema = Pick<
  WaWidgetType,
  | 'id'
  | 'whatsappButtonText'
  | 'whatsappPrefilledText'
  | 'widgetText'
  | 'themeColor'
  | 'position'
  | 'size'
  | 'storeName'
  | 'storeDescription'
  | 'storeLogo'
  | 'showCard'
  | 'quickResponseEnabled'
  | 'includeChatId'
  | 'includeCurrentUrl'
  | 'phonePreference'
  | 'powerMsgHeader'
  | 'powerMsgDescription'
  | 'powerMsgDisplayFrequency'
  | 'powerMsgEnabled'
  | 'powerMsgColor'
  | 'widgetColorType'
  | 'widgetColor1'
  | 'widgetColor2'
  | 'socialColorType'
  | 'socialColorCustom'
> & {
  widgetButton: Pick<WaWidgetType, 'iconType' | 'radiusType'>;
  whatsappPhone: BBBTelInputValue | null;
  textColor: { label: string; value: WidgetTextColor } | null;
  hiddenPage: {
    label: string;
    label2: string;
    value: string;
    parentValue?: string;
  }[];
  quickResponses: QuickResponseForm[];
  powerMsgDisplayDelay: string;
  social: {
    socialOrders: WaWidgetType['socialOrders'];
    values: SocialValues;
  };
};

const settingsSchema = yup.object().shape({
  whatsappPhone: phoneValidator().when('phonePreference', {
    is: (val: PhonePreference) => val === 'manual',
    otherwise: () => yup.mixed(),
  }),
  whatsappButtonText: yup.string().required('This field cannot be empty'),
  whatsappPrefilledText: yup.string(),
  quickResponseEnabled: yup.boolean(),
  quickResponses: yup.array().when('quickResponseEnabled', {
    is: true,
    then: (rule) =>
      rule.of(
        yup.object().shape({
          buttonText: yup
            .string()
            .required('Trigger button text cannot be empty'),
          chatbot: yup.mixed<AutoReply>().required().label('Chatbot'),
        })
      ),
  }),
  hiddenPage: yup.mixed<FormSchema['hiddenPage']>(),
  powerMsgEnabled: yup.bool(),
  powerMsgHeader: yup.string().when('powerMsgEnabled', {
    is: true,
    then: (rule) => rule.required('Header message is required!'),
  }),
});

const appearanceSchema = yup.object().shape({
  storeLogo: yup.string().nullable(),
  storeName: yup.string(),
  storeDescription: yup.string(),
  themeColor: yup.string().required('This field cannot be empty'),
  textColor: yup.object().required('This field cannot be empty'),
  position: yup.string().required('This field cannot be empty'),
  widgetText: yup.string(),
  widgetButton: yup.object().shape({
    iconType: yup
      .mixed<WidgetIconType>()
      .required('This field cannot be empty'),
    radiusType: yup
      .mixed<WidgetRadiusType>()
      .required('This field cannot be empty'),
  }),
});

const mapSectionToSchema = {
  settings: settingsSchema,
  appearance: appearanceSchema,
};

const sections = [
  { name: 'Settings', key: 'settings', icon: AgendaIcon },
  { name: 'Appearance', key: 'appearance', icon: BrushIcon },
  { name: 'Social media', key: 'social', icon: HamburgerIcon },
];

const sectionsMap = Object.fromEntries(
  sections.map((section) => [section.key, section])
);

export default function WaWidget() {
  const isMobile = useResponsive('sm');

  if (isMobile) {
    return (
      <DesktopOptimized
        backLink="/home"
        description="Chat widget is more optimized in desktop website. Switch over to be able to set up your chat widget."
      />
    );
  }

  return <_WaWidget />;
}

function _WaWidget() {
  const [section, setSection] = useState(sectionsMap['settings']);

  const { data, isInitialLoading: loadingWidget } = useWidget();
  const { mutate: updateWidget, isLoading: loadingEdit } = useEditWaWidget();
  const { toggle } = useConfirmationBanner();

  const { data: whatsAppIntegration, isInitialLoading: loadingIntegrations } =
    useWhatsappBusinessIntegration();
  const { data: cloudApiIntegration } = useWhatsappCloudIntegration();

  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITCHAT');

  const loading = loadingWidget || loadingIntegrations;

  const phoneNumberWhatsApp = useDefaultCountryCode(
    data?.whatsappPhone || whatsAppIntegration?.extra?.number
  );

  const dataFromApi = useMemo<DeepPartial<FormSchema>>(
    () => ({
      id: data?.id,
      whatsappPhone: phoneNumberWhatsApp,
      whatsappButtonText: data?.whatsappButtonText || '',
      whatsappPrefilledText: data?.whatsappPrefilledText || '',
      quickResponseEnabled: data?.quickResponseEnabled || false,
      quickResponses: data?.quickResponses.length
        ? data?.quickResponses.map(({ buttonText, ...data }) => ({
            buttonText: buttonText,
            chatbot: {
              id: data.chatbotId,
              name: data.chatbotName,
              keywords: data.chatbotMsg,
            },
          }))
        : [{ buttonText: '', chatbot: null }],
      storeLogo: data?.storeLogo,
      storeDescription: data?.storeDescription || '',
      widgetText: data?.widgetText || '',
      textColor: data?.textColor
        ? textColorOptions.find((opt) => opt.value === data.textColor)
        : null,
      themeColor: data?.themeColor,
      position: data?.position,
      widgetButton:
        data?.iconType && data.radiusType
          ? {
              iconType: data.iconType,
              radiusType: data.radiusType,
            }
          : undefined,
      size: data?.size,
      hiddenPage:
        data?.hiddenPage?.map((_page) => ({
          label: _page,
          label2: _page,
          value: _page.split(': ')[1],
          parentValue: _page.startsWith('Mobile:') ? 'Mobile' : 'Desktop',
        })) || [],
      storeName: data?.storeName || '',
      showCard: data?.showCard,
      includeChatId: data?.includeChatId,
      includeCurrentUrl: data?.includeCurrentUrl,
      phonePreference: data?.phonePreference,
      powerMsgHeader: data?.powerMsgHeader || '',
      powerMsgDescription: data?.powerMsgDescription || '',
      powerMsgDisplayDelay:
        typeof data?.powerMsgDisplayDelay !== 'undefined'
          ? data?.powerMsgDisplayDelay.toString()
          : '',
      powerMsgDisplayFrequency: data?.powerMsgDisplayFrequency,
      powerMsgEnabled: data?.powerMsgEnabled,
      powerMsgColor: data?.powerMsgColor,
      widgetColor1: data?.widgetColor1,
      widgetColor2: data?.widgetColor2,
      widgetColorType: data?.widgetColorType,
      social: {
        socialOrders: data?.socialOrders,
        values: {
          instagram: {
            enabled: data?.socialIgEnabled,
            value: data?.socialIgValue || '',
          },
          facebook: {
            enabled: data?.socialFbEnabled,
            value: data?.socialFbValue || '',
          },
          email: {
            enabled: data?.socialEmailEnabled,
            value: data?.socialEmailValue || '',
          },
          x: {
            enabled: data?.socialXEnabled,
            value: data?.socialXValue || '',
          },
          tiktok: {
            enabled: data?.socialTiktokEnabled,
            value: data?.socialTiktokValue || '',
          },
        },
      },
      socialColorCustom: data?.socialColorCustom,
      socialColorType: data?.socialColorType,
    }),
    [
      data?.socialColorCustom,
      data?.socialColorType,
      data?.id,
      data?.whatsappButtonText,
      data?.whatsappPrefilledText,
      data?.quickResponseEnabled,
      data?.quickResponses,
      data?.storeLogo,
      data?.storeDescription,
      data?.widgetText,
      data?.themeColor,
      data?.position,
      data?.iconType,
      data?.radiusType,
      data?.size,
      data?.hiddenPage,
      data?.storeName,
      data?.showCard,
      data?.includeChatId,
      data?.includeCurrentUrl,
      data?.phonePreference,
      data?.powerMsgHeader,
      data?.powerMsgDescription,
      data?.powerMsgDisplayDelay,
      data?.powerMsgDisplayFrequency,
      data?.powerMsgEnabled,
      data?.powerMsgColor,
      data?.widgetColor1,
      data?.widgetColor2,
      data?.widgetColorType,
      data?.socialOrders,
      data?.socialIgEnabled,
      data?.socialIgValue,
      data?.socialFbEnabled,
      data?.socialFbValue,
      data?.socialEmailEnabled,
      data?.socialEmailValue,
      data?.socialXEnabled,
      data?.socialXValue,
      data?.socialTiktokEnabled,
      data?.socialTiktokValue,
      data?.textColor,
      phoneNumberWhatsApp,
    ]
  );

  const resolver =
    mapSectionToSchema[section.key as keyof typeof mapSectionToSchema];

  const { control, watch, handleSubmit, reset, setValue } =
    useCustomForm<FormSchema>({
      resolver: resolver ? yupResolver(resolver) : undefined,
      defaultValues: dataFromApi,
    });

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    function onSubmit({
      widgetButton,
      whatsappPhone,
      textColor,
      hiddenPage,
      quickResponses,
      powerMsgDisplayDelay,
      social,
      ...data
    }: FormSchema) {
      updateWidget({
        ...data,
        iconType: widgetButton.iconType,
        radiusType: widgetButton.radiusType,
        whatsappPhone:
          data.phonePreference === 'manual'
            ? formatPhonePayload(whatsappPhone)
            : data.phonePreference === 'wa_business'
            ? whatsAppIntegration!.extra!.number
            : cloudApiIntegration!.extra!.number,
        textColor: textColor?.value,
        hiddenPage: hiddenPage.map(
          (_page) => `${_page.parentValue}: ${_page.value}`
        ),
        quickResponses: quickResponses
          .filter((quickResponse) => !!quickResponse.chatbot)
          .map((quickResponse) => ({
            buttonText: quickResponse.buttonText,
            chatbotId: quickResponse.chatbot!.id,
            chatbotMsg:
              'keywords' in quickResponse.chatbot!
                ? quickResponse.chatbot.keywords
                : quickResponse.chatbot!.nodes.find(
                    (node) => node.type === 'trigger'
                  )?.data?.keywords[0],
            chatbotName: quickResponse.chatbot!.name,
          })),
        shopifyDomain: shopifyIntegration?.domain,
        powerMsgDisplayDelay: powerMsgDisplayDelay.length
          ? Number(powerMsgDisplayDelay)
          : undefined,
        socialOrders: social.socialOrders,
        socialEmailEnabled: social.values.email?.enabled,
        socialEmailValue: social.values.email?.value,
        socialFbEnabled: social.values.facebook?.enabled,
        socialFbValue: social.values.facebook?.value,
        socialIgEnabled: social.values.instagram?.enabled,
        socialIgValue: social.values.instagram?.value,
        socialTiktokEnabled: social.values.tiktok?.enabled,
        socialTiktokValue: social.values.tiktok?.value,
        socialXEnabled: social.values.x?.enabled,
        socialXValue: social.values.x?.value,
      });
    }

    const isFormEqual = isEqual(dataFromApi, watch());

    toggle('wa-widget-form', {
      show: !isFormEqual,
      variant: loadingWidget || loadingEdit ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: () => {
        reset(dataFromApi);
      },
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    updateWidget,
    handleSubmit,
    loadingEdit,
    loadingWidget,
    reset,
    toggle,
    watch(),
    shopifyIntegration?.domain,
    cloudApiIntegration,
    whatsAppIntegration,
  ]);

  return (
    <Wrapper
      title="Chat Widget"
      description="Enhance customer engagement by adding chat widget to your store"
      control={control}
    >
      <div className="px-24">
        <BBBNavigatorTab2
          navigator={sections}
          value={section.key}
          onChange={(value) => setSection(value)}
          className="mb-5"
        />
      </div>

      <EnableWidget />
      {section.key === 'settings' && (
        <>
          <WhatsAppNumber
            control={control}
            loading={loading}
            setValue={setValue}
          />
          <PowerMessage control={control} />
          <QuickResponses control={control} loading={loading} />
          <HideWidget control={control} loading={loading} />
          {!shopifyIntegration && <EmbedCode control={control} />}
        </>
      )}

      {section.key === 'appearance' && (
        <>
          <ButtonWidget control={control} loading={loading} />
          <Appearance control={control} loading={loading} />
          <WidgetColor control={control} />
        </>
      )}

      {section.key === 'social' && (
        <>
          <SocialMedia control={control} />
          <SocialColor control={control} />
        </>
      )}
    </Wrapper>
  );
}
