import { useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { PlusCircle } from 'react-feather';
import {
  Control,
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '../..';
import QuickResponseOptions from './QuickResponseOptions';

import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import {
  BBBAlert,
  BBBCard,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';
import { integrationsMeta } from '@/constants/integrations';
import { useFlows1 } from '@/hooks/whatsApp/flow';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';

type Props = {
  control: Control<FormSchema>;
  loading: boolean;
};

export default function QuickResponses({ control, loading }: Props) {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'quickResponses',
  });

  const { errors } = useFormState({
    control,
  });

  const phonePreference = useWatch({ control, name: 'phonePreference' });

  const { data: chatbotData } = useFlows1({
    channel:
      phonePreference === 'wa_business'
        ? 'whatsapp'
        : phonePreference === 'wa_cloud'
        ? 'whatsapp_meta'
        : undefined,
  });

  const chatbotWhatsappBusiness = chatbotData?.filter(
    (chatbot) => chatbot.channel === 'WHATSAPP'
  );

  const chatbotWhatsappCloud = chatbotData?.filter(
    (chatbot) => chatbot.channel === 'WHATSAPP_META'
  );

  const formattedOptions = [
    ...((phonePreference === 'manual' || phonePreference === 'wa_business') &&
    !!chatbotWhatsappBusiness?.length
      ? [{ name: integrationsMeta['whatsapp'].title, id: 'whatsapp' }]
      : []),
    ...((phonePreference === 'manual' || phonePreference === 'wa_cloud') &&
    !!chatbotWhatsappCloud?.length
      ? [{ name: integrationsMeta['whatsapp_meta'].title, id: 'whatsapp_meta' }]
      : []),
    ...(chatbotWhatsappBusiness
      ? chatbotWhatsappBusiness.map((chatbot) => ({
          ...chatbot,
          parentId: 'whatsapp',
        }))
      : []),
    ...(chatbotWhatsappCloud
      ? chatbotWhatsappCloud.map((chatbot) => ({
          ...chatbot,
          parentId: 'whatsapp_meta',
        }))
      : []),
  ];

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      if (!destination) return;

      move(source.index, destination.index);
    },
    [move]
  );

  return (
    <BBBCard
      title="Quick responses"
      className="mb-cardBottom"
      desc="Instantly engage customers with pre-built buttons and automated replies"
      rightButton={
        <>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : (
            <Controller
              control={control}
              name="quickResponseEnabled"
              render={({ field }) => (
                <BBBPrimarySwitch
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          )}
        </>
      }
      id="widget-quick-responses"
    >
      {phonePreference === 'manual' && (
        <BBBAlert type="info" className="mb-5">
          Chatbot might not trigger if you’re not connected.{' '}
          <Link className="font-bold underline" to={`/integrations`}>
            Connect your WhatsApp number
          </Link>
        </BBBAlert>
      )}
      <div className={cx('flex flex-col')}>
        <div className="flex items-center gap-5">
          <div className="opacity-0 bg-red-100">
            <BBBDraggableIcon color="#CACACA" />
          </div>
          <p className="text-neutral-70 flex-1 font-medium">Button text</p>
          <p className="text-neutral-70 flex-1 font-medium">
            Select chatbot to trigger
          </p>
          <div className="opacity-0">
            <TrashWithTransition />
          </div>
        </div>
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
          <Droppable droppableId="quick-response-id">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={`flex gap-5 w-full mb-3 items-center ${
                          snapshot.isDragging && 'opacity-50'
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div {...provided.dragHandleProps}>
                          <BBBDraggableIcon color="#CACACA" />
                        </div>
                        <div className="w-full">
                          <BBBTextInput
                            placeholder="Enter trigger button text"
                            containerClassname="mb-0"
                            isHookForm
                            control={control}
                            controlName={`quickResponses.${index}.buttonText`}
                            error={
                              errors.quickResponses?.[index]?.buttonText
                                ?.message
                            }
                          />
                        </div>
                        <div className="w-full">
                          <Controller
                            control={control}
                            name={`quickResponses.${index}.chatbot`}
                            render={({ field }) => {
                              return (
                                <QuickResponseOptions
                                  id={
                                    item.chatbot ? item.chatbot.id : undefined
                                  }
                                  placeholder="Select chatbot"
                                  options={formattedOptions || []}
                                  optionValue="id"
                                  optionLabel="name"
                                  isGrouped
                                  //@ts-ignore
                                  optionGroupKey="parentId"
                                  value={field.value}
                                  onValueChange={(val) => {
                                    field.onChange(val);
                                  }}
                                  error={
                                    errors.quickResponses?.[index]?.chatbot
                                      ?.message
                                  }
                                />
                              );
                            }}
                          />
                        </div>
                        <div>
                          <TrashWithTransition
                            height={24}
                            width={24}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex items-center gap-5 text-neutral-40">
          <div className="opacity-0">
            <BBBDraggableIcon />
          </div>
          <div
            className={cx('grow flex items-center gap-2 cursor-pointer')}
            onClick={() =>
              append({
                buttonText: '',
                chatbot: null,
              })
            }
          >
            <PlusCircle />
            <div className="grow">Add new quick responses</div>
          </div>
        </div>
      </div>
    </BBBCard>
  );
}
