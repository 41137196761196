import { useInfiniteQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useApiKey } from '../rtk/selector';
import { useAppSelector } from '../rtk/store';
import { selectActiveChatTruthy } from './chat';

const useListMedia = ({ limit }: { limit?: number }) => {
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  const apiKey = useApiKey();

  return useInfiniteQuery(
    ['list-media', { conversationId: selectedChat.clientNumber }],
    ({ pageParam }) =>
      services.whatsApp.v3.message.getMedias(
        {
          conversationId: selectedChat.clientNumber,
          apiKey,
        },
        {
          cursor: pageParam,
          sources: selectedChat.sources,
          limit,
        }
      ),
    {
      getNextPageParam: (lastPage) =>
        lastPage.meta.hasNextPage ? lastPage.meta.endCursor : undefined,
    }
  );
};

export default useListMedia;
