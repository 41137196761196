import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany, useUserId } from 'hooks/rtk/selector';
import { IntegrationExtra } from 'types/bitApp/v2';
import { CompanyIntegrationAssociation } from 'types/bitApp/v2/association';
import { IntegrationName } from 'types/bitLink/v2';
import { toast } from 'utils/common/toast';

export const useIntegrations = () =>
  useQuery(['bitlink-integrations'], async () =>
    services.bitLink.integrations.getIntegrations()
  );

export const useCompanyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitlink-company-integrations', activeCompany],
    () => services.bitLink.integrations.getCompanyIntegrations(activeCompany),
    { enabled, ...options }
  );
};

export const useShopifyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitlink-company-integrations', activeCompany],
    () => services.bitLink.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'shopify'),
      ...options,
    }
  );
};

export const useShopifyIntegrationExtra = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    IntegrationExtra | null,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitlink-company-integrations', activeCompany],
    () => services.bitLink.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) =>
        companyIntegrationSelector(data, 'shopify')?.extra || null,
      ...options,
    }
  );
};

export const useToggleIntegration = () => {
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const client = useQueryClient();

  return useMutation(
    async (
      data: Omit<
        Parameters<
          typeof services.bitLink.integrations.toggleCompanyIntegrations
        >[1],
        'userId'
      >
    ) =>
      services.bitLink.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([
          'bitlink-company-integrations',
          activeCompany,
        ]);
        client.invalidateQueries(['bitlink-shopify', activeCompany]);
        toast.success('Integration disconnected successfully!');
      },
    }
  );
};

function companyIntegrationSelector(
  data: CompanyIntegrationAssociation[],
  name: IntegrationName,
  options?: { includeDisconnected?: boolean }
) {
  return (
    options?.includeDisconnected
      ? data
      : data.filter((integration) => integration.status === 'CONNECTED')
  ).find((integration) => integration.integration.name === name);
}
