export default function BackgroundSmallBlob({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="368"
      height="108"
      className={className}
      viewBox="0 0 368 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M366.102 51.9535C344.455 118.238 284.147 107.331 183.051 107.331C81.9546 107.331 0 82.5378 0 51.9535C0 21.3692 136.806 8.5369 136.806 8.5369C136.806 8.5369 392.399 -28.5736 366.102 51.9535Z"
        fill="#FFF5EF"
      />
    </svg>
  );
}
