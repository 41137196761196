import { useEffect, useState } from 'react';
import PlusCircleIcon from 'assets/icons/PlusCircleIcon';
import useSettings from 'hooks/bitChat/settings/useSettings';
import TagOptions from 'pages/Customers/Segmentation/TagOptions';
import { Tags } from 'types/customers';
import { SettingsTag } from 'types/whatsApp/settings';

import { BBBSelect } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';

export type TicketCategoryPayload =
  | Pick<SettingsTag, 'label' | 'id'>
  | null
  | undefined;
export type CustomerTagsPayload =
  | Pick<Tags, 'id' | 'name'>[]
  | null
  | undefined;

export type TicketCategoryForm = SettingsTag | TicketCategoryPayload;
export type CustomerTagsForm = CustomerTagsPayload | Tags[];

export default function ShortcutAction({
  defaultTicketCategory,
  defaultCustomerTags,
  ticketCategory,
  customerTags,
  onChangeCustomerTags,
  onChangeTicketCategory,
}: {
  ticketCategory: TicketCategoryForm;
  customerTags: CustomerTagsForm;
  onChangeTicketCategory: (val: TicketCategoryForm) => void;
  onChangeCustomerTags: (val: CustomerTagsForm) => void;
  defaultTicketCategory: TicketCategoryPayload;
  defaultCustomerTags: CustomerTagsPayload;
}) {
  const [showTicketCategory, setShowTicketCategory] = useState(
    !!defaultTicketCategory
  );
  const [showCustomerTags, setShowCustomerTags] = useState(
    !!defaultCustomerTags
  );

  const { data } = useSettings({
    enabled: showCustomerTags || showTicketCategory,
  });

  useEffect(() => {
    setShowTicketCategory(!!defaultTicketCategory);
  }, [defaultTicketCategory]);

  useEffect(() => {
    setShowCustomerTags(!!defaultCustomerTags);
  }, [defaultCustomerTags]);

  const isMobile = useResponsive('sm');

  return (
    <>
      {showTicketCategory && (
        <BBBSelect
          options={data?.ticketTags || []}
          optionLabel="label"
          optionValue="id"
          label="Set ticket category to"
          placeholder="Choose ticket category"
          containerClassName="mt-5"
          value={ticketCategory}
          onValueChange={onChangeTicketCategory}
        />
      )}
      {showCustomerTags && (
        <TagOptions
          isMulti
          containerClassName="min-w-full my-5 md:min-w-full"
          placeholder="Search tag"
          label={'Add this customer tag'}
          isSearchable={!isMobile}
          //@ts-ignore
          value={customerTags || null}
          onValueChange={onChangeCustomerTags}
          isCreatable={!isMobile}
          sensitive
        />
      )}
      {((!defaultTicketCategory && !showTicketCategory) ||
        (!defaultCustomerTags && !showCustomerTags)) && (
        <div className="my-5 flex flex-col lg:flex-row items-center lg:justify-center gap-2">
          <span>You can also:</span>
          <div className="flex items-center gap-2">
            {!defaultTicketCategory && !showTicketCategory && (
              <div
                className="flex items-center gap-0.5 cursor-pointer"
                onClick={() => {
                  setShowTicketCategory(true);
                  onChangeTicketCategory(data?.ticketTags?.[0] || null);
                }}
              >
                <PlusCircleIcon />
                <span className="font-bold underline">Set ticket category</span>
              </div>
            )}
            {!defaultTicketCategory &&
              !showTicketCategory &&
              !defaultCustomerTags &&
              !showCustomerTags && <span>or</span>}
            {!defaultCustomerTags && !showCustomerTags && (
              <div
                className="flex items-center gap-0.5 cursor-pointer"
                onClick={() => {
                  setShowCustomerTags(true);
                }}
              >
                <PlusCircleIcon />
                <span className="font-bold underline">Add customer tags</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
