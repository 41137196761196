import { EditorState } from 'draft-js';
import * as yup from 'yup';

export const richValidation = yup
  .mixed<EditorState>()
  .test('rich-test', 'This field is required', (value) => {
    return value ? value.getCurrentContent().hasText() : false;
  });

export const createRichValidation = (opts?: {
  maxChar?: number;
  requiredMessage?: string;
  maxCharMessage?: string;
}) =>
  yup
    .mixed<EditorState>()
    .test(
      'rich-test',
      opts?.requiredMessage || 'This field is required',
      (value) => {
        return value ? value.getCurrentContent().hasText() : false;
      }
    )
    .test(
      'max-char',
      opts?.maxCharMessage || 'Exceeds maximum character limit',
      (value) => {
        if (!value) return true; // Handle the case where value is undefined

        return opts?.maxChar
          ? value.getCurrentContent().getPlainText().length <= opts.maxChar
          : true;
      }
    );

export const createOtpRichValidation = () =>
  yup
    .mixed<EditorState>()
    .test('otp-code-test', 'Message must include {{otp.code}}', (value) => {
      if (!value) return false;
      const text = value.getCurrentContent().getPlainText();
      return text.includes('{{otp.code}}');
    });
