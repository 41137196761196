import { BBBCheckbox, BBBPrimarySwitch } from '@/components/ui';
import { cn } from '@/utils/styles';

type Required =
  | {
      hasRequired: true;
      required: boolean;
      onRequiredChange: (val: boolean) => void;
    }
  | {
      hasRequired: false;
      required?: never;
      onRequiredChange?: never;
    };

type Props = {
  icon: React.ReactNode;
  title: string | null;
  subtitle?: string;
  active: boolean;
  onActiveChange: (val: boolean | undefined) => void;
  onClick?: () => void;
} & Required;

export default function ItemCard({
  icon,
  title,
  subtitle,
  hasRequired,
  active,
  required,
  onRequiredChange,
  onActiveChange,
  onClick,
}: Props) {
  return (
    <div
      onClick={(e) => {
        onClick && onClick();
        e.stopPropagation();
      }}
      className={cn(
        'flex items-center px-5 py-6 gap-5  border border-neutral-30 rounded-2xl shadow shadow-[0px 3px 6px 0px rgba(155, 155, 155, 0.15)]',
        onClick && 'cursor-pointer'
      )}
    >
      {icon}
      <div className="font-semibold text-primary-main flex flex-1">
        <div className="flex flex-col">
          {title}
          {subtitle && (
            <div className="text-sm text-neutral-50">{subtitle}</div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-5">
        {hasRequired && active && (
          <BBBCheckbox
            label="Required"
            checked={required}
            onValueChange={onRequiredChange}
          />
        )}
        <BBBPrimarySwitch
          checked={active}
          onChange={onActiveChange}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
}
