import { env } from 'config/env';

import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBButton, BBBCard } from '@/components/ui';
import {
  useOldOnboarding,
  useUpdateOldOnboarding,
} from '@/hooks/onboarding/bitcrm';

function EnableWidget() {
  const { data } = useOldOnboarding('wa-widget-banner-embed-status');
  const { mutate: updateOnboarding } = useUpdateOldOnboarding();

  if (data?.lastStep) return null;

  return (
    <BBBCard
      title="Enable bitChat on Shopify theme"
      desc="Enable bitChat on Shopify theme to add Chat Widget to your store"
      className="mb-5"
      rightButton={
        <ShopifyAwareWrapper appType="BITCHAT" fallbackToChildren>
          {({ connectMiddleware }) => (
            <BBBButton
              text="Enable now"
              onClick={() => {
                connectMiddleware((data) => {
                  window.open(
                    `https://${data.domain}/admin/themes/current/editor?context=apps&activateAppId=${env.REACT_APP_BITCHAT_WIDGET_THEME_EXTENSION_UUID}/app-embed`,
                    '_blank'
                  );
                  updateOnboarding({
                    moduleKey: 'wa-widget-banner-embed-status',
                    lastStep: 1,
                  });
                });
              }}
            />
          )}
        </ShopifyAwareWrapper>
      }
    />
  );
}

export default EnableWidget;
