import api from '@/config/api';

export type FieldType =
  | 'phoneNumber'
  | 'gender'
  | 'dateOfBirth'
  | 'address'
  | 'single_line_text'
  | 'multi_line_text'
  | 'dropdown_list'
  | 'radio_button'
  | 'form_title'
  | 'form_sub_heading'
  | 'consent_disclaimer'
  | 'multiple_choice';

export type Orientation = 'vertical' | 'horizontal';

export type GroupSetting = 'basic' | 'address' | 'custom' | 'title';

export type LoginSetting = {
  type: FieldType;
  mandatory: boolean | null;
  active: boolean | null;
  orientation: Orientation | null;
  options: string[] | null;
  label: string | null;
  key: string | null;
  group: GroupSetting | null;
  value: string | null;
  index: number | null;
};

export type LoginTitle = {
  title: string | null;
  subheading: string | null;
  disclaimer: boolean | null;
};

export default {
  getLoginSetting: (domain: string | undefined) =>
    api.bitLogin
      .get<LoginSetting[]>(`/login/settings/${domain}`)
      .then((res) => res.data),
  upsertLoginSetting: (
    domain: string | undefined,
    payload: UpsertLoginSettingPayload
  ) =>
    api.bitLogin
      .post(`/login/settings/${domain}`, {
        ...payload,
      })
      .then((res) => res.data),
  deleteLoginSetting: (
    domain: string | undefined,
    payload: DeleteLoginSettingPayload
  ) =>
    api.bitLogin
      .delete(`/login/settings/${domain}`, {
        data: {
          key: payload.key,
        },
      })
      .then((res) => res.data),
};

export type UpsertLoginSettingPayload = Partial<LoginSetting>;
export type DeleteLoginSettingPayload = {
  key: string | null;
};
