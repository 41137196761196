import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';
import Cookies from 'universal-cookie';

import UpsellIcon from '@/assets/icons/UpsellIcon';
import { BBBButton, BBBCard, Link } from '@/components/ui';
import { env } from '@/config/env';
import useResponsive from '@/hooks/common/useResponsive';
import { AppType } from '@/types/systemLogin';

const cookies = new Cookies(null, { path: '/' });

type Props = {
  title: string;
  description?: string;
  appType: AppType;
  className?: string;
  id: string;
  submitText?: string;
  submitHref?: string;
  onlyGuides?: boolean;
};

function BBBUpsellCard({
  title,
  description,
  appType,
  className,
  id: _id,
  submitText = 'Upgrade',
  submitHref,
  onlyGuides,
}: Props) {
  const isMobile = useResponsive('sm');
  const [show, setShow] = useState(true);

  const id = `upsell-banner-${appType.toLowerCase()}-${_id}`;

  const cookieValues = cookies.get(id);

  function setNextCookie() {
    const BANNER_DELAY: [number, dayjs.ManipulateType] = [
      onlyGuides ? 9999 : 2,
      'days',
    ];

    cookies.set(
      id,
      JSON.stringify({
        showAt: dayjs()
          .add(...BANNER_DELAY)
          .toISOString(),
      })
    );
  }

  const handleCancelAdvertisement = () => {
    setNextCookie();
    setShow(false);
  };

  const SubmitButton = (
    <BBBButton size={isMobile ? 'sm' : 'md'} text={submitText} />
  );

  if (!show) return null;

  if (cookieValues && !dayjs().isAfter(dayjs(cookieValues.showAt))) return null;

  return (
    <>
      <BBBCard
        className={cx(
          'mb-cardBottom flex lg:flex-row flex-col items-center gap-4 border-2 border-secondary-main',
          className
        )}
      >
        {!onlyGuides && !isMobile ? (
          <div className="flex-none">
            <UpsellIcon />
          </div>
        ) : null}
        <div className="grow">
          <div className="font-bold mb-1">{title}</div>
          <div className="text-sm">{description}</div>
        </div>
        <div className="flex lg:items-center gap-2.5">
          <BBBButton
            text="Not now"
            size={isMobile ? 'sm' : 'md'}
            variant="secondary"
            onClick={handleCancelAdvertisement}
          />
          {submitHref ? (
            <a href={submitHref} target="_blank" rel="noreferrer">
              {SubmitButton}
            </a>
          ) : (
            <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
              {SubmitButton}
            </Link>
          )}
        </div>
      </BBBCard>
    </>
  );
}

export default BBBUpsellCard;
