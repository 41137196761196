import api from 'config/api';

export default {
  getCustomerTagByCompany: (companyId: number | undefined) =>
    api.ai.v1
      .get<CustomerPrompt>(`/customerTag/${companyId}/prompt`)
      .then((res) => res.data),
  getCustomerTagByCompanyAndTicket: (
    companyId: number | undefined,
    ticketId: string | undefined
  ) =>
    api.ai.v1
      .get<CustomerPromptTags>(`/customerTag/${companyId}/result/${ticketId}`)
      .then((res) => res.data),
  upsertCustomerTagPrompt: (
    companyId: number | undefined,
    payload: Partial<PromptExperiments>
  ) =>
    api.ai.v1
      .put<{
        prompt_experiment_1_updated: boolean;
        prompt_experiment_2_updated: boolean;
      }>(`/customerTag/${companyId}/prompt`, payload)
      .then((res) => res.data),
};

export type CustomerPrompt = {
  prompt_1: string;
  prompt_2: string;
};

export type CustomerPromptTags = {
  prompt_1_tags: string[];
  prompt_2_tags: string[];
};

export type PromptExperiments = {
  prompt_experiment_1: string;
  prompt_experiment_2: string;
};
