import { useEffect } from 'react';
import { PricingFeatureNames } from 'api/services/pricing';
import { useAppDispatch } from 'hooks/rtk/store';
import { setPricingAlert } from 'stores/common';
import { usePricingFeature } from './v2/pricing';
import usePricingOptions from './usePricingOptionsByApp';

function useWithCRMPricing(
  appType: 'BITCHAT' | 'BITCRM' | 'BITAI',
  featureName: PricingFeatureNames,
  options?: { enabled?: boolean }
) {
  const dispatch = useAppDispatch();

  const { data, status } = usePricingFeature(
    { app: appType, featureName },
    options
  );

  const { data: pricingOptions, status: pricingOptionsStatus } =
    usePricingOptions(
      appType,
      //@ts-ignore
      data?.companyPricing.type,
      {
        enabled:
          (typeof options?.enabled !== 'undefined' ? options.enabled : true) &&
          !!data,
      }
    );

  useEffect(() => {
    return () => {
      dispatch(setPricingAlert(null));
    };
  }, [dispatch]);

  const limit = data
    ? //@ts-ignore
      data.pricingFeature.pricingFeatureDetails.find(
        //@ts-ignore
        (detail) => detail.pricingName === data.companyPricing.pricingName
      )?.limit || Infinity
    : 0;

  const isReachingTreshold = data ? data.usage >= limit : false;

  return {
    data: data,
    isReachingTreshold,
    limit,
    usage: data?.usage || 0,
    validatePricing: (_cb: () => void) => {
      if (status === 'success' && pricingOptions) {
        if (isReachingTreshold) {
          dispatch(
            setPricingAlert({
              type: 'redirect',
              appType: appType,
              show: true,
              desiredPlan:
                pricingOptions[
                  pricingOptions.findIndex(
                    (opt) => opt.name === data!.companyPricing.pricingName
                  ) + 1
                ].name,
            })
          );
        } else {
          _cb();
        }
      }
    },
    loading: pricingOptionsStatus !== 'success',
  };
}

export default useWithCRMPricing;
