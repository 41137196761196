function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M20 12H4"
      ></path>
    </svg>
  );
}

export default MinusIcon;
