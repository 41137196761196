import Skeleton from 'react-loading-skeleton';

type Props = {
  loading: boolean;
  error?: boolean;
  imgUrl?: string;
  title?: string;
};

function CoPilotProductRecommendation({
  loading,
  error,
  imgUrl,
  title,
}: Props) {
  if (loading) {
    return (
      <div className="flex gap-3 items-center mb-2">
        <Skeleton width={64} height={64} borderRadius={8} />
        <div>
          <Skeleton width={256} height={8} />
          <Skeleton width={144} height={8} />
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div className="flex gap-3 items-center mb-2">
      {imgUrl && (
        <img
          alt="product-recommend"
          src={imgUrl}
          className="w-16 h-16 object-cover rounded-lg"
        />
      )}
      <div>
        <div className="text-primary-main mb-2 line-clamp-2">{title}</div>
      </div>
    </div>
  );
}

export default CoPilotProductRecommendation;
