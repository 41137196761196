import { useQuery } from '@tanstack/react-query';

import api from '@/config/api';
import { Linklists } from '@/types/bitLink/v2';

const useLinkById = (id: number, options?: { enabled?: boolean }) =>
  useQuery(
    ['bitlink-links', id],
    () =>
      api.bitLink
        .get<Linklists>(`/linklist/link/${id}`)
        .then((res) => res.data),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );

export default useLinkById;
