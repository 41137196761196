const SentimentNeutralIcon = ({
  size = 20,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2036_22050)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0007 2.49967C5.85852 2.49967 2.50065 5.85754 2.50065 9.99967C2.50065 14.1418 5.85852 17.4997 10.0007 17.4997C14.1428 17.4997 17.5007 14.1418 17.5007 9.99967C17.5007 5.85754 14.1428 2.49967 10.0007 2.49967ZM0.833984 9.99967C0.833984 4.93706 4.93804 0.833008 10.0007 0.833008C15.0633 0.833008 19.1673 4.93706 19.1673 9.99967C19.1673 15.0623 15.0633 19.1663 10.0007 19.1663C4.93804 19.1663 0.833984 15.0623 0.833984 9.99967Z"
          fill="#9E9E9E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66732 6.625C7.12756 6.625 7.50065 6.9981 7.50065 7.45833V7.54167C7.50065 8.0019 7.12756 8.375 6.66732 8.375C6.20708 8.375 5.83398 8.0019 5.83398 7.54167V7.45833C5.83398 6.9981 6.20708 6.625 6.66732 6.625ZM13.334 6.625C13.7942 6.625 14.1673 6.9981 14.1673 7.45833V7.54167C14.1673 8.0019 13.7942 8.375 13.334 8.375C12.8737 8.375 12.5007 8.0019 12.5007 7.54167V7.45833C12.5007 6.9981 12.8737 6.625 13.334 6.625ZM5.83398 11.6667C5.83398 11.2064 6.20708 10.8333 6.66732 10.8333H13.334C13.7942 10.8333 14.1673 11.2064 14.1673 11.6667C14.1673 12.1269 13.7942 12.5 13.334 12.5H6.66732C6.20708 12.5 5.83398 12.1269 5.83398 11.6667Z"
          fill="#9E9E9E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2036_22050">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SentimentNeutralIcon;
