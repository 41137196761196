import dayjs from 'dayjs';

import { useAppSelector } from '@/hooks/rtk/store';

const useShouldInitChat = () => {
  return useAppSelector((state) => {
    const selectedChatState = state.bitCRM.selectedChat;

    if (!selectedChatState) return false;

    if (selectedChatState.sources !== 'WHATSAPP_META') return false;

    if (dayjs().isBefore(dayjs(selectedChatState.expiredAt))) {
      return false;
    }

    return true;
  });
};

export default useShouldInitChat;
