import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import integrationServices from 'api/services/integration';
import { useActiveCompany } from '../rtk/selector';

const useInstagramInfo = (
  clientNumber: string,
  options: Omit<
    UseQueryOptions<any, unknown, any, (string | number)[]>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['instagram-info', activeCompany, clientNumber],
    () =>
      integrationServices.instagram.getInstagramInfo(
        activeCompany,
        clientNumber
      ),
    {
      ...options,
    }
  );
};

export default useInstagramInfo;
