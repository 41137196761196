import { env } from 'config/env';
import defaultRedirectionPath from './defaultRedirectOptions';

export const CIRCLE_IMAGE_GRIDS = [
  {
    title: 'Women',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%201.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
  {
    title: 'Men',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%202.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
  {
    title: 'Shoes',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%205.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
  {
    title: 'Jewelry',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%203.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
  {
    title: 'Bags',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%206.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
  {
    title: 'Hat',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/presets/circle-image-grid/circle%20image%20grid%204.jpg`,
    active: true,
    link: defaultRedirectionPath,
    imageGridContentId: 0,
  },
];
