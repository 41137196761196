function ArrowRight({ size = 67 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 67 67"
    >
      <path
        stroke="#262627"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        d="M11.5 33.5h6.875m37.125 0L39 17m16.5 16.5L39 50m16.5-16.5H26.625"
      ></path>
    </svg>
  );
}

export default ArrowRight;
