export const optionsDateFilter = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 Days',
    value: 'week',
  },
  {
    label: 'Last 30 Days',
    value: '30-days',
  },
  {
    label: 'Last 90 Days',
    value: '90-days',
  },
  {
    label: 'Last 365 Days',
    value: '365-days',
  },
  {
    label: 'Last Month',
    value: 'month',
  },
  {
    label: 'Last 12 Month',
    value: '12-months',
  },
  {
    label: 'Last Year',
    value: 'year',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export default optionsDateFilter;
