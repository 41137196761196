import useQuerySearchParams from './url/useQuerySearchParams';

import { env } from '@/config/env';
import { AppType } from '@/types/systemLogin';

export const embedQueryValues = encodeURIComponent(
  `embed-${env.REACT_APP_EMBED_KEY}`
);

const useAppEmbed = () => {
  const query = useQuerySearchParams();
  const version = query.get('v');
  const app = query.get('app') as AppType;

  const isEmbedVersion = _isEmbed(version);

  return {
    isEmbedVersion,
    embedQuery: `v=${embedQueryValues}${app ? `&app=${app}` : ''}`,
  };
};

function _isEmbed(_version: string | null) {
  return _version === embedQueryValues;
}

export const isEmbed = _isEmbed(
  new URLSearchParams(window.location.search).get('v')
);

export default useAppEmbed;
