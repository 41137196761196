import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import useSettings from 'hooks/bitChat/settings/useSettings';
import AgentOptions from 'pages/BitChat/components/AgentOptions';
import { FormSchema } from '..';

import { BBBCheckbox, BBBSelect } from '@/components/ui';
import { Link } from '@/components/ui/Link';

type Props = {
  control: Control<FormSchema>;
};

export default function Ticket({ control }: Props) {
  const settingsQuery = useSettings();

  const { errors } = useFormState({
    control,
  });

  const convertToTicket = useWatch({
    control,
    name: 'convertToTicket',
  });

  return (
    <>
      <div className="my-4">
        <Controller
          control={control}
          name="convertToTicket"
          render={({ field }) => (
            <BBBCheckbox
              checked={field.value}
              onValueChange={field.onChange}
              label="Convert campaign message into a ticket"
            />
          )}
        />
      </div>
      {convertToTicket && (
        <div className="flex flex-col gap-6">
          <Controller
            control={control}
            name="ticketCategory"
            render={({ field }) => (
              <BBBSelect
                label="Ticket category"
                options={settingsQuery.data?.ticketTags}
                value={field.value}
                onValueChange={field.onChange}
                optionLabel="label"
                optionValue="id"
                placeholder="Choose category"
                error={errors.ticketCategory?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="agent"
            render={({ field }) => (
              <AgentOptions
                label="Assign ticket to:"
                // @ts-ignore
                value={field.value}
                onValueChange={field.onChange}
                placeholder="Choose asignee"
                error={errors.agent?.message}
                includeSelf
                withAiAgent
                withEveryone
              />
            )}
          />
          <div className="flex flex-col gap-3 items-start">
            <Controller
              control={control}
              name="ignoreAutoResolve"
              render={({ field }) => (
                <BBBCheckbox
                  disabled={!settingsQuery.data?.autoResolveTicket}
                  checked={field.value}
                  onValueChange={field.onChange}
                  label="Ignore auto-resolve"
                  labelClassName="text-neutral-60"
                />
              )}
            />
            {!settingsQuery.data?.autoResolveTicket && (
              <p className="text-primary-main">
                Haven&apos;t set up auto-resolve yet?{' '}
                <Link
                  to="/bitchat/settings?section=livechat-ticket"
                  className="text-info-main underline cursor-pointer"
                >
                  Set up here
                </Link>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
