import { ApiResponse } from 'api/types';
import api from 'config/api';
import { CategoryResponse } from 'types/bitApp/association';
import { Category, SubCategory } from 'types/bitApp/v2';

export default {
  getCategories: () =>
    api.bitApp
      .get<ApiResponse<Pick<CategoryResponse, 'data'>>>(`/category`)
      .then((res) => res.data.data),
  getCategoryByCompanyId: (
    companyId: number | undefined,
    params?: {
      offset?: number;
    }
  ) =>
    api.bitApp
      .get<ApiResponse<CategoryResponse>>(`/category/${companyId}`, {
        params,
      })
      .then((res) => res.data.data),
  getCategoryById: (
    categoryId: number | undefined,
    companyId: number | undefined
  ) =>
    api.bitApp
      .get<ApiResponse<Category>>(
        `/category/${categoryId}/company/${companyId}/detail`
      )
      .then((res) => res.data.data),
  rearrangeCategory: (
    companyId: number | undefined,
    payload: {
      data: {
        id: number;
        position: number;
      }[];
    }
  ) =>
    api.bitApp
      .post<CategoryResponse>(`/category/rearrange/${companyId}`, payload)
      .then((res) => res.data.data),
  addCategory: (
    companyId: number | undefined,
    payload: Pick<
      Category,
      'active' | 'position' | 'title' | 'idCollection'
    > & {
      subCategories: Omit<SubCategory, 'id'>[];
    }
  ) =>
    api.bitApp
      .post<ApiResponse<Category>>(`/category/${companyId}`, payload)
      .then((res) => res.data.data),
  updateCategory: (
    categoryId: number | undefined,
    companyId: number | undefined,
    payload: Partial<
      Pick<Category, 'active' | 'position' | 'title' | 'idCollection'> & {
        subCategories: Omit<SubCategory, 'id'>[];
      }
    >
  ) =>
    api.bitApp
      .post<ApiResponse<Category>>(
        `/category/${categoryId}/company/${companyId}`,
        payload
      )
      .then((res) => res.data.data),
  deleteCategory: (companyId: number | undefined, categoryId: number) =>
    api.bitApp
      .delete<ApiResponse<Pick<CategoryResponse, 'data'>>>(
        `/category/${categoryId}/company/${companyId}`
      )
      .then((res) => res.data.data),
};
