import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Skeleton from 'react-loading-skeleton';
import { createId } from '@paralleldrive/cuid2';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import CoPilot from './CoPilot/CoPilot';
import ChatBubble from './ChatBubble';

import { BBBSpinner } from '@/components/ui';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import useChatMessages, {
  findScrollableMessagePanel,
} from '@/hooks/whatsApp/useChatMessages';
import { useFocusMessage } from '@/hooks/whatsApp/useClickMessage';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import {
  hideSeparator,
  setActiveSeparator,
  setSearchedMessageId,
} from '@/stores/bitCRM';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { cn } from '@/utils/styles';

dayjs.extend(isToday);

type Props = {
  coPilotQuickActionRef: HTMLDivElement | null | undefined;
};

export default function ChatBoard(props: Props) {
  return <_ChatBoard {...props} />;
}

function _ChatBoard(props: Props) {
  return <__ChatBoard {...props} />;
}

function __ChatBoard({ ...props }: Props) {
  const selectedChatKey = useAppSelector((s) =>
    s.bitCRM.selectedChat ? `${s.bitCRM.selectedChat.id}` : undefined
  );

  return (
    <div className="grow relative flex flex-col" key={selectedChatKey}>
      <SearchInfo />
      <div className="grow relative">
        <div className="absolute inset-0">
          <div
            className={cx(
              'flex h-full overflow-y-auto relative overflow-x-hidden',
              selectedChatKey ? 'flex-col-reverse' : 'flex-col justify-center'
            )}
            id="chat-bubbles"
          >
            <div id="bottom-bubble" />
            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SearchInfo() {
  const isSearch = useAppSelector(
    (state) => !!state.bitCRM.selectedChat?.messageId
  );

  const dispatch = useAppDispatch();

  if (!isSearch) return null;

  return (
    <div className="p-2 text-center text-gray-500">
      You are viewing older messages.{' '}
      <span
        className="underline font-medium cursor-pointer"
        onClick={() => dispatch(setSearchedMessageId(undefined))}
      >
        See recent messages.
      </span>
    </div>
  );
}

function Content({ ...props }: Props) {
  const searchedMessageId = useAppSelector(
    (state) => state.bitCRM.selectedChat?.messageId
  );
  const isGroup = useIsGroupChat();
  const focusMessage = useFocusMessage();

  const {
    data,
    isInitialLoading: loadingChatList,
    fetchNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    hasNextPage,
    status,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useChatMessages();

  const flattenedData = (data?.pages || [])?.flatMap((page) => page.response);

  const groupedMessages = groupMessages(flattenedData);

  useEffect(() => {
    if (status === 'success' && searchedMessageId) {
      focusMessage({ id: searchedMessageId });
    }
  }, [focusMessage, searchedMessageId, status]);

  return (
    <>
      <CoPilot {...props} />
      <ScrollableComponent
        className="flex flex-col-reverse pt-3 infinite-scrollable"
        onScrollToBottom={async () => {
          if (hasNextPage) {
            await fetchNextPage();
          }
        }}
        onScrollToTop={async () => {
          if (hasPreviousPage) {
            await fetchPreviousPage();

            pullScrollableMessageUp();
          }
        }}
      >
        <ActiveSeparator />
        {isFetchingPreviousPage && <LoadNext type="prev" />}
        {loadingChatList ? (
          <ChatBubbleSkeleton />
        ) : (
          groupedMessages.map((message) => (
            <ChatBubble
              chat={message}
              key={'__typename' in message ? message.id : message.messageId2}
              isGroup={!!isGroup}
            />
          ))
        )}
        {isFetchingNextPage && <LoadNext type="next" />}
      </ScrollableComponent>
    </>
  );
}

function ActiveSeparator() {
  const activeSeparator = useAppSelector((s) => s.bitCRM.activeSeparator);

  if (!activeSeparator) return null;

  const chatBubblesRef = document.getElementById('chat-bubbles');

  if (!chatBubblesRef) return null;

  return createPortal(
    <motion.div
      style={{
        position: 'absolute',
        top: 8,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      className={cn('bg-[#9A9A9A] rounded-md px-3 py-2 text-white')}
      animate={{ opacity: activeSeparator.show ? 1 : 0 }}
      transition={{ type: 'tween', duration: 0.15 }}
    >
      {activeSeparator.label}
    </motion.div>,
    chatBubblesRef
  );
}

function LoadNext({ type }: { type: 'next' | 'prev' }) {
  return (
    <div className="my-3 text-center flex justify-center opacity-50 items-center gap-2">
      <span>Loading {type === 'next' ? 'older' : 'newer'} messages</span>
      <BBBSpinner width={2} height={10} />
    </div>
  );
}

function ChatBubbleSkeleton() {
  return (
    <div className="px-4 my-3">
      {Array.from({ length: 12 }).map((_, i) => (
        <div
          className={cx(
            'flex mb-3',
            i % 2 === 0 ? 'justify-start' : 'justify-end'
          )}
          key={i}
        >
          <Skeleton width={300} height={50} borderRadius={'1rem'} />
        </div>
      ))}
    </div>
  );
}

type ScrollableComponentProps = {
  onScrollToBottom?: () => void;
  onScrollToTop?: () => void;
  className?: string;
};

const ScrollableComponent: React.FC<ScrollableComponentProps> = ({
  onScrollToBottom,
  onScrollToTop,
  children,
  className,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const separatorRef = document.querySelectorAll(
        '[data-message-separator="true"]'
      );

      const separatorMeta: { offset: number; label: string }[] = [];

      separatorRef.forEach((separator) => {
        separatorMeta.push({
          label: separator.getAttribute('data-message-separator-label')!,
          offset:
            separator.getBoundingClientRect().bottom -
            scrollContainer.getBoundingClientRect().top +
            scrollContainer.scrollTop,
        });
      });

      let timeoutId: NodeJS.Timeout | null = null;

      const handleScroll = () => {
        const activeSeparator = separatorMeta.find(
          (meta) => scrollContainer.scrollTop > meta.offset
        );

        dispatch(
          setActiveSeparator(
            activeSeparator
              ? { label: activeSeparator.label, show: true }
              : null
          )
        );

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          dispatch(hideSeparator());
          timeoutId = null;
        }, 2000);

        if (Math.abs(scrollContainer.scrollTop) <= 1) {
          if (onScrollToTop) {
            onScrollToTop();
          }
        } else if (
          Math.abs(
            scrollContainer.scrollHeight -
              scrollContainer.clientHeight -
              Math.abs(scrollContainer.scrollTop)
          ) <= 1
        ) {
          if (onScrollToBottom) {
            onScrollToBottom();
          }
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [dispatch, onScrollToBottom, onScrollToTop]);

  return (
    <div ref={scrollContainerRef} className={cx('overflow-y-auto', className)}>
      {children}
    </div>
  );
};

function pullScrollableMessageUp() {
  const scrollableRef = findScrollableMessagePanel();
  scrollableRef.scrollTop = -1 * (scrollableRef.clientHeight * 0.5);
}

function groupMessages(messages: MessageNewAssociation[]): RenderedMessage[] {
  const newMessages: RenderedMessage[] = [];

  for (let i = 0; i < messages.length; i += 1) {
    const next = messages[i + 1];
    const curr = messages[i];

    if (next) {
      if (
        dayjs(curr.timestamp).startOf('day').toISOString() !==
        dayjs(next.timestamp).startOf('day').toISOString()
      ) {
        newMessages.push(curr);

        newMessages.push({
          __typename: 'dateDivider',
          label: getLabel(curr.timestamp),
          id: createId(),
        });
      } else {
        newMessages.push(curr);
      }
    } else {
      newMessages.push(curr);
      newMessages.push({
        __typename: 'dateDivider',
        label: getLabel(curr.timestamp),
        id: createId(),
      });
    }
  }

  return newMessages;
}

function getLabel(date: string) {
  let label;
  const timestampToDayjs = dayjs(date);

  if (timestampToDayjs.isToday()) {
    label = 'Today';
  } else if (timestampToDayjs.isYesterday()) {
    label = 'Yesterday';
  } else if (timestampToDayjs.year() === dayjs().year()) {
    label = timestampToDayjs.format('MMMM D');
  } else {
    label = timestampToDayjs.format('MMMM D YYYY');
  }

  return label;
}

export type RenderedMessage = MessageNewAssociation | DividerMessage;

export type DividerMessage = {
  __typename: 'dateDivider';
  label: string;
  id: string;
};
