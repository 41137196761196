import { useMutation } from '@tanstack/react-query';
import { LoginPayload, LoginResponseData } from './useLogin';

import api from '@/config/api';

const useOauthLogin = () =>
  useMutation(async (payload: LoginPayload) => {
    const {
      data: { data },
    } = await api.systemLogin.post<{ data: LoginResponseData }>(
      `/auth/login`,
      payload
    );
    return data;
  });

export default useOauthLogin;
