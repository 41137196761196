import { useEffect } from 'react';
import { useParams } from 'react-router';
import { createId } from '@paralleldrive/cuid2';
import { shallow } from 'zustand/shallow';
import { useAutoPosition } from '../../hooks';
import useStore, { RFState } from '../../store';
import ActionModal from './ActionModal';
import MessageModal from './MessageModal';
import TriggerAIModal from './TriggerAIModal';
import TriggerMessageModal from './TriggerMessageModal';
import WelcomeActionModal from './WelcomeActionModal';

import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';

const selector = (state: RFState) => ({
  showStateModal: state.showStateModal,
  addRelations: state.addRelations,
  updateMessageNode: state.updateMessageNode,
  onChangeStateModal: state.onChangeStateModal,
  updateActionNode: state.updateActionNode,
  triggerModalState: state.triggerModalState,
  onChangeTriggerModalState: state.onChangeTriggerModalState,
  updateTriggerAINode: state.updateTriggerAINode,
});

export default function Modals() {
  const {
    showStateModal,
    addRelations,
    updateMessageNode,
    onChangeStateModal,
    updateActionNode,
    triggerModalState,
    onChangeTriggerModalState,
    updateTriggerAINode,
  } = useStore(selector, shallow);

  const setExpandState = useStore((s) => s.setExpandState);

  const queryParams = useQuerySearchParams();

  const { id } = useParams<{ id: string }>();
  const triggerType = queryParams.get('trigger');

  useEffect(() => {
    if (id === 'new' && triggerType) {
      onChangeTriggerModalState({ type: triggerType });
    }
  }, [id, onChangeTriggerModalState, triggerType]);

  const autoPosition = useAutoPosition();

  return (
    <>
      {triggerModalState && (
        <>
          {triggerModalState.type === 'ai' && (
            <TriggerAIModal
              onSave={({ conditions, customConditions, ...props }) => {
                if (!triggerModalState.id) {
                  onChangeTriggerModalState(null);
                  const parentId = createId();
                  addRelations([
                    {
                      id: parentId,
                      type: 'trigger',
                      position: { x: 20, y: 100 },
                      data: { ...props, action: 'ai_auto_pilot' },
                    },
                    //@ts-ignore
                    ...conditions.map(({ id, value }) => {
                      return {
                        id,
                        type: 'child1',
                        position: { x: 0, y: 0 },
                        data: {
                          condition: value,
                          parentId,
                        },
                        draggable: false,
                        extent: 'parent',
                        parentNode: parentId,
                      };
                    }),
                    //@ts-ignore
                    ...customConditions.map(({ id, value }) => {
                      return {
                        id,
                        type: 'child1',
                        position: { x: 0, y: 0 },
                        data: {
                          customCondition: value,
                          parentId,
                        },
                        draggable: false,
                        extent: 'parent',
                        parentNode: parentId,
                      };
                    }),
                  ]);
                  setExpandState(conditions[0].id, 'clicked');
                } else {
                  updateTriggerAINode(triggerModalState.id, {
                    conditions,
                    customConditions,
                  });
                }
              }}
              nodeId={triggerModalState.id}
            />
          )}
          {triggerModalState.type === 'message' && (
            <TriggerMessageModal
              onSave={(data) => {
                if (!triggerModalState.id) {
                  const { messageCondition, replies, ..._data } = data;
                  const parentId = createId();
                  addRelations([
                    {
                      id: parentId,
                      type: 'trigger',
                      position: { x: 20, y: 100 },
                      data: {
                        ..._data,
                        action: messageCondition,
                      },
                    },
                    //@ts-ignore
                    ...replies.map(({ id, reply, index }) => {
                      return {
                        id,
                        type: 'child1',
                        position: { x: 0, y: 0 },
                        data: { reply, parentId, index },
                        draggable: false,
                        extent: 'parent',
                        parentNode: parentId,
                      };
                    }),
                  ]);
                  if (replies.length) {
                    setExpandState(replies[0].id, 'clicked');
                  }
                } else {
                  updateMessageNode(triggerModalState.id, data);
                }
              }}
              onClose={() => {
                onChangeTriggerModalState(null);
              }}
              nodeId={triggerModalState.id}
            />
          )}
          {triggerModalState.type === 'welcome-action' && (
            <WelcomeActionModal
              onSave={(data) => {
                if (!triggerModalState.id) {
                  const { replies, ..._data } = data;
                  const parentId = createId();
                  addRelations([
                    {
                      id: parentId,
                      type: 'welcome_action',
                      position: { x: 20, y: 100 },
                      data: {
                        ..._data,
                      },
                    },
                    //@ts-ignore
                    ...(replies ?? []).map(({ id, reply, index }) => {
                      return {
                        id,
                        type: 'child1',
                        position: { x: 0, y: 0 },
                        data: { reply, parentId, index },
                        draggable: false,
                        extent: 'parent',
                        parentNode: parentId,
                      };
                    }),
                  ]);
                  if (replies?.length) {
                    setExpandState(replies[0].id, 'clicked');
                  }
                } else {
                  updateMessageNode(triggerModalState.id, data);
                }
              }}
              onClose={() => {
                onChangeTriggerModalState(null);
              }}
              nodeId={triggerModalState.id}
            />
          )}
        </>
      )}
      {showStateModal?.type === 'action' && (
        <ActionModal
          onClose={() => onChangeStateModal(null)}
          nodeId={
            'nodeId' in showStateModal ? showStateModal.nodeId : undefined
          }
          onSave={(data) => {
            if ('sourceId' in showStateModal) {
              const position = autoPosition(showStateModal.sourceId);

              if (position) {
                const targetId = createId();

                const nodesData = [
                  {
                    id: targetId,
                    type: 'action',
                    data,
                    position: { x: position.x, y: position.y },
                  },
                ];

                addRelations(nodesData, [
                  {
                    id: createId(),
                    source: showStateModal.sourceId,
                    target: targetId,
                    type: 'custom',
                  },
                ]);
              }
            } else {
              updateActionNode(showStateModal.nodeId, data);
            }
          }}
        />
      )}
      {showStateModal?.type === 'message' && (
        <MessageModal
          onClose={() => onChangeStateModal(null)}
          nodeId={
            'nodeId' in showStateModal ? showStateModal.nodeId : undefined
          }
          onSave={(props) => {
            const { replies, ...data } = props;
            if ('sourceId' in showStateModal) {
              const position = autoPosition(showStateModal.sourceId);

              if (position) {
                const targetId = createId();

                const nodesData = [
                  {
                    id: targetId,
                    type: 'message',
                    data,
                    position: {
                      x: position.x,
                      y: position.y,
                    },
                  },
                  ...replies.map(({ id, reply }, index) => {
                    return {
                      id,
                      type: 'child1',
                      data: { reply, parentId: targetId, index },
                      position: { x: 0, y: 0 },
                      draggable: false,
                      extent: 'parent',
                      parentNode: targetId,
                    };
                  }),
                ];

                //@ts-ignore
                addRelations(nodesData, [
                  {
                    id: createId(),
                    source: showStateModal.sourceId,
                    target: targetId,
                    type: 'custom',
                  },
                ]);
              }
            } else {
              //@ts-ignore
              updateMessageNode(showStateModal.nodeId, props);
            }
          }}
        />
      )}
    </>
  );
}
