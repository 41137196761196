function PartyIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      fill="none"
      viewBox="0 0 110 110"
      {...props}
    >
      <path
        fill="#262627"
        d="M83.75 38.281c-1.875-2.135-3.802-4.27-5.833-6.302l-1.51-1.406.729-.625a16.406 16.406 0 004.687-18.177A20.364 20.364 0 0062.917.208v10.417a10.417 10.417 0 019.167 5.208c.885 2.084 0 4.375-2.448 6.823-.365.417-.782.677-1.146 1.042-15.625-12.552-32.76-19.635-41.146-11.25-.878.898-1.6 1.936-2.135 3.073v.52l-.938 2.761-22.969 68.23A17.083 17.083 0 0017.5 109.582a16.72 16.72 0 005.209-.885L94.167 85a5.208 5.208 0 002.24-1.615l1.093-.937c7.448-7.5 2.656-21.927-7.344-35.938a34.532 34.532 0 0119.636-4.635V31.458a43.958 43.958 0 00-26.042 6.823zM19.688 98.802a6.719 6.719 0 01-8.49-8.49l7.084-21.354a55.73 55.73 0 0022.343 22.865l-20.937 6.98zm36.458-12.135a46.198 46.198 0 01-32.812-32.76l5.208-15.626.312.469c.573 1.146 1.303 2.344 2.032 3.542.729 1.198.833 1.51 1.354 2.291.52.782 1.719 2.5 2.656 3.802.938 1.303.99 1.459 1.563 2.188.572.73 2.24 2.864 3.437 4.27l1.51 1.824c1.771 2.03 3.646 4.01 5.626 6.041 1.979 2.032 3.541 3.334 5.208 5.209l1.719 1.458 4.062 3.281 1.927 1.459c1.459 1.041 2.865 2.083 4.323 3.02l1.615 1.042c1.875 1.146 3.75 2.24 5.573 3.177h.26l-15.573 5.313zm33.906-11.615h-.312c-3.594 1.98-18.542-2.969-35.365-19.844-1.77-1.77-3.437-3.49-4.948-5.208l-1.51-1.823-2.76-3.333-1.51-2.084c-.678-.99-1.407-1.927-2.032-2.864l-1.354-2.188-1.51-2.448c-.417-.729-.73-1.406-1.094-2.083-.365-.677-.782-1.354-1.094-2.083-.28-.61-.523-1.237-.73-1.875-.26-.677-.572-1.354-.78-1.98-.209-.624-.261-.989-.417-1.614a33.803 33.803 0 01-.365-1.667 11.765 11.765 0 010-1.354 5.206 5.206 0 010-1.25l.573-1.614c1.875-1.875 11.615 0 24.635 9.895a21.51 21.51 0 01-6.979 1.823v10.417c5.533-.318 10.9-2 15.625-4.896l2.5 2.396c2.188 2.188 4.219 4.427 6.146 6.667a27.707 27.707 0 00-3.125 17.708l10.417-2.031a18.595 18.595 0 010-5.834c1.59 2.41 3 4.935 4.218 7.553 2.917 6.874 2.709 10.729 1.771 11.614z"
      ></path>
    </svg>
  );
}

export default PartyIcon;
