import { useLocation } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'config/api';
import { useAppSelector } from '../rtk/store';
import { InvitationResponseData } from './useInvitation';

import useHistory from '@/hooks/common/useHistory';
import { toast } from '@/utils/common/toast';

type ResendInviteReqBody = {
  userId: number;
};

const useResendInvitation = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const history = useHistory();
  const { pathname } = useLocation();
  return useMutation<InvitationResponseData, unknown, ResendInviteReqBody>(
    async (payload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(
        `/invitation/user/${payload.userId}/company/${activeCompany}/resend`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success(
          `An invitation email has been sent to ${data.invitedUserCompany.user.email}`
        );
        client.invalidateQueries(['user-company']);
        client.invalidateQueries(['company-users']);
        if (pathname !== '/settings') {
          history.push('/settings');
        }
      },
    }
  );
};

export default useResendInvitation;
