import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import services from '@/api/services';
import {
  DeleteLoginSettingPayload,
  FieldType,
  GroupSetting,
  LoginSetting,
  UpsertLoginSettingPayload,
} from '@/api/services/bitLogin/login/setting';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { toast } from '@/utils/common/toast';

export const useLoginSettings = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const pricingName = pricingData?.pricingName || 'free';

  return useQuery(
    ['bitlogin-login-setting', data?.domain],
    () => services.bitLogin.login.setting.getLoginSetting(data?.domain),
    {
      enabled:
        !!(status === 'success' && data?.domain) && pricingName !== 'free',
    }
  );
};

export const useLoginSetting = (type: GroupSetting) => {
  const { data: loginSetting } = useLoginSettings();

  const getSetting = (type: GroupSetting) =>
    loginSetting?.filter((setting) => setting.group === type);

  return getSetting(type);
};

export const useLoginSettingByType = (type: FieldType) => {
  const { data: loginSetting } = useLoginSettings();

  const getSetting = (type: FieldType): Partial<LoginSetting> =>
    loginSetting?.find((setting) => setting.type === type) || {};

  return getSetting(type);
};

export const useLoginTitleSetting = (type: FieldType) => {
  const data = useLoginSetting('title');

  const getSetting = (type: FieldType): Partial<LoginSetting> =>
    data?.find((setting) => setting.type === type) || {};

  return getSetting(type);
};

export const useLoginBasicSetting = (type: FieldType) => {
  const data = useLoginSetting('basic');

  const getSetting = (type: FieldType): Partial<LoginSetting> =>
    data?.find((setting) => setting.type === type) || {};

  return getSetting(type);
};

export const useLoginCustomSetting = (type: FieldType) => {
  const data = useLoginSetting('custom');

  const getSetting = (type: FieldType): Partial<LoginSetting> =>
    data?.find((setting) => setting.type === type) || {};

  return getSetting(type);
};

export const useLoginSetting2 = (domain: string | undefined) => {
  return useQuery(
    ['bitlogin-login-setting-with-domain', domain],
    () => services.bitLogin.login.setting.getLoginSetting(domain),
    {
      enabled: !!domain,
    }
  );
};

export const useActiveLoginSettings = (domain: string | undefined) => {
  return useQuery(
    ['bitlogin-login-setting-with-domain', domain],
    () => services.bitLogin.login.setting.getLoginSetting(domain),
    {
      enabled: !!domain,
      select: (data) => data.filter((d) => !!d.active),
    }
  );
};

export const useUpsertLoginSetting = (
  options?: Omit<
    UseMutationOptions<void, unknown, UpsertLoginSettingPayload, unknown>,
    'mutationFn'
  >
) => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UpsertLoginSettingPayload, unknown>(
    (payload: UpsertLoginSettingPayload) =>
      services.bitLogin.login.setting.upsertLoginSetting(data?.domain, payload),
    {
      ...options,
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          'bitlogin-login-setting',
          data?.domain,
        ]);

        const previousLoginSetting = queryClient.getQueryData([
          'bitlogin-login-setting',
          data?.domain,
        ]);

        queryClient.setQueryData<UpsertLoginSettingPayload[]>(
          ['bitlogin-login-setting', data?.domain],
          (old: UpsertLoginSettingPayload[] = []) => {
            if (old.some((setting) => setting.key === payload.key)) {
              const updatedData = old.map((setting) =>
                setting.key === payload.key
                  ? { ...setting, ...payload }
                  : setting
              );

              return updatedData;
            }

            return [...old, payload];
          }
        );

        return { previousLoginSetting };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-login-setting']);
        toast.success('Successfully update login setting');
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['bitlogin-login-setting', data?.domain],
          (context as { previousLoginSetting?: UpsertLoginSettingPayload[] })
            ?.previousLoginSetting
        );
      },
    }
  );
};

export const useDeleteLoginSetting = (
  options?: Omit<
    UseMutationOptions<void, unknown, DeleteLoginSettingPayload, unknown>,
    'mutationFn'
  >
) => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation<void, unknown, DeleteLoginSettingPayload, unknown>(
    (payload: DeleteLoginSettingPayload) =>
      services.bitLogin.login.setting.deleteLoginSetting(data?.domain, {
        key: payload.key,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-login-setting']);
        toast.success('Successfully update login setting');
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['bitlogin-login-setting', data?.domain],
          (context as { previousLoginSetting?: UpsertLoginSettingPayload[] })
            ?.previousLoginSetting
        );
      },
    }
  );
};

export const useTotalCollectDataPage = () => {
  const customSettingData = useLoginSetting('custom');
  const basicSettingData = useLoginSetting('basic');

  const hasCustomSettings = customSettingData?.some(
    (setting) => setting.active
  );
  const hasBasicSettings = basicSettingData?.some((setting) => setting.active);

  const { active: addressActive } = useLoginSettingByType('address');

  const getTotalPage = () => {
    let count = 0;

    if (hasBasicSettings) {
      count += 1;
    }

    if (hasCustomSettings) {
      count += 1;
    }

    if (addressActive) {
      count += 1;
    }

    return count;
  };

  return getTotalPage();
};
