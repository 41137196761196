const ChatIcon = ({
  width = 30,
  height = 28,
  className,
  color = '#FFFF',
}: {
  width?: number;
  height?: number;
  className?: string;
  color?: string;
} & JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 23.3333C23.028 23.3333 25.5427 23.3333 27.104 21.7707C28.6667 20.2093 28.6667 17.6947 28.6667 12.6667C28.6667 7.63867 28.6667 5.124 27.104 3.56267C25.5427 2 23.028 2 18 2H12.6667C7.63867 2 5.124 2 3.56267 3.56267C2 5.124 2 7.63867 2 12.6667C2 17.6947 2 20.2093 3.56267 21.7707C4.43333 22.6427 5.6 23.028 7.33333 23.1973"
        stroke={color}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3343 12.667V12.6803M10.001 12.667V12.6803M20.6676 12.667V12.6803M18.001 23.3337C16.353 23.3337 14.537 24.0003 12.8796 24.8603C10.2156 26.243 8.88364 26.935 8.22764 26.4937C7.57164 26.0537 7.69564 24.687 7.94497 21.955L8.00097 21.3337"
        stroke={color}
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChatIcon;
