function YotpoIcon({ size = 24 }: { size?: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 71.1 76"
      xmlSpace="preserve"
    >
      <g>
        <ellipse cx="36" cy="38.3" fill="#2F84ED" rx="33.1" ry="34.1"></ellipse>
      </g>
      <g fill="#FFF">
        <path d="M17.5 25.1L21.4 25.1 24.4 30.6 27.3 25.1 31 25.1 26.1 33.9 26.1 38.6 22.5 38.6 22.5 34z"></path>
        <path d="M29.9 31.9c0-4.1 3.3-6.9 7.1-6.9s7.1 2.9 7.1 6.9c0 4.1-3.3 7-7.1 7-3.8-.1-7.1-2.9-7.1-7m10.5 0c0-2.1-1.3-3.8-3.5-3.8s-3.5 1.7-3.5 3.8c0 2.1 1.3 3.8 3.5 3.8 2.3 0 3.5-1.7 3.5-3.8"></path>
        <path d="M47.4 28.1L43.9 28.1 43.9 25.1 54.5 25.1 54.5 28.1 51 28.1 51 38.6 47.4 38.6z"></path>
        <path d="M22.1 53.4h-3.5V39.9h5c3.6 0 5.6 2.3 5.6 5.3s-2 5.3-5.6 5.3h-1.5v2.9zm1.3-6c1.6 0 2.2-.9 2.2-2.2 0-1.3-.6-2.3-2.2-2.3h-1.3v4.5h1.3zM29.8 46.7c0-4.1 3.3-6.9 7.1-6.9s7.1 2.9 7.1 6.9c0 4.1-3.3 7-7.1 7-3.8-.1-7.1-2.9-7.1-7m10.6 0c0-2.1-1.3-3.8-3.5-3.8s-3.5 1.7-3.5 3.8c0 2.1 1.3 3.8 3.5 3.8s3.5-1.7 3.5-3.8M44 51.7c0-1.2.9-2 1.9-2 1 0 1.9.8 1.9 2s-.9 2-1.9 2c-1-.1-1.9-.9-1.9-2"></path>
      </g>
    </svg>
  );
}

export default YotpoIcon;
