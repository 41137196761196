import React, { useMemo, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { BBBSelect } from '@/components/ui';
import useArticle from '@/hooks/bitApp/shopify/useArticle';
import useArticles from '@/hooks/bitApp/shopify/useArticles';
import { ShopifyArticles } from '@/types/shopify/ShopifyBlogs';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';

type ShopifyArticleListsOptionsProps = {
  label: string | React.ReactNode;
  value: string | undefined | null;
  onChange: (val?: string | undefined | null) => void;
};

function ShopifyArticleListsOptions({
  label,
  value,
  onChange,
}: ShopifyArticleListsOptionsProps) {
  const [title, setTitle] = useState<string>();

  const memoizedParams = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const client = useQueryClient();

  const articlesQuery = useArticles(memoizedParams);

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = articlesQuery;

  const { data: articleData, isInitialLoading: isLoadingArticle } = useArticle(
    value,
    {
      initialData: client
        .getQueryData<
          InfiniteData<ShopifyConnection<ShopifyArticles>> | undefined
        >(['bitapp-infinite-articles', memoizedParams])
        ?.pages.flatMap((page) => page.edges.map((edge) => edge.node))
        .find((data) => data.id === value),
    }
  );

  const memoizedCurrentPages = useMemo(
    () => data?.pages.flatMap((page) => page.edges.map((edge) => edge.node)),
    [data?.pages]
  );

  return (
    <BBBSelect
      label={label}
      options={memoizedCurrentPages}
      optionLabel="title"
      loading={isInitialLoading || isLoadingArticle}
      optionValue="id"
      search={title}
      isSearchable
      onChangeSearch={setTitle}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      value={articleData}
      onValueChange={(val) => onChange(val?.id)}
    />
  );
}

export default ShopifyArticleListsOptions;
