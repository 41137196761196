export default function MailOpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#FD823E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 11.083a4 4 0 011.706-3.277l6-4.2a4 4 0 014.588 0l6 4.2A4 4 0 0122 11.083V19a2 2 0 01-2 2H4a2 2 0 01-2-2v-7.917z"
      ></path>
      <path
        stroke="#FD823E"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.5 9.5l7.001 5.501a4 4 0 004.998 0L21.5 9.5"
      ></path>
    </svg>
  );
}
