import { TooltipProps } from 'recharts';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { twMerge as cx } from 'tailwind-merge';

export type BBBCustomTooltipChartProps =
  | ({
      isCustomTooltip?: true;
      customContent?: (
        payload: Payload<ValueType, NameType>[] | undefined
      ) => JSX.Element;
      className?: string;
    } & TooltipProps<ValueType, NameType>)
  | ({
      isCustomTooltip?: false;
      customContent?: never;
      className?: never;
    } & TooltipProps<ValueType, NameType>);

const BBBCustomTooltipChart = ({
  active,
  payload,
  isCustomTooltip = false,
  customContent,
  className,
}: BBBCustomTooltipChartProps) => {
  if (active) {
    return (
      <div
        className={cx(
          'bg-neutral-70 !border-transparent opacity-70 rounded-lg text-neutral-10 p-2 stroke-none',
          className
        )}
      >
        {isCustomTooltip ? customContent?.(payload) : payload?.[0]?.value}
      </div>
    );
  }

  return null;
};
export default BBBCustomTooltipChart;
