import { Circle } from 'react-feather';

type PasswordCheckProps = {
  password: string;
};

function PasswordCheck({ password }: PasswordCheckProps) {
  const isUpperCase = (word: string) => {
    for (let i = 0; i < word.length; i += 1) {
      if (word[i].match(/[a-zA-z]/gm) && word[i].toUpperCase() === word[i]) {
        return true;
      }
    }
    return false;
  };

  const isNumber = (word: string) => {
    for (let i = 0; i < word.length; i += 1) {
      if (word[i].match(/[0-9]/gm)) {
        return true;
      }
    }
    return false;
  };

  const is8and16 = (word: string) => word.length >= 8 && word.length <= 16;

  const isSpecial = (word: string) => {
    const specialChars = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    for (let i = 0; i < specialChars.length; i += 1) {
      if (word.indexOf(specialChars[i]) > -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="flex gap-4">
      <div className="flex gap-2 items-center">
        <Circle
          fill={isNumber(password) ? '#22bb33' : '#FF6060'}
          stroke={isNumber(password) ? '#22bb33' : '#FF6060'}
          size={8}
        />
        <div
          className={`text-[12px] ${
            isNumber(password) ? 'text-[#22bb33]' : 'text-danger-main'
          }`}
        >
          Number
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Circle
          fill={isUpperCase(password) ? '#22bb33' : '#FF6060'}
          stroke={isUpperCase(password) ? '#22bb33' : '#FF6060'}
          size={8}
        />
        <div
          className={`text-[12px] ${
            isUpperCase(password) ? 'text-[#22bb33]' : 'text-danger-main'
          }`}
        >
          Upper case
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Circle
          fill={isSpecial(password) ? '#22bb33' : '#FF6060'}
          stroke={isSpecial(password) ? '#22bb33' : '#FF6060'}
          size={8}
        />
        <div
          className={`text-[12px] ${
            isSpecial(password) ? 'text-[#22bb33]' : 'text-danger-main'
          }`}
        >
          Symbols
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Circle
          fill={is8and16(password) ? '#22bb33' : '#FF6060'}
          stroke={is8and16(password) ? '#22bb33' : '#FF6060'}
          size={8}
        />
        <div
          className={`text-[12px] ${
            is8and16(password) ? 'text-[#22bb33]' : 'text-danger-main'
          }`}
        >
          8-16 characters
        </div>
      </div>
    </div>
  );
}

export default PasswordCheck;
