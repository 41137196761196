// import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setAccessToken } from '@/stores/auth';
import { Profile, SocialLoginProvider, User } from '@/types/systemLogin';
import { _localStorage } from '@/utils/common/localStorage';

export type LoginResponseData = {
  accessToken: string | undefined;
  user: User & {
    profile?: Profile;
  };
};

export type SocialLoginPayload = {
  email: string;
  provider: SocialLoginProvider;
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
};

export type LoginPayload = (
  | {
      email: string;
      password: string;
    }
  | SocialLoginPayload
) & {
  redirectUrl?: string;
};

const useLogin = () => {
  const dispatch = useAppDispatch();

  return useMutation<LoginResponseData, unknown, LoginPayload>(
    async (payload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/auth/login`, payload);
      return data;
    },
    {
      onSuccess: (data, payload) => {
        _localStorage.setItem('accessToken', data.accessToken as string);
        dispatch(setAccessToken(data.accessToken as string));
      },
    }
  );
};

export default useLogin;
