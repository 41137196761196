import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    'Unlimited Customer',
    '100 Ticket',
    '25 AI Chatbot Conversation',
    'AI Segmentation',
    'AI Training',
    'Product Recom',
    'Lead Collection',
    'Instagram',
  ],
  standard: [
    'WhatsApp API & Green Tick*',
    '1,000 Ticket',
    '300 AI Chatbot Conversation',
    '5 Custom AI Labeling',
    'Enhanced Analytics',
  ],
  pro: [
    '3,000 Ticket',
    '1,000 AI Chatbot Conversation',
    '10 Custom AI Labeling',
  ],
  pro1: [
    '10,000 Ticket',
    '3,000 AI Chatbot Conversation',
    '20 Custom AI Labeling',
    'Custom AI Prompt / AI agents',
  ],
  enterprise: ['API Access', 'Customizable Plan'],
};
