import React, { useCallback, useEffect, useRef } from 'react';
import { FileText, Plus, X } from 'react-feather';
import { createId } from '@paralleldrive/cuid2';
import { createSelector } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { twMerge as cx } from 'tailwind-merge';
import EmojiPicker from '../EmojiPicker';
import { acceptedFiles } from '..';

import FileIcon from '@/assets/icons/FileIcon';
import SentIcon2 from '@/assets/icons/SentIcon2';
import { BBBRichTextEditor, BBBSpinner } from '@/components/ui';
import colors from '@/constants/common/colors';
import { useUploadFiles } from '@/hooks/common/upload';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { liveChatMemoSelector } from '@/hooks/whatsApp/useActiveChatMemo';
import useSendMessage from '@/hooks/whatsApp/useSendMessage';
import { RootState } from '@/stores';
import { updateMediaCaption, upsertLivechatMemo } from '@/stores/bitCRM';
import { Media } from '@/types/whatsApp/chat';
import { MediaProcessed } from '@/types/whatsApp/chat';
import { humanFileSize } from '@/utils/common/file';
import { emptyEditor } from '@/utils/common/rich';

type Props = {
  show: boolean;
};

export const activeMediasSelector = createSelector(
  [liveChatMemoSelector],
  (data) => data?.mediaUrl
);

const richId = createId();

export default function AttachmentModal() {
  const hasMedias = useAppSelector((s) => {
    return s.bitCRM.selectedChat
      ? !!s.bitCRM.liveChatMemo[
          `${s.bitCRM.selectedChat.clientNumber}-${s.bitCRM.selectedChat.sources}`
        ]?.mediaUrl?.length
      : undefined;
  });

  if (!hasMedias) return null;

  return <_AttachmentModal show />;
}

function _AttachmentModal({ show }: Props) {
  const dispatch = useAppDispatch();
  const uploadFiles = useUploadFiles();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files?.length) {
        await uploadFiles(e.target.files as unknown as File[], {
          retainSelectedMedia: true,
        });
      }
    } finally {
      e.target.value = '';
    }
  };

  const close = useCallback(
    () =>
      dispatch(
        upsertLivechatMemo({
          selectedMedia: null,
          mediaUrl: [],
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    function escFunction(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        close();
      }
    }
    window.addEventListener('keydown', escFunction, false);

    return () => {
      window.removeEventListener('keydown', escFunction, false);
    };
  }, [close]);

  return (
    <div
      className={cx(
        `absolute flex flex-col gap-5 py-5 top-0 right-0 left-0 bottom-0 bg-neutral-30 z-[80] transform transition-transform`,
        show ? 'translate-y-0' : 'translate-y-full'
      )}
    >
      <div className="mx-3">
        <X cursor="pointer" onClick={close} />
      </div>
      <div className="grow relative">
        <MediaSelectedPreview />
      </div>

      <div className="flex gap-2 mb-3 py-2 items-center mx-3">
        <MediaCaption />
      </div>

      <div className="flex justify-between items-center mx-3">
        <div />
        <div className="w-3/4">
          <div className="flex gap-2 justify-center">
            <MediaPreviews />
            <div
              className="cursor-pointer my-1 flex-none w-[60px] h-[60px] rounded-2xl flex justify-center items-center outline outline-2 outline-neutral-40"
              onClick={() => {
                inputFile.current?.click();
              }}
            >
              <Plus className="text-primary-main" />
              <input
                type="file"
                ref={inputFile}
                className="hidden"
                onChange={handleChangeFile}
                accept={acceptedFiles}
                multiple
              />
            </div>
          </div>
        </div>

        <SendDirect />
      </div>
    </div>
  );
}

function SendDirect() {
  const hasUploadingMedias = useAppSelector(hasUploadingMediasSelector);

  const { mutate: sendMessage, isLoading: loadingSendMedia } = useSendMessage();

  return (
    <div
      className={cx(
        'self-end bg-primary-main rounded-lg transition-opacity p-3 cursor-pointer',
        (loadingSendMedia || hasUploadingMedias) &&
          'pointer-events-none opacity-25'
      )}
      onClick={() =>
        sendMessage({
          type: 'media',
        })
      }
    >
      <SentIcon2 />
    </div>
  );
}

function activeMediaSelector(s: RootState) {
  const memo = s.bitCRM.selectedChat
    ? s.bitCRM.liveChatMemo[
        `${s.bitCRM.selectedChat.clientNumber}-${s.bitCRM.selectedChat.sources}`
      ]
    : undefined;

  return memo?.mediaUrl?.find((_media) => _media.id === memo.selectedMedia);
}

function MediaSelectedPreview() {
  const activeMedia = useAppSelector(activeMediaSelector);

  if (!activeMedia) return null;

  return (
    <div className="absolute inset-0 flex justify-center items-center">
      {activeMedia.loading ? (
        <div>
          <BBBSpinner />
          <div className="text-center mt-4">
            Uploading ({activeMedia.progress}/100)
          </div>
        </div>
      ) : (
        <_MediaSelectedPreview media={activeMedia} />
      )}
    </div>
  );
}

function _MediaSelectedPreview({
  media: activeMedia,
}: {
  media: MediaProcessed;
}) {
  const mediaUrl = activeMedia.value;

  return activeMedia.type.includes('image') ? (
    <img src={mediaUrl} alt="livechat" className="drop-shadow-lg" width={336} />
  ) : activeMedia.type.includes('video') ? (
    <video
      src={mediaUrl}
      controls
      autoPlay
      className="object-contain h-full max-w-sm shadow-lg "
    />
  ) : activeMedia.type.includes('pdf') ? (
    <object
      data={mediaUrl}
      type="application/pdf"
      width={'100%'}
      height={'100%'}
    />
  ) : (
    <div>
      <div className="mb-[1.875rem] flex justify-center">
        <FileIcon size={100} color={colors.neutral[50]} />
      </div>
      <div className="text-center text-neutral-60 text-2xl mb-2">
        No preview available
      </div>
      {activeMedia.meta && (
        <div className="text-neutral-60 text-center">
          {humanFileSize(activeMedia.meta.size)} - {activeMedia.meta.name}
        </div>
      )}
    </div>
  );
}

function MediaPreviews() {
  const mediaUrls = useAppSelector(activeMediasSelector);
  const dispatch = useAppDispatch();
  const activeMedia = useAppSelector(activeMediaSelector);

  const handleClickPreviewImage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    data: Media
  ) => {
    if (mediaUrls?.length === 1) {
      dispatch(
        upsertLivechatMemo({
          selectedMedia: null,
          mediaUrl: [],
        })
      );
    } else {
      dispatch(
        upsertLivechatMemo({
          mediaUrl: (mediaUrls || []).filter((img) => img.id !== data.id),
          selectedMedia:
            activeMedia?.id === data.id
              ? mediaUrls?.[
                  mediaUrls?.findIndex(
                    (data) => data.id === activeMedia?.id
                  ) === 0
                    ? index + 1
                    : index - 1
                ].id
              : undefined,
        })
      );
    }
    e.stopPropagation();
  };

  return (
    <div className="flex gap-2 overflow-x-auto py-1 px-2">
      {mediaUrls?.map((data, index) => {
        if (data.loading) {
          return <BBBSpinner key={data.id} />;
        }

        const mediaUrl = data.value;

        return (
          <div
            key={data.id}
            onClick={() => {
              dispatch(
                upsertLivechatMemo({
                  selectedMedia: data.id,
                })
              );
            }}
            id="preview-image"
            className={cx(
              `relative flex-none w-16 h-16 rounded-2xl group outline outline-2 cursor-pointer`,
              activeMedia?.id === data.id
                ? 'outline-neutral-40'
                : 'outline-transparent'
            )}
          >
            <div
              className="opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 transition-opacity absolute top-[4px] right-[4px]"
              onClick={(e) => handleClickPreviewImage(e, index, data)}
            >
              <X color="white" size={16} />
            </div>

            {data.type.includes('image') ? (
              <img
                src={mediaUrl}
                alt="list-attachment"
                className="w-full h-full rounded-2xl shadow !max-w-none object-cover"
                loading="lazy"
              />
            ) : data.type.includes('video') ? (
              <video
                src={mediaUrl}
                className="z-0 w-full h-full rounded shadow !max-w-none object-cover"
              />
            ) : data.type.includes('pdf') ? (
              <object
                data={mediaUrl}
                type="application/pdf"
                width={'100%'}
                height={'100%'}
                className="pointer-events-none"
              />
            ) : data.type.includes('officedocument') ? (
              <FileText size={60} />
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <FileIcon size={28} color={colors.neutral[50]} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function MediaCaption() {
  const source = useAppSelector((s) => s.bitCRM.selectedChat?.sources);

  if (source === 'GOOGLE_MY_BUSINESS') return null;

  return <_MediaCaption />;
}

function _MediaCaption() {
  const message = useAppSelector((s) => {
    const currentMediaUrl = s.bitCRM.selectedChat
      ? s.bitCRM.liveChatMemo[
          `${s.bitCRM.selectedChat.clientNumber}-${s.bitCRM.selectedChat.sources}`
        ].mediaUrl?.[0]
      : undefined;

    return currentMediaUrl
      ? currentMediaUrl.message || emptyEditor
      : emptyEditor;
  });

  const hasUploadingMedias = useAppSelector(hasUploadingMediasSelector);

  const { mutate: sendMessage } = useSendMessage();
  const dispatch = useAppDispatch();

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!hasUploadingMedias) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        sendMessage({
          type: 'media',
        });
      }
    }
  };

  const handleChangeInputTextMedia = (value?: EditorState) => {
    dispatch(updateMediaCaption(value));
  };

  return (
    <BBBRichTextEditor
      containerClassName="grow"
      placeholder="Type a message"
      editorState={message}
      onChangeEditorState={handleChangeInputTextMedia}
      onKeyDown={onEnter}
      rows={1}
      id={richId}
      withoutInline
      customInlineComponent={
        <EmojiPicker
          editorState={message}
          onChangeEditorState={(val) => handleChangeInputTextMedia(val)}
        />
      }
    />
  );
}

function hasUploadingMediasSelector(s: RootState) {
  return s.bitCRM.selectedChat
    ? s.bitCRM.liveChatMemo[
        `${s.bitCRM.selectedChat.clientNumber}-${s.bitCRM.selectedChat.sources}`
      ]?.mediaUrl?.filter((media) => media.loading).length
    : undefined;
}
