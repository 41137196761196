import { useRef, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import { Model } from '@/api/services/bitAi/v2-new/model';
import { BBBBottomSheet } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import colors from '@/constants/common/colors';
import useDeleteModel from '@/hooks/bitAi/models/useDeleteModel';
import useDuplicateModel from '@/hooks/bitAi/models/useDuplicateModel';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { formatDate2 } from '@/utils/common/date';
import { toast } from '@/utils/common/toast';

export default function useColumn(): HeadersTypes<Model> {
  const [actionOption, setActionOption] = useState<string>();
  const optionRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(optionRef, () => setActionOption(''));

  const deleteModel = useDeleteModel();
  const duplicateModel = useDuplicateModel();

  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'advance_custom_model'
  );

  const advanceCustomModelUsage = pricingFeatureData?.usage ?? 0;

  const isLimitReached = advanceCustomModelUsage >= limit;

  return [
    {
      accesor: 'name',
      render: (row) => (
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-2">
            <div className="w-fit">
              <Link
                to={{
                  pathname: `/bitai/model/${row.id}`,
                  state: {
                    id: row.id,
                  },
                }}
                className="w-fit text-sm font-semibold text-primary-main group-hover:underline"
              >
                {row.name}
              </Link>
            </div>
            <p className="text-xs text-neutral-500">
              Last updated {formatDate2(row.updatedAt)}
            </p>
          </div>
          <MoreVertical
            onClick={(e) => {
              setActionOption(row.id);
              e.stopPropagation();
              e.preventDefault();
            }}
            color={colors.primary.main}
          />
          <MobileActions
            id={row.id}
            title="Actions"
            show={!!actionOption}
            onHide={() => setActionOption(undefined)}
            onDelete={(id) => {
              deleteModel(true)(id);
            }}
            onDuplicate={(id) => {
              duplicateModel(true)({
                modelId: id,
              });
            }}
            limit={limit}
            isLimitReached={isLimitReached}
          />
        </div>
      ),
    },
  ];
}

function MobileActions({
  id,
  show,
  title,
  limit,
  isLimitReached,
  onHide,
  onDelete,
  onDuplicate,
}: {
  id: string;
  show: boolean;
  title: string;
  onHide?: () => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  limit: number;
  isLimitReached: boolean;
}) {
  return (
    <BBBBottomSheet show={show} title={title} onClose={onHide}>
      <div className="flex flex-col gap-4">
        <Link
          to={{
            pathname: `/bitai/model/${id}`,
            state: {
              id,
            },
          }}
        >
          <div className="text-primary-main">Edit</div>
        </Link>
        <div
          onClick={(e) => {
            if (isLimitReached) {
              toast.success(
                `On a free plan, you can only have ${limit} advance custom model.`
              );
            } else {
              onDuplicate(id);
            }
            e.preventDefault();
          }}
          className={cx(
            'text-primary-main',
            isLimitReached ? 'opacity-50' : 'opacity-100'
          )}
        >
          Duplicate
        </div>
        <div className="text-primary-main" onClick={() => onDelete(id)}>
          Delete
        </div>
      </div>
    </BBBBottomSheet>
  );
}
