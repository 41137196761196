import { Clock } from 'react-feather';

import ArrowBack from '@/assets/icons/ArrowBack';
import MessageIcon from '@/assets/icons/MessageIcon';
import MoonIcon from '@/assets/icons/MoonIcon';
import SparklesIcon from '@/assets/icons/SparklesIcon';
import SunIcon from '@/assets/icons/SunIcon';
import ThunderIcon from '@/assets/icons/ThunderIcon';

export const triggerConditionOptions = [
  {
    label: 'Action',
    value: 'action',
    icon: <ThunderIcon />,
  },
  {
    label: 'Message',
    value: 'message',
    icon: <MessageIcon size={24} />,
  },
  {
    label: 'Back',
    value: 'back',
    icon: <ArrowBack />,
  },
];

export const triggerConditionOptionsWithoutBack =
  triggerConditionOptions.filter((trigger) => trigger.value !== 'back');

export const fallbackOptions = [
  { label: 'Assign to agent', value: 'assign_to_agent' },
  { label: 'Send message', value: 'send_message' },
];

export const aiTriggerConditions = [
  {
    label: 'Custom or special request of the product',
    value: 'custom-special-request',
  },
  {
    label: 'Complaint about quality',
    value: 'complaint-quality',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
  {
    label: 'Safety concern (material, allergies, etc)',
    value: 'safety-concern',
  },
  {
    label: 'Time sensitive',
    value: 'time-sensitive',
  },
  {
    label: 'International shipping and customs concern',
    value: 'international-shipping',
  },
  {
    label: 'Delivery for surpirse occasions',
    value: 'delivery',
  },
];

export const messageConditions = [
  {
    label: 'Is contain',
    value: 'message_contain',
  },
  { label: 'Is exact', value: 'message_exact' },
];

export const actionItems = [
  { label: 'Assign ticket', value: 'assign_ticket' },
  { label: 'Set ticket category', value: 'set_ticket_category' },
  { label: 'Add customer tag', value: 'add_customer_tag' },
  { label: 'Remove customer tag', value: 'remove_customer_tag' },
  { label: 'Run other chatbot', value: 'run_flow' },
] as const;

export type ActionItem = typeof actionItems[number];

export const welcomeActionOptions = [
  { label: 'Run chatbot', value: 'run_flow' },
  { label: 'Send message', value: 'send_message' },
] as const;

export type WelcomeActionType = typeof welcomeActionOptions[number];

export const activeTimeOptions = [
  {
    label: 'Always on',
    value: 'always_on',
    icon: <Clock className="text-info-main" />,
    tooltip: 'Active 24/7',
  },
  {
    label: 'Off-time',
    value: 'off_time',
    icon: <MoonIcon />,
    tooltip: 'Outside operational hours',
  },
  {
    label: 'Day-time',
    value: 'day_time',
    icon: <SunIcon />,
    tooltip: 'Inside operational hours',
  },
];
