import { gql } from 'graphql-request';

export default gql`
  query getBlogById($id: ID!) {
    blog(id: $id) {
      id
      title
    }
  }
`;
