import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';
import { CampaignLogAnalyticsProps } from './CampaignLogAnalytics';

import { BBBOverviewBox, BBBRenderHtml } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { formatDate2 } from '@/utils/common/date';

const WhatsappMessageAnalytics = ({
  loadingCampaign,
  campaignData,
}: Omit<CampaignLogAnalyticsProps, 'campaignId'>) => {
  const isMobile = useResponsive('sm');
  const [showMore, setShowMore] = useState(false);

  const campaignMessage =
    campaignData?.whatsappMessage === null
      ? ''
      : campaignData?.whatsappMessage?.message ?? '';

  return (
    <div
      className={cx(
        `grid grid-cols-6 gap-6 grid-rows-1 mb-4 ${isMobile && 'px-4'}`
      )}
    >
      {loadingCampaign ? (
        <WhatsappMessageAnalyticsSkeleton isMobile={isMobile} />
      ) : (
        <>
          <div className="col-span-6 flex flex-col gap-2 px-6 py-6 bg-white border-[1.5px] border-gray-300 rounded-xl">
            <p className="text-base text-primary-main font-semibold">
              Message:
            </p>
            <div className="inline">
              <BBBRenderHtml
                content={
                  (showMore || campaignMessage?.length <= 210
                    ? campaignMessage
                    : campaignMessage?.substring(0, 210) + '...') || '-'
                }
              />
              {campaignMessage?.length > 210 && (
                <span
                  className="underline cursor-pointer"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? ' Show less' : ' Show more'}
                </span>
              )}
            </div>
          </div>
          <BBBOverviewBox
            title="Completely sent at"
            className={isMobile ? 'col-span-6' : 'col-span-3'}
            value={
              campaignData?.sendAt ? formatDate2(campaignData.sendAt) : '-'
            }
          />
          <BBBOverviewBox
            title="Segmentation"
            className={isMobile ? 'col-span-6' : 'col-span-3'}
            value={campaignData?.customerSegment || '-'}
          />
        </>
      )}
    </div>
  );
};

function WhatsappMessageAnalyticsSkeleton({ isMobile }: { isMobile: boolean }) {
  return (
    <>
      <div className="col-span-6">
        <Skeleton height={150} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-3' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-3' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
    </>
  );
}

export default WhatsappMessageAnalytics;
