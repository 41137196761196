import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useWidget = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['wa-widget', activeCompany], () =>
    services.bitChat.widget.getWidget(activeCompany)
  );
};

export default useWidget;
