import React from 'react';

type Props = {
  size?: number;
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const WhatsAppFormIcon = ({ size = 30, color = '#9A9A9A' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75 5H6.25L6.25 25H23.75V5ZM6.25 2.5C4.86929 2.5 3.75 3.61929 3.75 5V25C3.75 26.3807 4.86929 27.5 6.25 27.5H23.75C25.1307 27.5 26.25 26.3807 26.25 25V5C26.25 3.61929 25.1307 2.5 23.75 2.5H6.25Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 8.75C7.5 8.05964 8.05964 7.5 8.75 7.5H21.25C21.9404 7.5 22.5 8.05964 22.5 8.75C22.5 9.44036 21.9404 10 21.25 10H8.75C8.05964 10 7.5 9.44036 7.5 8.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 15C11.25 14.3096 11.8096 13.75 12.5 13.75L21.25 13.75C21.9404 13.75 22.5 14.3096 22.5 15C22.5 15.6904 21.9404 16.25 21.25 16.25L12.5 16.25C11.8096 16.25 11.25 15.6904 11.25 15Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 20C11.25 19.3096 11.8096 18.75 12.5 18.75L21.25 18.75C21.9404 18.75 22.5 19.3096 22.5 20C22.5 20.6904 21.9404 21.25 21.25 21.25L12.5 21.25C11.8096 21.25 11.25 20.6904 11.25 20Z"
        fill={color}
      />
      <path
        d="M10 15C10 15.6904 9.44036 16.25 8.75 16.25C8.05964 16.25 7.5 15.6904 7.5 15C7.5 14.3096 8.05964 13.75 8.75 13.75C9.44036 13.75 10 14.3096 10 15Z"
        fill={color}
      />
      <path
        d="M10 20C10 20.6904 9.44036 21.25 8.75 21.25C8.05964 21.25 7.5 20.6904 7.5 20C7.5 19.3096 8.05964 18.75 8.75 18.75C9.44036 18.75 10 19.3096 10 20Z"
        fill={color}
      />
    </svg>
  );
};

export default WhatsAppFormIcon;
