import api from 'config/api';
import useChatFilter from 'hooks/whatsApp/useChatFilter';
import { ChatResponse } from 'types/whatsApp/chat';

export const GET_CHATS_DEFAULT_LIMIT = 30;

export default {
  getChats: ({ limit = GET_CHATS_DEFAULT_LIMIT, ...params }: GetChatsParams) =>
    api.whatsApp.v3
      .get<ChatResponse>('/chat', {
        params: { ...params, limit },
      })
      .then((res) => res.data),
};

export type GetChatsParams = {
  userId: number;
  status?: string;
  page?: number;
  limit?: number;
  channel?: string;
  key?: string;
  cursor?: string;
} & Partial<ReturnType<typeof useChatFilter>>;
