import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import useHistory from '@/hooks/common/useHistory';
import { toast } from '@/utils/common/toast';

type ResetPasswordPayload = {
  email: string;
};

const useResetPasswordRequest = (redirect = true) => {
  const history = useHistory();
  return useMutation(
    async (payload: ResetPasswordPayload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/auth/reset-password/request`, {
        email: payload.email,
      });
      return data;
    },
    {
      onSuccess: () => {
        toast.success(
          'Password reset request succesfully sent. Please check your email'
        );
        if (redirect) {
          history.push('/');
        }
      },
    }
  );
};
export default useResetPasswordRequest;
