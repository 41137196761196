function CheckIcon2({
  className,
  color = '#757575',
  size = '14',
  height,
  width,
}: {
  className?: string;
  color?: string;
  size?: string;
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || width}
      height={size || height}
      className={className}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.333 7l3.5 3.5 5.834-7"
      ></path>
    </svg>
  );
}

export default CheckIcon2;
