function UpsellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M0 16.5C0 7.664 7.164.5 16 .5s16 7.164 16 16-7.164 16-16 16-16-7.164-16-16zM16 3.167c-7.364 0-13.333 5.97-13.333 13.333 0 7.364 5.97 13.333 13.333 13.333 7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.333-13.333zm-7.61 12.39l6.667-6.666c.52-.521 1.365-.521 1.886 0l6.666 6.666a1.333 1.333 0 11-1.885 1.886l-4.39-4.39v10.114a1.333 1.333 0 01-2.667 0V13.052l-4.39 4.39a1.333 1.333 0 11-1.886-1.885z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UpsellIcon;
