import { gql } from 'graphql-request';

export default gql`
  query getCollections($first: Int, $query: String, $after: String) {
    collections(first: $first, query: $query, after: $after) {
      edges {
        node {
          id
          image {
            url
          }
          title
          description
          products(first: 10) {
            edges {
              node {
                title
                featuredImage {
                  url
                }
                id
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;
