import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import integrationServices from '@/api/services/integration';
import { FacebookAccountList } from '@/types/facebook';

const useListFacebook = (
  options: Omit<
    UseQueryOptions<
      FacebookAccountList[],
      unknown,
      FacebookAccountList[],
      (string | number)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['facebook-list', activeCompany],
    () => integrationServices.facebook.getFacebookList(activeCompany),
    {
      ...options,
    }
  );
};

export default useListFacebook;
