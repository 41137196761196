import { formatInternational } from './common/phone';

import { Order, Product, ProductVariant } from '@/api/services/customer/order';
import { Block, Condition, Metafield } from '@/constants/customers';
import { AiTag, CustomerAddresses, Tags, VisitedSite } from '@/types/customers';

export const segmentQueryBuilder = (
  conditions: Condition[],
  blocks: Block[],
  parentId?: string,
  blockId?: string
) => {
  const finalQuery: Record<string, any> = {};

  conditions
    .filter((seg) => seg.blockId === blockId && seg.parentId === parentId)
    .forEach((condition) => {
      if ('conjunction' in condition) {
        const q = blocks
          .filter(
            (block) =>
              block.conjunctionId === condition.id &&
              !!Object.keys(
                segmentQueryBuilder(conditions, blocks, condition.id, block.id)
              ).length
          )
          .map((block) => {
            return segmentQueryBuilder(
              conditions,
              blocks,
              condition.id,
              block.id
            );
          });

        if (q.length) {
          finalQuery[condition.conjunction] = q;
        }
      } else {
        if (
          condition.key?.value &&
          condition.operator?.value &&
          condition.value
        ) {
          if (condition.key.value === 'customerTags') {
            if (
              condition.operator.value === 'equals' ||
              condition.operator.value === 'notEquals' ||
              condition.operator.value === 'in' ||
              condition.operator.value === 'notIn'
            ) {
              const isNegative =
                condition.operator.value === 'notEquals' ||
                condition.operator.value === 'notIn';

              finalQuery.customerTags = {
                [isNegative ? 'every' : 'some']: {
                  tagName:
                    condition.operator.value === 'notEquals'
                      ? {
                          not: {
                            equals: (condition.value as Tags).name,
                          },
                          mode: 'insensitive',
                        }
                      : {
                          [condition.operator.value]: Array.isArray(
                            condition.value
                          )
                            ? (condition.value as Tags[]).map((val) => val.name)
                            : (condition.value as Tags).name,
                          mode: 'insensitive',
                        },
                },
              };
            }
          } else if (condition.key.value === 'aiTags') {
            if (
              condition.operator.value === 'equals' ||
              condition.operator.value === 'notEquals'
            ) {
              finalQuery.customerAiTags = {
                [condition.operator.value === 'notEquals' ? 'every' : 'some']: {
                  aiTagName:
                    condition.operator.value === 'notEquals'
                      ? {
                          not: {
                            equals: (condition.value as AiTag).name,
                          },
                          mode: 'insensitive',
                        }
                      : {
                          equals: (condition.value as AiTag).name,
                          mode: 'insensitive',
                        },
                },
              };
            }
          } else if (condition.key.value === 'visited') {
            if (
              condition.operator.value === 'equals' ||
              condition.operator.value === 'notEquals'
            ) {
              finalQuery.visitedSites = {
                [condition.operator.value === 'notEquals' ? 'every' : 'some']: {
                  visitedSitePath:
                    condition.operator.value === 'notEquals'
                      ? {
                          not: {
                            equals: (condition.value as VisitedSite).path,
                          },
                          mode: 'insensitive',
                        }
                      : {
                          equals: (condition.value as VisitedSite).path,
                          mode: 'insensitive',
                        },
                },
              };
            }
          } else if (
            //@ts-ignore
            condition.key.value.startsWith('metafield_') &&
            (condition.value as Metafield).value
          ) {
            if (
              condition.operator.value === 'equals' ||
              condition.operator.value === 'notEquals'
            ) {
              const metafieldValue = condition.value as Metafield;

              //@ts-ignore
              const [, key] = condition.key.value.split('_');
              const baseCondition = {
                key: key,
                value:
                  metafieldValue.type === 'multiple_choice'
                    ? {
                        array_contains: [metafieldValue.value],
                      }
                    : {
                        equals: metafieldValue.value,
                      },
              };

              if (condition.operator.value === 'notEquals') {
                finalQuery.additionalDatas = {
                  every: {
                    NOT: baseCondition,
                  },
                };
              } else {
                finalQuery.additionalDatas = {
                  some: baseCondition,
                };
              }
            }
          }
        }
      }
    });

  return finalQuery;
};

export const formatDisplayName = (
  phoneNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  const fullName = [
    ...(firstName ? [firstName] : []),
    ...(lastName ? [lastName] : []),
  ];

  const displayName = fullName.length ? fullName.join(' ') : phoneNumber ?? '-';

  return formatInternational(displayName);
};

export const formatAddressLocation = (addresses?: CustomerAddresses[]) => {
  const addressArray = addresses?.length
    ? [addresses[0].address1, addresses[0].city, addresses[0].country]
    : undefined;

  return addressArray?.filter(Boolean).length
    ? addressArray.filter(Boolean).join(', ')
    : '-';
};

export function getOrderName(order: Order) {
  return order.orderId2 || order.orderId || order.id;
}

export function getProductName(
  product: Product | null,
  productVariant: ProductVariant | null
) {
  if (!productVariant && !product) return null;

  if (!product) return null;

  return productVariant
    ? `${productVariant.productOptionName} - ${productVariant.productOptionValuesValue}`
    : product.name;
}
