import { useMemo } from 'react';

import useCustomers from '@/hooks/customers/customer/useCustomers';
import { useSegments } from '@/hooks/customers/segment';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

const useSegmentOptions = (search?: string | undefined) => {
  const { data: companyPricing } = usePricingByApp('BITCRM');
  const { data: customersData } = useCustomers();

  const {
    data: customerSegment,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useSegments({ search });

  const flattenedCustomerSegment = useMemo(
    () =>
      customerSegment?.pages.flatMap((page) =>
        page.data.map((segment) => ({
          label: `${segment.data.name} - ${segment.count}`,
          value: segment.data.name,
          disabled: companyPricing?.pricingName === 'free',
          count: segment.count,
        }))
      ),
    [companyPricing?.pricingName, customerSegment?.pages]
  );

  const customerSegmentsWithAll = useMemo(
    () => [
      ...(companyPricing?.pricingName === 'free'
        ? [{ label: 'Demo', value: 'demo', count: 0 }]
        : []),
      ...(flattenedCustomerSegment?.length && customersData?.meta.total
        ? [
            {
              label: `All - ${customersData.meta.total}`,
              value: 'all',
              disabled: companyPricing?.pricingName === 'free',
              count: customersData.meta.total,
            },
            ...flattenedCustomerSegment,
          ]
        : []),
    ],
    [
      companyPricing?.pricingName,
      customersData?.meta.total,
      flattenedCustomerSegment,
    ]
  );

  return {
    data: customerSegmentsWithAll,
    hasNextPage,
    fetchNextPage,
    isLoading,
  };
};

export default useSegmentOptions;
