import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAnalytics = (
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-analytics', activeCompany, params],
    () =>
      services.bitCRM.v2.analytics.getAutomationAndCampaign(activeCompany, {
        startDate: params.startDate,
        endDate: params.endDate,
      }),
    options
  );
};

export default useAnalytics;
