export default function MoonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#8F68C0"
        d="M20.958 15.325c.204-.486-.379-.9-.868-.684a7.685 7.685 0 01-3.101.648c-4.185 0-7.577-3.324-7.577-7.425a7.28 7.28 0 011.134-3.91c.284-.448-.057-1.068-.577-.936C5.96 4.041 3 7.613 3 11.862 3 16.91 7.175 21 12.326 21c3.9 0 7.24-2.345 8.632-5.675z"
      ></path>
      <path
        fill="#8F68C0"
        d="M15.611 3.103c-.53-.354-1.162.278-.809.808l.63.945a2.332 2.332 0 010 2.588l-.63.945c-.353.53.28 1.162.81.808l.944-.63a2.332 2.332 0 012.588 0l.945.63c.53.354 1.162-.278.808-.808l-.63-.945a2.332 2.332 0 010-2.588l.63-.945c.354-.53-.278-1.162-.809-.808l-.944.63a2.332 2.332 0 01-2.588 0l-.945-.63z"
      ></path>
    </svg>
  );
}
