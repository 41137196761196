import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import getProduct from 'graphql/query/getProduct';
import { ShopifyProduct } from 'types/shopify/products';
import { generateShopifyId } from 'utils/bitApp';
import useGraphQLRequest from '../useGraphQLRequest';

const useProduct = (
  id: string | null | undefined,
  options?: Omit<
    UseQueryOptions<ShopifyProduct, unknown, ShopifyProduct, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const { request, enabled } = useGraphQLRequest();

  return useQuery<ShopifyProduct>(
    ['bitapp-shopify-product', id],
    async () => {
      const data = await request({
        query: getProduct,
        variables: {
          id: generateShopifyId('Product', id),
        },
      });
      return data?.product;
    },
    {
      enabled: !!enabled && !!id,
      ...options,
    }
  );
};

export default useProduct;
