import { ReactNode } from 'react';

import { IBBBToastProps } from '@/components/ui/BBBToast';
import { rootStore } from '@/stores';
import { addToast } from '@/stores/common';

export type ToastFunctionProps = Omit<IBBBToastProps, 'index' | 'id'> & {
  id?: string;
};

interface ToastFunctions {
  success: (message: string, options?: ToastFunctionProps | undefined) => void;
  action: (
    message: string,
    options?: Partial<ToastFunctionProps | undefined>
  ) => void;
  error: (message: ReactNode, options?: ToastFunctionProps | undefined) => void;
}

export const toast: ToastFunctions = {
  success: (message, options) => {
    rootStore.dispatch(
      addToast({
        ...options,
        message,
        type: 'primary',
        isCloseable: true,
      })
    );
  },
  action: (message, options) => {
    rootStore.dispatch(
      addToast({
        ...options,
        message,
        duration: 6000,
        type: 'primary',
        hasAction: true,
      })
    );
  },
  error: (message, options) => {
    rootStore.dispatch(
      addToast({
        ...options,
        message,
        type: 'error',
        isCloseable: true,
      })
    );
  },
};
