import { useAppDispatch } from '@/hooks/rtk/store';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import { CommonStore, setConnectIntegration } from '@/stores/common';

const useConnectIntegration = () => {
  const dispatch = useAppDispatch();
  const { setShow } = useShopifyConnectModal();

  const connectIntegration = (state: CommonStore['connectIntegration']) =>
    dispatch(setConnectIntegration(state));

  return (integration: CommonStore['connectIntegration']) => {
    if (integration?.name === 'shopify') {
      setShow({ appType: integration.appType });
    } else {
      connectIntegration(integration);
    }
  };
};

export default useConnectIntegration;
