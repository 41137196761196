import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SocialLogin from '../SocialLogin';

import AuthCard from '@/components/Auth/Card';
import PasswordCheck from '@/components/Auth/Register/PasswordCheck';
import {
  BBBButton,
  BBBCheckbox,
  BBBPasswordInput,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextInput,
} from '@/components/ui';
import { Link } from '@/components/ui/Link';
import passwordValidation from '@/constants/systemLogin/passwordValidation';
import { SocialLoginPayload } from '@/hooks/auth/useLogin';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import { phoneValidator } from '@/utils/common/phone';

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required field'),
  lastName: yup.string().required('Last Name is required field'),
  email: yup
    .string()
    .email('Email should be valid email format')
    .required('Email is required'),
  password: passwordValidation,
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please retype your password'),
  phone: phoneValidator(),
  agree: yup.bool().oneOf([true], 'Please accept the terms and condition'),
});

const defaultValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  firstName: '',
  lastName: '',
  phone: null,
  agree: false,
};

export type RegisterFormSchema = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  agree: boolean;
  phone: BBBTelInputValue | null;
};

type SourceRedirection =
  | {
      isFromLogin?: false;
      onRedirect?: () => void;
    }
  | {
      isFromLogin?: true;
      onRedirect?: never;
    };

type RegisterCardProps = {
  data?: Partial<Omit<RegisterFormSchema, 'phone'> & { phone: string }>;
  onSubmit: (data: RegisterFormSchema | SocialLoginPayload) => void;
  loadingSave?: boolean;
  onHaveAccount?: () => void;
  noLoginOptions?: boolean;
} & SourceRedirection;

function RegisterCard({
  data,
  onSubmit,
  loadingSave,
  onHaveAccount,
  noLoginOptions,
}: RegisterCardProps) {
  const { search } = useLocation();
  const phoneDefaultValue = useDefaultCountryCode(data?.phone);

  const { handleSubmit, formState, control, watch, setValue } =
    useCustomForm<RegisterFormSchema>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  useEffect(() => {
    setValue('phone', phoneDefaultValue || null);
    setValue('email', data?.email || defaultValues.email);
    setValue('password', data?.password || defaultValues.password);
    setValue(
      'passwordConfirmation',
      data?.passwordConfirmation || defaultValues.passwordConfirmation
    );
    setValue('firstName', data?.firstName || defaultValues.firstName);
    setValue('lastName', data?.lastName || defaultValues.lastName);
    setValue('agree', data?.agree || defaultValues.agree);
  }, [data, phoneDefaultValue, setValue]);

  const watchPassword = watch('password');

  return (
    <AuthCard title="Sign up" description="Grow your brand together">
      <SocialLogin type="register" onSubmit={onSubmit} loading={loadingSave} />
      <div className="flex flex-col gap-2 mb-10">
        <div className="flex flex-col md:flex-row gap-3">
          <BBBTextInput
            labelClassname="text-primary-main"
            placeholder="What is your first name?"
            label="First name"
            isHookForm
            containerClassname="flex-1"
            control={control}
            controlName="firstName"
            error={formState.errors.firstName?.message}
          />
          <BBBTextInput
            labelClassname="text-primary-main"
            placeholder="What is your last name?"
            label="Last name"
            isHookForm
            containerClassname="flex-1"
            control={control}
            controlName="lastName"
            error={formState.errors.lastName?.message}
          />
        </div>
        <div className="w-full">
          <BBBTextInput
            label="Email"
            labelClassname="text-primary-main"
            placeholder="What is your email?"
            type="email"
            isHookForm
            control={control}
            disabled={!!data?.email}
            controlName="email"
            error={formState.errors.email?.message}
          />
        </div>
        <div className="w-full">
          <BBBTelInput
            isHookForm
            label="Phone number"
            placeholder="123456789"
            control={control}
            controlName="phone"
            error={formState.errors.phone?.message}
          />
        </div>
        <div className="w-full">
          <BBBPasswordInput
            label="Password"
            labelClassname="text-primary-main"
            placeholder="Create a strong password"
            type="password"
            containerClassname="!mb-0"
            isHookForm
            control={control}
            controlName="password"
          />
          <PasswordCheck password={watchPassword} />
        </div>
        <div className="w-full">
          <BBBPasswordInput
            label="Repeat password"
            labelClassname="text-primary-main"
            containerClassname="!mb-0"
            placeholder="Re-type your password"
            type="password"
            isHookForm
            control={control}
            controlName="passwordConfirmation"
            error={formState.errors.passwordConfirmation?.message}
          />
        </div>
        <Controller
          render={({ field }) => (
            <BBBCheckbox
              checked={field.value}
              onValueChange={field.onChange}
              errors={formState.errors.agree?.message}
              label={
                <label
                  htmlFor="agree"
                  className="text-[14px] text-primary-main"
                >
                  Yes, I understand and agree to bitbybit’s{' '}
                  <Link
                    to="http://"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#2b6aaf] underline"
                  >
                    Terms of Service
                  </Link>
                  , including the{' '}
                  <Link
                    to="http://"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#2b6aaf] underline"
                  >
                    User Agreement
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="http://"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#2b6aaf] underline"
                  >
                    Privacy Policy.
                  </Link>
                </label>
              }
            />
          )}
          control={control}
          name="agree"
        />
      </div>
      <div className="flex flex-col justify-center gap-2">
        <BBBButton
          loadingState={loadingSave}
          text="Sign up"
          width="full"
          onClick={handleSubmit(onSubmit)}
        />
        {!noLoginOptions && (
          <span className="mt-6">
            Already have an account?{' '}
            <span className="underline cursor-pointer">
              {onHaveAccount ? (
                <span onClick={onHaveAccount}>Login</span>
              ) : (
                <Link to={'/login' + search}>Login</Link>
              )}
            </span>
          </span>
        )}
      </div>
    </AuthCard>
  );
}

export default RegisterCard;
