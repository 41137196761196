function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.537 1.316a.497.497 0 01.926 0l1.035 2.867a.495.495 0 00.463.317h2.543c.47 0 .676.585.306.871L9 7a.484.484 0 00-.16.546l.66 2.801c.161.45-.36.837-.754.56l-2.458-1.56a.5.5 0 00-.575 0l-2.459 1.56c-.393.277-.915-.11-.754-.56l.661-2.801A.484.484 0 003.001 7L1.19 5.37c-.37-.286-.165-.871.304-.871H4.04a.495.495 0 00.463-.317l1.035-2.867h0z"
      ></path>
    </svg>
  );
}

export default StarIcon;
