import { useEffect } from 'react';
import useResponsive from 'hooks/common/useResponsive';
import LivePreview from '../components/LivePreview';
import NavigatorTab from '../Design/components/NavigatorTab';
import Form from './components/Form';
import Journey from './Journey';

import { BBBContainer } from '@/components/ui';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setActivePreview } from '@/stores/bitApp';

export default function Branding() {
  const isTablet = useResponsive('lg');

  return (
    <div className="flex h-full overflow-auto">
      {isTablet ? <MobileView /> : <DefaultView />}
    </div>
  );
}

function DefaultView() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActivePreview('app-icon'));
  }, [dispatch]);

  return (
    <>
      <Journey />
      <div className="flex-initial h-full w-[45%] flex flex-col md:border-r-2">
        <div className="grow relative">
          <div className="absolute inset-0">
            <BBBContainer
              hasHeading
              pageTitle="Branding"
              pageDescription="Use your own branding to personalize your app"
            >
              <Form />
            </BBBContainer>
          </div>
          <div
            id="bitapp-footer-container"
            className="absolute bottom-0 z-50 right-0 left-0"
          ></div>
        </div>
      </div>
      <LivePreview module="branding" />
    </>
  );
}

function MobileView() {
  return (
    <div className="flex flex-col w-full bg-white">
      <div className="grow relative">
        <div className="absolute inset-0 overflow-auto">
          <Form />
        </div>
      </div>
      <NavigatorTab />
    </div>
  );
}
