import CheckIconDouble from '@/assets/icons/CheckIconDouble';
import CheckIconSingle from '@/assets/icons/CheckIconSingle';
import PendingIcon from '@/assets/icons/PendingIcon';
import UndeliveredIcon from '@/assets/icons/UndeliveredIcon';

function ChatBubbleACK({ ack }: { ack: number }) {
  return (
    <div className="ml-1">
      {ack === -1 ? (
        <UndeliveredIcon />
      ) : ack === 0 ? (
        <PendingIcon />
      ) : ack === 1 ? (
        <CheckIconSingle size={12} />
      ) : (
        <CheckIconDouble read={ack === 3} />
      )}
    </div>
  );
}

export default ChatBubbleACK;
