export default function LocationIcon2({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.1C10.3984 6.1 9.1 7.39837 9.1 9C9.1 10.6016 10.3984 11.9 12 11.9C13.6016 11.9 14.9 10.6016 14.9 9C14.9 7.39837 13.6016 6.1 12 6.1ZM10.9 9C10.9 8.39249 11.3925 7.9 12 7.9C12.6075 7.9 13.1 8.39249 13.1 9C13.1 9.60751 12.6075 10.1 12 10.1C11.3925 10.1 10.9 9.60751 10.9 9Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 9.7721C19 13.2051 14.5223 18.9685 12.7294 21.1387C12.3461 21.6026 11.6539 21.6026 11.2706 21.1387C9.47766 18.9685 5 13.2051 5 9.7721C5 5.47969 8.13401 2 12 2C15.866 2 19 5.47969 19 9.7721ZM17 9.7721C17 10.241 16.835 10.9571 16.4308 11.9111C16.0389 12.8362 15.4817 13.8479 14.8429 14.866C13.9048 16.3611 12.8432 17.7872 12 18.8575C11.1568 17.7872 10.0952 16.3611 9.15707 14.866C8.5183 13.8479 7.96114 12.8362 7.56921 11.9111C7.16504 10.9571 7 10.241 7 9.7721C7 6.38227 9.43028 4 12 4C14.5697 4 17 6.38227 17 9.7721Z"
        fill="black"
      />
    </svg>
  );
}
