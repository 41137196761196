import { twMerge as cx } from 'tailwind-merge';
import { AgentPerformance } from './components/agent-performance';
import Conversations from './components/conversations';
import Tickets from './components/tickets';

import { ChannelIntegrationValues } from '@/constants/integrations';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import { useDefaultConnectedChannels } from '@/hooks/whatsApp/channels';

type Props = {
  containerClassName?: string;
  startDate: string;
  endDate: string;
  isPreview?: boolean;
  loading?: boolean;
};

export default function BitChatAnalytics({
  containerClassName,
  startDate,
  endDate,
  isPreview,
  loading,
}: Props) {
  const querySearchParams = useQuerySearchParams();

  const channelQueryParams = querySearchParams.get('channel');
  const { data: defaultChannelIntegrations } = useDefaultConnectedChannels({
    enabled: !channelQueryParams,
  });

  const channel = (channelQueryParams || defaultChannelIntegrations) as
    | ChannelIntegrationValues
    | undefined;

  if (!channel) return null;

  const props = {
    isPreview: isPreview,
    startDate: startDate,
    endDate: endDate,
    channel: channel,
    loading,
  };

  return (
    <div className={cx('w-full flex flex-col gap-4', containerClassName)}>
      <Conversations {...props} />
      <Tickets {...props} />
      <AgentPerformance {...props} />
    </div>
  );
}
