import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Control, useWatch } from 'react-hook-form';
import ChatIcon from 'assets/icons/ChatIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { FormSchema } from '../..';
import { sortSocialMeta } from '../SocialMedia';

import BBBLogoIcon from '@/assets/icons/BBBLogoIcon';
import TelegramPlaneIcon from '@/assets/icons/TelegramPlaneIcon';
import colors from '@/constants/common/colors';
import { socialMeta, SocialType } from '@/constants/social';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setPreviewPowerMessage } from '@/stores/bitCRM';
import { checkBrightness } from '@/utils/common/colors';
import { formatPhonePayload } from '@/utils/common/phone';
import { cn } from '@/utils/styles';

export type PreviewWidgetProps = {
  control: Control<FormSchema>;
};

export default function PreviewWidget({ control }: PreviewWidgetProps) {
  const previewPowerMessage = useAppSelector(
    (s) => s.bitCRM.previewPowerMessage
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (previewPowerMessage) {
      timeout = setTimeout(() => {
        dispatch(setPreviewPowerMessage(false));
      }, 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch, previewPowerMessage]);

  return (
    <AnimatePresence mode="wait" initial={false}>
      {previewPowerMessage ? (
        <PreviewPowerMessage control={control} />
      ) : (
        <_PreviewWidget control={control} />
      )}
    </AnimatePresence>
  );
}

function PreviewPowerMessage({ control }: PreviewWidgetProps) {
  const [show, setShow] = useState(false);

  const powerMsgHeader = useWatch({ control, name: 'powerMsgHeader' });
  const powerMsgDescription = useWatch({
    control,
    name: 'powerMsgDescription',
  });
  const powerMsgColor = useWatch({
    control,
    name: 'powerMsgColor',
  });

  const widgetColor = useWatch({
    control,
    name: 'themeColor',
  });

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);

    setTimeout(() => {
      setShow(false);
    }, 4000);
  }, []);

  return (
    <motion.div
      className="relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence>
        {show && (
          <motion.div
            className="absolute z-30 bottom-full translate-y-2 right-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className={cn(
                'px-4 py-3 rounded-2xl bg-white border min-w-[17.5rem] max-w-sm transition-colors',
                powerMsgColor === 'no_outline' && 'shadow-md'
              )}
              style={{
                borderColor:
                  powerMsgColor === 'default'
                    ? colors.secondary.main
                    : powerMsgColor === 'no_outline'
                    ? 'transparent'
                    : widgetColor,
              }}
            >
              <div className="flex items-center mb-2">
                <div className="grow">
                  <div className="text-xl font-semibold">{powerMsgHeader}</div>
                </div>
                <X />
              </div>
              <div>{powerMsgDescription}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ButtonWidget control={control} />
    </motion.div>
  );
}

function _PreviewWidget({ control }: PreviewWidgetProps) {
  const enabled = useWatch({
    control,
    name: 'showCard',
  });

  return (
    <motion.div
      className="flex justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col">
        {enabled && <WidgetCard control={control} />}
        <ButtonWidget control={control} />
      </div>
    </motion.div>
  );
}

const mapDefaultSocialBg: Record<SocialType, string> = {
  email: '#E54747',
  instagram: '#FF6611',
  tiktok: '#000000',
  facebook: '#5080FD',
  x: '#000000',
};

const mapDefaultSocialIconColor: Record<SocialType, string> = {
  email: '#FFFFFF',
  instagram: '#FFFFFF',
  tiktok: '#FFFFFF',
  facebook: '#FFFFFF',
  x: '#FFFFFF',
};

function WidgetCard({ control }: { control: Control<FormSchema> }) {
  const profilePicture = useWatch({
    control,
    name: 'storeLogo',
  });

  const headerWidget = useWatch({
    control,
    name: 'storeName',
  });

  const subtitleText = useWatch({
    control,
    name: 'storeDescription',
  });

  const quickResponses = useWatch({
    control,
    name: 'quickResponses',
  });

  const whatsappContactPhone = useWatch({
    control,
    name: 'whatsappPhone',
  });

  const whatsappContactPrefilledMessage = useWatch({
    control,
    name: 'whatsappPrefilledText',
  });

  const whatsappContactButtonText = useWatch({
    control,
    name: 'whatsappButtonText',
  });

  const widgetColor = useWatch({
    control,
    name: 'widgetColor1',
  });

  const widgetColor2 = useWatch({
    control,
    name: 'widgetColor2',
  });

  const widgetColorType = useWatch({
    control,
    name: 'widgetColorType',
  });

  const social = useWatch({
    control,
    name: 'social',
  });

  const socialColorCustom = useWatch({
    control,
    name: 'socialColorCustom',
  });

  const socialColorType = useWatch({
    control,
    name: 'socialColorType',
  });

  const socials = sortSocialMeta(socialMeta, social.socialOrders);

  const actualWidgetColor =
    widgetColorType === 'solid'
      ? widgetColor
      : `linear-gradient(180deg, ${widgetColor}, ${widgetColor2})`;

  const truthyQuickResponses = quickResponses.filter(
    (quickResponse) => quickResponse.buttonText && quickResponse.chatbot
  );

  const mainBrightness = checkBrightness(widgetColor);
  const secondaryBrightness = checkBrightness(widgetColor2);

  const shownSocials = socials.filter((_social) => {
    const data = social.values[_social.value];

    return !!data?.enabled && !!data.value;
  });

  const Socials = (
    <>
      {shownSocials.map((_social) => {
        const Icon = _social.icon;

        const values = social.values[_social.value]!.value!;

        const actualSocialBg =
          socialColorType === 'custom'
            ? socialColorCustom!
            : mapDefaultSocialBg[_social.value];

        const socialColorCustomBrightness = checkBrightness(actualSocialBg);

        return (
          <a
            href={
              (_social.protocol || 'https://') +
              (_social.prefix || '') +
              (_social.protocol ? '' : '/') +
              values
            }
            key={_social.value}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="w-8 h-8 rounded-full flex items-center justify-center"
              style={{
                backgroundColor: actualSocialBg,
              }}
            >
              <Icon
                size={'1.25rem'}
                color={
                  socialColorType === 'custom'
                    ? socialColorCustomBrightness === 'bright'
                      ? undefined
                      : 'white'
                    : mapDefaultSocialIconColor[_social.value]
                }
              />
            </div>
          </a>
        );
      })}
    </>
  );

  return (
    <div
      className="mb-5 rounded-xl w-96 self-end relative"
      style={{ background: actualWidgetColor }}
    >
      <div
        className={cn(
          'absolute right-4 top-5',
          mainBrightness === 'dark' && 'text-white'
        )}
      >
        <X />
      </div>
      <div className=" w-full rounded-tl-xl rounded-tr-xl px-4 pb-4 pt-6">
        <div className="flex items-center gap-2 mb-3">
          {profilePicture && (
            <img src={profilePicture} className="w-12 h-12 rounded-full" />
          )}
          <div>
            <div
              className={cn(
                'font-medium text-xl',
                mainBrightness === 'dark' && 'text-white'
              )}
            >
              {headerWidget}
            </div>
            <div className={cn(mainBrightness === 'dark' && 'text-white')}>
              {subtitleText}
            </div>
          </div>
        </div>
        <div
          className="bg-white rounded-lg px-3 py-4"
          style={{
            boxShadow: '0px 3px 6px 0px #9B9B9B26',
          }}
        >
          <div className="font-semibold mb-3">Contact us</div>
          {whatsappContactPhone && whatsappContactPrefilledMessage ? (
            <a
              href={`https://wa.me/${formatPhonePayload(
                whatsappContactPhone
              )}?text=${whatsappContactPrefilledMessage}`}
              className="px-3 hover:border-primary-main transition-colors cursor-pointer py-2.5 border border-neutral-30 flex items-center gap-2.5 rounded"
              rel="noreferrer"
              target="_blank"
            >
              <WhatsAppIcon />
              <div className="text-sm grow">{whatsappContactButtonText}</div>
              <TelegramPlaneIcon />
            </a>
          ) : (
            <div className="px-3 hover:border-primary-main transition-colors cursor-pointer py-2.5 border border-neutral-30 flex items-center gap-2.5 rounded">
              <WhatsAppIcon />
              <div className="text-sm grow">{whatsappContactButtonText}</div>
              <TelegramPlaneIcon />
            </div>
          )}
          {!!shownSocials.length && (
            <>
              <div className="mt-3 flex items-center justify-center gap-4">
                <div className="text-sm">or start chat with:</div>
                {shownSocials.length <= 3 && (
                  <div className="flex items-center gap-2.5">{Socials}</div>
                )}
              </div>
              {shownSocials.length > 3 && (
                <div className="mt-3 flex justify-center items-center gap-2.5">
                  {Socials}
                </div>
              )}
            </>
          )}
        </div>
        {!!truthyQuickResponses.length && (
          <div className="bg-white rounded-lg px-3 py-4 mt-5">
            <div className="font-semibold mb-3">Quick responses</div>
            {truthyQuickResponses.map((quickResponse, index) =>
              whatsappContactPhone && quickResponse.chatbot ? (
                <a
                  className="px-3 py-2.5 mb-2 hover:border-primary-main transition-colors border border-neutral-30 flex items-center gap-2.5 rounded"
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={`https://wa.me/${formatPhonePayload(
                    whatsappContactPhone
                  )}?text=${whatsappContactPrefilledMessage}`}
                >
                  <div className="text-sm grow">{quickResponse.buttonText}</div>
                  <TelegramPlaneIcon />
                </a>
              ) : (
                <div
                  className="px-3 py-2.5 mb-2 hover:border-primary-main transition-colors border border-neutral-30 flex items-center gap-2.5 rounded"
                  key={quickResponse.buttonText}
                >
                  <div className="text-sm grow">{quickResponse.buttonText}</div>
                  <TelegramPlaneIcon />
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div
        className={cn(
          'py-2 rounded-bl-xl rounded-br-xl flex justify-center items-center gap-1',
          widgetColorType === 'gradient' ? 'bg-transparent' : 'bg-white',
          widgetColorType === 'gradient' &&
            secondaryBrightness === 'dark' &&
            'text-white'
        )}
      >
        <div className={cn('text-sm')}>Powered by</div>
        <BBBLogoIcon className={cn('h-7 w-[3.375rem]')} />
      </div>
    </div>
  );
}

function ButtonWidget({ control }: { control: Control<FormSchema> }) {
  const widgetColor = useWatch({
    control,
    name: 'themeColor',
  });

  const shape = useWatch({
    control,
    name: 'widgetButton.radiusType',
  });

  const icon = useWatch({
    control,
    name: 'widgetButton.iconType',
  });

  const textButtonWidget = useWatch({
    control,
    name: 'widgetText',
  });

  const brightness = checkBrightness(widgetColor);

  return (
    <div className="flex justify-end" id="button-widget">
      <div
        className={cx(
          'flex justify-center items-center rounded-lg',
          shape === 'circle' && 'rounded-full',
          textButtonWidget ? 'p-4.5' : 'w-16 h-16'
        )}
        style={{
          backgroundColor: widgetColor,
        }}
      >
        <div className="flex-none">
          {icon === 'whatsapp' ? (
            <WhatsAppIcon color="white" size={26} />
          ) : (
            <ChatIcon />
          )}
        </div>

        {textButtonWidget && (
          <div
            className={cn(
              'text-xl ml-2',
              brightness === 'dark' && 'text-white'
            )}
          >
            {textButtonWidget}
          </div>
        )}
      </div>
    </div>
  );
}

function WhatsAppIcon({
  color = '#262627',
  size = 20,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.512 11.985c-.248-.124-1.465-.723-1.692-.806-.227-.082-.392-.123-.558.125-.165.247-.639.805-.783.97-.145.166-.289.186-.536.063-.248-.125-1.046-.386-1.992-1.23-.736-.656-1.234-1.467-1.378-1.715-.144-.248-.016-.382.109-.505.111-.111.247-.29.371-.434.124-.145.165-.248.248-.414.083-.165.041-.309-.021-.433s-.557-1.343-.763-1.84c-.201-.482-.405-.416-.557-.424-.145-.007-.31-.009-.475-.009a.907.907 0 00-.66.31c-.228.248-.867.847-.867 2.066 0 1.219.887 2.396 1.011 2.562.124.165 1.746 2.666 4.23 3.739a14.17 14.17 0 001.412.52c.593.19 1.133.163 1.56.1.475-.072 1.465-.6 1.671-1.178.206-.579.206-1.075.145-1.178-.062-.103-.227-.165-.475-.289zm-4.518 6.17h-.003a8.225 8.225 0 01-4.193-1.15l-.3-.177-3.119.818.833-3.04-.196-.312a8.217 8.217 0 01-1.26-4.383c.002-4.542 3.697-8.237 8.242-8.237 2.2 0 4.268.859 5.823 2.415a8.188 8.188 0 012.41 5.828c-.002 4.541-3.697 8.237-8.237 8.237zm7.01-15.248A9.847 9.847 0 009.994 0C4.531 0 .084 4.446.083 9.91a9.887 9.887 0 001.322 4.954L0 20l5.253-1.378a9.901 9.901 0 004.737 1.206h.004c5.462 0 9.909-4.445 9.91-9.91a9.85 9.85 0 00-2.9-7.011z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
