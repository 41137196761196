function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        d="M7.036 4.66L4.78 2.057c-.26-.3-.737-.299-1.039.004L1.887 3.919c-.552.552-.71 1.373-.39 2.031a19.47 19.47 0 009.04 9.053c.658.32 1.478.162 2.03-.39l1.872-1.876c.303-.304.304-.783.001-1.043l-2.613-2.243c-.274-.235-.698-.204-.972.07l-.91.911a.308.308 0 01-.368.059 9.705 9.705 0 01-3.574-3.578.309.309 0 01.059-.37l.907-.907c.274-.276.304-.702.067-.976z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
