import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';
import AddCustomerModal from '../../AddCustomerModal';
import Notes from './Notes';

import BriefcaseIcon from '@/assets/icons/BriefcaseIcon';
import ChatIcon2 from '@/assets/icons/ChatIcon2';
import CopyIcon from '@/assets/icons/CopyIcon';
import EmailIcon from '@/assets/icons/EmailIcon';
import InstagramIcon from '@/assets/icons/InstagramIcon';
import LocationIcon from '@/assets/icons/LocationIcon';
import PhoneIcon from '@/assets/icons/PhoneIcon';
import UserIcon from '@/assets/icons/UserIcon';
import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';
import { BBBCard, BBBModal, BBBSelect, BBBTooltip } from '@/components/ui';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useWACloudTemplates from '@/hooks/bitChat/settings/useWACloudTemplates';
import { useStartChat } from '@/hooks/whatsApp/chat';
import { CustomerWithAssociation } from '@/types/customers/association';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { formatInternational } from '@/utils/common/phone';
import { formatDisplayName } from '@/utils/customers';
import { cn } from '@/utils/styles';

export type DetailCardProps = Pick<
  CustomerWithAssociation,
  | 'addresses'
  | 'phoneNumber'
  | 'firstName'
  | 'lastName'
  | 'instagramId'
  | 'email'
  | 'sources'
  | 'notes'
  | 'id'
  | 'customerTags'
>;

function DetailCard(props: DetailCardProps) {
  const {
    addresses,
    phoneNumber,
    firstName,
    lastName,
    instagramId,
    email,
    notes,
    sources,
    id,
  } = props;

  const [copied, setCopied] = useState<'phone' | 'insta' | 'mail'>();
  const [showAdd, setShowAdd] = useState(false);

  const address = [addresses?.[0]?.city, addresses?.[0]?.country].filter(
    Boolean
  );

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(undefined);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    if (copied === 'phone') {
      navigator.clipboard.writeText(phoneNumber!);
    } else if (copied === 'mail') {
      navigator.clipboard.writeText(email!);
    } else if (copied === 'insta') {
      navigator.clipboard.writeText(instagramId!);
    }
  }, [copied, email, instagramId, phoneNumber]);

  const { data: businessIntegration } = useWhatsappBusinessIntegration();
  const { data: cloudIntegration } = useWhatsappCloudIntegration();

  const [showChooseChannel, setShowChooseChannel] = useState(false);

  const hasBusinessIntegration = !!businessIntegration;
  const hasCloudIntegration = !!cloudIntegration;

  const hasBothWhatsappIntegration =
    hasBusinessIntegration && hasCloudIntegration;

  const [showSelectTemplate, setShowSelectTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WACloudTemplate | null | undefined
  >(null);

  const { data: templatesData } = useWACloudTemplates({ status: 'APPROVED' });
  const { mutateAsync: startChat, isLoading } = useStartChat();

  const history = useHistory();

  return (
    <>
      {showAdd && (
        <AddCustomerModal
          show={showAdd}
          onClose={() => {
            setShowAdd(false);
          }}
          data={props}
        />
      )}
      <BBBModal
        show={showSelectTemplate}
        onHide={() => {
          setShowSelectTemplate(false);
          setSelectedTemplate(null);
        }}
        title="Select template"
        subtitle="Choose template you want to send to the customer"
        footer
        submitText="Start chat"
        handleSave={async () => {
          await startChat({
            conversationId: phoneNumber!,
            sources: 'WHATSAPP_META',
            templateId: selectedTemplate!.id as string,
            message: selectedTemplate!.message as string,
            withoutCallback: true,
          });

          history.push(
            `/bitchat/livechat?chatId=${phoneNumber}&sources=WHATSAPP_META`
          );
        }}
        disableSave={!selectedTemplate}
        loadingSave={isLoading}
      >
        <BBBSelect
          options={templatesData}
          optionLabel="name"
          optionValue="id"
          placeholder="Choose message template"
          label="Template"
          containerClassName="mb-3"
          value={selectedTemplate}
          onValueChange={(opt) => {
            setSelectedTemplate(opt!);
          }}
          withCreateRedirectOption
          onClickCreateRedirect={() => {
            history.push(
              `/misc/whatsapp-api-settings/templates/new?section=chat`
            );
          }}
          createRedirectLabel="Create new template"
          persistCreateRedirectOption
        />
      </BBBModal>
      <BBBModal
        show={showChooseChannel}
        onHide={() => {
          setShowChooseChannel(false);
        }}
        title="Choose channel"
        subtitle="Select the channel that you want to initiate chat"
        size="xl"
      >
        <div className="flex justify-center gap-5">
          <div
            onClick={() => {
              setShowSelectTemplate(true);
            }}
            className={cn(isLoading && 'pointer-events-none')}
          >
            <div className="w-36 h-36 hover:outline-secondary-main transition-[outline-color] cursor-pointer flex flex-col items-center justify-center outline outline-1 outline-neutral-30 rounded-xl">
              <div className="mb-3 flex-none">
                <WhatsAppCloudApiIcon size={'1.875rem'} />
              </div>
              <div className="text-center mx-4">WhatsApp Cloud API</div>
            </div>
          </div>
          <div
            onClick={async () => {
              await startChat({
                conversationId: phoneNumber!,
                sources: 'WHATSAPP',
                withoutCallback: true,
              });

              history.push(
                `/bitchat/livechat?chatId=${phoneNumber}&sources=WHATSAPP`
              );
            }}
            className={cn(isLoading && 'pointer-events-none')}
          >
            <div className="w-36 h-36 hover:outline-secondary-main transition-[outline-color] cursor-pointer flex flex-col items-center justify-center outline outline-1 outline-neutral-30 rounded-xl">
              <div className="mb-3 flex-none">
                <WhatsAppIcon4 size={'1.875rem'} />
              </div>
              <div className="text-center mx-4">WhatsApp Business App</div>
            </div>
          </div>
        </div>
      </BBBModal>
      <BBBCard
        title="Customer details"
        rightButton={
          <span
            className="text-[#2B6AAF] underline cursor-pointer text-sm"
            onClick={() => setShowAdd(true)}
          >
            Edit
          </span>
        }
        className="mb-5"
      >
        <div className="flex gap-1.5 items-center mb-4">
          <UserIcon />
          <span className="text-primary-main">
            {formatDisplayName(phoneNumber, firstName, lastName)}
          </span>
        </div>
        <div className="flex gap-1.5 items-center mb-4">
          <div
            className={cx(
              'flex gap-1.5 relative cursor-pointer hover:underline items-center',
              !phoneNumber && 'pointer-events-none'
            )}
            onClick={() => setCopied('phone')}
          >
            <PhoneIcon />
            <span className="text-primary-main">
              {formatInternational(phoneNumber)}
            </span>
            {!!phoneNumber && <CopyIcon />}
            {copied === 'phone' && <CopyTooltip />}
          </div>
          {formatInternational(phoneNumber) !== '-' &&
            (hasBusinessIntegration || hasCloudIntegration) &&
            (hasBothWhatsappIntegration ? (
              <BBBTooltip show content="Chat">
                <div
                  onClick={() => {
                    setShowChooseChannel(true);
                  }}
                >
                  <ChatIcon2 color={'#9A9A9A'} />
                </div>
              </BBBTooltip>
            ) : (
              <Link
                to={`/bitchat/livechat?chatId=${phoneNumber}&sources=${
                  hasBusinessIntegration ? 'WHATSAPP' : 'WHATSAPP_META'
                }`}
              >
                <BBBTooltip show content="Chat">
                  <ChatIcon2 color={'#9A9A9A'} />
                </BBBTooltip>
              </Link>
            ))}
        </div>

        <div
          className={cx(
            'flex gap-1.5 cursor-pointer relative hover:underline items-center mb-4',
            !instagramId && 'pointer-events-none'
          )}
          onClick={() => setCopied('insta')}
        >
          <InstagramIcon />
          <span className="text-primary-main">{instagramId ?? '-'}</span>
          {!!instagramId && <CopyIcon />}
          {copied === 'insta' && <CopyTooltip />}
        </div>
        <div
          className={cx(
            'flex gap-1.5 relative cursor-pointer hover:underline items-center mb-4',
            !email && 'pointer-events-none'
          )}
          onClick={() => setCopied('mail')}
        >
          <EmailIcon />
          <span className="text-primary-main">{email ?? '-'}</span>
          {!!email && <CopyIcon />}
          {copied === 'mail' && <CopyTooltip />}
        </div>
        <div className="flex gap-1.5 items-center mb-4">
          <BriefcaseIcon />
          <span className="text-primary-main">
            {addresses?.length ? addresses[0].company || '-' : '-'}
          </span>
        </div>
        <div className="flex gap-1.5 items-center mb-4">
          <LocationIcon />
          <span className="text-primary-main">
            {addresses?.length && address.length ? address.join(', ') : '-'}
          </span>
        </div>
        <Notes id={id} notes={notes} />
      </BBBCard>
    </>
  );
}

function CopyTooltip() {
  return (
    <div className="absolute -top-6 left-12 rounded-lg p-2 bg-[#4E4E4EB2] text-neutral-10 text-xs">
      Copied
    </div>
  );
}

export default DetailCard;
