import { Model, ModelRules } from './model';

import { ApiResponse, CursoredResponse } from '@/api/types';
import api from '@/config/api';

export default {
  getCollections: (companyId: number, params?: GetCollectionParams) =>
    api.bitAi.v2New
      .get<CursoredResponse<CollectionAssociation[]>>(
        `/sortings/company/${companyId}`,
        { params }
      )
      .then((res) => res.data),
  getCollection: (companyId: number, collectionId: string) =>
    api.bitAi.v2New
      .get<ApiResponse<GetCollectionPayload>>(
        `/sortings/company/${companyId}/${collectionId}`
      )
      .then((res) => res.data.data),
  getAutomaticUpdate: (companyId: number) =>
    api.bitAi.v2New
      .get<ApiResponse<AutomaticUpdate>>(
        `/sortings/company/${companyId}/automatic-updates`
      )
      .then((res) => res.data.data),
  getCollectionProducts: (
    companyId: number,
    collectionId: string,
    params?: GetCollectionProductsParams
  ) =>
    api.bitAi.v2New
      .get<
        ApiResponse<
          (CollectionProduct & {
            product: Product;
          })[]
        >
      >(`/sortings/company/${companyId}/${collectionId}/products`, { params })
      .then((res) => res.data.data),
  updateOos: (
    companyId: number,
    collectionId: string,
    payload: { oos: boolean }
  ) =>
    api.bitAi.v2New.post(
      `/sortings/company/${companyId}/${collectionId}/oos`,
      payload
    ),
  updateSortingModel: (
    companyId: number,
    collectionId: string,
    payload: {
      modelId: string | null;
    }
  ) =>
    api.bitAi.v2New.post(
      `/sortings/company/${companyId}/${collectionId}/model`,
      payload
    ),
  bulkDeleteCollections: (
    companyId: number,
    payload: BulkDeleteCollectionsParams
  ) =>
    api.bitAi.v2New.post(
      `/sortings/company/${companyId}/bulk-delete-collections`,
      payload
    ),
  updateSortingRules: (
    companyId: number,
    collectionId: string,
    payload: { features: string[][] }
  ) =>
    api.bitAi.v2New.post(
      `/sortings/company/${companyId}/${collectionId}/rules`,
      payload
    ),
};

export type AutomaticUpdate = {
  companyId: number;
  nextUpdate: string | null;
  lastUpdate: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSortingRulesParams = {
  rules: CollectionSortingRow[];
};

export type BulkDeleteCollectionsParams = {
  collectionId: string[];
};

export type GetCollectionProductsParams = {
  search?: string;
  except?: string[];
};

export type CollectionAssociation = Collection & {
  sorting:
    | (Sorting & {
        model: Model | null;
      })
    | null;
  collectionProducts: CollectionProduct[];
};

export type GetCollectionPayload = Pick<Collection, 'id' | 'title'> & {
  sorting:
    | (Sorting & {
        model:
          | (Pick<Model, 'id' | 'name' | 'type'> & {
              rules: GetCollectionPayloadModelRule[];
            })
          | null;
        features: (Feature & {
          featureProducts: (FeatureProduct & {
            product: Product;
          })[];
        })[];
      })
    | null;
};

type Feature = {
  index: number;
  collectionId: string;
  companyId: number;
};

type FeatureProduct = {
  collectionId: string;
  companyId: number;
  featuredIndex: number;
  productId: string;
  index: number;
};

export type GetCollectionPayloadModelRule = Pick<
  ModelRules,
  'id' | 'qty' | 'condition'
>;
export type CollectionSortingRow = GetCollectionPayloadModelRule & {
  featuredProduct: Product[];
};

export type GetCollectionParams = {
  hideUnmanaged?: boolean;
  modelId?: string[];
  search?: string;
  limit?: number;
  after?: string;
  before?: string;
};

type Collection = {
  id: string;
  companyId: number;
  title: string;
  handle: string;
  createdAt: string;
  updatedAt: string;
};

type Sorting = {
  collectionId: string;
  companyId: number;
  oos: boolean;
  modelId: string | null;
};

type CollectionProduct = {
  collectionId: string;
  productId: string;
  companyId: number;
};

export type Product = {
  id: string;
  companyId: number;
  title: string;
  imageUrl: string | null;
  totalInventory: number;
  totalVariants: number;
  createdAt: Date;
  updatedAt: Date;
  totalRevenue: number | null;
  totalSold: number | null;
  minVariantComparePrice: number | null;
  maxVariantComparePrice: number | null;
};
