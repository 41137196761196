import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { AddCustomerTagsParams } from '@/api/services/customer/customer';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useAddCustomerTags = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();

  return useMutation(
    (params: Omit<AddCustomerTagsParams, 'companyId'>) =>
      services.customer.customer.addCustomerTags({
        companyId: activeCompany!,
        ...params,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers']);
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['infinite-customers']);
        queryClient.invalidateQueries(['customer-phonenumber']);
        toast.success(`Changes saved`);
      },
    }
  );
};

export default useAddCustomerTags;
