import Skeleton from 'react-loading-skeleton';
import { startCase } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import {
  useReviewIntegration,
  useSetReviewIntegration,
} from '@/hooks/bitApp/integration/integrations';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setPricingAlert } from '@/stores/common';
import { formatFreeWithUsagePlan } from '@/utils/pricing';

export default function ReviewSource() {
  const dispatch = useAppDispatch();

  const reviewQuery = useReviewIntegration();
  const connectIntegration = useConnectIntegration();

  const { data: pricingData } = usePricingByApp('BITAPP');

  const { mutate } = useSetReviewIntegration();

  const reviewIntegrations =
    pricingData && reviewQuery.data
      ? reviewQuery.data.map((integration) => ({
          ...integration,
          disabled: formatFreeWithUsagePlan(pricingData.pricingName) === 'free',
        }))
      : [];

  const dataFromApi =
    reviewIntegrations?.find(
      (integration) => integration.status === 'CONNECTED'
    ) || null;

  const handleClickIntegrations = (item: typeof reviewIntegrations[number]) => {
    if (item.status === 'DISCONNECTED') {
      if (item.integration.name === 'stamped')
        return connectIntegration({
          name: 'stamped',
        });
      if (item.integration.name === 'yotpo')
        return connectIntegration({
          name: 'yotpo',
        });
    }
    if (!item?.disabled) {
      mutate({
        integrationListId: item!.integrationId,
      });
    } else {
      dispatch(
        setPricingAlert({
          show: true,
          appType: 'BITAPP',
          desiredPlan: 'standard',
        })
      );
    }
  };

  return (
    <div className="flex flex-col gap-2.5">
      <p className="text-primary-main text-ls">
        Choose one app as your source of reviews on products:
      </p>

      <div className="flex gap-2.5 items-center">
        {reviewQuery.status === 'loading' ? (
          <ReviewAppSkeleton />
        ) : (
          reviewIntegrations.map((item) => (
            <div
              key={item.integrationId}
              className="flex flex-col items-center justify-center gap-1"
              onClick={() => handleClickIntegrations(item)}
            >
              <div
                className={cx(
                  'cursor-pointer border rounded-lg flex justify-center items-center py-5 px-6 hover:border-secondary-main transition-all',
                  dataFromApi?.integrationId === item.integrationId
                    ? 'border-secondary-main'
                    : 'border-neutral-30'
                )}
              >
                <img
                  src={item?.integration.logo || ''}
                  className="w-8 h-8 object-cover"
                  alt="integration-logo"
                />
              </div>
              <p className="text-xs text-primary-main">
                {startCase(item?.integration?.name)}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

function ReviewAppSkeleton() {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="flex flex-col items-center justify-center">
          <div className="border rounded-lg flex justify-center items-center py-5 px-6 border-neutral-30">
            <Skeleton width={24} height={24} className="w-8 h-8 object-cover" />
          </div>
          <Skeleton width={32} height={6} />
        </div>
      ))}
    </>
  );
}
