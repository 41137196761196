import { bitlinkProxyUrl } from '@/constants/api';
import useBranding from '@/hooks/bitLink/appearance/useBranding';
import { useAppSelector } from '@/hooks/rtk/store';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

const useIframeQuery = () => {
  const { data: shopifyIntegration, isInitialLoading: isLoadingIntegration } =
    useShopifyIntegrationByApp('BITLINK');
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const {
    data,
    isInitialLoading: isLoadingBranding,
    status,
  } = useBranding({
    enabled: !shopifyIntegration,
  });

  if (!shopifyIntegration && status !== 'success') return null;

  const proxy = shopifyIntegration
    ? `${bitlinkProxyUrl}/proxy/${activeCompany}`
    : `${bitlinkProxyUrl}/${data!.link}`;

  return { data: proxy, loading: isLoadingBranding || isLoadingIntegration };
};

export default useIframeQuery;
