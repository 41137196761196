import { ReactNode } from 'react';
import { Menu } from 'react-feather';
import { createId } from '@paralleldrive/cuid2';
import { ActiveDesign } from 'stores/bitApp';

import LayoutIcon from '@/assets/icons/LayoutIcon';
import MiscIcon from '@/assets/icons/MiscIcon';
import BBBNavigatorTab2 from '@/components/ui/BBBNavigatorTab/BBBNavigatorTab2';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setActiveDesign, setActivePreview } from '@/stores/bitApp';

const navigator: {
  id: string;
  name: string;
  icon: ReactNode;
  key: ActiveDesign;
}[] = [
  {
    id: createId(),
    name: 'Layout',
    icon: <LayoutIcon />,
    key: 'layout',
  },
  {
    id: createId(),
    name: 'Navigation',
    icon: <Menu />,
    key: 'navigation',
  },
  {
    id: createId(),
    name: 'Category',
    icon: <MiscIcon color="currentColor" />,
    key: 'category',
  },
];

export default function NavigatorTab() {
  const dispatch = useAppDispatch();
  const activeDesign = useAppSelector((state) => state.bitApp.activeDesign);

  return (
    <BBBNavigatorTab2
      id="navigator-tab"
      navigator={navigator}
      value={activeDesign}
      onChange={(item) => {
        dispatch(setActiveDesign(item.key));
        if (item.key === 'category') {
          dispatch(setActivePreview('category'));
        } else {
          dispatch(setActivePreview('home'));
        }
      }}
    />
  );
}
