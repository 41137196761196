import { createSelector } from '@reduxjs/toolkit';

import { useAppSelector } from '@/hooks/rtk/store';
import { RootState } from '@/stores';
import { emptyEditor } from '@/utils/common/rich';

export const memoSelector = (state: RootState) => state.bitCRM.liveChatMemo;
export const activeChatSelector = (state: RootState) =>
  state.bitCRM.selectedChat;

export const liveChatMemoSelector = createSelector(
  [memoSelector, activeChatSelector],
  (memo, activeChat) =>
    activeChat
      ? memo[`${activeChat.clientNumber}-${activeChat.sources}`]
      : undefined
);

export const activeMessageSelector = createSelector(
  [liveChatMemoSelector],
  (data) => data?.messageToSend || emptyEditor
);

export const recommendedProductSelector = createSelector(
  [liveChatMemoSelector],
  (data) => data?.recommendedProduct
);

export const selectedReplySelector = createSelector(
  [liveChatMemoSelector],
  (data) => data?.selectedReply
);

const useActiveChatMemo = () => useAppSelector(liveChatMemoSelector);

export default useActiveChatMemo;
