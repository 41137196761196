import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useRearrangeNavigation = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useMutation(
    (
      reorderedData: Parameters<
        typeof services.bitApp.navigation.rearrangeNavigation
      >[1]['reorderedData']
    ) =>
      services.bitApp.navigation.rearrangeNavigation(activeCompany!, {
        reorderedData,
      }),
    {
      onMutate: async (reorderedData) => {
        await client.cancelQueries(['navigations', activeCompany]);
        const previousNavigation = client.getQueryData([
          'navigations',
          activeCompany,
        ]);
        client.setQueryData(['navigations', activeCompany], {
          active: reorderedData.active,
          unActive: reorderedData.nonActive,
        });
        return { previousNavigation };
      },
      onError: (_, __, context) => {
        client.setQueryData(
          ['navigation', activeCompany],
          context?.previousNavigation
        );
      },
      onSettled: () => {
        client.invalidateQueries(['navigation', activeCompany]);
      },
    }
  );
};

export default useRearrangeNavigation;
