import { SVGProps } from 'react';

const RecommendationIcon = ({
  width = 18,
  height = 18,
  ...props
}: {
  width?: number;
  height?: number;
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13973 6.03593C2.97029 6.0359 2.80705 6.09968 2.68253 6.21459C2.558 6.3295 2.48132 6.4871 2.46777 6.656L1.95362 13.0589C1.95362 13.0589 1.95362 13.0589 1.95362 13.0589C1.94618 13.1516 1.95801 13.2449 1.98838 13.3328C2.01876 13.4208 2.06701 13.5015 2.1301 13.5698C2.19319 13.6382 2.26975 13.6928 2.35497 13.7301C2.44013 13.7674 2.53209 13.7867 2.62507 13.7868C2.62513 13.7868 2.62501 13.7868 2.62507 13.7868H11.3751C11.3751 13.7868 11.375 13.7868 11.3751 13.7868C11.468 13.7867 11.5602 13.7674 11.6453 13.7301C11.7306 13.6928 11.8071 13.6382 11.8702 13.5698C11.9333 13.5015 11.9815 13.4208 12.0119 13.3328C12.0423 13.2449 12.0541 13.1516 12.0467 13.0589C12.0467 13.0589 12.0467 13.0589 12.0467 13.0589L11.5325 6.65602C11.519 6.48713 11.4423 6.3295 11.3178 6.21459C11.1933 6.09968 11.03 6.0359 10.8606 6.03593H3.13973ZM3.13986 4.82275C2.66546 4.82269 2.20844 5.00129 1.8598 5.32301C1.51113 5.64475 1.29644 6.08601 1.25848 6.55892C1.25848 6.55891 1.25848 6.55893 1.25848 6.55892L0.74433 12.9618C0.72349 13.2215 0.756634 13.4827 0.841679 13.7289C0.926724 13.9751 1.06183 14.2011 1.23848 14.3925C1.41513 14.584 1.62951 14.7368 1.86811 14.8413C2.10672 14.9459 2.36438 14.9999 2.62489 15H11.3752C11.6357 14.9999 11.8936 14.9459 12.1322 14.8413C12.3708 14.7368 12.5852 14.584 12.7618 14.3925C12.9385 14.2011 13.0736 13.9751 13.1586 13.7289C13.2437 13.4827 13.2768 13.2215 13.256 12.9618L12.7418 6.55894C12.7418 6.55895 12.7418 6.55894 12.7418 6.55894C12.7039 6.08604 12.4892 5.64475 12.1405 5.32301C11.7919 5.00129 11.3348 4.82269 10.8604 4.82275C10.8605 4.82275 10.8604 4.82275 10.8604 4.82275H3.13986C3.1399 4.82275 3.13982 4.82275 3.13986 4.82275Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73455 1.88495C5.33494 1.28456 6.14924 0.947266 6.99831 0.947266C7.84739 0.947266 8.66169 1.28456 9.26207 1.88495C9.86246 2.48533 10.1998 3.29963 10.1998 4.1487V7.35014C10.1998 7.70376 9.91309 7.99043 9.55946 7.99043C9.20584 7.99043 8.91918 7.70376 8.91918 7.35014V4.1487C8.91918 3.63926 8.7168 3.15068 8.35657 2.79045C7.99634 2.43022 7.50776 2.22784 6.99831 2.22784C6.48887 2.22784 6.00029 2.43022 5.64006 2.79045C5.27983 3.15068 5.07745 3.63926 5.07745 4.1487V7.35014C5.07745 7.70376 4.79078 7.99043 4.43716 7.99043C4.08354 7.99043 3.79688 7.70376 3.79688 7.35014V4.1487C3.79688 3.29963 4.13417 2.48533 4.73455 1.88495Z"
        fill="#757575"
      />
    </svg>
  );
};

export default RecommendationIcon;
