import { useQuery } from '@tanstack/react-query';

import api from '@/config/api';

export type IGetFiltersCampaignLog = string[];

const useCampaignLogFilter = () => {
  return useQuery<IGetFiltersCampaignLog>(['company-users'], async () => {
    const { data } = await api.bitCRM.v2.get(`/log/filters`);
    return data;
  });
};

export default useCampaignLogFilter;
