import { useEffect } from 'react';
import { FieldErrorsImpl, FieldValues } from 'react-hook-form';

export default function useScrollToError<T extends FieldValues>(
  errors: Partial<FieldErrorsImpl<T>>
) {
  useEffect(() => {
    const getNestedErrorPaths = (obj: any, path = ''): string[] => {
      if (obj && typeof obj === 'object' && !obj.message) {
        return Object.entries(obj).flatMap(([key, value]) => {
          const newPath = path ? `${path}.${key}` : key;
          return getNestedErrorPaths(value, newPath);
        });
      }
      return [path];
    };

    const errorPaths = getNestedErrorPaths(errors);

    const elements = errorPaths
      .map((path) => {
        return (
          document.getElementsByName(path)[0] ||
          document.querySelector(`[data-form-name="${path}"]`)
        );
      })
      .filter((el) => !!el);

    elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
    elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [errors]);
}
