import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { chunk, cloneDeep } from 'lodash-es';

import api from '@/config/api';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { triggerRefreshIframe } from '@/stores/bitLink';
import { Linklists } from '@/types/bitLink/v2';

const useRearrangeLinklist = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (data: Linklists[]) =>
      api.bitLink.put(
        `/linklist/rearrange`,
        data.map((item, index) => ({
          id: item.id,
          position: index + 1,
        }))
      ),
    {
      onMutate: async (payload) => {
        await client.cancelQueries(['linklist', activeCompany]);

        const previousLinklist = client.getQueryData<
          InfiniteData<{
            data: Linklists[];
            meta: {
              page?: number | undefined;
              limit: number;
            };
          }>
        >(['linklist', activeCompany]);

        client.setQueryData<
          | InfiniteData<{
              data: Linklists[];
              meta: {
                page?: number | undefined;
                limit: number;
              };
            }>
          | undefined
        >(['linklist', activeCompany], (old) => {
          const mappedNewPosition = Object.fromEntries(
            payload.map((newPos, i) => [newPos.id, i + 1])
          );

          const pages = cloneDeep(old?.pages || [])
            .flatMap((page) => page.data)
            .map((link) => {
              if (mappedNewPosition[link.id]) {
                return {
                  ...link,
                  position: mappedNewPosition[link.id],
                };
              }
              return link;
            })
            .sort((a, b) => (a.position! > b.position! ? 1 : -1));

          const chunkedPage = chunk(pages, 10).map((chunked, i) => ({
            data: chunked,
            meta: {
              page: old?.pages[i].meta.page,
              limit: old?.pages[i].meta.limit || 5,
            },
          }));

          return {
            pages: chunkedPage,
            pageParams: old?.pageParams || [],
          };
        });

        return { previousLinklist };
      },
      onError: (__, _, context: any) => {
        client.setQueryData(
          ['linklist', activeCompany],
          context.previousLinklist
        );
      },
      onSettled: () => {
        client.invalidateQueries(['linklist', activeCompany]);
        dispatch(triggerRefreshIframe());
      },
    }
  );
};

export default useRearrangeLinklist;
