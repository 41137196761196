import { Fragment, useEffect, useMemo, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import {
  Control,
  Controller,
  FormProvider,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ProxyCallbackParams } from '@/api/services/bitLogin/login/proxy';
import { FieldType, LoginSetting } from '@/api/services/bitLogin/login/setting';
import { Country, State } from '@/api/services/countries';
import {
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBSelect,
  BBBSpinner,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';
import { BBBRadio } from '@/components/ui/BBBRadio';
import { useActiveLoginSettings } from '@/hooks/bitLogin/login/login-settings';
import {
  useDecryptedData,
  useProxyCallback,
} from '@/hooks/bitLogin/login/proxy';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import {
  useCountries,
  useCountryStates,
  useStateCities,
} from '@/hooks/countries';
import { formatPhonePayload, phoneValidator } from '@/utils/common/phone';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

type Gender = 'male' | 'female' | 'preferNotToSay';

type FormSchema = {
  phone: BBBTelInputValue | null;
  gender: {
    label: string;
    value: Gender;
  } | null;
  dob: string;
  address1: string;
  address2: string;
  city: { label: string; value: string } | null;
  country: Country | null;
  province: State | null;
  zip: string;
} & Record<string, any>;

const genderOptions: {
  label: string;
  value: Gender;
}[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Others',
    value: 'preferNotToSay',
  },
];

const phoneSchema = (optional: boolean) => phoneValidator(optional);
const genderSchema = yup
  .mixed<{
    label: string;
    value: Gender;
  } | null>()
  .label('Gender');
const dobSchema = yup.string().label('Date of birth');

export default function SignupProxy() {
  const query = useQuerySearchParams();

  const code = query.get('code');
  const domain = query.get('domain');

  if (!code || !domain) throw new Error('Invalid code');

  return <_SignupProxy domain={domain} code={code} />;
}

function _SignupProxy({ domain, code }: { domain: string; code: string }) {
  const { data: loginSettings, isLoading: loadingSettings } =
    useActiveLoginSettings(domain);

  if (loadingSettings) {
    return (
      <div className="h-screen flex items-center justify-center">
        <BBBSpinner />
      </div>
    );
  }

  return (
    <__SignupProxy loginSettings={loginSettings!} code={code} domain={domain} />
  );
}

function __SignupProxy({
  loginSettings,
  code,
  domain,
}: {
  loginSettings: LoginSetting[];
  code: string;
  domain: string;
}) {
  const defaultPhone = useDefaultCountryCode();

  const basicSettings = Object.fromEntries(
    loginSettings
      ?.filter((d) => d.group === 'basic')
      .map((setting) => [setting.type, setting]) || []
  ) as Partial<Record<FieldType, LoginSetting>>;

  const addressSettings = loginSettings?.filter((d) => d.group === 'address');

  const customSettings = useMemo(
    () => loginSettings?.filter((d) => d.group === 'custom'),
    [loginSettings]
  );

  const titleSettings = Object.fromEntries(
    loginSettings
      ?.filter((d) => d.group === 'title')
      .map((setting) => [setting.type, setting]) || []
  ) as Partial<Record<FieldType, LoginSetting>>;

  const hasBasicSettings = !!Object.keys(basicSettings).length;
  const hasAddressSettings = !!addressSettings?.length;
  const hasCustomSettings = !!customSettings?.length;

  const getTotalPage = () => {
    let count = 0;

    if (hasBasicSettings) {
      count += 1;
    }

    if (hasCustomSettings) {
      count += 1;
    }

    if (hasAddressSettings) {
      count += 1;
    }

    return count;
  };

  const totalPage = getTotalPage();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (totalPage) {
      setStep(1);
    }
  }, [totalPage]);

  const { data: decryptedData } = useDecryptedData(code);

  const { mutate: loginAction, isLoading: loadingProxyCallback } =
    useProxyCallback();

  const onSubmit = ({
    phone,
    gender,
    dob,
    city,
    country,
    address1,
    address2,
    province,
    zip,
    ...metadata
  }: FormSchema) => {
    const customSettingKeyToType = Object.fromEntries(
      customSettings.map((setting) => [
        setting.key,
        {
          type: setting.type,
          label: setting.label,
        },
      ])
    ) as Record<
      string,
      {
        type: FieldType;
        label: string;
      }
    >;

    const payload: ProxyCallbackParams = {
      ...decryptedData!,
      shop: domain,
      phoneNumber: formatPhonePayload(phone),
      gender: gender?.value,
      dateOfBirth: dob,
      city: city?.label || '',
      region: country?.name || '',
      country_name: country?.name || '',
      address: address1,
      address2: address2,
      province: province?.state_code || '',
      zip,
      metadata: Object.entries(metadata).map(([key, value]) => {
        let outValue = value;

        if (customSettingKeyToType[key].type === 'dropdown_list' && value) {
          outValue = value['value'];
        }

        return {
          key,
          value:
            outValue !== null && typeof outValue === 'object'
              ? JSON.stringify(outValue)
              : outValue,
          label: customSettingKeyToType[key].label,
        };
      }),
    };

    loginAction(payload);
  };

  const defaultValues = useMemo(() => {
    const customSettingsMap = customSettings?.length
      ? Object.fromEntries(
          customSettings.map((setting) => {
            let value: any | null = null;

            if (
              setting.type === 'single_line_text' ||
              setting.type === 'multi_line_text'
            ) {
              value = '';
            } else if (setting.type === 'dropdown_list') {
              value = null;
            } else if (setting.type === 'radio_button') {
              value = null;
            } else if (setting.type === 'multiple_choice') {
              value = [];
            }

            return [setting.key, value];
          })
        )
      : {};

    return {
      phone: defaultPhone,
      dob: '',
      gender: null,
      address1: '',
      address2: '',
      city: null,
      country: null,
      province: null,
      zip: '',
      ...customSettingsMap,
    };
  }, [customSettings, defaultPhone]);

  const isAddressMandatory =
    !!addressSettings.length && !!addressSettings?.[0].mandatory;

  const methods = useCustomForm<FormSchema>({
    resolver: yupResolver(
      yup.object({
        ...(addressSettings?.length && {
          address1: createAddressValidation('Address1', isAddressMandatory),
          address2: yup.string().label('Address2'),
          city: createAddressValidation('City', isAddressMandatory),
          country: createAddressValidation('Country', isAddressMandatory),
          province: createAddressValidation('Province', isAddressMandatory),
          zip: createAddressValidation('Zip', isAddressMandatory),
        }),
        phone: phoneSchema(!basicSettings['phoneNumber']?.mandatory),
        gender: basicSettings['gender']?.mandatory
          ? genderSchema.required()
          : genderSchema,
        dob: basicSettings['dateOfBirth']?.mandatory
          ? dobSchema.required()
          : dobSchema,
        ...(customSettings?.length &&
          Object.fromEntries(
            customSettings.map((data) => {
              let validation;

              if (
                data.type === 'single_line_text' ||
                data.type === 'multi_line_text'
              ) {
                let schema = yup.string().label(data.label!);

                if (data.mandatory) {
                  schema = schema.required();
                }

                validation = schema;
              } else if (
                data.type === 'dropdown_list' ||
                data.type === 'radio_button' ||
                data.type === 'multiple_choice'
              ) {
                let schema = yup.mixed().label(data.label!);

                if (data.mandatory) {
                  schema = schema.required().test((value) => {
                    if (!value) {
                      return false;
                    }

                    if (data.type === 'multiple_choice') {
                      return value.length > 0;
                    }

                    return true;
                  });
                }

                validation = schema;
              }

              return [data.key, validation];
            })
          )),
      })
    ),
    defaultValues,
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <BBBCard className="my-auto min-h-[23.75rem] w-full max-w-md flex flex-col justify-center !p-10 shadow-md">
      {step === 1 ? (
        <TitleFields titleSettings={titleSettings} />
      ) : (
        <div
          className="flex items-center gap-2 mb-4 cursor-pointer"
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
        >
          <ArrowLeft size={16} />
          Back
        </div>
      )}

      <FormProvider {...methods}>
        <FormContent
          basicSettings={basicSettings}
          addressSettings={addressSettings}
          customSettings={customSettings}
          hasAddressSettings={hasAddressSettings}
          hasBasicSettings={hasBasicSettings}
          hasCustomSettings={hasCustomSettings}
          control={control}
          page={step}
          totalPage={totalPage}
        />
      </FormProvider>

      {step < totalPage ? (
        <BBBButton
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          className="mt-2"
        >
          Next
        </BBBButton>
      ) : (
        <BBBButton
          onClick={() => {
            handleSubmit(
              (data) => {
                onSubmit(data);
              },
              () => {
                toast.error(
                  'Missing required fields. Please check your submission'
                );
              }
            )();
          }}
          className="mt-5"
          loadingState={loadingProxyCallback}
        >
          Submit
        </BBBButton>
      )}

      {step === totalPage && titleSettings.consent_disclaimer?.active && (
        <a
          href="https://www.bitbybit.studio/knowledge-base/enabling-the-capture-more-data-feature-in-bitlogin"
          target="_blank"
          rel="noreferrer"
          className="text-center mt-3 text-neutral-500 text-xs underline cursor-pointer"
        >
          How we use data for marketing needs
        </a>
      )}
    </BBBCard>
  );
}

function FormContent({
  page,
  totalPage,
  basicSettings,
  addressSettings,
  customSettings,
  hasBasicSettings,
  hasAddressSettings,
  hasCustomSettings,
  control,
}: {
  page: number;
  totalPage: number;
  basicSettings: Partial<Record<FieldType, LoginSetting>>;
  addressSettings: LoginSetting[] | undefined;
  customSettings: LoginSetting[] | undefined;
  hasBasicSettings: boolean;
  hasAddressSettings: boolean;
  hasCustomSettings: boolean;
  control: Control<FormSchema>;
}) {
  if (!totalPage) {
    return null;
  }

  if (totalPage === 1) {
    if (hasBasicSettings) {
      return <BasicFields basicSettings={basicSettings} control={control} />;
    }

    if (hasAddressSettings) {
      return (
        <AddressFields addressSettings={addressSettings} control={control} />
      );
    }

    if (hasCustomSettings) {
      return <CustomFields customFields={customSettings} control={control} />;
    }

    return null;
  }

  if (totalPage === 2) {
    if (hasBasicSettings && hasAddressSettings) {
      if (page === 1) {
        return <BasicFields basicSettings={basicSettings} control={control} />;
      }

      return (
        <AddressFields addressSettings={addressSettings} control={control} />
      );
    }

    if (hasBasicSettings && hasCustomSettings) {
      if (page === 1) {
        return <BasicFields basicSettings={basicSettings} control={control} />;
      }

      return <CustomFields customFields={customSettings} control={control} />;
    }

    if (hasAddressSettings && hasCustomSettings) {
      if (page === 1) {
        return (
          <AddressFields addressSettings={addressSettings} control={control} />
        );
      }

      return <CustomFields customFields={customSettings} control={control} />;
    }
  }

  if (totalPage === 3) {
    if (page === 1) {
      return <BasicFields basicSettings={basicSettings} control={control} />;
    }

    if (page === 2) {
      return (
        <AddressFields addressSettings={addressSettings} control={control} />
      );
    }

    return <CustomFields customFields={customSettings} control={control} />;
  }

  return null;
}

function CustomFields({
  customFields,
  control,
}: {
  customFields: LoginSetting[] | undefined;
  control: Control<FormSchema>;
}) {
  const { errors } = useFormState({ control });

  const customFieldSorted = customFields?.sort((a, b) => {
    const aIndex = a.index ?? Infinity;
    const bIndex = b.index ?? Infinity;

    return aIndex - bIndex;
  });

  if (!customFieldSorted?.length) return null;

  return (
    <>
      {customFieldSorted.map((field) => (
        <Fragment key={field.key || field.type}>
          {field.type === 'single_line_text' && (
            <BBBTextInput
              isHookForm
              control={control}
              controlName={field.key!}
              error={errors[field.key!]?.message as string | undefined}
              label={
                <p className="text-primary-main   mb-1">
                  {field.label}
                  {field?.mandatory && (
                    <span className="text-danger-main">*</span>
                  )}
                </p>
              }
              placeholder="Enter your answer"
            />
          )}
          {field.type === 'multi_line_text' && (
            <BBBTextAreaInput
              isHookForm
              control={control}
              controlName={field.key!}
              error={errors[field.key!]?.message as string | undefined}
              label={
                <p className="text-primary-main   mb-1">
                  {field.label}
                  {field?.mandatory && (
                    <span className="text-danger-main">*</span>
                  )}
                </p>
              }
              placeholder="Enter your answer"
              rows={5}
            />
          )}
          {field.type === 'dropdown_list' && (
            <Controller
              control={control}
              name={field.key!}
              render={({ field: formField }) => (
                <BBBSelect
                  options={field.options?.map((opt) => ({
                    label: opt,
                    value: opt,
                  }))}
                  optionLabel="label"
                  optionValue="value"
                  label={
                    <p className="text-primary-main   mb-1">
                      {field.label}
                      {field?.mandatory && (
                        <span className="text-danger-main">*</span>
                      )}
                    </p>
                  }
                  containerClassName="mb-3"
                  placeholder="Enter your answer"
                  value={formField.value}
                  onValueChange={formField.onChange}
                  error={errors[field.key!]?.message as string | undefined}
                />
              )}
            />
          )}
          {field.type === 'radio_button' && (
            <Controller
              control={control}
              name={field.key!}
              render={({ field: formField }) => (
                <div className="mb-4">
                  <BBBRadio
                    options={
                      field.options?.map((opt) => ({
                        label: opt,
                        value: opt,
                      })) || []
                    }
                    value={formField.value}
                    onChange={formField.onChange}
                    label={
                      <p className="text-primary-main  ">
                        {field.label}
                        {field?.mandatory && (
                          <span className="text-danger-main">*</span>
                        )}
                      </p>
                    }
                    labelClassName="text-sm"
                    optionsClassName="flex-wrap gap-2.5"
                    containerClassName="gap-1"
                    vertical={field.orientation === 'vertical'}
                  />
                  {errors[field.key!]?.message && (
                    <p className="!text-danger-main ">
                      {errors[field.key!]!.message}
                    </p>
                  )}
                </div>
              )}
            />
          )}
          {field.type === 'multiple_choice' && (
            <Controller
              control={control}
              name={field.key!}
              render={({ field: formField }) => (
                <div className="mb-4">
                  <div className="mb-2">
                    <p className="text-primary-main   mb-1">
                      {field.label}
                      {field?.mandatory && (
                        <span className="text-danger-main">*</span>
                      )}
                    </p>
                  </div>
                  <div
                    className={cn(
                      'flex gap-2.5 flex-wrap',
                      field.orientation === 'vertical' && 'flex-col'
                    )}
                  >
                    {field.options?.map((opt) => (
                      <BBBCheckbox
                        checked={formField.value.includes(opt)}
                        onValueChange={(val) => {
                          if (val) {
                            if (!formField.value.includes(opt)) {
                              formField.onChange([...formField.value, opt]);
                            }
                          } else {
                            formField.onChange(
                              //@ts-ignore
                              formField.value.filter((v) => v !== opt)
                            );
                          }
                        }}
                        labelClassName="text-sm"
                        label={opt}
                        key={opt}
                      />
                    ))}
                  </div>
                  {errors[field.key!]?.message && (
                    <p className="!text-danger-main ">
                      {errors[field.key!]!.message}
                    </p>
                  )}
                </div>
              )}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

function BasicFields({
  basicSettings,
  control,
}: {
  basicSettings: Partial<Record<FieldType, LoginSetting>>;
  control: Control<FormSchema>;
}) {
  const { errors } = useFormState({ control });

  return (
    <>
      {basicSettings.phoneNumber && (
        <BBBTelInput
          isHookForm
          label={
            <p className="text-primary-main   mb-1">
              Phone Number
              {basicSettings.phoneNumber.mandatory && (
                <span className="text-danger-main">*</span>
              )}
            </p>
          }
          placeholder="123456789"
          control={control}
          controlName="phone"
          error={errors.phone?.message}
          containerClassname="mb-4"
        />
      )}
      {basicSettings.gender && (
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <BBBSelect
              value={field.value}
              options={genderOptions}
              placeholder="What is your gender?"
              optionLabel="label"
              label={
                <p className="text-primary-main   mb-1">
                  Gender
                  {basicSettings.gender?.mandatory && (
                    <span className="text-danger-main">*</span>
                  )}
                </p>
              }
              optionValue="value"
              onValueChange={field.onChange}
              isSearchable
              error={errors.gender?.message}
              containerClassName="mb-4"
              activeLabelClassName="text-sm"
            />
          )}
        />
      )}
      {basicSettings.dateOfBirth && (
        <BBBTextInput
          label={
            <p className="text-primary-main   mb-1">
              Date of birth
              {basicSettings.dateOfBirth?.mandatory && (
                <span className="text-danger-main">*</span>
              )}
            </p>
          }
          labelClassname="text-primary-main"
          placeholder="Date of birth"
          type="date"
          isHookForm
          control={control}
          controlName="dob"
          error={errors.dob?.message}
          containerClassname="mb-4"
        />
      )}
    </>
  );
}

function TitleFields({
  titleSettings,
}: {
  titleSettings: Partial<Record<FieldType, LoginSetting>>;
}) {
  if (!Object.keys(titleSettings).length) return null;

  return (
    <div className="mb-4">
      {titleSettings['form_title'] && (
        <div className="text-2xl ">{titleSettings['form_title'].value}</div>
      )}
      {titleSettings['form_sub_heading'] && (
        <div className="mt-2 text-neutral-40">
          {titleSettings['form_sub_heading'].value}
        </div>
      )}
    </div>
  );
}

function AddressFields({
  control,
  addressSettings,
}: {
  control: Control<FormSchema>;
  addressSettings: LoginSetting[] | undefined;
}) {
  const { setValue } = useFormContext<FormSchema>();

  const { errors } = useFormState({ control });

  const { data: countries, isLoading: loadingCountries } = useCountries();

  const country = useWatch({ control, name: 'country' });
  const province = useWatch({ control, name: 'province' });

  const { data: states, isLoading: loadingStates } = useCountryStates({
    country: country?.name,
  });

  const { data: cities, isLoading: loadingCities } = useStateCities({
    country: country?.name,
    state: province?.name,
  });

  if (!addressSettings?.length) return null;

  const mandatoryAddress = addressSettings[0].mandatory;

  return (
    <>
      <BBBTextInput
        isHookForm
        control={control}
        controlName="address1"
        error={errors.address1?.message}
        placeholder="Enter your address"
        containerClassname="mb-4"
        label={
          <p className="text-primary-main  mb-1">
            Address 1
            {mandatoryAddress && <span className="text-danger-main">*</span>}
          </p>
        }
      />
      <BBBTextInput
        isHookForm
        control={control}
        controlName="address2"
        error={errors.address2?.message}
        placeholder="Enter your address"
        containerClassname="mb-4"
        label={<p className="text-primary-main   mb-1">Address 2 (optional)</p>}
      />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <BBBSelect
            error={errors.country?.message}
            placeholder="Enter your country"
            containerClassName="mb-4"
            label={
              <p className="text-primary-main   mb-1">
                Country/region
                {mandatoryAddress && (
                  <span className="text-danger-main">*</span>
                )}
              </p>
            }
            options={countries}
            optionLabel="name"
            optionValue="iso2"
            value={field.value}
            onValueChange={(v) => {
              field.onChange(v);
              setValue('province', null);
              setValue('city', null);
            }}
            loading={loadingCountries}
            isSearchable
          />
        )}
      />
      <Controller
        control={control}
        name="province"
        render={({ field }) => (
          <BBBSelect
            error={errors.province?.message}
            placeholder="Enter your province"
            containerClassName="mb-4"
            label={
              <p className="text-primary-main   mb-1">
                Province
                {mandatoryAddress && (
                  <span className="text-danger-main">*</span>
                )}
              </p>
            }
            value={field.value}
            onValueChange={(v) => {
              field.onChange(v);
              setValue('city', null);
            }}
            options={states}
            optionValue="state_code"
            optionLabel="name"
            loading={!!country && loadingStates}
            isSearchable
          />
        )}
      />

      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <BBBSelect
            error={errors.city?.message}
            placeholder="Enter your city"
            containerClassName="mb-4"
            label={
              <p className="text-primary-main   mb-1">
                City
                {mandatoryAddress && (
                  <span className="text-danger-main">*</span>
                )}
              </p>
            }
            value={field.value}
            onValueChange={field.onChange}
            options={cities}
            optionValue="value"
            optionLabel="label"
            loading={!!country && !!province && loadingCities}
          />
        )}
      />
      <BBBTextInput
        isHookForm
        control={control}
        controlName="zip"
        error={errors.zip?.message}
        placeholder="Enter your postal"
        containerClassname="mb-4"
        label={
          <p className="text-primary-main   mb-1">
            Postal / Zip Code
            {mandatoryAddress && <span className="text-danger-main">*</span>}
          </p>
        }
      />
    </>
  );
}

function createAddressValidation(label: string, mandatory: boolean) {
  let schema = yup.string();

  if (label === 'City' || label === 'Country' || label === 'Province') {
    //@ts-ignore
    schema = yup.mixed();
  }

  schema = schema.label(label);

  if (mandatory) {
    schema = schema.required();
  }

  return schema;
}
