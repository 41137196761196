import { BBBTopNav } from '@/components/ui';
import useSidebarMeta from '@/hooks/common/useSidebarMeta';

export default function EmbedLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { mapActiveSidebar } = useSidebarMeta();

  return (
    <div className="w-full h-full bg-neutral-20 relative mx-auto flex flex-col">
      <BBBTopNav items={mapActiveSidebar} />
      <div className="relative grow">
        <div className="absolute inset-0">{children}</div>
      </div>
    </div>
  );
}
