import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import CustomerCart from './CustomerCart';
import CustomerDetail from './CustomerDetail';
import OrderHistory from './OrderHistory';
import Sandbox from './Sandbox';
import TicketDetail from './TicketDetail';
import WebActivity from './WebActivity';

import { integrationsMeta } from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import { useAppSelector } from '@/hooks/rtk/store';
import { selectActiveChatTruthy } from '@/hooks/whatsApp/chat';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';

type Props = {
  onEventChange?: (val: 'back') => void;
  className?: string;
};

type _InfoPanelProps = Props;

export default function InfoPanel(props: Props) {
  const selectedChat = useAppSelector((state) => !!state.bitCRM.selectedChat);

  if (!selectedChat) return null;

  return <_InfoPanel {...props} />;
}

function _InfoPanel({ onEventChange, className }: _InfoPanelProps) {
  const activeInfoPanel = useAppSelector(
    (state) => state.bitCRM.selectedInfoPanel
  );
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return (
    <div
      className={cx(
        `flex-none transition-all overflow-auto flex flex-col gap-4`,
        className
      )}
    >
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={activeInfoPanel}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.2 }}
          className="flex-grow flex flex-col gap-4"
        >
          {activeInfoPanel === 'customer-details' ? (
            <CustomerDetail
              onEventChange={onEventChange}
              sources={selectedChat.sources}
            />
          ) : activeInfoPanel === 'tickets' ? (
            <TicketDetail />
          ) : activeInfoPanel === 'order-history' ? (
            <OrderHistory />
          ) : activeInfoPanel === 'web-activity' ? (
            <WebActivity />
          ) : activeInfoPanel === 'customer-cart' ? (
            <CustomerCart />
          ) : activeInfoPanel === 'sandbox' ? (
            <Sandbox />
          ) : null}
          <Info />
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

function Info() {
  return (
    <div className="grow justify-end flex flex-col gap-1 text-neutral-40">
      <GroupId />
      <Channel />
    </div>
  );
}

function GroupId() {
  const isGroup = useIsGroupChat();
  const clientNumber = useAppSelector(
    (s) => s.bitCRM.selectedChat!.clientNumber
  );

  if (!isGroup) return null;

  return (
    <>
      <p>Group chat ID:</p>
      <p>{clientNumber}</p>
    </>
  );
}

function Channel() {
  const source = useAppSelector((state) => state.bitCRM.selectedChat!.sources);

  if (!source) return null;

  const channel = integrationsMeta[mapSourceToKnownIntegrations[source]].title;

  if (!channel) return null;

  return <span>Channel: {channel}</span>;
}
