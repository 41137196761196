import { useState } from 'react';
import { env } from 'config/env';
import { useProducts } from 'hooks/bitCRM/shopify';

import { BBBSelect } from '@/components/ui';
import { SingleOnValueChangeParam } from '@/components/ui';
import { ShopifyProduct } from '@/types/shopify/products';

export type ShopifyProductPayload = Pick<
  ShopifyProduct,
  'id' | 'title' | 'featuredImage'
>;

export type ShopifyProductForm = ShopifyProduct | ShopifyProductPayload;

type Props = {
  className?: string;
  label: string | React.ReactNode;
  onChange: (val?: SingleOnValueChangeParam<ShopifyProductForm>) => void;
  value: SingleOnValueChangeParam<ShopifyProductForm>;
  error?: string;
};

function ShopifyProductListsOptions({
  className,
  label,
  value,
  onChange,
  error,
}: Props) {
  const [search, setSearch] = useState<string>();

  const productQuery = useProducts({
    search,
  });

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = productQuery;

  const products = data?.pages
    .flatMap((page) => page.products)
    .map((page) => ({
      ...page,
      label2: <ProductOption product={page} />,
    }));

  const _value = value
    ? { ...value, label2: <ProductOption product={value} /> }
    : null;

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={products}
      //@ts-ignore
      optionLabel="label2"
      optionValue="id"
      placeholder="Choose product"
      loading={isInitialLoading}
      search={search}
      isSearchable
      onChangeSearch={setSearch}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      value={_value}
      onValueChange={onChange}
      error={error}
    />
  );
}
function ProductOption({ product }: { product: ShopifyProductPayload }) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          product?.featuredImage?.url ||
          `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={product.id}
        loading="lazy"
      />
      <p className="text-primary-main">{product.title}</p>
    </div>
  );
}

export default ShopifyProductListsOptions;
