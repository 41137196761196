import { useEffect, useRef, useState } from 'react';
import { BBBTag } from '../ui/BBBTag';

interface TruncatedTagsProps {
  items: string[] | undefined;
  withoutDelete?: boolean;
  maxLines?: number;
  containerWidth?: number | string;
  onDelete?: (tag: string, e: React.MouseEvent<SVGElement, MouseEvent>) => void;
}

const TruncatedTags: React.FC<TruncatedTagsProps> = ({
  items,
  maxLines = 2,
  containerWidth,
  withoutDelete = false,
  onDelete,
}) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const [visibleItems, setVisibleItems] =
    useState<TruncatedTagsProps['items']>(items);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [remainingItemsCount, setRemainingItemsCount] = useState(0);

  useEffect(() => {
    setVisibleItems(items);
  }, [items]);

  useEffect(() => {
    if (containerRef.current && !showAllItems) {
      const container = containerRef.current;

      const _items = container.querySelectorAll('.item');

      let count = 0;

      _items.forEach((item) => {
        const dist =
          item.getBoundingClientRect().top -
          container.getBoundingClientRect().top;
        if (dist <= 36) {
          count += 1;
        }
      });

      setVisibleItems((prev) => prev?.filter((_, i) => i < count));
    }
  }, [maxLines, showAllItems]);

  useEffect(() => {
    if (!showAllItems && items?.length && visibleItems?.length) {
      setRemainingItemsCount(items?.length - visibleItems?.length);
    }
  }, [items?.length, showAllItems, visibleItems?.length]);

  useEffect(() => {
    if (containerRef.current && !showAllItems) {
      const container = containerRef.current;
      const more = container.querySelector('.more-items');

      if (more) {
        const diff =
          more.getBoundingClientRect().top -
          container.getBoundingClientRect().top;

        if (diff > 36) {
          setVisibleItems((prev) =>
            prev?.filter((_, i) => i < prev?.length - 1)
          );
        }
      }
    }
  }, [remainingItemsCount, showAllItems]);

  return (
    <div
      ref={containerRef}
      className="flex flex-wrap gap-2"
      style={{
        maxWidth: containerWidth,
      }}
    >
      {visibleItems?.map((item) => (
        <BBBTag
          key={item}
          className="item"
          text={item}
          onDelete={withoutDelete ? undefined : (e) => onDelete?.(item, e)}
        />
      ))}

      {remainingItemsCount > 0 && (
        <BBBTag
          className="more-items cursor-pointer"
          onClick={(e) => {
            if (!showAllItems) {
              setVisibleItems(items);
            }
            setShowAllItems((prev) => !prev);
            e.stopPropagation();
          }}
          textClassName="underline"
          text={
            <div className="font-bold">
              {showAllItems ? 'Show less' : `${remainingItemsCount} more`}
            </div>
          }
        />
      )}
    </div>
  );
};

export default TruncatedTags;
