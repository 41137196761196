import { useCallback, useState } from 'react';
import useColumnDefault from './hooks/model/useColumn';
import useColumnMobile from './hooks/model/useColumnMobile';

import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/ui/BBBLimitAlert/UpgradeText';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import useBulkDeleteModels from '@/hooks/bitAi/models/useBulkDeleteModels';
import useSortingModels from '@/hooks/bitAi/models/useSortingModels';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';

export default function Models() {
  const isMobile = useResponsive('sm');
  const toggleConfirmation = useConfirmationModal();
  const [search, setSearch] = useState('');

  const { data: dataSortingModels, isInitialLoading: loadingSortingModels } =
    useSortingModels({ skipPrebuilt: true });

  const { mutate: deleteModel } = useBulkDeleteModels();

  const handleDeleteBulkModel = useCallback(
    (modelIds: string[]) =>
      toggleConfirmation({
        title: 'Delete model?',
        description: `Once deleted you're not able to recover it`,
        onAccept: (hide) => {
          deleteModel({ modelId: modelIds });
          hide();
        },
        deleteModal: true,
        submitText: 'Yes',
        cancelText: 'Cancel',
      }),
    [deleteModel, toggleConfirmation]
  );

  const columnsDefault = useColumnDefault();
  const columnsMobile = useColumnMobile();

  return (
    <div className="w-full flex flex-col">
      <PricingAlert />
      <BBBTableV2
        data={dataSortingModels}
        headers={isMobile ? columnsMobile : columnsDefault}
        loadingBody={loadingSortingModels}
        isFilterable
        isSelectable={!isMobile}
        onActionChange={(event, row) => {
          if (event === 'delete') {
            handleDeleteBulkModel(row);
          }
        }}
        withoutHeader={isMobile}
        isSearchable
        searchPlaceholder="Search model"
        searchValue={search}
        onChangeSearch={setSearch}
        dataId="id"
        linkDestination={(row) =>
          isMobile ? undefined : `/bitai/model/${row.id}`
        }
        renderFilterSection={() => (
          <>
            {isMobile && (
              <>
                <p className="text-primary-main font-semibold">
                  Showing {dataSortingModels?.length} result
                </p>
                <div className="w-full h-[1px] bg-neutral-30"></div>
              </>
            )}
          </>
        )}
      />
    </div>
  );
}

function PricingAlert() {
  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'advance_custom_model'
  );

  const { data: pricingData } = usePricingByApp('BITAI');

  if (!pricingData || !pricingFeatureData || pricingData.pricingName === 'pro1')
    return null;

  return (
    <BBBLimitAlert
      usage={pricingFeatureData.usage}
      appType="BITAI"
      module={pricingFeatureData.pricingFeature.label}
      limit={limit}
      currentPlan={{
        label: pricingData.pricingModule.label,
        name: pricingData.pricingName,
      }}
      className="mb-4 rounded-lg"
      customReachingTresholdLabel={
        <>
          {limit - pricingFeatureData.usage} active custom model left{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customExceedTresholdLabel={
        <>
          On a free plan, you can only have {limit} custom model.{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customTip="Models are managed collections that are actively used in the sorting process."
      customTresholdFn={() => 0}
    />
  );
}
