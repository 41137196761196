import { twMerge as cx } from 'tailwind-merge';

import SparklesIcon from '@/assets/icons/SparklesIcon';

export default function AutoPilotInfo({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'mb-5 rounded-lg border-[#A879E3] border p-4 gap-2 flex items-center',
        className
      )}
    >
      <SparklesIcon color="#8F68C0" />
      Auto-pilot conversation
    </div>
  );
}
