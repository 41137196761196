import React from 'react';

type Props = {
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function TicketIcon2({ className, ...props }: Props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M7.90823 1.82508C7.54207 2.19125 7.56178 2.78369 7.77867 3.25501C8.00522 3.74816 8.07532 4.29886 7.97956 4.83304C7.8838 5.36722 7.62676 5.85927 7.24302 6.24302C6.85927 6.62676 6.36722 6.8838 5.83304 6.97956C5.29886 7.07532 4.74816 7.00522 4.25501 6.77867C3.78369 6.56272 3.19125 6.54207 2.82508 6.90823L2.27489 7.45842C2.09888 7.63449 2 7.87326 2 8.12222C2 8.37118 2.09888 8.60995 2.27489 8.78601L12.214 18.7251C12.3901 18.9011 12.6288 19 12.8778 19C13.1267 19 13.3655 18.9011 13.5416 18.7251L14.0918 18.1749C14.4579 17.8087 14.4382 17.2163 14.2213 16.745C13.9948 16.2518 13.9247 15.7011 14.0204 15.167C14.1162 14.6328 14.3732 14.1407 14.757 13.757C15.1407 13.3732 15.6328 13.1162 16.167 13.0204C16.7011 12.9247 17.2518 12.9948 17.745 13.2213C18.2163 13.4373 18.8087 13.4579 19.1749 13.0918L19.7251 12.5416C19.9011 12.3655 20 12.1267 20 11.8778C20 11.6288 19.9011 11.3901 19.7251 11.214L9.78601 1.27489C9.60995 1.09888 9.37118 1 9.12222 1C8.87326 1 8.63449 1.09888 8.45842 1.27489L7.90823 1.82508Z"
        fill="white"
        stroke="#262627"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TicketIcon2;
