import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { MergeCustomersParams } from '@/api/services/customer/customer';
import { useUserId } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useMergeCustomers = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  return useMutation(
    (payload: Omit<MergeCustomersParams, 'userId'>) =>
      services.customer.customer.mergeCustomers({
        userId,
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`customers`]);
        queryClient.invalidateQueries([`customer`]);
        queryClient.invalidateQueries([`customer-phonenumber`]);
        queryClient.invalidateQueries([`infinite-customers`]);
        toast.success('Customers merged succesfully');
      },
    }
  );
};

export default useMergeCustomers;
