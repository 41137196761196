import usePricingByApp from './usePricingByApp';
import usePricingOptions from './usePricingOptionsByApp';

import { AppType } from '@/types/systemLogin';

const useIsMaximumLevel = (appType: AppType) => {
  const { data } = usePricingByApp(appType);

  //@ts-ignore
  const { data: pricingOptions } = usePricingOptions(appType, data?.type, {
    enabled: !!data,
  });

  if (typeof data === 'undefined')
    return { isMaximumLevel: false, isLoading: true };

  if (!data) {
    return { isMaximumLevel: false, isLoading: false };
  }

  if (!pricingOptions) {
    return { isMaximumLevel: false, isLoading: true };
  }

  return {
    isMaximumLevel:
      pricingOptions.findIndex((opt) => opt.name === data.pricingName) ===
      pricingOptions.length - 1,
    pricingName: data.pricingName,
    isLoading: false,
  };
};

export default useIsMaximumLevel;
