import { useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

export const useGetScheduleImporting = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitai-get-schedule-importing', activeCompany], () =>
    services.bitAi.v2New.sorting.getAutomaticUpdate(activeCompany)
  );
};
