import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import useCompanyNavigation from '@/hooks/bitApp/design/useCompanyNavigation';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setActiveDesign, setActivePreview } from '@/stores/bitApp';

type Props = {
  className?: string;
};

const _pageOptions = [
  {
    title: 'Homepage',
    value: 'home',
  },
  {
    title: 'Product page',
    value: 'product',
  },
];

const pageBrandingOptiosn = [
  {
    title: 'App Icon',
    value: 'app-icon',
  },
  {
    title: 'Splash',
    value: 'splash',
  },
];

function PageNavigator({ className }: Props) {
  const dispatch = useAppDispatch();
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);

  const isActived = (value: string) => activePreview === value;

  return (
    <div
      className={cx(
        'inline-flex items-center rounded-lg shadow overflow-clip',
        className
      )}
    >
      {activePreview === 'splash' || activePreview === 'app-icon' ? (
        <BrandingPageNavigator dispatch={dispatch} isActived={isActived} />
      ) : (
        <DefautlPageNavigator
          dispatch={dispatch}
          activePreview={activePreview}
          isActived={isActived}
        />
      )}
    </div>
  );
}

function DefautlPageNavigator({
  dispatch,
  activePreview,
  isActived,
}: {
  dispatch: ReturnType<typeof useAppDispatch>;
  activePreview: string;
  isActived: (value: string) => boolean;
}) {
  const { data } = useCompanyNavigation();

  const isCategoryPageActive = data?.active?.find(
    (item) => item.navigation.name === 'Category'
  );

  const pageOptions = isCategoryPageActive
    ? _pageOptions
    : _pageOptions?.filter((item) => item.value !== 'category');

  useEffect(() => {
    if (!activePreview) {
      dispatch(setActivePreview('home'));
    }
  }, [activePreview, dispatch]);

  return (
    <>
      {pageOptions?.map((item) => (
        <div
          key={item.title}
          className={cx(
            'relative flex gap-2 items-center w-full py-3 px-6 cursor-pointer rounded-lg bg-white'
          )}
          onClick={() => dispatch(setActivePreview(item.value))}
        >
          {isActived(item.value) ? (
            <motion.div
              layoutId="page-navigator"
              className="bg-primary-main absolute inset-0 rounded-lg"
            ></motion.div>
          ) : null}
          {item.value === 'home' ? (
            <HomeIcon
              className="relative z-10"
              isActive={isActived(item.value)}
            />
          ) : item.value === 'cart' ? (
            <CartIcon
              className="relative z-10"
              isActive={isActived(item.value)}
            />
          ) : (
            <ProductIcon
              className="relative z-10"
              isActive={isActived(item.value)}
            />
          )}
          {isActived(item.value) && (
            <span className="relative text-neutral-10 text-sm font-medium whitespace-nowrap z-10">
              {item.title}
            </span>
          )}
        </div>
      ))}
    </>
  );
}

function BrandingPageNavigator({
  dispatch,
  isActived,
}: {
  dispatch: ReturnType<typeof useAppDispatch>;
  isActived: (value: string) => boolean;
}) {
  return (
    <>
      {pageBrandingOptiosn?.map((item) => (
        <div
          key={item.title}
          className={cx(
            'relative flex gap-2 items-center w-full py-3 px-6 cursor-pointer rounded-lg bg-white'
          )}
          onClick={() => dispatch(setActivePreview(item.value))}
        >
          {isActived(item.value) ? (
            <motion.div
              layoutId="branding-page-navigator"
              className="bg-primary-main absolute inset-0 rounded-lg"
            ></motion.div>
          ) : null}
          {item.value === 'app-icon' ? (
            <AppIcon
              className="relative z-10"
              isActive={isActived(item.value)}
            />
          ) : (
            <SplashIcon
              className="relative z-10"
              isActive={isActived(item.value)}
            />
          )}
          {isActived(item.value) && (
            <span className="relative text-neutral-10 text-sm font-medium whitespace-nowrap z-10">
              {item.title}
            </span>
          )}
        </div>
      ))}
    </>
  );
}

function HomeIcon({
  isActive,
  className,
}: {
  isActive?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.3151 7.63502L9.53259 1.72502C9.46286 1.65473 9.37991 1.59893 9.28852 1.56085C9.19712 1.52278 9.0991 1.50317 9.00009 1.50317C8.90108 1.50317 8.80305 1.52278 8.71165 1.56085C8.62026 1.59893 8.53731 1.65473 8.46759 1.72502L2.68509 7.64252C2.54516 7.78357 2.43466 7.95102 2.35998 8.13513C2.28531 8.31924 2.24795 8.51635 2.25009 8.71502V15C2.24951 15.384 2.39617 15.7535 2.65987 16.0325C2.92357 16.3116 3.28423 16.4789 3.66759 16.5H14.3326C14.7159 16.4789 15.0766 16.3116 15.3403 16.0325C15.604 15.7535 15.7507 15.384 15.7501 15V8.71502C15.7507 8.31222 15.5947 7.92496 15.3151 7.63502ZM7.50009 15V10.5H10.5001V15H7.50009ZM14.2501 15H12.0001V9.75002C12.0001 9.55111 11.9211 9.36035 11.7804 9.21969C11.6398 9.07904 11.449 9.00002 11.2501 9.00002H6.75009C6.55117 9.00002 6.36041 9.07904 6.21976 9.21969C6.0791 9.36035 6.00009 9.55111 6.00009 9.75002V15H3.75009V8.68502L9.00009 3.32252L14.2501 8.71502V15Z"
        fill={isActive ? '#fff' : '#262627'}
      />
    </svg>
  );
}

function ProductIcon({
  isActive,
  className,
}: {
  isActive?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68391 1.62703C9.86849 1.62703 10.0181 1.77665 10.0181 1.96123V8.31094C10.0181 8.4955 9.86849 8.64514 9.68391 8.64514H3.3342C3.14962 8.64514 3 8.4955 3 8.31094V1.96123C3 1.77665 3.14962 1.62703 3.3342 1.62703H9.68391ZM8.59777 3.04736H4.42033V7.22481H8.59777V3.04736ZM18.9021 4.87888C19.0326 5.00938 19.0326 5.22099 18.9021 5.35149L15.1211 9.1325C14.9906 9.263 14.779 9.263 14.6485 9.1325L10.8675 5.35149C10.737 5.22099 10.737 5.00938 10.8675 4.87888L14.6485 1.09788C14.779 0.967374 14.9906 0.967374 15.1211 1.09788L18.9021 4.87888ZM17.1298 5.11518L14.8848 2.87024L12.6399 5.1152L14.8848 7.36016L17.1298 5.11518ZM9.68391 9.98192C9.86849 9.98192 10.0181 10.1316 10.0181 10.3161V16.6658C10.0181 16.8504 9.86849 17 9.68391 17H3.3342C3.14962 17 3 16.8504 3 16.6658V10.3161C3 10.1316 3.14962 9.98192 3.3342 9.98192H9.68391ZM8.59777 11.4022H4.42033V15.5797H8.59777V11.4022ZM18.0388 9.98192C18.2234 9.98192 18.373 10.1316 18.373 10.3161V16.6658C18.373 16.8504 18.2234 17 18.0388 17H11.6891C11.5045 17 11.3549 16.8504 11.3549 16.6658V10.3161C11.3549 10.1316 11.5045 9.98192 11.6891 9.98192H18.0388ZM16.9527 11.4022H12.7752V15.5797H16.9527V11.4022Z"
        fill={isActive ? '#fff' : '#262627'}
      />
    </svg>
  );
}

function CartIcon({
  isActive,
  className,
}: {
  isActive?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1587_7061)">
        <path
          d="M16.7793 5.0771C16.6455 4.84682 16.4538 4.65493 16.223 4.52013C15.9922 4.38532 15.7302 4.31222 15.4625 4.30794H5.54765L5.09839 2.56963C5.053 2.40183 4.95173 2.25423 4.81107 2.15088C4.67042 2.04752 4.49867 1.9945 4.32379 2.00045H2.7746C2.56916 2.00045 2.37214 2.08149 2.22687 2.22573C2.08161 2.36998 2 2.56562 2 2.76961C2 2.97361 2.08161 3.16925 2.22687 3.31349C2.37214 3.45774 2.56916 3.53877 2.7746 3.53877H3.7351L5.87299 11.4304C5.91838 11.5982 6.01965 11.7458 6.1603 11.8491C6.30096 11.9525 6.47271 12.0055 6.64758 11.9995H13.619C13.762 11.9991 13.9021 11.9594 14.0238 11.8847C14.1455 11.81 14.244 11.7034 14.3083 11.5765L16.849 6.53081C16.9591 6.30161 17.0104 6.04891 16.9982 5.79525C16.9861 5.54159 16.9109 5.29487 16.7793 5.0771ZM13.1387 10.4612H7.23628L5.97368 5.84626H15.4625L13.1387 10.4612Z"
          fill={isActive ? '#fff' : '#262627'}
        />
        <path
          d="M5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16Z"
          fill={isActive ? '#fff' : '#262627'}
        />
        <path
          d="M14.5 16C15.3284 16 16 15.3284 16 14.5C16 13.6716 15.3284 13 14.5 13C13.6716 13 13 13.6716 13 14.5C13 15.3284 13.6716 16 14.5 16Z"
          fill={isActive ? '#fff' : '#262627'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_7061">
          <rect width="19" height="18" fill={isActive ? '#fff' : '#262627'} />
        </clipPath>
      </defs>
    </svg>
  );
}

function AppIcon({
  isActive,
  className,
}: {
  isActive?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="path-1-inside-1_1690_12750" fill="white">
        <rect x="11" y="10" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="11"
        y="10"
        width="8"
        height="8"
        rx="1"
        stroke={isActive ? '#fff' : '#262627'}
        strokeWidth="2.6"
        mask="url(#path-1-inside-1_1690_12750)"
      />
      <path
        d="M9.75 13.6553C9.75 15.7169 8.14002 17.3501 6.2 17.3501C4.25998 17.3501 2.65 15.7169 2.65 13.6553C2.65 11.5938 4.25998 9.96055 6.2 9.96055C8.14002 9.96055 9.75 11.5938 9.75 13.6553Z"
        stroke={isActive ? '#fff' : '#262627'}
        strokeWidth="1.3"
      />
      <path
        d="M12.694 1.88915C12.8274 1.64912 13.1726 1.64912 13.306 1.88915L13.8742 1.57348L13.306 1.88915L16.6064 7.83002C16.736 8.06331 16.5674 8.35 16.3005 8.35H9.69951C9.43264 8.35 9.26396 8.06331 9.39356 7.83003L12.694 1.88915Z"
        stroke={isActive ? '#fff' : '#262627'}
        strokeWidth="1.3"
      />
    </svg>
  );
}

function SplashIcon({
  isActive,
  className,
}: {
  isActive?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63636 2.45455C4.05771 2.45455 3.50276 2.68442 3.09359 3.09359C2.68442 3.50276 2.45455 4.05771 2.45455 4.63636V13.3636C2.45455 13.9423 2.68441 14.4972 3.09359 14.9064C3.50276 15.3156 4.05771 15.5455 4.63636 15.5455H13.3636C13.9423 15.5455 14.4972 15.3156 14.9064 14.9064C15.3156 14.4972 15.5455 13.9423 15.5455 13.3636V4.63636C15.5455 4.05771 15.3156 3.50276 14.9064 3.09359C14.4972 2.68441 13.9423 2.45455 13.3636 2.45455H4.63636ZM2.06507 2.06507C2.74702 1.38312 3.67194 1 4.63636 1H13.3636C14.3281 1 15.253 1.38312 15.9349 2.06507C16.6169 2.74702 17 3.67194 17 4.63636V13.3636C17 14.3281 16.6169 15.253 15.9349 15.9349C15.253 16.6169 14.3281 17 13.3636 17H4.63636C3.67194 17 2.74702 16.6169 2.06507 15.9349C1.38312 15.253 1 14.3281 1 13.3636V4.63636C1 3.67194 1.38312 2.74702 2.06507 2.06507Z"
        fill={isActive ? '#fff' : '#262627'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45344 5.36409C5.851 5.36409 5.36262 5.85246 5.36262 6.4549C5.36262 7.05735 5.851 7.54572 6.45344 7.54572C7.05588 7.54572 7.54426 7.05735 7.54426 6.4549C7.54426 5.85246 7.05588 5.36409 6.45344 5.36409ZM3.9082 6.4549C3.9082 5.04921 5.04774 3.90967 6.45344 3.90967C7.85913 3.90967 8.99868 5.04921 8.99868 6.4549C8.99868 7.8606 7.85913 9.00014 6.45344 9.00014C5.04774 9.00014 3.9082 7.8606 3.9082 6.4549Z"
        fill={isActive ? '#fff' : '#262627'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.908 8.24756C12.2112 8.24695 12.5112 8.30954 12.7889 8.43135C13.0665 8.55315 13.3158 8.7315 13.5207 8.95501L16.4526 12.1525C16.4609 12.1616 16.4697 12.1712 16.479 12.1813C16.573 12.2835 16.7116 12.4342 16.8117 12.6102C16.9441 12.8429 16.9991 13.0882 16.9991 13.3628V13.4596C16.9991 14.3983 16.6262 15.2986 15.9624 15.9625C15.2986 16.6263 14.3983 16.9992 13.4595 16.9992H4.63549C4.34763 16.9992 4.08685 16.8294 3.97039 16.5661C3.85393 16.3029 3.90371 15.9957 4.09734 15.7827L10.2981 8.96163C10.5021 8.73729 10.7506 8.55786 11.0278 8.43492C11.305 8.31199 11.6047 8.24818 11.908 8.24756ZM12.2045 9.76337C12.112 9.72276 12.012 9.7019 11.9109 9.70211C11.8098 9.70231 11.7099 9.72358 11.6175 9.76456C11.5252 9.80552 11.4424 9.86529 11.3744 9.94004C11.3743 9.94006 11.3744 9.94001 11.3744 9.94004L6.27948 15.5446H13.4595C14.0125 15.5446 14.5428 15.325 14.9339 14.9339C15.3249 14.5429 15.5446 14.0126 15.5446 13.4596V13.3628C15.5446 13.3428 15.5436 13.3298 15.5428 13.3222C15.5377 13.3149 15.5279 13.3016 15.51 13.2801C15.4812 13.2458 15.4475 13.2089 15.394 13.1502L15.3784 13.1332L12.4485 9.93799C12.3802 9.86348 12.2971 9.80397 12.2045 9.76337Z"
        fill={isActive ? '#fff' : '#262627'}
      />
    </svg>
  );
}

export default PageNavigator;
