import { useState } from 'react';
import { Lock, X } from 'react-feather';

import PricingAwareWrapper from '@/components/PricingAwareWrapper';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBCard } from '@/components/ui/BBBCard';
import { BBBPrimarySwitch } from '@/components/ui/BBBPrimarySwitch';
import { env } from '@/config/env';
import { knowledgeBase } from '@/constants/bitLogin/knowledge-base';
import useSocialConfigProvider from '@/hooks/bitLogin/useSocialConfigProvider';
import useUpsertSocialConfig from '@/hooks/bitLogin/useUpsertSocialConfig';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { SocialLoginProvider } from '@/types/bitLogin/v2';
import { capitalize } from '@/utils/common/text';

type Props = {
  providerName: SocialLoginProvider;
};

const imgUrl: { [key in SocialLoginProvider]: string } = {
  GOOGLE: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-google-settings.png`,
  FACEBOOK: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-facebook-settings.png`,
  APPLE: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-apple-settings.png`,
  WHATSAPP: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-whatsapp-settings.png`,
  MICROSOFT: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-microsoft-settings.png`,
  TWITTER: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-twitter-settings.png`,
  AMAZON: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-amazon-settings.png`,
};

function LoginStoreCard({ providerName }: Props) {
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const { data } = useSocialConfigProvider(providerName);
  const { mutate: updateConfig } = useUpsertSocialConfig();

  const [isCardVisible, setIsCardVisible] = useState(false);

  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  return (
    <BBBCard
      title={`Use your store name on ${capitalize(providerName)} login`}
      rightButton={
        <div className="flex items-center gap-5">
          {pricingData?.pricingName === 'free' && <Lock />}
          <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
            {({ connectMiddleware }) => (
              <PricingAwareWrapper appType="BITLOGIN" desiredPlan="pro">
                {({ validatePricing }) => (
                  <BBBPrimarySwitch
                    checked={data?.usePersonalOauth}
                    onChange={(value) =>
                      connectMiddleware(() =>
                        validatePricing(() =>
                          updateConfig({
                            usePersonalOauth: value,
                            provider: providerName,
                          })
                        )
                      )
                    }
                  />
                )}
              </PricingAwareWrapper>
            )}
          </ShopifyAwareWrapper>
        </div>
      }
      containerTitleClassName="mb-2"
    >
      <>
        <div className="lg:mb-2 mb-1 text-neutral-40 lg:text-xs text-base">
          You can use your store name on {capitalize(providerName)} login to
          allow your customers to log in with their {capitalize(providerName)}{' '}
          account.
        </div>
        {!isCardVisible && (
          <a
            className="text-info-main underline cursor-pointer lg:text-ls text-base"
            onClick={toggleCardVisibility}
          >
            What is this?
          </a>
        )}
      </>
      {isCardVisible && (
        <BBBCard className="bg-[#f8f8f8]">
          <div className="flex flex-wrap justify-evenly">
            <div className="flex flex-col w-[30%] py-2 px-4">
              <div className="w-full mx-auto text-center">
                <img
                  src={imgUrl[providerName]}
                  alt={capitalize(providerName)}
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col w-[70%] py-2 px-4 my-auto">
              <div
                className="absolute top-5 right-5 rounded-full p-1 cursor-pointer"
                onClick={toggleCardVisibility}
              >
                <X />
              </div>
              <p className="mb-3">
                {capitalize(providerName)} uses your store name to display when
                user is logging in with {capitalize(providerName)}.{' '}
                <b>
                  Insert your{' '}
                  {providerName != 'APPLE'
                    ? 'Client ID & Client Secret'
                    : 'Client ID, Client Secret, Team ID & Private Key'}{' '}
                  on top to activate it.
                </b>
              </p>
              <p className="leading-7">
                Instructions:
                <ul className="list-disc">
                  <li className="ml-4 text-info-main">
                    <a
                      href={knowledgeBase[providerName]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      How to get {capitalize(providerName)}{' '}
                      {providerName != 'APPLE'
                        ? 'Client ID & Client Secret'
                        : 'Client ID, Client Secret, Team ID & Private Key'}
                    </a>
                  </li>
                  <li
                    className={
                      providerName === 'GOOGLE' ||
                      providerName == 'MICROSOFT' ||
                      providerName == 'AMAZON'
                        ? 'ml-4 text-info-main'
                        : 'ml-4'
                    }
                  >
                    {providerName === 'GOOGLE' ? (
                      <a
                        href="https://developers.google.com/identity/sign-in/web/sign-in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google’s instruction to create authentication app
                      </a>
                    ) : providerName === 'FACEBOOK' ? (
                      'Sign in to Facebook developer account > Create a new app > Configure basic settings > Save'
                    ) : providerName === 'TWITTER' ? (
                      'Standalone Apps > App settings > Keys and tokens > Consumer keys'
                    ) : providerName === 'APPLE' ? (
                      'Sign in to Apple developer account > Access Certificates, Identifiers & Profiles > Note team ID'
                    ) : providerName === 'MICROSOFT' ? (
                      <a
                        href={knowledgeBase.MICROSOFT}
                        target="_blank"
                        rel="noreferrer"
                        className="text-info-main"
                      >
                        Microsoft’s instruction to create authentication app
                      </a>
                    ) : (
                      <a
                        href="https://developer.amazon.com/docs/login-with-amazon/conceptual-overview.html"
                        target="_blank"
                        rel="noreferrer"
                        className="text-info-main"
                      >
                        Amazon’s instruction to create authentication app
                      </a>
                    )}
                  </li>
                  {providerName !== 'TWITTER' &&
                  providerName !== 'MICROSOFT' &&
                  providerName !== 'AMAZON' ? (
                    <li className="ml-4">
                      {providerName === 'GOOGLE'
                        ? 'App verification is required. Request verification from API & Services > OAuth consent screen, or click on one of the article listed above'
                        : providerName === 'FACEBOOK'
                        ? 'Get App ID & App secret > Complete Facebook login setup > Test'
                        : 'Set App ID > Set Key ID & Private key'}
                    </li>
                  ) : null}
                </ul>
              </p>
            </div>
          </div>
        </BBBCard>
      )}
    </BBBCard>
  );
}

export default LoginStoreCard;
