import { shallowEqual } from 'react-redux';
import { useApiKey, useUserId } from '../rtk/selector';
import { useAppSelector } from '../rtk/store';
import useChatFilter from './useChatFilter';

const useChatListsQueryKey = () => {
  const activeStatus = useAppSelector(
    (state) => state.bitCRM.activeStatus,
    shallowEqual
  );
  const userId = useUserId();
  const _filter = useChatFilter();
  const apiKey = useApiKey();

  const { chat, ...filter } = _filter;

  const splittedChatFilter = chat?.split(',');

  const status = [
    ...(activeStatus ? [activeStatus] : []),
    ...(splittedChatFilter?.length ? splittedChatFilter : []),
  ].join(',');

  const params = {
    userId,
    status,
    limit: 10,
    key: apiKey,
    ...filter,
  };

  return ['chatlists', params] as const;
};

export default useChatListsQueryKey;
