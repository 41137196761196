import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetCompanyUserByUserAndCompanyIdParam } from '@/api/services/systemLogin';

const useCompanyUsersByUserAndCompanyId = (
  params: Partial<GetCompanyUserByUserAndCompanyIdParam>
) => {
  return useQuery(
    ['company-users', params?.userId, params.companyId],
    () =>
      services.systemLogin.getCompanyUserByUserAndCompanyId({
        companyId: params.companyId!,
        userId: params.userId!,
      }),
    {
      enabled: !!params.companyId && !!params.userId,
    }
  );
};

export default useCompanyUsersByUserAndCompanyId;
