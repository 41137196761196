import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';
import {
  UserCompanyWithAssociation,
  UserWithAssociation,
} from 'types/systemLogin/association';
import { Ticket } from 'types/whatsApp/ticket';
import { formatUserDisplayName } from 'utils/auth';
import { toast } from 'utils/common/toast';

import UserProfilePicture from '@/components/Auth/UserProfilePicture';
import { BBBModal } from '@/components/ui';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useUserCompany, useUserId } from '@/hooks/rtk/selector';
import { useUpdateChat } from '@/hooks/whatsApp/chat';

type Props = {
  ticket: Ticket;
  onHide?: () => void;
};

function AssignTicketModal({ onHide, ticket }: Props) {
  const [selected, setSelected] = useState<UserWithAssociation>();
  const confirm = useConfirmationModal();

  const userId = useUserId();

  const authedUserCompany = useUserCompany();

  const query = useCompanyUsers({
    excludedUsers: [userId],
    limit: 10,
  });

  const {
    data: userCompanies,
    hasNextPage,
    isInitialLoading: loadingUserCompanies,
    fetchNextPage,
  } = query;

  const { mutate: updateChat } = useUpdateChat();

  const flattenedUserCompaniesData: UserCompanyWithAssociation[] = [
    authedUserCompany,
    ...(userCompanies?.pages.flatMap((page) => page.data) ?? []),
  ];

  return (
    <BBBModal
      title="Assign ticket"
      show
      footer
      submitText="Assign"
      cancelText="Close"
      handleSave={() => {
        if (selected) {
          const formattedDisplayNameSelected = formatUserDisplayName(selected);

          confirm({
            title: `Assign ticket to ${formattedDisplayNameSelected}`,
            description: `Are you sure to assign this ticket to ${formattedDisplayNameSelected}? ${formattedDisplayNameSelected} will receive notification for this action`,
            submitText: `Assign to ${formattedDisplayNameSelected}`,
            onAccept: (hide) => {
              updateChat(
                {
                  status: 'transfer',
                  target: ticket,
                  assignedUser: {
                    userId: selected!.id,
                    userAccent: selected.accent,
                    userProfilePicture:
                      selected.profile?.profilePicture || null,
                    userDisplayName: formatUserDisplayName(selected),
                    userEmail: selected.email,
                  },
                },
                {
                  onSuccess: () => {
                    hide();
                    onHide?.();
                    toast.success(
                      `Ticket #${ticket.id} assigned to ${
                        selected?.profile?.firstName ?? selected?.email
                      }`
                    );
                  },
                }
              );
            },
          });
        }
      }}
      onHide={onHide}
      disableSave={!selected}
    >
      <InfiniteScroll
        scrollableTarget="scrollable-assign-ticket"
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        dataLength={flattenedUserCompaniesData?.length ?? 0}
        loader={null}
      >
        <div
          className="grid grid-cols-4 gap-y-6 gap-x-3 max-h-72 overflow-y-auto"
          id="scrollable-assign-ticket"
        >
          {loadingUserCompanies ? (
            <AsigneeSkeleton />
          ) : (
            flattenedUserCompaniesData?.map((userCompany) => (
              <div
                key={`${userCompany.userId}-${userCompany.companyId}`}
                className="flex flex-col items-center"
                onClick={() => setSelected(userCompany.user)}
              >
                <div
                  className={cx(
                    'w-18 h-18 cursor-pointer mb-2 rounded-full transition-[border] border-[3px] border-transparent',
                    userCompany.userId === selected?.id &&
                      'border-primary-main pointer-events-none'
                  )}
                >
                  <UserProfilePicture
                    {...userCompany.user}
                    size={'100%'}
                    fontSize={24}
                  />
                </div>
                <div className="text-neutral-60 text-center break-words">
                  {userCompany.userId === userId
                    ? 'Me'
                    : formatUserDisplayName(userCompany.user)}
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
    </BBBModal>
  );
}

function AsigneeSkeleton() {
  return (
    <>
      {Array.from({ length: 10 }).map((_, i) => (
        <div className="flex flex-col items-center" key={i}>
          <Skeleton
            width={72}
            height={72}
            borderRadius={9999}
            className="mb-2"
          />
          <Skeleton width={100} />
        </div>
      ))}
    </>
  );
}

export default AssignTicketModal;
