import { useEffect, useState } from 'react';

import { BBBSelect, SingleOnValueChangeParam } from '@/components/ui/BBBSelect';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
} from '@/constants/bitChat/agent';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import { useUserId } from '@/hooks/rtk/selector';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { formatUserDisplayName } from '@/utils/auth';

export type AgentOption = UserCompanyWithAssociation & {
  formattedName?: string;
};

type Props = {
  value: AgentOption | undefined;
  onValueChange?: (val: SingleOnValueChangeParam<AgentOption>) => void;
  label?: string;
  containerClassName?: string;
  role?: string;
  includeSelf?: boolean;
  fallbackDefault?: boolean;
  placeholder?: string;
  error?: string;
  withEveryone?: boolean;
  withAiAgent?: boolean;
  isDisabled?: boolean;
};

export default function AgentOptions({
  value,
  onValueChange,
  label,
  containerClassName,
  role = 'STAFF_AGENT,AGENT,SUPER_ADMIN,ADMIN',
  includeSelf,
  fallbackDefault,
  placeholder = 'Choose agents',
  error,
  withAiAgent,
  withEveryone,
  isDisabled,
}: Props) {
  const userId = useUserId();

  const [search, setSearch] = useState('');

  const {
    data: _userCompanies,
    hasNextPage,
    fetchNextPage,
    status: statusUserCompany,
    isLoading: loadingUserCompanies,
  } = useCompanyUsers({
    excludedUsers: includeSelf ? undefined : [userId],
    search,
    limit: 10,
    role,
    status: 'ACTIVE',
  });

  const userCompanies = _userCompanies?.pages.flatMap((page) =>
    page.data.map((uC) => ({
      ...uC,
      formattedName: formatUserDisplayName(uC.user),
    }))
  );

  const userCompaniesData = [
    ...(withAiAgent ? [generalAiAgentOption] : []),
    ...(withEveryone && _userCompanies?.pages[0].meta.total
      ? [
          {
            formattedName: `Everyone ${`(${_userCompanies.pages[0].meta.total})`}`,
            user: { id: EVERYONE_AGENT_ID },
          },
        ]
      : []),
    ...(userCompanies?.length ? userCompanies : []),
  ];

  useEffect(() => {
    if (fallbackDefault && !value && statusUserCompany === 'success') {
      onValueChange?.(userCompanies?.[0]);
    }
  }, [fallbackDefault, onValueChange, statusUserCompany, userCompanies, value]);

  return (
    <BBBSelect
      //@ts-ignore
      options={userCompaniesData}
      placeholder={placeholder}
      optionLabel="formattedName"
      optionValue="user.id"
      isPaginated
      hasMore={!!hasNextPage}
      fetchNext={fetchNextPage}
      isSearchable
      search={search}
      onChangeSearch={setSearch}
      value={value}
      onValueChange={onValueChange}
      label={label}
      containerClassName={containerClassName}
      error={error}
      loading={loadingUserCompanies}
      isDisabled={isDisabled}
    />
  );
}
