import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetCollectionProductsParams } from '@/api/services/bitAi/v2-new/sorting';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useSearchProducts = (
  collectionId: string,
  params?: GetCollectionProductsParams
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitai-search-products', activeCompany, collectionId, params],
    () =>
      services.bitAi.v2New.sorting.getCollectionProducts(
        activeCompany,
        collectionId,
        params
      ),
    {
      keepPreviousData: true,
    }
  );
};

export default useSearchProducts;
