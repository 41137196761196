import { useState } from 'react';
import { useHistory } from 'react-router';
import ConnectWaCloudBanner from '../ConnectWaCloudBanner';
import useColumn from './hooks/useColumn';

import { BBBButton, BBBContainer, BBBSelect } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { statusOptions } from '@/constants/bitChat/template';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import useWACloudTemplates from '@/hooks/bitChat/settings/useWACloudTemplates';
import { useTemplatesBulkAction } from '@/hooks/bitCRM/template/bulk-action';
import useResponsive from '@/hooks/common/useResponsive';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

export default function ChatInitiation() {
  const history = useHistory();
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');
  const bulkAction = useTemplatesBulkAction();
  const column = useColumn();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<string | undefined>('updatedAt:desc');
  const [selected, setSelected] = useState<WACloudTemplate[]>([]);
  const [status, setStatus] = useState<typeof statusOptions[number] | null>();

  const { data, isLoading } = useWACloudTemplates({
    page: pageIndex,
    size: pageSize,
    search,
    sort,
    status: status?.value,
  });
  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  return (
    <BBBContainer
      hasHeading
      pageTitle="Chat initiation template"
      pageDescription={
        <>
          Create message template to initiate conversation with your customer
          using WhatsApp{' '}
          <a
            href="https://www.bitbybit.studio/knowledge/bitcrm"
            target="_blank"
            className="text-info-main underline"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </>
      }
      className="md:px-0 md:py-0 lg:max-w-none"
    >
      <ConnectWaCloudBanner />
      <div
        className={cn(
          'z-[99999] h-full',
          !isWhatsAppCloudConnected
            ? 'opacity-50 pointer-events-none cursor-none'
            : 'opacity-100 pointer-events-auto'
        )}
      >
        <BBBTableV2
          containerProps={{
            id: 'template-table',
          }}
          headers={column}
          data={data ?? []}
          dataId="id"
          loadingBody={isLoading}
          isPaginate
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: data?.length || 0,
            maxShown: 3,
          }}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
          isSelectable={!isMobile}
          withoutActionOption
          onChangeSelectable={setSelected}
          selected={selected}
          isSearchable
          searchValue={search}
          onChangeSearch={setSearch}
          searchPlaceholder="Search on chat initiation template"
          isColumnHeightFixed={isMobile || isTablet ? false : true}
          isFilterable
          renderFilterSection={() =>
            selected.length ? (
              <>
                <BBBButton
                  variant="danger-outline"
                  text={`Delete${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() =>
                    bulkAction({
                      onSuccess: () => {
                        setSelected([]);
                      },
                      action: 'delete',
                      selectedRows: selected.map((row) => row.id),
                      type: 'chat',
                    })
                  }
                  size="sm"
                />
                <BBBButton
                  variant="secondary"
                  text={`Duplicate${
                    selected.length > 1 ? ` (${selected.length})` : ''
                  }`}
                  onClick={() => {
                    if (selected.length > 5) {
                      toast.error(
                        `Maximum 5 templates can be duplicated at a time.`
                      );
                    } else {
                      bulkAction({
                        onSuccess: () => {
                          setSelected([]);
                        },
                        action: 'duplicate',
                        selectedRows: selected.map((row) => row.id),
                        type: 'chat',
                      });
                    }
                  }}
                  size="sm"
                />
              </>
            ) : (
              <>
                <BBBSelect
                  placeholder="Status"
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={status}
                  onValueChange={setStatus}
                />
                <BBBButton
                  text="Create new template"
                  onClick={() =>
                    history.push({
                      pathname: `/misc/whatsapp-api-settings/templates/new`,
                      state: { from: location.pathname },
                      search: `?section=chat`,
                    })
                  }
                />
              </>
            )
          }
          onClickRow={(row) =>
            history.push({
              pathname: `/misc/whatsapp-api-settings/templates/${row.id}`,
              search: `section=chat`,
            })
          }
          isAsyncSort
          onClickSortHeader={(key, sort) => {
            setSort(`${key}:${sort}`);
          }}
        />
      </div>
    </BBBContainer>
  );
}
