import { ApiResponse } from 'api/types';
import api from 'config/api';

export default {
  getAccounts: (companyId: number) =>
    api.bitChat
      .get<ApiResponse<GoogleSheetAccount[]>>(
        `/google-sheets/accounts/${companyId}`
      )
      .then((res) => res.data.data),
  getSpreadsheets: (
    companyId: number,
    email: string,
    params?: { search?: string }
  ) =>
    api.bitChat
      .get<ApiResponse<Spreadsheet[]>>(
        `/google-sheets/documents/${companyId}/${email}`,
        { params }
      )
      .then((res) => res.data.data),
  getWorksheets: (
    companyId: number,
    email: string,
    spreadsheetId: string,
    params?: { search?: string }
  ) =>
    api.bitChat
      .get<ApiResponse<Worksheet[]>>(
        `/google-sheets/documents/${companyId}/${email}/spreadsheet/${spreadsheetId}`,
        { params }
      )
      .then((res) => res.data.data),
};

export type GoogleSheetAccount = {
  email: string;
  companyId: number;
};

export type Spreadsheet = {
  kind: string;
  mimeType: string;
  id: string;
  name: string;
};

export type Worksheet = {
  sheetId: number;
  title: string;
  index: number;
  sheetType: string;
  gridProperties: {
    rowCount: number;
    columnCount: number;
  };
};
