import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import Displays from './Displays';

import YoutubeIcon from '@/assets/icons/YoutubeIcon';
import {
  BBBCard,
  BBBDebounceInput,
  BBBFooterAction,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import useOembedYoutube from '@/hooks/bitLink/youtube/useOembedYoutube';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';

const REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/gm;

const schema = yup.object().shape({
  link: yup
    .string()
    .required('Youtube link is required')
    .matches(REGEX, 'URL is not a valid youtube link'),
  title: yup.string().optional(),
  active: yup.bool().required('Active is required'),
  display: yup
    .mixed()
    .oneOf(['button', 'card'])
    .required('Display is required'),
  options: yup.mixed().oneOf(['video-only', 'with-background']).optional(),
});

export type FormYoutubeInput = {
  link: string;
  active: boolean;
  title?: string;
  display: string;
  options: string;
};

export default function FormYouTube() {
  const { handleSubmit, reset, watch, setValue, control } =
    useCustomForm<FormYoutubeInput>({
      resolver: yupResolver(schema),
      defaultValues: {
        link: '',
        title: '',
        active: true,
        display: 'button',
        options: 'video-only',
      },
    });

  const linkUrl = watch('link');

  const formYouTube = useAppSelector((state) => state.bitLink.form);

  const isMobile = useResponsive('sm');

  const dispatch = useAppDispatch();

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();
  const { data: dataYoutube, isLoading: isLoadingYoutube } = useOembedYoutube({
    url: linkUrl,
  });

  const deleteLinklist = useLinklistDeleteMutation();

  const handleDeleteForm = () => {
    if (formYouTube?.id) {
      deleteLinklist(formYouTube?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  const onSubmit = async (data: FormYoutubeInput) => {
    const requestData = {
      type: 'youtube' as const,
      textPrimary: data.link,
      active: data.active,
      textSecondary: data.title,
      display: data.display,
      options: data.options,
    };

    if (!formYouTube?.id) {
      addLinklist(requestData);
    } else {
      editLinklist({ id: formYouTube?.id, data: requestData });
    }
  };

  useEffect(() => {
    setValue('link', formYouTube?.textPrimary || '');
    setValue('title', formYouTube?.textSecondary || '');
    setValue('active', formYouTube?.active ?? true);
    setValue('display', formYouTube?.display || 'button');
    setValue('options', formYouTube?.options || 'video-only');
  }, [formYouTube, setValue]);

  return (
    <BBBCard
      title="Youtube"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
                containerClassName="flex justify-end mb-3"
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom', isMobile && 'border-none p-0')}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 items-center">
          <div className="relative">
            <YoutubeIcon
              className="absolute 
            top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              color={watch('link') ? '#FFF' : '#9E9E9E'}
            />
            {watch('link') ? (
              <img
                alt="Youtube thumbnail"
                src={dataYoutube?.thumbnail_url}
                className="w-[184px] h-[140px] rounded-lg bg-black"
              />
            ) : (
              <div className="w-[184px] h-[140px] border border-neutral-30 rounded-lg" />
            )}
          </div>
          <div className="grow flex flex-col gap-3.5">
            <Controller
              control={control}
              name="link"
              render={({ field }) => (
                <BBBDebounceInput
                  label="Youtube Link URL"
                  placeholder="Enter your Youtube Link"
                  value={field.value}
                  onValueChange={(value) => field.onChange(value)}
                  onBlur={() => {
                    if (dataYoutube && !formYouTube?.textSecondary) {
                      setValue(
                        'title',
                        dataYoutube?.title?.length > 97
                          ? `${dataYoutube?.title?.slice(0, 97)}...`
                          : dataYoutube?.title || ''
                      );
                    }
                  }}
                />
              )}
            />
            <BBBTextInput
              label="Title (optional)"
              placeholder="Enter your title"
              control={control}
              isHookForm
              controlName="title"
              hasMaxCharLabel
              maxChar={100}
            />
          </div>
        </div>
        <Displays control={control} />
        <BBBFooterAction
          containerClassName="justify-end"
          withoutDelete={formYouTube?.id ? undefined : true}
          onCancel={() => {
            reset();
            dispatch(setForm(null));
          }}
          loadingSave={isLoading || isLoadingEdit}
          onSave={handleSubmit(onSubmit)}
          onDelete={() => handleDeleteForm()}
        />
      </div>
    </BBBCard>
  );
}
