import { DraggableProvided } from 'react-beautiful-dnd';
import { Trash2 } from 'react-feather';

import { Product as ProductType } from '@/api/services/bitAi/v2-new/sorting';
import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import { BBBTooltip } from '@/components/ui';
import { env } from '@/config/env';
import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

export default function Product({
  provided,
  onClickDelete,
  enableEdit,
  ...product
}: ProductType & {
  provided: DraggableProvided;
  onClickDelete: () => void;
  enableEdit?: boolean;
}) {
  const isMobile = useResponsive('sm');
  return (
    <div
      className="flex items-center lg:gap-4 gap-2 lg:py-2 py-0 lg:pr-2 pr-0  rounded-lg cursor-pointer group"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {(!isMobile || enableEdit) && (
        <div>
          <BBBDraggableIcon
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            color="#DDDDDD"
          />
        </div>
      )}
      <div className="flex w-full grow justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src={
              product.imageUrl ||
              `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
            }
            alt={product.title}
            className="lg:w-6 w-4 lg:h-6 h-4 rounded object-cover"
          />
          <BBBTooltip
            content={product.title}
            offset={{ top: -20 }}
            show={product.title ? product.title.length > 40 : false}
          >
            <p className="lg:text-base text-sm text-primary-main line-clamp-1 lg:w-[12rem] w-[4rem]">
              {product.title}{' '}
            </p>
          </BBBTooltip>
        </div>
        <Trash2
          className={cn(
            'text-danger-main lg:min-w-5 lg:min-h-5 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity',
            enableEdit && 'opacity-100'
          )}
          onClick={() => onClickDelete()}
          height={isMobile ? 14 : 20}
          width={isMobile ? 14 : 20}
        />
      </div>
    </div>
  );
}
