import { Plus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import AuthorizeShopify from '../components/AuthorizeShopify';
import Models from './Model';

import { BBBButton, BBBContainer } from '@/components/ui';
import StickyFooter from '@/components/ui/StickyFooter/StickyFooter';
import { useCompanyIntegrations } from '@/hooks/bitAi/integration/integrations';
import useHistory from '@/hooks/common/useHistory';
import useResponsive from '@/hooks/common/useResponsive';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';

export default function Model() {
  const {
    data: companyIntegrationsData,
    isInitialLoading,
    error,
  } = useCompanyIntegrations();

  const isMobile = useResponsive('sm');

  const integrationStatus =
    companyIntegrationsData?.find(
      (integration) => integration.integration.name === 'shopify'
    )?.status === 'CONNECTED';

  return (
    <BBBContainer
      hasHeading
      pageTitle="Model"
      pageDescription="Create a new model and set it according to your needs"
      rightComponent={
        isInitialLoading ? (
          <Skeleton width={300} height={20} />
        ) : !error && integrationStatus && !isMobile ? (
          <CreateModelCta />
        ) : integrationStatus && isMobile ? (
          <StickyFooter>
            <div className="p-3 bg-white">
              <CreateModelCta />
            </div>
          </StickyFooter>
        ) : null
      }
    >
      {integrationStatus ? <Models /> : <AuthorizeShopify />}
    </BBBContainer>
  );
}

function CreateModelCta() {
  const history = useHistory();

  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'advance_custom_model'
  );

  const advanceCustomModelUsage = pricingFeatureData?.usage ?? 0;

  const isLimitReached = advanceCustomModelUsage >= limit;

  return (
    <BBBButton
      onClick={() => history.push('/bitai/model/new')}
      text={
        <div className="flex gap-1 justify-center align-middle items-center text-center w-full mx-auto">
          Create Model
          <Plus />
        </div>
      }
      disabled={isLimitReached}
      width="full"
      id="create-model"
    />
  );
}
