import { useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

const useApprovedTemplates = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitlogin-approved-templates'], () =>
    services.bitLogin.template.getTemplates(activeCompany, {
      status: 'APPROVED',
    })
  );
};

export default useApprovedTemplates;
