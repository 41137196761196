import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';
import { useAppSelector } from 'hooks/rtk/store';
import { selectActiveChatTruthy } from 'hooks/whatsApp/chat';

export const useOrderHistory = () => {
  const activeCompany = useActiveCompany();

  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useQuery(['order', activeCompany, selectedChat.clientNumber], () => {
    return services.customer.order.getOrders(
      activeCompany,
      selectedChat.clientNumber
    );
  });
};

export const useOrderDetail = (orderId: string | undefined | null) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['order-detail', activeCompany, orderId],
    () => services.customer.order.getOrder(activeCompany, orderId!),
    {
      enabled: !!orderId,
    }
  );
};
