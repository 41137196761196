import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { EditorState } from 'draft-js';
import { isEqual } from 'lodash-es';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from 'utils/common/rich';
import { useCancel, useFlowChannel } from '../../hooks';
import useStore from '../../store';
import { WelcomeActionData } from '../../types';
import AddItem from '../AddItem';
import FlowOptions from '../FlowOptions';
import TrashWithTransition from '../TrashWithTransition';

import {
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import {
  welcomeActionOptions,
  WelcomeActionType,
} from '@/constants/bitChat/flows';
import { useFlow, useFlows1 } from '@/hooks/whatsApp/flow';
import { Flow } from '@/types/whatsApp/flow';

const welcomeActionSchema = yup.object({
  action: yup.mixed<WelcomeActionType | null>().required().label('Action'),
  flow: yup
    .mixed<Flow>()
    .label('Flow')
    .when('action', {
      is: (value: WelcomeActionType | null) => value?.value === 'run_flow',
      then: (rule) => rule.required(),
    }),
  message: yup
    .string()
    .label('Message')
    .when('action', {
      is: (value: WelcomeActionType | null) => value?.value === 'send_message',
      then: (rule) => rule.required(),
    }),
  replies: yup.array().of(
    yup.object().shape({
      replyId: yup.string(),
      reply: yup.string().label('Reply'),
    })
  ),
});

type WelcomeActionForm = {
  action: WelcomeActionType | null;
  flow?: Flow | null;
  message: EditorState;
  replies: {
    replyId: string;
    reply: string;
  }[];
};

function WelcomeActionModal({
  onClose,
  onSave,
  nodeId,
}: {
  onClose?: () => void;
  onSave: (val: WelcomeActionData) => void;
  nodeId?: string;
}) {
  const actionDefaultValues = useMemo(
    () => ({
      message: '',
      replies: [],
      fallback: null,
      flow: null,
      action: null,
    }),
    []
  );

  const source = useFlowChannel();
  const { data: flows } = useFlows1(
    { channel: source! },
    { enabled: !!source }
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<WelcomeActionForm>({
    resolver: yupResolver(welcomeActionSchema),
    defaultValues: actionDefaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'replies',
  });

  const actionValue = watch('action');

  const node = useStore((s) => s.nodes.find((node) => node.id === nodeId));
  const childNodes = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === nodeId),
    shallow
  );

  const { data: flowData } = useFlow(node?.data.flow?.id);

  const dataFromProps = useMemo(
    () =>
      node
        ? {
            message: convertHtmlToEditorState(node.data.message) || '',
            replies: childNodes.length
              ? childNodes.map((child) => ({
                  replyId: child.id,
                  reply: child.data.reply,
                }))
              : actionDefaultValues.replies,
            flow: flowData,
            action: welcomeActionOptions.find(
              (opt) => opt.value === node.data.action
            ),
          }
        : undefined,
    [actionDefaultValues.replies, childNodes, flowData, node]
  );

  const isDataEqual = isEqual(dataFromProps, watch());

  useEffect(() => {
    if (dataFromProps) {
      reset(dataFromProps);
    }
  }, [dataFromProps, reset]);

  const handleCancel = useCancel(nodeId);

  return (
    <BBBModal
      title="Welcome action"
      show
      footer
      cancelText="Discard"
      submitText="Save"
      handleSave={() => {
        handleSubmit((data) => {
          onSave({
            message:
              data.action?.value === 'send_message'
                ? convertEditorStateToHtml(data.message) || ''
                : null,
            replies:
              data.action?.value === 'send_message'
                ? data.replies
                    .filter(({ reply }) => !!reply)
                    .map(({ reply, replyId }, index) => ({
                      id: replyId,
                      reply,
                      index,
                    }))
                : null,
            flow: data.action?.value === 'run_flow' ? data.flow || null : null,
            action: data.action!.value,
          });
          onClose?.();
        })();
      }}
      onHide={handleCancel}
      disableSave={isDataEqual}
    >
      <Controller
        control={control}
        name="action"
        render={({ field }) => (
          <BBBSelect
            //@ts-ignore
            options={welcomeActionOptions}
            optionLabel="label"
            optionValue="value"
            label="Choose welcome action"
            containerClassName="mb-5"
            placeholder="Choose welcome action"
            value={field.value}
            onValueChange={field.onChange}
            error={errors.action?.message}
          />
        )}
      />
      {actionValue?.value === 'run_flow' && (
        <Controller
          control={control}
          name="flow"
          render={({ field }) => (
            <FlowOptions
              value={field.value}
              onValueChange={field.onChange}
              error={errors.flow?.message}
              flows={flows}
            />
          )}
        />
      )}
      {actionValue?.value === 'send_message' && (
        <>
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <BBBRichTextEditor
                label="Message"
                placeholder="Input message that will sent to customer"
                rows={5}
                editorState={field.value}
                onChangeEditorState={(val) => field.onChange(val)}
                error={errors.message?.message}
              />
            )}
          />
          {fields.map((reply, i) => (
            <div className="flex items-center gap-2 mb-5" key={reply.id}>
              <Controller
                control={control}
                name={`replies.${i}.reply`}
                render={({ field }) => (
                  <BBBTextInput
                    containerClassname="grow mb-0"
                    placeholder="Enter reply"
                    value={field.value}
                    onChange={({ target: { value } }) => field.onChange(value)}
                    error={errors.replies?.[i]?.reply?.message}
                  />
                )}
              />
              <TrashWithTransition
                className="text-neutral-30 hover:text-danger-hover cursor-pointer"
                onClick={() => remove(i)}
              />
            </div>
          ))}
          <AddItem onClick={() => append({ replyId: createId(), reply: '' })} />
        </>
      )}
    </BBBModal>
  );
}

export default WelcomeActionModal;
