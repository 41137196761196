import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { triggerRefreshIframe } from '@/stores/bitLink';
import { Appearances, Branding } from '@/types/bitLink/v2';
import { toast } from '@/utils/common/toast';

const useEditAppearance = () => {
  const activeCompany = useActiveCompany();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (payload: {
      appearance: Partial<Appearances>;
      branding: Partial<Branding>;
    }) =>
      Promise.all([
        services.bitLink.appearance.editAppearance(
          activeCompany,
          payload.appearance
        ),
        services.bitLink.appearance.editBranding(
          activeCompany,
          payload.branding
        ),
      ]),
    {
      onSuccess: () => {
        dispatch(triggerRefreshIframe());
        toast.success('Changes saved');
        return Promise.all([
          queryClient.invalidateQueries(['appearance']),
          queryClient.invalidateQueries(['bitlink-branding']),
        ]);
      },
    }
  );
};

export default useEditAppearance;
