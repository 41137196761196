export const arrayMove = <T>(
  arr: T[],
  fromIndex: number,
  toIndex: number,
  el?: T
) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, el || element);
};
