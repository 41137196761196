import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import { useApiKey } from '../rtk/selector';
import { useAppSelector } from '../rtk/store';
import { selectActiveChatTruthy } from './chat';
import { ChatMessagesType } from './useChatMessages';
import useChatMessagesQueryKey from './useChatMessagesQueryKey';

import services from '@/api/services';

const useDeleteMessage = () => {
  const client = useQueryClient();
  const selectedChat = useAppSelector(selectActiveChatTruthy);
  const messagesQueryKey = useChatMessagesQueryKey();
  const apiKey = useApiKey();

  return useMutation(
    (payload: { serialized: string }) =>
      services.whatsApp.chat.deleteChat({
        key: apiKey,
        serialized: payload.serialized,
        number: selectedChat.clientNumber,
        sources: selectedChat.sources,
      }),
    {
      onMutate: (payload) => {
        client.setQueryData<ChatMessagesType>(messagesQueryKey, (old) => {
          if (old) {
            const oldData = cloneDeep(old?.pages);

            const previousMessages = oldData?.map((page) => {
              const revokedIdx = page.response.findIndex(
                (p) => p.messageId === payload.serialized
              );

              if (revokedIdx !== -1) {
                page.response[revokedIdx].type = 'REVOKED';
              }

              return page;
            });

            return {
              pages: previousMessages,
              pageParams: old.pageParams,
            };
          }
        });
      },
    }
  );
};

export default useDeleteMessage;
