import { useQuery } from '@tanstack/react-query';
import services from 'api/services';

import { GetCollectionParams } from '@/api/services/bitAi/v2-new/sorting';
import { useActiveCompany } from '@/hooks/rtk/selector';

const QUERY_KEY = 'bitai-sorting-v2' as const;

export const useCollections = (params?: GetCollectionParams) => {
  const activeCompany = useActiveCompany();

  return useQuery([`${QUERY_KEY}-collections`, activeCompany, params], () =>
    services.bitAi.v2New.sorting.getCollections(activeCompany, {
      ...params,
    })
  );
};
