import { Control } from 'react-hook-form';
import useResponsive from 'hooks/common/useResponsive';
import { AvailableSources } from 'hooks/whatsApp/useAvailableSources';
import { FormSchema as CampaignWAFormSchema } from 'pages/BitCRM/Campaign/Whatsapp';
import WhatsAppLivePreview from './WhatsAppLivePreview';

import { BBBContainer } from '@/components/ui';
import { FormSchema as TemplateWAFormSchema } from '@/pages/Misc/WhatsappApiSettings/WhatsappTemplate';

export type ControlTypeTemplateWAAutoReply = {
  type: 'template-wa-reply';
  control: Control<TemplateWAFormSchema>;
};

export type ControlTypeCampaignWAAutoReply = {
  type: 'campaign-wa-reply';
  control: Control<CampaignWAFormSchema>;
  source: AvailableSources;
};

export type ControlType =
  | ControlTypeTemplateWAAutoReply
  | ControlTypeCampaignWAAutoReply;

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  loading?: boolean;
  backUrl?: string;
} & ControlType;

export default function CampaignWrapper({
  children,
  title,
  description,
  loading,
  backUrl,
  ...controlType
}: Props) {
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');

  return (
    <div className="flex min-h-full">
      <div className="flex-[2_2_0%] relative">
        <div className="absolute inset-0">
          <BBBContainer
            {...(backUrl
              ? { hasBack: true, backUrl }
              : {
                  hasBack: false,
                })}
            hasHeading
            pageTitle={title}
            pageDescription={description}
            className={isMobile ? 'p-0 border-transparent' : undefined}
          >
            {children}
          </BBBContainer>
        </div>
      </div>
      {!isTablet && (
        <div className="border-l flex-[1_1_0%] flex justify-center items-center overflow-auto">
          <WhatsAppLivePreview {...controlType} loading={loading} />
        </div>
      )}
    </div>
  );
}
