export default function FacebookIcon({
  color = '#FFF',
  size = 24,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M24 12.5c0-6.627-5.373-12-12-12S0 5.873 0 12.5c0 5.989 4.388 10.954 10.125 11.855V15.97H7.077V12.5h3.048V9.856c0-3.008 1.792-4.67 4.533-4.67 1.313 0 2.686.235 2.686.235v2.953h-1.513c-1.49 0-1.956.925-1.956 1.875v2.25h3.328l-.532 3.47h-2.796v8.386C19.612 23.455 24 18.49 24 12.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="none"
        fillRule="evenodd"
        d="M16.593 16.283l.532-3.47h-3.328v-2.25c0-.949.464-1.875 1.956-1.875h1.514V5.735S15.893 5.5 14.58 5.5c-2.74 0-4.532 1.66-4.532 4.67v2.644H7v3.47h3.048v8.385a12.28 12.28 0 003.75 0v-8.386h2.796z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
