import integrations from './integrations';
import v1 from './v1';
import v2 from './v2';
import v2New from './v2-new';

export default {
  integrations,
  v1,
  v2,
  v2New,
};
