import { useQuery } from '@tanstack/react-query';
import services from 'api/services';

import { useActiveCompany } from '@/hooks/rtk/selector';

const useCustomer = (id: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['customer', id, activeCompany],
    () => services.customer.customer.getCustomer(id, activeCompany!),
    {
      enabled: !!id,
    }
  );
};

export default useCustomer;
