import { useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import {
  addMedia,
  initUploadMedia,
  updateMediaUploadProgress,
} from 'stores/bitCRM';
import { v4 } from 'uuid';
import { useActiveCompany } from '../rtk/selector';
import { useAppDispatch } from '../rtk/store';

import api from '@/config/api';
import { sanitizeFileName } from '@/utils/common/file';

export type UploadOption = { original?: boolean; fileId?: string };

export const useUploadFile = (_options?: {
  onProgress?: (progress: Record<string, number>) => void;
}) => {
  const activeCompany = useActiveCompany();
  const [loading, setLoading] = useState<Record<string, boolean>>();
  const [progress, setProgress] = useState<Record<string, number>>();

  async function uploadFile(
    file: File,
    options?: UploadOption
  ): Promise<string> {
    const fileId = options?.fileId || createId();

    setLoading((prev) => ({ ...prev, [fileId]: true }));

    const formData = new FormData();

    // Sanitize the file name before uploading
    const sanitizedFile = new File([file], sanitizeFileName(file.name), {
      type: file.type,
    });
    formData.append('image', sanitizedFile);

    try {
      const {
        data: { urlData },
      } = await api.systemLogin.post<{ urlData: string }>(
        `media/${activeCompany}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            original: options?.original,
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const percentCompleted = Math.floor((current / total) * 100);

            const updatedProgress = {
              ...progress,
              [fileId]: percentCompleted,
            };

            setProgress(updatedProgress);

            _options?.onProgress?.(updatedProgress);
          },
        }
      );

      return urlData;
    } finally {
      setLoading((prev) => ({ ...prev, [fileId]: false }));

      setProgress((prev) => {
        if (prev) {
          delete prev[fileId];

          return prev;
        }

        return;
      });
    }
  }

  return {
    uploadFile,
    loading,
    progress,
  };
};

// scoped only for livechat attachment modal use case
export const useUploadFiles = () => {
  const { uploadFile } = useUploadFile({
    onProgress: (progress) => {
      dispatch(updateMediaUploadProgress(progress));
    },
  });
  const dispatch = useAppDispatch();

  return async (
    files: File[],
    options?: {
      defaultSelectAdded?: boolean;
      retainSelectedMedia?: boolean;
    }
  ) => {
    const _files = Array.from(files);

    const fileWithIds = _files.map(() => v4());

    dispatch(initUploadMedia(fileWithIds));

    const fileData = await Promise.all(
      _files.map(async (_file, index) => {
        const id = fileWithIds[index];

        const value = await uploadFile(_file, { fileId: id });

        return {
          value,
          id,
          type: _file.type,
          meta: _file,
          loading: false,
        };
      })
    );

    dispatch(
      addMedia({
        mediaUrl: fileData,
        ...options,
      })
    );
  };
};
