import { env } from 'config/env';
import { useOnboarding } from 'hooks/onboarding/bitai';
import { useUpdateOnboarding } from 'hooks/onboarding/onboarding';
import OnboardingPage from 'layouts/OnboardingPage';
import { ShopifyCardBitAi } from 'pages/Integrations';

import { BBBButton, BBBCard } from '@/components/ui';
import { Link } from '@/components/ui/Link';

export default function BitAiOnboarding() {
  const { keyPoints, dismissed } = useOnboarding();

  const completeCount = keyPoints.filter(
    (point) => point.completed || point.skipped
  ).length;

  return (
    <OnboardingPage
      keyPoints={keyPoints}
      completeCount={completeCount}
      app="BITAI"
      dismissed={!!dismissed}
    >
      {({ keyPoint, completed, skipped }) => (
        <>
          {keyPoint === 'shopify_integration' && <ShopifyIntegrations />}
          {keyPoint === 'sorting_collections' && (
            <SortingCollections completed={!!completed} skipped={!!skipped} />
          )}
          {keyPoint === 'custom_model' && (
            <CustomModel completed={!!completed} skipped={!!skipped} />
          )}
        </>
      )}
    </OnboardingPage>
  );
}

function ShopifyIntegrations() {
  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitChat/bitchat-shopify-integrations.png`}
        className="object-contain mb-6"
        loading="lazy"
      ></img>
      <div className="text-xl mb-6">Boost sales with Shopify integration</div>
      <p className="mb-3">
        You&apos;ve connected your favorite chat channels - awesome! Now,
        let&apos;s integrate your Shopify store to unlock the full potential of
        bitCRM.
      </p>
      <p className="mb-3">
        Benefits of connecting Shopify:
        <ul className="list-disc ml-4">
          <li>
            <span className="font-bold">Auto push Out-Of-Stock items:</span>{' '}
            Automatically push down out-of-stock products.
          </li>
          <li>
            <span className="font-bold">Maximize Profits:</span> Optimize your
            inventory, sales, and watch your profit grows.
          </li>
          <li>
            <span className="font-bold">Optimized Collections:</span> Smartly
            organize and sort your product collections.
          </li>
          <li>
            <span className="font-bold">Auto-surface New Products:</span>{' '}
            Effortlessly highlight new products for testing.
          </li>
        </ul>
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Start boosting your sales! 🚀
        </span>{' '}
      </p>
      <ShopifyCardBitAi v="v3" />
    </BBBCard>
  );
}

function SortingCollections({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITAI');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitAi/bitai-sorting-collections.png`}
        loading="lazy"
        className="object-contain mb-6 rounded-lg"
      ></img>
      <div className="text-xl mb-6">
        Effortlessly Highlight Collections with Sorting
      </div>
      <p className="mb-6">
        Add as many new models as you need to feature sorting collections
        according to your preference.
      </p>
      <p className="mb-3">
        You can set these to sort your collection:
        <ul className="list-disc ml-4">
          <li>Sort by Model</li>
          <li>Push Out of Stock Products</li>
          <li>Feature Products Manually</li>
          <li>And many more!</li>
        </ul>
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Highlight your collection now✨
        </span>{' '}
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({
                  module: 'sorting_collections',
                  skipped: true,
                });
              }}
            >
              Skip this
            </div>
          )}
          <Link to={'sorting'}>
            <BBBButton>Go to sorting</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}

function CustomModel({
  completed,
  skipped,
}: {
  completed: boolean;
  skipped: boolean;
}) {
  const { mutate: updateOnboarding } = useUpdateOnboarding('BITAI');

  return (
    <BBBCard>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/onboarding/bitAi/bitai-custom-model.png`}
        loading="lazy"
        className="object-contain mb-6 rounded-lg"
      ></img>
      <div className="text-xl mb-6">Create Custom Model</div>
      <p className="mb-6">
        Create custom sorting models with as many categories as needed to
        organize your collections. Adjust the quantity to feature any number of
        products without limits.
      </p>
      <p className="mb-3">
        <span className="text-secondary-main font-bold">
          Create your own model now✨
        </span>{' '}
      </p>
      {!completed && (
        <div className="flex justify-end items-center gap-4">
          {!skipped && (
            <div
              className="underline cursor-pointer"
              onClick={() => {
                updateOnboarding({
                  module: 'custom_model',
                  skipped: true,
                });
              }}
            >
              Skip this step
            </div>
          )}
          <Link to={'model'}>
            <BBBButton>Go to Model</BBBButton>
          </Link>
        </div>
      )}
    </BBBCard>
  );
}
