import { SidebarIcon } from '@/constants/layouts/sidebarData';

function BitLinkIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitLinkIconDefault {...props} />
      ) : (
        <BitLinkIconActive {...props} />
      )}
    </>
  );
}

export function BitLinkIconDefault({
  size = 24,
  ...props
}: React.SVGProps<SVGSVGElement> & SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        fill="#727272"
        fillRule="evenodd"
        clipPath="url(#clip0_12225_10431)"
        clipRule="evenodd"
      >
        <path d="M15.16 4.958a4.167 4.167 0 00-5.892 0L8.089 6.137a.833.833 0 01-1.178-1.179L8.089 3.78a5.833 5.833 0 018.25 8.25l-1.179 1.178a.833.833 0 01-1.178-1.179l1.178-1.178a4.167 4.167 0 000-5.893zM4.958 15.16a4.167 4.167 0 005.893 0l1.178-1.178a.833.833 0 111.179 1.179l-1.179 1.178a5.833 5.833 0 01-8.25-8.25l1.179-1.178a.833.833 0 111.179 1.178L4.958 9.268a4.167 4.167 0 000 5.893z"></path>
        <path d="M6.97 13.09a.833.833 0 010-1.18l5-5a.833.833 0 111.179 1.18l-5 5a.833.833 0 01-1.179 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_12225_10431">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BitLinkIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        fill="#FF7C11"
        fillRule="evenodd"
        clipPath="url(#clip0_12225_10672)"
        clipRule="evenodd"
      >
        <path d="M15.16 4.958a4.167 4.167 0 00-5.892 0L8.089 6.137a.833.833 0 01-1.178-1.179L8.089 3.78a5.833 5.833 0 018.25 8.25l-1.179 1.178a.833.833 0 01-1.178-1.179l1.178-1.178a4.167 4.167 0 000-5.893zM4.958 15.16a4.167 4.167 0 005.893 0l1.178-1.178a.833.833 0 111.179 1.179l-1.179 1.178a5.833 5.833 0 01-8.25-8.25l1.179-1.178a.833.833 0 111.179 1.178L4.958 9.268a4.167 4.167 0 000 5.893z"></path>
        <path d="M6.97 13.09a.833.833 0 010-1.18l5-5a.833.833 0 111.179 1.18l-5 5a.833.833 0 01-1.179 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_12225_10672">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default BitLinkIcon;
