import { _PricingInfo } from './PricingInfo';

import CircleCheckIcon from '@/assets/icons/CircleCheckIcon';
import CircleXIcon from '@/assets/icons/CircleXIcon';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

function BitLoginStatus() {
  const { data } = usePricingByApp('BITLOGIN');

  if (!data) return null;

  return (
    <_PricingInfo
      app="BITLOGIN"
      subscriptionPlan={data.pricingModule.label || ''}
    >
      <div className="flex items-center gap-2 mb-4">
        <div className="flex items-center grow gap-2">
          <div className="text-neutral-50 text-xs">
            Sign in with your own OAuth
          </div>
        </div>
        <div className="text-neutral-50 text-xs font-semibold">
          {data.pricingName === 'free' ? <CircleXIcon /> : <CircleCheckIcon />}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="flex items-center grow gap-2">
          <div className="text-neutral-50 text-xs">Google One Tap</div>
        </div>
        <div className="text-neutral-50 text-xs font-semibold">
          {data.pricingName === 'free' ? <CircleXIcon /> : <CircleCheckIcon />}
        </div>
      </div>
    </_PricingInfo>
  );
}

export default BitLoginStatus;
