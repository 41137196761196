import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from 'hooks/bitLink/links/useLinklistMutation';
import useCustomForm from 'hooks/common/useCustomForm';
import useDefaultCountryCode from 'hooks/common/useDefaultCountryCode';
import useResponsive from 'hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from 'hooks/rtk/store';
import { setForm } from 'stores/bitLink';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { phoneValidator } from 'utils/common/phone';

import {
  BBBCard,
  BBBFooterAction,
  BBBPrimarySwitch,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextInput,
} from '@/components/ui';
import { formatPhonePayload } from '@/utils/common/phone';

const schema = yup.object().shape({
  title: yup.string().required('Button text is required'),
  phone: phoneValidator().required('Phone number is required'),
});

type FormWhatsappInput = {
  phone: BBBTelInputValue | null;
  title: string;
  active: boolean;
};

export default function FormWhatsapp() {
  const { handleSubmit, formState, setValue, control } =
    useCustomForm<FormWhatsappInput>({
      resolver: yupResolver(schema),
      defaultValues: {
        title: '',
        active: true,
        phone: null,
      },
    });

  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');

  const formWhatsapp = useAppSelector((state) => state.bitLink.form);
  const phoneDefaultValue = useDefaultCountryCode(formWhatsapp?.textSecondary);

  useEffect(() => {
    setValue('phone', phoneDefaultValue || null);
    setValue('title', formWhatsapp?.textPrimary || '');
    setValue('active', formWhatsapp?.active ?? true);
  }, [formWhatsapp, phoneDefaultValue, setValue]);

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();

  const onSubmit = (data: FormWhatsappInput) => {
    const requestData = {
      type: 'whatsapp' as const,
      textPrimary: data.title,
      active: data.active,
      textSecondary: formatPhonePayload(data.phone),
    };

    if (!formWhatsapp?.id) {
      addLinklist(requestData);
    } else {
      editLinklist({ id: formWhatsapp?.id, data: requestData });
    }
  };

  const handleDeleteForm = () => {
    if (formWhatsapp?.id) {
      deleteLinklist(formWhatsapp?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  return (
    <BBBCard
      title="WhatsApp"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
                containerClassName="flex justify-end mb-3"
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom', isMobile && 'border-none p-0')}
    >
      <div className="grow">
        <BBBTelInput
          placeholder="1234567"
          isHookForm
          control={control}
          controlName="phone"
          containerClassname="mb-3"
          label="Phone number"
          error={formState.errors.phone?.message}
        />
        <BBBTextInput
          label="Button text"
          control={control}
          containerClassname="mb-3"
          isHookForm
          placeholder="Example: Contact me!"
          controlName="title"
          error={formState.errors.title?.message}
        />
        <BBBFooterAction
          containerClassName="justify-end"
          onCancel={() => {
            dispatch(setForm(null));
          }}
          withoutDelete={formWhatsapp?.id ? undefined : true}
          loadingSave={isLoading || isLoadingEdit}
          onSave={handleSubmit(onSubmit)}
          onDelete={handleDeleteForm}
        />
      </div>
    </BBBCard>
  );
}
