import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toLower, upperFirst } from 'lodash-es';

import ChatIcon2 from '@/assets/icons/ChatIcon2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { CampaignLog } from '@/types/bitCRM/campaign';
import { formatDate2 } from '@/utils/common/date';

const useColumn = () => {
  return useMemo<HeadersTypes<CampaignLog>>(() => {
    return [
      {
        accesor: 'destination',
        isSortable: true,
        renderHeader: () => 'Customer',
        render: (info) => {
          return (
            <p className="text-primary-main">
              {info.destinationDisplayName || info.destination}
            </p>
          );
        },
      },
      {
        accesor: 'status',
        isSortable: false,
        headerClassName: '!flex-initial w-1/6',
        renderHeader: () => 'Status',
        render: (info) => (
          <p className="text-primary-main">
            {upperFirst(toLower(info.status))}
          </p>
        ),
      },
      {
        accesor: 'sendAt',
        isSortable: true,
        renderHeader: () => 'Sent date',
        sortOrder: 'desc',
        render: (info) => {
          return (
            <p className="text-primary-main">{formatDate2(info.sendAt)}</p>
          );
        },
      },
      {
        isAdditionalColumn: true,
        headerClassName: 'item-center justify-center',
        renderHeader: () => 'Action',
        headerAlign: 'center',
        render: (row) => (
          <Link
            to={`/bitchat/livechat?sources=${row.destinationSource}&chatId=${row.destination}`}
            className="w-fit flex items-center p-2 mx-auto justify-center border border-primary-main rounded"
          >
            <ChatIcon2 />
          </Link>
        ),
      },
    ];
  }, []);
};

export default useColumn;
