import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import PartyIcon from '@/assets/icons/PartyIcon';
import AuthCard from '@/components/Auth/Card';
import RegisterCard, {
  RegisterFormSchema,
} from '@/components/Auth/RegisterCard';
import { BBBButton, BBBSpinner } from '@/components/ui';
import useAcceptInvitation from '@/hooks/auth/useAcceptInvitation';
import { InvitationResponseData } from '@/hooks/auth/useInvitation';
import { LoginResponseData, SocialLoginPayload } from '@/hooks/auth/useLogin';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setAccessToken } from '@/stores/auth';
import { _localStorage } from '@/utils/common/localStorage';
import { formatPhonePayload } from '@/utils/common/phone';
import { toast } from '@/utils/common/toast';

type InvitationRedirectionProps = {
  id: string;
  data?: {
    isAutoRedirected: boolean;
    data: InvitationResponseData;
  };
};

function InvitationRedirection({ id, data }: InvitationRedirectionProps) {
  const { mutate: acceptInvitation, isLoading: isLoadingAccept } =
    useAcceptInvitation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [mode, setMode] = useState<'default' | 'accepted'>('default');
  const [tempAuthenticatedData, setTempAuthenticatedData] = useState<
    LoginResponseData & {
      authenticatedCompanyId: number;
    }
  >();

  const memoizedInvitedEmail = useMemo(
    () => ({ email: data?.data.invitedUserCompany.user.email }),
    [data?.data.invitedUserCompany.user.email]
  );

  useEffect(() => {
    if (data?.isAutoRedirected) {
      acceptInvitation(
        {
          id,
        },
        {
          onSuccess: (data) => {
            setMode('accepted');
            setTempAuthenticatedData(data);
          },
        }
      );
    }
  }, [acceptInvitation, data?.isAutoRedirected, id]);

  const onSubmitRegisterCard = (
    data: RegisterFormSchema | SocialLoginPayload
  ) => {
    if ('provider' in data) {
      if (data.email !== memoizedInvitedEmail.email) {
        toast.error(
          `Please authenticate with the correct email:${memoizedInvitedEmail.email} `
        );
      } else {
        acceptInvitation(
          {
            id,
            ...data,
          },
          {
            onSuccess: (data) => {
              setMode('accepted');
              setTempAuthenticatedData(data);
            },
          }
        );
      }
    } else {
      acceptInvitation(
        {
          id,
          lastName: data.lastName,
          password: data.password,
          firstName: data.firstName,
          phone: formatPhonePayload(data.phone),
        },
        {
          onSuccess: (data) => {
            setMode('accepted');
            setTempAuthenticatedData(data);
          },
        }
      );
    }
  };

  const onContinueDashboard = () => {
    _localStorage.setItem(
      'activeCompany',
      tempAuthenticatedData!.authenticatedCompanyId.toString()
    );
    _localStorage.setItem(
      'accessToken',
      tempAuthenticatedData!.accessToken as string
    );
    dispatch(setAccessToken(tempAuthenticatedData!.accessToken as string));
    history.push('/');
  };

  return (
    <>
      {mode === 'default' ? (
        <>
          {data?.isAutoRedirected ? (
            <>
              {isLoadingAccept && (
                <>
                  <BBBSpinner />
                  <div className="text-center mt-5">
                    Redirecting you to bitbybit..
                  </div>
                </>
              )}
            </>
          ) : (
            <RegisterCard
              onSubmit={onSubmitRegisterCard}
              data={memoizedInvitedEmail}
              loadingSave={isLoadingAccept}
              noLoginOptions
            />
          )}
        </>
      ) : (
        tempAuthenticatedData && (
          <AuthCard withoutLogo>
            <div className="overflow-y-auto scrollbar-hide flex flex-col items-center">
              <h1 className="text-[40px] font-bold text-center text-primary-main mb-10">
                Welcome to the {data?.data.invitedUserCompany.company.name}{' '}
                team!
              </h1>
              <PartyIcon className="mb-10" />
              <p className="text-base text-primary-main text-center mb-10">
                You have been invited to join{' '}
                {data?.data.invitedUserCompany.company.name}. Join{' '}
                {data?.data.invitedUserCompany.company.name} to start
                collaborating on bitbybit.
              </p>
              <BBBButton
                text="Continue to dashboard"
                onClick={onContinueDashboard}
              />
            </div>
          </AuthCard>
        )
      )}
    </>
  );
}

export default InvitationRedirection;
