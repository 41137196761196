export function checkBrightness(
  color: string | null | undefined
): 'dark' | 'bright' | undefined {
  let r: number, g: number, b: number;

  if (color?.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const rgbValues = color?.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    if (rgbValues) {
      r = parseInt(rgbValues[1], 10);
      g = parseInt(rgbValues[2], 10);
      b = parseInt(rgbValues[3], 10);
    } else {
      return undefined;
    }
  } else {
    // If hex --> Convert it to RGB
    color = color?.replace('#', '');
    if (color?.length === 3) {
      color = color?.replace(/(.)/g, '$1$1');
    }
    const hexColor = parseInt(color!, 16);
    r = (hexColor >> 16) & 255;
    g = (hexColor >> 8) & 255;
    b = hexColor & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'bright';
  } else {
    return 'dark';
  }
}

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
