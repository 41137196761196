import { useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

const useActiveTemplate = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitlogin-active-template'], () =>
    services.bitLogin.template.getActiveTemplate(activeCompany)
  );
};

export default useActiveTemplate;
