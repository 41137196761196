import { io } from 'socket.io-client';

import { env } from '@/config/env';

const isProduction = env.REACT_APP_NODE_ENV === 'production';

const isDevelopment = env.REACT_APP_NODE_ENV === 'development';

const baseUrl = isProduction
  ? `https://api.bitbybit.studio`
  : isDevelopment
  ? `https://staging.api.bitbybit.studio`
  : undefined;

if (!baseUrl) {
  throw `REACT_APP_NODE_ENV env is required for socket initialization`;
}

export const socketBitCRM = io(baseUrl, {
  path: '/bitcrm/api/v2/socket.io/',
  transports: ['websocket'],
});

export const socketWhatsApp = io(baseUrl, {
  path: '/whatsapp/socket.io/',
  transports: ['websocket'],
});

export const socketBaileys = io(baseUrl, {
  path: '/baileys/socket.io/',
  transports: ['websocket'],
});

export const socketIntegrations = io(baseUrl, {
  path: '/integrations/socket.io/',
  transports: ['websocket'],
});

export const socketBitLogin = io(baseUrl, {
  path: '/bitlogin/socket.io/',
  transports: ['websocket'],
});

export const socketCustomers = io(baseUrl, {
  path: '/customer/socket.io/',
  transports: ['websocket'],
});

export const socketBitApp = io(baseUrl, {
  path: '/bitapp/socket.io/',
  transports: ['websocket'],
});

export const socketBitChat = io(baseUrl, {
  path: '/bitchat/socket.io/',
  transports: ['websocket'],
});

export const socketPricing = io(baseUrl, {
  path: '/pricing/socket.io/',
  transports: ['websocket'],
});
