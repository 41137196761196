import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'api/types';
import axios from 'axios';

import api from '@/config/api';
import { mapAppTypeToApiBaseUrl } from '@/constants/api';
import { CompanyIntegration } from '@/types/bitApp/v2';
import { AppType, Company } from '@/types/systemLogin';

const useIntegratedCompaniesByShop = (appType?: AppType, domain?: string) => {
  return useQuery(
    ['integrated-companies', domain, appType],
    () =>
      axios
        .get<ApiResponse<CompanyIntegration[]>>(
          `${mapAppTypeToApiBaseUrl[appType!]}/integrations/shopify`,
          {
            params: {
              domain,
            },
          }
        )
        .then((res) => {
          const companyIds = res.data.data.map(
            (companyIntegration) => companyIntegration.companyId
          );

          if (res.data.data.length) {
            return api.systemLogin
              .get<Company[]>(`/company`, {
                params: {
                  id: companyIds.join(','),
                },
              })
              .then((res) => res.data);
          }

          return [];
        }),
    {
      enabled: !!domain && !!appType,
    }
  );
};

export default useIntegratedCompaniesByShop;
