import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import { BBBCard, BBBPrimarySwitch, BBBSelect } from '@/components/ui';
import {
  customOperationalHour,
  generateTimeOptions,
  operationalHoursTypeOptions,
  operationalHoursTypeOptionsHash,
} from '@/constants/bitChat/settings';
import { days } from '@/constants/common/time';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import { Settings } from '@/types/whatsApp/settings';

type SettingsForm = Pick<Settings, 'operationalHours'> & {
  operationalHoursType: typeof operationalHoursTypeOptions[number];
  operationalHourStart: {
    label: string;
    value: string;
  } | null;
  operationalHourEnd: {
    label: string;
    value: string;
  } | null;
  operationHourCustomDays: {
    operationalDay: string;
    operationalStart: {
      label: string;
      value: string;
    } | null;
    operationalStartEnd: {
      label: string;
      value: string;
    } | null;
    isOperationalActive: boolean;
  }[];
};

const timeOptions = generateTimeOptions();

const timeOptionsHash = timeOptions.reduce<
  Record<string, typeof timeOptions[number]>
>((acc, item) => {
  acc[item.value] = item;
  return acc;
}, {});

const schema = yup.object({
  operationalHours: yup.boolean().required(),
  operationalHoursType: yup.mixed().required(),
  operationalHourStart: yup
    .mixed()
    .label('Start time')
    .when('operationalHours', {
      is: true,
      then: (schema) =>
        schema.when('operationalHoursType', {
          is: (value: { value: string; label: string } | null) =>
            value?.value === 'custom',
          then: yup.mixed().required(),
        }),
    }),
  operationalHourEnd: yup
    .mixed()
    .label('End time')
    .when('operationalHours', {
      is: true,
      then: (schema) =>
        schema.when('operationalHoursType', {
          is: (value: { value: string; label: string } | null) =>
            value?.value === 'custom',
          then: yup.mixed().required(),
        }),
    }),
  operationHourCustomDays: yup.array().when('operationalHours', {
    is: true,
    then: (schema) =>
      schema.when('operationalHoursType', {
        is: (value: { value: string; label: string } | null) =>
          value?.value === 'custom',
        then: yup.array(
          yup.object({
            operationalDay: yup.string().required(),
            operationalStart: yup.mixed().required().label('Start time'),
            operationalStartEnd: yup.mixed().required().label('End time'),
            isOperationalActive: yup.boolean().required(),
          })
        ),
      }),
  }),
});

function OperationHours() {
  const { data } = useSettings();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();

  const dataFromApi = useMemo<Partial<SettingsForm>>(() => {
    const operationHourCustomDays = (
      data?.operationHourCustomDays || []
    ).reduce<Record<string, typeof customOperationalHour[number]>>(
      (acc, item) => {
        acc[item.operationalDay] = item;
        return acc;
      },
      {}
    );

    return {
      operationalHours: data?.operationalHours || false,
      operationalHoursType: data?.operationalHoursType
        ? operationalHoursTypeOptionsHash[data?.operationalHoursType]
        : operationalHoursTypeOptions[0],
      operationalHourStart: data?.operationalHourStart
        ? timeOptionsHash[data?.operationalHourStart]
        : null,
      operationalHourEnd: data?.operationalHourEnd
        ? timeOptionsHash[data?.operationalHourEnd]
        : null,
      operationHourCustomDays: days.map((day) => {
        const customDay = operationHourCustomDays[day];

        return {
          operationalDay: day,
          operationalStart: customDay?.operationalStart
            ? timeOptionsHash[customDay.operationalStart]
            : null,
          operationalStartEnd: customDay?.operationalStartEnd
            ? timeOptionsHash[customDay.operationalStartEnd]
            : null,
          isOperationalActive: customDay?.isOperationalActive || false,
        };
      }),
    };
  }, [
    data?.operationalHours,
    data?.operationHourCustomDays,
    data?.operationalHoursType,
    data?.operationalHourStart,
    data?.operationalHourEnd,
  ]);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useCustomForm<SettingsForm>({
    defaultValues: dataFromApi,
    resolver: yupResolver(schema),
  });

  const operationalHours = watch('operationalHours');
  const operationHourCustomDays = watch('operationHourCustomDays');
  const operationalHourType = watch('operationalHoursType');

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = ({
      operationalHours,
      operationalHourStart,
      operationalHourEnd,
      operationalHoursType,
      operationHourCustomDays,
    }: Partial<SettingsForm>) => {
      updateSettings({
        operationalHoursType: operationalHoursType?.value,
        operationalHourStart: operationalHourStart?.label,
        operationalHourEnd: operationalHourEnd?.label,
        operationHourCustomDays:
          operationalHoursType?.value !== 'custom'
            ? null
            : operationHourCustomDays?.map((item) => ({
                operationalDay: item.operationalDay,
                operationalStart: item.operationalStart?.value,
                operationalStartEnd: item.operationalStartEnd?.value,
                isOperationalActive: item.isOperationalActive,
              })),
        operationalHours,
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-settings', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingUpdateSettings ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingUpdateSettings,
    reset,
    toggle,
    watch(),
    updateSettings,
  ]);

  return (
    <BBBCard
      title="Operational hours"
      desc="Adjust your team operational hours to fit your customer needs"
      rightButton={
        <Controller
          control={control}
          name="operationalHours"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      }
      className="mb-cardBottom"
    >
      {operationalHours && (
        <div
          className={cx(
            'flex gap-5 items-center',
            operationalHourType?.value === 'custom' &&
              'flex-col items-start justify-center'
          )}
        >
          <Controller
            control={control}
            name="operationalHoursType"
            render={({ field }) => (
              <BBBSelect
                options={operationalHoursTypeOptions}
                optionLabel="label"
                optionValue="value"
                value={field.value}
                onValueChange={field.onChange}
                containerClassName="flex-1 w-full lg:w-3/5"
              />
            )}
          />
          {operationalHourType?.value === 'custom' ? (
            <div className="w-full flex flex-col gap-6">
              {operationHourCustomDays?.map((item, index) => (
                <div
                  key={item.operationalDay}
                  className="flex w-full flex-col lg:flex-row lg:items-center gap-5"
                >
                  <p className="text-neutral-60 flex-grow max-w-[200px]">
                    {item.operationalDay}
                  </p>
                  <div className="flex items-center gap-5 grow">
                    <Controller
                      control={control}
                      name="operationHourCustomDays"
                      render={({ field }) => (
                        <>
                          <BBBSelect
                            options={timeOptions}
                            placeholder="Select time"
                            optionLabel="label"
                            optionValue="value"
                            value={item.operationalStart}
                            onValueChange={(opt) => {
                              field.onChange(
                                operationHourCustomDays?.map((day) => {
                                  if (
                                    day.operationalDay === item.operationalDay
                                  ) {
                                    return {
                                      ...day,
                                      operationalStart: opt,
                                    };
                                  }
                                  return day;
                                })
                              );
                            }}
                            containerClassName="flex-grow"
                            error={
                              errors.operationHourCustomDays?.[index]
                                ?.operationalStart?.message
                            }
                            isSearchable
                          />
                          -
                          <BBBSelect
                            options={timeOptions}
                            placeholder="Select time"
                            optionLabel="label"
                            optionValue="value"
                            value={item.operationalStartEnd}
                            onValueChange={(opt) => {
                              field.onChange(
                                operationHourCustomDays?.map((day) => {
                                  if (
                                    day.operationalDay === item.operationalDay
                                  ) {
                                    return {
                                      ...day,
                                      operationalStartEnd: opt,
                                    };
                                  }
                                  return day;
                                })
                              );
                            }}
                            containerClassName="flex-grow"
                            error={
                              errors.operationHourCustomDays?.[index]
                                ?.operationalStartEnd?.message
                            }
                            isSearchable
                          />
                        </>
                      )}
                    />
                    <Controller
                      control={control}
                      name="operationHourCustomDays"
                      render={({ field }) => (
                        <BBBPrimarySwitch
                          checked={item.isOperationalActive}
                          onChange={() => {
                            field.onChange(
                              operationHourCustomDays?.map((day) => {
                                if (
                                  day.operationalDay === item.operationalDay
                                ) {
                                  return {
                                    ...day,
                                    isOperationalActive:
                                      !day.isOperationalActive,
                                  };
                                }
                                return day;
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex gap-5 items-center">
              <Controller
                control={control}
                name="operationalHourStart"
                render={({ field }) => (
                  <BBBSelect
                    options={timeOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              -
              <Controller
                control={control}
                name="operationalHourEnd"
                render={({ field }) => (
                  <BBBSelect
                    options={timeOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </BBBCard>
  );
}

export default OperationHours;
