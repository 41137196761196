import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { PlusCircle } from 'react-feather';
import { useReactFlow } from 'reactflow';
import colors from 'constants/common/colors';
import { twMerge as cx } from 'tailwind-merge';
import useStore from '../../store';

export default function Connector({ id }: { id: string }) {
  const plusRef = useRef<HTMLDivElement | null>(null);
  const { getZoom } = useReactFlow();

  const zoomRatio = getZoom();

  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );

  const [_isHeld, setIsHeld] = useState(false);
  const [plusTransition, setPlusTransition] = useState(false);

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);
  const onChangeActiveAddState = useStore((s) => s.onChangeActiveAddState);
  const setConnectingNode = useStore((s) => s.setConnectingNode);
  const resetConnectingNode = useStore((s) => s.resetConnectingNode);

  const isHeld = useStore((s) => s.connectingNode === id);

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState<{ x: number; y: number } | null>(
    null
  );

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handlePointerDown = () => {
    setConnectingNode(id);
    if (plusRef.current) {
      const { x, y, width, height } = plusRef.current.getBoundingClientRect();
      setStartPos({ x: x + width / 2, y: y + height / 2 });
    }
    timeoutRef.current = setTimeout(() => {
      setIsHeld(true);
    }, 200);
  };

  useEffect(() => {
    const handlePointerUp = (e: PointerEvent) => {
      const viewportSelector = document.querySelector<HTMLDivElement>(
        '.react-flow__viewport'
      );

      const { x: viewportX, y: viewportY } =
        viewportSelector!.getBoundingClientRect();

      resetConnectingNode({
        mouseX: e.clientX,
        mouseY: e.clientY,
        viewportX,
        viewportY,
        zoom: zoomRatio,
        sourceId: id,
      });

      setIsHeld(false);
    };

    if (isHeld) {
      window.addEventListener('pointerup', handlePointerUp);

      return () => {
        window.removeEventListener('pointerup', handlePointerUp);
      };
    } else {
      return () => {
        window.removeEventListener('pointerup', handlePointerUp);
      };
    }
  }, [id, isHeld, resetConnectingNode, startPos?.x, startPos?.y, zoomRatio]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (_isHeld) {
      setPlusTransition(true);
      timeout = setTimeout(() => {
        setPlusTransition(false);
      }, 2000);
    } else {
      setPlusTransition(false);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [_isHeld]);

  return (
    <>
      <div
        className={cx(
          'absolute pointer-events-none opacity-0 left-full -translate-x-3 top-1/2 -translate-y-1/2 z-[1000] cursor-pointer transition',
          (expandState === 'hover' || expandState === 'clicked' || isHeld) &&
            'opacity-100'
        )}
        onClick={(e) => {
          setExpandState(id, 'clicked');
          onChangeActiveAddState(id);
          e.stopPropagation();

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }}
        onPointerDown={handlePointerDown}
        ref={plusRef}
      >
        {!hasEdges && (
          <PlusCircle
            size={20}
            className={cx(
              'text-secondary-main bg-white transition hover:scale-150 duration-300 rounded-full flex-none pointer-events-auto',
              expandState === 'clicked' && 'text-primary-main'
            )}
          />
        )}
        {_isHeld && startPos && (
          <LineDrawer
            sourceX={startPos.x}
            sourceY={startPos.y}
            targetX={mousePosition.x}
            targetY={mousePosition.y}
          />
        )}
      </div>
    </>
  );
}

// function ConnectingTransition({ id }: { id: string }) {
//   const transitioningCreateEdges = useStore(
//     (s) => s.transitioningCreateEdges,
//     shallow
//   );

//   const { getZoom } = useReactFlow();

//   const zoomRatio = getZoom();

//   if (!transitioningCreateEdges) return null;

//   if (id !== transitioningCreateEdges.source) return null;

//   const source = document.querySelector(
//     `.react-flow__node[data-id="${transitioningCreateEdges.source}"]`
//   );
//   const target = document.querySelector(
//     `.react-flow__node[data-id="${transitioningCreateEdges.target}"]`
//   );

//   if (!source || !target) return null;

//   const { x: sourceX, y: sourceY } = source.getBoundingClientRect();
//   const { x: targetX, y: targetY, height } = target.getBoundingClientRect();

//   const distanceX = (targetX - sourceX) * (1 / zoomRatio);
//   const distanceY = (targetY + height / 2 - sourceY) * (1 / zoomRatio);

//   return (
//     <div
//       className="absolute left-full bg-primary-main/10 z-30"
//       style={{
//         width: distanceX,
//         height: distanceY,
//       }}
//     >
//       <motion.div
//         initial={{ height: 0 }}
//         animate={{ height: distanceY }}
//         transition={{ type: 'tween' }}
//         className="w-full bg-primary-main/10"
//       />
//     </div>
//   );
// }

const LineDrawer = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}) => {
  return createPortal(
    <svg
      width="100%"
      height="100%"
      style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
    >
      <line
        x1={sourceX}
        y1={sourceY}
        x2={targetX}
        y2={targetY}
        stroke={colors.primary.main}
        strokeWidth="1"
      />
    </svg>,
    document.body!
  );
};
