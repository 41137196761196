import analytics from './analytics';
import appearance from './appearance';
import integrations from './integrations';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { CompanyIntegrationAssociation } from '@/types/bitApp/v2/association';

export default {
  /**
   * @deprecated
   * Use bitLinkServices.integrations.getCompanyIntegrations instead
   */
  getCompanyIntegrations: async (companyId: number | undefined) => {
    const {
      data: { data },
    } = await api.bitLink.get<ApiResponse<CompanyIntegrationAssociation[]>>(
      `/integrations/${companyId}`
    );
    return data;
  },
  integrations,
  appearance,
  analytics,
};
