import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { triggerRefreshIframe } from '@/stores/bitLink';
import { Branding } from '@/types/bitLink/v2';

const useEditBranding = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (payload: Partial<Branding>) =>
      services.bitLink.appearance.editBranding(activeCompany!, payload),
    {
      onSuccess: () => {
        dispatch(triggerRefreshIframe());
        return queryClient.invalidateQueries(['bitlink-branding']);
      },
    }
  );
};

export default useEditBranding;
