import banner from './banner';
import branding from './branding';
import cartSettings from './cart-settings';
import category from './category';
import collection from './collection';
import collections from './collections';
import imageGrid from './image-grid';
import integrations from './integrations';
import navigation from './navigation';
import notification from './notification';
import publish from './publish';
import section from './section';

export default {
  banner,
  collection,
  category,
  integrations,
  imageGrid,
  branding,
  section,
  navigation,
  cartSettings,
  publish,
  collections,
  notification,
};
