import { useUserId } from '../rtk/selector';
import useUserCompanyDetail from './useUserCompanyDetail';

const useAuthenticatedUserCompany = (companyId?: number) => {
  const userId = useUserId();

  return useUserCompanyDetail(userId.toString(), companyId, {
    retry: false,
  });
};

export default useAuthenticatedUserCompany;
