import { useQuery } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { Subscriptions } from '@/types/bitLink/v2';

const useSubscription = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery<Subscriptions>(
    ['bitlink-subscription', activeCompany],
    async () => {
      const { data } = await api.bitLink.get(`/subscription/${activeCompany}`);
      return data;
    }
  );
};

export default useSubscription;
