import { useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBSelect,
  MultipleOnValueChangeCallback,
  SingleOnValueChangeCallback,
} from '@/components/ui';
import useAiTags from '@/hooks/customers/customer/useAiTags';
import { AiTag } from '@/types/customers';

type Props = (
  | {
      value: AiTag | null;
      onValueChange: SingleOnValueChangeCallback<AiTag>;
      isMulti?: false;
    }
  | {
      value: AiTag[] | null;
      onValueChange: MultipleOnValueChangeCallback<AiTag>;
      isMulti: true;
    }
) & {
  containerClassName?: string;
  placeholder?: string;
  label?: string;
  isSearchable?: boolean;
  error?: string;
};

export default function AiTagOptions({
  containerClassName,
  placeholder = 'AI Tags',
  isSearchable,
  ...props
}: Props) {
  const [search, setSearch] = useState<string>();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: loadingTags,
  } = useAiTags({ search });

  const flatennedData = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <BBBSelect
      options={flatennedData}
      optionLabel="name"
      optionValue="name"
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      placeholder={placeholder}
      containerClassName={cx(
        'md:min-w-[128px] md:max-w-[256px]',
        containerClassName
      )}
      loading={loadingTags}
      {...(isSearchable
        ? {
            isSearchable: true,
            search,
            onChangeSearch: setSearch,
          }
        : {
            isSearchable: false,
          })}
      {...props}
    />
  );
}
