import { useState } from 'react';
import { Info } from 'react-feather';
import dayjs from 'dayjs';
import { lowerCase, startCase } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBAlert,
  BBBBarChart,
  BBBButton,
  BBBCard,
  BBBOverviewBox,
  BBBPieChart,
  BBBTooltip,
} from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import {
  previewAnalytics,
  previewGender,
  previewLoginChannel,
  previewPeakHours,
  previewTopLocations,
  previewVisitorsInformation,
} from '@/constants/analytics/bitLogin';
import {
  useDownloadVisitors,
  useLoginChannel,
  useLoginLocation,
  useLoginSession,
  useNewUser,
  usePeakHourAnalytics,
  useUserGender,
  useVisitors,
} from '@/hooks/bitLogin/analytics';
import useSocialConfig from '@/hooks/bitLogin/useSocialConfig';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { formatDate2 } from '@/utils/common/date';

type Props = {
  className?: string;
  startDate: string;
  endDate: string;
  loading?: boolean;
  isPreview?: boolean;
};

export default function BitLoginAnalytics({
  className,
  startDate,
  endDate,
  loading,
  isPreview,
}: Props) {
  const isMobile = useResponsive('sm');

  const { data: loginAnalyticsData, isInitialLoading: loadingLoginAnalytics } =
    useLoginSession(
      {
        startDate,
        endDate,
      },
      {
        enabled: !loading && !isPreview,
      }
    );

  const {
    data: newUserAnalyticsData,
    isInitialLoading: loadingNewUserAnalytics,
  } = useNewUser(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const { data: peakHourAnalyticsData } = usePeakHourAnalytics(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const { data: loginChannelData, isInitialLoading: loadingLoginChannel } =
    useLoginChannel(
      {
        startDate,
        endDate,
      },
      {
        enabled: !loading && !isPreview,
      }
    );

  const { data: userGenderData } = useUserGender(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const loginChannel = isPreview
    ? previewLoginChannel.sort((a, b) => b.value - a.value)
    : loginChannelData?.sort((a, b) => b.value - a.value);

  const props = {
    startDate: startDate,
    endDate: endDate,
    isPreview: isPreview,
    loading: loading,
  };

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className={cx('col-span-4', className)}>
        <BBBOverviewBox
          title="Login session"
          tooltip="How many customers that login to your store"
          value={
            isPreview
              ? previewAnalytics.loginSession.count
              : loginAnalyticsData?.count || 0
          }
          loading={loadingLoginAnalytics || loading}
        />
      </div>
      <div className={cx('col-span-4', className)}>
        <BBBOverviewBox
          title="New customers"
          tooltip="How many customers that sign up to your store"
          value={
            isPreview
              ? previewAnalytics.newUser.count
              : newUserAnalyticsData?.count || 0
          }
          loading={loadingNewUserAnalytics || loading}
        />
      </div>
      <div className={cx('col-span-4', className)}>
        <BBBOverviewBox
          title="Avg. Login session"
          tooltip="How many average customers that login to your store in certain time period"
          value={
            isPreview
              ? previewAnalytics.averageLoginSession.count
              : (loginAnalyticsData?.average &&
                  Math.round(loginAnalyticsData?.average)) ||
                0
          }
          loading={loadingLoginAnalytics || loading}
        />
      </div>
      {!isMobile && (
        <div className={cx('col-span-12')}>
          {!peakHourAnalyticsData?.length && !isPreview ? (
            <Empty
              title="Peak hours"
              tooltip="Track your customer active hours"
            />
          ) : (
            <BBBBarChart
              title="Peak hours"
              tooltip="Track your customer active hours"
              lineDataKey="count"
              xAxisProps={{
                dataKey: 'hour',
              }}
              isHasBrush
              data={isPreview ? previewPeakHours : peakHourAnalyticsData}
            />
          )}
        </div>
      )}
      {!isMobile && (
        <div className={cx('col-span-6')}>
          {!userGenderData?.length && !isPreview ? (
            <Empty
              title="Gender"
              tooltip="This pie chart shows the distribution of your visitors by gender, based on their login information"
            />
          ) : (
            <BBBPieChart
              title="Gender"
              tooltip="This pie chart shows the distribution of your visitors by gender, based on their login information"
              data={isPreview ? previewGender : userGenderData}
              dataKey="value"
              colors={['#FD823E', '#AF1900', '#007F9F', '#FCEFD7']}
              containerClassName="h-[27rem]"
              renderLegend={(data, colors) => (
                <div className="flex flex-col gap-2">
                  {data?.map((entry, index) => (
                    <div
                      key={`item-${index}`}
                      className="flex items-center gap-2"
                    >
                      <div
                        className="w-5 h-5 rounded-md"
                        style={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      />
                      <p className="text-primary-main">
                        {/* @ts-ignore */}
                        {entry.value} ({entry?.payload?.percentage}%)
                      </p>
                    </div>
                  ))}
                </div>
              )}
              tooltipProps={{
                isCustomTooltip: true,
                customContent: (payload) => (
                  <p>
                    {payload?.[0]?.name} -{' '}
                    {`${payload?.[0]?.payload?.value} (${payload?.[0]?.payload?.percentage}%)`}
                  </p>
                ),
              }}
            >
              <BannerNotification />
            </BBBPieChart>
          )}
        </div>
      )}
      {!isMobile && (
        <div className={cx('col-span-6')}>
          {!loginChannelData?.length && !isPreview ? (
            <Empty
              title="Login channel"
              tooltip="This pie chart shows the distribution of your visitors by login channel, based on their login information"
            />
          ) : (
            <BBBPieChart
              title="Login channel"
              data={loginChannel}
              dataKey="value"
              containerClassName="h-[27rem]"
              renderLegend={(data, colors) => (
                <div className="flex flex-col gap-2">
                  {data?.map((entry, index) => (
                    <div
                      key={`item-${index}`}
                      className="flex items-center gap-2"
                    >
                      <div
                        className="w-5 h-5 rounded-md"
                        style={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      />
                      <p className="text-primary-main">
                        {/* @ts-ignore */}
                        {entry.value} ({entry?.payload?.percentage}%)
                      </p>
                    </div>
                  ))}
                </div>
              )}
              tooltipProps={{
                isCustomTooltip: true,
                customContent: (payload) => (
                  <p>
                    {payload?.[0]?.name} -{' '}
                    {`${payload?.[0]?.payload?.value} (${payload?.[0]?.payload?.percentage}%)`}
                  </p>
                ),
              }}
              loading={loadingLoginChannel || loading}
            />
          )}
        </div>
      )}
      <div className={cx('col-span-12')}>
        <TopLocations {...props} />
      </div>
      {!isMobile && (
        <div className={cx('col-span-12')}>
          <Visitors {...props} />
        </div>
      )}
    </div>
  );
}

function TopLocations({
  isPreview,
  startDate,
  endDate,
  loading,
}: {
  isPreview?: boolean;
  startDate: string;
  endDate: string;
  loading?: boolean;
}) {
  const { data: _data } = useLoginLocation(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const data = (isPreview ? previewTopLocations.locations : _data)?.sort(
    (a, b) => {
      if (a.country === 'Unknown' || b.country === 'Others') return 1;
      if (a.country === 'Others' || b.country === 'Unknown') return -1;
      return 0;
    }
  );

  if (!data?.length && !isPreview) {
    return (
      <Empty
        title="Locations"
        tooltip="A breakdown of your visitor locations based on their login attempts"
      />
    );
  }

  return (
    <BBBCard
      title={
        <div className="flex gap-1 items-center w-fit">
          <p className="text-base font-normal whitespace-nowrap">Locations</p>
          <BBBTooltip
            show
            content="A breakdown of your visitor locations based on their login attempts"
          >
            <Info size={14} color="#9E9E9E" />
          </BBBTooltip>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {data?.map((item) =>
          item.country !== 'Unknown' && item.country !== 'Others' ? (
            <div
              key={item.country}
              className="w-full h-7 flex justify-between items-center"
            >
              <div className="flex items-center gap-2.5">
                <img
                  alt="United States"
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.iso.toUpperCase()}.svg`}
                  className="w-[21px] border border-neutral-60"
                />
                <p className="text-primary-main">{startCase(item.country)}</p>
              </div>
              <p className="text-primary-main font-medium">
                {item.percentage}%
              </p>
            </div>
          ) : item.country === 'Others' ? (
            <div className="w-full h-7 flex justify-between items-center">
              <p className="text-primary-main">Others</p>
              <p className="text-primary-main font-medium">
                {item.percentage}%
              </p>
            </div>
          ) : (
            <div className="w-full h-7 flex justify-between items-center">
              <p className="text-primary-main">Unknown</p>
              <p className="text-primary-main font-medium">
                {item.percentage}%
              </p>
            </div>
          )
        )}
        <BannerNotification />
      </div>
    </BBBCard>
  );
}

const visitorColumns: HeadersTypes<
  typeof previewVisitorsInformation['visitors'][number]
> = [
  {
    accesor: 'firstName',
    isSortable: true,
    columnWidth: '20%',
    renderHeader: () => 'Full Name',
    render: (row) => (
      <p className="text-primary-main">
        {row.firstName || '-'} {row.lastName}
      </p>
    ),
  },
  {
    accesor: 'email',
    isSortable: true,
    columnWidth: '20%',
    renderHeader: () => 'Email',
    render: (row) => <p className="text-primary-main">{row.email || '-'}</p>,
  },
  {
    accesor: 'phoneNumber',
    isSortable: true,
    renderHeader: () => 'Phone number',
    render: (row) => (
      <p className="text-primary-main">{row.phoneNumber || '-'}</p>
    ),
  },
  {
    accesor: 'birthday',
    isSortable: true,
    renderHeader: () => 'Date of birth',
    render: (row) => (
      <p className="text-primary-main">
        {row.birthday ? dayjs(row.birthday).format('DD-MM-YYYY') : '-'}
      </p>
    ),
  },
  {
    accesor: 'provider',
    isSortable: true,
    columnWidth: '12%',
    renderHeader: () => 'Login by',
    render: (row) => (
      <p className="text-primary-main">{lowerCase(startCase(row.provider))}</p>
    ),
  },
  {
    accesor: 'updatedAt',
    isSortable: true,
    renderHeader: () => 'Last login',
    render: (row) => (
      <p className="text-primary-main">{formatDate2(row.updatedAt || '-')}</p>
    ),
  },
];

function Visitors({
  startDate,
  endDate,
  isPreview,
  loading,
}: {
  startDate: string;
  endDate: string;
  isPreview?: boolean;
  loading?: boolean;
}) {
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');

  const { data, isLoading } = useVisitors(
    {
      startDate,
      endDate,
      limit: pageSize,
      page: pageIndex + 1,
      search,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const { mutate, isLoading: isMutating } = useDownloadVisitors();

  return (
    <div className="flex flex-col gap-4 mt-4">
      <p className="text-primary-main text-xl">Visitors information</p>

      <BBBTableV2
        data={
          isPreview
            ? previewVisitorsInformation.visitors.filter((item) =>
                search
                  ? (item.firstName || item.lastName)
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  : item
              )
            : data?.data || []
        }
        dataId="id"
        headers={visitorColumns}
        loadingBody={isPreview ? false : isLoading}
        isSearchable
        searchPlaceholder="Search"
        searchValue={search}
        onChangeSearch={setSearch}
        isFilterable
        renderFilterSection={() => (
          <BBBButton
            text="Download CSV"
            variant="secondary"
            iconPosition="right"
            onClick={() =>
              mutate({
                search,
              })
            }
            loadingState={isMutating}
            size="sm"
          />
        )}
        isPaginate
        pagination={{
          page: pageIndex + 1,
          limit: pageSize,
          onChange: (page) => setPageIndex(page - 1),
          total: isPreview
            ? previewVisitorsInformation.visitors.length
            : parseInt(data?.total || '0') || 0,
        }}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(page) => {
          setPageSize(page!);
          setPageIndex(0);
        }}
      />
    </div>
  );
}

function BannerNotification() {
  const { data: socialConfig } = useSocialConfig();
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const socialProviderConfig = socialConfig?.filter(
    (config) => config.provider === 'FACEBOOK'
  );

  const isSocialEnabled = socialProviderConfig?.some(
    (config) => config.enabledWeb
  );

  if (!isSocialEnabled && pricingData?.pricingName !== 'free') {
    return (
      <BBBAlert type="info" className="p-2 justify-evenly gap-1">
        <p className="text-primary-main text-center w-fit">
          Turn on your{' '}
          <Link
            to={{
              pathname: '/bitlogin/social',
            }}
            className="font-bold underline"
          >
            Facebook social login
          </Link>{' '}
          to start seeing this analytics data!
        </p>
      </BBBAlert>
    );
  }

  return null;
}

function Empty({ title, tooltip }: { title: string; tooltip?: string }) {
  return (
    <BBBCard
      title={
        tooltip ? (
          <div className="flex gap-1 items-center w-fit">
            <p className="text-base font-normal whitespace-nowrap">{title}</p>
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              position="top"
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          </div>
        ) : (
          <p className="text-base font-normal whitespace-nowrap">{title}</p>
        )
      }
    >
      <div className="min-h-[20rem] h-full flex flex-col gap-1 items-center justify-center">
        <div className="text-primary-main font-medium">
          No sessions in this date range
        </div>
        <div className="text-neutral-50 text-sm">
          Try selecting a different date range
        </div>
      </div>
    </BBBCard>
  );
}
