import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { Campaign } from '@/types/bitCRM/campaign';
import { toast } from '@/utils/common/toast';

export type SendCampaignPayload = Partial<Campaign> & {
  customerTotal?: number;
  agentUserId?: number;
};

const useSendCampaign = () => {
  const history = useHistory();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  const userId = useUserId();

  return useMutation(
    ({
      isTest,
      leaveCb,
      ...payload
    }: SendCampaignPayload & {
      leaveCb?: () => void;
      isDraft?: boolean;
      isTest?: boolean;
    }) =>
      api.bitCRM.v2.post(`/campaign-new/${isTest ? 'test' : ''}`, {
        companyId: activeCompany,
        userId,
        ...payload,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(
          payload.isDraft
            ? 'Succesfully save draft campaign'
            : 'Succesfully trigger campaign'
        );
        queryClient.invalidateQueries(['bitcrm-campaigns', activeCompany]);

        if (payload.leaveCb) {
          payload.leaveCb();
        } else {
          history.push('/bitcrm/campaign');
        }
      },
      onError: (err: any, payload) => {
        toast.error(
          err.response?.data?.message ||
            (payload.isDraft
              ? 'Failed to save draft campaign'
              : 'Failed to trigger campaign')
        );
      },
    }
  );
};

export default useSendCampaign;
