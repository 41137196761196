export default function SoundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M10.998 5.02l.002.023v13.914c0 .01 0 .019-.002.024l-2.956-3.33-.014-.017A1.979 1.979 0 006.58 15H3.012A.064.064 0 013 14.959V9.04c0-.02.007-.033.012-.04H6.59a1.98 1.98 0 001.452-.642l2.956-3.339zM9.572 3.613C10.886 2.245 13 3.304 13 5.043v13.914c0 1.753-2.141 2.804-3.446 1.41a1.15 1.15 0 01-.018-.02L6.566 17H3c-1.142 0-2-.952-2-2.041V9.04C1 7.951 1.86 7 3 7h3.573l2.971-3.356a.988.988 0 01.028-.03zm8.705.695a1 1 0 011.414-.032c4.396 4.198 4.43 11.269-.005 15.45a1 1 0 01-1.372-1.455c3.59-3.384 3.575-9.13-.005-12.549a1 1 0 01-.032-1.414zM15.4 7.7a1 1 0 011.4.201c.836 1.115 1.2 2.655 1.2 4.1 0 1.444-.364 2.985-1.2 4.1a1 1 0 11-1.6-1.2c.497-.663.8-1.734.8-2.9s-.303-2.237-.8-2.9a1 1 0 01.2-1.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
