import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { GetCustomersParams } from '@/api/services/customer/customer';
import { CursoredResponse } from '@/api/types';
import { useAppSelector } from '@/hooks/rtk/store';
import { CustomerWithAssociation } from '@/types/customers/association';

const useInfiniteCustomers = (
  params?: Omit<GetCustomersParams, 'after' | 'before'>,
  options?: Omit<
    UseInfiniteQueryOptions<
      CursoredResponse<CustomerWithAssociation[]>,
      unknown,
      CursoredResponse<CustomerWithAssociation[]>,
      CursoredResponse<CustomerWithAssociation[]>,
      (
        | string
        | number
        | Omit<GetCustomersParams, 'after' | 'before'>
        | undefined
      )[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useInfiniteQuery(
    ['infinite-customers', activeCompany, params],
    ({ pageParam }) =>
      customerServices.customer.getCustomers(activeCompany!, {
        after: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.meta.hasNextPage ? lastPage.meta.endCursor : undefined,
      ...options,
    }
  );
};

export default useInfiniteCustomers;
