import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketBitCRM } from 'socket';

import { useAppSelector } from '@/hooks/rtk/store';
import { CountProgress } from '@/types/bitCRM/campaign';

const useCountProgressLive = (
  campaignIdFromParams: string,
  defaultSentAnalytic: number
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const [countLiveProgress, setCountLiveProgress] =
    useState<number>(defaultSentAnalytic);
  const client = useQueryClient();

  const receiveCampaignLiveProgress = useCallback(
    (data: CountProgress) =>
      data.campaignId === campaignIdFromParams &&
      setCountLiveProgress(data.progress),
    [campaignIdFromParams]
  );

  useEffect(() => {
    socketBitCRM.emit('join-campaign-progress', {
      companyId: activeCompany,
      campaignId: campaignIdFromParams,
    });
  }, [activeCompany, campaignIdFromParams]);

  useEffect(() => {
    socketBitCRM.on('campaign-progress-update', receiveCampaignLiveProgress);

    return () => {
      socketBitCRM.off('campaign-progress-update', receiveCampaignLiveProgress);
    };
  }, [activeCompany, client, receiveCampaignLiveProgress]);

  return countLiveProgress;
};

export default useCountProgressLive;
