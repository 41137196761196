import { useState } from 'react';

import { BBBModal } from '@/components/ui';
import { env } from '@/config/env';
import { MessageNewAssociation, OrderMessage } from '@/types/whatsApp/v3';
import { formatCurrency } from '@/utils/common/currency';
import { cn } from '@/utils/styles';

type ChatBubbleLocationProps = {
  chat: MessageNewAssociation;
};

function ChatBubbleOrder({ chat }: ChatBubbleLocationProps) {
  const estimatedTotal = chat.orderMsg?.reduce(
    (acc, curr) => acc + curr.orderTotal,
    0
  );

  return (
    <>
      <div
        className={cn(
          'flex items-center gap-4 p-1 rounded-md',
          chat.fromMe ? 'bg-secondary-border/80' : 'bg-[#ECECEC]'
        )}
      >
        <div className="flex flex-col gap-2">
          <div className="text-sm text-neutral-50 font-medium">
            Order via WhatsApp Catalog
          </div>
          <div className="flex items-center gap-2">
            <img
              className="rounded-md w-16 h-16 object-cover"
              src={
                chat.orderMsg?.[0]?.orderThumbnail ||
                `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
              }
              loading="lazy"
            />
            <div className="flex flex-col gap-1">
              <p className="text-primary-main text-sm">
                {chat.orderMsg?.length} item
              </p>
              <div className="text-sm text-gray-500">
                {formatCurrency(
                  estimatedTotal || 0,
                  chat.orderMsg?.[0].orderCurrency || 'IDR'
                )}{' '}
                (estimated total)
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ChatBubbleOrderDetailButton({ chat }: ChatBubbleLocationProps) {
  const [openModal, setOpenModal] = useState(false);
  const fromMe = chat.fromMe;

  return (
    <>
      <BBBModal
        show={openModal}
        onHide={() => setOpenModal(false)}
        title="Cart"
        subtitle="Cart that customer sent through WhatsApp Catalog"
        subtitleClassName="text-neutral-500"
      >
        <p className="text-primary-main font-medium my-5">
          {chat.orderMsg?.length} items
        </p>
        <div className="flex flex-col gap-5">
          {chat.orderMsg?.map((product) => (
            <ProductItem key={product.messageId} product={product} />
          ))}
        </div>
      </BBBModal>
      <div
        className={cn(
          'mt-2 border-t rounded-lg mb-1 flex-1 px-3 text-[#2B6AAF] flex gap-2 justify-center py-2 text-sm last:mb-0',
          !fromMe ? 'cursor-pointer pointer-events-auto' : 'pointer-events-none'
        )}
        onClick={() => setOpenModal(!openModal)}
      >
        <div className="line-clamp-1">View details</div>
      </div>
    </>
  );
}

function ProductItem({ product }: { product: OrderMessage }) {
  return (
    <div className="w-full flex items-center gap-2">
      <img
        className="rounded-md w-14 h-14 object-cover"
        src={
          product.orderThumbnail ||
          `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        loading="lazy"
      />
      <div className="w-full flex grow items-center justify-between">
        <div className="flex flex-col gap-1">
          <p className="text-primary-main line-clamp-1">{product.orderTitle}</p>
          <p className="text-sm text-neutral-500">
            Quantity: {product.orderItemsCount}
          </p>
        </div>
        <p className="text-primary-main">
          {formatCurrency(product.orderTotal, product.orderCurrency)}{' '}
        </p>
      </div>
    </div>
  );
}

export default ChatBubbleOrder;
