/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef } from 'react';
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
} from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import LivePreview from '../components/LivePreview';
import NavigatorTab from './components/NavigatorTab';
import Category from './Category';
import Layout from './Layout';
import Navigation from './Navigation';

import { BBBContainer } from '@/components/ui';
import scheduleCallUrl from '@/constants/bitApp/scheduleCallUrl';
import { useOnDragEnd } from '@/hooks/bitApp/design/section';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useHistory from '@/hooks/common/useHistory';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import {
  setActiveDesign,
  setActivePreview,
  setDraggingDraggable,
  setDraggingDroppable,
  setPlaceholderPosition,
  setPopup,
} from '@/stores/bitApp';

export default function Design() {
  const popup = useAppSelector((state) => state.bitApp.popup);
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  const destinationQuertAttr = 'data-rbd-droppable-id';

  const dispatch = useAppDispatch();

  const onDragEnd = useOnDragEnd();
  const isTablet = useResponsive('lg');

  const handleDragStart = (event: DragStart) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    // eslint-disable-next-line prefer-const
    let clientY =
      // @ts-expect-error
      parseFloat(window.getComputedStyle(draggedDOM?.parentNode).paddingTop) +
      // @ts-expect-error
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          // @ts-expect-error
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    dispatch(
      setPlaceholderPosition(
        // @ts-ignore
        {
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(
            // @ts-expect-error
            window.getComputedStyle(draggedDOM.parentNode).paddingLeft
          ),
          id: event.draggableId,
        } || null
      )
    );

    const {
      source: { droppableId },
      draggableId,
    } = event;
    dispatch(setDraggingDraggable(draggableId));
    dispatch(setDraggingDroppable(droppableId));
  };

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = draggedDOM.parentNode
      ? [...draggedDOM.parentNode.children]
      : [];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const droppedDom = getDestinationDom(event.destination.droppableId);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const destinationChildrenArray = droppedDom ? [...droppedDom.children] : [];
    let updatedArray;
    if (draggedDOM.parentNode === droppedDom) {
      updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ];
    } else {
      updatedArray = [
        ...destinationChildrenArray.slice(0, destinationIndex),
        movedItem,
        ...destinationChildrenArray.slice(destinationIndex + 1),
      ];
    }

    // eslint-disable-next-line prefer-const
    let clientY =
      // @ts-expect-error
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(1, destinationIndex).reduce((total, curr) => {
        // @ts-expect-error
        const style = curr?.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    dispatch(
      setPlaceholderPosition(
        // @ts-ignore
        {
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(
            // @ts-expect-error
            window.getComputedStyle(draggedDOM.parentNode).paddingLeft
          ),
          id: event.draggableId,
        } || null
      )
    );
  };

  const handleDragEnd = (event: DropResult) => {
    if (popup?.id) {
      dispatch(
        setPopup({
          ...popup,
          hasError: true,
          errorMessage:
            'Please save the changes before moving to another section',
        })
      );
      return;
    } else {
      onDragEnd(event);
    }
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const getDestinationDom = (dropabbleId: string) => {
    const domQuery = `[${destinationQuertAttr}='${dropabbleId}']`;
    const destinationDOm = document.querySelector(domQuery);
    return destinationDOm;
  };

  return (
    <>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <div className="flex h-full overflow-auto">
          {isTablet ? <MobileView /> : <DefaultView />}
        </div>
      </DragDropContext>
    </>
  );
}

function DefaultView() {
  const activeDesign = useAppSelector((state) => state.bitApp.activeDesign);

  const firstRender = useRef(true);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const toggleConfirmation = useConfirmationModal();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.get('from') === 'onboarding' && firstRender.current) {
      toggleConfirmation({
        description:
          'We will help you to build your app structure,until publish your app to the stores. You can book a call later',
        title: 'Need help to build your app?',
        onAccept: (hide) => {
          window.open(scheduleCallUrl, '_blank');
          hide();
        },
        onCancel: () => {
          queryParams.delete('from');
          history.replace({
            search: queryParams.toString(),
          });
        },
        submitText: 'Book a call',
        cancelText: 'Try it myself',
      });
      firstRender.current = false;
    }
  }, [history, search, toggleConfirmation]);

  useEffect(() => {
    dispatch(setActiveDesign('layout'));
    dispatch(setActivePreview('home'));
  }, [dispatch]);

  return (
    <>
      <div className="flex-initial h-full w-[45%] flex flex-col md:border-r-2">
        <div className="grow relative">
          <div className="absolute inset-0">
            <BBBContainer
              hasHeading
              pageTitle="Design"
              pageDescription="Customize your mobile app appearance or choose a preset for a quick setup"
            >
              <NavigatorTab />
              {activeDesign === 'layout' ? (
                <Layout />
              ) : activeDesign === 'category' ? (
                <Category />
              ) : (
                <Navigation />
              )}
            </BBBContainer>
          </div>
          <div
            id="bitapp-footer-container"
            className="absolute bottom-0 z-50 right-0 left-0"
          ></div>
        </div>
      </div>
      <LivePreview module="design" />
    </>
  );
}

function MobileView() {
  const activeDesign = useAppSelector((state) => state.bitApp.activeDesign);

  return (
    <div className="flex flex-col w-full bg-white">
      {activeDesign === 'layout' ? <Layout /> : <Navigation />}
      <NavigatorTab />
    </div>
  );
}
