import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

type OembedYoutube = {
  title: string;
  author_name: string;
  author_url: string;
  type: string;
  height: number;
  width: number;
  version: string;
  provider_name: string;
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
};

const useOembedYoutube = (params: { url: string; format?: 'json' }) => {
  return useQuery(['oembed-youtube', params], () =>
    axios
      .get<OembedYoutube>(`https://www.youtube.com/oembed`, {
        params: {
          url: params.url,
          format: params.format,
        },
      })
      .then((res) => res.data)
  );
};

export default useOembedYoutube;
