type Props = {
  color?: string;
  size?: number;
};

function SparklesIcon({ color = '#2080D1', size = 20 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_8122_67056)">
        <path
          fill={color}
          fillRule="evenodd"
          d="M4.165.833c.397 0 .739.28.817.669l.159.783a2.501 2.501 0 001.605 1.859l.2.072a.833.833 0 010 1.569l-.2.071a2.501 2.501 0 00-1.605 1.859l-.159.784a.833.833 0 01-1.633 0l-.159-.784a2.501 2.501 0 00-1.606-1.859l-.2-.071a.833.833 0 010-1.57l.2-.07a2.501 2.501 0 001.606-1.86l.159-.783a.833.833 0 01.816-.669zM3.397 5a4.167 4.167 0 01.768.838A4.167 4.167 0 014.934 5a4.166 4.166 0 01-.769-.837A4.165 4.165 0 013.397 5zm9.935-3.333c.394 0 .735.276.816.662l.463 2.212a5.833 5.833 0 003.801 4.317l1.026.355a.833.833 0 010 1.575l-1.026.355a5.834 5.834 0 00-3.801 4.316l-.463 2.212a.833.833 0 01-1.632 0l-.463-2.212a5.833 5.833 0 00-3.801-4.316l-1.026-.355a.833.833 0 010-1.575l1.026-.355a5.834 5.834 0 003.801-4.317l.463-2.212a.833.833 0 01.816-.662zM9.802 10a7.5 7.5 0 013.53 3.933A7.5 7.5 0 0116.862 10a7.501 7.501 0 01-3.53-3.932A7.499 7.499 0 019.802 10zm-3.137 2.5c.403 0 .749.289.82.686l.192 1.063a.836.836 0 00.508.626l.46.186a.833.833 0 010 1.545l-.46.186a.834.834 0 00-.509.625l-.19 1.064a.833.833 0 01-1.64 0l-.192-1.063a.835.835 0 00-.508-.626l-.46-.186a.833.833 0 010-1.545l.46-.186a.834.834 0 00.508-.625l.191-1.064a.833.833 0 01.82-.686zm0 3.304l.028.03-.028.03-.028-.03.029-.03z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8122_67056">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SparklesIcon;
