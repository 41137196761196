import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { Onboarding } from '@/types/bitLogin/v2';

const useUpdateOnboarding = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useMutation(
    async ({ moduleKey, ...payload }: Partial<Onboarding>) => {
      const {
        data: { data: responseData },
      } = await api.bitLogin.post(
        `/onboarding/${activeCompany}/${moduleKey}`,
        payload
      );
      return responseData;
    },
    {
      onMutate: async (data) => {
        await client.cancelQueries([
          'onboarding-bitlogin',
          activeCompany,
          data.moduleKey,
        ]);

        const previousOnboarding = client.getQueryData([
          'onboarding-bitlogin',
          activeCompany,
          data.moduleKey,
        ]);

        client.setQueryData<Partial<Onboarding>>(
          ['onboarding-bitlogin', activeCompany, data.moduleKey],
          (old) => ({
            ...old,
            ...data,
          })
        );

        return { previousOnboarding };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, payload, context) => {
        client.setQueryData(
          ['onboarding-bitlogin', activeCompany, payload.moduleKey],
          context?.previousOnboarding
        );
      },
      // Always refetch after error or success:
      onSettled: (_, __, payload) => {
        client.invalidateQueries([
          'onboarding-bitlogin',
          activeCompany,
          payload.moduleKey,
        ]);
      },
    }
  );
};
export default useUpdateOnboarding;
