import axios from 'axios';
import { toast } from './toast';

export function getUrlExtension(url: string): string;
export function getUrlExtension(url?: string): undefined;
export function getUrlExtension(url: string | undefined) {
  return url?.match(/\.(jpg|jpeg|png|gif)$/i)
    ? 'image'
    : url?.match(/^https?:\/\/.*[\\/][^?#]*\.([a-zA-Z0-9]+)\??#?/)?.[1];
}

export function isImage(url: string | undefined) {
  return url?.match(/\.(jpg|jpeg|png|gif)$/i);
}

export function fileNameFromUrl(url: string): string;
export function fileNameFromUrl(url?: string): string | undefined;
export function fileNameFromUrl(url: string | undefined) {
  return url?.split('/').pop();
}

export const kebabCase = (string1: string) => {
  return string1
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

export function humanFileSize(bytes: number | undefined, si = false, dp = 1) {
  if (!bytes) return;

  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function downloadXlsxFile(
  data: BlobPart,
  fileName: string,
  action?: () => void
) {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
  toast.success(`Your ${fileName} has been exported`);
  action?.();
}

export async function getMimeType(url: string) {
  const response = await axios.head(url);
  const mimeType = response.headers['content-type'];
  return mimeType;
}

export const sanitizeFileName = (fileName: string) => {
  const lastIndexDot = fileName.lastIndexOf('.');
  const fileNameWithoutExt = fileName.substring(0, lastIndexDot) ?? fileName;
  const extension = fileName.substring(lastIndexDot + 1);

  return `${fileNameWithoutExt.replace(/[^a-zA-Z0-9 ]/g, '')}-${Math.floor(
    1000 + Math.random() * 9000
  )}.${extension}`;
};
