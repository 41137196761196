import { Control, Controller } from 'react-hook-form';
import { FormSchema } from '..';

import { BBBCard } from '@/components/ui/BBBCard';
import { BBBSelect } from '@/components/ui/BBBSelect';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

type Props = {
  control: Control<FormSchema>;
  loading: boolean;
};

export const optionsPage = [
  'Home page',
  'Products',
  'Collections list',
  'Cart',
  'Checkout',
  'Thank you page',
  'Account',
  'Pages',
  'Blogs',
  'Blog posts',
];

export const optionsPageLists = optionsPage.map((page) => ({
  label: page,
  value: page,
}));

export const optionsPageListsHash = Object.fromEntries(
  optionsPageLists.map((page) => [page.value, page])
);

export default function HideWidget(props: Props) {
  const { data: shopifyData } = useShopifyIntegrationByApp('BITCHAT');

  if (!shopifyData) return null;

  return <_HideWidget {...props} />;
}

const categories = ['Desktop', 'Mobile'];

function _HideWidget({ control }: Props) {
  const options: {
    label: string;
    value: string;
    parentValue?: string;
    label2?: string;
  }[] = optionsPage
    ? [
        ...categories.map((category) => ({
          label: category,
          value: category,
        })),
        ...optionsPage.map((page) => ({
          label: page,
          label2: `Desktop: ${page}`,
          value: page,
          parentValue: 'Desktop',
        })),
        ...optionsPage.map((page) => ({
          label: page,
          label2: `Mobile: ${page}`,
          value: page,
          parentValue: 'Mobile',
        })),
      ]
    : [];

  return (
    <BBBCard title="Hide widget on specific pages" className="mb-cardBottom">
      <Controller
        control={control}
        name="hiddenPage"
        render={({ field }) => {
          const sortedData = field.value.sort((a, b) => {
            const label2A = a.label2.toLowerCase();
            const label2B = b.label2.toLowerCase();

            const isDesktopA = label2A.startsWith('desktop');
            const isDesktopB = label2B.startsWith('desktop');

            if (isDesktopA && !isDesktopB) {
              return -1;
            } else if (!isDesktopA && isDesktopB) {
              return 1;
            }

            return 0;
          });

          return (
            <>
              <BBBSelect
                options={options}
                optionLabel="label"
                optionValue="value"
                selectedLabel="label2"
                isGrouped
                isMulti
                optionGroupKey="parentValue"
                containerClassName="mb-5"
                placeholder="Choose page"
                value={sortedData}
                onValueChange={field.onChange}
                enableToggleOption
                persistShowOptionsOnClick
              />
            </>
          );
        }}
      />
      <div className="text-neutral-50">
        Choose page name from the dropdown if you want to hide chat widget to
        specific pages, leave it blank if you want to show widget for all pages.
      </div>
    </BBBCard>
  );
}
