import { useMutation, useQueryClient } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { UpsertCustomerPayload } from '@/api/services/customer/customer';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';
import { formatDisplayName } from '@/utils/customers';

export const useCreateCustomer = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpsertCustomerPayload) =>
      customerServices.customer.upsertCustomer(activeCompany!, payload),
    {
      onSuccess: (_data, payload) => {
        queryClient.invalidateQueries(['customers']);
        queryClient.invalidateQueries(['infinite-customers']);
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['customer-phonenumber']);
        queryClient.invalidateQueries(['segments']);
        if (payload.type === 'bulk_file') {
          toast.success(`Upload file request has been processed`);
        } else if (payload.type === 'individual') {
          toast.success(
            `${formatDisplayName(
              payload.phone,
              payload.firstName,
              payload.lastName
            )} was added`
          );
        }
      },
    }
  );
};

export const useUpdateNotes = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { id: string; notes: string }) =>
      customerServices.customer.upsertCustomer(activeCompany!, {
        type: 'individual',
        id: payload.id,
        notes: payload.notes,
      }),
    {
      onSuccess: (_data, payload) => {
        queryClient.invalidateQueries(['customers']);
        queryClient.invalidateQueries(['infinite-customers']);
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['customer-phonenumber']);
        queryClient.invalidateQueries(['segments']);

        toast.success(`Notes updated`);
      },
    }
  );
};
