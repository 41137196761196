import React, { useEffect, useRef, useState } from 'react';
import { Camera, File as FileIcon, Paperclip } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { hideCoPilotSelector } from '../ChatBoard/CoPilot/CoPilot';
import EmojiPicker from '../EmojiPicker';
import RecommendationProduct from '../RecommendationProduct';
import { acceptedFiles, acceptedMedias } from '..';

import FormIcon from '@/assets/icons/FormIcon';
import SparklesIcon from '@/assets/icons/SparklesIcon';
import {
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import { BBBCard } from '@/components/ui/BBBCard';
import { useUploadFiles } from '@/hooks/common/upload';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { useForms } from '@/hooks/whatsApp/form';
import { activeMessageSelector } from '@/hooks/whatsApp/useActiveChatMemo';
import useSendMessage from '@/hooks/whatsApp/useSendMessage';
import { upsertLivechatMemo } from '@/stores/bitCRM';
import { FormTemplate } from '@/types/whatsApp/form';
import { scrollToBottom } from '@/utils/bitChat';
import { convertEditorStateToHtml, emptyEditor } from '@/utils/common/rich';

type Props = {
  setCoPilotQuickActionRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null | undefined>
  >;
};

export default function QuickAction({ setCoPilotQuickActionRef }: Props) {
  const activeMessages = useAppSelector(activeMessageSelector);
  const hideCopilot = useAppSelector(hideCoPilotSelector);
  const source = useAppSelector((s) => s.bitCRM.selectedChat!.sources);

  const dispatch = useAppDispatch();
  const actionButtonRefs = useRef<HTMLDivElement>(null);

  const inputFileDocs = useRef<HTMLInputElement | null>(null);
  const inputFileMedias = useRef<HTMLInputElement | null>(null);

  const [active, setActive] = useState(false);

  useOutsideAlerterv2(actionButtonRefs, () => {
    setActive(false);
  });

  const uploadFiles = useUploadFiles();

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await uploadFiles(e.target.files as unknown as File[], {
        defaultSelectAdded: true,
      });
    } finally {
      e.target.value = '';
    }
  };

  const [isAnimating, setIsAnimating] = useState<'p1' | 'p2'>();

  useEffect(() => {
    if (hideCopilot) {
      setIsAnimating('p1');

      setTimeout(() => {
        setIsAnimating('p2');
      }, 150);

      setTimeout(() => {
        setIsAnimating(undefined);
      }, 300);
    }
  }, [hideCopilot]);

  return (
    <div className="flex justify-end gap-4">
      {source === 'WHATSAPP_META' && <Form />}
      <RecommendationProduct />
      <EmojiPicker
        editorState={activeMessages}
        onChangeEditorState={(val) =>
          dispatch(
            upsertLivechatMemo({
              messageToSend: val,
            })
          )
        }
      />
      <div className="relative">
        <BBBCard
          className={cx(
            'border-none overflow-auto shadow p-0 md:p-0 absolute z-10 bottom-[150%] transform w-[300px] opacity-0 py-4 px-4 transition-opacity',
            active ? 'opacity-100 pointer-events-auto' : 'pointer-events-none'
          )}
          ref={actionButtonRefs}
          id="action-btns"
        >
          {source !== 'GOOGLE_MY_BUSINESS' && (
            <div
              className="flex p-2 hover:bg-gray-100 items-center gap-3 mb-2 cursor-pointer"
              onClick={() => {
                inputFileDocs.current?.click();
                setActive(false);
              }}
            >
              <div className="bg-gray-300 rounded-full p-2">
                <FileIcon size={20} color="white" />
              </div>
              <div>Document</div>
              <input
                type="file"
                id="document-file"
                ref={inputFileDocs}
                className="hidden"
                onChange={(e) => handleChangeFile(e)}
                accept={acceptedFiles}
                multiple
              />
            </div>
          )}
          <div
            className="flex p-2 hover:bg-gray-100 items-center gap-3 cursor-pointer"
            onClick={() => {
              inputFileMedias.current?.click();
              setActive(false);
            }}
          >
            <div className="bg-gray-300 rounded-full p-2">
              <Camera size={20} color="white" />
            </div>
            <div>Photos and Videos</div>
          </div>
          <input
            type="file"
            id="media-file"
            ref={inputFileMedias}
            className="hidden"
            onChange={(e) => handleChangeFile(e)}
            accept={acceptedMedias}
            multiple
          />
        </BBBCard>
        <Paperclip
          size={18}
          className="cursor-pointer text-neutral-50"
          onClick={() => setActive((prev) => !prev)}
        />
      </div>
      <motion.div
        onClick={() => {
          dispatch(upsertLivechatMemo({ hideCopilot: false }));
          scrollToBottom(500);
        }}
        className={cx('cursor-pointer', !hideCopilot && 'pointer-events-none')}
        id="copilot-quick-action"
        animate={{
          rotate:
            isAnimating === 'p1'
              ? -12.5
              : isAnimating === 'p2'
              ? 12.5
              : undefined,
          width: isAnimating ? 25 : undefined,
          x: isAnimating ? -5 : undefined,
          y: isAnimating ? -5 : undefined,
        }}
        transition={{
          duration: 0.3,
        }}
        ref={(ref) => setCoPilotQuickActionRef?.(ref)}
      >
        <SparklesIcon color={hideCopilot ? '#FD823E' : '#757575'} />
      </motion.div>
    </div>
  );
}

const formSchema = yup.object().shape({
  title: yup.string(),
  buttonText: yup.string().required().label('Button text'),
  form: yup.mixed<FormTemplate | null>().required().label('Form'),
  body: yup
    .mixed<EditorState>()
    .test(
      'required-body',
      'Body is required',
      (val) => !!val?.getCurrentContent().getPlainText(' ').length
    ),
});

function Form() {
  const [show, setShow] = useState(false);

  return (
    <>
      <FormIcon onClick={() => setShow(true)} className="cursor-pointer" />
      {show && <FormModal onHide={() => setShow(false)} />}
    </>
  );
}

function FormModal({ onHide }: { onHide: () => void }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    form: FormTemplate | null;
    title: string;
    body: EditorState;
    buttonText: string;
  }>({
    defaultValues: {
      title: '',
      body: emptyEditor,
      buttonText: '',
      form: null,
    },
    resolver: yupResolver(formSchema),
  });

  const { mutate: sendMessage, isLoading: loadingSendMessage } =
    useSendMessage();

  const { data } = useForms({ status: 'PUBLISHED' });

  return (
    <BBBModal
      title="Send a form"
      show
      onHide={onHide}
      footer
      submitText="Send form"
      handleSave={() =>
        handleSubmit(({ form, body, ...data }) => {
          const formPayload = {
            ...data,
            id: form!.metaId,
            body: convertEditorStateToHtml(body)!,
          };

          sendMessage(
            { type: 'chat', form: formPayload, temporaryId: v4() },
            {
              onSuccess: () => {
                onHide();
              },
            }
          );
        })()
      }
      loadingSave={loadingSendMessage}
    >
      <Controller
        control={control}
        name="form"
        render={({ field }) => (
          <BBBSelect
            label="Select form"
            placeholder="Choose form"
            containerClassName="mb-5"
            options={data}
            optionLabel="name"
            optionValue="id"
            value={field.value}
            onValueChange={field.onChange}
            error={errors.form?.message}
          />
        )}
      />
      <BBBTextInput
        label="Message title (optional)"
        placeholder="Message title"
        className="mb-5"
        isHookForm
        control={control}
        controlName="title"
        hasMaxCharLabel
        maxChar={60}
      />
      <Controller
        control={control}
        name="body"
        render={({ field }) => (
          <BBBRichTextEditor
            label="Message body"
            placeholder="Message body"
            containerClassName="mb-5"
            editorState={field.value}
            onChangeEditorState={field.onChange}
            error={errors.body?.message}
          />
        )}
      />
      <BBBTextInput
        label="Button text"
        placeholder="Message body"
        isHookForm
        control={control}
        controlName="buttonText"
        error={errors.buttonText?.message}
        hasMaxCharLabel
        maxChar={20}
      />
    </BBBModal>
  );
}
