import Skeleton from 'react-loading-skeleton';
import { parsePhoneNumber } from 'libphonenumber-js';
import { twMerge as cx } from 'tailwind-merge';

import { BBBRenderHtml } from '@/components/ui';
import { env } from '@/config/env';
import useCollection from '@/hooks/bitLink/shopify/useCollection';
import useProduct from '@/hooks/bitLink/shopify/useProduct';
import { Linklists } from '@/types/bitLink/v2';

type Props = {
  type: Linklists['type'];
  productId: Linklists['items'];
  textPrimary: Linklists['textPrimary'];
  textSecondary?: Linklists['textSecondary'];
};

export default function Text({
  type,
  textPrimary,
  textSecondary,
  productId: _productId,
}: Props) {
  const collectionId = _productId;
  const productIds = _productId?.split(',');

  const renderTextSecondary = () => {
    if (type === 'youtube') {
      return textPrimary;
    }
    if (type === 'divider') {
      return textPrimary === 'blank'
        ? 'Blank Space'
        : textPrimary === 'solid'
        ? 'Solid Line'
        : 'Dashed Line';
    }
    if (type === 'whatsapp') {
      return parsePhoneNumber(`+${textSecondary}`).formatInternational();
    }
    return textSecondary;
  };

  const renderTextPrimary = () => {
    if (type === 'text') {
      return (
        <div className="line-clamp-1">
          <BBBRenderHtml
            content={
              textPrimary?.length && textPrimary?.length > 90
                ? textPrimary?.substring(0, 90) + '...'
                : textPrimary
            }
          />
        </div>
      );
    }

    if (type === 'divider') {
      return 'Divider';
    }

    if (type === 'image') {
      if (textPrimary) {
        return textPrimary;
      }
      return 'Image';
    }

    if (type !== 'youtube') {
      return textPrimary;
    }

    if (type === 'youtube') {
      if (textSecondary) {
        return textSecondary;
      }
      return 'Youtube';
    }
    return undefined;
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-primary-main font-medium">{renderTextPrimary()}</div>
      {type === 'shop' && (
        <div className="flex gap-1.5">
          {textSecondary === 'product' ? (
            productIds?.map((productId) => (
              <Product
                key={productId}
                productId={productId}
                type={textSecondary}
              />
            ))
          ) : (
            <Collection collectionId={collectionId} type={textSecondary} />
          )}
        </div>
      )}
      {type !== 'text' && type !== 'shop' && (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
          }}
          className={cx(
            (type === 'link' || type === 'image' || type === 'youtube') &&
              'text-info-main'
          )}
        >
          {renderTextSecondary()}
        </div>
      )}
    </div>
  );
}

function Product({
  productId,
  type,
}: {
  productId: string | undefined;
  type: Props['textSecondary'];
}) {
  const { data: productData, isInitialLoading: isLoadingProduct } = useProduct(
    productId,
    {
      enabled: type === 'product',
    }
  );

  return (
    <>
      {isLoadingProduct ? (
        Array.from({ length: 1 }).map((_, index) => (
          <Skeleton key={index} width={56} height={56} />
        ))
      ) : (
        <img
          src={
            productData?.featuredImage?.url
              ? productData?.featuredImage?.url
              : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
          }
          alt={productData?.title}
          className="rounded-md border border-neutral-30 object-cover w-14 h-14 max-w-none"
          loading="lazy"
        />
      )}
    </>
  );
}

function Collection({
  collectionId,
  type,
}: {
  collectionId: string | undefined | null;
  type: Props['textSecondary'];
}) {
  const { data: collectionData, isInitialLoading: isLoadingCollection } =
    useCollection(collectionId || '', {
      enabled: type === 'collection',
    });

  const collectionDataFiltered = collectionData?.products?.edges?.filter(
    (item) => item.node?.status === 'ACTIVE'
  );

  return (
    <>
      {isLoadingCollection
        ? Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} width={56} height={56} />
          ))
        : collectionDataFiltered?.map((item) => (
            <img
              key={item?.node?.id}
              src={
                item?.node?.featuredImage?.url
                  ? item?.node?.featuredImage?.url
                  : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
              }
              alt={item?.node?.title}
              className="rounded-md border border-neutral-30 object-cover w-14 h-14 max-w-none"
              loading="lazy"
            />
          ))}
    </>
  );
}
