function DoubleCheckIcon({
  width = 17,
  height = 10,
  color,
}: {
  width?: number;
  height?: number;
  color: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85938 5.60938L4.51562 8.26562M8.23438 4.01562L10.8906 1.35938M6.10938 5.60938L8.76562 8.26562L15.1406 1.35938"
        stroke={color}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DoubleCheckIcon;
