import React from 'react';

type Props = {
  color?: string;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
function StarIcon2({ size = 12, color = '#9E9E9E', ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <g fill={color} clipPath="url(#clip0_4437_7539)">
        <path d="M5.537 1.317a.497.497 0 01.926 0l1.035 2.867a.495.495 0 00.463.316h2.544c.47 0 .675.585.305.872L9 7a.484.484 0 00-.16.546l.66 2.801c.161.45-.36.837-.754.56l-2.458-1.56a.5.5 0 00-.575 0l-2.459 1.56c-.393.277-.915-.11-.754-.56l.661-2.801A.484.484 0 003.001 7L1.19 5.372c-.37-.287-.165-.872.304-.872H4.04a.495.495 0 00.463-.316l1.035-2.868"></path>
        <path
          fillRule="evenodd"
          d="M5.999 1.507L4.97 4.353l-.004.01a.995.995 0 01-.93.637h-.001l.002-.5V5H1.524a.508.508 0 00-.032-.027l.001.002.001.002a.038.038 0 01.004.009.023.023 0 010 .008.016.016 0 01-.004.004.024.024 0 01-.006.002h.036l1.798 1.618a.941.941 0 00-.018-.014L2.999 7l.335-.372-.012-.01a.984.984 0 01.32 1.065l.005-.022-.487-.115.471.168a.962.962 0 00.01-.031l-.655 2.78a.504.504 0 01-.007.026l-.013.009-.002-.002.001.002-.004.003h.005l.001.001a.04.04 0 01.002.013v.001l.004-.008a.531.531 0 00.006-.019.206.206 0 01.006-.004l2.45-1.555a1.214 1.214 0 00-.01.008l.287.409-.268-.422-.009.005a1 1 0 011.128 0l-.009-.005-.267.422.287-.41a1.094 1.094 0 00-.011-.007l2.45 1.555a.217.217 0 01.007.004.556.556 0 00.01.03v-.003a.041.041 0 01.002-.016.02.02 0 01.005 0l.001.001s-.002 0-.005-.003l.001-.001-.001.001a.606.606 0 00-.013-.009.513.513 0 01-.008-.027l-.655-2.78.01.032.471-.168-.487.115.006.022a.984.984 0 01.32-1.065l-.012.01.334.372-.305-.396a.934.934 0 00-.017.014L10.474 5H10.51a.024.024 0 01-.005-.003h-.001l-.004-.003.001-.009.004-.008v-.002c.003-.003.003-.002 0 0l-.001.001a.538.538 0 00-.029.024H7.961v-.5.5M5.999 1.507v-.001zm-4.5 3.486zm3.58-3.881a.506.506 0 00-.014.035L4.035 4h-2.54C.571 4 .13 5.161.865 5.754l1.799 1.618a.494.494 0 00.02.017.495.495 0 00-.012.042l-.655 2.778c-.3.916.75 1.636 1.512 1.115l2.45-1.555A.495.495 0 006 9.756a.503.503 0 00.019.013l2.45 1.555c.761.52 1.81-.198 1.511-1.115l-.655-2.778a.502.502 0 00-.012-.042.507.507 0 00.02-.017l1.799-1.618c.735-.592.296-1.754-.628-1.754H7.962l-1.03-2.853-.003-.008C6.61.297 5.42.286 5.08 1.112z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4437_7539">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarIcon2;
