export default function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        fill="#9E9E9E"
        fillRule="evenodd"
        d="M5 1.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM.417 5.5a4.583 4.583 0 119.166 0 4.583 4.583 0 01-9.166 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#9E9E9E"
        fillRule="evenodd"
        d="M4.764 3.898c-.059.046-.13.136-.225.325a.417.417 0 01-.744-.376c.113-.223.25-.443.452-.603A1.17 1.17 0 015 3c.778 0 1.25.628 1.25 1.24 0 .297-.082.549-.28.784a2.798 2.798 0 01-.553.469v.424a.417.417 0 01-.834 0v-.87l.188-.123c.316-.21.475-.334.561-.437.062-.073.085-.13.085-.246A.402.402 0 005 3.833c-.132 0-.192.03-.236.065zM5 6.958c.23 0 .417.187.417.417v.208a.417.417 0 01-.834 0v-.208c0-.23.187-.417.417-.417z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
