import { ReactNode, useEffect, useRef } from 'react';
import { Plus, PlusCircle } from 'react-feather';
import { EditorState } from 'draft-js';
import isEqual from 'lodash-es/isEqual';

import {
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components/ui';
import { useParametersOptions } from '@/hooks/bitCRM/template/template';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';
import DynamicVariableLink from '@/pages/BitCRM/Campaign/components/DynamicVariableLink';
import { TemplateBodyParameters } from '@/types/bitCRM/template';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
  insertText,
} from '@/utils/common/rich';

type Props = {
  editorState?: EditorState;
  onChangeEditorState: (val: EditorState) => void;
  placeholder?: string;
  title?: ReactNode;
  source?: 'whatsapp-cloud' | 'whatsapp-business';
  params?: TemplateBodyParameters[];
  onChangeParams?: (
    val: SingleOnValueChangeParam<TemplateBodyParameters>[]
  ) => void;
  onChange?: (val: {
    params: SingleOnValueChangeParam<TemplateBodyParameters>[];
    editorState: EditorState;
  }) => void;
  withoutDynamicVariable?: boolean;
  error?: string;
  type?: 'campaign' | 'automation' | 'chat' | 'bitlogin';
  paramErrors?: string[];
};

export default function CampaignMessage({
  editorState = emptyEditor,
  onChangeEditorState,
  placeholder,
  title = 'Message',
  source,
  params,
  onChangeParams,
  withoutDynamicVariable,
  error,
  type,
  paramErrors,
  onChange,
}: Props) {
  const htmlMessage = convertEditorStateToHtml(editorState);

  const ref = useRef<Draft.DraftComponent.Base.DraftEditor | null>(null);

  const parameterOptions = useParametersOptions(type);

  const convertedParamsFromEditor =
    convertEditorStateToHtml(editorState)?.match(/{{[0-9]}}/gm);

  const handleAppendParam = () => {
    const textToInsert = `{{${(convertedParamsFromEditor?.length ?? 0) + 1}}}`;

    onChangeEditorState(insertText(editorState, textToInsert));
  };

  const removeParam = (param: string, index: number) => {
    const match = htmlMessage?.match(/{{[0-9]}}/gm);
    if (!match) return;

    // find the params inside the editor and if match then remove it
    const newHtmlMessage = match.reduce((acc, item) => {
      if (item === param) return acc?.replace(item, '');
      return acc;
    }, htmlMessage);

    onChange?.({
      params: params?.filter((_, i) => i !== index) || [],
      editorState: EditorState.moveFocusToEnd(
        convertHtmlToEditorState(newHtmlMessage)
      ),
    });
  };

  useEffect(() => {
    if (source === 'whatsapp-cloud') {
      let counter = -1;
      const replaced = htmlMessage?.replaceAll(/{{[0-9]}}/gm, () => {
        counter += 1;
        return `{{${counter + 1}}}`;
      });

      if (counter >= 0) {
        if (replaced) {
          if (htmlMessage === replaced) {
            // do nothing
          } else {
            onChangeEditorState?.(
              EditorState.moveFocusToEnd(convertHtmlToEditorState(replaced))
            );
          }
        }
      }
    }
  }, [htmlMessage, onChangeEditorState, source]);

  const prevParamsRef = useRef<any[]>();

  useEffect(() => {
    const newParams =
      convertedParamsFromEditor?.map((_, index) => params?.[index]) || [];

    if (!isEqual(newParams, prevParamsRef.current)) {
      onChangeParams?.(newParams);
      prevParamsRef.current = newParams;
    }
  }, [convertedParamsFromEditor, onChangeParams, params]);

  return (
    <>
      <div id="template-body-message">
        <div className="flex items-end mb-1">
          {withoutDynamicVariable ? (
            <div className="grow">{title}</div>
          ) : (
            <>
              {source === 'whatsapp-cloud' && (
                <>
                  <div className="grow">{title}</div>
                  <div
                    className="text-link flex items-center gap-1 cursor-pointer"
                    onClick={() => handleAppendParam()}
                  >
                    Add variable{' '}
                    <span>
                      <Plus size={16} />
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <BBBRichTextEditor
          editorState={editorState}
          onChangeEditorState={onChangeEditorState}
          placeholder={
            type === 'bitlogin' ? 'ie: This is your code' : placeholder
          }
          error={error}
          ref={ref}
        />

        {source === 'whatsapp-cloud' && type === 'bitlogin' && (
          <div className="flex">
            <div
              className="flex items-center gap-1 cursor-pointer"
              onClick={() => {
                onChangeEditorState(insertText(editorState, '{{otp.code}}'));
              }}
            >
              <PlusCircle size={12} />
              <p className="text-neutral-900 text-sm underline">
                Add dynamic OTP
              </p>
            </div>
          </div>
        )}
        {source === 'whatsapp-cloud' && (
          <div className="flex flex-col gap-2 mb-5">
            {convertedParamsFromEditor?.map((_, index) => {
              return (
                <div key={index} className="flex gap-2 justify-center">
                  <div className="w-fit flex-none cursor-pointer">
                    <BBBTextInput
                      disabled
                      readOnly
                      value={`{{${index + 1}}}`}
                      label="Parameter"
                      containerClassname="w-20 mb-0"
                      className="opacity-100"
                    />
                  </div>
                  <div className="grow flex-1 w-full">
                    <BBBSelect
                      options={parameterOptions}
                      placeholder="Select variable"
                      onValueChange={(val) => {
                        onChangeParams?.(
                          convertedParamsFromEditor?.map(
                            (_param, paramIndex) => {
                              if (paramIndex === index) return val;
                              return params?.[paramIndex];
                            }
                          ) ?? []
                        );
                      }}
                      value={params?.[index]}
                      label="Variable"
                      optionLabel="label"
                      optionValue="value"
                      error={paramErrors?.[index]}
                    />
                  </div>
                  <div className="flex-none">
                    <TrashWithTransition
                      className="mt-4"
                      onClick={() => removeParam(`{{${index + 1}}}`, index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!withoutDynamicVariable && source === 'whatsapp-business' && (
          <div className="flex justify-end">
            <DynamicVariableLink />
          </div>
        )}
      </div>
    </>
  );
}
