import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { triggerRefreshIframe } from '@/stores/bitLink';
import { SocialMedia, SocialMediaKind } from '@/types/bitLink/v2';

const useUpdateSocialMedia = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async ({
      username,
      active,
      media,
    }: {
      username: string;
      active: boolean;
      media: number;
    }) => {
      const { data } = await api.bitLink.post(`/sosmed/${activeCompany}`, {
        username,
        active,
        media,
      });
      return data;
    },
    {
      onMutate: async (payload) => {
        await client.cancelQueries(['bitlink-social', activeCompany]);

        const previousData = client.getQueryData([
          'bitlink-social',
          activeCompany,
        ]);

        client.setQueryData(
          ['bitlink-social', activeCompany],
          (
            old:
              | (SocialMedia & {
                  socialMediaKind: SocialMediaKind;
                })[]
              | undefined
          ) =>
            (old || []).map((social) => {
              if (social.socialMediaKindId !== payload?.media) {
                return social;
              }
              return {
                ...social,
                ...payload,
              };
            })
        );

        return { previousData };
      },
      onError: (err, newShopData, context: any) => {
        client.setQueryData(
          ['bitlink-social', activeCompany],
          context.previousData
        );
      },
      onSettled: () => {
        client.invalidateQueries(['bitlink-social', activeCompany]);
        dispatch(triggerRefreshIframe());
      },
    }
  );
};

const useUpdateSocialMediaPosition = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async (payload: { socialMediaKindId: number; position: number }[]) => {
      const { data } = await api.bitLink.put(
        `/sosmed/${activeCompany}/rearrange`,
        payload
      );
      return data;
    },
    {
      onMutate: async (payload) => {
        await client.cancelQueries(['bitlink-social', activeCompany]);

        const previousData = client.getQueryData([
          'bitlink-social',
          activeCompany,
        ]);

        client.setQueryData(
          ['bitlink-social', activeCompany],
          (
            old:
              | (SocialMedia & {
                  socialMediaKind: SocialMediaKind;
                })[]
              | undefined
          ) =>
            (old || []).map((social) => ({
              ...social,
              position:
                payload.find(
                  (newData) =>
                    newData.socialMediaKindId === social.socialMediaKindId
                )?.position ?? -1,
            }))
        );

        return { previousData };
      },
      onError: (err, newShopData, context: any) => {
        client.setQueryData(
          ['bitlink-social', activeCompany],
          context.previousData
        );
      },
      onSettled: () => {
        client.invalidateQueries(['bitlink-social', activeCompany]);
        dispatch(triggerRefreshIframe());
      },
    }
  );
};

export default useUpdateSocialMedia;
export { useUpdateSocialMediaPosition };
