import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { socketWhatsApp } from 'socket';

import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { useReceiveMessage } from '@/hooks/whatsApp/realtime-v2';
import { ChatMessagesType } from '@/hooks/whatsApp/useChatMessages';
import useChatMessagesQueryKey from '@/hooks/whatsApp/useChatMessagesQueryKey';
import { useTicketByChatQueryKeys } from '@/hooks/whatsApp/useTicketByChat';
import { updateTypingStatus } from '@/stores/bitCRM';
import { Chat } from '@/types/whatsApp/chat';
import { Ticket } from '@/types/whatsApp/ticket';

dayjs.extend(isSameOrBefore);

const useRealtimeEvent = () => {
  const receiveChat = useReceiveMessage();
  const activeCompany = useActiveCompany();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const messagesQueryKey = useChatMessagesQueryKey();
  const ticketChatQueryKey = useTicketByChatQueryKeys();

  const onReceiveACK = useCallback(
    (
      ackData: {
        messageId: string;
        messageIdNew: string;
        ack: number;
        clientNumber: string;
        sources: Chat['sources'];
      } & {
        __typename: 'ackMessageV2';
      }
    ) => {
      queryClient.setQueryData<ChatMessagesType>(messagesQueryKey, (old) => {
        if (!old) return;

        const previousMessages = old.pages.map((page) => {
          return {
            ...page,
            response: page.response.map((message) => {
              if (
                message.messageId2 === ackData.messageIdNew ||
                message.messageId === ackData.messageIdNew
              ) {
                return {
                  ...message,
                  ack: ackData.ack,
                };
              }
              return message;
            }),
          };
        });

        return {
          pages: previousMessages,
          pageParams: old.pageParams,
        };
      });
    },
    [messagesQueryKey, queryClient]
  );

  const onUserTyping = useCallback(
    (payload: {
      clientNumber: string;
      source: Chat['sources'];
      isTyping: boolean;
    }) => {
      dispatch(updateTypingStatus(payload));
    },
    [dispatch]
  );

  const onMessageDelete = useCallback(
    (payload: { companyId: number; messageId: string }) => {
      queryClient.setQueryData<ChatMessagesType>(messagesQueryKey, (old) => {
        if (old) {
          return {
            ...old,
            pages: old.pages.map((page) => {
              if (
                page.response.some((msg) => msg.messageId === payload.messageId)
              ) {
                return {
                  ...page,
                  response: page.response.map((msg) => {
                    const msgId = msg.messageId;

                    if (msgId === payload.messageId) {
                      return { ...msg, type: 'REVOKED' };
                    }

                    return msg;
                  }),
                };
              }

              return page;
            }),
          };
        }
      });
    },
    [messagesQueryKey, queryClient]
  );

  const onTicketUpdate = useCallback(
    async ({
      data,
    }: {
      clientNumber: string;
      source: Chat['sources'];
      data: Ticket | null;
    }) => {
      queryClient.setQueryData(ticketChatQueryKey, (old) =>
        !data ? null : { ...(old || {}), ...data }
      );
    },
    [queryClient, ticketChatQueryKey]
  );

  useEffect(() => {
    socketWhatsApp.emit('join-room', {
      companyId: activeCompany,
    });
  }, [activeCompany]);

  useEffect(() => {
    socketWhatsApp.on(`new-message-v3-${activeCompany}`, receiveChat);
    return () => {
      socketWhatsApp.off(`new-message-v3-${activeCompany}`, receiveChat);
    };
  }, [activeCompany, receiveChat]);

  useEffect(() => {
    socketWhatsApp.on(`message-ack-v2`, onReceiveACK);
    return () => {
      socketWhatsApp.off(`message-ack-v2`, onReceiveACK);
    };
  }, [onReceiveACK]);

  useEffect(() => {
    socketWhatsApp.on(`user-typing`, onUserTyping);
    return () => {
      socketWhatsApp.off(`user-typing`, onUserTyping);
    };
  }, [onUserTyping]);

  useEffect(() => {
    socketWhatsApp.on('message-deleted-v2', onMessageDelete);

    return () => {
      socketWhatsApp.off('message-deleted-v2', onMessageDelete);
    };
  }, [onMessageDelete]);

  useEffect(() => {
    socketWhatsApp.on('ticket-update-v2', onTicketUpdate);

    return () => {
      socketWhatsApp.off('ticket-update-v2', onTicketUpdate);
    };
  }, [onTicketUpdate]);
};

export default useRealtimeEvent;
