import { UserStore } from '@/stores/auth';
import { UserWithAssociation } from '@/types/systemLogin/association';
import { _localStorage } from '@/utils/common/localStorage';

export const formatUserDisplayName = (
  user: UserStore | UserWithAssociation
) => {
  return user.profile?.firstName || user.profile?.lastName
    ? `${user.profile.firstName} ${user.profile.lastName}`
    : user.email;
};

export const removeUserSession = () => {
  _localStorage.removeItem('accessToken');
  _localStorage.removeItem('activeCompany');
};
