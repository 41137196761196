import { formatInternational } from '@/utils/common/phone';

type Props = {
  phoneNumber: string;
  from?: string;
};

function TicketCustomerName({ phoneNumber, from }: Props) {
  return <span>{from || formatInternational(phoneNumber)}</span>;
}

export default TicketCustomerName;
