import axios from 'axios';
import { aiApiV1BaseUrl } from 'constants/api';
import { intercepts } from 'utils/api';

export default {
  v1: intercepts(
    axios.create({
      baseURL: aiApiV1BaseUrl,
    })
  ),
};
