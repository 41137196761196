import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Company,
  Invitation,
  Profile,
  User,
  UserCompany,
} from '../../types/systemLogin/index';

import api from '@/config/api';

export type InvitationResponseData = Invitation & {
  userCompany: UserCompany & {
    user: User & {
      profile: Profile;
    };
    company: Company;
  };
  invitedUserCompany: UserCompany & {
    user: User & {
      profile: Profile;
    };
    company: Company;
  };
};
const useInvitation = (id: string) =>
  useQuery<
    {
      isAutoRedirected: boolean;
      data: InvitationResponseData;
    },
    AxiosError
  >(
    ['invitation', id],
    async () => {
      const {
        data: { data },
      } = await api.systemLogin.get(`/invitation/${id}`);
      return data;
    },
    {
      meta: {
        disableToast: true,
      },
    }
  );

export default useInvitation;
