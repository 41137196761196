import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Branding } from '@/types/bitApp/v2';

export default {
  getBrandingByCompanyId: (companyId: number) =>
    api.bitApp
      .get<ApiResponse<Branding>>(`/branding/${companyId}`)
      .then((res) => res.data.data),
  saveBranding: (companyId: number, payload: Partial<Branding>) =>
    api.bitApp.post(`/branding/${companyId}`, payload),
};
