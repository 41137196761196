import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import services from 'api/services';
import { AgentVariables } from 'types/ai/agent-variables';
import { DeepPartial } from 'types/utils/deepPartial';
import { useActiveCompany } from '../rtk/selector';

const AGENT_VARIABLE_QUERY_KEY = 'ai-agent-variable' as const;

export const useAgentVariables = (
  options?: Omit<
    UseQueryOptions<
      AgentVariables,
      unknown,
      AgentVariables,
      (string | number | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [AGENT_VARIABLE_QUERY_KEY, activeCompany],
    () => services.ai.v1.agentVariables.getAgentVariables(activeCompany),
    options
  );
};

export const useUpdateAgentVariables = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeepPartial<AgentVariables>) =>
      services.ai.v1.agentVariables.upsertAgentVariables(activeCompany, data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          AGENT_VARIABLE_QUERY_KEY,
          activeCompany,
        ]),
    }
  );
};
