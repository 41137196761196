import { ApiResponse as ApiResponse1 } from '@/api/types';
import api from '@/config/api';
import { AutoReply } from '@/types/bitChat/chatbot';
import { ApiResponse } from '@/types/bitCRM/api';
import { Chat } from '@/types/whatsApp/chat';

export default {
  getAutoReplyById: (autoReplyId: string | undefined) =>
    api.whatsApp.v1
      .get<AutoReply>(`/chatbot`, {
        params: {
          id: autoReplyId,
        },
      })
      .then((res) => res.data),
  getAutoReply: ({
    page,
    limit,
    filter,
    search,
    source,
    companyId,
  }: GetAutoReplyParams) =>
    api.whatsApp.v1
      .get<ApiResponse<AutoReply>>(`/chatbot/company/${companyId}`, {
        params: {
          page,
          size: limit,
          filter,
          search,
          source,
        },
      })
      .then((res) => res.data),
  getEnabledRating: ({ companyId }: { companyId: number }) =>
    api.whatsApp.v1
      .get<ApiResponse1<number>>(`/chatbot/${companyId}/count-enabled-rating`)
      .then((res) => res.data.data),
};

export type GetAutoReplyParams = {
  companyId: number | undefined;
  page?: number;
  limit?: number;
  filter?: string | null;
  search?: string;
  source?: Chat['sources'][];
};
