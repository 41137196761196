import { useMutation, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';
import { toast } from 'utils/common/toast';

const useDeleteTemplate = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) =>
      services.bitLogin.template.deleteTemplate(activeCompany, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-templates']);
        queryClient.invalidateQueries(['bitlogin-active-template']);
        toast.success('Template deleted successfully');
      },
    }
  );
};

export default useDeleteTemplate;
