import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { BulkDeleteCollectionsParams } from '@/api/services/bitAi/v2-new/sorting';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useBulkDeleteCollections = () => {
  const activeCompany = useActiveCompany();

  const client = useQueryClient();

  return useMutation(
    (payload: BulkDeleteCollectionsParams) =>
      services.bitAi.v2New.sorting.bulkDeleteCollections(
        activeCompany,
        payload
      ),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your collection has been deleted`);
        client.invalidateQueries(['bitai-sorting-collections', activeCompany]);
      },
    }
  );
};

export default useBulkDeleteCollections;
