function CheckIcon({
  width = 15,
  height = 12,
  color = '#262627',
}: {
  width?: number;
  height?: number;
  color?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92188 6.29688L5.64062 10.0156L13.0781 2.04688"
        stroke={color}
        strokeOpacity="0.5"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon;
