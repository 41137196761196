export default function PlusIcon({
  size = 16,
  color = '#262627',
}: {
  size?: number | string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.182 1.523a1.023 1.023 0 10-2.046 0v5.454H1.023a1.023 1.023 0 100 2.046h5.113v5.454a1.023 1.023 0 002.046 0V9.023h5.795a1.023 1.023 0 000-2.046H8.182V1.523z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
