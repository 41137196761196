import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { Branding } from '@/types/bitApp/v2';
import { toast } from '@/utils/common/toast';

const useUpsertBranding = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<Branding>) =>
      services.bitApp.branding.saveBranding(activeCompany, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitapp-branding', activeCompany]);
        toast.success('Sucessfully update branding');
      },
    }
  );
};

export default useUpsertBranding;
