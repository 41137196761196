import { SVGProps } from 'react';

function LayoutIcon({
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 11C4.45 11 3.979 10.804 3.587 10.412C3.19567 10.0207 3 9.55 3 9V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V9C21 9.55 20.8043 10.0207 20.413 10.412C20.021 10.804 19.55 11 19 11H5ZM5 9H19V5H5V9ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V15C3 14.45 3.19567 13.979 3.587 13.587C3.979 13.1957 4.45 13 5 13H19C19.55 13 20.021 13.1957 20.413 13.587C20.8043 13.979 21 14.45 21 15V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM5 19H19V15H5V19ZM5 5V9V5ZM5 15V19V15Z"
      />
    </svg>
  );
}

export default LayoutIcon;
