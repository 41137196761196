export default function GenderIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8814 8.57678C10.4241 8.99376 10.2613 9.53248 10.0893 10.1466C12.2673 10.1466 14.0329 11.9212 14.0329 14.1103C14.6439 13.9374 15.1799 13.7738 15.5948 13.3142C15.2472 10.8642 13.319 8.92611 10.8814 8.57678Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0693 7.14469L20.3902 4.812V7.19775C20.3902 7.36343 20.5245 7.49775 20.6902 7.49775H21.6986C21.8642 7.49775 21.9985 7.36348 21.9986 7.19783L21.9999 2.20011C22 2.08961 21.9104 2.00003 21.7999 2.00006L16.8396 2.0015C16.674 2.00154 16.5397 2.13584 16.5397 2.3015L16.5397 3.32769C16.5397 3.49337 16.674 3.62769 16.8397 3.62769L18.9945 3.62769L16.7431 5.89052C15.8581 5.29328 14.793 4.94484 13.6469 4.94484C10.5754 4.94484 8.08547 7.44744 8.08547 10.5346C8.08547 13.6217 10.5754 16.1243 13.6469 16.1243C16.7184 16.1243 19.2083 13.6217 19.2083 10.5346C19.2083 9.25997 18.7839 8.08501 18.0693 7.14469ZM13.6469 14.4982C15.8249 14.4982 17.5904 12.7236 17.5904 10.5346C17.5904 8.34552 15.8249 6.57094 13.6469 6.57094C11.4689 6.57094 9.70334 8.34551 9.70334 10.5346C9.70334 12.7236 11.4689 14.4982 13.6469 14.4982Z"
        fill="black"
      />
      <path
        d="M7.56142 11.0613L7.56143 11.068C6.69617 11.795 6.14579 12.888 6.14579 14.1103C6.14579 16.2993 7.91138 18.0739 10.0893 18.0739C11.3054 18.0739 12.3928 17.5207 13.1162 16.651L13.1228 16.651C13.8707 16.651 14.5841 16.5027 15.2355 16.2336C14.4038 18.2677 12.413 19.7 10.0893 19.7C8.82108 19.7 7.65197 19.2733 6.71636 18.5549L6.14775 19.1262L7.3676 20.3522C7.48404 20.4692 7.48404 20.6584 7.3676 20.7754L6.50379 21.6436C6.38651 21.7615 6.19573 21.7615 6.07845 21.6436L4.86042 20.4194L3.49968 21.7864C3.38239 21.9042 3.19165 21.9042 3.07439 21.7863L2.21057 20.9181C2.09411 20.801 2.09414 20.6119 2.21063 20.4949L3.57341 19.1258L2.35462 17.9008C2.23818 17.7838 2.23818 17.5947 2.35462 17.4776L3.21843 16.6094C3.33571 16.4916 3.52649 16.4916 3.64377 16.6094L4.86075 17.8326L5.46862 17.2219C4.87452 16.3325 4.52792 15.2621 4.52792 14.1103C4.52792 11.7748 5.95294 9.77388 7.97672 8.93795C7.70904 9.59262 7.56142 10.3096 7.56142 11.0613Z"
        fill="black"
      />
    </svg>
  );
}
