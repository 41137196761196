import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge as cx } from 'tailwind-merge';

import { BBBRenderHtml } from '@/components/ui';
import { useInfiniteQuickReply } from '@/hooks/bitChat/quick-reply';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { activeMessageSelector } from '@/hooks/whatsApp/useActiveChatMemo';
import { applyQuickReplyWithMedia, upsertLivechatMemo } from '@/stores/bitCRM';
import { QuickReply } from '@/types/bitChat/quickReply';
import {
  convertHtmlToEditorState,
  formatSelectionToEnd,
} from '@/utils/common/rich';

type Props = {
  richId: string;
  onQuickReplyEnter: () => void;
};

export default function QuickReplyRecommendation(props: Props) {
  return <_QuickReplyRecommendation {...props} />;
}

function _QuickReplyRecommendation(props: Props) {
  const _search = useAppSelector(activeMessageSelector);
  const search = _search.getCurrentContent().getPlainText('\u0001');

  const query = useInfiniteQuickReply({
    search: search,
    limit: 10,
  });

  if (query.status === 'loading' || query.status === 'error') return null;

  const flattenedData = query.data.pages.flatMap((page) => page.content);

  if (!flattenedData.length) return null;

  return (
    <__QuickReplyRecommendation
      {...props}
      data={flattenedData}
      hasMore={query.hasNextPage}
      fetchNext={query.fetchNextPage}
    />
  );
}
function __QuickReplyRecommendation({
  richId,
  onQuickReplyEnter,
  data,
  hasMore,
  fetchNext,
}: Props & {
  data: QuickReply[];
  hasMore: boolean | undefined;
  fetchNext: () => void;
}) {
  const [activeElmts, setActiveElmts] = useState(0);

  const dispatch = useAppDispatch();

  const quickReplyLength = data.length;

  useEffect(() => {
    const messageBoxAreaQuerySelector = document.querySelector<HTMLDivElement>(
      `#bbb-custom-rich-${richId}`
    );

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setActiveElmts((prev) => {
          const siblings = document.getElementById(
            `quick-reply-recommendation-${prev + 1}`
          );

          const ref = document.getElementById(`scrollableQuickReply`);

          const offsetTop =
            e.key === 'ArrowDown'
              ? siblings?.getBoundingClientRect().bottom ?? 0
              : siblings?.getBoundingClientRect().top ?? 0;

          const refOffset =
            e.key === 'ArrowDown'
              ? ref?.getBoundingClientRect().bottom ?? 0
              : ref?.getBoundingClientRect().top ?? 0;

          if (siblings && ref) {
            if (e.key === 'ArrowDown' && offsetTop > refOffset) {
              ref.scrollTop += ref.clientHeight / 2;
            } else if (e.key === 'ArrowUp' && offsetTop <= refOffset) {
              ref.scrollTop -= ref.clientHeight / 2;
            }
          }

          return e.key === 'ArrowDown'
            ? prev + 1 > quickReplyLength - 1
              ? quickReplyLength - 1
              : prev + 1
            : prev - 1 < 0
            ? 0
            : prev - 1;
        });
      }
    }

    if (messageBoxAreaQuerySelector) {
      messageBoxAreaQuerySelector.addEventListener('keydown', onKeyDown);

      return () => {
        messageBoxAreaQuerySelector.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [richId, quickReplyLength]);

  useEffect(() => {
    const messageBoxAreaQuerySelector = document.querySelector<HTMLDivElement>(
      `#bbb-custom-rich-${richId}`
    );

    async function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        const media = document.getElementById(
          `quick-reply-media-${activeElmts}`
        )?.innerHTML;

        const mimeType = document.getElementById(
          `quick-reply-media-mimetype-${activeElmts}`
        )?.innerHTML;

        const message = formatSelectionToEnd(
          convertHtmlToEditorState(
            document.getElementById(`quick-reply-message-${activeElmts}`)
              ?.innerHTML
          )
        );

        if (media && mimeType) {
          dispatch(
            applyQuickReplyWithMedia({
              message,
              media,
              mimeType,
            })
          );
        } else {
          dispatch(
            upsertLivechatMemo({
              messageToSend: message,
            })
          );
        }

        onQuickReplyEnter();
      }
    }

    if (messageBoxAreaQuerySelector) {
      messageBoxAreaQuerySelector.addEventListener('keydown', onKeyDown);

      return () => {
        messageBoxAreaQuerySelector.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [richId, dispatch, activeElmts, onQuickReplyEnter]);

  return (
    <div
      className="absolute z-10 bottom-full left-0 right-0 mx-2 bg-white rounded shadow max-h-[200px] overflow-auto"
      id="scrollableQuickReply"
    >
      <InfiniteScroll
        dataLength={quickReplyLength}
        scrollableTarget="scrollableQuickReply"
        loader={
          <div className="flex justify-center items-center p-2">Loading...</div>
        }
        next={fetchNext}
        hasMore={!!hasMore}
      >
        {data.map((quickRep, index) => (
          <div
            className={cx(
              'flex items-center gap-2 p-2 rounded cursor-pointer last:mb-0 hover:bg-secondary-surface',
              activeElmts === index && 'bg-secondary-surface'
            )}
            onClick={() => {
              const val = convertHtmlToEditorState(quickRep.message);
              const message = formatSelectionToEnd(val);

              if (quickRep.mediaUrl && quickRep.mimeType) {
                dispatch(
                  applyQuickReplyWithMedia({
                    message,
                    media: quickRep.mediaUrl,
                    mimeType: quickRep.mimeType,
                  })
                );
              } else {
                dispatch(
                  upsertLivechatMemo({
                    messageToSend: message,
                  })
                );
              }
            }}
            key={quickRep.id}
            id={`quick-reply-recommendation-${index}`}
            tabIndex={0}
          >
            <div className="flex-[1_1_0%] truncate">{quickRep.command}</div>
            <div
              className="flex-[2_2_0%] text-gray-400 line-clamp-1"
              id={`quick-reply-message-${index}`}
            >
              <BBBRenderHtml content={quickRep.message} />
            </div>
            <div
              className="hidden pointer-events-none w-0 h-0"
              id={`quick-reply-media-${index}`}
            >
              {quickRep.mediaUrl}
            </div>
            <div
              className="hidden pointer-events-none w-0 h-0"
              id={`quick-reply-media-mimetype-${index}`}
            >
              {quickRep.mimeType}
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
