import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AutoReply } from '@/types/bitChat/chatbot';
import { DeepPartial } from '@/types/utils/deepPartial';
import { toast } from '@/utils/common/toast';

const mapActionToTitle = {
  delete: 'Delete multiple reply',
};

const mapDescriptionToTitle = {
  delete:
    'Are you sure to delete selected reply? Once you deleted the action cannot be undone',
};

const useChatbotBulkAction = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  const bulkActionMutation = useMutation(
    ({
      action,
      selectedRows,
    }:
      | { action: 'delete'; selectedRows: string[] }
      | { action: 'update'; selectedRows: DeepPartial<AutoReply>[] }) =>
      api.whatsApp.v1.post(`/chatbot/bulk_action`, {
        companyId: activeCompany,
        data: selectedRows,
        action,
      }),
    {
      onSuccess: (_, variables) => {
        toast.success(
          `Your ${
            variables.action === 'delete' ? 'auto reply' : 'configuration'
          } has been ${variables.action === 'delete' ? 'deleted' : 'updated'}`
        );
        queryClient.invalidateQueries(['chatbot-autoreplies']);
        queryClient.invalidateQueries(['infinite-chatbot-autoreplies']);
        queryClient.invalidateQueries(['bitchat-chatbot-rule']);
      },
    }
  );

  function bulkAction(options: {
    action: 'delete';
    selectedRows: string[];
    onSuccess?: () => void;
  }): void;
  function bulkAction(options: { action: 'update' }): {
    mutate: (
      data: DeepPartial<AutoReply>[],
      params?: { onSuccess?: () => void }
    ) => void;
    loading: boolean;
  };
  function bulkAction(
    params:
      | {
          action: 'delete';
          selectedRows: string[];
          onSuccess?: () => void;
        }
      | {
          action: 'update';
        }
  ): void | {
    mutate: (
      data: DeepPartial<AutoReply>[],
      params?: { onSuccess?: () => void }
    ) => void;
    loading: boolean;
  } {
    if (params.action === 'delete') {
      const title = mapActionToTitle[params.action];
      const description = mapDescriptionToTitle[params.action];

      toggleConfirmation({
        title,
        description,
        onAccept: (hide) => {
          bulkActionMutation.mutate(
            {
              action: params.action,
              selectedRows: params.selectedRows,
            },
            {
              onSuccess: () => {
                hide();
                params.onSuccess?.();
              },
            }
          );
        },
        deleteModal: true,
        submitText: 'Delete',
        cancelText: 'Cancel',
      });
    } else {
      return {
        mutate: (
          data: DeepPartial<AutoReply>[],
          params?: { onSuccess?: () => void }
        ) => {
          {
            bulkActionMutation.mutate(
              {
                action: 'update',
                selectedRows: data,
              },
              {
                onSuccess: () => {
                  params?.onSuccess?.();
                },
              }
            );
          }
        },
        loading: bulkActionMutation.isLoading,
      };
    }
  }

  return bulkAction;
};

export default useChatbotBulkAction;
