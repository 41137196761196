import { gql } from 'graphql-request';

export default gql`
  query getArticle($id: ID!) {
    node(id: $id) {
      id
      ... on Article {
        id
        title
      }
    }
  }
`;
