import { gql } from 'graphql-request';

export default gql`
  query getProductById($id: ID!) {
    product(id: $id) {
      id
      title
    }
  }
`;
