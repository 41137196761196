import { Control, Controller, useWatch } from 'react-hook-form';
import { FormSchema } from '..';

import { BBBCard, BBBColorPicker } from '@/components/ui';
import { cn } from '@/utils/styles';

function SocialColor({ control }: { control: Control<FormSchema> }) {
  const socialColorType = useWatch({ control, name: 'socialColorType' });

  return (
    <BBBCard title="Social media color">
      <div className="rounded-lg border border-neutral-30 px-5 py-6 flex gap-6">
        <Controller
          control={control}
          name="socialColorType"
          render={({ field }) => (
            <>
              <div className="flex-1">
                <div
                  className={cn(
                    'rounded-lg bg-[#1877F2] flex items-center justify-center py-3.5 cursor-pointer',
                    field.value === 'social' && 'outline outline-offset-2'
                  )}
                  onClick={() => {
                    field.onChange('social');
                  }}
                >
                  <div className="text-white">Social color</div>
                </div>
              </div>
              <div className="flex-1">
                <div
                  className={cn(
                    'rounded-lg border border-dashed border-neutral-40 flex items-center justify-center py-3.5 cursor-pointer',
                    field.value === 'custom' && 'outline outline-offset-2'
                  )}
                  onClick={() => {
                    field.onChange('custom');
                  }}
                >
                  Custom
                </div>
              </div>
            </>
          )}
        />
        <Controller
          control={control}
          name="socialColorCustom"
          render={({ field }) => (
            <BBBColorPicker
              label="Button color"
              color={field.value || undefined}
              onChangeColor={field.onChange}
              containerClassName={cn(
                socialColorType === 'social' && 'pointer-events-none opacity-10'
              )}
            />
          )}
        />
      </div>
    </BBBCard>
  );
}

export default SocialColor;
