export default function CheckIconDouble({
  size = 16,
  read,
}: {
  color?: string;
  size?: number;
  read?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.toString()}
      height={size.toString()}
      fill="none"
      viewBox="0 0 12 6"
    >
      <path
        fill={read ? '#19e1f7' : 'rgb(156,163,175)'}
        d="M6.848.349a.656.656 0 01.937.918L4.293 5.634a.656.656 0 01-.945.017L1.033 3.336a.656.656 0 11.928-.928L3.793 4.24 6.83.368a.22.22 0 01.017-.02zm-.805 4.497l.805.805a.656.656 0 00.944-.017l3.494-4.367a.658.658 0 00-.234-1.082.656.656 0 00-.721.183L7.292 4.24l-.424-.426-.826 1.032h.001z"
      />
    </svg>
  );
}
