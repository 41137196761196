import { shallowEqual } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import EditCircleImageGrid from '../../Design/components/Popup/EditCircleImageGrid';
import EditCollections from '../../Design/components/Popup/EditCollections';
import EditImageSlider from '../../Design/components/Popup/EditImageSlider';
import CartPage from './CartPage';
import CategoryPage from './CategoryPage';
import Homepage from './Homepage';
import PageNavigator from './PageNavigator';
import ProductPage from './ProductPage';

import AlertIcon2 from '@/assets/icons/AlertIcon2';
import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { BBBButton, Link } from '@/components/ui';
import { BBBDeviceFrame } from '@/components/ui/BBBDeviceFrame';
import useBranding from '@/hooks/bitApp/design/useBranding';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppSelector } from '@/hooks/rtk/store';

type LivePreviewProps = {
  module: 'design' | 'branding';
};

export default function LivePreview({ module = 'design' }: LivePreviewProps) {
  const popup = useAppSelector((state) => state.bitApp.popup, shallowEqual);
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);
  const isTablet = useResponsive('lg');

  return (
    <>
      {isTablet ? null : (
        <div
          className={cx(
            'flex flex-none w-[55%] h-screen justify-center items-center py-11 px-5 overflow-y-auto'
          )}
        >
          <div className="h-full grow justify-center items-center flex-col">
            <div id="bitapp-diff-section" />
            <div className="min-h-full flex grow flex-col items-center justify-center py-10">
              {activePreview !== 'category' && (
                <PageNavigator className="mb-[1.875rem]" />
              )}
              {module === 'design' && activePreview === 'home' && (
                <OnboardingNotification />
              )}
              <div className="flex-none">
                {module === 'design' ? (
                  <>
                    <RootApp />
                    {activePreview === 'home' && (
                      <Link to="/bitapp/preview">
                        <BBBButton
                          className="mt-6"
                          size="full"
                          text="Preview"
                        />
                      </Link>
                    )}
                  </>
                ) : activePreview === 'splash' ? (
                  <SplashIcon />
                ) : activePreview === 'app-icon' ? (
                  <AppIcon />
                ) : undefined}
              </div>
            </div>
          </div>
          <AnimatePresence mode="wait" exitBeforeEnter initial={false}>
            {popup?.type == 'banners-landscape' ? (
              <EditImageSlider type="landscape" />
            ) : popup?.type === 'banners-portrait' ? (
              <EditImageSlider type="portrait" />
            ) : popup?.type === 'imagegrids' ? (
              <EditCircleImageGrid />
            ) : popup?.type === 'collections' ? (
              <EditCollections />
            ) : null}
          </AnimatePresence>
        </div>
      )}
    </>
  );
}

type RootAppProps = {
  className?: string;
};

export function RootApp({ className }: RootAppProps) {
  const activePreview = useAppSelector((state) => state.bitApp.activePreview);

  return (
    <BBBDeviceFrame className={className}>
      {activePreview === 'home' && <Homepage />}
      {activePreview === 'category' && <CategoryPage />}
      {activePreview === 'product' && <ProductPage />}
      {activePreview === 'cart' && <CartPage />}
    </BBBDeviceFrame>
  );
}

type SplashIconProps = {
  deviceClassName?: string;
  className?: string;
};

export function SplashIcon({ deviceClassName, className }: SplashIconProps) {
  const { data: brandingData } = useBranding();

  return (
    <div className={cx('w-100 flex gap-2 justify-center', className)}>
      <BBBDeviceFrame className={deviceClassName}>
        <div className="flex items-center justify-center flex-col w-full h-full">
          {brandingData?.splashScreen ? (
            <img
              src={brandingData?.splashScreen}
              className="w-full h-full object-cover"
              alt="splash-logo"
            />
          ) : (
            <BBBThumbnail width={120} height={120} />
          )}
        </div>
      </BBBDeviceFrame>
    </div>
  );
}

type AppIconProps = {
  deviceClassName?: string;
  className?: string;
};

export function AppIcon({ deviceClassName, className }: AppIconProps) {
  const { data: brandingData } = useBranding();

  return (
    <div className={cx('w-100 flex gap-2 justify-center', className)}>
      <BBBDeviceFrame className={deviceClassName}>
        <div className="grid grid-cols-3 py-14 px-10 gap-4">
          {Array.from({ length: 8 }).map((_, i) =>
            i === 0 ? (
              <div className="flex items-center flex-col gap-2" key={i}>
                {brandingData?.appIcon ? (
                  <img
                    src={brandingData?.appIcon}
                    className="w-[4rem] h-[4rem] object-cover rounded self-center"
                    alt="app-icon"
                  />
                ) : (
                  <BBBThumbnail shape="square" width="4rem" height="4rem" />
                )}
                <div className="text-xs text-primary-main text-center font-bold">
                  {brandingData?.name || 'Your app'}
                </div>
              </div>
            ) : (
              <div className="flex items-center flex-col gap-2" key={i}>
                <div className="w-[4rem] h-[4rem] bg-gray-200 rounded-lg" />
                <div className="text-xs text-primary-main text-center">
                  App name
                </div>
              </div>
            )
          )}
        </div>
      </BBBDeviceFrame>
    </div>
  );
}

function OnboardingNotification() {
  const { data, status } = useOnboarding('design-notifications');

  if (data?.lastStep) {
    if (status !== 'success' || data.lastStep >= 1) return null;
  }

  return (
    <div className="flex items-center gap-2 justify-center mb-2.5">
      <AlertIcon2 type="secondary" size={20} />
      <p className="text-secondary-main">Click section to start editing</p>
    </div>
  );
}
