export const appType = [
  'bitcrm',
  'bitlink',
  'bitchat',
  'bitlogin',
  'bitai',
  'bitapp',
];

export const mapParamToAppType = {
  bitcrm: 'BITCRM',
  bitlink: 'BITLINK',
  bitchat: 'BITCHAT',
  bitlogin: 'BITLOGIN',
  bitapp: 'BITAPP',
  bitai: 'BITAI',
} as const;
