import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import {
  CollectionAssociation,
  GetCollectionParams,
} from '@/api/services/bitAi/v2-new/sorting';
import { CursoredResponse } from '@/api/types';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useUpdateOos = (params: GetCollectionParams) => {
  const activeCompany = useActiveCompany();

  const client = useQueryClient();

  return useMutation(
    ({
      data: { collectionId, oos },
    }: {
      data: { collectionId: string; oos: boolean };
    }) =>
      services.bitAi.v2New.sorting.updateOos(
        activeCompany,
        encodeURIComponent(collectionId),
        {
          oos,
        }
      ),
    {
      onMutate: async ({ data: { collectionId, oos } }) => {
        await client.cancelQueries([
          `bitai-sorting-v2-collections`,
          activeCompany,
          params,
        ]);

        const previousData = client.getQueryData<
          CursoredResponse<CollectionAssociation[]>
        >([`bitai-sorting-v2-collections`, activeCompany, params]);

        client.setQueryData<CursoredResponse<CollectionAssociation[]>>(
          [`bitai-sorting-v2-collections`, activeCompany, params],
          (old) => {
            if (!old) return;

            return {
              ...old,
              data: old.data.map((collection) =>
                collection.id === collectionId
                  ? {
                      ...collection,
                      sorting: collection.sorting
                        ? { ...collection.sorting, oos }
                        : {
                            modelId: null,
                            collectionId,
                            companyId: activeCompany,
                            oos,
                            model: null,
                          },
                    }
                  : collection
              ),
            };
          }
        );

        return { previousData };
      },
      onError: (err, variables, context) => {
        client.setQueryData(
          [`bitai-sorting-v2-collections`, activeCompany, params],
          context?.previousData
        );
        toast.error('Failed to update collection status');
      },
      onSuccess: () => {
        toast.success(
          'Sorting request acknowledged. Please allow a few minutes for the changes to be reflected live on your store.'
        );
      },
      onSettled: () => {
        client.invalidateQueries([
          `bitai-sorting-v2-collections`,
          activeCompany,
          params,
        ]);
      },
    }
  );
};

export default useUpdateOos;
