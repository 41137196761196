import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { RoleTypes } from '@/types/systemLogin';
import { toast } from '@/utils/common/toast';

const useUpsertAuthority = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  return useMutation(
    async ({
      userId,
      ...payload
    }: {
      role: Exclude<RoleTypes, 'SUPER_ADMIN'>;
      userId: number;
    }) => {
      const { data } = await api.systemLogin.post(
        `/authority/user/${userId}/company/${activeCompany}`,
        payload
      );
      return data;
    },
    {
      onSuccess: (data, payload) => {
        toast.success('Authority has been updated');
        client.invalidateQueries([
          'user-company',
          [payload.userId.toString(), activeCompany],
        ]);
        client.invalidateQueries(['company-users']);
      },
    }
  );
};

export default useUpsertAuthority;
