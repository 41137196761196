import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { EmptyCartSetting } from '@/types/bitApp/v2';

const useCartSettings = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery<EmptyCartSetting>(
    ['bitapp-cart-settings', activeCompany],
    () =>
      services.bitApp.cartSettings.getCartSettingsByCompanyId(activeCompany!)
  );
};

export default useCartSettings;
