import { useState } from 'react';
import ChooseCompany from '../ChooseCompany';
import ShopifyOauthRegisterForm from '../RegisterForm';

import LoginCard from '@/components/Auth/LoginCard';
import { LoginPayload } from '@/hooks/auth/useLogin';
import useOauthLogin from '@/hooks/auth/useOauthLogin';
import { AppType } from '@/types/systemLogin';

type ShopifyOauthLoginFormProps = {
  companyDomain: string;
  app: AppType;
};

function ShopifyOauthLoginForm({
  companyDomain,
  app,
}: ShopifyOauthLoginFormProps) {
  const { mutate: login } = useOauthLogin();

  const [authMode, setAuthMode] = useState<
    'login' | 'sign-up' | 'auth-success'
  >('login');

  const [authenticatedUserId, setAuthenticatedUserId] = useState<number>();
  const [authenticatedAccessToken, setAuthenticatedAccessToken] =
    useState<string>();

  const onSubmit = (data: LoginPayload) => {
    login(data, {
      onSuccess: (data) => {
        setAuthMode('auth-success');
        setAuthenticatedUserId(data.user.id);
        setAuthenticatedAccessToken(data.accessToken);
      },
    });
  };

  return (
    <>
      {authMode === 'login' ? (
        <LoginCard
          onSubmit={onSubmit}
          onCreateNewAccount={() => setAuthMode('sign-up')}
        />
      ) : authMode === 'auth-success' &&
        authenticatedUserId &&
        authenticatedAccessToken ? (
        <ChooseCompany
          id={authenticatedUserId}
          showBack
          onBack={() => setAuthMode('login')}
          companyDomain={companyDomain}
          app={app}
          authenticatedAccessToken={authenticatedAccessToken}
        />
      ) : (
        <ShopifyOauthRegisterForm
          companyDomain={companyDomain}
          app={app}
          onChangeMode={() => setAuthMode('login')}
          onChangeAuthMode={setAuthMode}
        />
      )}
    </>
  );
}

export default ShopifyOauthLoginForm;
