import { ChevronRight } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

type Props = {
  disabled?: boolean;
  name: string;
  onClick?: () => void;
};

function CompanyInfo({ disabled, name, onClick }: Props) {
  return (
    <div
      className={cx(
        'mb-3 rounded-xl cursor-pointer hover:bg-gray-100 flex justify-between items-center border-grayColor2 p-5 border',
        disabled ? 'pointer-events-none' : ''
      )}
      onClick={onClick}
    >
      <div>{name}</div>
      <ChevronRight className="text-grayColor2" />
    </div>
  );
}

export default CompanyInfo;
