import { createRoot } from 'react-dom/client';
import App from './App';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './assets/stylesheets/main.scss';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
