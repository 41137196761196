import { ApiResponse } from 'api/types';
import api from 'config/api';
import { Chat } from 'types/whatsApp/chat';
import { MessageNewAssociation } from 'types/whatsApp/v3';

export default {
  searchChat: (
    key: string,
    params?: {
      search?: string;
      cursor?: string;
    }
  ) =>
    api.whatsApp.v3
      .get<SearchChatPayload>(`/data`, {
        params,
        headers: {
          'x-bitbybit-key': key,
        },
      })
      .then((res) => res.data),
};

export type SearchChatPayload = ApiResponse<
  SearchChatDataDefault | SearchChatDataNext
> & {
  meta: {
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type SearchChatDataDefault = [Chat[], MessageNewAssociation[]];

export type SearchChatDataNext = MessageNewAssociation[];
