function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        d="M8 1.5A5.507 5.507 0 002.5 7a5.444 5.444 0 001.108 3.3s.15.197.175.226L8 15.5l4.22-4.976.172-.224v-.002A5.442 5.442 0 0013.5 7 5.506 5.506 0 008 1.5zM8 9a2 2 0 110-4 2 2 0 010 4z"
      ></path>
    </svg>
  );
}

export default LocationIcon;
