const ShopifyLogo = ({
  width = '132',
  height = '135',
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 132 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.32 28.0281C107.232 27.3777 106.676 27.0183 106.216 26.9787C105.758 26.9393 96.0471 26.2026 96.0471 26.2026C96.0471 26.2026 89.3029 19.3486 88.5631 18.5902C87.8221 17.8318 86.3759 18.0624 85.8147 18.2318C85.7314 18.2567 84.341 18.6962 82.0402 19.4245C79.7871 12.7887 75.8117 6.69002 68.8169 6.69002C68.624 6.69002 68.4251 6.69833 68.2262 6.70976C66.2369 4.01679 63.7727 2.84692 61.6444 2.84692C45.3509 2.84692 37.5666 23.6978 35.1257 34.294C28.7946 36.3023 24.2965 37.7299 23.7221 37.9148C20.1882 39.0493 20.0765 39.1636 19.6126 42.5724C19.2625 45.1532 10.0156 118.358 10.0156 118.358L82.0697 132.178L121.112 123.532C121.112 123.532 107.406 28.6785 107.32 28.0281ZM78.0577 20.6858C76.248 21.2593 74.1909 21.9107 71.9611 22.6172C71.9631 22.1777 71.9651 21.7445 71.9651 21.2717C71.9651 17.1471 71.4059 13.8266 70.5087 11.1939C74.1127 11.6572 76.513 15.8547 78.0577 20.6858ZM66.0381 12.0116C67.0397 14.582 67.6913 18.2703 67.6913 23.2479C67.6913 23.5024 67.6893 23.7351 67.6873 23.9709C63.7219 25.2281 59.4137 26.5933 55.0952 27.9627C57.5198 18.3835 62.0646 13.757 66.0381 12.0116ZM61.1968 7.32067C61.9002 7.32067 62.6086 7.56482 63.2866 8.04274C58.0648 10.5581 52.4675 16.8936 50.1038 29.545C46.649 30.64 43.2724 31.7102 40.1505 32.7003C42.9192 23.0505 49.4939 7.32067 61.1968 7.32067Z"
        fill="#95BF46"
      />
      <path
        d="M106.217 26.9789C105.758 26.9394 96.0476 26.2028 96.0476 26.2028C96.0476 26.2028 89.3035 19.3488 88.5636 18.5904C88.2865 18.3078 87.913 18.1634 87.5223 18.1011L82.0742 132.177L121.112 123.532C121.112 123.532 107.407 28.6787 107.32 28.0283C107.233 27.3778 106.676 27.0184 106.217 26.9789Z"
        fill="#5E8E3E"
      />
      <path
        d="M68.8174 49.0618L64.0037 63.7204C64.0037 63.7204 59.7857 61.416 54.6157 61.416C47.0363 61.416 46.6547 66.2856 46.6547 67.5126C46.6547 74.2076 63.7032 76.7728 63.7032 92.4548C63.7032 104.793 56.0589 112.737 45.7514 112.737C33.3826 112.737 27.0576 104.857 27.0576 104.857L30.3693 93.6561C30.3693 93.6561 36.8708 99.3698 42.3575 99.3698C45.9422 99.3698 47.4006 96.4809 47.4006 94.3693C47.4006 85.6361 33.4141 85.2465 33.4141 70.8964C33.4141 58.8186 41.8825 47.1304 58.9767 47.1304C65.5636 47.1304 68.8174 49.0618 68.8174 49.0618Z"
        fill="white"
      />
    </svg>
  );
};

export default ShopifyLogo;
