import Skeleton from 'react-loading-skeleton';

import useCompanyUsers from '@/hooks/auth/useCompanyUsers';

function UnauthorizedScreen() {
  const { data: userCompanies, isInitialLoading } = useCompanyUsers({
    role: 'SUPER_ADMIN',
  });

  const userCompany = userCompanies?.pages[0].data[0];

  return (
    <div className="top-1/2 absolute transform -translate-y-1/2 left-0 right-0 max-w-5xl mx-auto">
      <div className="text-4xl text-center mb-4">Unauthorized</div>
      <div className="text-center">
        Access to this page has been restricted. Please ask{' '}
        <span>
          {isInitialLoading ? (
            <Skeleton width={50} height={20} />
          ) : (
            <>
              {' '}
              {userCompany?.user.profile?.firstName}{' '}
              {userCompany?.user.profile?.lastName} ({userCompany?.user.email})
              to{' '}
            </>
          )}
        </span>
        grant access
      </div>
    </div>
  );
}

export default UnauthorizedScreen;
