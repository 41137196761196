import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import PreviewWidget, { PreviewWidgetProps } from '../PreviewWidget';

import { BBBContainer } from '@/components/ui/BBBContainer';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useResponsive from '@/hooks/common/useResponsive';

type Props = {
  children: React.ReactNode;
  title: string;
  description: string;
} & PreviewWidgetProps;

export default function Wrapper(props: Props) {
  return <__WrapperOnboarded {...props} />;
}

function __WrapperOnboarded({ ...props }: Props) {
  return <__Wrapper {...props} />;
}

function __Wrapper({ children, title, description, control }: Props) {
  const isMobile = useResponsive('sm');

  const { data: isRestricted } = useIsModuleRestricted('BITCHAT_CHAT_WIDGET');

  return (
    <div className="flex min-h-full">
      <div className="flex-[2_2_0%] relative">
        <div className="absolute inset-0">
          <BBBContainer
            hasHeading
            pageTitle={title}
            pageDescription={description}
            className={isMobile ? 'p-0 border-transparent' : undefined}
            id="widget-container-left"
          >
            <div className={cx(isRestricted && 'pointer-events-none')}>
              {children}
            </div>
          </BBBContainer>
        </div>
      </div>
      <div
        className={cx(
          'border-l flex-[1_1_0%] flex justify-center items-center flex-col'
        )}
        id="widget-preview"
      >
        <PreviewWidget control={control} />
      </div>
    </div>
  );
}
