import { useRef, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useTicket from '@/hooks/whatsApp/useTicket';
import useTicketByChat from '@/hooks/whatsApp/useTicketByChat';
import useUpdateTicket from '@/hooks/whatsApp/useUpdateTicket';
import { Ticket } from '@/types/whatsApp/ticket';

type TagsByTicket = {
  type: 'ticket';
} & Pick<Ticket, 'sources' | 'id'>;

type TagsByChat = {
  type: 'chat';
};

type Props = TagsByChat | TagsByTicket;

function TicketTags(props: Props) {
  if (props.type === 'ticket') return <_TicketTagsByTicket {...props} />;

  return <_TicketTagsByChat />;
}

function _TicketTagsByChat() {
  const { data: ticketData } = useTicketByChat();

  if (!ticketData) return null;

  return <_TicketTags {...ticketData} type="chat" />;
}

function _TicketTagsByTicket({ sources, id }: TagsByTicket) {
  const { data: ticketData } = useTicket(sources, id);

  if (!ticketData) return null;

  return <_TicketTags {...ticketData} type="ticket" />;
}

function _TicketTags({
  tags,
  sources,
  id,
  type,
}: Ticket & Pick<Props, 'type'>) {
  const tagDropdownRef = useRef<HTMLInputElement>(null);

  const [showTagOptions, setShowTagOptions] = useState(false);

  const { data: settingsData } = useSettings({
    enabled: showTagOptions,
  });

  const { mutate: updateTicket } = useUpdateTicket();

  useOutsideAlerter(tagDropdownRef, () => {
    setShowTagOptions(false);
  });

  const { data: isRestricted } = useIsModuleRestricted(
    type === 'ticket'
      ? 'BITCHAT_EDIT_TICKET_DIRECT'
      : 'BITCHAT_EDIT_TICKET_LIVECHAT'
  );

  return (
    <div
      className={cx(
        'relative my-auto',
        isRestricted && 'pointer-events-none opacity-50'
      )}
    >
      <BBBBadge
        onClick={() => setShowTagOptions(!showTagOptions)}
        className="cursor-pointer"
        text={tags?.[0]?.Tags.label || 'Regular Chat'}
        type={tags?.[0]?.Tags.color}
      />
      {showTagOptions && (
        <BBBCard
          ref={tagDropdownRef}
          className="absolute w-56 h-60 overflow-auto px-4 py-2 border-none transition-opacity shadow bg-white rounded-lg mt-1 top-full left-0 flex flex-col gap-3 z-50"
        >
          {settingsData?.ticketTags?.map((item) => (
            <BBBBadge
              onClick={() => {
                updateTicket(
                  {
                    tagId: item.id,
                    source: sources,
                    ticketId: id,
                  },
                  {
                    onSuccess: () => {
                      setShowTagOptions(false);
                    },
                  }
                );
              }}
              key={item.label}
              className="cursor-pointer"
              text={item.label}
              type={item.color}
            />
          ))}
        </BBBCard>
      )}
    </div>
  );
}

export default TicketTags;
