import { useState } from 'react';
import { Lock, X } from 'react-feather';

import PricingAwareWrapper from '@/components/PricingAwareWrapper';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBCard } from '@/components/ui/BBBCard';
import { BBBPrimarySwitch } from '@/components/ui/BBBPrimarySwitch';
import { env } from '@/config/env';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useSocialConfigProvider from '@/hooks/bitLogin/useSocialConfigProvider';
import useUpsertSocialAppearance from '@/hooks/bitLogin/useUpsertSocialAppearance';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { toast } from '@/utils/common/toast';

function GoogleOneTapCard() {
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const { data } = useSocialAppearance();
  const { data: configData } = useSocialConfigProvider('GOOGLE');
  const { mutate } = useUpsertSocialAppearance();

  const [isCardVisible, setIsCardVisible] = useState(false);
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  return (
    <BBBCard
      title="Use Google One Tap"
      rightButton={
        <div className="flex items-center gap-5">
          {pricingData?.pricingName === 'free' && <Lock />}
          <ShopifyAwareWrapper appType="BITLOGIN" fallbackToChildren>
            {({ connectMiddleware }) => (
              <PricingAwareWrapper appType="BITLOGIN" desiredPlan="pro">
                {({ validatePricing }) => (
                  <BBBPrimarySwitch
                    checked={data?.googleOneTap || false}
                    onChange={(value) =>
                      connectMiddleware(() =>
                        validatePricing(() => {
                          if (
                            !configData?.clientId &&
                            !configData?.clientSecret
                          ) {
                            toast.error(
                              'We need your OAuth data to activate this feature. Please fill it in to continue.'
                            );
                            return;
                          }
                          mutate({
                            googleOneTap: value,
                          });
                        })
                      )
                    }
                  />
                )}
              </PricingAwareWrapper>
            )}
          </ShopifyAwareWrapper>
        </div>
      }
      containerTitleClassName="mb-2"
    >
      <>
        <div className="lg:mb-2 mb-1 text-neutral-40 lg:text-xs text-base">
          You can use Google One Tap to allow your customers to log in with
          their Google account.
        </div>
        {!isCardVisible && (
          <a
            className="text-info-main underline cursor-pointer lg:text-ls text-base"
            onClick={toggleCardVisibility}
          >
            What is this?
          </a>
        )}
      </>
      {isCardVisible && (
        <BBBCard className="bg-[#f8f8f8]">
          <div className="flex flex-wrap justify-evenly">
            <div className="flex flex-col w-[40%] py-2 px-4">
              <div className="w-full mx-auto text-center">
                <img
                  src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitLogin/bitlogin-google-one-tap.png`}
                  alt="Google One Tap"
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col w-[60%] py-2 px-4 my-auto">
              <div
                className="absolute top-5 right-5 rounded-full p-1 cursor-pointer"
                onClick={toggleCardVisibility}
              >
                <X />
              </div>
              <p className="mb-3">
                Google One-Tap is a feature that allows user to sign up or login
                to your website with just a single click.
              </p>
              <p className="mb-3">
                User will be able to instantly sign up for apps or web services
                with their Google credentials without creating an account.
              </p>
              <p className="mb-3">
                It creates a smooth experience for user to access your store
                without going through a series of authentication steps.
              </p>
              <a
                href="https://bitlogin.tawk.help/article/activate-google-one-tap"
                target="_blank"
                className="text-info-main underline "
                rel="noreferrer"
              >
                Complete instruction to activate Google One Tap
              </a>
            </div>
          </div>
        </BBBCard>
      )}
    </BBBCard>
  );
}

export default GoogleOneTapCard;
