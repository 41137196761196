import { useParams } from 'react-router';

import { BBBSelect } from '@/components/ui';
import { Flow } from '@/types/whatsApp/flow';

export default function FlowOptions({
  value,
  onValueChange,
  error,
  flows: _flowOptions,
}: {
  value: Flow | undefined | null;
  onValueChange: (value: Flow | null | undefined) => void;
  error?: string;
  flows: Flow[] | undefined;
}) {
  const { id } = useParams<{ id: string }>();

  const flowOptions = _flowOptions?.filter((flow) => flow.id !== id);

  return (
    <BBBSelect
      label="Select chatbot to run"
      options={flowOptions}
      optionLabel="name"
      optionValue="id"
      value={value}
      onValueChange={onValueChange}
      error={error}
      placeholder="Choose chatbot"
    />
  );
}
