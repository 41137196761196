import { useState } from 'react';
import { Download } from 'react-feather';
import {
  previewBitChatAgents,
  previewBitChatAnalytics,
  previewCustomerSatisfaction,
} from 'constants/analytics/bitChat';
import { ChannelIntegrationValues } from 'constants/integrations';
import { useCustomerSatisfactions } from 'hooks/bitChat/analytics/analytics-v3';
import useAnalyticAgentPerfomance from 'hooks/bitChat/analytics/useAnalyticAgentPerfomance';
import useAnalyticAgents from 'hooks/bitChat/analytics/useAnalyticAgents';
import useDownloadAgents from 'hooks/bitChat/analytics/useDownloadAgents';
import queryString from 'query-string';
import { AnalyticAgents } from 'types/bitChat/analytics';
import { formatAverageTime, formatDate2 } from 'utils/common/date';
import { formatDuration } from 'utils/common/time';
import { Loader } from '../Loader';

import { BBBBarChart, BBBButton, BBBOverviewBox } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import useResponsive from '@/hooks/common/useResponsive';

type Props = {
  isPreview?: boolean;
  startDate: string;
  endDate: string;
  loading?: boolean;
  channel?: ChannelIntegrationValues;
};

const columns: HeadersTypes<AnalyticAgents> = [
  {
    accesor: 'firstName',
    isSortable: true,
    renderHeader: () => 'Agent Name',
    render: (row) => {
      return (
        <div className="flex gap-4 items-center">
          <p className="text-primary-main">
            {row.firstName ? `${row.firstName} ${row.lastName}` : '-'}
          </p>
        </div>
      );
    },
  },
  {
    accesor: 'ticketHandled',
    renderHeader: () => 'Ticket handle',
    isSortable: true,
    sortOrder: 'desc',
    render: (row) => (
      <p className="text-primary-main">
        {row.ticketHandled ? row.ticketHandled : '-'}
      </p>
    ),
  },
  {
    accesor: 'responseTime.hours',
    renderHeader: () => 'Response time',
    isSortable: true,
    render: (row) => (
      <p className="text-primary-main">{formatDuration(row.responseTime)}</p>
    ),
  },
  {
    accesor: 'resolutionTime.hours',
    renderHeader: () => 'Resolution time',
    isSortable: true,
    render: (row) => (
      <p className="text-primary-main">{formatDuration(row.resolutionTime)}</p>
    ),
  },
  {
    accesor: 'rating',
    renderHeader: () => 'Rating',
    isSortable: true,
    render: (row) => <p className="text-primary-main">{row.rating || '-'}</p>,
  },
  {
    accesor: 'lastChatHandled',
    renderHeader: () => 'Last Chat Handled',
    isSortable: true,
    render: (row) => {
      return (
        <p className="text-primary-main">
          {row.lastChatHandled ? formatDate2(row.lastChatHandled) : '-'}
        </p>
      );
    },
  },
];

export function AgentPerformance({
  isPreview,
  startDate,
  endDate,
  loading,
  channel,
}: Props) {
  const isMobile = useResponsive('sm');
  const [searchAgent, setSearchAgent] = useState('');

  const { data: customerSatisfactions } = useCustomerSatisfactions(
    {
      startDate,
      endDate,
      channel,
    },
    { enabled: !loading && !isPreview }
  );

  const { data, status } = useAnalyticAgentPerfomance(
    {
      startDate,
      endDate,
      channel,
    },
    {
      enabled: !loading && !isPreview && !!channel,
    }
  );

  const { data: bitChatAgent } = useAnalyticAgents(
    {
      startDate,
      endDate,
      search: searchAgent,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const { mutate, isLoading: isMutating } = useDownloadAgents({
    startDate,
    endDate,
    search: searchAgent,
  });

  const _queryParams = queryString.stringify({ startDate, endDate });

  const queryParams = _queryParams ? '?' + _queryParams : '';

  return (
    <>
      <p className="text-primary-color text-xl">Agent performance</p>
      <div className="w-full gap-4 grid grid-cols-2 lg:grid-cols-3">
        <BBBOverviewBox
          isRedirect
          redirectUrl={`/analytics/bitchat/ticket-performance${queryParams}`}
          title="Avg. Response time"
          tooltip="How long your customers wait for your first response."
          value={
            isPreview
              ? formatAverageTime(
                  previewBitChatAnalytics.averageResponseTime.count
                )
              : formatAverageTime(data?.averageResponseTime?.count)
          }
        />
        <BBBOverviewBox
          isRedirect
          redirectUrl={`/analytics/bitchat/ticket-performance${queryParams}`}
          title="Avg. Resolution time"
          tooltip="How quickly your teammates close conversations and resolve your customers’ issues."
          value={
            isPreview
              ? formatAverageTime(
                  previewBitChatAnalytics.averageResolutionTime.count
                )
              : formatAverageTime(data?.averageResolutionTime?.count)
          }
        />
        <BBBOverviewBox
          title="Customer satisfaction"
          value={`${
            isPreview
              ? previewBitChatAnalytics.averageRating.count.toFixed(1)
              : data?.averageRating?.count || 0 > 0
              ? data?.averageRating?.count
              : 0
          }/5`}
          className="col-span-2 lg:col-span-1"
        />
        <BBBBarChart
          title="Customer satisfaction"
          tooltip="How happy your customer with the service based on their rating."
          layout="vertical"
          containerClassName="w-full col-span-3"
          lineDataKey="value"
          data={
            isPreview
              ? previewCustomerSatisfaction
              : customerSatisfactions || []
          }
          xAxisProps={{
            dataKey: 'value',
          }}
          yAxisProps={{
            dataKey: 'label',
            width: 30,
          }}
        />
      </div>
      {!isMobile && (
        <BBBTableV2
          data={
            isPreview
              ? previewBitChatAgents.filter((agent) =>
                  searchAgent
                    ? (agent.firstName || agent.lastName)
                        .toLowerCase()
                        .includes(searchAgent.toLowerCase())
                    : true
                )
              : bitChatAgent?.data
          }
          dataId="conversation"
          headers={columns}
          isColumnHeightFixed
          isSearchable
          searchPlaceholder="Search agent"
          searchValue={searchAgent}
          onChangeSearch={setSearchAgent}
          isFilterable
          renderFilterSection={() => (
            <BBBButton
              text="Download"
              variant="secondary"
              icon={<Download width={16} height={16} />}
              iconPosition="right"
              onClick={() => mutate()}
              loadingState={isMutating}
              size="sm"
            />
          )}
        />
      )}
    </>
  );
}
