import getDescendantProp from '@/types/utils/getDescendantProp';
import { Paths } from '@/types/utils/nestedObjectPath';

function BBBSelectOptionIcon<T extends Record<string, any>>({
  value,
  accesor,
}: {
  value: T;
  accesor?: Paths<T>;
}) {
  if (!accesor) return null;
  const data = getDescendantProp(value, accesor);
  if (!data) return null;
  if (typeof data === 'string') {
    return (
      <img
        loading="lazy"
        src={data}
        alt="icon"
        className="w-6 h-6 !max-w-none rounded-full object-contain"
      />
    );
  }
  return data as React.ReactElement;
}

export default BBBSelectOptionIcon;
