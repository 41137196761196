import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import AppProvider from 'provider/AppProvider';
import GlobalCropImageModal from 'components/GlobalCropImageModal';
import IntegrationModal from 'components/IntegrationModal/IntegrationModal';
import { BBBToastContainer } from 'components/ui/BBBToast';
import ChoosePaymentMethodModal from './components/choose-payment-method-modal';
import Routes from './routes';

import { env } from '@/config/env';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-KQ6RZZG',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: import.meta.env.MODE !== 'development',
  replaysSessionSampleRate:
    env.REACT_APP_NODE_ENV === 'development' ? 1.0 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: cancelled',
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    'TypeError: Importing a module script failed.',
    'Error: NotAllowedError: Document is not focused.',
    "TypeError: undefined is not an object (evaluating '__gCrWeb.instantSearch.setIOSParameters')",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

function App() {
  window.dataLayer.push({
    event: 'pageview',
  });

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <AppProvider>
      <IntegrationModal />
      <BBBToastContainer />
      <GlobalCropImageModal />
      <ChoosePaymentMethodModal />
      <Routes />
    </AppProvider>
  );
}

export default App;
