function RepliedIcon({
  width = 17,
  height = 15,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 0.958008L1.375 4.91634L5.33333 8.87467"
        stroke="#262627"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.375 4.91602H7.70833C12.0807 4.91602 15.625 8.46031 15.625 12.8327V13.6243"
        stroke="#262627"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RepliedIcon;
