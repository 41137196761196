import { useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'react-feather';
import { XAxisProps, YAxisProps } from 'recharts';
import { twMerge as cx } from 'tailwind-merge';
import BBBComposedChart, { BBBComposedChartProps } from './BBBComposedChart';

import { BBBTooltip } from '@/components/ui/BBBTooltip';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import { Paths } from '@/types/utils/nestedObjectPath';

type Option = {
  label: string;
  value: string;
};

export type BBBComposedChartMultiTabProps<
  T extends {
    [k: string]: unknown;
  }
> = Omit<
  BBBComposedChartProps<T>,
  | 'data'
  | 'xAxisProps'
  | 'yAxisProps'
  | 'lineDataKeyBar'
  | 'lineDataKeyGraph'
  | 'isHasAdditionalBar'
  | 'lineDataKeyAdditionalBar'
> & {
  isTabChart?: true;
  options: Option[];
  xAxisDataKey: (activeTab: Option) => XAxisProps['dataKey'];
  yAxisDataKey?: (activeTab: Option) => YAxisProps['dataKey'];
  lineDataKeyBar: (activeTab: Option) => Paths<T>;
  lineDataKeyGraph: (activeTab: Option) => Paths<T>;
  data: (activeTab: Option) => T[] | undefined;
  isHasAdditionalBar: (activeTab: Option) => boolean;
  lineDataKeyAdditionalBar: (activeTab: Option) => Paths<T>;
};

const BBBComposedChartMultiTab = <
  T extends {
    [k: string]: unknown;
  }
>({
  data,
  xAxisDataKey,
  yAxisDataKey,
  lineDataKeyBar,
  lineDataKeyGraph,
  isHasAdditionalBar,
  lineDataKeyAdditionalBar,
  tooltip,
  ...props
}: BBBComposedChartMultiTabProps<T>) => {
  const isMobile = useResponsive('sm');

  const [showOptions, setShowOptions] = useState(false);
  const [activeTab, setActiveTab] = useState<Option>(props.options?.[0]);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideAlerter(ref, () => {
    setShowOptions(false);
  });

  const handleChangeTab = (option: Option) => {
    setShowOptions(false);
    setActiveTab(option);
    xAxisDataKey?.(activeTab);
    yAxisDataKey?.(activeTab);
    lineDataKeyBar?.(activeTab);
    lineDataKeyGraph?.(activeTab);
    isHasAdditionalBar?.(activeTab);
    lineDataKeyAdditionalBar?.(activeTab);
    data?.(activeTab);
  };

  if (isMobile) return null;

  return (
    <BBBComposedChart
      customHeaders={
        <div
          className="relative flex gap-1 items-center w-fit cursor-pointer"
          onClick={() => setShowOptions(!showOptions)}
        >
          <p className="text-base font-normal whitespace-nowrap">
            {activeTab.label}
          </p>
          {showOptions ? (
            <ChevronUp size={14} color="#262627" />
          ) : (
            <ChevronDown size={14} color="#262627" />
          )}
          {tooltip && (
            <BBBTooltip
              show={!!tooltip}
              content={tooltip}
              className="bottom-[95%]"
            >
              <Info size={14} color="#9E9E9E" />
            </BBBTooltip>
          )}
          {showOptions && (
            <div
              ref={ref}
              className="absolute top-6 w-48 bg-neutral-10 overflow-clip rounded-lg transition-all shadow-md flex flex-col z-[999] border border-grayColor2"
            >
              {props.options?.map((option) => (
                <div
                  key={option.value}
                  className={cx(
                    'text-base text-primary-main hover:bg-secondary-surface px-4 py-2',
                    option.value === activeTab.value && 'bg-secondary-surface'
                  )}
                  onClick={() => handleChangeTab(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      }
      data={data?.(activeTab)}
      xAxisProps={{
        dataKey: xAxisDataKey?.(activeTab),
      }}
      yAxisProps={{
        dataKey: yAxisDataKey?.(activeTab),
        interval: 0,
        domain: [0, 'dataMax'],
      }}
      lineDataKeyBar={lineDataKeyBar?.(activeTab)}
      lineDataKeyGraph={lineDataKeyGraph?.(activeTab)}
      isHasAdditionalBar={isHasAdditionalBar?.(activeTab)}
      lineDataKeyAdditionalBar={lineDataKeyAdditionalBar?.(activeTab)}
      {...props}
    />
  );
};

export default BBBComposedChartMultiTab;
