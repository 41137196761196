import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

const useSocialConfig = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    ['bitlogin-config', data?.domain],
    () =>
      services.bitLogin.configs.getSocialConfig(data?.domain, {
        deviceType: 'web',
      }),
    {
      enabled: status === 'success' && !!data,
    }
  );
};

export default useSocialConfig;
