import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { useAccessToken } from '@/hooks/rtk/selector';
import { UserWithAssociation } from '@/types/systemLogin/association';

const useAuthenticatedUser = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    UserWithAssociation,
    unknown,
    UserWithAssociation,
    (string | null)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const accessToken = useAccessToken();

  return useQuery(
    ['authenticated-user', accessToken],
    () => services.systemLogin.getAuthenticatedUser(),
    {
      enabled: !!accessToken && enabled,
      ...options,
    }
  );
};

export default useAuthenticatedUser;
