import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { unescape } from 'lodash-es';

export const convertEditorStateToHtml = (editorState?: EditorState) =>
  editorState &&
  (editorState.getCurrentContent().hasText()
    ? unescape(
        convertToHTML({
          blockToHTML: (block) => {
            if (!block.text) return <br />;
            return <p />;
          },
        })(editorState.getCurrentContent())
      )
    : undefined);

export const convertHtmlToEditorState = (html?: string) => {
  if (!html) return emptyEditor;

  const blocksFromHTML = convertFromHTML(html);
  return EditorState.createWithContent(blocksFromHTML);
};

export const formatSelectionToEnd = (editorState: EditorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  return EditorState.forceSelection(editorState, selection);
};

const TEMPLATE_DYNAMIC_VARIABLE_REGEX = /\{\{.*?\}\}/g;

const templateDynamicVariableStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  findWithRegex(TEMPLATE_DYNAMIC_VARIABLE_REGEX, contentBlock, callback);
};

function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText();
  let matchArr, start;

  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

//@ts-ignore
const DynamicVariableBlock = (props) => {
  return (
    <span {...props} className="text-secondary-main">
      {props.children}
    </span>
  );
};

const compositeDecorator = new CompositeDecorator([
  {
    strategy: templateDynamicVariableStrategy,
    component: DynamicVariableBlock,
  },
]);

const generateEmpty = () => EditorState.createEmpty(compositeDecorator);

export const emptyEditor = generateEmpty();

export const insertText = (editorState: EditorState, _textToInsert: string) => {
  // Add whitespace at end of text
  const textToInsert = _textToInsert + ' ';

  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  const textWithInsert = Modifier.insertText(
    currentContent,
    currentSelection,
    textToInsert
  );

  // Create new state while preserving the decorator
  const newState = EditorState.push(
    editorState,
    textWithInsert,
    'insert-characters'
  );

  const nextOffSet = currentSelection.getFocusOffset() + textToInsert.length;

  const newSelection = currentSelection.merge({
    focusOffset: nextOffSet,
    anchorOffset: nextOffSet,
  });

  return EditorState.acceptSelection(newState, newSelection);
};
