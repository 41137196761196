import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { CommonStore, toggleShopifyConnectModal } from '@/stores/common';

const useShopifyConnectModal = () => {
  const show = useAppSelector((state) => state.common.shopifyConnectModalShow);
  const dispatch = useAppDispatch();

  return {
    show,
    setShow: (payload: CommonStore['shopifyConnectModalShow']) => {
      dispatch(toggleShopifyConnectModal(payload));
    },
  };
};

export default useShopifyConnectModal;
