import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetCampaignLogParams } from '@/api/services/bitCRM/v2/campaign';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useCampaignLog = (
  campaignId: string | undefined,
  params: GetCampaignLogParams
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-campaign-log', activeCompany, campaignId, params],
    () =>
      services.bitCRM.v2.campaign.getCampaignLog(
        activeCompany,
        campaignId,
        params
      ),
    {
      keepPreviousData: true,
    }
  );
};

export default useCampaignLog;
