import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { WaWidget, WaWidget2 } from '@/types/bitChat/waWidget';
import { toast } from '@/utils/common/toast';

const useEditWaWidget = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    async (payload: Partial<WaWidget2> & { shopifyDomain?: string }) =>
      services.bitChat.widget.updateWidget(activeCompany, payload),
    {
      onMutate: async ({ shopifyDomain, ...payload }) => {
        await client.cancelQueries(['wa-widget', activeCompany]);
        const previousWaWidget = client.getQueryData<Partial<WaWidget>>([
          'wa-widget',
          activeCompany,
        ]);

        client.setQueryData<Partial<WaWidget2>>(
          ['wa-widget', activeCompany],
          (old) => ({
            ...(old || {}),
            ...payload,
          })
        );

        return {
          previousWaWidget,
        };
      },
      onSuccess: () => {
        toast.success('Your chat widget settings updated');
        client.invalidateQueries(['wa-widget']);
      },
      onError: (err, newTodo, context) => {
        client.setQueryData(
          ['wa-widget', activeCompany],
          context?.previousWaWidget
        );
      },
    }
  );
};

export default useEditWaWidget;
