import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { Campaign } from '@/types/bitCRM/campaign';

const useCampaignDetail = (
  id: string | undefined,
  options?:
    | Omit<
        UseQueryOptions<
          Campaign,
          unknown,
          Campaign,
          (string | number | undefined)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-campaign-detail', activeCompany, id],
    () => services.bitCRM.v2.campaign.getCampaignDetail(activeCompany, id),
    options
  );
};

export default useCampaignDetail;
