import React from 'react';

function AppleIcon({ color = '#FFF' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill={color}
        d="M15.415 22.08c-1.152 1.21-2.422 1.021-3.633.45-1.288-.581-2.465-.618-3.825 0-1.693.792-2.592.562-3.611-.45C-1.412 15.663-.562 5.888 5.98 5.52c1.588.092 2.699.95 3.634 1.021 1.389-.306 2.719-1.182 4.206-1.067 1.787.156 3.123.92 4.016 2.293-3.676 2.392-2.805 7.636.571 9.108-.675 1.92-1.542 3.818-2.995 5.221l.002-.016zM9.487 5.45C9.315 2.6 11.45.254 13.907.024c.337 3.289-2.763 5.75-4.42 5.428z"
      ></path>
    </svg>
  );
}

export default AppleIcon;
