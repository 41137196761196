import { PublishStatus } from 'types/bitApp/v2';

export const submissionStatus: { label: string; value: PublishStatus }[] = [
  {
    label: 'Submitted',
    value: 'submitted',
  },
  {
    label: 'Submitted',
    value: 'submittedToStore',
  },
  {
    label: 'In review',
    value: 'review',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Your app is now live!',
    value: 'live',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

export const submissionOptionsInternal: {
  label: string;
  value: PublishStatus;
}[] = [
  {
    label: 'Submitted',
    value: 'submittedToStore',
  },
  {
    label: 'Review',
    value: 'review',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Live',
    value: 'live',
  },
];
