/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState } from 'react';
import { X } from 'react-feather';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import AlertIcon2 from '@/assets/icons/AlertIcon2';
import { Accent } from '@/types/utils/color';

export type IBBBAlert = {
  message?: string | React.ReactNode;
  type?: Accent;
  onClick?: () => void;
  pressable?: boolean;
  className?: string;
  children?: React.ReactNode;
  closable?: boolean;
  isIconOutline?: boolean;
  iconSize?: number;
};

function BBBAlert({
  message = 'Data not found',
  type = 'warning',
  onClick = () => ({}),
  pressable = false,
  className = '',
  children,
  closable = false,
  isIconOutline = false,
  iconSize = 20,
}: IBBBAlert) {
  const [show, setShow] = useState(true);
  if (!show) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={cx(
        'px-6 py-4 rounded-2xl flex w-full justify-center relative items-center gap-4 border',
        pressable && 'cursor-pointer',
        getVariantClassNames(type),
        className
      )}
      onClick={() => onClick()}
    >
      {closable && (
        <div
          className="absolute -top-2 -right-2 bg-red-50 rounded-full p-1 cursor-pointer"
          onClick={() => {
            setShow(false);
          }}
        >
          <X />
        </div>
      )}
      <div className="flex-none">
        <AlertIcon2 type={type} size={iconSize} isOutline={isIconOutline} />
      </div>
      <div className="text-sm lg:text-base">{children || message}</div>
    </motion.div>
  );
}

export default BBBAlert;

export function getVariantClassNames(type: Accent) {
  if (type === 'warning') {
    return 'bg-yellow-200 text-yellow-600 border-yellow-600';
  }
  if (type === 'danger') {
    return 'bg-danger-surface text-primary-main border-danger-main';
  }
  if (type === 'success') {
    return 'bg-green-200 text-green-600 border-green-600';
  }

  if (type === 'secondary') {
    return 'bg-secondary-surface text-primary-main border-secondary-main';
  }

  if (type === 'info') {
    return 'bg-info-surface border-transparent';
  }
}
