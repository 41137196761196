import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { env } from 'config/env';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import { useActiveCompany } from 'hooks/rtk/selector';
import { socketPricing } from 'socket';
import useShopifyIntegrationByApp from '../shopify/useShopifyIntegrationByApp';
import usePricingBillingUrl from './usePricingBillingUrl';
import usePricingByApp from './usePricingByApp';

import { PlanType, PricingPlan } from '@/api/services/pricing';
import { AppType } from '@/types/systemLogin';

const eligibleForFreeTrial:
  | Record<string, { trialDuration: Partial<Record<AppType, number>> }>
  | undefined = env.REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY
  ? JSON.parse(env.REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY)
  : undefined;

const useRedirectUpgradePlan = ({
  onSuccess,
  appType,
  type = 'EVERY_30_DAYS',
}: {
  onSuccess?: () => void;
  appType: AppType;
  type: PlanType;
}) => {
  const { data: shopifyMeta } = useShopifyIntegrationByApp(appType);
  const { data: pricingData } = usePricingByApp(appType);

  const activeCompany = useActiveCompany();

  const _billingUrl = usePricingBillingUrl({
    appType,
    type,
  });

  const { toggle, hide } = useConfirmationBanner();

  const queryClient = useQueryClient();

  const onPricingEnd = useCallback(
    ({ app }: { app: AppType }) => {
      hide(`${app}-pricing`);

      onSuccess?.();

      // delay by consumer
      setTimeout(() => {
        queryClient.invalidateQueries([`pricing-v2`]);
      }, 2000);
    },
    [onSuccess, queryClient, hide]
  );

  useEffect(() => {
    socketPricing.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  useEffect(() => {
    socketPricing.on(`pricing-billing-callback-success`, onPricingEnd);

    return () => {
      socketPricing.off(`pricing-billing-callback-success`, onPricingEnd);
    };
  }, [onPricingEnd]);

  useEffect(() => {
    socketPricing.on(`pricing-billing-callback-cancelled`, onPricingEnd);

    return () => {
      socketPricing.off(`pricing-billing-callback-cancelled`, onPricingEnd);
    };
  }, [onPricingEnd]);

  const confirm = useConfirmationModal();

  return {
    fn: (
      plan: Exclude<PricingPlan, 'free' | 'enterprise'>,
      method: 'shopify' | 'stripe'
    ) => {
      const billingUrl = (method: 'shopify' | 'stripe', trialDays?: number) => {
        let baseUrl = `${_billingUrl}&plan=${plan}&provider=${method}`;

        if (trialDays) {
          baseUrl += `&trialDays=${trialDays}`;
        }

        if (method === 'shopify' && shopifyMeta?.domain) {
          baseUrl += `&domain=${shopifyMeta.domain}`;
        }

        return baseUrl;
      };

      if (eligibleForFreeTrial) {
        const companyId = activeCompany.toString();

        const eligible = Object.keys(eligibleForFreeTrial).includes(companyId);

        if (
          eligible &&
          eligibleForFreeTrial[companyId].trialDuration[appType] &&
          pricingData?.hasFreeTrialQuota
        ) {
          const days = eligibleForFreeTrial[companyId].trialDuration[appType]!;

          const daysToMonths = days / 30;

          confirm({
            title: `${
              daysToMonths > 1 ? daysToMonths + ' Months' : days + ' Days'
            } free trial`,
            description: `Hello Early Adopter! Enjoy a free ${
              daysToMonths > 1 ? daysToMonths + '-month' : days + '-day'
            } trial of our ${plan} Plan, packed with powerful bitbybit features. Start growing your business today!`,
            submitText: 'Upgrade now',
            cancelText: 'Later',
            onAccept: (hide) => {
              _fn(days);
              hide();
            },
          });
        } else {
          _fn();
        }
      } else {
        _fn();
      }

      function _fn(trialDays?: number) {
        toggle(`${appType}-pricing`, {
          show: true,
          text: 'Waiting for payment',
          variant: 'loading',
        });

        window.open(billingUrl(method, trialDays), '_blank');
      }
    },
  };
};

export default useRedirectUpgradePlan;
