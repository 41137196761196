import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';
import { LiveChatSettings } from './LivechatSettings';

import {
  BBBCard,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import { autoResolveOptions } from '@/constants/bitChat/settings';

type Props = {
  control: Control<LiveChatSettings>;
};

function AutoResolveTicket({ control }: Props) {
  const autoResolveTicket = useWatch({
    control,
    name: 'autoResolveTicket',
  });

  const { errors } = useFormState({
    control,
    name: 'autoResolveTicket',
  });

  return (
    <BBBCard
      title="Auto resolve ticket"
      desc="Resolve your tickets automatically by interval of time"
      rightButton={
        <Controller
          control={control}
          name="autoResolveTicket"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      }
      className="mb-cardBottom"
    >
      <div
        className={cx(
          'flex gap-2 items-center',
          !autoResolveTicket && 'opacity-25 pointer-events-none'
        )}
      >
        <div>Auto resolve ticket after</div>
        <BBBTextInput
          placeholder="Number of time"
          containerClassname="mb-0"
          isHookForm
          control={control}
          controlName="autoResolveTime"
          error={errors.autoResolveTime?.message}
          type="number"
        />
        <Controller
          control={control}
          name="autoResolveType"
          render={({ field }) => (
            <BBBSelect
              options={autoResolveOptions}
              optionLabel="label"
              optionValue="value"
              value={field.value}
              onValueChange={(opt) => field.onChange(opt!)}
              error={errors.autoResolveType?.message}
            />
          )}
        />
      </div>
    </BBBCard>
  );
}

export default AutoResolveTicket;
