import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ItemCard from '../ItemCard';
import MultipleFields from './Fields/MultipleFields';
import SingleTextField from './Fields/SingleTextField';

import {
  FieldType,
  LoginSetting,
  Orientation,
} from '@/api/services/bitLogin/login/setting';
import DropdownIcon from '@/assets/icons/DropdownIcon';
import LineTextIcon from '@/assets/icons/LineTextIcon';
import MultipleIcon from '@/assets/icons/MultipleIcon';
import PlusIcon from '@/assets/icons/PlusIcon';
import RadioButtonIcon from '@/assets/icons/RadioButtonIcon';
import { BBBButton, BBBCard, BBBModal } from '@/components/ui';
import { orientationOptionsHash } from '@/constants/bitLogin/additional-data';
import {
  useDeleteLoginSetting,
  useLoginSetting,
  useTotalCollectDataPage,
  useUpsertLoginSetting,
} from '@/hooks/bitLogin/login/login-settings';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setCollectDataPage } from '@/stores/bitLogin';

const fieldOptions: Array<{
  name: FieldType;
  icon: React.ReactNode;
  icon2: React.ReactNode;
  label: string;
}> = [
  {
    name: 'single_line_text',
    icon: <LineTextIcon />,
    icon2: <LineTextIcon size={24} />,
    label: 'Single-line text',
  },
  {
    name: 'multi_line_text',
    icon: <LineTextIcon />,
    icon2: <LineTextIcon size={24} />,
    label: 'Multi-line text',
  },
  {
    name: 'dropdown_list',
    icon: <DropdownIcon />,
    icon2: <DropdownIcon size={24} />,
    label: 'Dropdown list',
  },
  {
    name: 'radio_button',
    icon: <RadioButtonIcon />,
    icon2: <RadioButtonIcon size={24} />,
    label: 'Radio button',
  },
  {
    name: 'multiple_choice',
    icon: <MultipleIcon />,
    icon2: <MultipleIcon size={24} />,
    label: 'Multiple choice',
  },
];

export const fieldOptionsHash = Object.fromEntries(
  fieldOptions.map((d) => [d.name, d])
);

export default function CustomFieldCard() {
  const [customField, setCustomField] = useState<
    | {
        data: LoginSetting | FieldType;
        index: number;
      }
    | undefined
  >();

  const [showAddCustomField, setShowAddCustomField] = useState(false);

  const mappedCustomFields = useLoginSetting('custom');

  const customFieldSorted = mappedCustomFields?.sort((a, b) => {
    const aIndex = a.index ?? Infinity;
    const bIndex = b.index ?? Infinity;

    return aIndex - bIndex;
  });

  const { mutate: updateLoginSetting } = useUpsertLoginSetting();

  const totalPage = useTotalCollectDataPage();
  const dispatch = useAppDispatch();

  return (
    <>
      <BBBCard
        title="Custom fields"
        desc="Add customizable fields to your form"
        rightButton={
          !!mappedCustomFields?.length && (
            <BBBButton
              onClick={() => setShowAddCustomField(true)}
              variant="secondary"
              text="Add new field"
              className="w-fit"
              icon={<PlusIcon />}
              iconPosition="right"
            />
          )
        }
      >
        <div className="flex flex-col gap-4">
          {customFieldSorted?.map((field, index) => (
            <ItemCard
              key={field.key || field.type}
              icon={fieldOptionsHash[field.type].icon2}
              title={field.label}
              subtitle={fieldOptionsHash[field.type].label}
              active={field.active || false}
              onActiveChange={(val) =>
                updateLoginSetting({
                  active: val,
                  key: field.key,
                  type: field.type,
                })
              }
              hasRequired
              required={field.mandatory || false}
              onRequiredChange={(val) =>
                updateLoginSetting({
                  mandatory: val,
                  key: field.key,
                  type: field.type,
                })
              }
              onClick={() => {
                setCustomField({
                  data: field,
                  index,
                });
              }}
            />
          ))}
        </div>
        {!mappedCustomFields?.length && (
          <div
            onClick={() => {
              setShowAddCustomField(true);
            }}
            className="py-7 flex rounded-2xl items-center gap-2 justify-center border border-neutral-30 shadow shadow-[0px 3px 6px 0px rgba(155, 155, 155, 0.15)] cursor-pointer hover:border-2 hover:border-secondary-main transition-all"
          >
            <PlusIcon color="#FD823E" />
            <p className="text-secondary-main font-semibold">
              Add your own custom fields
            </p>
          </div>
        )}
      </BBBCard>
      {showAddCustomField && (
        <ChooseFieldModal
          onHide={() => {
            setCustomField(undefined);
            setShowAddCustomField(false);
          }}
          onSubmit={(name) => {
            setCustomField({
              data: name,
              index: customFieldSorted?.length || 1,
            });

            setShowAddCustomField(false);
          }}
        />
      )}
      {customField && (
        <FieldFormModal
          onHide={() => {
            setCustomField(undefined);
          }}
          onSuccess={() => {
            setCustomField(undefined);
            dispatch(setCollectDataPage(totalPage));
          }}
          field={customField.data}
          index={customField.index}
        />
      )}
    </>
  );
}

export type FieldForm = {
  label: string;
  mandatory: boolean;
  type: FieldType;
  options: { value: string }[];
  orientation: { value: string; label: string } | null;
};

function FieldFormModal({
  onHide,
  field,
  index,
  onSuccess,
}: {
  onHide: () => void;
  field: LoginSetting | FieldType;
  index: number;
  onSuccess: () => void;
}) {
  const { mutate: updateLoginSetting } = useUpsertLoginSetting();
  const { mutate: deleteLoginSetting } = useDeleteLoginSetting();

  const defaultValues: FieldForm =
    typeof field === 'object'
      ? {
          label: field.label || '',
          mandatory: !!field.mandatory,
          type: field.type,
          options: field.options?.length
            ? field.options?.map((opt) => ({ value: opt }))
            : [{ value: '' }, { value: '' }, { value: '' }],
          orientation: field.orientation
            ? orientationOptionsHash[field.orientation]
            : null,
        }
      : {
          label: '',
          mandatory: false,
          type: field,
          options: [{ value: '' }, { value: '' }, { value: '' }],
          orientation: orientationOptionsHash['vertical'],
        };

  const { control, handleSubmit } = useForm<FieldForm>({
    defaultValues,
  });

  const type = typeof field === 'object' ? field.type : field;

  return (
    <BBBModal
      show
      size="2xl"
      onHide={() => {
        onHide();
      }}
      title={<p className="font-semibold">{fieldOptionsHash[type].label}</p>}
      footer
      submitText="Save"
      handleSave={() => {
        handleSubmit((data) => {
          updateLoginSetting(
            {
              group: 'custom',
              type: data.type,
              orientation: data.orientation!.value as Orientation,
              options: data.options
                .filter((opt) => opt.value)
                .map((option) => option.value),
              mandatory: data.mandatory,
              label: data.label,
              ...(typeof field === 'object' && {
                key: field.key,
              }),
              index,
              ...(typeof field !== 'object' && {
                active: true,
              }),
            },
            {
              onSuccess: () => {
                onSuccess();
              },
            }
          );
        })();
      }}
      handleDelete={() => {
        if (typeof field === 'object') {
          deleteLoginSetting(
            { key: field.key },
            {
              onSuccess: () => {
                onHide();
              },
            }
          );
        }
      }}
    >
      {type === 'single_line_text' || type === 'multi_line_text' ? (
        <SingleTextField control={control} />
      ) : (
        <MultipleFields control={control} />
      )}
    </BBBModal>
  );
}

function ChooseFieldModal({
  onHide,
  onSubmit,
}: {
  onHide: () => void;
  onSubmit: (name: FieldType) => void;
}) {
  return (
    <BBBModal
      show
      size="lg"
      onHide={() => {
        onHide();
      }}
      title="Choose field"
      subtitle="Select the field that you want for your custom fields"
    >
      <div className="flex flex-wrap gap-5 items-center justify-center">
        {fieldOptions.map((option) => (
          <FieldOption
            key={option.name}
            label={option.label}
            icon={option.icon}
            onClick={() => {
              onSubmit(option.name);
            }}
          />
        ))}
      </div>
    </BBBModal>
  );
}

function FieldOption({
  label,
  icon,
  onClick,
}: {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className="w-[8.125rem] h-[8.125rem] rounded-xl flex flex-col py-5 items-center justify-center gap-3 border-2 border-neutral-30 hover:border-secondary-main transition-all cursor-pointer"
    >
      {icon}
      <p className="text-neutral-60 text-center w-4/5 mx-auto leading-4">
        {label}
      </p>
    </div>
  );
}
