import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { WACloudTemplate } from '@/types/whatsApp/settings';

const useWACloudTemplates = (
  params?: {
    status?: WACloudTemplate['status'];
    page?: number;
    size?: number;
    search?: string;
    type?: string | null;
    sort?: string | null;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-wa-cloud-templates', activeCompany, params],
    () => services.bitChat.template.getWACloudTemplates(activeCompany, params),
    options
  );
};

export default useWACloudTemplates;
