import { ModelCondition } from '@/api/services/bitAi/v2-new/model';
import { BBBSelect } from '@/components/ui';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

const modelRuleOptions: (
  | {
      label: string;
      value: ModelCondition;
      parentValue: 'basic' | 'advance';
    }
  | {
      label: string;
      value: string;
    }
)[] = [
  { label: 'Basic', value: 'basic' },
  {
    label: 'Top seller',
    value: 'TOP_SELLER',
    parentValue: 'basic',
  },
  {
    label: 'Top revenue',
    value: 'TOP_REVENUE',
    parentValue: 'basic',
  },
  {
    label: 'Highest discount',
    value: 'HIGHEST_DISCOUNT',
    parentValue: 'basic',
  },
  {
    label: 'Highest discount percentage',
    value: 'HIGHEST_DISCOUNT_PERCENTAGE',
    parentValue: 'basic',
  },
  {
    label: 'Highest stock',
    value: 'HIGHEST_STOCK',
    parentValue: 'basic',
  },
  {
    label: 'New product',
    value: 'NEWEST_PRODUCT',
    parentValue: 'basic',
  },
  { label: 'Advance', value: 'advance' },
  {
    label: 'Highest Margin (%)',
    value: 'HIGHEST_MARGIN_PERCENTAGE',
    parentValue: 'advance',
  },
  {
    label: 'Highest Margin ($)',
    value: 'HIGHEST_MARGIN',
    parentValue: 'advance',
  },
];

const modelRuleHash = Object.fromEntries(
  modelRuleOptions.map((ruleOpt) => [ruleOpt.value, ruleOpt])
);

export default function ModelRuleOptions({
  value: _value,
  onValueChange,
}: {
  value: ModelCondition;
  onValueChange: (val: ModelCondition) => void;
}) {
  const { data: pricingData } = usePricingByApp('BITAI');

  const pricingName = pricingData?.pricingName || 'free';

  const options =
    pricingName === 'free'
      ? modelRuleOptions.map((rule) => ({
          ...rule,
          //@ts-ignore
          disabled: rule.parentValue === 'advance' || rule.value === 'advance',
        }))
      : modelRuleOptions;

  const value = modelRuleHash[_value];

  const confirm = useConfirmationModal();

  return (
    <BBBSelect
      options={options}
      optionLabel="label"
      optionValue="value"
      optionGroupKey="parentValue"
      isGrouped
      value={value}
      onValueChange={(opt) => {
        //@ts-ignore
        if (opt!.value === 'advance' || opt!.parentValue === 'advance') {
          confirm({
            title: 'Proceed to bitAI Pricing',
            description:
              "Are you sure want to visit the bitAI Pricing page? You'll see our latest plans and pricing details.",
            submitText: 'Yes',
            submitLink: '/pricing?tab=bitai',
            onAccept: (hide) => hide(),
          });
        } else {
          onValueChange(opt!.value as ModelCondition);
        }
      }}
      //@ts-ignore
      optionDisabled="disabled"
      bypassDisableClickHandler
    />
  );
}
