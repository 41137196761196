import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetOrderRecommendationParams } from '@/api/services/bitChat/livechat';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { activeChatSelector } from '@/hooks/whatsApp/useActiveChatMemo';

const useProductRecommendation = (params: GetOrderRecommendationParams) => {
  const activeCompany = useActiveCompany();

  const selectedChat = useAppSelector(activeChatSelector);

  return useQuery(
    [
      'product-recommendation',
      activeCompany,
      {
        phone: selectedChat,
        search: params.search,
      },
    ],
    () => {
      return services.bitChat.livechat.getOrderRecommendation(activeCompany, {
        phone: selectedChat!.clientNumber,
        search: params.search,
      });
    },
    {
      enabled: !!selectedChat,
    }
  );
};

export default useProductRecommendation;
