import React from 'react';

export default function DropdownIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3333 32.4533C26.5792 32.4533 32.4533 26.5793 32.4533 19.3333C32.4533 12.0873 26.5792 6.21331 19.3333 6.21331C12.0873 6.21331 6.21325 12.0873 6.21325 19.3333C6.21325 26.5793 12.0873 32.4533 19.3333 32.4533ZM19.3333 35.3333C28.1698 35.3333 35.3333 28.1699 35.3333 19.3333C35.3333 10.4968 28.1698 3.33331 19.3333 3.33331C10.4967 3.33331 3.33325 10.4968 3.33325 19.3333C3.33325 28.1699 10.4967 35.3333 19.3333 35.3333Z"
        fill="black"
      />
      <path
        d="M20.6263 24.9933C20.0291 25.98 18.6375 25.98 18.0402 24.9933L13.1675 16.9424C12.5327 15.8936 13.2629 14.5333 14.4605 14.5333H24.206C25.4037 14.5333 26.1338 15.8936 25.499 16.9424L20.6263 24.9933Z"
        fill="black"
      />
    </svg>
  );
}
