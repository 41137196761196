import SparklesIcon from 'assets/icons/SparklesIcon';
import TagIcon from 'assets/icons/TagIcon';
import { CurveType } from 'recharts/types/shape/Curve';

export const dummyAnalyticsKey: {
  color: string;
  key: string;
  type: CurveType;
}[] = [
  {
    color: '#FD823E',
    key: 'occasion',
    type: 'monotone',
  },
  {
    color: '#FD823E',
    key: 'payment',
    type: 'basis',
  },
  {
    color: '#2699FB',
    key: 'product',
    type: 'monotone',
  },
  {
    color: '#2699FB',
    key: 'language',
    type: 'basis',
  },
  {
    color: '#8F68C0',
    key: 'intention',
    type: 'monotone',
  },
];

export const mappedAiTagKey: Record<
  typeof dummyAnalyticsKey[0]['key'],
  string
> = {
  occasion: 'Occasion: Wedding',
  payment: 'Payment',
  product: 'Product: Papan bunga',
  language: 'Language: English',
  intention: 'Intention: Order',
} as const;

export const mappedOverviewAiTags = [
  {
    title: 'Total tags generated (all)',
    tooltip: (
      <p>
        This number represents the total of{' '}
        <span className="font-bold">both</span> user-defined tags and
        AI-suggested tags automatically assigned by AI.
      </p>
    ),
    value: '18,392',
  },
  {
    title: (
      <div className="flex items-center gap-1">
        <TagIcon />
        <p className="text-primary-main">Total user-defined tags</p>
      </div>
    ),
    tooltip: (
      <p>
        Total number of user-defined tags. User-defined tags are the custom
        labels that you defined with intentions and values, and automatically
        assigned by AI to all tickets.
      </p>
    ),
    value: '1,528',
  },
  {
    title: (
      <div className="flex items-center gap-1">
        <SparklesIcon size={16} color="#8F68C0" />
        <p className="text-primary-main">Total AI suggested tags</p>
      </div>
    ),
    tooltip: (
      <p>
        Total number of tags automatically suggested by AI based on the content
        of analyzed chats. These tags are single words describing key topics or
        actions within the chat, and are not predefined by you.
      </p>
    ),
    value: '1,528',
  },
  {
    title: 'Avg. AI tags per day',
    tooltip: null,
    value: '214',
  },
];

export const dummyAiTagsGraphData = [
  {
    date: 'May 11',
    occasion: 25,
    payment: 30,
    product: 20,
    language: 35,
    intention: 40,
  },
  {
    date: 'May 13',
    occasion: 30,
    payment: 40,
    product: 25,
    language: 40,
    intention: 45,
  },
  {
    date: 'May 15',
    occasion: 35,
    payment: 50,
    product: 30,
    language: 45,
    intention: 50,
  },
  {
    date: 'May 17',
    occasion: 40,
    payment: 60,
    product: 35,
    language: 50,
    intention: 55,
  },
  {
    date: 'May 19',
    occasion: 45,
    payment: 70,
    product: 40,
    language: 55,
    intention: 60,
  },
  {
    date: 'May 21',
    occasion: 50,
    payment: 80,
    product: 45,
    language: 60,
    intention: 65,
  },
  {
    date: 'May 23',
    occasion: 55,
    payment: 90,
    product: 50,
    language: 65,
    intention: 70,
  },
  {
    date: 'May 25',
    occasion: 60,
    payment: 100,
    product: 55,
    language: 70,
    intention: 75,
  },
  {
    date: 'May 27',
    occasion: 65,
    payment: 110,
    product: 60,
    language: 75,
    intention: 80,
  },
  {
    date: 'May 29',
    occasion: 70,
    payment: 120,
    product: 65,
    language: 80,
    intention: 85,
  },
];

export const dummyAiTagsGraphData2 = [
  {
    date: 'May 11',
    occasion: 28,
    payment: 33,
    product: 24,
    language: 36,
    intention: 41,
  },
  {
    date: 'May 13',
    occasion: 32,
    payment: 42,
    product: 27,
    language: 38,
    intention: 47,
  },
  {
    date: 'May 15',
    occasion: 38,
    payment: 49,
    product: 33,
    language: 46,
    intention: 52,
  },
  {
    date: 'May 17',
    occasion: 41,
    payment: 57,
    product: 37,
    language: 53,
    intention: 56,
  },
  {
    date: 'May 19',
    occasion: 47,
    payment: 65,
    product: 41,
    language: 59,
    intention: 61,
  },
  {
    date: 'May 21',
    occasion: 52,
    payment: 78,
    product: 48,
    language: 64,
    intention: 68,
  },
  {
    date: 'May 23',
    occasion: 54,
    payment: 88,
    product: 53,
    language: 66,
    intention: 72,
  },
  {
    date: 'May 25',
    occasion: 60,
    payment: 95,
    product: 58,
    language: 73,
    intention: 76,
  },
  {
    date: 'May 27',
    occasion: 63,
    payment: 105,
    product: 61,
    language: 77,
    intention: 82,
  },
  {
    date: 'May 29',
    occasion: 68,
    payment: 118,
    product: 67,
    language: 84,
    intention: 86,
  },
];

export const mappedOverviewAOccasionTags = [
  {
    title: (
      <div className="flex items-center gap-1">
        <TagIcon />
        <p className="text-primary-main">Total &apos;Occasion&apos; Tags</p>
      </div>
    ),
    tooltip: null,
    value: '18,392',
  },
  {
    title: (
      <div className="flex items-center gap-1">
        <TagIcon />
        <p className="text-primary-main">
          Avg &apos;Occasion&apos; tags per day
        </p>
      </div>
    ),
    tooltip: null,
    value: '214',
  },
  {
    title: (
      <div className="flex items-center gap-1">
        <TagIcon />
        <p className="text-primary-main">Currenly active values</p>
      </div>
    ),
    tooltip: null,
    value: '5',
  },
];

export const dummyOccasionTags = [
  {
    date: 'May 11',
    anniversary: 25,
    apologies: 30,
    birthday: 20,
    grandOpenings: 35,
    wedding: 40,
  },
  {
    date: 'May 13',
    anniversary: 30,
    apologies: 35,
    birthday: 25,
    grandOpenings: 40,
    wedding: 45,
  },
  {
    date: 'May 15',
    anniversary: 35,
    apologies: 40,
    birthday: 30,
    grandOpenings: 45,
    wedding: 50,
  },
  {
    date: 'May 17',
    anniversary: 40,
    apologies: 45,
    birthday: 35,
    grandOpenings: 50,
    wedding: 55,
  },
  {
    date: 'May 19',
    anniversary: 45,
    apologies: 50,
    birthday: 40,
    grandOpenings: 55,
    wedding: 60,
  },
  {
    date: 'May 21',
    anniversary: 50,
    apologies: 55,
    birthday: 45,
    grandOpenings: 60,
    wedding: 65,
  },
  {
    date: 'May 23',
    anniversary: 55,
    apologies: 60,
    birthday: 50,
    grandOpenings: 65,
    wedding: 70,
  },
  {
    date: 'May 25',
    anniversary: 60,
    apologies: 65,
    birthday: 55,
    grandOpenings: 70,
    wedding: 75,
  },
  {
    date: 'May 27',
    anniversary: 65,
    apologies: 70,
    birthday: 60,
    grandOpenings: 75,
    wedding: 80,
  },
  {
    date: 'May 29',
    anniversary: 70,
    apologies: 75,
    birthday: 65,
    grandOpenings: 80,
    wedding: 85,
  },
];

export const previewOccasionPencetage = [
  {
    name: 'Birthday',
    value: 45,
    percentage: '45',
  },
  {
    name: 'Wedding',
    value: 60,
    percentage: '25',
  },
  {
    name: 'Anniversary',
    value: 50,
    percentage: '20',
  },
  {
    name: 'Apologies',
    value: 20,
    percentage: '8',
  },
  {
    name: 'Grand Openings',
    value: 5,
    percentage: '2',
  },
];

export const mappedSuggestedTags = [
  {
    title: (
      <div className="flex items-center gap-1">
        <SparklesIcon size={16} color="#8F68C0" />
        <p className="text-primary-main">Total AI suggested tags</p>
      </div>
    ),
    tooltip: null,
    value: '18,392',
  },
  {
    title: (
      <div className="flex items-center gap-1">
        <SparklesIcon size={16} color="#8F68C0" />
        <p className="text-primary-main">Avg. AI suggested tags per day</p>
      </div>
    ),
    tooltip: null,
    value: '214',
  },
];
