import { ReactNode, useRef } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { FormSchema } from '../..';

import {
  BBBCard,
  BBBCheckbox,
  BBBSelect,
  BBBTelInput,
  BBBTextInput,
} from '@/components/ui';
import { ChannelIcon, integrationsMeta } from '@/constants/integrations';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import { PhonePreference } from '@/types/bitChat/waWidget';
import { formatInternational } from '@/utils/common/phone';

type Props = {
  control: Control<FormSchema>;
  loading: boolean;
  setValue: UseFormSetValue<FormSchema>;
  disableTelInput?: boolean;
};

function WhatsAppNumber({ control, loading, disableTelInput }: Props) {
  const { errors: error } = useFormState({ control });
  const whatsAppContactPrefilledMessageRef = useRef<HTMLInputElement | null>(
    null
  );

  const phonePreference = useWatch({ control, name: 'phonePreference' });

  const connect = useConnectIntegration();

  const { data: cloudApiIntegration } = useWhatsappCloudIntegration();
  const { data: businessIntegration } = useWhatsappBusinessIntegration();

  const numberOptions: { label: ReactNode; value: PhonePreference }[] = [
    ...(cloudApiIntegration
      ? [
          {
            label: (
              <div className="flex items-center gap-2">
                <ChannelIcon integration="whatsapp_meta" />
                <span> {integrationsMeta['whatsapp_meta'].title}</span>
                <span className="text-neutral-50">
                  ({formatInternational(cloudApiIntegration.extra?.number)})
                </span>
              </div>
            ),
            value: 'wa_cloud' as const,
          },
        ]
      : [
          {
            label: (
              <div className="flex items-center gap-2">
                <ChannelIcon integration="whatsapp_meta" />
                Connect to your {integrationsMeta['whatsapp_meta'].title}
              </div>
            ),
            value: 'wa_cloud' as const,
          },
        ]),
    ...(businessIntegration
      ? [
          {
            label: (
              <div className="flex items-center gap-2">
                <ChannelIcon integration="whatsapp" />
                <span> {integrationsMeta['whatsapp'].title}</span>
                <span className="text-neutral-50">
                  ({formatInternational(businessIntegration.extra?.number)})
                </span>
              </div>
            ),
            value: 'wa_business' as const,
          },
        ]
      : [
          {
            label: (
              <div className="flex items-center gap-2">
                <ChannelIcon integration="whatsapp" />
                Connect to your {integrationsMeta['whatsapp'].title}
              </div>
            ),
            value: 'wa_business' as const,
          },
        ]),
    { label: 'Add phone number manually', value: 'manual' as const },
  ];

  const numberMap = Object.fromEntries(
    numberOptions.map((option) => [option.value, option])
  );

  return (
    <BBBCard
      title="Contact us"
      className="mb-cardBottom"
      id="widget-contact-us"
    >
      <div className="flex flex-col gap-3">
        <Controller
          control={control}
          name="phonePreference"
          render={({ field }) => (
            <BBBSelect
              options={numberOptions}
              optionLabel="label"
              optionValue="value"
              label="Select phone number"
              placeholder="Select phone number"
              value={numberMap[field.value]}
              onValueChange={(opt) => {
                if (opt?.value === 'wa_business' && !businessIntegration) {
                  connect({ name: 'whatsapp' });
                } else if (opt?.value === 'wa_cloud' && !cloudApiIntegration) {
                  connect({ name: 'whatsapp_meta' });
                } else {
                  field.onChange(opt!.value);
                }
              }}
            />
          )}
        />
        {phonePreference === 'manual' && (
          <BBBTelInput
            label="Phone number"
            placeholder="123456789"
            disabled={loading || disableTelInput}
            isHookForm
            control={control}
            controlName="whatsappPhone"
            error={error?.whatsappPhone?.message}
            id="test-phone"
          />
        )}
        <BBBTextInput
          label="WhatsApp button text"
          disabled={loading}
          isHookForm
          control={control}
          placeholder="Enter WhatsApp button text"
          controlName="whatsappButtonText"
          error={error?.whatsappButtonText?.message}
        />
        <Controller
          control={control}
          name="whatsappPrefilledText"
          render={({ field }) => (
            <div className="flex flex-col">
              <BBBTextInput
                ref={whatsAppContactPrefilledMessageRef}
                label="Pre-filled message body (optional)"
                disabled={loading}
                placeholder="Enter pre-filled message"
                error={error?.whatsappPrefilledText?.message}
                value={field.value}
                onChange={({ target: { value } }) => field.onChange(value)}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="includeCurrentUrl"
          render={({ field }) => (
            <BBBCheckbox
              label="Include current page URL when customer sends a message"
              checked={!!field.value}
              onValueChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="includeChatId"
          render={({ field }) => (
            <BBBCheckbox
              label="Track customer’s activity with Chat ID"
              checked={!!field.value}
              onValueChange={field.onChange}
            />
          )}
        />
      </div>
    </BBBCard>
  );
}

export default WhatsAppNumber;
