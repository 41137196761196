import { AppType } from '@/types/systemLogin';

export const mapAppTypeToUrl: Record<AppType, string> = {
  BITAPP: 'bitapp',
  BITLINK: 'bitlink',
  BITCRM: 'bitcrm',
  BITLOGIN: 'bitlogin',
  BITCHAT: 'bitchat',
  BITAI: 'bitai',
};

export const mapUrlToAppType: Record<
  string,
  AppType | 'ANALYTICS' | 'CUSTOMERS' | 'PRICING'
> = {
  bitapp: 'BITAPP',
  bitlink: 'BITLINK',
  bitcrm: 'BITCRM',
  bitlogin: 'BITLOGIN',
  bitchat: 'BITCHAT',
  bitai: 'BITAI',
  analytics: 'ANALYTICS',
  customers: 'CUSTOMERS',
  pricing: 'PRICING',
};
