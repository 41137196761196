import { useParams } from 'react-router-dom';
import GeneralLayout from 'layouts/GeneralLayout';
import ExpiredIcon from '../../assets/icons/ExpiredIcon';
import InvitationRedirection from './InvitationRedirection';

import AuthCard from '@/components/Auth/Card';
import { BBBAlert, BBBSpinner } from '@/components/ui';
import useInvitation from '@/hooks/auth/useInvitation';

function InvitationPage() {
  const { id } = useParams<{ id: string }>();

  const { data, isInitialLoading, error } = useInvitation(id);

  return (
    <GeneralLayout>
      {isInitialLoading ? (
        <BBBSpinner />
      ) : error ? (
        error?.response?.status === 400 ? (
          <AuthCard withoutLogo>
            {error?.response?.data?.type === 'INVITATION_NOT_FOUND' ? (
              <>
                <h1 className="text-[40px] font-medium text-primary-main mb-10">
                  Invitation not found
                </h1>
                <ExpiredIcon className="mb-8" />
                <p className="text-base text-primary-main text-center mb-10">
                  This invitation link appears to be invalid. Please contact
                  your team leader for assistance.
                </p>
              </>
            ) : error?.response?.data?.type === 'INVITATION_ALREADY_USED' ? (
              <>
                <h1 className="text-[40px] font-medium text-primary-main mb-10">
                  Invitation already used
                </h1>
                <ExpiredIcon className="mb-8" />
                <p className="text-base text-primary-main text-center mb-10">
                  This invitation has already been used. Please contact your
                  team leader if you need a new invitation.
                </p>
              </>
            ) : error?.response?.data?.type === 'INVITATION_EXPIRED' ? (
              <>
                <h1 className="text-[40px] font-medium text-primary-main mb-10">
                  Your invitation has expired
                </h1>
                <ExpiredIcon className="mb-8" />
                <p className="text-base text-primary-main text-center mb-10">
                  Please contact your team leader for a new invitation. The link
                  is valid for 24 hours.
                </p>
              </>
            ) : (
              <>
                <h1 className="text-[40px] font-medium text-primary-main mb-10">
                  Invalid invitation
                </h1>
                <ExpiredIcon className="mb-8" />
                <p className="text-base text-primary-main text-center mb-10">
                  Something went wrong with this invitation. Please contact your
                  team leader.
                </p>
              </>
            )}
          </AuthCard>
        ) : (
          <BBBAlert message={error.response?.data} type="danger" />
        )
      ) : (
        <InvitationRedirection data={data} id={id} />
      )}
    </GeneralLayout>
  );
}

export default InvitationPage;
