import api from '@/config/api';
import { ApiResponse } from '@/types/bitCRM/api';
import { Campaign, CampaignLog } from '@/types/bitCRM/campaign';

export default {
  getCampaign: (companyId: number | undefined, params: GetCampaignParams) =>
    api.bitCRM.v2
      .get<ApiResponse<Campaign>>(`/campaign-new/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  getCampaignLog: (
    companyId: number | undefined,
    campaignId: string | undefined,
    params: GetCampaignLogParams
  ) =>
    api.bitCRM.v2
      .get<ApiResponse<CampaignLog>>(
        `/campaign-new/${companyId}/${campaignId}/log`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getCampaignDetail: (companyId: number | undefined, id: string | undefined) =>
    api.bitCRM.v2
      .get<Campaign>(`/campaign-new/${companyId}/${id}`)
      .then((res) => res.data),
};

export type GetCampaignLogParams = {
  page?: number;
  size?: number;
  status?: string | null;
  search?: string;
  sort?: string;
};

export type GetCampaignParams = {
  page: number;
  size: number;
  search: string;
  status?: string | null;
  type?: string | null;
  sort?: string | null;
};
