import { Facebook, Twitter } from 'react-feather';

import WhatsAppIcon2 from '@/assets/icons/WhatsAppIcon2';
import { env } from '@/config/env';

export const socialShare = (url: string) => [
  {
    name: 'facebook',
    label: 'Facebook',
    logo: <Facebook size={30} color="#262627" />,
    url: `https://www.facebook.com/sharer/sharer.php?app_id=${
      env.REACT_APP_FACEBOOK_APP_ID
    }&u=${encodeURIComponent(url)}&href=${encodeURIComponent(url)}`,
  },
  {
    name: 'twitter',
    label: 'Twitter',
    logo: <Twitter size={30} color="#262627" />,
    url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
  },
  {
    name: 'whatsapp',
    label: 'Whatsapp',
    logo: <WhatsAppIcon2 width={30} height={30} color="#262627" />,
    url: `https://wa.me/?text=${encodeURIComponent(url)}`,
  },
];
