import { Handle, NodeProps, Position } from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import { useDeleteState } from '../../hooks';
import useStore from '../../store';
import { ActionData } from '../../types';
import TrashWithTransition from '../TrashWithTransition';
import Connector from './Connector';
import CreateConnection from './CreateConnection';

import ThunderIcon from '@/assets/icons/ThunderIcon';
import { BBBCard, BBBTag } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
  staticAgents,
} from '@/constants/bitChat/agent';
import { formatUserDisplayName } from '@/utils/auth';

export default function ActionNode({ data, id }: NodeProps<ActionData>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const hasTarget = useStore((state) =>
    state.edges.some((edge) => edge.target === id)
  );
  const isHeld = useStore((s) => s.connectingNode === id);

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);
  const deleteNode = useDeleteState();

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  return (
    <BBBCard
      title={
        data.action === 'assign_ticket'
          ? 'Assign ticket'
          : data.action === 'set_ticket_category'
          ? 'Ticket category'
          : data.action === 'run_flow'
          ? 'Run other chatbot'
          : 'Customer tag'
      }
      rightButton={
        <TrashWithTransition
          className="group-hover:opacity-100 transition-all opacity-0 text-neutral-40 hover:text-danger-hover"
          onClick={(e) => {
            deleteNode(id);
            e.stopPropagation();
          }}
        />
      }
      className={cx(
        'w-96 group relative transition-colors',
        expandState === 'hover' && 'border-secondary-main',
        expandState === 'clicked' && 'border-secondary-main',
        isHeld && 'border-secondary-main'
      )}
      onClick={() => {
        onChangeStateModal({ nodeId: id, type: 'action' });
      }}
      onMouseEnter={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, 'hover');
        }
      }}
      onMouseLeave={() => {
        if (expandState !== 'clicked') {
          setExpandState(id, null);
        }
      }}
    >
      <Connector id={id} />
      <CreateConnection
        onAddState={(state) => {
          onChangeStateModal({
            sourceId: id,
            type: state,
          });
        }}
        state={expandState}
        onChangeState={(state) => setExpandState(id, state)}
        nodeId={id}
      />
      {data.action === 'assign_ticket' ? (
        <>
          <div className="flex items-center flex-wrap gap-1 text-neutral-50">
            <span>Assign ticket to</span>
            {data.agent && (
              <>
                <span className="font-bold">
                  {'formattedName' in data.agent
                    ? data.agent.formattedName
                    : //@ts-ignore
                    staticAgents.includes(data.agent.id)
                    ? //@ts-ignore
                      data.agent.id === EVERYONE_AGENT_ID
                      ? //@ts-ignore
                        `Everyone (${data.agent.meta?.count})`
                      : generalAiAgentOption.formattedName
                    : //@ts-ignore
                      formatUserDisplayName(data.agent)}
                </span>
              </>
            )}
          </div>
        </>
      ) : data.action === 'set_ticket_category' ? (
        <>
          <div className="flex items-center flex-wrap gap-1 text-neutral-50">
            <span>Change ticket category to </span>
            <BBBBadge
              text={data.ticketCategory?.label}
              type={data.ticketCategory?.color}
            />{' '}
            <span>ticket </span>
          </div>
        </>
      ) : data.action === 'run_flow' ? (
        <div className="rounded-lg flex items-center gap-2 p-4 border border-neutral-30">
          <ThunderIcon /> {data.flow?.name}
        </div>
      ) : (
        <div className="flex flex-wrap items-center gap-2">
          {data.action === 'add_customer_tag' ? 'Add' : 'Remove'}{' '}
          {data.customerTags?.map((tag) => (
            <BBBTag key={tag.id} text={tag.name} />
          ))}{' '}
          {!!data.customerTags?.length &&
            (data.customerTags.length > 1 ? 'tags' : 'tag')}
        </div>
      )}
      <Handle
        type="target"
        position={Position.Left}
        className={!hasTarget ? 'opacity-0 pointer-events-none' : ''}
      />
      <Handle
        type="source"
        position={Position.Right}
        className={!hasEdges ? 'opacity-0 pointer-events-none' : ''}
      />
    </BBBCard>
  );
}
