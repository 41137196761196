import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { Onboarding } from '@/types/bitLogin/v2';

const useOnboarding = (
  moduleKey: string,
  options?:
    | Omit<
        UseQueryOptions<Onboarding, unknown, Onboarding, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(
    ['onboarding-bitlogin', activeCompany, moduleKey],
    async () => {
      const {
        data: { data },
      } = await api.bitLogin.get<ApiResponse<Onboarding>>(
        `/onboarding/${activeCompany}/${moduleKey}`
      );
      return data;
    },
    options
  );
};

export default useOnboarding;
