import AlertIcon3 from 'assets/icons/AlertIcon3';
import colors from 'constants/common/colors';

export default function NeedAction() {
  return (
    <div className="absolute top-[calc(100% + 0.25rem)] flex items-center gap-2">
      <AlertIcon3 color={colors.danger.main} />
      <span className="text-sm text-danger-main">Need action</span>
    </div>
  );
}
