import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import api from '@/config/api';
import { TemplateBodyParameters } from '@/types/bitCRM/template';

const useCampaignVariables = (
  options?:
    | Omit<
        UseQueryOptions<
          TemplateBodyParameters[],
          unknown,
          TemplateBodyParameters[],
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) =>
  useQuery(
    ['bitcrm-variables'],
    () =>
      api.bitCRM.v2
        .get<TemplateBodyParameters[]>(`/app/variables/campaign`)
        .then((res) => res.data),
    options
  );

export default useCampaignVariables;
