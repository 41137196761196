import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { GetAiCreditsUsageHistoryParams } from '@/api/services/pricing';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

export const useAiCreditsUsageHistory = (
  params?: GetAiCreditsUsageHistoryParams
) => {
  const activeCompany = useActiveCompany();

  return useQuery(['ai-credits-usage-history', activeCompany, params], () =>
    services.pricing.getAiCreditsUsageHistory(activeCompany, params)
  );
};

export const useUpdateAutoChargeSettings = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { enabled: boolean }) =>
      services.pricing.updateAutoChargeSettings(activeCompany, payload),
    {
      onSuccess: (data, { enabled }) => {
        queryClient.invalidateQueries(['pricing-v2']);

        if (enabled) {
          toast.success(
            'Auto charge enabled. Credits will be automatically topup once it reach 0'
          );
        }
      },
    }
  );
};
