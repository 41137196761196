import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryMeta,
} from '@tanstack/react-query';
import axios from 'axios';
import ErrorPages from 'pages/Error/Error';

import api from '@/config/api';
import { env } from '@/config/env';
import { rootStore } from '@/stores';
import { toast } from '@/utils/common/toast';

type Props = {
  children: React.ReactNode;
};

const globalErrorHandler = (err: unknown, queryMeta?: QueryMeta) => {
  if (queryMeta && queryMeta.disableToast) {
    return;
  }

  const serverError = (
    <>
      <p className="mb-1">
        Internal server error. Please try again in a moment.
      </p>
      <p>
        If issue persists, contact us at{' '}
        <a href="mailto:help@bitybit.studio" className="font-medium underline">
          help@bitybit.studio
        </a>
      </p>
    </>
  );

  if (axios.isAxiosError(err)) {
    if (err.response) {
      if (err.response.status >= 400 && err.response.status < 500) {
        toast.error(err.response.data.message || 'Bad request');
      } else {
        toast.error(serverError);
      }
    } else {
      const message =
        err.message === 'Network Error' ? serverError : err.message;
      toast.error(message);
    }
  }
};

const errorBoundaryHandler = (err: unknown): boolean => {
  const isErrorAxios = axios.isAxiosError(err);

  if (!isErrorAxios) return true;

  if (err.response?.status && err.response.status > 500) {
    return true;
  }

  return false;
};

const queryCache = new QueryCache({
  onError: (err, query) => {
    globalErrorHandler(err, query.meta);
  },
});
const mutationCache = new MutationCache({
  onError: (err, variables, context, mutation) => {
    globalErrorHandler(err, mutation.meta);
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: errorBoundaryHandler,
      refetchOnReconnect: true,
      retry: false,
      meta: {
        disableToast: true,
      },
    },
    mutations: {
      useErrorBoundary: errorBoundaryHandler,
    },
  },
  queryCache,
  mutationCache,
});

function AppProvider({ children }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Provider store={rootStore}>
          <Sentry.ErrorBoundary fallback={ErrorPages} onError={handleError}>
            {children}
          </Sentry.ErrorBoundary>
        </Provider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

export default AppProvider;

function handleError(error: Error, componentStack: string, eventId: string) {
  api.integrations
    .post('/hangout/error/fe', {
      text: JSON.stringify(
        {
          error: error.message,
          componentStack,
          eventId,
        },
        null,
        2
      ),
    })
    .catch(() => {
      // Silently fail if error reporting fails
    });
}
