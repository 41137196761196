import { Control, Controller } from 'react-hook-form';
import { AppearanceForm } from 'pages/BitLink/Appearance';

import {
  BBBCard,
  BBBImageUpload,
  BBBRichTextEditor,
  BBBTextInput,
} from '@/components/ui';

type Props = {
  control: Control<AppearanceForm>;
};

function StoreProfile({ control }: Props) {
  return (
    <BBBCard
      id="store-profile-appearance"
      title={<p className="text-neutral-70 font-medium">Store Profile</p>}
      className="mb-cardBottom"
    >
      <Controller
        control={control}
        name="logo"
        render={({ field }) => (
          <BBBImageUpload
            containerClassName="mb-3"
            imageUrl={field.value}
            shape="circle"
            width={'5rem'}
            height={'5rem'}
            imageClassName="w-24 h-24"
            buttonText="Upload Image"
            onChangeImage={field.onChange}
            crop={{
              circular: true,
              aspect: 1 / 1,
              whitelistedRatio: ['square'],
            }}
          />
        )}
      />
      <BBBTextInput
        placeholder="Enter your link title"
        label="Title"
        isHookForm
        control={control}
        controlName="title"
      />
      <Controller
        control={control}
        name="bio"
        render={({ field }) => (
          <BBBRichTextEditor
            editorState={field.value}
            placeholder="ie: Hi, thanks for visiting bitbybit!"
            label="Store Bio (optional)"
            rows={2}
            onChangeEditorState={field.onChange}
          />
        )}
      />
    </BBBCard>
  );
}

export default StoreProfile;
