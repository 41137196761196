import { Product } from '@/api/services/customer/order';

type Props = {
  currency: string | null;
  product: Product | null;
  price: number;
  quantity: number;
};

function LineItem({ currency, product, quantity, price }: Props) {
  if (!product) return null;

  return (
    <div className="flex items-center gap-2 mb-3">
      <div className="flex-none p-1 rounded border-neutral-30 border-[1.5px]">
        {product.media && (
          <img src={product.media} className="w-14 h-14 rounded" />
        )}
      </div>
      <div>
        <div className="text-primary-main mb-1">{product.name}</div>
        <div className="text-primary-main">
          {currency} {price} x {quantity}
        </div>
      </div>
    </div>
  );
}

export default LineItem;
