import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const mapActionToTitle = (type: 'automation' | 'campaign' | 'chat') => ({
  delete: `Delete multiple ${type} template`,
  duplicate: `Duplicate multiple ${type} template`,
});

const mapDescriptionToTitle = (type: 'automation' | 'campaign' | 'chat') => ({
  delete: `Are you sure to delete selected ${type} template? Once you deleted the action cannot be undone`,
  duplicate: `Are you sure to duplicate selected ${type} template?`,
});

type MutationPayload = {
  action: 'delete' | 'duplicate';
  selectedRows: string[];
  type: 'automation' | 'campaign' | 'chat';
};

export const useTemplatesBulkAction = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  const { mutate: bulkAction } = useMutation(
    async ({ action, selectedRows, type }: MutationPayload) => {
      if (type === 'automation' || type === 'campaign') {
        await api.bitCRM.v2.post(`/campaign_template/bulk_action`, {
          companyId: activeCompany,
          data: selectedRows,
          action,
        });
      } else {
        await api.bitChat.post(`/template/bulk_action`, {
          companyId: activeCompany,
          data: selectedRows,
          action,
        });
      }
    },
    {
      onSuccess: (_, payload) => {
        toast.success(
          payload.action === 'duplicate'
            ? 'Template duplicated'
            : `Your campaign template has been deleted`
        );

        if (payload.type === 'chat') {
          client.invalidateQueries(['bitchat-wa-cloud-templates']);
        } else {
          client.invalidateQueries(['bitcrm-template']);
          client.invalidateQueries(['bitcrm-templates']);
        }
      },
    }
  );

  return ({
    action,
    selectedRows,
    onSuccess,
    type,
  }: MutationPayload & {
    onSuccess?: () => void;
  }) => {
    const title = mapActionToTitle(type)[action];
    const description = mapDescriptionToTitle(type)[action];

    if (title || description) {
      toggleConfirmation({
        title: title,
        description,
        onAccept: (hide) =>
          bulkAction(
            {
              action,
              selectedRows,
              type,
            },
            {
              onSuccess: () => {
                hide();
                onSuccess?.();
              },
            }
          ),
        deleteModal: action === 'delete',
        submitText: 'Delete',
        cancelText: 'Cancel',
      });
    } else {
      bulkAction(
        {
          action,
          selectedRows,
          type,
        },
        {
          onSuccess: () => {
            onSuccess?.();
          },
        }
      );
    }
  };
};
