function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M18.58 3.367a3.591 3.591 0 00-5.151-1.485 3.591 3.591 0 00-1.326 1.485L1.768 23.684c-1.237 2.426.326 5.647 3.237 5.647h20.672c2.912 0 4.472-3.22 3.238-5.647L18.58 3.367zm-3.238 6.874a1.274 1.274 0 011.273 1.273v6.363a1.272 1.272 0 01-2.174.9 1.272 1.272 0 01-.373-.9v-6.363a1.272 1.272 0 011.274-1.272zm0 10.818a1.274 1.274 0 011.273 1.272v.637a1.272 1.272 0 01-2.547 0v-.637a1.272 1.272 0 011.274-1.272z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AlertIcon;
