import { useRef, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import { Model } from '@/api/services/bitAi/v2-new/model';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import useDeleteModel from '@/hooks/bitAi/models/useDeleteModel';
import useDuplicateModel from '@/hooks/bitAi/models/useDuplicateModel';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { formatDate2 } from '@/utils/common/date';
import { toast } from '@/utils/common/toast';

export default function useColumn(): HeadersTypes<Model> {
  const [actionOption, setActionOption] = useState<string>();
  const optionRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(optionRef, () => setActionOption(''));

  const deleteModel = useDeleteModel();
  const duplicateModel = useDuplicateModel();

  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'advance_custom_model'
  );

  const advanceCustomModelUsage = pricingFeatureData?.usage ?? 0;

  const isLimitReached = advanceCustomModelUsage >= limit;

  return [
    {
      accesor: 'name',
      isSortable: true,
      sortOrder: 'asc',
      renderHeader: () => (
        <div className="flex gap-4 items-center justify-center">Model Name</div>
      ),
      render: (row, value) => (
        <div className="flex gap-4 items-center">
          <Link
            to={{
              pathname: `/bitai/model/${row.id}`,
              state: {
                id: row.id,
              },
            }}
            className="text-primary-main group-hover:underline"
          >
            {value}
          </Link>
        </div>
      ),
    },
    {
      accesor: 'createdAt',
      isSortable: true,
      renderHeader: () => 'Created date',
      render: (row) => (
        <p className="text-primary-main">{formatDate2(row.createdAt)}</p>
      ),
    },
    {
      accesor: 'updatedAt',
      isSortable: true,
      renderHeader: () => 'Last updated',
      render: (row) => (
        <p className="text-primary-main">{formatDate2(row.updatedAt)}</p>
      ),
    },
    {
      isAdditionalColumn: true,
      renderHeader: () => 'Action',
      align: 'center',
      render: (row) => (
        <div className="group relative">
          <div
            className="flex justify-center items-center w-7 h-7 mx-auto cursor-pointer"
            onClick={(e) => {
              setActionOption(row.id);
              e.preventDefault();
            }}
          >
            <MoreVertical className="hover:text-[#767676]" />
          </div>
          {actionOption === row.id && (
            <div
              className={cx(
                `opacity-100 pointer-events-auto rounded-md bg-white whitespace-nowrap transition-opacity z-50 absolute top-0 right-0 shadow-md overflow-clip`
              )}
              ref={optionRef}
            >
              <Link
                to={{
                  pathname: `/bitai/model/${row.id}`,
                  state: {
                    id: row.id,
                  },
                }}
              >
                <div className="cursor-pointer px-6 py-2  hover:bg-[#FFE6D8]">
                  Edit
                </div>
              </Link>
              <div
                onClick={(e) => {
                  if (isLimitReached) {
                    toast.success(
                      `On a free plan, you can only have ${limit} advance custom model.`
                    );
                  } else {
                    duplicateModel(true)({
                      modelId: row.id,
                    });
                  }
                  e.preventDefault();
                }}
                className={cx(
                  'cursor-pointer px-6 py-2  hover:bg-[#FFE6D8]',
                  isLimitReached ? 'opacity-50' : 'opacity-100'
                )}
              >
                Duplicate
              </div>
              <div
                className="text-danger-main cursor-pointer px-6 py-2  hover:bg-[#FFE6D8]"
                onClick={(e) => {
                  deleteModel(true)(row.id);
                  e.preventDefault();
                }}
              >
                Delete
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];
}
