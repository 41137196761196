import { Control, Controller } from 'react-hook-form';
import { AppearanceForm } from 'pages/BitLink/Appearance';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard, BBBColorPicker } from '@/components/ui';
import { buttonStyle } from '@/constants/bitLink/appearance/buttonStyle';
import useResponsive from '@/hooks/common/useResponsive';

const mapClassName: Record<typeof buttonStyle[number], string> = {
  Fill: 'normal',
  Outline: 'outlined',
  'Hard shadow': 'btn-shadow-hard',
  'Soft shadow': 'btn-shadow',
};

const mapStyle: Partial<Record<typeof buttonStyle[number], string>> = {
  Fill: 'bg-neutral-30',
  Outline: 'bg-transparent border border-neutral-50',
  'Hard shadow': 'bg-neutral-30 shadow-[2px_2px_5px_0px_rgba(0,0,0,0.50)]',
  'Soft shadow': 'bg-neutral-30 shadow',
};

type Props = {
  control: Control<AppearanceForm>;
};

function ButtonStyle({ control }: Props) {
  const isMobile = useResponsive('sm');

  const getClass = (p: 'Outline' | 'Soft shadow' | 'Hard shadow' | 'Fill') =>
    mapClassName[p];

  const getStyle = (p: 'Outline' | 'Soft shadow' | 'Hard shadow' | 'Fill') =>
    mapStyle[p];

  return (
    <BBBCard
      className="mb-cardBottom"
      title={<p className="text-neutral-70 font-medium">Button Style</p>}
      desc={
        isMobile
          ? 'Edit style currently only available on desktop site'
          : undefined
      }
    >
      {!isMobile && (
        <>
          {buttonStyle.map((p) => (
            <div className="grid grid-cols-4 items-center gap-6 mb-5" key={p}>
              <p className="text-primary-main">{p}</p>
              <Controller
                control={control}
                name="presetScope"
                render={({ field }) => (
                  <>
                    <div
                      className={cx(
                        'cursor-pointer w-full h-10 mr-[1em] bg-neutral-30',
                        getStyle(p),
                        field.value.btnStyle === `${getClass(p)}_full`
                          ? `outline outline-primary-main outline-1 outline-offset-4`
                          : ``
                      )}
                      onClick={() =>
                        field.onChange({
                          ...field.value,
                          btnStyle: `${getClass(p)}_full`,
                        })
                      }
                    />
                    <div
                      className={cx(
                        'cursor-pointer w-full h-10 mr-[1em] bg-white rounded-lg',
                        getStyle(p),
                        field.value.btnStyle === `${getClass(p)}_light-rounded`
                          ? `outline outline-primary-main outline-1 outline-offset-4`
                          : ``
                      )}
                      onClick={() =>
                        field.onChange({
                          ...field.value,
                          btnStyle: `${getClass(p)}_light-rounded`,
                        })
                      }
                    />
                    <div
                      className={cx(
                        'cursor-pointer w-full h-10 mr-[1em] bg-neutral-30 rounded-full',
                        getStyle(p),
                        field.value.btnStyle === `${getClass(p)}_full-rounded`
                          ? `outline outline-primary-main outline-1 outline-offset-4`
                          : ``
                      )}
                      onClick={() =>
                        field.onChange({
                          ...field.value,
                          btnStyle: `${getClass(p)}_full-rounded`,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
          ))}
          <div className="grid grid-cols-4 items-center gap-6 mb-5">
            <p className="text-primary-main">Style</p>
            <Controller
              control={control}
              name="presetScope"
              render={({ field }) => (
                <BBBColorPicker
                  color={field.value.btnBgColor || '#FFFFFF'}
                  onChangeColor={(btnBgColor) =>
                    field.onChange({
                      ...field.value,
                      btnBgColor,
                    })
                  }
                  label="Button color"
                />
              )}
            />

            <Controller
              control={control}
              name="presetScope"
              render={({ field }) => (
                <BBBColorPicker
                  color={field.value.btnFontColor || '#000000'}
                  onChangeColor={(btnFontColor) =>
                    field.onChange({
                      ...field.value,
                      btnFontColor,
                    })
                  }
                  label="Button font color"
                />
              )}
            />

            <Controller
              control={control}
              name="presetScope"
              render={({ field }) => (
                <BBBColorPicker
                  color={field.value.btnShadowColor || '#FFFFFF'}
                  onChangeColor={(btnShadowColor) =>
                    field.onChange({
                      ...field.value,
                      btnShadowColor,
                    })
                  }
                  label="Shadow color"
                />
              )}
            />
          </div>
        </>
      )}
    </BBBCard>
  );
}
export default ButtonStyle;
