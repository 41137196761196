export default function CommentIcon({
  width = 17,
  height = 15,
  color = '#202C33',
  className,
}: {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3496_34828)">
        <path
          d="M1.75 0.125C1.28587 0.125 0.840752 0.309375 0.512563 0.637563C0.184375 0.965752 0 1.41087 0 1.875L0 13.0689C1.8388e-05 13.1554 0.0257185 13.2401 0.0738476 13.312C0.121977 13.384 0.190371 13.44 0.270374 13.4731C0.350378 13.5062 0.438393 13.5148 0.523282 13.4978C0.60817 13.4808 0.686114 13.439 0.74725 13.3777L3.24363 10.8814C3.40768 10.7173 3.6302 10.625 3.86225 10.625H12.25C12.7141 10.625 13.1592 10.4406 13.4874 10.1124C13.8156 9.78425 14 9.33913 14 8.875V1.875C14 1.41087 13.8156 0.965752 13.4874 0.637563C13.1592 0.309375 12.7141 0.125 12.25 0.125L1.75 0.125Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3496_34828">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
