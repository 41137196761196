import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Product } from '@/types/shopify/rest/products';
import { Chat } from '@/types/whatsApp/chat';

export default {
  /**
   * @deprecated
   * Use chatServices.summarizeMessage instead
   */
  summarizeMessage: ({
    key,
    number,
    limit = 15,
    sources,
  }: {
    key: string;
    number: string;
    limit?: number;
  } & Pick<Chat, 'sources'>) =>
    api.whatsApp.v1
      .post<{
        data: {
          sentiment?: number | undefined;
          summary: string[] | undefined;
        };
        message: string;
        prompt: string;
        status: boolean;
      }>(`/message/summarize`, {
        key,
        number,
        limit,
        sources,
      })
      .then((res) => res.data.data),
  getProductById: (
    companyId: number | undefined,
    productId: number | undefined
  ) =>
    api.bitChat
      .get<ApiResponse<Product>>(`/product/${companyId}/shopify/${productId}`)
      .then((res) => res.data.data),
  getOrderRecommendation: (
    companyId: number | undefined,
    { phone, search }: GetOrderRecommendationParams
  ) =>
    api.bitChat
      .get<Product[]>(`/recommendation/${companyId}`, {
        params: { phone, search },
      })
      .then((res) => res.data),
  /**
   * @deprecated
   * Use whatsAppServices.transferChat instead
   */
  transferChat: (payload: {
    key: string;
    userId: number;
    notes: string;
    number: string;
  }) => api.whatsApp.v1.put(`/ticket`, payload).then((res) => res.data),
};

export type GetOrderRecommendationParams = {
  phone?: string | null;
  search?: string;
};
