import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketWhatsApp } from 'socket';

import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useConnectFacebook = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const userId = useUserId();
  const queryClient = useQueryClient();

  const [enableFacebookIntegration, setEnableFacebookIntegration] =
    useState(false);

  const onIntegrationAuthorized = useCallback(() => {
    toast.success('Succesfully connected to facebook');
    setEnableFacebookIntegration(false);
    setTimeout(() => {
      queryClient.invalidateQueries(['facebook-list']);
      queryClient.invalidateQueries([`bitchat-company-integrations`]);
    }, 500);
  }, [queryClient]);

  useEffect(() => {
    if (enableFacebookIntegration) {
      socketWhatsApp.emit('join-facebook', {
        companyId: activeCompany,
        userId,
      });
    }
  }, [activeCompany, enableFacebookIntegration, userId]);

  useEffect(() => {
    if (enableFacebookIntegration) {
      socketWhatsApp.on(
        `facebook-integration-authorized`,
        onIntegrationAuthorized
      );
      return () => {
        socketWhatsApp.off(
          `facebook-integration-authorized`,
          onIntegrationAuthorized
        );
      };
    }
  }, [enableFacebookIntegration, onIntegrationAuthorized]);

  return [enableFacebookIntegration, setEnableFacebookIntegration] as const;
};

export default useConnectFacebook;
