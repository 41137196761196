import { useHistory } from 'react-router';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import customerServices from 'api/services/customer';
import {
  DeleteSegmentParams,
  GetSegmentPayload,
  GetSegmentsPayload,
  UpsertSegmentPayload,
} from 'api/services/customer/customer';
import { AxiosResponse } from 'axios';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import { useActiveCompany } from 'hooks/rtk/selector';
import { Segments } from 'types/customers';
import { toast } from 'utils/common/toast';

export const useSegments = (params?: Omit<GetSegmentsPayload, 'cursor'>) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    ['segments', activeCompany, params],
    ({ pageParam }) =>
      customerServices.customer.getSegments(activeCompany, {
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
    }
  );
};

export const useSegmentByName = (
  name?: GetSegmentPayload,
  {
    enabled,
    ...options
  }: Omit<
    UseQueryOptions<
      { data: Segments; count: number },
      unknown,
      { data: Segments; count: number },
      (string | undefined)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['segment', 'name', name],
    () => customerServices.customer.getSegmentByName(activeCompany!, name!),
    {
      enabled:
        name !== undefined &&
        (typeof enabled === 'undefined' ? true : !!enabled),
      ...options,
      meta: { disableToast: true },
    }
  );
};

export const useSegment = (
  id?: GetSegmentPayload,
  {
    enabled,
    ...options
  }: Omit<
    UseQueryOptions<
      { data: Segments; count: number },
      unknown,
      { data: Segments; count: number },
      (string | undefined)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['segment', id],
    () =>
      customerServices.customer.getSegment(id!, { companyId: activeCompany }),
    {
      enabled:
        id !== undefined && (typeof enabled === 'undefined' ? true : !!enabled),
      ...options,
    }
  );
};

export const useDeleteSegment = () => {
  const queryClient = useQueryClient();
  const toggleConfirmation = useConfirmationModal();
  const history = useHistory();

  const deleteSegmentMutation = useMutation(
    (segmentId: DeleteSegmentParams) =>
      customerServices.customer.deleteSegment(segmentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['segments']);
        toast.success('Segment deleted successfully');
        history.push('/customers');
      },
    }
  );

  function deleteSegment(withConfirmation: true): (param: string) => void;
  function deleteSegment(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, string, unknown>;
  function deleteSegment(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, string, unknown> {
    return withConfirmation
      ? (id) =>
          toggleConfirmation({
            title: 'Delete segment?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteSegmentMutation.mutate(id, {
                onSuccess: () => {
                  hide();
                },
              }),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteSegmentMutation;
  }

  return deleteSegment;
};

export const useCreateSegment = () => {
  const activeCompany = useActiveCompany();

  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpsertSegmentPayload) =>
      customerServices.customer.upsertSegments(activeCompany!, payload),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries(['segments']);
        queryClient.invalidateQueries(['segment']);
        toast.success(
          `Successfully ${payload.id ? 'edit' : 'create'} segments`
        );
      },
    }
  );
};
