import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import Flow from 'components/Flow/Flow';
import useStore, { RFState } from '../store';
import Action from './components/Action';
import CustomEdge from './components/Edges/CustomEdge';
import CustomEdgeCondition from './components/Edges/CustomEdgeCondition';
import Modals from './components/Modals/Modals';
import ActionNode from './components/Nodes/ActionNode';
import ConditionNode from './components/Nodes/ConditionNode';
import TriggerNode from './components/Nodes/TriggerNode';

import { BBBSpinner } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import DesktopOptimized from '@/layouts/DesktopOptimized';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  panOnDrag: !state.connectingNode,
});

const nodeTypes = {
  trigger: TriggerNode,
  action: ActionNode,
  conditions: ConditionNode,
};

const edgeTypes = {
  custom: CustomEdge,
  customCondition: CustomEdgeCondition,
};

export default function AutomationNewDetail() {
  const isMobile = useResponsive('sm');

  if (isMobile) {
    return (
      <DesktopOptimized
        backLink="/bitcrm/automation-new"
        description="Automation is more optimized in desktop website. Switch over to be able to create your automation scenario."
      />
    );
  }

  return <_AutomationNewDetail />;
}

function _AutomationNewDetail() {
  const [loadDefaultDelay, setLoadDefaultDelay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadDefaultDelay(false);
    }, 500);
  }, []);

  if (loadDefaultDelay) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <div>
          <BBBSpinner />
        </div>
        <div>Loading scenario</div>
      </div>
    );
  }

  return <__AutomationNewDetail />;
}

function __AutomationNewDetail() {
  const { nodes, edges, onEdgesChange, onNodesChange, onConnect } = useStore(
    selector,
    shallow
  );

  const loadingDuplicateFlow = useStore((s) => s.loadingDuplicateFlow);
  const panOnDrag = useStore((s) => s.panOnDrag);

  if (loadingDuplicateFlow)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="mb-2">
          <BBBSpinner />
        </div>
        Duplicating automation
      </div>
    );

  return (
    <>
      <Action />
      <Flow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        panOnDrag={panOnDrag}
        edgeTypes={edgeTypes}
        nodesDraggable={false}
      >
        <Modals />
      </Flow>
      <CustomStyle />
    </>
  );
}

function CustomStyle() {
  const activeAddState = useStore((s) => s.activeAddState);

  const activeChildNodes = useStore((s) =>
    s.nodes
      .filter((node) =>
        s.activeAddState ? node.parentNode === s.activeAddState : false
      )
      .map((node) => node.id)
  );

  return (
    <>
      {activeAddState && (
        <style>
          {`.react-flow__node[data-id="${activeAddState}"] {
        z-index: 1000 !important;
      }
      `}
        </style>
      )}
      {!!activeChildNodes.length &&
        activeChildNodes.map((node) => (
          <style key={node}>
            {`.react-flow__node[data-id="${node}"] {
        z-index: 1000 !important;
      }
      `}
          </style>
        ))}
    </>
  );
}
