import analytics from './analytics';
import autopilot from './autopilot';
import chatbot from './chatbot';
import googleSheets from './google-sheets';
import integrations from './integrations';
import livechat from './livechat';
import tags from './tags';
import template from './template';
import widget from './widget';

export default {
  analytics,
  autopilot,
  integrations,
  livechat,
  tags,
  chatbot,
  widget,
  template,
  'google-sheets': googleSheets,
};
