import { useRef } from 'react';
import BBBTextInput from './BBBTextInput';

import { currencyOptions, formatCurrency } from '@/constants/common/currency';
import { useCompany } from '@/hooks/rtk/selector';
import { cn } from '@/utils/styles';

type BBBPriceInputProps = {
  totalPrice: string;
  onChangeTotalPrice: (value: string) => void;
  errorTotalPrice?: string;
  labelTotalPrice?: string;
  containerClassName?: string;
};

function BBBPriceInput({
  totalPrice,
  onChangeTotalPrice,
  errorTotalPrice,
  labelTotalPrice,
  containerClassName,
}: BBBPriceInputProps) {
  const currency = useCompany().currency || currencyOptions[0].code;

  return (
    <PriceInput
      error={errorTotalPrice}
      value={formatCurrency(totalPrice, currency)}
      onChange={(value) => {
        onChangeTotalPrice(value.replace(/\D/g, ''));
      }}
      currency={currency}
      label={labelTotalPrice}
      containerClassName={containerClassName}
    />
  );
}

export default BBBPriceInput;

function PriceInput({
  error,
  value,
  onChange,
  currency,
  label,
  containerClassName,
  placeholder = 'Input total price value',
}: {
  error?: string;
  value: string;
  onChange: (value: string) => void;
  currency?: string;
  label?: string;
  containerClassName?: string;
  placeholder?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = inputRef.current?.selectionStart ?? 0;
    const unformattedValue = value.replace(/\D/g, '');

    const prevValue = value as string;
    onChange(unformattedValue);

    // Difference in length after formatting
    const formattedValue = formatCurrency(unformattedValue, currency || 'USD');
    const lengthDifference = formattedValue.length - prevValue.length;

    // Restore cursor position with adjustment for formatting
    requestAnimationFrame(() => {
      if (inputRef.current) {
        const adjustedPosition = cursorPosition + lengthDifference;
        inputRef.current.selectionStart = adjustedPosition;
        inputRef.current.selectionEnd = adjustedPosition;
      }
    });
  };

  return (
    <BBBTextInput
      ref={inputRef}
      containerClassname={cn('grow mb-0', containerClassName)}
      label={label}
      placeholder={placeholder}
      error={error}
      value={value}
      onChange={handleChange}
      isFixed
      fixedLabel={
        <span className="font-medium text-sm flex items-center justify-center px-4 border-r text-primary-main/50">
          {currency}
        </span>
      }
    />
  );
}
