import { MessageTemplateStatus } from '@/types/whatsApp/settings';

export const statusOptions: {
  label: string;
  value: MessageTemplateStatus;
}[] = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
];
