import React, { ReactNode, useMemo, useState } from 'react';
import { Check, ChevronDown, PlusCircle } from 'react-feather';
import { Controller, FieldError } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { setActiveDesign, setActivePreview } from 'stores/bitApp';
import * as yup from 'yup';

import {
  BBBBottomSheet,
  BBBButton,
  BBBModal,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import { CountryOptions } from '@/constants/systemLogin/countries';
import industries from '@/constants/systemLogin/industries';
import useAddUserCompany from '@/hooks/auth/useAddUserCompany';
import useAuthenticatedUserCompanies from '@/hooks/auth/useAuthenticatedUserCompanies';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useCompany } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setActiveCompany, setLoadingChangeCompany } from '@/stores/auth';
import { setActiveStatus, setSelectedLivechat } from '@/stores/bitCRM';
import { Company } from '@/types/systemLogin';
import { handleCancelLiveSection } from '@/utils/bitApp';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

export default function CompanyList({
  onCreateSuccess,
}: {
  onCreateSuccess?: () => void;
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useResponsive('sm');

  const [showAddCompany, setShowAddCompany] = useState(false);

  const handleChangeCompany = (id: number) => {
    if (id === -1) {
      setShowAddCompany(true);
      return;
    }

    dispatch(setLoadingChangeCompany(true));

    setTimeout(() => {
      _localStorage.setItem('activeCompany', id.toString());
      dispatch(setActiveCompany(id));
      dispatch(setSelectedLivechat(null));
      dispatch(setLoadingChangeCompany(false));
      dispatch(setActivePreview('home'));
      dispatch(setActiveDesign('layout'));
      handleCancelLiveSection();

      _localStorage.removeItem('activeStatus1');
      dispatch(setActiveStatus(null));

      history.push('/');
    }, 500);
  };

  return (
    <>
      <CompanyCreationModal
        show={showAddCompany}
        onChangeShow={(val) => setShowAddCompany(val)}
        onSuccess={onCreateSuccess}
      />

      {isMobile ? (
        <MobileSwitchCompany onChangeCompany={handleChangeCompany} />
      ) : (
        <DefaultSwitchCompany onChangeCompany={handleChangeCompany} />
      )}
    </>
  );
}

function DefaultSwitchCompany({
  onChangeCompany,
}: {
  onChangeCompany: (companyId: number) => void;
}) {
  const selectedCompany = useCompany();

  const [search, setSearch] = useState('');

  const query = useAuthenticatedUserCompanies({ search });

  const {
    data: companiesData,
    isInitialLoading: isLoadingCompanies,
    hasNextPage,
    fetchNextPage,
  } = query;

  const companyOptions = useMemo<
    (Partial<Company> & { logo?: string | React.ReactNode })[]
  >(
    () => [
      ...(companiesData?.pages
        .flatMap((page) => page.data)
        ?.map((userCompany) => userCompany.company) || []),
    ],
    [companiesData?.pages]
  );
  return (
    <>
      <BBBSelect
        options={companyOptions}
        value={selectedCompany}
        onValueChange={(value) => onChangeCompany(value!.id!)}
        optionLabel="name"
        optionIcon="logo"
        loading={isLoadingCompanies}
        optionValue="id"
        placeholder="Select Store"
        isSearchable
        search={search}
        onChangeSearch={setSearch}
        isPaginated
        fetchNext={fetchNextPage}
        hasMore={!!hasNextPage}
        label="Select company"
      />
      <div className="my-5 text-center">Or</div>
      <BBBButton
        width="full"
        variant="secondary"
        onClick={() => {
          onChangeCompany(-1);
        }}
      >
        Create company
      </BBBButton>
    </>
  );
}

function MobileSwitchCompany({
  onChangeCompany,
}: {
  onChangeCompany: (companyId: number) => void;
}) {
  const selectedCompany = useCompany();

  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const [target, setTarget] = useState(selectedCompany.id);

  const query = useAuthenticatedUserCompanies();

  const { data: companiesData, hasNextPage, fetchNextPage } = query;

  if (!companiesData) return null;

  const flattenedCompaniesData = companiesData.pages.flatMap(
    (page) => page.data
  );

  return (
    <>
      <BBBBottomSheet
        title="Select store"
        show={showBottomSheet}
        onClose={() => {
          setShowBottomSheet(false);
        }}
        snapPoints={() => [400]}
      >
        <div
          className="h-[19rem] overflow-auto"
          id="companylist-scrollable"
          data-body-scroll-lock-ignore
        >
          <InfiniteScroll
            next={() => fetchNextPage()}
            hasMore={!!hasNextPage}
            loader={null}
            dataLength={flattenedCompaniesData.length}
            scrollableTarget="companylist-scrollable"
          >
            {flattenedCompaniesData.map((company) => (
              <div
                key={company.companyId}
                className="mb-2 px-2.5 py-2 flex items-center gap-2"
                data-company-list
                onClick={() => {
                  setTarget(company.companyId);
                }}
              >
                <div className="grow">{company.company.name}</div>
                {target === company.companyId && <Check size={16} />}
              </div>
            ))}
          </InfiniteScroll>
        </div>
        <BBBButton
          width="full"
          onClick={() => {
            onChangeCompany(target);
          }}
        >
          Choose
        </BBBButton>
      </BBBBottomSheet>

      <SelectValue
        containerClassName="m-4"
        onClick={() => {
          setShowBottomSheet(true);
        }}
      >
        {selectedCompany.name}
      </SelectValue>
    </>
  );
}

function SelectValue({
  containerClassName,
  children,
  onClick,
}: {
  containerClassName?: string;
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <div
      className={cn(
        'px-4 py-2 border border-neutral-30 rounded-lg flex items-center gap-2',
        containerClassName
      )}
      onClick={onClick}
    >
      <div className="grow truncate">{children}</div>
      <ChevronDown size={16} />
    </div>
  );
}

const companyCreateSChema = yup.object().shape({
  company_name: yup.string().required('Company name is required'),
  industries: yup.mixed().required('Industry is required'),
  country: yup.mixed().required('Country is required'),
});

type CompanyCreationFormSchema = {
  industries?: {
    label: string;
    value: string;
  };
  country?: {
    label: string;
    value: string;
  };
  company_name: string;
};

type CompanyCreationModalProps = {
  onChangeShow: (data: boolean) => void;
  show: boolean;
  onSuccess?: () => void;
};

function CompanyCreationModal({
  show,
  onChangeShow,
  onSuccess,
}: CompanyCreationModalProps) {
  const { handleSubmit, formState, control, reset } =
    useCustomForm<CompanyCreationFormSchema>({
      resolver: yupResolver(companyCreateSChema),
      defaultValues: {
        company_name: '',
        industries: undefined,
        country: undefined,
      },
    });

  const { mutate: addCompany, isLoading } = useAddUserCompany();

  const onSubmit = (data: CompanyCreationFormSchema) => {
    addCompany(
      {
        country: data.country?.value,
        name: data.company_name,
        industry: data.industries?.label,
      },
      {
        onSuccess: (_, payload) => {
          toast.success(`Succesfully added ${payload.name} to your company.`);
          onChangeShow(false);
          reset();
          onSuccess?.();
        },
      }
    );
  };

  return (
    <BBBModal
      show={show}
      onHide={() => onChangeShow(false)}
      title="Create company"
      footer
      handleSave={() => handleSubmit(onSubmit)()}
      disableSave={isLoading}
    >
      <BBBTextInput
        isHookForm
        control={control}
        controlName="company_name"
        placeholder="What is your company name?"
        label="Company name"
        error={formState.errors.company_name?.message}
      />
      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <BBBSelect
            value={field.value}
            options={CountryOptions}
            placeholder="What country are you from?"
            optionLabel="label"
            containerClassName="mb-3"
            optionValue="value"
            onValueChange={field.onChange}
            isSearchable
            label="Country"
            error={(formState.errors.country as FieldError)?.message}
          />
        )}
      />
      <Controller
        name="industries"
        control={control}
        render={({ field }) => (
          <BBBSelect
            value={field.value}
            options={industries}
            optionLabel="label"
            containerClassName="mb-3"
            optionValue="value"
            onValueChange={field.onChange}
            isSearchable
            label="Industry"
            placeholder="What industry are you in?"
            error={(formState.errors.industries as FieldError)?.message}
          />
        )}
      />
    </BBBModal>
  );
}
