import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import { BBBBarChart, BBBOverviewBox } from '@/components/ui';
import useLogCampaignChart from '@/hooks/bitCRM/analytics/useLogCampaignChart';
import useResponsive from '@/hooks/common/useResponsive';
import { Campaign } from '@/types/bitCRM/campaign';

export type CampaignLogAnalyticsProps = {
  loadingCampaign: boolean;
  campaignData: Campaign | undefined;
  campaignId: string;
};

export default function CampaignLogAnalytics({
  loadingCampaign,
  campaignData,
  campaignId,
}: CampaignLogAnalyticsProps) {
  const isMobile = useResponsive('sm');

  const { data: logCampaignData, isInitialLoading: isInitialLoading } =
    useLogCampaignChart(campaignId);

  const isLoadingCampaign = loadingCampaign || isInitialLoading;

  return (
    <div className={`grid grid-cols-4 gap-6 ${isMobile && 'px-4'}`}>
      {isLoadingCampaign ? (
        <CampaignLogAnalyticsSkeleton isMobile={isMobile} />
      ) : (
        <>
          <BBBOverviewBox
            title="Sent"
            className={isMobile ? 'col-span-2' : 'col-span-1'}
            value={campaignData?.sentCount}
          />
          <BBBOverviewBox
            title="Delivered"
            className={isMobile ? 'col-span-2' : 'col-span-1'}
            value={
              <AnalyticsCopy
                base={campaignData?.deliveredCount}
                compare={campaignData?.sentCount}
              />
            }
          />
          <BBBOverviewBox
            title="Opened"
            className={isMobile ? 'col-span-2' : 'col-span-1'}
            value={
              <AnalyticsCopy
                base={campaignData?.readCount}
                compare={campaignData?.sentCount}
              />
            }
          />
          <BBBOverviewBox
            title="Replied"
            className={isMobile ? 'col-span-2' : 'col-span-1'}
            value={
              <AnalyticsCopy
                base={campaignData?.replyCount}
                compare={campaignData?.sentCount}
              />
            }
          />
          <div className={cx('md:col-span-4')}>
            <BBBBarChart
              title="Active Hours"
              tooltip="Track your customer active hours. They read and reply on these hours"
              lineDataKey="value"
              xAxisProps={{
                dataKey: 'time',
              }}
              isHasBrush
              data={logCampaignData}
            />
          </div>
        </>
      )}
    </div>
  );
}

function AnalyticsCopy({ base, compare }: { base?: number; compare?: number }) {
  if (!base || !compare) return <>-</>;

  return (
    <>
      {base.toLocaleString()}{' '}
      <span className="text-base text-neutral-40">
        ({Math.ceil((base / compare) * 100)}%)
      </span>
    </>
  );
}

function CampaignLogAnalyticsSkeleton({ isMobile }: { isMobile: boolean }) {
  return (
    <>
      {Array.from(Array(4).keys()).map((item, idx) => (
        <div
          key={idx}
          className={cx(`${isMobile ? 'col-span-2' : 'col-span-1'}`)}
        >
          <Skeleton height={110} />
        </div>
      ))}
    </>
  );
}
