import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { EmptyCartSettings } from '@/types/bitApp/CartSettings';
import { EmptyCartSetting, EmptyCartTemplate } from '@/types/bitApp/v2';

export default {
  getTemplates: () =>
    api.bitApp
      .get<ApiResponse<EmptyCartTemplate[]>>(`/cart-settings/template`)
      .then((res) => res.data.data),
  getCartSettingsByCompanyId: (companyId: number) =>
    api.bitApp
      .get<ApiResponse<EmptyCartSetting>>(`/cart-settings/${companyId}`)
      .then((res) => res.data.data),
  saveCartSettings: (
    companyId: number,
    payload: Partial<EmptyCartSettings> | null
  ) => api.bitApp.post(`/cart-settings/${companyId}`, payload),
};
