import BBBUpsellCard from '@/components/ui/BBBUpsellCard/BBBUpsellCard';
import {
  isSameOrAbovePricingTier,
  standardPlanDeprecation,
} from '@/constants/pricing';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

export default function Upsell() {
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const pricingName = pricingData?.pricingName || 'free';

  if (
    isSameOrAbovePricingTier(standardPlanDeprecation(pricingName), 'proAdvance')
  )
    return null;

  return (
    <BBBUpsellCard
      title={'Upgrade to collect more data when sign up'}
      description={
        'This feature allow you to gather more data from your customers to enable more comprehensive customer profiling.'
      }
      appType="BITLOGIN"
      id="upgrade-bitlogin-signup"
    />
  );
}
