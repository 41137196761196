import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import {
  CompanyIntegration,
  CompanyIntegrationAssociation,
  IntegrationAssociation,
} from '@/types/bitCRM/integrations';
import { AccountConfig } from '@/types/whatsApp/chat';

export default {
  getCompanyIntegrations: (companyId: number | undefined) =>
    api.bitCRM.v2
      .get<ApiResponse<CompanyIntegrationAssociation[]>>(
        `/integrations/${companyId}`
      )
      .then((res) => res.data.data),
  getIntegrations: () =>
    api.bitCRM.v2
      .get<ApiResponse<IntegrationAssociation[]>>(`/integrations`)
      .then((res) => res.data.data),
  toggleCompanyIntegrations: (
    companyId: number | undefined,
    {
      integrationId,
      status,
      userId,
    }: Pick<CompanyIntegration, 'integrationId' | 'status'> & {
      userId: number;
    }
  ) =>
    api.bitCRM.v2.post(`/integrations/${companyId}/toggle`, {
      integrationId,
      status,
      userId,
    }),
  getWhatsAppBusinessIntegration: (companyId: number) =>
    api.bitCRM.v2
      .get<AccountConfig>(`/integration/whatsapp_unofficial/${companyId}`)
      .then((res) => res.data),
};
