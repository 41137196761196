import { Handle, NodeProps, Position } from 'reactflow';
import { twMerge as cx } from 'tailwind-merge';
import { useDeleteState } from '../../hooks';
import useStore from '../../store';
import { WelcomeActionData } from '../../types';
import TrashWithTransition from '../TrashWithTransition';
import Connector from './Connector';
import CreateConnection from './CreateConnection';

import ThunderIcon from '@/assets/icons/ThunderIcon';
import { BBBCard } from '@/components/ui';

export default function WelcomeActionNode({
  data,
  id,
}: NodeProps<WelcomeActionData>) {
  const childLength = useStore(
    (s) => s.nodes.filter((node) => node.parentNode === id).length
  );
  const deleteNode = useDeleteState();
  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );
  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);

  return (
    <BBBCard
      title="Welcome action"
      className={cx(
        'w-96 group relative',
        expandState === 'hover' && !childLength && 'border-secondary-main',
        expandState === 'clicked' && !childLength && 'border-secondary-main'
      )}
      id={`message-flow-${id}`}
      onClick={() => {
        onChangeTriggerModalState({ id, type: 'welcome-action' });
      }}
      rightButton={
        <TrashWithTransition
          onClick={(e) => {
            deleteNode(id);
            e.stopPropagation();
          }}
        />
      }
      onMouseEnter={() => {
        if (!childLength && expandState !== 'clicked') {
          setExpandState(id, 'hover');
        }
      }}
      onMouseLeave={() => {
        if (!childLength && expandState !== 'clicked') {
          setExpandState(id, null);
        }
      }}
    >
      {!childLength && (
        <>
          <Connector id={id} />
          <CreateConnection
            onAddState={(state) => {
              onChangeStateModal({
                sourceId: id,
                type: state,
              });
            }}
            nodeId={id}
            state={expandState}
            onChangeState={(state) => setExpandState(id, state)}
          />
        </>
      )}
      {data.action === 'run_flow' && (
        <div className="rounded-lg flex items-center gap-2 p-4 border border-neutral-30">
          <ThunderIcon /> {data.flow?.name}
        </div>
      )}
      {data.action === 'send_message' && (
        <>
          <div className="mb-2">Message:</div>
          <div className="rounded-lg bg-neutral-20 p-2 text-neutral-50">
            {data.message}
          </div>
          {!!childLength && (
            <div
              style={{ height: childLength * 64 }}
              className="relative mt-4"
              id={`child1-slot-${id}`}
            />
          )}
        </>
      )}
      <Handle
        type="source"
        position={Position.Right}
        className={!hasEdges ? 'opacity-0 pointer-events-none' : ''}
      />
    </BBBCard>
  );
}
