import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Virtuoso } from 'react-virtuoso';
import { InfiniteData } from '@tanstack/react-query';
import Chat from './Chat';

import {
  SearchChatDataDefault,
  SearchChatDataNext,
  SearchChatPayload,
} from '@/api/services/whatsApp/v3/data';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { useChat2, useReadChat } from '@/hooks/whatsApp/chat';
import useChatLists from '@/hooks/whatsApp/useChatLists';
import useSearchChat from '@/hooks/whatsApp/useSearchChat';
import { setSelectedLivechat } from '@/stores/bitCRM';
import type {
  Chat as ChatType,
  ChatTicketHumanAgent,
} from '@/types/whatsApp/chat';
import { MessageNew } from '@/types/whatsApp/v3';

const defaultChat: ChatType[] = [];

export default function ChatPreview() {
  return <_ChatPreview />;
}

function _ChatPreview() {
  const search = useAppSelector((state) => !!state.bitCRM.searchChat);

  if (search) {
    return <ChatPreviewSearchResults />;
  }

  return <ChatPreviewDefaultResults />;
}

function ChatPreviewSearchResults() {
  const {
    data: searchData,
    isInitialLoading: isLoadingSearch,
    fetchNextPage: fetchNextSearchResults,
    hasNextPage: hasNextSearchResults,
  } = useSearchChat();

  const searchResults = useMemo(
    () => (searchData ? transformSearchResults(searchData) : undefined),
    [searchData]
  );

  if (isLoadingSearch) return <ChatPreviewSkeleton />;

  if (!searchResults)
    return (
      <div className="my-8 text-center text-neutral-60">
        Search results not found
      </div>
    );

  return (
    <div className="pl-2 h-full">
      {searchResults.chats.length > 0 && (
        <>
          <div className="text-xl mb-1 mt-2">Chats</div>
          <ChatResults chats={searchResults.chats} />
        </>
      )}
      {searchResults.messages.length > 0 && (
        <>
          <div className="text-xl mb-1 mt-2">Messages</div>
          <MessageResults
            messages={searchResults.messages}
            hasNextPage={hasNextSearchResults}
            fetchNextPage={fetchNextSearchResults}
          />
        </>
      )}
    </div>
  );
}

function MessageResults({
  messages,
  hasNextPage,
  fetchNextPage,
}: {
  messages: MessageNew[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}) {
  const { mutate: startChat } = useChat2();

  const dispatch = useAppDispatch();

  return (
    <Virtuoso
      className="h-full"
      data={messages}
      endReached={() => hasNextPage && fetchNextPage()}
      itemContent={(index) => {
        const chat = messages[index];

        return (
          <Chat
            key={chat.messageId2}
            clientNumber={chat.clientNumber}
            displayName={chat.clientName || chat.clientNumber}
            lastMsg={chat.body || ''}
            timestamp={chat.timestamp}
            onClick={() => {
              const clientNumber = chat.clientNumber;
              const sources = chat.sources;
              const messageId = chat.messageId2;

              startChat(
                {
                  conversationId: clientNumber,
                  sources,
                },
                {
                  onSuccess: (data) => {
                    dispatch(setSelectedLivechat({ ...data, messageId }));
                  },
                }
              );
            }}
            sources={chat.sources}
            lastMessageType={chat.type}
            lastMessageFromCustomer={chat.fromMe}
            messageId={chat.messageId2}
          />
        );
      }}
    />
  );
}

function ChatResults({ chats }: { chats: ChatType[] }) {
  const readChat = useReadChat();

  return (
    <>
      {chats.map((chat) => (
        <Chat
          key={`${chat.companyId}_${chat.connectedNumber}_${chat.clientNumber}`}
          tags={chat.tags}
          clientNumber={chat.clientNumber}
          profilePictureUpdated={chat.profilePictureUpdated}
          defaultProfilePicture={chat.profilePicture}
          displayName={chat.clientName || chat.clientNumber}
          lastMsg={chat.lastMessage || ''}
          timestamp={chat.lastMessageTimestamp || undefined}
          unreadCount={chat.unreadMessages}
          onClick={() => readChat(chat)}
          sources={chat.sources}
          lastMessageFromCustomer={chat.lastMessageFromCustomer}
          lastMessageType={chat.lastMessageType}
        />
      ))}
    </>
  );
}

function ChatPreviewDefaultResults() {
  const query = useChatLists();

  const {
    data: chatListData,
    isInitialLoading: isLoadingChatlists,
    hasNextPage: hasNextChats,
    fetchNextPage: fetchNextChats,
  } = query;

  const flattenedData =
    chatListData?.pages.flatMap((page) => page?.content || []) ?? defaultChat;

  const nextCursor = flattenedData[flattenedData.length - 1]?.id;

  const readChat = useReadChat();

  return (
    <>
      {isLoadingChatlists ? (
        <ChatPreviewSkeleton />
      ) : !flattenedData.length ? (
        <div className="my-8 text-center text-neutral-60">
          You have no message here
        </div>
      ) : (
        <>
          <Virtuoso
            className="h-full"
            data={flattenedData}
            endReached={() =>
              hasNextChats && fetchNextChats({ pageParam: nextCursor })
            }
            itemContent={(index, chat) => {
              const ticket = chat.ticket as ChatTicketHumanAgent | undefined;
              return (
                <Chat
                  tags={chat.tags}
                  displayName={chat.clientName || chat.clientNumber}
                  timestamp={chat.lastMessageAt || undefined}
                  lastMsg={chat.lastMessage || ''}
                  unreadCount={chat.unreadMessages}
                  onClick={() => readChat(chat)}
                  profilePictureUpdated={chat.profilePictureUpdated}
                  defaultProfilePicture={chat.profilePicture}
                  sources={chat.sources}
                  clientNumber={chat.clientNumber}
                  agentId={ticket?.userId}
                  agentAccent={ticket?.agentAccent}
                  agentName={ticket?.agentName}
                  agentProfilePicture={ticket?.agentProfilePicture}
                  status={chat.status}
                  lastMessageType={chat.lastMessageType}
                  lastMessageFromCustomer={chat.lastMessageFromCustomer}
                  type={chat.type || undefined}
                  ack={chat.lastAck}
                />
              );
            }}
          />
        </>
      )}
    </>
  );
}

function transformSearchResults(payload: InfiniteData<SearchChatPayload>) {
  const results: { chats: ChatType[]; messages: MessageNew[] } = {
    chats: [],
    messages: [],
  };

  if (payload.pages.length) {
    results.chats.push(...(payload.pages[0].data as SearchChatDataDefault)[0]);
    results.messages.push(
      ...(payload.pages[0].data as SearchChatDataDefault)[1],
      ...payload.pages
        .slice(1, payload.pages.length)
        .flatMap((page) => page.data as SearchChatDataNext)
    );
  }

  return results;
}

function ChatPreviewSkeleton() {
  return (
    <>
      {Array.from({
        length: 10,
      }).map((skeleton, index: number) => (
        <div className="flex px-2 pt-3 gap-4 items-center" key={index}>
          <div>
            <Skeleton circle width={80} height={80} />
          </div>
          <div>
            <div>
              <Skeleton width={100} />
            </div>
            <div className="mt-2">
              <Skeleton width={100} height={30} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
