import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { FormSchema } from '..';

import { BBBCard, BBBColorPicker } from '@/components/ui';
import { cn } from '@/utils/styles';

function WidgetColor({ control }: { control: Control<FormSchema> }) {
  const widgetColorType = useWatch({ control, name: 'widgetColorType' });
  const widgetColor1 = useWatch({ control, name: 'widgetColor1' });
  const widgetColor2 = useWatch({ control, name: 'widgetColor2' });

  return (
    <BBBCard title="Widget color">
      <Controller
        control={control}
        name="widgetColorType"
        render={({ field }) => (
          <div className="flex justify-center gap-[2.875rem] mb-5">
            <div>
              <div
                className={cn(
                  'cursor-pointer h-[12.25rem] w-[7.9375rem] rounded-lg',
                  field.value === 'solid' && 'outline outline-offset-2'
                )}
                onClick={() => field.onChange('solid')}
                style={{
                  backgroundColor: widgetColor1,
                }}
              />
              <div className="text-center mt-2.5">Solid color</div>
            </div>
            <div>
              <div
                className={cn(
                  'cursor-pointer h-[12.25rem] w-[7.9375rem] rounded-lg bg-blue-100',
                  field.value === 'gradient' && 'outline outline-offset-2'
                )}
                onClick={() => field.onChange('gradient')}
                style={{
                  background: `linear-gradient(180deg, ${widgetColor1}, ${widgetColor2})`,
                }}
              />
              <div className="text-center mt-2.5">Gradient</div>
            </div>
          </div>
        )}
      />
      <div className="mb-1">Color</div>
      <div className="flex gap-5">
        <Controller
          control={control}
          name="widgetColor1"
          render={({ field }) => (
            <BBBColorPicker
              label={
                widgetColorType === 'solid'
                  ? 'Solid color'
                  : 'Top gradient color'
              }
              color={field.value}
              onChangeColor={field.onChange}
            />
          )}
        />
        {widgetColorType === 'gradient' && (
          <Controller
            control={control}
            name="widgetColor2"
            render={({ field }) => (
              <BBBColorPicker
                label={'Bottom gradient color'}
                color={field.value}
                onChangeColor={field.onChange}
              />
            )}
          />
        )}
      </div>
    </BBBCard>
  );
}

export default WidgetColor;
