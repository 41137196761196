import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import DetailCard from './DetailCard/DetailCard';
import GeneralInfoCard from './GeneralInfoCard/GeneralInfoCard';
import TagsCard from './TagsCard/TagsCard';
import Timelines from './Timelines/Timelines';
import AdditionalDataCard from './AdditionalDataCard';

import { BBBContainer } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import useCustomer from '@/hooks/customers/customer/useCustomer';
import { Source } from '@/types/customers';

export const mapSourceToLabel: Record<Source, string> = {
  bitbybit_add_individual: 'Individual Import',
  bitbybit_bulk_import: 'Bulk Import',
  bitbybit_instagram_inbound: 'Inbound Chat (Instagram)',
  bitbybit_whatsapp_inbound: 'Inbound Chat (WhatsApp)',
  bitbybit_whatsapp_cloud_api_inbound: 'Inbound Chat (WhatsApp Cloud API)',
  bitbybit_whatsapp_jatis_inbound: 'Inbound Chat (WhatsApp Jatis)',
  bitbybit_google_my_business_inbound: 'Inbound Chat (Google My Business)',
  bitbybit_facebook_inbound: 'Inbound Chat (Facebook)',
  shopify_bulk_import: 'Bulk Import (Shopify)',
  bitlogin_google_login: 'bitLogin Google Login',
  bitlogin_facebook_login: 'bitLogin Facebook Login',
  bitlogin_apple_login: 'bitLogin Apple Login',
  bitlogin_whatsapp_login: 'bitLogin WhatsApp Login',
  bitlink_subscription: 'bitLink Subscription',
  shopify_orders_webhook_event: 'Shopify orders event',
  jubelio_orders_webhook_event: 'Jubelio orders event',
};

function CustomerDetail() {
  const isMobile = useResponsive('sm');

  const { id } = useParams<{ id: string }>();

  const {
    data,
    isLoading: loadingCustomer,
    error: errorCustomer,
  } = useCustomer(id);

  if (loadingCustomer) {
    return (
      <BBBContainer
        backLabel="Back to Customer list"
        {...(!isMobile
          ? { hasHeading: true, hasBack: true, backUrl: '/customers' }
          : {
              hasHeading: false,
              hasBack: false,
              backLabel: undefined,
              backUrl: undefined,
            })}
        id="customer-detail-container"
      >
        <div className="flex flex-col-reverse lg:flex-row gap-5">
          <div className="lg:w-2/3">
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
          </div>
          <div className="lg:w-1/3">
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
            <Skeleton
              borderRadius={16}
              height={100}
              className="mb-cardBottom"
            />
          </div>
        </div>
      </BBBContainer>
    );
  }

  if (
    errorCustomer &&
    axios.isAxiosError(errorCustomer) &&
    errorCustomer.response?.status === 400
  ) {
    return <Redirect to={'/customers'} />;
  }

  return (
    <BBBContainer
      backLabel="Back to Customer list"
      {...(!isMobile
        ? { hasHeading: true, hasBack: true, backUrl: '/customers' }
        : {
            hasHeading: false,
            hasBack: false,
            backLabel: undefined,
            backUrl: undefined,
          })}
      id="customer-detail-container"
    >
      <GeneralInfoCard {...data!} />
      <div className="flex flex-col-reverse lg:flex-row gap-5">
        <div className="lg:w-2/3">
          <Timelines />
        </div>
        <div className="lg:w-1/3">
          <DetailCard {...data!} />
          <AdditionalDataCard additionalDatas={data!.additionalDatas} />
          <TagsCard customerTags={data!.customerTags} />
        </div>
      </div>
    </BBBContainer>
  );
}

export default CustomerDetail;
