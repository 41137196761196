import React from 'react';
import { startCase } from 'lodash-es';

import { BBBAlert } from '@/components/ui';
import { Link } from '@/components/ui/Link';
import useSocialConfig from '@/hooks/bitLogin/useSocialConfig';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { SocialLoginProvider } from '@/types/bitLogin/v2';

type Props = {
  type: SocialLoginProvider;
};

function BannerNotification({ type = 'GOOGLE' }: Props) {
  const { data: socialConfig } = useSocialConfig();
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const socialProviderConfig = socialConfig?.filter(
    (config) => config.provider === type
  );

  const isSocialEnabled = socialProviderConfig?.some(
    (config) => config.enabledWeb
  );

  if (!isSocialEnabled && pricingData?.pricingName !== 'free') {
    return (
      <BBBAlert type="info">
        <p className="text-primary-main">
          You have not enabled your {startCase(type.toLowerCase())} login
          button.{' '}
          <Link
            to={{
              pathname: '/bitlogin/social',
            }}
            className="font-bold underline"
          >
            Enable button
          </Link>{' '}
          to activate this feature!
        </p>
      </BBBAlert>
    );
  }

  return null;
}

export default BannerNotification;
