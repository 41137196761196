import { cn } from '@/utils/styles';

export default function AiCredit({
  className,
  withLabel,
  value,
  iconSize = 'md',
}: {
  className?: string;
  withLabel?: boolean;
  value: number;
  iconSize?: 'sm' | 'md';
}) {
  return (
    <div className={cn('flex items-center gap-2 text-3xl', className)}>
      <CoinsIcon size={iconSize} />
      <div className=" text-secondary-main font-semibold">
        {value.toLocaleString()} {withLabel && 'Token'}
      </div>
    </div>
  );
}

function CoinsIcon({ size = 'md' }: { size?: 'sm' | 'md' }) {
  const mapCoinSize = {
    sm: { width: 8, height: 12 },
    md: { width: 14, height: 29 },
  };

  const { width, height } = mapCoinSize[size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 29"
    >
      <path
        fill="#FD823E"
        d="M0 14.5c0 3.87.584 7.372 1.496 9.86.458 1.244.997 2.23 1.566 2.883.569.653 1.138.967 1.722.967.584 0 1.153-.314 1.722-.967.57-.653 1.108-1.64 1.567-2.883.911-2.488 1.495-5.99 1.495-9.86 0-3.87-.584-7.372-1.495-9.858-.459-1.247-.997-2.232-1.567-2.885C5.937 1.104 5.368.79 4.784.79c-.584 0-1.153.314-1.722.967-.57.653-1.108 1.638-1.566 2.885C.584 7.128 0 10.63 0 14.5zm6.955 13.71h2.528c.317 0 .886-.314 1.455-.967.57-.653 1.108-1.64 1.566-2.883C13.416 21.872 14 18.37 14 14.5c0-3.87-.584-7.372-1.496-9.858-.458-1.247-.997-2.232-1.566-2.885C10.37 1.104 9.8.79 9.483.79H6.955c.065.065.126.13.186.201.685.79 1.274 1.888 1.763 3.223.982 2.671 1.57 6.292 1.57 10.286 0 3.994-.588 7.615-1.57 10.285-.489 1.334-1.078 2.434-1.763 3.223-.06.07-.126.137-.186.202z"
      ></path>
    </svg>
  );
}
