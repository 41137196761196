import { useMemo } from 'react';

import { BBBTooltip } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { Accent } from '@/types/utils/color';
import { getTemplateUrl, isTemplateNoAction } from '@/utils/bitCRM';
import { formatDate2 } from '@/utils/common/date';

const mapStatusToText = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  WAITING: 'Waiting',
  SUBMITTED: 'Submitted',
  ERROR: 'Error',
};

const mapStatusToBadgeVariant: Record<string, Accent> = {
  APPROVED: 'success',
  REJECTED: 'danger',
  WAITING: 'info',
  SUBMITTED: 'info',
  ERROR: 'danger',
};

const useColumn = () => {
  return useMemo<HeadersTypes<CampaignTemplate>>(() => {
    return [
      {
        accesor: 'templateName',
        isSortable: true,
        renderHeader: () => 'Template name',
        render: (info) => (
          <div className="line-clamp-1">
            {!isTemplateNoAction(info) ? (
              <Link
                to={getTemplateUrl(info.type, info.id)}
                className="text-primary-main group-hover:underline"
              >
                {info.templateName || '-'}
              </Link>
            ) : (
              info.templateName || '-'
            )}
          </div>
        ),
      },
      {
        accesor: 'message',
        renderHeader: () => 'Message',
        columnWidth: '38%',
        isSortable: true,
        render: (info) => {
          return (
            <div className="line-clamp-1 text-primary-main whitespace-pre-line">
              <BBBTooltip
                content={
                  <div className="line-clamp-4">
                    {info.body?.message?.replace(/<[^>]+>/g, '') || ''}
                  </div>
                }
                show={
                  info.body?.message &&
                  info.body?.message?.replace(/<[^>]+>/g, '').length > 50
                    ? true
                    : false
                }
              >
                {info.body?.message?.replace(/<[^>]+>/g, '') || ''}
              </BBBTooltip>
            </div>
          );
        },
      },
      {
        accesor: 'status',
        renderHeader: () => 'Status',
        isSortable: true,
        render: (info) => {
          const status = info.status;
          return (
            <>
              {status ? (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type={mapStatusToBadgeVariant[status]}
                  text={
                    mapStatusToText[status as keyof typeof mapStatusToText] ||
                    status
                  }
                />
              ) : (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type="neutral"
                  text="Draft"
                />
              )}
            </>
          );
        },
      },
      {
        accesor: 'updatedAt',
        renderHeader: () => 'Last Updated',
        isSortable: true,
        sortOrder: 'desc',
        render: (info) => {
          const updatedAt = info.updatedAt;
          return (
            <>
              {!updatedAt ? null : (
                <p className="text-primary-main">
                  {formatDate2(updatedAt, '12-hour', 'chat')}
                </p>
              )}
            </>
          );
        },
      },
    ];
  }, []);
};

export default useColumn;
