import { X } from 'react-feather';

import { BBBModal, CustomModalProp } from '@/components/ui';

export type BBBStepOnboarding = {
  id: string;
  gif: string;
  title: string;
  desc: string;
};

export type BBBOnboardingModalProps = {
  steps: BBBStepOnboarding[];
  handleBackButton?: () => void;
  handleButton?: () => void;
  buttonText?: string;
  currentStep?: number;
} & Omit<CustomModalProp, 'children'>;

export default function BBBOnboardingModal({
  steps,
  buttonText,
  currentStep = 0,
  handleBackButton,
  handleButton,
  ...props
}: BBBOnboardingModalProps) {
  const step = steps.find((_, index) => index === currentStep);

  if (!step) return null;

  return (
    <BBBModal
      centerFooter
      cancelText="Back"
      onCancel={handleBackButton}
      withoutCancel={currentStep === 0}
      className="p-0 pb-8 overflow-clip"
      bodyClassName="m-0"
      footer
      submitText={
        buttonText
          ? buttonText
          : currentStep === steps.length - 1
          ? 'Finish'
          : 'Next'
      }
      handleSave={handleButton}
      {...props}
    >
      <div className="w-full h-80 bg-[#6E99BD] relative">
        <img src={step.gif} className="w-full h-full object-cover" />
        <div
          className="absolute top-4 right-4 p-1 rounded-full opacity-25 bg-black cursor-pointer"
          onClick={() => props.onHide?.()}
        >
          <X color="#FFFF" size={15} />
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-4 items-center text-center justify-center px-8 mb-3">
        <p className="text-xl text-neutral-70">{step.title}</p>
        <p className="text-neutral-60">{step.desc}</p>
      </div>
    </BBBModal>
  );
}
