export default function CheckCircle2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="69"
      fill="none"
      viewBox="0 0 70 69"
    >
      <rect width="69" height="69" x="0.5" fill="#FD823E" rx="34.5"></rect>
      <path
        fill="#fff"
        d="M46.887 23.825A3.282 3.282 0 0149.1 23a3.276 3.276 0 012.199.856c.588.544.928 1.281.95 2.057a2.895 2.895 0 01-.833 2.1L34.529 47.92c-.29.295-.64.531-1.03.695a3.339 3.339 0 01-2.483.044 3.196 3.196 0 01-1.057-.66L18.76 37.445a3.002 3.002 0 01-.735-.972 2.848 2.848 0 01-.044-2.338c.16-.372.397-.71.699-.995.301-.284.66-.508 1.055-.658a3.344 3.344 0 012.481.04c.39.164.74.4 1.03.694l8.863 8.35 14.698-17.652c.026-.031.054-.06.084-.088h-.004z"
      ></path>
    </svg>
  );
}
