import axios from 'axios';

import { whatsAppApiV1BaseUrl, whatsAppApiV2BaseUrl } from '@/constants/api';
import { whatsAppApiV3BaseUrl } from '@/constants/api';
import { intercepts } from '@/utils/api';

export default {
  v1: intercepts(
    axios.create({
      baseURL: whatsAppApiV1BaseUrl,
    })
  ),
  v2: intercepts(
    axios.create({
      baseURL: whatsAppApiV2BaseUrl,
    })
  ),
  v3: intercepts(
    axios.create({
      baseURL: whatsAppApiV3BaseUrl,
    })
  ),
};
