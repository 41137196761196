export default function FormIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z"></path>
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M15.818 6.273H8.182a.636.636 0 00-.637.636v10.182c0 .351.285.636.637.636h7.636a.636.636 0 00.636-.636V6.909a.636.636 0 00-.636-.636zM8.182 5a1.91 1.91 0 00-1.91 1.91v10.18A1.91 1.91 0 008.182 19h7.636a1.91 1.91 0 001.909-1.91V6.91A1.91 1.91 0 0015.817 5H8.183z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M11.363 10.727c0-.351.285-.636.637-.636h2.545a.636.636 0 110 1.272H12a.636.636 0 01-.637-.636zM11.363 13.273c0 .351.285.636.637.636h2.545a.636.636 0 100-1.272H12a.636.636 0 00-.637.636zM11.363 15.819c0 .35.285.636.637.636h2.545a.636.636 0 100-1.273H12a.636.636 0 00-.637.636zM8.817 10.727c0-.351.285-.636.637-.636h.636a.636.636 0 110 1.272h-.636a.636.636 0 01-.637-.636zM8.817 13.273c0 .351.285.636.637.636h.636a.636.636 0 100-1.272h-.636a.636.636 0 00-.637.636zM8.817 15.819c0 .35.285.636.637.636h.636a.636.636 0 100-1.273h-.636a.636.636 0 00-.637.636z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
