import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useUpdateProxyLink = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  return useMutation(
    async ({ link, redirectId }: { link: string; redirectId?: string }) =>
      redirectId === undefined
        ? api.bitLink.post(`/branding/${activeCompany}`, { link })
        : api.bitLink.post(`/shopify/redirect/company/${activeCompany}`, {
            link,
            redirectId,
          }),
    {
      onSuccess: (_, payload) => {
        if (payload.redirectId === undefined) {
          client.invalidateQueries(['bitlink-branding', activeCompany]);
        } else {
          client.invalidateQueries(['bitlink-shopify-redirect', activeCompany]);
        }
        toast.success('Your link is saved!');
      },
    }
  );
};

export default useUpdateProxyLink;
