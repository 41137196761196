import { SVGProps } from 'react';

export default function BrushIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
      {...props}
    >
      <g fill="currentColor" clipPath="url(#clip0_8505_1084)">
        <path d="M.705 20.69A2.533 2.533 0 010 18.95c-.011-.363.05-.726.179-1.065.13-.34.324-.649.574-.91l4.881-4.958a.967.967 0 000-1.35l-.752-.748a2.727 2.727 0 01-.75-1.863 2.728 2.728 0 01.725-1.874c.228-.25.504-.448.81-.585.306-.136.636-.208.97-.21.357-.011.712.05 1.045.182.332.132.636.33.89.585l6.495 6.611c.476.5.744 1.167.748 1.863a2.726 2.726 0 01-.726 1.872 2.448 2.448 0 01-.808.583 2.407 2.407 0 01-.97.21 2.622 2.622 0 01-1.045-.184 2.661 2.661 0 01-.892-.583l-.803-.818a.836.836 0 00-.593-.25.825.825 0 00-.593.25l-4.937 5.03a2.625 2.625 0 01-1.83.763 2.624 2.624 0 01-1.838-.74l-.075-.071zm6.77-13.41a1.11 1.11 0 00-.8-.304.905.905 0 00-.665.279 1.115 1.115 0 00-.023 1.533l.733.748a2.58 2.58 0 01.727 1.799 2.58 2.58 0 01-.727 1.798L1.858 18.1a1.139 1.139 0 00-.298.816.96.96 0 00.273.677 1.072 1.072 0 001.504.026l4.945-5.034a2.38 2.38 0 011.696-.714 2.38 2.38 0 011.696.714l.803.818a1.102 1.102 0 001.158.234.934.934 0 00.303-.206 1.12 1.12 0 00.025-1.533L7.476 7.28zM15.859 12.142L9.192 5.349a2.13 2.13 0 01-.605-1.491c0-.559.218-1.095.605-1.49l.928-.948c.346-.351.768-.614 1.233-.767a3.04 3.04 0 011.44-.112 8.059 8.059 0 012.093.656c1.412.662 2.224 1.559 2.941 2.353.64.705 1.191 1.316 2.086 1.671a4.83 4.83 0 002.806.153 1.05 1.05 0 01.661.085c.206.1.375.263.483.467a1.106 1.106 0 01-.176 1.277l-4.904 4.941a2.05 2.05 0 01-1.463.615 2.05 2.05 0 01-1.461-.617zm.23-8.175a6.215 6.215 0 00-1.855-1.35 6.578 6.578 0 00-1.69-.51 1.5 1.5 0 00-1.321.434l-.928.956a.512.512 0 00-.113.567c.026.064.065.12.113.168l6.665 6.786a.502.502 0 00.36.156.494.494 0 00.362-.156l3.901-3.945a5.762 5.762 0 01-2.239-.393c-1.228-.49-1.956-1.298-2.663-2.077-.195-.207-.388-.42-.591-.626v-.01z"></path>
      </g>
      <defs>
        <clipPath id="clip0_8505_1084">
          <path
            fill="#fff"
            d="M0 0H24V21H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
