import dayjs from 'dayjs';

import { MessageNewAssociation } from '@/types/whatsApp/v3';

type ChatBubbleTimestampProps = {
  chat: MessageNewAssociation;
  isQuotedLevel?: boolean;
};

function ChatBubbleTimestamp({ chat }: ChatBubbleTimestampProps) {
  return (
    <div className="text-xs text-gray-500">
      {dayjs(chat.timestamp).format('HH:mm')}
    </div>
  );
}

export default ChatBubbleTimestamp;
