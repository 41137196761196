import React from 'react';
import { twMerge as cx } from 'tailwind-merge';

export const DIVIDER_OPTIONS = [
  {
    name: 'solid',
    label: 'Solid Line',
    component: <SolidLine />,
  },
  {
    name: 'dashed',
    label: 'Dashed Line',
    component: <DashedLine />,
  },
  {
    name: 'blank',
    label: 'Blank Space',
    component: <BlankLine />,
  },
] as const;

type Props = {
  value: typeof DIVIDER_OPTIONS[number]['name'];
  onChange: (value: typeof DIVIDER_OPTIONS[number]['name']) => void;
};

function Options({ value, onChange }: Props) {
  return (
    <div className="w-full flex items-center gap-10 justify-center">
      {DIVIDER_OPTIONS.map((divider) => (
        <div
          key={divider.name}
          className="flex flex-col gap-2.5"
          onClick={() => onChange(divider.name)}
        >
          <div
            className={cx(
              'px-5 py-6 rounded-xl border hover:border-primary-main cursor-pointer transition-all',
              value === divider.name
                ? 'border-primary-main'
                : 'border-neutral-30'
            )}
          >
            {divider.component}
          </div>
          <p className="text-sm text-primary-main text-center">
            {divider.label}
          </p>
        </div>
      ))}
    </div>
  );
}

function BlankLine() {
  return (
    <div className="flex flex-col gap-6 items-center justify-center mx-auto">
      <LinkIcon />
      <LinkIcon />
    </div>
  );
}

function DashedLine() {
  return (
    <div className="flex flex-col gap-3 items-center justify-center mx-auto">
      <LinkIcon />
      <svg
        width="134"
        height="5"
        viewBox="0 0 134 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="2.75"
          x2="134"
          y2="2.75"
          stroke="#FD823E"
          strokeWidth="4.5"
          strokeDasharray="8 4"
        />
      </svg>

      <LinkIcon />
    </div>
  );
}

function SolidLine() {
  return (
    <div className="flex flex-col gap-3 items-center justify-center mx-auto">
      <LinkIcon />
      <svg
        width="134"
        height="5"
        viewBox="0 0 134 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="2.75" x2="134" y2="2.75" stroke="#FD823E" strokeWidth="4.5" />
      </svg>
      <LinkIcon />
    </div>
  );
}

function LinkIcon() {
  return (
    <div className="bg-[#F0F0F0] text-neutral-50 text-xs rounded flex items-center justify-center py-2 w-40">
      Link Title
    </div>
  );
}

export default Options;
