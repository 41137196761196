export default function UserAddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#2699FB"
        fillRule="evenodd"
        d="M8 2.5a2.667 2.667 0 100 5.333A2.667 2.667 0 008 2.5zM4 5.167a4 4 0 118 0 4 4 0 01-8 0zM4.432 10.5a1.333 1.333 0 00-1.323 1.168l-.26 2.083a.667.667 0 00.661.749h7.823a.667.667 0 010 1.333H3.511a2.002 2.002 0 01-1.985-2.248l.26-2.082a2.667 2.667 0 012.647-2.336h.234a.667.667 0 110 1.333h-.235zm8.234-1.333c.369 0 .667.298.667.666v.667H14a.667.667 0 010 1.333h-.667v.667a.667.667 0 01-1.333 0v-.667h-.667a.667.667 0 010-1.333H12v-.667c0-.368.298-.666.666-.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
