import React, { Ref, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router';
import { defaultFormSchema, FormSchema, RouteState } from '..';
import Params from './Params';

import message from '@/api/services/whatsApp/v3/message';
import { BBBSelect } from '@/components/ui';
import { useInfiniteTemplates } from '@/hooks/bitCRM/template/template';
import useHistory from '@/hooks/common/useHistory';
import { typePayload } from '@/pages/Misc/WhatsappApiSettings/WhatsappTemplate';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { convertEditorStateToHtml } from '@/utils/common/rich';

type Props = {
  control: Control<FormSchema, any>;
  setValue: UseFormSetValue<FormSchema>;
  type1?: 'campaign' | 'automation' | 'chat';
  onClickRedirect?: (cb: () => void) => void;
  isMenuOpen?: boolean;
  setIsMenuOpen?: (value: React.SetStateAction<boolean>) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  enabled?: boolean;
};

const TemplateOptions = (
  {
    setValue,
    control,
    type1 = 'campaign',
    onClickRedirect,
    error,
    enabled,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>
) => {
  const data = useWatch({ control });
  const { errors } = useFormState({ control });

  const history = useHistory();
  const { pathname } = useLocation();

  const [search, setSearch] = useState('');

  const {
    data: templateData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteTemplates(
    {
      size: 10,
      search,
      type: typePayload(type1),
      status: 'APPROVED',
    },
    { enabled }
  );

  const unifiedTemplateData = useMemo<CampaignTemplate[] | undefined>(
    () => templateData?.pages.flatMap((page) => page.content),
    [templateData?.pages]
  );

  const redirectCb = () => {
    const originalData = data as FormSchema;

    const _data: RouteState = {
      campaignName: originalData.campaignName || defaultFormSchema.campaignName,
      customerSegment:
        originalData.customerSegment || defaultFormSchema.customerSegment,
      isScheduled: originalData.isScheduled || defaultFormSchema.isScheduled,
      date: originalData.date || defaultFormSchema.date,
      campaignSchedule2:
        originalData.campaignSchedule2 || defaultFormSchema.campaignSchedule2,
    };

    history.push({
      pathname: `/misc/whatsapp-api-settings/templates/new`,
      search: `?section=${type1}`,
      state: {
        key: 'shortcut-create-template',
        from: pathname,
        data: _data,
      },
    });
  };

  return (
    <>
      <Controller
        control={control}
        name={'template'}
        render={({ field }) => (
          <BBBSelect
            options={unifiedTemplateData || []}
            optionValue="id"
            optionLabel="templateName"
            label="Message template"
            ref={ref}
            withCreateRedirectOption
            onClickCreateRedirect={() => {
              if (typeof onClickRedirect !== 'undefined') {
                onClickRedirect(redirectCb);
              } else {
                redirectCb();
              }
            }}
            persistCreateRedirectOption
            createRedirectLabel="Create new template"
            search={search}
            isSearchable
            onChangeSearch={setSearch}
            isPaginated
            hasMore={!!hasNextPage}
            fetchNext={fetchNextPage}
            value={field.value || null}
            onValueChange={field.onChange}
            //@ts-ignore
            error={error || errors.template?.message}
            {...props}
          />
        )}
      />

      <Params control={control} setValue={setValue} type={type1} />
    </>
  );
};

export default React.forwardRef(TemplateOptions);
