export default function SunIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#FD823E" d="M12 7a5 5 0 100 10 5 5 0 000-10z"></path>
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M12 1a1 1 0 011 1v1a1 1 0 01-2 0V2a1 1 0 011-1zM3.293 3.293a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zm17.414 0a1 1 0 010 1.414l-1.5 1.5a1 1 0 11-1.414-1.414l1.5-1.5a1 1 0 011.414 0zM1 12a1 1 0 011-1h1a1 1 0 010 2H2a1 1 0 01-1-1zm19 0a1 1 0 011-1h1a1 1 0 010 2h-1a1 1 0 01-1-1zM6.207 17.793a1 1 0 010 1.414l-1.5 1.5a1 1 0 01-1.414-1.414l1.5-1.5a1 1 0 011.414 0zm11.586 0a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zM12 20a1 1 0 011 1v1a1 1 0 01-2 0v-1a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
