import { AlertCircle, Trash2 } from 'react-feather';
import ModelRuleOptions from '../../components/ModelRuleOptons';

import { ModelRules } from '@/api/services/bitAi/v2-new/model';
import { BBBTextInput, SingleOnValueChangeParam } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';

export default function useColumnManage({
  onUpdate,
  onRemove,
}: {
  onUpdate: (
    rowId: string,
    columnId: string,
    value:
      | number
      | string
      | SingleOnValueChangeParam<{ value: string; label: string }>
  ) => void;
  onRemove: (rowId: string) => void;
}): HeadersTypes<Pick<ModelRules, 'id' | 'qty' | 'index' | 'condition'>> {
  const toggleConfirmation = useConfirmationModal();

  return [
    {
      accesor: 'qty',
      renderHeader: () => <div className="ml-6">Product Qty</div>,
      render: (row) => (
        <div className="flex items-center gap-4">
          <BBBTextInput
            value={row.qty}
            onChange={(e) => onUpdate(row.id, 'qty', e.target.value)}
            placeholder="Please input with minimum 1 size"
            inputClassName={`${!row.qty && 'border-danger-main'}`}
            containerClassname="mb-0"
          />
          {!row.qty && <AlertCircle fill="#FF6060" color="#FFFF" />}
        </div>
      ),
    },
    {
      accesor: 'condition',
      renderHeader: () => 'Product Ordering',
      render: (row) => (
        <ModelRuleOptions
          value={row.condition}
          onValueChange={(value) => onUpdate(row.id, 'condition', value)}
        />
      ),
    },
    {
      isAdditionalColumn: true,
      renderHeader: () => 'Action',
      render: (row) => (
        <div className="flex py-4">
          <Trash2
            className="text-danger-main cursor-pointer hover:text-red-400"
            onClick={() =>
              toggleConfirmation({
                title: 'Delete Product',
                description: 'Are you sure want to delete this product?',
                submitText: 'Yes',
                onAccept: (hide) => {
                  onRemove(row.id);
                  hide();
                },
              })
            }
          />
        </div>
      ),
    },
  ];
}
