import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketBaileys, socketWhatsApp } from 'socket';
import { useAppDispatch } from '../rtk/store';

import { useActiveCompany } from '@/hooks/rtk/selector';
import useAccounts from '@/hooks/whatsApp/useAccounts';
import { setConnectIntegrationMeta } from '@/stores/common';
import { toast } from '@/utils/common/toast';

type QRFromSocketTypes = {
  SessId: string;
  companyId: number;
  src: string;
};

type SocketReadyTypes = {
  id: number;
};

const useConnectWhatsApp = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const activeCompany = useActiveCompany();

  const { data, status: accountsQueryStatus } = useAccounts();

  useEffect(() => {
    if (accountsQueryStatus === 'success') {
      socketBaileys.emit('request-qr-code', {
        companyId: activeCompany,
        data: data?.apiKey || undefined,
      });
    }
  }, [accountsQueryStatus, activeCompany, data?.apiKey]);

  useEffect(() => {
    socketBaileys.emit('join-room', {
      companyId: activeCompany,
      channel: 'WHATS_APP',
    });
  }, [activeCompany]);

  useEffect(() => {
    socketWhatsApp.emit('join-room', {
      companyId: activeCompany,
      channel: 'WHATS_APP',
    });
  }, [activeCompany]);

  useEffect(() => {
    dispatch(
      setConnectIntegrationMeta({
        modalShow: true,
        isLoading: true,
        isLoadingConnect: false,
        qrUrL: undefined,
      })
    );
  }, [dispatch]);

  const onSocketReady = useCallback(
    (data: SocketReadyTypes) => {
      socketBaileys.emit('update-integration-data', {
        id: data.id,
        companyId: activeCompany,
      });

      setTimeout(() => {
        queryClient.invalidateQueries([`bitcrm-company-integrations`]);
        queryClient.invalidateQueries([`bitchat-company-integrations`]);
      }, 500);

      dispatch(setConnectIntegrationMeta({ isLoadingConnect: false }));

      toast.success('Successfully connected');
    },
    [activeCompany, dispatch, queryClient]
  );

  const onReceiveQR = useCallback(
    (data: QRFromSocketTypes) => {
      if (data.companyId === activeCompany) {
        dispatch(
          setConnectIntegrationMeta({
            isLoading: false,
            qrUrl: data.src,
          })
        );
      }
    },
    [activeCompany, dispatch]
  );

  const onLoadConnect = useCallback(() => {
    dispatch(
      setConnectIntegrationMeta({ isLoadingConnect: true, modalShow: false })
    );
  }, [dispatch]);

  useEffect(() => {
    socketBaileys.on('qr', onReceiveQR);
    return () => {
      socketBaileys.off('qr', onReceiveQR);
    };
  }, [onReceiveQR]);

  useEffect(() => {
    socketBaileys.on('ready', onSocketReady);
    return () => {
      socketBaileys.off('ready', onSocketReady);
    };
  }, [onSocketReady]);

  useEffect(() => {
    socketBaileys.on('loading_screen', onLoadConnect);
    return () => {
      socketBaileys.off('loading_screen', onLoadConnect);
    };
  }, [onLoadConnect]);
};

export default useConnectWhatsApp;
