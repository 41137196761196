import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import {
  CompanyIntegration,
  CompanyIntegrationAssociation,
  IntegrationAssociation,
} from '@/types/bitChat/integrations';

export default {
  getCompanyIntegrations: (companyId: number | undefined) =>
    api.bitChat
      .get<ApiResponse<CompanyIntegrationAssociation[]>>(
        `/integrations/${companyId}`
      )
      .then((res) => res.data.data),
  getIntegrations: () =>
    api.bitChat
      .get<ApiResponse<IntegrationAssociation[]>>(`/integrations`)
      .then((res) => res.data.data),
  toggleCompanyIntegrations: (
    companyId: number | undefined,
    { integrationId, status, userId }: ToggleCompanyIntegrationPayload
  ) =>
    api.bitChat.post(`/integrations/${companyId}/toggle`, {
      integrationId,
      status,
      userId,
    }),
};

export type ToggleCompanyIntegrationPayload = Pick<
  CompanyIntegration,
  'integrationId' | 'status'
> & {
  userId: number;
};
