import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch } from '../rtk/store';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { updateCompany } from '@/stores/auth';
import { Company } from '@/types/systemLogin';
import { toast } from '@/utils/common/toast';

const useUpdateCompany = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation<Company, unknown, Partial<Omit<Company, 'id'>>>(
    (payload) =>
      api.systemLogin
        .put<ApiResponse<Company>>(`/company/${activeCompany}`, payload)
        .then((res) => res.data.data),
    {
      onSuccess: (data) => {
        toast.success('Company profile has been updated');
        client.invalidateQueries(['authenticated-company']);
        dispatch(updateCompany(data));
      },
    }
  );
};
export default useUpdateCompany;
