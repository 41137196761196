import { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { MoonLoader } from 'react-spinners';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import DraggableIcon from '@/assets/icons/BBBDraggableIcon';
import { BBBCard, BBBPrimarySwitch } from '@/components/ui';
import api from '@/config/api';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';
import useCompanyNavigation from '@/hooks/bitApp/design/useCompanyNavigation';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setActiveDesign, setActivePreview } from '@/stores/bitApp';
import { Branding } from '@/types/bitApp/v2';
import { toast } from '@/utils/common/toast';

type Props = {
  className?: string;
};

function NavigationCard({ ...props }: Props) {
  const { data, status } = useOnboarding('design');

  if (status === 'success' && data?.lastStep) {
    if (data.lastStep === 8) return <_NavigationCard isNewUsers {...props} />;
  }

  return <_NavigationCard {...props} />;
}

function _NavigationCard({
  className,
  isNewUsers,
}: Props & {
  isNewUsers?: boolean;
}) {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const draggingDroppable = useAppSelector(
    (state) => state.bitApp.draggingDroppable
  );

  const draggingDraggable = useAppSelector(
    (state) => state.bitApp.draggingDraggable
  );

  const { data: brandingData } = useBrandingQuery();
  const { data } = useCompanyNavigation();

  const { mutate: save, isLoading: isLoadingSave } = useMutation(
    (data: Partial<Branding>) =>
      api.bitApp.post(`/branding/${activeCompany}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitapp-branding']);
      },
    }
  );

  const handleChangeBranding = (payload: Partial<Branding>) => {
    save({
      ...brandingData,
      ...payload,
    });
  };

  useEffect(() => {
    if (
      !!draggingDroppable &&
      !!draggingDraggable &&
      (!['non-active-bottom', 'active-bottom'].includes(draggingDroppable) ||
        ['bottomNav-5', 'bottomNav-6', 'bottomNav-1'].includes(
          draggingDraggable
        ))
    ) {
      toast.error('This navigation cannot be deactivated.');
    }
  }, [draggingDraggable, draggingDroppable]);

  return (
    <BBBCard
      id="design-navigation"
      className={cx('my-4', className)}
      titleClassName="text-primary-main text-xl"
      headerClassName="w-full inline"
      title="Bottom Navigation"
      desc={
        'Select at least three (3) or up to five (5) menus to be displayed in the navigation bar'
      }
      rightTitleButton={
        <div className="flex items-center gap-2">
          {isLoadingSave && <MoonLoader size={16} />}
          <BBBPrimarySwitch
            checked={brandingData?.navigationMode === 'bottom'}
            onChange={() => {
              handleChangeBranding({
                navigationMode:
                  brandingData?.navigationMode === 'bottom' ? null : 'bottom',
              });
            }}
          />
        </div>
      }
    >
      <div className="relative">
        {isNewUsers && <DragIconAnimation />}
        <div className="bottom-nav-section">
          <Droppable
            droppableId="active-bottom"
            isDropDisabled={
              !!draggingDroppable &&
              (!['non-active-bottom', 'active-bottom'].includes(
                draggingDroppable
              ) ||
                (draggingDroppable === 'non-active-bottom' &&
                  data?.active.length === 5))
            }
          >
            {(provided) => (
              <div
                className="bottom-nav-wrapper"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <p className="text-sm font-bold mb-5">Active navigation</p>
                {data?.active?.map((nav, idx: number) => (
                  <Draggable
                    index={idx}
                    key={`${nav.navigationId}`}
                    draggableId={`bottomNav-${nav.navigationId}`}
                  >
                    {(providedDraggable) => (
                      <div
                        className="shadow-[0px_3px_6px_0px_#9B9B9B26] p-5 flex rounded-xl items-center justify-between mb-3 border border-neutral-30"
                        key={`${nav.navigationId}`}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <div className="flex items-center gap-5">
                          <img
                            src={nav.navigation.icon || ''}
                            alt={nav.navigation.name || 'icon'}
                            className="w-[1.875rem] h-[1.875rem] object-cover"
                          />
                          <div className="text-sm font-semibold">
                            {nav.navigation.name}
                          </div>
                        </div>

                        <div
                          className={cx(
                            'flex items-center',
                            nav.navigation.name === 'Category' && 'gap-5'
                          )}
                        >
                          {nav.navigation.name === 'Category' && (
                            <p
                              className="text-info-main underline cursor-pointer"
                              onClick={() => {
                                dispatch(setActiveDesign('category'));
                                dispatch(setActivePreview('category'));
                              }}
                            >
                              Set up category
                            </p>
                          )}
                          <DraggableIcon />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className="bottom-nav-section" style={{ marginTop: '1.5rem' }}>
          <Droppable
            droppableId="non-active-bottom"
            isDropDisabled={
              !!draggingDroppable &&
              !!draggingDraggable &&
              (!['non-active-bottom', 'active-bottom'].includes(
                draggingDroppable
              ) ||
                ['bottomNav-5', 'bottomNav-6', 'bottomNav-1'].includes(
                  draggingDraggable
                ))
            }
          >
            {(provided) => (
              <div
                className="bottom-nav-wrapper"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <p className="text-sm font-bold mb-5">
                  Non - Active navigation
                </p>

                {data?.unActive
                  ?.sort((a, b) => (b.position ?? 0) - (a.position ?? 0))
                  ?.map((data, idx: number) => (
                    <Draggable
                      index={idx}
                      key={data.navigation.id}
                      draggableId={`bottomNav-${data.navigation.id.toString()}`}
                    >
                      {(providedDraggable) => (
                        <div
                          className="shadow-[0px_3px_6px_0px_#9B9B9B26] p-5 flex rounded-xl items-center justify-between mb-3 opacity-50 border border-neutral-30"
                          key={data.navigation.id}
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                        >
                          <div className="flex items-center gap-5">
                            <img
                              src={data.navigation.icon || ''}
                              alt={data.navigation.name || 'icon'}
                              className="w-[1.875rem] h-[1.875rem] object-cover"
                            />
                            <div className="text-sm font-semibold">
                              {data.navigation.name}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <DraggableIcon />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </BBBCard>
  );
}

function DragIconAnimation() {
  return (
    <>
      <motion.svg
        initial={{ y: 50, rotate: 30 }}
        animate={{ y: 300, rotate: 30 }}
        transition={{
          type: 'tween',
          repeat: Infinity,
          duration: 2.6,
          repeatType: 'mirror',
          repeatDelay: 0.5,
        }}
        className="absolute right-0 w-[35px] h-[35px] z-[150]"
        width="103"
        height="103"
        viewBox="0 0 103 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_502_3684)">
          <path
            d="M47.4972 66.441C46.4147 67.066 43.0189 61.8097 41.4563 59.1034L35.9872 49.6315C46.1009 43.1905 66.5987 30.1523 67.6812 29.5272C68.7638 28.9022 70.2157 32.8752 70.8064 34.9398C63.4877 45.1797 48.5797 65.8159 47.4972 66.441Z"
            fill="white"
          />
          <path
            d="M44.6923 64.7082L37.0461 62.8587C36.4078 62.7045 35.7449 62.681 35.0973 62.7896C34.4496 62.8982 33.8307 63.1367 33.2776 63.4908C32.7246 63.8448 32.2489 64.3071 31.8791 64.8498C31.5093 65.3925 31.2532 66.0043 31.126 66.6486C30.8909 67.8339 31.105 69.0644 31.7267 70.1006C32.3484 71.1369 33.3332 71.9049 34.4897 72.2554L60.4741 80.1229C62.1295 80.6213 63.9136 80.4175 65.4101 79.5534L77.951 72.3124C84.4461 68.5621 85.6509 60.6494 82.5257 55.2369L69.1328 32.0414"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.9608 30.0118C69.0818 29.3645 70.5152 29.7486 71.1625 30.8695L84.5554 54.065C88.2504 60.4644 86.8686 69.8699 79.1229 74.3422L66.5821 81.5832C64.5225 82.7724 62.0695 83.051 59.7984 82.3672L33.8105 74.4987C32.0973 73.9795 30.6378 72.8416 29.7168 71.3065C28.7961 69.7717 28.4789 67.9495 28.8268 66.194C29.0152 65.2399 29.3946 64.3338 29.9422 63.5302C30.49 62.7262 31.1947 62.0415 32.014 61.517C32.8332 60.9925 33.7502 60.6391 34.7095 60.4782C35.6689 60.3173 36.6509 60.3521 37.5965 60.5806L45.2433 62.4302C46.5015 62.7345 47.2747 64.0012 46.9703 65.2593C46.666 66.5174 45.3994 67.2906 44.1413 66.9863L36.4957 65.137C36.4955 65.1369 36.4959 65.137 36.4957 65.137C36.1648 65.0571 35.8206 65.0448 35.485 65.1011C35.1491 65.1575 34.8281 65.2812 34.5413 65.4648C34.2544 65.6484 34.0077 65.8882 33.816 66.1696C33.6242 66.451 33.4914 66.7684 33.4254 67.1025C33.3035 67.7172 33.4141 68.3576 33.7365 68.895C34.0589 69.4324 34.5696 69.8307 35.1694 70.0125L61.1498 77.8787C61.1493 77.8786 61.1503 77.8789 61.1498 77.8787C62.1891 78.1912 63.305 78.0626 64.2382 77.5238L76.7791 70.2828C82.0235 67.2546 83.0514 60.8347 80.496 56.4089L67.103 33.2134C66.4558 32.0925 66.8398 30.659 67.9608 30.0118Z"
            fill="black"
          />
          <path
            d="M61.9062 38.2744L61.0139 36.7291C57.4418 30.5426 65.5607 25.8548 69.1328 32.0413M53.7873 42.9622L51.1091 38.3236C47.537 32.1371 55.6558 27.4493 59.2279 33.6358L61.9062 38.2744M45.6685 47.6499L41.762 40.8842C38.19 34.6977 46.3088 30.0099 49.8809 36.1965L53.7873 42.9622M44.6923 64.7081L27.3928 34.7469C26.7712 33.6703 26.6027 32.3908 26.9244 31.19C27.2462 29.9891 28.0317 28.9652 29.1084 28.3436C31.3492 27.0498 34.2147 27.8129 35.5101 30.0564L45.6685 47.6499"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2803 30.3734C29.742 30.6842 29.3492 31.1962 29.1883 31.7966C29.0275 32.397 29.1117 33.0368 29.4225 33.5751L46.722 63.5363C47.3692 64.6572 46.9852 66.0907 45.8642 66.7379C44.7433 67.3852 43.3098 67.0011 42.6626 65.8801L25.3631 35.919C24.4307 34.304 24.1779 32.3848 24.6605 30.5835C25.1431 28.7823 26.3215 27.2465 27.9364 26.314C31.2955 24.3745 35.5956 25.5175 37.5398 28.8846L40.1447 33.396C40.6647 32.7811 41.2856 32.2662 41.9704 31.8708C43.4975 30.9891 45.3801 30.6794 47.2275 31.1901C47.769 31.3398 48.2918 31.5554 48.7895 31.8348C49.4044 30.7675 50.2888 29.9041 51.3175 29.3102C52.8445 28.4285 54.7271 28.1188 56.5745 28.6294C57.4247 28.8645 58.2286 29.2619 58.9616 29.8137C59.554 28.9417 60.3345 28.2283 61.2223 27.7157C62.7494 26.834 64.632 26.5242 66.4794 27.0349C68.3532 27.5529 70.0021 28.8598 71.1625 30.8695C71.8097 31.9905 71.4257 33.4239 70.3047 34.0711C69.1837 34.7184 67.7503 34.3343 67.103 33.2134C66.4774 32.1298 65.7719 31.7027 65.2304 31.553C64.6625 31.396 64.0688 31.4849 63.5662 31.7751C63.0636 32.0653 62.6898 32.535 62.5418 33.1053C62.4008 33.6491 62.418 34.4737 63.0436 35.5573L63.9359 37.1025C64.5831 38.2235 64.1991 39.6569 63.0781 40.3042C61.9571 40.9514 60.5237 40.5674 59.8765 39.4464L57.1982 34.8079C56.5725 33.7243 55.867 33.2972 55.3255 33.1475C54.7576 32.9905 54.164 33.0794 53.6613 33.3696C53.1587 33.6598 52.7849 34.1295 52.637 34.6998C52.4959 35.2436 52.5131 36.0682 53.1388 37.1518L55.817 41.7903C56.4643 42.9113 56.0803 44.3447 54.9593 44.992C53.8383 45.6392 52.4049 45.2552 51.7576 44.1342L47.8511 37.3685C47.2255 36.2849 46.52 35.8578 45.9785 35.7081C45.4106 35.5511 44.8169 35.64 44.3143 35.9302C43.8117 36.2205 43.4379 36.6901 43.2899 37.2605C43.1489 37.8042 43.1661 38.6288 43.7917 39.7124L47.6982 46.4781C48.3455 47.5991 47.9614 49.0325 46.8405 49.6797C45.7195 50.327 44.286 49.943 43.6388 48.822L33.4804 31.2285C32.8338 30.1086 31.4028 29.7253 30.2803 30.3734Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_502_3684">
            <rect
              width="75"
              height="75"
              fill="white"
              transform="translate(0.27356 37.7759) rotate(-30.002)"
            />
          </clipPath>
        </defs>
      </motion.svg>
    </>
  );
}

export default NavigationCard;
