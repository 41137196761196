import { shallowEqual } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useActiveCompany, useApiKey } from '../rtk/selector';
import { selectActiveChat } from './chat';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

export const useTicketByChatQueryKeys = () => {
  const activeCompany = useActiveCompany();

  const selectedChat = useAppSelector(selectActiveChat, shallowEqual);

  return ['ticket-chat', activeCompany, selectedChat] as const;
};

const useTicketByChat = () => {
  const ticketChatQueryKeys = useTicketByChatQueryKeys();
  const [, activeCompany, selectedChat] = ticketChatQueryKeys;

  const apiKey = useApiKey();

  return useQuery(
    ticketChatQueryKeys,
    () =>
      services.whatsApp.v2.ticket.getTicketByChat(
        activeCompany!,
        selectedChat!.sources,
        selectedChat!.clientNumber,
        apiKey
      ),
    {
      enabled: !!selectedChat,
    }
  );
};

export default useTicketByChat;
