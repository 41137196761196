import { createId } from '@paralleldrive/cuid2';

import { PricingPlan } from '@/api/services/pricing';
import { IBBBTableComparisonData } from '@/types/pricing';

export const TRESHOLD_1 = 0.5;
export const TRESHOLD_2 = (limit: number) =>
  limit === 3 || limit === 5 ? 0.8 : 0.9;

export const TRESHOLD_3 = 1;
export const TRESHOLD_1_PERCENTAGE = TRESHOLD_1 * 100;
export const TRESHOLD_2_PERCENTAGE = (limit: number) => TRESHOLD_2(limit) * 100;
export const TRESHOLD_3_PERCENTAGE = TRESHOLD_3 * 100;

export const dataStaticPlanComparisonBitLink = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      {
        id: createId(),
        title: 'Free',
        name: 'free',
        price: '0',
      },
      {
        id: createId(),
        title: 'Standard',
        name: 'standard',
        price: '4.85',
      },
    ],
    features: [
      {
        id: createId(),
        title: 'Link and domain',
        data: [
          {
            title: 'Shoppable e-commerce links',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Use your Offical Store domain',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Email subscription',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Unlimited links',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Social icons',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'QR code to bring customers online',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Mobile app link',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Embed Youtube videos',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
      },
      {
        id: createId(),
        title: 'Monetization',
        data: [
          {
            title: 'No transaction fee',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Stores powered by Shopify',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
      },
      {
        id: createId(),
        title: 'Customization',
        data: [
          {
            title: 'Free themes',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Button & font styles',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Image banners with various styles',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Link sections',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Link thumbails + icons',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Link dividers',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Link headers',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Custom profile picture, title & bio.',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Thousand of custom background colors',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Gradient background colors',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Extended themes',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Hide bitLink logo from bottom page',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
      },
      {
        id: createId(),
        title: 'Analytics',
        data: [
          {
            title: 'Custom report with UTM',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Custom date range',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Data export',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Referral based links',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Individual link analytics',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Lifetime analytics',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Conversion tracking',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Commerce links analytics',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Mailing & phone number list',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Device analytics',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Location analytics',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
      },
      {
        id: createId(),
        title: 'Marketing Technology',
        data: [
          {
            title: 'Store bitLink contacts in Google sheets',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Add UTM to track campaign performance',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Send email with Shopify mail',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
      },
      {
        id: createId(),
        title: 'Management',
        data: [
          {
            title: 'Priority support',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Knowledge base',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Email support',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Multi Store set up',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'WhatsApp support',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Add admins',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparisonBitApp = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      {
        id: createId(),
        title: 'Free',
        name: 'free',
        price: '0',
      },
      {
        id: createId(),
        title: 'Standard',
        name: 'standard',
        price: '85',
      },
    ],
    features: [
      {
        title: 'in-App features',
        data: [
          {
            title: 'Unlimited design blocks!',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Real-time updates & Shopify sync',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Customizable navigations',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Natively integrates with Android and iOS',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Wishlist',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Product Reviews',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Customizations',
        data: [
          {
            title: 'Custom your own app icon and splash screen',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Custom your own layouts and themes',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Product Categories',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Showcase your blog',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Custom your in-app banners and product images',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'User information',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Select button colors',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Hide bitApp logo',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Integrations',
        data: [
          {
            title: 'Stamped.io',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Yotpo',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Push Notifications',
        data: [
          {
            title: 'Instant blast notification at bitCRM',
            free: 'Unlimited',
            standard: 'Unlimited',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'In-app login options',
        data: [
          {
            title: 'Login with email',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Login with Google',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Additional fee',
        data: [
          {
            title: 'Charge every transaction through bitApp',
            free: '2% (100 first orders free)',
            standard: false,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Shopify Sync',
        data: [
          {
            title: 'Customer Login/Sign Up',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Real time sync',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Order Tracking',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Products & Collections',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Gift Cards',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Discount & Automatic Discount',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Pages',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Search',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Pick Up & Local Delivery',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Sub Collections',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Blogs & Articles',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Analytics',
        data: [
          {
            title: 'Integration with Google Analytics (UTM)',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Basic app analytics',
            free: false,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Management',
        data: [
          {
            title: 'Priority support',
            free: false,
            standard: true,
            id: createId(),
          },
          {
            title: 'Email support',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'Multi Store set up',
            free: true,
            standard: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Support',
            free: true,
            standard: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparisonBitCRM = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      {
        id: createId(),
        title: 'Free',
        name: 'free',
        price: '0',
      },
      {
        id: createId(),
        title: 'Basic',
        name: 'standard',
        price: '9.99',
      },
      {
        id: createId(),
        title: 'Standard',
        name: 'pro',
        price: '29.99',
      },
    ],
    features: [
      {
        title: 'WhatsApp Number',
        data: [
          {
            title: 'WhatsApp Cloud API',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
            description:
              'Use official WhatsApp Cloud API by Meta. You connect your official WhatsApp API.',
          },
          {
            title: 'WhatsApp Business App',
            free: 'Use bitbybit sandbox number',
            standard: 'Use your number',
            pro: 'Use your number',
            id: createId(),
            description:
              'Connect your whatsapp directly, just scan a QR to connect',
          },
        ],
        id: createId(),
      },
      {
        title: 'WhatsApp Automations',
        data: [
          {
            title: 'Custom scheduling',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Send to international numbers',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Order created',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Abandoned cart',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Order fulfilled',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Active automations',
            free: '3 automations',
            standard: '5 automations',
            pro: 'Unlimited automations',
            id: createId(),
          },
          {
            title: 'Integration with Shopify chatbot',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Customizables Automations',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Payment reminder',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Product review',
            free: false,
            standard: false,
            pro: true,
            id: createId(),
          },
          {
            title: 'Free messages per month',
            free: 100,
            standard: 1000,
            pro: 5000,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'WhatsApp campaign',
        data: [
          {
            title: 'Custom segmentations',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Send media broadcast',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Send button broadcast',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Custom schedulling',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Dynamic variables',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Give personalized discount vouchers',
            free: false,
            standard: false,
            pro: true,
            id: createId(),
            isSoon: true,
          },
          {
            title: 'Free messages per month',
            free: false,
            standard: 2000,
            pro: 20000,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'bitCRM Integrations',
        data: [
          {
            title: 'Available apps',
            free: 0,
            standard: 2,
            pro: 2,
            id: createId(),
          },
          {
            title: 'Yotpo for product reviews',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Stamped.io - product review & recommendations',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Integration with shopify chatbot',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Analytics',
        data: [
          {
            title: 'Real time data',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Custom date range',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Open rate',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Click rate',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sent chats',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Unsent chats',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Best performing chats',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
            isSoon: true,
          },
          {
            title: 'Under performing chats',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
            isSoon: true,
          },
        ],
        id: createId(),
      },
      {
        title: 'Management',
        data: [
          {
            title: 'Priority support',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Email support',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Multi Store set up',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Support',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Additional add-ons',
        data: [
          {
            title: 'Message Included',
            free: 9,
            standard: 6,
            pro: 6,
            id: createId(),
          },
          {
            title: 'Cost Per Message',
            free: 200,
            standard: 200,
            pro: 200,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparisonBitLogin = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      {
        id: createId(),
        title: 'Free',
        name: 'free',
        price: '0',
      },
      {
        id: createId(),
        title: 'Standard',
        name: 'pro',
        price: '4.85',
      },
      {
        id: createId(),
        title: 'Advance',
        name: 'proAdvance',
        price: '9',
      },
    ],
    features: [
      {
        title: 'Social login',
        data: [
          {
            title: 'Unlimited logins per day',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sign in with Facebook',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sign in with Google',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sign in with Apple',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sign in with your own OAuth',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
            description:
              'With your own OAuth, your store name will be displayed to customers when they log in with Google.',
          },
          {
            title: 'Google One Tap',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
            description:
              'Google One-Tap is a feature that allows user to sign up or login to your website with just a single click.',
          },
        ],
        id: createId(),
      },
      {
        title: 'Integrations',
        data: [
          {
            title: 'Implement social login in mobile app with bitApp',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Sync contacts to Shopify',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Additional data collections',
            free: false,
            proAdvance: true,
            pro: false,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Customization',
        data: [
          {
            title: 'Free button styles',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Customizable button position',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Select your own button color',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Analytics',
        data: [
          {
            title: 'Real time data',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Custom date range',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Login sessions',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'New customers',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Avg. Login sessions',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Login channel',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Peak hours',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Management',
        data: [
          {
            title: 'Priority support',
            free: false,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Knowledge base',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Email support',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Multi Store set up',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Support',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Add admins',
            free: true,
            proAdvance: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparionBitChat = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      {
        id: createId(),
        title: 'Free',
        name: 'free',
        price: '0',
      },
      {
        id: createId(),
        title: 'Standard',
        name: 'standard',
        price: '45',
      },
      {
        id: createId(),
        title: 'Pro',
        name: 'pro',
        price: '95',
      },
    ],
    features: [
      {
        title: 'WhatsApp Number',
        data: [
          {
            title: 'WhatsApp API',
            free: {
              label: 'Using unofficial API',
              description:
                'May not be as secure and reliable as the official WhatsApp API. It may not have all the benefits of the official WhatsApp API.',
            },
            standard: {
              label: 'Can use official API',
              description:
                "The official WhatsApp API is more reliable and secure to use as it's directly connected to WhatsApp's infrastructure and not dependent on any third-party services.",
            },
            pro: {
              label: 'Can use official API',
              description:
                "The official WhatsApp API is more reliable and secure to use as it's directly connected to WhatsApp's infrastructure and not dependent on any third-party services.",
            },
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Multi-agent chat',
        data: [
          {
            title: 'Conversations/mo',
            free: 100,
            standard: 1000,
            pro: 1000,
            id: createId(),
          },
          {
            title: 'Ticket system',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Connected to Shopify orders data',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'AI tagging',
            free: 100,
            standard: 300,
            pro: 1000,
            id: createId(),
            description:
              'AI tagging is a powerful feature that automatically generates tags from customer chats to help agents quickly identify and resolve tickets.',
          },
        ],
        id: createId(),
      },
      {
        title: 'Chatbot',
        data: [
          {
            title: 'Active auto-reply',
            free: 5,
            standard: 10,
            pro: 20,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Message cost',
        data: [
          {
            title: 'Cost per incoming message',
            free: '$0.0010',
            standard: '$0.0007',
            pro: '$0.0003',
            id: createId(),
          },
          {
            title: 'Cost per outgoing message',
            free: '$0.0010',
            standard: '$0.0007',
            pro: '$0.0003',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Analytics',
        data: [
          {
            title: 'Real time data',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Custom date range',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Avg. Response time',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Avg. Resolution time',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Avg. Rating',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'CSAT Rating',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Peak hours',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Avg. Chat volume',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Agent performance',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Management',
        data: [
          {
            title: 'Priority support',
            free: false,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Email support',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'Multi Store set up',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Support',
            free: true,
            standard: true,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Additional add-ons',
        data: [
          {
            title: 'API access',
            free: false,
            standard: false,
            pro: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparionBitChat2 = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      { id: createId(), title: 'Free', name: 'free', price: '0' },
      { id: createId(), title: 'Basic', name: 'standard', price: '49' },
      { id: createId(), title: 'Standard', name: 'pro', price: '99' },
      { id: createId(), title: 'Pro', name: 'pro1', price: '299' },
      { id: createId(), title: 'Enterprise', name: 'enterprise', price: '0' },
    ],
    features: [
      {
        title: 'Channel',
        data: [
          {
            title: 'WhatsApp API',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'WhatsApp APP',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Instagram',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Facebook',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Google My Business',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Custom Channel',
            free: false,
            standard: false,
            pro: false,
            pro1: false,
            enterprise: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Platform Fee',
        data: [
          {
            title: 'WhatsApp API',
            unifiedDescription:
              'Free, we do not charge extra on top of Meta fees',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'AI: Chatbot & Tagging',
        data: [
          {
            title: 'AI Chatbot',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Product recommendation',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Order Tracking',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'FAQ',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'AI Customer Segmentation',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Sentiment Analysis',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'AI Auto-Summary',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'AI Co-Pilot',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Custom AI Label',
            free: 3,
            standard: 6,
            pro: 12,
            pro1: 20,
            enterprise: 'Unlimited',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Inbox & Contacts',
        data: [
          {
            title: 'Customer',
            free: 'Unlimited',
            standard: 'Unlimited',
            pro: 'Unlimited',
            pro1: 'Unlimited',
            enterprise: 'Unlimited',
            id: createId(),
          },
          {
            title: 'Supervisor Seat',
            free: 'Unlimited',
            standard: 'Unlimited',
            pro: 'Unlimited',
            pro1: 'Unlimited',
            enterprise: 'Unlimited',
            id: createId(),
          },
          {
            title: 'Agent Seat',
            free: 3,
            standard: 6,
            pro: 12,
            pro1: 20,
            enterprise: 'Unlimited',
            id: createId(),
          },
          {
            title: 'Green Tick Application',
            free: false,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Team Inbox',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Shortcuts',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Contact Import',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Contact Merging',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Chat History Migration',
            free: false,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Web View History',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'No Branding (Powered by bitbybit)',
            free: false,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Automation Rule',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Commerce',
        data: [
          {
            title: 'Product Catalog',
            free: 'Unlimited',
            standard: 'Unlimited',
            pro: 'Unlimited',
            pro1: 'Unlimited',
            enterprise: 'Unlimited',
            id: createId(),
          },
          {
            title: 'Order History',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Customer History',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Product Recommendation',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Integration',
        data: [
          {
            title: 'Shopify',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Shopify Flow',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Form',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'WhatsApp Catalog',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Google Sheet',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Stamped.io Reviews',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Yotpo Reviews',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Smile.io Reviews',
            free: true,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Xendit',
            free: false,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'Jubelio',
            free: false,
            standard: true,
            pro: true,
            pro1: true,
            enterprise: true,
            id: createId(),
          },
          {
            title: 'API Access',
            free: false,
            standard: false,
            pro: false,
            pro1: false,
            enterprise: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const dataStaticPlanComparionBitAI = () => {
  const tableData: IBBBTableComparisonData = {
    plan: [
      { id: createId(), title: 'Free', name: 'free', price: '0' },
      { id: createId(), title: 'Standard', name: 'pro', price: '39' },
      { id: createId(), title: 'Advanced', name: 'proAdvance', price: '199' },
      { id: createId(), title: 'Pro', name: 'pro1', price: '199' },
    ],
    features: [
      {
        title: 'Automation',
        data: [
          {
            title: 'Automation Updates',
            free: 'Weekly',
            pro: '1/day',
            proAdvance: '2/day',
            pro1: '4/day',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Collection',
        data: [
          {
            title: 'Managed Collections',
            free: 10,
            pro: 25,
            proAdvance: 100,
            pro1: 'Unlimited',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Model',
        data: [
          {
            title: 'Custom Model',
            free: 2,
            pro: 5,
            proAdvance: 25,
            pro1: 'Unlimited',
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Basic Sort',
        data: [
          {
            title: 'OOS, Top Selling, Inventory',
            free: true,
            pro: true,
            proAdvance: true,
            pro1: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Advance Sort',
        data: [
          {
            title: 'Margin, Profit',
            free: false,
            pro: false,
            proAdvance: true,
            pro1: true,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Integrations (Future)',
        data: [
          {
            title: 'Google Analytics',
            free: false,
            pro: false,
            proAdvance: true,
            pro1: false,
            id: createId(),
          },
        ],
        id: createId(),
      },
      {
        title: 'Review Integrations (Future)',
        data: [
          {
            title: 'Yotpo',
            free: false,
            pro: false,
            proAdvance: true,
            pro1: false,
            id: createId(),
          },
          {
            title: 'Stamped.io',
            free: false,
            pro: false,
            proAdvance: true,
            pro1: false,
            id: createId(),
          },
        ],
        id: createId(),
      },
    ],
  };
  return tableData;
};

export const pricingTier: PricingPlan[] = [
  'free',
  'freeWithUsageCharge',
  'standard',
  'pro',
  'proAdvance',
  'pro1',
  'enterprise',
];

export function isSameOrAbovePricingTier(
  plan: PricingPlan,
  target: PricingPlan
) {
  return pricingTier.indexOf(plan) >= pricingTier.indexOf(target);
}

// used specifically for bitlogin, bitai, and bitlink pricing
// since there is pricing changes schema on new pricing
export const standardPlanDeprecation = (plan: PricingPlan): PricingPlan => {
  if (plan === 'standard') return 'pro';

  return plan;
};
