import React, { SVGProps } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { capitalize } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import { FormYoutubeInput } from './FormYouTube';

import { BBBRadio } from '@/components/ui/BBBRadio';

type IlustrationProps = {
  className?: string;
} & SVGProps<SVGSVGElement>;

type Props = {
  control: Control<FormYoutubeInput>;
};

const DISPLAY_OPTIONS = [
  {
    name: 'button',
    component: <ButtonDisplay className="mx-auto" />,
  },
  {
    name: 'card',
    component: <CardDisplay className="mx-auto" />,
  },
] as const;

function Displays({ control }: Props) {
  const display = useWatch({
    control,
    name: 'display',
  });
  return (
    <div className="flex flex-col gap-2">
      <p className="text-primary-main">Display</p>
      <div className="flex gap-5">
        {DISPLAY_OPTIONS.map((display) => {
          return (
            <Controller
              control={control}
              key={display.name}
              name="display"
              render={({ field }) => (
                <div
                  className="flex flex-col gap-2 items-center justify-center"
                  key={display.name}
                  onClick={() => field.onChange(display.name)}
                >
                  <div
                    className={cx(
                      'w-40 py-3 mx-auto rounded-xl border cursor-pointer hover:border-primary-main transition-all',
                      field.value === display.name
                        ? 'border-primary-main'
                        : 'border-neutral-30'
                    )}
                  >
                    {display.component}
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <p className="text-center text-primary-main">
                      {capitalize(display.name)}
                    </p>
                  </div>
                </div>
              )}
            />
          );
        })}
        {display === 'card' && (
          <Controller
            control={control}
            name="options"
            render={({ field }) => (
              <Options
                value={
                  field.value === 'video-only'
                    ? 'video-only'
                    : 'with-background'
                }
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}

function Options({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="h-fit p-2.5 flex gap-5 items-center ">
      <BBBRadio
        options={[
          {
            label: 'Video only',
            description: 'Only show the video without background',
            value: 'video-only',
          },
          {
            label: 'With background',
            description: 'Use button background for your video',
            value: 'with-background',
          },
        ]}
        vertical
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function ButtonDisplay({ className, ...props }: IlustrationProps) {
  return (
    <svg
      width="66"
      height="98"
      viewBox="0 0 66 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="65"
        height="97"
        rx="7.5"
        fill="#F8F8F8"
        stroke="#DDDDDD"
      />
      <circle cx="33.0244" cy="18.7734" r="8" fill="#D4D4D4" />
      <rect
        x="18.9258"
        y="30.8379"
        width="28.6173"
        height="4.6178"
        rx="2"
        fill="#D4D4D4"
      />
      <rect x="7" y="44.6387" width="52" height="12" rx="6" fill="#FD823E" />
    </svg>
  );
}

function CardDisplay({ className, ...props }: IlustrationProps) {
  return (
    <svg
      width="66"
      height="98"
      viewBox="0 0 66 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="65"
        height="97"
        rx="7.5"
        fill="#F8F8F8"
        stroke="#DDDDDD"
      />
      <circle cx="33.0244" cy="18.7734" r="8" fill="#D4D4D4" />
      <rect
        x="18.9258"
        y="30.8379"
        width="28.6173"
        height="4.6178"
        rx="2"
        fill="#D4D4D4"
      />
      <rect x="7" y="44.6387" width="52" height="38" rx="4" fill="#FD823E" />
    </svg>
  );
}

export default Displays;
