import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';

import { GetCollectionParams } from '@/api/services/bitAi/v2-new/sorting';
import { GetProductsParams } from '@/api/services/bitCRM/v2/shopify';

const SHOPIFY_QUERY_KEY = 'bitcrm-shopify' as const;

export const useCollection = (
  collectionId: number,
  options?: {
    enabled?: boolean;
  }
) => {
  const companyId = useActiveCompany();

  return useQuery(
    [`${SHOPIFY_QUERY_KEY}-collection`, companyId, collectionId],
    () => services.bitCRM.v2.shopify.getCollection(companyId, collectionId),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useCollections = (params: GetCollectionParams) => {
  const companyId = useActiveCompany();

  return useInfiniteQuery(
    [`${SHOPIFY_QUERY_KEY}-collections`, companyId, { params }],
    ({ pageParam }) =>
      services.bitCRM.v2.shopify.getCollections(companyId!, {
        after: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.endCursor,
    }
  );
};

export const useProduct = (
  productId: number,
  {
    enabled,
  }: {
    enabled?: boolean;
  }
) => {
  const companyId = useActiveCompany();

  return useQuery(
    [`${SHOPIFY_QUERY_KEY}-product`, companyId, productId],
    () => services.bitCRM.v2.shopify.getProduct(companyId, productId),
    {
      enabled,
    }
  );
};

export const useProducts = (params: GetProductsParams) => {
  const companyId = useActiveCompany();

  return useInfiniteQuery(
    [`${SHOPIFY_QUERY_KEY}-products`, companyId, { params }],
    ({ pageParam }) =>
      services.bitCRM.v2.shopify.getProducts(companyId!, {
        after: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.endCursor,
    }
  );
};
