export default function GoogleRainbowIcon({
  color,
  size = 24,
}: {
  color?: string;
  size?: number | string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill={color || '#4285F4'}
        d="M24.19 12.776c0-.816-.066-1.636-.207-2.439H12.665v4.621h6.481a5.554 5.554 0 01-2.398 3.647v2.998h3.867c2.27-2.09 3.576-5.176 3.576-8.827z"
      ></path>
      <path
        fill={color || '#34A853'}
        d="M12.665 24.5c3.236 0 5.966-1.063 7.954-2.897l-3.867-2.998c-1.076.732-2.465 1.146-4.083 1.146-3.13 0-5.785-2.112-6.737-4.951h-3.99v3.09a12.001 12.001 0 0010.723 6.61z"
      ></path>
      <path
        fill={color || '#FBBC05'}
        d="M5.927 14.8a7.188 7.188 0 010-4.595V7.114H1.941a12.01 12.01 0 000 10.776l3.986-3.09z"
      ></path>
      <path
        fill={color || '#EA4335'}
        d="M12.665 5.249a6.52 6.52 0 014.603 1.799l3.426-3.426A11.533 11.533 0 0012.664.5 11.998 11.998 0 001.942 7.114l3.986 3.091c.948-2.844 3.607-4.956 6.738-4.956z"
      ></path>
    </svg>
  );
}
