import { useMemo } from 'react';
import { OnboardingModule } from 'types/systemLogin';
import useCompanyUsers from '../auth/useCompanyUsers';
import { useHasEnabledRating } from '../bitChat/chatbot/chatbot';
import useShopifyIntegrationByApp from '../shopify/useShopifyIntegrationByApp';
import { useConnectedToAnyChannels } from '../whatsApp/channels';
import { useHasNewChatbot } from '../whatsApp/flow';
import { useOldOnboarding } from './bitcrm';
import { useOnboardingMeta } from './onboarding';

export const useOnboarding = () => {
  const { data: onboardingData } = useOnboardingMeta('BITCHAT');

  const onboardingDataObj = onboardingData
    ? (Object.fromEntries(
        onboardingData.details.map((detail) => [detail.module, detail.skipped])
      ) as Record<OnboardingModule, boolean> | undefined)
    : undefined;

  const { data: connectedToAnyChannels } = useConnectedToAnyChannels(undefined);
  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITCHAT');
  const { data: companyUsers } = useCompanyUsers();
  const { data: hasNewChatbot } = useHasNewChatbot();
  const { data: hasEnabledRating } = useHasEnabledRating();
  const { data: oldOnboarding } = useOldOnboarding(
    'wa-widget-banner-embed-status'
  );

  const hasShopifyIntegrations = !!shopifyIntegration;

  const countTotalMember = companyUsers?.pages[0].meta.total;
  const hasOtherMembers = !!(countTotalMember && countTotalMember > 1);
  const hasEnabledWidget = !!oldOnboarding?.lastStep;

  const keyPoints = [
    {
      label: 'Unlock the power of unified messaging',
      value: 'unified_messaging',
      pageTitle: 'Connect your channels',
      completed: connectedToAnyChannels,
      skipped: onboardingDataObj?.unified_messaging,
    },
    {
      label: 'Boost sales with Shopify integration',
      value: 'shopify_integration',
      pageTitle: 'Connect to your Shopify store',
      completed: hasShopifyIntegrations,
      skipped: onboardingDataObj?.shopify_integration,
    },
    {
      label: 'Collaborate effectively with your team',
      value: 'team_collaboration',
      pageTitle: 'Invite your team members',
      completed: hasOtherMembers,
      skipped: onboardingDataObj?.team_collaboration,
    },
    {
      label: 'Automate message with Chatbot',
      value: 'chatbot',
      pageTitle: 'Set up chatbot and ask rating',
      completed: hasNewChatbot && hasEnabledRating,
      skipped:
        (onboardingDataObj?.chatbot || hasNewChatbot) &&
        (onboardingDataObj?.rating || hasEnabledRating),
      meta: {
        completedChatbot: hasNewChatbot,
        completedRating: hasEnabledRating,
        skippedChatbot: onboardingDataObj?.chatbot,
        skippedRating: onboardingDataObj?.rating,
      },
    },
    {
      label: 'Increase engagement with Chat Widget ',
      value: 'chat_widget',
      pageTitle: 'Set up chat widget',
      skipped: onboardingDataObj?.chat_widget,
      completed: hasEnabledWidget,
    },
  ];

  return { keyPoints, dismissed: onboardingData?.dismissed };
};

export const useOnboardingCompletion = () => {
  const { keyPoints, dismissed } = useOnboarding();

  const completionPercentage = useMemo(() => {
    const completedCount = keyPoints.filter(
      (point) => point.completed || point.skipped
    ).length;
    const totalCount = keyPoints.length;
    return totalCount > 0 ? (completedCount / totalCount) * 100 : 0;
  }, [keyPoints]);

  return { progress: completionPercentage, dismissed };
};
