import axios from 'axios';

import { pixelApiV1BaseUrl } from '@/constants/api';
import { intercepts } from '@/utils/api';

export default {
  v1: intercepts(
    axios.create({
      baseURL: pixelApiV1BaseUrl,
    })
  ),
};
