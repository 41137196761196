import { useMutation, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { useAppSelector } from 'hooks/rtk/store';
import { afterSubmit } from 'utils/bitApp';
import { toast } from 'utils/common/toast';

export const useAddImageGrid = (params?: { isDropEnd?: boolean }) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const selected = useAppSelector((state) => state.bitApp.selected);

  const client = useQueryClient();

  return useMutation(
    async (
      data: Parameters<typeof services.bitApp.imageGrid.addImageGrid>[1]
    ) => {
      return services.bitApp.imageGrid.addImageGrid(activeCompany!, {
        ...data,
      });
    },
    {
      onSuccess: () => {
        if (!params?.isDropEnd) {
          afterSubmit();
          toast.success('Changes saved.');
        }
        client.invalidateQueries(['sections', activeCompany]);
        client.invalidateQueries(['section', selected]);
      },
    }
  );
};
