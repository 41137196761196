import { useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Redirect } from 'react-router';
import { TimePickerValue } from 'react-time-picker';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import DynamicVariableLink from '../components/DynamicVariableLink';
import ReviewChecklist from '../components/ReviewChecklist';
import LivePreview from './components/LivePreview';

import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBDatePickerModal,
  BBBModal,
  BBBSelect,
  BBBSpinner,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';
import Link from '@/components/ui/Link/Link';
import { env } from '@/config/env';
import redirectionPathSchema from '@/constants/bitApp/redirectionPathSchema';
import { IRedirectNotificationOptions } from '@/constants/bitApp/redirectNotificationOptions';
import useBlastNotifications from '@/hooks/bitApp/campaign/useBlastNotifications';
import { useRecentPublish } from '@/hooks/bitApp/publish';
import useCustomForm from '@/hooks/common/useCustomForm';
import PageRedirectionFields from '@/pages/BitApp/components/PageRedirectionFields';
import { formatRedirectionPathObject } from '@/utils/bitApp';

const schema = yup.object().shape({
  message: yup.string().required('Message is required'),
  title: yup.string().required('Title is required'),
  campaignName: yup.string().required('Campaign Name is required'),
  isScheduled: yup.bool().required(),
  redirectPath: redirectionPathSchema,
  platform: yup.mixed().required('Platform is required'),
});

const platformOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Android',
    value: 'android',
  },
  {
    label: 'iOS',
    value: 'ios',
  },
];

export type NotificationFormSchema = {
  message: string;
  title: string;
  campaignName: string;
  isScheduled: boolean;
  platform?: {
    label: string;
    value: 'ios' | 'android';
  };
  redirectPath: IRedirectNotificationOptions | null;
};

export default function CampaignNotification() {
  const { data: publishData, isInitialLoading, isError } = useRecentPublish();

  const isEitherLive = !!publishData;

  if (isInitialLoading) {
    return <BBBSpinner />;
  }

  if (!isEitherLive || isError) {
    return (
      <Redirect
        to={{ pathname: '/bitapp/publish', search: 'from=notification' }}
      />
    );
  }

  return <_CampaignNotification />;
}

function _CampaignNotification() {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showSendNotif, setShowSendNotif] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null | undefined>(
    dayjs().add(1, 'days').toDate()
  );
  const [selectedTime, setSelectedTime] = useState<TimePickerValue>(
    dayjs().format('HH:mm')
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useCustomForm<NotificationFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      message: '',
      title: '',
      campaignName: '',
      isScheduled: false,
    },
  });

  const [showCalendar, setShowCalendar] = useState(false);

  const [tempFinal, setTempFinal] = useState<NotificationFormSchema | null>(
    null
  );

  const { mutate: createCampaign, isLoading: loadingSave } =
    useBlastNotifications();

  const postData = () => {
    const redirectPathOuter = tempFinal!.redirectPath;
    let redirectPath;

    if (redirectPathOuter) {
      redirectPath = formatRedirectionPathObject(redirectPathOuter);
    }

    createCampaign(
      {
        campaignName: tempFinal!.campaignName,
        title: tempFinal!.title,
        body: tempFinal!.message,
        redirectPath: redirectPath,
        platform: tempFinal!.platform!.value,
        test: env.REACT_APP_BLAST_NOTIFICATIONS_CAMPAIGN_TEST === '1',
      },
      {
        onSuccess: () => {
          setShowSendNotif(false);
          setShowReviewModal(false);
        },
      }
    );
  };

  const onSubmit = (data: NotificationFormSchema) => {
    setTempFinal(data);
    setShowReviewModal(true);
  };

  const handleReview = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      {showSendNotif && (
        <BBBModal
          title="Send Notification"
          centerTitle
          show={showSendNotif}
          centerBody
          onHide={() => setShowSendNotif(false)}
          loadingSave={loadingSave}
          handleSave={postData}
          footer
          size="md"
        >
          <div>Are you sure to send the notification now?</div>
        </BBBModal>
      )}

      {showReviewModal && tempFinal && (
        <ReviewChecklist
          showReviewModal={showReviewModal}
          setShowReviewModal={setShowReviewModal}
          tempFinal={tempFinal}
          onConfirm={() => setShowSendNotif(true)}
          type="notification"
        />
      )}

      {showCalendar && (
        <BBBDatePickerModal
          selectedDay={selectedDay}
          selectedTime={selectedTime}
          onChangeDate={(date) => setSelectedDay(date)}
          setSelectedTime={setSelectedTime}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
        />
      )}
      <div className="flex min-h-full">
        <div className="flex-[2_2_0%] relative">
          <div className="absolute inset-0">
            <BBBContainer
              hasHeading
              pageTitle="Push Notification"
              pageDescription="Reach your customer with push notification"
            >
              <BBBCard
                title="Notification settings"
                desc="Create your push notification. You can see the preview on phone view."
              >
                <div className="w-full flex flex-col gap-2">
                  <BBBTextInput
                    label="Campaign name"
                    placeholder="Enter campaign name"
                    isHookForm
                    control={control}
                    controlName="campaignName"
                    error={errors.campaignName?.message}
                  />
                  <BBBTextInput
                    label="Notification headline"
                    placeholder="Flash Sale! Up to 50% off - Today Only!"
                    isHookForm
                    control={control}
                    controlName="title"
                    error={errors.title?.message}
                    hasMaxCharLabel
                    maxChar={60}
                  />
                  <BBBTextAreaInput
                    label="Message"
                    placeholder="Shop our biggest sale of the season and save big on your favorite styles!"
                    isHookForm
                    control={control}
                    controlName="message"
                    className="mb-0"
                    error={errors.message?.message}
                    rows={4}
                    hasMaxCharLabel
                    maxChar={180}
                    maxLength={180}
                  />
                  <div className="flex justify-end">
                    <DynamicVariableLink />
                  </div>
                  <Controller
                    control={control}
                    name="redirectPath"
                    render={({ field }) => (
                      <PageRedirectionFields
                        label="Redirect your customer to:"
                        value={field.value}
                        onChange={(val) => field.onChange(val || null)}
                        errorValue={errors.redirectPath?.value?.message}
                        errorValueParam={(
                          errors.redirectPath?.params as any[]
                        )?.map((err) => err.data?.message)}
                        containerClassName="mb-3"
                        destinationPagePlaceholder="Choose destination page"
                      />
                    )}
                  />

                  <Controller
                    name="platform"
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <BBBSelect
                        options={platformOptions}
                        placeholder="Choose platform"
                        onValueChange={onChange}
                        value={value}
                        label="Platform"
                        containerClassName="mb-0"
                        error={(errors.platform as FieldError)?.message}
                        optionLabel="label"
                        optionValue="value"
                        isClearable
                      />
                    )}
                  />
                </div>
              </BBBCard>
              <div className="flex justify-end mt-4 gap-2">
                <Link to="/bitcrm/campaign">
                  <BBBButton variant="secondary" text="Cancel" />
                </Link>
                <BBBButton
                  variant="primary"
                  text="Review"
                  onClick={() => handleReview()}
                />
              </div>
            </BBBContainer>
          </div>
        </div>
        <div className="border border-l flex-[1_1_0%] px-4 flex items-center justify-center overflow-auto">
          <LivePreview
            data={{
              title: watch('title'),
              message: watch('message'),
            }}
          />
        </div>
      </div>
    </>
  );
}
