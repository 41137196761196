import { useQuery } from '@tanstack/react-query';
import api from 'config/api';
import { useAccessToken, useUser } from '../rtk/selector';
import useVerifyEmailRequest from './useVerifyEmailRequest';

import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';

const useVerifyEmailStatus = () => {
  const data = useUser();
  const { toggle } = useConfirmationBanner();
  const { mutate: requestVerify } = useVerifyEmailRequest();

  const accessToken = useAccessToken();

  return useQuery(
    ['verify-email-status'],
    async () => {
      const {
        data: { data },
      } = await api.systemLogin.get(`/auth/email-verify`);
      return data;
    },
    {
      onSuccess: () =>
        toggle('verification-status', {
          text: (
            <>
              Your email (<strong>{data?.email}</strong>) is not verified.
            </>
          ),
          variant: 'actionable',
          acceptLabel: 'Resend email',
          onAccept: () => requestVerify(),
          show: true,
        }),
      enabled: !!accessToken && !data.isVerified,
    }
  );
};

export default useVerifyEmailStatus;
