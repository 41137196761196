import { useInfiniteQuery } from '@tanstack/react-query';
import getArticles from 'graphql/query/getArticles';
import useGraphQLRequest from '../useGraphQLRequest';

import { ShopifyArticles } from '@/types/shopify/ShopifyBlogs';
import ShopifyConnection from '@/types/shopify/ShopifyConnection';

const useArticles = (query: Record<string, string | undefined>) => {
  const { request, enabled } = useGraphQLRequest();

  const requestQuery =
    Object.entries(query)
      .filter(([key, value]) => !!value?.length)
      ?.map(([key, val]) => `${key}:${val}`)
      ?.join(' AND ') || undefined;

  return useInfiniteQuery<ShopifyConnection<ShopifyArticles>>(
    ['bitapp-infinite-articles', query],
    async ({ pageParam }) => {
      const data = await request({
        query: getArticles,
        variables: {
          first: 10,
          after: pageParam,
          query: requestQuery,
        },
      });
      return data?.articles;
    },
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.pageInfo.hasNextPage) {
          return lastPage?.pageInfo.endCursor;
        }
        return null;
      },
    }
  );
};

export default useArticles;
