import { useMutation } from '@tanstack/react-query';
import api from 'config/api';
import { useAppDispatch } from '../rtk/store';
import { LoginResponseData, SocialLoginPayload } from './useLogin';

import { setAccessToken } from '@/stores/auth';
import { _localStorage } from '@/utils/common/localStorage';

export type RegisterPayload =
  | {
      lastName?: string | undefined;
      phone?: string | undefined;
      password: string;
      email: string;
      firstName: string;
    }
  | SocialLoginPayload;

const useRegister = () => {
  const dispatch = useAppDispatch();

  return useMutation<LoginResponseData, unknown, RegisterPayload>(
    async (payload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/auth/sign-up`, payload);
      return data;
    },
    {
      onSuccess: (data) => {
        _localStorage.setItem('accessToken', data.accessToken as string);
        dispatch(setAccessToken(data.accessToken as string));
      },
    }
  );
};

export default useRegister;
