import { useMemo, useState } from 'react';
import { version } from '../../../../../package.json';
import DeleteCompanyModal from './DeleteCompanyModal';
import DeleteUserModal from './DeleteUserModal';

import { BBBButton, BBBCard } from '@/components/ui';
import useAuthenticatedUserCompanies from '@/hooks/auth/useAuthenticatedUserCompanies';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useResponsive from '@/hooks/common/useResponsive';
import { useCompany, useUser } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';

export default function SettingsDangerZone({
  type,
}: {
  type: 'user' | 'company';
}) {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return (
    <>
      <BBBCard
        className="mb-cardBottom"
        title={<h5 className="text-danger-main ">Danger Zone</h5>}
      >
        {type === 'company' && <SettingsDangerZoneCompany />}
        {type === 'user' && <SettingsDangerZoneUser />}
      </BBBCard>
      {type === 'company' && (
        <div className="flex flex-col text-neutral-40 text-sm">
          <p>Version: {version}</p>
          <p>ID: {activeCompany}</p>
        </div>
      )}
    </>
  );
}

function SettingsDangerZoneUser() {
  const isMobile = useResponsive('sm');
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const userData = useUser();

  const { data: companyData } = useAuthenticatedUserCompanies({
    role: ['SUPER_ADMIN'],
  });

  const data = useMemo(
    () => companyData?.pages[0].meta.total,
    [companyData?.pages]
  );

  return (
    <>
      {showDeleteUser && (
        <DeleteUserModal
          show={showDeleteUser}
          onChangeShow={setShowDeleteUser}
        />
      )}
      <div className="h5 font-semibold mb-2">Account</div>
      <div className="lg:mb-6 mb-2 font-light">
        This button will permanently delete your account (
        <span className="font-bold">{userData?.profile?.firstName}</span>). Once
        you confirm to delete your account, the action cannot be undone.
      </div>
      <BBBButton
        onClick={() => setShowDeleteUser(true)}
        text="Delete account"
        disabled={(data ?? 0) > 0}
        variant="danger-outline"
        size={isMobile ? 'sm' : 'md'}
      />
      {(data ?? 0) > 0 && (
        <div className="text-danger-main mt-2 text-sm">
          There are {data} companies that ownership belongs to you. Please
          transfer the ownership first before proceeding to account deletion.
        </div>
      )}
    </>
  );
}

function SettingsDangerZoneCompany() {
  const [showDeleteCompany, setShowDeleteCompany] = useState(false);

  const authenticatedCompanyData = useCompany();

  const { data: isRestricted } = useIsModuleRestricted(
    'SETTINGS_DELETE_COMPANY'
  );

  return (
    <>
      {showDeleteCompany && (
        <DeleteCompanyModal
          show={showDeleteCompany}
          onChangeShow={setShowDeleteCompany}
        />
      )}
      <div className="md:w-full">
        <div className="h5 font-semibold mb-2">Company</div>
        <div className="lg:mb-6 mb-2 font-light">
          This button will delete this company (
          <span className="font-bold">{authenticatedCompanyData?.name}</span>)
          from your account. Once you confirm to delete your company, the action
          cannot be undone.
        </div>
        <BBBButton
          text="Delete company"
          variant="danger-outline"
          onClick={() => setShowDeleteCompany(true)}
          disabled={isRestricted}
        />
      </div>
    </>
  );
}
