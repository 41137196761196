import useResponsive from 'hooks/common/useResponsive';
import { useUser } from 'hooks/rtk/selector';
import HelpCenter from './components/HelpCenter';
import OverviewPerformance from './components/Overview';
import Suggestion from './components/Suggestion';

import { BBBContainer } from '@/components/ui';

export default function Home() {
  const data = useUser();

  const isMobile = useResponsive('sm');

  return (
    <BBBContainer className="px-2 py-4 md:py-[4.25rem] md:px-4 w-full">
      {!isMobile && (
        <p className="mb-8 text-[2rem]">
          👋 Welcome to bitbybit, {data.profile?.firstName || ''}!
        </p>
      )}
      <OverviewPerformance />
      <Suggestion />
      <HelpCenter />
    </BBBContainer>
  );
}
