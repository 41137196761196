import { HelpCircle } from 'react-feather';
import { _PricingInfo } from './PricingInfo';

import { PricingFeatureNames } from '@/api/services/pricing';
import { BBBPercentageBar, BBBTooltip } from '@/components/ui';
import { mapLimitAutomaticUpdates } from '@/constants/bitAI/pricing-limit';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { usePricingFeatures } from '@/hooks/pricing/v2/pricing';

const mapFeatureToTip: Partial<Record<PricingFeatureNames, string>> = {
  advance_custom_model:
    'Advance custom model is a powerful feature that allows you to create your own model for your AI.',
  managed_collections:
    'Managed collections is a feature that allows you to manage your collections.',
};

function BitAiStatus() {
  const { data: pricingData } = usePricingByApp('BITAI');
  const { data: pricingFeatures } = usePricingFeatures({ app: 'BITAI' });

  if (!pricingData || !pricingFeatures) return null;

  return (
    <_PricingInfo
      app="BITAI"
      subscriptionPlan={pricingData.pricingModule.label || ''}
    >
      <div className="flex items-center gap-2 mb-4">
        <div className="flex items-center grow gap-1">
          <div className="text-neutral-50 text-xs">Automatic Updates </div>
          <BBBTooltip
            content={`Automatic updates ${
              mapLimitAutomaticUpdates[pricingData.pricingName]
            }`}
            show
            position="right"
          >
            <HelpCircle size="0.625rem" className="text-neutral-50" />
          </BBBTooltip>
        </div>
        <div className="text-neutral-60 text-xs font-semibold">
          {mapLimitAutomaticUpdates[pricingData.pricingName]}
        </div>
      </div>
      {pricingFeatures
        .filter((feature) => feature.featureName !== 'automatic_updates')
        .map((feature) => (
          <BBBPercentageBar
            usage={feature.usage}
            limit={
              pricingData.pricingName === 'pro1'
                ? Infinity
                : feature.pricingFeature.pricingFeatureDetails.find(
                    (detail) => detail.pricingName === pricingData.pricingName
                  )?.limit ?? 0
            }
            description={feature.pricingFeature.label}
            className="mb-4"
            key={feature.featureName}
            hasTip={!!mapFeatureToTip[feature.featureName]}
            tip={mapFeatureToTip[feature.featureName]}
            mode={feature.pricingFeature.mode}
          />
        ))}
    </_PricingInfo>
  );
}

export default BitAiStatus;
