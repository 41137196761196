import { useMutation, useQueryClient } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { DeleteTagPayload } from '@/api/services/customer/customer';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useDeleteTag = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    ({ tagName, customerId }: Omit<DeleteTagPayload, 'companyId'>) =>
      customerServices.customer.deleteTag({
        tagName,
        customerId,
        companyId: activeCompany!,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers']);
        queryClient.invalidateQueries(['infinite-customers']);
        queryClient.invalidateQueries(['customer-phonenumber']);
        queryClient.invalidateQueries(['customer']);
        toast.success('Tag deleted successfully');
      },
    }
  );
};

export default useDeleteTag;
