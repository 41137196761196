import { ApiResponse } from '../types';

import api from '@/config/api';

export default {
  getCountriesAndFlagUrl: () =>
    api.countries
      .get<ApiResponse<Country[]>>(`/countries/flag/images`)
      .then((res) => res.data.data),
  getStateByCountryName: (payload: CommonCountryQueryParams) =>
    api.countries
      .post<
        ApiResponse<{
          name: string;
          iso3: string;
          states: State[];
        }>
      >(`/countries/states`, {
        ...payload,
      })
      .then((res) => res.data.data.states),
  getCityByState: (payload: CommonCountryQueryParams) =>
    api.countries
      .post<ApiResponse<string[]>>(`/countries/state/cities`, payload)
      .then((res) => res.data.data.map((d) => ({ label: d, value: d }))),
};

export type CommonCountryQueryParams = { country: string };
export type StateCitiesParams = CommonCountryQueryParams & {
  state: string;
};

export type Country = {
  name: string;
  flag: string;
  iso2: string;
  iso3: string;
};

export type State = {
  name: string;
  state_code: string;
};
