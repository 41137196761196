import { forwardRef, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { snapPoints } from 'react-spring-bottom-sheet/dist/types';
import 'react-spring-bottom-sheet/dist/style.css';

import { BBBSpinner, IBBBCard } from '@/components/ui';

export type BBBBottomSheetProps = IBBBCard & {
  show?: boolean;
  withoutBackdrop?: boolean;
  onClose?: () => void;
  isPaginated?: boolean;
  fetchNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  snapPoints?: snapPoints;
};

const BBBBottomSheet = forwardRef<HTMLDivElement, BBBBottomSheetProps>(
  (props, cardRef) => {
    const {
      show,
      children,
      withoutBackdrop,
      className,
      onClose,
      isPaginated,
      hasMore,
      fetchNext,
      dataLength,
      snapPoints,
      ...otherProps
    } = props;

    const sheetRef = useRef<BottomSheetRef | null>(null);

    const [_show, setShow] = useState(show);

    useEffect(() => {
      setShow(show);
    }, [show]);

    const scrollableBottomSheetRef =
      document.querySelector('[data-rsbs-scroll]');

    return (
      <BottomSheet
        open={!!_show}
        onDismiss={() => {
          setShow(false);
          onClose?.();
        }}
        ref={sheetRef}
        className="bg-white z-[500]"
        snapPoints={
          typeof snapPoints !== 'undefined'
            ? snapPoints
            : ({ maxHeight, minHeight }) => [
                minHeight,
                maxHeight * 0.5,
                maxHeight * 0.75,
              ]
        }
      >
        <div className="text-center mb-5 font-bold">{otherProps.title}</div>
        {isPaginated && scrollableBottomSheetRef ? (
          <InfiniteScroll
            dataLength={dataLength || 0}
            next={fetchNext!}
            hasMore={hasMore!}
            loader={<BBBSpinner width={3} height={16} />}
            scrollableTarget={scrollableBottomSheetRef}
            scrollThreshold={0}
          >
            <div className="p-4">{children}</div>
          </InfiniteScroll>
        ) : (
          <div className="p-4">{children}</div>
        )}
      </BottomSheet>
    );
  }
);

BBBBottomSheet.displayName = 'BBBBottomSheet';

export default BBBBottomSheet;
