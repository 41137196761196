import { useCompanyIntegrations } from './integration/integrations';

import { formatDomainStoreToNameStore } from '@/utils/bitAi';

const useGetStoreName = () => {
  const { data: companyIntegrationsData } = useCompanyIntegrations();

  const domainStore = companyIntegrationsData?.find(
    (integration) => integration.integration.name === 'shopify'
  )?.extra?.domain;

  return formatDomainStoreToNameStore(domainStore);
};

export default useGetStoreName;
