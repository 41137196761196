import { UserProfilePicture1 } from '@/components/Auth/UserProfilePicture';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
  staticAgents,
} from '@/constants/bitChat/agent';

type Props = {
  userId: number;
  name: string;
  accent?: string;
  profilePicture?: string;
};

export default function UserDetail(props: Props) {
  if (staticAgents.includes(props.userId)) {
    if (props.userId === EVERYONE_AGENT_ID) return null;

    return (
      <div
        className="text-sm text-neutral-60 grow"
        id={`ticket-user-${props.userId}`}
      >
        {generalAiAgentOption.formattedName}
      </div>
    );
  }

  return <_UserDetail {...props} />;
}

function _UserDetail({ userId, name, accent, profilePicture }: Props) {
  return (
    <>
      <UserProfilePicture1
        size={16}
        accent={accent}
        profilePicture={profilePicture}
        displayName={name
          ?.split(' ')
          .slice(0, 2)
          .map((name) => name.slice(0, 1))
          .join('')}
      />
      <div
        className="text-sm text-neutral-60 grow"
        id={`ticket-user-${userId}`}
      >
        {name}
      </div>
    </>
  );
}
