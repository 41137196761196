import React, { useMemo } from 'react';
import { env } from 'config/env';
import useCustomerByPhoneNumber from 'hooks/customers/customer/useCustomerByPhoneNumber';
import useWebActivity from 'hooks/pixels/useWebActivity';
import useIsGroupChat from 'hooks/whatsApp/useIsGroupChat';
import { Cart } from 'types/pixel/event';
import { formatCurrency } from 'utils/common/currency';
import useWebActivityStream from '../../hooks/useWebActivityStream';

import { BBBCard, BBBTooltip } from '@/components/ui';

export default function CustomerCart() {
  const isGroup = useIsGroupChat();

  if (isGroup)
    return (
      <>
        <Header />
        <Info
          message={
            <p className="text-center">
              Customer cart are not <br />
              available for this channel
            </p>
          }
        />
      </>
    );

  return <_CustomerCart />;
}

function _CustomerCart() {
  const { data: customerData } = useCustomerByPhoneNumber();

  const { data: activities } = useWebActivity({
    ...(customerData?.email && { email: customerData?.email }),
    ...(customerData?.phoneNumber && {
      phone: customerData?.phoneNumber,
    }),
  });

  const { data: updateActivities } = useWebActivityStream({
    email: customerData?.email,
    phoneNumber: customerData?.phoneNumber,
  });

  const carts = updateActivities?.length
    ? updateActivities[updateActivities.length - 1]?.cart
    : activities?.cart_data;

  const isCartEmpty =
    activities?.cart_data?.lines === undefined ||
    activities?.cart_data?.lines?.length === 0;

  return (
    <>
      <Header />
      {isCartEmpty ? (
        <Info message="No cart activity" />
      ) : (
        <Products products={carts} />
      )}
    </>
  );
}

function Header() {
  return <BBBCard title="Customer's Cart" />;
}

function Products({ products }: { products: Cart | undefined }) {
  const cartData = useMemo(
    () =>
      products?.lines?.length ? products?.lines : products?.lines?.slice(0, 10),
    [products?.lines]
  );

  const calculateTotal = useMemo(() => {
    if (cartData?.length) {
      return cartData.reduce((acc, curr) => {
        return acc + curr.merchandise.price.amount * curr.quantity;
      }, 0);
    }

    return 0;
  }, [cartData]);

  const findCurrency = useMemo(() => {
    if (cartData?.length) {
      return cartData[0].merchandise.price.currencyCode;
    }

    return '';
  }, [cartData]);

  return (
    <BBBCard>
      <div className="flex flex-col gap-4">
        {cartData?.map((product) => (
          <Product
            key={product.merchandise?.id}
            quantity={product.quantity}
            {...product.merchandise}
          />
        ))}
        <div className="py-2.5 border-t border-neutral-30 flex items-center justify-end w-full">
          <div className="flex gap-2 items-center justify-center">
            <p className="text-sm text-primary-main">Total:</p>
            <p className="text-xl font-bold text-primary-main">
              {formatCurrency(calculateTotal, findCurrency)}
            </p>
          </div>
        </div>
      </div>
    </BBBCard>
  );
}

function Product({
  id,
  image,
  price,
  quantity,
  ...props
}: Cart['lines'][number]['merchandise'] & {
  quantity: number | undefined;
}) {
  const { product } = props;
  return (
    <div className="flex gap-2.5 items-center">
      <div className="flex-none rounded border border-neutral-30 p-1 w-[3.625rem] h-[3.625rem]">
        <img
          className="object-cover h-full w-full"
          src={
            image.src ||
            `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
          }
          alt={`product-image-${id}`}
        />
      </div>
      <div className="h-full flex flex-1 flex-col grow justify-between">
        <BBBTooltip content={product.title} show>
          {product.url ? (
            <div
              className="text-primary-main line-clamp-1 underline transition-all cursor-pointer"
              onClick={() => window.open(`${product.url}`, '_blank')}
            >
              {product.title}
            </div>
          ) : (
            <div className="text-primary-main line-clamp-1">
              {product.title}
            </div>
          )}
          <div className="text-primary-main text-sm font-bold">
            {quantity}x - {formatCurrency(price.amount, price.currencyCode)}
          </div>
        </BBBTooltip>
      </div>
    </div>
  );
}

function Info({ message }: { message: string | React.ReactNode }) {
  return (
    <BBBCard>
      <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="25" fill="#DDDDDD" />
          <path
            d="M18.5332 21.0449H32.478C32.7605 21.0449 33.04 21.1041 33.2982 21.2188C33.5565 21.3334 33.7879 21.5008 33.9775 21.7103C34.1671 21.9198 34.3107 22.1667 34.399 22.4351C34.4873 22.7035 34.5184 22.9874 34.4903 23.2686L33.8836 29.3359C33.8337 29.835 33.6001 30.2977 33.2283 30.6342C32.8564 30.9708 32.3728 31.1571 31.8712 31.1572H22.2141C21.7463 31.1573 21.293 30.9954 20.9313 30.6989C20.5695 30.4025 20.3217 29.9898 20.23 29.5311L18.5332 21.0449Z"
            stroke="#757575"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M18.5337 21.0449L17.7146 17.7655C17.6598 17.5468 17.5335 17.3527 17.3558 17.214C17.1781 17.0753 16.9591 17 16.7337 17H15.5M21.5673 35.202H23.5898M29.6571 35.202H31.6796"
            stroke="#757575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="text-neutral-50">{message}</p>
      </div>
    </BBBCard>
  );
}
