import { useHistory } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { SaveGoogleSheetsParams } from 'api/services/whatsApp/form';
import { setConnectIntegration } from 'stores/common';
import { FormTemplateStatus, WaFormCreation } from 'types/whatsApp/form';
import { toast } from 'utils/common/toast';
import { useActiveCompany } from '../rtk/selector';
import { useAppDispatch } from '../rtk/store';

export const useForms = (params?: {
  search?: string;
  status?: FormTemplateStatus;
}) => {
  const activeCompany = useActiveCompany();

  return useQuery(['whatsapp-forms', activeCompany, params], () =>
    services.whatsApp.form.getForms(activeCompany, params)
  );
};

export const useFormElements = (
  id: string,
  options?: {
    enabled?: boolean;
  }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['whatsapp-form', id, activeCompany],
    () => services.whatsApp.form.getFormById(activeCompany, id),
    {
      enabled: id !== 'new' || options?.enabled,
    }
  );
};

export const useFormResponses = (
  id: string,
  params?: { search?: string; after?: string; before?: string; limit?: number }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['whatsapp-form-responses', id, activeCompany, params],
    () => services.whatsApp.form.getFormResponseById(activeCompany, id, params),
    { keepPreviousData: true }
  );
};

export const useCreateForm = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  const history = useHistory();

  return useMutation(
    (payload: Omit<WaFormCreation, 'companyId'>) =>
      services.whatsApp.form.createForm(companyId, { ...payload, companyId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['whatsapp-forms']);
        queryClient.invalidateQueries(['whatsapp-form']);

        history.push(`/bitchat/whatsapp-form`);
      },
    }
  );
};

export const useTestForm = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { id: string; number: string }) =>
      services.whatsApp.form.testForm(companyId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['whatsapp-forms']);
        queryClient.invalidateQueries(['whatsapp-form']);
      },
    }
  );
};

export const usePublishForm = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) =>
      services.whatsApp.form.publishForm(companyId, { id: payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['whatsapp-forms']);
        queryClient.invalidateQueries(['whatsapp-form']);
      },
    }
  );
};

export const useSaveGoogleSheets = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    ({ formId, ...payload }: SaveGoogleSheetsParams & { formId: string }) =>
      services.whatsApp.form.saveGoogleSheets(activeCompany, formId, payload),
    {
      onSuccess: () => {
        toast.success(`Sucesfully saved to google sheets`);
        queryClient.invalidateQueries(['whatsapp-forms']);
        dispatch(setConnectIntegration(null));
      },
    }
  );
};

export const useDeleteForm = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) => services.whatsApp.form.deleteForm(companyId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['whatsapp-forms']);
        queryClient.invalidateQueries(['whatsapp-form']);
      },
    }
  );
};

export const useDuplicateForm = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) =>
      services.whatsApp.form.duplicateForm(companyId, payload),
    {
      onSuccess: () => {
        toast.success(`Form successfully duplicated`);
        queryClient.invalidateQueries(['whatsapp-forms']);
        queryClient.invalidateQueries(['whatsapp-form']);
      },
    }
  );
};
