import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import getArticle from 'graphql/query/getArticle';
import useGraphQLRequest from '../useGraphQLRequest';

import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { ShopifyArticles } from '@/types/shopify/ShopifyBlogs';
import { generateShopifyId } from '@/utils/bitApp';

const useArticle = (
  id: string | null | undefined,
  options?: Omit<
    UseQueryOptions<ShopifyArticles, unknown, ShopifyArticles, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const { request, enabled } = useGraphQLRequest();

  return useQuery<ShopifyArticles>(
    ['bitapp-shopify-article', id],
    async () => {
      const data = await request({
        query: getArticle,
        variables: {
          id: generateShopifyId('Article', id),
        },
      });
      return data?.article;
    },
    {
      enabled: !!enabled && !!id,
      ...options,
    }
  );
};

export default useArticle;
