import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import {
  RequestOTPPayload,
  VerifyOTPPayload,
  WhatsappLogin,
  WhatsappLoginCallbackParams,
} from '@/types/bitLogin/v2';

export default {
  getQRCode: (
    domain: string | null | undefined,
    code: string | null | undefined
  ) =>
    api.bitLogin
      .get<{ qrCode: string }>(`/login/whatsapp/barcode/${domain}?code=${code}`)
      .then((res) => res.data),
  addRegistrationLogin: ({
    code,
    shop,
    ...payload
  }: WhatsappLoginCallbackParams) =>
    api.bitLogin
      .post<{ data: string }>('/login/whatsapp/callback', payload, {
        params: { code, shop },
      })
      .then((res) => res.data.data),
  getWhatsappLoginStatus: (code: string, domain: string) =>
    api.bitLogin
      .get<ApiResponse<WhatsappLogin>>(
        `/login/whatsapp/code/${code}/domain/${domain}`
      )
      .then((res) => res.data.data),
  updateWhatsappLoginStatus: (
    code: string,
    domain: string,
    body: { status: number }
  ) =>
    api.bitLogin
      .post<ApiResponse<WhatsappLogin>>(
        `/login/whatsapp/code/${code}/domain/${domain}`,
        body
      )
      .then((res) => res.data.data),
  sendOTP: ({ phoneNumber, domain }: RequestOTPPayload) =>
    api.bitLogin
      .post(`/login/whatsapp_meta`, {
        phoneNumber,
        domain,
      })
      .then((res) => res.data),
  verifyOTP: ({ phoneNumber, domain, code }: VerifyOTPPayload) =>
    api.bitLogin
      .post(`/login/whatsapp_meta/verify`, {
        phoneNumber,
        domain,
        code,
      })
      .then((res) => res.data),
};
