import { ComponentProps } from 'react';
import { Trash2 } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

export default function TrashWithTransition({
  className,
  ...props
}: ComponentProps<typeof Trash2>) {
  return (
    <Trash2
      className={cx(
        'text-neutral-30 hover:text-danger-hover cursor-pointer',
        className
      )}
      {...props}
    />
  );
}
