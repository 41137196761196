import { LoginChannel, UserGender } from '@/types/bitLogin/analytics';
import { SocialLoginProvider } from '@/types/bitLogin/v2';
import { Customer } from '@/types/customers';

export const previewAnalytics = {
  loginSession: {
    count: 400,
    percentage: 15.9,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
  newUser: {
    count: 50,
    percentage: null,
    description: 'DOWN',
    detail: {
      before: 0,
      after: 0,
    },
  },
  averageLoginSession: {
    count: 35,
    percentage: 15.9,
    description: 'UP',
    detail: {
      before: 0,
      after: 0,
    },
  },
};

export const previewPeakHours = [
  {
    hour: '9 AM',
    count: 15,
  },
  {
    hour: '10 AM',
    count: 30,
  },

  {
    hour: '11 AM',
    count: 68,
  },
  {
    hour: '12 AM',
    count: 90,
  },
  {
    hour: '1 PM',
    count: 60,
  },
  {
    hour: '2 PM',
    count: 40,
  },
  {
    hour: '3 PM',
    count: 55,
  },
];

export const previewLoginChannel: LoginChannel[] = [
  {
    name: 'apple',
    value: 78,
    percentage: '1.47',
  },
  {
    name: 'facebook',
    value: 139,
    percentage: '2.62',
  },
  {
    name: 'google',
    value: 4560,
    percentage: '85.81',
  },
  {
    name: 'google one_tap',
    value: 500,
    percentage: '9.41',
  },
  {
    name: 'whatsapp',
    value: 36,
    percentage: '0.68',
  },
  {
    name: 'amazon',
    value: 0,
    percentage: '0.00',
  },
  {
    name: 'twitter',
    value: 0,
    percentage: '0.00',
  },
  {
    name: 'microsoft',
    value: 1,
    percentage: '0.02',
  },
];

export const previewGender: UserGender[] = [
  {
    name: 'Male',
    value: 46,
    percentage: '46',
  },
  {
    name: 'Female',
    value: 40,
    percentage: '40',
  },
  {
    name: 'Non-binary',
    value: 10,
    percentage: '10',
  },
  {
    name: 'Others',
    value: 4,
    percentage: '4',
  },
];

export const previewTopLocations = {
  locations: [
    {
      iso: 'ID',
      country: 'indonesia',
      percentage: 60,
    },
    {
      iso: 'MY',
      country: 'malaysia',
      percentage: 16,
    },
    {
      iso: 'SG',
      country: 'singapore',
      percentage: 14,
    },
    {
      iso: 'TH',
      country: 'thailand',
      percentage: 7,
    },
    {
      iso: 'VN',
      country: 'vietnam',
      percentage: 1,
    },
  ],
  others: 1,
  unknown: 1,
};

export const previewVisitorsInformation: {
  visitors: Partial<
    Customer & {
      birthday: string | null;
      provider: SocialLoginProvider;
    }
  >[];
  meta: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
    total: number;
  };
  range: {
    start: string;
    end: string;
  };
} = {
  visitors: [
    {
      id: '123',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane_doe@mail,com',
      phoneNumber: '+01-123-4567-890',
      birthday: '1969-12-24',
      provider: 'FACEBOOK',
      updatedAt: new Date('2021-12-24').toUTCString(),
    },
    {
      id: '124',
      firstName: 'John',
      lastName: 'Doe',
      email: 'doe.john@mail.com',
      phoneNumber: null,
      birthday: null,
      provider: 'GOOGLE',
      updatedAt: new Date('2021-12-24').toUTCString(),
    },
    {
      id: '125',
      firstName: 'Janice',
      lastName: 'Doe',
      email: 'janice_doe@mail.com',
      phoneNumber: '+01-123-4567-890',
      birthday: null,
      provider: 'WHATSAPP',
      updatedAt: new Date('2021-12-24').toUTCString(),
    },
    {
      id: '120',
      firstName: 'Jack',
      lastName: 'Doe',
      email: 'jackdoe@mail.com',
      phoneNumber: '+01-123-4567-890',
      birthday: null,
      provider: 'APPLE',
      updatedAt: new Date('2021-12-24').toUTCString(),
    },
    {
      id: '129',
      firstName: 'Jeanie',
      lastName: 'Doe',
      email: 'jdoe@mail.com',
      phoneNumber: null,
      birthday: null,
      provider: 'MICROSOFT',
      updatedAt: new Date('2021-12-24').toUTCString(),
    },
  ],
  meta: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
    total: 5,
  },
  range: {
    start: '2023-03-01',
    end: '2023-03-07',
  },
};
