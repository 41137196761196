import { gql } from 'graphql-request';

export default gql`
  query getBlogs($first: Int, $query: String, $after: String) {
    blogs(first: $first, query: $query, after: $after) {
      edges {
        node {
          id
          title
          handle
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;
