import api from '@/config/api';
import { Events } from '@/types/pixel/event';

export default {
  getWebActivity: (
    companyId: number,
    params?: {
      email?: string | null;
      phone?: string | null;
      size?: number | null;
      last_id?: string | null;
    }
  ) =>
    api.pixel.v1
      .get<Events>(`/${companyId}/events`, {
        params,
      })
      .then((res) => res.data),
  streamWebActivity: (
    companyId: number,
    params?: {
      email?: string | null;
      phone?: string | null;
      size?: number | null;
      last_id?: string | null;
    }
  ) =>
    api.pixel.v1.get(`/${companyId}/events/stream`, {
      params,
      responseType: 'stream',
    }),
};
