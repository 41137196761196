import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useSortingCollection = (id: string) => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitai-sorting-collection', activeCompany], () =>
    services.bitAi.v2New.sorting.getCollection(activeCompany, id)
  );
};

export default useSortingCollection;
