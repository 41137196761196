import { pixelApiV1BaseUrl } from '@/constants/api';

const eventPixel = {
  streamWebActivity: (
    companyId: number,
    params?: {
      email?: string | null;
      phone?: string | null;
    }
  ) => {
    const queryParams = new URLSearchParams();

    if (params?.email) {
      queryParams.set('email', params.email);
    }

    if (params?.phone) {
      queryParams.set('phone', params.phone);
    }

    const url = `${pixelApiV1BaseUrl}/${companyId}/events/stream?${queryParams.toString()}`;
    return new EventSource(url);
  },
};

export default eventPixel;
