import { useMutation, useQueryClient } from '@tanstack/react-query';
import customerServices from 'api/services/customer';
import { useActiveCompany } from 'hooks/rtk/selector';
import { toast } from 'utils/common/toast';

const CUSTOMER_QUERY_KEY = 'customers' as const;

export const useBulkCustomerTag = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    async ({
      tagName,
      customerIds,
      isAllRowSelected,
    }: {
      tagName: string[];
      customerIds: string[];
      isAllRowSelected: boolean;
    }) =>
      customerServices.customer.bulkAddCustomerTag({
        tagName: tagName,
        customerIds: customerIds,
        companyId: activeCompany!,
        isAllSelected: isAllRowSelected,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(`Successfully update tag customers`);
        client.invalidateQueries([CUSTOMER_QUERY_KEY]);
      },
    }
  );
};
