type Props = {
  isStonks?: boolean;
} & React.HTMLAttributes<SVGElement>;

function ArrowGraphicIcon({ isStonks, ...props }: Props) {
  if (isStonks) {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M1.54038 7.54038C1.28654 7.79422 1.28654 8.20578 1.54038 8.45962C1.79422 8.71346 2.20578 8.71346 2.45962 8.45962L1.54038 7.54038ZM8.65 2C8.65 1.64101 8.35899 1.35 8 1.35L2.15 1.35C1.79102 1.35 1.5 1.64101 1.5 2C1.5 2.35898 1.79102 2.65 2.15 2.65L7.35 2.65L7.35 7.85C7.35 8.20898 7.64102 8.5 8 8.5C8.35899 8.5 8.65 8.20898 8.65 7.85L8.65 2ZM2.45962 8.45962L8.45962 2.45962L7.54038 1.54038L1.54038 7.54038L2.45962 8.45962Z"
          fill="#79D64F"
        />
      </svg>
    );
  }
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.45962 1.54038C2.20578 1.28654 1.79422 1.28654 1.54038 1.54038C1.28654 1.79422 1.28654 2.20578 1.54038 2.45962L2.45962 1.54038ZM8 8.65C8.35899 8.65 8.65 8.35899 8.65 8V2.15C8.65 1.79102 8.35899 1.5 8 1.5C7.64102 1.5 7.35 1.79102 7.35 2.15V7.35H2.15C1.79102 7.35 1.5 7.64102 1.5 8C1.5 8.35899 1.79102 8.65 2.15 8.65H8ZM1.54038 2.45962L7.54038 8.45962L8.45962 7.54038L2.45962 1.54038L1.54038 2.45962Z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

export default ArrowGraphicIcon;
