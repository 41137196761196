import { SVGProps } from 'react';

export default function EyeClosed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
      {...props}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M.838 6.28a.625.625 0 01.881.057l.002.002.012.014.063.064c.058.059.148.146.27.253a7.27 7.27 0 001.104.792 8.273 8.273 0 002.466.966.627.627 0 01.124.026c.53.107 1.11.171 1.74.171a8.797 8.797 0 001.829-.19 8.274 8.274 0 002.5-.973c.49-.29.86-.579 1.105-.792a5.196 5.196 0 00.333-.317l.012-.014.001-.001.001-.001a.625.625 0 01.94.825l-.471-.412.47.412-.002.002-.002.003-.008.009-.026.028a6.392 6.392 0 01-.427.408c-.097.085-.21.179-.338.28l.81.954a.625.625 0 11-.954.808l-.9-1.061a9.51 9.51 0 01-2.151.908l.371 1.114a.625.625 0 01-1.186.395l-.414-1.244c-.468.07-.966.109-1.493.109-.531 0-1.032-.04-1.503-.11l-.402 1.24a.625.625 0 11-1.19-.385l.364-1.121a9.51 9.51 0 01-2.141-.906l-.902 1.062a.625.625 0 11-.952-.81l.81-.954a8.023 8.023 0 01-.34-.28 6.408 6.408 0 01-.452-.435l-.008-.01-.002-.002-.002-.001v-.001l.47-.412-.47.412a.625.625 0 01.058-.882z"
        clipRule="evenodd"
        opacity="0.5"
      ></path>
    </svg>
  );
}
