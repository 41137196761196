import usePricingByApp from 'hooks/pricing/usePricingByApp';
import { _PricingInfo } from './PricingInfo';

import CircleCheckIcon from '@/assets/icons/CircleCheckIcon';
import CircleXIcon from '@/assets/icons/CircleXIcon';

function BitLinkStatus() {
  const { data } = usePricingByApp('BITLINK');

  if (!data) return null;

  return (
    <_PricingInfo
      app="BITLINK"
      subscriptionPlan={data.pricingModule.label || ''}
    >
      <div className="flex items-center gap-2 mb-4">
        <div className="text-neutral-50 text-xs grow">bitbybit logo</div>
        <div className="text-neutral-50 text-xs font-semibold">
          {data.pricingName === 'free' ? <CircleXIcon /> : <CircleCheckIcon />}
        </div>
      </div>
    </_PricingInfo>
  );
}

export default BitLinkStatus;
