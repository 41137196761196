import { SidebarIcon } from '@/constants/layouts/sidebarData';

function BitAiIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitAiIconDefault {...props} />
      ) : (
        <BitAiIconActive {...props} />
      )}
    </>
  );
}

function BitAiIconActive({ size = 26 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M9.166 2.5v1.667h1.667v-.834c0-.92.746-1.667 1.667-1.667a.833.833 0 110 1.667v.833h.833a2.5 2.5 0 012.5 2.5V7.5H17.5a.833.833 0 110 1.666h-1.667v1.667H17.5a.833.833 0 110 1.667h-1.667v.833a2.5 2.5 0 01-2.5 2.5H12.5V17.5a.833.833 0 11-1.667 0v-1.667H9.166V17.5a.833.833 0 11-1.666 0v-1.667h-.833a2.5 2.5 0 01-2.5-2.5V12.5H2.5a.833.833 0 110-1.667h1.667V9.166h-.834c-.92 0-1.667-.746-1.667-1.666a.833.833 0 011.667 0h.833v-.833a2.5 2.5 0 012.5-2.5H7.5V2.5a.833.833 0 111.666 0zm4.167 3.333H6.666a.833.833 0 00-.833.833v6.667c0 .46.373.833.833.833h6.667c.46 0 .833-.373.833-.833V6.666a.833.833 0 00-.833-.833z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function BitAiIconDefault({ size }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M9.166 2.5v1.667h1.667v-.834c0-.92.746-1.667 1.667-1.667a.833.833 0 110 1.667v.833h.833a2.5 2.5 0 012.5 2.5V7.5H17.5a.833.833 0 110 1.666h-1.667v1.667H17.5a.833.833 0 110 1.667h-1.667v.833a2.5 2.5 0 01-2.5 2.5H12.5V17.5a.833.833 0 11-1.667 0v-1.667H9.166V17.5a.833.833 0 11-1.666 0v-1.667h-.833a2.5 2.5 0 01-2.5-2.5V12.5H2.5a.833.833 0 110-1.667h1.667V9.166h-.834c-.92 0-1.667-.746-1.667-1.666a.833.833 0 011.667 0h.833v-.833a2.5 2.5 0 012.5-2.5H7.5V2.5a.833.833 0 111.666 0zm4.167 3.333H6.666a.833.833 0 00-.833.833v6.667c0 .46.373.833.833.833h6.667c.46 0 .833-.373.833-.833V6.666a.833.833 0 00-.833-.833z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BitAiIcon;
