import { BBBModal } from '@/components/ui';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import {
  ConfirmationModalStoreTypes,
  hideConfirmationModal,
} from '@/stores/common';

export default function ConfirmationModal() {
  const shown = useAppSelector((state) => state.common.confirmationModal.show);

  if (!shown) return null;

  return <GeneralConfirmationModal />;
}

export function GeneralConfirmationModal() {
  const props = useAppSelector((state) => state.common.confirmationModal);

  return <_ConfirmationModal {...props} />;
}

export function _ConfirmationModal(props: ConfirmationModalStoreTypes) {
  const dispatch = useAppDispatch();

  return (
    <BBBModal
      show={!!props.show}
      onHide={() => {
        props.onHide ? props.onHide() : props.onCancel?.();
        dispatch(hideConfirmationModal());
      }}
      footer={props.withoutFooter ? false : true}
      submitText={props.submitText}
      cancelText={props.cancelText}
      title={props.title}
      handleSave={() => {
        props.onAccept?.(() => dispatch(hideConfirmationModal()));
      }}
      size={props.size}
      onCancel={() => {
        props.onCancel?.();
        dispatch(hideConfirmationModal());
      }}
      centerFooter
      centerTitle
      deleteModal={props.deleteModal}
      disableSave={props.loadingSubmit || false}
      loadingSave={props.loadingSave}
      withoutCancel={props.withoutCancel}
      submitLink={props.submitLink}
      additionalButton={props.additionalButton}
      submitHref={props.submitHref}
    >
      <div className="text-center">{props.description}</div>
    </BBBModal>
  );
}
