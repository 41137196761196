export const formatNumber = (
  value: number | null | undefined,
  locales = 'en-IN'
) => {
  if (!value) return new Intl.NumberFormat(locales).format(0);

  return new Intl.NumberFormat(locales).format(value);
};

export const formulaOverviewPercentage = (
  total: number | null | undefined,
  totalIncrease: number | null | undefined
) => {
  if (!total || !totalIncrease) return '0%';

  const percentage = (totalIncrease / total) * 100;
  return `${percentage.toFixed(0)}`;
};
