import React, { ReactNode, useMemo } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import BBBSpinner from '@/components/ui/BBBSpinner/BBBSpinner';

type ButtonSize = 'md' | 'sm' | 'lg' | 'full';
type ButtonWidth = 'full' | number;
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'danger-outline'
  | 'secondary-outline'
  | null;

export type BBBButtonTypes = {
  variant?: ButtonVariant;
  loadingState?: boolean;
  isSubmitButton?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: ButtonSize;
  width?: ButtonWidth;
  disabled?: boolean;
  text?: string | React.ReactNode;
  className?: string | undefined;
  backgroundColor?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  loadingColor?: string;
  textClassName?: string;
  children?: ReactNode;
} & JSX.IntrinsicElements['button'];

const mapSizeToPaddingClassNames = (): Record<ButtonSize, string> => ({
  lg: 'px-8 py-4',
  md: 'px-5 py-3',
  sm: 'px-3 py-2',
  full: `py-4 w-full`,
});

export default function BBBButton({
  variant = 'primary',
  loadingState,
  isSubmitButton,
  disabled,
  size = 'md',
  icon,
  iconPosition = 'left',
  text,
  className,
  backgroundColor,
  width,
  loadingColor,
  textClassName,
  children,
  ...props
}: BBBButtonTypes) {
  const variantClassNames = useMemo(() => {
    if (variant === 'primary') {
      return 'border border-transparent bg-primary-main text-white hover:bg-primary-hover active:bg-primary-pressed';
    }
    if (variant === 'secondary') {
      return 'border bg-neutral-10 border-primary-main';
    }
    if (variant === 'danger-outline') {
      return 'border border-danger-main text-danger-main hover:bg-danger-main hover:text-white hover:border-transparent';
    }
    if (variant === 'secondary-outline') {
      return 'border border-neutral-10 text-neutral-10 hover:bg-neutral-10 hover:text-primary-main hover:border-transparent';
    }
    if (variant === 'danger') {
      return 'bg-danger-main hover:bg-red-300 hover:border-transparent text-white hover:bg-danger-hover active:bg-danger-pressed';
    }

    return 'bg-white border border-primary-main';
  }, [variant]);

  const sizeClassNames = mapSizeToPaddingClassNames()[size];

  return (
    <button
      type={isSubmitButton ? 'submit' : 'button'}
      disabled={disabled}
      className={cx(
        `transition-all duration-100 font-semibold whitespace-nowrap shadow rounded-lg align-middle disabled:pointer-events-none disabled:opacity-50`,
        icon || loadingState
          ? 'flex gap-4 items-center justify-center'
          : 'inline-block',
        loadingState && 'pointer-events-none',
        typeof width === 'string' && 'w-full',
        variantClassNames,
        sizeClassNames,
        iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
        className
      )}
      style={{
        backgroundColor,
        ...(typeof width === 'number'
          ? {
              width,
            }
          : {}),
      }}
      {...props}
    >
      {icon}
      {text || children || 'Submit'}
      {loadingState && (
        <BBBSpinner
          width={3}
          height={8}
          color={loadingColor || (variant === 'primary' ? 'white' : 'black')}
        />
      )}
    </button>
  );
}
