import { BBBDeviceFrame } from '@/components/ui';
import { env } from '@/config/env';

type Props = {
  data: {
    title: string;
    message: string;
  };
};

function LivePreview({ data }: Props) {
  return (
    <BBBDeviceFrame>
      <img
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/notifications/bg-livepreview-notification.png`}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="relative h-full w-full">
        <div className="w-full flex justify-between py-4 px-5">
          <p className="text-neutral-10 font-bold text-sm">19:02</p>
          <div className="flex items-center gap-1">
            <SignalIcon />
            <WifiIcon />
            <BatteryIcon />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-3 text-neutral-10">
          <div>
            <LockIcon />
          </div>
          <div className="text-[4rem] font-semibold">9.41</div>
          <div className="text-xl">Monday, June 1</div>
        </div>
        <div className="mx-2 mt-3 py-3 px-2 bg-neutral-10 bg-opacity-[67%] flex flex-nowrap gap-1.5 rounded-lg overflow-clip">
          <img
            src={`${env.REACT_APP_CDN_URL}/bitbybit/static/v1/logo-black.png`}
            className="w-4 h-4 object-contain bg-primary-main rounded"
          />
          <div className="w-[90%] flex flex-col gap-0.5">
            <div className="w-full flex justify-between">
              <p className="text-black text-xs font-bold whitespace-pre-line break-words w-4/5 line-clamp-1">
                {data.title || 'Notification Headline'}
              </p>
              <p className="text-neutral-60 text-[0.625rem]">1m ago</p>
            </div>
            <p className="w-full text-xs text-black leading-4 break-words">
              {data.message ||
                `This will be the message of your notification, where user can read
              in more details on the notification.`}
            </p>
          </div>
        </div>
        <div className="absolute bottom-[20px] flex justify-between px-5 w-full">
          <FlashIcon />
          <CameraIcon />
        </div>
      </div>
    </BBBDeviceFrame>
  );
}

function SignalIcon() {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3348 0.304321C12.7825 0.304321 12.3348 0.752036 12.3348 1.30432V7.75363C12.3348 8.30592 12.7825 8.75363 13.3348 8.75363H13.8696C14.4219 8.75363 14.8696 8.30592 14.8696 7.75363V1.30432C14.8696 0.752037 14.4219 0.304321 13.8696 0.304321H13.3348ZM8.53262 2.99415C8.53262 2.44186 8.98034 1.99415 9.53262 1.99415H10.0674C10.6197 1.99415 11.0674 2.44186 11.0674 2.99415V7.7536C11.0674 8.30588 10.6197 8.7536 10.0674 8.7536H9.53262C8.98034 8.7536 8.53262 8.30588 8.53262 7.7536V2.99415ZM5.73033 3.68406C5.17804 3.68406 4.73033 4.13178 4.73033 4.68407V7.75365C4.73033 8.30594 5.17804 8.75365 5.73033 8.75365H6.26512C6.81741 8.75365 7.26512 8.30593 7.26512 7.75365V4.68406C7.26512 4.13178 6.81741 3.68406 6.26512 3.68406H5.73033ZM1.77315 8.75363C1.30651 8.75363 0.928223 8.37535 0.928223 7.9087V5.79637C0.928223 5.32973 1.30651 4.95144 1.77315 4.95144H2.61809C3.08473 4.95144 3.46302 5.32973 3.46302 5.79637V7.9087C3.46302 8.37535 3.08473 8.75363 2.61809 8.75363H1.77315Z"
        fill="white"
      />
    </svg>
  );
}

function WifiIcon() {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00038 2.0768C9.03166 2.07689 10.9853 2.85335 12.4574 4.24571C12.5683 4.3532 12.7455 4.35185 12.8547 4.24267L13.9143 3.17873C13.9696 3.12336 14.0005 3.04835 14 2.9703C13.9995 2.89226 13.9678 2.81761 13.9119 2.76288C10.048 -0.920961 3.95218 -0.920961 0.0882526 2.76288C0.032281 2.81757 0.000523064 2.89219 6.96858e-06 2.97024C-0.000509218 3.04828 0.0302588 3.12332 0.0855023 3.17873L1.1455 4.24267C1.25461 4.35201 1.43194 4.35337 1.54273 4.24571C3.01507 2.85326 4.9689 2.07679 7.00038 2.0768ZM7.00038 5.53824C8.11644 5.53817 9.19267 5.95085 10.02 6.6961C10.1318 6.80187 10.3081 6.79958 10.4172 6.69094L11.4757 5.627C11.5314 5.57119 11.5623 5.49549 11.5615 5.41682C11.5607 5.33815 11.5282 5.26308 11.4714 5.20842C8.95213 2.87714 5.05077 2.87714 2.53153 5.20842C2.47462 5.26308 2.44216 5.33819 2.44141 5.41688C2.44066 5.49557 2.4717 5.57127 2.52756 5.627L3.58572 6.69094C3.6948 6.79958 3.87106 6.80187 3.98295 6.6961C4.80969 5.95135 5.88506 5.5387 7.00038 5.53824ZM9.03451 8.0774C9.09112 8.02213 9.1223 7.94607 9.12068 7.86718C9.11906 7.78829 9.08479 7.71356 9.02596 7.66064C7.85666 6.67676 6.14411 6.67676 4.97481 7.66064C4.91594 7.71352 4.88161 7.78822 4.87993 7.86711C4.87826 7.946 4.90938 8.02209 4.96595 8.0774L6.79718 9.91558C6.85086 9.9696 6.92403 10 7.00038 10C7.07674 10 7.14991 9.9696 7.20358 9.91558L9.03451 8.0774Z"
        fill="white"
      />
    </svg>
  );
}

function BatteryIcon() {
  return (
    <svg
      width="20"
      height="9"
      viewBox="0 0 20 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.4458 2.30772C1.4458 1.79792 1.87733 1.38464 2.40966 1.38464H15.6627C16.195 1.38464 16.6265 1.79792 16.6265 2.30772V6.69234C16.6265 7.20214 16.195 7.61541 15.6627 7.61541H2.40966C1.87733 7.61541 1.4458 7.20214 1.4458 6.69234V2.30772Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40964 0.692308H15.6627C16.5942 0.692308 17.3494 1.41554 17.3494 2.30769V6.69231C17.3494 7.58446 16.5942 8.30769 15.6627 8.30769H2.40964C1.47807 8.30769 0.722892 7.58446 0.722892 6.69231V2.30769C0.722892 1.41554 1.47807 0.692308 2.40964 0.692308ZM0 2.30769C0 1.03319 1.07883 0 2.40964 0H15.6627C16.9935 0 18.0723 1.03319 18.0723 2.30769V6.69231C18.0723 7.96681 16.9935 9 15.6627 9H2.40964C1.07883 9 0 7.96681 0 6.69231V2.30769ZM20 4.5C20 5.20929 19.486 5.80359 18.7952 5.95986V3.04014C19.486 3.19641 20 3.79071 20 4.5Z"
        fill="white"
      />
    </svg>
  );
}

function LockIcon() {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.75 8.75V6.25C16.75 2.75 14 0 10.5 0C7 0 4.25 2.75 4.25 6.25V8.75C2.125 8.75 0.5 10.375 0.5 12.5V21.25C0.5 23.375 2.125 25 4.25 25H16.75C18.875 25 20.5 23.375 20.5 21.25V12.5C20.5 10.375 18.875 8.75 16.75 8.75ZM6.75 6.25C6.75 4.125 8.375 2.5 10.5 2.5C12.625 2.5 14.25 4.125 14.25 6.25V8.75H6.75V6.25Z"
        fill="white"
      />
    </svg>
  );
}

function FlashIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#D9D9D9" fillOpacity="0.5" />
      <path
        d="M22 16V27C22 27.5304 21.7893 28.0391 21.4142 28.4142C21.0391 28.7893 20.5304 29 20 29H18C17.4696 29 16.9609 28.7893 16.5858 28.4142C16.2107 28.0391 16 27.5304 16 27V16L14 14V12H24V14L22 16ZM18 17V18C18 18.2652 18.1054 18.5196 18.2929 18.7071C18.4804 18.8946 18.7348 19 19 19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V17C20 16.7348 19.8946 16.4804 19.7071 16.2929C19.5196 16.1054 19.2652 16 19 16C18.7348 16 18.4804 16.1054 18.2929 16.2929C18.1054 16.4804 18 16.7348 18 17ZM14 9H24V11H14V9Z"
        fill="white"
      />
    </svg>
  );
}

function CameraIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#D9D9D9" fillOpacity="0.5" />
      <path
        d="M19 17C17.374 17 16 18.374 16 20C16 21.626 17.374 23 19 23C20.626 23 22 21.626 22 20C22 18.374 20.626 17 19 17Z"
        fill="white"
      />
      <path
        d="M27 13H24.414L21.707 10.293C21.6143 10.2 21.5041 10.1262 21.3828 10.0759C21.2614 10.0256 21.1313 9.99981 21 10H17C16.8687 9.99981 16.7386 10.0256 16.6172 10.0759C16.4959 10.1262 16.3857 10.2 16.293 10.293L13.586 13H11C9.897 13 9 13.897 9 15V26C9 27.103 9.897 28 11 28H27C28.103 28 29 27.103 29 26V15C29 13.897 28.103 13 27 13ZM19 25C16.29 25 14 22.71 14 20C14 17.29 16.29 15 19 15C21.71 15 24 17.29 24 20C24 22.71 21.71 25 19 25Z"
        fill="white"
      />
    </svg>
  );
}

export default LivePreview;
