import { useMutation } from '@tanstack/react-query';
import { useAppSelector } from '../rtk/store';
import { selectActiveChatTruthy } from './chat';

import services from '@/api/services';
import { useApiKey } from '@/hooks/rtk/selector';

const useSummarizeMessage = () => {
  const apiKey = useApiKey();
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useMutation(() =>
    services.whatsApp.chat.summarizeMessage({
      key: apiKey,
      number: selectedChat.clientNumber,
      sources: selectedChat.sources,
    })
  );
};

export default useSummarizeMessage;
