function PointerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.333 3.842c.292 0 .528.237.528.528v6.726a.527.527 0 01-.928.358l-1.637-1.9a.784.784 0 00-.876-.28c-.47.153-.65.668-.412 1.053l.003.005 1.245 2.063c1.19 1.985 2 3.084 2.847 3.706.81.595 1.703.788 3.188.788 1.447 0 2.706-.375 3.592-1.074.87-.687 1.422-1.718 1.422-3.148V7.52a.641.641 0 00-.643-.66c-.125 0-.31.04-.45.14-.112.08-.226.217-.226.52a.528.528 0 01-1.056 0c0-.647.282-1.103.669-1.38a1.88 1.88 0 011.063-.335c.967 0 1.699.78 1.699 1.715v5.146c0 1.737-.688 3.08-1.824 3.976-1.12.885-2.631 1.302-4.246 1.302-1.575 0-2.738-.203-3.813-.994-1.039-.763-1.943-2.04-3.127-4.012l-1.242-2.06c-.61-.99-.109-2.254.985-2.609a1.84 1.84 0 012.009.603l.702.815V4.37c0-.291.237-.528.528-.528z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M7.52 1.32a.66.66 0 00-.66.66v6.728a.528.528 0 11-1.055 0V1.98a1.715 1.715 0 113.43 0v1.584a1.715 1.715 0 012.219.866 1.715 1.715 0 012.532 1.509v2.77a.528.528 0 11-1.056 0v-2.77a.66.66 0 10-1.32 0v2.77a.528.528 0 11-1.055 0V5.146a.66.66 0 10-1.32 0v3.562a.528.528 0 11-1.055 0V1.98a.66.66 0 00-.66-.66z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PointerIcon;
