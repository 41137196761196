import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import getBlog from 'graphql/query/getBlog';
import useGraphQLRequest from '../useGraphQLRequest';

import ShopifyBlogs from '@/types/shopify/ShopifyBlogs';
import { generateShopifyId } from '@/utils/bitApp';

const useBlog = (
  id: string | null | undefined,
  options?: Omit<
    UseQueryOptions<ShopifyBlogs, unknown, ShopifyBlogs, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const { request, enabled } = useGraphQLRequest();

  return useQuery<ShopifyBlogs>(
    ['bitapp-shopify-blog', id],
    async () => {
      const data = await request({
        query: getBlog,
        variables: {
          id: generateShopifyId('Blog', id),
        },
      });
      return data?.blog;
    },
    {
      enabled: !!enabled && !!id,
      ...options,
    }
  );
};

export default useBlog;
