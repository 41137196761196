import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { CompanyIntegration } from '@/types/bitApp/v2';
import {
  CompanyIntegrationAssociation,
  IntegrationAssociation,
} from '@/types/bitApp/v2/association';

export default {
  getCompanyIntegrations: (companyId: number | undefined) =>
    api.bitLogin
      .get<ApiResponse<CompanyIntegrationAssociation[]>>(
        `/integrations/${companyId}`
      )
      .then((res) => res.data.data),
  getIntegrations: () =>
    api.bitLogin
      .get<ApiResponse<IntegrationAssociation[]>>(`/integrations`)
      .then((res) => res.data.data),
  toggleCompanyIntegrations: (
    companyId: number | undefined,
    {
      integrationId,
      status,
      userId,
    }: Pick<CompanyIntegration, 'integrationId' | 'status'> & {
      userId: number;
    }
  ) =>
    api.bitLogin.post(`/integrations/${companyId}/toggle`, {
      integrationId,
      status,
      userId,
    }),
};
