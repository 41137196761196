import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ProductWithVariants } from '@/api/services/customer/products';
import CartIcon2 from '@/assets/icons/CartIcon2';
import ShopifyIcon from '@/assets/icons/ShopifyIcon';
import BetaBadge from '@/components/Badge/BetaBadge';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBModal,
  BBBPrimarySwitch,
  BBBSelect,
} from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { currencyOptions, formatCurrency } from '@/constants/common/currency';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import {
  useHasAlreadyImported,
  useImportProducts,
  useProducts,
  useProductsActions,
  useToggleDisplayOnWhatsappCatalog,
} from '@/hooks/customers/products';
import { useActiveCompany, useCompany } from '@/hooks/rtk/selector';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { socketCustomers } from '@/socket';
import { ProductSource } from '@/types/customers';
import { formatDate2 } from '@/utils/common/date';
import { cn } from '@/utils/styles';

const mapSourceToLabel = (source: ProductSource) => {
  const sourceMap = {
    bitbybit: 'bitbybit ',
    shopify_bulk_import: 'Shopify',
    shopify_orders_create: 'Shopify',
  };

  return sourceMap[source];
};

const sourceOptions = [
  {
    label: 'bitbybit',
    value: 'bitbybit',
  },
  {
    label: 'Shopify',
    value: 'shopify',
  },
];

const filterOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Whatsapp catalog only',
    value: 'whatsapp-catalog',
  },
];

function Products() {
  const {
    mutate: toggleDisplayWaCatalog,
    isLoading: loadingToggleDisplayWaCatalog,
  } = useToggleDisplayOnWhatsappCatalog();

  const { data: cloudApiIntegration } = useWhatsappCloudIntegration();
  const hasCloudApiIntegration = !!cloudApiIntegration;

  const connect = useConnectIntegration();

  const currency = useCompany().currency || currencyOptions[0].code;

  const companyId = useActiveCompany();

  const columns: HeadersTypes<ProductWithVariants> = [
    {
      accesor: 'media',
      renderHeader: () => null,
      render: (row) =>
        !row.media ? (
          <div className="w-[2.5rem] h-[2.5rem] bg-neutral-30 rounded-lg"></div>
        ) : (
          <img
            src={row.media}
            className="rounded-lg w-[2.5rem] h-[2.5rem]"
            alt="product"
          />
        ),
      columnWidth: '4rem',
    },
    {
      accesor: 'name',
      renderHeader: () => 'Name',
      render: (row) => <p>{row.name}</p>,
    },
    {
      accesor: 'variants',
      renderHeader: () => 'Price',
      render: (row) => {
        if (row.variants.length === 0) {
          return <p>-</p>;
        }

        if (row.variants.length === 1) {
          return <p>{formatCurrency(row.variants[0].price, currency)}</p>;
        }

        const prices = row.variants.map((v) => v.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        return (
          <p>
            {minPrice === maxPrice
              ? formatCurrency(minPrice, currency)
              : `${formatCurrency(minPrice, currency)} - ${formatCurrency(
                  maxPrice,
                  currency
                )}`}
          </p>
        );
      },
    },
    {
      accesor: 'displayOnWhatsappCatalog',
      renderHeader: () => 'WhatsApp Catalog',
      render: (row) => (
        <BBBPrimarySwitch
          checked={row.displayOnWhatsappCatalog}
          onChange={(value) => {
            if (hasCloudApiIntegration) {
              toggleDisplayWaCatalog({
                productId: row.id,
                displayOnWhatsappCatalog: !!value,
              });
            } else {
              connect({
                name: 'whatsapp_meta',
              });
            }
          }}
          disabled={loadingToggleDisplayWaCatalog}
        />
      ),
    },
    {
      accesor: 'source',
      renderHeader: () => 'Source',
      render: (row) => <p>{row.source ? mapSourceToLabel(row.source) : '-'}</p>,
    },
    {
      accesor: 'updatedAt',
      renderHeader: () => 'Last Updated',
      render: (row) => <p>{formatDate2(row.updatedAt)}</p>,
    },
  ];

  const [search, setSearch] = useState('');
  const [source, setSource] = useState<{ label: string; value: string } | null>(
    null
  );
  const [selectedFilter, setSelectedFilter] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [showImportModal, setShowImportModal] = useState(false);

  const { data: hasAlreadyImported, status: hasAlreadyImportedStatus } =
    useHasAlreadyImported();

  const { data } = useProducts({
    limit: pageSize,
    page: pageIndex + 1,
    search,
    source: source?.value,
    ...(selectedFilter?.value === 'whatsapp-catalog'
      ? { whatsappCatalog: true }
      : {}),
  });

  useEffect(() => {
    if (hasAlreadyImportedStatus === 'success' && !hasAlreadyImported) {
      socketCustomers.emit('join', {
        companyId,
      });
    }
  }, [hasAlreadyImported, hasAlreadyImportedStatus, companyId]);

  useEffect(() => {
    function onProgressImporting() {
      setShowImportModal(false);
    }

    if (hasAlreadyImportedStatus === 'success' && !hasAlreadyImported) {
      socketCustomers.on('bulk-import-products-progress', onProgressImporting);
    }
  }, [hasAlreadyImported, hasAlreadyImportedStatus]);

  useEffect(() => {
    function onFinishImporting() {
      setShowImportModal(false);
    }

    if (hasAlreadyImportedStatus === 'success' && !hasAlreadyImported) {
      socketCustomers.on('bulk-import-products-finish', onFinishImporting);
    }
  }, [hasAlreadyImported, hasAlreadyImportedStatus]);

  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selected, setSelected] = useState<ProductWithVariants[]>([]);

  const actualSelected = isAllRowsSelected
    ? data?.meta.total
      ? data.meta.total - selected.length
      : 0
    : selected.length;

  const [loadingDelete, setLoadingDelete] = useState(false);

  const bulkParams = useMemo(
    () => ({
      selected: selected.map((row) => row.id),
      isAllRowsSelected,
      count: actualSelected,
    }),
    [actualSelected, isAllRowsSelected, selected]
  );

  const bulkAction = useProductsActions({
    onSuccess: () => {
      setIsAllRowsSelected(false);
      setSelected([]);
    },
    onChangeLoadDelete: setLoadingDelete,
  });

  return (
    <BBBContainer
      pageTitle={
        <div className="flex items-center justify-start gap-2">
          Product <BetaBadge />
        </div>
      }
      pageDescription="Add product to sell to your customers. Add them to WhatsApp catalog for more discoverability."
      hasHeading
      rightComponent={
        <Link to="/products/new">
          <BBBButton>Add new product</BBBButton>
        </Link>
      }
    >
      <BBBTableV2
        data={data?.data}
        headers={columns}
        dataId="id"
        isSearchable
        searchValue={search}
        onChangeSearch={setSearch}
        searchPlaceholder="Search on products"
        isPaginate
        isFilterable
        renderFilterSection={() => (
          <>
            <BBBSelect
              options={filterOptions}
              optionLabel="label"
              optionValue="value"
              onValueChange={(value) => {
                setSelectedFilter(value!);
              }}
              value={selectedFilter}
              placeholder="Filter"
              containerClassName="w-32"
              enableToggleOption
            />
            <BBBSelect
              options={sourceOptions}
              optionLabel="label"
              optionValue="value"
              onValueChange={(value) => {
                setSource(value!);
              }}
              value={source}
              placeholder="Source"
              containerClassName="w-32"
              enableToggleOption
            />
          </>
        )}
        withoutActionOption
        subHeaderComponent={
          actualSelected > 0 && (
            <div className="flex justify-end items-center mb-3.5 mx-2">
              <BBBButton
                variant="danger-outline"
                text={`${
                  loadingDelete ? `Deleting` : `Delete`
                } (${actualSelected.toLocaleString()})`}
                onClick={() => {
                  bulkAction('delete', bulkParams, {
                    onSuccess: () => {
                      setSelected([]);
                    },
                  });
                }}
                loadingState={loadingDelete}
                size="sm"
              />
              {/* <BBBButton
                variant="secondary"
                text={`${
                  loadingExport ? 'Downloading' : 'Download'
                } customer (${actualSelected.toLocaleString()})`}
                onClick={() => handleBulkExport()}
                loadingState={loadingExport}
              />
              <BBBButton
                variant="secondary"
                text={`Add customer Tag (${actualSelected.toLocaleString()})`}
                onClick={() => setShowAddTag(!showAddTag)}
              /> */}
            </div>
          )
        }
        customSelectedHeader={() => (
          <p className="pl-1.5 text-base font-medium">
            {actualSelected.toLocaleString()} selected{' '}
            {actualSelected < (data?.meta.total || 0) ? (
              <>
                or{' '}
                <span
                  className="text-info-main underline cursor-pointer hover:opacity-90 transition-all"
                  onClick={() => {
                    setSelected([]);
                    setIsAllRowsSelected(true);
                  }}
                >
                  select all products ({data?.meta.total?.toLocaleString()})
                </span>
              </>
            ) : null}
          </p>
        )}
        pagination={{
          page: pageIndex + 1,
          limit: pageSize,
          onChange: (page) => setPageIndex(page - 1),
          total: data?.meta.total || 0,
          maxShown: 3,
        }}
        isSelectable
        onChangeSelectable={setSelected}
        selected={selected}
        isAllRowSelected={isAllRowsSelected}
        onChangeAllRowSelected={setIsAllRowsSelected}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(page) => {
          setPageSize(page!);
          setPageIndex(0);
        }}
        linkDestination={(row) => `/products/${row.id}`}
        renderEmptyMessage={() => (
          <div className="h-[18.8125rem] flex justify-center items-center flex-col gap-6 max-w-lg mx-auto">
            <div className="w-[3.125rem] h-[3.125rem] bg-neutral-30 rounded-full flex items-center justify-center">
              <CartIcon2 size={'1.625rem'} />
            </div>
            <p className="text-neutral-500 text-center">
              There is no product. Add your product and start selling it through
              WhatsApp Catalog.
            </p>
            <div className="flex items-center gap-2">
              {!hasAlreadyImported && (
                <ShopifyAwareWrapper appType="BITCHAT" fallbackToChildren>
                  {({ connectMiddleware }) => (
                    <BBBButton
                      variant="secondary"
                      onClick={() => {
                        connectMiddleware(() => {
                          setShowImportModal(true);
                        });
                      }}
                    >
                      Import from Shopify
                    </BBBButton>
                  )}
                </ShopifyAwareWrapper>
              )}
              <Link to="/products/new">
                <BBBButton>Add product</BBBButton>
              </Link>
            </div>
          </div>
        )}
      />
      <ImportModal
        show={showImportModal}
        onClose={() => setShowImportModal(false)}
      />
    </BBBContainer>
  );
}

export default Products;

function ImportModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { data: shopifyIntegration } = useShopifyIntegrationByApp('BITCHAT');

  const shopifyStore = shopifyIntegration?.domain;

  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  const { mutate: importProducts, isLoading: loadingImport } =
    useImportProducts();

  return (
    <BBBModal
      title="Import from Shopify"
      show={show}
      onHide={() => {
        setSelectedStore(null);
        onClose();
      }}
      footer
      submitText="Import"
      disableSave={!selectedStore}
      loadingSave={loadingImport}
      handleSave={() => {
        if (selectedStore) {
          importProducts(
            {
              domain: selectedStore,
            },
            {
              onSuccess: () => {
                onClose();
              },
            }
          );
        }
      }}
    >
      <BBBCard
        className={cn(
          'flex items-center gap-2.5 mb-4 cursor-pointer hover:border-[#FF7C11] outline outline-1 outline-transparent hover:outline-[#FED5BF80] transition-colors',
          selectedStore === shopifyStore ? 'border-[#FF7C11]' : ''
        )}
        onClick={() => {
          setSelectedStore(shopifyStore!);
        }}
      >
        <ShopifyIcon />
        <p className="text-neutral-600 grow">
          {shopifyStore ? shopifyStore : 'Select store'}
        </p>
      </BBBCard>
    </BBBModal>
  );
}
