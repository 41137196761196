import * as yup from 'yup';

const passwordValidation = yup
  .string()
  .required('Password is required')
  .min(8, 'Password should be at least 8 characters')
  .max(16, 'Password cannot exceed 16 characters')
  .matches(
    /(?:[A-Z].*[0-9])|(?:[0-9].*[A-Z])/,
    'Password should consist of number, capital, and special character'
  );

export default passwordValidation;
