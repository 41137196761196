import BBBUpsellCard from '@/components/ui/BBBUpsellCard/BBBUpsellCard';
import { env } from '@/config/env';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';

const upsellContents = {
  'enable-one-tap': {
    title: 'Upgrade to enable Google One Tap!',
    description:
      'Google One Tap allow faster sign-up and sign-in process to reduce friction to signing in & checking out to your store.',
  },
  'oauth-helper': {
    title: 'Need help finding your OAuth data?',
    description: `If you're struggling to find your App ID, App Secret, or other OAuth data, contact us right away and we'll be happy to assist you.`,
    submitHref: `https://wa.me/${
      env.REACT_APP_BBB_CS_NUMBER
    }?text=${encodeURIComponent(`I have questions about BITLOGIN`)}`,
    submitText: 'Contact us',
    onlyGuides: true,
  },
};

function UpsellCard({
  type = 'enable-one-tap',
}: {
  type?: keyof typeof upsellContents;
}) {
  const { data: pricingData } = usePricingByApp('BITLOGIN', {
    enabled: type === 'enable-one-tap',
  });

  const pricingName = pricingData?.pricingName || 'free';

  if (type === 'enable-one-tap' && pricingName !== 'free') {
    return null;
  }

  const meta = upsellContents[type];

  return <BBBUpsellCard appType="BITLOGIN" id={type} {...meta} />;
}

export default UpsellCard;
