import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { PlusCircle } from 'react-feather';
import Product from '../../components/Product';

import { CollectionSortingRow } from '@/api/services/bitAi/v2-new/sorting';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';

type Columns = {
  onShowAddProductModal: (val: string) => void;
  onRearrangeProducts: (params: {
    startIndex: number;
    endIndex: number;
    draggableId: string;
  }) => void;
  onDeleteProduct: (params: { productId: string; rowId: string }) => void;
  isManageSorting: boolean;
};

export default function useColumnManage({
  ...props
}: Columns): HeadersTypes<CollectionSortingRow> {
  const reorderData = (result: DropResult) => {
    const { source, destination, type, draggableId } = result;

    if (!destination) return;

    const startIndex = source.index;
    const endIndex = destination.index;

    if (type.startsWith('droppableSub')) {
      props.onRearrangeProducts({ startIndex, endIndex, draggableId });

      return;
    }
  };

  return [
    {
      isAdditionalColumn: true,
      render: (row) => (
        <div className="w-full flex flex-col gap-2">
          <p className="text-primary-main font-semibold">{row.condition}</p>
          <p className="text-primary-main">
            Product Qty ({row.qty === -1 ? '*Custom*' : row.qty})
          </p>
          <div className="w-full flex items-start justify-between">
            <p className="text-primary-main">Feature Product</p>
            <DragDropContext onDragEnd={reorderData}>
              <Droppable
                droppableId={`${row.id}`}
                type={`droppableSub-${row.id}`}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="max-w-[10rem]"
                    {...provided.droppableProps}
                  >
                    {row.featuredProduct.map((product, index) => (
                      <Draggable
                        draggableId={`${row.id}-${product.id}`}
                        index={index}
                        isDragDisabled={!props.isManageSorting}
                        key={`${row.id}-${product.id}`}
                      >
                        {(provided) => (
                          <Product
                            provided={provided}
                            onClickDelete={() => {
                              props.onDeleteProduct({
                                productId: product.id,
                                rowId: row.id,
                              });
                            }}
                            enableEdit={props.isManageSorting}
                            {...product}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div
                      className="grow flex items-center gap-2 cursor-pointer py-2"
                      onClick={() => {
                        props.onShowAddProductModal(row.id);
                      }}
                    >
                      <PlusCircle size="16px" />
                      <div className="grow">Add product</div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      ),
    },
  ];
}
