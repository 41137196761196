import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { downloadXlsxFile } from '@/utils/common/file';
import { toast } from '@/utils/common/toast';

export const useResendCampaign = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: string) =>
      api.bitCRM.v2.post(`/campaign-new/action/resend`, {
        companyId: activeCompany,
        id: payload,
      }),
    {
      onSuccess: () => {
        toast.success('Your campaign has been resend');
        queryClient.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );
};

export const useResumeCampaign = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useMutation(
    async ({ id }: { id: string }) =>
      api.bitCRM.v2.post(`/campaign-new/action/resume`, {
        companyId: activeCompany,
        id,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your campaign has been resumed`);
        client.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );
};

export const usePauseCampaign = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: string) =>
      api.bitCRM.v2.post(`/campaign-new/action/stop`, {
        companyId: activeCompany,
        id: payload,
      }),
    {
      onSuccess: () => {
        toast.success('Your campaign has been paused');
        queryClient.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );
};

export const useCancelCampaign = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  const cancelCampaignMutation = useMutation(
    async ({ id }: { id: string }) =>
      api.bitCRM.v2.post(`/campaign-new/action/cancel`, {
        companyId: activeCompany,
        id,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your campaign has been cancelled`);
        client.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );

  function cancelCampaign(withConfirmation: true): (param: string) => void;
  function cancelCampaign(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown>;
  function cancelCampaign(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown> {
    return withConfirmation
      ? (id) =>
          toggleConfirmation({
            title: 'Cancel campaign?',
            description: `Once canceled you're not able to recover it`,
            onAccept: async (hide) =>
              cancelCampaignMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Cancel campaign',
            cancelText: 'Not now',
          })
      : cancelCampaignMutation;
  }
  return cancelCampaign;
};

export const useDeleteCampaign = () => {
  const toggleConfirmation = useConfirmationModal();
  const client = useQueryClient();

  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const deleteCampaignMutation = useMutation(
    ({ id }: { id: string }) =>
      api.bitCRM.v2.delete(`/campaign-new/${activeCompany}/${id}`),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your campaign has been deleted`);
        client.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );

  function deleteCampaign(withConfirmation: true): (param: string) => void;
  function deleteCampaign(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown>;
  function deleteCampaign(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown> {
    return withConfirmation
      ? (id) =>
          toggleConfirmation({
            title: 'Delete campaign?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteCampaignMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteCampaignMutation;
  }

  return deleteCampaign;
};

export const useExportCampaign = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const exportCampaign = (campaignIds: string[], action?: () => void) =>
    api.bitCRM.v2
      .get(`/log/campaign/export`, {
        params: {
          companyId: Number(activeCompany),
          campaignIds: campaignIds.join(','),
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
        downloadXlsxFile(data, 'Campaign', action);
      });

  return {
    exportCampaign,
  };
};

export const useCancelAndDeleteCampaign = () => {
  const cancelCampaign = useCancelCampaign();
  const deleteCampaign = useDeleteCampaign();
  const toggleConfirmation = useConfirmationModal();

  return (id: string) => {
    toggleConfirmation({
      description: `Once deleted you're not able to recover it.`,
      title: `Delete & cancel campaign?`,
      onAccept: (hide) => {
        cancelCampaign(false).mutate(
          {
            id,
          },
          {
            onSuccess: () => {
              deleteCampaign(false).mutate({ id });
              hide();
            },
          }
        );
      },
      deleteModal: true,
      submitText: 'Delete & cancel',
      cancelText: 'Not now',
    });
  };
};
