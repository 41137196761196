import { isEmbed } from '@/hooks/common/useAppEmbed';
import { AppType } from '@/types/systemLogin';

function customLocalStorage(_app: AppType) {
  return {
    ...localStorage,
    getItem: (key) => {
      const localStorageEmbed = localStorage.getItem(`bbb-embed-meta`);

      if (!localStorageEmbed) {
        return undefined;
      }

      const parsedLocalStorage = JSON.parse(localStorageEmbed) as Record<
        AppType,
        Record<string, string> | undefined
      >;

      return parsedLocalStorage[_app]?.[key];
    },
    setItem(key, value) {
      const embedStorage = localStorage.getItem(`bbb-embed-meta`);
      localStorage.setItem(
        `bbb-embed-meta`,
        JSON.stringify({
          ...(embedStorage ? JSON.parse(embedStorage) : {}),
          [_app]: {
            ...(embedStorage ? JSON.parse(embedStorage)[_app] : {}),
            [key]: value,
          },
        })
      );
    },
    clear() {
      localStorage.removeItem(`bbb-embed-meta`);
    },
  } as Storage;
}

const appQuery = new URLSearchParams(window.location.search).get(
  'app'
) as AppType | null;

export const _localStorage =
  isEmbed && appQuery ? customLocalStorage(appQuery) : localStorage;
