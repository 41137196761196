export default function BackgroundBlob({ className }: { className?: string }) {
  return (
    <svg
      width="460"
      height="381"
      viewBox="0 0 460 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M460.001 199.864C460.001 256.612 421.777 347.353 348.196 374.401C278.653 399.964 176.034 343.017 94.3073 343.017C59.4674 343.017 27.6919 344.365 0 345.5V209V43.2334C46.2748 16.8656 99.9166 0 155.45 0C200.529 0 227.598 63.8961 266.09 75.4307C371.219 106.934 460.001 119.065 460.001 199.864Z"
        fill="#FFF5EF"
      />
    </svg>
  );
}
