import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useBlastNotifications = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const history = useHistory();

  return useMutation(
    (payload: {
      campaignName: string;
      title: string;
      body: string;
      redirectPath?: string | null | undefined;
      platform: string;
      test?: boolean;
    }) =>
      services.bitApp.notification.blastNotifications(activeCompany!, payload),
    {
      onSuccess: () => {
        toast.success('Successfully create campaign notification');
        history.push('/bitcrm/campaign');
      },
    }
  );
};

export default useBlastNotifications;
