import { SidebarIcon } from '@/constants/layouts/sidebarData';

function BitChatIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitChatIconDefault {...props} />
      ) : (
        <BitChatIconActive {...props} />
      )}
    </>
  );
}

function BitChatIconDefault({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M15.833 4.425H4.167v7.7H6.32c.664 0 1.3.303 1.768.845L10 15.177l1.91-2.207c.47-.542 1.105-.846 1.768-.846h2.155v-7.7zM4.167 2.5c-.92 0-1.667.862-1.667 1.925v7.7c0 1.062.746 1.924 1.667 1.924H6.32c.222 0 .433.101.59.282l2.5 2.887a.757.757 0 001.178 0l2.5-2.887a.78.78 0 01.59-.282h2.154c.92 0 1.667-.862 1.667-1.925v-7.7c0-1.062-.746-1.924-1.667-1.924H4.167z"
        clipRule="evenodd"
      ></path>
      <circle cx="10" cy="8.333" r="0.833" fill="#727272"></circle>
      <circle cx="6.667" cy="8.333" r="0.833" fill="#727272"></circle>
      <circle cx="13.333" cy="8.333" r="0.833" fill="#727272"></circle>
    </svg>
  );
}

function BitChatIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M15.833 4.425H4.167v7.7H6.32c.664 0 1.3.303 1.768.845L10 15.177l1.91-2.207c.47-.542 1.105-.846 1.768-.846h2.155v-7.7zM4.167 2.5c-.92 0-1.667.862-1.667 1.925v7.7c0 1.062.746 1.924 1.667 1.924H6.32c.222 0 .433.101.59.282l2.5 2.887a.757.757 0 001.178 0l2.5-2.887a.78.78 0 01.59-.282h2.154c.92 0 1.667-.862 1.667-1.925v-7.7c0-1.062-.746-1.924-1.667-1.924H4.167z"
        clipRule="evenodd"
      ></path>
      <circle cx="10" cy="8.333" r="0.833" fill="#FF7C11"></circle>
      <circle cx="6.667" cy="8.333" r="0.833" fill="#FF7C11"></circle>
      <circle cx="13.333" cy="8.333" r="0.833" fill="#FF7C11"></circle>
    </svg>
  );
}

export default BitChatIcon;
