import { Control, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { SettingsFormSchema } from 'pages/Settings';

import { BBBCard, BBBSelect } from '@/components/ui';
import { currencyOptions2 } from '@/constants/common/currency';
import { timeFormatOptions, timezoneOptions } from '@/constants/settings';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  control: Control<SettingsFormSchema>;
};

export default function Timezone({ control }: Props) {
  return (
    <>
      <BBBCard
        title="Time zone"
        titleClassName="lg:font-normal font-semibold"
        className="mb-cardBottom"
      >
        <div className="flex items-center gap-2">
          <Controller
            control={control}
            name="timezone"
            render={({ field }) => (
              <BBBSelect
                label="Time zone"
                containerClassName="flex-1"
                placeholder="Time zone"
                options={timezoneOptions}
                optionLabel="text"
                optionValue="value"
                value={field.value}
                onValueChange={field.onChange}
                isSearchable
              />
            )}
          />
          <Controller
            control={control}
            name="timeFormat"
            render={({ field }) => (
              <BBBSelect
                label="Time format"
                containerClassName="flex-1"
                placeholder="Time format"
                //@ts-ignore
                options={timeFormatOptions}
                optionLabel="label"
                optionValue="value"
                value={field.value}
                onValueChange={field.onChange}
              />
            )}
          />
        </div>
      </BBBCard>
      <BBBCard title="Currency" titleClassName="lg:font-normal font-semibold">
        <Controller
          control={control}
          name="currency"
          render={({ field }) => (
            <BBBSelect
              label="Currency"
              containerClassName="flex-1"
              placeholder="Currency"
              options={currencyOptions2}
              optionLabel="label"
              optionValue="value"
              value={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
      </BBBCard>
    </>
  );
}
