import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { twMerge as cx } from 'tailwind-merge';
import ChatPanel, { PanelState } from './components/ChatPanel';
import EmptyState from './components/EmptyState';
import useRealtimeEvent from './components/hooks/useRealtimeEvent';
import InfoPanel from './components/InfoPanel';
import MenuPanel from './components/MenuPanel';
import MessagePanel from './components/MessagePanel';
import ImageZoom from './components/MessagePanel/ImageZoom';

import { BBBSpinner } from '@/components/ui';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { useConnectedToAnyChannels } from '@/hooks/whatsApp/channels';
import { useChat } from '@/hooks/whatsApp/chat';
import useAccounts from '@/hooks/whatsApp/useAccounts';
import useClickMessage from '@/hooks/whatsApp/useClickMessage';
import {
  setApiKey,
  setHasConnectedChannels,
  setSelectedLivechat,
} from '@/stores/bitCRM';
import { Chat } from '@/types/whatsApp/chat';

export default function LiveChat() {
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);

  const dispatch = useAppDispatch();

  const { data: hasConnectedChannels, status: statusConnectedChannels } =
    useConnectedToAnyChannels(undefined);

  const accountsQuery = useAccounts();

  useEffect(() => {
    if (accountsQuery.status === 'success') {
      dispatch(setApiKey(accountsQuery.data?.apiKey || null));
      setLoadingAccounts(false);
    } else if (accountsQuery.status === 'error') {
      setLoadingAccounts(false);
    }
  }, [accountsQuery.data?.apiKey, accountsQuery.status, dispatch]);

  useEffect(() => {
    if (statusConnectedChannels === 'success') {
      dispatch(setHasConnectedChannels(hasConnectedChannels));
      setLoadingChannels(false);
    } else if (statusConnectedChannels === 'error') {
      setLoadingChannels(false);
    }
  }, [dispatch, hasConnectedChannels, statusConnectedChannels]);

  if (loadingChannels || loadingAccounts)
    return <BBBSpinner text="Loading chat" />;

  return (
    <>
      <EmptyState />
      <Content />
    </>
  );
}

function Content() {
  const isConnectingWhatsApp = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'whatsapp'
      ? state.common.connectIntegration?.meta?.isLoadingConnect
      : false
  );

  if (isConnectingWhatsApp) return null;

  return <_Content />;
}

function _Content() {
  const dispatch = useAppDispatch();
  const params = useQuerySearchParams();

  const chatId = params.get('chatId') as string | null;
  const messageId = params.get('messageId') as string | null;
  const sources = params.get('sources') as Chat['sources'] | null;

  const [loading, setLoading] = useState(!!(chatId && sources));

  const clickMessage = useClickMessage();

  const { data: chat, status: chatQueryStatus } = useChat(
    chatId && sources
      ? {
          clientNumber: chatId,
          sources,
        }
      : undefined
  );

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      function loadParams() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve) => {
          if (chatId && sources && chatQueryStatus === 'success') {
            if (!chat) {
              setLoading(false);
              resolve();
              return;
            }

            dispatch(setSelectedLivechat(chat));

            if (messageId) {
              // set slight delay to propagate above query finish state
              setTimeout(() => {
                clickMessage({ id: messageId });
                setLoading(false);
                resolve();

                return;
              }, 1000);
            }

            setLoading(false);
            resolve();

            return;
          }
        });
      }

      await loadParams();

      history.replace({ pathname, search: '' });
    })();
  }, [
    chat,
    chatId,
    chatQueryStatus,
    clickMessage,
    dispatch,
    history,
    messageId,
    pathname,
    sources,
    // from,
    // templateId,
    // templateMessage,
    // startChat,
  ]);

  if (loading) return <BBBSpinner text="Loading chat" />;

  return <___Content />;
}

function ___Content() {
  const hasSelectedChat = useAppSelector((s) => !!s.bitCRM.selectedChat);
  const [expandedBoard, setExpandedBoard] = useState(false);

  const isTablet = useResponsive('lg');
  const isMobile = useResponsive('sm');

  useRealtimeEvent();

  return (
    <div className="flex relative flex-none h-full overflow-hidden">
      <ImageZoom />
      {isMobile ? (
        <LivechatMobile />
      ) : isTablet ? (
        <>
          <LivechatTablet />
        </>
      ) : (
        <>
          <ChatPanel className="w-1/4" />
          {!hasSelectedChat ? (
            <InitialStateLivechat />
          ) : (
            <div className={cx('flex w-3/4')}>
              <div
                className={cx(
                  'flex flex-none transition-all',
                  !expandedBoard ? 'w-[68%]' : 'w-full'
                )}
              >
                <MessagePanel
                  expandedBoard={expandedBoard}
                  className={cx('grow', !expandedBoard ? 'w-[68%]' : 'w-[90%]')}
                />
                <MenuPanel
                  onExpand={() => setExpandedBoard((prev) => !prev)}
                  isExpanded={expandedBoard}
                />
              </div>
              <InfoPanel
                className={cx('py-4 pr-5', !expandedBoard ? 'w-[32%]' : '')}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function LivechatTablet() {
  const [panelState, setPanelState] = useState<PanelState[]>([
    'chat',
    'message',
  ]);

  return (
    <>
      {[
        panelState[panelState.length - 2],
        panelState[panelState.length - 1],
      ].map((panel) => (
        <Fragment key={panel}>
          {panel === 'chat' ? (
            <ChatPanel className="w-1/2" />
          ) : panel === 'message' ? (
            <MessagePanel
              onEventChange={(ev) =>
                ev === 'customer-click' &&
                setPanelState(['message', 'customer'])
              }
              className="w-1/2"
            />
          ) : (
            <InfoPanel
              onEventChange={(ev) =>
                ev === 'back' && setPanelState(['chat', 'message'])
              }
              className="w-1/2"
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

function LivechatMobile() {
  const [panelState, setPanelState] = useState<PanelState>('chat');
  const selectedLivechat = useAppSelector((state) => state.bitCRM.selectedChat);

  useEffect(() => {
    if (selectedLivechat) {
      setPanelState('message');
    }
  }, [selectedLivechat]);

  return panelState === 'chat' ? (
    <ChatPanel className="w-full" />
  ) : panelState === 'message' ? (
    <MessagePanel
      onEventChange={(ev) =>
        setPanelState(ev === 'customer-click' ? 'customer' : 'chat')
      }
      className="w-full"
    />
  ) : (
    <InfoPanel
      onEventChange={(ev) => ev === 'back' && setPanelState('message')}
      className="w-full"
    />
  );
}

function InitialStateLivechat() {
  return (
    <div className="w-ful flex flex-col justify-center items-center px-8 h-full">
      <img
        src={'/bitChat/whatsAppEarlyState.png'}
        className="w-3/5 mx-auto mb-4"
        alt="wa-early"
      />
      <div className="text-4xl mt-16 mb-10 text-center" id="test-spotlight">
        bitChat Integration Across Channels
      </div>
      <div className="text-xl text-center">
        Bring your customer’s chat here. Know who your customer better with data
        that we serve. You can add your teammate to build a teamwork to serve
        your customer better.
      </div>
    </div>
  );
}
