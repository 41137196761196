import React, {
  CSSProperties,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

type Position = 'top' | 'bottom' | 'left' | 'right';

export type BBBTooltipProps = {
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  show?: boolean;
  position?: Position;
  offset?: {
    [k in Position]?: number;
  };
  targetRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  tooltipClassName?: string;
};

const positions: Position[] = ['top', 'bottom', 'left', 'right'];

const BBBTooltip = forwardRef<HTMLDivElement, BBBTooltipProps>(
  (
    {
      content,
      children,
      className,
      show = false,
      position = 'top',
      offset,
      tooltipClassName,
      targetRef,
    },
    ref
  ) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const [onShow, setOnShow] = useState<boolean>();
    const [tooltipPosition, setTooltipPosition] =
      useState<Partial<{ [k in Position]: number }>>();
    const _childrenRef = useRef<HTMLDivElement>(null);

    const childrenRef = targetRef || _childrenRef;

    const handleHoverDropdowns = useCallback(() => {
      // if (onShow) return;

      const childElement = childrenRef.current;

      if (childElement) {
        const doesOverflow =
          childElement.scrollHeight > childElement.clientHeight ||
          childElement.offsetWidth < childElement.scrollWidth;

        setOnShow(doesOverflow || show);

        setTimeout(() => {
          const contentElement = contentRef.current;

          if (contentElement) {
            setTooltipPosition((prev) => ({
              ...(prev ?? {}),
              top:
                position === 'top'
                  ? childElement.getBoundingClientRect().top -
                    contentElement.clientHeight
                  : position === 'bottom'
                  ? childElement.getBoundingClientRect().top +
                    childElement.getBoundingClientRect().height +
                    4
                  : childElement.getBoundingClientRect().top -
                    (contentElement.getBoundingClientRect().height -
                      childElement.getBoundingClientRect().height) /
                      2,
              left:
                position === 'top' || position === 'bottom'
                  ? childElement.getBoundingClientRect().left -
                    (contentElement.getBoundingClientRect().width -
                      childElement.getBoundingClientRect().width) /
                      2
                  : position === 'right'
                  ? childElement.getBoundingClientRect().x +
                    childElement.getBoundingClientRect().width +
                    4
                  : childElement.getBoundingClientRect().left,
            }));
          }
        }, 0);
      }
    }, [childrenRef, position, show /* onShow */]);

    return (
      <div
        onMouseEnter={handleHoverDropdowns}
        onMouseLeave={() => setOnShow(false)}
        className={cx('cursor-pointer', className)}
        ref={_childrenRef}
      >
        {createPortal(
          <AnimatePresence>
            {onShow && (
              <Tooltip
                show
                content={content}
                className={tooltipClassName}
                style={Object.fromEntries(
                  positions
                    .filter((k) => tooltipPosition?.[k] || offset?.[k])
                    .map((k) => [
                      k,
                      (tooltipPosition?.[k as keyof typeof tooltipPosition] ||
                        0) + (offset?.[k] ?? 0),
                    ])
                )}
                ref={contentRef}
              />
            )}
          </AnimatePresence>,
          document.body!
        )}
        {children}
      </div>
    );
  }
);

BBBTooltip.displayName = 'BBBTooltip';

export default BBBTooltip;

const _Tooltip = (
  {
    show,
    className,
    content,
    style,
  }: {
    show: boolean;
    className?: string;
    content: ReactNode;
    style?: CSSProperties;
  },
  ref: Ref<HTMLDivElement>
) => {
  return (
    <motion.div
      animate={{
        opacity: 0.75,
        y: 0,
      }}
      initial={{
        opacity: 0,
        y: -10,
      }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className={cx(
        'max-w-sm w-auto absolute p-2 top-0 left-0 bg-neutral-70 rounded-lg text-sm text-neutral-10 break-words z-[1000]',
        !show && 'pointer-events-none',
        className
      )}
      style={style}
      ref={ref}
    >
      {content}
    </motion.div>
  );
};

export const Tooltip = React.forwardRef(_Tooltip);
