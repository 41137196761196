import api from 'config/api';
import { CollectionForms } from 'pages/BitApp/Design/components/Popup/EditCollections';

export default {
  saveCollection: (
    companyId: number | undefined,
    payload: Partial<
      Omit<CollectionForms, 'collections' | 'displayed_number'> & {
        shopifyCollectionId: number | null;
        active: boolean;
        displayedNumber: number;
        sectionId: number | null;
      }
    >
  ) =>
    api.bitApp
      .post(`/collections/${companyId}`, {
        ...payload,
      })
      .then((res) => res.data),
};
