import { useEffect, useState } from 'react';
import { TimePickerValue } from 'react-time-picker';
import CustomModal from '../BBBModal/BBBModal';
import BBBDatePicker from './BBBDatePicker/BBBDatePicker';

export type BBBDatePickerModalProps = {
  showCalendar: boolean;
  setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDay: Date | null | undefined;
  selectedTime?: TimePickerValue | null;
  setSelectedTime?: React.Dispatch<React.SetStateAction<TimePickerValue>>;
  onChangeDate: (date: Date | null | undefined) => void;
  showTimePicker?: boolean;
};

export default function BBBDatePickerModal({
  showCalendar,
  setShowCalendar,
  selectedDay,
  onChangeDate,
  selectedTime,
  setSelectedTime,
  showTimePicker,
}: BBBDatePickerModalProps) {
  const [localDate, setLocalDate] = useState(selectedDay);

  useEffect(() => {
    setLocalDate(selectedDay);
  }, [selectedDay]);

  return (
    <CustomModal
      show={showCalendar}
      onHide={() => {
        setShowCalendar(false);
      }}
      footer
      title="Set campaign schedule"
      handleSave={() => {
        setShowCalendar(false);
        onChangeDate(localDate);
      }}
      size="md"
      bodyClassName="my-5"
    >
      <BBBDatePicker
        selectedDay={localDate}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        onChangeDate={(date) => {
          setLocalDate(date as Date);
        }}
        showTimePicker={showTimePicker}
        className="w-full mx-auto"
      />
    </CustomModal>
  );
}
