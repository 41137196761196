import { AiCreditAlert } from '../../Livechat/components/ChatPanel/HeaderChatPanel';

import InfoList from '@/components/InfoList/InfoList';
import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';

type Props = {
  tags: string[] | undefined;
  withGap?: boolean;
};

export default function AITagging({ tags, withGap }: Props) {
  const sortedTags = tags?.sort((a, b) => {
    if (a.includes(':') && !b.includes(':')) return -1;
    if (!a.includes(':') && b.includes(':')) return 1;
    return 0;
  });

  return (
    <InfoList
      label="AI tagging (1 token/ticket)"
      withGap={withGap}
      isChildrenNewLine
    >
      {!sortedTags?.length ? (
        '-'
      ) : (
        <TruncatedTags items={sortedTags} withoutDelete maxLines={2} />
      )}
      <div className="mt-4">
        <AiCreditAlert context="ticket-detail" />
      </div>
    </InfoList>
  );
}
