import ChatInfo from '../Info';
import { MediaLoading } from '..';

export default function ChatBubbleAudio({
  mediaUrl,
  isLoading,
  error,
  fromMe,
}: {
  mediaUrl?: string | null;
  isLoading?: boolean;
  error?: unknown;
  fromMe: boolean;
}) {
  return isLoading ? (
    <MediaLoading fromMe={!!fromMe} />
  ) : error ? (
    <ChatInfo />
  ) : mediaUrl ? (
    <audio controls src={mediaUrl}></audio>
  ) : null;
}
