import { createSlice } from '@reduxjs/toolkit';

import { Linklists } from '@/types/bitLink/v2';

type Store = {
  form: Partial<Linklists> | null;
  refreshIframe: number;
};

const initialState: Store = {
  form: null,
  refreshIframe: 0,
};

export const bitLinkSlice = createSlice({
  name: 'bitLink',
  initialState,
  reducers: {
    triggerRefreshIframe: (state) => {
      state.refreshIframe += 1;
    },
    setForm: (state, action: { payload: Partial<Linklists> | null }) => {
      state.form = action.payload;
    },
  },
});

export const { setForm, triggerRefreshIframe } = bitLinkSlice.actions;

export default bitLinkSlice.reducer;
