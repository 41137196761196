import { useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { Plus } from 'react-feather';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { FieldForm } from '../CustomFieldCard';

import DraggableIcon from '@/assets/icons/BBBDraggableIcon';
import {
  BBBButton,
  BBBCheckbox,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import { orientationOptions } from '@/constants/bitLogin/additional-data';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';
import { cn } from '@/utils/styles';

type Props = {
  control: Control<FieldForm>;
};

export default function MultipleFields({ control }: Props) {
  const type = useWatch({
    control,
    name: 'type',
  });

  const { append, remove, fields, move } = useFieldArray({
    control,
    name: 'options',
  });

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;

    move(source.index, destination.index);
  };

  const portal = useMemo(() => document.getElementById('drag-drop-portal'), []);

  return (
    <div className="relative flex flex-col gap-3">
      <BBBTextInput
        label={
          <>
            Label text <span className="text-danger-main">*</span>
          </>
        }
        placeholder="Enter label text"
        hasMaxCharLabel
        maxChar={20}
        containerClassname="mb-0"
        isHookForm
        control={control}
        controlName="label"
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="collect-data-id">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className="flex flex-col gap-3 mb-3"
              {...provided.droppableProps}
            >
              {fields.map((option, idx) => (
                <Draggable key={option.id} draggableId={option.id} index={idx}>
                  {(providedDraggable, snapshot) => {
                    const content = (
                      <div
                        className={cn(
                          'flex items-center gap-2 !left-auto !top-auto',
                          snapshot.isDragging && 'opacity-50'
                        )}
                        style={{
                          top: snapshot.isDragging ? 0 : 0,
                        }}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <div {...providedDraggable.dragHandleProps}>
                          <DraggableIcon />
                        </div>
                        <BBBTextInput
                          containerClassname="flex-1 mb-0"
                          placeholder={`Option ${idx + 1}`}
                          hasMaxCharLabel
                          maxChar={20}
                          isHookForm
                          control={control}
                          controlName={`options.${idx}.value`}
                        />
                        <TrashWithTransition
                          size={16}
                          onClick={() => remove(idx)}
                        />
                      </div>
                    );

                    // Render content in portal if dragging, otherwise in normal DOM
                    return snapshot.isDragging && portal
                      ? createPortal(content, portal)
                      : content;
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <BBBButton
        text="Add option"
        variant="secondary"
        icon={<Plus />}
        iconPosition="right"
        size="sm"
        onClick={() =>
          append({
            value: '',
          })
        }
        className="w-fit ml-6"
      />
      {(type === 'multiple_choice' || type === 'radio_button') && (
        <Controller
          name="orientation"
          control={control}
          render={({ field }) => (
            <BBBSelect
              label="Orientation"
              options={orientationOptions}
              placeholder="Select orientation"
              optionLabel="label"
              optionValue="value"
              onValueChange={(val) => field.onChange(val)}
              value={field.value}
            />
          )}
        />
      )}
      <Controller
        name="mandatory"
        control={control}
        render={({ field }) => (
          <BBBCheckbox
            label="Required field"
            checked={field.value || false}
            onValueChange={(val) => field.onChange(val)}
          />
        )}
      />
    </div>
  );
}
