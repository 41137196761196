import api from '@/config/api';
import { ImportingStatus } from '@/types/bitAi/sorting/collections';

export default {
  getImportingStatus: (shopGid: string) =>
    api.bitAi.v1
      .get<ImportingStatus>(
        `/shopify/importing/status/${encodeURIComponent(shopGid)}`
      )
      .then((res) => res.data),
  updateImportCollections: (shopGid: string) =>
    api.bitAi.v1
      .post<{
        status: string;
      }>(`/shopify/importing/${encodeURIComponent(shopGid)}/start`)
      .then((res) => res.data),
  getScheduleImporting: (shopGid: string) =>
    api.bitAi.v1
      .get<ScheduleImport>(`/importing/schedule/${encodeURIComponent(shopGid)}`)
      .then((res) => res.data),
};

export type ScheduleImport = {
  last_update: string;
  next_scheduled_at: string;
};
