import { useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { Shop } from '@/types/shopify/Shop';

const useShop = () => {
  const { data: integrationData, status } =
    useShopifyIntegrationByApp('BITLINK');

  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery(
    ['bitlink-shopify-shop', activeCompany],
    async () => {
      const {
        data: { data },
      } = await api.bitLink.get<ApiResponse<Shop>>(
        `/shopify/shop/company/${activeCompany}`
      );
      return data;
    },
    {
      enabled: status === 'success' && !!integrationData,
    }
  );
};
export default useShop;
