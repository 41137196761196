import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import api from 'config/api';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import { useActiveCompany } from 'hooks/rtk/selector';
import { QuickReply } from 'types/bitChat/quickReply';
import { ApiResponse } from 'types/bitCRM/api';
import { toast } from 'utils/common/toast';

export const useQuickReply = (
  { page, search, limit }: { page: number; search: string; limit: number } = {
    page: 1,
    search: '',
    limit: 10,
  },
  opt?: Omit<
    UseQueryOptions<
      ApiResponse<QuickReply>,
      unknown,
      ApiResponse<QuickReply>,
      (string | number | { page: number; search: string; limit: number })[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['quick-reply', activeCompany, { page, search, limit }],
    () =>
      api.bitChat
        .get<ApiResponse<QuickReply>>(`/quick-reply/${activeCompany}`, {
          params: {
            search,
            page,
            size: limit,
          },
        })
        .then((res) => res.data),
    { keepPreviousData: true, ...(opt || {}) }
  );
};

export const useInfiniteQuickReply = (
  param: { search: string; limit: number } = {
    search: '',
    limit: 10,
  }
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    ['infinite-quick-reply', activeCompany, { param }],
    ({ pageParam = 0 }) =>
      api.bitChat
        .get<ApiResponse<QuickReply>>(`/quick-reply/${activeCompany}`, {
          params: {
            search: param.search,
            page: pageParam,
            size: param.limit,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage, page) =>
        !lastPage.last ? lastPage.number + 1 : undefined,
      enabled: !!param.search.length && param.search.startsWith('/'),
    }
  );
};

export const useUpsertQuickReply = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    (payload: {
      id?: string;
      command: string;
      message: string;
      mediaUrl?: string;
      mimeType?: string;
    }) =>
      api.bitChat
        .post(`/quick-reply`, {
          ...payload,
          companyId: activeCompany,
        })
        .then((res) => res.data),
    {
      onSuccess: (_, _var) => {
        toast.success(
          `Succesfully ${_var.id !== undefined ? 'edit' : 'create'} quick reply`
        );
        client.invalidateQueries(['quick-reply', activeCompany]);
      },
    }
  );
};

const mapActionToTitle = {
  delete: 'Delete multiple quick reply',
};

const mapDescriptionToTitle = {
  delete:
    'Are you sure to delete selected quick reply? Once you deleted the action cannot be undone',
};

export const useQuickReplyBulkAction = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  const { mutate: bulkAction } = useMutation(
    async ({
      action,
      selectedRows,
    }: {
      action: 'delete';
      selectedRows: string[];
    }) =>
      api.bitChat.post(`/quick-reply/bulk_action`, {
        companyId: activeCompany,
        data: selectedRows,
        action,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your quick reply has been deleted`);
        client.invalidateQueries(['quick-reply']);
      },
    }
  );

  return (
    action: 'delete',
    selectedRows: string[],
    {
      onSuccess,
    }: {
      onSuccess?: () => void;
    } = {}
  ) => {
    toggleConfirmation({
      title: mapActionToTitle[action],
      description: mapDescriptionToTitle[action],
      onAccept: (hide) => {
        bulkAction(
          {
            action,
            selectedRows,
          },
          {
            onSuccess: () => {
              hide();
              onSuccess?.();
            },
          }
        );
      },
      deleteModal: true,
      submitText: 'Delete',
      cancelText: 'Cancel',
    });
  };
};

export const useDeleteQuickReply = () => {
  const activeCompany = useActiveCompany();
  const toggleConfirmation = useConfirmationModal();

  const client = useQueryClient();
  const deleteQuickReplyMutation = useMutation(
    (payload: { id: string }) =>
      api.bitChat
        .delete(`/quick-reply/${activeCompany}/${payload.id}`)
        .then((res) => res.data),
    {
      onSuccess: (_, _var) => {
        toast.success(`Succesfully delete quick reply`);
        client.invalidateQueries(['quick-reply', activeCompany]);
      },
    }
  );

  function deleteQuickReply(
    withConfirmation: true
  ): (params: string, options?: { onSuccess?: () => void }) => void;
  function deleteQuickReply(
    withConfirmation: true | false
  ):
    | ((params: string, options?: { onSuccess?: () => void }) => void)
    | UseMutationResult<
        AxiosResponse<unknown>,
        unknown,
        { id: string },
        unknown
      > {
    return withConfirmation
      ? (id, options) =>
          toggleConfirmation({
            title: 'Delete quick reply?',
            description: `Once deleted you're not able to recover it`,
            onAccept: (hide) =>
              deleteQuickReplyMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: () => {
                    hide();
                    options?.onSuccess?.();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteQuickReplyMutation;
  }

  return deleteQuickReply;
};
