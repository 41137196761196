import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetCampaignParams } from '@/api/services/bitCRM/v2/campaign';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { ApiResponse } from '@/types/bitCRM/api';
import { Campaign } from '@/types/bitCRM/campaign';

const useCampaigns = (params: GetCampaignParams) => {
  const activeCompany = useActiveCompany();

  return useQuery<ApiResponse<Campaign>>(
    ['bitcrm-campaigns', activeCompany, params],
    async () => services.bitCRM.v2.campaign.getCampaign(activeCompany, params),
    {
      keepPreviousData: true,
    }
  );
};

export default useCampaigns;
