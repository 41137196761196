export default function FacebookIcon3({
  size = 24,
  color = '#262627',
}: {
  size?: string | number;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 25"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.333 5.6h-3.841c-.694 0-1.257.533-1.257 1.19v4.177h5.098l-.746 4.598h-4.351V24H5.629v-8.435H1v-4.598h4.569l.06-4.364-.009-.793a4.543 4.543 0 01.368-1.837 4.75 4.75 0 011.087-1.561A5.051 5.051 0 018.71 1.367 5.27 5.27 0 0110.647 1h5.686v4.6z"
      ></path>
    </svg>
  );
}
