import { useEffect, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

const BBBRedirectWithTimer = ({
  redirectUrl,
  timeoutInSeconds,
  className,
}: {
  redirectUrl: string;
  timeoutInSeconds: number;
  className?: string;
}): JSX.Element => {
  const [seconds, setSeconds] = useState(timeoutInSeconds);

  useEffect(() => {
    const countdown = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds === 0) {
      clearInterval(countdown);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      window.location.replace(redirectUrl);
    }
  }, [seconds, redirectUrl]);

  return (
    <div>
      <p className={cx('text-base text-neutral-50', className)}>
        Redirecting in {seconds} seconds...
      </p>
    </div>
  );
};

export default BBBRedirectWithTimer;
