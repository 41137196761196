import React, { useEffect } from 'react';
import { Info } from 'react-feather';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { twMerge as cx } from 'tailwind-merge';
import { BBBCustomTooltipChart } from '../BBBCustomTooltipChart';

import {
  BBBCard,
  BBBTooltip,
  BBBTooltipProps,
  IBBBCard,
} from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { Paths } from '@/types/utils/nestedObjectPath';

export type BBBBarChartProps<T extends { [k: string]: unknown }> = {
  title?: string | React.ReactNode;
  containerClassName?: string;
  heightContainer?: number;
  data: T[] | undefined;
  tooltip?: BBBTooltipProps['content'];
  xAxisProps: XAxisProps;
  yAxisProps?: YAxisProps;
  lineDataKey: Paths<T>;
  isHasBrush?: boolean;
  customHeaders?: React.ReactNode;
  layout?: CategoricalChartProps['layout'];
} & IBBBCard;

const BBBBarChart = <
  T extends {
    [k: string]: unknown;
  }
>({
  title,
  containerClassName,
  data: _data,
  xAxisProps,
  tooltip,
  yAxisProps,
  lineDataKey,
  heightContainer = 250,
  isHasBrush = false,
  customHeaders,
  layout = 'horizontal',
  ...props
}: BBBBarChartProps<T>) => {
  const isMobile = useResponsive('sm');

  const dataLength = _data?.length ?? 0;
  const data = dataLength > 14 ? _data?.slice(0, 14) : _data;

  useEffect(() => {
    const sliderBrush = document.getElementsByClassName(
      'recharts-brush-slide'
    )[0];

    if (sliderBrush) {
      sliderBrush.setAttribute('fill', '#9e9e9e');
    }
  }, []);

  const Title =
    customHeaders ||
    (tooltip ? (
      <div className="flex gap-1 items-center w-fit">
        <p className="text-base font-normal whitespace-nowrap">{title}</p>
        <BBBTooltip show={!!tooltip} content={tooltip} className="bottom-[95%]">
          <Info size={14} color="#9E9E9E" />
        </BBBTooltip>
      </div>
    ) : (
      <p className="text-base font-normal whitespace-nowrap">{title}</p>
    ));

  const commonCardProps = {
    containerTitleClassName: layout === 'vertical' ? 'mb-0' : 'mb-4',
    className: cx(containerClassName),
    title: Title,
  };

  if (isMobile) return null;

  if (!data?.length) {
    return (
      <BBBCard {...commonCardProps}>
        <div className="flex items-center justify-center flex-col h-44">
          <div className="font-semibold">No sessions in this date range</div>
          <div className="text-sub-heading">
            Try selecting a different date range
          </div>
        </div>
      </BBBCard>
    );
  }

  return (
    <BBBCard {...commonCardProps} {...props}>
      <ResponsiveContainer width="100%" height={heightContainer}>
        <BarChart
          layout={layout}
          width={500}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="8"
            syncWithTicks={dataLength ? true : false}
          />
          <XAxis
            axisLine={false}
            tickMargin={10}
            interval={0}
            className="text-sm text-primary-main bg-white"
            type={layout === 'horizontal' ? 'category' : 'number'}
            domain={layout === 'vertical' ? [0, 100] : [0, 'auto']}
            tickCount={layout === 'vertical' ? 11 : undefined}
            {...xAxisProps}
          />
          <YAxis
            axisLine={false}
            id="rechart-yAxis"
            tickMargin={10}
            className="text-sm text-primary-main"
            type={layout === 'horizontal' ? 'number' : 'category'}
            domain={data?.length ? [0, 'dataMax'] : []}
            interval={0}
            {...yAxisProps}
          />
          {dataLength >= 7 && isHasBrush && (
            <Brush
              dataKey={xAxisProps.dataKey as string}
              height={8}
              stroke="#fff"
              fill="#fff"
            />
          )}
          <Bar
            dataKey={lineDataKey}
            barSize={layout === 'vertical' ? 14 : undefined}
            stackId="a"
            fill="#FED5BF"
            activeBar={<Rectangle fill="#D36C34" />}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor={layout === 'horizontal' ? 'pointer' : 'default'}
              />
            ))}
          </Bar>
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<BBBCustomTooltipChart />}
          />
        </BarChart>
      </ResponsiveContainer>
    </BBBCard>
  );
};

export default BBBBarChart;
