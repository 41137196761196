import api from '@/config/api';
import { SocialLoginProvider } from '@/types/bitLogin/v2';

export default {
  decryptLoginData: (body: { code: string }) =>
    api.bitLogin
      .post<{
        data: string;
      }>(`/login/decrypt`, body)
      .then(
        (res) =>
          JSON.parse(res.data.data) as Pick<
            ProxyCallbackParams,
            | 'email'
            | 'firstName'
            | 'lastName'
            | 'city'
            | 'country_code'
            | 'country_name'
            | 'region'
            | 'provider'
          >
      ),
  signupCallback: (payload: ProxyCallbackParams) =>
    api.bitLogin
      .post<{ data: string }>(`/login`, payload)
      .then((res) => res.data.data),
};

export type ProxyCallbackParams = {
  shop: string;
  email: string;
  phoneNumber?: string;
  gender?: 'male' | 'female' | 'preferNotToSay';
  dateOfBirth?: string;
  address?: string;
  firstName?: string;
  lastName?: string;
  city?: string;
  region?: string;
  country_name?: string;
  country_code?: string;
  address2?: string;
  province?: string;
  zip?: string;
  provider: SocialLoginProvider;
  metadata?: {
    key: string;
    label: string;
    value: string;
  }[];
};
