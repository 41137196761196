import { PricingFeature, PricingPlan } from '@/api/services/pricing';

// bitApp special pricing case
export const formatFreeWithUsagePlan = (plan: PricingPlan): PricingPlan =>
  plan === 'freeWithUsageCharge' ? 'free' : plan;

function _mapInfinityPricingFeature(pricingFeature: PricingFeature) {
  return {
    ...pricingFeature,
    pricingFeatureDetails: pricingFeature.pricingFeatureDetails.map(
      (detail) => ({
        ...detail,
        limit: detail.limit === 0 ? Infinity : detail.limit,
      })
    ),
  };
}

export const mapInfinityPricingFeature = _mapInfinityPricingFeature;
