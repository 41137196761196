import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import BBBLogoIcon from '@/assets/icons/BBBLogoIcon';
import CircleX from '@/assets/icons/CircleX';
import {
  BBBButton,
  BBBCard,
  BBBTelInput,
  BBBTelInputValue,
} from '@/components/ui';
import {
  useRequestOTP,
  useVerifyOTP,
} from '@/hooks/bitLogin/login/whatsapp/whatsapp';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import { phoneValidator } from '@/utils/common/phone';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

export default function WhatsappCloudLogin() {
  return (
    <BBBCard className="my-auto min-h-[23.75rem] w-full max-w-md flex flex-col justify-center !p-10 shadow-md">
      <WhatsappLoginForm />
    </BBBCard>
  );
}

const loginFormSchema = yup.object().shape({
  phone: phoneValidator(),
});

type WhatsAppLoginForm = {
  phone: BBBTelInputValue | null;
};

function WhatsappLoginForm() {
  const phoneDefaultValue = useDefaultCountryCode();
  const { mutate: request, isLoading: isLoadingRequest } = useRequestOTP();
  const [step, setStep] = useState<'Login' | 'Verify' | 'Success' | 'Failed'>(
    'Login'
  );
  const [submittedPhone, setSubmittedPhone] = useState<string | null>(null);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
  const [otpError, setOtpError] = useState<string | null>(null);
  const [countdown, setCountdown] = useState(60);
  const query = useQuerySearchParams();
  const domain = query.get('domain');

  if (!domain) throw new Error('Invalid domain');

  const handleSuccess = () => {
    setStep('Success');
  };
  const handleFailed = () => {
    setStep('Failed');
  };
  const { mutate: verify, isLoading: isLoadingVerify } = useVerifyOTP(
    handleSuccess,
    handleFailed,
    (error) => {
      setOtpError(error);
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useCustomForm<WhatsAppLoginForm>({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      phone: phoneDefaultValue || null,
    },
  });

  useEffect(() => {
    setValue('phone', phoneDefaultValue || null);
  }, [phoneDefaultValue, setValue]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (step === 'Verify' && countdown > 0) {
      timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [step, countdown]);

  const onSubmit = (data: WhatsAppLoginForm) => {
    const fullPhoneNumber = `${data.phone?.countryCode}${data.phone?.phoneNumber}`;
    setSubmittedPhone(fullPhoneNumber);
    request(
      { phoneNumber: fullPhoneNumber, domain },
      {
        onSuccess: () => {
          setStep('Verify');
          setCountdown(120);
        },
      }
    );
  };

  const handleResend = () => {
    if (submittedPhone) {
      setCountdown(120);
      request({ phoneNumber: submittedPhone, domain });
    }
  };

  const handleVerifyOtp = (data: {
    phoneNumber: string;
    domain: string;
    code: string;
  }) => {
    if (data.code.length !== 6) {
      setOtpError('Please enter a valid 6 digit OTP!');
      return;
    }
    verify({
      phoneNumber: data.phoneNumber,
      domain: data.domain,
      code: data.code,
    });
  };

  return (
    <>
      {step === 'Success' ? (
        <div className="text-center">
          <p className="text-3xl text-neutral-60 font-semibold">
            Login success!
          </p>
          <div className="my-20 w-fit mx-auto">
            <FaCheckCircle fill="#25D366" stroke="#FFFF" size={140} />
          </div>
          <p className="text-lg text-neutral-50">Redirecting...</p>
        </div>
      ) : step === 'Failed' ? (
        <div className="text-center">
          <p className="text-3xl text-neutral-60 font-semibold">
            Login failed!
          </p>
          <div className="my-20 w-fit mx-auto">
            <CircleX height={140} width={140} fill="#FF6060" stroke="#FFFFFF" />
          </div>
          <p className="text-lg text-neutral-50">
            WhatsApp login is unsuccessful. Please try again and remember to
            check your WhatsApp to complete the login process.
          </p>
        </div>
      ) : (
        <>
          <div className="text-center">
            {step === 'Login' ? (
              <>
                <div className="text-2xl font-semibold">Login with OTP</div>
                <div className="mt-2 text-neutral-40">
                  Enter your login details
                </div>
              </>
            ) : (
              <>
                <div className="text-2xl font-semibold">Enter OTP</div>
                <div className="mt-2 text-neutral-40">
                  OTP sent to WhatsApp number <br /> +{submittedPhone}
                </div>
              </>
            )}
          </div>
          <div className="w-full my-10">
            {step === 'Login' ? (
              <BBBTelInput
                isHookForm
                label="WhatsApp phone number"
                containerClassname="font-semibold"
                placeholder="123456789"
                control={control}
                controlName="phone"
                error={errors.phone?.message}
              />
            ) : (
              <OTPInput otp={otp} setOtp={setOtp} error={otpError} />
            )}
          </div>
          <BBBButton
            width="full"
            text={step === 'Login' ? 'Request OTP' : 'Verify OTP'}
            onClick={
              step === 'Login'
                ? handleSubmit(onSubmit)
                : () =>
                    handleVerifyOtp({
                      phoneNumber: submittedPhone || '',
                      domain,
                      code: otp.join(''),
                    })
            }
            loadingState={step === 'Login' ? isLoadingRequest : isLoadingVerify}
            disabled={step === 'Login' ? isLoadingRequest : isLoadingVerify}
          />
          {step === 'Verify' && (
            <div className="w-full text-center mt-5">
              <div className="mt-2 text-neutral-60">
                {`Didn't receive OTP? `}
                {countdown > 0 ? (
                  `Resend code in ${Math.floor(countdown / 60)}:${String(
                    countdown % 60
                  ).padStart(2, '0')}`
                ) : (
                  <span
                    className="text-blue-500 cursor-pointer font-medium"
                    onClick={handleResend}
                  >
                    RESEND
                  </span>
                )}
              </div>
              <div
                className="text-blue-500 cursor-pointer"
                onClick={() => setStep('Login')}
              >
                Change phone number
              </div>
            </div>
          )}
          <div
            className={cn(
              'py-2 rounded-bl-xl rounded-br-xl flex justify-center items-center gap-1 mt-5'
            )}
          >
            <div className={cn('text-sm')}>Powered by</div>
            <BBBLogoIcon className={cn('h-7 w-[3.375rem]')} />
          </div>
        </>
      )}
    </>
  );
}

function OTPInput({
  otp,
  setOtp,
  error,
}: {
  otp: string[];
  setOtp: (otp: string[]) => void;
  error?: string | null;
}) {
  const handleChange = (value: string, index: number) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (value && nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  };

  const handleBackspace = (index: number) => {
    const prevInput = document.getElementById(`otp-${index - 1}`);
    if (!otp[index] && prevInput) {
      (prevInput as HTMLInputElement).focus();
    }
  };

  return (
    <>
      <div className="text-left mb-1 font-semibold">OTP</div>
      <div className="flex justify-center items-center space-x-2">
        {otp.map((data, index) => (
          <input
            key={index}
            id={`otp-${index}`}
            type="text"
            maxLength={1}
            value={data}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') handleBackspace(index);
            }}
            className="w-14 h-14 text-center text-lg border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        ))}
      </div>
      {error && <div className="text-danger-main mt-2 text-md">{error}</div>}
    </>
  );
}
