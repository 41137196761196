import { useCallback, useEffect } from 'react';
import { socketBitApp } from 'socket';

import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useBlastCampaignRealtime = () => {
  const activeCompany = useActiveCompany();

  const { toggle } = useConfirmationBanner();

  const onUpdate = useCallback(
    ({
      campaignName,
      countFail,
      countSuccess,
      countTotal,
      campaignId,
    }: {
      campaignId: number;
      campaignName: string;
      countFail: number;
      countSuccess: number;
      countTotal: number;
    }) => {
      toggle(`blast-campaign-${campaignId}`, {
        show: countSuccess + countFail < countTotal,
        text:
          `Blasting ${campaignName} on progress... ${countSuccess}/${countTotal} success` +
          (countFail > 0 ? ` ${countFail}/${countTotal} fail` : ''),
        variant: 'loading',
      });
    },
    [toggle]
  );

  useEffect(() => {
    socketBitApp.emit('join', {
      companyId: activeCompany,
    });
  }, [activeCompany]);

  useEffect(() => {
    socketBitApp.on('progressUpdate', onUpdate);

    return () => {
      socketBitApp.off('progressUpdate', onUpdate);
    };
  }, [onUpdate]);
};

export default useBlastCampaignRealtime;
