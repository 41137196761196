import { PricingFeatureNames } from 'api/services/pricing';
import { _PricingInfo } from './PricingInfo';

import CircleCheckIcon from '@/assets/icons/CircleCheckIcon';
import CircleXIcon from '@/assets/icons/CircleXIcon';
import { BBBPercentageBar } from '@/components/ui';
import { Info } from '@/components/ui/BBBPercentageBar/BBBPercentageBar';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { usePricingFeatures } from '@/hooks/pricing/v2/pricing';

const mapFeatureToTip: Partial<Record<PricingFeatureNames, string>> = {
  automation_messages:
    'Automation message generates automatically every time a scenario happened. When limit is reached, automation will stop generating. Limit will refresh every month.',
  campaign_messages:
    'When limit is reached, you will not be able to send any campaign broadcast. Limit will refresh every month.',
};

function BitCRMStatus() {
  const { data: pricingData } = usePricingByApp('BITCRM');
  const { data: pricingFeatures } = usePricingFeatures({ app: 'BITCRM' });

  const { data: whatsappBusinessIntegration } =
    useWhatsappBusinessIntegration();
  const { data: whatsappCloudIntegration } = useWhatsappCloudIntegration();

  if (!pricingData || !pricingFeatures) return null;

  return (
    <_PricingInfo
      app="BITCRM"
      subscriptionPlan={pricingData.pricingModule.label || undefined}
    >
      <Info
        type="one-time"
        description="Use your own number"
        className="mb-4"
        value={
          pricingData.pricingName === 'free' ? (
            <CircleXIcon />
          ) : (
            <CircleCheckIcon />
          )
        }
      />
      {pricingFeatures.map((feature) => (
        <BBBPercentageBar
          usage={feature.usage}
          limit={
            feature.pricingFeature.pricingFeatureDetails.find(
              (detail) => detail.pricingName === pricingData.pricingName
            )?.limit ?? 0
          }
          description={feature.pricingFeature.label}
          className="mb-4"
          key={feature.featureName}
          hasTip={!!mapFeatureToTip[feature.featureName]}
          tip={mapFeatureToTip[feature.featureName]}
          mode={feature.pricingFeature.mode}
        />
      ))}
      <div className="text-xs mb-4 text-[#9E9E9E]">
        WhatsApp{' '}
        {whatsappBusinessIntegration?.status === 'CONNECTED' ||
        whatsappCloudIntegration?.status === 'CONNECTED'
          ? 'connected'
          : 'not connected'}
      </div>
    </_PricingInfo>
  );
}

export default BitCRMStatus;
