import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import {
  GetAiTagsParams,
  GetAiTagsUserDefinedParams,
} from '@/api/services/whatsApp/analytics';
import { toast } from '@/utils/common/toast';

const ANALYTICS_QUERY_KEY = 'whatsapp-analytics' as const;

export const useAiTags = (
  params: GetAiTagsParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${ANALYTICS_QUERY_KEY}-ai-tags`, activeCompany, params],
    () => services.whatsApp.analytics.getAiTags(activeCompany, params),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useAiTagsUserDefined = (
  type: string,
  params?: GetAiTagsUserDefinedParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      `${ANALYTICS_QUERY_KEY}-ai-tags-user-defined`,
      activeCompany,
      type,
      params,
    ],
    () =>
      services.whatsApp.analytics.getAiTagsUserDefined(
        activeCompany,
        type,
        params
      ),
    {
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
    }
  );
};

export const useDownloadAiTags = (
  params: Parameters<typeof services.whatsApp.analytics.exportTagsToCsv>[1]
) => {
  const activeCompany = useActiveCompany();
  return useMutation(
    async () =>
      services.whatsApp.analytics.exportTagsToCsv(activeCompany, params),
    {
      onSuccess: (data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `ai-tags.csv`;
        a.click();
        toast.success('Your ai tags has been exported');
      },
    }
  );
};

export const useAiTagsSuggested = (
  params: Parameters<typeof services.whatsApp.analytics.getAiTagsSuggested>[1],
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    [`${ANALYTICS_QUERY_KEY}-ai-tags-suggested`, activeCompany, params],
    ({ pageParam: cursor }) =>
      services.whatsApp.analytics.getAiTagsSuggested(activeCompany, {
        ...params,
        cursor,
      }),
    {
      getNextPageParam: (data) => {
        return data?.meta?.hasNextPage ? data?.meta?.endCursor : undefined;
      },
      enabled: typeof options?.enabled !== 'undefined' ? options.enabled : true,
      ...options,
    }
  );
};
