import { Controller, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthCard from '@/components/Auth/Card';
import { BBBButton, BBBSelect, BBBTextInput } from '@/components/ui';
import { CountryOptions } from '@/constants/systemLogin/countries';
import industries from '@/constants/systemLogin/industries';
import useCustomForm from '@/hooks/common/useCustomForm';

const schema = yup.object().shape({
  company_name: yup.string().required('Company name is required'),
  industries: yup.mixed().required('Industry is required'),
  country: yup.mixed().required('Country is required'),
});

export type CompanyCreationFormSchema = {
  industries?: {
    label: string;
    value: string;
  };
  country?: {
    label: string;
    value: string;
  };
  company_name: string;
};

type CompanyCreationCardBackProps =
  | {
      showBack?: false;
      onBack?: never;
    }
  | {
      showBack: true;
      onBack?: () => void;
    };

type CompanyCreationCardProps = {
  onSubmit: (data: CompanyCreationFormSchema) => void;
  loadingSubmit?: boolean;
} & CompanyCreationCardBackProps;

function CompanyCreationCard({
  onBack,
  onSubmit,
  showBack,
  loadingSubmit,
}: CompanyCreationCardProps) {
  const { handleSubmit, formState, control } =
    useCustomForm<CompanyCreationFormSchema>({
      resolver: yupResolver(schema),
      defaultValues: {
        company_name: '',
        industries: undefined,
        country: undefined,
      },
    });

  return (
    <AuthCard
      title="Tell us a bit about your company"
      description="So we could help you grow!"
    >
      <div className="w-full flex flex-col gap-6">
        <BBBTextInput
          isHookForm
          containerClassname="mb-0"
          control={control}
          controlName="company_name"
          placeholder="What is your company name?"
          label="Company name"
          error={formState.errors.company_name?.message}
        />
        <div className="">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <BBBSelect
                value={field.value}
                options={CountryOptions}
                placeholder="What country are you from?"
                optionLabel="label"
                label="Country"
                optionValue="value"
                onValueChange={field.onChange}
                isSearchable
              />
            )}
          />
          <div className="text-danger-main">
            {(formState.errors.country as FieldError)?.message}
          </div>
        </div>
        <div className="">
          <Controller
            name="industries"
            control={control}
            render={({ field }) => (
              <BBBSelect
                value={field.value}
                options={industries}
                optionLabel="label"
                optionValue="value"
                onValueChange={field.onChange}
                isSearchable
                label="Industry"
                placeholder="What industry are you in?"
              />
            )}
          />
          <div className="text-danger-main">
            {(formState.errors.industries as FieldError)?.message}
          </div>
        </div>
        <BBBButton
          text="Continue"
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          variant="primary"
          width="full"
          disabled={loadingSubmit}
        />
        {showBack && (
          <BBBButton text="Back" onClick={onBack} variant="secondary" />
        )}
      </div>
    </AuthCard>
  );
}

export default CompanyCreationCard;
