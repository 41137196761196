import { useState } from 'react';
import { useVisitedSites } from 'hooks/customers/customer/visited-sites';
import { twMerge as cx } from 'tailwind-merge';
import { VisitedSite } from 'types/customers';

import {
  BBBSelect,
  MultipleOnValueChangeCallback,
  SingleOnValueChangeCallback,
} from '@/components/ui';

type Props = (
  | {
      value: VisitedSite | null;
      onValueChange: SingleOnValueChangeCallback<VisitedSite>;
      isMulti?: false;
    }
  | {
      value: VisitedSite[] | null;
      onValueChange: MultipleOnValueChangeCallback<VisitedSite>;
      isMulti: true;
    }
) & {
  containerClassName?: string;
  placeholder?: string;
  label?: string;
  isSearchable?: boolean;
  error?: string;
};

export default function VisitedSitesOptions({
  containerClassName,
  placeholder = 'Search page',
  isSearchable,
  ...props
}: Props) {
  const [search, setSearch] = useState<string>();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: loadingTags,
  } = useVisitedSites({ search });

  const flatennedData = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <BBBSelect
      options={flatennedData}
      optionLabel="path"
      optionValue="path"
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      placeholder={placeholder}
      containerClassName={cx(
        'md:min-w-[128px] md:max-w-[256px]',
        containerClassName
      )}
      loading={loadingTags}
      {...(isSearchable
        ? {
            isSearchable: true,
            search,
            onChangeSearch: setSearch,
          }
        : {
            isSearchable: false,
          })}
      {...props}
    />
  );
}
