import { useInfiniteQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/rtk/store';
import useChatListsQueryKey from './useChatListsQueryKey';

import services from '@/api/services';

const useChatLists = () => {
  const hasConnectedChannels = useAppSelector(
    (s) => s.bitCRM.hasConnectedChannels
  );
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);

  const queryKey = useChatListsQueryKey();

  return useInfiniteQuery(
    queryKey,
    ({ pageParam: cursor }) =>
      services.whatsApp.v3.chat.getChats({
        ...queryKey[1],
        cursor,
      }),
    {
      getNextPageParam: (data) => {
        return data.meta.hasNextPage ? data.meta.endCursor : undefined;
      },
      enabled: !!apiKey && !!hasConnectedChannels,
    }
  );
};

export default useChatLists;
