import React, { Ref } from 'react';

import { BBBSelect, DropdownTypes } from '@/components/ui';
import useHistory from '@/hooks/common/useHistory';

const QuickResponseOptions = <
  T extends {
    [k: string]: any;
  }
>(
  { ref: omittedRef, ...props }: DropdownTypes<T>,
  ref: Ref<HTMLInputElement>
) => {
  const history = useHistory();

  return (
    <BBBSelect
      {...props}
      ref={ref}
      withCreateRedirectOption
      onClickCreateRedirect={() => {
        history.push(`/bitchat/flows`);
      }}
      createRedirectLabel="Create new chatbot"
      persistCreateRedirectOption
    />
  );
};

export default React.forwardRef(QuickResponseOptions) as <
  T extends {
    [k: string]: any;
  }
>(
  p: DropdownTypes<T> & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement;
