import { twMerge as cx } from 'tailwind-merge';

import CopyIcon from '@/assets/icons/CopyIcon';
import { toast } from '@/utils/common/toast';

type InfoListProps = {
  label: string | React.ReactNode;
  children: React.ReactNode;
  isChildrenNewLine?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  withoutDivider?: boolean;
  autoWidth?: boolean;
  withCopy?: boolean;
  withGap?: boolean;
};

export default function InfoList({
  label,
  children,
  isChildrenNewLine,
  labelClassName,
  containerClassName,
  withoutDivider,
  autoWidth,
  withCopy,
  withGap,
}: InfoListProps) {
  return (
    <div className={cx(containerClassName, withGap && 'mb-4')}>
      <div
        className={cx(
          'flex gap-2 items-center',
          isChildrenNewLine && 'mb-2',
          withCopy && 'cursor-pointer'
        )}
        onClick={() => {
          if (withCopy && typeof children === 'string') {
            navigator.clipboard.writeText(children);
            toast.success('Copied');
          }
        }}
      >
        <div
          className={cx(
            'flex-none w-32 xl:w-18 2xl:w-32 text-sm lg:text-base text-neutral-50 flex justify-between',
            autoWidth && 'w-auto xl:w-auto 2xl:w-auto',
            labelClassName
          )}
        >
          <div className="whitespace-nowrap">{label}</div>
          {!withoutDivider && <div>:</div>}
        </div>
        {!isChildrenNewLine && (
          <div className="grow flex items-center gap-1">
            {children} {withCopy && <CopyIcon />}
          </div>
        )}
      </div>
      {isChildrenNewLine && children}
    </div>
  );
}
