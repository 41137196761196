import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

const useSocialAppearance = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    ['bitlogin-appearance', data?.domain],
    () => services.bitLogin.appearance.getSocialAppearance(data?.domain),
    {
      enabled: status === 'success' && !!data,
    }
  );
};

export default useSocialAppearance;
