import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { sidebarDatav2 } from '@/constants/layouts/sidebarData';

const useSidebarMeta = () => {
  const { pathname } = useLocation();

  const parentPath = useMemo(
    () =>
      pathname.split('/').slice(0, pathname.split('/').length === 2 ? 1 : 2),
    [pathname]
  );

  const app = pathname.split('/').slice(0, 2).pop()!;

  const mapActiveSidebar = useMemo(
    () =>
      sidebarDatav2.filter((data) => data.parentPath === parentPath.join('/')),
    [parentPath]
  );

  return {
    app,
    mapActiveSidebar,
    parentPath,
  };
};

export default useSidebarMeta;
