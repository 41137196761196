export default function AlertIcon3({ color = '#BABABA' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M7 1.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM.583 7a6.417 6.417 0 1112.834 0A6.417 6.417 0 01.583 7z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M7 3.5c.322 0 .583.261.583.583v3.5a.583.583 0 11-1.166 0v-3.5c0-.322.26-.583.583-.583zm0 5.542c.322 0 .583.26.583.583v.292a.583.583 0 01-1.166 0v-.292c0-.322.26-.583.583-.583z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
