import { ApiResponse, CursoredResponse } from '@/api/types';
import api from '@/config/api';
import {
  AppType,
  Authority,
  AuthorityGroup,
  Company,
  OnboardingModule,
} from '@/types/systemLogin';
import {
  OnboardingAssociation,
  UserCompanyWithAssociation,
  UserWithAssociation,
} from '@/types/systemLogin/association';
import { sortAppOrder } from '@/utils/app';

export default {
  getCompanyUsers: (
    companyId: number,
    params?: {
      search?: string;
      role?: string;
      excludedUsers?: number[];
      after?: string;
      before?: string;
      status?: string;
      limit?: number;
      order?: string;
    }
  ) =>
    api.systemLogin
      .get<ApiResponse<CursoredResponse<UserCompanyWithAssociation[]>>>(
        `/company/${companyId}/users`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getCompanyUserByUserAndCompanyId: ({
    companyId,
    userId,
  }: {
    companyId: number;
    userId: number;
  }) =>
    api.systemLogin
      .get<ApiResponse<UserCompanyWithAssociation | null>>(
        `/company/${companyId}/users/${userId}`
      )
      .then((res) => res.data.data),
  getAuthenticatedUserCompanies: (params?: {
    after?: string;
    before?: string;
    search?: string;
    role?: string;
    limit?: number;
  }) =>
    api.systemLogin
      .get<ApiResponse<CursoredResponse<UserCompanyWithAssociation[]>>>(
        `/auth/companies`,
        {
          params: {
            after: params?.after,
            before: params?.before,
            search: params?.search,
            role: params?.role,
            limit: params?.limit,
          },
        }
      )
      .then((res) => res.data.data),
  getUserCompaniesByUserId: (
    userId: number,
    params: {
      after?: string;
      before?: string;
      search?: string;
      limit?: number;
      order?: string;
    }
  ) =>
    api.systemLogin
      .get<ApiResponse<CursoredResponse<UserCompanyWithAssociation[]>>>(
        `/user/${userId}/companies`,
        {
          params,
        }
      )
      .then((res) => res.data.data),
  getUserCompanyDetail: (userId?: string, companyId?: number) =>
    api.systemLogin
      .get<ApiResponse<UserCompanyWithAssociation>>(
        `/user/${userId}/company/${companyId}`
      )
      .then((res) => res.data.data),
  getAuthenticatedUser: () =>
    api.systemLogin
      .get<ApiResponse<UserWithAssociation>>(`/auth/user`)
      .then((res) => res.data.data),
  getAuthenticatedCompany: (companyId: number) =>
    api.systemLogin
      .get<ApiResponse<Company>>(`/company/${companyId}`)
      .then((res) => res.data.data),
  getAuthorities: () =>
    api.systemLogin
      .get<ApiResponse<(AuthorityGroup & { authorities: Authority[] })[]>>(
        `/authority`
      )
      .then((res) =>
        res.data.data.sort((a, b) => {
          const groupA = a.group;
          const groupB = b.group;
          return sortAppOrder(groupA, groupB);
        })
      ),
  deleteUserCompany: (userId?: number, companyId?: number) =>
    api.systemLogin
      .delete(`/company/${companyId}/user/${userId}`)
      .then((res) => res.data),
  subscribeBeta: (companyId: number) =>
    api.systemLogin
      .post(`/company/${companyId}/subscribe-beta/request`)
      .then((res) => res.data),
  getOnboarding: (companyId: number, app: AppType) =>
    api.systemLogin
      .get<ApiResponse<OnboardingAssociation>>(
        `/onboarding/${companyId}/${app}`
      )
      .then((res) => res.data.data),
  updateOnboarding: (
    companyId: number,
    app: AppType,
    payload: UpdateOnboardingParams
  ) =>
    api.systemLogin
      .post(`/onboarding/${companyId}/${app}`, payload)
      .then((res) => res.data),
};

export type UpdateOnboardingParams =
  | { module: OnboardingModule; skipped: boolean }
  | { dismissed: boolean };

export type GetUserCompaniesByUserIdPayload = {
  data: UserCompanyWithAssociation[];
  meta: {
    startCursor: string;
    endCursor: string;
    total: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
};

export type GetUserCompaniesByUserIdParam = {
  after?: string;
  before?: string;
  search?: string;
  limit?: number;
  order?: string;
};

export type GetCompanyUsersParam =
  | {
      search?: string;
      role?: string;
      excludedUsers?: number[];
      after?: string;
      before?: string;
      status?: string;
      limit?: number;
      order?: string;
    }
  | undefined;

export type GetCompanyUsersPayload = {
  data: UserCompanyWithAssociation[];
  meta: {
    startCursor: string;
    endCursor: string;
    total: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
};

export type GetCompanyUserByUserAndCompanyIdParam = {
  companyId: number;
  userId: number;
};

export type GetCompanyUserByUserAndCompanyIdPayload =
  UserCompanyWithAssociation | null;
