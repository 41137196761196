import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateOnboardingParams } from 'api/services/systemLogin';
import { AppType } from 'types/systemLogin';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

export const useOnboardingMeta = (app: AppType) => {
  const activeCompany = useActiveCompany();

  return useQuery(['new-onboarding', activeCompany, app], () =>
    services.systemLogin.getOnboarding(activeCompany, app)
  );
};

export const useUpdateOnboarding = (app: AppType) => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateOnboardingParams) =>
      services.systemLogin.updateOnboarding(activeCompany, app, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['new-onboarding']);
      },
    }
  );
};
