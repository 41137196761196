import { isEqual } from 'lodash-es';

import { env } from '@/config/env';
import { Appearances } from '@/types/bitLink/v2';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

export const presets = [
  {
    value: 'theme-custom',
    label: 'Custom',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/custom.png`,
  },
  {
    value: 'theme-default',
    label: 'Default',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/default.png`,
  },
  {
    value: 'theme-basic-red',
    label: 'Basic red',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/basic-red.png`,
  },
  {
    value: 'theme-basic-green',
    label: 'Basic green',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/basic-green.png`,
  },
  {
    value: 'theme-basic-navy',
    label: 'Basic navy',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/basic-navy.png`,
  },
  {
    value: 'theme-basic-orange',
    label: 'Basic orange',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/basic-orange.png`,
  },
  {
    value: 'theme-gradient-red',
    label: 'Gradient red',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/gradient-red.png`,
  },
  {
    value: 'theme-gradient-green',
    label: 'Gradient green',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/gradient-green.png`,
  },
  {
    value: 'theme-gradient-purple',
    label: 'Gradient purple',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/gradient-purple.png`,
  },
  {
    value: 'theme-clean-beige',
    label: 'Clean beige',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/clean-beige.png`,
  },
  {
    value: 'theme-professional',
    label: 'Professional',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/professional.png`,
  },
  {
    value: 'theme-fashion',
    label: 'Fashion',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/fashion.png`,
  },
  {
    value: 'theme-coffee-shop',
    label: 'Coffee shop',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/coffee-shop.png`,
  },
  {
    value: 'theme-modern',
    label: 'Modern',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/modern.png`,
  },
  {
    value: 'theme-business',
    label: 'Business',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/business.png`,
  },
] as const;

export const mapThemeAppearance: Record<
  typeof presets[number]['value'],
  Partial<
    Omit<Appearances, 'bgImage'> & {
      bgImage: Nullable<FileType> | undefined;
    }
  >
> = {
  'theme-custom': {
    bgColor: '#FFFFFF',
    btnBgColor: '#FFFFFF',
    btnStyle: 'normal_full',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: '#FFFFFF',
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-default': {
    bgColor: '#F5F5F5',
    btnBgColor: '#262627',
    btnStyle: 'btn-shadow_light-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#262627',
    btnShadowColor: '#262627',
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-basic-red': {
    bgColor: '#F5F5F5',
    btnBgColor: '#F55659',
    btnStyle: 'btn-shadow-hard_full',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#262627',
    btnShadowColor: '#262627',
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-basic-green': {
    bgColor: '#F5F5F5',
    btnBgColor: '#008E8D',
    btnStyle: 'btn-shadow-hard_full-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#008E8D',
    btnShadowColor: null,
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-basic-navy': {
    bgColor: '#2D3047',
    btnBgColor: '#FFFFFF',
    btnStyle: 'outlined_light-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-basic-orange': {
    bgColor: '#FD823E',
    btnBgColor: '#FFFFFF',
    btnStyle: 'outlined_full',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'SOLID',
    bgColorBottom: '#FFFFFF',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-gradient-red': {
    bgColor: '#920404',
    btnBgColor: '#FFFFFF',
    btnStyle: 'outlined_full-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'GRADIENT',
    bgColorBottom: '#EE594F',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-gradient-green': {
    bgColor: '#3DCDA2',
    btnBgColor: '#EAF2EF',
    btnStyle: 'btn-shadow_full',
    btnFontColor: '#000000',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: '#00000026',
    bgType: 'GRADIENT',
    bgColorBottom: '#1F96C8',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-gradient-purple': {
    bgColor: '#C490E4',
    btnBgColor: '#EDFFA6',
    btnStyle: 'normal_full-rounded',
    btnFontColor: '#000000',
    fontColorTitleBio: '#EDFFA6',
    btnShadowColor: null,
    bgType: 'GRADIENT',
    bgColorBottom: '#260A53',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgImage: convertRemoteUrlToFileType(undefined),
  },
  'theme-clean-beige': {
    bgColor: '#AD5E25',
    btnBgColor: '#AD5E25',
    btnStyle: 'btn-shadow-hard_light-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#753506',
    btnShadowColor: '#75350699',
    bgType: 'IMAGE',
    typography:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    bgColorBottom: '#FFFFFF',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-clean-beige.jpg`
    ),
  },
  'theme-professional': {
    bgColor: '#3E7074',
    btnBgColor: '#3E7074',
    btnStyle: 'btn-shadow-hard_light-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'IMAGE',
    bgColorBottom: '#FFFFFF',
    typography: 'Montserrat, sans-serif',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-professional.jpg`
    ),
  },
  'theme-fashion': {
    bgColor: '#FBEBD2',
    btnBgColor: '#FBEBD2',
    btnStyle: 'normal_light-rounded',
    btnFontColor: '#922828',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'IMAGE',
    bgColorBottom: '#FFFFFF',
    typography: 'Poppins, sans-serif',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-fashion.jpg`
    ),
  },
  'theme-coffee-shop': {
    bgColor: '#793915',
    btnBgColor: '#793915',
    btnStyle: 'btn-shadow_full-rounded',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: '#262626',
    bgType: 'IMAGE',
    bgColorBottom: '#FFFFFF',
    typography: 'Oswald, sans-serif',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-coffee-shop.jpg`
    ),
  },
  'theme-modern': {
    bgColor: '#FFFFFF',
    btnBgColor: '#FFFFFF',
    btnStyle: 'outlined_full',
    btnFontColor: '#FFFFFF',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'IMAGE',
    bgColorBottom: '#FFFFFF',
    typography: 'PT Sans, sans-serif',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-modern.jpg`
    ),
  },
  'theme-business': {
    bgColor: '#FFFFFF',
    btnBgColor: '#FFFFFF',
    btnStyle: 'normal_full',
    btnFontColor: '#000000',
    fontColorTitleBio: '#FFFFFF',
    btnShadowColor: null,
    bgType: 'IMAGE',
    bgColorBottom: '#FFFFFF',
    typography: 'Noto Sans, sans-serif',
    bgImage: convertRemoteUrlToFileType(
      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitLink/presets/backgrounds/bg-business.jpg`
    ),
  },
};

export const getThemeAppearanceFromStyle = (
  style: Partial<
    Omit<Appearances, 'bgImage'> & {
      bgImage: Nullable<FileType> | undefined;
    }
  >
): typeof presets[number]['value'] | undefined => {
  const data = Object.entries(mapThemeAppearance).find(([key, value]) => {
    if (style.bgType === 'SOLID' || style.bgType === 'GRADIENT') {
      const { bgImage: bgImageValue, ...restValue } = value;
      const { bgImage: bgImageStyle, ...restStyle } = style;
      return isEqual(restValue, restStyle);
    }
    return isEqual(value, style);
  }) as [
    typeof presets[number]['value'],
    Partial<
      Omit<Appearances, 'bgImage'> & {
        bgImage: Nullable<FileType> | undefined;
      }
    >
  ];
  return data?.[0] || 'theme-custom';
};
