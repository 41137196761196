import React, { useState } from 'react';
import { env } from 'config/env';
import { useCollections } from 'hooks/bitCRM/shopify';

import { BBBSelect } from '@/components/ui';
import { SingleOnValueChangeParam } from '@/components/ui';
import { ShopifyCollection } from '@/types/shopify/products';

export type ShopifyCollectionPayload = Pick<
  ShopifyCollection,
  'id' | 'title' | 'image'
>;

export type ShopifyCollectionForm =
  | ShopifyCollection
  | ShopifyCollectionPayload;

type Props = {
  onChange: (val?: SingleOnValueChangeParam<ShopifyCollectionForm>) => void;
  onClick?: () => void;
  value: SingleOnValueChangeParam<ShopifyCollectionForm>;
  label: string | React.ReactNode;
  error?: string;
  className?: string;
  disabled?: boolean;
};

function ShopifyCollectionListsOptions({
  onChange,
  onClick,
  label,
  error,
  value,
  className,
  disabled,
}: Props) {
  const [search, setSearch] = useState<string>();

  const query = useCollections({ search });

  const { data, hasNextPage, isInitialLoading, fetchNextPage } = query;

  const options = data?.pages
    .flatMap((page) => page.collections)
    .map((page) => ({
      ...page,
      label2: <CollectionOption collection={page} />,
    }));

  const _value = value
    ? { ...value, label2: <CollectionOption collection={value} /> }
    : null;

  return (
    <BBBSelect
      containerClassName={className}
      label={label}
      options={options}
      loading={isInitialLoading}
      optionValue="id"
      //@ts-ignore
      optionLabel="label2"
      isSearchable
      search={search}
      onChangeSearch={setSearch}
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      placeholder="Choose collection"
      //@ts-ignore
      value={_value}
      onValueChange={onChange}
      onClick={onClick}
      isDisabled={disabled}
      error={error}
    />
  );
}

function CollectionOption({
  collection,
}: {
  collection: ShopifyCollectionPayload;
}) {
  return (
    <div className="flex items-center gap-2.5">
      <img
        className="border-[0.px] border-neutral-30 rounded object-cover w-6 h-6"
        src={
          collection.image
            ? collection?.image?.url
            : `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/no-image.png`
        }
        alt={collection.id}
      />
      <p className="text-primary-main">{collection.title}</p>
    </div>
  );
}

export default ShopifyCollectionListsOptions;
