import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow';
import { createId } from '@paralleldrive/cuid2';
import { create } from 'zustand';
import { toast } from 'utils/common/toast';
import { autoLayout } from 'utils/flow';
import {
  ActionData,
  actionSchema,
  ConditionData,
  TriggerData,
  triggerSchema,
} from './types';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

type State =
  | {
      type: string;
      sourceId: string;
      targetId?: string;
    }
  | {
      type: string;
      nodeId: string;
    }
  | {
      type: string;
      edgeId: string;
    };

type TriggerModalState = {
  id?: string;
};

export type RFState = {
  nodes: Node[];
  edges: Edge<ConditionData>[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  showStateModal: State | null;
  onChangeStateModal: (props: State | null) => void;
  triggerModalState: TriggerModalState | null;
  onChangeTriggerModalState: (props: TriggerModalState | null) => void;
  deleteRelation: (nodeId: string) => void;
  addRelations: (nodes: Node[], edges?: Edge[]) => void;
  activeAddState: string | null;
  loadingDuplicateFlow: boolean;
  onChangeActiveAddState: (val: string | null) => void;
  setNodes: (val: Node[]) => void;
  setEdges: (val: Edge[]) => void;
  setLoadingDuplicateFlow: (val: boolean) => void;
  connectingNode: string | null;
  setConnectingNode: (val: string | null) => void;
  resetConnectingNode: (params: {
    mouseX: number;
    mouseY: number;
    viewportX: number;
    viewportY: number;
    zoom: number;
    sourceId: string;
    callback?: (dropNodeExists: boolean) => void;
  }) => void;
  transitioningCreateEdges: {
    source: string;
    target: string;
  } | null;
  expandState: { [id: string]: 'hover' | 'clicked' | null } | null;
  setExpandState: (val: string, payload: 'hover' | 'clicked' | null) => void;
  updateNode: (nodeId: string, data: ActionData | TriggerData) => void;
  insertNode: (
    node: Node,
    params: { sourceId: string; targetId?: string }
  ) => void;
  errorNode: {
    [nodeId in string]: ('not-connected' | 'missing-fields')[];
  };
  setErrorNode: () => void;
  setErrorNode2: (nodeId: [string, boolean][]) => void;
  draggedNode: string | null;
  withinDragBounds: string | null;
  setDraggedNode: (val: string | null) => void;
  onDrag: (params: { x: number; y: number }) => void;
  onDragFinish: () => void;
  insertConditions: (sourceId: string) => void;
  replaceWithCondition: (nodeId: string) => void;
  updateConditionEdgeData: (edgeId: string, data: ConditionData) => void;
  removeConditionEdge: (edgeId: string) => void;
  deleteConditionNode: (nodeId: string) => void;
  addNewCondition: (sourceId: string) => void;
  duplicateNode: (nodeId: string) => void;
  panOnDrag: boolean;
  setPanOnDrag: (val: boolean) => void;

  customAutomationId: string;
};

const useStore = create<RFState>((set, get) => ({
  customAutomationId: createId(),
  nodes: initialNodes,
  edges: initialEdges,
  showStateModal: null,
  activeAddState: null,
  triggerModalState: null,
  expandState: null,
  loadingDuplicateFlow: false,
  connectingNode: null,
  transitioningCreateEdges: null,
  draggedNode: null,
  withinDragBounds: null,
  errorNode: {},
  setErrorNode: () => {
    const validations = get().nodes.map((node) => {
      if (node.type === 'trigger') {
        return [
          node.id,
          (node.data as TriggerData | null)?.source
            ? triggerSchema.isValidSync(node.data)
            : true,
        ] as const;
      }

      return [
        node.id,
        (node.data as ActionData | null)?.source
          ? actionSchema.isValidSync(node.data)
          : true,
      ] as const;
    });

    const prevErrorNodes = { ...get().errorNode };

    validations.forEach(([nodeId, status]) => {
      if (!status) {
        if (!prevErrorNodes[nodeId]) {
          prevErrorNodes[nodeId] = ['missing-fields'];
        } else {
          if (!prevErrorNodes[nodeId].includes('missing-fields')) {
            prevErrorNodes[nodeId] = [
              ...prevErrorNodes[nodeId],
              'missing-fields',
            ];
          }
        }
      } else {
        if (prevErrorNodes[nodeId]) {
          prevErrorNodes[nodeId] = prevErrorNodes[nodeId].filter(
            (err) => err !== 'missing-fields'
          );
        }
      }
    });

    set({ errorNode: prevErrorNodes });
  },
  setErrorNode2(nodes) {
    const prevErrorNodes = { ...get().errorNode };

    nodes.forEach((node) => {
      const [nodeId, status] = node;

      if (status) {
        if (!prevErrorNodes[nodeId]) {
          prevErrorNodes[nodeId] = ['not-connected'];
        } else {
          if (!prevErrorNodes[nodeId].includes('not-connected')) {
            prevErrorNodes[nodeId] = [
              ...prevErrorNodes[nodeId],
              'not-connected',
            ];
          }
        }
      } else {
        if (prevErrorNodes[nodeId]) {
          prevErrorNodes[nodeId] = prevErrorNodes[nodeId].filter(
            (err) => err !== 'not-connected'
          );
        }
      }
    });

    set({ errorNode: prevErrorNodes });
  },
  setDraggedNode: (val) => set({ draggedNode: val }),
  onDragFinish: () => {
    function updateNodes() {
      const dragBounds = get().withinDragBounds;

      if (!dragBounds) return;

      const [source, target] = dragBounds.split('_');
      const newNodeId = get().draggedNode!;

      if (target === newNodeId || source === newNodeId) return;

      const draggedNode = get().nodes.find(
        (node) => node.id === get().draggedNode
      )!;

      const sourcePrevEdge = get().edges.find(
        (edge) => edge.target === newNodeId
      )!;
      const sourceEdge = get().edges.find((edge) => edge.source === newNodeId);

      const nodesAfterDragRemoval = get().nodes.filter(
        (node) => node.id !== newNodeId
      );
      const edgesAfterDragRemoval = get()
        .edges.filter((edge) => edge.target !== newNodeId)
        .map((edge) => {
          if (edge.source === sourceEdge?.source) {
            return {
              ...edge,
              source: sourcePrevEdge.source,
            };
          }

          return edge;
        });

      const updatedEdges: Edge[] = [
        ...edgesAfterDragRemoval.map((edge) => {
          if (edge.source === source && edge.target === target) {
            return {
              ...edge,
              target: newNodeId,
            };
          }

          return edge;
        }),
        {
          id: createId(),
          type: 'custom',

          source: newNodeId,
          target,
        },
      ];

      const updatedNodes = [...nodesAfterDragRemoval, draggedNode];

      set({
        nodes: autoLayout(updatedNodes, updatedEdges),
        edges: updatedEdges,
      });

      toast.success('Card was moved');
    }

    updateNodes();
    set({ draggedNode: null, withinDragBounds: null });
  },
  onDrag: ({ x, y }) => {
    const edgeWithinDropRadius = get().edges.find((edge) => {
      const { source, target } = edge;

      const sourceNode = document.querySelector(
        `.react-flow__node[data-id="${source}"]`
      )!;
      const targetNode = document.querySelector(
        `.react-flow__node[data-id="${target}"]`
      )!;

      const sourceRect = sourceNode.getBoundingClientRect();
      const targetRect = targetNode.getBoundingClientRect();

      if (
        x > sourceRect.right &&
        x < targetRect.left &&
        y > sourceRect.y - 60 &&
        y < sourceRect.bottom + 60
      ) {
        return true;
      }

      return false;
    });

    if (edgeWithinDropRadius) {
      const { source, target } = edgeWithinDropRadius;
      set({ withinDragBounds: `${source}_${target}` });
    } else {
      set({ withinDragBounds: null });
    }
  },
  setLoadingDuplicateFlow: (val) => set({ loadingDuplicateFlow: val }),
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },
  onChangeStateModal: (props) => {
    set({ showStateModal: props });
  },
  onChangeTriggerModalState: (props) => {
    set({ triggerModalState: props });
  },
  deleteRelation: (nodeId) => {
    set({
      nodes: get().nodes.filter((node) =>
        node.parentNode ? node.parentNode !== nodeId : node.id !== nodeId
      ),
      edges: get().edges.filter((edge) =>
        edge.target === nodeId || edge.source === nodeId ? false : true
      ),
    });
  },
  addRelations: (nodes: Node[], edges?: Edge[]) => {
    set({
      nodes: [...get().nodes, ...nodes],
      ...(edges && { edges: [...get().edges, ...edges] }),
    });
  },
  onChangeActiveAddState: (val) => set({ activeAddState: val }),
  setNodes: (nodes) => set({ nodes }),
  setEdges: (edges) => set({ edges }),
  setConnectingNode: (val) => {
    set((state) => ({
      connectingNode: val,
      nodes: state.nodes.map((node) => {
        if (node.id === val) {
          return {
            ...node,
            draggable: false,
          };
        }

        return node;
      }),
    }));
  },
  resetConnectingNode: ({
    mouseX,
    mouseY,
    viewportX,
    viewportY,
    zoom,
    sourceId,
    callback,
  }) => {
    const prevNodes = get().nodes;
    const prevEdges = get().edges;

    const nearestNode = prevNodes.find((node) => {
      const { clientWidth: width, clientHeight: height } =
        document.querySelector(`.react-flow__node[data-id="${node.id}"]`)!;

      const nodeLeft = viewportX + node.position.x * zoom;
      const nodeRight = viewportX + (node.position.x + width) * zoom;

      const nodeTop = viewportY + node.position.y * zoom;
      const nodeBottom = viewportY + (node.position.y + height) * zoom;

      if (
        mouseX >= nodeLeft &&
        mouseX <= nodeRight &&
        mouseY >= nodeTop &&
        mouseY <= nodeBottom
      ) {
        const sourceParent = prevNodes.find(
          (_node) => _node.id === sourceId
        )?.parentNode;

        if (node.id === sourceId) return false;

        if (sourceParent && node.id === sourceParent) return false;

        if (prevEdges.find((edge) => edge.target === node.id)) return false;

        return true;
      }

      return false;
    });

    set((state) => {
      return {
        nodes: state.nodes.map((node) => {
          if (node.type !== 'child1' && !node.draggable) {
            return {
              ...node,
              draggable: true,
            };
          }

          return node;
        }),
        connectingNode: null,
      };
    });

    if (nearestNode) {
      set((state) => ({
        edges: [
          ...state.edges,
          {
            id: createId(),
            source: sourceId,
            target: nearestNode.id,
            type: 'custom',
          },
        ],
      }));

      callback?.(true);
    } else {
      callback?.(false);
    }
  },
  setExpandState: (id, payload) => {
    set({ expandState: { ...get().expandState, [id]: payload } });
  },
  updateNode: (nodeId, data) => {
    set({
      nodes: [
        ...get().nodes.map((node) =>
          node.id === nodeId ? { ...node, data } : node
        ),
      ],
    });
  },
  insertNode: (node, params) => {
    const previousNodes = get().nodes;
    const previousEdges = get().edges;

    const newNodeId = node.id;

    const targetId = params.targetId;

    const updatedEdges: Edge[] = [
      ...(targetId
        ? previousEdges.map((edge) => {
            if (edge.source === params.sourceId && edge.target === targetId) {
              return {
                ...edge,
                target: newNodeId,
              };
            }

            return edge;
          })
        : previousEdges),
      {
        id: createId(),
        type: 'custom',
        ...(targetId
          ? {
              source: newNodeId,
              target: targetId,
            }
          : {
              source: params.sourceId,
              target: newNodeId,
            }),
      },
    ];

    const updatedNodes = [...previousNodes, node];

    set({ nodes: autoLayout(updatedNodes, updatedEdges), edges: updatedEdges });
  },
  insertConditions: (sourceId: string) => {
    const previousNodes = get().nodes;
    const previousEdges = get().edges;

    const targetNodeData: ActionData = {
      source: null,
      action: null,
      message: null,
      delayCount: null,
      delayUnit: null,
      params: null,
      templateId: null,
      templateMessage: null,
      templateName: null,
      customerTags: null,
      addedLoyaltyPoints: null,
      subtractedLoyaltyPoints: null,
      powerMsgBody: null,
      powerMsgHeader: null,
      fileUrl: null,
    };

    const sourceNodeId = createId();
    const targetNode1Id = createId();
    const targetNode2Id = createId();

    const appendedNodes: Node[] = [
      {
        id: sourceNodeId,
        position: { x: 0, y: 0 },
        type: 'conditions',
        data: null,
      },
      {
        id: targetNode1Id,
        position: { x: 0, y: 0 },
        type: 'action',
        data: targetNodeData,
      },
      {
        id: targetNode2Id,
        position: { x: 0, y: 0 },
        type: 'action',
        data: targetNodeData,
      },
    ];

    const appendedEdges: Edge[] = [
      {
        id: createId(),
        source: sourceId,
        target: sourceNodeId,
        type: 'custom',
      },
      {
        id: createId(),
        source: sourceNodeId,
        target: targetNode1Id,
        type: 'customCondition',
      },
      {
        id: createId(),
        source: sourceNodeId,
        target: targetNode2Id,
        type: 'customCondition',
      },
    ];

    const updatedEdges = [...previousEdges, ...appendedEdges];
    const updatedNodes = [...previousNodes, ...appendedNodes];

    set({
      nodes: autoLayout(updatedNodes, updatedEdges),
      edges: updatedEdges,
    });
  },
  replaceWithCondition: (nodeId: string) => {
    const previousNodes = get().nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          position: { x: 0, y: 0 },
          type: 'conditions',
          data: null,
          id: node.id,
        };
      }

      return node;
    });

    const previousEdges = get().edges;

    const targetNodeData: ActionData = {
      source: null,
      action: null,
      message: null,
      delayCount: null,
      delayUnit: null,
      params: null,
      templateId: null,
      templateMessage: null,
      templateName: null,
      customerTags: null,
      addedLoyaltyPoints: null,
      subtractedLoyaltyPoints: null,
      powerMsgBody: null,
      powerMsgHeader: null,
      fileUrl: null,
    };

    const targetNode1Id = createId();
    const targetNode2Id = createId();

    const appendedNodes: Node[] = [
      {
        id: targetNode1Id,
        position: { x: 0, y: 0 },
        type: 'action',
        data: targetNodeData,
      },
      {
        id: targetNode2Id,
        position: { x: 0, y: 0 },
        type: 'action',
        data: targetNodeData,
      },
    ];

    const appendedEdges: Edge[] = [
      {
        id: createId(),
        source: nodeId,
        target: targetNode1Id,
        type: 'customCondition',
      },
      {
        id: createId(),
        source: nodeId,
        target: targetNode2Id,
        type: 'customCondition',
      },
    ];

    const updatedEdges = [...previousEdges, ...appendedEdges];
    const updatedNodes = [...previousNodes, ...appendedNodes];

    set({
      nodes: autoLayout(updatedNodes, updatedEdges),
      edges: updatedEdges,
    });
  },
  updateConditionEdgeData(edgeId, data) {
    const edges = get().edges.map((edge) => {
      if (edge.id === edgeId) {
        return {
          ...edge,
          data,
        };
      }
      return edge;
    });

    set({ edges });
  },
  removeConditionEdge(edgeId) {
    const targetNodeId = get().edges.find((edge) => edge.id === edgeId)!.target;

    const filteredEdges = get().edges.filter((edge) => edge.id !== edgeId);

    const { nodes, edges } = removeNodeAndDescendants(
      get().nodes,
      filteredEdges,
      targetNodeId
    );

    set({ edges, nodes });
  },
  deleteConditionNode(nodeId) {
    const { nodes, edges } = removeNodeAndDescendants(
      get().nodes,
      get().edges,
      nodeId
    );

    set({ nodes: autoLayout(nodes, edges), edges });
  },
  addNewCondition: (sourceId) => {
    const targetNodeId = createId();

    const newEdge: Edge[] = [
      {
        id: createId(),
        source: sourceId,
        target: targetNodeId,
        type: 'customCondition',
      },
    ];

    const targetNodeData: ActionData = {
      source: null,
      action: null,
      message: null,
      delayCount: null,
      delayUnit: null,
      params: null,
      templateId: null,
      templateMessage: null,
      templateName: null,
      customerTags: null,
      addedLoyaltyPoints: null,
      subtractedLoyaltyPoints: null,
      powerMsgBody: null,
      powerMsgHeader: null,
      fileUrl: null,
    };

    const newNode: Node = {
      id: targetNodeId,
      position: { x: 0, y: 0 },
      data: targetNodeData,
      type: 'action',
    };

    const newEdges = [...get().edges, ...newEdge];

    set({
      edges: newEdges,
      nodes: autoLayout([...get().nodes, newNode], newEdges),
    });
  },
  duplicateNode: (nodeId) => {
    const currentNode = get().nodes.find((node) => node.id === nodeId)!;
    const currentEdge = get().edges.find((edge) => edge.source === nodeId);

    const newId = createId();
    const newNode = { ...currentNode, id: newId };

    let newEdge: Edge | undefined = undefined;

    const edgeId = createId();

    if (currentEdge) {
      newEdge = {
        source: newId,
        target: currentEdge.target,
        id: edgeId,
        type: 'custom',
      };
    } else {
      newEdge = {
        source: nodeId,
        target: newId,
        id: edgeId,
        type: 'custom',
      };
    }

    const newEdges = [
      ...(currentEdge
        ? get().edges.map((edge) => {
            if (edge.source === nodeId) {
              return {
                ...edge,
                target: newId,
              };
            }
            return edge;
          })
        : get().edges),
      ...(newEdge ? [newEdge] : []),
    ];

    set({
      edges: newEdges,
      nodes: autoLayout([...get().nodes, newNode], newEdges),
    });
  },
  panOnDrag: true,
  setPanOnDrag: (val) => {
    set({ panOnDrag: val });
  },
}));

export default useStore;

function removeNodeAndDescendants(
  nodes: Node[],
  edges: Edge[],
  nodeId: string
): { nodes: Node[]; edges: Edge[] } {
  // Helper function to get all descendants of a node
  function getDescendants(nodeId: string, edges: Edge[]): string[] {
    const descendants: string[] = [];
    const queue: string[] = [nodeId];

    while (queue.length > 0) {
      const current = queue.shift()!;
      descendants.push(current);
      const children = edges
        .filter((edge) => edge.source === current)
        .map((edge) => edge.target);
      queue.push(...children);
    }

    return descendants;
  }

  // Get all descendants including the node itself
  const descendants = getDescendants(nodeId, edges);

  // Filter nodes and edges to exclude the node and its descendants
  const filteredNodes = nodes.filter((node) => !descendants.includes(node.id));
  const filteredEdges = edges.filter(
    (edge) =>
      !descendants.includes(edge.source) && !descendants.includes(edge.target)
  );

  return { nodes: filteredNodes, edges: filteredEdges };
}
