import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash-es';

import { BBBCard, BBBTextAreaInput, BBBTextInput } from '@/components/ui';
import {
  useAgentVariables,
  useUpdateAgentVariables,
} from '@/hooks/ai/agent-variables';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';

export type FormAiAgent = {
  aiChatbotName: string;
  aiRole: string;
  aiBrandName: string;
  aiDescription: string;
};

export default function FormAiAgent() {
  const { toggle } = useConfirmationBanner();
  const { control, reset, watch, handleSubmit } = useCustomForm<FormAiAgent>({
    defaultValues: {
      aiDescription: '',
      aiBrandName: '',
      aiChatbotName: '',
      aiRole: '',
    },
  });

  const { data: agentVariablesData } = useAgentVariables();

  const { mutate: updateAgentVariables, isLoading: loadingAgentVariables } =
    useUpdateAgentVariables();

  const dataFromApi = useMemo<Partial<FormAiAgent>>(
    () => ({
      aiDescription: agentVariablesData?.brandDescription || '',
      aiBrandName: agentVariablesData?.brandName || '',
      aiChatbotName: agentVariablesData?.name || '',
      aiRole: agentVariablesData?.role || '',
    }),
    [
      agentVariablesData?.brandDescription,
      agentVariablesData?.brandName,
      agentVariablesData?.name,
      agentVariablesData?.role,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  useEffect(() => {
    const onSubmit = ({ ...data }: Partial<FormAiAgent>) => {
      updateAgentVariables({
        name: data.aiChatbotName,
        role: data.aiRole,
        brandName: data.aiBrandName,
        brandDescription: data.aiDescription,
      });
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('form-agent-banner', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant: loadingAgentVariables ? 'loading' : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingAgentVariables,
    reset,
    toggle,
    updateAgentVariables,
    watch(),
  ]);

  return (
    <BBBCard
      title="AI agents "
      desc="Let AI handle your customer’s questions first"
    >
      <div className="flex flex-col">
        <BBBTextInput
          isHookForm
          control={control}
          controlName="aiChatbotName"
          label="Chatbot name"
          placeholder="Give a name to your AI agent"
          containerClassname="mb-5"
        />
        <BBBTextInput
          isHookForm
          control={control}
          controlName="aiRole"
          label="Role"
          placeholder="What is the main job of this AI agent? eg: customer service, sales agent, etc"
          containerClassname="mb-5"
        />
        <BBBTextInput
          isHookForm
          control={control}
          controlName="aiBrandName"
          label="Brand name"
          placeholder="What is your brand name?"
          containerClassname="mb-5"
        />
        <BBBTextAreaInput
          isHookForm
          control={control}
          controlName="aiDescription"
          label="Description of your business (optional)"
          rows={5}
          placeholder={`Describe your business to give more context to AI Agents. Some examples below: \n\n “ZARA is a Spanish multinational fast-fashion company with over 2,000 stores worldwide. We specialize in selling clothing, accessories, beauty products, and perfumes.”`}
        />
      </div>
    </BBBCard>
  );
}
