import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { WaWidget2 } from '@/types/bitChat/waWidget';

export default {
  getWidget: (companyId: number | undefined) =>
    api.bitChat
      .get<ApiResponse<WaWidget2>>(`/wa-widget/style/${companyId}/new`)
      .then((res) => res.data.data),
  getWidgetPages: () =>
    api.bitChat
      .get<string[]>('/wa-widget/option/pages')
      .then((res) =>
        res.data.filter(
          (data) => !['Thank you page', 'Checkout'].includes(data)
        )
      ),
  updateWidget: (
    companyId: number | undefined,
    data: Partial<WaWidget2> & { shopifyDomain?: string }
  ) =>
    api.bitChat
      .post(`/wa-widget/new`, {
        ...data,
        companyId,
      })
      .then((res) => res.data),
};
