import { useParams } from 'react-router-dom';
import AddTag from './AddTag';

import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import { BBBCard } from '@/components/ui';
import useDeleteTag from '@/hooks/customers/customer/useDeleteTag';
import { CustomerTags } from '@/types/customers';

type Props = {
  customerTags?: CustomerTags[];
  customerId?: string;
  title?: React.ReactNode;
};

function TagsCard({ customerTags, customerId, title }: Props) {
  const { id } = useParams<{ id: string }>();

  const realCustomerId = customerId || id;

  const { mutate: deleteTag } = useDeleteTag();

  return (
    <BBBCard title={typeof title !== 'undefined' ? title : 'Tags'}>
      <AddTag customerId={realCustomerId} />
      {!!customerTags?.length && (
        <TruncatedTags
          items={customerTags.map((tag) => tag.tagName)}
          onDelete={(tagName) =>
            deleteTag({
              tagName,
              customerId: realCustomerId,
            })
          }
        />
      )}
    </BBBCard>
  );
}

export default TagsCard;
