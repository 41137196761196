import { ApiResponse } from 'api/types';
import api from 'config/api';
import {
  FormResponse,
  FormResponseElements,
  FormTemplate,
  FormTemplateElements,
  FormTemplateStatus,
  WaFormCreation,
} from 'types/whatsApp/form';

export default {
  getForms: (
    companyId: number,
    params?: { search?: string; status?: FormTemplateStatus }
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<FormTemplate[]>>(`/form-template/${companyId}`, {
        params,
      })
      .then((res) => res.data.data),
  getFormById: (companyId: number, id: string) =>
    api.whatsApp.v1
      .get<ApiResponse<FormTemplate & { element: FormTemplateElements[] }>>(
        `/form-template/${companyId}/${id}`
      )
      .then((res) => res.data.data),
  getFormResponseById: (
    companyId: number,
    id: string,
    params?: { search?: string; after?: string; before?: string }
  ) =>
    api.whatsApp.v1
      .get<{
        data: (FormResponse & {
          element: FormResponseElements[];
        })[];
        meta: { count: number };
      }>(`/form-template/${companyId}/${id}/response`, { params })
      .then((res) => res.data),
  createForm: (companyId: number, payload: WaFormCreation) =>
    api.whatsApp.v1
      .post<
        ApiResponse<{
          database_id: string;
          meta_id: string;
        }>
      >(`/form-template/${companyId}`, payload)
      .then((res) => res.data.data),
  testForm: (companyId: number, payload: { id: string; number: string }) =>
    api.whatsApp.v1
      .post(`/form-template/${companyId}/test`, payload)
      .then((res) => res.data),
  publishForm: (companyId: number, payload: { id: string }) =>
    api.whatsApp.v1
      .post(`/form-template/${companyId}/publish`, payload)
      .then((res) => res.data),
  saveGoogleSheets: (
    companyId: number,
    formId: string,
    body: SaveGoogleSheetsParams
  ) =>
    api.whatsApp.v1
      .post(`/form-template/${companyId}/google-sheet/${formId}`, body)
      .then((res) => res.data),
  deleteForm: (companyId: number, formId: string) =>
    api.whatsApp.v1
      .delete(`/form-template/${companyId}/${formId}`)
      .then((res) => res.data),
  duplicateForm: (companyId: number, formId: string) =>
    api.whatsApp.v1
      .post(`/form-template/${companyId}/${formId}/duplicate`)
      .then((res) => res.data),
};

export type SaveGoogleSheetsParams = {
  spreadSheetId: string;
  sheetId: string;
  emailAccount: string;
};
