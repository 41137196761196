import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import services from 'api/services';
import {
  ChannelIntegrationValues,
  getOrderIndex,
  integrationsMeta,
  KnownIntegration,
} from 'constants/integrations';
import {
  CompanyIntegrationAssociation,
  IntegrationName,
} from 'types/bitChat/integrations';
import useConfirmationModal from '../common/useConfirmationModal';
import { useActiveCompany } from '../rtk/selector';

export const useConnectedChannels = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number)[]
  >,
  'queryKey' | 'queryFn' | 'select'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      select: selectConnectedChannels,
      enabled,
      meta: {
        disableToast: false,
      },
      ...options,
    }
  );
};

export const useDefaultConnectedChannels = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    ChannelIntegrationValues,
    (string | number)[]
  >,
  'queryKey' | 'queryFn' | 'select'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      select: selectDefaultConnectedChannels,
      enabled,
      ...options,
    }
  );
};

export function useConnectedToAnyChannels(
  channel: IntegrationName | undefined,
  {
    enabled,
    ...options
  }: Omit<
    UseQueryOptions<
      CompanyIntegrationAssociation[],
      unknown,
      boolean,
      (string | number)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => isConnectedToAnyChannels(data, channel),
      ...options,
    }
  );
}

export const useHasWhatsappIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    boolean,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => {
        return !!data.filter(
          (d) =>
            (d.integration.name === 'whatsapp' ||
              d.integration.name === 'whatsapp_meta') &&
            d.status === 'CONNECTED'
        ).length;
      },
      ...options,
    }
  );
};

export const useConnectChannelConfirmation = () => {
  const confirm = useConfirmationModal();

  return (channelName: KnownIntegration, modules: string) => {
    confirm({
      title: "You're not connected",
      description: `Connect to ${integrationsMeta[channelName].title} first before creating ${modules}`,
      submitText: 'Go to integration page',
      submitLink: '/integrations',
      onAccept: (hide) => {
        hide();
      },
    });
  };
};

function isConnectedToAnyChannels(
  data: CompanyIntegrationAssociation[],
  channel: IntegrationName | undefined
) {
  return !channel
    ? !!data.filter(
        (companyIntegration) =>
          companyIntegration.integration.integrationCategory.name ===
            'channel' && companyIntegration.status === 'CONNECTED'
      ).length
    : !!data.find(
        (companyIntegration) =>
          companyIntegration.integration.name === channel &&
          companyIntegration.status === 'CONNECTED'
      );
}

function selectConnectedChannels(data: CompanyIntegrationAssociation[]) {
  return data.filter((integrations) => {
    return (
      integrations.integration.integrationCategory.name === 'channel' &&
      integrations.status === 'CONNECTED'
    );
  });
}

function selectDefaultConnectedChannels(
  data: CompanyIntegrationAssociation[]
): ChannelIntegrationValues {
  return (
    (selectConnectedChannels(data).sort((a, b) => {
      const aIndex = getOrderIndex(a.integration.name);
      const bIndex = getOrderIndex(b.integration.name);

      return aIndex - bIndex;
    })[0]?.integration.name as ChannelIntegrationValues | undefined) ||
    'whatsapp_meta'
  );
}
