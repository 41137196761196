import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import useHistory from '@/hooks/common/useHistory';
import { toast } from '@/utils/common/toast';

type ResetPasswordPayload = {
  password: string;
  id: string;
};

const useResetPassword = () => {
  const history = useHistory();
  return useMutation(
    async ({ id, password }: ResetPasswordPayload) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/auth/reset-password/${id}`, {
        password,
      });
      return data;
    },
    {
      onSuccess: () => {
        toast.success(
          'Password succesfully changed. Please login again with your new credentials'
        );
        history.push('/');
      },
    }
  );
};
export default useResetPassword;
