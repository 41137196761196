import { ChannelSource } from '@/constants/whatsApp';

export const typeOptions: {
  label: string;
  value: ChannelSource | 'all';
}[] = [
  { label: 'All', value: 'all' },
  { label: 'WhatsApp', value: 'WHATSAPP' },
  { label: 'WhatsApp Cloud', value: 'WHATSAPP_CLOUD_API' },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Sent', value: 'sent' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Sending', value: 'sending' },
  { label: 'Error', value: 'error' },
  { label: 'Paused', value: 'paused' },
  { label: 'Cancelled', value: 'cancelled' },
];
