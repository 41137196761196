function ExpiredIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="100"
      fill="none"
      viewBox="0 0 112 100"
      {...props}
    >
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M.128 4.437A3.788 3.788 0 013.916.65h104.166a3.788 3.788 0 013.788 3.788v67.709a3.788 3.788 0 01-7.575 0V8.226H7.704v65.34h53.504a3.788 3.788 0 110 7.576H3.916a3.788 3.788 0 01-3.788-3.788V4.437z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M84.645 52.496c-10.852 0-19.65 8.798-19.65 19.65 0 10.852 8.798 19.65 19.65 19.65 10.853 0 19.65-8.798 19.65-19.65 0-10.852-8.797-19.65-19.65-19.65zM57.42 72.146c0-15.036 12.19-27.226 27.225-27.226 15.037 0 27.226 12.19 27.226 27.226S99.681 99.37 84.645 99.37 57.42 87.182 57.42 72.146z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#262627"
        fillRule="evenodd"
        d="M.74 2.373a3.788 3.788 0 015.24-1.111L56 33.774l50.019-32.512a3.788 3.788 0 114.128 6.351L58.064 41.468a3.788 3.788 0 01-4.129 0L1.852 7.613A3.788 3.788 0 01.74 2.373zM92.532 64.26a3.788 3.788 0 010 5.357L82.116 80.033a3.788 3.788 0 11-5.357-5.357l10.416-10.417a3.788 3.788 0 015.357 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ExpiredIcon;
