import api from '@/config/api';
import { WACloudTemplate } from '@/types/whatsApp/settings';

export default {
  getTemplates: (
    companyId: number,
    params?: {
      status?: string;
      page?: number;
      size?: number;
      search?: string;
      type?: string | null;
      sort?: string | null;
    }
  ) =>
    api.bitLogin
      .get<WACloudTemplate[]>(`/template/${companyId}/whatsapp_meta`, {
        params,
      })
      .then((res) => res.data),
  getTemplate: (companyId: number, templateId: string) =>
    api.bitLogin
      .get<WACloudTemplate>(
        `/template/${companyId}/whatsapp_meta/${templateId}`
      )
      .then((res) => res.data),
  getActiveTemplate: (companyId: number) =>
    api.bitLogin.get(`/template/active/${companyId}`).then((res) => res.data),
  updateActiveTemplate: (
    companyId: number,
    payload: {
      templateId: string;
    }
  ) =>
    api.bitLogin
      .post(`/template/active/${companyId}`, payload)
      .then((res) => res.data),
  deleteTemplate: (companyId: number, templateId: string) =>
    api.bitLogin
      .delete(`/template/${companyId}/whatsapp_meta/${templateId}`)
      .then((res) => res.data),
  upsertTemplate: (
    companyId: number,
    payload: Partial<
      Pick<WACloudTemplate, 'name' | 'language' | 'message' | 'isDraft' | 'id'>
    >
  ) =>
    api.bitLogin
      .post<WACloudTemplate>(`/template/${companyId}/whatsapp_meta`, payload)
      .then((res) => res.data),
};
