import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ExpiredIcon from '../../assets/icons/ExpiredIcon';

import PartyIcon from '@/assets/icons/PartyIcon';
import AuthCard from '@/components/Auth/Card';
import { BBBAlert, BBBSpinner } from '@/components/ui';
import api from '@/config/api';
import GeneralLayout from '@/layouts/GeneralLayout';

export default function BetaSubscriptionRedirect() {
  const { hash } = useParams<{ hash: string }>();

  const {
    mutate,
    isLoading: loading,
    error,
  } = useMutation<unknown, AxiosError>(() =>
    api.systemLogin
      .post(`/company/subscribe-beta/${hash}`)
      .then((res) => res.data)
  );

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralLayout>
      {loading ? (
        <BBBSpinner />
      ) : error ? (
        error?.response?.status === 400 ? (
          <AuthCard withoutLogo>
            <div className="text-[40px] text-center font-medium text-primary-main mb-10">
              Beta access request was expired
            </div>
            <div className="flex justify-center">
              <ExpiredIcon className="mb-8" />
            </div>
          </AuthCard>
        ) : (
          <BBBAlert message={error.response?.data} type="danger" />
        )
      ) : (
        <AuthCard withoutLogo>
          <div className="overflow-y-auto scrollbar-hide flex flex-col items-center">
            <h1 className="text-[40px] font-medium text-center text-primary-main mb-10">
              Access granted
            </h1>
            <div className="flex justify-center">
              <PartyIcon className="mb-10" />
            </div>
          </div>
        </AuthCard>
      )}
    </GeneralLayout>
  );
}
