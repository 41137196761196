import { ApiResponse } from 'api/types';
import api from 'config/api';

export default {
  getOrders: (companyId: number, phoneNumber: string) =>
    api.customers
      .get<ApiResponse<Order[]>>(
        `/order/company/${companyId}/phone/${phoneNumber}/orders`
      )
      .then((res) => res.data.data),
  getOrder: (companyId: number, orderId: string) =>
    api.customers
      .get<ApiResponse<Order | null>>(
        `/order/company/${companyId}/orders/${orderId}`
      )
      .then((res) => res.data.data),
};

export type Order = {
  id: string;
  fulfillmentStatus: OrderFulfillmentStatus | null;
  financialStatus: OrderFinancialStatus;
  createdAt: Date;
  updatedAt: Date;
  total: number;
  currency: string | null;
  orderId: string | null;
  orderId2: string | null;
  orderUrl: string | null;
  fulfilledAt: Date | null;
  paymentGateways: string[];
  notes: string | null;
  channel: OrderChannel;
  channel2: OrderChannel | null;
  storeName: string | null;
  customerId: string;

  // association
  lineItems: OrderLineItem[];
  // shippingAddress: Prisma.$ShippingAddressPayload<ExtArgs> | null
  // customer: Prisma.$CustomerPayload<ExtArgs>
  shippingLines: ShippingLine[];
};

export type OrderFulfillmentStatus =
  | 'shipped'
  | 'partial'
  | 'unshipped'
  | 'any'
  | 'unfulfilled';
export type OrderFinancialStatus =
  | 'any'
  | 'authorized'
  | 'pending'
  | 'paid'
  | 'partially_paid'
  | 'refunded'
  | 'voided'
  | 'partially_refunded'
  | 'unpaid'
  | 'completed';
export type OrderChannel =
  | 'tokopedia'
  | 'shopee'
  | 'shopify'
  | 'jubelio'
  | 'lazada';

export type OrderLineItem = {
  id: string;
  title: string;
  orderId: string | null;
  quantity: number;
  sku: string | null;
  originalPrice: number;
  price: number;
  tax: number | null;
  discount: number | null;
  discountPercentage: number | null;
  thumbnailSrc: string | null;
  lineItemsId: string | null;
  channel: OrderChannel;
  channel2: OrderChannel | null;
  productId: string | null;
  productVariantId: string | null;

  // association

  product: Product | null;
  // order: Prisma.$OrderPayload<ExtArgs> | null
  productVariant: ProductVariant | null;
};

export type Product = {
  id: string;
  name: string;
  description: string | null;
  productId: string | null;
  medias: string[];
  media: string | null;
  status: ProductStatus | null;
  category: string | null;
  category1: string | null;
  category2: string | null;
  createdAt: Date;
  updatedAt: Date;
  channel: OrderChannel;
  channel2: OrderChannel | null;
  customerId: string;
};

export type ProductStatus = 'ACTIVE' | 'DRAFT';

export type ProductVariant = {
  productOptionName: string;
  productOptionValuesValue: string;
  price: number;
  id: string;
  productVariantId: string | null;
  media: string | null;
  channel: OrderChannel;
  channel2: OrderChannel | null;
};

export type ShippingLine = {
  id: string;
  shippingLineId: string;
  carrierIdentifier: string | null;
  title: string;
  price: number;
  orderId: string;
};
