import { DropResult } from 'react-beautiful-dnd';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import cloneDeep from 'lodash/cloneDeep';
import { useAddBanner } from './banner';
import { useAddCollection } from './collections';
import { useAddImageGrid } from './image-grid';
import useCompanyNavigation from './useCompanyNavigation';
import useRearrangeNavigation from './useRearrangeNavigation';

import services from '@/api/services';
import { env } from '@/config/env';
import { CIRCLE_IMAGE_GRIDS } from '@/constants/bitApp/designPresets';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { addItem } from '@/stores/bitApp';
import { setDraggingDroppable, setLoadingPreview } from '@/stores/bitApp';
import { ModalState } from '@/stores/bitApp/types';
import {
  SectionAssociation,
  SectionResponse,
} from '@/types/bitApp/association';
import { handleCancelLiveSection } from '@/utils/bitApp';
import { toast } from '@/utils/common/toast';

const LIMIT_SECTION = 10;

export const useSection = () => {
  const activeCompany = useActiveCompany();
  return useInfiniteQuery(
    ['sections', activeCompany],
    ({ pageParam = 0 }) =>
      services.bitApp.section.getSectionByCompanyId(activeCompany!, {
        offset: pageParam,
        limit: LIMIT_SECTION,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.hasMore) {
          return lastPage.meta.offset;
        }
        return undefined;
      },
    }
  );
};

export const useSectionDetail = (sectionId: number | null) => {
  const activeCompany = useActiveCompany();
  return useQuery<SectionAssociation>(
    ['section', sectionId],
    () => services.bitApp.section.getSectionDetail(sectionId!, activeCompany!),
    {
      enabled: !!sectionId,
    }
  );
};

export const useDeleteSection = () => {
  const selected = useAppSelector((state) => state.bitApp.selected);

  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  const toggleConfirmation = useConfirmationModal();

  const { mutate, isLoading } = useMutation(
    async (payload: number) =>
      services.bitApp.section.deleteSection(payload!, activeCompany!),
    {
      onSuccess: (_, payload) => {
        toast.success('Section deleted successfully');
        client.invalidateQueries(['sections', activeCompany]);
      },
    }
  );

  return () =>
    toggleConfirmation({
      title: 'Confirm Deletion',
      description: `Once deleted you're not able to recover it`,
      deleteModal: true,
      submitText: 'Delete',
      onAccept: (hide) => {
        mutate(selected as number, {
          onSuccess: () => {
            handleCancelLiveSection();
            hide();
          },
        });
      },
      loadingSubmit: isLoading,
    });
};

export const useAddSection = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    ({
      draggableId,
      destination,
    }: {
      draggableId: keyof ModalState;
      destination: number;
    }) =>
      services.bitApp.section.addSection(activeCompany!, {
        active: true,
        position: destination,
        type: draggableId,
      }),
    {
      onSuccess: ({ data: { data: sectionData } }, { draggableId }) => {
        client.invalidateQueries(['sections', activeCompany]);
        dispatch(
          addItem({
            type: draggableId,
            id: sectionData.id,
          })
        );
      },
    }
  );
};

export const useRearrangeSection = () => {
  const client = useQueryClient();
  const activeCompany = useActiveCompany();

  return useMutation(
    ({ data }: { data: InfiniteData<SectionResponse> | undefined }) =>
      services.bitApp.section.rearrangeSection(activeCompany!, {
        data:
          data?.pages
            .flatMap((page) => page.data)
            .map((p, index: number) => ({
              id: p.id,
              position: index,
            })) ?? [],
      }),
    {
      onMutate: async (data) => {
        await client.cancelQueries(['sections', activeCompany]);
        const previousSection = client.getQueryData<SectionResponse>([
          'sections',
          activeCompany,
        ]);
        client.setQueryData(['sections', activeCompany], data.data);
        return { previousSection };
      },
      onError: (err, payload, context) => {
        client.setQueryData(
          ['sections', activeCompany],
          context?.previousSection
        );
      },
      onSettled: () => {
        client.invalidateQueries(['sections', activeCompany]);
      },
    }
  );
};

export const useOnDragEnd = () => {
  const dispatch = useAppDispatch();
  const client = useQueryClient();
  const activeCompany = useActiveCompany();

  const { data: bottomNavData } = useCompanyNavigation();
  const { data: dataSection } = useSection();

  const { mutate: rearrangeSection } = useRearrangeSection();
  const { mutate: rearrangeNavigation } = useRearrangeNavigation();
  const { mutate: addSection, isLoading: isLoadingAddSection } =
    useAddSection();
  const { mutate: addBanner } = useAddBanner({
    isDropEnd: true,
  });
  const { mutate: addImageGrid } = useAddImageGrid({
    isDropEnd: true,
  });
  const { mutate: addCollection } = useAddCollection({
    isDropEnd: true,
  });

  const handleRearrangeSection = (source: number, destination: number) => {
    const pagesData =
      cloneDeep(dataSection?.pages || []).flatMap((page) => page.data) || [];
    const [reorderedItem] = pagesData.splice(source, 1) || [];
    pagesData.splice(destination, 0, reorderedItem);

    const chunkedPages = chunk(pagesData, LIMIT_SECTION);

    rearrangeSection({
      data: {
        pageParams: dataSection?.pageParams as unknown[],
        pages: chunkedPages.map((chunkedPage, index) => ({
          data: chunkedPage,
          meta: dataSection?.pages[index].meta as {
            hasMore: boolean;
            offset: number;
            total: number;
          },
        })),
      },
    });
  };

  const handleAddSection = async (
    draggableId: keyof ModalState,
    destination: number
  ) => {
    addSection(
      {
        draggableId,
        destination,
      },
      {
        onSuccess: ({ data: { data: sectionData } }) => {
          dispatch(setLoadingPreview(false));
          if (sectionData.type.includes('banners')) {
            return addBanner({
              bannerId: sectionData?.banners?.id,
              sectionId: sectionData.id,
              bannerContents: Array.from({ length: 6 }).map((item, idx) => ({
                link: 'none',
                image: `${
                  env.REACT_APP_CDN_URL
                }/bitbybit/static/bitApp/presets/image-slider/image%20slider%20${
                  idx + 1
                }.jpg`,
                active: true,
                bannerContentId: 0 - idx,
              })),
            });
          }
          if (sectionData.type === 'imagegrids') {
            return addImageGrid({
              sectionId: sectionData.id,
              imageGridId: sectionData?.imageGrids?.id,
              title: 'SHOP BY CATEGORY',
              collection: CIRCLE_IMAGE_GRIDS.map((item, idx) => ({
                ...item,
                link: 'none',
                imageGridContentId: 0 - idx,
              })),
              active: true,
            });
          }

          if (sectionData.type === 'collections') {
            return addCollection({
              sectionId: sectionData.id,
              active: true,
              shopifyCollectionId: 0,
              title: 'Best seller',
              collectionId: undefined,
              displayedNumber: 0,
            });
          }
        },
      }
    );
  };

  return (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (isLoadingAddSection) {
      return dispatch(setLoadingPreview(true));
    }

    if (
      source.droppableId === 'device-preview' &&
      destination?.droppableId === 'device-preview'
    ) {
      if (destination.index !== 0) {
        handleRearrangeSection(source.index, destination.index);
      }
    } else if (source.droppableId === 'active-bottom') {
      const clone = cloneDeep(bottomNavData?.unActive || []);
      const cloneActive = cloneDeep(bottomNavData?.active || []);
      if (destination?.droppableId === 'non-active-bottom') {
        clone.splice(destination.index, 0, cloneActive[source.index]);
        cloneActive.splice(source.index, 1);
        rearrangeNavigation({ active: cloneActive, nonActive: clone });
      } else if (destination?.droppableId === 'active-bottom') {
        const cloneData = JSON.parse(JSON.stringify(bottomNavData?.active));
        const [reorderedItem] = cloneData.splice(source.index, 1);
        cloneData.splice(destination.index, 0, reorderedItem);
        rearrangeNavigation({
          active: cloneData,
          nonActive: bottomNavData?.unActive || [],
        });
      }
    } else if (source.droppableId === 'non-active-bottom') {
      const clone = cloneDeep(bottomNavData?.unActive || []);
      const cloneActive = cloneDeep(bottomNavData?.active || []);
      if (destination?.droppableId === 'active-bottom') {
        cloneActive.splice(destination.index, 0, clone[source.index]);
        clone.splice(source.index, 1);
        rearrangeNavigation({ active: cloneActive, nonActive: clone });
      } else if (destination?.droppableId === 'non-active-bottom') {
        const cloneData = cloneDeep(bottomNavData?.unActive || []);
        const [reorderedItem] = cloneData.splice(source.index, 1);
        cloneData.splice(destination.index, 0, reorderedItem);
        client.setQueryData(['navigations', activeCompany], {
          active: bottomNavData?.active,
          unActive: cloneData,
        });
      }
    } else if (
      destination?.droppableId === 'device-preview' &&
      source.droppableId === 'layout-contents'
    ) {
      handleAddSection(draggableId as keyof ModalState, destination.index || 1);
    }
    dispatch(setDraggingDroppable(null));
  };
};
