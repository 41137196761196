import React from 'react';
import { BitLinkIconDefault as LinkIcon } from 'assets/icons/BitLinkIcon';
import { twMerge as cx } from 'tailwind-merge';
import { PublishStatus } from 'types/bitApp/v2';
import { toast } from 'utils/common/toast';

import { BBBButton } from '@/components/ui';
import BBBAccordion from '@/components/ui/BBBAccordion';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';

type Props = {
  statusIos: PublishStatus | null;
  statusAndroid: PublishStatus | null;
  urlIos: string | null;
  urlAndroid: string | null;
  onClickResubmit?: () => void;
  rejectedMessageAndroid: string | null;
  rejectedMessageIos: string | null;
};

export default function SubmissionTracker({
  statusAndroid,
  statusIos,
  urlAndroid,
  urlIos,
  onClickResubmit,
  rejectedMessageAndroid,
  rejectedMessageIos,
}: Props) {
  return (
    <BBBAccordion>
      {statusAndroid === 'live' ? (
        <StatusLive type="google" urlAndroid={urlAndroid} />
      ) : (
        <StatusOnGoing
          type="google"
          status={statusAndroid}
          rejectedMessage={rejectedMessageAndroid}
        />
      )}
      {statusIos === 'live' ? (
        <StatusLive type="apple" urlIos={urlIos} />
      ) : (
        <StatusOnGoing
          type="apple"
          status={statusIos}
          rejectedMessage={rejectedMessageIos}
        />
      )}

      {(statusAndroid === 'rejected' || statusIos === 'rejected') &&
        typeof onClickResubmit !== undefined && (
          <BBBAccordion.Item
            collapse={true}
            hideChevron
            hideToggle
            content={
              <div className="flex justify-end items-end">
                <BBBButton text="Re-submit app" onClick={onClickResubmit} />
              </div>
            }
          />
        )}
    </BBBAccordion>
  );
}

function StatusOnGoing({
  type,
  status,
  rejectedMessage,
}: {
  type: 'google' | 'apple';
  status: PublishStatus | null;
  rejectedMessage: string | null;
}) {
  return (
    <BBBAccordion.Item
      id={`${type === 'google' ? 'android' : 'ios'}-submission`}
      title={`${type === 'google' ? 'Android' : 'iOS'} Submission`}
      collapse={true}
      subtitle={`It often takes up to 7 business days for your app to be published, depending on ${
        type === 'google' ? 'Google’s' : "Apple's"
      } review processes.`}
      leftChildren={type === 'google' ? <AndroidIcon /> : <AppleIcon />}
      hideChevron
      content={
        <Progress
          type={type}
          status={status}
          rejectedMessage={rejectedMessage}
        />
      }
    />
  );
}

function StatusLive({
  type,
  urlAndroid,
  urlIos,
}: { type: 'google' | 'apple' } & Partial<
  Pick<Props, 'urlAndroid' | 'urlIos'>
>) {
  return (
    <BBBAccordion.Item
      title={
        <div className="flex items-center gap-2">
          <p className="text-xl text-primary-main">
            {type === 'google' ? 'Android' : 'iOS'}
          </p>
          <BBBBadge type="success" text="Live" />
          <LinkIcon
            size={16}
            onClick={() =>
              urlAndroid || urlIos
                ? window.open(
                    type === 'google' ? `${urlAndroid}` : `${urlIos}`,
                    '_blank'
                  )
                : toast.error('URL not found')
            }
          />
        </div>
      }
      leftChildren={<LiveIcon />}
      hideChevron
    />
  );
}

const statusToPercentageMap: Record<PublishStatus, number> = {
  submitted: 25,
  submittedToStore: 50,
  review: 75,
  rejected: 75,
  approved: 90,
  live: 100,
};

const appLabelMap: Record<string, string> = {
  google: 'Google',
  apple: 'Apple',
};

const statusLabelMap: Record<
  string,
  (type?: string, rejectedMessage?: string | null) => string
> = {
  submitted: () => 'Your app is submitted',
  submittedToStore: () => 'Your app is already submitted to play / app store',
  review: (type) =>
    `Your app is currently being reviewed by ${appLabelMap[type!]}`,
  live: (type) =>
    `Your app is live on ${
      type === 'google' ? 'Google Play Store' : 'Apple App Store'
    }.`,
  approved: (type) => `Your app has been approved by ${appLabelMap[type!]}.`,
  rejected: () =>
    'Your app is rejected. Please make appropriate changes to your app and re-submit.',
};

function Progress({
  status,
  type,
  rejectedMessage,
}: {
  status: PublishStatus | null;
  type: 'google' | 'apple';
  rejectedMessage?: string | null;
}) {
  const parseStatusToPercentage = (status: PublishStatus): number => {
    return statusToPercentageMap[status] || 25;
  };

  const getStatusLabel = (
    status: string,
    type: string,
    rejectedMessage?: string | null
  ): string => {
    const labelGenerator = statusLabelMap[status];

    return labelGenerator(type, rejectedMessage);
  };

  if (!status) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1 mt-6">
      <p className="text-neutral-60 text-sm">
        ({parseStatusToPercentage(status)})%{' '}
        {getStatusLabel(status, type, rejectedMessage)}
      </p>
      <div className="relative h-2 w-full rounded overflow-clip bg-neutral-30">
        <div
          className={cx(
            'absolute h-2 rounded ',
            status === 'rejected' ? 'bg-danger-main' : 'bg-primary-main'
          )}
          style={{ width: `${parseStatusToPercentage(status)}%` }}
        />
      </div>
      {status === 'rejected' && (
        <div className="mt-5">
          <p className="text-neutral-60">Rejection Reason:</p>
          <p className="text-neutral-60 ml-4">{rejectedMessage}</p>
        </div>
      )}
    </div>
  );
}

function AndroidIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2192_3099)">
        <path
          d="M19.0917 4.11614C19.3524 4.24665 19.5506 4.47533 19.6428 4.75192C19.735 5.02852 19.7137 5.3304 19.5834 5.59124L18.292 8.17624C19.7462 9.18911 20.9339 10.5387 21.7537 12.1099C22.5735 13.6811 23.0011 15.4273 23 17.1995V18.2995C23 18.883 22.7682 19.4426 22.3556 19.8552C21.9431 20.2678 21.3835 20.4995 20.8 20.4995H3.2C2.61653 20.4995 2.05695 20.2678 1.64437 19.8552C1.23179 19.4426 1 18.883 1 18.2995V17.1995C0.998632 15.4271 1.42609 13.6807 2.2459 12.1092C3.06571 10.5378 4.25353 9.18805 5.708 8.17514L4.4166 5.59124C4.29673 5.33174 4.28258 5.03576 4.37715 4.76601C4.47173 4.49626 4.6676 4.27391 4.92327 4.14608C5.17894 4.01824 5.47434 3.99495 5.74689 4.08114C6.01943 4.16734 6.24772 4.35624 6.3834 4.60784L7.6308 7.10154C9.00999 6.5046 10.4972 6.19758 12 6.19954C13.5521 6.19954 15.0305 6.52074 16.3692 7.10154L17.6166 4.60784C17.7471 4.34713 17.9758 4.14889 18.2524 4.05669C18.529 3.9645 18.8309 3.98588 19.0917 4.11614ZM12 8.39954C9.6661 8.39954 7.42778 9.32668 5.77746 10.977C4.12714 12.6273 3.2 14.8656 3.2 17.1995V18.2995H20.8V17.1995C20.8 14.8656 19.8729 12.6273 18.2225 10.977C16.5722 9.32668 14.3339 8.39954 12 8.39954ZM8.15 12.7995C8.58761 12.7995 9.00729 12.9734 9.31673 13.2828C9.62616 13.5922 9.8 14.0119 9.8 14.4495C9.8 14.8871 9.62616 15.3068 9.31673 15.6163C9.00729 15.9257 8.58761 16.0995 8.15 16.0995C7.71239 16.0995 7.29271 15.9257 6.98327 15.6163C6.67384 15.3068 6.5 14.8871 6.5 14.4495C6.5 14.0119 6.67384 13.5922 6.98327 13.2828C7.29271 12.9734 7.71239 12.7995 8.15 12.7995ZM15.85 12.7995C16.2876 12.7995 16.7073 12.9734 17.0167 13.2828C17.3262 13.5922 17.5 14.0119 17.5 14.4495C17.5 14.8871 17.3262 15.3068 17.0167 15.6163C16.7073 15.9257 16.2876 16.0995 15.85 16.0995C15.4124 16.0995 14.9927 15.9257 14.6833 15.6163C14.3738 15.3068 14.2 14.8871 14.2 14.4495C14.2 14.0119 14.3738 13.5922 14.6833 13.2828C14.9927 12.9734 15.4124 12.7995 15.85 12.7995Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2192_3099">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function AppleIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9974 22.0076C16.8344 23.135 15.5645 22.957 14.3422 22.4229C13.0486 21.877 11.8618 21.8533 10.497 22.4229C8.78802 23.1588 7.88607 22.9451 6.86544 22.0076C1.07395 16.0381 1.92843 6.94732 8.5032 6.61503C10.1054 6.6981 11.2209 7.49324 12.1585 7.56445C13.5589 7.27962 14.8999 6.46074 16.3953 6.56755C18.1873 6.70997 19.5403 7.42204 20.4303 8.70376C16.7276 10.923 17.6058 15.8007 21 17.1655C20.3235 18.9457 19.4453 20.714 17.9856 22.0194L17.9974 22.0076ZM12.0398 6.54382C11.8618 3.8973 14.0099 1.71362 16.4784 1.5C16.8225 4.56189 13.7013 6.84051 12.0398 6.54382Z"
        fill="black"
      />
    </svg>
  );
}

function LiveIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#79D64F" />
      <path
        d="M16.1347 8.28694C16.3421 8.10086 16.618 7.99799 16.9043 8.00003C17.1906 8.00207 17.4649 8.10887 17.6692 8.29788C17.8736 8.4869 17.992 8.74335 17.9996 9.01312C18.0072 9.28289 17.9033 9.54488 17.7098 9.74381L11.8363 16.6674C11.7353 16.7699 11.6134 16.8522 11.4779 16.9093C11.3424 16.9665 11.196 16.9972 11.0476 16.9998C10.8992 17.0024 10.7518 16.9768 10.6141 16.9244C10.4765 16.8721 10.3514 16.7941 10.2465 16.6952L6.35139 13.0238C6.24292 12.9286 6.15592 12.8137 6.09558 12.686C6.03523 12.5584 6.00279 12.4206 6.00017 12.2809C5.99756 12.1411 6.02483 12.0024 6.08035 11.8728C6.13588 11.7432 6.21853 11.6255 6.32337 11.5267C6.42821 11.4279 6.55309 11.35 6.69056 11.2976C6.82803 11.2453 6.97528 11.2196 7.12352 11.2221C7.27176 11.2245 7.41796 11.2551 7.55338 11.312C7.68881 11.3689 7.8107 11.4509 7.91177 11.5531L10.9943 14.4571L16.1067 8.31746C16.1159 8.30678 16.1258 8.29659 16.1362 8.28694H16.1347Z"
        fill="white"
      />
    </svg>
  );
}
