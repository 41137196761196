import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useBeforeUnload = (show?: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (show) {
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [show]);
};

export const useHistoryChange = (
  block: boolean,
  callback: (cb: () => void) => void
): void => {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location) => {
      //@ts-ignore
      if (!location.state?.key?.includes('shortcut')) {
        if (block) {
          callback(() => history.push(location));

          return false;
        }
      }
    });

    return () => {
      unblock();
    };
  }, [history, callback, block]);
};
