import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import {
  automationButtonContentOptionsHash,
  buttonTypeOptionsHash,
  callToActionsOptionsHash,
} from 'constants/bitCRM/template';
import { acceptedCrmFileTypes } from 'constants/crm';
import { EditorState } from 'draft-js';
import { useWhatsappCloudIntegration } from 'hooks/bitChat/integration/integrations';
import useUpdateWACloudTemplate from 'hooks/bitChat/settings/useUpdateWACloudTemplate';
import useWACloudTemplate from 'hooks/bitChat/settings/useWACloudTemplate';
import {
  useCreateOrUpdateTemplate,
  useLanguages,
  useTemplate,
} from 'hooks/bitCRM/template/template';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import useCustomForm from 'hooks/common/useCustomForm';
import useResponsive from 'hooks/common/useResponsive';
import {
  ButtonCtaType,
  CampaignTemplate,
  CTAButtonType,
  MessageTemplateButtonType,
  MessageTemplateType,
} from 'types/bitCRM/template';
import { FileType } from 'types/utils/file';
import { Nullable } from 'types/utils/nullable';
import * as yup from 'yup';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from 'utils/common/rich';
import {
  createOtpRichValidation,
  richValidation,
} from 'utils/common/validation';
import CampaignMessage from '../../../BitCRM/components/CampaignMessage';
import CampaignWrapper from '../../../BitCRM/components/Wrapper';
import AIGenerate from './components/AIGenerate';
import ButtonType from './components/ButtonType';
import Footer from './components/Footer';

import {
  BBBCard,
  BBBFileUpload,
  BBBPrimarySwitch,
  BBBSelect,
  BBBTextInput,
} from '@/components/ui';
import { useCollection } from '@/hooks/bitCRM/shopify';
import useUpdateTemplate from '@/hooks/bitLogin/useUpdateTemplate';
import { useConnectChannelConfirmation } from '@/hooks/whatsApp/channels';
import { useForms } from '@/hooks/whatsApp/form';
import { ShopifyCollectionForm } from '@/pages/BitCRM/components/Shopify/CollectionListOptions';
import { ShopifyProductForm } from '@/pages/BitCRM/components/Shopify/ProductListOptions';
import { TemplateBodyParameters } from '@/types/bitCRM/template';
import { FormTemplate } from '@/types/whatsApp/form';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

type ButtonTypeForm = {
  label: string;
  value: MessageTemplateButtonType;
};

type BringCustomerToForm = {
  label: string;
  value: ButtonCtaType;
};

type WhatsAppFormType = {
  metaId: FormTemplate['metaId'];
  name: FormTemplate['name'];
};

export const defaultReplyButtons = [
  {
    text: '',
  },
];

export type FormSchema = {
  id?: string;
  campaignName: string;
  fileUrl?: Nullable<FileType>;
  language?: { label: string; value: string } | null;
  body: {
    message: EditorState;
    parameters: TemplateBodyParameters[];
  };
  hasButtonType?: boolean;
  buttonType?: ButtonTypeForm | null;
  buttonText: string;
  whatsAppForm?: WhatsAppFormType | null;
  bringCustomerTo?: BringCustomerToForm | null;
  customUrl: string | null;
  collection?: ShopifyCollectionForm | null;
  product?: ShopifyProductForm | null;
  ctaButtonContent: { label: string; value: string } | null;
  replyButtons: { text: string }[];
};

const getBaseSchema = (type: TemplateType) => {
  return {
    id: yup.string(),
    campaignName: yup.string().required().label('Template name'),
    fileUrl: yup.mixed<Nullable<FileType>>(),
    body: yup.object().shape({
      message: type === 'bitlogin' ? createOtpRichValidation() : richValidation,
      parameters: yup.array().of(
        yup.object().shape({
          value: yup.string().required('Param is required'),
        })
      ),
    }),
    hasButtonType: yup.bool(),
    buttonType: yup
      .mixed<ButtonTypeForm | null>()
      .label('Button type')
      .when('hasButtonType', {
        is: true,
        then: (rule) => rule.required(),
      }),
    buttonText: yup
      .string()
      .label('Button text')
      .when('buttonType', {
        is: (value: ButtonTypeForm | null) =>
          value && value.value !== 'reply_button',
        then: (rule) => rule.required(),
      }),
    replyButtons: yup
      .array()
      .label('Button text')
      .of(
        yup.object().shape({
          text: yup.string(),
        })
      )
      .min(1, 'At least one reply button is required')
      .max(3, 'Maximum of 3 reply buttons'),
    whatsAppForm: yup
      .mixed<Pick<FormTemplate, 'id' | 'name'>>()
      .label('Whatsapp form')
      .when('buttonType', {
        is: (val: ButtonTypeForm | null) => val?.value === 'whatsapp_form',
        then: (rule) => rule.required(),
      }),
  };
};

const buttonSchemaDefault = {
  bringCustomerTo: yup.mixed<BringCustomerToForm>().when('buttonType', {
    is: (val: ButtonTypeForm | null) => val?.value === 'call_to_action',
    then: (rule) => rule.required(),
  }),
  customUrl: yup
    .string()
    .nullable()
    .label('URL')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => {
        return val?.value === 'web_url';
      },
      then: (rule) => rule.required(),
    }),
  collection: yup
    .mixed<ShopifyCollectionForm>()
    .nullable()
    .label('Collection')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => val?.value === 'collections',
      then: (rule) => rule.required(),
    }),
  product: yup
    .mixed<ShopifyProductForm>()
    .nullable()
    .label('Product')
    .when('bringCustomerTo', {
      is: (val: BringCustomerToForm | null) => val?.value === 'product',
      then: (rule) => rule.required(),
    }),
};

const buttonSchemaAutomation = {
  ctaButtonContent: yup
    .mixed()
    .label('Button content')
    .when('buttonType', {
      is: (val: ButtonTypeForm | null) => val?.value === 'call_to_action',
      then: (rule) => rule.required(),
    }),
};

export const typePayload = (type: TemplateType) =>
  `${type.toUpperCase()}_WHATSAPP_CLOUD_API` as MessageTemplateType;

const defaultFormSchema: FormSchema = {
  campaignName: '',
  fileUrl: undefined,
  body: {
    message: emptyEditor,
    parameters: [],
  },
  language: null,
  id: undefined,
  hasButtonType: false,
  buttonType: null,
  buttonText: '',
  whatsAppForm: null,
  bringCustomerTo: null,
  customUrl: '',
  collection: null,
  product: null,
  ctaButtonContent: null,
  replyButtons: defaultReplyButtons,
};

const formatFormToCRMPayload = (
  data: FormSchema & {
    type: MessageTemplateType;
  }
) =>
  ({
    id: data.id === 'new' ? undefined : data.id,
    templateName: data.campaignName,
    language: data.language?.value,
    footer: 'Powered by bitbybit',
    body: {
      message: convertEditorStateToHtml(data.body.message),
      parameters: data.body.parameters.map((sample) => ({
        sample: sample.placeholder,
      })),
    },
    params: data.body.parameters,
    button: undefined,
    header: data.fileUrl?.remoteUrl
      ? {
          type: 'MEDIA',
          message: 'header message',
          imageUrl: data.fileUrl.remoteUrl,
        }
      : undefined,
    hasButtonType: data.hasButtonType,
    buttonType: data.buttonType?.value,
    buttonText: data.buttonText,
    customUrl: data.customUrl,
    ctaType: data.bringCustomerTo?.value,
    collectionId: data.collection?.id,
    productId: data.product?.id,
    whatsAppFormId: data.whatsAppForm?.metaId,
    ctaButtonContent: data.ctaButtonContent?.value || null,
    replyButtons: data.replyButtons
      .filter((reply) => !!reply.text)
      .map((reply) => reply.text),
    type: data.type,
  } as Partial<CampaignTemplate>);

const formatFormToChatPayload = (
  data: FormSchema
): Partial<WACloudTemplate> => ({
  message: convertEditorStateToHtml(data.body.message),
  params: data.body.parameters,
  language: data.language?.value,
  name: data.campaignName,
  hasButtonType: data.hasButtonType,
  buttonType: data.buttonType?.value,
  buttonText: data.buttonText,
  whatsAppFormId: data.whatsAppForm?.metaId,
  customUrl: data.customUrl,
  ctaType: data.bringCustomerTo?.value,
  replyButtons: data.replyButtons
    .filter((reply) => !!reply.text)
    .map((reply) => reply.text),
});

type ChatProps = {
  source: 'whatsapp-cloud';
  type: 'chat';
};

type BitloginProps = {
  source: 'whatsapp-cloud';
  type: 'bitlogin';
};

type CRMProps = {
  source: 'whatsapp-cloud';
  type: 'campaign' | 'automation';
};

type Props = CRMProps | ChatProps | BitloginProps;

export type TemplateType = 'campaign' | 'automation' | 'chat' | 'bitlogin';

const mapTemplateTypeToBackUrl: Record<TemplateType, string> = {
  automation: '/misc/whatsapp-api-settings?section=automation',
  bitlogin: '/misc/whatsapp-api-settings?section=bitlogin-whatsapp-settings',
  campaign: '/misc/whatsapp-api-settings?section=campaign',
  chat: '/misc/whatsapp-api-settings?section=chat-initiation',
};

export default function WhatsAppTemplate(
  props:
    | {
        source: 'whatsapp-cloud';
        type?: 'campaign' | 'automation';
      }
    | {
        source: 'whatsapp-cloud';
        type?: 'chat';
      }
    | {
        source: 'whatsapp-cloud';
        type?: 'bitlogin';
      }
) {
  const query = useQuerySearchParams();

  const type = (query.get('section') || props.type) as Props['type'];

  if (!['campaign', 'automation', 'chat', 'bitlogin'].includes(type) && props) {
    throw new Error('Invalid type query specified');
  }

  return <_WhatsAppTemplateCheckType {...({ ...props, type } as Props)} />;
}

function _WhatsAppTemplateCheckType({ ...props }: Props) {
  if (props.type === 'chat') return <_WhatsAppTemplateChat />;
  if (props.type === 'bitlogin') return <_WhatsAppTemplateBitlogin />;

  return <WhatsAppTemplateDefault {...props} />;
}

function _WhatsAppTemplateChat() {
  const { data } = useWACloudTemplate();

  return <_WhatsAppTemplate type="chat" source="whatsapp-cloud" data={data} />;
}

function _WhatsAppTemplateBitlogin() {
  const { data } = useWACloudTemplate();

  return (
    <_WhatsAppTemplate type="bitlogin" source="whatsapp-cloud" data={data} />
  );
}

function WhatsAppTemplateDefault(props: CRMProps) {
  return <_WhatsAppTemplateCheckConnection {...props} />;
}

function _WhatsAppTemplateCheckConnection({ ...props }: CRMProps) {
  const { id } = useParams<{ id: string }>();

  const { data } = useTemplate(id);
  const {
    data: whatsAppCloudApiIntegrationData,
    status: whatsAppCloudApiIntegrationStatus,
  } = useWhatsappCloudIntegration({
    enabled: props.source === 'whatsapp-cloud',
  });

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  if (
    props.source === 'whatsapp-cloud' &&
    whatsAppCloudApiIntegrationStatus === 'success' &&
    !isWhatsAppCloudConnected
  ) {
    return (
      <Redirect
        to={{
          pathname: `/misc`,
          search: `section=${props.type}&message=check-connection-failed`,
        }}
      />
    );
  }

  return <_WhatsAppTemplate {...props} data={data} />;
}

function _WhatsAppTemplate({
  source,
  type,
  data,
}:
  | (CRMProps & {
      data?: CampaignTemplate | undefined;
    })
  | (ChatProps & {
      data?: WACloudTemplate | undefined;
    })
  | (BitloginProps & {
      data?: WACloudTemplate | undefined;
    })) {
  const { id } = useParams<{ id: string }>();
  const query = useQuerySearchParams();
  const isEdit = id !== 'new';
  const section = query.get('section');

  const { data: cloudApiIntegration } = useWhatsappCloudIntegration({
    enabled: type === 'chat',
  });

  const baseSchema = getBaseSchema(type);

  const schema = yup.object().shape({
    ...baseSchema,
    ...(type === 'automation' ? buttonSchemaAutomation : buttonSchemaDefault),
  });

  const { handleSubmit, formState, control, setValue, watch, reset } =
    useCustomForm<FormSchema>({
      resolver: yupResolver(schema),
      defaultValues: defaultFormSchema,
    });

  const { mutate: updateOrCreate, isLoading: loadingSave } =
    useCreateOrUpdateTemplate({ section });

  const { mutate: updateWACloudTemplate, isLoading: loadingUpdateWACloud } =
    useUpdateWACloudTemplate();

  const { mutate: updateBitloginTemplate, isLoading: loadingUpdateBitlogin } =
    useUpdateTemplate();

  const { data: languageData, isInitialLoading: isLoadingLanguage } =
    useLanguages({
      enabled: source === 'whatsapp-cloud',
    });

  const whatsAppFormsQuery = useForms();

  const parseCollectionId =
    data && 'collectionId' in data && data.collectionId
      ? parseInt(data.collectionId.replace('gid://shopify/Collection/', ''), 10)
      : undefined;

  const { data: collection } = useCollection(parseCollectionId!, {
    enabled: !!parseCollectionId,
  });

  const parseProductId =
    data && 'productId' in data && data.productId
      ? parseInt(data.productId.replace('gid://shopify/Product/', ''), 10)
      : undefined;

  const { data: product } = useCollection(parseProductId!, {
    enabled: !!parseProductId,
  });

  const dataFromApi = useMemo<FormSchema>(() => {
    const mediaUrl =
      type === 'chat' || type === 'bitlogin' ? null : data?.header?.imageUrl;
    const message =
      type === 'chat' || type === 'bitlogin'
        ? data?.message
        : data?.body?.message;
    const id = data?.id;
    const campaignName =
      (type === 'chat' || type === 'bitlogin'
        ? data?.name
        : data?.templateName) || '';
    const language =
      languageData?.find((opt) => opt.value === data?.language) || null;
    const body = {
      message: convertHtmlToEditorState(message),
      parameters:
        type === 'chat' || type === 'bitlogin'
          ? []
          : data?.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
            data?.type === 'AUTOMATION_WHATSAPP_CLOUD_API'
          ? data.params?.filter((param) => !!param) || []
          : [],
    };
    const hasButtonType = !!data?.buttonType;
    const buttonType = data?.buttonType
      ? buttonTypeOptionsHash[data.buttonType]
      : null;
    const buttonText = data?.buttonText || defaultFormSchema.buttonText;
    const whatsAppForm =
      whatsAppFormsQuery.data?.find(
        (form) => form.metaId === data?.whatsAppFormId
      ) || null;
    const bringCustomerTo =
      data?.buttonType === 'call_to_action' && data?.ctaType
        ? callToActionsOptionsHash[data.ctaType]
        : defaultFormSchema.bringCustomerTo;
    const customUrl =
      data?.buttonType === 'call_to_action' && data?.customUrl
        ? data.customUrl
        : defaultFormSchema.customUrl;

    return {
      id,
      campaignName,
      fileUrl: mediaUrl ? convertRemoteUrlToFileType(mediaUrl) : undefined,
      language,
      body,
      hasButtonType,
      buttonType,
      buttonText,
      whatsAppForm,
      bringCustomerTo,
      customUrl,
      collection,
      product,
      ctaButtonContent:
        type === 'automation' && data?.ctaButtonContent
          ? automationButtonContentOptionsHash[data.ctaButtonContent]
          : null,
      replyButtons:
        data && 'replyButtons' in data && data.replyButtons?.length
          ? data.replyButtons.map((reply) => ({ text: reply }))
          : defaultFormSchema.replyButtons,
    };
  }, [data, languageData, type, whatsAppFormsQuery.data, collection, product]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const confirmConnect = useConnectChannelConfirmation();
  const confirm = useConfirmationModal();

  function submitTemplate(data: FormSchema, isDraft: boolean) {
    if (isDraft) {
      updateWACloudTemplate({
        ...formatFormToChatPayload(data),
        isDraft: true,
      });
    } else {
      confirm({
        title: 'Submit template',
        description:
          'Your template will undergo review by Meta and typically takes 1-3 days for approval. Are you ready to submit the template now?',
        onAccept: (hide) => {
          updateWACloudTemplate({ ...formatFormToChatPayload(data) });
          hide();
        },
        submitText: 'Submit',
      });
    }
  }

  function submitBitloginTemplate(data: FormSchema, isDraft: boolean) {
    if (isDraft) {
      updateBitloginTemplate({
        ...formatFormToChatPayload(data),
        isDraft: true,
      });
    } else {
      confirm({
        title: 'Submit template',
        description:
          'Your template will undergo review by Meta and typically takes 1-3 days for approval. Are you ready to submit the template now?',
        onAccept: (hide) => {
          updateBitloginTemplate({
            ...formatFormToChatPayload(data),
            isDraft: false,
          });
          hide();
        },
        submitText: 'Submit',
      });
    }
  }

  function updateTemplate(
    payload: Partial<CampaignTemplate>,
    isDraft: boolean,
    leaveCb?: () => void
  ) {
    confirm({
      title: 'Are you sure you want to update the template?',
      description: (
        <>
          Please note that you can only edit and update automation templates
          once <span className="font-bold">every 24 hours</span>. Updating
          template requires resubmission to Meta for verification.
        </>
      ),
      onAccept: (hide) => {
        updateOrCreate({
          ...payload,
          isDraft,
          leaveCb,
        });
        hide();
      },
      submitText: 'Yes, update template',
    });
  }

  const _handleSave = (
    data: FormSchema,
    isDraft: boolean,
    leaveCb?: () => void
  ) => {
    if (type === 'chat') {
      if (!cloudApiIntegration) {
        confirmConnect('whatsapp_meta', 'template');
      } else {
        submitTemplate(data, isDraft);
      }
    } else if (type === 'bitlogin') {
      if (!cloudApiIntegration) {
        confirmConnect('whatsapp_meta', 'template');
      } else {
        submitBitloginTemplate(data, isDraft);
      }
    } else {
      const payload = formatFormToCRMPayload({
        ...data,
        id,
        type: typePayload(type),
      });

      if (isEdit) {
        return updateTemplate(payload, isDraft, leaveCb);
      }

      updateOrCreate({
        ...payload,
        isDraft,
        leaveCb,
      });
    }
  };

  const _handleSubmit = () => {
    handleSubmit((data) => _handleSave(data, false))();
  };

  const handleSubmitDraft = (leaveCb?: () => void) => {
    _handleSave(watch(), true, leaveCb);
  };

  const isMobile = useResponsive('sm');

  const campaignTitle = `${
    type === 'automation'
      ? 'Automation'
      : type === 'campaign'
      ? 'Campaign'
      : type === 'chat'
      ? 'Chat Initiation'
      : 'OTP - WhatsApp Cloud API'
  } Template`;

  const disableAutomationInput =
    (type === 'automation' || type === 'campaign') && isEdit;

  return (
    <CampaignWrapper
      title={campaignTitle}
      description={`Prepare your message before you send it to customers`}
      control={control}
      type="template-wa-reply"
      backUrl={mapTemplateTypeToBackUrl[type]}
    >
      <BBBCard
        id="create-template"
        title={`${isEdit ? 'Update' : 'Create'} template`}
        desc={`${
          isEdit
            ? 'Updating template can only be done once every 24 hours.'
            : type === 'bitlogin'
            ? 'Create your OTP template here.'
            : 'Create your template here. Use variables and media according to your needs.'
        }`}
        className={isMobile ? 'rounded-none' : ''}
      >
        <BBBTextInput
          placeholder="Type your template name"
          label="Template name"
          isHookForm
          control={control}
          controlName="campaignName"
          error={formState.errors.campaignName?.message}
          containerClassname="mb-6"
          disabled={disableAutomationInput}
        />
        {source === 'whatsapp-cloud' && (
          <Controller
            name="language"
            control={control}
            render={({ field: { value, onChange } }) => (
              <BBBSelect
                options={languageData}
                placeholder="Choose language"
                onValueChange={onChange}
                value={value}
                label="Languages"
                optionLabel="label"
                optionValue="value"
                loading={isLoadingLanguage}
                error={formState.errors.language?.message}
                containerClassName="mb-6"
                isSearchable
                isDisabled={disableAutomationInput}
              />
            )}
          />
        )}

        {type === 'campaign' && (
          <AIGenerate
            onGenerated={(data) =>
              setValue('body', {
                message: convertHtmlToEditorState(data),
                parameters: [],
              })
            }
          />
        )}

        <Controller
          control={control}
          name="body"
          render={({ field }) => (
            <CampaignMessage
              editorState={field.value.message}
              onChangeEditorState={(message) => {
                field.onChange({ ...field.value, message });
              }}
              source={source}
              params={field.value.parameters}
              onChangeParams={(parameters) => {
                field.onChange({ ...field.value, parameters });
              }}
              onChange={({ params, editorState }) => {
                field.onChange({
                  ...field.value,
                  parameters: params,
                  message: editorState,
                });
              }}
              withoutDynamicVariable={type === 'bitlogin'}
              //@ts-ignore
              error={formState.errors.body?.message?.message}
              type={type}
              //@ts-ignore
              paramErrors={formState.errors.body?.parameters?.map(
                (param) => param?.value?.message || ''
              )}
            />
          )}
        />

        {type !== 'chat' && type !== 'bitlogin' && (
          <div id="template-media" className="mb-5">
            <Controller
              name="fileUrl"
              control={control}
              render={({ field }) => (
                <BBBFileUpload
                  isSingle
                  files={field.value}
                  onChangeFile={field.onChange}
                  accept={acceptedCrmFileTypes}
                  withLabel
                />
              )}
            />
          </div>
        )}

        {source === 'whatsapp-cloud' && type !== 'bitlogin' && (
          <div className="flex flex-col gap-6">
            <Controller
              name="hasButtonType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <BBBPrimarySwitch
                  checked={value}
                  onChange={onChange}
                  label="Button"
                  description={`Add reply, redirect, or form button to your ${
                    type === 'chat' ? 'template' : 'campaign'
                  }`}
                />
              )}
            />
            {watch('hasButtonType') && (
              <ButtonType control={control} setValue={setValue} type={type} />
            )}
          </div>
        )}
      </BBBCard>
      <Footer
        control={control}
        dataFromApi={dataFromApi}
        onSubmit={_handleSubmit}
        onSubmitDraft={handleSubmitDraft}
        loadingSave={
          loadingSave || loadingUpdateWACloud || loadingUpdateBitlogin
        }
      />
    </CampaignWrapper>
  );
}
