import { env } from 'config/env';
import { v4 } from 'uuid';

export const suggestionContent = [
  {
    id: v4(),
    app: 'bitChat',
    title: 'bitChat - WhatsApp AI Chatbot',
    subtitle:
      'The best of WhatsApp + AI in minutes. AI Chatbot for Support, Sales, Quiz. Chat Inbox with ChatGPT.',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitChat.png`,
    link: '/bitchat',
  },
  {
    id: v4(),
    app: 'bitCRM',
    title: 'bitCRM - WhatsApp Abandoned Cart',
    subtitle:
      'Recover abandoned carts, collect reviews, and send personalized marketing messages via WhatsApp.',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitCRM.png`,
    link: '/bitcrm',
  },
  {
    id: v4(),
    app: 'bitApp',
    title: 'bitApp - Free Mobile App Builder',
    subtitle:
      'Launch a modern iOS & Android App for your store. Generate more sales & increase conversion in hours',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitApp.png`,
    link: '/bitapp',
  },
  {
    id: v4(),
    app: 'bitLink',
    title: 'bitLink - Shoppable Link Bio',
    subtitle:
      'Your brand, your domain. Use your own domain for your link-in-bio for free.',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitLink.png`,
    link: '/bitlink',
  },
  {
    id: v4(),
    app: 'bitLogin',
    title: 'bitLogin - Social Login',
    subtitle:
      'Unlimited Login with Apple, Google, Facebook & WhatsApp with a single click no registration required.',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitLogin.png`,
    link: '/bitlogin',
  },
  {
    id: v4(),
    app: 'bitAI',
    title: 'bitAI - Advance Sort Collection',
    subtitle:
      'Improve conversion rate. Automate merchandising and sorting collections. Push down Out-Of-Stock.',
    image: `${env.REACT_APP_CDN_URL}/bitbybit/static/home/bitAi.png`,
    link: '/bitai',
  },
];
