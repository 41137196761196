function PremiumIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
    >
      <path
        fill="#FD823E"
        d="M3.464 1.5a.402.402 0 00-.206.057.385.385 0 00-.145.154L1.542 4.757a.37.37 0 00.046.411l5.107 6.092A.39.39 0 007 11.4a.403.403 0 00.305-.14l5.106-6.092a.375.375 0 00.048-.411L10.887 1.71a.385.385 0 00-.145-.154.402.402 0 00-.207-.056h-7.07zM2.53 4.548l1.178-2.285h1.61l-.589 2.285h-2.2zm.189.761h2.03l1.267 3.933-3.297-3.933zm2.852 0h2.86L7 9.742 5.57 5.308zm3.683 0h2.029L7.985 9.241l1.268-3.933zm2.218-.761h-2.2l-.59-2.285h1.612l1.178 2.285zm-3.01 0H5.538l.59-2.285h1.744l.59 2.285z"
      ></path>
    </svg>
  );
}

export default PremiumIcon;
