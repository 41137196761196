import { ReactNode } from 'react';
import { ChevronLeft, EyeOff } from 'react-feather';
import { Link } from 'react-router-dom';

import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import {
  BBBButton,
  BBBCard,
  BBBContainer,
  BBBDeviceFrame,
} from '@/components/ui';
import useBranding from '@/hooks/bitApp/design/useBranding';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useResponsive from '@/hooks/common/useResponsive';
import SocialLoginButtons from '@/pages/BitLogin/SocialLogin/components/SocialLoginButtons';

export default function LoginOptions() {
  return (
    <Layout>
      <BBBCard
        className="my-4"
        title="Enable login button"
        desc={
          <div className="font-light">
            Make login easier with social login buttons.{' '}
            <Link to="/bitlogin/appearance" className="font-medium underline">
              Open bitLogin for more options.
            </Link>
          </div>
        }
        rightButton={<ShopifyAwareWrapper appType="BITLOGIN" />}
      >
        <ShopifyAwareWrapper appType="BITLOGIN" lazyConnect>
          {() => <SocialLoginButtons type="mobile" />}
        </ShopifyAwareWrapper>
      </BBBCard>
    </Layout>
  );
}

type Props = {
  children: ReactNode;
};

function Layout({ children }: Props) {
  const isMobile = useResponsive('sm');
  return (
    <div className="flex items-center gap-4 h-full">
      <BBBContainer
        hasHeading
        pageTitle="Login Options"
        pageDescription="Add login options to your mobile app"
        containerClassName="w-full"
      >
        {children}
      </BBBContainer>
      {!isMobile && (
        <div className="flex items-center justify-center w-full h-full">
          <LivePreview />
        </div>
      )}
    </div>
  );
}

function LivePreview() {
  return (
    <BBBDeviceFrame>
      <_LivePreview />
    </BBBDeviceFrame>
  );
}

function _LivePreview() {
  const { data } = useSocialAppearance();

  return (
    <div className="pt-3 h-full flex flex-col relative select-none">
      <div className="relative mt-8 ml-2">
        <ChevronLeft />
      </div>
      <div className="absolute flex justify-center flex-col gap-2 top-1/2 left-0 right-0 px-7 transform -translate-y-1/2">
        <h5 className="text-start font-bold text-xl">
          WELCOME <br /> BACK
        </h5>
        <p className="text-sm my-4 uppercase">Please log in to continue</p>
        {data?.position === 'top' && (
          <>
            <SocialLoginButtons
              type="mobile"
              livePreview
              containerClassName="gap-3"
            />
            <Divider />
          </>
        )}
        <Form />
        {data?.position === 'bottom' && (
          <>
            <Divider />
            <SocialLoginButtons
              type="mobile"
              livePreview
              containerClassName="gap-3"
            />
          </>
        )}
        <div className="uppercase text-[10px]">
          Don&apos;t have an account?&nbsp;
          <span className="underline">register</span>
        </div>
      </div>
    </div>
  );
}

function Divider() {
  return (
    <div className="relative w-full h-2 mb-3">
      <div className="absolute left-0 right-0 text-center text-[10px] w-fit z-10 mx-auto bg-white px-1">
        OR
      </div>
      <hr className="absolute top-2 bottom-0 right-0 left-0 border-[1px]" />
    </div>
  );
}

function Form() {
  const { data } = useBranding();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        <div className="w-full border-b-[1px] py-2 text-gray-400 text-[12px] uppercase">
          E-mail
        </div>
        <div className="w-full border-b-[1px] py-2 text-gray-400 text-[12px]">
          <div className="flex justify-between items-center uppercase">
            <div>Password</div>
            <EyeOff width={12} height={12} />
          </div>
        </div>
        <BBBButton
          text="Log In"
          className="pointer-events-none !rounded-none"
          backgroundColor={data?.primaryColor || undefined}
        />
        <div className="underline uppercase text-[10px]">
          Forgot your password?
        </div>
      </div>
    </div>
  );
}
