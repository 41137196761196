import { FiBox } from 'react-icons/fi';
import CustomerCartIcon from 'assets/icons/CustomerCartIcon';
import CustomerIcon2 from 'assets/icons/CustomerIcon2';
import OrderHistoryIcon from 'assets/icons/OrderHistoryIcon';
import TicketIcon2 from 'assets/icons/TicketIcon2';
import WebActivityIcon from 'assets/icons/WebActivityIcon';

export const infoPanelMenu = [
  {
    name: 'customer-details',
    title: 'Customer details',
    icon: <CustomerIcon2 className="'w-[1.375rem] h-[1.375rem]" />,
  },
  {
    name: 'tickets',
    title: 'Tickets',
    icon: <TicketIcon2 className="'w-[1.375rem] h-[1.375rem]" />,
  },
  {
    name: 'order-history',
    title: 'Order History',
    icon: <OrderHistoryIcon className="'w-[1.375rem] h-[1.375rem]" />,
  },
  {
    name: 'web-activity',
    title: 'Web Activity',
    icon: <WebActivityIcon className="'w-[1.375rem] h-[1.375rem]" />,
  },
  {
    name: 'customer-cart',
    title: `Customer's Cart`,
    icon: <CustomerCartIcon className="'w-[1.375rem] h-[1.375rem]" />,
  },
  {
    name: 'sandbox',
    title: `Sandbox`,
    icon: <FiBox size={22} />,
  },
];
