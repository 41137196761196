import React from 'react';
import { useHistory, useLocation } from 'react-router';
import AiAgent from './components/AiAgent';
import AiTagging from './components/AiAgent/AiTagging';

import { BBBContainer } from '@/components/ui';
import BBBNavigationCard from '@/components/ui/BBBNavigationCard/BBBNavigationCard';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import { cn } from '@/utils/styles';

const aiSettingsNavigation = [
  { label: 'AI agents & skillset', value: 'ai-agent-skillsets' },
  { label: 'AI tagging', value: 'ai-tagging' },
];

const defaultNavigation = aiSettingsNavigation[0].value;

export default function AiSettings() {
  const { data: isRestricted } = useIsModuleRestricted('BITCHAT_SETTINGS');

  return (
    <BBBContainer
      className={cn(
        isRestricted && 'pointer-events-none',
        'max-w-none lg:max-w-none'
      )}
    >
      <_AiSettings />
    </BBBContainer>
  );
}

function _AiSettings() {
  const query = useQuerySearchParams();

  const history = useHistory();
  const location = useLocation();

  const section = query.get('section') || defaultNavigation;

  const isMobile = useResponsive('sm');

  return (
    <div className="flex gap-6 items-start">
      {!isMobile && (
        <BBBNavigationCard
          selected={section}
          options={aiSettingsNavigation}
          onChange={(val) =>
            history.push({
              pathname: location.pathname,
              search: `?section=${val}`,
            })
          }
          title="AI Settings"
        />
      )}
      <div className="grow">
        {section === 'ai-agent-skillsets' && (
          <BBBContainer
            pageTitle="AI Agent & Skillset"
            className="md:px-0 md:py-0 lg:max-w-none"
            hasHeading
          >
            <AiAgent />
          </BBBContainer>
        )}
        {section === 'ai-tagging' && (
          <BBBContainer
            pageTitle="AI Tagging"
            className="md:px-0 md:py-0 lg:max-w-none"
            hasHeading
          >
            <AiTagging />
          </BBBContainer>
        )}
      </div>
    </div>
  );
}
