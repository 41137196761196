import { useState } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';
import { startCase } from 'lodash-es';
import { socialSection } from 'pages/BitLink/SocialMedia';
import { twMerge as cx } from 'tailwind-merge';

import DraggableIcon from '@/assets/icons/BBBDraggableIcon';
import {
  BBBButton,
  BBBCard,
  BBBPrimarySwitch,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextInput,
} from '@/components/ui';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import useResponsive from '@/hooks/common/useResponsive';
import { SocialMediaType } from '@/types/bitLink/v2';
import { SocialMediaAssociation } from '@/types/bitLink/v2/association';
import { formatPhonePayload } from '@/utils/common/phone';

type Props = {
  index?: number;
  isActive?: boolean;
  onChangeActive?: (val: boolean | undefined) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
  disableDrag?: boolean;
  section: SocialMediaAssociation;
  onValueChange?: (social: SocialMediaType, value: string) => void;
};

function LinkEdit({
  index,
  onCancel,
  onSubmit,
  isActive = false,
  onChangeActive,
  isSubmitDisabled = false,
  disableDrag,
  section,
  onValueChange,
}: Props) {
  return disableDrag ? (
    <_LinkEdit
      onCancel={onCancel}
      onSubmit={onSubmit}
      isActive={isActive}
      onChangeActive={onChangeActive}
      isSubmitDisabled={isSubmitDisabled}
      disableDrag
      section={section}
      onValueChange={onValueChange}
    />
  ) : (
    <Draggable
      draggableId={`social-${section.socialMediaKind.name.toString()}`}
      index={index!}
    >
      {(providedDraggable) => (
        <_LinkEdit
          onCancel={onCancel}
          onSubmit={onSubmit}
          isActive={isActive}
          onChangeActive={onChangeActive}
          isSubmitDisabled={isSubmitDisabled}
          providedDraggable={providedDraggable}
          section={section}
          onValueChange={onValueChange}
        />
      )}
    </Draggable>
  );
}

function _LinkEdit({
  onCancel,
  onSubmit,
  isActive = false,
  onChangeActive,
  isSubmitDisabled = false,
  disableDrag,
  providedDraggable,
  section,
  onValueChange,
}: Omit<Props, 'id' | 'index'> & {
  providedDraggable?: DraggableProvided;
}) {
  const isMobile = useResponsive('sm');
  const phoneDefaultValue = useDefaultCountryCode(section.username);
  const [telInputValue, setTelInputValue] = useState<
    BBBTelInputValue | undefined
  >(phoneDefaultValue);

  return (
    <BBBCard
      className={cx('mb-4 last:mb-0', isMobile && 'p-0 border-none')}
      ref={!disableDrag ? providedDraggable!.innerRef : undefined}
      {...(!disableDrag ? providedDraggable!.draggableProps : {})}
    >
      <motion.div
        layout
        transition={{ duration: 0.3 }}
        initial={{ height: 60 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        className="flex gap-5"
      >
        <div className="grow">
          {!isMobile && (
            <div className="flex mb-4">
              <div className="grow">
                <div className="flex items-center">
                  {socialSection[section.socialMediaKind.name].icon}
                  <h6 className="grow ml-5">
                    {startCase(
                      socialSection[section.socialMediaKind.name].label
                    )}
                  </h6>
                </div>
              </div>
              <BBBPrimarySwitch checked={isActive} onChange={onChangeActive} />
            </div>
          )}
          <div className="mb-5">
            {section.socialMediaKind.name === 'email' ? (
              <BBBTextInput
                value={section.username || ''}
                onChange={({ target: { value } }) =>
                  onValueChange?.(section.socialMediaKind.name, value)
                }
                placeholder="your@email.com"
              />
            ) : section.socialMediaKind.name === 'whatsapp' ? (
              <BBBTelInput
                placeholder="1234567890"
                value={telInputValue}
                onChange={(val) => {
                  setTelInputValue(val);
                  onValueChange?.(
                    section.socialMediaKind.name,
                    formatPhonePayload(val)!
                  );
                }}
              />
            ) : (
              <BBBTextInput
                value={section.username || ''}
                isFixed
                fixedLabel={`${section.socialMediaKind.name}.com/${
                  section.socialMediaKind.name === 'tiktok' ||
                  section.socialMediaKind.name === 'youtube'
                    ? '@'
                    : ''
                }`}
                onChange={({ target: { value } }) =>
                  onValueChange?.(section.socialMediaKind.name, value)
                }
              />
            )}
          </div>
          <div className="flex justify-end items-center gap-2">
            <BBBButton variant="secondary" text="Cancel" onClick={onCancel} />
            <BBBButton
              disabled={isSubmitDisabled}
              variant="primary"
              onClick={onSubmit}
              text="Save"
            />
          </div>
        </div>
        {!disableDrag && (
          <div
            className="self-center cursor-grab"
            {...providedDraggable!.dragHandleProps}
          >
            <DraggableIcon />
          </div>
        )}
      </motion.div>
    </BBBCard>
  );
}

export default LinkEdit;
