import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { AutoPilot } from '@/types/bitChat/autoPilot';

export default {
  getAutoPilotRules: (companyId: number | undefined) =>
    api.bitChat
      .get<ApiResponse<AutoPilot>>(`/autopilot/${companyId}`)
      .then((res) => res.data.data),
  updateAutoPilotRules: (data: AutoPilot, companyId: number | undefined) =>
    api.bitChat.post(`/autopilot/${companyId}`, data),
  uploadKnowledgeBase: (
    companyId: number | undefined,
    {
      fileUrl,
    }: {
      fileUrl: string | undefined;
    }
  ) =>
    api.bitChat.post(`/autopilot/${companyId}/document`, {
      fileUrl,
    }),
  deleteResource: (
    companyId: number | undefined,
    documentId: string | undefined
  ) => api.bitChat.delete(`/autopilot/${companyId}/document/${documentId}`),
};
