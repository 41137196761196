import api from 'config/api';
import { AgentVariables } from 'types/ai/agent-variables';
import { DeepPartial } from 'types/utils/deepPartial';

export default {
  getAgentVariables: (companyId: number | undefined) =>
    api.ai.v1
      .get<AgentVariables>(`/agent/${companyId}/variables`)
      .then((res) => res.data),
  upsertAgentVariables: (
    companyId: number | undefined,
    data: DeepPartial<AgentVariables>
  ) =>
    api.ai.v1
      .patch<AgentVariables>(`/agent/${companyId}/variables`, data)
      .then((res) => res.data),
};
