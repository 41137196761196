type Props = {
  size?: number;
} & React.SVGProps<SVGSVGElement>;

const ChatIcon2 = ({ size = 16, color = '#262627', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M9.3335 12.6667C11.8475 12.6667 13.1048 12.6667 13.8855 11.8853C14.6668 11.1047 14.6668 9.84733 14.6668 7.33333C14.6668 4.81933 14.6668 3.562 13.8855 2.78133C13.1048 2 11.8475 2 9.3335 2H6.66683C4.15283 2 2.8955 2 2.11483 2.78133C1.3335 3.562 1.3335 4.81933 1.3335 7.33333C1.3335 9.84733 1.3335 11.1047 2.11483 11.8853C2.55016 12.3213 3.1335 12.514 4.00016 12.5987"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33349 12.6665C8.50949 12.6665 7.60149 12.9998 6.77283 13.4298C5.44083 14.1212 4.77483 14.4672 4.44683 14.2465C4.11883 14.0265 4.18083 13.3432 4.30549 11.9772L4.33349 11.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChatIcon2;
