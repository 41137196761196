import { useAppSelector } from '@/hooks/rtk/store';

const useActiveStatus = () => {
  return useAppSelector((state) => {
    const activeStatus = state.bitCRM.activeStatus;

    return activeStatus;
  });
};

export default useActiveStatus;
