import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { mapSourceToLabel } from '../CustomerDetail';

import { BBBSelect } from '@/components/ui';
import useCustomerMonths from '@/hooks/customers/customer/useCustomerMonths';
import useCustomerTimelines from '@/hooks/customers/customer/useCustomerTimelines';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';
import { Timeline } from '@/pages/BitChat/Ticket/TicketModal/TicketModal';
import { Timeline as TimelineType } from '@/types/customers';
import { Ticket } from '@/types/whatsApp/ticket';

const mapTimelineSourceToCampaign: Partial<
  Record<Ticket['sources'], AvailableSources>
> = {
  WHATSAPP: 'whatsapp-business',
  WHATSAPP_META: 'whatsapp-cloud',
  INSTAGRAM: 'instagram',
  SANDBOX_WHATSAPP: 'whatsapp-business',
};

const filterTimelineOptions = [
  {
    label: 'All',
    value: -1,
  },
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'February',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];

function Timelines() {
  const [months, setMonths] =
    useState<typeof filterTimelineOptions[number][]>();

  const { id } = useParams<{ id: string }>();

  const filteredMonths = months?.filter((month) => month.value !== -1);

  const mappedMonths = filteredMonths?.length
    ? filteredMonths.map((month) => month.value).join(',')
    : undefined;

  const {
    data: timelineData,
    hasNextPage,
    fetchNextPage,
    isLoading: loadingTimeline,
    error: errorTimeline,
  } = useCustomerTimelines(id, {
    months: mappedMonths,
  });

  const { data: customerMonths } = useCustomerMonths(id);

  const flattenedTimelines = timelineData?.pages.flatMap((page) => page.data);

  const filteredTimelineMonths = filterTimelineOptions.filter(
    (opt) => opt.value === -1 || customerMonths?.includes(opt.value)
  );

  const timelines = useMemo(() => {
    const sortedObj: Record<string, TimelineType[]> = {};
    flattenedTimelines?.forEach((e) => {
      // loop over all elements
      const k = e.createdAt.slice(0, 7); // key in YYYY-MM (e.g. 2019-10)
      sortedObj[k] = sortedObj[k] || []; // create new entry if no value for key exists
      sortedObj[k].push(e); // add key to existing list
    });

    return Object.entries(sortedObj).sort(
      //@ts-expect-error ignore error
      (a, b) => new Date(b[0]) - new Date(a[0])
    );
  }, [flattenedTimelines]);

  return (
    <>
      <div className="flex justify-between">
        <div className="text-xl font-medium mb-6">Timeline</div>
        <BBBSelect
          placeholder="Filter timeline"
          options={filteredTimelineMonths}
          optionLabel="label"
          optionValue="value"
          value={months}
          isMulti
          enableToggleOption
          onValueChange={(opt, selected) => {
            if (months?.some((month) => month.value === selected?.value)) {
              setMonths((prev) =>
                prev?.filter((opt) => opt.value !== selected?.value)
              );
            } else {
              if (selected?.value === -1) {
                setMonths([selected]);
              } else {
                setMonths((prev) => [
                  ...(prev?.filter((month) => month.value !== -1) ?? []),
                  selected!,
                ]);
              }
            }
          }}
          renderCustomSelectedValues={(values) => <>{values?.length} Filters</>}
        />
      </div>
      {loadingTimeline ? (
        <TimelineSkeleton />
      ) : errorTimeline ? null : !timelines.length ? (
        <>
          No activity yet
          {months?.length
            ? ` in ${months?.map((month) => month.label).join(', ')}`
            : ``}{' '}
        </>
      ) : (
        <InfiniteScroll
          scrollableTarget="customer-detail-container"
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          dataLength={flattenedTimelines?.length ?? 0}
          loader={<>loading...</>}
        >
          {timelines.map(([title, timelines]) => (
            <div key={title} className="mb-5">
              <div className="text-neutral-50 ml-5 font-medium mb-6">
                {title === dayjs().format('YYYY-MM')
                  ? 'This month'
                  : dayjs(title).format('MMMM YYYY')}
              </div>
              <Timeline
                data={timelines.map((timeline) => {
                  const editLog = timeline.edit_log;
                  let editText = '';

                  const parsedEditLogs = editLog
                    ? JSON.parse(editLog)
                    : undefined;

                  const keys = parsedEditLogs
                    ? Object.keys(parsedEditLogs)
                    : undefined;

                  let tagAction = '';

                  if (keys?.length === 1 && keys[0] === 'tags') {
                    const [[_, actionObj]] = Object.entries(parsedEditLogs);
                    const [[action]] = Object.entries(
                      actionObj as Record<string, unknown>
                    );
                    tagAction = action;
                  }

                  if (keys?.length === 1 && keys[0] === 'notes') {
                    editText = `Notes were edited.`;
                  } else if (keys?.length === 1 && keys[0] === 'tags') {
                    editText = `Tags were ${tagAction}.`;
                  } else if (keys?.length) {
                    editText = `Customer’s details were edited.`;
                  }

                  return {
                    createdAt: timeline.createdAt,
                    description:
                      timeline.type === 'customer_created' ? (
                        `Customer created and added to database from ${
                          mapSourceToLabel[
                            timeline.source as keyof typeof mapSourceToLabel
                          ]
                        }`
                      ) : timeline.type === 'customer_edited' && editText ? (
                        <>{editText}</>
                      ) : timeline.type === 'conversation_started' ? (
                        `Conversation started by this customer`
                      ) : timeline.type === 'conversation_resolved' ? (
                        `Conversation resolved by autopilot.`
                      ) : timeline.type === `ticket_created` ||
                        timeline.type === 'ticket_resolved' ? (
                        <>
                          Ticket ID{' '}
                          <a
                            href={`/bitchat/ticket?id=${timeline.ticketId}&source=${timeline.ticketSource}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-secondary-main hover:underline">
                              #{timeline.ticketId}
                            </span>
                          </a>{' '}
                          is{' '}
                          <span className="font-semibold">
                            {timeline.type === 'ticket_created'
                              ? `created`
                              : `resolved`}
                            .
                          </span>
                        </>
                      ) : timeline.type === 'campaign_received' &&
                        mapTimelineSourceToCampaign[
                          timeline.campaignSource as keyof typeof mapTimelineSourceToCampaign
                        ] ? (
                        <>
                          Customer <span className="font-medium">received</span>{' '}
                          campaign{' '}
                          <a
                            href={`/bitcrm/campaign/${
                              mapTimelineSourceToCampaign[
                                timeline.campaignSource as keyof typeof mapTimelineSourceToCampaign
                              ]
                            }/${timeline.campaignId}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-secondary-main hover:underline">
                              &quot;{timeline.campaignName}&quot;
                            </span>
                          </a>{' '}
                          from {timeline.source}
                        </>
                      ) : timeline.type === 'rating_given' ? (
                        <>
                          Customer gave{' '}
                          <span className="font-medium">rating</span> to Ticket
                          ID{' '}
                          <span className="text-secondary-main hover:underline">
                            #{timeline.ticketId}
                          </span>
                          .
                        </>
                      ) : (
                        <>
                          Customer was{' '}
                          <span className="font-medium">merged</span> by{' '}
                          <span className="font-medium">
                            Agent {timeline.userDisplayName}
                          </span>
                          , former records are deleted
                        </>
                      ),
                    type: timeline.type,
                  };
                })}
                expandFirstIndex
                asCard
                containerClassName="mb-3.5"
                connectorClassName="h-[54px]"
              />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </>
  );
}

function TimelineSkeleton() {
  return (
    <>
      <Skeleton className="ml-5 mb-6" width={100} />
      <div className="flex gap-5">
        <Skeleton width={10} height={'100%'} />
        <div className="grow">
          {Array.from({ length: 6 }).map((_, i) => (
            <Skeleton
              width={'100%'}
              className="mb-4"
              borderRadius={16}
              height={50}
              key={i}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Timelines;
