import { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import useStore from '../../../store';

import PlusIcon from '@/assets/icons/PlusIcon';
import colors from '@/constants/common/colors';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';

export default function CustomEdgeCondition({
  id,
  markerEnd,
  data,
  ...props
}: EdgeProps) {
  const offset = 4;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    ...props,
    sourceX: props.sourceX - offset,
    targetX: props.targetX + offset,
    borderRadius: 20,
  });

  const hasExpandedState = useStore((state) => {
    if (state.withinDragBounds) {
      const [source, target] = state.withinDragBounds.split('_');
      if (source === props.source && target === props.target) return true;
    }

    const expandedKeys = state.expandState
      ? Object.entries(state.expandState)
          .filter(([k, v]) => !!v)
          .map(([k]) => k)
      : [];

    return !!state.edges.filter(
      (edge) => expandedKeys.includes(edge.target) && edge.id === id
    ).length;
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={
          hasExpandedState
            ? {
                stroke: colors.secondary.main,
              }
            : undefined
        }
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <CreateCondition data={data} id={id} source={props.source} />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function CreateCondition({
  data,
  id,
  source,
}: {
  data: EdgeProps['data'];
  id: string;
  source: string;
}) {
  const onChangeStateModal = useStore((s) => s.onChangeStateModal);
  const removeConditionEdge = useStore((s) => s.removeConditionEdge);

  const siblingsLength = useStore(
    (s) => s.edges.filter((edge) => edge.source === source).length
  );
  const currentIndex = useStore((s) =>
    s.edges
      .filter((edge) => edge.source === source)
      .findIndex((s) => s.id === id)
  );

  const confirm = useConfirmationModal();

  const [hovering, setHovering] = useState(false);

  const baseWidth = !data ? 96 : 80;

  return (
    <div className="relative w-[96px]">
      <motion.div
        className={cx(
          'rounded-[100px] group border-primary-main cursor-pointer border px-3 py-2 flex items-center gap-1 bg-white pointer-events-auto',
          data && 'bg-primary-main text-white'
        )}
        onClick={() => {
          onChangeStateModal({ type: 'condition', edgeId: id });
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        animate={{
          width: hovering ? baseWidth + 10 : baseWidth,
          transition: {
            type: 'tween',
            duration: 0.1,
          },
        }}
        initial={{ width: baseWidth }}
      >
        <div className="text-xs whitespace-nowrap">
          {!data ? 'Add condition' : `Condition ${currentIndex + 1}`}
        </div>
        {hovering && (
          <TrashWithTransition
            size={'0.75rem'}
            className={cx('flex-none text-primary-main', data && 'text-white')}
            onClick={(e) => {
              confirm({
                title: 'Delete this conditional',
                description:
                  'Are you sure want to delete this conditional? Once you delete the action cannot be undone',
                onAccept: (hide) => {
                  removeConditionEdge(id);
                  hide();
                },
                deleteModal: true,
              });

              e.stopPropagation();
            }}
          />
        )}
      </motion.div>
      {currentIndex + 1 === siblingsLength && (
        <>
          <div className="absolute top-full left-1/2 -translate-x-1/2 w-[2px] h-36 bg-neutral-30" />
          <div className="absolute top-[calc(100%+9rem)] -left-4">
            <CreateNewCondition sourceId={source} />
          </div>
        </>
      )}
    </div>
  );
}

export function CreateNewCondition({
  sourceId,
  className,
}: {
  sourceId: string;
  className?: string;
}) {
  const addNewCondition = useStore((s) => s.addNewCondition);

  return (
    <div
      className={cx(
        'rounded-[100px] w-40 cursor-pointer border-primary-main border px-3 py-2 flex justify-center items-center gap-1 bg-white pointer-events-auto',
        className
      )}
      onClick={() => {
        addNewCondition(sourceId);
      }}
    >
      <div className="text-xs">Add new condition</div>
      <div className="flex-none">
        <PlusIcon size={'0.5rem'} />
      </div>
    </div>
  );
}
