import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import integrationServices from '@/api/services/integration';
import { InstagramAccountList } from '@/types/instagram';

const useListInstagram = (
  options: Omit<
    UseQueryOptions<
      InstagramAccountList[],
      unknown,
      InstagramAccountList[],
      (string | number)[]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['instagram-list', activeCompany],
    () => integrationServices.instagram.getInstagramList(activeCompany),
    {
      ...options,
    }
  );
};

export default useListInstagram;
