import type { AnalyticAgents as Time } from '@/types/bitChat/analytics';

export const formatDuration = (time?: Time['resolutionTime'] | null) => {
  if (!time) return '-';

  const { hours, minutes, seconds } = time;

  const formatUnit = (value: number, unit: 'h' | 'm' | 's') =>
    value ? `${value}${unit}` : '';

  if (!hours && !minutes && !seconds) return '-';

  return [
    formatUnit(hours, 'h'),
    formatUnit(minutes, 'm'),
    formatUnit(seconds, 's'),
  ]
    .filter(Boolean)
    .join(' ');
};
