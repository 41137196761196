import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

const useSettings = () => {
  const { data, status } = useShopifyIntegrationByApp('BITLOGIN');

  return useQuery(
    ['bitlogin-settings', data?.domain],
    () => services.bitLogin.settings.getSocialSettings(data?.domain),
    {
      enabled: status === 'success' && !!data,
    }
  );
};

export default useSettings;
