import { useState } from 'react';
import useSegmentOptions from '../hooks/useSegmentOptions';

import { BBBSelect } from '@/components/ui';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setPricingAlert } from '@/stores/common';

function SegmentOptions({
  value,
  onChange,
  error,
}: {
  value:
    | {
        label: string;
        value: string;
        disabled?: boolean;
        count: number;
      }
    | null
    | undefined;
  onChange: (
    val: { label: string; value: string; disabled?: boolean } | undefined | null
  ) => void;
  error?: string;
}) {
  const [search, setSearch] = useState<string>();
  const { data: companyPricing } = usePricingByApp('BITCRM');
  const dispatch = useAppDispatch();
  const {
    data,
    hasNextPage: hasNextSegments,
    fetchNextPage: fetchNextSegments,
    isLoading: loadingSegments,
  } = useSegmentOptions(search);

  return (
    <BBBSelect
      options={data}
      optionValue="value"
      optionLabel="label"
      optionDisabled="disabled"
      loading={loadingSegments}
      placeholder="Choose campaign targets"
      error={error}
      value={value}
      onValueChange={(opt) => {
        if (opt?.value !== 'demo' && companyPricing?.pricingName === 'free') {
          dispatch(
            setPricingAlert({
              appType: 'BITCRM',
              show: true,
              desiredPlan: 'standard',
            })
          );
          return;
        }
        onChange(opt);
      }}
      isPaginated
      hasMore={!!hasNextSegments}
      fetchNext={fetchNextSegments}
      label="Send to:"
      containerClassName="mb-5"
      isSearchable
      search={search}
      onChangeSearch={setSearch}
    />
  );
}

export default SegmentOptions;
