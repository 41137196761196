import React from 'react';

type Props = {
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function OrderHistoryIcon({ className, ...props }: Props) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M3.77895 9.14056C3.81766 8.68411 4.03662 8.25821 4.39224 7.94767C4.74785 7.63714 5.21402 7.46475 5.6979 7.46484H17.3021C17.786 7.46475 18.2521 7.63714 18.6078 7.94767C18.9634 8.25821 19.1823 8.68411 19.2211 9.14056L19.9938 18.2477C20.0151 18.4983 19.9813 18.7504 19.8945 18.988C19.8078 19.2257 19.67 19.4438 19.4898 19.6286C19.3097 19.8133 19.091 19.9608 18.8477 20.0617C18.6043 20.1626 18.3415 20.2148 18.0758 20.2148H4.92416C4.65847 20.2148 4.39568 20.1626 4.15233 20.0617C3.90897 19.9608 3.69033 19.8133 3.51016 19.6286C3.32999 19.4438 3.1922 19.2257 3.10546 18.988C3.01872 18.7504 2.98491 18.4983 3.00617 18.2477L3.77895 9.14056Z"
        stroke="#262627"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3493 10.1964V5.64285C15.3493 4.67671 14.9437 3.75013 14.2218 3.06697C13.4999 2.3838 12.5208 2 11.4998 2C10.4789 2 9.49978 2.3838 8.77787 3.06697C8.05596 3.75013 7.65039 4.67671 7.65039 5.64285V10.1964"
        stroke="#262627"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OrderHistoryIcon;
