import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import BitloginWhatsappSettings from '../components/BitloginWhatsappSettings';
import ChatInitiation from './components/ChatInitation';
import WhatsappApiProfile from './components/WhatsappApiProfile';
import CampaignAutomation from './WhatsappTemplate/CampaignAutomation';

import { BBBContainer } from '@/components/ui';
import BBBNavigationCard from '@/components/ui/BBBNavigationCard/BBBNavigationCard';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

const whatsAppApiSettingsNavigation = [
  { label: 'Campaign', value: 'campaign', parentValue: 'Templates' },
  { label: 'Automation', value: 'automation', parentValue: 'Templates' },
  {
    label: 'Chat initiation',
    value: 'chat-initiation',
    parentValue: 'Templates',
  },
  {
    label: 'WhatsApp API Profile',
    value: 'whatsapp-api-profile',
    parentValue: 'Others',
  },
  {
    label: 'bitLogin WhatsApp settings',
    value: 'bitlogin-whatsapp-settings',
    parentValue: 'Others',
  },
];

const defaultNavigation = whatsAppApiSettingsNavigation[0].value;

export default function WhatsappApiSettings() {
  const { data: isRestricted } = useIsModuleRestricted('BITCHAT_SETTINGS');

  return (
    <BBBContainer
      className={cn(
        isRestricted && 'pointer-events-none',
        'max-w-none lg:max-w-none'
      )}
    >
      <_WhatsappApiSettings />
    </BBBContainer>
  );
}

function _WhatsappApiSettings() {
  const query = useQuerySearchParams();

  const history = useHistory();
  const location = useLocation();

  const section = query.get('section') || defaultNavigation;
  const message = query.get('message');

  const isMobile = useResponsive('sm');

  useEffect(() => {
    if (message === 'check-connection-failed') {
      toast.error('Failed to check connection');
    }
  }, [message]);

  return (
    <div className="flex gap-6 items-start">
      {!isMobile && (
        <BBBNavigationCard
          selected={section}
          options={whatsAppApiSettingsNavigation}
          onChange={(val) =>
            history.push({
              pathname: location.pathname,
              search: `?section=${val}`,
            })
          }
          title="Whatsapp API settings"
        />
      )}
      <div className="grow">
        {section === 'campaign' && <CampaignAutomation type="campaign" />}
        {section === 'automation' && <CampaignAutomation type="automation" />}
        {section === 'chat-initiation' && <ChatInitiation />}
        {section === 'whatsapp-api-profile' && <WhatsappApiProfile />}
        {section === 'bitlogin-whatsapp-settings' && (
          <BitloginWhatsappSettings />
        )}
      </div>
    </div>
  );
}
