import { useMutation, useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { ProxyCallbackParams } from '@/api/services/bitLogin/login/proxy';

export const useDecryptedData = (code: string) => {
  return useQuery(['decrypt-data', code], () =>
    services.bitLogin.login.proxy.decryptLoginData({ code })
  );
};

export const useProxyCallback = () => {
  return useMutation(
    (payload: ProxyCallbackParams) =>
      services.bitLogin.login.proxy.signupCallback(payload),
    {
      onSuccess: (data) => {
        const script = document.createElement('script');
        script.innerHTML = data;

        document.body.appendChild(script);
      },
    }
  );
};
