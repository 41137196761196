import { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { env } from 'config/env';
import {
  useLoginStatus,
  useUpdateLoginStatus,
} from 'hooks/bitLogin/login/whatsapp/whatsapp';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';

import { BBBSpinner } from '@/components/ui';
import BBBRedirectWithTimer from '@/components/ui/BBBRedirectWithTimer';

export default function WhatsAppLoginMobileFlow() {
  const query = useQuerySearchParams();
  const code = query.get('code');
  const domain = query.get('domain');

  if (!code || !domain) throw new Error('Invalid code');

  const { data: loginStatus, status } = useLoginStatus(code, domain);
  const { mutate: updateLoginStatus } = useUpdateLoginStatus();

  useEffect(() => {
    if (loginStatus?.status === 2) {
      updateLoginStatus({ code, domain, status: 3 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  if (
    status === 'error' ||
    (loginStatus?.status && (loginStatus.status < 1 || loginStatus.status > 2))
  )
    return null;

  return (
    <div className="h-screen flex flex-col justify-center z-50 items-center">
      {status === 'loading' ? (
        <BBBSpinner />
      ) : loginStatus.status === 1 ? (
        <div className="mx-auto text-center p-auto">
          <BBBRedirectWithTimer
            redirectUrl={`whatsapp://send?phone=${env.REACT_APP_WHATSAPP_LOGIN_NUMBER}&text=Send%20this%20message%20to%20continue%20to%20${loginStatus.shopName}%0A%0AIdentifier%20code%3A%20${code}`}
            timeoutInSeconds={3}
          />
        </div>
      ) : loginStatus.status === 2 ? (
        <>
          <p className="text-center text-2xl text-neutral-60 mb-10">
            Login success!
          </p>
          <div className="mb-8 w-fit mx-auto">
            <FaCheckCircle fill="#25D366" stroke="#FFFF" size={180} />
          </div>
        </>
      ) : null}
    </div>
  );
}
