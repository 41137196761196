import React, { useEffect } from 'react';

export default function useOutsideAlerter(
  ref:
    | React.MutableRefObject<HTMLElement | null | undefined>
    | HTMLElement
    | null,
  onClick?: (target: HTMLElement) => void,
  ignoreModal?: boolean,
  containerRef: Element | Document = document
) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      const actualRef = ref
        ? 'current' in ref
          ? ref.current
          : ref
        : undefined;

      if (actualRef) {
        if (ignoreModal) {
          const modalRoot = document.getElementById('modal-root');
          const reachPortal = document.querySelector('reach-portal'); // Assuming <reach-portal> is a valid tag

          if (
            modalRoot &&
            (event.target as HTMLElement).closest('#modal-root') === modalRoot
          ) {
            return;
          }

          if (
            reachPortal &&
            (event.target as HTMLElement).closest('reach-portal') ===
              reachPortal
          ) {
            return;
          }
        }
        if (!actualRef.contains(event.target as Node)) {
          onClick?.(event.target as HTMLElement);
        }
      }
    }
    if (containerRef) {
      containerRef.addEventListener('mousedown', handleClickOutside);
      return () => {
        containerRef.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [containerRef, ignoreModal, onClick, ref]);
}
