import { useAppSelector } from './store';

export const useUser = () => useAppSelector((state) => state.auth.user)!;
export const useUserId = () => useAppSelector((state) => state.auth.user!.id);
export const useActiveCompany = () =>
  useAppSelector((state) => state.auth.activeCompany)!;
export const useCompany = () => useAppSelector((state) => state.auth.company)!;
export const useUserCompany = () =>
  useAppSelector((state) => state.auth.userCompany)!;
export const useAccessToken = () =>
  useAppSelector((state) => state.auth.accessToken);

export const useApiKey = () => useAppSelector((s) => s.bitCRM.apiKey)!;
