/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Helmet } from 'react-helmet';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useAccessToken } from '@/hooks/rtk/selector';
import GeneralLayout from '@/layouts/GeneralLayout';

export default function PublicRoute({
  title,
  component: Component,
  bypassAuth,
  plainLayout,
  ...rest
}: RouteProps & {
  title?: string;
  bypassAuth?: boolean;
  plainLayout?: boolean;
}) {
  const accessToken = useAccessToken();

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Helmet>
              <title>{title ? `${title} - bitbybit` : 'bitbybit'}</title>
            </Helmet>
            {!accessToken || bypassAuth ? (
              <GeneralLayout plainLayout={plainLayout}>
                {/* @ts-ignore */}
                <Component {...props} />
              </GeneralLayout>
            ) : (
              <Redirect to={'/' + props.location.search} />
            )}
          </>
        );
      }}
    />
  );
}
