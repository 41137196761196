import { ReactNode } from 'react';
import { Plus } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';
import SingleFileUpload, {
  SingleFileUploadProps,
} from './SingleFileUpload/SingleFileUpload';

import { BBBButton } from '@/components/ui';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';

export type CountFileUploadTypes =
  | {
      isSingle?: false;
      files?: Nullable<FileType[]>;
      onChangeFile?: (val: Nullable<FileType[]>) => void;
      withoutAddButton?: boolean;
    }
  | {
      isSingle: true;
      files: Nullable<FileType> | null;
      onChangeFile?: (val: Nullable<FileType> | null) => void;
      withoutAddButton?: never;
    };

export type BBBFileUploadTypes = {
  containerClassName?: string;
  accept?: string;
  isDisabled?: boolean;
  placeholder?: ReactNode;
  withLabel?: boolean;
  label?: string;
  description?: string;
} & CountFileUploadTypes &
  Omit<SingleFileUploadProps, 'file' | 'accept' | 'onChangeFile'>;

function BBBFileUpload({
  files,
  onChangeFile,
  containerClassName,
  accept = 'image/*',
  isSingle,
  withoutAddButton,
  withLabel,
  label = 'Media (Optional)',
  description = 'Send image to your customers',
  ...dropzoneProps
}: BBBFileUploadTypes) {
  const handleChangeFile = (data: FileType | null, iP: number) => {
    const prev = [...((files as FileType[]) || [])];

    if (!data) {
      prev.splice(iP, 1);
      (onChangeFile as (val: Nullable<FileType[]>) => void)?.(prev);
      return;
    }
    prev.splice(iP, 1, data);
    (onChangeFile as (val: Nullable<FileType[]>) => void)?.(prev);
  };

  return (
    <div className={cx(containerClassName)}>
      {withLabel && (
        <>
          <div className="text-neutral-60 mb-[0.3125rem]">{label}</div>
          <div className="text-sm text-primary-main mb-2">{description}</div>
        </>
      )}
      {isSingle ? (
        <SingleFileUpload
          accept={accept}
          file={files}
          onChangeFile={onChangeFile}
          {...dropzoneProps}
        />
      ) : (
        files?.map((file, iP) => (
          <SingleFileUpload
            accept={accept}
            file={file}
            onChangeFile={(value) => handleChangeFile(value, iP)}
            key={iP}
            {...dropzoneProps}
          />
        ))
      )}
      {!isSingle && !withoutAddButton && (
        <BBBButton
          text="Add media"
          width="full"
          className="mt-3"
          onClick={() => {
            onChangeFile?.([
              ...(files || []),
              {
                fileData: {
                  type: 'image',
                },
                remoteUrl: undefined,
              },
            ]);
          }}
        />
      )}
    </div>
  );
}

export default BBBFileUpload;
