import { AppType } from 'types/systemLogin';

export const appNameFromType: Record<AppType, string> = {
  BITAPP: 'bitApp',
  BITLINK: 'bitLink',
  BITCHAT: 'bitChat',
  BITCRM: 'bitCRM',
  BITLOGIN: 'bitLogin',
  BITAI: 'bitAI',
};

export const typeToAppName: Record<string, AppType> = {
  bitApp: 'BITAPP',
  bitLink: 'BITLINK',
  bitChat: 'BITCHAT',
  bitCRM: 'BITCRM',
  bitLogin: 'BITLOGIN',
  bitAI: 'BITAI',
};
