const BarIcon = ({
  className,
  width = 8,
  height = 9,
  color = '#FD823E',
}: {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 9"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="8" height="8" fill={color} />
    </svg>
  );
};

export default BarIcon;
