export default function TelegramPlaneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fill="#9A9A9A"
        d="M18.883 2.204l-4.899 12.143c-.359.854-.924.983-1.598.432L8.84 11.057l-2.3 1.545c-.256.179-.47.329-.89.255l1.025-4.1 8.757-5.493c.38-.235.01-.467-.482-.252L4.576 7.245.777 5.278c-.826-.427-.736-1.031.41-1.262L17.98.731c.776-.144 1.337.403.903 1.473z"
      ></path>
    </svg>
  );
}
