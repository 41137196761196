import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAnalyticsChart = (
  params: {
    startDate?: string;
    endDate?: string;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitcrm-chart-analytics', activeCompany, params],
    () =>
      services.bitCRM.v2.analytics.getAutomationAndCampaignChart(
        activeCompany,
        {
          startDate: params.startDate,
          endDate: params.endDate,
        }
      ),
    options
  );
};

export default useAnalyticsChart;
