import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MutateOptions } from '@tanstack/react-query';
import customerServices from 'api/services/customer';
import { AxiosResponse } from 'axios';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import { useUserId } from 'hooks/rtk/selector';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import {
  ImportProductsParams,
  ToggleDisplayOnWhatsappCatalogParams,
  UpsertProductParams,
} from '@/api/services/customer/products';
import { toast } from '@/utils/common/toast';

export const useProducts = (params?: {
  limit?: number;
  page?: number;
  search?: string;
  source?: string;
  whatsappCatalog?: boolean;
}) => {
  const companyId = useActiveCompany();

  return useQuery({
    queryKey: ['products', companyId, params],
    queryFn: () => services.customer.products.fetchProducts(companyId, params),
    keepPreviousData: true,
  });
};

export const useProduct = (productId: string) => {
  const companyId = useActiveCompany();

  return useQuery({
    queryKey: ['product', companyId, productId],
    queryFn: () =>
      services.customer.products.fetchProduct(companyId, productId),
    enabled: productId !== 'new',
  });
};

export const useUpsertProduct = () => {
  const companyId = useActiveCompany();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpsertProductParams) =>
      services.customer.products.upsertProduct(companyId, data),
    onSuccess: (_, { productId }) => {
      history.push('/products');

      if (productId) {
        toast.success('Product updated successfully');
      } else {
        toast.success('Product created successfully');
      }

      queryClient.invalidateQueries({ queryKey: ['products', companyId] });
    },
  });
};

export const useImportProducts = () => {
  const companyId = useActiveCompany();

  return useMutation({
    mutationFn: (data: ImportProductsParams) =>
      services.customer.products.importProducts(companyId, data),
    onSuccess: () => {
      toast.success(
        'We have received your request. It may take a while to complete.'
      );
    },
  });
};

export const useToggleDisplayOnWhatsappCatalog = () => {
  const companyId = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ToggleDisplayOnWhatsappCatalogParams) =>
      services.customer.products.toggleDisplayOnWhatsappCatalog(
        companyId,
        payload
      ),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['products', companyId] });
      await queryClient.cancelQueries({
        queryKey: ['product', payload.productId],
      });

      const previousProducts = queryClient.getQueryData([
        'products',
        companyId,
      ]);

      const previousProduct = queryClient.getQueryData([
        'product',
        payload.productId,
      ]);

      queryClient.setQueryData(['products', companyId], (old: any) => {
        return old?.map((product: any) =>
          product.id === payload.productId
            ? {
                ...product,
                displayOnWhatsappCatalog: payload.displayOnWhatsappCatalog,
              }
            : product
        );
      });

      queryClient.setQueryData(['product', payload.productId], (old: any) => {
        return old
          ? {
              ...old,
              displayOnWhatsappCatalog: payload.displayOnWhatsappCatalog,
            }
          : old;
      });

      return { previousProducts, previousProduct };
    },
    onError: (_, __, context: any) => {
      queryClient.setQueryData(
        ['products', companyId],
        context.previousProducts
      );
      toast.error('Failed to update product');
    },
    onSuccess: () => {
      toast.success('Product updated successfully');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['products', companyId] });
    },
  });
};

export const useHasAlreadyImported = () => {
  const companyId = useActiveCompany();

  return useQuery({
    queryKey: ['hasAlreadyImported', companyId],
    queryFn: () => services.customer.products.hasAlreadyImported(companyId),
  });
};

const mapActionToTitle = {
  delete: 'Delete multiple products',
};

const mapActionToSubmitText = {
  delete: 'Delete',
};

const mapDescriptionToTitle = {
  delete:
    'Are you sure to delete selected products? Once you deleted the action cannot be undone',
};

type BulkParams = {
  selected: string[];
  isAllRowsSelected: boolean;
  count: number;
};

export const useProductsActions = (params: {
  onSuccess: () => void;
  onChangeLoadDelete: (val: boolean) => void;
}) => {
  const toggleConfirmation = useConfirmationModal();
  const { toggle, hide } = useConfirmationBanner();
  const client = useQueryClient();
  const companyId = useActiveCompany();

  const { mutate: bulkDeleteAction } = useMutation(
    async (bulkParams: BulkParams) => {
      toggle('delete-products', {
        text: 'Deleting products',
        show: true,
        variant: 'loading',
      });

      return await services.customer.products.bulkDeleteProducts({
        ids: bulkParams.selected,
        companyId,
        isAllSelected: bulkParams.isAllRowsSelected,
      });
    },
    {
      onMutate: (payload) => {
        params.onChangeLoadDelete(true);
      },
      onSuccess: (_, payload) => {
        toast.success(`Your products has been deleted`);
        client.invalidateQueries(['products']);
        params.onSuccess();
        params.onChangeLoadDelete(false);

        hide('delete-products');
      },
    }
  );

  return (
    action: 'delete',
    bulkParams: {
      selected: string[];
      isAllRowsSelected: boolean;
      count: number;
    },
    {
      onSuccess,
      ...options
    }:
      | MutateOptions<
          AxiosResponse<any>,
          unknown,
          {
            selected: string[];
            isAllRowsSelected: boolean;
          },
          unknown
        >
      | undefined = {}
  ) => {
    toggleConfirmation({
      title: mapActionToTitle[action],
      description: mapDescriptionToTitle[action],
      onAccept: (hide) => {
        if (action === 'delete') {
          bulkDeleteAction(bulkParams, {
            onSuccess: (...payload) => {
              hide();
              onSuccess?.(...payload);
            },
            ...options,
          });
        }

        hide();
      },
      deleteModal: action === 'delete',
      submitText: mapActionToSubmitText[action],
      cancelText: 'Cancel',
    });
  };
};
