import usePricingByApp from './usePricingByApp';
import usePricingOptions from './usePricingOptionsByApp';

import { PricingPlan } from '@/api/services/pricing';
import { AppType } from '@/types/systemLogin';
import { formatFreeWithUsagePlan } from '@/utils/pricing';

const useIsPricingActive = (active: AppType) => {
  const { data: pricingData } = usePricingByApp(active);

  const pricingName = pricingData
    ? formatFreeWithUsagePlan(pricingData.pricingName)
    : null;

  const { data: pricingOptions } = usePricingOptions(
    active,
    //@ts-ignore
    pricingData?.type,
    { enabled: !!pricingData }
  );

  return {
    isPlanActive: (name: PricingPlan) => {
      if (!pricingName || !pricingOptions) return false;

      return (
        pricingOptions.findIndex((opt) => opt.name === name) ===
        pricingOptions.findIndex((opt) => opt.name === pricingName)
      );
    },
    index:
      !pricingName || !pricingOptions
        ? -1
        : pricingOptions.findIndex((opt) => opt.name === pricingName),
  };
};

export default useIsPricingActive;
