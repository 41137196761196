import { useMemo } from 'react';
import { mapSourceToLabel } from '../CustomerDetail/CustomerDetail';

import { BBBTag } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import useDeleteTag from '@/hooks/customers/customer/useDeleteTag';
import { CustomerWithAssociation } from '@/types/customers/association';
import { formatInternational } from '@/utils/common/phone';

const useColumnMobile = () => {
  const { mutate: deleteTag } = useDeleteTag();

  return useMemo<HeadersTypes<CustomerWithAssociation>>(() => {
    return [
      {
        accesor: 'firstName',
        render: (info) => (
          <div className="flex flex-col gap-[10px] px-2">
            <div className="text-primary-main group-hover:underline w-fit">
              {info.firstName ? info.firstName : '-'}
            </div>
            {info.email ? (
              <p className="text-primary-main">{info.email}</p>
            ) : (
              <p className="text-primary-main">-</p>
            )}
            <p className="text-primary-main">
              {formatInternational(info.phoneNumber)}
            </p>
            <div className="flex flex-wrap gap-2.5">
              {info.customerTags.map((tag) => (
                <BBBTag
                  key={tag.tagName}
                  text={tag.tagName}
                  onDelete={(e) => {
                    deleteTag({
                      tagName: tag.tagName,
                      customerId: tag.customerId,
                    });
                    e.stopPropagation();
                  }}
                />
              ))}
            </div>
            <p className="text-primary-main">
              {info.sources
                .map(
                  (source) =>
                    mapSourceToLabel[source as keyof typeof mapSourceToLabel]
                )
                .join(', ')}
            </p>{' '}
          </div>
        ),
      },
    ];
  }, [deleteTag]);
};

export default useColumnMobile;
