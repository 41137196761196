import { ApiResponse } from 'api/types';
import api from 'config/api';
import { ImageGridsForm } from 'pages/BitApp/Design/components/Popup/EditCircleImageGrid';
import { ImageGrid, Section } from 'types/bitApp/v2';

export default {
  addImageGrid: (
    companyId: number | undefined,
    payload: Partial<
      ImageGridsForm<true> & {
        sectionId: number | null;
      }
    >
  ) =>
    api.bitApp
      .post<
        ApiResponse<
          Section & {
            imageGrids: ImageGrid;
          }
        >
      >(`/image-grid/${companyId}`, {
        ...payload,
      })
      .then((res) => res.data.data),
};
