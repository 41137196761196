import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany, useUserId } from 'hooks/rtk/selector';
import { cloneDeep } from 'lodash-es';
import { IntegrationExtra, IntegrationName } from 'types/bitApp/v2';
import { CompanyIntegrationAssociation } from 'types/bitApp/v2/association';
import { CompanyIntegration } from 'types/bitLink/v2';
import { toast } from 'utils/common/toast';

export const useIntegrations = () =>
  useQuery(['bitai-integrations'], () =>
    services.bitAi.integrations.getIntegrations()
  );

export const useCompanyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitai-company-integrations', activeCompany],
    () => services.bitAi.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled: enabled,
      ...options,
    }
  );
};

export const useShopifyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitai-company-integrations', activeCompany],
    () => services.bitAi.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'shopify'),
      ...options,
    }
  );
};

export const useShopifyIntegrationExtra = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    IntegrationExtra | null,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitai-company-integrations', activeCompany],
    () => services.bitAi.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled: enabled,
      select: (data) =>
        companyIntegrationSelector(data, 'shopify')?.extra || null,
      ...options,
    }
  );
};

export const useToggleIntegration = () => {
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const client = useQueryClient();

  return useMutation(
    async (data: Pick<CompanyIntegration, 'integrationId' | 'status'>) =>
      services.bitAi.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['bitai-company-integrations', activeCompany]);
        client.invalidateQueries(['bitai-shopify', activeCompany]);
        toast.success('Integration disconnected successfully!');
      },
    }
  );
};

export const useToggleIntegrationMutation = () => {
  const activeCompany = useActiveCompany();

  const userId = useUserId();
  const client = useQueryClient();

  return useMutation(
    async (data: Pick<CompanyIntegration, 'integrationId' | 'status'>) =>
      services.bitAi.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onMutate: async (payload) => {
        await client.cancelQueries([
          'bitai-company-integrations',
          activeCompany,
        ]);

        const previousData = client.getQueryData([
          'bitai-company-integrations',
          activeCompany,
        ]);

        client.setQueryData(
          ['bitai-company-integrations', activeCompany],
          (old: CompanyIntegration[] | undefined) => {
            const clone = cloneDeep(old || []);
            const integrationListIdx = clone.findIndex(
              (data) => data.integrationId === payload.integrationId
            );

            clone[integrationListIdx].status = payload.status;

            return clone;
          }
        );

        return {
          previousData,
        };
      },
      onSuccess: () => {
        toast.success('Integration disconnected successfully!');
      },
      onError: (err: any, _, context: any) => {
        client.setQueryData(
          ['bitai-company-integrations', activeCompany],
          context.previousData
        );
      },
      onSettled: () => {
        client.invalidateQueries(['bitai-company-integrations', activeCompany]);
      },
    }
  );
};

function companyIntegrationSelector(
  data: CompanyIntegrationAssociation[],
  name: IntegrationName,
  options?: { includeDisconnected?: boolean }
) {
  return (
    options?.includeDisconnected
      ? data
      : data.filter((integration) => integration.status === 'CONNECTED')
  ).find((integration) => integration.integration.name === name);
}
