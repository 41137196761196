import { useState } from 'react';
import { AlertCircle, MoreVertical } from 'react-feather';
import ModelRuleOptions from '../../components/ModelRuleOptons';

import { ModelRules } from '@/api/services/bitAi/v2-new/model';
import {
  BBBBottomSheet,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import colors from '@/constants/common/colors';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';

export default function useColumnManage({
  onUpdate,
  onRemove,
}: {
  onUpdate: (
    rowId: string,
    columnId: string,
    value:
      | number
      | string
      | SingleOnValueChangeParam<{ value: string; label: string }>
  ) => void;
  onRemove: (rowId: string) => void;
}): HeadersTypes<Pick<ModelRules, 'id' | 'qty' | 'index' | 'condition'>> {
  const [actionOptions, setActionOptions] = useState(false);
  const toggleConfirmation = useConfirmationModal();

  return [
    {
      accesor: 'qty',
      render: (row) => (
        <div className="flex items-start gap-4">
          <div className="flex flex-col grow gap-2">
            <BBBTextInput
              value={row.qty}
              onChange={(e) => onUpdate(row.id, 'qty', e.target.value)}
              placeholder="Please input with minimum 1 size"
              inputClassName={`${!row.qty && 'border-danger-main'}`}
              containerClassname="mb-0"
              label="Product Qty"
              isFixedSuffix={!row.qty}
              fixedSuffixLabel={
                <>{!row.qty && <AlertCircle fill="#FF6060" color="#FFFF" />}</>
              }
            />

            <ModelRuleOptions
              value={row.condition}
              onValueChange={(value) => onUpdate(row.id, 'condition', value)}
            />
          </div>
          <MoreVertical
            onClick={(e) => {
              setActionOptions(true);
              e.stopPropagation();
            }}
            color={colors.primary.main}
          />
          <MobileActions
            id={row.id}
            title="Actions"
            show={actionOptions}
            onDelete={(id) =>
              toggleConfirmation({
                title: 'Delete Product',
                description: 'Are you sure you want to delete this product?',
                submitText: 'Delete',
                onAccept: (hide) => {
                  onRemove(id);
                  hide();
                },
              })
            }
            onHide={() => setActionOptions(false)}
          />
        </div>
      ),
    },
  ];
}

function MobileActions({
  id,
  show,
  title,
  onHide,
  onDelete,
}: {
  id: string;
  show: boolean;
  title: string;
  onHide?: () => void;
  onDelete?: (id: string) => void;
}) {
  return (
    <BBBBottomSheet show={show} title={title} onClose={onHide}>
      <div className="flex flex-col gap-4">
        <div className="text-primary-main" onClick={() => onDelete?.(id)}>
          Delete
        </div>
      </div>
    </BBBBottomSheet>
  );
}
