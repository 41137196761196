function MagicLinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.975 14.51a1.05 1.05 0 000-1.485 2.95 2.95 0 010-4.172l3.536-3.535a2.95 2.95 0 114.172 4.172l-1.093 1.092a1.05 1.05 0 001.485 1.485l1.093-1.092a5.05 5.05 0 00-7.142-7.142L9.49 7.368a5.05 5.05 0 000 7.142c.41.41 1.075.41 1.485 0zm2.05-5.02a1.05 1.05 0 000 1.485 2.95 2.95 0 010 4.172l-3.5 3.5a2.95 2.95 0 11-4.171-4.172l1.025-1.025a1.05 1.05 0 00-1.485-1.485L3.87 12.99a5.05 5.05 0 007.142 7.142l3.5-3.5a5.05 5.05 0 000-7.142 1.05 1.05 0 00-1.485 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MagicLinkIcon;
