import { createId } from '@paralleldrive/cuid2';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const preBuiltAiTags = [
  {
    id: createId(),
    label: 'Language',
    description: 'Customer language',
    values: 'English, Indonesia, Chinese',
  },
  {
    id: createId(),
    label: 'Priority',
    description: 'Priority of conversation',
    values: 'High, low, neutral',
  },
  {
    id: createId(),
    label: 'Intention',
    description: 'Summarize the intention of the conversation',
    values: 'Complaint, return, product, order, status',
  },
];

export const autoResolveOptions = [
  { label: 'Hours', value: 'hours' },
  { label: 'Minutes', value: 'minutes' },
];

export function generateTimeOptions(format: '12hr' | '24hr' = '24hr') {
  const intervalOptions = ['00', '15', '30', '45'];

  const options =
    format === '12hr'
      ? ['12', ...Array.from({ length: 11 }).map((_, i) => (i + 1).toString())]
      : Array.from({ length: 24 }).map((_, i) => i.toString());
  return options.flatMap((option) =>
    intervalOptions.map((interval) => {
      const value = `${
        option.length === 1 ? '0' + option : option
      }:${interval}`;
      return {
        label: value,
        value,
      };
    })
  );
}

export const operationalHoursTypeOptions = [
  {
    label: 'Weekdays only (Mon - Fri)',
    value: 'weekday',
  },
  {
    label: 'Everyday',
    value: 'everyday',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export const operationalHoursTypeOptionsHash =
  operationalHoursTypeOptions.reduce<
    Record<string, typeof operationalHoursTypeOptions[number]>
  >((acc, item) => {
    acc[item.value] = item;
    return acc;
  }, {});

export const customOperationalHour = [
  {
    operationalDay: 'Sunday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: false,
  },
  {
    operationalDay: 'Monday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
  {
    operationalDay: 'Tuesday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
  {
    operationalDay: 'Wednesday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
  {
    operationalDay: 'Thursday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
  {
    operationalDay: 'Friday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
  {
    operationalDay: 'Saturday',
    operationalStart: '09:00',
    operationalStartEnd: '21:00',
    isOperationalActive: true,
  },
];
