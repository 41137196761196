import { PricingPlan } from 'api/services/pricing';

export const highlightedCardText: Partial<Record<PricingPlan, string[]>> = {
  free: [
    'Custom model sorting',
    'Push down Out of Stock',
    'Advance sorting',
    'Fully integrated with Shopify',
  ],
  pro: [
    'Daily Automatic Updates',
    'More Managed Collections',
    'More Advance Custom Model',
  ],
  proAdvance: [
    'Automatic Updates (2x/day)',
    'Advance sorting',
    'More Managed Collections',
    'More Advance Custom Model',
  ],
};
