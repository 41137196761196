import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setActiveCompany, updateCompany } from '@/stores/auth';
import { Company } from '@/types/systemLogin';
import { _localStorage } from '@/utils/common/localStorage';

type AddUserCompanyPayload = {
  name: string;
  industry?: string;
  country?: string;
};

const useAddUserCompany = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation<Company, unknown, AddUserCompanyPayload>(
    (payload) =>
      api.systemLogin
        .post<ApiResponse<Company>>(`/user/company`, payload)
        .then((res) => res.data.data),
    {
      onSuccess: (data) => {
        dispatch(setActiveCompany(data.id));
        dispatch(updateCompany(data));
        queryClient.invalidateQueries(['authenticated-user-companies']);
        _localStorage.setItem('activeCompany', data.id.toString());
      },
    }
  );
};

export default useAddUserCompany;
