import { Link } from 'react-router-dom';

import { BBBButton } from '@/components/ui';

export default function DesktopOptimized({
  backLink,
  description,
}: {
  backLink: string;
  description: string;
}) {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col px-4">
      <div className="text-2xl font-medium mb-4 text-center">
        Switch over to desktop site
      </div>
      <div className="mb-8 text-center">{description}</div>
      <Link to={backLink}>
        <BBBButton>Go back to previous page</BBBButton>
      </Link>
    </div>
  );
}
