import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';
import { toast } from 'utils/common/toast';

import { WACloudTemplate } from '@/types/whatsApp/settings';

const useUpdateTemplate = () => {
  const activeCompany = useActiveCompany();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    (
      payload: Partial<
        Pick<
          WACloudTemplate,
          'name' | 'language' | 'message' | 'isDraft' | 'params'
        >
      >
    ) => {
      const updatedPayload = { ...payload };

      if (updatedPayload.message?.includes('{{otp.code}}')) {
        updatedPayload.message = updatedPayload.message.replace(
          /{{otp.code}}/g,
          '{{1}}'
        );

        updatedPayload.params = [
          ...(updatedPayload.params || []),
          ...(updatedPayload.params?.some(
            (param) => param.value === '{{otp.code}}'
          )
            ? []
            : [
                {
                  group: 'otp',
                  label: 'OTP Code',
                  value: '{{otp.code}}',
                  placeholder: '123456',
                },
              ]),
        ];
      }

      return services.bitLogin.template.upsertTemplate(activeCompany, {
        id: id === 'new' ? undefined : id,
        ...updatedPayload,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-templates']);
        queryClient.invalidateQueries(['bitlogin-active-template']);
        toast.success('Template updated successfully');
        history.push(
          '/misc/whatsapp-api-settings?section=bitlogin-whatsapp-settings'
        );
      },
    }
  );
};

export default useUpdateTemplate;
