import api from '@/config/api';
import { ApiResponse } from '@/types/bitCRM/api';
import {
  Automation,
  AutomationLog,
  JubelioChannelsData,
} from '@/types/bitCRM/automation';
import { MessageHistory } from '@/types/bitCRM/message-history';

export default {
  getAutomationLog: (
    companyId: number | undefined,
    automationId: string | undefined,
    params: GetAutomationLogParams
  ) =>
    api.bitCRM.v2
      .get<AutomationLog>(
        `/automation/${companyId}/${automationId}/analytics`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getAutomationMessageLog: (
    companyId: number | undefined,
    automationId: string | undefined,
    params: GetAutomationMessageLogParams
  ) =>
    api.bitCRM.v2
      .get<ApiResponse<MessageHistory>>(
        `/automation/${companyId}/${automationId}/analytics/log-v2`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getAdvanceScheduleTypes: () =>
    api.bitCRM.v2
      .get<string[]>(`/automation/option/advance-schedule-types`)
      .then((res) => res.data),
  getJubelioChannels: (companyId: number) =>
    api.bitCRM.v2
      .get<JubelioChannelsData[]>(`/jubelio/channels/${companyId}`)
      .then((res) =>
        res.data.map((_data) => ({
          label: `${_data?.store_name} - ${_data?.channel_name}`,
          value: _data.store_id,
        }))
      ),
  getScenarioList: (
    companyId: number | undefined,
    { page = 0, size = 10 }: GetScenarioListParams
  ) =>
    api.bitCRM.v2
      .get<ApiResponse<Automation>>(`/automation/${companyId}`, {
        params: {
          page,
          size,
        },
      })
      .then((res) => res.data),
};

export type GetScenarioListParams = {
  page: number;
  size?: number;
};

export type GetAutomationLogParams = {
  startDate: string;
  endDate: string;
};

export type GetAutomationMessageLogParams = {
  startDate: string;
  endDate: string;
  page?: number;
  size?: number;
  status?: string | null;
  search?: string;
  sort?: string;
};
