import { ApiResponse } from 'api/types';
import api from 'config/api';
import { Collection } from 'types/bitApp/v2';

export default {
  getCollections: () =>
    api.bitApp
      .get<ApiResponse<Collection[]>>(`/collections`)
      .then((res) => res.data.data),
};
