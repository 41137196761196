export const orderOptions = [
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
];

export const dateOptions = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 3 days',
    value: 'last-3-days',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    label: 'Last 30 days',
    value: 'last-30-days',
  },
];

export const statusOptions = [
  {
    label: 'Waiting',
    value: 'Waiting',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Resolved',
    value: 'Resolved',
  },
];
