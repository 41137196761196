import { useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Trash2 } from 'react-feather';
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  useFieldArray,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import PageRedirectionFields from 'pages/BitApp/components/PageRedirectionFields';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import {
  formatApiLinkToRedirectionObject,
  formatRedirectionPathObject,
  handleCancelLiveSection,
} from 'utils/bitApp';
import { _localStorage } from 'utils/common/localStorage';
import { toast } from 'utils/common/toast';
import Popup from '.';

import AlertIcon2 from '@/assets/icons/AlertIcon2';
import {
  BBBAlert,
  BBBImageUpload,
  BBBSpinner,
  BBBTextInput,
  BBBTooltip,
} from '@/components/ui';
import { env } from '@/config/env';
import defaultRedirectionPath from '@/constants/bitApp/defaultRedirectOptions';
import { CIRCLE_IMAGE_GRIDS } from '@/constants/bitApp/designPresets';
import redirectionPathSchema from '@/constants/bitApp/redirectionPathSchema';
import redirectNotificationOptions, {
  IRedirectNotificationOptions,
} from '@/constants/bitApp/redirectNotificationOptions';
import { useAddImageGrid } from '@/hooks/bitApp/design/image-grid';
import {
  useDeleteSection,
  useSectionDetail,
} from '@/hooks/bitApp/design/section';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import useUpdateOnboarding from '@/hooks/bitApp/onboarding/useUpdateOnboarding';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setChangedPopup, setPopup, setPopupError } from '@/stores/bitApp';

const formSchema = {
  title: yup.string().optional(),
  link: redirectionPathSchema,
  image: yup.string().required('Image is required'),
  active: yup.boolean().required('Active is required'),
  imageGridContentId: yup.number().required('Image grid id is required'),
};

export type ImageGridsForm<isSubmit = false> = {
  imageGridId: number;
  active: boolean;
  collection: {
    title: string;
    link: isSubmit extends false ? IRedirectNotificationOptions : string | null;
    image: string;
    active: boolean;
    imageGridContentId: number;
  }[];
  title: string;
};

const schema = yup
  .object()
  .shape({
    collection: yup
      .array()
      .of(yup.object().shape(formSchema))
      .required('Must have fields')
      .min(1, 'Minimum of 1 field'),
    active: yup.boolean(),
    title: yup.string().required('Image Grid Title is required'),
    imageGridId: yup.number().required('Image Grid id is required'),
  })
  .required();

export default function EditCircleImageGrid() {
  const { data, status } = useOnboarding('design');

  if (status === 'success' && data?.lastStep) {
    if (data.lastStep === 5 || data.lastStep === 6)
      return <_EditCircleImageGridNewUsers steps={data?.lastStep} />;
  }

  return <_EditCircleImageGridOnboarded />;
}

function _EditCircleImageGridNewUsers({
  steps,
}: {
  steps: number | undefined;
}) {
  return (
    <Popup
      id="edit-collection-popup"
      onSubmit={() => null}
      onCancel={() => null}
    >
      <div className="flex flex-col gap-6">
        <div className="w-full flex justify-between">
          <p className="text-primary text-2xl">Edit circle image grid</p>
          <Trash2 className="text-danger-main cursor-pointer hover:text-red-400" />
        </div>
        <div className="h-[1px] bg-neutral-30" />
        <BBBTextInput
          label={
            <div>
              Grid title<span className="text-danger-main">*</span>
            </div>
          }
          placeholder="Enter your title"
          value={steps === 6 ? 'Category' : ''}
          hasMaxCharLabel
          maxChar={60}
        />
        <div className="h-[1px] bg-neutral-30" />
        <div className="w-full overflow-auto scrollbar-hide pb-4">
          <div className="inline-flex items-center gap-2.5">
            <AddImagePlaceholder />
            <div id="circle-grid-list" className="w-full flex gap-2.5">
              {CIRCLE_IMAGE_GRIDS?.map((item, idx) => (
                <div
                  key={item.title}
                  className={cx(
                    'w-20 h-20 relative border rounded-lg p-0.5 cursor-pointer hover:border-2 transition-all duration-200 ease-in-out overflow-clip',
                    idx === 0
                      ? 'border-secondary-main border-2'
                      : 'border-neutral-40 hover:border-secondary-main'
                  )}
                >
                  <img
                    className="w-full h-full object-cover rounded-lg"
                    src={
                      item.image ||
                      `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/image-placeholder.png`
                    }
                    alt="image"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-neutral-30" />
        <div className="flex flex-col gap-3">
          <p className="text-primary-main">
            Image 1 of 6<span className="text-danger-main">*</span>
          </p>
          <BBBImageUpload
            imageUrl={CIRCLE_IMAGE_GRIDS[0]?.image}
            imageClassName="object-cover"
            className="flex-col gap-4 item-center justify-center"
            crop={{
              circular: true,
              aspect: 1 / 1,
              whitelistedRatio: ['square'],
            }}
            shape="circle"
            height={'8.96875rem'}
            width={'8.96875rem'}
          />
          <BBBTextInput
            label="Image title"
            placeholder="Enter your title"
            value={steps === 6 ? 'Women' : ''}
            hasMaxCharLabel
            maxChar={60}
            containerClassname="mb-0"
          />
          <PageRedirectionFields
            value={
              redirectNotificationOptions.find(
                (opt) => opt.label === 'Product'
              )!
            }
            containerClassName="!mt-4 flex flex-col gap-4"
            onChange={(value) => null}
            withoutDynamicVariables
            withRequiredLabel
          />
        </div>
      </div>
    </Popup>
  );
}

function _EditCircleImageGridOnboarded() {
  const dispatch = useAppDispatch();
  const toggleConfirmation = useConfirmationModal();

  const popup = useAppSelector((state) => state.bitApp.popup);
  const selected = useAppSelector((state) => state.bitApp.selected);

  const { isInitialLoading: isLoadingSection, data: dataSection } =
    useSectionDetail(selected);

  const imageGridDataFromApi = useMemo(
    () => dataSection?.imageGrids,
    [dataSection?.imageGrids]
  );

  const formDefaultValue: ImageGridsForm = useMemo(
    () => ({
      imageGridId: imageGridDataFromApi?.id || -1,
      title: '',
      collection: CIRCLE_IMAGE_GRIDS.map((item, idx) => ({
        ...item,
        imageGridContentId: 0 - idx,
      })),
      active: true,
    }),
    [imageGridDataFromApi?.id]
  );

  const {
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    trigger,
    handleSubmit,
  } = useCustomForm<ImageGridsForm>({
    defaultValues: formDefaultValue,
    resolver: yupResolver(schema),
  });

  const { fields, insert, remove, move } = useFieldArray({
    control,
    name: 'collection',
  });

  const [active, setActive] = useState<number | null>(
    watch('collection.0.imageGridContentId')
  );
  const [hoveredSlide, setHoveredSlide] = useState<number | null>(null);
  const [showBannerError, setShowBannerError] = useState(false);

  const isHasErrors =
    fields.some(
      (item, idx) =>
        errors.collection?.[idx]?.image?.message ||
        errors.collection?.[idx]?.title?.message ||
        errors.collection?.[idx]?.link?.message ||
        errors.collection?.[idx]?.link?.params?.[0]?.data?.message
    ) || popup?.hasError;

  const errorIndexes = fields.reduce((acc: number[], _, idx) => {
    if (
      errors.collection?.[idx]?.image?.message ||
      errors.collection?.[idx]?.title?.message ||
      errors.collection?.[idx]?.link?.message ||
      errors.collection?.[idx]?.link?.params?.[0]?.data?.message
    ) {
      acc.push(idx + 1);
    }
    return acc;
  }, []);

  const { mutate: save, isLoading: isLoadingSave } = useAddImageGrid();
  const { mutate: updateOnboarding } = useUpdateOnboarding();

  const activeIndex = fields.findIndex(
    (item) => item.imageGridContentId === active
  );

  const renderForm = useMemo(() => {
    if (activeIndex !== -1) {
      return (
        <>
          <BBBImageUpload
            imageUrl={watch(`collection.${activeIndex}.image`) || ''}
            onChangeImage={(value) => {
              setValue(`collection.${activeIndex}.image`, value || '');
            }}
            imageClassName="object-cover rounded-full"
            className="flex-col gap-4 item-center justify-center"
            crop={{
              circular: true,
              aspect: 1 / 1,
              whitelistedRatio: ['square'],
            }}
            shape="circle"
            height={'8.96875rem'}
            width={'8.96875rem'}
          />
          <BBBTextInput
            label="Image title"
            placeholder="Enter your title"
            value={watch(`collection.${activeIndex}.title`)}
            onChange={({ target: { value } }) => {
              setValue(`collection.${activeIndex}.title`, value);
            }}
            hasMaxCharLabel
            maxChar={60}
            error={errors.collection?.[activeIndex]?.title?.message}
            containerClassname="mb-0"
          />
          <PageRedirectionFields
            value={watch(`collection.${activeIndex}.link`)}
            containerClassName="!mt-4 flex flex-col gap-4"
            onChange={(value) => {
              setValue(
                `collection.${activeIndex}.link`,
                value as IRedirectNotificationOptions
              );
            }}
            withoutDynamicVariables
            errorValue={errors.collection?.[activeIndex]?.link?.message}
            errorValueParam={(
              errors.collection?.[activeIndex]?.link?.params as {
                [key: string]: {
                  message: string;
                };
              }[]
            )?.map((err) => err?.data?.message)}
            withRequiredLabel
          />
          {(showBannerError || popup?.hasError) && (
            <BBBAlert type="danger" className="mt-3">
              <p className="text-center text-sm leading-4">
                {errorIndexes.length ? (
                  <>
                    Missing information in image{' '}
                    {errorIndexes.map(
                      (item) => `${item}${errorIndexes.length > 1 ? ', ' : ''}`
                    )}
                    ! Please complete all required fields to continue saving
                  </>
                ) : (
                  popup?.errorMessage
                )}
              </p>
            </BBBAlert>
          )}
        </>
      );
    }
  }, [
    activeIndex,
    errorIndexes,
    errors.collection,
    popup?.errorMessage,
    popup?.hasError,
    setValue,
    showBannerError,
    watch(),
  ]);

  const onSubmit = async (data: ImageGridsForm) => {
    const finalData = {
      ...data,
      sectionId: selected,
      collection: data.collection.map((imageGridContent) => {
        const link = formatRedirectionPathObject(imageGridContent.link) || '';
        return {
          ...imageGridContent,
          link: link === 'none' ? null : link,
        };
      }),
    };
    save(finalData, {
      onSuccess: () => {
        updateOnboarding({
          moduleKey: 'design-notifications',
          lastStep: 1,
        });
      },
    });
  };

  const deleteSection = useDeleteSection();

  const handleDeleteSection = () => {
    deleteSection();
  };

  const handleUndoSlide = (lastPosition: number) => {
    const tempLocalDeletedSection = _localStorage.getItem(
      'temp-deleted-circle-image-grid'
    );
    if (tempLocalDeletedSection) {
      const parsed = JSON.parse(tempLocalDeletedSection) as
        | ImageGridsForm<false>['collection']
        | undefined;

      const selectedGrid = parsed!.find(
        (item) =>
          item.imageGridContentId === fields[lastPosition]?.imageGridContentId
      )!;

      if (parsed) {
        insert(lastPosition, {
          active: selectedGrid.active,
          title: selectedGrid.title,
          image: selectedGrid.image,
          link: selectedGrid.link,
          imageGridContentId: selectedGrid.imageGridContentId,
        });
        setActive(fields[lastPosition]?.imageGridContentId);
      }
    }
  };

  const handleDeleteSlide = (
    imageGridContentId: number,
    position: number,
    fields: FieldArrayWithId<ImageGridsForm<false>, 'collection', 'id'>[]
  ) => {
    remove(position);
    setActive(fields[position + 1]?.imageGridContentId);

    const tempLocalDeletedSection = _localStorage.getItem(
      'temp-deleted-circle-image-grid'
    );

    if (tempLocalDeletedSection) {
      const parsed = JSON.parse(tempLocalDeletedSection) as
        | ImageGridsForm<false>['collection']
        | undefined;

      if (parsed) {
        _localStorage.setItem(
          'temp-deleted-circle-image-grid',
          JSON.stringify([...parsed, fields[position]])
        );
      }
    } else {
      _localStorage.setItem(
        'temp-deleted-circle-image-grid',
        JSON.stringify([fields[position]])
      );
    }

    toast.action('Image deleted.', {
      id: `delete-circle-image-grid-${imageGridContentId}`,
      actionCallback(onHide) {
        handleUndoSlide(position);
        onHide();
      },
      actionText: 'Undo delete',
      duration: 6000,
    });
  };

  const formIsEqual = isEqual(
    {
      title: imageGridDataFromApi?.title as string,
      collection: imageGridDataFromApi?.imageGridContents.map(
        ({ image, link, active, title, id }) => {
          const formattedLink = formatApiLinkToRedirectionObject(link);
          return {
            image: image || '',
            link: formattedLink,
            active: active || false,
            title: title || '',
            imageGridContentId: id,
          };
        }
      ),
      imageGridId: imageGridDataFromApi?.id,
      active: imageGridDataFromApi?.active || true,
    },
    watch()
  );

  const handleCancel = () => {
    if (!formIsEqual) {
      return toggleConfirmation({
        title: 'Unsaved changes',
        description: 'You have unsaved changes. Are you sure want to continue?',
        cancelText: 'Back to edit',
        submitText: 'Discard changes',
        onAccept: (hide) => {
          handleCancelLiveSection();
          hide();
        },
      });
    }
    handleCancelLiveSection();
  };

  const handleOnDragEnd = (event: DropResult) => {
    if (!event.destination) {
      return;
    }

    move(event.source.index, event.destination.index);
  };

  const handleAddSlide = (
    fields: FieldArrayWithId<ImageGridsForm<false>, 'collection', 'id'>[]
  ) => {
    if (fields) {
      if (fields.length > 9) {
        return toast.error('You can only upload 9 images at a time');
      }
      insert(0, {
        title: '',
        link: defaultRedirectionPath,
        active: true,
        image: '',
        imageGridContentId: 0 - fields.length - 99,
      });
      setActive(0 - fields.length - 99);
    }
  };

  const handleClickSlide = (imageGridContentId: number) => {
    trigger();
    if (isHasErrors) {
      dispatch(
        setPopup({
          type: 'imagegrids',
          id: selected,
          hasError: true,
        })
      );
    }
    setActive(imageGridContentId);
  };

  const handleInvalidSubmit = (
    errors: Partial<FieldErrorsImpl<ImageGridsForm>>
  ) => {
    dispatch(
      setPopup({
        type: 'imagegrids',
        id: selected,
        hasError: true,
      })
    );

    const errorIndexes = Object.keys(errors.collection || {}).map((key) =>
      parseInt(key, 10)
    );
    const firstErrorIndex = errorIndexes[0];
    if (firstErrorIndex !== undefined) {
      setShowBannerError(true);
      setActive(fields[firstErrorIndex].imageGridContentId);
    }
  };

  const handleSave = () => {
    if (isHasErrors) {
      setShowBannerError(true);
    } else {
      setShowBannerError(false);
    }
    handleSubmit(onSubmit, handleInvalidSubmit)();
  };

  useEffect(() => {
    if (imageGridDataFromApi?.imageGridContents?.length) {
      reset({
        title: imageGridDataFromApi.title as string,
        collection: imageGridDataFromApi.imageGridContents.map(
          ({ image, link, active, title, id }) => {
            const formattedLink = formatApiLinkToRedirectionObject(link);
            return {
              image: image || '',
              link: formattedLink,
              active: active || false,
              title: title || '',
              imageGridContentId: id,
            };
          }
        ),
        imageGridId: imageGridDataFromApi.id,
        active: imageGridDataFromApi.active || true,
      });
      setActive(imageGridDataFromApi.imageGridContents[0].id);
    }
  }, [
    reset,
    formDefaultValue,
    imageGridDataFromApi?.imageGridContents,
    imageGridDataFromApi?.title,
    imageGridDataFromApi?.active,
    imageGridDataFromApi?.id,
  ]);

  useEffect(() => {
    if (isHasErrors) {
      dispatch(setPopupError(selected));
    } else {
      dispatch(setPopupError(null));
      setShowBannerError(false);
    }
  }, [dispatch, isHasErrors, selected]);

  useEffect(() => {
    if (!formIsEqual) {
      dispatch(setChangedPopup(true));
    } else {
      dispatch(setChangedPopup(false));
    }
  }, [dispatch, formIsEqual]);

  return (
    <Popup
      onSubmit={handleSave}
      onCancel={handleCancel}
      isLoadingSubmit={isLoadingSave || isLoadingSection}
      className={
        showBannerError || popup?.hasError
          ? 'shadow shadow-danger-main border-danger-main shake'
          : ''
      }
      disabledSubmit={formIsEqual}
    >
      {isLoadingSection ? (
        <div className="w-full h-full flex items-center justify-center">
          <BBBSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="w-full flex justify-between">
            <p className="text-primary text-2xl">Edit circle image grid</p>
            <Trash2
              className="text-danger-main cursor-pointer hover:text-red-400"
              onClick={handleDeleteSection}
            />
          </div>
          <div className="h-[1px] bg-neutral-30" />
          <BBBTextInput
            label={
              <div>
                Grid title<span className="text-danger-main">*</span>
              </div>
            }
            placeholder="Enter your title"
            isHookForm
            control={control}
            controlName="title"
            hasMaxCharLabel
            maxChar={60}
            error={errors.title?.message}
          />
          <div className="h-[1px] bg-neutral-30" />
          <div className="w-full overflow-auto scrollbar-hide pb-4">
            <div className="inline-flex items-center gap-2.5">
              {fields.length < 9 && (
                <div onClick={() => handleAddSlide(fields)}>
                  <AddImagePlaceholder />
                </div>
              )}
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable
                  droppableId="image-circle-grid"
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      id="circle-grid-list"
                      className="w-full flex gap-2.5"
                    >
                      {watch('collection').map((item, idx) => (
                        <Draggable
                          key={item.imageGridContentId}
                          draggableId={`${item.imageGridContentId}-${idx}`}
                          index={idx}
                        >
                          {(providedDraggable) => (
                            <div
                              ref={providedDraggable?.innerRef}
                              onClick={() =>
                                handleClickSlide(item.imageGridContentId)
                              }
                              {...providedDraggable?.draggableProps}
                            >
                              <div
                                className={cx(
                                  'w-20 h-20 relative border rounded-lg p-0.5 cursor-pointer hover:border-2 transition-all duration-200 ease-in-out overflow-clip',
                                  active === item.imageGridContentId &&
                                    (errors.collection?.[idx]?.image?.message ||
                                      errors.collection?.[idx]?.title
                                        ?.message ||
                                      errors.collection?.[idx]?.link?.message ||
                                      errors.collection?.[idx]?.link
                                        ?.params?.[0]?.data?.message)
                                    ? 'border-danger-main border-2 hover:border-danger-main'
                                    : errors.collection?.[idx]?.image
                                        ?.message ||
                                      errors.collection?.[idx]?.title
                                        ?.message ||
                                      errors.collection?.[idx]?.link?.message ||
                                      errors.collection?.[idx]?.link
                                        ?.params?.[0]?.data?.message
                                    ? 'border-danger-main border hover:border-danger-main'
                                    : active === item.imageGridContentId
                                    ? 'border-secondary-main border-2'
                                    : active === item.imageGridContentId
                                    ? 'border-secondary-main border-2'
                                    : 'border-neutral-40 hover:border-secondary-main'
                                )}
                                onMouseEnter={() =>
                                  setHoveredSlide(item.imageGridContentId)
                                }
                                onMouseLeave={() => setHoveredSlide(null)}
                                {...providedDraggable?.dragHandleProps}
                              >
                                <img
                                  className="w-full h-full object-cover rounded-lg"
                                  src={
                                    item.image ||
                                    `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/image-placeholder.png`
                                  }
                                  alt="image"
                                />
                                {fields?.length > 1 &&
                                  hoveredSlide === item.imageGridContentId && (
                                    <>
                                      <div className="absolute inset-0 bg-black/50" />
                                      <Trash2
                                        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white hover:text-danger-main cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteSlide(
                                            item.imageGridContentId,
                                            idx,
                                            fields
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                {(errors.collection?.[idx]?.image?.message ||
                                  errors.collection?.[idx]?.title?.message ||
                                  errors.collection?.[idx]?.link?.message ||
                                  errors.collection?.[idx]?.link?.params?.[0]
                                    ?.data?.message) && (
                                  <AlertIcon2
                                    className="absolute right-1 top-1"
                                    type="danger"
                                    size={18}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className="h-[1px] bg-neutral-30" />
          <div className="flex flex-col gap-3">
            <p className="text-primary-main">
              Image {activeIndex + 1} of {fields.length}
              <span className="text-danger-main">*</span>
            </p>
            {renderForm}
          </div>
        </div>
      )}
    </Popup>
  );
}

function AddImagePlaceholder() {
  return (
    <BBBTooltip show content="Add new image">
      <svg
        width="74"
        height="74"
        viewBox="0 0 74 74"
        fill="none"
        className="w-20 h-20 cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.9377 73.393C2.98217 72.582 1.41797 71.0178 0.607045 69.0623L1.06891 68.8708C0.702487 67.9872 0.5 67.0179 0.5 66V62.375H0V55.125H0.5V47.875H0V40.625H0.5V33.375H0V26.125H0.5V18.875H0V11.625H0.5V8C0.5 6.98214 0.702487 6.01284 1.06891 5.12922L0.607044 4.9377C1.41797 2.98217 2.98217 1.41797 4.9377 0.607044L5.12922 1.06891C6.01284 0.702487 6.98214 0.5 8 0.5H11.625V0H18.875V0.5H26.125V0H33.375V0.5H40.625V0H47.875V0.5H55.125V0H62.375V0.5H66C67.0179 0.5 67.9872 0.702487 68.8708 1.06891L69.0623 0.607043C71.0178 1.41797 72.582 2.98217 73.393 4.9377L72.9311 5.12922C73.2975 6.01284 73.5 6.98214 73.5 8V11.625H74V18.875H73.5V26.125H74V33.375H73.5V40.625H74V47.875H73.5V55.125H74V62.375H73.5V66C73.5 67.0179 73.2975 67.9872 72.9311 68.8708L73.393 69.0623C72.582 71.0178 71.0178 72.582 69.0623 73.393L68.8708 72.9311C67.9872 73.2975 67.0179 73.5 66 73.5H62.375V74H55.125V73.5H47.875V74H40.625V73.5H33.375V74H26.125V73.5H18.875V74H11.625V73.5H8C6.98214 73.5 6.01284 73.2975 5.12922 72.9311L4.9377 73.393Z"
          fill="white"
          stroke="#9E9E9E"
          strokeDasharray="8 8"
        />
        <path
          d="M37.5751 32.5527V37.5614M37.5751 37.5614V42.5701M37.5751 37.5614H42.5838M37.5751 37.5614H32.5664"
          stroke="#9E9E9E"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M37.57 50.0882C44.4863 50.0882 50.093 44.4814 50.093 37.5651C50.093 30.6488 44.4863 25.0421 37.57 25.0421C30.6536 25.0421 25.0469 30.6488 25.0469 37.5651C25.0469 44.4814 30.6536 50.0882 37.57 50.0882Z"
          stroke="#9E9E9E"
          strokeWidth="1.5"
        />
      </svg>
    </BBBTooltip>
  );
}
