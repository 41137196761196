function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#757575"
        d="M8.498 6.332A2.172 2.172 0 006.331 8.5c0 1.194.974 2.168 2.167 2.168A2.172 2.172 0 0010.666 8.5a2.172 2.172 0 00-2.168-2.168zM15 8.5c0-.898.008-1.787-.042-2.683-.05-1.04-.288-1.964-1.049-2.725-.762-.763-1.684-.999-2.725-1.05C10.285 1.993 9.396 2 8.5 2c-.898 0-1.787-.008-2.683.043-1.04.05-1.964.288-2.725 1.049-.763.762-.999 1.684-1.05 2.725C1.993 6.715 2 7.604 2 8.5c0 .896-.008 1.787.043 2.683.05 1.04.288 1.964 1.049 2.725.762.763 1.684.999 2.725 1.05.897.05 1.787.041 2.683.041.897 0 1.787.009 2.683-.042 1.04-.05 1.964-.288 2.725-1.049.763-.762.999-1.684 1.049-2.725.052-.896.042-1.785.042-2.683zm-6.5 3.335A3.33 3.33 0 015.162 8.5a3.33 3.33 0 013.335-3.335A3.33 3.33 0 0111.833 8.5a3.33 3.33 0 01-3.335 3.335zm3.47-6.028a.778.778 0 110-1.558.778.778 0 110 1.558z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
