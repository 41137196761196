import React from 'react';

import colors from '@/constants/common/colors';

type Props = React.SVGProps<SVGSVGElement>;

function CircleXIcon({ ...props }: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C3.68636 1 1 3.68636 1 7C1 10.3136 3.68636 13 7 13C10.3136 13 13 10.3136 13 7C13 3.68636 10.3136 1 7 1ZM9.022 5.74927C9.12136 5.6464 9.17634 5.50862 9.17509 5.3656C9.17385 5.22258 9.11649 5.08578 9.01536 4.98464C8.91422 4.88351 8.77742 4.82615 8.6344 4.82491C8.49138 4.82366 8.3536 4.87864 8.25073 4.978L7 6.22873L5.74927 4.978C5.69896 4.9259 5.63877 4.88435 5.57222 4.85576C5.50567 4.82718 5.4341 4.81213 5.36167 4.8115C5.28925 4.81087 5.21742 4.82467 5.15039 4.8521C5.08335 4.87952 5.02245 4.92003 4.97124 4.97124C4.92003 5.02245 4.87952 5.08335 4.8521 5.15039C4.82467 5.21742 4.81087 5.28925 4.8115 5.36167C4.81213 5.4341 4.82718 5.50567 4.85576 5.57222C4.88435 5.63877 4.9259 5.69896 4.978 5.74927L6.22873 7L4.978 8.25073C4.9259 8.30104 4.88435 8.36123 4.85576 8.42778C4.82718 8.49433 4.81213 8.5659 4.8115 8.63833C4.81087 8.71075 4.82467 8.78258 4.8521 8.84961C4.87952 8.91665 4.92003 8.97755 4.97124 9.02876C5.02245 9.07998 5.08335 9.12048 5.15039 9.1479C5.21742 9.17533 5.28925 9.18913 5.36167 9.1885C5.4341 9.18787 5.50567 9.17282 5.57222 9.14424C5.63877 9.11565 5.69896 9.0741 5.74927 9.022L7 7.77127L8.25073 9.022C8.3536 9.12136 8.49138 9.17634 8.6344 9.17509C8.77742 9.17385 8.91422 9.11649 9.01536 9.01536C9.11649 8.91422 9.17385 8.77742 9.17509 8.6344C9.17634 8.49138 9.12136 8.3536 9.022 8.25073L7.77127 7L9.022 5.74927Z"
        fill={colors.neutral[50]}
      />
    </svg>
  );
}

export default CircleXIcon;
