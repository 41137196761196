import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import services from 'api/services';
import { CategoryResponse } from 'types/bitApp/association';
import { toast } from 'utils/common/toast';
import useConfirmationModal from '../common/useConfirmationModal';
import { useActiveCompany } from '../rtk/selector';

const CATEGORY_QUERY_KEY = 'bitapp-category' as const;

export const useCategory = () => {
  const activeCompany = useActiveCompany();
  return useInfiniteQuery(
    [CATEGORY_QUERY_KEY, activeCompany],
    ({ pageParam = 0 }) =>
      services.bitApp.category.getCategoryByCompanyId(activeCompany!, {
        offset: pageParam,
      })
  );
};

export const useCategoryByCompanyId = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${CATEGORY_QUERY_KEY}-by-company`, activeCompany],
    () => services.bitApp.category.getCategoryByCompanyId(activeCompany),
    {
      enabled: !!activeCompany,
    }
  );
};

export const useCategoryById = (
  categoryId: Parameters<typeof services.bitApp.category.getCategoryById>[0],
  {
    enabled,
  }: {
    enabled?: boolean;
  }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`${CATEGORY_QUERY_KEY}-by-id`, categoryId, activeCompany],
    () => services.bitApp.category.getCategoryById(categoryId, activeCompany),
    {
      enabled: !!activeCompany && enabled,
    }
  );
};

export const useAddCategory = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    (payload: Parameters<typeof services.bitApp.category.addCategory>[1]) =>
      services.bitApp.category.addCategory(activeCompany!, {
        ...payload,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([CATEGORY_QUERY_KEY, activeCompany]);
      },
    }
  );
};

export const useUpdateCategory = (
  categoryId: Parameters<typeof services.bitApp.category.updateCategory>[0]
) => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    (payload: Parameters<typeof services.bitApp.category.updateCategory>[2]) =>
      services.bitApp.category.updateCategory(categoryId, activeCompany!, {
        ...payload,
      }),
    {
      onSuccess: () => {
        toast.success('Category is updated!');
        client.invalidateQueries([CATEGORY_QUERY_KEY]);
      },
    }
  );
};

export const useRearrangeCategory = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    ({ data }: { data: InfiniteData<CategoryResponse> | undefined }) =>
      services.bitApp.category.rearrangeCategory(activeCompany!, {
        data:
          data?.pages
            .flatMap((page) => page.data)
            .map((p, index: number) => ({
              id: p.id,
              position: index,
            })) ?? [],
      }),
    {
      onMutate: async (data) => {
        await client.cancelQueries([CATEGORY_QUERY_KEY, activeCompany]);
        const previousSection = client.getQueryData<CategoryResponse>([
          CATEGORY_QUERY_KEY,
          activeCompany,
        ]);
        client.setQueryData([CATEGORY_QUERY_KEY, activeCompany], data.data);
        return { previousSection };
      },
      onError: (err, payload, context) => {
        client.setQueryData(
          [CATEGORY_QUERY_KEY, activeCompany],
          context?.previousSection
        );
      },
      onSettled: () => {
        client.invalidateQueries([CATEGORY_QUERY_KEY, activeCompany]);
      },
    }
  );
};

export const useDeleteCategory = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  const toggleConfirmation = useConfirmationModal();

  const { mutate, isLoading } = useMutation(
    async (
      payload: Parameters<typeof services.bitApp.category.deleteCategory>[1]
    ) => services.bitApp.category.deleteCategory(activeCompany!, payload),
    {
      onSuccess: (_, payload) => {
        toast.success('Category deleted successfully');
        client.invalidateQueries([CATEGORY_QUERY_KEY, activeCompany]);
      },
    }
  );

  return (
    categoryId: Parameters<typeof services.bitApp.category.deleteCategory>[1]
  ) =>
    toggleConfirmation({
      title: 'Confirm Deletion',
      description: `Once deleted you're not able to recover it`,
      deleteModal: true,
      submitText: 'Delete',
      onAccept: (hide) => {
        mutate(categoryId, {
          onSuccess: () => {
            hide();
          },
        });
      },
      loadingSubmit: isLoading,
    });
};
