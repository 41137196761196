import { Fragment, useMemo } from 'react';
import { File } from 'react-feather';
import { useWatch } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import {
  ControlType,
  ControlTypeCampaignWAAutoReply,
  ControlTypeTemplateWAAutoReply,
} from 'pages/BitCRM/components/Wrapper';

import DoubleCheckIcon from '@/assets/icons/DoubleCheckIcon';
import { BBBButton, BBBDeviceFrame, BBBRenderHtml } from '@/components/ui';
import useCampaignVariables from '@/hooks/bitCRM/campaign/useCampaignVariables';
import {
  CTAButtonType,
  MessageTemplateButtonType,
} from '@/types/bitCRM/template';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { convertRemoteUrlToFileType, toWhatsAppFormat } from '@/utils/bitCRM';
import { convertEditorStateToHtml } from '@/utils/common/rich';

type Props = {
  loading?: boolean;
} & ControlType;

function WhatsAppLivePreview(props: Props) {
  return props.type === 'campaign-wa-reply' ? (
    <CampaignWhatsAppLivePreview {...props} />
  ) : (
    <TemplateWhatsAppLivePreview {...props} />
  );
}

function TemplateWhatsAppLivePreview({
  control,
  loading,
}: Pick<Props, 'loading'> & ControlTypeTemplateWAAutoReply) {
  const _message = useWatch({
    control,
    name: 'body.message',
  });

  const parameters = useWatch({
    control,
    name: 'body.parameters',
  });

  const _parameters =
    parameters?.map((param) => (param ? param.placeholder : '')) || [];

  const mediaUrl = useWatch({
    control,
    name: 'fileUrl',
  });

  const buttonType = useWatch({
    control,
    name: 'buttonType.value',
  });

  const buttonText = useWatch({
    control,
    name: 'buttonText',
  });

  const replyButtons = useWatch({
    control,
    name: 'replyButtons',
  });

  const message = toWhatsAppFormat(
    convertEditorStateToHtml(_message),
    true,
    _parameters
  );

  const customUrl = useWatch({
    control,
    name: 'customUrl',
  });

  return (
    <_LivePreview
      message={message}
      buttons={replyButtons.map((btn, index) => ({
        label: btn.text,
        ctaId: index.toString(),
      }))}
      files={[mediaUrl]}
      loading={loading}
      buttonText={buttonText}
      buttonType={buttonType}
      buttonUrl={customUrl}
    />
  );
}

function CampaignWhatsAppLivePreview({
  control,
  loading,
  source,
}: Pick<Props, 'loading'> & ControlTypeCampaignWAAutoReply) {
  const _message = useWatch({
    control,
    name: 'template.message',
  });

  const buttons = useWatch({
    control,
    name: 'buttons',
  });

  const params = useWatch({
    control,
    name: 'param',
  });

  const mediaUrl = useWatch({
    control,
    name: 'template.mediaUrl',
  });

  const buttonText = useWatch({
    control,
    name: 'template.buttonText',
  });

  const buttonType = useWatch({
    control,
    name: 'template.buttonType',
  });

  const { data: campaignVariables } = useCampaignVariables({
    enabled: source === 'whatsapp-business',
  });

  const formatPlaceholder = useMemo(() => {
    let formattedWhatsapp = _message || '';
    if (source === 'whatsapp-business') {
      campaignVariables?.forEach((variable) => {
        formattedWhatsapp = formattedWhatsapp.replaceAll(
          variable.value,
          variable.placeholder
        );
      });
    } else if (source === 'whatsapp-cloud') {
      params?.forEach((param, index) => {
        formattedWhatsapp = formattedWhatsapp.replace(
          `{{${index + 1}}}`,
          param?.placeholder || ''
        );
      });
    }
    return formattedWhatsapp;
  }, [_message, campaignVariables, params, source]);

  const memoizedLivePreviewFiles = useMemo(
    () => (mediaUrl ? [convertRemoteUrlToFileType(mediaUrl)] : undefined),
    [mediaUrl]
  );

  return (
    <_LivePreview
      message={formatPlaceholder}
      buttons={buttons}
      files={memoizedLivePreviewFiles}
      loading={loading}
      buttonText={buttonText || undefined}
      buttonType={buttonType || undefined}
    />
  );
}

type _Props = {
  files?: Nullable<FileType>[];
  message?: string;
  buttons?: CTAButtonType[];
  keys?: { label: string; value: string }[];
  loading?: boolean;
  buttonType?: MessageTemplateButtonType;
  buttonText?: string;
  buttonUrl?: string | null;
};

function _LivePreview({
  files,
  message,
  buttons,
  keys: replyMessage,
  loading,
  buttonText,
  buttonType,
  buttonUrl,
}: _Props) {
  return (
    <BBBDeviceFrame>
      <div className="flex flex-col h-full bg-neutral-20 relative">
        <div className="h-[28px] flex-none bg-[#9E9E9E]" />
        <div className="h-[59px] flex-none bg-[#DDDDDD]" />
        <div className="grow overflow-auto">
          <div className="pb-20">
            <div className="mx-3 mt-4">
              {!!replyMessage?.length &&
                replyMessage.map((message) => (
                  <div
                    key={message.value}
                    className="max-w-[215px] bg-[#FFE6D8] break-words rounded-xl mt-2 p-3 text-sm ml-auto shadow"
                  >
                    <BBBRenderHtml content={message.value} />
                    <div className="flex items-center gap-1 justify-end w-full text-[#757575]">
                      13.20
                      <DoubleCheckIcon width={14} height={14} color="#757575" />
                    </div>
                  </div>
                ))}
              {loading ? (
                <Skeleton height={250} />
              ) : (
                files?.filter(Boolean).map((file, index) =>
                  file?.fileData?.type?.includes('image') ? (
                    <Fragment key={index}>
                      {file.remoteUrl ? (
                        <img
                          src={file.remoteUrl}
                          alt="preview"
                          className="img-fluid mb-2 rounded-lg"
                        />
                      ) : null}
                    </Fragment>
                  ) : (
                    <div
                      className="bg-white p-3 rounded-xl"
                      key={file?.fileData?.name}
                    >
                      <File size={72} />
                      {file?.fileData?.name}{' '}
                    </div>
                  )
                )
              )}
              {loading ? (
                <Skeleton height={50} className="mt-4" />
              ) : (
                !!message?.length && (
                  <div className="max-w-[215px] bg-white break-words rounded-xl mt-4 p-3 text-sm shadow">
                    <BBBRenderHtml content={message} />
                    <div className="flex items-center gap-1 justify-end w-full text-[#757575]">
                      13.20
                      <DoubleCheckIcon width={14} height={14} color="#757575" />
                    </div>
                  </div>
                )
              )}
              {buttonType === 'reply_button'
                ? !!buttons?.length && (
                    <div className="mt-2">
                      {buttons.map(
                        (btn) =>
                          btn.label && (
                            <BBBButton
                              key={btn.label}
                              variant="secondary"
                              text={btn.label}
                              disabled
                              className="w-full mb-2 last:mb-0"
                            />
                          )
                      )}
                    </div>
                  )
                : buttonText &&
                  (buttonUrl ? (
                    <a href={buttonUrl} className="mt-2 block">
                      <BBBButton
                        variant="secondary"
                        text={buttonText}
                        disabled
                        className="w-full mb-2 last:mb-0"
                      />
                    </a>
                  ) : (
                    <BBBButton
                      variant="secondary"
                      text={buttonText}
                      disabled
                      className="w-full mb-2 last:mb-0 mt-2"
                    />
                  ))}
            </div>
          </div>
        </div>
        <div className="absolute left-0 right-0 z-20 bottom-5 px-3 gap-2 flex items-center">
          <div className="grow bg-white rounded-3xl h-[40px] shadow" />
          <div className="bg-[#9E9E9E] rounded-full w-[40px] h-[40px]" />
        </div>
      </div>
    </BBBDeviceFrame>
  );
}

export default WhatsAppLivePreview;
