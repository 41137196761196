import { twMerge as cx } from 'tailwind-merge';

type Props = {
  percentage?: number | string;
  previous?: number;
  current?: number;
  label?: string;
  isAverage?: boolean;
  isFixed?: boolean;
};

function Statistics({
  percentage,
  previous,
  current,
  isAverage,
  label = isAverage ? 'Average per day' : undefined,
  isFixed,
}: Props) {
  const formattedPercentage =
    typeof percentage === 'string'
      ? percentage
      : typeof percentage === 'number'
      ? Math.abs(percentage).toFixed(0)
      : 0;

  const status = (current || 0) >= (previous || 0) ? 'UP' : 'DOWN';

  const currentFormatted = Number((current || 0) / (isAverage ? 7 : 1)).toFixed(
    0
  );
  const previousFormatted = Number(
    (previous || 0) / (isAverage ? 7 : 1)
  ).toFixed(0);

  return (
    <div className="text-base">
      <p
        className={cx('text-neutral-50 font-medium', isFixed ? 'mb-1' : 'mb-2')}
      >
        {label}
      </p>
      <div className="flex gap-2">
        <p
          className={cx(
            'text-neutral-70 font-semibold',
            isFixed ? 'text-base' : 'text-2xl'
          )}
        >
          {currentFormatted}
        </p>
        {!isFixed && (
          <div
            className={cx(
              `flex items-center gap-1 px-2 py-1 rounded-lg font-medium`,
              status === 'UP'
                ? 'text-success-main bg-success-surface'
                : 'text-danger-main bg-danger-surface'
            )}
          >
            {}
            <ArrowIcon
              className={status === 'DOWN' ? 'transform rotate-180' : ''}
              color={status === 'UP' ? '#65B242' : '#FF4D4F'}
            />
            {previousFormatted} ({formattedPercentage}%)
          </div>
        )}
      </div>
    </div>
  );
}

function ArrowIcon({
  color = '#65B242',
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_364_1415)">
        <path
          d="M9.1665 4.73028H6.24979L6.24979 12.75H3.74979L3.74979 4.73028L0.833171 4.73028L4.99984 0.249998L9.1665 4.73028Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_364_1415">
          <rect
            width="10"
            height="12.5"
            fill="white"
            transform="matrix(-1 0 0 -1 10 12.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Statistics;
