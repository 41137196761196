import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { BulkDeleteModelPayload } from '@/api/services/bitAi/v2-new/model';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useBulkDeleteModels = () => {
  const activeCompany = useActiveCompany();
  const client = useQueryClient();

  return useMutation(
    (payload: BulkDeleteModelPayload) =>
      services.bitAi.v2New.model.bulkDeleteModel(activeCompany, payload),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your model has been deleted`);
        client.invalidateQueries(['bitai-sorting-models']);
        client.invalidateQueries(['pricing-v2']);
      },
    }
  );
};

export default useBulkDeleteModels;
