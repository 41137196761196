import React from 'react';

import colors from '@/constants/common/colors';

type Props = React.SVGProps<SVGSVGElement>;

function CircleCheckIcon({ ...props }: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C3.68636 1 1 3.68636 1 7C1 10.3136 3.68636 13 7 13C10.3136 13 13 10.3136 13 7C13 3.68636 10.3136 1 7 1ZM9.60073 5.98546C9.64862 5.93071 9.68507 5.86695 9.70796 5.79791C9.73084 5.72887 9.73968 5.65595 9.73397 5.58345C9.72825 5.51094 9.70809 5.44031 9.67468 5.37571C9.64126 5.31111 9.59526 5.25384 9.53939 5.20728C9.48352 5.16072 9.41889 5.1258 9.34933 5.10458C9.27976 5.08336 9.20665 5.07627 9.1343 5.08372C9.06195 5.09118 8.99182 5.11303 8.92804 5.14798C8.86426 5.18294 8.80812 5.2303 8.76291 5.28727L6.41745 8.10127L5.20382 6.88709C5.10094 6.78773 4.96316 6.73275 4.82015 6.734C4.67713 6.73524 4.54032 6.7926 4.43919 6.89374C4.33806 6.99487 4.28069 7.13167 4.27945 7.27469C4.27821 7.41771 4.33319 7.55549 4.43255 7.65836L6.06891 9.29473C6.1225 9.34829 6.18667 9.3901 6.25732 9.41749C6.32797 9.44488 6.40354 9.45725 6.47924 9.45381C6.55493 9.45037 6.62908 9.4312 6.69695 9.39752C6.76483 9.36384 6.82494 9.31638 6.87345 9.25818L9.60073 5.98546Z"
        fill={colors.secondary.main}
      />
    </svg>
  );
}

export default CircleCheckIcon;
