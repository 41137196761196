import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

const useUploadKnowledgeBase = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  return useMutation(
    ({ fileUrl }: { fileUrl: string | undefined }) =>
      services.bitChat.autopilot.uploadKnowledgeBase(activeCompany, {
        fileUrl,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['auto-pilot-rules']);
      },
    }
  );
};

export default useUploadKnowledgeBase;
