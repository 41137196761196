import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { CIRCLE_IMAGE_GRIDS } from '@/constants/bitApp/designPresets';
import { SectionAssociation } from '@/types/bitApp/association';

type Props = {
  data: SectionAssociation['imageGrids'];
};

export default function ImageGrid({ data }: Props) {
  return (
    <>
      {(data && (
        <div
          id="livepreview-circle-image"
          className="flex flex-col  px-1 py-5 bg-neutral-20 rounded-xl"
        >
          <div className="font-bold text-primary-main text-center mb-2.5 text-ls">
            {data.title || 'Title'}
          </div>
          <div className="grid grid-cols-3 gap-4 overflow-hidden">
            {data.imageGridContents?.length > 0 ? (
              data.imageGridContents?.map((dataCollection) => (
                <div
                  className="flex flex-col items-center"
                  key={dataCollection.id}
                >
                  <img
                    src={dataCollection.image || undefined}
                    className="w-[4rem] h-[4rem] border border-neutral-30 object-cover rounded-full"
                    alt={dataCollection.title || 'image'}
                    loading="lazy"
                  />
                  <div className="text-xs text-primary-main mt-1 text-center">
                    {dataCollection.title}
                  </div>
                </div>
              ))
            ) : (
              <>
                {[0, 1, 2, 3, 4, 5].map((item) => (
                  <div className="flex items-center flex-col" key={item}>
                    <img
                      src="https://images.unsplash.com/photo-1517816743773-6e0fd518b4a6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2803&q=80"
                      className="w-[48px] h-[48px] object-cover rounded-full shadow"
                      alt=""
                      loading="lazy"
                    />
                    <div className="text-sm mt-1">Collections</div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )) || <ImageGridPlaceholder />}
    </>
  );
}

export function ImageGridPlaceholder() {
  return (
    <div className="relative w-full transition-all group h-[18.75rem]">
      <div className="opacity-100 group-hover:opacity-0 transition-all z-0">
        <div className="bg-neutral-20 rounded-lg py-4">
          <p className="text-neutral-50 font-bold uppercase mb-3.5 text-center">
            Title
          </p>
          <div className="h-full grid grid-cols-3 gap-x-9 gap-y-5">
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="h-fit mx-auto text-center">
                <div className="border border-neutral-30 rounded-full px-4 py-7 mb-2">
                  <BBBThumbnail className="opacity-50" width={43} height={21} />
                </div>
                <div className="w-18 mx-auto h-4 bg-neutral-30" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="opacity-0 group-hover:opacity-100 transition-all duration-200  absolute transition-1/2 top-0 left-0 w-full h-full z-10">
        <div className="bg-neutral-20 rounded-lg py-4">
          <p className="text-primary-main font-bold uppercase mb-3.5 text-center">
            Shop by category
          </p>
          <div className="grid grid-cols-3 gap-x-9 gap-y-5 h-fit">
            {CIRCLE_IMAGE_GRIDS.map((item, index) => (
              <div key={index} className="h-fit mx-auto text-center">
                <img
                  className="w-20 h-20 border border-neutral-30 object-cover rounded-full mb-2"
                  src={item.image}
                />
                <p className="text-xs text-neutral-60">
                  {CIRCLE_IMAGE_GRIDS[index].title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
