import { useMutation } from '@tanstack/react-query';
import { LoginResponseData } from './useLogin';
import { RegisterPayload } from './useRegister';

import api from '@/config/api';

const useOauthRegister = () =>
  useMutation<LoginResponseData, unknown, RegisterPayload>(async (payload) => {
    const {
      data: { data },
    } = await api.systemLogin.post(`/auth/sign-up`, payload);
    return data;
  });

export default useOauthRegister;
