import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { GetAnalyticAgentPerformanceParams } from '@/api/services/whatsApp/analytics';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAnalyticAgentPerfomance = (
  params: GetAnalyticAgentPerformanceParams,
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-analytics-agent-performance', activeCompany, params],
    () =>
      services.whatsApp.analytics.getAnalyticAgentPerformance(
        activeCompany,
        params
      ),
    options
  );
};

export default useAnalyticAgentPerfomance;
