import { useMutation, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { UpdateMessageParams } from 'api/services/whatsApp/chat';
import { useAppSelector } from '../rtk/store';
import { selectActiveChatTruthy } from './chat';
import { ChatMessagesType } from './useChatMessages';
import useChatMessagesQueryKey from './useChatMessagesQueryKey';

import { useApiKey } from '@/hooks/rtk/selector';

const useUpdateMessage = () => {
  const apiKey = useApiKey();
  const queryClient = useQueryClient();
  const queryKey = useChatMessagesQueryKey();

  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useMutation(
    (payload: Omit<UpdateMessageParams, 'key' | 'id' | 'sources'>) =>
      services.whatsApp.chat.updateMessage({
        ...payload,
        key: apiKey,
        id: selectedChat.clientNumber,
        sources: selectedChat.sources,
      }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries({ queryKey: queryKey });

        const previous = queryClient.getQueryData<ChatMessagesType>(queryKey);

        queryClient.setQueryData<ChatMessagesType>(queryKey, (old) =>
          old
            ? {
                ...old,
                pages: old.pages.map((data) => {
                  const messageIndex = data.response.findIndex(
                    (message) => message.messageId === payload.messageId
                  );

                  if (messageIndex === -1) return data;

                  return {
                    ...data,
                    response: data.response.map((message, index) =>
                      index === messageIndex
                        ? {
                            ...message,
                            isStarred: payload.isStarred,
                          }
                        : message
                    ),
                  };
                }),
              }
            : undefined
        );

        return { previous };
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(queryKey, context?.previous);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['chat']);
        queryClient.invalidateQueries(['starred-messages']);
      },
    }
  );
};

export default useUpdateMessage;
