import { ReactNode } from 'react';
import BitAiStatus from './BitAiStatus';
import BitAppStatus from './BitAppStatus';
import BitChatStatus from './BitChatStatus';
import BitCRMStatus from './BitCRMStatus';
import BitLinkStatus from './BitLinkStatus';
import BitLoginStatus from './BitLoginStatus';
import TresholdAlert from './TresholdAlert';

import PremiumIcon from '@/assets/icons/PremiumIcon';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import { AppType } from '@/types/systemLogin';

type Props = {
  app: AppType;
};

function PricingInfo({ app }: Props) {
  const { isMaximumLevel, pricingName } = useIsMaximumLevel(app);

  if (isMaximumLevel && pricingName === 'enterprise') return null;

  return (
    <>
      {app === 'BITLINK' ? (
        <BitLinkStatus />
      ) : app === 'BITAPP' ? (
        <BitAppStatus />
      ) : app === 'BITCRM' ? (
        <BitCRMStatus />
      ) : app === 'BITCHAT' ? (
        <BitChatStatus />
      ) : app === 'BITLOGIN' ? (
        <BitLoginStatus />
      ) : app === 'BITAI' ? (
        <BitAiStatus />
      ) : null}
    </>
  );
}

export default PricingInfo;

export function _PricingInfo({
  children,
  app,
  subscriptionPlan,
}: {
  children: ReactNode;
  app: AppType;
  subscriptionPlan?: string;
}) {
  const isPaidPlan = subscriptionPlan && subscriptionPlan !== 'Free';

  return (
    <div className="bg-[#DDDDDD]/10 p-4">
      <div className="flex items-center mb-4 gap-1">
        <div className="font-semibold">{subscriptionPlan} plan</div>
        {isPaidPlan && <PremiumIcon />}
      </div>
      {children}
      <TresholdAlert appType={app} />
    </div>
  );
}
