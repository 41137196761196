import { Mail } from 'react-feather';
import { FaWhatsapp } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import {
  BBBButton,
  BBBCard,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';
import { BBBContainer } from '@/components/ui';
import { Link } from '@/components/ui/Link';
import useEditSubscription from '@/hooks/bitLink/subs/useEditSubscription';
import useSubscription from '@/hooks/bitLink/subs/useSubscription';
import useResponsive from '@/hooks/common/useResponsive';

const subscriptionReminderCardStyles = [
  {
    group: 'filled',
    label: 'Filled',
    data: [
      {
        name: 'filled1',
        imgUrl: `/bitLink/subscriptions/filledDarkReminderCard.png`,
      },
      {
        name: 'filled2',
        imgUrl: `/bitLink/subscriptions/filledLightReminderCard.png`,
      },
    ],
  },
  {
    group: 'frosted',
    label: 'Frosted',
    data: [
      {
        name: 'frosted1',
        imgUrl: '/bitLink/subscriptions/frostedLightReminderCard.png',
      },
      {
        name: 'frosted2',
        imgUrl: `/bitLink/subscriptions/frostedDarkReminderCard.png`,
      },
    ],
  },
];

function Subscription() {
  const { data, isInitialLoading, error } = useSubscription();
  const { mutate } = useEditSubscription();

  const isMobile = useResponsive('sm');

  return (
    <BBBContainer
      hasHeading
      pageTitle="Subscription"
      pageDescription="Let your customers know your store update"
    >
      <BBBCard title="Subscription details" className="mb-cardBottom">
        {isInitialLoading ? (
          <SubscriptionDetailSkeleton />
        ) : error ? null : (
          <>
            <div className="flex items-center mb-4 flex-row gap-2">
              <div className=" grow">
                <div className="flex items-center mb-2">
                  <Mail />
                  <div className="ml-3 font-medium">Email</div>
                </div>
                <div className="font-light">
                  Add an email field so your bitLink visitor can easily signup.
                </div>
              </div>
              <ShopifyAwareWrapper appType="BITLINK" className="w-auto">
                {() => (
                  <BBBPrimarySwitch
                    checked={data?.isEmail ?? false}
                    onChange={(val) =>
                      mutate({
                        isEmail: val,
                      })
                    }
                  />
                )}
              </ShopifyAwareWrapper>
            </div>
            <div className="flex items-center mb-4 flex-row gap-2">
              <div className=" grow">
                <div className="flex items-center mb-2">
                  <FaWhatsapp size={24} />
                  <div className="ml-3 font-medium">Whatsapp</div>
                </div>
                <div className="font-light">
                  Add a phone number field so your bitLink visitor can easily
                  signup
                </div>
              </div>
              <BBBPrimarySwitch
                checked={data?.isWhatsapp ?? false}
                onChange={(val) =>
                  mutate({
                    isWhatsapp: val,
                  })
                }
              />
            </div>
            <BBBTextInput
              label="Custom Terms & Condition (Optional)"
              placeholder="Enter T&C link"
              containerClassname="!mb-0"
              value={data?.term ?? ''}
              onChange={({ target: { value } }) => {
                mutate({
                  term: value,
                });
              }}
            />
          </>
        )}
      </BBBCard>
      <BBBCard title="Style" className="mb-cardBottom">
        {!isMobile && (
          <div className="mb-5">Subscription Reminder Card Style</div>
        )}
        {isInitialLoading ? (
          <SubscriptionStyleSkeleton />
        ) : error ? null : isMobile ? (
          <>
            {subscriptionReminderCardStyles
              .flatMap((data) => data.data)
              .map((subscription) => (
                <SubscriptionStyleItem
                  key={subscription.name}
                  onClick={() => mutate({ style: subscription.name })}
                  imgUrl={subscription.imgUrl}
                  name={subscription.name}
                  className={
                    subscription.name === data?.style
                      ? `pointer-events-none`
                      : 'outline-transparent'
                  }
                />
              ))}
          </>
        ) : (
          subscriptionReminderCardStyles.map((style) => (
            <div
              className="flex mb-5 last:mb-0 gap-3 items-center"
              key={`${style.group}-${style.label}`}
            >
              <div className="font-medium  w-1/4">{style.label}</div>
              <div className="grow flex gap-3">
                {style.data.map((type) => (
                  <SubscriptionStyleItem
                    key={type.name}
                    onClick={() => mutate({ style: type.name })}
                    imgUrl={type.imgUrl}
                    name={type.name}
                    className={
                      type.name === data?.style
                        ? `pointer-events-none`
                        : 'outline-transparent'
                    }
                  />
                ))}
              </div>
            </div>
          ))
        )}
      </BBBCard>
      {/* <Link to="/bitchat/chatbot">
        <BBBCard
          title="Enable chatbot"
          desc="Create a personalized message template that allows you to greet your customers when they subscribe to your WhatsApp and chat automatically."
          rightButton={<BBBButton text="Go to chatbot" variant="secondary" />}
          className="mb-cardBottom"
        />
      </Link> */}
    </BBBContainer>
  );
}

function SubscriptionStyleItem({
  onClick,
  className,
  name,
  imgUrl,
}: {
  onClick?: () => void;
  className?: string;
  name?: string;
  imgUrl?: string;
}) {
  return (
    <div
      className={cx(
        `flex-1 cursor-pointer outline outline-offset-4 rounded-lg`,
        className
      )}
      onClick={onClick}
      key={name}
    >
      <img className="object-cover w-full" src={imgUrl} alt={name} />
    </div>
  );
}

function SubscriptionDetailSkeleton() {
  return (
    <>
      {Array.from({
        length: 2,
      }).map((_, i) => (
        <div className="flex" key={i}>
          <div className="grow">
            <div className="flex items-center gap-3 mb-2">
              <Skeleton circle width={50} height={50} />
              <div className="grow">
                <Skeleton />
              </div>
            </div>
            <Skeleton />
          </div>
          <Skeleton
            width={50}
            height={25}
            containerClassName="self-center ml-3"
          />
        </div>
      ))}
      <Skeleton />
      <Skeleton height={40} />
    </>
  );
}

function SubscriptionStyleSkeleton() {
  return (
    <>
      {Array.from({
        length: 2,
      }).map((_, i) => (
        <div className="flex mb-3 last:mb-0" key={i}>
          <div className=" w-1/4">
            <Skeleton />
          </div>
          <Skeleton
            containerClassName="grow ml-3 flex gap-2"
            className="flex-1"
            count={2}
            height={30}
          />
        </div>
      ))}
    </>
  );
}

export default Subscription;
