import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { socketWhatsApp } from 'socket';
import { useAppDispatch } from '../rtk/store';

import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { setConnectIntegration } from '@/stores/common';
import { toast } from '@/utils/common/toast';

const useConnectWhatsAppCloud = () => {
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const [enableWhatsAppCloudIntegration, setEnableWhatsAppCloudIntegration] =
    useState<'embedded' | 'default' | null>(null);

  const onIntegrationAuthorized = useCallback(() => {
    toast.success('Successfully connected to WhatsApp Cloud Api');
    queryClient.invalidateQueries(['whatsappp-cloud-list']);
    queryClient.invalidateQueries([`bitchat-company-integrations`]);

    setEnableWhatsAppCloudIntegration(null);

    if (enableWhatsAppCloudIntegration === 'embedded') {
      dispatch(setConnectIntegration(null));
    }
  }, [dispatch, enableWhatsAppCloudIntegration, queryClient]);

  useEffect(() => {
    if (enableWhatsAppCloudIntegration) {
      socketWhatsApp.emit('join-whatsapp-cloud', {
        companyId: activeCompany,
        userId,
      });
    }
  }, [activeCompany, enableWhatsAppCloudIntegration, userId]);

  useEffect(() => {
    if (enableWhatsAppCloudIntegration) {
      socketWhatsApp.on(
        `whatsapp-cloud-integration-authorized`,
        onIntegrationAuthorized
      );
      return () => {
        socketWhatsApp.off(
          `whatsapp-cloud-integration-authorized`,
          onIntegrationAuthorized
        );
      };
    }
  }, [
    enableWhatsAppCloudIntegration,
    onIntegrationAuthorized,
    setEnableWhatsAppCloudIntegration,
  ]);

  return [
    enableWhatsAppCloudIntegration,
    setEnableWhatsAppCloudIntegration,
  ] as const;
};

export default useConnectWhatsAppCloud;
