import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { EmptyCartTemplate } from '@/types/bitApp/v2';

const useCartSettingsTemplate = (
  options?: Omit<
    UseQueryOptions<
      EmptyCartTemplate[],
      unknown,
      EmptyCartTemplate[],
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useQuery(
    ['bitapp-cart-template', activeCompany],
    () => services.bitApp.cartSettings.getTemplates(),
    options
  );
};

export default useCartSettingsTemplate;
