import React, { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  useWatch,
} from 'react-hook-form';
import { twMerge as cx } from 'tailwind-merge';

export type TextareaAutosizeTypes = Omit<
  JSX.IntrinsicElements['textarea'],
  'ref'
>;

export type MaxCharType =
  | {
      hasMaxCharLabel?: true;
      maxChar: number;
    }
  | {
      hasMaxCharLabel?: false;
      maxChar?: never;
    };

export type HookFormTextAreaInput<T extends FieldValues> = {
  isHookForm: true;
  control: Control<T, any>;
  controlName: Path<T>;
};

export type NormalTextAreaInput = {
  isHookForm?: false;
  control?: never;
  controlName?: never;
};

const BBBTextAreaInput = <T extends FieldValues>(
  {
    isHookForm = false,
    controlName,
    control,
    error,
    label,
    hasMaxCharLabel = false,
    maxChar,
    rows = 1,
    errorClassName,
    ...inputProps
  }: (HookFormTextAreaInput<T> | NormalTextAreaInput) &
    TextareaAutosizeTypes &
    MaxCharType & {
      error?: string;
      label?: string | React.ReactNode;
      inputClassName?: string;
      errorClassName?: string;
    },
  ref: Ref<HTMLTextAreaElement>
) => {
  const {
    className,
    onChange,
    onBlur,
    inputClassName: inputClassNameFromProps,
    disabled,
    ...inputPropsExcluded
  } = inputProps;
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const node = inputRef.current;
    if (node) {
      node.dispatchEvent(
        new Event('input', {
          bubbles: true,
        })
      );
    }
  }, [ref, inputPropsExcluded.value]);

  // this practice actually not recommended. usually, we would separate this logic into separate component that handle the "Controlled" version of textarea input via "react-hook-form"
  // this will also mean to involve imitating / cloning the current component props
  // For simplicity and time efficiency sake, we can disable the eslint rule temporarily to make this work
  if (isHookForm) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const valueHookForm = useWatch({
      control,
      // @ts-ignore
      name: controlName,
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const node = inputRef.current;
      if (node) {
        node.dispatchEvent(
          new Event('input', {
            bubbles: true,
          })
        );
      }
    }, [ref, valueHookForm]);
  }

  const inputClassName = cx(
    'form-default form-padding hover:form-hover focus:form-focus',
    disabled && 'opacity-50 pointer-events-none',
    inputClassNameFromProps
  );

  const onInput = useCallback(() => {
    if (inputRef.current) {
      if ((rows ?? 1) > 1) {
        inputRef.current.style.minHeight = (rows ?? 1) * 24 + 'px';
      }
      inputRef.current.style.height = '0';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  }, [rows]);

  const memoizedInput = useCallback(
    (field?: ControllerRenderProps<T, Path<T>>) => {
      const value = inputRef.current?.value;

      if (field) {
        const {
          onChange: onChangeHookForms,
          ref,
          onBlur: onBlurHookForms,
          ...hookFormFields
        } = field;

        return (
          <div className="relative flex flex-col">
            <textarea
              className={inputClassName}
              tabIndex={0}
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === 'function') {
                  ref(node);
                } else if (ref) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  ref.current = node;
                }
              }}
              rows={rows}
              onInput={onInput}
              maxLength={maxChar}
              onChange={(e) => {
                onChange?.(e);
                onChangeHookForms(e);
              }}
              onBlur={(e) => {
                onBlur?.(e);
                onBlurHookForms?.();
              }}
              {...inputPropsExcluded}
              {...hookFormFields}
            ></textarea>
            <MaxCharLabel
              value={value}
              hasMaxCharLabel={hasMaxCharLabel}
              maxChar={maxChar}
            />
          </div>
        );
      }

      return (
        <div className="relative flex flex-col">
          <textarea
            className={inputClassName}
            tabIndex={0}
            ref={(node) => {
              inputRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                ref.current = node;
              }
            }}
            rows={rows}
            maxLength={maxChar}
            onInput={onInput}
            onChange={onChange}
            onBlur={(e) => {
              onBlur?.(e);
            }}
            {...inputPropsExcluded}
          ></textarea>
          <MaxCharLabel
            maxChar={maxChar}
            hasMaxCharLabel={hasMaxCharLabel}
            value={value}
          />
        </div>
      );
    },
    [
      hasMaxCharLabel,
      inputClassName,
      inputPropsExcluded,
      maxChar,
      onBlur,
      onChange,
      onInput,
      ref,
      rows,
    ]
  );

  const input = useMemo(() => {
    if (isHookForm) {
      return (
        <Controller
          name={controlName as Path<T>}
          control={control}
          render={({ field }) => memoizedInput(field)}
        />
      );
    }
    return memoizedInput();
  }, [control, controlName, isHookForm, memoizedInput]);

  useEffect(() => {
    window.addEventListener('resize', onInput);
    return () => {
      window.removeEventListener('resize', onInput);
    };
  }, [onInput]);

  return (
    <div className={cx(`mb-3`, className)}>
      {label && <div>{label}</div>}
      {input}
      {!!error && (
        <div className={cx('text-danger-main', errorClassName)}>{error}</div>
      )}
    </div>
  );
};

BBBTextAreaInput.displayName = 'BBBTextAreaInput';

export default React.forwardRef(BBBTextAreaInput) as <T extends FieldValues>(
  p: (HookFormTextAreaInput<T> | NormalTextAreaInput) &
    TextareaAutosizeTypes &
    MaxCharType & {
      error?: string;
      label?: string | React.ReactNode;
      inputClassName?: string;
      errorClassName?: string;
    } & { ref?: Ref<HTMLTextAreaElement> }
) => React.ReactElement;

const MaxCharLabel = ({
  value,
  maxChar,
  hasMaxCharLabel,
}: {
  value?: string;
  hasMaxCharLabel?: boolean;
  maxChar?: number;
}) =>
  hasMaxCharLabel && maxChar ? (
    <div className="z-10 text-sm opacity-30 absolute bottom-0 right-2 transform -translate-y-1/2">
      {value?.length || 0}/{maxChar}
    </div>
  ) : null;
