export default function GoogleSheetsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="51"
      fill="none"
      viewBox="0 0 39 51"
    >
      <path
        fill="#0F9D58"
        d="M24.132.001h-19.7C2.52.001.957 1.575.957 3.487v44.04a3.486 3.486 0 003.477 3.476h30.126a3.486 3.486 0 003.477-3.477V13.914L29.919 8.12 24.132 0z"
      ></path>
      <path
        fill="#F1F1F1"
        d="M10.23 24.919v16.81h18.544V24.92H10.23zm8.107 14.49h-5.795v-2.897h5.795v2.898zm0-4.64h-5.795V31.87h5.795v2.898zm0-4.631h-5.795V27.24h5.795v2.898zm8.117 9.272h-5.797v-2.898h5.796v2.898zm0-4.641h-5.797V31.87h5.796v2.898zm0-4.631h-5.797V27.24h5.796v2.898z"
      ></path>
      <path
        fill="url(#paint0_linear_1263_18814)"
        d="M25.148 12.898l12.887 12.887v-11.87l-12.887-1.017z"
      ></path>
      <path
        fill="#87CEAC"
        d="M24.136.005v10.428a3.474 3.474 0 003.476 3.476H38.04L24.136.005z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.2"
        d="M4.433.001C2.52.001.956 1.575.956 3.487v.288A3.486 3.486 0 014.433.3h19.7V0h-19.7v.001z"
      ></path>
      <path
        fill="#263238"
        fillOpacity="0.2"
        d="M34.56 50.703H4.433a3.486 3.486 0 01-3.477-3.477v.29a3.486 3.486 0 003.477 3.476h30.126a3.486 3.486 0 003.477-3.477v-.289a3.485 3.485 0 01-3.476 3.477z"
      ></path>
      <path
        fill="#263238"
        fillOpacity="0.1"
        d="M27.61 13.914a3.474 3.474 0 01-3.477-3.476v.289a3.474 3.474 0 003.476 3.476h10.427v-.289H27.61z"
      ></path>
      <path
        fill="url(#paint1_radial_1263_18814)"
        d="M24.132.001h-19.7C2.52.001.957 1.575.957 3.487v44.04a3.486 3.486 0 003.477 3.476h30.126a3.486 3.486 0 003.477-3.477V13.914L24.132.001z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1263_18814"
          x1="31.591"
          x2="31.591"
          y1="14.003"
          y2="25.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#263238" stopOpacity="0.2"></stop>
          <stop offset="1" stopColor="#263238" stopOpacity="0.02"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_1263_18814"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(59.8012 0 0 59.8013 2.13 1.389)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}
