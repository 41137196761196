import { shallowEqual } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { GET_MESSAGES_DEFAULT_LIMIT } from 'api/services/whatsApp/v3/message';
import { env } from 'config/env';
import { chunk } from 'lodash-es';
import { socketWhatsApp } from 'socket';
import { updateIncomingChat } from 'stores/bitCRM';
import { NewMessageV2Payload } from 'types/whatsApp/socket-realtime';
import { MessageNewAssociation } from 'types/whatsApp/v3';
import useSound from 'use-sound';
import { sendNotification } from 'utils/chat';
import { toast } from 'utils/common/toast';
import useSettings from '../bitChat/settings/useSettings';
import { useActiveCompany, useUserId } from '../rtk/selector';
import { useAppDispatch, useAppSelector } from '../rtk/store';
import { selectActiveChat, useUpdateChats } from './chat';
import useActiveStatus from './useActiveStatus';
import useChatFilter from './useChatFilter';
import { ChatMessagesType } from './useChatMessages';
import useChatMessagesQueryKey from './useChatMessagesQueryKey';

export const useReceiveMessage = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const chatMessageQueryKey = useChatMessagesQueryKey();
  const settingsQuery = useSettings();
  const [playNotification] = useSound(
    `${env.REACT_APP_CDN_URL}/iphone_sound.mp3`
  );
  const chatFilter = useChatFilter();
  const updateChats = useUpdateChats();
  const apiKey = useAppSelector((s) => s.bitCRM.apiKey);
  const userId = useUserId();

  const dispatch = useAppDispatch();

  const selectedChat = useAppSelector(selectActiveChat, shallowEqual);

  const activeStatus = useActiveStatus();

  return async (payload: NewMessageV2Payload) => {
    const _incomingChat = payload.chat;

    if (_incomingChat) {
      const incomingChat = {
        ..._incomingChat,
        ...(selectedChat &&
          selectedChat.clientNumber === _incomingChat.clientNumber &&
          selectedChat.sources === _incomingChat.sources &&
          _incomingChat.ticket?.userId === userId &&
          _incomingChat.status === 'Active' && {
            unreadMessages: 0,
          }),
      };

      // start filter message
      const unreadFilters = chatFilter.chat?.split(',');
      const ticketsFilter = chatFilter.tickets?.split(',');
      const channelsFilter = chatFilter.sources?.split(',');
      const agentsFilter = chatFilter.agents?.split(',').map(Number);
      const tagsFilter = chatFilter.ticketCategory?.split(',');
      const incomingChatTags = incomingChat.tags?.map((_tag) => _tag.tagId);

      const skipped =
        (tagsFilter?.length &&
          tagsFilter.every((tag) =>
            incomingChatTags ? !incomingChatTags.includes(tag) : false
          )) ||
        (agentsFilter?.length &&
          incomingChat.ticket?.userId &&
          !agentsFilter.includes(incomingChat.ticket.userId)) ||
        (channelsFilter?.length &&
          !channelsFilter.includes(incomingChat.sources)) ||
        (ticketsFilter?.length &&
          incomingChat.status &&
          !ticketsFilter.includes(incomingChat.status)) ||
        (unreadFilters?.length && unreadFilters.includes('read')) ||
        (activeStatus === 'active'
          ? incomingChat.ticket
            ? incomingChat.ticket.userId !== userId
            : true
          : activeStatus === 'waiting'
          ? incomingChat.ticket?.userId
          : activeStatus !== null);

      if (!skipped) {
        updateChats(
          incomingChat,
          payload.messageData ? { shift: true } : undefined
        );

        if (
          selectedChat &&
          incomingChat.clientNumber === selectedChat.clientNumber &&
          incomingChat.sources === selectedChat.sources
        ) {
          dispatch(updateIncomingChat(incomingChat));
        }

        if (
          payload.messageData &&
          !payload.messageData.type.startsWith('ACTIVITY')
        ) {
          // start notification
          const fromMe = payload.messageData.fromMe;

          if (
            typeof fromMe !== 'undefined' &&
            !fromMe &&
            settingsQuery.status === 'success'
          ) {
            const notificationShown =
              (incomingChat.isGroup && settingsQuery.data.groupChat) ||
              (!incomingChat.isGroup && settingsQuery.data.individualChat) ||
              (incomingChat.ticket?.userId === userId &&
                settingsQuery.data.ticketChat);

            if (notificationShown) {
              if (settingsQuery.data.notificationPopUp) {
                const lastMessage = incomingChat.lastMessage;

                if (lastMessage) {
                  const sender =
                    incomingChat.clientName || incomingChat.clientNumber;

                  sendNotification({ sender, lastMessage });

                  toast.success(
                    `${sender} just send you a new message: ${lastMessage}`
                  );
                }
              }

              if (settingsQuery.data.notificationSound) {
                playNotification();
              }
            }
          }
          // end notification
        }
      }
      // end filter message
    }

    if (selectedChat) {
      const messageData = payload.messageData;

      if (messageData) {
        const clientNumber = messageData.clientNumber;

        const sources = messageData.sources;

        if (
          selectedChat.clientNumber === clientNumber &&
          selectedChat.sources === sources
        ) {
          if (
            payload.chat?.ticket?.userId === userId &&
            payload.chat?.status === 'Active'
          ) {
            const messageId = messageData.messageId;

            if (apiKey) {
              socketWhatsApp.emit('read-message', {
                source: sources,
                clientNumber: clientNumber,
                companyId: activeCompany,
                messageId,
                key: apiKey,
              });
            }
          }

          queryClient.setQueryData<ChatMessagesType>(
            chatMessageQueryKey,
            (data) => {
              if (data) {
                const flattenedContents = data.pages.flatMap(
                  (page) => page.response
                );

                const foundIndex = flattenedContents.findIndex((message) => {
                  return (
                    message.messageId === messageData.messageId ||
                    message.messageId === messageData.messageId2
                  );
                });

                if (foundIndex > -1) {
                  flattenedContents.splice(foundIndex, 1, messageData);
                } else {
                  flattenedContents.push(messageData);
                }

                const chunkSize =
                  GET_MESSAGES_DEFAULT_LIMIT || flattenedContents.length;

                const chunks = chunk(
                  sortByTimestamp(flattenedContents),
                  chunkSize
                );

                if (data.pages.length) {
                  const mergedPages = data.pages
                    .filter((_page, index) => !!chunks[index])
                    .map((_page, index) => ({
                      ..._page,
                      response: chunks[index],
                    }));

                  return {
                    ...data,
                    pages: mergedPages,
                  };
                }

                const mergedPages = [{ response: chunks[0], meta: {} }];

                return {
                  ...data,
                  pages: mergedPages,
                };
              }
            }
          );
        }
      }
    }
  };
};

export function sortByTimestamp(data: MessageNewAssociation[]) {
  return data
    .map((_data, index) => ({
      ..._data,
      idx: index,
    }))
    .sort((a, b) => {
      const keyA = new Date(a.timestamp);
      const keyB = new Date(b.timestamp);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return b.idx - a.idx;
    });
}
