import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from 'hooks/rtk/selector';

export const useHasEnabledRating = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['count-enabled-rating', activeCompany],
    () =>
      services.bitChat.chatbot.getEnabledRating({ companyId: activeCompany }),
    {
      select: (data) => data > 0,
    }
  );
};
