import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAppearance = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['appearance', activeCompany], () =>
    services.bitLink.appearance.getAppearance(activeCompany)
  );
};

export default useAppearance;
