import { useAppDispatch } from '@/hooks/rtk/store';
import { ConfirmationModalStoreTypes, trigger } from '@/stores/common';

const useConfirmationModal = () => {
  const dispatch = useAppDispatch();

  const toggleConfirmationModal = (
    state: Omit<ConfirmationModalStoreTypes, 'show'>
  ) => {
    dispatch(trigger({ ...state, size: state.size || 'lg' }));
  };

  return toggleConfirmationModal;
};

export default useConfirmationModal;
