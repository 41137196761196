function FlagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M5 12.833h9.326c.57 0 .882-.769.517-1.275l-2.444-3.391 2.444-3.391c.365-.507.053-1.276-.517-1.276H5v14"
      ></path>
    </svg>
  );
}

export default FlagIcon;
