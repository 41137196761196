import { useEffect, useMemo, useState } from 'react';

import InfoIcon from '@/assets/icons/InfoIcon';
import {
  BBBCard,
  BBBCheckbox,
  BBBDebounceInput,
  BBBTextInput,
  BBBTooltip,
} from '@/components/ui';
import {
  useLoginTitleSetting,
  useUpsertLoginSetting,
} from '@/hooks/bitLogin/login/login-settings';

type FormSchema = {
  title: string;
  subHeading: string;
  consent: boolean;
};

export default function TitleCard() {
  const { value: titleValue } = useLoginTitleSetting('form_title');
  const { value: subHeadingValue } = useLoginTitleSetting('form_sub_heading');
  const { active: consentValue } = useLoginTitleSetting('consent_disclaimer');

  const values: FormSchema = useMemo(
    () => ({
      title: titleValue || '',
      subHeading: subHeadingValue || '',
      consent: consentValue || false,
    }),
    [consentValue, subHeadingValue, titleValue]
  );

  const { mutate } = useUpsertLoginSetting();

  return (
    <BBBCard title="Title">
      <div className="flex flex-col gap-4">
        <BBBDebounceInput
          label="Form title (optional)"
          placeholder="Enter form title"
          hasMaxCharLabel
          maxChar={20}
          value={values.title}
          onValueChange={(value) => {
            mutate({
              value,
              type: 'form_title',
              group: 'title',
              active: true,
            });
          }}
        />
        <BBBDebounceInput
          label="Sub-heading (optional)"
          placeholder="Enter sub-heading"
          hasMaxCharLabel
          maxChar={40}
          value={values.subHeading}
          onValueChange={(value) =>
            mutate({
              value: value,
              type: 'form_sub_heading',
              group: 'title',
              active: true,
            })
          }
        />
        <BBBCheckbox
          label={
            <div className="flex gap-1 items-center justify-center">
              <p className="text-primary-main text-sm">Consent disclaimer</p>
              <BBBTooltip
                show
                content="Add a disclaimer to your form to explain why you're collecting data and reassure visitors about data usage."
              >
                <InfoIcon />
              </BBBTooltip>
            </div>
          }
          checked={values.consent}
          onValueChange={(val) =>
            mutate({
              group: 'title',
              type: 'consent_disclaimer',
              active: val || false,
            })
          }
        />
      </div>
    </BBBCard>
  );
}
