function ShopifyIcon3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      fill="none"
      viewBox="0 0 22 24"
    >
      <path
        fill="#fff"
        d="M14.43 2.81s-.271.077-.716.213a5.666 5.666 0 00-.35-.852c-.503-.969-1.258-1.492-2.15-1.492-.057 0-.115 0-.193.02-.02-.04-.058-.058-.077-.097-.388-.426-.891-.62-1.492-.6C8.29.04 7.128.871 6.198 2.363 5.54 3.41 5.036 4.727 4.9 5.754c-1.336.407-2.266.697-2.285.717-.678.213-.697.232-.775.871C1.782 7.827 0 21.462 0 21.462L14.643 24V2.79c-.116 0-.174.02-.213.02zm-3.39 1.046c-.774.232-1.626.503-2.46.755.233-.91.698-1.82 1.24-2.42.213-.214.504-.466.833-.62.33.697.407 1.646.388 2.285zM9.453.796c.271 0 .504.058.698.174-.31.155-.62.407-.91.697-.737.794-1.299 2.034-1.531 3.215-.697.213-1.395.427-2.034.62C6.101 3.642 7.67.834 9.452.795zM7.205 11.448c.078 1.24 3.351 1.51 3.545 4.435.136 2.305-1.22 3.874-3.177 3.99-2.363.155-3.66-1.24-3.66-1.24l.503-2.13s1.298.988 2.344.91c.678-.038.93-.6.91-.987-.097-1.627-2.77-1.53-2.944-4.204-.155-2.246 1.317-4.513 4.571-4.726 1.26-.077 1.898.233 1.898.233l-.736 2.789s-.832-.387-1.82-.31c-1.434.097-1.453 1.007-1.434 1.24zm4.61-7.826c0-.58-.077-1.414-.348-2.11.89.173 1.317 1.161 1.51 1.762-.348.097-.736.213-1.162.348zM15.089 23.96l6.082-1.51s-2.615-17.684-2.634-17.8a.225.225 0 00-.213-.194c-.097 0-1.802-.039-1.802-.039S15.476 3.41 15.09 3.022v20.939z"
      ></path>
    </svg>
  );
}

export default ShopifyIcon3;
