import { PlusCircle, Trash2 } from 'react-feather';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { createId } from '@paralleldrive/cuid2';
import { LiveChatSettings } from '../LivechatSettings';
import TagsColorPicker from './TagsColorPicker';

import { BBBCard, BBBTextInput } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';

type Props = {
  control: Control<LiveChatSettings>;
};

function TicketCategory({ control }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ticketTags',
  });

  return (
    <BBBCard
      title="Ticket category"
      desc="Organize your tickets and find the best solution to your customer"
      className="mb-cardBottom"
    >
      {fields.map((field, index) => (
        <div
          key={field.id}
          className="flex justify-between items-center mb-5 last:mb-0"
        >
          <div className="flex items-center gap-4">
            <BBBTextInput
              hasMaxCharLabel
              maxChar={20}
              isHookForm
              control={control}
              controlName={`ticketTags.${index}.label`}
              containerClassname="mb-0"
              placeholder="Name this tag"
              disabled={index === 0}
              className="w-72"
            />
            <Controller
              control={control}
              name={`ticketTags.${index}.color`}
              render={({ field }) => (
                <TagsColorPicker
                  value={field.value}
                  onValueChange={field.onChange}
                  className={index === 0 ? 'pointer-events-none' : undefined}
                />
              )}
            />
          </div>
          <div className="flex items-center gap-4">
            <Controller
              control={control}
              name={`ticketTags.${index}`}
              render={({ field }) => (
                <BBBBadge
                  text={field.value.label}
                  type={field.value.color}
                  className={!field.value.label ? 'w-11 h-7' : ''}
                />
              )}
            />
            {index > 0 ? (
              <Trash2
                size={16}
                className="cursor-pointer text-neutral-30 hover:text-danger-main transition-colors"
                onClick={() => remove(index)}
              />
            ) : (
              <div className="w-4 h-4" />
            )}
          </div>
        </div>
      ))}
      <div
        className="mt-5 flex items-center gap-2 cursor-pointer text-neutral-40 hover:text-neutral-50 transition-colors"
        onClick={() => append({ id: createId(), label: '', color: 'neutral' })}
      >
        <PlusCircle />
        <div>Add new ticket tag</div>
      </div>
    </BBBCard>
  );
}

export default TicketCategory;
