import { useMutation, useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { WhatsAppCloudAccount } from 'types/whatsApp/chat';
import useAccounts from './useAccounts';

const QUERY_KEY = 'bitchat-whatsapp-business-profile' as const;

export const useProfile = () => {
  const accountsQuery = useAccounts();

  return useQuery(
    [QUERY_KEY, accountsQuery.data?.apiKey],
    () =>
      services.whatsApp.businessProfile.getProfile({
        key: accountsQuery.data!.apiKey!,
      }),
    { enabled: !!accountsQuery.data?.apiKey }
  );
};

export const useUpdateProfile = () => {
  const accountsQuery = useAccounts();

  return useMutation((data: Omit<WhatsAppCloudAccount, 'key'>) =>
    services.whatsApp.businessProfile.updateProfile({
      key: accountsQuery.data!.apiKey!,
      ...data,
    })
  );
};
