function QRIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <rect
        width="10.294"
        height="10.294"
        x="0.5"
        y="-0.5"
        fill="#fff"
        stroke="#000"
        rx="3.5"
        transform="matrix(1 0 0 -1 0 10.794)"
      ></rect>
      <rect
        width="10.294"
        height="10.294"
        x="0.5"
        y="-0.5"
        fill="#fff"
        stroke="#000"
        rx="3.5"
        transform="matrix(1 0 0 -1 0 23.5)"
      ></rect>
      <rect
        width="10.294"
        height="10.294"
        x="0.5"
        y="-0.5"
        fill="#fff"
        stroke="#000"
        rx="3.5"
        transform="matrix(1 0 0 -1 12.706 10.794)"
      ></rect>
      <rect
        width="4.235"
        height="4.235"
        fill="#000"
        rx="2"
        transform="matrix(1 0 0 -1 16.235 8.265)"
      ></rect>
      <rect
        width="4.235"
        height="4.235"
        fill="#000"
        rx="2"
        transform="matrix(1 0 0 -1 3.53 8.265)"
      ></rect>
      <rect
        width="4.235"
        height="4.235"
        fill="#000"
        rx="2"
        transform="matrix(1 0 0 -1 3.53 20.97)"
      ></rect>
      <rect
        width="8"
        height="8"
        fill="#000"
        rx="3"
        transform="matrix(1 0 0 -1 14 22.5)"
      ></rect>
    </svg>
  );
}

export default QRIcon;
