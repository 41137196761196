import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { Branding } from '@/types/bitLink/v2';

const useBranding = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    Branding | null,
    unknown,
    Branding | null,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitlink-branding', activeCompany],
    () => services.bitLink.appearance.getBranding(activeCompany),
    {
      ...options,
      enabled,
    }
  );
};

export default useBranding;
