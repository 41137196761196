import api from 'config/api';

import { WACloudTemplate } from '@/types/whatsApp/settings';

export default {
  getWACloudTemplates: (
    companyId: number,
    params?: {
      status?: WACloudTemplate['status'];
      page?: number;
      size?: number;
      search?: string;
      type?: string | null;
      sort?: string | null;
    }
  ) =>
    api.bitChat
      .get<WACloudTemplate[]>(`/template/${companyId}/whatsapp_meta`, {
        params,
      })
      .then((res) => res.data),
  getWACloudTemplate: (companyId: number, templateId: string) =>
    api.bitChat
      .get<WACloudTemplate>(
        `/template/${companyId}/whatsapp_meta/${templateId}`
      )
      .then((res) => res.data),
  updateWACloudTemplate: (
    companyId: number,
    payload: Partial<
      Pick<WACloudTemplate, 'name' | 'language' | 'message' | 'isDraft' | 'id'>
    >
  ) =>
    api.bitChat
      .post<WACloudTemplate>(`/template/${companyId}/whatsapp_meta`, payload)
      .then((res) => res.data),
  deleteWACloudTemplate: (companyId: number, templateId: string) =>
    api.bitChat
      .delete(`/template/${companyId}/whatsapp_meta/${templateId}`)
      .then((res) => res.data),
};
