function TicketIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.282 1.458c-.203.204-.192.533-.072.795A1.476 1.476 0 012.253 4.21c-.262-.12-.591-.131-.795.072l-.305.306a.522.522 0 000 .738l5.521 5.521a.522.522 0 00.738 0l.306-.305c.203-.204.192-.533.072-.795A1.476 1.476 0 019.747 7.79c.262.12.591.131.795-.072l.305-.306a.522.522 0 000-.738L5.326 1.153a.522.522 0 00-.738 0l-.306.305z"
      ></path>
    </svg>
  );
}

export default TicketIcon;
