import React from 'react';

type Props = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const EyeIcon = ({ color = '#9A9A9A', ...props }: Props) => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1428 5C10.3472 5 9.58411 5.31607 9.0215 5.87868C8.45889 6.44129 8.14282 7.20435 8.14282 8C8.14282 8.79565 8.45889 9.55871 9.0215 10.1213C9.58411 10.6839 10.3472 11 11.1428 11C11.9385 11 12.7015 10.6839 13.2641 10.1213C13.8268 9.55871 14.1428 8.79565 14.1428 8C14.1428 7.20435 13.8268 6.44129 13.2641 5.87868C12.7015 5.31607 11.9385 5 11.1428 5ZM11.1428 13C9.81674 13 8.54497 12.4732 7.60729 11.5355C6.66961 10.5979 6.14282 9.32608 6.14282 8C6.14282 6.67392 6.66961 5.40215 7.60729 4.46447C8.54497 3.52678 9.81674 3 11.1428 3C12.4689 3 13.7407 3.52678 14.6784 4.46447C15.616 5.40215 16.1428 6.67392 16.1428 8C16.1428 9.32608 15.616 10.5979 14.6784 11.5355C13.7407 12.4732 12.4689 13 11.1428 13ZM11.1428 0.5C6.14282 0.5 1.87282 3.61 0.142822 8C1.87282 12.39 6.14282 15.5 11.1428 15.5C16.1428 15.5 20.4128 12.39 22.1428 8C20.4128 3.61 16.1428 0.5 11.1428 0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeIcon;
