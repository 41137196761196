import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { CompanyIntegration, Integration } from '@/types/bitApp/v2';
import {
  CompanyIntegrationAssociation,
  IntegrationAssociation,
} from '@/types/bitApp/v2/association';

export default {
  getCompanyIntegrations: (companyId: number | undefined) =>
    api.bitApp
      .get<ApiResponse<CompanyIntegrationAssociation[]>>(
        `/integrations/${companyId}`
      )
      .then((res) => res.data.data),

  getIntegrations: () =>
    api.bitApp
      .get<ApiResponse<IntegrationAssociation[]>>(`/integrations`)
      .then((res) => res.data.data),

  getIntegrationDetail: (id: number | undefined) =>
    api.bitApp
      .get<ApiResponse<Integration>>(`/integrations/${id}/detail`)
      .then((res) => res.data.data),

  getReviewIntegration: (
    companyId: number | undefined,
    params?: { active?: boolean }
  ) =>
    api.bitApp
      .get<ApiResponse<CompanyIntegrationAssociation[]>>(
        `/integrations/${companyId}/review`,
        { params }
      )
      .then((res) => res.data.data),
  toggleCompanyIntegrations: (
    companyId: number | undefined,
    {
      integrationId,
      status,
      userId,
    }: Pick<CompanyIntegration, 'integrationId' | 'status'> & {
      userId: number;
    }
  ) =>
    api.bitApp.post(`/integrations/${companyId}/toggle`, {
      integrationId,
      status,
      userId,
    }),
  setReviewIntegration: (
    companyId: number | undefined,
    {
      integrationListId,
    }: {
      integrationListId: number | undefined;
    }
  ) =>
    api.bitApp.post(`/integrations/${companyId}/review`, {
      integrationListId,
    }),
};
