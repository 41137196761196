export default function UnionIcon({ size = 20 }: { size?: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#9A9A9A"
        fillRule="evenodd"
        d="M4.2 10c0 .683.062 1.351.181 2H2.252A8.015 8.015 0 012 10c0-.69.088-1.36.252-2h2.13c-.12.649-.182 1.317-.182 2zm2.223-2a9.03 9.03 0 000 4h7.026a9.04 9.04 0 00.055-4H6.423zm6.4-2H7.135a9.04 9.04 0 012.899-3.37A9.035 9.035 0 0112.824 6zm2.717 2a11.072 11.072 0 01-.044 4h2.252c.165-.64.252-1.31.252-2s-.087-1.36-.252-2H15.54zm1.39-2h-1.934a11 11 0 00-2.103-3.461A8.028 8.028 0 0116.93 6zM4.95 6H3.07a8.028 8.028 0 014.053-3.467C6.197 3.533 5.455 4.707 4.95 6zm-1.88 8h1.88a11.005 11.005 0 002.173 3.467A8.028 8.028 0 013.07 14zm9.633 0H7.135a9.04 9.04 0 002.731 3.25A9.04 9.04 0 0012.703 14zm-.153 3.585A11.013 11.013 0 0014.902 14h2.028a8.024 8.024 0 01-4.38 3.585zM10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
