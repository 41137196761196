import { SidebarIcon } from '@/constants/layouts/sidebarData';

function CustomerIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <CustomerIconDefault {...props} />
      ) : (
        <CustomerIconActive {...props} />
      )}
    </>
  );
}

function CustomerIconDefault({ size = 25 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M10 12.083c-2.775 0-4.861 1.95-4.861 4.167a.694.694 0 01-1.389 0c0-3.153 2.888-5.556 6.25-5.556s6.25 2.403 6.25 5.556a.694.694 0 01-1.389 0c0-2.217-2.086-4.167-4.861-4.167zM10 8.611a2.083 2.083 0 100-4.167 2.083 2.083 0 000 4.167zm0 1.39a3.472 3.472 0 100-6.945A3.472 3.472 0 0010 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function CustomerIconActive({ size = 25 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M10 12.083c-2.775 0-4.861 1.95-4.861 4.167a.694.694 0 01-1.389 0c0-3.153 2.888-5.556 6.25-5.556s6.25 2.403 6.25 5.556a.694.694 0 01-1.389 0c0-2.217-2.086-4.167-4.861-4.167zM10 8.611a2.083 2.083 0 100-4.167 2.083 2.083 0 000 4.167zm0 1.39a3.472 3.472 0 100-6.945A3.472 3.472 0 0010 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CustomerIcon;
