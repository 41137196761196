type Props = {
  color?: string;
  size?: number;
};

function TagIcon({ color = '#2080D1', size = 17 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4912 3.25874L8.89291 3.02235C8.50011 2.98691 8.3032 2.9692 8.11692 3.00159C7.95172 3.03055 7.7929 3.08829 7.64768 3.17218C7.48367 3.26633 7.34446 3.40554 7.06606 3.68394L3.36982 7.38018C2.80289 7.94711 2.51942 8.23058 2.41312 8.55759C2.31964 8.84512 2.31964 9.15488 2.41312 9.44241C2.51942 9.76942 2.80289 10.0529 3.36982 10.6198L6.20451 13.4545C6.77145 14.0215 7.05492 14.3049 7.38192 14.4112C7.66947 14.5046 7.97921 14.5046 8.26675 14.4112C8.59325 14.3054 8.87672 14.022 9.44264 13.456L13.1394 9.75929C13.4183 9.48038 13.5575 9.34117 13.6517 9.17717C13.7357 9.0324 13.7934 8.87345 13.8217 8.70742C13.8541 8.52114 13.8369 8.32474 13.8015 7.93193L13.5651 5.33363C13.5038 4.65685 13.4725 4.31972 13.3257 4.05448C13.1956 3.82112 13.0027 3.62826 12.7699 3.49868C12.5051 3.35137 12.168 3.321 11.4912 3.25874Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10.25" cy="6.5" r="1" fill={color} />
    </svg>
  );
}

export default TagIcon;
