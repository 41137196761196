import * as React from 'react';

import { SidebarIcon } from '@/constants/layouts/sidebarData';

const CartIcon2 = ({ mode = 'default', size = 25 }: SidebarIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 18 17"
  >
    <path
      fill={mode === 'default' || mode === 'hover' ? '#727272' : '#FF7C11'}
      fillRule="evenodd"
      d="M9 1.73a2.5 2.5 0 0 1 2.5 2.5h-5A2.5 2.5 0 0 1 9 1.73m-4.167 2.5a4.167 4.167 0 1 1 8.334 0v.417h3.171c.519 0 .912.469.82.98l-1.785 10a.83.83 0 0 1-.82.686H3.447a.83.83 0 0 1-.82-.686l-1.786-10a.833.833 0 0 1 .82-.98h3.171zm-.688 10.417L2.657 6.313h12.686l-1.489 8.334z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default CartIcon2;
