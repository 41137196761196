export const env = {
  REACT_APP_API_BBBGATEWAY_BASEURL: import.meta.env
    .REACT_APP_API_BBBGATEWAY_BASEURL,
  REACT_APP_NODE_ENV: import.meta.env.REACT_APP_NODE_ENV,
  REACT_APP_SHOPIFY_API_KEY_BITAPP: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITAPP,
  REACT_APP_SHOPIFY_API_KEY_BITCHAT: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITCHAT,
  REACT_APP_SHOPIFY_API_KEY_BITCRM: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITCRM,
  REACT_APP_SHOPIFY_API_KEY_BITLINK: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITLINK,
  REACT_APP_SHOPIFY_API_KEY_BITAI: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITAI,
  REACT_APP_SHOPIFY_API_KEY_BITLOGIN: import.meta.env
    .REACT_APP_SHOPIFY_API_KEY_BITLOGIN,
  REACT_APP_SHOPIFY_BITLOGIN_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITLOGIN_SCOPES,
  REACT_APP_SHOPIFY_BITAI_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITAI_SCOPES,
  REACT_APP_SHOPIFY_BITLINK_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITLINK_SCOPES,
  REACT_APP_SHOPIFY_BITAPP_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITAPP_SCOPES,
  REACT_APP_SHOPIFY_BITCHAT_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITCHAT_SCOPES,
  REACT_APP_SHOPIFY_BITCRM_SCOPES: import.meta.env
    .REACT_APP_SHOPIFY_BITCRM_SCOPES,
  REACT_APP_SHOPIFY_LOGIN_SECRETKEY: import.meta.env
    .REACT_APP_SHOPIFY_LOGIN_SECRETKEY,
  REACT_APP_PUBLISH_PRICING_SECRETKEY: import.meta.env
    .REACT_APP_PUBLISH_PRICING_SECRETKEY,
  REACT_APP_SHOPIFY_CONNECT_SECRETKEY: import.meta.env
    .REACT_APP_SHOPIFY_CONNECT_SECRETKEY,
  REACT_APP_API_BITLINK_PROXY_BASEURL: import.meta.env
    .REACT_APP_API_BITLINK_PROXY_BASEURL,
  REACT_APP_API_BITAI_BASEURL: import.meta.env.REACT_APP_API_BITAI_BASEURL,
  REACT_APP_SENTRY_DSN: import.meta.env.REACT_APP_SENTRY_DSN,
  REACT_APP_BITLOGIN_THEME_EXTENSION_UUID: import.meta.env
    .REACT_APP_BITLOGIN_THEME_EXTENSION_UUID,
  REACT_APP_CUSTOMERS_TEMPLATE_REMOTE_URL: import.meta.env
    .REACT_APP_CUSTOMERS_TEMPLATE_REMOTE_URL,
  REACT_APP_BITCHAT_WIDGET_THEME_EXTENSION_UUID: import.meta.env
    .REACT_APP_BITCHAT_WIDGET_THEME_EXTENSION_UUID,
  REACT_APP_WIDGET_PROXY_URL: import.meta.env.REACT_APP_WIDGET_PROXY_URL,
  REACT_APP_FRONTEND_URL: import.meta.env.REACT_APP_FRONTEND_URL,
  REACT_APP_EMBED_KEY: import.meta.env.REACT_APP_EMBED_KEY,
  REACT_APP_EMBED_PREVIEW_MODE: import.meta.env.REACT_APP_EMBED_PREVIEW_MODE,
  REACT_APP_PRICING_TRIAL_DURATION: import.meta.env
    .REACT_APP_PRICING_TRIAL_DURATION,
  REACT_APP_CDN_URL: import.meta.env.REACT_APP_CDN_URL,
  REACT_APP_BBB_CS_NUMBER: import.meta.env.REACT_APP_BBB_CS_NUMBER,
  REACT_APP_GOOGLE_CLIENT_ID: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FACEBOOK_APP_ID: import.meta.env.REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_BLAST_NOTIFICATIONS_CAMPAIGN_TEST: import.meta.env
    .REACT_APP_BLAST_NOTIFICATIONS_CAMPAIGN_TEST,
  REACT_APP_WHATSAPP_LOGIN_NUMBER: import.meta.env
    .REACT_APP_WHATSAPP_LOGIN_NUMBER,
  REACT_APP_INTERNAL_SECURITY: import.meta.env
    .REACT_APP_INTERNAL_SECURITY as string,
  REACT_APP_CLOUDINARY_CLOUD_NAME: import.meta.env
    .REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY: import.meta.env
    .REACT_APP_ELIGIBLE_FREE_TRIAL_COMPANY,
};

export const isProd = env?.REACT_APP_NODE_ENV?.includes('production');
