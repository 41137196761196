import api from '@/config/api';
import { SocialConfig, SocialLoginProvider } from '@/types/bitLogin/v2';

export default {
  getSocialConfig: (
    domain: string | undefined,
    params?: { deviceType?: string }
  ) =>
    api.bitLogin
      .get<SocialConfig[]>(`/config/domain/${domain}`, { params })
      .then((res) => res.data),
  getSocialConfigProvider: (
    domain: string | undefined,
    provider: SocialLoginProvider | undefined
  ) =>
    api.bitLogin
      .get<SocialConfig>(`/config/domain/${domain}/provider/${provider}`)
      .then((res) => res.data),
  upsertSocialConfig: (
    domain: string | undefined,
    payload: Partial<SocialConfig>
  ) =>
    api.bitLogin
      .post<SocialConfig>(`/config/domain/${domain}`, payload)
      .then((res) => res.data),
};
