type Props = {
  color?: string;
  isDashed?: boolean;
} & React.HTMLAttributes<SVGElement>;

function LineTypeIcon({ color, isDashed, ...props }: Props) {
  if (isDashed) {
    return (
      <svg
        width="11"
        height="2"
        viewBox="0 0 11 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z"
          fill={color}
        />
        <path
          d="M5 1C5 1.55228 4.55228 2 4 2C3.44772 2 3 1.55228 3 1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1Z"
          fill={color}
        />
        <path
          d="M8 1C8 1.55228 7.55228 2 7 2C6.44772 2 6 1.55228 6 1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1Z"
          fill={color}
        />
        <path
          d="M11 1C11 1.55228 10.5523 2 10 2C9.44771 2 9 1.55228 9 1C9 0.447715 9.44771 0 10 0C10.5523 0 11 0.447715 11 1Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      width="13"
      height="2"
      viewBox="0 0 13 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1H12" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default LineTypeIcon;
