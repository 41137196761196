export default function UserIcon3({ size = 24 }: { size?: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#9A9A9A"
        fillRule="evenodd"
        d="M12 15c-3.996 0-7 2.808-7 6a1 1 0 11-2 0c0-4.54 4.159-8 9-8s9 3.46 9 8a1 1 0 11-2 0c0-3.192-3.004-6-7-6zM12 10a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 100-10 5 5 0 000 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
