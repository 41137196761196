import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

function StickyFooter({ children }: { children: React.ReactNode }) {
  if (!document.getElementById('sticky-footer-section')!) return null;

  return (
    <>
      {createPortal(
        <AnimatePresence>
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{
              type: 'spring',
              bounce: 0,
              duration: 0.3,
            }}
          >
            {children}
          </motion.div>
        </AnimatePresence>,
        document.getElementById('sticky-footer-section')!
      )}
    </>
  );
}

export default StickyFooter;
