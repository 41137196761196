import Skeleton from 'react-loading-skeleton';
import useAccounts from 'hooks/whatsApp/useAccounts';

import { BBBCard, BBBTextInput } from '@/components/ui';

export default function APIKey() {
  const accountsQuery = useAccounts();

  if (accountsQuery.status === 'error' || accountsQuery.data === null)
    return null;

  return (
    <BBBCard title="API Key" titleClassName="lg:font-normal font-semibold">
      {accountsQuery.status === 'loading' ? (
        <Skeleton height={'2rem'} borderRadius={'0.5rem'} />
      ) : (
        <BBBTextInput
          withCopy
          value={accountsQuery.data?.apiKey}
          label="bitChat API Key"
        />
      )}
    </BBBCard>
  );
}
