import { PricingPlan } from '@/api/services/pricing';

export const mapLimitAutomaticUpdates: Partial<Record<PricingPlan, string>> = {
  free: '1x / Week',
  pro: 'Daily Update',
  proAdvance: '2x / Week',
  pro1: '4x / Week',
};

export const mapLimitAutomaticUpdates2: Partial<Record<PricingPlan, string>> = {
  free: '1x / Week',
  pro: 'Daily',
  proAdvance: '2x / Week',
  pro1: '4x / Week',
};
