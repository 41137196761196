function MergeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        fill="#fff"
        d="M.741 11.369a.6.6 0 01-.185-.454.6.6 0 01.185-.453l2.786-2.686a2.57 2.57 0 00.59-.842c.136-.313.203-.642.203-.987V2.645l-.608.583a.645.645 0 01-.464.178.67.67 0 01-.481-.194.6.6 0 01-.186-.453.6.6 0 01.186-.453L4.523.622a.6.6 0 01.22-.138.76.76 0 01.252-.04.77.77 0 01.254.041.603.603 0 01.22.137l1.755 1.684a.619.619 0 01.194.445.59.59 0 01-.194.46.655.655 0 01-.473.179.655.655 0 01-.473-.178l-.607-.567v3.302c0 .345.067.674.202.987.135.313.332.594.591.842l2.786 2.686a.622.622 0 01.194.446.587.587 0 01-.194.46.656.656 0 01-.473.179.656.656 0 01-.473-.178L4.995 8.197l-3.308 3.172a.675.675 0 01-.464.186.642.642 0 01-.482-.186z"
      ></path>
    </svg>
  );
}

export default MergeIcon;
