import dayjs from 'dayjs';

export const dateFilter = (date: string | undefined) => {
  const now = dayjs();

  let startDate = now.startOf('day');
  const endDate = now.endOf('day');

  if (date === 'yesterday') {
    startDate = now.subtract(1, 'day');
  }

  if (date === 'last-3-days') {
    startDate = now.subtract(3, 'days');
  }

  if (date === 'last-7-days') {
    startDate = now.subtract(7, 'days');
  }

  if (date === 'last-30-days') {
    startDate = now.subtract(1, 'month');
  }

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};
