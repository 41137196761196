import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { InstagramAccountList } from '@/types/instagram';

export default {
  getInstagramList: (companyId: number) =>
    api.instagram
      .get<ApiResponse<InstagramAccountList[]>>(`/list/${companyId}`)
      .then((res) => res.data.data),
  saveInstagramAccount: (companyId: number, pageId: string) =>
    api.instagram.post('/integrations', {
      companyId,
      pageId,
    }),
  getInstagramInfo: (companyId: number, clientNumber: string) =>
    api.whatsApp.v1
      .get('/facebook/instagram/media', {
        params: {
          id: clientNumber,
          companyId,
        },
      })
      .then((res) => res.data.data),
};
