import { useReactFlow } from 'reactflow';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import useStore from './store';

export function useAutoPosition() {
  const nodes = useStore((s) => s.nodes);
  const { getZoom } = useReactFlow();

  return (sourceId: string) => {
    const node = nodes.find((node) => node.id === sourceId);
    const parentNode = node?.parentNode || node?.id;

    if (parentNode) {
      const sourceElement = document.querySelector<HTMLDivElement>(
        `.react-flow__node[data-id="${sourceId}"]`
      );

      const parentElement = document.querySelector<HTMLDivElement>(
        `.react-flow__node[data-id="${sourceId}"]`
      );

      const paneSelector = document.querySelector<HTMLDivElement>(
        '.react-flow__viewport'
      );

      if (sourceElement && parentElement && paneSelector) {
        const { x, y, width } = sourceElement.getBoundingClientRect();
        const { height: parentHeight } = parentElement.getBoundingClientRect();

        const { x: viewportX, y: viewportY } =
          paneSelector.getBoundingClientRect();

        const zoomRatio = getZoom();

        return {
          x: (x - viewportX + width + 100) * (1 / zoomRatio),
          y: (y - parentHeight - viewportY - 100) * (1 / zoomRatio),
        };
      }
    }
  };
}

export function useDeleteState() {
  const confirm = useConfirmationModal();
  const deleteRelation = useStore((s) => s.deleteRelation);

  return (nodeId: string) => {
    confirm({
      title: 'Delete state',
      description:
        'Are you sure want to delete this state? Once you delete the action cannot be undone',
      onAccept: (hide) => {
        deleteRelation(nodeId);
        hide();
      },
      deleteModal: true,
      submitText: 'Delete state',
    });
  };
}
