import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { twMerge as cx } from 'tailwind-merge';

import {
  BBBBottomSheet,
  BBBButton,
  BBBCard,
  BBBDatePicker,
  BBBSelect,
} from '@/components/ui';
import optionsDateFilter from '@/constants/analytics/common/optionsDateFilter';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import { selectedDate } from '@/types/datePicker';
import getRangeValue from '@/utils/common/date';
import { cn } from '@/utils/styles';

type Props = {
  selectedDay: selectedDate;
  selectedEndDay: selectedDate;
  setSelectedDay: React.Dispatch<React.SetStateAction<selectedDate>>;
  setSelectedEndDay: React.Dispatch<React.SetStateAction<selectedDate>>;
  active: boolean;
  onChangeActive?: (val: boolean) => void;
};

export default function BBBAnalyticsSelectRange({
  selectedDay,
  selectedEndDay,
  setSelectedDay,
  setSelectedEndDay,
  active,
  onChangeActive,
}: Props) {
  const isMobile = useResponsive('sm');

  const outsideRef = useRef(null);

  const [localStartDate, setLocalStartDate] = useState<selectedDate>();
  const [localEndDate, setLocalEndDate] = useState<selectedDate>();

  useEffect(() => {
    setLocalEndDate(selectedEndDay);
    setLocalStartDate(selectedDay);
  }, [selectedEndDay, selectedDay]);

  const onCancel = () => {
    setSelectedDay(selectedDay);
    setSelectedEndDay(selectedEndDay);
    onChangeActive?.(!active);
  };

  useOutsideAlerterv2(outsideRef, onCancel);

  const rangeValue = useMemo(
    () => getRangeValue(localStartDate, localEndDate),
    [localEndDate, localStartDate]
  );

  const handleApply = () => {
    setSelectedDay(localStartDate);
    setSelectedEndDay(localEndDate);
    onChangeActive?.(false);
  };

  const handleChangeFilter = useCallback((value: string | undefined) => {
    const currentDate = dayjs().startOf('day');
    const endDate = dayjs().endOf('day');

    if (value === 'yesterday') {
      setLocalEndDate(endDate.subtract(1, 'day').toDate());
      setLocalStartDate(currentDate.subtract(1, 'day').toDate());
    }
    if (value === 'today') {
      setLocalStartDate(currentDate.toDate());
      setLocalEndDate(endDate.toDate());
    }
    if (value === 'week') {
      setLocalEndDate(endDate.subtract(1, 'day').toDate());
      setLocalStartDate(currentDate.subtract(7, 'day').toDate());
    }
    if (value === '30-days') {
      setLocalEndDate(endDate.subtract(1, 'day').toDate());
      setLocalStartDate(currentDate.subtract(30, 'day').toDate());
    }
    if (value === '90-days') {
      setLocalEndDate(endDate.subtract(1, 'day').toDate());
      setLocalStartDate(currentDate.subtract(90, 'day').toDate());
    }
    if (value === '365-days') {
      setLocalEndDate(endDate.subtract(1, 'day').toDate());
      setLocalStartDate(currentDate.subtract(365, 'day').toDate());
    }
    if (value === 'month') {
      const lastMonth = currentDate.subtract(1, 'month').toDate();
      const lastMonthEndDate = dayjs(lastMonth).endOf('month').toDate();
      setLocalEndDate(lastMonthEndDate);
      setLocalStartDate(dayjs(lastMonth).startOf('month').toDate());
    }
    if (value === '12-months') {
      const lastMonth = currentDate.subtract(1, 'month').toDate();
      const lastMonthEndDate = dayjs(lastMonth).endOf('month').toDate();
      setLocalEndDate(lastMonthEndDate);
      setLocalStartDate(
        dayjs(lastMonth).subtract(12, 'month').startOf('month').toDate()
      );
    }
    if (value === 'year') {
      const lastYear = currentDate.subtract(1, 'year').toDate();
      const lastYearEndDate = dayjs(lastYear).endOf('year').toDate();
      setLocalEndDate(lastYearEndDate);
      setLocalStartDate(dayjs(lastYear).startOf('year').toDate());
    }
  }, []);

  if (isMobile) {
    const mobileDateOptions = optionsDateFilter.filter(
      (opt) => opt.value !== 'custom'
    );

    return (
      <BBBBottomSheet
        show
        onClose={() => {
          handleApply();
        }}
      >
        {mobileDateOptions.map((date) => (
          <div
            className={cn(
              'flex px-2.5 py-2',
              rangeValue?.value === date.value && 'bg-secondary-surface'
            )}
            key={date.value}
            onClick={() => {
              handleChangeFilter(date.value);
            }}
          >
            {date.label}
          </div>
        ))}
      </BBBBottomSheet>
    );
  }

  return (
    <div
      className={cx('absolute')}
      style={{
        top: '120%',
        right: '0',
      }}
      ref={outsideRef}
    >
      <BBBCard
        className="p-5 z-[999] w-[370px]"
        titleClassName="font-medium text-2xl"
        title="Select Range"
      >
        <div className="mb-5">
          <BBBSelect
            options={optionsDateFilter}
            placeholder="Choose range"
            value={rangeValue}
            onValueChange={(opt) => handleChangeFilter(opt?.value)}
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div className="mb-5">
          <BBBDatePicker
            selectedDay={localStartDate}
            onChangeDate={(dates) => {
              if (Array.isArray(dates)) {
                const [start, end] = dates;
                if (start) {
                  setLocalStartDate(dayjs(start).startOf('day').toDate());
                } else {
                  setLocalStartDate(null);
                }
                if (end) {
                  setLocalEndDate(dayjs(end).endOf('day').toDate());
                } else {
                  setLocalEndDate(null);
                }
              }
            }}
            selectedEndDay={localEndDate}
            isRange
            disableBefore={false}
            disableAfter
            showTimePicker={false}
          />
        </div>
        <div className="flex justify-end">
          <BBBButton
            text="Cancel"
            variant="secondary"
            onClick={onCancel}
            className="mr-2"
          />
          <BBBButton
            text="Apply"
            disabled={!localEndDate || !localStartDate}
            onClick={handleApply}
          />
        </div>
      </BBBCard>
    </div>
  );
}
