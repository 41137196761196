import api from 'config/api';
import { FormType } from 'pages/BitApp/Design/components/Popup/EditImageSlider';

export default {
  addBanner: (
    companyId: number | undefined,
    payload: Partial<
      FormType<true> & {
        sectionId: number | null;
      }
    >
  ) =>
    api.bitApp
      .post(`/banner/${companyId}`, {
        bannerData: payload.bannerContents,
        active: true,
        sectionId: payload.sectionId,
        bannerId: payload.bannerId,
      })
      .then((res) => res.data),
};
