import { ActionItem as ActionItemType } from '@/constants/bitChat/flows';

export default function ActionItem({
  onChange,
  ...props
}: ActionItemType & { onChange: (params: ActionItemType) => void }) {
  return (
    <div
      className="cursor-pointer hover:text-neutral-60 transition-colors border border-dashed mb-5 last:mb-0 rounded-lg flex justify-center items-center text-neutral-50 p-3"
      onClick={() => onChange(props)}
    >
      {props.label}
    </div>
  );
}
