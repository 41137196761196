import { shallowEqual } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useActiveCompany, useApiKey } from '../rtk/selector';
import useChatMessagesQueryKey from './useChatMessagesQueryKey';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';

export type ChatMessagesType = ReturnType<typeof useChatMessages>['data'];

const useChatMessages = () => {
  const activeCompany = useActiveCompany();
  const apiKey = useApiKey();
  const selectedChat = useAppSelector((s) => {
    const _selectedChat = s.bitCRM.selectedChat!;
    return {
      clientNumber: _selectedChat.clientNumber,
      sources: _selectedChat.sources,
      messageId: _selectedChat.messageId,
    };
  }, shallowEqual);

  const queryKey = useChatMessagesQueryKey();

  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) => {
      return services.whatsApp.v3.message.getMessages({
        chatId: selectedChat.clientNumber,
        sources: selectedChat.sources,
        apiKey,
        companyId: activeCompany,
        cursor: pageParam,
        ...(selectedChat.messageId && {
          point: selectedChat.messageId,
          point2: `${selectedChat.messageId}`,
        }),
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage?.meta.endCursor,
      getPreviousPageParam: (lastPage) => lastPage?.meta.startCursor,
    }
  );
};

export default useChatMessages;

export function findScrollableMessagePanel() {
  const scrollableRef = document.getElementsByClassName(
    'infinite-scrollable'
  )[0];
  return scrollableRef;
}
