import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { FacebookAccountList } from '@/types/facebook';

export default {
  getFacebookList: (companyId: number) =>
    api.facebook
      .get<ApiResponse<FacebookAccountList[]>>(
        `/facebook/accounts/${companyId}`
      )
      .then((res) => res.data.data),
  saveFacebookPage: (companyId: number, id: string) =>
    api.facebook.post(`/facebook/accounts/${companyId}`, {
      id,
    }),
};
