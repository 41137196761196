import { twMerge as cx } from 'tailwind-merge';
import ChatPreview from './ChatPreview';
import HeaderChatPreview from './HeaderChatPanel';

export type PanelState = 'chat' | 'message' | 'customer';

type ChatPanelContentProps = {
  className?: string;
};

function ChatPanelContent({ className }: ChatPanelContentProps) {
  return (
    <div className={cx('flex bg-white flex-col flex-none', className)}>
      <HeaderChatPreview />
      <div className="grow relative">
        <div className="absolute inset-0 overflow-auto" id="previewChats">
          <ChatPreview />
        </div>
      </div>
    </div>
  );
}

export default ChatPanelContent;
