import React from 'react';

export default function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2814_5276)">
        <path
          fill="#757575"
          fillRule="evenodd"
          d="M6 1.333a.667.667 0 00-1.333 0V2H4A3.333 3.333 0 00.667 5.333V12A3.333 3.333 0 004 15.333h8A3.333 3.333 0 0015.334 12V5.333A3.333 3.333 0 0012 2h-.666v-.667a.667.667 0 00-1.334 0V2H6v-.667zm-1.333 2V4A.667.667 0 006 4v-.667h4V4a.667.667 0 001.334 0v-.667H12a2 2 0 012 2V6H2v-.667a2 2 0 012-2h.667zM2 7.333V12a2 2 0 002 2h8a2 2 0 002-2V7.333H2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2814_5276">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
