import { createId } from '@paralleldrive/cuid2';

import { env } from '@/config/env';
import { BitLinkShopifyProducts } from '@/hooks/bitLink/shopify/useCollectionQuery';
import {
  AnalyticsProductClicks,
  AnalyticsTopLink,
  AnalyticsTrafficSource,
  AnalyticsViewsClicksCTR,
} from '@/types/bitLink/analytics';
import { Customer } from '@/types/customers';

export const previewEngagementRate = {
  current: 120,
  previous: 10,
  percentage: 8.0,
};

export const previewShareData = {
  current: 160,
  previous: 3,
  percentage: 12.0,
};

export const previewSubscribers = {
  current: 10,
  previous: 3,
  percentage: 12.0,
};

export const previewDailyViewsData = {
  current: 180,
  previous: 8,
  percentage: 15.9,
};

export const previewPageViewData = {
  current: 200,
  previous: 10,
  percentage: 15.9,
};

export const previewLinkClickData = {
  current: 190,
  previous: 10,
  percentage: -15.9,
};

export const previewCTR = {
  current: '90%',
};

export const previewViewChart = [
  {
    date: 'Wed',
    total: 15,
  },
  {
    date: 'Thu',
    total: 30,
  },

  {
    date: 'Fri',
    total: 68,
  },
  {
    date: 'Sat',
    total: 90,
  },
  {
    date: 'Sun',
    total: 60,
  },
  {
    date: 'Mon',
    total: 40,
  },
  {
    date: 'Tue',
    total: 55,
  },
];

export const previewTrafficSource: AnalyticsTrafficSource = [
  {
    name: 'instagram.com',
    value: 45,
    percentage: '45',
  },
  {
    name: 'tiktok.com',
    value: 60,
    percentage: '25',
  },
  {
    name: 'facebook.com',
    value: 50,
    percentage: '20',
  },
  {
    name: 'twitter.com',
    value: 20,
    percentage: '8',
  },
  {
    name: 'Others',
    value: 5,
    percentage: '2',
  },
];

export const previewChartClicksViewsCTR: AnalyticsViewsClicksCTR = [
  {
    label: 'Wed',
    range: {
      start: '2023-03-03',
      end: '2023-03-03',
    },
    clicks: 20,
    views: 20,
    CTR: 80,
  },
  {
    label: 'Thu',
    range: {
      start: '2023-03-04',
      end: '2023-03-04',
    },
    clicks: 40,
    views: 50,
    CTR: 45,
  },
  {
    label: 'Fri',
    range: {
      start: '2023-03-05',
      end: '2023-03-05',
    },
    clicks: 60,
    views: 40,
    CTR: 20,
  },
  {
    label: 'Sat',
    range: {
      start: '2023-03-06',
      end: '2023-03-06',
    },
    clicks: 40,
    views: 30,
    CTR: 30,
  },
  {
    label: 'Sun',
    range: {
      start: '2023-03-07',
      end: '2023-03-07',
    },
    clicks: 20,
    views: 10,
    CTR: 40,
  },
  {
    label: 'Mon',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    clicks: 40,
    views: 50,
    CTR: 45,
  },
  {
    label: 'Tue',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    clicks: 60,
    views: 80,
    CTR: 85,
  },
];

export const previewTopLinks = [
  {
    id: createId(),
    type: 'youtube',
    title: 'Youtube',
    total: 810,
  },
  {
    id: createId(),
    type: 'link',
    title: 'Donate here',
    total: 461,
    media: `${env.REACT_APP_CDN_URL}/bitbybit/static/v1/logo-black.png`,
  },
  {
    id: createId(),
    type: 'whatsapp',
    title: 'Contact us',
    total: 209,
  },
  {
    id: createId(),
    type: 'shop',
    title: 'Valentine Hampers 2024',
    total: 133,
  },
  {
    id: createId(),
    type: 'link',
    title: 'Check out our blogs',
    total: 76,
    media: '',
  },
];

export const previewTopLocations: {
  locations: AnalyticsTopLink;
  others: number;
  unknown: number;
} = {
  locations: [
    {
      iso: 'ID',
      country: 'indonesia',
      total: 372,
    },
    {
      iso: 'MY',
      country: 'malaysia',
      total: 110,
    },
    {
      iso: 'SG',
      country: 'singapore',
      total: 87,
    },
    {
      iso: 'TH',
      country: 'thailand',
      total: 64,
    },
    {
      iso: 'VN',
      country: 'vietnam',
      total: 42,
    },
  ],
  others: 1,
  unknown: 125,
};

export const previewSubsribersInformation: {
  subscribers: Partial<Customer>[];
  meta: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
    total: number;
  };
  range: {
    start: string;
    end: string;
  };
} = {
  subscribers: [
    {
      id: '123',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane_doe@mail,com',
      phoneNumber: '+01-123-4567-890',
      companyId: 123,
      createdAt: new Date('2021-12-24').toUTCString(),
      source: 'bitlink_subscription',
    },
    {
      id: '124',
      firstName: 'John',
      lastName: 'Doe',
      email: null,
      phoneNumber: '+01-123-4567-890',
      companyId: 123,
      createdAt: new Date('2021-12-24').toUTCString(),
      source: 'bitlink_subscription',
    },
    {
      id: '125',
      firstName: 'Chris',
      lastName: 'Doe',
      email: 'chris_doe@mail,com',
      phoneNumber: null,
      companyId: 123,
      createdAt: new Date('2021-12-24').toUTCString(),
      source: 'bitlink_subscription',
    },
  ],
  meta: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
    total: 3,
  },
  range: {
    start: '2023-03-01',
    end: '2023-03-07',
  },
};

export const previewAnalyticsProducts: BitLinkShopifyProducts[] = [
  {
    id: '221321sadasd',
    title: 'product 1',
    onlineStoreUrl: 'https://www.shopify.xxx',
    status: 'ACTIVE',
    featuredImage: {
      url: '',
    },
  },
  {
    id: '221321s3asd',
    title: 'product 2',
    onlineStoreUrl: 'https://www.shopify.xxx',
    status: 'ACTIVE',
    featuredImage: {
      url: '',
    },
  },
  {
    id: '221321213dasd',
    title: 'product 3',
    onlineStoreUrl: 'https://www.shopify.xxx',
    status: 'ACTIVE',
    featuredImage: {
      url: '',
    },
  },
];

export const previewAnalyticsProductClicks: AnalyticsProductClicks = [
  {
    date: 'Wed',
    range: {
      start: '2023-03-03',
      end: '2023-03-03',
    },
    clicks: 20,
  },
  {
    date: 'Thu',
    range: {
      start: '2023-03-04',
      end: '2023-03-04',
    },
    clicks: 40,
  },
  {
    date: 'Fri',
    range: {
      start: '2023-03-05',
      end: '2023-03-05',
    },
    clicks: 60,
  },
  {
    date: 'Sat',
    range: {
      start: '2023-03-06',
      end: '2023-03-06',
    },
    clicks: 40,
  },
  {
    date: 'Sun',
    range: {
      start: '2023-03-07',
      end: '2023-03-07',
    },
    clicks: 20,
  },
  {
    date: 'Mon',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    clicks: 40,
  },
  {
    date: 'Tue',
    range: {
      start: '2023-03-08',
      end: '2023-03-08',
    },
    clicks: 60,
  },
];
