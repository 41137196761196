import { useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { CompanyPricing, PlanType, PricingApp } from '@/api/services/pricing';
import {
  AiCreditTransactionType,
  AiCreditUsageHistory,
} from '@/api/services/pricing';
import InfoIcon from '@/assets/icons/InfoIcon';
import AiCredit from '@/components/ai-credit';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import { BBBButton, BBBCard, BBBModal, BBBTooltip } from '@/components/ui';
import { BBBPrimarySwitch, BBBSpinner } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { pricingApiBaseUrl } from '@/constants/api';
import { appNameFromType } from '@/constants/app';
import { mapAppTypeToUrl } from '@/constants/systemLogin/urlRedirect';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import {
  useAiCreditsUsageHistory,
  useUpdateAutoChargeSettings,
} from '@/hooks/pricing/v2/ai-credits';
import { useCompanyPricings } from '@/hooks/pricing/v2/pricing';
import { useActiveCompany } from '@/hooks/rtk/selector';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { socketPricing } from '@/socket';
import { formatDate2 } from '@/utils/common/date';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

const mapTransactionTypeLabel: Record<AiCreditTransactionType, string> = {
  purchase_one_time: 'Purchased AI Token',
  usage: 'AI Token daily usage',
  auto_charge: 'Auto-charge AI Token',
};

const columns: HeadersTypes<AiCreditUsageHistory> = [
  {
    accesor: 'transactionType',
    isSortable: true,
    renderHeader: () => 'Transaction type',
    render: (row) => {
      return <>{mapTransactionTypeLabel[row.transactionType]}</>;
    },
  },
  {
    accesor: 'amount',
    isSortable: true,
    renderHeader: () => 'Amount',
    render: (row) => {
      return (
        <span
          className={
            row.amount > 0 ? 'text-success-hover' : 'text-danger-hover'
          }
        >
          {row.amount > 0 ? '+' : '-'}
          {Math.abs(row.amount).toLocaleString()} AI Tokens
        </span>
      );
    },
  },
  {
    accesor: 'price',
    isSortable: true,
    renderHeader: () => 'Price',
    render: (row) => {
      return <>{row.price ? `$${row.price}` : '-'}</>;
    },
  },
  {
    accesor: 'transactionDate',
    isSortable: true,
    renderHeader: () => 'Transaction date',
    render: (row) => {
      return <>{formatDate2(row.transactionDate)}</>;
    },
  },
];

export default function PlanManagement() {
  const queryParams = useQuerySearchParams();

  const subSection = queryParams.get('sub_section');

  if (subSection === 'ai-token-usage-history') {
    return <AITokenUsage />;
  }

  return <PlanManagementDefault />;
}

function PlanManagementDefault() {
  const [showPlan, setShowPlan] = useState<
    | (CompanyPricing & {
        pricingModule: PricingApp;
      })
    | null
  >(null);

  const { data } = useCompanyPricings();

  return (
    <>
      <AICreditsCard />
      <div className="text-sub-heading font-medium mt-5 mb-4">ACTIVE PLAN</div>
      {showPlan && (
        <PlanDetailModal
          onHide={() => setShowPlan(null)}
          companyPricing={showPlan}
        />
      )}
      {data && (
        <BBBCard>
          {data.map((companyPricing) => (
            <PlanList
              onClick={() => setShowPlan(companyPricing)}
              key={companyPricing.app}
              companyPricing={companyPricing}
            />
          ))}
        </BBBCard>
      )}
    </>
  );
}

function AICreditsCard() {
  const isMobile = useResponsive('sm');
  const [showTopupModal, setShowTopupModal] = useState(false);

  const { data } = usePricingByApp('BITCHAT');

  const aiCreditsBalance = data?.aiCreditBalance || 0;

  const { mutate: updateAutoChargeSetings } = useUpdateAutoChargeSettings();

  return (
    <>
      {showTopupModal && <TopupModal onHide={() => setShowTopupModal(false)} />}
      <BBBCard
        title="AI Token Balance"
        titleClassName="lg:font-normal font-semibold"
        footer={
          <div className="flex items-center gap-2">
            <span className="font-semibold">Usage History</span>
            <ArrowRight />
          </div>
        }
        footerClassName="cursor-pointer"
        footerLink="/settings?section=plan-management&sub_section=ai-token-usage-history"
      >
        <div className="flex items-center mb-5">
          <div className="grow">
            <AiCredit value={aiCreditsBalance} className="mb-3 ml-2" />
            <div className="text-sub-heading">
              AI Tokens can be used to access AI features in bitChat.
            </div>
          </div>
          <BBBButton
            variant="secondary"
            size={isMobile ? 'sm' : 'md'}
            onClick={() => setShowTopupModal(true)}
            disabled={!!data?.aiCreditAutoCharge}
          >
            Top-up AI Token
          </BBBButton>
        </div>
        <BBBPrimarySwitch
          label="Enable auto-charge token"
          description="Automatically add 1,000 AI token when your balance rans out"
          checked={data?.aiCreditAutoCharge}
          onChange={(value) => {
            updateAutoChargeSetings({ enabled: !!value });
          }}
        />
      </BBBCard>
    </>
  );
}

function PlanDetailModal({
  companyPricing,
  onHide,
}: {
  companyPricing: CompanyPricing & {
    pricingModule: PricingApp;
  };
  onHide: () => void;
}) {
  return (
    <BBBModal
      show
      title={appNameFromType[companyPricing.app]}
      subtitle={
        companyPricing.type === 'EVERY_30_DAYS'
          ? 'Recurring monthly payment'
          : 'Recurring annual payment'
      }
      onHide={onHide}
    >
      <div className="text-sub-heading mb-2">Date subscribed</div>
      <div className="text-neutral-900 mb-5">May 30, 2024</div>
      <div className="text-sub-heading mb-2">Plan type</div>
      <div className="text-neutral-900 mb-5">
        {companyPricing.pricingModule.label} Plan,{' '}
        {mapPlanTypeText[companyPricing.type]}
      </div>
      <div className="text-sub-heading mb-2">Amount</div>
      <div className="text-neutral-900 mb-5">
        {companyPricing.pricingName === 'enterprise'
          ? 'Custom'
          : companyPricing.pricingName === 'free' ||
            companyPricing.pricingName === 'freeWithUsageCharge'
          ? 'Free'
          : `${companyPricing.pricingModule.price}/month`}
      </div>
      <div className="text-sub-heading mb-2">Status</div>
      <div className="text-neutral-900 flex items-center gap-2.5">
        {companyPricing.status === 'frozen' ||
        companyPricing.status === 'cancelled' ? (
          <>
            Expires {formatDate2(companyPricing.lastBillingCycle)}{' '}
            <BBBTooltip
              show
              content="Your plan will expire on this date if not renewed."
              position="right"
            >
              <InfoIcon />
            </BBBTooltip>
          </>
        ) : (
          <>Renews {formatDate2(companyPricing.lastBillingCycle)} </>
        )}
      </div>

      {companyPricing.pricingName === 'free' && (
        <Link to={`/pricing?tab=${mapAppTypeToUrl[companyPricing.app]}`}>
          <BBBButton className="mt-5" width="full">
            Upgrade now
          </BBBButton>
        </Link>
      )}
    </BBBModal>
  );
}

const mapPlanTypeText: Record<PlanType, string> = {
  ANNUAL: 'annual',
  EVERY_30_DAYS: 'monthly',
};

function PlanList({
  onClick,
  companyPricing,
}: {
  onClick: () => void;
  companyPricing: CompanyPricing & {
    pricingModule: PricingApp;
  };
}) {
  return (
    <div
      className="flex items-center gap-5 pb-3 mb-3 last:mb-0 border-b border-neutral-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="grow">
        <div className="text-xl font-semibold mb-1">
          {appNameFromType[companyPricing.app]}
        </div>
        <div className="text-sub-heading mb-2">
          {companyPricing.pricingModule.label} plan,{' '}
          {mapPlanTypeText[companyPricing.type]}
        </div>
        <div className="text-neutral-900">
          Renews {formatDate2(companyPricing.lastBillingCycle)}
        </div>
      </div>
      <div className="text-sub-heading text-xl">
        {companyPricing.pricingName === 'enterprise'
          ? 'Custom'
          : companyPricing.pricingName === 'free'
          ? 'Free'
          : `$${companyPricing.pricingModule.price}`}
      </div>
      <ChevronRight className="text-sub-heading" />
    </div>
  );
}

const defaultAiUsageHistory: AiCreditUsageHistory[] = [];

function AITokenUsage() {
  const [after, setAfter] = useState<string>();
  const [before, setBefore] = useState<string>();
  const [pageSize, setPageSize] = useState(25);

  const { data } = useAiCreditsUsageHistory({ limit: pageSize, after, before });

  const aiUsageHistory = data?.data || defaultAiUsageHistory;

  const meta = data?.meta;

  return (
    <BBBTableV2
      headers={columns}
      dataId="id"
      data={aiUsageHistory}
      isPaginate
      pagination={{
        hasNext: meta?.hasNextPage,
        hasPrev: meta?.hasPreviousPage,
        onNext: () => setAfter(meta?.endCursor),
        onPrev: () => setBefore(meta?.startCursor),
      }}
      isShowLimit
      limitValue={pageSize}
      onLimitChange={(page) => {
        setPageSize(page!);
        setAfter(undefined);
        setBefore(undefined);
      }}
    />
  );
}

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="9"
      fill="none"
      viewBox="0 0 14 9"
    >
      <path
        fill="#1E1E1E"
        fillRule="evenodd"
        d="M0 4.5a.5.5 0 00.5.5h11.793L9.146 8.146a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 10-.708.708L12.293 4H.5a.5.5 0 00-.5.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

const topupLists = [
  { value: 1000, price: 10 },
  { value: 5000, price: 49 },
  { value: 25000, price: 240 },
  { value: 100000, price: 950 },
];

function TopupModal({ onHide }: { onHide: () => void }) {
  const [loadTopup, setLoadTopup] = useState(false);

  const [selected, setSelected] = useState<{
    value: number;
    price: number;
  } | null>(null);

  const activeCompany = useActiveCompany();

  const { data: shopifyIntegrationData } =
    useShopifyIntegrationByApp('BITCHAT');

  const queryClient = useQueryClient();

  useEffect(() => {
    socketPricing.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  useEffect(() => {
    function onTopupCreditSucces() {
      setLoadTopup(false);
      queryClient.invalidateQueries(['pricing-v2']);
      toast.success('Sucesfully topup ai credits');
      onHide();
    }

    socketPricing.on('topup-auto-credit-success', onTopupCreditSucces);

    return () => {
      socketPricing.off('topup-auto-credit-success', onTopupCreditSucces);
    };
  }, [onHide, queryClient]);

  if (loadTopup) {
    return (
      <BBBModal title="AI token top-up" show>
        <div className="h-56 flex flex-col gap-4 justify-center items-center">
          <div>
            <BBBSpinner height={16} />
          </div>
          <div>Waiting for payment</div>
        </div>
      </BBBModal>
    );
  }

  return (
    <BBBModal
      show
      title="Select AI Token amount to top-up"
      footer
      withoutCancel
      submitText="Continue to payment"
      submitHref={`${pricingApiBaseUrl}/pricing/company/${activeCompany}/topup-ai-credits?amount=${selected?.value}&domain=${shopifyIntegrationData?.domain}`}
      onHide={onHide}
      disableSave={!shopifyIntegrationData || !selected}
      handleSave={() => {
        setLoadTopup(true);
      }}
    >
      {topupLists.map((topup) => (
        <div
          key={topup.value}
          className={cn(
            'mb-5 last:mb-0 rounded-lg p-4 outline outline-transparent outline-1 border border-neutral-30 hover:border-neutral-500 transition-colors cursor-pointer flex items-center',
            selected?.value === topup.value &&
              'border-secondary-main shadow outline-secondary-surface'
          )}
          onClick={() => setSelected(topup)}
        >
          <div className="grow">
            <AiCredit value={topup.value} withLabel className="mb-0 text-xl" />
          </div>
          <div>${topup.price}</div>
        </div>
      ))}
    </BBBModal>
  );
}
