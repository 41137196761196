import { twMerge as cx } from 'tailwind-merge';
import RoleBadge from '../../RoleBadge';

import UserProfilePicture from '@/components/Auth/UserProfilePicture';
import { BBBSpinner } from '@/components/ui';
import useCancelInvite from '@/hooks/auth/useCancelInvite';
import useResendInvitation from '@/hooks/auth/useResendInvitation';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { formatUserDisplayName } from '@/utils/auth';
import { formatDate2 } from '@/utils/common/date';

function UserList({
  role,
  status,
  disabled,
  user,
  createdAt,
  onClick,
  receivedInvitations,
}: UserCompanyWithAssociation & {
  disabled?: boolean;
  onClick: (id: number) => void;
}) {
  const toggleConfirmation = useConfirmationModal();
  const { mutate: resendInvitation, isLoading: isLoadingInvitePeople } =
    useResendInvitation();
  const { mutate: cancelInvite } = useCancelInvite();

  return (
    <div
      className={cx(
        'flex justify-between items-center mb-6 last:mb-0',
        disabled ? 'pointer-events-none' : ''
      )}
      key={user.id}
    >
      <div className="flex gap-3.5 items-center">
        {user && <UserProfilePicture {...user} size={36} />}
        <div className="flex justify-between flex-col">
          <div
            className="hover:underline cursor-pointer"
            onClick={() => onClick(user.id)}
          >
            {status === 'PENDING' ? user.email : formatUserDisplayName(user)}
          </div>
          <div className="text-xs text-neutral-40 mt-0.5">
            {status === 'ACTIVE' && <>Joined on {formatDate2(createdAt)}</>}
            {status === 'PENDING' &&
              (!receivedInvitations.length ? (
                <>
                  Invitation expired.{' '}
                  {isLoadingInvitePeople ? (
                    <BBBSpinner width={2} height={10} />
                  ) : (
                    <>
                      <span
                        className="text-info-main cursor-pointer"
                        onClick={() => {
                          resendInvitation({
                            userId: user.id,
                          });
                        }}
                      >
                        Resend invitation
                      </span>{' '}
                      or{' '}
                      <span
                        className="text-danger-main cursor-pointer"
                        onClick={() => {
                          toggleConfirmation({
                            title: 'Cancel invite',
                            description: `Are you sure to cancel invite ${user.email}
                from your team? Your action cannot 
                be undone.`,
                            size: 'md',
                            submitText: 'Remove',
                            deleteModal: true,
                            onAccept: () => cancelInvite({ userId: user.id }),
                          });
                        }}
                      >
                        Remove user
                      </span>
                      {'.'}
                    </>
                  )}
                </>
              ) : (
                <>Invitation pending</>
              ))}
          </div>
        </div>
      </div>
      <RoleBadge role={role} />
    </div>
  );
}

export default UserList;
