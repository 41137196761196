import React from 'react';

export default function LineTextIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6666 10C24.6666 10.9205 23.9204 11.6667 22.9999 11.6667L9.66659 11.6667C8.74611 11.6667 7.99992 10.9205 7.99992 10C7.99992 9.07955 8.74611 8.33335 9.66659 8.33335L22.9999 8.33335C23.9204 8.33335 24.6666 9.07955 24.6666 10Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3333 10C17.2537 10 17.9999 10.7462 17.9999 11.6667V25C17.9999 25.9205 17.2537 26.6667 16.3333 26.6667C15.4128 26.6667 14.6666 25.9205 14.6666 25V11.6667C14.6666 10.7462 15.4128 10 16.3333 10Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9999 6.66669C30.9204 6.66669 31.6666 7.41288 31.6666 8.33335V26.6667C31.6666 27.5872 30.9204 28.3334 29.9999 28.3334C29.0794 28.3334 28.3333 27.5872 28.3333 26.6667L28.3333 8.33335C28.3333 7.41288 29.0794 6.66669 29.9999 6.66669Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 31.6667C3.33325 30.7462 4.07944 30 4.99992 30L34.9999 30C35.9204 30 36.6666 30.7462 36.6666 31.6667C36.6666 32.5872 35.9204 33.3333 34.9999 33.3333L4.99992 33.3334C4.07944 33.3334 3.33325 32.5872 3.33325 31.6667Z"
        fill="black"
      />
    </svg>
  );
}
