import FacebookIcon3 from '@/assets/icons/FacebookIcon3';
import InstagramIcon4 from '@/assets/icons/InstagramIcon4';
import MailIcon from '@/assets/icons/MailIcon';
import TiktokIcon from '@/assets/icons/TiktokIcon';
import TwitterIcon2 from '@/assets/icons/TwitterIcon2';

export const socialMeta = [
  {
    value: 'instagram',
    prefix: 'instagram.com',
    icon: InstagramIcon4,
    label: 'Instagram',
    protocol: null,
  },
  {
    value: 'tiktok',
    prefix: 'tiktok.com',
    icon: TiktokIcon,
    label: 'Tiktok',
    protocol: null,
  },
  {
    value: 'facebook',
    prefix: 'facebook.com',
    icon: FacebookIcon3,
    label: 'Facebook',
    protocol: null,
  },
  {
    value: 'x',
    prefix: 'x.com',
    icon: TwitterIcon2,
    label: 'X',
    protocol: null,
  },
  {
    value: 'email',
    icon: MailIcon,
    prefix: null,
    protocol: 'mailto:',
    label: 'Email',
  },
] as const;

export type SocialType = typeof socialMeta[number]['value'];
export type SocialValues = Partial<
  Record<SocialType, Partial<{ enabled: boolean; value: string }>>
>;
