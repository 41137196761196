import axios from 'axios';
import { intercepts } from 'utils/api';

import {
  bitAiApiV0BaseUrl,
  bitAIApiV1BaseUrl,
  bitAIApiV2BaseUrl,
  bitAIApiV2NewBaseUrl,
} from '@/constants/api';

export default {
  v0: intercepts(
    axios.create({
      baseURL: bitAiApiV0BaseUrl,
    })
  ),
  v1: intercepts(
    axios.create({
      baseURL: bitAIApiV1BaseUrl,
    })
  ),
  v2: intercepts(
    axios.create({
      baseURL: bitAIApiV2BaseUrl,
    })
  ),
  v2New: intercepts(
    axios.create({
      baseURL: bitAIApiV2NewBaseUrl,
    })
  ),
};
