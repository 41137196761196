import { useEffect, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton, BBBTextAreaInput } from '@/components/ui';
import useUpdateTicket from '@/hooks/whatsApp/useUpdateTicket';
import { Ticket } from '@/types/whatsApp/ticket';

type Props = {
  value: string;
  buttonClassName?: string;
  buttonContainerClassName?: string;
  disabled?: boolean;
} & Pick<Ticket, 'sources' | 'id'>;

function Notes({
  value,
  sources,
  id,
  buttonClassName,
  buttonContainerClassName,
  disabled,
}: Props) {
  const [notes, setNotes] = useState<string>();

  const { mutate: updateTicket, isLoading: loadingUpdateTicket } =
    useUpdateTicket();

  useEffect(() => {
    setNotes(value ?? '');
  }, [value]);

  return (
    <>
      <BBBTextAreaInput
        className={cx('text-neutral-60')}
        value={notes}
        onChange={({ target: { value } }) => {
          setNotes(value);
        }}
        placeholder="Add new ticket notes"
        disabled={disabled}
      />
      {(value ?? '') !== notes && (
        <div className={buttonContainerClassName}>
          <BBBButton
            text="Save notes"
            onClick={() =>
              updateTicket({
                notes,
                source: sources,
                ticketId: id,
              })
            }
            loadingState={loadingUpdateTicket}
            className={buttonClassName}
          />
        </div>
      )}
    </>
  );
}

export default Notes;
