import { ApiResponse } from 'api/types';
import api from 'config/api';
import { AutomationNew } from 'types/bitCRM/automation-new';
import { AutomationNewNode } from 'types/bitCRM/automation-new';
import { DeepPartial } from 'types/utils/deepPartial';

export default {
  getFlows: (companyId: number, params?: { search?: string }) =>
    api.bitCRM.v2
      .get<ApiResponse<AutomationNew[]>>(`/automation-new/${companyId}`, {
        params,
      })
      .then((res) => res.data.data),
  getFlowById: (companyId: number, id: string) =>
    api.bitCRM.v2
      .get<ApiResponse<AutomationNew>>(`/automation-new/${companyId}/${id}`)
      .then((res) => res.data.data),
  deleteFlow: (companyId: number, id: string) =>
    api.bitCRM.v2.delete(`/automation-new/${companyId}/${id}`),
  duplicateFlow: (companyId: number, id: string) =>
    api.bitCRM.v2.post<ApiResponse<AutomationNew>>(
      `/automation-new/${companyId}/${id}/duplicate`
    ),
  updateFlow: (payload: DeepPartial<AutomationNew>) =>
    api.bitCRM.v2
      .post<ApiResponse<AutomationNew>>(`/automation-new`, payload)
      .then((res) => res.data.data),
  toggleFlow: (
    companyId: number,
    { id, ...payload }: Pick<AutomationNew, 'id' | 'status'>
  ) =>
    api.bitCRM.v2
      .put<ApiResponse<AutomationNew>>(
        `/automation-new/${companyId}/${id}`,
        payload
      )
      .then((res) => res.data.data),
  checkNewAutomation: (companyId: number) =>
    api.bitCRM.v2
      .get<
        ApiResponse<
          (AutomationNewNode & {
            automation: AutomationNew;
          })[]
        >
      >(`/automation-new/${companyId}/check-new-automation`)
      .then((res) => res.data.data),
};
