import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import services from '@/api/services';
import {
  GetUserCompaniesByUserIdParam,
  GetUserCompaniesByUserIdPayload,
} from '@/api/services/systemLogin';

const useUserCompanies = (
  userId: number,
  param?: GetUserCompaniesByUserIdParam,
  {
    enabled,
    ...options
  }: Omit<
    UseInfiniteQueryOptions<
      GetUserCompaniesByUserIdPayload,
      unknown,
      GetUserCompaniesByUserIdPayload,
      GetUserCompaniesByUserIdPayload,
      (string | number | GetUserCompaniesByUserIdParam | undefined)[]
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  > = {}
) =>
  useInfiniteQuery(
    ['user-companies', userId, param],
    ({ pageParam }) =>
      services.systemLogin.getUserCompaniesByUserId(userId, {
        after: pageParam,
        search: param?.search,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
      enabled: !!userId && enabled,
      ...options,
    }
  );

export default useUserCompanies;
