import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';

const useTransferOwnership = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useMutation(
    async ({ userId, password }: { userId: number; password: string }) => {
      const data = await api.systemLogin.post(
        `/company/${activeCompany}/transfer`,
        {
          userId,
          password,
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['company-users']);
        client.invalidateQueries(['user-company']);
      },
      meta: {
        disableToast: true,
      },
    }
  );
};

export default useTransferOwnership;
