import axios from 'axios';

import { integrationsApiBaseUrl } from '@/constants/api';
import { intercepts } from '@/utils/api';

export default intercepts(
  axios.create({
    baseURL: integrationsApiBaseUrl,
  })
);
