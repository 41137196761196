import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { UpsertModelPayload } from '@/api/services/bitAi/v2-new/model';
import useHistory from '@/hooks/common/useHistory';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useUpsertModel = () => {
  const activeCompany = useActiveCompany();

  const history = useHistory();
  const client = useQueryClient();

  return useMutation(
    (data: UpsertModelPayload) =>
      services.bitAi.v2New.model.upsertModel(activeCompany, data),
    {
      onSuccess: () => {
        toast.success('Model has been saved!');
        client.invalidateQueries(['bitai-sorting-models']);
        setTimeout(() => {
          client.invalidateQueries(['pricing-v2']);
          client.invalidateQueries(['pricing-feature-v2']);
          client.invalidateQueries(['pricing-features-v2']);
        }, 1000);
        history.push('/bitai/model');
      },
    }
  );
};

export default useUpsertModel;
