import { Image } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { CIRCLE_IMAGE_GRIDS } from '@/constants/bitApp/designPresets';
import useCollection from '@/hooks/bitApp/shopify/useCollection';
import { SectionAssociation } from '@/types/bitApp/association';
import { formatCurrency } from '@/utils/common/currency';

type Props = {
  data: SectionAssociation['collections'];
};

const ITEMS_PLACEHOLDER = [
  {
    title: 'Leather Jacket - Brown',
    price: 80,
    discount: 20,
  },
  {
    title: 'Men collared shirt - White',
    price: 120,
    discount: 20,
  },
  {
    title: 'Flat shoes - Silver',
    price: 20,
    discount: 20,
  },
  {
    title: 'Emame Gold rings 3 set',
    price: 10,
    discount: 20,
  },
  {
    title: 'Leather Bags - Black',
    price: 120,
    discount: 40,
  },
  {
    title: 'Snapback Hat - White',
    price: 40,
    discount: 10,
  },
];

export default function Collections({ data }: Props) {
  return (
    <_Collections
      title={data?.title}
      id={data?.idCollection?.toString() as string}
      limit={data?.displayedNumber as number}
      idSection={data?.id?.toString() as string}
    />
  );
}

function _Collections({
  title,
  id,
  limit,
  idSection,
}: {
  title: string | null | undefined;
  id: string;
  limit: number;
  idSection?: string;
}) {
  const { data: collectionShopifyData, isInitialLoading } = useCollection(
    id,
    limit ?? 6
  );

  const isHasProduct = !!collectionShopifyData?.products.edges.length || false;

  if (isInitialLoading) return <Skeleton />;

  return (
    <div className="gap-2 max-w-full bg-neutral-20 rounded-xl">
      {((title || collectionShopifyData) && (
        <>
          <div className="px-[1.85rem] flex justify-between items-center mb-2 pt-6">
            <div className="font-bold text-primary-main">{title}</div>
            <p className="text-neutral-50 text-[10px] underline">Show all</p>
          </div>
          <div className="pb-6 flex gap-4 max-w-full overflow-auto scrollbar-hide px-[1.85rem] ">
            {isHasProduct ? (
              collectionShopifyData?.products?.edges
                .map((edge) => edge.node)
                .map((item) => (
                  <div
                    className="flex flex-col gap-1 overflow-hidden min-w-[4.875rem]"
                    key={item.id}
                  >
                    {item.featuredImage ? (
                      <img
                        src={item.featuredImage?.url}
                        className="w-20 h-20 flex items-center justify-center border border-neutral-30 bg-neutral-10 rounded object-cover"
                        alt={item.id}
                        loading="lazy"
                      />
                    ) : (
                      <Image color="rgba(0,0,0,0.5)" width={72} height={72} />
                    )}
                    <div className="text-[8px] text-neutral-60 break-words line-clamp-2 mt-1">
                      {item.title}
                    </div>
                    <p className="text-[9px] font-bold text-neutral-60 ">
                      {formatCurrency(
                        parseInt(item.priceRange?.minVariantPrice.amount),
                        item.priceRange?.minVariantPrice.currencyCode
                      )}
                    </p>
                    <p className="text-[7px] text-neutral-40 line-through">
                      {formatCurrency(
                        parseInt(item.priceRange?.maxVariantPrice.amount),
                        item.priceRange?.maxVariantPrice.currencyCode
                      )}
                    </p>
                  </div>
                ))
            ) : (
              <ProductsPlaceholder />
            )}
          </div>
        </>
      )) || <CollectionPlaceholder isHovered={!!idSection} />}
    </div>
  );
}

export function CollectionPlaceholder({
  isHovered = false,
}: {
  isHovered?: boolean;
}) {
  return (
    <div className="relative w-full h-full transition-all group">
      <div className="opacity-100 group-hover:opacity-0 transition-all z-0">
        <div className="w-full bg-neutral-20 rounded-lg px-[1.85rem] py-6">
          <div className="flex flex-col gap-3.5 overflow-hidden">
            <div className="w-full flex justify-between items-center">
              <p className="text-neutral-50 font-bold uppercase">Title</p>
              <p className="text-xs text-neutral-50 underline">Show all</p>
            </div>
            <div className="flex gap-4 items-center w-[7.75rem]">
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-1 min-w-[4.875rem]"
                >
                  <div className="w-20 h-20 flex items-center justify-center border border-neutral-30 bg-neutral-10 rounded">
                    <BBBThumbnail
                      className="opacity-50"
                      width={42}
                      height={21}
                    />
                  </div>
                  <div className="w-16 h-3.5 bg-neutral-30" />
                  <div className="w-8 h-3.5 bg-neutral-30" />
                  <div className="w-4 h-3.5 bg-neutral-30" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'overflow-clip rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-200 absolute transition-1/2 top-0 left-0 w-full h-full z-10',
          isHovered ? 'opacity-100' : 'opacity-0'
        )}
      >
        <div className="w-full bg-neutral-20 rounded-lg px-[1.85rem] py-6">
          <div className="flex flex-col gap-3.5 overflow-hidden">
            <div className="w-full flex justify-between items-center">
              <p className="text-primary-main font-bold">Best Seller</p>
              <p className="text-xs text-neutral-50 underline">Show all</p>
            </div>
            <ProductsPlaceholder />
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductsPlaceholder() {
  return (
    <div className="flex gap-4 items-center">
      {CIRCLE_IMAGE_GRIDS.map((item, index) => (
        <div key={index} className="flex flex-col gap-1 min-w-[4.875rem]">
          <img
            className="w-20 h-20 flex items-center justify-center border border-neutral-30 bg-neutral-10 rounded object-cover"
            src={item.image}
          />

          <p className="text-[8px] text-neutral-60 break-words">
            {ITEMS_PLACEHOLDER[index].title}
          </p>
          <p className="text-[9px] font-bold text-neutral-60 ">
            {formatCurrency(ITEMS_PLACEHOLDER[index].price, 'USD')}
          </p>
          <p className="text-[7px] text-neutral-40 line-through">
            {formatCurrency(ITEMS_PLACEHOLDER[index].discount, 'USD')}
          </p>
        </div>
      ))}
    </div>
  );
}
