function SentIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M2.867 1.585l12.04 5.758a.715.715 0 010 1.289L2.867 14.39a.715.715 0 01-.998-.832l1.205-4.416a.286.286 0 01.237-.208l5.834-.792a.143.143 0 00.112-.086l.01-.035a.143.143 0 00-.09-.154l-.031-.008-5.829-.793a.286.286 0 01-.236-.208l-1.212-4.44a.714.714 0 01.998-.833z"
      ></path>
    </svg>
  );
}

export default SentIcon2;
