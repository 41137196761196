import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import getCollection from 'graphql/query/getCollection';
import { ShopifyCollection } from 'types/shopify/products';
import { generateShopifyId } from 'utils/bitApp';
import useGraphQLRequest from '../useGraphQLRequest';

const useCollection = (
  id: string | null | undefined,
  limit = 10,
  options?: Omit<
    UseQueryOptions<ShopifyCollection, unknown, ShopifyCollection, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const { request, enabled } = useGraphQLRequest();

  return useQuery<ShopifyCollection>(
    ['bitapp-shopify-collections', id],
    async () => {
      const data = await request({
        query: getCollection,
        variables: {
          id: generateShopifyId('Collection', id),
          first: limit ?? 10,
        },
      });

      return data?.collection;
    },
    {
      enabled: !!enabled && !!id,
      ...options,
    }
  );
};

export default useCollection;
