import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import services from '@/api/services';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useDeleteModel = () => {
  const activeCompany = useActiveCompany();
  const toggleConfirmation = useConfirmationModal();
  const client = useQueryClient();

  const deleteModelMutation = useMutation(
    ({ modelId }: { modelId: string }) =>
      services.bitAi.v2New.model.deleteModel(activeCompany, modelId),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your model has been deleted`);
        client.invalidateQueries(['bitai-sorting-models']);
      },
    }
  );

  function deleteModel(withConfirmation: true): (param: string) => void;
  function deleteModel(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { modelId: string }, unknown>;
  function deleteModel(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, { modelId: string }, unknown> {
    return withConfirmation
      ? (modelId) =>
          toggleConfirmation({
            title: 'Delete model?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteModelMutation.mutate(
                {
                  modelId,
                },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteModelMutation;
  }

  return deleteModel;
};

export default useDeleteModel;
