import { forwardRef, useEffect, useState } from 'react';
import { Trash2 } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import CopyIcon from '@/assets/icons/CopyIcon';
import ReplyIcon from '@/assets/icons/ReplyIcon';
import StarIcon2 from '@/assets/icons/StarIcon2';
import StarIcon3 from '@/assets/icons/StarIcon3';
import { BBBCard, BBBTooltip } from '@/components/ui';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppDispatch } from '@/hooks/rtk/store';
import useDeleteMessage from '@/hooks/whatsApp/useDeleteMessage';
import useUpdateMessage from '@/hooks/whatsApp/useUpdateMessage';
import { upsertLivechatMemo } from '@/stores/bitCRM';
import { MessageNewAssociation } from '@/types/whatsApp/v3';

export default function ChatBubbleAction({
  chat,
}: {
  chat: MessageNewAssociation;
}) {
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const bubblesWrapper = document.getElementById('chat-bubbles');

    if (bubblesWrapper) {
      bubblesWrapper.style.pointerEvents = showActions ? 'none' : 'auto';
    }
  }, [showActions]);

  return (
    <Actions
      className="group-hover:opacity-100 opacity-0"
      onClose={() => setShowActions(false)}
      {...chat}
    />
  );
}

const Actions = forwardRef<
  HTMLDivElement,
  {
    onClose: () => void;
    className?: string;
  } & MessageNewAssociation
>(({ onClose, className, ...chat }, ref) => {
  const dispatch = useAppDispatch();
  const toggleConfirmation = useConfirmationModal();
  const [hover, setHover] = useState<string | undefined>();

  const fromMe = chat.fromMe;
  const chatId = chat.messageId;

  const { mutate: updateMessage } = useUpdateMessage();
  const { mutate: deleteMessage } = useDeleteMessage();

  return (
    <BBBCard
      className={cx(
        'md:p-1.5 p-1.5 flex justify-center items-center gap-1.5 absolute -top-6 z-50 rounded-md border-[1.3px]',
        fromMe ? 'left-3' : 'right-3',
        className
      )}
    >
      <BBBTooltip show content="Reply">
        <div
          className="white-space-nowrap"
          onClick={() => {
            dispatch(
              upsertLivechatMemo({
                selectedReply: chat,
              })
            );
            onClose();
          }}
          onMouseEnter={() => setHover('reply')}
          onMouseLeave={() => setHover(undefined)}
        >
          <ReplyIcon color={hover === 'reply' ? '#FF7C11' : '#9A9A9A'} />
        </div>
      </BBBTooltip>
      <BBBTooltip
        show
        content={`${!chat.isStarred ? 'Star' : 'Unstar'} message`}
      >
        <div
          className="white-space-nowrap"
          onClick={() => {
            updateMessage({
              isStarred: !chat.isStarred,
              messageId: chatId,
            });
            onClose();
          }}
          onMouseEnter={() => setHover('star')}
          onMouseLeave={() => setHover(undefined)}
        >
          {chat.isStarred ? (
            <StarIcon2
              size={16}
              color={hover === 'star' ? '#FF7C11' : '#9A9A9A'}
            />
          ) : (
            <StarIcon3 color={hover === 'star' ? '#FF7C11' : '#9A9A9A'} />
          )}
        </div>
      </BBBTooltip>
      <BBBTooltip show content="Copy">
        <div
          className="white-space-nowrap"
          onClick={() => {
            navigator.clipboard.writeText(chat.body || '');
            onClose();
          }}
          onMouseEnter={() => setHover('copy')}
          onMouseLeave={() => setHover(undefined)}
        >
          <CopyIcon
            size={16}
            color={hover === 'copy' ? '#FF7C11' : '#9A9A9A'}
          />
        </div>
      </BBBTooltip>
      {true && (
        <BBBTooltip show content="Delete">
          <div
            className="white-space-nowrap"
            onClick={() => {
              toggleConfirmation({
                description: `Once deleted you're not able to recover it.`,
                title: `Delete section?`,
                onAccept: (hide) => {
                  deleteMessage({ serialized: chatId });
                  hide();
                },
                deleteModal: true,
                submitText: 'Delete',
                cancelText: 'Cancel',
              });
              onClose();
            }}
            onMouseEnter={() => setHover('delete')}
            onMouseLeave={() => setHover(undefined)}
          >
            <Trash2
              size={16}
              color={hover === 'delete' ? '#FF5046' : '#9A9A9A'}
            />
          </div>
        </BBBTooltip>
      )}
    </BBBCard>
  );
});

Actions.displayName = 'Actions';
