import RegisterCard, {
  RegisterFormSchema,
} from '@/components/Auth/RegisterCard';
import { SocialLoginPayload } from '@/hooks/auth/useLogin';
import useRegister from '@/hooks/auth/useRegister';
import { formatPhonePayload } from '@/utils/common/phone';

const Register = () => {
  const { mutate: registerAccount, isLoading: loading } = useRegister();

  const onSubmit = (payload: RegisterFormSchema | SocialLoginPayload) => {
    if ('provider' in payload) {
      registerAccount(payload);
    } else {
      registerAccount({
        ...payload,
        phone: formatPhonePayload(payload.phone),
      });
    }
  };

  return <RegisterCard onSubmit={onSubmit} loadingSave={loading} />;
};

export default Register;
