import { useCallback, useEffect, useState } from 'react';
import { RiPencilLine } from 'react-icons/ri';

import { BBBButton, BBBTextAreaInput } from '@/components/ui';
import { useUpdateNotes } from '@/hooks/customers/customer/update-customer';
import { Customer } from '@/types/customers';

type Props = Pick<Customer, 'id'> & {
  containerClassName?: string;
  disabled?: boolean;
  withoutLabel?: boolean;
  withoutSaveButton?: boolean;
  notes?: string | null;
};

function Notes({
  notes,
  id,
  containerClassName,
  disabled,
  withoutLabel,
  withoutSaveButton,
}: Props) {
  const [_notes, setNotes] = useState('');

  useEffect(() => {
    setNotes(notes ?? '');
  }, [notes]);

  const { mutate: updateNotes, isLoading: loadingUpdateNotes } =
    useUpdateNotes();

  const sendRequest = useCallback(
    (value: string) => {
      updateNotes({
        id,
        notes: value,
      });
    },
    [updateNotes, id]
  );

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendRequest(_notes);
    }
  };

  return (
    <div className={containerClassName}>
      <BBBTextAreaInput
        placeholder="Add customer notes"
        value={_notes}
        onChange={({ target: { value } }) => {
          setNotes(value);
        }}
        onKeyDown={handleEnter}
        label={
          !withoutLabel && (
            <div className="flex items-center gap-1 text-neutral-60">
              <p className="">Notes</p>
              <RiPencilLine />
            </div>
          )
        }
        maxLength={300}
        disabled={disabled}
      />
      {(notes || '') !== _notes && !withoutSaveButton && (
        <BBBButton
          text="Save notes"
          size="sm"
          onClick={() => sendRequest(_notes)}
          loadingState={loadingUpdateNotes}
        />
      )}
    </div>
  );
}

export default Notes;
