import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import services from 'api/services';
import { ToggleCompanyIntegrationPayload } from 'api/services/bitChat/integrations';
import { useActiveCompany, useUserId } from 'hooks/rtk/selector';
import {
  CompanyIntegrationAssociation,
  IntegrationExtra,
  IntegrationName,
} from 'types/bitChat/integrations';
import { toast } from 'utils/common/toast';

export const useIntegrations = () =>
  useQuery(['bitchat-integrations'], () =>
    services.bitChat.integrations.getIntegrations()
  );

export const useCompanyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation[],
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    { enabled, ...options }
  );
};

export const useWhatsappBusinessIntegration = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | null | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'whatsapp'),
      ...options,
    }
  );
};

export const useWhatsappCloudIntegration = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | null | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'whatsapp_meta'),
      ...options,
    }
  );
};

export const useShopifyIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'shopify'),
      ...options,
    }
  );
};

export const useFacebookIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'facebook'),
      ...options,
    }
  );
};

export const useInstagramIntegrations = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    CompanyIntegrationAssociation | undefined,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();
  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) => companyIntegrationSelector(data, 'instagram'),
      ...options,
    }
  );
};

export const useShopifyIntegrationExtra = ({
  enabled,
  ...options
}: Omit<
  UseQueryOptions<
    CompanyIntegrationAssociation[],
    unknown,
    IntegrationExtra | null,
    (string | number)[]
  >,
  'queryKey' | 'queryFn'
> = {}) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['bitchat-company-integrations', activeCompany],
    () => services.bitChat.integrations.getCompanyIntegrations(activeCompany),
    {
      enabled,
      select: (data) =>
        companyIntegrationSelector(data, 'shopify')?.extra || null,
      ...options,
    }
  );
};

export const useToggleIntegration = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const userId = useUserId();

  return useMutation(
    (data: Pick<ToggleCompanyIntegrationPayload, 'integrationId' | 'status'>) =>
      services.bitChat.integrations.toggleCompanyIntegrations(activeCompany, {
        ...data,
        userId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'bitchat-company-integrations',
          activeCompany,
        ]);
        queryClient.invalidateQueries(['bitchat-shopify', activeCompany]);
        toast.success('Integration disconnected successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['instagram-list']);
          queryClient.invalidateQueries(['facebook-list']);
          queryClient.invalidateQueries(['whatsappp-cloud-list']);
        }, 2000);
      },
    }
  );
};

export function companyIntegrationSelector(
  data: CompanyIntegrationAssociation[],
  name: IntegrationName,
  options?: { includeDisconnected?: boolean }
) {
  return (
    options?.includeDisconnected
      ? data
      : data.filter((integration) => integration.status === 'CONNECTED')
  ).find((integration) => integration.integration.name === name);
}
