import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton, BBBModal } from '@/components/ui';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setExpandedSubNav } from '@/stores/common';

type StepSpotlight = {
  target: string;
  title: ReactNode;
  content: ReactNode;
  placement?: 'top' | 'left' | 'right' | 'bottom';
  spotlightSize?: Partial<{
    height: number;
    width: number;
  }>;
  offset?: Partial<{ top: number; left: number }>;
  spotlightClassName?: string;
  spotlightColor?: string;
  clickSpotlightToNext?: boolean;
  onClickSpotlight?: () => void;
  onClickBack?: () => void;
  withoutBack?: boolean;
  timeout?: number;
  withoutPulse?: boolean;
  pulseOffset?: Partial<{
    top: number;
    left: number;
    right: number;
    bottom: number;
  }>;
  scrollOffset?: Partial<{
    top: number;
  }>;
};

type StepModal = {
  title: ReactNode;
  content: ReactNode;
  isModal: true;
  icon?: ReactNode;
  withoutSkip?: boolean;
  submitText?: string;
  onNext?: (val: number) => void;
};

export type Step = StepSpotlight | StepModal;

type Props = {
  steps: Step[];
  stepIndex: number;
  onChangeStep: (val: number) => void;
};

export default function BBBJourneyV2({
  steps,
  stepIndex,
  onChangeStep,
}: Props) {
  const currentStep = steps[stepIndex];

  /**
   * @description
   * will be remove after implement onboarding(v3) in all apps
   */
  const dispatch = useAppDispatch();
  const expandedSubNav = useAppSelector((s) => s.common.expandedSubNav);

  useEffect(() => {
    if (expandedSubNav) {
      dispatch(setExpandedSubNav(false));
    }
  }, [dispatch, expandedSubNav]);

  if ('isModal' in currentStep) {
    return (
      <BBBModal
        show
        title={
          <>
            <div className="flex justify-center mb-5">{currentStep.icon}</div>
            <div className="font-bold text-xl text-primary-main">
              {currentStep.title}
            </div>
          </>
        }
        bodyClassName="mt-1.5 mb-5 text-primary-main"
        centerBody
        centerFooter
        centerTitle
        footer
        withoutCancel={stepIndex !== 0 || currentStep.withoutSkip}
        footerClassName="items-center flex-col-reverse gap-3"
        submitText={
          currentStep.submitText
            ? currentStep.submitText
            : stepIndex === 0
            ? 'Start the quick tour'
            : 'Finish the tour'
        }
        handleSave={() => {
          onChangeStep(stepIndex + 1);
          currentStep.onNext?.(stepIndex + 1);
        }}
        cancelText={
          stepIndex === 0 && (
            <span
              className="text-sm underline cursor-pointer"
              onClick={() => {
                onChangeStep(steps.length);
              }}
            >
              Skip the tour
            </span>
          )
        }
        overrideCancelBtn
        target={
          stepIndex === 0
            ? document.getElementById('content') || undefined
            : undefined
        }
      >
        {currentStep.content}
      </BBBModal>
    );
  }

  return (
    <Journey
      steps={steps}
      stepIndex={stepIndex}
      onChangeStep={onChangeStep}
      currentStep={currentStep}
    />
  );
}

function Journey({
  currentStep,
  stepIndex,
  steps,
  onChangeStep,
}: Props & { currentStep: StepSpotlight }) {
  const [target, setTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let isMounted = true;

    const findTarget = () => {
      const element = document.querySelector(currentStep.target);
      if (element && isMounted) {
        setTarget(element as HTMLElement);
      } else if (isMounted) {
        setTimeout(findTarget, 100);
      }
    };

    findTarget();

    return () => {
      isMounted = false;
    };
  }, [currentStep.target]);

  if (!target) return null;

  return (
    <_Journey
      target={target}
      onChangeStep={onChangeStep}
      steps={steps}
      stepIndex={stepIndex}
      step={currentStep}
    />
  );
}

function _Journey({
  target,
  onChangeStep,
  step,
  steps,
  stepIndex,
}: Pick<Props, 'steps' | 'stepIndex' | 'onChangeStep'> & {
  target: Element;
  step: StepSpotlight;
}) {
  const [{ top, left, width, height }, setDimensions] = useState({
    top: target.getBoundingClientRect().top,
    left: target.getBoundingClientRect().left,
    width: target.getBoundingClientRect().width,
    height: target.getBoundingClientRect().height,
  });

  const mutationObserver = useRef<MutationObserver | null>(null);

  const updateDimensions = useCallback(() => {
    const rect = target.getBoundingClientRect();
    setDimensions({
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    });
  }, [target]);

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  useEffect(() => {
    mutationObserver.current = new MutationObserver(updateDimensions);
    mutationObserver.current.observe(document.getElementById('content')!, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      if (mutationObserver.current) {
        mutationObserver.current.disconnect();
      }
    };
  }, [updateDimensions]);

  const placement = step.placement || 'bottom';

  const stepWidthCustom = step.spotlightSize?.width ?? 0;
  const stepHeightCustom = step.spotlightSize?.height ?? 0;

  const nearestOverflowElement = getScrollParent(
    target
  ) as HTMLDivElement | null;

  const isBelowViewport =
    nearestOverflowElement &&
    (target.getBoundingClientRect().bottom -
      nearestOverflowElement.getBoundingClientRect().top >
      nearestOverflowElement.clientHeight ||
      target.getBoundingClientRect().top < nearestOverflowElement.scrollTop);

  const [isTransitioning, setTransitioning] = useState(
    step.timeout ? true : isBelowViewport
  );

  useEffect(() => {
    if (step.timeout) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
      }, step.timeout);
    }
  }, [step.timeout]);

  useEffect(() => {
    if (isBelowViewport) {
      setTransitioning(true);

      const _nearestOverflowElement = getScrollParent(
        target
      ) as HTMLDivElement | null;

      const stepTopOffset = step?.scrollOffset?.top ?? 0;

      _nearestOverflowElement?.scrollTo({
        top:
          target.getBoundingClientRect().top -
          8 -
          _nearestOverflowElement.getBoundingClientRect().top +
          stepTopOffset,
        behavior: 'smooth',
      });

      setTimeout(() => {
        setTransitioning(false);
      }, 1000);
    }
  }, [isBelowViewport, step?.scrollOffset?.top, target]);

  if (isTransitioning) return null;

  return (
    <>
      {createPortal(
        <>
          <div
            style={{
              position: 'absolute',
              inset: 0,
              background: 'rgba(0,0,0,0.25)',
              zIndex: 500,
              mixBlendMode: 'hard-light',
              overflow: 'hidden',
            }}
          >
            <motion.div
              style={{
                position: 'absolute',
                top: top - 8,
                width: width + 16 + stepWidthCustom,
                zIndex: 500,
                height: height + 16 + stepHeightCustom,
                left: left - 8,
                background: step.spotlightColor ?? 'gray',
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={stepIndex}
              className={cx(
                step.spotlightClassName,
                step.clickSpotlightToNext && 'cursor-pointer'
              )}
              onClick={
                step.clickSpotlightToNext
                  ? () => {
                      onChangeStep(stepIndex + 1);
                      step.onClickSpotlight?.();
                    }
                  : undefined
              }
            />
          </div>
          {step.clickSpotlightToNext && !step.withoutPulse && (
            <>
              <div
                className="w-[2.5rem] h-[2.5rem] flex justify-center items-center z-[9999]"
                style={{
                  position: 'absolute',
                  top: top + 16 + (step.pulseOffset?.top ?? 0),
                  left:
                    typeof width === 'number'
                      ? left + width / 2 + 36 + (step.pulseOffset?.left ?? 0)
                      : undefined,
                  ...(step.pulseOffset?.right && {
                    right: step.pulseOffset?.right,
                  }),
                  ...(step.pulseOffset?.bottom && {
                    bottom: step.pulseOffset?.bottom,
                  }),
                }}
              >
                <motion.div
                  className="flex justify-center  items-center rounded-full bg-secondary-main/20"
                  animate={{
                    opacity: [0, 1, 0],
                    width: ['0.5rem', '2.5rem'],
                    height: ['0.5rem', '2.5rem'],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                  }}
                >
                  <motion.div
                    className="flex justify-center  items-center rounded-full bg-secondary-main/25"
                    animate={{
                      opacity: [0, 1, 0],
                      width: ['0.5rem', '1.875rem'],
                      height: ['0.5rem', '1.875rem'],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 1,
                    }}
                  ></motion.div>
                </motion.div>
              </div>

              <motion.div
                style={{
                  position: 'absolute',
                  top: top + 25.5 + (step.pulseOffset?.top ?? 0),
                  left:
                    typeof width === 'number'
                      ? left + width / 2 + 45.5 + (step.pulseOffset?.left ?? 0)
                      : undefined,
                  ...(step.pulseOffset?.right && {
                    right: step.pulseOffset?.right,
                  }),
                  ...(step.pulseOffset?.bottom && {
                    bottom: step.pulseOffset?.bottom,
                  }),
                }}
                className="flex justify-center w-4.5 h-4.5 items-center rounded-full bg-secondary-main z-[9999]"
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ repeat: Infinity, duration: 1 }}
              />
            </>
          )}
          <motion.div
            style={{
              position: 'absolute',
              top:
                placement === 'top'
                  ? undefined
                  : top +
                    (placement === 'bottom' ? height + 24 : height / 2) +
                    (step.offset?.top ?? 0),
              width: 368,
              zIndex: 500,
              left:
                placement === 'right'
                  ? left + width + 24
                  : placement === 'top' || placement === 'bottom'
                  ? left + width / 2 + (step.offset?.left ?? 0)
                  : undefined,
              background: 'white',
              right:
                placement === 'left'
                  ? window.innerWidth - target.getBoundingClientRect().left + 24
                  : undefined,
              bottom:
                placement === 'top'
                  ? window.innerHeight - target.getBoundingClientRect().top + 24
                  : undefined,
              ...((placement === 'top' || placement === 'bottom') && {
                translateX: '-50%',
              }),
              ...((placement === 'right' || placement === 'left') && {
                translateY: '-50%',
              }),
            }}
            className="p-6 rounded-lg"
            key={stepIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              style={{
                position: 'absolute',
                left:
                  placement === 'top' || placement === 'bottom'
                    ? '50%'
                    : placement === 'right'
                    ? 0
                    : '100%',
                top:
                  placement === 'right' || placement === 'left'
                    ? '50%'
                    : placement === 'top'
                    ? '100%'
                    : 0,
                zIndex: 500,
                transform: 'translate(-50%, -50%)',
              }}
              key={stepIndex}
            >
              <div
                className={cx(
                  'w-0 h-0  border-white border-l-[14px] border-r-[14px] border-r-transparent border-l-transparent border-b-[19px]',
                  placement === 'right' && '-rotate-90',
                  placement === 'left' && 'rotate-90',
                  placement === 'top' && 'rotate-180'
                )}
              />
            </motion.div>
            <div className="text-center font-semibold mb-4">{step.title}</div>
            <div className="text-center mb-4">{step.content}</div>
            <div className="flex justify-between">
              {stepIndex > 0 && !step.withoutBack ? (
                <div
                  className="text-info-main underline cursor-pointer"
                  onClick={(e) => {
                    onChangeStep(stepIndex - 1);
                    step.onClickBack?.();
                    e.preventDefault();
                  }}
                >
                  Back
                </div>
              ) : (
                <div />
              )}
              {stepIndex < steps.length - 1 && !step.clickSpotlightToNext && (
                <BBBButton
                  variant="secondary"
                  size="sm"
                  text="Next"
                  onClick={() => onChangeStep(stepIndex + 1)}
                />
              )}
            </div>
          </motion.div>
        </>,
        document.body
      )}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function getScrollParent(node: Element) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return getScrollParent(node.parentNode);
  }
}
