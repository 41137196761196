import { SidebarIcon } from '@/constants/layouts/sidebarData';

function AnalyticsIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <AnalyticsIconDefault {...props} />
      ) : (
        <AnalyticsIconActive {...props} />
      )}
    </>
  );
}

function AnalyticsIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M2.592 1.667c.512 0 .926.414.926.925v12.963c0 .512.415.926.926.926h12.963a.926.926 0 110 1.852H4.444a2.778 2.778 0 01-2.777-2.778V2.592c0-.51.414-.925.925-.925z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF7C11"
        fillRule="evenodd"
        d="M13.3 4.459a.695.695 0 00.66 1.221l.38-.206-2.516 3.983L9.59 7.28l-4.384 5.338a.893.893 0 00.145 1.278.95.95 0 001.312-.142l3.082-3.752 2.431 2.368 3.96-6.266.078.768a.694.694 0 101.382-.14l-.197-1.94a1.62 1.62 0 00-2.384-1.261l-1.714.928z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function AnalyticsIconDefault({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M2.592 1.667c.512 0 .926.414.926.925v12.963c0 .512.415.926.926.926h12.963a.926.926 0 110 1.852H4.444a2.778 2.778 0 01-2.777-2.778V2.592c0-.51.414-.925.925-.925z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M13.3 4.459a.695.695 0 00.66 1.221l.38-.206-2.516 3.983L9.59 7.28l-4.384 5.338a.893.893 0 00.145 1.278.95.95 0 001.312-.142l3.082-3.752 2.431 2.368 3.96-6.266.078.768a.694.694 0 101.382-.14l-.197-1.94a1.62 1.62 0 00-2.384-1.261l-1.714.928z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AnalyticsIcon;
