import { useMutation } from '@tanstack/react-query';

import api from '@/config/api';
import { Company } from '@/types/systemLogin';

type AddUserCompanyByUserIdPayload = {
  name: string;
  industry: string;
  country: string;
  userId: number;
};

const useAddUserCompanyByUserId = () =>
  useMutation<Company, unknown, AddUserCompanyByUserIdPayload>(
    async ({ userId, ...payload }) => {
      const {
        data: { data },
      } = await api.systemLogin.post(`/user/${userId}/company`, payload);
      return data;
    }
  );

export default useAddUserCompanyByUserId;
