import { ApiResponse } from 'api/types';
import api from 'config/api';
import {
  Chat,
  WAGroupParticipantResponse,
  WhatsAppCloudAccountList,
} from 'types/whatsApp/chat';
import { IntegrationSources } from 'types/whatsApp/shape';

const chatServices = {
  transferChat: (payload: {
    key: string;
    userId: number;
    notes: string;
    number: string;
  }) => api.whatsApp.v1.put(`/ticket`, payload).then((res) => res.data),
  updateConversationTags: (
    params: {
      key?: string;
      number: string;
      tags?: string[];
    } & Pick<Chat, 'sources'>
  ) => api.whatsApp.v1.put('/conversation/tags', params),
  getChat: (
    chatId: string,
    params: {
      key: string;
    } & Pick<Chat, 'sources'>
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<Chat | null>>(`/conversation/${chatId}`, {
        params,
      })
      .then((res) => res.data.data),
  deleteChat: (
    params: {
      key: string;
      serialized: string;
      number: string;
    } & Pick<Chat, 'sources'>
  ) =>
    api.whatsApp.v1.delete(`/message`, {
      params,
    }),
  updateChat: (
    payload: {
      key: string;
      companyId: number;
      number: Chat['clientNumber'];
      sources: Chat['sources'];
    } & (
      | ({
          status: 'transfer';
          notes?: string;
          oldUser: UserPayloadPropsExist;
        } & UserPayloadPropsExist)
      | ({
          status: 'join';
        } & UserPayloadPropsExist)
      | ({
          status: 'took_over';
          oldUser: UserPayloadPropsExist;
        } & UserPayloadPropsExist)
      | ({
          status: 'close';
        } & UserPayloadPropsExist)
      | {
          action: 'unspam' | 'spam' | 'unread' | 'read';
        }
    )
  ) => api.whatsApp.v1.put(`/conversation`, payload),
  getMedia: (
    clientNumber: string,
    params: {
      id: string;
      key: string;
      mimetype: string;
    } & Pick<Chat, 'sources'>
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<string>>(`/media/${clientNumber}/message`, {
        params,
      })
      .then((res) => res.data.data),
  getProfilePicture: (
    chatId: string,
    params: {
      key: string;
      source: Chat['sources'];
    }
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<string>>(`/media/${chatId}/profile`, {
        params,
      })
      .then((res) => res.data.data),
  sendMedia: (
    params: {
      key?: string;
      number: string;
      message: string;
      imageUrl?: string;
      messageId?: string;
      companyId?: number;
      temporaryId: string;
      file?: File | null;
      mimeType?: string;
    } & Pick<Chat, 'sources'>
  ) => {
    const formData = new FormData();

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        formData.append(key, value);
      }
    });

    return api.whatsApp.v1
      .post('/send_media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data.response);
  },
  sendMessage: ({
    key,
    number,
    message,
    messageId,
    userId,
    hasMedia,
    media,
    sources,
    temporaryId,
    form,
  }: {
    key?: string;
    number: string;
    message?: string;
    messageId?: string;
    userId: number;
    official?: boolean;
    hasMedia?: boolean;
    media?: string;
    temporaryId: string;
    form?: {
      id: string;
      body: string;
      title: string;
      buttonText: string;
    };
  } & Pick<Chat, 'sources'>) =>
    api.whatsApp.v1
      .post('/send_message', {
        key,
        number,
        message,
        messageId,
        userId,
        hasMedia,
        media,
        sources,
        temporaryId,
        form,
      })
      .then((res) => res.data.response),
  getWhatsAppGroupInfo: (
    clientNumber: string,
    params: {
      key: string;
    }
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<WAGroupParticipantResponse>>(`/groups/${clientNumber}`, {
        params,
      })
      .then((res) => res.data.data),
  summarizeMessage: ({
    key,
    number,
    limit = 15,
    sources,
  }: {
    key: string;
    number: string;
    limit?: number;
  } & Pick<Chat, 'sources'>) =>
    api.whatsApp.v1
      .post<{
        data: {
          sentiment?: number;
          summary: string[];
        };
        message: string;
        prompt: string;
        status: boolean;
      }>(`/message/summarize`, {
        key,
        number,
        limit,
        sources,
      })
      .then((res) => res.data.data),
  recommendMessage: (
    params: {
      key: string;
      number: string;
      limit?: number;
      templateId?: string;
    } & Pick<Chat, 'sources'>
  ) =>
    api.whatsApp.v1
      .post<{
        conversations: unknown[];
        data: {
          recommendations: string;
          parsedTags: {
            product?: {
              id: string;
              url: string;
              title: string;
              image_url?: string;
            };
          } | null;
        };
        message: string;
      }>(`/message/recommendation`, params)
      .then((res) => res.data),
  getIntegrations: (companyId: number) =>
    api.whatsApp.v1
      .get<
        ApiResponse<{
          companyId: number;
          apiKey: string;
        } | null>
      >(`/integrations/${companyId}`)
      .then((res) => res.data.data),
  getWhatsAppCloudAccounts: (companyId: number) =>
    api.whatsApp.v1
      .get<ApiResponse<WhatsAppCloudAccountList[]>>(
        `/meta/whatsapp_cloud_api/accounts/${companyId}`
      )
      .then((res) => res.data.data),
  saveWhatsAppCloudNumber: (companyId: number, id: string) =>
    api.whatsApp.v1.post(`/meta/whatsapp_cloud_api/accounts/${companyId}`, {
      id,
    }),
  updateMessage: ({
    messageId,
    id,
    ...payload
  }: {
    isStarred: boolean;
    messageId: string;
    key?: string;
    sources: IntegrationSources;
    id: string;
  }) =>
    api.whatsApp.v1.put(`/conversation/${id}/messages/${messageId}`, payload),
};

export default chatServices;

export type TransferChatParams = Parameters<
  typeof chatServices['transferChat']
>[0];
export type UpdateConversationTagsParams = Parameters<
  typeof chatServices['updateConversationTags']
>[0];
export type GetChatParams = Parameters<typeof chatServices['getChat']>[1];
export type GetChatPayload = Awaited<
  ReturnType<typeof chatServices['getChat']>
>;
export type DeleteChatParams = Parameters<typeof chatServices['deleteChat']>[0];
export type UpdateChatParams = Parameters<typeof chatServices['updateChat']>[0];
export type GetMediaParams = Parameters<typeof chatServices['getMedia']>[0];
export type GetProfilePictureParams = Parameters<
  typeof chatServices['getProfilePicture']
>[0];
export type SendMediaParams = Parameters<typeof chatServices['sendMedia']>[0];
export type SendMediaPayload = Awaited<
  ReturnType<typeof chatServices['sendMedia']>
>;
export type SendMessageParams = Parameters<
  typeof chatServices['sendMessage']
>[0];
export type SendMessagePayload = Awaited<
  ReturnType<typeof chatServices['sendMessage']>
>;
export type GetWhatsAppGroupInfoParams = Parameters<
  typeof chatServices['getWhatsAppGroupInfo']
>[0];
export type RecommendMessageParams = Parameters<
  typeof chatServices['recommendMessage']
>[0];
export type RecommendMessagePayload = Awaited<
  ReturnType<typeof chatServices['recommendMessage']>
>;
export type UpdateMessageParams = Parameters<
  typeof chatServices['updateMessage']
>[0];
export type UserPayloadPropsExist =
  | {
      // ai agent or everyone agent
      userId: -1 | -2;
    }
  | UserPayloadPropsExistHuman;

export type UserPayloadPropsExistHuman = {
  userId: number;
  userEmail: string;
  userAccent: string | null;
  userProfilePicture: string | null;
  userDisplayName: string | null;
};

export type UserPayloadPropsNotExist = {
  userId: null;
};

export type UserPayloadProps = UserPayloadPropsExist | UserPayloadPropsNotExist;
