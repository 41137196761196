import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import BBBToast from './BBBToast';

import { useAppSelector } from '@/hooks/rtk/store';

const BBBToastContainer = () => {
  const toast = useAppSelector((state) => state.common.toasts);

  return (
    <div id="toast-root">
      <div className="absolute lg:right-4 right-2 lg:w-[460px] w-[31.25rem] h-full z-[401] overflow-hidden pointer-events-none">
        <div className={cx('relative w-full h-full')}>
          <AnimatePresence>
            {toast
              ?.filter((toast) => !toast.destroying)
              .map((item, index) => (
                <BBBToast key={item.id} {...item} index={index} />
              ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default BBBToastContainer;
