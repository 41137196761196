export default function ThunderIcon({ size = 24 }: { size?: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#FD823E"
        fillRule="evenodd"
        d="M10.135 1.5h6.713c1.611 0 2.715 1.838 1.62 3.223l-3.25 4.108H20a1 1 0 01.687 1.726L7.578 22.961l-.03.028c-1.628 1.417-4.118-.342-3.22-2.36L7 13.955H5.05c-1.499 0-2.61-1.61-1.765-3.013l5.089-8.466a2.06 2.06 0 011.762-.976zm-.01 2a.06.06 0 00-.05.026L5.012 11.95l.008.003a.109.109 0 00.029.003h3.429a1 1 0 01.928 1.372l-3.23 8.065a1.002 1.002 0 01-.028.068.09.09 0 00.023.023.1.1 0 00.037.017.124.124 0 00.017-.011L17.488 10.83h-4.337a1 1 0 01-.784-1.62l4.515-5.707h-.002a.119.119 0 00-.032-.004h-6.723z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
