import { Calendar } from 'react-feather';
import ItemCard from './ItemCard';

import GenderIcon from '@/assets/icons/GenderIcon';
import PhoneIcon2 from '@/assets/icons/PhoneIcon2';
import { BBBCard } from '@/components/ui';
import {
  useLoginBasicSetting,
  useUpsertLoginSetting,
} from '@/hooks/bitLogin/login/login-settings';

export default function BasicInformationCard() {
  const { active: phoneNumberActive, mandatory: phoneNumberRequired } =
    useLoginBasicSetting('phoneNumber');
  const { active: genderActive, mandatory: genderRequired } =
    useLoginBasicSetting('gender');
  const { active: dateOfBirthActive, mandatory: dateOfBirthRequired } =
    useLoginBasicSetting('dateOfBirth');

  const { mutate: updateLoginSetting } = useUpsertLoginSetting();
  return (
    <BBBCard title="Basic information">
      <div className="flex flex-col gap-3.5">
        <ItemCard
          icon={<PhoneIcon2 />}
          title="Phone number"
          active={phoneNumberActive || false}
          onActiveChange={(val) =>
            updateLoginSetting({
              active: val,
              type: 'phoneNumber',
              group: 'basic',
            })
          }
          hasRequired
          required={phoneNumberRequired || false}
          onRequiredChange={(val) =>
            updateLoginSetting({
              mandatory: val,
              type: 'phoneNumber',
              group: 'basic',
            })
          }
        />
        <ItemCard
          icon={<GenderIcon />}
          title="Gender"
          active={genderActive || false}
          onActiveChange={(val) =>
            updateLoginSetting({
              active: val,
              type: 'gender',
              group: 'basic',
            })
          }
          hasRequired
          required={genderRequired || false}
          onRequiredChange={(val) =>
            updateLoginSetting({
              mandatory: val,
              type: 'gender',
              group: 'basic',
            })
          }
        />
        <ItemCard
          icon={<Calendar size={18} />}
          title="Date of birth"
          active={dateOfBirthActive || false}
          onActiveChange={(val) =>
            updateLoginSetting({
              active: val,
              type: 'dateOfBirth',
              group: 'basic',
            })
          }
          hasRequired
          required={dateOfBirthRequired || false}
          onRequiredChange={(val) =>
            updateLoginSetting({
              mandatory: val,
              type: 'dateOfBirth',
              group: 'basic',
            })
          }
        />
      </div>
    </BBBCard>
  );
}
