import facebook from './facebook';
import instagram from './instagram';
import shopify from './shopify';

const integrationServices = {
  shopify,
  facebook,
  instagram,
};

export default integrationServices;
