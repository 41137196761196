import { useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { MessageNewAssociation } from '@/types/whatsApp/v3';

export default function StoryMention({
  description,
  src,
  isFromMe,
  chat,
  isSquared,
}: {
  isFromMe?: boolean;
  description: string;
  src?: string | null;
  chat: MessageNewAssociation;
  isSquared?: boolean;
}) {
  const [error, setError] = useState(false);

  return (
    <div
      className={cx(
        'flex flex-col gap-2 mb-3',
        isFromMe ? 'items-end' : 'items-start'
      )}
    >
      <p className="text-sm text-neutral-50">{description}</p>
      {src &&
        (error ? (
          <div className="w-10 h-10 rounded-2xl bg-[#D9D9D9]" />
        ) : chat.mediaMimeType === 'video/mp4' ? (
          <video
            src={src}
            className={cx(
              ' rounded-2xl mb-2',
              isSquared ? 'w-48 h-48' : 'w-[136px] h-[223px]'
            )}
            controls
          />
        ) : (
          <img
            src={src}
            className={cx(
              'bg-[#D9D9D9] rounded-2xl w-[136px] h-[223px] mb-2',
              isSquared ? 'w-48 h-48' : 'w-[136px] h-[223px]'
            )}
            onError={() => {
              setError(true);
            }}
          />
        ))}
    </div>
  );
}
