import { createId } from '@paralleldrive/cuid2';

import { BBBStepOnboarding } from '@/components/ui';

export const BITLOGIN_SOCIAL_STEPS: BBBStepOnboarding[] = [
  {
    id: createId(),
    gif: '/gif/social_enablebitLogin.gif',
    title: 'Enable bitLogin on Shopify theme',
    desc: 'Add social login to your website by enable bitLogin on your Shopify theme. Social login helps your customers to personalize their shopping experience',
  },
  {
    id: createId(),
    gif: '/gif/social_chooseSocials.gif',
    title: 'Get started with your own social login button',
    desc: 'Get started with various social login options (Google, Facebook, Apple, WhatsApp etc.). Allow your customers to sign up for an account via pre-existing accounts. This helps to escalate sign up and check out instead of creating a whole new identity',
  },
];

export const BITLOGIN_APPEARANCE_STEPS: BBBStepOnboarding[] = [
  {
    id: createId(),
    gif: '/gif/appearance_matchButton.gif',
    title: 'Match login button to your theme',
    desc: 'Customize login buttons based on your own aesthetic. Adjust placements, size and colors all from ‘Appearance’ page',
  },
  {
    id: createId(),
    gif: '/gif/appearance_buttonPosition.gif',
    title: 'Adjust the position',
    desc: 'Adjust the position of you login buttons to your own liking (on top or bottom of regular login)',
  },
];
