import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { AutoReply } from '@/types/bitChat/chatbot';
import { DeepPartial } from '@/types/utils/deepPartial';
import { toast } from '@/utils/common/toast';

const useUpsertChatbot = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: DeepPartial<AutoReply>) =>
      api.whatsApp.v1.post(`/chatbot`, {
        companyId: activeCompany,
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitchat-chatbot-rule']);
        toast.success('Successfully added chatbot rule');
      },
    }
  );
};

export default useUpsertChatbot;
