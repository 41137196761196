import InstagramIcon2 from '@/assets/icons/InstagramIcon2';
import WhatsAppCloudApiIcon from '@/assets/icons/WhatsAppCloudApiIcon';
import WhatsAppIcon4 from '@/assets/icons/WhatsAppIcon4';
import { ChannelIntegrations } from '@/types/bitChat/integrations';

export const channelOptions: {
  label: string;
  icon: JSX.Element;
  value: ChannelIntegrations;
}[] = [
  {
    label: 'WhatsApp Business App',
    icon: <WhatsAppIcon4 />,
    value: 'whatsapp',
  },
  {
    label: 'WhatsApp Cloud API',
    icon: <WhatsAppCloudApiIcon />,
    value: 'whatsapp_meta',
  },
  {
    label: 'Instagram',
    icon: <InstagramIcon2 size={28} />,
    value: 'instagram',
  },
];
