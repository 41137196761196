import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import {
  PostDraftPublishPayload,
  SubmissionPayload,
} from 'api/services/bitApp/publish';
import { useActiveCompany, useUserId } from 'hooks/rtk/selector';
import { toast } from 'utils/common/toast';

export const useRecentPublish = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['recent-publish', activeCompany], () =>
    services.bitApp.publish.getRecentPublish(activeCompany)
  );
};

export const useRecentPublishRequest = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['recent-publish-request', activeCompany], () =>
    services.bitApp.publish.getRecentPublishRequest(activeCompany)
  );
};

export const useCreateDraftPublish = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: PostDraftPublishPayload) =>
      services.bitApp.publish.postDraftPublish(activeCompany, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitapp-branding']);
        queryClient.invalidateQueries(['recent-publish-request']);
        toast.success('Updated draft publish');
      },
    }
  );
};

export const useRequestPublish = () => {
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const queryClient = useQueryClient();

  return useMutation(
    () => services.bitApp.publish.postRequestPublish(activeCompany, { userId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['recent-publish-request']);
        toast.success(
          'App succesfully submitted. Please check your email shortly'
        );
      },
    }
  );
};

export const useUpdateSubmission = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const userId = useUserId();

  return useMutation(
    async (payload: SubmissionPayload) =>
      services.bitApp.publish.updateSubmission2(activeCompany, userId, payload),
    {
      onSuccess: () => {
        toast.success('Submission has been updated successfully.');
        queryClient.invalidateQueries(['recent-publish-request']);
      },
    }
  );
};
