import { ApiResponse } from '@/api/types';
import api from '@/config/api';

export default {
  getModels: (companyId: number, params?: GetModelsParams) =>
    api.bitAi.v2New
      .get<ApiResponse<Model[]>>(`/models/company/${companyId}`, { params })
      .then((res) => res.data.data),
  getModel: (companyId: number, modelId: string) =>
    api.bitAi.v2New
      .get<ApiResponse<ModelAssociation>>(
        `/models/company/${companyId}/${modelId}`
      )
      .then((res) => res.data.data),
  deleteModel: (companyId: number, modelId: string) =>
    api.bitAi.v2New.delete(`/models/company/${companyId}/${modelId}`),
  duplicateModel: (companyId: number, modelId: string) =>
    api.bitAi.v2New.post(`/models/company/${companyId}/${modelId}/duplicate`),
  upsertModel: (companyId: number, payload: UpsertModelPayload) =>
    api.bitAi.v2New.post(`/models/company/${companyId}`, payload),
  bulkDeleteModel: (companyId: number, payload: BulkDeleteModelPayload) =>
    api.bitAi.v2New.post(`/models/company/${companyId}/bulk-delete`, payload),
};

export type GetModelsParams = {
  skipPrebuilt: boolean;
};

export type BulkDeleteModelPayload = {
  modelId: string[];
};

export type UpsertModelPayload = {
  name: string;
  rules: {
    qty: number;
    condition: ModelCondition;
    index: number;
    id: string;
  }[];
  id?: string | undefined;
};

export type Model = {
  id: string;
  companyId: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  type: 'BASIC' | 'CUSTOM' | 'ADVANCE';
};

export type ModelRules = {
  id: string;
  modelId: string;
  companyId: number;
  qty: number;
  index: number;
  condition: ModelCondition;
};

export type ModelCondition =
  | 'TOP_SELLER'
  | 'TOP_REVENUE'
  | 'RANDOM'
  | 'NEWEST_PRODUCT'
  | 'HIGHEST_STOCK'
  | 'HIGHEST_DISCOUNT'
  | 'HIGHEST_DISCOUNT_PERCENTAGE'
  | 'HIGHEST_MARGIN'
  | 'HIGHEST_MARGIN_PERCENTAGE';

export type ModelAssociation = Model & {
  rules: ModelRules[];
};
