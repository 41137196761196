import { RefreshCw } from 'react-feather';

type Props = { onClick: () => void };

export default function ShowMore({ onClick }: Props) {
  return (
    <div className="text-info-main flex justify-center">
      <div className="cursor-pointer flex items-center gap-2" onClick={onClick}>
        <RefreshCw size={16} />
        Load more
      </div>
    </div>
  );
}
