function BBBChannelIcon({ size = 32 }: { size?: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 708.66 708.66"
    >
      <g data-name="Layer 2">
        <circle
          cx={354.33}
          cy={354.33}
          r={354.33}
          style={{
            fill: '#262627',
            stroke: '#000',
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M302.37 524.13a82.1 82.1 0 0 1-30.7-35.82h-1.43v43.55h-69.49v-343.4h69.49V321h1.43a82.62 82.62 0 0 1 31.06-36.29q20.58-13.21 47.72-13.21 32.13 0 55.33 15.83t35.82 45.45q12.61 29.63 12.61 71.27v.24q0 41.41-12.61 71.15t-35.93 45.69q-23.35 15.97-55.47 15.97-27.61 0-47.83-13m54.73-53.78q12.61-9.16 19.39-26.06t6.78-40v-.24q0-23.32-6.9-40.1t-19.51-25.82q-12.62-9-29.75-9-16.9 0-29.63 9.16t-20 26.06q-7.26 16.9-7.26 39.74v.24q0 23.08 7.14 40t20 26.06a49.91 49.91 0 0 0 29.75 9.16q17.37 0 30-9.16"
          style={{
            fill: '#fff',
          }}
        />
        <path
          d="M444.25 233.32q-10.83-10.83-10.83-25.58a34.47 34.47 0 0 1 10.83-25.58 36.51 36.51 0 0 1 26.53-10.59q15.71 0 26.42 10.59a34.66 34.66 0 0 1 10.71 25.58 35.17 35.17 0 0 1-10.71 25.58 35.68 35.68 0 0 1-26.42 10.83 36.07 36.07 0 0 1-26.53-10.83"
          style={{
            fill: '#fd823e',
          }}
        />
      </g>
    </svg>
  );
}
export default BBBChannelIcon;
