import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import {
  BBBButton,
  BBBModal,
  BBBRichTextEditor,
  BBBSpinner,
} from '@/components/ui';
import SearchInput from '@/components/ui/SearchInput';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useUserId } from '@/hooks/rtk/selector';
import { useUpdateChat } from '@/hooks/whatsApp/chat';
import useSummarizeMessage from '@/hooks/whatsApp/useSummarizeMessage';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { formatUserDisplayName } from '@/utils/auth';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from '@/utils/common/rich';

type Props = { onSuccess?: () => void; expandedBoard?: boolean };

const schema = yup.object().shape({
  notes: yup.mixed<EditorState>().label('Notes'),
});

function AssigneeList(props: Props) {
  const userId = useUserId();
  const [search, setSearch] = useState('');

  const { handleSubmit, control, setValue } = useCustomForm<{
    notes: EditorState;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      notes: emptyEditor,
    },
  });

  const query = useCompanyUsers({
    excludedUsers: [userId],
    search,
    limit: 10,
  });

  const {
    data: userCompanies,
    isInitialLoading,
    hasNextPage,
    fetchNextPage,
  } = query;

  const { mutate: updateChat, isLoading: loadingTransferChat } =
    useUpdateChat();

  const [showTransferChatModal, setTransferChatModal] = useState(false);

  const [transferredUser, setTransferredUser] =
    useState<UserCompanyWithAssociation>();

  const userCompaniesData = useMemo(
    () => userCompanies?.pages.flatMap((page) => page.data),
    [userCompanies]
  );

  const { mutate: summarize, isLoading: loadingSummarize } =
    useSummarizeMessage();

  const handleSummarize = () =>
    summarize(undefined, {
      onSuccess: (data) =>
        setValue(
          'notes',
          convertHtmlToEditorState(
            `Conversation summary (AI generated);\n${
              (data.summary?.length || 0) > 0
                ? data.summary?.map((summary) => `- ${summary}\n`).join('')
                : 'No summary available'
            }`
          )
        ),
    });

  const onSubmit = (data: { notes?: EditorState }) =>
    updateChat(
      {
        status: 'transfer',
        notes: convertEditorStateToHtml(data.notes),
        assignedUser: {
          userId: transferredUser!.userId,
          userAccent: transferredUser!.user.accent,
          userDisplayName: formatUserDisplayName(transferredUser!.user),
          userProfilePicture:
            transferredUser!.user.profile?.profilePicture || null,
          userEmail: transferredUser!.user.email,
        },
      },
      {
        onSuccess: () => {
          setTransferredUser(undefined);
          setTransferChatModal(false);
          props.onSuccess?.();
        },
      }
    );

  return (
    <>
      {showTransferChatModal && transferredUser && (
        <BBBModal
          title={`Transfer chat to ${formatUserDisplayName(
            transferredUser.user
          )}`}
          centerTitle
          disableSave={loadingTransferChat}
          footer
          customDelete={
            <div className="grow mr-3">
              <BBBButton
                variant="secondary"
                text="Summarize last conversation"
                onClick={handleSummarize}
                loadingState={loadingSummarize}
              />
            </div>
          }
          onHide={() => {
            setTransferredUser(undefined);
            setTransferChatModal(false);
          }}
          handleSave={() => handleSubmit(onSubmit)()}
          submitText="Transfer"
          show={showTransferChatModal}
        >
          <Controller
            control={control}
            name="notes"
            render={({ field }) => (
              <BBBRichTextEditor
                editorState={field.value}
                onChangeEditorState={field.onChange}
                placeholder="Input your notes to next agent"
                label="Message"
                labelClassName="mb-2"
              />
            )}
          />
        </BBBModal>
      )}
      <div
        className={cx(
          'transition-opacity shadow absolute z-20 overflow-hidden rounded-lg  top-0 w-full bg-white',
          props.expandedBoard ? 'right-[104%]' : 'left-[104%]'
        )}
      >
        <SearchInput
          value={search}
          onValueChange={(value) => setSearch(value)}
          placeholder="Search agent"
          className="border-t-0 border-l-0 border-r-0 rounded-none"
          inputClassName="px-0"
          containerClassname="mb-0"
        />
        <div className="max-h-72 overflow-auto" id="assignee-list-wrapper">
          {isInitialLoading ? (
            <BBBSpinner />
          ) : !userCompaniesData?.length ? (
            <div className={cx(`px-3 py-2 flex gap-2 items-center`)}>
              No agent found
            </div>
          ) : (
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              dataLength={userCompaniesData?.length || 0}
              scrollableTarget="assignee-list-wrapper"
              loader={undefined}
            >
              {userCompaniesData?.map((u) => (
                <div
                  key={u.userId}
                  className={cx(
                    `px-3 py-2 hover:bg-gray-100 mb-2 last:mb-0 cursor-pointer flex gap-2 items-center`,
                    u.userId === userId && 'pointer-events-none'
                  )}
                  onClick={() => {
                    setTransferredUser(u);
                    setTransferChatModal(true);
                  }}
                >
                  {u.user.profile?.profilePicture && (
                    <img
                      src={u.user.profile?.profilePicture}
                      className="w-6 h-6 rounded-full flex-none"
                    />
                  )}
                  <div className="line-clamp-1">
                    {formatUserDisplayName(u.user)}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
}

export default AssigneeList;
