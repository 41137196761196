import { useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useAutoPilotRules = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['auto-pilot-rules', activeCompany], () =>
    services.bitChat.autopilot.getAutoPilotRules(activeCompany)
  );
};

export default useAutoPilotRules;
