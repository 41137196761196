import { useEffect, useState } from 'react';
import { socketWhatsApp } from 'socket';

import { useAppSelector } from '@/hooks/rtk/store';

function RealtimeUpdates() {
  const activeCompany = useAppSelector((store) => store.auth.activeCompany);
  const [count, setCount] = useState(0);

  const onTicketUpdated = () => setCount((prev) => (prev += 1));

  useEffect(() => {
    socketWhatsApp.emit('join-room-ticket', {
      companyId: activeCompany,
    });
  }, [activeCompany]);

  useEffect(() => {
    socketWhatsApp.on('ticket_created', onTicketUpdated);

    return () => {
      socketWhatsApp.off('ticket_created', onTicketUpdated);
    };
  }, []);

  useEffect(() => {
    socketWhatsApp.on('ticket_updated', onTicketUpdated);

    return () => {
      socketWhatsApp.off('ticket_updated', onTicketUpdated);
    };
  }, []);

  if (count === 0) return null;

  return (
    <div className="my-6 w-full text-center text-neutral-60 underline cursor-pointer">
      {count}+ Update from your tickets. Click here to refresh
    </div>
  );
}

export default RealtimeUpdates;
