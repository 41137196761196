import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useReactFlow } from 'reactflow';
import { useQueryClient } from '@tanstack/react-query';
import useStore from './store';

import { mapIntegrationChannels2 } from '@/constants/whatsApp';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { ChannelIntegrations } from '@/types/bitChat/integrations';
import { Flow } from '@/types/whatsApp/flow';
import { IntegrationSources } from '@/types/whatsApp/shape';

export function useAutoPosition() {
  const nodes = useStore((s) => s.nodes);
  const { getZoom } = useReactFlow();

  return (sourceId: string) => {
    const node = nodes.find((node) => node.id === sourceId);
    const parentNode = node?.parentNode || node?.id;

    if (parentNode) {
      const sourceElement = document.querySelector<HTMLDivElement>(
        `.react-flow__node[data-id="${sourceId}"]`
      );

      const parentElement = document.querySelector<HTMLDivElement>(
        `.react-flow__node[data-id="${sourceId}"]`
      );

      const paneSelector = document.querySelector<HTMLDivElement>(
        '.react-flow__viewport'
      );

      if (sourceElement && parentElement && paneSelector) {
        const { x, y, width } = sourceElement.getBoundingClientRect();
        const { height: parentHeight } = parentElement.getBoundingClientRect();

        const { x: viewportX, y: viewportY } =
          paneSelector.getBoundingClientRect();

        const zoomRatio = getZoom();

        return {
          x: (x - viewportX + width + 100) * (1 / zoomRatio),
          y: (y - parentHeight - viewportY - 100) * (1 / zoomRatio),
        };
      }
    }
  };
}

export function useAutoSize(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  slotSelector: string,
  containerSelector: string,
  nodeId: string,
  data: Record<string, any>
) {
  const nodeIndex = useStore((s) =>
    s.nodes
      .filter((node) => node.parentNode === data.parentId)
      .findIndex((childNode) => childNode.id === nodeId)
  );

  const { getZoom } = useReactFlow();

  const updateChildNodeDimensions = useStore(
    (state) => state.updateChildNodeDimensions
  );

  useEffect(() => {
    if (ref.current) {
      const conditionsSlotRef = document.querySelector<HTMLDivElement>(
        slotSelector
      ) as HTMLDivElement | null;

      const triggerCardRef = document.querySelector<HTMLDivElement>(
        containerSelector
      ) as HTMLDivElement | null;

      if (conditionsSlotRef && triggerCardRef) {
        const { right: cardRight, width: wrapperWidth } =
          triggerCardRef.getBoundingClientRect();
        const { width: elementWidth } = ref.current.getBoundingClientRect();
        const { right } = conditionsSlotRef.getBoundingClientRect();

        const xOffset =
          (wrapperWidth - elementWidth - (cardRight - right)) / getZoom();

        const top = conditionsSlotRef.offsetTop;

        updateChildNodeDimensions(nodeId, {
          x: xOffset,
          y: top + nodeIndex * (ref.current.offsetHeight + 16),
        });
      }
    }
  }, [
    getZoom,
    data,
    ref,
    updateChildNodeDimensions,
    slotSelector,
    containerSelector,
    nodeId,
    nodeIndex,
  ]);
}

export function useDeleteState() {
  const confirm = useConfirmationModal();
  const deleteRelation = useStore((s) => s.deleteRelation);

  return (nodeId: string) => {
    confirm({
      title: 'Delete state',
      description:
        'Are you sure want to delete this state? Once you delete the action cannot be undone',
      onAccept: (hide) => {
        deleteRelation(nodeId);
        hide();
      },
      deleteModal: true,
      submitText: 'Delete state',
    });
  };
}

export function useCancel(nodeId: string | undefined) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const node = useStore((s) => s.nodes.find((node) => node.id === nodeId));
  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );

  return () => {
    if (id === 'new') {
      if (!node) {
        history.push('/bitchat/flows');
        onChangeTriggerModalState(null);
      } else {
        onChangeTriggerModalState(null);
      }
    } else {
      onChangeTriggerModalState(null);
    }
  };
}

export const useFlowChannel = () => {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const query = useQuerySearchParams();

  const channelFromApi = queryClient.getQueryData<Flow>(['flow', id])?.channel;

  const source =
    id === 'new'
      ? (query.get('source') as ChannelIntegrations | undefined)
      : channelFromApi
      ? mapIntegrationChannels2[channelFromApi]
      : undefined;

  return source;
};

export const mapChannelIntegrationsToIntegrationSources: Record<
  ChannelIntegrations,
  IntegrationSources
> = {
  facebook: 'FACEBOOK',
  google_my_business: 'GOOGLE_MY_BUSINESS',
  instagram: 'INSTAGRAM',
  whatsapp: 'WHATSAPP',
  whatsapp_meta: 'WHATSAPP_META',
};
