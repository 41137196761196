export default function PlusCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        stroke="#1E1E1E"
        strokeLinecap="round"
        d="M6.5 4.5v2m0 0v2m0-2h2m-2 0h-2"
      ></path>
      <path stroke="#1E1E1E" d="M6.5 11.5a5 5 0 100-10 5 5 0 000 10z"></path>
    </svg>
  );
}
