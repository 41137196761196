type Props = {
  children?: React.ReactNode;
};

function ChatInfo({ children }: Props) {
  return (
    <div className="text-gray-500 text-sm">
      {children ?? (
        <>
          bitChat doesn&apos;t support this message type. Please preview on your
          phone
        </>
      )}
    </div>
  );
}

export default ChatInfo;
