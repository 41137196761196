import { Fragment, ReactNode, useEffect } from 'react';
import { Clock } from 'react-feather';
import { Control, Controller, DefaultValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { timeUnitOptions, timeUnitOptionsObj } from 'constants/bitChat';
import { integrationsMeta, KnownIntegration } from 'constants/integrations';
import { mapAutomationSourceToKnownIntegrations } from 'constants/whatsApp';
import { EditorState } from 'draft-js';
import { useInfiniteTemplates } from 'hooks/bitCRM/template/template';
import { twMerge as cx } from 'tailwind-merge';
import {
  CampaignTemplate,
  TemplateBody,
  TemplateBodyParameters,
} from 'types/bitCRM/template';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from 'utils/common/rich';
import useStore from '../../../store';
import {
  Action,
  ActionData,
  ActionDelay,
  ActionSource,
  TriggerData,
} from '../../../types';

import ConditionIcon from '@/assets/icons/ConditionIcon';
import MessageIcon from '@/assets/icons/MessageIcon';
import UserIcon3 from '@/assets/icons/UserIcon3';
import {
  BBBButton,
  BBBFileUpload,
  BBBModal,
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components/ui';
import { automationVariables } from '@/constants/bitCRM/template';
import { acceptedCrmFileTypes } from '@/constants/crm';
import CampaignMessage from '@/pages/BitCRM/components/CampaignMessage';
import TagOptions from '@/pages/Customers/Segmentation/TagOptions';
import { Tags } from '@/types/customers';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

type TemplateForm = Pick<CampaignTemplate, 'id' | 'templateName'> & {
  _message: TemplateBody['message'];
};

type ActionFormSource =
  | 'whatsapp'
  | 'whatsapp_meta'
  | 'yotpo'
  | 'stamped'
  | 'time_delay'
  | 'instagram'
  | 'stamped_loyalty'
  | 'customer'
  | 'power_message';

type ActionForm = {
  source: ActionFormSource | null;
  template: (CampaignTemplate | TemplateForm) | null;
  delayCount: string | null;
  customerTags: (Pick<Tags, 'id' | 'name'> | Tags)[] | null;
  addedLoyaltyPoints: string | null;
  subtractedLoyaltyPoints: string | null;
  action: Action | null;
  delayUnit: ActionDelay | null;
  params: string[] | null;
  powerMsgHeader: string;
  powerMsgBody: string;
  body: {
    message: EditorState;
    parameters: TemplateBodyParameters[];
  };
  fileUrl: Nullable<FileType>;
};

const actionDefaultValues: ActionForm = {
  source: null,
  action: null,
  body: {
    message: emptyEditor,
    parameters: [],
  },
  delayCount: '',
  delayUnit: null,
  params: null,
  template: null,
  addedLoyaltyPoints: '',
  subtractedLoyaltyPoints: '',
  powerMsgBody: '',
  powerMsgHeader: '',
  customerTags: null,
  fileUrl: null,
};

const shownIntegrations: {
  category: string;
  integrations: KnownIntegration[];
}[] = [
  {
    category: 'Communication channel',
    integrations: ['whatsapp_meta', 'whatsapp', 'instagram'],
  },
  {
    category: 'Product review',
    integrations: ['stamped', 'yotpo'],
  },
  {
    category: 'Loyalty',
    integrations: ['stamped_loyalty'],
  },
];

const channelActionOptions: { label: string; value: Action }[] = [
  { label: 'Send a message', value: 'send_message' },
];
const reviewActionOptions: { label: string; value: Action }[] = [
  { label: 'Ask a product review', value: 'ask_review' },
];
const customerActionOptions: { label: string; value: Action }[] = [
  { label: 'Add customer tag', value: 'add_customer_tag' },
  { label: 'Get customer information', value: 'get_customer_information' },
];
const stampedLoyaltyActionOptions: { label: string; value: Action }[] = [
  { label: 'Add point', value: 'add_loyalty_point' },
  { label: 'Subtract point', value: 'subtract_loyalty_point' },
  { label: 'Get membership info', value: 'get_loyalty_membership_info' },
];
const powerMessageActionOptions: { label: string; value: Action }[] = [
  { label: 'Send power message', value: 'send_power_message' },
];

export const actionOptions: Partial<
  Record<ActionFormSource, { label: string; value: Action }[]>
> = {
  stamped: reviewActionOptions,
  yotpo: reviewActionOptions,
  instagram: channelActionOptions,
  whatsapp: channelActionOptions,
  whatsapp_meta: channelActionOptions,
  customer: customerActionOptions,
  stamped_loyalty: stampedLoyaltyActionOptions,
  power_message: powerMessageActionOptions,
};

export const mapActionSource: Record<ActionFormSource, ActionSource> = {
  instagram: 'INSTAGRAM',
  stamped: 'STAMPED',
  time_delay: 'time_delay',
  whatsapp: 'WHATSAPP',
  whatsapp_meta: 'WHATSAPP_META',
  yotpo: 'YOTPO',
  stamped_loyalty: 'stamped_loyalty',
  customer: 'customer',
  power_message: 'power_message',
};

export const mapSourceAction = Object.fromEntries(
  Object.entries(mapActionSource).map(([actionForm, actionSource]) => [
    actionSource,
    actionForm,
  ])
) as Record<ActionSource, ActionFormSource>;

export default function ActionModal({
  onSave,
  onClose,
  nodeId,
  sourceId,
  show,
}: {
  onSave: (val: ActionData) => void;
  onClose: () => void;
  nodeId?: string;
  sourceId?: string;
  show: boolean;
}) {
  const { handleSubmit, control, reset, watch, setValue } = useForm<ActionForm>(
    {
      defaultValues: actionDefaultValues,
    }
  );

  const nodeData = useStore((s) =>
    nodeId
      ? (s.nodes.find((node) => node.id === nodeId)?.data as
          | ActionData
          | undefined)
      : undefined
  );

  const insertConditions = useStore((s) => s.insertConditions);
  const replaceWithCondition = useStore((s) => s.replaceWithCondition);
  const hasNextNode = useStore((s) =>
    nodeId ? s.edges.some((edge) => edge.source === nodeId) : false
  );

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  useEffect(() => {
    if (nodeData) {
      reset({
        source: nodeData.source ? mapSourceAction[nodeData.source] : null,
        action: nodeData.action,
        template:
          nodeData.templateId && nodeData.templateName
            ? {
                id: nodeData.templateId,
                templateName: nodeData.templateName,
                _message: nodeData.templateMessage || undefined,
              }
            : null,
        body: {
          message: nodeData.message
            ? convertHtmlToEditorState(nodeData.message)
            : emptyEditor,
          parameters: [],
        },
        params: nodeData.params,
        delayCount: nodeData.delayCount ? nodeData.delayCount.toString() : '',
        delayUnit: nodeData.delayUnit,
        customerTags: nodeData.customerTags,
        addedLoyaltyPoints: nodeData.addedLoyaltyPoints
          ? nodeData.addedLoyaltyPoints.toString()
          : '',
        subtractedLoyaltyPoints: nodeData.subtractedLoyaltyPoints
          ? nodeData.subtractedLoyaltyPoints.toString()
          : '',
        powerMsgBody: nodeData.powerMsgBody || '',
        powerMsgHeader: nodeData.powerMsgHeader || '',
        fileUrl: nodeData.fileUrl
          ? convertRemoteUrlToFileType(nodeData.fileUrl)
          : null,
      });
    } else {
      reset(actionDefaultValues);
    }
  }, [nodeData, reset]);

  const action = watch('action');
  const source = watch('source');

  const stampedLoyaltyTriggerValue = useStore((s) => {
    if (source !== 'stamped_loyalty') return;

    return (
      s.nodes.find((node) => {
        return (
          node.type === 'trigger' &&
          (node.data as TriggerData | null)?.source === 'stamped_loyalty'
        );
      })?.data as TriggerData | undefined
    )?.triggerValue;
  });

  const useWebhookTrigger = useStore((s) => {
    return !!(s.nodes.find((node) => {
      return (
        node.type === 'trigger' &&
        (node.data as TriggerData | null)?.source === 'custom_webhook'
      );
    })?.data as TriggerData | undefined);
  });

  return (
    <BBBModal
      show={show}
      title="Action"
      footer
      submitText="Save"
      cancelText="Discard"
      onHide={() => onChangeStateModal(null)}
      handleSave={() => {
        handleSubmit(
          ({
            template,
            source,
            body: { message },
            delayCount,
            delayUnit,
            customerTags,
            addedLoyaltyPoints,
            subtractedLoyaltyPoints,
            powerMsgBody,
            powerMsgHeader,
            fileUrl,
            ...data
          }) => {
            onSave({
              ...data,
              templateName: template?.templateName || null,
              templateId: template?.id || null,
              templateMessage: template
                ? '_message' in template
                  ? template._message || null
                  : template.body?.message || null
                : null,
              source: source ? mapActionSource[source] : null,
              message: convertEditorStateToHtml(message) || null,
              delayCount: delayCount ? Number(delayCount) : null,
              delayUnit: delayUnit,
              customerTags:
                customerTags?.map((tag) => ({
                  id: tag.id,
                  name: tag.name,
                })) || null,
              addedLoyaltyPoints: addedLoyaltyPoints
                ? Number(addedLoyaltyPoints)
                : null,
              subtractedLoyaltyPoints: subtractedLoyaltyPoints
                ? Number(subtractedLoyaltyPoints)
                : null,
              powerMsgBody,
              powerMsgHeader,
              fileUrl: fileUrl?.remoteUrl || null,
            });
            onClose();
          }
        )();
      }}
      bodyClassName="px-2"
    >
      {!source ? (
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange } }) => (
            <>
              {shownIntegrations.map(({ integrations, category }) => {
                return (
                  <Fragment key={category}>
                    <div className="mb-2 mx-3">{category}</div>
                    {integrations.map((integration) => {
                      const meta = integrationsMeta[integration];
                      const Icon = meta.icon;

                      return (
                        <Source
                          key={integration}
                          onClick={() => {
                            onChange(integration);
                          }}
                          Icon={Icon}
                          title={meta.title}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
              <div className="mb-2 mx-3">Built-in tools</div>
              <Source
                onClick={() => {
                  onChange('time_delay');
                }}
                Icon={<Clock className="text-[#9A9A9A]" size={'1.75rem'} />}
                title={'Time delay'}
              />
              {!hasNextNode && (
                <Source
                  onClick={() => {
                    if (nodeId) {
                      replaceWithCondition(nodeId);
                    }

                    if (sourceId) {
                      insertConditions(sourceId);
                    }

                    onClose();
                  }}
                  Icon={<ConditionIcon size={'1.75rem'} />}
                  title={'Conditions'}
                />
              )}
              <Source
                onClick={() => {
                  onChange('customer');
                }}
                Icon={<UserIcon3 size={'1.75rem'} />}
                title={'Customer tools'}
              />
              <Source
                onClick={() => {
                  onChange('power_message');
                }}
                Icon={<MessageIcon size={'1.75rem'} />}
                title={'Power message'}
              />
            </>
          )}
        />
      ) : (
        <>
          {source !== 'time_delay' ? (
            <>
              <Source
                title={integrationsMeta[source].title}
                Icon={integrationsMeta[source].icon}
                withChange
                onChange={() => {
                  reset(actionDefaultValues);
                }}
                className="mb-5"
              />
              <div className="mx-3">
                <Controller
                  control={control}
                  name="action"
                  render={({ field }) => {
                    let options = source ? actionOptions[source] : [];

                    if (stampedLoyaltyTriggerValue === 'loyalty-rewards-earn') {
                      options = options?.filter(
                        (opt) => opt.value !== 'add_loyalty_point'
                      );
                    } else if (
                      stampedLoyaltyTriggerValue === 'loyalty-rewards-used'
                    ) {
                      options = options?.filter(
                        (opt) => opt.value !== 'subtract_loyalty_point'
                      );
                    }

                    const value =
                      options?.find((opt) => opt.value === field.value) || null;

                    return (
                      <BBBSelect
                        options={options}
                        optionLabel="label"
                        optionValue="value"
                        value={value}
                        onValueChange={(val) => field.onChange(val?.value)}
                        label="Action"
                        placeholder="Choose action"
                        containerClassName="mb-5"
                      />
                    );
                  }}
                />
                {action === 'send_message' &&
                  (source === 'whatsapp_meta' ? (
                    <Controller
                      control={control}
                      name="template"
                      render={({ field }) => {
                        const value = field.value as
                          | (CampaignTemplate | TemplateForm)
                          | null;

                        const message = value
                          ? '_message' in value
                            ? value._message
                            : value.body?.message
                          : null;

                        const regex = /{{\d+}}/g;
                        const matches = message
                          ? message.match(regex)
                          : undefined;
                        const numberOfDynamicVariables = matches
                          ? matches.length
                          : 0;

                        const hasParams =
                          (value &&
                            'params' in value &&
                            !!value.params?.length) ||
                          !!nodeData?.params?.length;

                        return (
                          <>
                            <TemplateOptions
                              source={source}
                              value={value}
                              error={
                                useWebhookTrigger && hasParams
                                  ? 'Template with dynamic variable is currently unsupported in Webhook trigger'
                                  : undefined
                              }
                              onChange={(val) => {
                                field.onChange(val);
                                setValue(
                                  'params',
                                  val?.params?.map(
                                    (param) => param?.value || ''
                                  ) || []
                                );
                              }}
                            />
                            {message && (
                              <>
                                <div className="text-sm text-neutral-500 mb-2">
                                  Message preview
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: message,
                                  }}
                                />
                                {Array.from({
                                  length: numberOfDynamicVariables,
                                }).map((_data, index) => (
                                  <DynamicVariableOptions
                                    control={control}
                                    key={`${value!.templateName}-${index}`}
                                    index={index}
                                    sourceId={sourceId}
                                    nodeId={nodeId}
                                  />
                                ))}
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  ) : source === 'whatsapp' ? (
                    <>
                      <Controller
                        control={control}
                        name="body"
                        render={({ field }) => (
                          <CampaignMessage
                            editorState={field.value.message}
                            onChangeEditorState={(message) => {
                              field.onChange({ ...field.value, message });
                            }}
                            source="whatsapp-business"
                            params={field.value.parameters}
                            onChangeParams={(parameters) => {
                              field.onChange({ ...field.value, parameters });
                            }}
                            onChange={({ params, editorState }) => {
                              field.onChange({
                                ...field.value,
                                parameters: params,
                                message: editorState,
                              });
                            }}
                            type={'automation'}
                          />
                        )}
                      />
                      <Controller
                        name="fileUrl"
                        control={control}
                        render={({ field }) => (
                          <BBBFileUpload
                            isSingle
                            files={field.value}
                            onChangeFile={field.onChange}
                            accept={acceptedCrmFileTypes}
                            withLabel
                            containerClassName="my-5"
                          />
                        )}
                      />
                    </>
                  ) : (
                    <Controller
                      control={control}
                      name="body"
                      render={({ field }) => (
                        <BBBRichTextEditor
                          editorState={field.value.message}
                          onChangeEditorState={(message) => {
                            field.onChange({ ...field.value, message });
                          }}
                          placeholder="Input your message here"
                          label="Message"
                        />
                      )}
                    />
                  ))}
                {action === 'ask_review' && (
                  <>
                    Add communication channel action in next action and choose
                    variable with {integrationsMeta[source].title} logo
                  </>
                )}
                {action === 'add_customer_tag' && (
                  <Controller
                    control={control}
                    name="customerTags"
                    render={({ field }) => (
                      <TagOptions
                        isMulti
                        containerClassName="w-full md:min-w-full"
                        placeholder="Search tag"
                        label={'Customer tag'}
                        isSearchable
                        //@ts-ignore
                        value={field.value}
                        onValueChange={field.onChange}
                        isCreatable
                        sensitive
                      />
                    )}
                  />
                )}
                {action === 'add_loyalty_point' && (
                  <BBBTextInput
                    label="Amount of points"
                    placeholder="Input points"
                    isHookForm
                    control={control}
                    controlName="addedLoyaltyPoints"
                  />
                )}
                {action === 'subtract_loyalty_point' && (
                  <BBBTextInput
                    label="Amount of points"
                    placeholder="Input points"
                    isHookForm
                    control={control}
                    controlName="subtractedLoyaltyPoints"
                  />
                )}
                {action === 'send_power_message' && (
                  <>
                    <BBBTextInput
                      label="Header"
                      placeholder="Input header"
                      isHookForm
                      control={control}
                      controlName="powerMsgHeader"
                      hasMaxCharLabel
                      maxChar={20}
                    />
                    <BBBTextInput
                      label="Message body"
                      placeholder="Input your message"
                      isHookForm
                      control={control}
                      controlName="powerMsgBody"
                      hasMaxCharLabel
                      maxChar={60}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <Source
                Icon={
                  <Clock className="text-secondary-main" size={'1.75rem'} />
                }
                title={'Time delay'}
                withChange
                onChange={() => {
                  reset(actionDefaultValues);
                }}
                className="mb-5"
              />
              <div className="flex items-center gap-2 mx-3">
                <BBBTextInput
                  isHookForm
                  control={control}
                  controlName={'delayCount'}
                  containerClassname="flex-1 mb-0"
                  placeholder="Number of time"
                />
                <Controller
                  control={control}
                  name="delayUnit"
                  render={({ field }) => {
                    const value = field.value
                      ? timeUnitOptionsObj[field.value]
                      : null;

                    return (
                      <BBBSelect
                        placeholder="Select unit"
                        value={value}
                        onValueChange={(val) => field.onChange(val!.value)}
                        options={timeUnitOptions}
                        optionLabel="label"
                        optionValue="value"
                        containerClassName="flex-1"
                      />
                    );
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </BBBModal>
  );
}

export function Source({
  onClick,
  title,
  Icon,
  withChange,
  onChange,
  className,
}: {
  onClick?: () => void;
  title: string;
  Icon:
    | ReactNode
    | (({
        size,
      }: {
        size?: string | number | undefined;
        className?: string | undefined;
      }) => JSX.Element)
    | undefined;
  withChange?: boolean;
  onChange?: () => void;
  className?: string;
}) {
  return (
    <div
      className={cx(
        'flex items-center gap-4 mb-2 last:mb-0  rounded-md p-3',
        typeof onClick !== 'undefined' &&
          'hover:bg-secondary-surface transition-colors cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <div className="w-12 h-12 rounded-lg border border-neutral-30 flex items-center justify-center">
        {typeof Icon === 'function' ? Icon && <Icon /> : Icon}
      </div>
      <div className="grow">{title}</div>
      {withChange && (
        <BBBButton
          variant="secondary"
          className="pointer-events-auto"
          onClick={onChange}
        >
          Change
        </BBBButton>
      )}
    </div>
  );
}

export function TemplateOptions({
  source,
  value,
  onChange,
  error,
}: {
  source: ActionFormSource;
  onChange: (val: SingleOnValueChangeParam<CampaignTemplate>) => void;
  value: CampaignTemplate | TemplateForm | null;
  error?: string;
}) {
  const { data: _templateData, isInitialLoading: loadingTemplate } =
    useInfiniteTemplates(
      {
        type: 'AUTOMATION_WHATSAPP_CLOUD_API',
        status: 'APPROVED',
      },
      {
        enabled: source === 'whatsapp_meta',
      }
    );

  const templates = _templateData?.pages.flatMap((page) => page.content);

  const history = useHistory();

  const onChangeStateModal = useStore((s) => s.onChangeStateModal);

  return (
    <BBBSelect
      label="Message template"
      placeholder="Select template"
      //@ts-ignore
      value={value}
      //@ts-ignore
      onValueChange={onChange}
      options={templates}
      optionLabel="templateName"
      optionValue="id"
      containerClassName="mb-5"
      loading={loadingTemplate}
      withCreateRedirectOption
      createRedirectLabel="Create new template"
      onClickCreateRedirect={() => {
        history.push(
          `/misc/whatsapp-api-settings/templates/new?section=automation`,
          {
            key: 'shortcut-automation-new',
          }
        );
        onChangeStateModal(null);
      }}
      error={error}
    />
  );
}

function DynamicVariableOptions({
  control,
  index,
  sourceId,
  nodeId,
}: {
  control: Control<ActionForm>;
  index: number;
  nodeId?: string;
  sourceId?: string;
}) {
  const previousSource = useStore((s) => {
    const sourceNode =
      sourceId || s.edges.find((edge) => edge.target === nodeId)?.source;

    if (!sourceNode) return;

    const prevNodeData = s.nodes.find((node) => node.id === sourceNode);

    if (!prevNodeData) return;

    if (prevNodeData.type === 'trigger') return;

    return (prevNodeData.data as ActionData | null)?.source;
  });

  const SourceIcon = previousSource
    ? integrationsMeta[mapAutomationSourceToKnownIntegrations[previousSource]]
        .icon
    : null;

  const dynamicVariableOptions = [
    ...automationVariables,
    ...(previousSource === 'STAMPED' || previousSource === 'YOTPO'
      ? [
          {
            label: 'Review URL',
            value: 'review_url',
            icon: SourceIcon ? <SourceIcon /> : null,
          },
        ]
      : []),
  ];

  const automationVariableObj = Object.fromEntries(
    dynamicVariableOptions.map((automationVariable) => [
      automationVariable.value,
      automationVariable,
    ])
  );

  return (
    <Controller
      control={control}
      name={`params.${index}`}
      render={({ field }) => {
        const value = field.value ? automationVariableObj[field.value] : null;

        return (
          <BBBSelect
            containerClassName="mt-5"
            options={dynamicVariableOptions}
            optionLabel="label"
            optionValue="value"
            label={`Variable ${index + 1}`}
            placeholder="Select parameter"
            value={value}
            onValueChange={(val) => field.onChange(val!.value)}
            optionIcon="icon"
          />
        );
      }}
    />
  );
}
