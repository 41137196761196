import customer from './customer';
import order from './order';
import products from './products';

const customerServices = {
  customer,
  order,
  products,
};

export default customerServices;
