import {
  WidgetIconType,
  WidgetRadiusType,
  WidgetTextColor,
} from 'types/bitChat/waWidget';

export const buttonWidgetCategories: {
  icon: WidgetIconType;
  shape: WidgetRadiusType;
}[] = [
  {
    icon: 'whatsapp',
    shape: 'rounded',
  },
  {
    icon: 'whatsapp',
    shape: 'circle',
  },
  {
    icon: 'chat',
    shape: 'rounded',
  },
  {
    icon: 'chat',
    shape: 'circle',
  },
];

export const textColorOptions: { label: string; value: WidgetTextColor }[] = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
];

export const buttonSizeOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'regular' },
  { label: 'Large', value: 'large' },
];

export const buttonPositionOptions = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'right',
    label: 'Right',
  },
];
