import { useQuery } from '@tanstack/react-query';
import integrationServices from 'api/services/integration';
import { request, RequestDocument } from 'graphql-request';

import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';

const useGraphQLRequest = () => {
  const { data: shopifyIntegrationExtra } =
    useShopifyIntegrationByApp('BITAPP');

  const { data: shopifyMeta } = useQuery(
    ['shopify-meta', 'BITAPP'],
    () =>
      integrationServices.shopify.getShopifyData(
        'BITAPP',
        shopifyIntegrationExtra?.domain
      ),
    { enabled: !!shopifyIntegrationExtra?.domain }
  );

  return {
    request: async ({
      query,
      variables,
    }: {
      query: RequestDocument;
      variables: Record<string, any>;
    }) => {
      if (!shopifyMeta?.storefrontToken) return undefined;

      const data = await request(
        `https://${shopifyMeta.domain}/api/2022-10/graphql.json`,
        query,
        variables,
        {
          'Content-Type': `application/json`,
          'X-Shopify-Storefront-Access-Token': shopifyMeta.storefrontToken!,
        }
      );

      return data;
    },
    enabled: !!shopifyMeta,
  };
};

export default useGraphQLRequest;
