function TwitterIcon({ color = '#FFFFFF' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 48 48"
    >
      <polygon fill={color} points="41,6 9.929,42 6.215,42 37.287,6"></polygon>
      <polygon
        fill={color === '#FFFFFF' ? '#000000' : '#FFFFFF'}
        points="31.143,41 7.82,7 16.777,7 40.1,41"
      ></polygon>
      <path
        fill={color}
        d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
