import { useQuery } from '@tanstack/react-query';
import { selectActiveChatTruthy } from './chat';

import services from '@/api/services';
import { useApiKey } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';

const useStarredMessages = () => {
  const apiKey = useApiKey();
  const selectedChat = useAppSelector(selectActiveChatTruthy);

  return useQuery(['starred-messages', selectedChat], () =>
    services.whatsApp.v3.message.getStarredMessages({
      key: apiKey,
      chatId: selectedChat.clientNumber,
      sources: selectedChat.sources,
    })
  );
};

export default useStarredMessages;
