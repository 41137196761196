import analytics from './analytics';
import automation from './automation';
import automationNew from './automation-new';
import campaign from './campaign';
import integrations from './integrations';
import shopify from './shopify';
import template from './template';

export default {
  analytics,
  automation,
  template,
  campaign,
  integrations,
  shopify,
  automationNew,
};
