import { twMerge as cx } from 'tailwind-merge';
import DeviceFrameNotch from './DeviceFrameNotch/DeviceFrameNotch';

function BBBDeviceFrames({
  children,
  className,
  withoutNotch,
}: {
  children: React.ReactNode;
  className?: string;
  withoutNotch?: boolean;
}) {
  return (
    <div
      id="bbb-device-frame"
      className={cx(
        'relative rounded-[48px] z-1 border-primary-main border-[0.4rem] overflow-hidden bg-white aspect-[320/692] h-[43.25rem]',
        className
      )}
    >
      <div className="flex flex-col h-full">
        {!withoutNotch && <DeviceFrameNotch />}
        <div className="grow relative">
          <div className="absolute inset-0 overflow-auto">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default BBBDeviceFrames;
