import { useHistory } from 'react-router';
import { twMerge as cx } from 'tailwind-merge';
import { BBBTopNavItem } from './BBBTopNavItem';

import BBBLogo from '@/assets/icons/BBBLogo';
import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import { env } from '@/config/env';
import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';

export type BBBTopNavProps = {
  className?: string;
  items: sidebarDataTypeSectionV2[];
};

const BBBTopNav = ({ className, items }: BBBTopNavProps) => {
  const history = useHistory();
  return (
    <div
      className={cx(
        'w-full py-4 px-7 flex justify-between items-center bg-neutral-10',
        className
      )}
    >
      <div onClick={() => history.push('/')}>
        <BBBLogo className="h-[40px]" />
      </div>
      <div className="flex gap-16 items-center">
        {items
          .filter((item) => !item.disabled)
          .map((item) => (
            <BBBTopNavItem
              key={item.path}
              path={item.path}
              title={item.title}
            />
          ))}
      </div>
      {!env.REACT_APP_EMBED_PREVIEW_MODE ? (
        <a
          href={window.location.origin + window.location.pathname}
          target="_blank"
          className="flex  items-center gap-1 text-neutral-60"
          rel="noreferrer"
        >
          <p className="text-neutral-60 underline">Go to full site</p>
          <LinkOutIcon color="#404040" />
        </a>
      ) : (
        <div />
      )}
    </div>
  );
};

export default BBBTopNav;
