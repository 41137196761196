import { useInfiniteQuery } from '@tanstack/react-query';

import customerServices from '@/api/services/customer';
import { GetTagPayload } from '@/api/services/customer/customer';
import { useActiveCompany } from '@/hooks/rtk/selector';

const useTags = (params?: Omit<GetTagPayload, 'cursor'>) => {
  const activeCompany = useActiveCompany();
  return useInfiniteQuery(
    ['tags', activeCompany, params],
    ({ pageParam }) =>
      customerServices.customer.getTags(activeCompany, {
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.meta.endCursor,
    }
  );
};

export default useTags;
