import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { RoleTypes } from '@/types/systemLogin';
import { toast } from '@/utils/common/toast';

type InvitePeopleReqBody = {
  invitedEmail: string;
  role: Exclude<RoleTypes, 'SUPER_ADMIN'>;
};

const useInvitePeople = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  return useMutation(
    async (payload: InvitePeopleReqBody) => {
      const { data } = await api.systemLogin.post(
        `/invitation/company/${activeCompany}`,
        payload
      );
      return data;
    },
    {
      onSuccess: (data, payload) => {
        toast.success(
          `An invitation email has been sent to ${payload.invitedEmail}`
        );
        client.invalidateQueries(['user-company']);
        client.invalidateQueries(['company-users']);
      },
    }
  );
};

export default useInvitePeople;
