import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { Navigation } from '@/types/bitApp/v2';
import { CompanyNavigationAssociation } from '@/types/bitApp/v2/association';

export default {
  getNavigations: () =>
    api.bitApp.get<ApiResponse<Navigation[]>>(`/navigation`),
  getNavigationByCompanyId: (companyId: number) =>
    api.bitApp
      .get<ApiResponse<CompanyNavigationAssociation[]>>(
        `/navigation/${companyId}`
      )
      .then((res) => {
        const data = res.data.data;
        return {
          active: data?.filter((navData) => navData.active === true),
          unActive: data?.filter((navData) => navData.active === false),
        };
      }),
  rearrangeNavigation: (
    companyId: number,
    payload: {
      reorderedData: {
        active: CompanyNavigationAssociation[];
        nonActive: CompanyNavigationAssociation[];
      };
    }
  ) => api.bitApp.post(`/navigation/${companyId}`, payload),
};
