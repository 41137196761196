export default function ComposeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        fill="#fff"
        clipPath="url(#clip0_909_25067)"
        filter="url(#filter0_d_909_25067)"
      >
        <path d="M2 11h20v7a2 2 0 01-2 2H4a2 2 0 01-2-2v-7zM2 6a2 2 0 012-2h16a2 2 0 012 2v3.5H2V6z"></path>
      </g>
      <defs>
        <filter
          id="filter0_d_909_25067"
          width="28"
          height="24"
          x="-2"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_909_25067"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_909_25067"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_909_25067">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
