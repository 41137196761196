import { Handle, NodeProps, Position } from 'reactflow';
import ThunderIcon from 'assets/icons/ThunderIcon';
import { integrationsMeta } from 'constants/integrations';
import { twMerge as cx } from 'tailwind-merge';
import useStore from '../../../store';
import { TriggerData } from '../../../types';
import { NodeCreator } from '../Edges/CustomEdge';
import { triggerOptions } from '../Modals/TriggerModal';
import NeedAction from './NeedAction';

import { BBBCard } from '@/components/ui';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import { cn } from '@/utils/styles';

export default function TriggerNode({
  data,
  id,
}: NodeProps<TriggerData | null>) {
  const hasEdges = useStore((state) =>
    state.edges.some((edge) => edge.source === id)
  );
  const isHeld = useStore((s) => s.connectingNode === id);
  const isDragGlobal = useStore((state) => !!state.draggedNode);
  const nodeErrors = useStore((s) => s.errorNode[id] || []);

  const isNodeError = !!nodeErrors.length;

  const expandState = useStore((state) => state.expandState?.[id] || null);
  const setExpandState = useStore((state) => state.setExpandState);

  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );

  const meta = data?.source ? integrationsMeta[data.source] : undefined;

  const Icon = meta?.icon;

  const connect = useConnectIntegration();

  const triggers = data?.source ? triggerOptions[data.source] : null;

  return (
    <div className="relative">
      <BBBCard
        className={cx(
          'w-96 group transition-colors',
          isNodeError && 'border-red-500',
          expandState === 'hover' && 'border-secondary-main',
          expandState === 'clicked' && 'border-secondary-main',
          isHeld && 'border-secondary-main'
        )}
        onClick={() => {
          if (nodeErrors.includes('not-connected')) {
            connect({ name: 'shopify', appType: 'BITCRM' });
          } else {
            onChangeTriggerModalState({ id });
          }
        }}
        onMouseEnter={() => {
          if (!isDragGlobal && expandState !== 'clicked') {
            setExpandState(id, 'hover');
          }
        }}
        onMouseLeave={() => {
          if (expandState !== 'clicked') {
            setExpandState(id, null);
          }
        }}
      >
        {data?.source ? (
          <div className="flex items-center gap-5">
            {Icon ? (
              <Icon size={'1.5rem'} />
            ) : (
              <div className="w-6 h-6 rounded-lg border border-neutral-30" />
            )}
            <div>
              <div className="text-xl text-neutral-60 mb-1.5">
                {meta?.title}
              </div>

              {data.source === 'custom_webhook' ? (
                <div
                  className={cn(
                    'text-neutral-50',
                    !data.triggerName && 'pointer-events-none opacity-0'
                  )}
                >
                  {data.triggerName || '1'}
                </div>
              ) : (
                <div
                  className={cn(
                    'text-neutral-50',
                    !data.triggerValue && 'pointer-events-none opacity-0'
                  )}
                >
                  {triggers?.find((opt) => opt.event === data.triggerValue)
                    ?.label || '1'}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-5">
            <ThunderIcon size={'1.5rem'} />
            <div>
              <div className="text-xl text-neutral-60 mb-1.5">Trigger</div>
              <div className="text-neutral-50">Click to select a trigger</div>
            </div>
          </div>
        )}
        <Handle
          type="source"
          position={Position.Right}
          className={'opacity-0 pointer-events-none'}
        />
      </BBBCard>
      {isNodeError && <NeedAction />}
      {!hasEdges && (
        <div className="absolute left-full top-1/2 -translate-y-1/2 flex items-center">
          <div className="h-[1px] w-24 bg-[#9E9E9E]" />
          <NodeCreator source={id} />
        </div>
      )}
    </div>
  );
}
