function PendingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        fill="#707070"
        d="M5.5.917a4.583 4.583 0 100 9.166 4.583 4.583 0 000-9.166zm0 8.25a3.667 3.667 0 110-7.334 3.667 3.667 0 010 7.333z"
      />
      <path
        fill="#707070"
        d="M7.334 5.042H5.959V3.667a.458.458 0 10-.917 0V5.5a.458.458 0 00.458.458h1.834a.458.458 0 100-.916z"
      />
    </svg>
  );
}

export default PendingIcon;
