export default function StampedIcon2({
  size = 24,
}: {
  size?: string | number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 769 785"
      width={size}
      height={size}
    >
      <path
        fill="#FF672E"
        fillRule="evenodd"
        d="M248.209 256.874l94.008-289.327h86.524l-94.008 289.327h-86.524z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFD600"
        fillRule="evenodd"
        d="M250.072 517.981L3.955 339.167l26.738-82.289L276.81 435.692l-26.738 82.289z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFB800"
        fillRule="evenodd"
        d="M498.195 596.628L252.078 775.442l-69.999-50.858L428.196 545.77l69.999 50.858z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFAA05"
        fillRule="evenodd"
        d="M650.109 384.377l94.008 289.327-70 50.857-94.008-289.327 70-50.857z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF883B"
        fillRule="evenodd"
        d="M495.821 174.582h304.217l26.736 82.289H522.558l-26.737-82.289z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
