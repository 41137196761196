import { usePricingFeatures } from './v2/pricing';

import { AppType } from '@/types/systemLogin';

const usePricingFeaturesByApp = (
  app: AppType,
  options?: { enabled?: boolean }
) => {
  return usePricingFeatures({ app }, options);
};

export default usePricingFeaturesByApp;
