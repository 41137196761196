import React, { Ref, useCallback, useMemo, useRef, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { createId } from '@paralleldrive/cuid2';
import { AnimatePresence } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { Tooltip } from '../BBBTooltip/BBBTooltip';

import CopyIcon from '@/assets/icons/CopyIcon';
import useResponsive from '@/hooks/common/useResponsive';

const getFormattedUrl = (oldUrl: string) => {
  const urlRegx = new RegExp(/:\/?\/?/);
  const subScheme = oldUrl.match(urlRegx);
  const splittedURL = oldUrl.split(urlRegx);
  let baseScheme = `https`;
  const isProtocolDefined = splittedURL.length > 1;
  if (isProtocolDefined) {
    [baseScheme] = splittedURL;
  }
  return `${baseScheme}${subScheme || '://'}${
    isProtocolDefined ? splittedURL[1] : splittedURL[0]
  }`;
};

export type HookFormTextInput<T extends FieldValues> = {
  isHookForm: true;
  control: Control<T, any>;
  controlName: Path<T>;
};

export type NormalTextInput = {
  isHookForm?: false;
  control?: never;
  controlName?: never;
};

export type MaxCharType =
  | {
      hasMaxCharLabel?: true;
      maxChar: number;
    }
  | {
      hasMaxCharLabel?: false;
      maxChar?: never;
    };

export type FixedInputType =
  | {
      isFixed: true;
      fixedLabel: string | React.ReactNode;
      fixedLabelClassname?: string;
    }
  | {
      isFixed?: false;
      fixedLabel?: never;
      fixedLabelClassname?: never;
    };

export type FixedSuffixInputType = {
  isFixedSuffix?: boolean;
  fixedSuffixLabel?: string | React.ReactNode;
  suffixWithoutBorder?: boolean;
};

export type BBBTextInputProps<T extends FieldValues> = (
  | HookFormTextInput<T>
  | NormalTextInput
) &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
  MaxCharType &
  FixedInputType &
  FixedSuffixInputType & {
    error?: string;
    label?: string | React.ReactNode;
    description?: string | React.ReactNode;
    labelClassname?: string;
    descriptionClassname?: string;
    containerClassname?: string;
    isUrl?: boolean;
    inputClassName?: string;
    customId?: string;
    ignoreFocus?: boolean;
    withCopy?: boolean;
    requiredLabel?: boolean;
    errorClassname?: string;
  };

const BBBTextInput = <T extends FieldValues>(
  {
    isHookForm = false,
    controlName,
    control,
    error,
    label,
    description,
    containerClassname,
    labelClassname,
    descriptionClassname,
    inputClassName,
    hasMaxCharLabel = false,
    maxChar,
    isUrl = false,
    isFixed,
    isFixedSuffix,
    suffixWithoutBorder,
    fixedLabelClassname,
    fixedSuffixLabel,
    fixedLabel,
    customId = createId(),
    ignoreFocus,
    withCopy,
    requiredLabel,
    errorClassname,
    ...inputProps
  }: BBBTextInputProps<T>,
  ref: Ref<HTMLInputElement>
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { className, onChange, onBlur, disabled, ...inputPropsExcluded } =
    inputProps;

  const [isFocus, setIsFocus] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const getFixedInputWrapperClassName = cx(
    'form-default form-transition',
    (isFixed || isFixedSuffix || hasMaxCharLabel) && 'bg-white',
    isFocus ? 'form-focus' : isHover && 'form-hover',
    disabled && 'opacity-50 pointer-events-none',
    className
  );

  const getInputClassName = cx(
    'flex-1 form-padding form-radius',
    inputClassName
  );

  const isMobile = useResponsive('sm');

  const renderFixedLabel = useMemo(
    () => (
      <>
        {typeof fixedLabel === 'string' ? (
          <div
            className={cx(
              'px-4 border-r py-2',
              isMobile ? 'flex-none w-fit truncate' : undefined,
              fixedLabelClassname
            )}
          >
            <span className="text-gray-400 font-medium whitespace-nowrap">
              {fixedLabel}
            </span>
          </div>
        ) : (
          fixedLabel
        )}
      </>
    ),
    [fixedLabel, fixedLabelClassname, isMobile]
  );

  const [isCopying, setIsCopying] = useState(false);
  const [isHoveringCopy, setIsHoveringCopy] = useState(false);

  const renderFixedSuffixLabel = useMemo(
    () =>
      isFixedSuffix || withCopy ? (
        <div
          className={cx(
            'px-4 flex items-center py-2',
            !suffixWithoutBorder && 'border-l',
            withCopy &&
              cx(
                'rounded-tr-lg rounded-br-lg transition-colors cursor-pointer relative',
                isCopying ? 'bg-[#DDDDDD]' : isHoveringCopy && 'bg-[#ECECEC]'
              )
          )}
          onClick={() => {
            if (withCopy && inputRef.current?.value) {
              navigator.clipboard.writeText(inputRef.current.value);
              setIsCopying(true);
              setTimeout(() => {
                setIsCopying(false);
              }, 2000);
            }
          }}
          onMouseEnter={() => setIsHoveringCopy(true)}
          onMouseLeave={() => setIsHoveringCopy(false)}
        >
          {withCopy && (
            <AnimatePresence>
              {isCopying && (
                <Tooltip
                  show
                  content="Copied"
                  style={{ top: 'unset', bottom: 'calc(100% - 4px)' }}
                />
              )}
            </AnimatePresence>
          )}
          {withCopy ? (
            <CopyIcon color="#757575" />
          ) : typeof fixedSuffixLabel === 'string' ? (
            <span className="text-gray-400 font-medium whitespace-nowrap">
              {fixedSuffixLabel}
            </span>
          ) : (
            <span>{fixedSuffixLabel}</span>
          )}
        </div>
      ) : null,
    [
      fixedSuffixLabel,
      isCopying,
      isFixedSuffix,
      isHoveringCopy,
      suffixWithoutBorder,
      withCopy,
    ]
  );

  const renderUnControlledMaxCharSuffix = useCallback(
    () => (
      <div className="px-4 py-2">
        <span className="opacity-25 text-primary-main text-sm whitespace-nowrap">
          {(
            (
              inputPropsExcluded as React.DetailedHTMLProps<
                React.InputHTMLAttributes<HTMLInputElement>,
                HTMLInputElement
              >
            ).value as string
          )?.length || 0}
          /{maxChar}
        </span>
      </div>
    ),
    [inputPropsExcluded, maxChar]
  );

  const renderControlledMaxCharSuffix = useCallback(
    (value: PathValue<T, Path<T>>) => (
      <div className="px-4 py-2">
        <span className="opacity-25 text-primary-main text-sm whitespace-nowrap">
          {value?.length ?? 0}/{maxChar}
        </span>
      </div>
    ),
    [maxChar]
  );

  const handleFocus = useCallback(() => {
    if (!ignoreFocus) {
      setIsFocus(true);
    }
  }, [ignoreFocus]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!ignoreFocus) {
        setIsFocus(false);
        if (
          (e.relatedTarget as HTMLElement | null)?.closest(
            `#input-wrapper-${customId}`
          )?.id === `input-wrapper-${customId}`
        ) {
          document.getElementById(`input-${customId}`)?.focus();
        }
      }
    },
    [customId, ignoreFocus]
  );

  const handleClick = useCallback(
    () => document.getElementById(`input-${customId}`)?.focus(),
    [customId]
  );

  const memoizedUnControlledInput = useCallback(
    () => (
      <div
        className={cx(`relative`, getFixedInputWrapperClassName)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={0}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
        id={`input-wrapper-${customId}`}
      >
        {isFixed && renderFixedLabel}
        <input
          className={cx(getInputClassName)}
          maxLength={maxChar || undefined}
          id={`input-${customId}`}
          {...inputPropsExcluded}
          onChange={onChange}
          onBlur={(prop) => {
            const {
              target: { value: targetValue, ...restTarget },
              ...restProp
            } = prop;
            if (isUrl && targetValue) {
              const formattedUrl = getFormattedUrl(targetValue);
              onChange?.({
                ...restProp,
                target: {
                  ...restTarget,
                  value: formattedUrl,
                },
              });
            }
            onBlur?.(prop);
          }}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              ref.current = node;
            }
          }}
        />
        {hasMaxCharLabel && renderUnControlledMaxCharSuffix()}
        {renderFixedSuffixLabel}
      </div>
    ),
    [
      customId,
      getFixedInputWrapperClassName,
      getInputClassName,
      handleBlur,
      handleClick,
      handleFocus,
      hasMaxCharLabel,
      inputPropsExcluded,
      isFixed,
      isUrl,
      maxChar,
      onBlur,
      onChange,
      ref,
      renderFixedLabel,
      renderFixedSuffixLabel,
      renderUnControlledMaxCharSuffix,
    ]
  );

  const memoizedControlledInput = useCallback(
    ({
      onChange,
      onBlur,
      value,
      ...rest
    }: ControllerRenderProps<T, Path<T>>) => (
      <div
        className={(cx(`relative`), getFixedInputWrapperClassName)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
        tabIndex={0}
        id={`input-wrapper-${customId}`}
      >
        {isFixed && renderFixedLabel}
        <input
          className={cx(getInputClassName)}
          maxLength={maxChar || undefined}
          id={`input-${customId}`}
          {...inputPropsExcluded}
          onChange={({ target: { value: targetValue } }) => {
            onChange(targetValue);
          }}
          onBlur={({ target: { value: targetValue } }) => {
            if (isUrl) {
              const formattedUrl = getFormattedUrl(targetValue);
              onChange(formattedUrl);
            }
            onBlur();
          }}
          value={value}
          {...rest}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              ref.current = node;
            }
          }}
        />
        {isFixedSuffix && renderFixedSuffixLabel}
        {hasMaxCharLabel && renderControlledMaxCharSuffix(value)}
      </div>
    ),
    [
      getFixedInputWrapperClassName,
      handleFocus,
      handleBlur,
      handleClick,
      customId,
      isFixed,
      renderFixedLabel,
      getInputClassName,
      maxChar,
      inputPropsExcluded,
      ref,
      isFixedSuffix,
      renderFixedSuffixLabel,
      hasMaxCharLabel,
      renderControlledMaxCharSuffix,
      isUrl,
    ]
  );

  const input = useMemo(() => {
    if (isHookForm) {
      return (
        <Controller
          name={controlName as Path<T>}
          control={control}
          render={({ field }) => memoizedControlledInput(field)}
        />
      );
    }
    return memoizedUnControlledInput();
  }, [
    control,
    controlName,
    isHookForm,
    memoizedControlledInput,
    memoizedUnControlledInput,
  ]);

  return (
    <div className={cx(`mb-3`, containerClassname)}>
      {label && (
        <div className={cx('text-ls text-primary-main', labelClassname)}>
          {label}
          {requiredLabel && <span className="text-danger-main">*</span>}
        </div>
      )}
      {input}
      {description && (
        <div className={`${descriptionClassname ? 'font-bold' : ''}`}>
          {description}
        </div>
      )}
      {!!error && (
        <div className={cx('text-danger-main', errorClassname)}>{error}</div>
      )}
    </div>
  );
};

export default React.forwardRef(BBBTextInput) as <T extends FieldValues>(
  p: BBBTextInputProps<T> & { ref?: Ref<HTMLInputElement> }
) => React.ReactElement;
