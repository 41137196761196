import usePricingByApp from './usePricingByApp';
import usePricingFeaturesByApp from './usePricingFeaturesByApp';
import usePricingOptions from './usePricingOptionsByApp';

import {
  TRESHOLD_1_PERCENTAGE,
  TRESHOLD_2_PERCENTAGE,
  TRESHOLD_3_PERCENTAGE,
} from '@/constants/pricing';
import { AppType } from '@/types/systemLogin';

const usePricingTreshold = (appType: AppType) => {
  const { data: pricingData } = usePricingByApp(appType);

  const { data: pricingOptions } = usePricingOptions(
    appType,
    //@ts-ignore
    pricingData?.type,
    { enabled: !!pricingData }
  );

  const { data: pricingFeatures } = usePricingFeaturesByApp(appType);

  const nextFeature =
    pricingData?.pricingName && pricingOptions
      ? pricingOptions[
          pricingOptions.findIndex(
            (opt) => opt.name === pricingData.pricingName
          ) + 1
        ]
      : undefined;

  const nextPrice =
    pricingOptions && nextFeature
      ? //@ts-ignore
        pricingOptions.find(
          //@ts-ignore
          (opt) => opt.name === nextFeature.name
        )?.price
      : 'loading..';

  const mapFeatureUsagePercentage = pricingFeatures
    ? pricingFeatures.map((feature) => {
        const limit =
          //@ts-ignore
          feature.pricingFeature.pricingFeatureDetails.find(
            //@ts-ignore
            (detail) => detail.pricingName === pricingData?.pricingName
          )?.limit ?? 0;

        return {
          featureName: feature.pricingFeature.label,
          percentage: (feature.usage / limit) * 100,
          remaining: limit - feature.usage,
          limit,
          isReset: feature.pricingFeature.isReset,
        };
      })
    : [];

  const limitTreshold = mapFeatureUsagePercentage.reduce<{
    count: number;
    feature?: typeof mapFeatureUsagePercentage[number][];
  }>(
    (prev, curr) =>
      curr.percentage >= TRESHOLD_3_PERCENTAGE
        ? {
            count: prev.count + 1,
            feature: [...(prev.feature ?? []), curr],
          }
        : prev,
    { count: 0 }
  );

  const lowerLimitTreshold = mapFeatureUsagePercentage.reduce<{
    count: number;
    feature?: typeof mapFeatureUsagePercentage[number][];
  }>(
    (prev, curr) =>
      curr.percentage >= TRESHOLD_2_PERCENTAGE(curr.limit) &&
      curr.percentage < TRESHOLD_3_PERCENTAGE
        ? {
            count: prev.count + 1,
            feature: [...(prev.feature ?? []), curr],
          }
        : prev,
    { count: 0 }
  );

  const halfLimitTreshold = mapFeatureUsagePercentage.reduce(
    (prev, curr) =>
      curr.percentage >= TRESHOLD_1_PERCENTAGE &&
      curr.percentage < TRESHOLD_2_PERCENTAGE(curr.limit)
        ? prev + 1
        : prev,
    0
  );

  return {
    lowerLimitTreshold,
    halfLimitTreshold,
    limitTreshold,
    pricingData,
    next: {
      name: nextFeature ? nextFeature.label : 'loading..',
      price: nextPrice,
    },
  };
};

export default usePricingTreshold;
