import ItemCard from './ItemCard';

import LocationIcon2 from '@/assets/icons/LocationIcon2';
import { BBBCard } from '@/components/ui';
import {
  useLoginSettingByType,
  useUpsertLoginSetting,
} from '@/hooks/bitLogin/login/login-settings';

export default function AddressCard() {
  const { active: addressActive, mandatory: addressMandatory } =
    useLoginSettingByType('address');

  const { mutate: updateLoginSetting } = useUpsertLoginSetting();

  return (
    <BBBCard title="Address">
      <ItemCard
        icon={<LocationIcon2 />}
        title="Address"
        active={addressActive || false}
        hasRequired
        required={addressMandatory || false}
        onRequiredChange={(val) =>
          updateLoginSetting({
            mandatory: val,
            type: 'address',
            group: 'address',
          })
        }
        onActiveChange={(val) =>
          updateLoginSetting({
            active: val,
            type: 'address',
            group: 'address',
          })
        }
      />
    </BBBCard>
  );
}
