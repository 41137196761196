import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Control, Controller } from 'react-hook-form';
import { FormSchema } from '..';

import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import NewBadge from '@/components/Badge/NewBadge';
import {
  BBBButton,
  BBBCard,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';
import { socialMeta, SocialType, SocialValues } from '@/constants/social';
import { arrayMove } from '@/utils/common/array';

export default function SocialMedia({
  control,
}: {
  control: Control<FormSchema>;
}) {
  return (
    <BBBCard
      title={
        <>
          Social media <NewBadge />
        </>
      }
      desc="Let customer reach you through social media"
      className="mb-cardBottom"
    >
      <Controller
        control={control}
        name="social"
        render={({ field }) => (
          <Social
            values={field.value.values}
            order={field.value.socialOrders}
            onChangeOrder={(value) =>
              field.onChange({ ...field.value, socialOrders: value })
            }
            onChangeValues={(value) =>
              field.onChange({ ...field.value, values: value })
            }
          />
        )}
      />
    </BBBCard>
  );
}

function Social({
  values,
  order,
  onChangeValues,
  onChangeOrder,
}: {
  values?: SocialValues;
  order?: SocialType[] | null;
  onChangeOrder?: (val: SocialType[]) => void;
  onChangeValues?: (val: SocialValues) => void;
}) {
  const [editing, setEditing] = useState<SocialType | null>(null);

  const socials = sortSocialMeta(socialMeta, order);

  const [localInput, setLocalInput] = useState<Record<
    SocialType,
    string
  > | null>(null);

  useEffect(() => {
    const _values = Object.entries(values || {}).map(([type, values]) => [
      type,
      values.value,
    ]);

    setLocalInput(Object.fromEntries(_values));
  }, [values]);

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const oldSocial = Array.from(socials);

        if (result.destination) {
          arrayMove(oldSocial, result.source.index, result.destination.index);

          onChangeOrder?.(oldSocial.map((old) => old.value));
        }
      }}
    >
      <Droppable droppableId="widget-social-droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {socials.map((social, idx) => {
              const value = values?.[social.value];

              return (
                <Draggable
                  draggableId={`widget-social-${social.value}`}
                  key={social.value}
                  index={idx}
                >
                  {(providedDraggable) => (
                    <BBBCard
                      className="mb-5"
                      onClick={() => {
                        if (!editing || editing !== social.value) {
                          setEditing((prev) =>
                            prev === social.value ? null : social.value
                          );
                        }
                      }}
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                    >
                      <div className="flex items-center gap-5 cursor-pointer">
                        <social.icon />
                        <div className="grow">
                          {editing === social.value ? (
                            <div className="">{social.label}</div>
                          ) : value?.value ? (
                            <div className="font-medium text-sm">
                              {value.value}
                            </div>
                          ) : (
                            <div className="text-neutral-40 text-sm">
                              {social.value === 'email'
                                ? 'Add email address'
                                : 'Add username or URL'}
                            </div>
                          )}
                        </div>
                        <BBBPrimarySwitch
                          checked={value?.enabled}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(val) => {
                            onChangeValues?.({
                              ...values,
                              [social.value]: {
                                value: localInput?.[social.value] || '',
                                enabled: val,
                              },
                            });
                          }}
                        />
                        <div
                          className="cursor-grab"
                          {...providedDraggable.dragHandleProps}
                        >
                          <BBBDraggableIcon />
                        </div>
                      </div>

                      {editing === social.value && (
                        <>
                          <div className="mt-5">
                            {social.prefix ? (
                              <BBBTextInput
                                isFixed
                                fixedLabel={social.prefix + '/'}
                                placeholder="yoursocial"
                                onChange={({ target: { value } }) => {
                                  setLocalInput((prev) =>
                                    prev
                                      ? {
                                          ...prev,
                                          [social.value]: value,
                                        }
                                      : null
                                  );
                                }}
                                value={localInput?.[social.value]}
                              />
                            ) : (
                              <BBBTextInput
                                placeholder="yoursocial"
                                onChange={({ target: { value } }) => {
                                  setLocalInput((prev) =>
                                    prev
                                      ? {
                                          ...prev,
                                          [social.value]: value,
                                        }
                                      : null
                                  );
                                }}
                                value={localInput?.[social.value]}
                              />
                            )}
                          </div>
                          <div className="mt-5 flex justify-end items-center gap-4">
                            <BBBButton
                              variant="secondary"
                              onClick={() => setEditing(null)}
                            >
                              Cancel
                            </BBBButton>
                            <BBBButton
                              onClick={() => {
                                onChangeValues?.({
                                  ...values,
                                  [social.value]: {
                                    ...values?.[social.value],
                                    value: localInput?.[social.value] || '',
                                  },
                                });
                                setEditing(null);
                              }}
                            >
                              Save
                            </BBBButton>
                          </div>
                        </>
                      )}
                    </BBBCard>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export function sortSocialMeta(
  meta: typeof socialMeta,
  order?: SocialType[] | null
) {
  if (!order) {
    return meta;
  }

  const orderMap = new Map(order.map((item, index) => [item, index]));

  return [...meta].sort((a, b) => {
    const indexA = orderMap.get(a.value);
    const indexB = orderMap.get(b.value);

    if (indexA === undefined) return 1;
    if (indexB === undefined) return -1;

    return indexA - indexB;
  });
}
