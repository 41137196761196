import { useQuery } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';

const useTemplates = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['bitlogin-templates'], () =>
    services.bitLogin.template.getTemplates(activeCompany)
  );
};

export default useTemplates;
