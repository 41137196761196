import React from 'react';

type Props = {
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function CustomerCartIcon({ className, ...props }: Props) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M5.0332 6.04492H18.978C19.2605 6.04493 19.54 6.10415 19.7982 6.21876C20.0565 6.33336 20.2879 6.50082 20.4775 6.71032C20.6671 6.91983 20.8107 7.16673 20.899 7.43513C20.9873 7.70352 21.0184 7.98745 20.9903 8.2686L20.3836 14.3359C20.3337 14.835 20.1001 15.2977 19.7283 15.6342C19.3564 15.9708 18.8728 16.1571 18.3712 16.1572H8.71406C8.24634 16.1573 7.79301 15.9954 7.43126 15.6989C7.06952 15.4025 6.82171 14.9898 6.73004 14.5311L5.0332 6.04492Z"
        stroke="#262627"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.03367 6.04489L4.21458 2.7655C4.1598 2.54682 4.03352 2.35272 3.85581 2.21403C3.67809 2.07534 3.45912 2.00001 3.23369 2H2M8.06734 20.202H10.0898M16.1571 20.202H18.1796"
        stroke="#262627"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CustomerCartIcon;
