import { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch } from '@/hooks/rtk/store';
import {
  ConfirmationBannerStoreTypes,
  triggerConfirmationBanner,
} from '@/stores/common';

const useConfirmationBanner = () => {
  const dispatch = useAppDispatch();
  const activeKeys = useRef<Record<string, number>>({});

  /**
   * @deprecated
   * This function call will only be used inside the hook
   */
  const hide = useCallback(
    (id: string) =>
      dispatch(
        triggerConfirmationBanner({
          [id]: {
            show: false,
          },
        })
      ),
    [dispatch]
  );

  const toggle = useCallback(
    (id: string, payload: ConfirmationBannerStoreTypes) => {
      activeKeys.current = {
        ...activeKeys.current,
        [id]: (activeKeys.current[id] || 0) + 1,
      };
      dispatch(
        triggerConfirmationBanner({
          [id]: payload,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      Object.keys(activeKeys.current).forEach((id) => {
        hide(id);
      });
    };
  }, [hide]);

  return { toggle, hide };
};

export default useConfirmationBanner;
