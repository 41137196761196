export const BANNER_INFORMATION = [
  {
    id: `analytics-outage-10/26`,
    text: 'bitbybit is experiencing analytics service outage with all analytics related feature are affected. We are deeply apologize for the inconvenient',
    isExpired: true,
  },
  {
    id: `kubernetes-upgrade-12/1`,
    text: 'bitbybit will undergo scheduled system maintenance on December 1st at 2PM UTC for up to 1 hour. Some features might be temporarily unavailable during this time. We apologize for any inconvenience.',
    isExpired: true,
  },
];
