import { createId } from '@paralleldrive/cuid2';
import {
  ButtonCtaType,
  MessageTemplateButtonType,
} from 'types/bitCRM/template';

export const statusOptions = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
];

export const PREFILLED_ONBOARDING = {
  id: createId(),
  campaignName: 'Payment reminders',
  body: `Hi, {{order.customer.name}}. We wanted to remind you that your payment for your recent purchase is due soon. Please make sure to complete the payment by the specified due date to avoid any delays in processing your order. If you have any questions or need assistance, feel free to reach out to our customer support team.`,
};

export const buttonTypeOptions: {
  label: string;
  value: MessageTemplateButtonType;
}[] = [
  {
    label: 'Reply button',
    value: 'reply_button',
  },
  {
    label: 'WhatsApp Form',
    value: 'whatsapp_form',
  },
  {
    label: 'Call to action',
    value: 'call_to_action',
  },
];

export const buttonTypeOptionsHash = Object.fromEntries(
  buttonTypeOptions.map((d) => [d.value, d])
);

export const callToActionOptionsWebUrl: {
  label: string;
  value: ButtonCtaType;
}[] = [
  {
    label: 'Web URL',
    value: 'web_url',
  },
];

export const callToActionsOptions: {
  label: string;
  value: ButtonCtaType;
}[] = [
  ...callToActionOptionsWebUrl,
  {
    label: 'Specific product',
    value: 'product',
  },
  {
    label: 'Specific collections',
    value: 'collections',
  },
];

export const callToActionsOptionsHash = Object.fromEntries(
  callToActionsOptions.map((d) => [d.value, d])
);

export const automationButtonContentOptions = [
  { label: 'Order url', value: 'order_url' },
  { label: 'Review url', value: 'review_url' },
  { label: 'Abandoned cart url', value: 'abandoned_cart_url' },
];

export const automationButtonContentOptionsHash = Object.fromEntries(
  automationButtonContentOptions.map((d) => [d.value, d])
);

export const campaignDynamicVariables = [
  {
    group: 'customer',
    label: 'Customer name',
    value: '{{customer.fullName}}',
    placeholder: 'John Doe',
  },
  {
    group: 'customer',
    label: 'Customer first name',
    value: '{{customer.firstName}}',
    placeholder: 'John',
  },
  {
    group: 'customer',
    label: 'Customer last name',
    value: '{{customer.lastName}}',
    placeholder: 'Doe',
  },
  {
    group: 'customer',
    label: 'Customer email',
    value: '{{customer.email}}',
    placeholder: 'john@gmail.com',
  },
  {
    group: 'customer',
    label: 'Customer phone',
    value: '{{customer.phone}}',
    placeholder: '6281234567890',
  },
  {
    group: 'customer',
    label: 'Address1',
    value: '{{customer.address1}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'customer',
    label: 'Address2',
    value: '{{customer.address2}}',
    placeholder: 'Pattimura Street number 500',
  },
  {
    group: 'customer',
    label: 'Company',
    value: '{{customer.company}}',
    placeholder: 'bitbybit',
  },
  {
    group: 'customer',
    label: 'Country',
    value: '{{customer.country}}',
    placeholder: 'Indonesia',
  },
  {
    group: 'customer',
    label: 'Province',
    value: '{{customer.province}}',
    placeholder: 'East Java',
  },
  {
    group: 'customer',
    label: 'City',
    value: '{{customer.city}}',
    placeholder: 'Jakarta',
  },
  {
    group: 'customer',
    label: 'Zip code',
    value: '{{customer.zip}}',
    placeholder: '12110',
  },
  {
    group: 'customer',
    label: 'Shipping Address',
    value: '{{customer.shippingAddress}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'customer',
    label: 'Billing Address',
    value: '{{customer.billingAddress}}',
    placeholder: 'Hasanudin Street number 499',
  },
];

export const automationVariables = [
  {
    group: 'all',
    label: 'Order Code / Name',
    value: '{{order.name}}',
    placeholder: 'OB12700',
  },
  {
    group: 'all',
    label: 'Total Price',
    value: '{{order.totalPrice}}',
    placeholder: '4000$',
  },
  {
    group: 'all',
    label: 'Order Note',
    value: '{{order.note}}',
    placeholder: 'Please read this order notes',
  },
  {
    group: 'all',
    label: 'Item Lines',
    value: '{{order.itemLines}}',
    placeholder:
      '1. Extra Shipping Cost - Cost: 85k - Rp. 85.000,00 x 1\u003Cbr\u003E2. Black T-Shirt - 10$ x 1',
  },
  {
    group: 'all',
    label: 'Order Link',
    value: '{{order.link}}',
    placeholder: 'http://rebrand.ly/qeozz4x',
  },
  {
    group: 'all',
    label: 'Order Properties Label',
    value: '{{order.itemLines.properties.name[index]}}',
    placeholder: 'Recipients : ',
  },
  {
    group: 'all',
    label: 'Order Properties Value',
    value: 'order.itemLines.properties.value[index]',
    placeholder: 'Mr. John Doe',
  },
  {
    group: 'customer',
    label: 'Customer full name',
    value: '{{order.customer.name}}',
    placeholder: 'John Doe',
  },
  {
    group: 'customer',
    label: 'Customer first name',
    value: '{{order.customer.firstName}}',
    placeholder: 'John',
  },
  {
    group: 'customer',
    label: 'Customer last name',
    value: '{{order.customer.lastName}}',
    placeholder: 'Doe',
  },
  {
    group: 'customer',
    label: 'Customer email',
    value: '{{order.customer.email}}',
    placeholder: 'john@gmail.com',
  },
  {
    group: 'customer',
    label: 'Customer phone',
    value: '{{order.customer.phone}}',
    placeholder: '628xxxxxxxxxx',
  },
  {
    group: 'customer',
    label: 'Customer address',
    value: '{{order.customer.address}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping full name',
    value: '{{order.shippingAddress.name}}',
    placeholder: 'John Doe',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping first name',
    value: '{{order.shippingAddress.firstName}}',
    placeholder: 'John',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping last name',
    value: '{{order.shippingAddress.lastName}}',
    placeholder: 'Doe',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping address 1',
    value: '{{order.shippingAddress.address1}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping address 2',
    value: '{{order.shippingAddress.address2}}',
    placeholder: 'Pattimura Street number 12',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping country',
    value: '{{order.shippingAddress.country}}',
    placeholder: 'Indonesia',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping province',
    value: '{{order.shippingAddress.province}}',
    placeholder: 'East Java',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping city',
    value: '{{order.shippingAddress.city}}',
    placeholder: 'Jakarta',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping zip / postal code',
    value: '{{order.shippingAddress.zip}}',
    placeholder: '12110',
  },
  {
    group: 'payment',
    label: 'Payment Status',
    value: '{{order.financialStatus}}',
    placeholder: 'paid',
  },
  {
    group: 'payment',
    label: 'Order Gateway',
    value: '{{order.gateway}}',
    placeholder: 'Bca_Xendit',
  },
  {
    group: 'payment',
    label: 'Payment Method',
    value: '{{order.paymentGatewayNames}}',
    placeholder: 'Bca_Xendit',
  },
  {
    group: 'fulfillment',
    label: 'Tracking company',
    value: '{{order.fulfillments.name}}',
    placeholder: 'JNE',
  },
  {
    group: 'fulfillment',
    label: 'Tracking number',
    value: '{{order.fulfillments.trackingNumber}}',
    placeholder: 'XXXXXXXX',
  },
  {
    group: 'fulfillment',
    label: 'Tracking url',
    value: '{{order.fulfillments.trackingUrl}}',
    placeholder: 'https://www.ups.com/mobile/track?trackingNumber={XXXXX}',
  },
  {
    group: 'fulfillment',
    label: 'Delivery status',
    value: '{{order.fulfillments.status}}',
    placeholder: 'send',
  },
  {
    group: 'abandoned_cart',
    label: 'Abandoned Cart Url',
    value: '{{order.abandonedCartUrl}}',
    placeholder: 'http://rebrand.ly/qeozz4x',
  },
  {
    group: 'discount',
    label: 'Voucher Code',
    value: '{{voucher.code}}',
    placeholder: 'FAVO1234',
  },
  {
    group: 'review',
    label: 'Review Url',
    value: '{{reviewUrl}}',
    placeholder: 'reviewurl.example.com/token',
  },
];

export const chatInitiationVariables = [
  {
    group: 'customer',
    label: 'Customer name',
    value: '{{customer.name}}',
    placeholder: 'John Doe',
  },
];

export const bitLoginVariables = [
  {
    group: 'otp',
    label: 'OTP Code',
    value: '{{otp.code}}',
    placeholder: '123456',
  },
];
