import { ApiResponse } from 'api/types';
import api from 'config/api';
import { Chat } from 'types/whatsApp/chat';

export default {
  startChat: ({
    apiKey,
    conversationId,
    sources,
    ...payload
  }: StartChatParams) =>
    api.whatsApp.v2
      .post<ApiResponse<Chat>>(
        `/chat/${sources}/${conversationId}/start`,
        payload,
        {
          headers: {
            'x-bitbybit-key': apiKey,
          },
        }
      )
      .then((res) => res.data.data),
  getChat: ({ apiKey, conversationId, sources }: GetChatParams) =>
    api.whatsApp.v2
      .get<ApiResponse<Chat>>(`/chat/${sources}/${conversationId}`, {
        headers: {
          'x-bitbybit-key': apiKey,
        },
      })
      .then((res) => res.data.data),
};

export type GetChatParams = {
  apiKey: string;
  conversationId: string;
  sources: Chat['sources'];
};

export type StartChatParams = {
  apiKey: string;
  conversationId: string;
  sources: Chat['sources'];
  templateId?: string;
  message?: string;
  temporaryId: string;
  source?: 'default' | 'send_message';
  user?: {
    userAccent: string | null;
    userId: number;
    userDisplayName: string | null;
    userEmail: string;
    userProfilePicture: string | null;
  };
};
