import { useQuery } from '@tanstack/react-query';

import pixelServices from '@/api/services/pixel';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useWebActivity = (
  params: Parameters<typeof pixelServices.pixel.getWebActivity>[1]
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['web-activity', params],
    () => pixelServices.pixel.getWebActivity(activeCompany!, params),
    {
      enabled: !!params,
      onError: () => {
        toast.error('Error while fetching web activity');
      },
    }
  );
};

export default useWebActivity;
