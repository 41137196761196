import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import services from '@/api/services';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';

const useDeleteResource = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const toggleConfirmation = useConfirmationModal();
  const client = useQueryClient();

  const deleteResourceMutation = useMutation(
    ({ documentId }: { documentId: string }) =>
      services.bitChat.autopilot.deleteResource(activeCompany, documentId),
    {
      onSuccess: (_, payload) => {
        client.invalidateQueries(['auto-pilot-rules']);
      },
    }
  );

  function deleteDocument(withConfirmation: true): (param: string) => void;
  function deleteDocument(withConfirmation: false): UseMutationResult<
    AxiosResponse<any>,
    any,
    {
      documentId: string;
    },
    unknown
  >;
  function deleteDocument(withConfirmation: true | false):
    | ((param: string) => void)
    | UseMutationResult<
        AxiosResponse<any>,
        any,
        {
          documentId: string;
        },
        unknown
      > {
    return withConfirmation
      ? (documentId) =>
          toggleConfirmation({
            title: 'Delete document?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteResourceMutation.mutate(
                { documentId },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteResourceMutation;
  }

  return deleteDocument;
};

export default useDeleteResource;
