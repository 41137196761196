import { useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { useActiveCompany } from '../rtk/selector';

export const useAccounts = () => {
  const activeCompany = useActiveCompany();

  return useQuery(['google-sheets-accounts', activeCompany], () =>
    services.bitChat['google-sheets'].getAccounts(activeCompany)
  );
};

export const useSpreadsheets = (
  email: string,
  params?: { search?: string }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['google-sheets-spreadsheets', activeCompany, email, params],
    () =>
      services.bitChat['google-sheets'].getSpreadsheets(
        activeCompany,
        email,
        params
      )
  );
};

export const useWorksheets = (
  email: string,
  spreadsheetId?: string,
  params?: { search?: string }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['google-sheets-worksheets', activeCompany, email, spreadsheetId, params],
    () =>
      services.bitChat['google-sheets'].getWorksheets(
        activeCompany,
        email,
        spreadsheetId!,
        params
      ),
    { enabled: typeof spreadsheetId !== 'undefined' }
  );
};
