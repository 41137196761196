import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { triggerRefreshIframe } from '@/stores/bitLink';
import { Subscriptions } from '@/types/bitLink/v2';

const useEditSubscription = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async (payload: Partial<Subscriptions>) => {
      const { data } = await api.bitLink.post(
        `/subscription/${activeCompany}`,
        payload
      );
      return data;
    },
    {
      onMutate: async (payload) => {
        await client.cancelQueries(['bitlink-subscription', activeCompany]);

        const previousAppearance = client.getQueryData([
          'bitlink-subscription',
          activeCompany,
        ]);

        client.setQueryData(
          ['bitlink-subscription', activeCompany],
          (old: Partial<Subscriptions> | undefined) => ({
            ...(old || {}),
            ...payload,
          })
        );

        return { previousAppearance };
      },
      onError: (err, __, context: any) => {
        client.setQueryData(
          ['bitlink-subscription', activeCompany],
          context.previousAppearance
        );
      },
      onSettled: () => {
        dispatch(triggerRefreshIframe());
      },
    }
  );
};

export default useEditSubscription;
