import React from 'react';

export default function MultipleIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1111 6.66667H12.8889C9.45245 6.66667 6.66667 9.45245 6.66667 12.8889V27.1111C6.66667 30.5476 9.45245 33.3333 12.8889 33.3333H27.1111C30.5476 33.3333 33.3333 30.5476 33.3333 27.1111V12.8889C33.3333 9.45245 30.5476 6.66667 27.1111 6.66667ZM12.8889 4C7.97969 4 4 7.97969 4 12.8889V27.1111C4 32.0203 7.97969 36 12.8889 36H27.1111C32.0203 36 36 32.0203 36 27.1111V12.8889C36 7.97969 32.0203 4 27.1111 4H12.8889Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5968 12.1983C29.1498 12.5691 29.308 13.3343 28.9502 13.9074L20.7392 27.0589C20.0518 28.1599 18.5598 28.3237 17.6651 27.3964L11.2052 20.7015C10.7395 20.2189 10.7395 19.4363 11.2052 18.9537C11.671 18.471 12.426 18.471 12.8917 18.9537L19.0039 25.2882L26.9478 12.5645C27.3056 11.9915 28.0439 11.8275 28.5968 12.1983Z"
        fill="black"
      />
    </svg>
  );
}
