import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { SocialSettings } from '@/types/bitLogin/v2';

const useUpsertSettings = () => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<SocialSettings>) =>
      services.bitLogin.settings.upsertSocialSettings(data?.domain, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-settings']);
      },
    }
  );
};

export default useUpsertSettings;
