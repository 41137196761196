import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActiveCompany } from '../rtk/selector';

import services from '@/api/services';
import { useAppDispatch } from '@/hooks/rtk/store';
import { setConnectIntegration } from '@/stores/common';
import { toast } from '@/utils/common/toast';

const useSaveWhatsAppCloud = () => {
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (id: string) => {
      return services.whatsApp.chat.saveWhatsAppCloudNumber(activeCompany, id);
    },
    {
      onSuccess: () => {
        toast.success('Success connect with WhatsApp number');
        setTimeout(() => {
          queryClient.invalidateQueries(['whatsappp-cloud-list']);
          queryClient.invalidateQueries([`bitchat-company-integrations`]);
        }, 500);
        dispatch(setConnectIntegration(null));
      },
    }
  );
};

export default useSaveWhatsAppCloud;
