import axios, { AxiosInstance } from 'axios';

import { systemLoginApiBaseUrl } from '@/constants/api';
import { rootStore } from '@/stores';
import { logout } from '@/stores/auth';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';

export const interceptRequest = (axios: AxiosInstance) =>
  axios.interceptors.request.use(
    (config) => {
      let headers = { ...config.headers };
      const token = _localStorage.getItem('accessToken');
      if (token)
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`,
        };
      config.headers = headers;
      return config;
    },
    (error) => Promise.reject(error)
  );

let toastExpiredCounter = 0;

export const interceptResponse = (axios1: AxiosInstance) =>
  axios1.interceptors.response.use(
    (res) => res,
    (error) => {
      const clear = () => {
        if (toastExpiredCounter === 0) {
          rootStore.dispatch(logout());
          toast.error('Your session has expired. Please login again.');
          toastExpiredCounter += 1;
        }
      };

      const isGatewayTokenExpired =
        axios.isAxiosError(error) &&
        error.response?.status === 500 &&
        error.response?.data?.message?.startsWith('JWT expired');

      const isSystemLoginTokenExpired =
        axios.isAxiosError(error) &&
        error.response?.config.baseURL === systemLoginApiBaseUrl &&
        error.response.status === 403;

      if (isGatewayTokenExpired || isSystemLoginTokenExpired) {
        clear();
      }

      return Promise.reject(error);
    }
  );

export const intercepts = (axios: AxiosInstance) => {
  interceptRequest(axios);
  interceptResponse(axios);

  return axios;
};
