import { useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { twMerge as cx } from 'tailwind-merge';
import { MediaLoading } from '..';

import { useAppDispatch } from '@/hooks/rtk/store';
import { setZoom } from '@/stores/bitCRM';
import { MessageNewAssociation } from '@/types/whatsApp/v3';

export default function ChatBubbleImage({
  chat,
  isQuotedLevel,
  onWidthChange,
  onImageError,
  imageError,
  mediaUrl,
  isActualImage,
  loadingMedia,
  fromMe,
}: {
  chat: MessageNewAssociation;
  isQuotedLevel: boolean;
  onWidthChange?: (val: number | undefined) => void;
  onImageError?: (val: boolean) => void;
  imageError?: boolean;
  mediaUrl?: string;
  isActualImage?: boolean;
  loadingMedia: boolean;
  fromMe: boolean;
}) {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (!isQuotedLevel && isActualImage) {
      dispatch(
        setZoom({
          status: true,
          chatId: chat.messageId2,
        })
      );
    }
  };

  const media = chat.media || mediaUrl;

  const { width, ref: imgRef } = useResizeDetector();

  const changedRef = useRef(false);

  useEffect(() => {
    if (!isQuotedLevel) {
      if (!changedRef.current && width) {
        changedRef.current = true;
        onWidthChange?.(width > 260 ? width : 260);
      }
    }
  }, [isQuotedLevel, onWidthChange, width]);

  if (imageError) return null;

  return (
    <div
      className={cx(
        chat.type === 'STICKER' ? 'pointer-events-none' : 'pointer-events-auto',
        !isQuotedLevel ? 'mb-1' : 'my-1.5'
      )}
    >
      {loadingMedia ? (
        <MediaLoading fromMe={fromMe} />
      ) : !media ? null : (
        <img
          src={media}
          className={cx(
            `w-full cursor-pointer object-cover`,
            isQuotedLevel
              ? 'rounded w-8 h-8'
              : 'rounded-lg max-w-[256px] max-h-96'
          )}
          alt="media"
          loading="lazy"
          onClick={handleClick}
          ref={imgRef}
          onError={() => onImageError?.(true)}
        />
      )}
    </div>
  );
}
