import { SVGProps } from 'react';

function ShopifyIcon({
  size = 24,
  ...props
}: { size?: string | number } & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 27"
      {...props}
    >
      <g>
        <path
          fill="#95BF46"
          d="M20.977 5.3c-.016-.132-.137-.206-.239-.214-.097-.012-2.191-.164-2.191-.164l-1.613-1.578c-.16-.16-.47-.114-.59-.078l-.817.25C15.043 2.136 14.187.87 12.68.87c-.043 0-.086 0-.13.004-.429-.559-.96-.805-1.417-.805C7.62.07 5.94 4.402 5.418 6.602a572.54 572.54 0 00-2.457.753c-.766.235-.79.258-.887.97-.078.534-2.07 15.738-2.07 15.738l15.531 2.87 8.414-1.796S20.996 5.437 20.977 5.3zm-6.305-1.523l-1.317.403c0-.094.004-.184.004-.282 0-.855-.12-1.546-.316-2.093.777.097 1.293.968 1.629 1.972zm-2.594-1.8c.219.53.36 1.3.36 2.332 0 .054 0 .101-.004.152-.852.258-1.782.543-2.711.828.52-1.988 1.5-2.95 2.355-3.312zM11.035 1c.152 0 .305.05.453.152-1.129.52-2.332 1.836-2.843 4.465L6.5 6.273C7.098 4.27 8.516 1 11.035 1zm0 0"
        ></path>
        <path
          fill="#5E8E3E"
          d="M20.738 5.086c-.097-.012-2.191-.164-2.191-.164l-1.613-1.578a.396.396 0 00-.223-.106l-1.176 23.696 8.414-1.797S20.996 5.437 20.977 5.3c-.016-.133-.137-.207-.239-.215"
        ></path>
        <path
          fill="#FFF"
          d="M12.68 9.672l-1.04 3.043s-.91-.477-2.023-.477c-1.633 0-1.715 1.008-1.715 1.266 0 1.39 3.676 1.922 3.676 5.18 0 2.562-1.648 4.21-3.871 4.21-2.664 0-4.027-1.636-4.027-1.636l.71-2.324s1.403 1.187 2.587 1.187c.773 0 1.085-.601 1.085-1.039 0-1.816-3.015-1.895-3.015-4.875 0-2.512 1.828-4.937 5.512-4.937 1.418 0 2.12.402 2.12.402"
        ></path>
      </g>
    </svg>
  );
}

export default ShopifyIcon;
