import {
  sidebarDataTypeSectionV2,
  SidebarIcon,
} from '@/constants/layouts/sidebarData';

function BitLoginIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <BitLoginIconDefault {...props} />
      ) : (
        <BitLoginIconActive {...props} />
      )}
    </>
  );
}

function BitLoginIconDefault({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask id="path-1-inside-1_12225_10914" fill="#fff">
        <path d="M5.833 6.667a4.167 4.167 0 018.334 0v3.259a.074.074 0 01-.074.074H5.908a.074.074 0 01-.075-.074v-3.26z"></path>
      </mask>
      <path
        stroke="#727272"
        strokeWidth="3"
        d="M5.833 6.667a4.167 4.167 0 018.334 0v3.259a.074.074 0 01-.074.074H5.908a.074.074 0 01-.075-.074v-3.26z"
        mask="url(#path-1-inside-1_12225_10914)"
      ></path>
      <rect
        width="1.667"
        height="2.5"
        x="9.166"
        y="13.334"
        fill="#727272"
        rx="0.833"
      ></rect>
      <rect
        width="11.833"
        height="8.5"
        x="4.083"
        y="9.084"
        stroke="#727272"
        strokeWidth="1.5"
        rx="1.25"
      ></rect>
    </svg>
  );
}

function BitLoginIconActive({ size = 24 }: SidebarIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask id="path-1-inside-1_12225_11156" fill="#fff">
        <path d="M5.833 6.667a4.167 4.167 0 018.334 0v3.259a.074.074 0 01-.074.074H5.908a.074.074 0 01-.075-.074v-3.26z"></path>
      </mask>
      <path
        stroke="#FF7C11"
        strokeWidth="3"
        d="M5.833 6.667a4.167 4.167 0 018.334 0v3.259a.074.074 0 01-.074.074H5.908a.074.074 0 01-.075-.074v-3.26z"
        mask="url(#path-1-inside-1_12225_11156)"
      ></path>
      <rect
        width="1.667"
        height="2.5"
        x="9.166"
        y="13.334"
        fill="#FF7C11"
        rx="0.833"
      ></rect>
      <rect
        width="11.833"
        height="8.5"
        x="4.083"
        y="9.084"
        stroke="#FF7C11"
        strokeWidth="1.5"
        rx="1.25"
      ></rect>
    </svg>
  );
}

export default BitLoginIcon;
