import { useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { AuthorityKey, RoleAuthorities } from '@/types/systemLogin';

const useIsModuleRestricted = (id: AuthorityKey) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const userId = useUserId();

  return useQuery(
    ['user-company-authority', userId, activeCompany, id],
    () =>
      api.systemLogin
        .get<ApiResponse<RoleAuthorities | null>>(
          `/company/${activeCompany}/users/${userId}/authorities/${id}`
        )
        .then((res) => res.data.data),
    {
      select: (data) => !data?.enabled,
      meta: {
        disableToast: true,
      },
    }
  );
};

export default useIsModuleRestricted;
