import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { BitLinkShopifyCollections } from './useCollectionQuery';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';

const useCollection = (
  collectionId: string | undefined,
  { enabled, ...options }: UseQueryOptions<BitLinkShopifyCollections>
) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery<BitLinkShopifyCollections>(
    ['bitlink-shopify-collections', collectionId],
    async () => {
      const { data } = await api.bitLink.get(
        `/shopify/collection/${encodeURIComponent(
          collectionId!
        )}/company/${activeCompany}`
      );
      return data;
    },
    {
      enabled: !!collectionId && enabled,
      ...options,
    }
  );
};
export default useCollection;
