import { useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Plus, Trash2 } from 'react-feather';
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import DraggableIcon from 'assets/icons/BBBDraggableIcon';
import colors from 'constants/common/colors';
import { useShopifyIntegrations } from 'hooks/bitApp/integration/integrations';
import ShopifyCollectionListsOptions from 'pages/BitApp/components/PageRedirectionFields/CollectionLists';
import ShopifyAwareWrapper from 'components/ShopifyAwareWrapper';
import { destinationOptions, SchemaCategory } from '.';

import { BBBButton, BBBSelect, BBBTextInput } from '@/components/ui';

type Props = {
  control: Control<SchemaCategory>;
  setValue: UseFormSetValue<SchemaCategory>;
  watch: UseFormWatch<SchemaCategory>;
};

function SubCategory({ control, setValue, watch }: Props) {
  const { data: shopifyData } = useShopifyIntegrations();

  const { append, move, remove, fields } = useFieldArray({
    control,
    name: 'subCategories',
  });

  const { errors } = useFormState({
    control,
    name: 'subCategories',
  });

  const id = useWatch({
    control,
    name: 'id',
  });

  const handleRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  const renderSubCategoryItem = useMemo(() => {
    return (
      <>
        {fields?.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(providedDraggable) => (
              <div
                ref={providedDraggable?.innerRef}
                {...providedDraggable?.draggableProps}
                className="p-4.5 flex flex-col gap-2 bg-neutral-20 border border-neutral-30 rounded-lg hover:border-secondary-border transition-all duration-300 ease-in-out"
              >
                <div className="flex justify-between w-full items-center pb-2 border-b border-neutral-30">
                  <p className="text-primary-main font-semibold text-sm">
                    Sub-category {index + 1}
                  </p>
                  <Trash2
                    className="flex-none text-neutral-30 hover:text-danger-hover cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={(e) => {
                      handleRemove(index);
                      e.stopPropagation();
                    }}
                    size={20}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div {...providedDraggable?.dragHandleProps}>
                    <DraggableIcon />
                  </div>
                  <div className="flex flex-col gap-2 grow">
                    <BBBTextInput
                      label="Title"
                      value="Best selling flowers"
                      placeholder="Enter title"
                      isHookForm
                      control={control}
                      controlName={`subCategories.${index}.title`}
                      hasMaxCharLabel
                      maxChar={60}
                      containerClassname="mb-0"
                      error={errors.subCategories?.[index]?.title?.message}
                    />
                    <div className="flex items-center  gap-2.5">
                      <Controller
                        control={control}
                        name={`subCategories.${index}.destination`}
                        render={({ field }) => (
                          <BBBSelect
                            label="Destination page:"
                            options={destinationOptions}
                            optionLabel="label"
                            optionValue="value"
                            value={field?.value}
                            containerClassName="w-2/5"
                            onValueChange={(val) => {
                              field.onChange(val);
                              setValue(
                                `subCategories.${index}.idCollection`,
                                null
                              );
                              setValue(
                                `subCategories.${index}.customUrl`,
                                null
                              );
                            }}
                          />
                        )}
                      />
                      {watch(`subCategories.${index}.destination`).value ===
                      'collections' ? (
                        <div className="w-full">
                          <Controller
                            control={control}
                            name={`subCategories.${index}.idCollection`}
                            render={({ field }) => (
                              <ShopifyAwareWrapper
                                appType="BITAPP"
                                size="full"
                                fallbackToChildren
                                className="w-full"
                              >
                                {({
                                  data: shopifyWrapperData,
                                  connectMiddleware,
                                }) => (
                                  <ShopifyCollectionListsOptions
                                    label="Navigate to:"
                                    value={field.value?.toString()}
                                    onChange={(val) => {
                                      connectMiddleware(() => {
                                        field.onChange(val);
                                      });
                                    }}
                                    className="w-full"
                                    onClick={() =>
                                      connectMiddleware(() => {
                                        return;
                                      })
                                    }
                                    error={
                                      errors.subCategories?.[index]
                                        ?.idCollection?.message
                                    }
                                    {...(!shopifyData ||
                                    !shopifyWrapperData ||
                                    shopifyData?.status === 'DISCONNECTED'
                                      ? {
                                          isMenuOpen: false,
                                          isSearchable: false,
                                        }
                                      : {
                                          isSearchable: true,
                                        })}
                                  />
                                )}
                              </ShopifyAwareWrapper>
                            )}
                          />
                        </div>
                      ) : (
                        <BBBTextInput
                          label="Insert your URL"
                          placeholder="Insert your URL"
                          isUrl
                          isHookForm
                          control={control}
                          controlName={`subCategories.${index}.customUrl`}
                          error={
                            errors.subCategories?.[index]?.customUrl?.message
                          }
                          containerClassname="mb-0 w-full"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        ))}
      </>
    );
  }, [
    control,
    errors.subCategories,
    fields,
    handleRemove,
    setValue,
    shopifyData,
    watch(),
  ]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="bitapp-sub-categories">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            id="bitapp-sub-categories"
            className="w-full border-l-2 border-neutral-30 pl-4.5 rounded-l-sm flex flex-col gap-3.5 "
          >
            {renderSubCategoryItem}
            {provided.placeholder}
            <BBBButton
              text={<p className="font-bold grow">Add sub-category</p>}
              variant="secondary"
              size="sm"
              iconPosition="right"
              className="px-5 w-full"
              onClick={() =>
                append({
                  title: '',
                  idCollection: '',
                  position: 0,
                  categoryId: id,
                  destination: destinationOptions[0],
                  customUrl: '',
                })
              }
              icon={<Plus color={colors.primary.main} />}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default SubCategory;
