/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Bold, Italic, Underline } from 'react-feather';
import { RiStrikethrough } from 'react-icons/ri';
import { createId } from '@paralleldrive/cuid2';
import {
  ContentState,
  DraftHandleValue,
  Editor,
  EditorCommand,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js';
import {
  handleDraftEditorPastedText,
  registerCopySource,
} from 'draftjs-conductor';

import { cn } from '@/utils/styles';

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: <Bold size={18} color="#757575" /> },
  {
    label: 'Italic',
    style: 'ITALIC',
    icon: <Italic size={18} color="#757575" />,
  },
  {
    label: 'Underline',
    style: 'UNDERLINE',
    icon: <Underline size={18} color="#757575" />,
  },
  {
    label: 'Strikethrough',
    style: 'STRIKETHROUGH',
    icon: <RiStrikethrough size={18} color="#757575" />,
  },
];

export type BBBRichTextEditorTypes = {
  editorState: EditorState;
  onChangeEditorState?: (val: EditorState) => void;
  additionalInlineComponent?: React.ReactNode;
  withoutInline?: boolean;
  customInlineComponent?: React.ReactNode;
  handleKeyCommand?: (
    command: EditorCommand,
    editorState: EditorState,
    eventTimeStamp: number
  ) => DraftHandleValue;
  handleTab?: (e: React.KeyboardEvent) => DraftHandleValue;
  placeholder?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  children?: ReactNode;
  rows?: number;
  id?: string;
  error?: string;
  requiredLabel?: boolean;
} & (
  | {
      label?: string;
      labelClassName?: string;
    }
  | {
      label: React.ReactNode;
      labelClassName?: never;
    }
);

const BBBRichTextEditor = React.forwardRef<
  Draft.DraftComponent.Base.DraftEditor,
  BBBRichTextEditorTypes
>(
  (
    {
      editorState,
      onChangeEditorState,
      additionalInlineComponent,
      handleKeyCommand,
      handleTab,
      label,
      labelClassName,
      placeholder = 'ie: Hi, How can we help you?',
      containerClassName,
      containerStyle,
      children,
      withoutInline,
      customInlineComponent,
      onPaste,
      onKeyDown,
      rows = 4,
      id: _id = createId(),
      error,
      requiredLabel,
    },
    _ref
  ) => {
    const [isHover, setIsHover] = useState(false);
    const inlineStyle = editorState.getCurrentInlineStyle();
    const ref = useRef<Draft.DraftComponent.Base.DraftEditor>(null);
    const placeholderRef = useRef<HTMLDivElement | null>(null);
    // Use useImperativeHandle to allow the parent component to control the ref
    //@ts-ignore
    useImperativeHandle(_ref, () => ({
      focus: () => {
        if (ref.current) {
          ref.current.focus();
        }
      },
    }));

    useEffect(() => {
      if (ref.current) {
        //@ts-expect-error coming from implementation of the lib
        registerCopySource(ref.current);
      }
    }, []);

    const onInlineClick = (style: string) => {
      const nextState = RichUtils.toggleInlineStyle(editorState, style);
      onChangeEditorState?.(nextState);
    };

    const keyBindingFn = (e: React.KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
        return 'BOLD';
      }

      if ((e.ctrlKey || e.metaKey) && e.key === 'i') {
        return 'ITALIC';
      }

      if ((e.ctrlKey || e.metaKey) && e.key === 'u') {
        return 'UNDERLINE';
      }

      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        return 'STRIKETHROUGH';
      }

      if (
        e.keyCode === 83 &&
        (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
      ) {
        return 'save';
      }

      if (e.keyCode === 27) {
        return 'escape';
      }

      return getDefaultKeyBinding(e);
    };

    const onReturn = (e: React.KeyboardEvent): DraftHandleValue => {
      if (e.shiftKey) {
        onChangeEditorState?.(RichUtils.insertSoftNewline(editorState));
        return 'not-handled';
      }
      return 'handled';
    };

    const minHeight = (rows ?? 1) * 24;

    const autoHeight = () => {
      const draftRoot = document.querySelector(
        '.DraftEditor-root'
      ) as HTMLElement;

      const contentElHeight = document.querySelector(
        '.public-DraftEditor-content > div'
      )!.clientHeight;

      if (draftRoot) {
        draftRoot.style.height = '0';

        const placeholderHeight = placeholderRef.current?.clientHeight || 0;

        if (
          draftRoot.scrollHeight -
            Math.max(contentElHeight, placeholderHeight) <
          stylesRef.current!.clientHeight
        ) {
          draftRoot.style.height =
            draftRoot.scrollHeight +
            (placeholderHeight >= contentElHeight ? placeholderHeight : 32) +
            'px';
        } else {
          draftRoot.style.height = draftRoot.scrollHeight + 'px';
        }
      }
    };

    const handlePastedText = (
      text: string,
      html: string | undefined,
      editorState: EditorState
    ): DraftHandleValue => {
      function defaultHandle() {
        const newState = handleDraftEditorPastedText(html, editorState);

        if (newState) {
          onChangeEditorState?.(newState);
          return 'handled';
        }

        return 'not-handled';
      }

      return defaultHandle();
    };

    const focused = document.activeElement === ref?.current?.editor;

    const stylesRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const editorText = editorState.getCurrentContent().getPlainText('\u0001');

    useEffect(() => {
      autoHeight();
    }, [editorText]);

    const id = `bbb-custom-rich-${_id}`;

    return (
      <div className={cn('mb-3', containerClassName)} style={containerStyle}>
        {label && (
          <div className="flex items-center">
            {typeof label === 'string' ? (
              <div className={cn('text-ls text-primary-main', labelClassName)}>
                {label}
              </div>
            ) : (
              label
            )}
            {requiredLabel && <span className="text-danger-main">*</span>}
          </div>
        )}
        <div
          className={cn(
            'grow bg-neutral-10 flex flex-col form-default px-4 py-2 cursor-text transition-all rounded-lg relative',
            focused ? 'form-focus' : isHover && 'form-hover'
          )}
          onMouseDown={() => {
            ref?.current?.focus();
          }}
          tabIndex={-1}
          onBlur={(e) => {
            const target = e.relatedTarget as HTMLElement | null;

            if (target?.tagName !== 'EM-EMOJI-PICKER') {
              const isBlurRich =
                target?.closest(`#${id}`) === document.querySelector(`#${id}`);
              if (isBlurRich) {
                ref.current?.focus();
                onChangeEditorState?.(
                  EditorState.forceSelection(
                    editorState,
                    editorState.getSelection()
                  )
                );
              }
            }
          }}
          id={id}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div
            className={cn(`relative flex flex-col`)}
            // @ts-ignore
            onClick={(e) => {
              ref?.current?.focus();
              e.stopPropagation();
            }}
            ref={inputRef}
            style={{
              minHeight,
            }}
          >
            <div
              className={cn(
                'absolute top-0 left-0 right-0 text-gray-400 pointer-events-none',
                editorText.length && 'opacity-0'
              )}
              ref={placeholderRef}
            >
              {placeholder}
            </div>

            <Editor
              ref={ref}
              editorState={editorState}
              //@ts-ignore
              onChange={onChangeEditorState}
              keyBindingFn={keyBindingFn}
              handleKeyCommand={(...params) => {
                const command = params[0];

                if (
                  ['BOLD', 'ITALIC', 'STRIKETHROUGH', 'UNDERLINE'].includes(
                    command
                  )
                ) {
                  const nextState = RichUtils.toggleInlineStyle(
                    editorState,
                    command
                  );
                  onChangeEditorState?.(nextState);
                  return 'handled';
                }

                return handleKeyCommand?.(...params) || 'not-handled';
              }}
              handleReturn={onReturn}
              onTab={handleTab}
              handlePastedText={handlePastedText}
            />
          </div>
          {children}
          <div className="absolute bottom-2.5 right-4" ref={stylesRef}>
            {!withoutInline ? (
              <div
                className="flex justify-end gap-1 select-none items-center"
                data-is-rich-text-style={true}
                tabIndex={-1}
              >
                {INLINE_STYLES.map((d, i) => (
                  <div
                    className={cn(
                      `p-1 cursor-pointer hover:bg-secondary-surface bg-neutral-10 hover:text-secondary-main transition-colors duration-300 select-none rounded-lg`,
                      inlineStyle.has(d.style)
                        ? 'bg-secondary-surface text-secondary-main'
                        : ''
                    )}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      onInlineClick(d.style);
                    }}
                    data-is-rich-text-style={true}
                    tabIndex={-1}
                  >
                    {d.icon || d.label}
                  </div>
                ))}
                {additionalInlineComponent}
              </div>
            ) : (
              customInlineComponent
            )}
          </div>
        </div>
        {error && <div className="text-red-500">{error}</div>}
      </div>
    );
  }
);

BBBRichTextEditor.displayName = 'BBBRichTextEditor';

export default BBBRichTextEditor;
