import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import useWebActivityStream from '../../hooks/useWebActivityStream';

import { BBBCard, BBBTooltip } from '@/components/ui';
import useCustomerByPhoneNumber from '@/hooks/customers/customer/useCustomerByPhoneNumber';
import useWebActivity from '@/hooks/pixels/useWebActivity';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import { Event } from '@/types/pixel/event';
import { formatActivityDate } from '@/utils/common/date';

const animation = {
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: {
    duration: 0.3,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
};

export default function WebActivity() {
  return <_WebActivityOnboarded />;
}

function _WebActivityOnboarded() {
  const isGroup = useIsGroupChat();

  if (isGroup) {
    return (
      <>
        <Header />
        <Info
          message={
            <p className="text-center">
              Web activity data are not <br />
              available for this channel
            </p>
          }
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <_WebActivity />
    </>
  );
}

function _WebActivity() {
  const { data: customerData, isInitialLoading: customerLoading } =
    useCustomerByPhoneNumber();

  const { data: activities } = useWebActivity({
    ...(customerData?.email && { email: customerData?.email }),
    ...(customerData?.phoneNumber && {
      phone: customerData?.phoneNumber,
    }),
  });

  const { data: updateActivities, activePing } = useWebActivityStream({
    email: customerData?.email,
    phoneNumber: customerData?.phoneNumber,
  });

  const events = useMemo(() => {
    if (activities?.events?.length === 0) {
      return activities?.events || [];
    }

    if (updateActivities?.length) {
      const updatedEvents = [
        ...(updateActivities?.flatMap((activity) => activity.event) || []),
        ...(activities?.events || []),
      ];

      return updatedEvents;
    }

    return activities?.events;
  }, [activities?.events, updateActivities]);

  const isHasSameEventId = useMemo(
    () =>
      events?.find((event) => event.event_id === activePing?.event?.event_id)
        ?.event_id,
    [activePing?.event?.event_id, events]
  );

  const isActivityEmpty =
    activities?.events?.length === 0 || activities?.events === undefined;

  const activityData = useMemo(
    () => (events?.length ? events?.slice(1) : events?.slice(0, 10)),
    [events]
  );

  if (isActivityEmpty) {
    return <Info message="No web activity" />;
  }

  if (customerLoading) {
    return <Skeleton className="rounded-lg" height={180} />;
  }

  return (
    <BBBCard>
      <p className="text-neutral-50 font-medium text-sm uppercase mb-2.5">
        History
      </p>
      {!isActivityEmpty && (
        <AnimatePresence>
          <Activity
            activity={events?.[0]}
            isActiveIndicator={
              isHasSameEventId === events?.[0]?.event_id ||
              updateActivities?.[0]?.event?.event_id === events?.[0]?.event_id
            }
            key={events?.[0]?.event_id}
            className="mb-4"
          />
        </AnimatePresence>
      )}
      <AnimatePresence>
        <motion.div
          layout
          key="content"
          initial={Object.keys(animation.variants)[1]}
          animate={Object.keys(animation.variants)[0]}
          exit={Object.keys(animation.variants)[1]}
          className="flex flex-col gap-4"
          variants={animation.variants}
          transition={animation.transition}
        >
          <Activities
            activities={activityData || []}
            activeEvent={isHasSameEventId}
          />
          {/* <CartItems
            carts={cartData || []}
            animation={animation}
            isEmpty={isCartEmpty}
          />
          {activities?.cart_data?.lines &&
            activities?.cart_data?.lines?.length > 3 && (
              <ToggleShowMore
                show={!isCartEmpty && (carts?.lines?.length || 0) > 3}
                isActive={showMore.cart}
                toggleShowActivities={() => toggleShowMore('cart')}
              />
            )} */}
        </motion.div>
      </AnimatePresence>
    </BBBCard>
  );
}

function Header() {
  return <BBBCard title="Web Activity" />;
}

function Activities({
  activities,
  activeEvent,
}: {
  activities: (Event | undefined)[];
  activeEvent?: string;
}) {
  return (
    <>
      {activities?.map((activity) => (
        <Activity
          isActiveIndicator={activeEvent === activity?.event_id}
          key={activity?.event_id}
          activity={activity}
        />
      ))}
    </>
  );
}

function Activity({
  activity,
  key,
  isActiveIndicator,
  className,
}: {
  activity: Event | undefined;
  key?: string;
  isActiveIndicator: boolean;
  className?: string;
}) {
  return (
    <motion.div
      layout
      key={key}
      initial={Object.keys(animation.variants)[1]}
      animate={Object.keys(animation.variants)[0]}
      exit={Object.keys(animation.variants)[1]}
      variants={animation.variants}
      transition={animation.transition}
      className={className}
    >
      <div className="w-full flex justify-between items-center gap-3">
        <div className="relative flex grow items-center gap-1">
          <Indicator isActive={isActiveIndicator} />
          <BBBTooltip
            content={activity?.page_pathname}
            show={
              activity?.page_pathname && activity?.page_pathname?.length > 30
                ? true
                : false
            }
          >
            <p className="text-neutral-60 line-clamp-1">
              {activity?.page_pathname && activity?.page_pathname?.length > 30
                ? `${activity?.page_pathname?.slice(0, 30)}...`
                : activity?.page_pathname}
            </p>
          </BBBTooltip>
        </div>
        <div className="text-neutral-40 text-xs">
          {formatActivityDate(activity?.timestamp)}
        </div>
      </div>
    </motion.div>
  );
}

function Indicator({ isActive }: { isActive: boolean }) {
  return (
    <div
      className={cx(
        'p-[2.5px] rounded-full border',
        isActive ? 'border-success-main ' : 'border-neutral-40'
      )}
    >
      <div
        className={cx(
          'rounded-full w-[7px] h-[7px]',
          isActive ? 'bg-success-main' : 'bg-neutral-40'
        )}
      />
    </div>
  );
}

function Info({ message }: { message: string | React.ReactNode }) {
  return (
    <BBBCard>
      <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="25" fill="#DDDDDD" />
          <path
            d="M21.4 33.4H28.6M16 20.8C16 20.3226 16.1896 19.8648 16.5272 19.5272C16.8648 19.1896 17.3226 19 17.8 19H32.2C32.6774 19 33.1352 19.1896 33.4728 19.5272C33.8104 19.8648 34 20.3226 34 20.8V28.9C34 29.3774 33.8104 29.8352 33.4728 30.1728C33.1352 30.5104 32.6774 30.7 32.2 30.7H17.8C17.3226 30.7 16.8648 30.5104 16.5272 30.1728C16.1896 29.8352 16 29.3774 16 28.9V20.8Z"
            stroke="#757575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="text-neutral-50">{message}</p>
      </div>
    </BBBCard>
  );
}
